import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import stackDiagram from "Assets/Images/StackDiagram.png";
import swapDiagram from "Assets/Images/SwapDiagram.png";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import {
    InstallationCalendarType,
    useUpdateInstallationAppointmentMutation,
    useUpdateServiceOrderScheduledDateMutation,
} from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
    popPendingMovement,
    selectMovementResolutionOption,
    selectPendingMovement,
    setPendingMovementResolution,
} from "Redux/weeklyIstallationCalendarReducer";

export type MovementResolutionOption = "Swap" | "Stack";

export default function MovementResolutionDialog() {
    const pendingMovement = useAppSelector(selectPendingMovement);
    const showPendingDialog = pendingMovement !== undefined;
    const movementResolutionOption = useAppSelector(selectMovementResolutionOption);
    const dispatch = useAppDispatch();

    const showSwap = pendingMovement?.isSwapAllowed === true;

    const [updateAppointment, { loading: loadingAppointment }] =
        useUpdateInstallationAppointmentMutation({
            onCompleted() {
                dispatch(popPendingMovement());
            },
        });
    const [updateService, { loading: loadingService }] = useUpdateServiceOrderScheduledDateMutation(
        {
            onCompleted() {
                dispatch(popPendingMovement());
            },
        }
    );

    function updateBlock(
        type: InstallationCalendarType,
        id: number,
        newDate: string,
        contractorId: number
    ) {
        if (type === "SERVICE") {
            updateService({
                variables: {
                    serviceOrderId: id,
                    scheduledDate: newDate,
                    contractorId: contractorId,
                },
            });
        } else if (type === "INSTALLATION") {
            updateAppointment({
                variables: {
                    appointmentId: id,
                    newStartDate: newDate,
                    newContractorId: contractorId,
                },
            });
        }
    }

    function submitBlock() {
        if (loadingAppointment || loadingService || !showPendingDialog) return;
        else {
            // Submit block change
            // Moves source obj and id to destination date and contractor id
            updateBlock(
                pendingMovement.sourceType,
                +pendingMovement.sourceIdentifier,
                pendingMovement.destinationDate,
                pendingMovement.destinationContractorId
            );

            // If the mode is swap, then move the destination object to the sources position
            if (
                movementResolutionOption === "Swap" &&
                isNotNullOrUndefined(pendingMovement.destinationType)
            ) {
                updateBlock(
                    pendingMovement.destinationType!,
                    +pendingMovement.destinationIdentifier!,
                    pendingMovement.sourceDate,
                    pendingMovement.sourceContractorId
                );
            }
        }
    }

    return (
        <Dialog open={showPendingDialog}>
            <DialogTitle>Override Needed</DialogTitle>
            <DialogContent>
                <RadioGroup
                    name={"movement-resolution-options"}
                    value={movementResolutionOption ?? null}
                    row
                    onChange={(e) =>
                        dispatch(
                            setPendingMovementResolution(e.target.value as MovementResolutionOption)
                        )
                    }
                >
                    {showSwap && (
                        <FormControlLabel
                            value="Swap"
                            control={<Radio />}
                            label={
                                <div className="flex-column">
                                    <img
                                        src={swapDiagram}
                                        style={{ width: "10rem" }}
                                        alt="Diagram showing appointments being swapped"
                                    />
                                    <Typography>Exchange both appointments</Typography>
                                </div>
                            }
                            labelPlacement="top"
                        />
                    )}
                    <FormControlLabel
                        value="Stack"
                        control={<Radio />}
                        label={
                            <div className="flex-column">
                                <img
                                    src={stackDiagram}
                                    style={{ width: "10rem" }}
                                    alt="Diagram showing appointments being stacked"
                                />
                                <Typography>Stack at destination</Typography>
                            </div>
                        }
                        labelPlacement="top"
                    />
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <SpacedButton
                    variant="outlined"
                    color="secondary"
                    onClick={() => dispatch(popPendingMovement())}
                >
                    Cancel
                </SpacedButton>
                <SpacedButton
                    variant="contained"
                    color="primary"
                    onClick={() => submitBlock()}
                >
                    Submit
                </SpacedButton>
            </DialogActions>
        </Dialog>
    );
}
