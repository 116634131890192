import { IconButton } from "@material-ui/core";
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatNotCertifiedButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return (
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatNotCertifiedIcon color={color}/>
        </IconButton>
    )
}

export function FlatNotCertifiedIcon({color="black"} : {color?: string}) {
    return <GppMaybeOutlinedIcon htmlColor={color}/>
}