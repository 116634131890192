import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Input, Typography } from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { GetJobContractDocument, GetJobContractQueryVariables, GetJobContractRevisionIdsDocument, GetJobContractRevisionIdsQueryVariables, JobConfiguration, Price, useGetJobConfigurationWithWasteFactorQuery, useGetQuotePriceForJobChangesLazyQuery, useSubmitChangeOrderMutation } from "generated/graphql";
import { isNullOrUndefined, isNumber } from "Globals/GenericValidators";
import { debounce } from "lodash";
import { useCallback } from "react";
import { convertConfigToInput, MAKE_NEW_AREA_INDEX, selectAreaIndex, selectChangeOrderEditorMode, selectDisplayMargin, selectDisplayPrice, selectLockDisplayPrice, setChangeOrderAreaIndex, setGenericSalesReducerState, setLockPrice, setMargin, setQuotePrice, UNASSINGED_AREA_INDEX } from "Redux/genericSalesReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { store } from "Redux/store";
import ChangeOrderAreaSelect from "./ChangeOrderAreaSelect";
import ChangeOrderAreaSqftEditor from "./ChangeOrderAreaSqftEditor";
import EditExistingAreaRooms from "./COEditExistingAreaRooms";
import { COJobProductEditor } from "./COJobProductEditor";
import COAreaSqftAdditionalDetailsViewer from "./TestingHelpers/COAreaSqftAdditionalDetailsViewer";

interface BaseChangeOrderProps {
    jobConfigurationId: number,
    jobContractId: number
}


interface ChangeOrderDialogProps extends BaseChangeOrderProps {
    open: boolean,
    setOpen: (open: boolean) => void
}

export default function ChangeOrderDialog({ setOpen, open, ...baseProps }: ChangeOrderDialogProps) {

    return (
        <>
            {
                open &&
                <ChangeOrderDialogInner closeDialog={() => setOpen(false)} {...baseProps} />
            }
        </>
    )
}

interface ChangeOrderDialogInnerProps extends BaseChangeOrderProps {
    closeDialog: () => void
}

function ChangeOrderDialogInner({ closeDialog, jobConfigurationId, jobContractId }: ChangeOrderDialogInnerProps) {

    const [recalculateQuery] = useGetQuotePriceForJobChangesLazyQuery({
        onCompleted(data) {
            if (data) {
                dispatch(setQuotePrice({ price: data.quotePriceForJobChanges.price.toFixed(2), cost: data.quotePriceForJobChanges.cost }))
            }
        }
    })

    const [submitChangeOrder] = useSubmitChangeOrderMutation({
        refetchQueries: [
            {
                query: GetJobContractDocument,
                variables: { jobContractId } as GetJobContractQueryVariables,
                fetchPolicy: "network-only"
            },
            {
                query: GetJobContractRevisionIdsDocument,
                variables: { jobContractId } as GetJobContractRevisionIdsQueryVariables,
                fetchPolicy: "network-only"
            },
        ]
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedRecalculatePrice = useCallback(debounce(() => {
        var config = store.getState().genericSales.jobConfiguration!

        recalculateQuery({
            variables: {
                originalJobConfigurationId: jobConfigurationId,
                scaledUpdatedJobConfiguration: convertConfigToInput(config)
            }
        })
    }, 1000), [recalculateQuery, jobConfigurationId]);

    const dispatch = useAppDispatch()

    const { data, loading } = useGetJobConfigurationWithWasteFactorQuery({
        variables: { jobConfigurationId },
        fetchPolicy: "network-only",
        nextFetchPolicy: 'cache-first',
        onCompleted(data) {
            dispatch(setGenericSalesReducerState(data.jobConfigurationWithWasteFactor))
            const defaultAreaIndex = data.jobConfigurationWithWasteFactor.areas.length > 0 ? 0 : UNASSINGED_AREA_INDEX
            dispatch(setChangeOrderAreaIndex(defaultAreaIndex))
            debouncedRecalculatePrice();
        }
    });

    if (data || loading) { }

    const editorMode = useAppSelector(selectChangeOrderEditorMode)

    function submitChanges() {
        const salesState = store.getState().genericSales
        var config = salesState.jobConfiguration!

        if (changesReadyToSubmit(config) && isNumber(salesState.displayPrice) && +salesState.displayPrice > 0) {
            const configWithPrice = { ...config, price: { mSRP: 0, savings: 0, ...config.price, total: +salesState.displayPrice } as Price }

            submitChangeOrder({
                variables: {
                    originalJobConfigurationId: jobConfigurationId,
                    scaledUpdatedJobConfiguration: convertConfigToInput(configWithPrice, true)
                }
            }).then(closeDialog, () => window.alert("Cannot submit changes"))
        }
    }

    return (
        <Dialog open={true}>
            <DialogTitle style={{ margin: "auto" }}>
                {editorMode}
            </DialogTitle>
            <DialogContent>
                <ChangeOrderAreaEditor requotePrice={debouncedRecalculatePrice} />
            </DialogContent>
            <DialogActions>
                <COPriceAdjustments />
                <SpacedButton variant="outlined" color="secondary" onClick={closeDialog}>Close</SpacedButton>
                <SpacedButton variant="contained" color="secondary" onClick={submitChanges}>Submit</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}

function changesReadyToSubmit(jobConfiguration: JobConfiguration): boolean {
    var result: boolean = true;

    jobConfiguration.areas.forEach(area => {
        if (result === false) return;

        const areaName = () => getNameOfArea(area.rooms.flatMap(room => room.labels))

        if (isNullOrUndefined(area.productTypeId)) {
            window.alert(`${areaName()} is missing a product type`)
            result = false;
        }
        else if (isNullOrUndefined(area.styleId)) {
            window.alert(`${areaName()} is missing a style`)
            result = false;
        }
        else if (isNullOrUndefined(area.colorId)) {
            window.alert(`${areaName()} is missing a style color`)
            result = false;
        }
    })

    return result;
}


function COPriceAdjustments() {

    const displayPrice = useAppSelector(selectDisplayPrice)
    const margin = useAppSelector(selectDisplayMargin)
    const lockDisplayPrice = useAppSelector(selectLockDisplayPrice)

    const dispatch = useAppDispatch()
    function updatePrice(newPrice: string) {
        dispatch(setQuotePrice({ price: newPrice }))
    }

    function updateMargin(newMargin: string) {
        dispatch(setMargin({ margin: newMargin }))
    }

    function updateLock(newLock: boolean) {
        dispatch(setLockPrice(newLock))
    }

    return (
        <div className="flex-row fill-width" style={{ justifyContent: "flex-start", alignItems: "center" }}>
            <Typography style={{ marginRight: ".5rem" }} >Price:</Typography>
            <Input
                value={displayPrice}
                onChange={e => updatePrice(e.target.value as string)}
                style={{ width: "6em" }}
                startAdornment="$"
                disabled={lockDisplayPrice}
            />
            <Input
                value={margin}
                onChange={e => updateMargin(e.target.value as string)}
                style={{ width: "4em", marginRight: ".5rem" }}
                inputProps={{ className: "text-align-right" }}
                endAdornment="%"
                disabled={lockDisplayPrice}
            />
            <Checkbox
                style={{ height: "2rem" }}
                checked={lockDisplayPrice}
                onChange={e => updateLock(e.target.checked)}
            />
            <Typography>Lock Price</Typography>
        </div>
    )
}

export interface UpdateQuotePriceProps {
    requotePrice: () => void
}

function ChangeOrderAreaEditor({ requotePrice }: UpdateQuotePriceProps) {

    const dispatch = useAppDispatch()
    const selectedAreaIndex = useAppSelector(selectAreaIndex)
    function updateAreaIndex(newIndex: number) {
        dispatch(setChangeOrderAreaIndex(newIndex))
        requotePrice();
    }

    return (
        <div style={{ width: "30rem", display: "flex", flexDirection: "column", justifyContent: "flex-start", minHeight: "30rem" }}>
            <ChangeOrderAreaSelect selectedAreaIndex={selectedAreaIndex} onChange={updateAreaIndex} showAddNewArea />
            {
                selectedAreaIndex >= 0 ?
                    <ChangeOrderExistingProductContent requotePrice={requotePrice} /> :
                    selectedAreaIndex === UNASSINGED_AREA_INDEX ?
                        <ChangeOrderUnassignedProductContent /> :
                        selectedAreaIndex === MAKE_NEW_AREA_INDEX ?
                            <ChangeOrderNewProductContent /> :
                            <></>
            }
        </div>
    )
}

function ChangeOrderExistingProductContent({ requotePrice }: UpdateQuotePriceProps) {
    return (
        <>
            <ChangeOrderAreaSqftEditor requotePrice={requotePrice} />
            <COAreaSqftAdditionalDetailsViewer />
            <EditExistingAreaRooms />
            <COJobProductEditor requotePrice={requotePrice} />
        </>
    )
}

function ChangeOrderUnassignedProductContent() {
    return <></>
}

function ChangeOrderNewProductContent() {

    return <></>
}
