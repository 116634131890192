import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useGetAllHowDidYouHearAboutUsOptionsQuery } from "generated/graphql";
import { selectHowDidYouHearId, setHowDidYouHearId } from "Redux/callCenterSchedulingReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";

export default function HowDidYouHearAboutUsSelector() {
    const {data} = useGetAllHowDidYouHearAboutUsOptionsQuery();
    const options = data?.allHowDidYouHearAboutUsOptions ?? [];

    const dispatch = useAppDispatch();
    const selectionId = useAppSelector(selectHowDidYouHearId);

    return (
        <FormControl className="w-13r whitespace-no-wrap">
            <InputLabel>How did you hear about us?</InputLabel>
            <Select
                value={selectionId}
                onChange={(e) => dispatch(setHowDidYouHearId(e.target.value as number))}
                required
            >
                {(selectionId < 1) && (<MenuItem key="how-did-you-hear-op-none" value={-1} disabled>Select Option</MenuItem>)}
                {options.map(option =>
                    <MenuItem key={`how-did-you-hear-op-${option.id}`} value={option.id}>{option.optionText}</MenuItem>)}
            </Select>
        </FormControl>
    )
}