import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useGetPriorityOptionsQuery } from "generated/graphql";
import { selectPriorityItemIds, setSelectedPriorityItemIds } from "Redux/callCenterSchedulingReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";

export default function PrioritySelector() {
    const { data } = useGetPriorityOptionsQuery();
    const options = data?.priorityOptions ?? [];

    const dispatch = useAppDispatch();
    const selectedPriorityItemIds = useAppSelector(selectPriorityItemIds);


    return (
        <FormControl className="w-13r whitespace-no-wrap">
            <InputLabel>Priority</InputLabel>
            <Select
                multiple
                value={selectedPriorityItemIds}
                onChange={(e) => dispatch(setSelectedPriorityItemIds(e.target.value as number[]))}
            >
                {options.map(opt => <MenuItem key={`priority-${opt.id}`} value={opt.id}>{opt.text}</MenuItem>)}
            </Select>
        </FormControl>
    )
}