import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton
} from "@material-ui/core";
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined";
import CloseIcon from "@material-ui/icons/Close";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import clsx from "clsx";
import ProjectCard from "Components/ProjectDashboards/ProjectCard";
import {
    useSetCallCenterClaimMutation,
    useSetCallCenterRecoveryClaimMutation,
    Worker
} from "generated/graphql";
import {
    dateTimeStrToMdy,
    dateTimeStrToTime,
    hourMinuteTo12HHMM
} from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { buildAppendedId } from "Globals/Hooks";
import { callCenterExistingJobPath, callCenterSchedulerPath, dialerLandingPage } from "Globals/PathStrings";
import { useAppHistory } from "Globals/routingHooks";
import { formatNameStringLastFirst } from "Globals/StringFormatting";
import RecoveryLedger from "Pages/Admin/RecoveryCenter/RecoveryLedger";
import { useState } from "react";
import CallCenterCallCustomerPhoneButton from "./CallCenterCallCustomerPhoneButton";
import { CallCenterJob } from "./CallCenterDashboard";

interface CallCenterCardProps {
    card: CallCenterJob;
    headerDividerClassName: string;
}

export default function CallCenterCard({card, headerDividerClassName}: CallCenterCardProps) {
    const [ledgerOpen, setLedgerOpen] = useState(false);

    const customerName = formatNameStringLastFirst({
        firstName: card.customerFirstName,
        lastName: card.customerLastName,
    });

    const [claimCardMutation] = useSetCallCenterClaimMutation();
    const [claimRecoveryCardMutation] = useSetCallCenterRecoveryClaimMutation();

    const history = useAppHistory();

    var claimCard: (() => void) | undefined = undefined;

    if (card.isInRecovery) {
        if (isNullOrUndefined(card.recoveryCallWorker))
            claimCard = () => claimRecoveryCardMutation({ variables: { jobId: card.id } });
    } else if (isNullOrUndefined(card.callCenterWorker)) {
        claimCard = () => claimCardMutation({ variables: { jobId: card.id, isClaimed: true } });
    }

    function onCustomerClick() {
        if (isNotNullOrUndefined(card.appointment)) {
            // Has appointment
            history.push(callCenterExistingJobPath + buildAppendedId(card.id));
        } else {
            // No appointment, need to schedule
            history.push(callCenterSchedulerPath + buildAppendedId(card.customerId));
        }
    }

    function onCallCustomer() {
        history.push(dialerLandingPage + buildAppendedId(card.customerPhone));
    }

    return (
        <>
            <CallCenterCardVisual
                {...card}
                headerDividerClassName={headerDividerClassName}
                claimCard={claimCard}
                onCustomerClick={onCustomerClick}
                openLedger={() => setLedgerOpen(true)}
                onCallCustomer={onCallCustomer}
            />
            <Dialog
                open={ledgerOpen}
                onClose={() => setLedgerOpen(false)}
                maxWidth="xl"
            >
                <DialogTitle>
                    <div
                        className="flex-row"
                        style={{ padding: ".5rem 0", justifyContent: "space-between" }}
                    >
                        <div>
                            {customerName} {card.appointment?.appointmentNumber}
                        </div>
                        <IconButton onClick={() => setLedgerOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <RecoveryLedger jobId={card.id} />
                </DialogContent>
            </Dialog>
        </>
    );
}

interface CallCenterCardVisualProps extends CallCenterJob {
    onCustomerClick?: () => void;
    claimCard?: () => void;
    onCallCustomer?: () => void;
    emailCustomer?: () => void;
    openLedger?: () => void;
    headerDividerClassName: string;
}

function CallCenterCardVisual({
    id,
    customerPhone,
    isInRecovery,
    recoveryCallWorker,
    callCenterWorker,
    appointment,
    onCustomerClick,
    customerFirstName,
    customerLastName,
    customerCity,
    minutesFromCreation,
    callBackTime,
    claimCard,
    lastAction,
    emailCustomer,
    onCallCustomer,
    openLedger,
    headerDividerClassName
}: CallCenterCardVisualProps) {
    const displayWorker = (isInRecovery ? recoveryCallWorker : callCenterWorker) ?? undefined;
    const workerName = formatAgentName(displayWorker);
    const workerDisplayColor =
        (displayWorker === undefined) ? undefined 
            : isInRecovery
                ? "var(--card-red)"
                : "var(--card-blue)";

    const hasAppointment = isNotNullOrUndefined(appointment);
    const apptNum = hasAppointment ? appointment?.appointmentNumber! : "No Appt.";

    const customerName = formatNameStringLastFirst({
        firstName: customerFirstName,
        lastName: customerLastName
    });

    const dateStr = hasAppointment
        ? dateTimeStrToMdy(appointment?.scheduledDate)
        : formatAge(minutesFromCreation);
    const dateComponent = (
        <p
            className="flat-font-sm bold-text margin-none text-align-right"
            style={{paddingRight: "4pt"}}
        >
            {dateStr}
        </p>
    )

    const hasLastAction = isNotNullOrUndefined(lastAction);
    const canClaimCard = claimCard !== undefined;

    const hasCallback = isNotNullOrUndefined(callBackTime);
    const { hour, minute } = hasCallback
        ? dateTimeStrToTime(callBackTime!)
        : { hour: -1, minute: 0 };
    const callbackStr = hour <= 0 ? "Today" : hourMinuteTo12HHMM({ hour, minute }, true);

    return (
        <ProjectCard
            cardNumber={apptNum}
            customerName={customerName}
            customerCity={customerCity}
            onCustomerClick={onCustomerClick}
            date={dateComponent}
            dividerClass={headerDividerClassName}
        >
            <div className="flex-column">
                <span className="cc-card-worker-row">
                    <PersonOutlineIcon
                        className="agent-icon"
                        style={{ fontSize: "unset" }}
                    />

                    <p
                        className="flat-font margin-none"
                        style={{ color: workerDisplayColor }}
                    >
                        {workerName}
                    </p>
                </span>

                {hasLastAction && (
                    <span className="cc-card-action-row">
                        <p className="flat-font margin-none">
                            Last: {lastAction?.callCenterActionOptionLabel}
                        </p>

                        <p className="flat-font margin-none">
                            {dateTimeStrToMdy(lastAction?.date)}
                        </p>
                    </span>
                )}

                {hasCallback && (
                    <span className="cc-card-action-row">
                        <p className="flat-font margin-none">
                            Callback: {callbackStr}
                        </p>

                        <p className="flat-font margin-none">
                            {dateTimeStrToMdy(callBackTime!)}
                        </p>
                    </span>
                )}

                {canClaimCard ? (
                    <CallCenterCardBottomRow
                        jobId={id}
                        onClaim={claimCard}
                        onCallCustomer={onCallCustomer!}
                        customerPhone={customerPhone}
                        emailCustomer={emailCustomer!}
                        onOpenLedger={openLedger!}
                    />
                ) : (
                    <div
                        className="flex-grow flex-row"
                        style={{ justifyContent: "flex-end" }}
                    >
                        <IconButton onClick={openLedger} >
                            <LibraryBooks style={{ width: "1rem", height: "1rem" }} htmlColor="black" />
                        </IconButton>
                    </div>
                )}
            </div>
        </ProjectCard>
    )
}

interface CallCenterCardBottomRowProps {
    jobId: number;
    onClaim: () => void;
    onCallCustomer: () => void;
    customerPhone: string;
    emailCustomer: () => void;
    onOpenLedger: () => void;
}

export function CallCenterCardBottomRow({
    onClaim,
    onCallCustomer,
    customerPhone,
    emailCustomer,
    jobId,
    onOpenLedger
}: CallCenterCardBottomRowProps) {
    const canClaim = isNotNullOrUndefined(onClaim);

    return (
        <div className="project-card-bottom-row">
            <div
                className="half-width flat-dark-bkg clickable"
                onClick={onClaim}
            >
                <p className="flat-font margin-none less-bold-text">CLAIM</p>
            </div>

            <div className={clsx("flat-darker-bkg padding-vertical-xsm", {"half-width": canClaim})}>
                {/* TODO: Remove usage of this call customer phone button. Needs to not be setup specifically for recovery center*/}
                <CallCenterCallCustomerPhoneButton
                    onCallConnected={onCallCustomer}
                    phoneNumber={customerPhone}
                    jobId={jobId}
                />

                <TextsmsOutlinedIcon
                    className="project-card-action-icon"
                    onClick={onCallCustomer}
                />

                <AlternateEmailOutlinedIcon className="project-card-action-icon" onClick={emailCustomer}/>

                <LibraryBooks className="project-card-action-icon" onClick={onOpenLedger}/>
            </div>
        </div>
    )
}

function formatAgentName(worker?: Partial<Worker>): string {
    if (worker === undefined) return "Unassigned";
    else return formatNameStringLastFirst(worker);
}

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;
const MINUTES_IN_WEEK = MINUTES_IN_DAY * 7;
const MINUTES_IN_MONTH = MINUTES_IN_DAY * 30.42;

function formatAge(ageInMinutes: number): string {
    if (ageInMinutes > MINUTES_IN_MONTH)
        return formatAgeText(ageInMinutes, MINUTES_IN_MONTH, "mth");
    else if (ageInMinutes > MINUTES_IN_WEEK)
        return formatAgeText(ageInMinutes, MINUTES_IN_WEEK, "wk");
    else if (ageInMinutes > MINUTES_IN_DAY)
        return formatAgeText(ageInMinutes, MINUTES_IN_DAY, "day");
    else if (ageInMinutes > MINUTES_IN_HOUR)
        return formatAgeText(ageInMinutes, MINUTES_IN_HOUR, "hr");
    else return formatAgeText(ageInMinutes, 1, "min");
}

function formatAgeText(ageInMinutes: number, divisor: number, unit: string) {
    const age = Math.floor(ageInMinutes / divisor);
    const plural = age > 1 ? "s" : "";
    return `${age.toFixed(0)} ${unit}${plural}`;
}
