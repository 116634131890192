import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CarpetCostsRowMode } from 'Pages/Admin/ProductCosts/CarpetMode/CarpetCostsStyleRow'
import { RootState } from './store'

export type ChangedValue<T> = { value?: T, changed?: boolean}
export type KeyedChangedNumber = { [key: string]: ChangedValue<number> }
export type KeyedChangedString = { [key: string]: ChangedValue<string> }

interface ProductCostsState { //Defined so that create slice can infer the type
    vendorId: number,
    hardOnlyProductTypeId: number,
    drawerOpen: boolean,
    isSoft: boolean,
    carpetCostMode: CarpetCostsRowMode,
    changedBulks: KeyedChangedNumber,
    changedSingles: KeyedChangedNumber,
    tabOpen: { [key: number]: boolean }
}

const initialState: ProductCostsState = {
    vendorId: -1,
    hardOnlyProductTypeId: 1,
    drawerOpen: false,
    isSoft: false,
    carpetCostMode: "sqft",
    changedBulks: {},
    changedSingles: {},
    tabOpen: {}
}

export function toRowId(styleId: number, sourceId?: number) {
    if (sourceId === undefined) return styleId.toString()
    else return `${styleId}-${sourceId}`
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const productCostsSlice = createSlice({
    name: "productCosts",
    initialState,
    reducers: {
        changeSingleCost: {
            reducer(state, action: PayloadAction<{ cost?: number, styleId: number, sourceId?: number }>) {
                const { cost, styleId, sourceId } = action.payload
                state.changedSingles[toRowId(styleId, sourceId)] = { value: cost, changed: true }
            },
            prepare(id: { styleId: number, sourceId?: number }, cost?: number) {
                return { payload: { cost, styleId: id.styleId, sourceId: id.sourceId } }
            }
        },
        resetSingleCost: {
            reducer(state, action: PayloadAction<{ styleId: number, sourceId?: number }>) {
                const { styleId, sourceId } = action.payload
                state.changedSingles[toRowId(styleId, sourceId)] = {}
            },
            prepare(styleId: number, sourceId?: number) {
                return { payload: { styleId, sourceId } }
            }
        },
        changeBulkCost: {
            reducer(state, action: PayloadAction<{ cost?: number, styleId: number, sourceId?: number }>) {
                const { cost, styleId, sourceId } = action.payload
                state.changedBulks[toRowId(styleId, sourceId)] = { value: cost, changed: true }
            },
            prepare(id: { styleId: number, sourceId?: number }, cost?: number) {
                return { payload: { cost, styleId: id.styleId, sourceId: id.sourceId } }
            }
        },
        resetBulkCost: {
            reducer(state, action: PayloadAction<{ styleId: number, sourceId?: number }>) {
                const { styleId, sourceId } = action.payload
                state.changedBulks[toRowId(styleId, sourceId)] = {}
            },
            prepare(styleId: number, sourceId?: number) {
                return { payload: { styleId, sourceId } }
            }
        },
        setTabOpen: {
            reducer(state, action: PayloadAction<{ styleId: number, isOpen: boolean }>) {
                const { styleId, isOpen } = action.payload
                state.tabOpen[styleId] = isOpen
            },
            prepare(styleId: number, isOpen: boolean) {
                return { payload: { styleId, isOpen } }
            }
        },
        clearAllCosts(state) {
            state.changedBulks = {}
            state.changedSingles = {}
            //state.tabOpen = {}
            state.drawerOpen = false
        },
        setCostsVendorId(state, action: PayloadAction<number>) {
            state.vendorId = action.payload
        },
        setCarpetCostsMode(state, action: PayloadAction<CarpetCostsRowMode>) {
            state.carpetCostMode = action.payload

            const converFunc =
                (action.payload === "sqft") ?
                    (val: number) => val / 9 :
                    (val: number) => val * 9

            //Scale all bulk edits to new value
            Object.keys(state.changedBulks).forEach(
                key => {
                    var { value: cost, changed } = state.changedBulks[key]
                    if (changed && cost !== undefined) {
                        state.changedBulks[key].value = converFunc(+cost)
                    }
                }
            )

            //Scale all single edits to new value
            Object.keys(state.changedSingles).forEach(
                key => {
                    var { value: cost, changed } = state.changedSingles[key]
                    if (changed && cost !== undefined) {
                        state.changedSingles[key].value = converFunc(+cost)
                    }
                }
            )
        },
        setCostsDrawerOpen(state, action: PayloadAction<boolean>) {
            state.drawerOpen = action.payload
        },
        setCostsPageIsSoft(state, action: PayloadAction<boolean>) {
            state.isSoft = action.payload
        },
        setHardCostsProductTypeId(state, action: PayloadAction<number>) {
            state.hardOnlyProductTypeId = action.payload
        }
    }
})

export const {
    changeBulkCost, resetBulkCost,
    changeSingleCost, resetSingleCost,
    setCostsVendorId, setCarpetCostsMode,
    setCostsDrawerOpen, clearAllCosts,
    setTabOpen, setCostsPageIsSoft,
    setHardCostsProductTypeId
} = productCostsSlice.actions //Unpacks the actions created in the slice

export const selectIsBulkDifferent =
    (styleId: number, sourceId?: number) =>
        (state: RootState) =>
            state.productCosts.changedBulks[toRowId(styleId, sourceId)]?.changed
export const selectIsSingleDifferent =
    (styleId: number, sourceId?: number) =>
        (state: RootState) =>
            state.productCosts.changedSingles[toRowId(styleId, sourceId)]?.changed

export const selectBulkCost =
    (styleId: number, sourceId?: number) =>
        (state: RootState) =>
            state.productCosts.changedBulks[toRowId(styleId, sourceId)]?.value
export const selectSingleCost =
    (styleId: number, sourceId?: number) =>
        (state: RootState) =>
            state.productCosts.changedSingles[toRowId(styleId, sourceId)]?.value

export const selectCostVendorId = (state: RootState) => state.productCosts.vendorId
export const selectCostProductTypeId = (state: RootState) => state.productCosts.hardOnlyProductTypeId
export const selectCarpetCostMode = (state: RootState) => state.productCosts.carpetCostMode
export const selectCostDrawerOpen = (state: RootState) => state.productCosts.drawerOpen
export const selectCostPageIsSoft = (state: RootState) => state.productCosts.isSoft

export const selectTabOpen =
    (styleId: number) =>
        (state: RootState) =>
            state.productCosts.tabOpen?.[styleId] ?? false

export default productCostsSlice.reducer