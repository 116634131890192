import { IconButton } from "@material-ui/core";
import { FlatIconButtonProps } from "./flatButtonUtils";
import CloseIcon from "@material-ui/icons/Close";

export default function FlatCloseButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatCloseIcon color={color}/>
        </IconButton>
    )
}

export function FlatCloseIcon({color="black"} : {color?: string}) {
    return <CloseIcon htmlColor={color}/>
}