import { IconButton, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import {
    InstallationCalendarType,
    useCalculateInstallationCalendarMovementLazyQuery,
    useUpdateInstallationAppointmentMutation,
    useUpdateServiceOrderScheduledDateMutation,
} from "generated/graphql";
import { CalendarItemProps } from "Pages/Home/ContractorHome/ContractorAppointmentCalendar";
import React, { useCallback } from "react";
import { useContextMenu } from "react-contexify";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { store } from "Redux/store";
import {
    deselectAll,
    popPendingMovement,
    pushPendingMovement,
    selectBlocked,
    selectInstallation,
    selectIsBlockedSelected,
    selectIsInstallationSelected,
    selectIsServiceSelected,
    selectService,
} from "Redux/weeklyIstallationCalendarReducer";
import {
    BlockedDateItemData,
    GenericItemData,
    InstallationItemData,
    itemDataToInstallationCalendarBlockIdentifier,
    ServiceItemData,
} from "./ContractorWeeklyInstallationCalendar";
import {
    InstallationRightClickProps,
    WEEKLY_INSTALLATION_APPT_MENU,
    WEEKLY_INSTALLATION_SERVICE_MENU,
} from "./WeeklyInstallationCalendar";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export function ContractorDaySlot({
    props,
    day,
    contractorId,
    startPolling,
    stopPolling,
}: {
    props?: CalendarItemProps<GenericItemData>;
    day: string;
    contractorId: number;
    startPolling: () => void;
    stopPolling: () => void;
}) {
    const dispatch = useAppDispatch();

    const [updateAppointment] = useUpdateInstallationAppointmentMutation({
        onCompleted(d) {
            dispatch(popPendingMovement());
        },
    });
    const [updateService] = useUpdateServiceOrderScheduledDateMutation({
        onCompleted(d) {
            dispatch(popPendingMovement());
        },
    });

    function updateBlock(
        type: InstallationCalendarType,
        id: number,
        newDate: string,
        contractorId: number
    ) {
        if (type === "SERVICE") {
            stopPolling();
            updateService({
                variables: {
                    serviceOrderId: id,
                    scheduledDate: newDate,
                    contractorId: contractorId,
                },
            }).finally(startPolling);
        } else if (type === "INSTALLATION") {
            stopPolling();
            updateAppointment({
                variables: {
                    appointmentId: id,
                    newStartDate: newDate,
                    newContractorId: contractorId,
                },
            }).finally(startPolling);
        }
    }

    const [calculateMovement] = useCalculateInstallationCalendarMovementLazyQuery({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted(data) {
            if (data.calculateInstallationCalendarMovement.isAllowed !== true) {
                window.alert("Unable to move");
                return;
            }

            if (
                data.calculateInstallationCalendarMovement.isOutOfContractorServiceArea
                && !window.confirm("This installation is outside of the contractor's normal service area. Are you sure you wish to assign this installation to this contractor?")
            ) return;

            if (
                data.calculateInstallationCalendarMovement.needsOverride ||
                data.calculateInstallationCalendarMovement.isSwapAllowed
            ) {
                // Present dialog to certify request
                dispatch(pushPendingMovement(data.calculateInstallationCalendarMovement));
            } else {
                // Movement can proceed without override
                const { destinationContractorId, destinationDate, sourceType, sourceIdentifier } =
                    data.calculateInstallationCalendarMovement;
                updateBlock(
                    sourceType,
                    +sourceIdentifier,
                    destinationDate,
                    destinationContractorId
                );
            }
            dispatch(deselectAll());
        },
    });

    const onClickCell = useCallback(() => {
        const state = store.getState().weeklyInstallation;
        if (state.selectedType === undefined) {
            // No current selected, try selecting this card
            if (props?.data.type === "Blocked") {
                dispatch(
                    selectBlocked(
                        (props.data as BlockedDateItemData).date,
                        contractorId,
                        day
                    )
                );
            } else if (props?.data.type === "Installation") {
                dispatch(
                    selectInstallation(
                        (props.data as InstallationItemData).installationAppointmentId,
                        contractorId,
                        day
                    )
                );
            } else if (props?.data.type === "Service") {
                dispatch(
                    selectService(
                        (props.data as ServiceItemData).serviceId,
                        contractorId,
                        day
                    )
                );
            }
        } else {
            // Has a selection
            if (
                state.selectedType === props?.data.type &&
                isSameIdentifier(state.selectedValue!, props.data)
            ) {
                // Clicked on selected card
                dispatch(deselectAll());
            } else {
                // Move selected card to this position
                calculateMovement({
                    variables: {
                        block: {
                            type: state.selectedType!.toUpperCase() as InstallationCalendarType,
                            identifier: state.selectedValue!,
                            startingDate: state.selectedDate!,
                            contractorId: state.selectedContractorId!,
    
                        },
                        destinationContractorId: contractorId,
                        destinationDate: day,
                        destinationBlock: itemDataToInstallationCalendarBlockIdentifier(
                            contractorId,
                            day,
                            props?.data
                        ),
                    },
                });
            }
        }
    }, [day, contractorId, props, dispatch, calculateMovement]);

    if (props === undefined)
        return (
            <div
                className={clsx("weekly-base-slot", { "flat-gray-2-bkg": contractorId === 1 })}
                onClick={onClickCell}
            >
                <div className="weekly-base-slot-bkg" />
                <div className="card-indicator" />
            </div>
        );
    else if (props.data.type === "Installation")
        return (
            <InstallationAppointmentDaySlot
                contractorId={contractorId}
                date={day}
                onClickCell={onClickCell}
                {...(props as CalendarItemProps<InstallationItemData>)}
            />
        );
    else if (props.data.type === "Blocked")
        return (
            <BlockedDaySlot
                contractorId={contractorId}
                date={day}
                onClickCell={onClickCell}
                {...(props as CalendarItemProps<BlockedDateItemData>)}
            />
        );
    else
        return (
            <ServiceDaySlot
                contractorId={contractorId}
                date={day}
                onClickCell={onClickCell}
                {...(props as CalendarItemProps<ServiceItemData>)}
            />
        );
}

function isSameIdentifier(identifier: string, data: GenericItemData) {
    if (data.type === "Blocked") {
        return (data as BlockedDateItemData).date === identifier;
    } else if (data.type === "Installation") {
        return (data as InstallationItemData).installationAppointmentId.toString() === identifier;
    } else if (data.type === "Service") {
        return (data as ServiceItemData).serviceId.toString() === identifier;
    }
    return false;
}

interface MoveableCalendarItemProps<T> extends CalendarItemProps<T> {
    onClickCell: () => void;
    date: string;
    contractorId: number;
}

function InstallationAppointmentDaySlot({
    onClickCell,
    date,
    contractorId,
    ...props
}: MoveableCalendarItemProps<InstallationItemData>) {
    const isSelected = useAppSelector(
        selectIsInstallationSelected(props.data.installationAppointmentId)
    );
    const { show } = useContextMenu({ id: WEEKLY_INSTALLATION_APPT_MENU });

    const rightClickProps: InstallationRightClickProps = {
        installationAppointmentId: props.data.installationAppointmentId,
        contractId: props.data.contractId,
        isReleasedToContractor: props.data.releasedToContractor,
    };

    return (
        <BaseDaySlot
            {...props}
            className={clsx("flat-dark-bkg", { "flat-gray-2-bkg": contractorId === 1 })}
            isSelected={isSelected}
            onClick={onClickCell}
            onContext={(e) =>
                show(e, {
                    props: rightClickProps,
                })
            }
        />
    );
}

function BlockedDaySlot({
    onClickCell,
    date,
    contractorId,
    ...props
}: MoveableCalendarItemProps<BlockedDateItemData>) {
    const isSelected = useAppSelector(selectIsBlockedSelected(props.data.date));

    return (
        <BaseDaySlot
            {...props}
            className={clsx("flat-light-bkg", { "flat-gray-2-bkg": contractorId === 1 })}
            isSelected={isSelected}
            onClick={onClickCell}
        />
    );
}

function ServiceDaySlot({
    onClickCell,
    date,
    contractorId,
    ...props
}: MoveableCalendarItemProps<ServiceItemData>) {
    const isSelected = useAppSelector(selectIsServiceSelected(props.data.serviceId));
    const { show } = useContextMenu({ id: WEEKLY_INSTALLATION_SERVICE_MENU });

    return (
        <BaseDaySlot
            {...props}
            className={clsx("flat-dark-bkg", { "flat-gray-2-bkg": contractorId === 1 })}
            isSelected={isSelected}
            onClick={onClickCell}
            onContext={(e) => show(e, { props: { serviceId: props.data.serviceId } })}
        />
    );
}

interface BaseDaySlotProps extends CalendarItemProps<GenericItemData> {
    onClick?: () => void;
    onContext?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    isSelected?: boolean;
    className?: string;
}

function BaseDaySlot({
    hasPreviousDay,
    hasNextDay,
    data,
    onClick,
    onContext,
    isSelected,
    className,
}: BaseDaySlotProps) {
    const isFirstCard = !hasPreviousDay;
    const isLastCard = !hasNextDay;

    const isSingleCard = isFirstCard && isLastCard;

    const showContextIcon = isLastCard && onContext !== undefined;
    const showLastCardMiddle = isLastCard && data.lastCardMiddle !== undefined;

    return (
        <div>
            <div
                className={clsx(className, "weekly-base-slot", "flex-column cont-rounded-stack", {
                    "cont-unround-start": hasPreviousDay,
                    "cont-unround-end": hasNextDay,
                    "selected-base-slot": isSelected,
                })}
                onClick={onClick}
                onContextMenu={onContext}
            >
                <div className="weekly-base-slot-bkg" />
                <div
                    style={{
                        backgroundColor: "var(--theme-bkg-primary-color)",
                        height: "1.9em",
                        minHeight: "1.9em",
                        maxHeight: "1.9em",
                        paddingLeft: "8px",
                        display: "flex",
                        alignItems: "center",
                    }}
                    className="disable-selection"
                >
                    <Tooltip title={<div className="card-top-label">{data.topLabel}</div>}>
                        <div
                            className={clsx("card-text-label card-top-label", {
                                "label-for-single-card": isSingleCard,
                            })}
                        >
                            {isFirstCard ? data.topLabel ?? "\xa0" : undefined}
                        </div>
                    </Tooltip>
                    <div className="flex-grow" />
                    <div
                        hidden={!showContextIcon}
                        style={{ paddingRight: "2px" }}
                        onClick={() => console.log("click 2")}
                    >
                        <IconButton
                            size="small"
                            style={{ width: "20px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onContext?.(e);
                            }}
                        >
                            <MoreVertIcon style={{ color: "var(--theme-main-text-color)" }} />
                        </IconButton>
                    </div>
                </div>
                {data.colorStripeVariables.map((cls) => (
                    <div
                        key={cls}
                        style={{
                            backgroundColor: cls,
                            height: "6px",
                            minHeight: "6px",
                            maxHeight: "6px",
                        }}
                    />
                ))}
                <div
                    style={{
                        flex: 1,
                        backgroundColor: "var(--theme-bkg-secondary-color)",
                        paddingLeft: "8px",
                        paddingBottom: "8px",
                        paddingRight: "8px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                    }}
                    className="disable-selection"
                >
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "space-evenly",
                        }}
                        className={clsx({ "bottom-label-for-single-card": isSingleCard })}
                    >
                        <Tooltip title={data.middleLabel ?? ""}>
                            <div
                                style={{ lineHeight: 1, overflowY: "hidden" }}
                                className={clsx("flat-font-md card-text-label", {
                                    "ellipse-label-for-single-card": isSingleCard,
                                })}
                            >
                                {isFirstCard ? data.middleLabel ?? "\xa0" : undefined}
                            </div>
                        </Tooltip>
                        <div className="flex-row fill-width flat-font-sm card-text-label"  style={{ lineHeight: 1, overflowY: "hidden" }}>
                            <Tooltip title={data.bottomLabel ?? ""} hidden={!isFirstCard}>
                                <div
                                    className={clsx("flat-font-bold", {
                                        "ellipse-label-for-single-card": isSingleCard,
                                    })}
                                   
                                >
                                    {isFirstCard ? data.bottomLabel ?? "\xa0" : undefined}
                                </div>
                            </Tooltip>
                            <div className="flex-grow" />
                            {
                                showLastCardMiddle && data.lastCardMiddle
                            }
                        </div>
                    </div>
                </div>
                <div className="card-indicator" />
            </div>
        </div>
    );
}
