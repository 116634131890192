import { DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import { FlatLabeledCheckbox } from "FlatComponents/Inputs/FlatCheckbox";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { BackgroundCheck, BackgroundCheckInput, useDeleteBackgroundCheckMutation, useUpsertBackgroundCheckMutation } from "generated/graphql";
import { prepareBackgroundCheck } from "Globals/dataPreparationUtils";
import { dateTimeStrToIso, dateTimeStrToMdy, dayValueToIso, isoToDayValue, SingleDateCalendarDialog } from "Globals/DateAndTimeHelpers";
import { isBackgroundCheckValid, isNotNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";

interface BackgroundCheckDialogProps {
    bgCheck: BackgroundCheck;
    onClose: () => void;
    toRefetchOnUpdate: string[];
}

export default function BackgroundCheckDialog({bgCheck: ogBgCheck, onClose, toRefetchOnUpdate}: BackgroundCheckDialogProps) {
    const newMode = ogBgCheck.id < 0;
    const [bgCheck, setBgCheck] = useState({
        ...ogBgCheck,
        datePerformed: dateTimeStrToIso(ogBgCheck.datePerformed),
        expirationDate: dateTimeStrToIso(ogBgCheck.expirationDate)
    });
    const [datePerformedOpen, setDatePerformedOpen] = useState(false);
    const [expirationDateOpen, setExpirationDateOpen] = useState(false);
    const headerText = newMode ? "Add Background Check" : "Update Background Check";

    const [upsertBackgroundCheck, {loading: upserting}] = useUpsertBackgroundCheckMutation({
        onError: () => alert(`Failed to ${newMode ? "add" : "update"} background check`),
        refetchQueries: toRefetchOnUpdate
    });

    function validateAndPrepareData(): BackgroundCheckInput | undefined {
        const preparedBgCheck = prepareBackgroundCheck(bgCheck);
        if (isBackgroundCheckValid(bgCheck, true)) {
            return preparedBgCheck;
        } else {
            return undefined;
        }
    }

    function onSubmit() {
        const preparedBgCheck = validateAndPrepareData();
        if (isNotNullOrUndefined(preparedBgCheck)) {
            upsertBackgroundCheck({
                variables: {backgroundCheck: preparedBgCheck!},
                onCompleted: onClose
            });
        }
    }

    const submitButton = (
        <FlatButton
            key={1}
            onClick={onSubmit}
            color="secondary"
            variant="contained"
            disabled={upserting}
        >Submit</FlatButton>
    );

    const [deleteBgCheck, {loading: deleting}] = useDeleteBackgroundCheckMutation({
        variables: {id: bgCheck.id},
        onCompleted: onClose,
        onError: () => alert("Failed to delete background check"),
        refetchQueries: toRefetchOnUpdate
    });

    function onDelete() {
        if (bgCheck.id > 0 && window.confirm("Are you sure you wish to delete this background check?")) {
            deleteBgCheck();
        }
    }

    const deleteButton = !newMode 
        ? <FlatDeleteButton
            disabled={deleting || upserting}
            onClick={onDelete}
        />
        : <></>

    return (<>
        <FlatDialog
            sectionProps={{ header: headerText, endAdornment: deleteButton }}
            dialogProps={{
                open: true,
                onClose: onClose,
                maxWidth: "md"
            }}
            actionButtons={[submitButton]}
        >
            <div className="grid-50-50">
                <FlatLabeledInput
                    label="Date Performed"
                    value={dateTimeStrToMdy(bgCheck.datePerformed)}
                    onClick={() => setDatePerformedOpen(true)}
                    readOnly
                />
                <FlatLabeledInput
                    label="Expiration Date"
                    value={dateTimeStrToMdy(bgCheck.expirationDate)}
                    onClick={() => setExpirationDateOpen(true)}
                    readOnly
                />
                <FlatLabeledCheckbox
                    label={"3rd Party"}
                    justification="left"
                    checked={!bgCheck.isInternal}
                    onClick={() => setBgCheck({...bgCheck, isInternal: !bgCheck.isInternal})}                
                />
                <FlatLabeledInput
                    label={"Number"}
                    value={bgCheck.number ?? ""}
                    onChange={(e) => setBgCheck({...bgCheck, number: e.target.value})}                
                />
            </div>
        </FlatDialog>

        <SingleDateCalendarDialog
            selectedDate={isoToDayValue(bgCheck.datePerformed)}
            setSelectedDate={(day: DayValue) => setBgCheck({...bgCheck, datePerformed: dayValueToIso(day)})}
            open={datePerformedOpen}
            setOpen={setDatePerformedOpen}
            blockPastDays={false}
        />
        <SingleDateCalendarDialog
            selectedDate={isoToDayValue(bgCheck.expirationDate)}
            setSelectedDate={(day: DayValue) => setBgCheck({...bgCheck, expirationDate: dayValueToIso(day)})}
            open={expirationDateOpen}
            setOpen={setExpirationDateOpen}
            blockPastDays={false}
        />
    </>)
}