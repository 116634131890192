import { Select, MenuItem } from "@material-ui/core"
import { useAppDispatch, useAppSelector } from "Redux/hooks"
import { selectCostPageIsSoft, clearAllCosts, setCostsPageIsSoft } from "Redux/productCostsReducer"
import { promptUserIfChanges } from "../ProductCostsPage"

export function CostInventoryModeSelect() {
    const dispatch = useAppDispatch()
    const isSoft = useAppSelector(selectCostPageIsSoft)

    function ChangeMode(newIsSoft: boolean) {
        if (promptUserIfChanges()) {
            dispatch(clearAllCosts())
            dispatch(setCostsPageIsSoft(newIsSoft))
        }
    }
    return (
        <div className="flex-column w-10r margin-side-xxsm">
            <label htmlFor="inventory-mode-select">Inventory:</label>
            <Select name='inventory-mode-select' value={+isSoft} onChange={e => ChangeMode(Boolean(e.target.value))}>
                <MenuItem value={+true}>Soft Surface</MenuItem>
                <MenuItem value={+false}>Hard Surface</MenuItem>
            </Select>
        </div>
    )
}