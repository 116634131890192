import { Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { ChatMessage } from "generated/graphql";

// reader is the person using the chat
// sender is the person that sent the message (could be either the reader or someone else)
export interface ChatMessageDisplayProps {
    id: number;
    text: string;
    readerPartyId: number;
    readerWorkerId: number;
    senderPartyId: number;
    senderWorkerId: number;
    senderName: MessageSenderLabelProps;
}

export function prepareMessageForDisplay(message: ChatMessage, readerPartyId: number, readerWorkerId: number): ChatMessageDisplayProps {
    return {
        id: message.id,
        text: message.text,
        readerPartyId: readerPartyId,
        readerWorkerId: readerWorkerId,
        senderPartyId: message.sendingPartyId,
        senderWorkerId: message.sendingWorkerId,
        senderName: {
            firstName: message.senderFirstName,
            lastName: message.senderLastName
        }
    };
}

export default function ChatMessageDisplay(message: ChatMessageDisplayProps) {
    const fromThisWorker = message.senderWorkerId === message.readerWorkerId;
    const fromThisParty = message.readerPartyId === message.senderPartyId;

    return (
        <div className={clsx("message-row", (fromThisWorker || fromThisParty) ? "message-row-same-party" : "message-row-different-party") }>
            <span className="chat-bubble">
                <pre>{message.text}</pre>
            </span>

            <MessageSenderLabel {...message.senderName} />
        </div>
    )
}

interface MessageSenderLabelProps {
    firstName: string;
    lastName: string;
}

function MessageSenderLabel({firstName, lastName}: MessageSenderLabelProps) {
    if (firstName.length < 1 || lastName.length < 1) {
        throw new Error("First and last name must be non-empty strings");
    }

    const initials = `${firstName[0]}${lastName[0]}`;
    
    return (
        <Tooltip
            title={`${firstName} ${lastName}`}
            // wait 1 second on hover to display the tooltip (can be annoying if it's too quick)
            enterDelay={1000}
            // make user hold for a tenth of a second before showing tooltip
            enterTouchDelay={100}
        >
            <span className="chat-message-sender-label not-selectable">
                {initials}
            </span>
        </Tooltip>
    )
}