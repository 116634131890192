import { Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
    AppliedDiscount,
    BasePromotion,
    DiscountsOnJob,
    GetJobConfigurationDocument,
    namedOperations,
    useUpdateAppliedPromotionMutation,
    useUpdateJobDiscountMutation,
} from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { OVERRIDE_DISCOUNT_ID, WE_PAY_SALES_TAX_DISCOUNT_ID } from "Globals/globalConstants";
import { padArray } from "Globals/Helpers";
import React from "react";
import { formatDiscount } from "../OldSellSheet/OldSellSheet";
import { MoneyTextFormat } from "./QuoteCard";

interface DiscountRowsProps {
    jobConfigurationId: number;
    discountsOnJob: DiscountsOnJob | null;
    appliedPromotion: BasePromotion | null;
    promoSavingsAmount: number | null;
    renderRowCount: number;
    pendingPriceRequestResponse: boolean;
    hasSalesTax: boolean;
}

export default function DiscountRows({
    jobConfigurationId,
    discountsOnJob,
    appliedPromotion,
    promoSavingsAmount,
    renderRowCount,
    pendingPriceRequestResponse,
    hasSalesTax,
}: DiscountRowsProps) {
    const discounts = discountsOnJob?.discounts ?? [];
    const isOverrideApplied = discountsOnJob?.isOverrideApplied ?? false;

    const [updateDiscount] = useUpdateJobDiscountMutation({
        refetchQueries: [
            {
                query: GetJobConfigurationDocument,
                variables: { jobConfigurationId },
                fetchPolicy: "network-only",
            },
            namedOperations.Query.CalculatePriceForLocalConfiguration,
        ],
        awaitRefetchQueries: true,
    });

    function removeDiscount(discountId: number) {
        updateDiscount({
            variables: {
                jobConfigurationId: jobConfigurationId ?? 0,
                discountId,
                isRemoval: true, // Is Remove flag
            },
        });
    }

    const [removePromotion] = useUpdateAppliedPromotionMutation({
        onError: () => alert("Could not remove promotion"),
        refetchQueries: [
            {
                query: GetJobConfigurationDocument,
                variables: { jobConfigurationId },
                fetchPolicy: "network-only",
            },
            namedOperations.Query.CalculatePriceForLocalConfiguration,
        ],
        awaitRefetchQueries: true,
        variables: { jobConfigurationId: jobConfigurationId },
    });

    function showDiscount(discount: AppliedDiscount): boolean {
        if (!hasSalesTax && discount.discountId === WE_PAY_SALES_TAX_DISCOUNT_ID) return false;
        else return true;
    }

    const filteredDiscounts = discounts.filter(showDiscount);

    // Supposed to have sales tax discount rendered but doesnt
    if (
        hasSalesTax &&
        !filteredDiscounts.some((dis) => dis.discountId === WE_PAY_SALES_TAX_DISCOUNT_ID)
    ) {
        const wePayTaxDiscount: AppliedDiscount = {
            id: -1,
            jobConfigurationId: -1,
            discountId: WE_PAY_SALES_TAX_DISCOUNT_ID,
            name: "We Pay Sales Tax",
            isScalar: true,
            amount: 0.94,
            isValid: true,
        };

        filteredDiscounts.push(wePayTaxDiscount);
    }

    const displayDiscounts = generateDisplayDiscounts(filteredDiscounts, renderRowCount);

    return (
        <>
            {isNotNullOrUndefined(appliedPromotion) && (
                <>
                    <div
                        className="flex-row align-items-center"
                        style={{ justifyContent: "flex-start" }}
                    >
                        <Button
                            style={{ minWidth: "0px", width: "2em", minHeight: "0", height: "2em" }}
                            className={clsx({
                                "visibility-hidden":
                                    isOverrideApplied || pendingPriceRequestResponse,
                            })}
                            onClick={() => removePromotion()}
                            disabled={isOverrideApplied}
                        >
                            X
                        </Button>
                        <Typography
                            style={{
                                fontWeight: "bold",
                                fontStyle: "italic",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap"
                            }}
                        >
                            {appliedPromotion!.name}
                        </Typography>
                    </div>
                    <div style={{whiteSpace:"nowrap"}}>-<MoneyTextFormat value={promoSavingsAmount} /></div>
                </>
            )}

            {displayDiscounts.map((dis, index) => (
                <React.Fragment key={`dis-name-${index}`}>
                    <div
                        className="flex-row"
                        style={{ alignItems: "center" }}
                    >
                        {dis !== undefined && (
                            <>
                                <Button
                                    style={{
                                        minWidth: "0px",
                                        width: "2em",
                                        minHeight: "0",
                                        height: "2em",
                                    }}
                                    className={clsx({
                                        "color-gray-important": !dis.isValid,
                                        "visibility-hidden":
                                            ((isOverrideApplied || pendingPriceRequestResponse) &&
                                                dis.discountId !== OVERRIDE_DISCOUNT_ID) ||
                                            dis.discountId === WE_PAY_SALES_TAX_DISCOUNT_ID,
                                    })}
                                    onClick={() => removeDiscount(dis.discountId)}
                                    disabled={
                                        isOverrideApplied && dis.discountId !== OVERRIDE_DISCOUNT_ID
                                    }
                                >
                                    X
                                </Button>

                                <div
                                    className={clsx({ "color-gray-important": !dis.isValid })}
                                    style={{
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden"
                                    }}
                                >
                                    {dis.name}
                                </div>
                            </>
                        )}
                    </div>
                    <div
                        className="flex-row"
                        style={{ justifyContent: "center" }}
                    >
                        {dis !== undefined && (
                            <div style={{ whiteSpace: "nowrap" }}>{formatDiscount(dis, true)}</div>
                        )}
                    </div>
                </React.Fragment>
            ))}
        </>
    );
}

function generateDisplayDiscounts(
    discounts: AppliedDiscount[],
    targetCount: number
): (AppliedDiscount | undefined)[] {
    return padArray(discounts, targetCount, undefined);
}
