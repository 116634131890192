import clsx from 'clsx';
import Select, { StylesConfig } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { FlatLabeledInputBase } from './flatInputUtils';

// https://react-select.com/styles#the-styles-prop
const flatSelectStyle: StylesConfig<FlatSelectOption> = {
    control: (_, __) => ({
        display: "flex",
        borderBottom: "2px solid darkgray",
        fontFamily: "var(--theme-main-text-font-family)",
        fontSize: "12pt",
        ':hover': {
            borderBottom: "2px solid black",
            boxShadow: "0 -1px inset black"
        },
        // TODO: can't get placeholder styling to work
        // '::placeholder': {
        // placeholder: {
        //     textTransform: "uppercase !important",
        //     color: "var(--flat-gray-4)",
        //     fontWeight: 500,
        //     fontSize: "13pt"
        // }
    }),
    option: (base, state) => ({
        ...base,
        fontFamily: "var(--theme-main-text-font-family)",
        backgroundColor: state.isSelected ? "var(--flat-gray-4)" : "",
        ':hover': {
            backgroundColor: "var(--flat-gray-2)"
        }
    }),
    dropdownIndicator: (base, _) => ({
        ...base,
        ':hover': {
            color: "black"
        }
    })
}

export interface FlatSelectOption {
    value: string | number;
    label: string | number;
}

type FlatSelectProps = StateManagerProps<FlatSelectOption>;

export default function FlatSelect(props: FlatSelectProps) {
    // prevent spread from overwriting the default values if these props aren't passed
    const {menuPlacement, styles, ...cleanedProps} = props;
    
    return (
        <Select
            {...cleanedProps}
            menuPlacement={menuPlacement ?? 'auto'} // 'auto' prevents menu from opening offscreen
            styles={styles ?? flatSelectStyle}
        />
    )
}

export type FlatLabeledSelectProps = FlatLabeledInputBase<FlatSelectProps>;

export function FlatLabeledSelect({
    label,
    bold=true,
    labelLocation="left",
    labelSize="standard",
    labelStyle,
    containerProps,
    ...selectProps
}: FlatLabeledSelectProps) {
    const containerClassName = clsx("align-items-center", "flex-gap-xsm", containerProps?.className,
        { "flex-row": labelLocation === "left", "flex-row-reverse": labelLocation === "right" }
    );
    const labelClassName = clsx("margin-none", containerProps?.className, {
        "flat-font-xsm": labelSize === "xsm",
        "flat-font-sm": labelSize === "sm",
        "flat-font": labelSize === "standard",
        "flat-font-md": labelSize === "md",
        "flat-font-lg": labelSize === "lg",
        "bold-text": bold
    });

    // remove className from the spread props because it will overwrite the directly passed className prop
    const {className, ...cleanedContainerProps} = {...containerProps};

    return (
        <span className={containerClassName} {...cleanedContainerProps}>
            <p
                className={labelClassName}
                style={{...labelStyle}}
            >{label}</p>
            <FlatSelect {...selectProps}/>
        </span>
    )
}
