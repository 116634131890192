import { Button } from "@material-ui/core";
import { Area } from "generated/graphql";
import { useState } from "react";
import { selectAreaIndex, selectArea, updateArea, selectOriginalJobConfig } from "Redux/genericSalesReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { updateInstallationService, updateShoeInRoomsForProductTypeChange } from "./businessLogicFunctions";
import { UpdateQuotePriceProps } from "./ChangeOrderDialog";
import { ProductStyleColorSelect } from "./SimpleEditors/ProductStyleColorSelect";


export function COJobProductEditor({requotePrice}: UpdateQuotePriceProps) {

    const [edit, setEdit] = useState<boolean>(false);
    const areaIndex = useAppSelector(selectAreaIndex);
    const area = useAppSelector(selectArea(areaIndex));
    const originalJobConfiguration = useAppSelector(selectOriginalJobConfig);

    const dispatch = useAppDispatch();

    const data = { typeId: area.productTypeId ?? undefined, styleId: area.styleId ?? undefined, colorId: area.colorId ?? undefined };
    const substrateIdsInArea = [...new Set(area.rooms.map(room => room.substrateId))].sort();


    function changeProductType(newId: number, name: string) {
        //TODO: notify about stair problems
        //TODO: update sqft of job when changing b/w SS <=> HS 
        const originalAreaIfExists = area.id > 0 ? originalJobConfiguration?.areas.filter(a => a.id === area.id)[0] : undefined;
        const originalRooms = originalAreaIfExists?.rooms ?? [];

        // Adds or removes shoe from area
        const updatedRooms = updateShoeInRoomsForProductTypeChange(data.typeId, newId, area.rooms, originalRooms);

        var updatedArea: Partial<Area> = {
            productTypeId: newId,
            productTypeName: name,
            styleId: null,
            styleName: "Not Selected",
            colorId: null,
            colorName: "Not Selected",
            rooms: updatedRooms
        };

        dispatch(updateArea(areaIndex, updatedArea));

    }

    function changeProductStyle(newId: number, name: string, installServiceId: number, materialId?: number) {
        const updatedRooms = updateInstallationService(area.rooms, installServiceId, materialId);
        dispatch(updateArea(areaIndex, {
            styleId: newId,
            styleName: name,
            colorId: null,
            rooms: updatedRooms,
            colorName: "Not Selected"
        }));

        requotePrice();
    }

    function changeProductColor(newId: number, name: string) {
        dispatch(updateArea(areaIndex, { colorId: newId, colorName: name }));

        requotePrice();
    }

    return (
        <>
            <div className="flex-row fill-width padding-bottom-xsm" style={{ alignItems: "center" }}>
                <Button variant="contained" color="secondary" onClick={() => setEdit(!edit)}>
                    {edit ? "Close Editor" : "Edit Product"}
                </Button>
            </div>
            {edit &&
                <ProductStyleColorSelect data={data} substrateIdsInArea={substrateIdsInArea} onChangeProductType={changeProductType} onChangeProductStyle={changeProductStyle} onChangeProductColor={changeProductColor} />}
        </>
    );
}
