import { useSetCallCenterCallbackMutation } from "generated/graphql";
import { CallbackTimePicker } from "../CallbackTimePicker";
import { NotInterestedMenuProps } from "./NotInterestedMenu";

export default function SetCallbackMenu({ jobId, open, onClose }: NotInterestedMenuProps) {
    const [updateCallbackTime] = useSetCallCenterCallbackMutation({
        onCompleted(data) {
            onClose(true);
        },
        onError(err) {},
    });

    return (
        <CallbackTimePicker
            open={open}
            onClose={(v) => {
                if (v !== null) {
                    const baseDate = new Date(
                        v.date.year,
                        v.date.month - 1,
                        v.date.day,
                        v.time?.hour ?? 0,
                        v.time?.minute ?? 0
                    );

                    updateCallbackTime({
                        variables: {
                            jobId: jobId!,
                            callBackTime: baseDate.toISOString(),
                        },
                    });
                }
                onClose(false);
            }}
        />
    );
}
