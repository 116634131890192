import MoneyInput from "Components/MoneyInput";
import FlatButton from "FlatComponents/Button/FlatButton";
import { FlatSubSection } from "FlatComponents/Layout/FlatSection";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useAppSelector } from "Redux/hooks";
import { selectJobConfigurationId, selectJobContractId } from "Redux/jobTrackerReducer";
import ChangeOrderDialog from "../NewChangeOrder/ChangeOrderDialog";

interface EditingPaneProps {
    originalPrice: number;
    originalOverriddenPrice?: number;
}

export default function EditingPane({ originalPrice, originalOverriddenPrice }: EditingPaneProps) {

    const jobConfigurationId = useAppSelector(selectJobConfigurationId)
    const jobContractId = useAppSelector(selectJobContractId)

    /* REGION: price editing */
    const [editingOverride, setEditingOverride] = useState(false);
    const [overriddenPrice, setOverriddenPrice] = useState<number | undefined>(originalOverriddenPrice);
    const [priceIsOverridden, setPriceIsOverridden] = useState(isNotNullOrUndefined(overriddenPrice));

    function onRemoveOverride() {
        setPriceIsOverridden(false);
        setOverriddenPrice(undefined);
        // TODO: make mutations
    }

    function onSubmitPriceOverride(newPrice: number) {
        if (newPrice === originalPrice) {
            onRemoveOverride();
        } else {
            setPriceIsOverridden(true);
        }

        setEditingOverride(false);
        // TODO: make mutations
    }

    /* REGION: configuration editing */
    const [changeDialogOpen, setChangeDialogOpen] = useState(false);

    function onOpenChangeDialog() {
        setChangeDialogOpen(true);
    }

    return (
        <FlatSubSection header="Make Changes">
            <div className="flex-row fill-width flex-space-between align-items-center margin-top-xsm flex-gap-md">
                <div className="grid-30-20-50 flex-row-gap-xsm fill-width">
                    <p className="margin-none font-weight-500">Calculated Price</p>
                    <NumberFormat
                        value={originalPrice}
                        thousandSeparator={true} prefix={'$'}
                        decimalScale={2} fixedDecimalScale
                        displayType="text"
                    />
                    <FlatButton
                        onClick={() => setEditingOverride(true)}
                        variant="contained"
                        color="secondary"
                        className="fit-content"
                        style={{visibility: editingOverride ? "hidden" : "visible", fontSize: "8pt"}} 
                    >
                        Edit Override
                    </FlatButton>

                    {editingOverride && (<>
                        <div style={{gridColumnStart: 1, gridColumnEnd: 4}}>
                            <MoneyInput
                                initialValue={priceIsOverridden ? overriddenPrice : undefined} setValue={setOverriddenPrice}
                                label="Override Price"
                                onSubmit={onSubmitPriceOverride}
                                onCancelEditing={() => setEditingOverride(false)}
                            />
                        </div>
                    </>)}

                    {(priceIsOverridden && !editingOverride) && (<>
                        <p className="margin-none font-weight-500">Overridden Price</p>
                        
                        {editingOverride ? (
                            <div style={{gridColumnStart: 2, gridColumnEnd: 4}}>
                                <MoneyInput
                                    initialValue={priceIsOverridden ? overriddenPrice : undefined}
                                    setValue={setOverriddenPrice}
                                    variant="standard"
                                    label="Override Price"
                                    onSubmit={onSubmitPriceOverride}
                                    onCancelEditing={() => setEditingOverride(false)}
                                />
                            </div>
                        ) : (<>
                            <NumberFormat
                                value={overriddenPrice}
                                thousandSeparator={true} prefix={'$'}
                                decimalScale={2} fixedDecimalScale
                                displayType="text"
                            />

                            <FlatButton
                                className="fit-content"
                                style={{fontSize: "8pt"}}
                                variant="contained"
                                color="secondary"
                                onClick={onRemoveOverride}
                            >Remove Override</FlatButton>
                        </>)}
                    </>)}

                    <div style={{gridColumnStart: 1, gridColumnEnd: 4}}>
                        <FlatButton
                            style={{fontSize: "8pt"}}
                            variant="contained"
                            color="secondary"
                            onClick={onOpenChangeDialog}
                        >Create Revision</FlatButton>
                    </div>
                </div>
            </div>

            <ChangeOrderDialog
                open={changeDialogOpen}
                setOpen={setChangeDialogOpen}
                jobConfigurationId={jobConfigurationId}
                jobContractId={jobContractId} />
        </FlatSubSection>
    )
}