import ItemAmountPicker, { ItemAmount } from 'Components/ItemAmountPicker/ItemAmountPicker';
import { ProductTypeOld } from 'generated/graphql';
import { useState } from 'react';

export default function DummyAmountPicker() {
    const [amount, setAmount] = useState<ItemAmount>({type: "plt", value: {primary: "", secondary: ""}})

    return (
        <div>
            <h1>Standalone picker + {amount?.value?.primary} {(amount?.value?.secondary) ? + amount.value.secondary : ""}</h1>
            <ItemAmountPicker type={ProductTypeOld.Lvp} amount={amount} onChange={e => setAmount(e)} width={200} height={35} boxesPerPallet={100} />
        </div>

    )
}