import { Button, Checkbox, FormControl, FormLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { Market, useUpdateMarketAndServicedForZipAndCityMutation, namedOperations } from "generated/graphql";
import { useState } from "react";

interface RowUpdatesSectionProps {
    markets: Market[];
    selectedRows: ReadonlySet<number>;
    setSelectedRows: (ids: ReadonlySet<number>) => void;
}

export default function RowUpdatesSection({markets, selectedRows, setSelectedRows}: RowUpdatesSectionProps) {
    // used when updating the market that the selected rows belong to
    const [marketIdForUpdate, setMarketIdForUpdate] = useState(markets[0]?.id || -1);
    const [servicedForUpdate, setServicedForUpdate] = useState(true);

    const [updateSucceeded, setUpdateSucceeded] = useState(false);    
    // updates the market/serviced status for ZipAndCity entries
    const [updateMarket] = useUpdateMarketAndServicedForZipAndCityMutation({
        onCompleted: (res) => {
            if (res.updateMarketAndServicedForZipAndCity) {
                setSelectedRows(new Set<number>());
                setUpdateSucceeded(true);
                setTimeout(() => setUpdateSucceeded(false), 3000);
            } else {
                alert("Could not update selected areas")
            }
        },
        onError: () => alert("Could not update selected areas"),
        refetchQueries: [namedOperations.Query.GetAllZipsAndCities]
    });

    function onSubmitUpdates() {
        if (window.confirm(`The options selected here will be applied to ${selectedRows.size} selected ${(selectedRows.size > 1) ? "rows" : "row"}. Are you sure you want to do this?`)) {
            updateMarket({
                variables: {
                    ids: Array.from(selectedRows).map(r => +r.valueOf()),
                    marketId: (marketIdForUpdate === -1) ? null : marketIdForUpdate,
                    serviced: servicedForUpdate
                }
            });
        }
    }

    return (
        <div className="flex-row flex-gap-lg margin-top-sm">
            <div className="flex-column">
                <h5>Update Selected Service Areas</h5>
                <div className="flex-row flex-gap-sm">
                    <FormControl>
                        <FormLabel component="legend">Market</FormLabel>
                        <Select
                            style={{width: 150}}
                            disabled={selectedRows.size === 0}
                            value={marketIdForUpdate}
                            onChange={(e => setMarketIdForUpdate(e.target.value as number))}
                        >
                            <MenuItem value={-1} key="mkt-unassigned">Unassigned</MenuItem>
                            {markets.map(m => (
                                <MenuItem value={m.id} key={`mkt-${m.id}`}>{m.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel component="legend">Serviced</FormLabel>
                        <Checkbox
                            checked={servicedForUpdate}
                            onChange={e => setServicedForUpdate(e.target.checked)}
                            disabled={selectedRows.size === 0}
                        />
                    </FormControl>
                </div>

                <div className="flex-row align-items-center">
                    <Button
                        style={{height: "fit-content", width: "fit-content"}}
                        variant="contained"
                        className="margin-vertical-sm"
                        disabled={selectedRows.size === 0}
                        onClick={onSubmitUpdates}
                    >Submit Updates</Button>
                    
                    {updateSucceeded && (
                        <Typography className="success-text margin-left-xsm">Update successful</Typography>
                    )}
                </div>
            </div>
        </div>
    )
}