import { ChatForJob, useChatMemberNewMessageStatusUpdatedSubscription, useClearNewMessageStatusMutation, useGetChatForJobFromChatLazyQuery, useGetDefaultDisplayedJobChatsQuery, useMessageSentToChatPartySubscription, useRemoveChatFromDrawerSubscription } from "generated/graphql";
import { dateToYmd } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { GENERAL_COMMAND_CENTER_CHAT_PARTY_ID } from "Globals/globalConstants";
import { closeAllChats, closeChat, openChat, openChatById, removeChat, selectClosedChats, selectDrawerOpen, selectOpenChats, selectSendingWorker, setChatNewMessageStatus, setClosedChats, setDrawerOpen } from "Redux/chatDrawerReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { NewMessageIcon } from "./Chat";
import ChatButton from "./ChatButton";
import ChatWindow, { ChatWindowProps } from "./ChatWindow";

interface ChatDrawerProps {
    actionComponent: JSX.Element;
    date: Date;
    marketId: number;
}

// NOTE: the actionComponent will be distinct for each chat that renders; using the actionComponent in one chat has no effect on the copies in the other chats
export default function ChatDrawer({actionComponent, date, marketId}: ChatDrawerProps) {
    const dispatch = useAppDispatch();
    const sendingWorker = useAppSelector(selectSendingWorker);
    const authCcWorkerId = sendingWorker?.id ?? -1;
    const openChats = useAppSelector(selectOpenChats);
    const closedChats = useAppSelector(selectClosedChats);
    const drawerOpen = useAppSelector(selectDrawerOpen);

    useGetDefaultDisplayedJobChatsQuery({
        variables: {
            date: dateToYmd(date),
            marketId: marketId!,
            commandCenterWorkerId: authCcWorkerId!,
        },
        skip: isNullOrUndefined(marketId) || isNullOrUndefined(authCcWorkerId) || marketId < 1,
        onCompleted: (data) => {
            dispatch(setClosedChats(data.defaultDisplayedJobChats));
        },
    });

    function onCloseDrawer() {
        dispatch(setDrawerOpen(false));
        dispatch(closeAllChats());
    }

    function drawerContainsChat(chatId: number) {
        return isNotNullOrUndefined([...closedChats, ...openChats].find(chat => chat.chatId === chatId))
    }

    // used when a new chat is added to the drawer via useMessageSentToChatParty
    const [ getChatForJobFromChat ] = useGetChatForJobFromChatLazyQuery({
        onError: () => alert("Something went wrong - please refresh the page"),
        onCompleted: (data) => { dispatch(openChat(data.chatForJobFromChat)) }
    });

    // listen to messages to the individual command center worker chat party
    useMessageSentToChatPartySubscription({
        variables: {
            recipientChatPartyId: sendingWorker!.individualChatParty!.id,
        },
        skip: sendingWorker!.individualChatParty!.id < 1,
        onSubscriptionData: (data) => {
            const chatId = data.subscriptionData.data?.messageSentToChatParty ?? -1;
            if (chatId > 0) {
                if (drawerContainsChat(chatId)) {
                    dispatch(openChatById(chatId))
                } else {
                    getChatForJobFromChat({variables: {chatId: chatId}});
                }
            } else {
                alert("Something has gone wrong");
            }
        }
    });

    // listen to messages to the general command center chat party
    useMessageSentToChatPartySubscription({
        variables: {
            recipientChatPartyId: GENERAL_COMMAND_CENTER_CHAT_PARTY_ID,
        },
        onSubscriptionData: (data) => {
            const chatId = data.subscriptionData.data?.messageSentToChatParty ?? -1;
            if (chatId > 0) {
                if (drawerContainsChat(chatId)) {
                    dispatch(setChatNewMessageStatus({chatId: chatId, hasNewMessage: true}))
                } else {
                    getChatForJobFromChat({variables: {chatId: chatId}});
                }
            } else {
                alert("Something has gone wrong");
            }
        }
    });

    useRemoveChatFromDrawerSubscription({
        // if the job is claimed by a different command center worker, remove this from the chat
        onSubscriptionData: (data) => {
            const removalData = data.subscriptionData.data?.removeChatFromDrawer;
            if (removalData) {
                const workerIdToRetainChat = removalData.retainForCommandCenterWorkerId ?? -1;
                const chatId = removalData.id;
                // CC worker that claimed the job's price requests should not be removed
                if (workerIdToRetainChat !== authCcWorkerId) {
                    dispatch(removeChat(chatId));
                }
            }
        },
        skip: authCcWorkerId < 1
    });

    return (
        <>
            {sendingWorker && (
                <div id="chat-drawer">
                    {/* FIXME: When a chat is minimized (and therefore removed from this div), all chats opened before it
                        (which by default appear to the left in the div) shift right once removed shift right, even if they
                        have been dragged. Ideally, they should only shift left if they haven't been moved yet.
                        https://www.npmjs.com/package/react-draggable#draggable-api
                    */}
                    <div className="flex-row align-self-end flex-gap-md justify-content-flex-end margin-top-sm margin-right-sm">
                        {openChats.map(oc => (
                            <DrawerWrappedChatWindow
                                key={`open-chat-${oc.chatId}`}
                                chatId={oc.chatId}
                                closeWindow={() => dispatch(closeChat(oc))}
                                sendingWorker={sendingWorker}
                                sendingMember={oc.commandCenterMember}
                                actionComponent={actionComponent}
                                title={oc.jobMember.partyName}
                                subtitle={oc.customerName}
                                disabled={false}
                                hasNewMessage={oc.commandCenterMember.hasNewMessage}
                                reportHasNewMessage={(hasNewMessage: boolean) => 
                                    dispatch(setChatNewMessageStatus({
                                        chatId: oc.chatId,
                                        hasNewMessage: hasNewMessage
                                    }))
                                }
                                inDrawer
                            />
                        ))}
                    </div>
                    
                    <div id="chat-drawer-closed-chats" >
                        <div className="padding-sm flex-gap-sm" style={{visibility: drawerOpen ? "visible" : "hidden"}}>
                            {closedChats.map(cc => (
                                <ClosedChatButton
                                    key={`chat-btn-${cc.chatId}`}
                                    title={cc.jobMember.partyName}
                                    subtitle={cc.customerName}
                                    onRemove={() => dispatch(removeChat(cc.chatId))}
                                    chat={cc}
                                    reportHasNewMessage={(hasNewMessage: boolean) => {
                                        dispatch(setChatNewMessageStatus({
                                            chatId: cc.chatId,
                                            hasNewMessage: hasNewMessage
                                        }
                                    ))}}
                                />
                            ))}
                        </div>
                        
                        {drawerOpen ?
                            <ChatButton
                                // individual chat buttons will show presence of new message 
                                showNewMessageIcon={false}
                                onClick={onCloseDrawer}
                                icon="close"
                            />
                        :
                            <ChatButton
                                // show the new message icon if any of the closed chats have a new message
                                showNewMessageIcon={closedChats.some(chat => chat.commandCenterMember.hasNewMessage)}
                                disabled={closedChats.length === 0}
                                onClick={() => dispatch(setDrawerOpen(true))}
                                icon="drawer"
                            />
                        }
                    </div>
                </div>
            )}
        </>
    )
}

interface DrawerWrapperChatWindow extends ChatWindowProps {
    reportHasNewMessage: (hasNew: boolean) => void;  // reports message status to chat drawer
}

// wraps around the chat window to facilitate reporting of new message status
function DrawerWrappedChatWindow({reportHasNewMessage, ...windowProps}: DrawerWrapperChatWindow) {
    useChatMemberNewMessageStatusUpdatedSubscription({
        variables: {chatId: windowProps.chatId!, chatPartyId: windowProps.sendingMember.chatPartyId},
        shouldResubscribe: true,  // resubscribes when chat is replaced after price request is claimed
        onSubscriptionData: (data) => {
            reportHasNewMessage(data.subscriptionData.data?.chatMemberNewMessageStatusUpdated ?? false);
        }
    });

    return (
        <ChatWindow {...windowProps}/>
    )
}

interface ClosedChatButtonProps {
    title: string;
    subtitle?: string;
    onRemove: () => void;
    chat: ChatForJob;
    reportHasNewMessage: (hasNew: boolean) => void;  // reports message status to chat drawer
}

export function ClosedChatButton({
    title,
    subtitle,
    onRemove,
    chat,
    reportHasNewMessage
}: ClosedChatButtonProps) {
    const chatId = chat.chatId;
    const ccPartyId = chat.commandCenterMember.chatPartyId;
    const hasNewMessage = chat.commandCenterMember.hasNewMessage;

    const dispatch = useAppDispatch();

    useChatMemberNewMessageStatusUpdatedSubscription({
        variables: {chatId: chatId, chatPartyId: ccPartyId},
        skip: chatId < 1 || ccPartyId < 1,
        shouldResubscribe: true,  // resubscribes when chat is replaced after price request is claimed
        onSubscriptionData: (data) => {
            const has = data.subscriptionData.data?.chatMemberNewMessageStatusUpdated ?? false;
            reportHasNewMessage(has);
        }
    });

    const [markMessagesRead] = useClearNewMessageStatusMutation({
        variables: {chatId: chatId, partyId: ccPartyId}
    });

    function onOpenChat() {
        if (hasNewMessage) {
            markMessagesRead();
        }
        dispatch(openChat(chat));
    }

    return (
        <span className="closed-chat-button" onClick={onOpenChat}>
            <p className="flat-font-sm" style={{fontWeight: "bold"}}>{title}</p>
            <p className="flat-font-sm">{subtitle}</p>
            
            {hasNewMessage ? (
                <NewMessageIcon useInvertedVariant style={{display: "inline-block"}}/>
            ) : (
                <button
                    className="padding-none"
                    onClick={(e) => {e.stopPropagation(); onRemove()}}
                >
                    x
                </button>
            )}
        </span>
    )
}