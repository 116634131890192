import { MeasuredValue } from "Components/Inputs/MeasuredValueInput";
import { GetProductSpecQuery, ProductCanBeInstalledInput, ProductSpecificationInput, StructuredProductDetailInput, UnstructuredProductDetailInput, VendorSourcedProductInput } from "generated/graphql";
import { isEmptyString, isPositiveNumber } from "Globals/GenericValidators";
import { IdToIds, LocalProductDetail, SpecEditorValues } from "./ProductSpecViewer/ProductSpecViewer";
import { v_allStructuredProductDetails, v_allUnstructuredProductDetails, v_sqft } from "./Validators";

type BSpecEditorValues = GetProductSpecQuery["productSpec"]
type BStructDetail = BSpecEditorValues["structuredProductDetails"][number]
type BUnstructDetail = BSpecEditorValues["unstructuredProductDetails"][number]
type BInstallMethod = BSpecEditorValues["installationConfigurations"][number]

//#region backend to frontend
export function bToFProductSpec(b_spec: BSpecEditorValues): SpecEditorValues {
    return {
        sqft: b_spec.sqft.toString(),
        productDetails: [
            ...bToFStructuredProductDetails(b_spec.structuredProductDetails),
            ...bToFUnstructuredProductDetails(b_spec.unstructuredProductDetails)
        ],
        installationMethods: bToFInstallationMethods(b_spec.installationConfigurations)
    }
}

function bToFStructuredProductDetails(b_details: BStructDetail[]): LocalProductDetail[] {
    return b_details.map(b_detail => bToFStructuredProductDetail(b_detail))
}

function bToFStructuredProductDetail(b_detail: BStructDetail): LocalProductDetail {
    return {
        type: "Structured",
        id: b_detail.structuredProductDetailOptionId,
        value: {
            inputValue: b_detail.measurementValue.toString(),
            unitId: b_detail.measurementUnitId
        }
    }
}

function bToFUnstructuredProductDetails(b_details: BUnstructDetail[]): LocalProductDetail[] {
    return b_details.map(b_detail => bToFUnstructuredProductDetail(b_detail))
}

function bToFUnstructuredProductDetail(b_detail: BUnstructDetail): LocalProductDetail {
    return {
        type: "Unstructured",
        id: b_detail.unstructuredProductDetailOptionId,
        value: b_detail.value
    }
}

function bToFInstallationMethods(b_installs: BInstallMethod[]): IdToIds[] {
    const output = b_installs.reduce<{ [key: number]: number[] }>((total, b_install) => {
        if (!total[b_install.jobServiceId]) total[b_install.jobServiceId] = []
        total[b_install.jobServiceId].push(b_install.substrateId)
        return total;
    }, {})

    //TODO: change IdToIds to {[key: number] : number[] }

    function keyToFE(k: keyof typeof output): IdToIds {
        return { id: +k, group: output[k] }
    }

    return Object.keys(output).map(value => keyToFE(+value))
}

//#endregion

//#region frontend to backend
export function fToBProductSpecification(f_sqft: string, f_structDetails: LocalProductDetail[], f_unstructDetails: LocalProductDetail[], f_installs: IdToIds[]): ProductSpecificationInput {
    return {
        sqft: +f_sqft,
        structuredProductDetails: fToBStructuredProductDetails(f_structDetails),
        unstructuredProductDetails: fToBUnstructuredProductDetails(f_unstructDetails),
        installationConfigurations: fToBInstallationMethods(f_installs)
    }
}

export function fToBStructuredProductDetails(f_details: LocalProductDetail[]): StructuredProductDetailInput[] {
    return f_details.map(fToBStructuredProductDetail)
}

function fToBStructuredProductDetail(f_detail: LocalProductDetail): StructuredProductDetailInput {
    const mv: MeasuredValue = f_detail.value as MeasuredValue

    const b_detail: StructuredProductDetailInput = {
        structuredProductDetailOptionId: f_detail.id,
        measurementValue: +mv.inputValue,
        measurementUnitId: mv.unitId
    }
    return b_detail;
}

export function fToBUnstructuredProductDetails(f_details: LocalProductDetail[]): UnstructuredProductDetailInput[] {
    return f_details.map(fToBUnstructuredProductDetail)
}

function fToBUnstructuredProductDetail(f_detail: LocalProductDetail): UnstructuredProductDetailInput {
    const b_detail: UnstructuredProductDetailInput = {
        unstructuredProductDetailOptionId: f_detail.id,
        value: f_detail.value as string
    }
    return b_detail;
}

export function fToBInstallationMethods(f_installs: IdToIds[]): ProductCanBeInstalledInput[] {
    return f_installs.flatMap(fToBInstallationMethod)
}

function fToBInstallationMethod(f_install: IdToIds): ProductCanBeInstalledInput[] {
    return f_install.group.map(substrateId => {
        const b_install: ProductCanBeInstalledInput = {
            jobServiceId: f_install.id,
            substrateId
        }
        return b_install
    })
}

export function fToBVendorSourcedProduct(
    f_vendorId: number,
    f_style: string,
    f_color: string,
    f_orderSize: string,
    f_singleCostPerSqft: string,
    f_bulkCostPerSqft: string,
    f_oneTimeCostPerSqft?: string
): VendorSourcedProductInput {
    return {
        vendorId: f_vendorId,
        style: f_style,
        color: f_color,
        orderQuantity: +f_orderSize,
        singleCostPerSqft: +f_singleCostPerSqft,
        bulkCostPerSqft: +f_bulkCostPerSqft,
        oneTimeCostPerSqft: (f_oneTimeCostPerSqft === undefined) ? undefined : +f_oneTimeCostPerSqft
    }
}
//#endregion

//#region Validate and convert
export function v_fToBProductSpecification(
    f_sqft: string,
    f_details: LocalProductDetail[],
    f_installs: IdToIds[]): ProductSpecificationInput {

    //Sort the details
    const f_structDetails = f_details.filter(d => d.type === "Structured")
    const f_unstructDetails = f_details.filter(d => d.type === "Unstructured")

    //Validation step
    if (!v_sqft(f_sqft))
        throw new Error("Sqft is invalid")
    if (!v_allStructuredProductDetails(f_structDetails))
        throw new Error("There is a problem with a product detail with a measurement")
    if (!v_allUnstructuredProductDetails(f_unstructDetails))
        throw new Error("There is a problem with a product detail with just text")
    //There is no way to validate installation methods...

    //All valid, pass on!
    return fToBProductSpecification(f_sqft, f_structDetails, f_unstructDetails, f_installs)
}

export function v_fToBVendorSourcedProduct(
    f_vendorId: number,
    f_style: string,
    f_color: string,
    f_orderSize: string,
    f_singleCostPerSqft: string,
    f_bulkCostPerSqft: string,
    f_oneTimeCostPerSqft?: string
): VendorSourcedProductInput {

    //Validation step
    if (f_vendorId < 0)
        throw new Error("Select a vendor id")
    if (isEmptyString(f_style) && isEmptyString(f_color))
        throw new Error("Provide information about the vendors style or color")
    if (!isPositiveNumber(f_orderSize))
        throw new Error("Order size is incorrect")
    if (f_oneTimeCostPerSqft === undefined) {
        if (!isPositiveNumber(f_singleCostPerSqft))
            throw new Error("Single quantity cost per sqft is incorrect")
        if (!isPositiveNumber(f_bulkCostPerSqft))
            throw new Error("Bulk quantity cost per sqft is incorrect")
    }
    else {
        if (!isPositiveNumber(f_oneTimeCostPerSqft))
            throw new Error("One time cost per sqft is incorrect")
        f_singleCostPerSqft = "0"
        f_bulkCostPerSqft = "0"
    }

    return fToBVendorSourcedProduct(f_vendorId, f_style, f_color, f_orderSize, f_singleCostPerSqft, f_bulkCostPerSqft, f_oneTimeCostPerSqft)
}

//#endregion