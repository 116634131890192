import NavbarPage from "Components/Page";
import BasePromoTable from "./Promotions/BasePromoTable";
import DiscountTable from "./Discounts/DiscountTable";
import ScheduledPromotionTable from "./Promotions/ScheduledPromotionTable";
import MarketScalarTable from "./MarketScalarTable";

export default function PricingControlsPage() {
    return (
        <NavbarPage title="Pricing Controls" centerHorizontally padContent>            
            <div className="margin-top-sm padding-sm">
                <BasePromoTable />
            </div>
            <div className="margin-top-md padding-sm">
                <ScheduledPromotionTable />
            </div>
            <div className="margin-top-md padding-sm">
                <DiscountTable />
            </div>
            <div className="margin-top-sm padding-sm">
                <MarketScalarTable />
            </div>
        </NavbarPage>
    )
}