import { Button } from "@material-ui/core";
import clsx from "clsx";
import { useGetPartialProductStyleQuery } from "generated/graphql";
import { isoStringToDateTime } from "Globals/GenericConversions";
import { CARPET_PRODUCT_ID } from "Globals/globalConstants";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { changePricingEndDate, changePricingFactor, changePricingPrices, changePricingPromoPrices, changePricingShipping, changePricingStartDate, resetPricingEndDate, resetPricingRow, resetPricingStartDate, selectIsEndDateDifferent, selectIsFactorDifferent, selectIsPriceDifferent, selectIsPromoPriceDifferent, selectIsShippingDifferent, selectIsStartDateDifferent, selectPricingEndDate, selectPricingFactor, selectPricingPrice, selectPricingProductType, selectPricingPromoPrice, selectPricingShipping, selectPricingStartDate, setPricingViewMode } from "Redux/productPricingReducer";
import { store } from "Redux/store";
import { UnderlinedMoneyNumberFormat } from "../LaborRates/Components/MoneyNumberFormat";
import NumberFormattedTextField from "./SimpleEditors/NumberFormattedTextField";

export default function ProductPricingRow({ styleId }: { styleId: number }) {
    const { data, loading } = useGetPartialProductStyleQuery({ variables: { styleId } })

    if (loading || !(data?.partialProductStyle)) return <tr />

    const {
        style,
        bulkCostPerSqft: loadedBulkCost,
        pricingFactor,
        shippingPrice,
        promo
    } = data.partialProductStyle

    const { promoPrice, startDate, endDate } = promo ?? { promoPrice: undefined, startDate: undefined, endDate: undefined }

    return <DataInjectedPricingRow
        styleId={styleId}
        loadedCost={loadedBulkCost}
        loadedFactor={pricingFactor}
        loadedShipping={shippingPrice}
        loadedPromoPrice={promoPrice ?? undefined}
        loadedStartDate={startDate ?? undefined}
        loadedEndDate={endDate ?? undefined}
        styleName={style} />
}

function roundDecimal(value: number, decimalScale: number) {
    const powScale = Math.pow(10, decimalScale)
    return Math.ceil(value * powScale) / powScale
}

function calculateMargin(cost: number, price: number) {
    return ((price - cost) / cost) * 100
    //(price  / cost) * 100
    //(((price - cost) / (price === 0 ? .001 : price)) * 100)
}

function calculatePrice(cost: number, factor: number | undefined, shipping: number | undefined) {
    return roundDecimal(cost * (factor ?? 0) + (shipping ?? 0), 2)
}

function deriveFactor(cost: number, shipping: number | undefined, price: number | undefined) {
    return roundDecimal(((price ?? 0) - (shipping ?? 0)) / cost, 4)
}

interface DataInjectPricingRowProps {
    styleId: number,
    loadedCost: number,
    loadedFactor: number,
    loadedShipping: number,
    loadedPromoPrice?: number,
    loadedStartDate?: string,
    loadedEndDate?: string,
    styleName: string
}

function DataInjectedPricingRow({
    styleId,
    loadedCost,
    loadedFactor,
    loadedShipping,
    loadedPromoPrice,
    loadedStartDate,
    loadedEndDate,
    styleName }: DataInjectPricingRowProps) {

    const dispatch = useAppDispatch()

    const cd = new Date()
    const currentDate = isoStringToDateTime(cd.toISOString())

    const productTypeId = useAppSelector(selectPricingProductType)
    const isCarpet = productTypeId === CARPET_PRODUCT_ID

    const reduxFactor = useAppSelector(selectPricingFactor(styleId))
    const reduxShipping = useAppSelector(selectPricingShipping(styleId))
    const reduxPrice = useAppSelector(selectPricingPrice(styleId))
    const reduxPromoPrice = useAppSelector(selectPricingPromoPrice(styleId))
    const reduxStartDate = useAppSelector(selectPricingStartDate(styleId))
    const reduxEndDate = useAppSelector(selectPricingEndDate(styleId))

    const isFactorDifferent = useAppSelector(selectIsFactorDifferent(styleId))
    const isShippingDifferent = useAppSelector(selectIsShippingDifferent(styleId))
    const isPriceDifferent = useAppSelector(selectIsPriceDifferent(styleId))
    const isPromoPriceDifferent = useAppSelector(selectIsPromoPriceDifferent(styleId))
    const isStartDateDifferent = useAppSelector(selectIsStartDateDifferent(styleId))
    const isEndDateDifferent = useAppSelector(selectIsEndDateDifferent(styleId))

    const isRowChange = isFactorDifferent || isShippingDifferent || isPriceDifferent || isPromoPriceDifferent || isStartDateDifferent || isEndDateDifferent

    const isPromoDifferent = isPromoPriceDifferent || isStartDateDifferent || isEndDateDifferent

    const showPromoPrice = (!isPromoDifferent && loadedPromoPrice !== undefined) || 
                            (isPromoDifferent && (reduxPromoPrice !== undefined || reduxStartDate !== undefined || reduxEndDate !== undefined))
    const showDeletePromo = showPromoPrice

    const cost = loadedCost * (isCarpet ? 9 : 1)
    const factor = (isFactorDifferent) ? reduxFactor : loadedFactor
    const shipping = (isShippingDifferent) ? reduxShipping : (loadedShipping * (isCarpet ? 9 : 1))
    const price = (isPriceDifferent) ? reduxPrice : calculatePrice(cost, factor, shipping)
    const promoPrice = (isPromoPriceDifferent) ? reduxPromoPrice : loadedPromoPrice
    const startDate = isoStringToDateTime(((isStartDateDifferent) ? reduxStartDate : loadedStartDate) ?? "")
    const endDate = isoStringToDateTime(((isEndDateDifferent) ? reduxEndDate : loadedEndDate) ?? "")

    const margin = calculateMargin(cost, price ?? .001)
    const promoMargin = calculateMargin(cost, promoPrice ?? .001)

    function changeFactor(newValue: number | undefined) {
        if (newValue !== factor) {
            dispatch(changePricingFactor(styleId, newValue))
            dispatch(changePricingPrices(styleId, calculatePrice(cost, newValue, shipping)))
        }
    }

    function changeShipping(newValue: number | undefined) {
        if (newValue !== shipping) {
            dispatch(changePricingShipping(styleId, newValue))
            dispatch(changePricingPrices(styleId, calculatePrice(cost, factor, newValue)))
        }
    }

    function changePrice(newValue: number | undefined) {
        if (newValue !== price) {
            dispatch(changePricingPrices(styleId, newValue))
            dispatch(changePricingFactor(styleId, deriveFactor(cost, shipping, newValue)))
        }
    }

    function changePromoPrice(newValue: number | undefined) {
        if (newValue !== price) {
            dispatch(changePricingPromoPrices(styleId, newValue))
        }
    }

    function changeStartDate(newValue: string) {
        if (newValue === "") dispatch(resetPricingStartDate(styleId))
        else {
            if (endDate === "" || Date.parse(newValue) > Date.parse(endDate)) {
                dispatch(changePricingEndDate(styleId, newValue))
            }
            dispatch(changePricingStartDate(styleId, newValue))
        }
    }

    function changeEndDate(newValue: string) {
        if (newValue === "") dispatch(resetPricingEndDate(styleId))
        else dispatch(changePricingEndDate(styleId, newValue))
    }

    function clearRow() {
        dispatch(resetPricingRow(styleId))
    }

    function addPromo() {
        const { viewMode } = store.getState().productPricing
        if (viewMode === 2) dispatch(setPricingViewMode(0))
        dispatch(changePricingPromoPrices(styleId, price))
    }

    function deletePromo() {
        dispatch(changePricingPromoPrices(styleId, undefined))
        dispatch(changePricingStartDate(styleId, undefined))
        dispatch(changePricingEndDate(styleId, undefined))
    }

    return (
        <tr>
            <td>{styleName}</td>
            <td>{"$" + cost.toFixed(2)}</td>
            <td className="w-1r">x</td>
            <td className="w-5r">
                <NumberFormattedTextField value={factor} onChange={changeFactor} decimalScale={4} />
            </td>
            <td className="w-1r">+</td>
            <td className="w-5r">
                <NumberFormattedTextField value={shipping} onChange={changeShipping} decimalScale={2} />
            </td>
            <td className="w-1r">=</td>
            <td className="w-7r">
                <UnderlinedMoneyNumberFormat
                    value={price}
                    onValueChange={v => changePrice(v.floatValue)}
                    giveToInputProps={{ name: "price-price", unit: (isCarpet ? "/sq.yd." : "/sq.ft.") }} />
            </td>
            <td className="whitespace-no-wrap">
                {margin.toFixed(0) + " %"}
            </td>
            <td className="w-7r">
                <Button hidden={showPromoPrice} onClick={addPromo}>Add Promo</Button>
                {
                    showPromoPrice &&
                    <UnderlinedMoneyNumberFormat
                        value={promoPrice}
                        onValueChange={v => changePromoPrice(v.floatValue)}
                        giveToInputProps={{
                            textFieldClassNames: "color-red",
                            autoFocus: loadedPromoPrice === undefined,
                            name: "promo-price",
                            unit: (isCarpet ? "/sq.yd." : "/sq.ft.")
                        }} />
                }
            </td>
            <td className={clsx({ 'visibility-hidden': !showPromoPrice }, "whitespace-no-wrap color-red")} >
                {promoMargin.toFixed(0) + " %"}
            </td>
            <td>
                <input
                    className={clsx({ 'visibility-hidden': !showPromoPrice }, 'w-9r')}
                    type="date"
                    min={currentDate}
                    value={startDate}
                    onChange={e => changeStartDate(e.currentTarget.value)} />
            </td>
            <td>
                <input
                    className={clsx({ 'visibility-hidden': !showPromoPrice }, 'w-9r')}
                    type="date"
                    min={startDate ?? currentDate}
                    value={endDate}
                    onChange={e => changeEndDate(e.currentTarget.value)} />
            </td>
            <td>
                <Button variant="outlined" hidden={!showDeletePromo} onClick={deletePromo}>Delete Promo</Button>
            </td>
            <td className="w-5r">
                <Button className={clsx({ 'visibility-hidden': !isRowChange })} onClick={clearRow}>Clear</Button>
            </td>
        </tr>
    )
}