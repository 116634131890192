import { Button, IconButton, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { Room } from "generated/graphql";
import { useCallback, useState } from "react";
import { RoomIDAndServicesForRoom } from "../EditInstallationServicesMenu";
import { NewWallFinishOptionSelect } from "./NewWallFinishOptionSelect";
import NewWallServiceForRoomEditor from "./NewWallServiceForRoomEditor";

interface NewWallFinishEditorProps {
    rooms: Room[];
    newWallFinishServices: RoomIDAndServicesForRoom[];
    setAllRoomMaterialCategories: (newMaterialIds: number[]) => void;
    setIndividualRoomMaterialCategories: (roomId: number, newServicesInfo: {materialCategoryId: number, lnft: number}[]) => void;
    setCustDoes: (custDoes: boolean) => void;
    removeForRoom?: (roomId: number) => void;
    removeForEntireArea?: () => void;
}

export default function NewWallFinishEditor({
    rooms,
    newWallFinishServices,
    setCustDoes,
    setAllRoomMaterialCategories,
    setIndividualRoomMaterialCategories,
    removeForRoom,
    removeForEntireArea
} : NewWallFinishEditorProps) {
    const [expandRooms, setExpandRooms] = useState(false);
    const custDoes = newWallFinishServices[0].customerDoesService;

    // if rooms can be removed, show rooms which have no new wall finish build up
    // this component will unmount if no rooms have new wall finish
    const roomsToDisplay = isNullOrUndefined(removeForRoom)
        ? rooms.filter(r => newWallFinishServices.some(es => es.roomId === r.id))
        : rooms;

    const getServicesByRoomId = useCallback((roomId: number) => {
        return newWallFinishServices.filter(s => s.roomId === roomId);
    }, [newWallFinishServices]);
    
    return (
        <div className="flex-column">
            <div className="fill-width flex-row">
                <div className="flex-row flex-gap-xsm flex-grow">
                    <Typography style={{fontSize: "1.25rem", marginTop: ".25rem"}}>New Wall Finish:</Typography>
                
                    <NewWallFinishOptionSelect
                        keyPrefix="whole-area"
                        isForEntireArea
                        setMaterialCategories={(newCatIds) => setAllRoomMaterialCategories(newCatIds)}
                        services={newWallFinishServices}
                        onSelectNone={removeForEntireArea}                     
                    />
                </div>

                <div className="flex-row-center" style={{ width: "8rem" }}>
                    <Button
                        style={{ height: "2rem", fontSize: "1.15rem" }}
                        variant="contained"
                        onClick={() => setCustDoes(!custDoes)}
                    >
                        {custDoes ? "CUST": "WOF"}
                    </Button>
                </div>

                <div className="w-3r">
                    {(rooms.length > 0) && (
                        <IconButton
                            onClick={() => setExpandRooms(!expandRooms)}
                            style={{
                                height: "2rem",
                                width: "4rem",
                                fontSize: "1.25rem",
                                margin: ".25rem 0",
                            }}
                        >
                            {!expandRooms && <ArrowDropDownIcon fontSize="large" />}
                            {expandRooms && <ArrowDropUpIcon fontSize="large" />}
                        </IconButton>
                    )}
                </div>
            </div>
            
            <div className="grid-10-90 flex-gap-xsm">
                {expandRooms && (<>
                    {roomsToDisplay.map(r => (
                        <NewWallServiceForRoomEditor
                            key={`nwf-r-${r.id}`}
                            room={r}
                            services={getServicesByRoomId(r.id)}
                            setRoomMaterialInfo={(materialInfo) => setIndividualRoomMaterialCategories(r.id, materialInfo)}
                            remove={removeForRoom ? () => removeForRoom(r.id) : undefined}
                        />
                    ))}
                </>)}
            </div>
        </div>
    )
}