import { IconButton } from "@material-ui/core";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatUndoDeleteButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatUndoDeleteIcon color={color}/>
        </IconButton>
    )
}

export function FlatUndoDeleteIcon({color="black"} : {color?: string}) {
    return <RestoreFromTrashIcon htmlColor={color}/>
}