import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import clsx from "clsx";
import {
    FinancingOption,
    namedOperations,
    useGetJobFinancingOptionsQuery,
    useUpdateJobFinancingOptionMutation
} from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { removeSalesTax } from "Pages/Admin/ProjectManagement/SellSheet/QuoteCard/QuoteCard";
import { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { selectContractConfigurationId, selectJobConfigurationId, selectQuoteConfigToQuoteIdMap, selectTotalPrice, selectUsageContext } from "Redux/pricingCenterReducer";
import "./financing_option.css";
import SwapMenu from "./SwapMenu";

interface FinancingOptionViewerProps {
    hasSalesTaxDiscount: boolean;
    selectedFinOpId: number;
    preventRcEditing: boolean;
}

export default function FinancingOptionViewer({
    hasSalesTaxDiscount,
    selectedFinOpId,
    preventRcEditing
}: FinancingOptionViewerProps) {
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const totalPrice = useAppSelector(selectTotalPrice);
    // see the definition of roundedTotal in QuoteCard.tsx for why it is done this way
    const roundedTotal = +removeSalesTax(totalPrice, hasSalesTaxDiscount).toFixed(0);

    const usageContext = useAppSelector(selectUsageContext);
    const contractConfigId = useAppSelector(selectContractConfigurationId);
    const quotedConfigIds = Object.keys(useAppSelector(selectQuoteConfigToQuoteIdMap)).map(id => +id);
    const disableInteraction = preventRcEditing || usageContext === "readonly" || isNotNullOrUndefined(contractConfigId) || quotedConfigIds.includes(jobConfigurationId);

    const { data } = useGetJobFinancingOptionsQuery({
        skip: roundedTotal < 0 || jobConfigurationId < 1,
        variables: { jobPrice: roundedTotal, jobConfigurationId },
    });
    const ops = data?.jobFinancingOptions;
    const finOps = [ops?.slotA, ops?.slotB, ops?.slotC].filter((op) =>isNotNullOrUndefined(op)) as FinancingOption[];
    const canAddFinOp = finOps.length < 3;
    // used to tell the SwapMenu dialog that we are adding a new option rather than replacing
    const addFinOpObj: FinancingOption = {
        id: -2,
        term: -1,
        minAmount: -1,
        typeId: -1,
        fee: -1,
        tranCode: "ADD",
        slot: finOps.length + 1
    };

    // the item that is pending replacement by another option; when not null, SwapMenu dialog opens
    const [toSwap, setToSwap] = useState<FinancingOption | null>(null);

    const [updateFinancingOption] = useUpdateJobFinancingOptionMutation({
        onError: () => alert("Could not update financing option")
    });

    // returns a function that can be passed to each financing option so that the inidividual option components need not care how selecting the option is done
    function makeSelectFinancingOption(optionId: number) {
        return () => {
            updateFinancingOption({
                variables: {
                    jobConfigurationId: jobConfigurationId,
                    financingOptionId: optionId,
                },
                refetchQueries: [namedOperations.Query.GetJobConfigurationFinancing]
            });
        };
    }

    return (
        <>
            <div>
                <div
                    className="flat-font flat-font-bold"
                    style={{ marginBottom: ".5rem" }}
                >
                    Options
                </div>
                <div className="flex-column fit-content flex-gap-xsm">
                    {finOps.map((o) => (
                        <div
                            className="flex-row align-items-center flat-font-sm"
                            key={`job-fin-op-${o.id}`}
                        >
                            <FinancingSectionOption
                                option={o!}
                                isSelected={o.id === selectedFinOpId}
                                selectFinancingOption={makeSelectFinancingOption(o.id)}
                                appearDisabled={hasSalesTaxDiscount} // when true, appears disabled but can still be clicked
                                disabled={disableInteraction} // when true, appears disabled and CAN'T still be clicked
                            />

                            <IconButton
                                onClick={() => setToSwap(o!)}
                                className="margin-left-xsm"
                                style={{visibility: disableInteraction ? "hidden" : "visible"}}
                            >
                                <SwapHorizIcon />
                            </IconButton>

                        </div>
                    ))}

                    {canAddFinOp && (
                        <div
                            className="flex-row align-items-center flat-font-sm flat-font-bold"
                            style={{visibility: disableInteraction ? "hidden" : "visible"}}
                        >
                            <div>Add additional option</div>
                            <IconButton
                                size="small"
                                onClick={() => setToSwap(addFinOpObj)}
                                className="margin-left-xsm"
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>

            {toSwap && (
                <SwapMenu
                    toSwap={toSwap}
                    clearToSwap={() => setToSwap(null)}
                    toSwapIsSelected={toSwap.id === selectedFinOpId}
                    currentlyDisplayedOptions={finOps!}
                />
            )}
        </>
    );
}

interface FinancingSectionOptionCardProps {
    option: FinancingOption;
    isSelected: boolean;
    selectFinancingOption: () => void;
    appearDisabled: boolean; // makes option look disabled, but can be clicked
    disabled: boolean; // makes option ACTUALLY disabled
}

function FinancingSectionOption({
    option,
    isSelected,
    selectFinancingOption,
    appearDisabled,
    disabled
}: FinancingSectionOptionCardProps) {
    function onClick() {
        if (!disabled) {
            selectFinancingOption()
        }
    }

    return (
        <div
            onClick={onClick}
            id={clsx({"financing-section-option-selected": isSelected && !appearDisabled})}
            className={clsx("financing-section-option", {
                "financing-section-option-enabled": !isSelected && !appearDisabled && !disabled,
                "financing-section-option-disabled-appearance": appearDisabled || disabled,
            })}
        >
            <div>
                {option.term} Months {option.typeName} Payments
            </div>
        </div>
    );
}
