import { PropsWithChildren } from "react";

export default function SubSection({ title, flexEnd, children }: PropsWithChildren<{ title?: string, flexEnd?: boolean }>) {
    return (
        <div className="fill-width flex-column" style={{ alignItems: "flex-start" }}>
            {title && <h5>{title}</h5>}
            <div className="thin-horizontal-bar" />
            <div className="fill-width flex-row padding-xsm" style={{ justifyContent: (flexEnd) ? "flex-end" : undefined }}>
                {children}
            </div>
        </div>
    );
}
