import { Button, Input } from "@material-ui/core";
import { useState } from "react";
import { decrement, increment, incrementByAmount, selectCount } from "Redux/dummyReducer"; //Pulls in the required actions from the reducer slice for this page
import { useAppDispatch, useAppSelector } from "Redux/hooks"; //Pulls in the typesafe wrappings for `useDispatch` and `useSelector`


function TopComponent(){
    const dispatch = useAppDispatch(); //Typesafe dispatch method
    const count = useAppSelector(selectCount); //Typesafe method to call the selectCount Selector 

    //Component is rerendered whenever count changes
    return (<div style={{display:"flex", flexDirection:"row"}}>
        <Button onClick={() => dispatch(decrement())}>-</Button>
        <h1>{count}</h1>
        <Button onClick={() => dispatch(increment())}>+</Button>
    </div>)
}

function BottomComponent(){
    const dispatch = useAppDispatch();
    const [incrementAmount, setIncrementAmount] = useState('2');

    //Calling dispatch will not cause a rerender for this component nor the parent
    return (<div style={{display:"flex", flexDirection:"row"}}>
        <Input value={incrementAmount} onChange={e => setIncrementAmount(e.target.value)}/>
        <Button onClick={() => dispatch(incrementByAmount(+incrementAmount))}>Submit</Button>
    </div>)
}


export default function DummyReducerPage() {
    //Page renders two components that need to share information without causing a full page rerender
    return (<div style={{display:"flex", flexDirection:"column"}}>
        <TopComponent/>
        <BottomComponent/>
    </div>)
}