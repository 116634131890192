import { Button, TextareaAutosize, Typography } from "@material-ui/core";
import clsx from "clsx";
import RatingSelector from "Components/Forms/Controls/RatingSelector";
import Loading from "Components/Loading";
import {
    GetInstallationAppointmentForContractorDocument,
    GetInstallationAppointmentForContractorQueryVariables,
    useGetCompletionCertificateQuery,
    useGetTempCompletionCertificateDataQuery,
    useSubmitCompletionCertificateMutation,
} from "generated/graphql";
import { dateToMdy } from "Globals/DateAndTimeHelpers";
import { useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import StarRatings from "react-star-ratings";

export interface CompletionCertificateProps {
    installationAppointmentId: number;
    printMode?: boolean;
    onClose?: () => void
}

export default function CompletionCertificate({
    installationAppointmentId,
    printMode,
    onClose
}: CompletionCertificateProps) {
    const [questionRating, setQuestionRating] = useState(0);
    const [overallRating, setOverallRating] = useState(0);
    const [comments, setComments] = useState("");
    const [signatureReady, setSignatureReady] = useState(false);
    const sigPadRef = useRef<SignaturePad | null>(null);

    const { loading: loadingCertificate } = useGetCompletionCertificateQuery({
        variables: { installationAppointmentId },
        skip: printMode !== true,
        onCompleted(data) {
            setQuestionRating(data.completionCertificate?.question1Rating ?? 0);
            setOverallRating(data.completionCertificate?.overallRating ?? 0);
            setComments(data.completionCertificate?.comments ?? "");
        },
    });

    const { loading: loadingSignature } = useGetTempCompletionCertificateDataQuery({
        variables: { installationAppointmentId },
        skip: printMode !== true,
        onCompleted(data) {
            sigPadRef.current?.fromDataURL(data.tempCompletionCertificateData ?? "");
        },
    });

    const [submitCertificate, { loading: submittingCertificate }] = useSubmitCompletionCertificateMutation();

    const loading = loadingCertificate || loadingSignature;

    function onSubmit() {
        submitCertificate({
            variables: {
                installationAppointmentId: installationAppointmentId,
                question1Rating: questionRating,
                overallExperience: overallRating,
                comments: comments === "" ? null : comments,
                signatureBase64: sigPadRef.current?.getTrimmedCanvas().toDataURL("image/png") ?? "",
            },
            refetchQueries: [
                {
                    query: GetInstallationAppointmentForContractorDocument,
                    variables: {
                        installationAppointmentId,
                    } as GetInstallationAppointmentForContractorQueryVariables,
                },
            ],
        }).then(() => onClose?.())
    }

    function onClear() {
        sigPadRef.current?.clear();
        setSignatureReady(false);
    }

    const showSubmitBar = printMode !== true;
    const readyToSubmit = overallRating > 0 && questionRating > 0 && signatureReady;

    return (
       <>
            {
                submittingCertificate ? 
                <Loading altText="Submitting" /> :
                <div
                style={{
                    height: "100%",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "1rem",
                    position: "relative"
                }}
            >
                {!loading && <div id="completion-pdf-hook" />}
                <Typography variant="h5">Completion Certificate</Typography>
                <div
                    className={clsx("flex-column fill-width", {'animate-star-ratings' : questionRating > 0 && overallRating === 0})}
                    style={{ padding: ".5rem", flex: 1, alignItems: "center",  marginTop: ".75em" }}
                >
                    <QuestionRatingBlock
                        questionNumber={1}
                        question={"How was your installation experience?"}
                    />
                    <div style={{ marginTop: ".25em" }}>
                        <RatingSelector
                            rating={questionRating}
                            setRating={setQuestionRating}
                        />
                    </div>
                    <FadedEndHBar />
                    <QuestionRatingBlock
                        questionNumber={2}
                        question={"Overall Satisfaction"}
                    />
                    <StarRatings
                        rating={overallRating}
                        changeRating={setOverallRating}
                        starDimension="calc(90vw / 6)"
                        starSpacing="3px"
                        starRatedColor="#ffd802"
                        starHoverColor="#ffd802"
                    />
                    <FadedEndHBar />
                    <Typography style={{ width: "100%", textAlign: "left" }}>Comments:</Typography>
                    <TextareaAutosize
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        style={{
                            width: "100%",
                            backgroundColor: "lavender",
                            borderRadius: ".2em",
                            flex: 1,
                            fontSize: "1.25em"
                        }}
                    />
                    <div
                        style={{
                            border: "1px solid black",
                            position: "relative",
                            marginTop: ".5em",
                            width: "100%",
                        }}
                    >
                        <SignaturePad
                            clearOnResize={false}
                            ref={sigPadRef}
                            canvasProps={{
                                style: { width: "100%", height: "5rem", background: "white" },
                            }}
                            onEnd={() => setSignatureReady(true)}
                        />
                        <div
                            style={{
                                position: "absolute",
                                bottom: 5,
                                left: 5,
                                width: "calc(100% - 15px)",
                                borderBottom: "1px solid black",
                            }}
                        >
                            X
                        </div>
                    </div>
                    {showSubmitBar ? (
                        <div className="fill-width flex-row">
                            <Button
                                style={{ flex: 1 }}
                                variant="outlined"
                                color="secondary"
                                onClick={onClear}
                            >
                                Clear
                            </Button>
                            <Button
                                style={{ flex: 1 }}
                                variant="contained"
                                color="secondary"
                                disabled={!readyToSubmit}
                                onClick={onSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    ) : (
                        <div className="fill-width flex-row" style={{justifyContent:"right"}}>
                       <Typography>
                            Date: {dateToMdy(new Date())}
                       </Typography>
                    </div>
                    )}
                </div>
            </div>
            }
       </>
    );
}

interface QuestionRationBlockProps {
    questionNumber: number;
    question: string;
}

function QuestionRatingBlock({ questionNumber, question }: QuestionRationBlockProps) {
    return (
        <>
            <div className="flex-row fill-width">
                <div style={{ width: "1.25rem" }}>
                    <Typography>{questionNumber}.</Typography>
                </div>
                <div style={{ flex: 1, paddingRight: "6px" }}>
                    <Typography>{question}</Typography>
                </div>
            </div>
        </>
    );
}

function FadedEndHBar() {
    return (
        <div
            style={{
                marginTop: "1em",
                marginBottom: "1em",
                width: "90vw",
                height: "1px",
                backgroundImage:
                    "linear-gradient(to right, #00000000, #292A32 10%, #292A32 90%, #00000000)",
            }}
        ></div>
    );
}
