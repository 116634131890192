import { Typography } from "@material-ui/core";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatCancelButton from "FlatComponents/Button/FlatCancelButton";
import FlatCheckButton from "FlatComponents/Button/FlatCheckButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import { FlatLabeledCheckbox } from "FlatComponents/Inputs/FlatCheckbox";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import { CustomService } from "generated/graphql";
import { emptyCustomService } from "Globals/DataStructures/EmptyDataStructures";
import { isEmptyString } from "Globals/GenericValidators";
import { useState } from "react";
import { useContextMenu } from "react-contexify";
import NumberFormat from "react-number-format";
import AdditionalServicesContextMenu, { ADDITIONAL_SERVICE_CONTEXT_MENU_ID } from "./AdditionalServicesContextMenu";

export interface RoomIdAndName {
    id: number;
    name: string;
}

interface AdditionalServicesEditorProps {
    areaId: number;
    rooms: RoomIdAndName[];
    customServices: CustomService[];
    setCustomServices: (services: CustomService[]) => void;
    presentFurnitureJobServiceIds: number[]; // tells which furniture services the area has
    presentRrJobServiceIds: number[]; // tells which R&R services the area has
    addFurnitureService: (jsId: number) => void;
    addRrService: (jsId: number) => void;
    setPreventSubmission: (prevent: boolean) => void;
}

export default function CustomServicesEditor({
    areaId,
    rooms,
    customServices,
    setCustomServices,
    presentFurnitureJobServiceIds,
    presentRrJobServiceIds,
    addFurnitureService,
    addRrService,
    setPreventSubmission
}: AdditionalServicesEditorProps) {
    // need awareness of whether some service is in edit mode so submission can be delayed until all edits are saved
    const [serviceIdsBeingEdited, setServiceIdsBeingEdited] = useState<number[]>([]);
    const { show } = useContextMenu({ id: ADDITIONAL_SERVICE_CONTEXT_MENU_ID });

    function addNewCustomService() {
        const newService = {
            ...emptyCustomService,
            id: Math.min(0, ...customServices.map(s => s.id)) - 1,
            areaId: areaId
        };
        setCustomServices([...customServices, newService]);
        setServiceIdsBeingEdited([...serviceIdsBeingEdited, newService.id]);
        setPreventSubmission(true);
    }

    function setService(updatedService: CustomService) {
        const updateIdx = customServices.findIndex(s => s.id === updatedService.id);
        const updatedServices = [...customServices];
        updatedServices.splice(updateIdx, 1, updatedService);
        setCustomServices(updatedServices);
    }

    // adds to the list of services being edited so that submission can be prevented
    function onStartEditing(serviceId: number) {
        const newServicesBeingEdited = [...serviceIdsBeingEdited, serviceId];
        setServiceIdsBeingEdited(newServicesBeingEdited);
        setPreventSubmission(true);
    }

    // removes from the list being edited and checks whether to prevent submission
    function onStopEditing(serviceId: number) {
        const newServicesBeingEdited = serviceIdsBeingEdited.filter(sId => sId !== serviceId);
        setServiceIdsBeingEdited(newServicesBeingEdited);
        if (newServicesBeingEdited.length === 0) {
            setPreventSubmission(false);
        }
    }

    function removeService(serviceId: number) {
        setCustomServices(customServices.filter(s => s.id !== serviceId));
        onStopEditing(serviceId);
    }

    function addNewAccessory() {
        alert("TODO:")
    }
    
    return  (<>
        {customServices.map(s => (
            <CustomServiceRow
                key={s.id}
                rooms={rooms}
                service={s}
                setService={setService} 
                removeService={() => removeService(s.id)}
                onStartEditing={() => onStartEditing(s.id)}
                onStopEditing={() => onStopEditing(s.id)}
            />
        ))}

        <div className="flex-row flex-gap-sm">
            <FlatButton
                onClick={e => show(e)}
            >Additional Service</FlatButton>
            <FlatButton onClick={addNewAccessory}>Additional Accessory</FlatButton>

        </div>

        <AdditionalServicesContextMenu
            handleCustomClicked={addNewCustomService}
            handleFurnitureClicked={addFurnitureService}
            handleRRClicked={addRrService}
            presentFurnitureJobServiceIds={presentFurnitureJobServiceIds}
            presentRrJobServiceIds={presentRrJobServiceIds}
        /> 
    </>)
}

interface CustomServiceRowProps {
    rooms: RoomIdAndName[];
    service: CustomService;
    setService: (updatedService: CustomService) => void;
    removeService: () => void;
    onStartEditing: () => void;
    onStopEditing: () => void;
}

export function CustomServiceRow({
    rooms,
    service: originalService,
    setService: setCustomExternal,
    removeService: removeCustom,
    onStartEditing,
    onStopEditing
}: CustomServiceRowProps) {
    const newMode = originalService.id < 1;
    const [editedService, setEditedService] = useState(toEditable(originalService, newMode));
    // default to editing mode when the service is new
    const [editing, setEditing] = useState(newMode);

    function onCancel() {
        if (editedService.removeOnCancel) {
            // remove the new service from the list (component will unmount)
            removeCustom();
        } else {
            setEditedService(toEditable(originalService, false));
            setEditing(false);
        }

        onStopEditing(); // tells parent component we are done editing this service
    }

    function toggleRoomId(roomId: number) {
        let newRoomIds: number[];
        if (editedService.roomIds.includes(roomId)) {
            newRoomIds = editedService.roomIds.filter(rId => rId !== roomId);
        } else {
            newRoomIds = [...editedService.roomIds, roomId];
        }

        setEditedService({...editedService, roomIds: newRoomIds});
    }

    function validateAndPrepareData(): CustomService | undefined {
        const priceStr = editedService.price;
        if (isEmptyString(priceStr)) {
            alert("Enter a price for the service");
            return;
        }
        
        const servicePrice = +priceStr;
        if (isNaN(servicePrice)) {
            alert("Service price is invalid - ensure you've only entered numeric characters");
            return;
        }

        if (servicePrice < 0) {
            alert("Service price can't be negative");
            return;
        }

        const cleanedDescription = editedService.description.trim();
        if (isEmptyString(cleanedDescription)) {
            alert("Enter a description for the service");
            return;
        }

        if (editedService.roomIds.length === 0) {
            alert("Select at least 1 room");
            return;
        }

        return fromEditable({
            ...editedService,
            description: cleanedDescription
        });
    }

    function onUpdate() {
        const cleanedData = validateAndPrepareData();
        if (cleanedData) {
            setEditedService({...editedService, removeOnCancel: false});
            setCustomExternal(cleanedData);
            setEditing(false);  // sets internal state
            onStopEditing(); // tells parent component we are done editing this service
        }
    }

    const serviceRoomNames = getRoomNamesForService(rooms, originalService.roomIds);
    const serviceSummaryString = `${originalService.description} - $${originalService.price.toFixed(2)} - ${serviceRoomNames}`

    if (editing) {
        return (
            <div className="flex-column">
                <div className="flex-gap-sm flex-row">
                    <NumberFormat
                        customInput={FlatLabeledInput}    
                        prefix="$"
                        label="Price"
                        className="w-5r"
                        value={editedService.price}
                        onValueChange={v => setEditedService({...editedService, price: v.value})}
                        decimalScale={2} fixedDecimalScale
                        allowNegative={false}
                    />

                    <FlatLabeledInput
                        label="Description"
                        value={editedService.description}
                        onChange={e => setEditedService({...editedService, description: e.target.value})}
                    />
                    
                    <FlatCancelButton onClick={onCancel}/>
                    
                    <FlatCheckButton onClick={onUpdate}/>    
                </div>

                <div className="flex-row flex-gap-sm">
                    {rooms.map(r => (
                        <FlatLabeledCheckbox
                            key={`room-select-${r.id}`}
                            label={r.name}
                            checked={editedService.roomIds.includes(r.id)}
                            onClick={() => toggleRoomId(r.id)}
                        />
                    ))}
                </div>
            </div>
        )
    } else {
        return (
            <span className="flex-gap-sm flex-row">
                <Typography style={{fontSize: "1.25rem"}}>{serviceSummaryString}</Typography>
                <FlatEditButton onClick={() => {setEditing(true); onStartEditing();}}/>
                <FlatDeleteButton onClick={removeCustom}/>
            </span>
        )
    }
}

/**
 * Makes the price a string because strings are easier to work with in inputs.
 * The "removeOnCancel" flag allows specification of what should happen when the cancel button is hit.
 * If the service already existed on the job (was loaded from DB), this should be false.
 * If the service was not loaded from the DB (is new), but the check button was clicked and
 * the fully filled out service was added to the list, this should be true.
 * If the service was not loaded from the DB, but it hasn't been added to the list with the full
 * information, then this will just remove it from the list.
 */
interface EditableCustomService extends Omit<CustomService, "__typename" | "price"> {
    price: string;
    // used to specify what happens when the cancel button is clicked while editing
    // needed because when cancelling the service while adding it, it should be removed, but 
    removeOnCancel: boolean;
}

const toEditable = (original: CustomService, removeOnCancel: boolean): EditableCustomService => (
    {
        ...original,
        // when the service is new, it has an initial price of -1
        price: (original.price === -1) ? "" : original.price.toString(),
        removeOnCancel: removeOnCancel
    }
);

function fromEditable(editable: EditableCustomService): CustomService {
    const {removeOnCancel: _, ...rest} = editable;
    return {...rest, price: +editable.price};
}

function getRoomNamesForService(allRooms: RoomIdAndName[], roomIdsForService: number[]) {
    const roomsForService = allRooms.filter(r => roomIdsForService.includes(r.id));
    const names = roomsForService.map(r => r.name);
    return names.join(", ");
}