import Typography from "@material-ui/core/Typography";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { BreakdownTableProps, makeLaborBreakdownRows } from "./BreakdownTableUtils";
import { formatAsIntOrNDecimals } from "Globals/StringFormatting";

export default function LaborBreakdownTable({ title, areaBreakdowns }: BreakdownTableProps) {

    const breakdownRows = makeLaborBreakdownRows(areaBreakdowns);

    if (breakdownRows.length <= 0) 
        return <Typography>No area or product information to show</Typography>;

    return (
        <FlatSection centerAdornment={title ?? "Labor"} className="flat-job-detail-table" removePadding={true}>
            <table>
                <thead>
                    <tr>
                        <td>Line</td>
                        <td>Type</td>
                        <td>Quantity</td>
                        <td>Description</td>
                        <td>Area</td>
                    </tr>
                </thead>
                <tbody>
                    { breakdownRows.map(
                        (row, index) => (
                            <tr key={index}>
                                <td>{row.line}</td>
                                <td>{row.type}</td>
                                <td>{formatAsIntOrNDecimals(row.amount)} {row.amountUnit !== "each" ? row.amountUnit : ""}</td>
                                <td>{row.description}</td>
                                <td>{row.area}</td>
                            </tr>
                        )
                    ) }
                </tbody>
            </table>
        </FlatSection>
    );
}
