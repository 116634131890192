import { Dialog, IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

interface FlatDialogAlertProps {
    open: boolean;
    title: string;
    body: string;
    onClose: () => void;
}

export default function FlatDialogAlert({ open, onClose, title, body }: FlatDialogAlertProps) {
    return (
        <Dialog
            open={open}
            maxWidth="xl"
            onClose={onClose}
        >
            <div
                style={{ minHeight: "10rem", minWidth: "min(30rem, 80vw)", padding: "8px" }}
                className="flex-column"
            >
                <div
                    className="flex-row fill-width"
                    style={{ alignItems: "center" }}
                >
                    <div className="flat-font-lg flex-grow">{title}</div>
                    <IconButton
                        onClick={(e) => {
                            onClose();
                            e.stopPropagation();
                        }}
                        style={{ padding: "0" }}
                        size="medium"
                    >
                        <HighlightOffIcon
                            style={{
                                backgroundColor: "#aaaa",
                                borderRadius: "50%",
                                padding: "1px",
                                boxSizing: "content-box",
                            }}
                        />
                    </IconButton>
                </div>
                <div
                    className="flat-h-bar"
                    style={{ margin: "4px 0" }}
                />
                <div
                    className="flat-font"
                    style={{ maxHeight: "60vh", overflow: "auto" }}
                >
                    {body}
                </div>
            </div>
        </Dialog>
    );
}
