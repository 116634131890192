import { Button, Dialog, IconButton, Tooltip } from "@material-ui/core";
import EmailIcon from '@mui/icons-material/Email';
import { S3PdfViewer } from "Components/Files/S3PdfViewers";
import Loading from "Components/Loading";
import FlatCancelButton from "FlatComponents/Button/FlatCancelButton";
import FlatCheckButton from "FlatComponents/Button/FlatCheckButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import FlatCheckbox from "FlatComponents/Inputs/FlatCheckbox";
import FlatInput from "FlatComponents/Inputs/FlatInput";
import { namedOperations, useEmailQuoteMutation, useGetQuotePdfS3FileQuery } from "generated/graphql";
import { allTrue, isEmail, isNotEmptyString, isNullOrUndefined } from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import { useState } from "react";

export default function QuoteViewerPage() {
    const { id: quoteId } = useNumericIdParam();

    const { data, loading } = useGetQuotePdfS3FileQuery({
        variables: {quoteId: quoteId!},
        skip: isNullOrUndefined(quoteId)
    });
    const quotePdfFileId = data?.quotePdfS3File?.id ?? -1;
    const primaryEmail = data?.customerForQuote.email;

    const [emailDialogOpen, setEmailDialogOpen] = useState(false);

    return (<>
        <div className="flat-outer-container margin-vertical-sm" style={{height: "90vh"}}>
            {loading ? (
                <Loading />
            ) : (<>
                {(quotePdfFileId < 1) ? (
                    <span className="flex-row flex-gap-sm align-items-center justify-content-center padding-sm">
                        <IconButton onClick={() => setEmailDialogOpen(true)}>
                            <EmailIcon />
                        </IconButton>

                        <p className="margin-none flat-font">
                            Quote PDF has not been generated. Click the mail button to generate and email the PDF.
                        </p>
                    </span>
                ) : (
                    <div className="flex-column flex-gap-xsm fill-width padding-side-md" style={{height: "85vh"}}>
                        <span className="fit-content margin-top-xsm">
                            <Tooltip title="Email Contract">
                                <IconButton
                                    onClick={() => setEmailDialogOpen(true)}
                                >
                                    <EmailIcon />
                                </IconButton>
                            </Tooltip>
                        </span>

                        <S3PdfViewer
                            fileId={quotePdfFileId}
                            style={{ position: "relative", top: 0, bottom: 0, right: 0, width: "100%", height: "100%" }}
                        />
                    </div>              
                )}
            </>)}
        </div>

        {(emailDialogOpen && (quoteId ?? -1) > 0) && (
            <EmailQuoteDialog
                quoteId={quoteId!}
                baseEmail={primaryEmail!}
                closeDialog={() => setEmailDialogOpen(false)}
            />
        )}
    </>)
}

interface EmailQuoteDialogProps {
    quoteId: number;
    baseEmail: string;
    closeDialog: () => void;
}

// TODO: document difference between this and the dialog that opens from the cart page
function EmailQuoteDialog({quoteId, baseEmail: originalBaseEmail, closeDialog}: EmailQuoteDialogProps) {
    const [additionalEmails, setAdditionalEmails] = useState<string[]>([]);
    const [showErrors, setShowErrors] = useState(false);
    const [includeBaseEmail, setIncludeBaseEmail] = useState(true);
    const [baseEmail, setBaseEmail] = useState(originalBaseEmail);
    const [editingBase, setEditingBase] = useState(false);

    const [emailQuote, {loading: sending}] = useEmailQuoteMutation({
        onError: () => alert("Failed to email quote"),
        onCompleted: (res) => {
            if (res.emailQuote) {
                closeDialog();
            } else {
                alert("Failed to email quote");
            }
        },
        // in case quote needed to be (re)generated
        refetchQueries: [namedOperations.Query.GetQuotePdfS3File]
    });

    function onSend() {
        const emails = ((includeBaseEmail) ? [baseEmail, ...additionalEmails] : additionalEmails).filter(e => isNotEmptyString(e));
        if (emails.length === 0) {
            alert('Enter at least one email address');
            return;
        } else if (allTrue(emails.map(isEmail))) {
            emailQuote({
                variables: {
                    quoteId: quoteId,
                    emailAddresses: emails,
                    updatePrimaryEmail: (baseEmail !== originalBaseEmail)
                }
            });
        } else {
            alert("Could not submit - all email addresses must be valid");
            setShowErrors(true);
        }
    }

    function onEditBaseEmail(newBase: string) {
        if (newBase !== originalBaseEmail) {
            // email needs to be sent to the updated base email
            setIncludeBaseEmail(true);
        }
        setBaseEmail(newBase);
    }

    function onCancelEdits() {
        setEditingBase(false);
        setBaseEmail(originalBaseEmail);
    }

    return (
        <Dialog
            open
            onClose={closeDialog}
            maxWidth="md"
        >
            <div className="flex-column" style={{ width: "min(30rem, 100vw)", margin: "1rem" }}>
                <h3>Email Quote</h3>
                <div id="email-list-container">
                    <FlatInput
                        value={baseEmail}
                        onChange={(newBase) => onEditBaseEmail(newBase.target.value)}
                        // use readonly when list of recipients includes this email so it appears active (but still can't be edited)
                        readOnly={!editingBase && includeBaseEmail}
                        disabled={!editingBase && !includeBaseEmail}
                        className="flex-grow"
                        error={showErrors && !isEmail(baseEmail)}
                    />

                    <span className="email-actions">
                        {editingBase ? (<>
                            <FlatCheckButton onClick={() => setEditingBase(false)}/>
                            <FlatCancelButton onClick={onCancelEdits}/>
                        </>) : (<>
                            <FlatCheckbox
                                checked={includeBaseEmail}
                                onClick={() => setIncludeBaseEmail(!includeBaseEmail)}
                                disabled={sending || (baseEmail !== originalBaseEmail)}
                                style={{padding: 0}}
                            />

                            <FlatEditButton onClick={() => setEditingBase(true)}/>
                        </>)}
                    </span>
                    
                    {additionalEmails.map((email, index) => (<>
                        <FlatInput
                            key={`additional-email-input-${index}`}
                            error={showErrors && !isEmail(email)}
                            autoFocus
                            className="flex-grow"
                            value={email}
                            onChange={(e) => {
                                var copy = [...additionalEmails]
                                copy[index] = e.target.value
                                setAdditionalEmails(copy)
                            }} 
                            disabled={sending}
                        />

                        <span className="email-options">
                            <FlatDeleteButton
                                key={`additional-email-delete-${index}`}
                                onClick={() => setAdditionalEmails(additionalEmails.filter((_, ind) => ind !== index))}
                                style={{marginLeft: "-2px"}}
                                disableFocusRipple
                                disabled={sending}
                            />
                        </span>
                    </>))}

                    <Button
                        variant="outlined"
                        onClick={() => setAdditionalEmails([...additionalEmails, ""])}
                        disabled={sending}
                        id="add-email-button"
                    >+</Button>
                </div>

                <div className="fill-width flex-row" style={{ justifyContent: "space-between", marginTop: ".5rem" }}>
                    <Button variant="outlined" color="secondary" onClick={closeDialog} disabled={sending}>Back</Button>
                    <Button variant="contained" color="primary" onClick={onSend} disabled={sending || editingBase}>Send Quote</Button>
                </div>
            </div>
        </Dialog>
    )
}
