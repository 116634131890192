import { Button, TextField, Typography } from "@material-ui/core"
import Loading from "Components/Loading"
import Section from "Components/PageLayout/Section"
import { useAddNewStyleMutation, useGetProductStyleOptionsQuery, useGetProductStyleQuery } from "generated/graphql"
import { CARPET_PRODUCT_ID } from "Globals/globalConstants"
import { useState } from "react"
import { clearNSE, selectNSEBulkCost, selectNSEError, selectNSEMode, selectNSESingleCost, selectNSEStyle, selectStyleId, setNSEBulkCost, setNSEError, setNSESingleCost, setNSEStyle, setStyleId } from "Redux/addProductReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"
import { store } from "Redux/store"
import { bToFProductSpec, fToBInstallationMethods, fToBStructuredProductDetails, fToBUnstructuredProductDetails } from "./Conversions"
import SpecificationViewer, { SpecEditorValues } from "./ProductSpecViewer/ProductSpecViewer"
import { BulkCostEditor, SingleCostEditor } from "./SimpleEditors/CostEditors"
import { v_allStructuredProductDetails, v_allUnstructuredProductDetails, v_cost, v_newStyleName, v_productTypeId, v_sqft } from "./Validators"

export default function CreateNewStyle({isForHardSurface}: {isForHardSurface: boolean}) {
    const dispatch = useAppDispatch()
    const styleId = useAppSelector(selectStyleId)
    var editorMode = useAppSelector(selectNSEMode)
    var errorText = useAppSelector(selectNSEError)
    var [addNewStyle, { loading }] = useAddNewStyleMutation({
        onCompleted(data) {
            dispatch(clearNSE())
            dispatch(setStyleId(data.addNewStyle.id))
        },
        onError(err) {
            dispatch(setNSEError(err.message));
        },
        refetchQueries: [
            useGetProductStyleOptionsQuery.name.substr(3, useGetProductStyleOptionsQuery.name.length - 8)
        ],
        awaitRefetchQueries: true
    })


    if (editorMode === "None") editorMode = "Create";

    const [values, setValues] = useState<SpecEditorValues>({
        sqft: "",
        productDetails: [],
        installationMethods: []
    });

    useGetProductStyleQuery({
        variables: { styleId },
        skip: editorMode === "Create" || styleId < 1,
        onCompleted(data) {
            dispatch(setNSEStyle(data.productStyle.style))
            setValues(
                bToFProductSpec(data.productStyle.spec)
            )
            //TODO: verify this is working
        }
    });

    function TryUpdateStyle() {
        //TODO: Finish \/ him 
        throw new Error("Finish ME!")
    }

    function TrySubmitNewStyle() {
        if (loading) return;
        const addProductData = store.getState().addProduct
        const newName = addProductData.styleEditor.newStyleName
        const singleCost = addProductData.styleEditor.singleCost
        const bulkCost = addProductData.styleEditor.bulkCost
        const productTypeId = addProductData.typeId

        const isCarpet = productTypeId === CARPET_PRODUCT_ID;

        const sqft = values.sqft
        const structuredDetails = values.productDetails.filter(v => v.type === "Structured")
        const unstructuredDetails = values.productDetails.filter(v => v.type === "Unstructured")

        if (!v_newStyleName(newName)) { dispatch(setNSEError("New style name is invalid")); return; }

        if (!v_cost(singleCost)) { dispatch(setNSEError(`${isCarpet ? "Cut" : "Box"} cost is invalid`)); return; }
        if (!v_cost(bulkCost)) { dispatch(setNSEError(`${isCarpet ? "Roll" : "Pallet"} cost is invalid`)); return; }

        if (!v_productTypeId(productTypeId)) { dispatch(setNSEError("No product type is selected")); return; }
        if (!v_sqft(sqft)) { dispatch(setNSEError("Sqft is invalid")); return; }
        if (!v_allStructuredProductDetails(structuredDetails)) { dispatch(setNSEError("There is a problem with a product detail with a measurement")); return; }
        if (!v_allUnstructuredProductDetails(unstructuredDetails)) { dispatch(setNSEError("There is a problem with a product detail with just text")); return; }

        const structuredProductDetails = fToBStructuredProductDetails(structuredDetails)
        const unstructuredProductDetails = fToBUnstructuredProductDetails(unstructuredDetails)
        const installationConfigurations = fToBInstallationMethods(values.installationMethods)

        const singleCostPerSqft = (isCarpet) ? (+singleCost) / 9 : +singleCost
        const bulkCostPerSqft = (isCarpet) ? (+bulkCost) / 9 : +bulkCost

        addNewStyle({
            variables: {
                productTypeId,
                styleName: newName,
                singleCostPerSqft,
                bulkCostPerSqft,
                specName: "default",
                sqft: +sqft,
                structuredProductDetails,
                unstructuredProductDetails,
                installationConfigurations
            },
        });
    }

    return (
        <div>
            <div style={{ position: "relative" }}>
                <Section title="New Style" alignTop>
                    <div className="flex-row flex-gap-md">
                        <NewStyleNameEditor locked={editorMode === "Edit"} />
                        <ReduxSingleCostEditor label={isForHardSurface ? "Ctn. Cost" : "Cut Cost"}/>
                        <ReduxBulkCostEditor label={isForHardSurface ? "Pallet Cost" : "Roll Cost"} />
                    </div>
                </Section>
                <SpecificationViewer values={values} setValues={setValues} specName="default" />
                <div hidden={!loading} style={{ position: "absolute", top: 0 }} className="fill-width fill-height">
                    <Loading altText="Submitting..." />
                    <div style={{ position: "absolute", top: 0, backgroundColor: "black", opacity: .2 }} className="fill-width fill-height" />
                </div>
            </div>
            <Section flexEnd>
                <Typography className="flex-grow">{errorText}</Typography>
                <Button
                    onClick={() => dispatch(clearNSE())}
                    variant="outlined">
                    Cancel
                </Button>
                {editorMode === "Edit" && <Button className="margin-side-sm" variant="contained" onClick={TryUpdateStyle}>Update</Button>}
                {editorMode === "Create" && <Button className="margin-side-sm" variant="contained" onClick={TrySubmitNewStyle}>Create</Button>}
            </Section>
        </div>
    )
}

//New Style Editor Components
function NewStyleNameEditor({ locked }: { locked?: boolean }) {
    const style = useAppSelector(selectNSEStyle)
    const dispatch = useAppDispatch()

    function updateValue(newValue: string) {
        dispatch(setNSEStyle(newValue))
    }

    return (
        <TextField
            disabled={locked}
            value={style}
            onChange={(e) => updateValue(e.target.value)}
            margin="none"
            variant="filled"
            label="New Style Name"
        />
    )
}

function ReduxSingleCostEditor({ label }: { label: string }) {
    const singleCost = useAppSelector(selectNSESingleCost)
    const dispatch = useAppDispatch()

    function updateValue(newCost: string) {
        dispatch(setNSESingleCost(newCost))
    }

    return (
        <div className="flex-column">
            <label>{label}</label>
            <SingleCostEditor key="redux-single-cost-editor" cost={singleCost} setCost={updateValue} />
        </div>
    )
}

function ReduxBulkCostEditor({ label }: { label: string }) {
    const bulkCost = useAppSelector(selectNSEBulkCost)
    const dispatch = useAppDispatch()

    function updateValue(newCost: string) {
        dispatch(setNSEBulkCost(newCost))
    }

    return (
        <div className="flex-column">
            <label>{label}</label>
            <BulkCostEditor key="redux-bulk-cost-editor" cost={bulkCost} setCost={updateValue} />
        </div>
    )
}

