import NavbarPage from "Components/Page";
import { Container } from "react-bootstrap";
import ChangeOrderDialog from "./Dashboard/NewChangeOrder/ChangeOrderDialog";

export default function TestCO() {

    const configId = 9 //8

    return (
        <NavbarPage title="Test">
            <Container className="flex-column solid-border fill-height padding-sm">
                <ChangeOrderDialog open={true} setOpen={() => { }} jobConfigurationId={configId} jobContractId={-1} />
            </Container>
        </NavbarPage>
    )
}