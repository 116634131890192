import { ApolloProvider } from "@apollo/client";
import {
    createTheme,
    ThemeProvider
} from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import "firebase/auth";
import "firebase/firestore";
import { apolloClient } from "Globals/appApolloClient";
import { idUrlMask } from "Globals/Hooks";
import { installJobPath, newSAHAppointmentPath } from "Globals/PathStrings";
import TestCO from "Pages/Admin/ProjectManagement/TestCO";
import TestPage from "Pages/Admin/ProjectManagement/TestPage";
import NewSAHAppointmentPage from "Pages/Admin/SAHScheduling/NewSAHAppointmentPage";
import BlockTimePage from "Pages/BlockTime/BlockTimePage";
import CompletionCertificatePage from "Pages/CompletionCertificatePage";
import DummyAmountPicker from "Pages/DummyLoc/DummyAmountPicker";
import DummyArrayUpdateTest from "Pages/DummyLoc/DummyArrayUpdateTest";
import DummyReducerPage from "Pages/DummyLoc/DummyReducerPage";
import ContractorBlockTime from "Pages/Home/ContractorHome/BlockTime/ContractorBlockTime";
import InstallJobPage from "Pages/Home/ContractorHome/InstallJob/InstallJobPage";
import SvgTest from "Pages/TestPages/SvgTest";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { store } from "Redux/store";
import "./App.css";
import RequireAuth from "./Components/RequireAuth";
import "./FlatStyles.css";
import Admin from "./Pages/Admin/Admin";
import DummyLoc from "./Pages/DummyLoc/DummyLoc";
import DummyLoc2 from "./Pages/DummyLoc/DummyLoc2";
import Home from "./Pages/Home/Home";
import SetRole from "./Pages/SetRole/SetRole";

// const blackTheme = createTheme({
//     palette: {
//         primary: {
//             //main: '#3c58e7',
//             main: "#000000",
//             light: "#ff606c",
//             dark: "#aa001b",
//             contrastText: "#ffffff",
//         },
//         secondary: {
//             main: "#263238",
//             light: "#4f5b62",
//             dark: "#000a12",
//             contrastText: "#ffffff",
//         },
//     },
// });

export const redTheme = createTheme({
    palette: {
        primary: {
            main: "#DC3B42", //#dc3b42
            light: "#E83F47",
            dark: "#9C2A30",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#595959",
            light: "#4f5b62",
            dark: "#000a12",
            contrastText: "#ffffff",
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 10,
                font: "arial"
            },
        },
    },
});

function App() {
    return (
        <>
            <ApolloProvider client={apolloClient}>
                <Provider store={store}>
                    {/* Gives access to the store throughout the application*/}
                    <ThemeProvider theme={redTheme}>
                        <Router>
                            <Switch>
                                <Route path="/dummyloc">
                                    <RequireAuth>
                                        <DummyLoc />
                                    </RequireAuth>
                                </Route>
                                <Route path="/dummyreducer">
                                    <RequireAuth>
                                        <DummyReducerPage />
                                    </RequireAuth>
                                </Route>
                                <Route path="/dummyloc2">
                                    <RequireAuth>
                                        <DummyLoc2 />
                                    </RequireAuth>
                                </Route>
                                <Route path="/dummyamountpicker">
                                    <RequireAuth>
                                        <DummyAmountPicker />
                                    </RequireAuth>
                                </Route>
                                <Route path="/dummyreducer2">
                                    <RequireAuth>
                                        <DummyArrayUpdateTest />
                                    </RequireAuth>
                                </Route>
                                <Route path="/admin">
                                    <RequireAuth>
                                        <Admin />
                                    </RequireAuth>
                                </Route>
                                <Route path="/setrole">
                                    <RequireAuth>
                                        <SetRole />
                                    </RequireAuth>
                                </Route>
                                {/* 
                                    TODO: the NewSAHAppointment component needs to be removed from the project, but removing this breaks the calendar
                                    on the call center scheduleder. I have no clue what the connection is there
                                */}
                                <Route path={newSAHAppointmentPath}>
                                    <NewSAHAppointmentPage />
                                </Route>
                                <Route path="/block-time">
                                    <RequireAuth>
                                        <BlockTimePage />
                                    </RequireAuth>
                                </Route>
                                <Route path="/contractor-block-time">
                                    <RequireAuth>
                                        <ContractorBlockTime />
                                    </RequireAuth>
                                </Route>
                                <Route path="/test">
                                    <RequireAuth>
                                        <TestPage />
                                    </RequireAuth>
                                </Route>
                                <Route path="/test-co">
                                    <RequireAuth>
                                        <TestCO />
                                    </RequireAuth>
                                </Route>
                                <Route path={installJobPath + idUrlMask}>
                                    <RequireAuth>
                                        <InstallJobPage />
                                    </RequireAuth>
                                </Route>
                                <Route path={"/completion-certificate" + idUrlMask}>
                                    <RequireAuth>
                                        <CompletionCertificatePage />
                                    </RequireAuth>
                                </Route>
                                <Route path={"/svg-test"}>
                                    <RequireAuth>
                                        <SvgTest />
                                    </RequireAuth>
                                </Route>
                                <Route path="/tracking">
                                    <TestPage />
                                </Route>
                                <Route path="/">
                                    <RequireAuth>
                                        <Home />
                                    </RequireAuth>
                                </Route>
                            </Switch>
                        </Router>
                    </ThemeProvider>
                </Provider>
            </ApolloProvider>
        </>
    );
}

export default App;
