import { IconButton } from "@material-ui/core";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatCircleCheckButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatCircleCheckIcon color={color}/>
        </IconButton>
    )
}

export function FlatCircleCheckIcon({color="black"} : {color?: string}) {
    return <CheckCircleOutlineIcon htmlColor={color}/>
}