export interface RouteNamePair {
    name : string,
    route : string
}

export default function NavbarRoutes(){
    const make = (n : string, r : string) => {
        return {name: n, route: r};
    }

    return [make("Home", "/"), make("Tracking", "/tracking"), make("Reports", "/reports"), make("Admin", "/admin")] as RouteNamePair[]
}