import { Item, ItemParams, Menu } from "react-contexify";

export interface RightClickMenu {
    id: string,
    generate: ()=> JSX.Element
}

export interface RightClickMenuPair<TRow> {
    label: JSX.Element | string,
    onClick: ((args: ItemParams<TRow, any>) => void) | undefined
    showCondition?: (x: TRow) => boolean
}

export function useContextMenu<TRow>(menuName: string, menuOptions: RightClickMenuPair<TRow>[]) : RightClickMenu {

    function generate() {
        let key = 0
        return (<Menu id={menuName} key={menuName}>
            {menuOptions.map(
                (x) => generateMenuRows(x, `${menuName}-${key++}`)
            )}
        </Menu>)
    }

    function generateMenuRows(rightClickPair: RightClickMenuPair<TRow>, key: string) {
        return <Item hidden={(x) => isItemDisabled(x.props, rightClickPair.showCondition)} key={key} onClick={rightClickPair.onClick}>{rightClickPair.label}</Item>
    }

    function isItemDisabled(row: TRow, showCondition?: (x: TRow) => boolean) {
        if (showCondition) return !showCondition(row);
        else return false
    }

    return { id: menuName, generate }
}