import ProductTypeSelector from "Pages/Admin/ProductPricing/SimpleEditors/ProductTypeSelector";
import ProductColorSelect from "./ProductColorSelect";
import ProductStyleSelect from "./ProductStyleSelect";

interface ProductStyleColorSelectProps {
    data: { typeId?: number; styleId?: number; colorId?: number; };
    substrateIdsInArea: number[];
    onChangeProductType: (newId: number, name: string) => void;
    onChangeProductStyle: (newId: number, name: string, installServiceId: number, materialId?: number) => void;
    onChangeProductColor: (newId: number, name: string) => void;
}
export function ProductStyleColorSelect({ data, substrateIdsInArea, onChangeProductType, onChangeProductStyle, onChangeProductColor }: ProductStyleColorSelectProps) {

    return (
        <div className="flex-column fill-width padding-bottom-xsm" style={{ alignItems: "center" }}>
            <div className="flex-row fill-width">
                <ProductTypeSelector pid={data.typeId} setPid={(p, s) => onChangeProductType(p, s!)} label="Type:" />
            </div>
            <div className="flex-row fill-width">
                <ProductStyleSelect pid={data.typeId} sid={data.styleId} setStyleId={onChangeProductStyle} substrateIdsInArea={substrateIdsInArea} />
            </div>
            <div className="flex-row fill-width">
                <ProductColorSelect sid={data.styleId} cid={data.colorId} setColorId={onChangeProductColor} />
            </div>
        </div>
    );
}
