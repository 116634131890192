import { IconButton } from "@material-ui/core";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatAddButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatAddIcon color={color}/>
        </IconButton>
    )
}

export function FlatAddIcon({color="black"} : {color?: string}) {
    return <AddCircleOutlinedIcon htmlColor={color}/>
}