import { idUrlMask, twoIdUrlMask } from "Globals/Hooks";
import { breakdownPath, changeOrderPath, jobTrackerPath, measurePath, originalContractPath, picturesPath, printPickSheetPath, printWorkOrderPath, serviceOrderPath, workOrdersPath } from "Globals/PathStrings";
import { Route, Switch } from "react-router-dom";
import JobBreakdownPage from "../Breakdown/JobBreakdownPage";
import ChangeOrderPage from "../ChangeOrder/ChangeOrderPage";
import JobInstallationTrackerPage from "../JobInstallationTrackerPage";
import JobPicturesPage from "../JobPictures/JobPicturesPage";
import ServiceOrderPage, { ServiceOrderStyleSelect } from "../Services/ServiceOrderPage";
import ContractDrawingViewer from "../ContractDrawingViewer";
import JobTrackerPage from "./JobTrackerPage";
import OriginalContractPage from "./OriginalContractPage";
import PrintWorkOrderPage from "./WorkOrder/PrintWorkOrderPage";
import PrintablePickSheet from "./WorkOrder/PrintablePickSheet";
import WorkOrdersPage from "./WorkOrder/WorkOrdersPage";

export default function JobTrackerRouter() {
    return (
        <Switch>
            <Route path={printWorkOrderPath + twoIdUrlMask}>
                <PrintWorkOrderPage />
            </Route>
            <Route path={printPickSheetPath + idUrlMask}>
                <PrintablePickSheet />
            </Route>
            <Route path={jobTrackerPath}>
                <Switch>
                    <Route path={originalContractPath + idUrlMask}>
                        <JobTrackerPage title="Original Contract">
                            <OriginalContractPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={breakdownPath + idUrlMask}>
                        <JobTrackerPage title="Break Down">
                            <JobBreakdownPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={measurePath + idUrlMask}>
                        <JobTrackerPage title="Measure">
                            <ContractDrawingViewer />
                        </JobTrackerPage>
                    </Route>
                    <Route path={workOrdersPath + idUrlMask}>
                        <JobTrackerPage title="Work Orders">
                            <WorkOrdersPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={changeOrderPath + idUrlMask}>
                        <JobTrackerPage title="Change Order">
                            <ChangeOrderPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={serviceOrderPath + idUrlMask}>
                        <JobTrackerPage decorationElement={<ServiceOrderStyleSelect />} title="Service Order">
                            <ServiceOrderPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={picturesPath + idUrlMask}>
                        <JobTrackerPage title="Pictures">
                            <JobPicturesPage />
                        </JobTrackerPage>
                    </Route>
                    <Route path={jobTrackerPath + idUrlMask} >
                        <JobTrackerPage title="Job Tracker" >
                            <JobInstallationTrackerPage />
                        </JobTrackerPage>
                    </Route>
                </Switch>
            </Route>
        </Switch>
    )
}