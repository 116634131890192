import Loading from "Components/Loading";
import {
    namedOperations, useDeletePictureForRoomMutation,
    useGetJobConfigurationQuery
} from "generated/graphql";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { RoomPhotoGallery } from "../Generics/RoomPhotoGallery";
import { RoomGalleryImageBaseProps } from "../JobPictureViewer";

export function JobPictureGallery({
    jobConfigurationId,
    mobile,
    preventDelete,
}: {
    jobConfigurationId: number;
    mobile?: boolean;
    preventDelete?: boolean;
}) {
    const { data, loading } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId },
    });

    const [deletePicture] = useDeletePictureForRoomMutation({
        refetchQueries: [namedOperations.Query.GetJobConfiguration],
        awaitRefetchQueries: true,
    });
    function deleteImage(pictureForRoomId: number) {
        deletePicture({ variables: { pictureForRoomId } });
    }

    if (loading || data === undefined)
        return (
            <div style={{ height: "15rem", width: "60rem" }}>
                <Loading altText="Loading Gallery" />
            </div>
        );

    const pictures: RoomGalleryImageBaseProps[] =
        data!.jobConfiguration.areas.flatMap((area) =>
            area.rooms.flatMap((room) =>
                room.pictures.map((pic) => ({
                    label: `${getNameOfArea(room.labels)}`,
                    description: pic.picture?.description ?? "",
                    pictureId: pic.picture?.id ?? -1,
                    pictureForRoomId: pic.id,
                }))
            )
        );

    return (
        <RoomPhotoGallery
            pictures={pictures}
            deletePicture={preventDelete ? undefined : deleteImage}
            mobile={mobile}
        />
    );
}
