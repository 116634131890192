import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

interface DummyState { //Defined so that create slice can infer the type
    count: number,
    arrayData: string[],
}

const initialState: DummyState = {
    count: 0,
    arrayData: ["10rem", "10rem", "10rem"]
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const dummySlice = createSlice({
    name: "dummy", //No clue what this does
    initialState, 
    reducers: { //DON'T Call async functions in here. No 
        increment: (state) => {
            state.count += 1
        },
        decrement: (state) => {
            state.count -= 1
        },
        incrementByAmount: (state, action: PayloadAction<number>) => { //Payload action is used to wrap the input type. Can be called like dispatch(incrementByAction(1))
            state.count += action.payload
        },
        changeIndex: (state, action: PayloadAction<{index: number, newValue: string}>) => {
            state.arrayData[action.payload.index] = action.payload.newValue
        }
    }
})

export const {increment, decrement, incrementByAmount, changeIndex} = dummySlice.actions //Unpacks the actions created in the slice

export const selectCount = (state: RootState) => state.dummy.count //This selector can be used as a getter for this value. Used like useSelector(selectCount)
export const selectArrayElement = (state: RootState, index: number) => state.dummy.arrayData[index] //This selector gets the specified index from the array. Will only cause rerenders if this specific value changes

export default dummySlice.reducer 