import { idUrlMask, phoneNumberUrlMask } from "Globals/Hooks";
import { callCenterExistingJobPath, callCenterPath, callCenterSchedulerPath, dialerLandingPage } from "Globals/PathStrings";
import { Route, Switch } from "react-router-dom";
import CallCenterExistingJob from "./CallCenterExistingJob";
import CallCenterSchedulingPage from "./Scheduler/CallCenterSchedulingPage";
import CallCenterScriptsPage from "./CallCenterScriptsPage";
import CallLandingPage from "./CallLandingPage/CallLandingPage";
import CallCenterDashboard from "./Dashboard/CallCenterDashboard";

export default function CallCenterRouter() {
    return (
        <Switch>
            <Route path={callCenterSchedulerPath + idUrlMask} component={CallCenterSchedulingPage}></Route>
            <Route path={dialerLandingPage + phoneNumberUrlMask} component={CallLandingPage}></Route>
            <Route path={callCenterExistingJobPath + idUrlMask} component={CallCenterExistingJob}></Route>
            <Route path={`${callCenterPath}/scripts`} component={CallCenterScriptsPage}></Route>
            <Route path={callCenterPath} component={CallCenterDashboard}></Route>
        </Switch>
    )
}