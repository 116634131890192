import CircleAddButton from "Components/Forms/Controls/CircleAddButton"
import { FullServiceMaterialCategoryPricing, useGetAllServiceMaterialCategoryPricingsQuery } from "generated/graphql"
import { useMemo, useState } from "react"
import AddServiceProductDialog from "./AddServiceProductDialog"
import ServiceProductPricingTableRow, { ServiceProductPricingTableRowData } from "./ServiceProductPricingTableRow"

export default function ServiceProductPricingTable() {

    const { data } = useGetAllServiceMaterialCategoryPricingsQuery()

    const [localByProduct, setLocalByProduct] = useState<number[]>([])

    const groupedProduct: ServiceProductPricingTableRowData[] = useMemo(
        () => groupCategoriesByPricing(data?.allServiceMaterialCategoryPricings ?? [], localByProduct),
        [data, localByProduct])

    const [addOpen, setAddOpen] = useState(false)

    return (
        <>
            <div className="table-fixed-new" style={{ height: "75vh" }}>
                <table style={{ width: "60rem" }}>
                    <thead>
                        <tr style={{ textAlign: "center" }}>
                            <th style={{ width: "20rem" }}>Product</th>
                            <th style={{ width: "12rem" }}>Price</th>
                            <th style={{ width: "12rem" }}>Cost</th>
                            <th style={{ width: "5rem" }}></th>
                            <th style={{ width: "6rem" }}>Markup %</th>
                            <th style={{ width: "5rem" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            groupedProduct.map(product => <ServiceProductPricingTableRow key={product.id} {...product} editByProduct={() => {
                                setLocalByProduct([...localByProduct, product.serviceMaterialCategoryId])
                            }} />)
                        }
                    </tbody>
                </table>
            </div>
            <CircleAddButton variant="contained" style={{ marginTop: ".25em" }} onClick={()=>setAddOpen(true)}/>
            <AddServiceProductDialog open={addOpen} onClose={()=>setAddOpen(false)}/>
        </>
    )
}

function groupCategoriesByPricing(allCategories: FullServiceMaterialCategoryPricing[], preventGroupingOnCategoryIds: number[]): ServiceProductPricingTableRowData[] {
    const grouped = allCategories.reduce((grouped: { [materialCategoryId: number]: FullServiceMaterialCategoryPricing[] }, category) => {
        grouped[category.serviceMaterialCategoryId] = [...(grouped[category.serviceMaterialCategoryId] ?? []), category]
        return grouped;
    }, {})

    return Object.keys(grouped).flatMap(categoryId => {
        const categories = grouped[+categoryId]
        if (!preventGroupingOnCategoryIds.some(cat => cat === +categoryId)
            && categories.length > 1
            && !categories.some(cat => isPricingDifference(cat, categories[0]))) {
            // All the categories should be updated in unison as they are grouped
            const baseGrouped = createService(categories[0])
            return [
                {
                    ...baseGrouped,
                    singleProductType: null,
                    groupedHistoryIds: categories.map(cat => cat.id)
                }
            ]
        }
        else return categories.map(createService)
    })
}

function createService(category: FullServiceMaterialCategoryPricing): ServiceProductPricingTableRowData {
    const { productTypeId, productType, ...rest } = category
    return {
        ...rest,
        singleProductType: productType,
        groupedHistoryIds: [rest.id]
    }
}

function isPricingDifference(category1: FullServiceMaterialCategoryPricing, category2: FullServiceMaterialCategoryPricing): boolean {
    return category1.costPerUnit !== category2.costPerUnit || category1.pricePerUnit !== category2.pricePerUnit
}