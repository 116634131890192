import { TextField } from "@material-ui/core"
import { selectVendorStyle, setVendorStyle } from "Redux/addProductReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"

export default function VendorStyleEditor() {
    const style = useAppSelector(selectVendorStyle)
    const dispatch = useAppDispatch()

    function updateValue(newValue: string) {
        dispatch(setVendorStyle(newValue))
    }

    return (
        <TextField
            value={style}
            onChange={(e) => updateValue(e.target.value)}
            className="w-10r"
            margin="none"
            variant="filled"
            label="Vendor Style"
        />
    )
}