import { Select, MenuItem } from "@material-ui/core"
import { SurfaceType, useGetProductTypeOptionsQuery } from "generated/graphql"
import { selectTypeId, setTypeId } from "Redux/addProductReducer"
import { useAppSelector, useAppDispatch } from "Redux/hooks"

export default function TypeEditor() {
    const { data } = useGetProductTypeOptionsQuery({ variables: { surface: SurfaceType.Hard } })

    const productId = useAppSelector(selectTypeId)
    const dispatch = useAppDispatch()

    function updateValue(newValue: number) {
        dispatch(setTypeId(newValue))
    }

    return (
        <div className="flex-column">
            <label htmlFor="type">Type:</label>
            <Select
                defaultValue={-1}
                onChange={(e) => updateValue(e.target.value as number)}
                value={productId}
                className="w-10r"
            >
                <MenuItem
                    value={-1}
                    disabled
                >
                    Unselected
                </MenuItem>

                {(data?.options ?? []).map(o => (
                    <MenuItem
                        key={`vendor-select-${o.id}`}
                        value={o.id}
                    >
                        {o.type}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}
