import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InstallationCalendarMovementResult } from "generated/graphql";
import { WeeklyContractorType } from "Pages/Admin/ProjectManagement/InstallationCalendar/ContractorWeeklyInstallationCalendar";
import { MovementResolutionOption } from "Pages/Admin/ProjectManagement/InstallationCalendar/MovementResolutionDialog";
import { RootState } from "./store";

interface WeeklyInstallationSlice {
    //Defined so that create slice can infer the type
    selectedType?: WeeklyContractorType;
    selectedValue?: string;
    selectedContractorId?: number;
    selectedDate?: string;
    pendingMovements: InstallationCalendarMovementResult[];
    pendingMovementResolution: MovementResolutionOption;
}

interface SelectedCalanderItem<T extends number | string> {
    selectedValue: T;
    selectedContractorId: number;
    selectedDate: string;
}

const initialState: WeeklyInstallationSlice = { pendingMovements: [], pendingMovementResolution: "Stack" };

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const weeklyInstallationSlice = createSlice({
    name: "weeklyInstallation",
    initialState,
    reducers: {
        selectInstallation: {
            reducer(state, action: PayloadAction<SelectedCalanderItem<number>>) {
                state.selectedType = "Installation";
                const { selectedValue, selectedContractorId, selectedDate } =
                    action.payload;

                state.selectedValue = selectedValue.toString();
                state.selectedContractorId = selectedContractorId;
                state.selectedDate = selectedDate;
            },
            prepare(
                selectedValue: number,
                selectedContractorId: number,
                selectedDate: string
            ) {
                return {
                    payload: { selectedValue, selectedContractorId, selectedDate },
                };
            },
        },
        selectBlocked: {
            reducer(state, action: PayloadAction<SelectedCalanderItem<string>>) {
                state.selectedType = "Blocked";
                const { selectedValue, selectedContractorId, selectedDate } =
                    action.payload;

                state.selectedValue = selectedValue.toString();
                state.selectedContractorId = selectedContractorId;
                state.selectedDate = selectedDate;
            },
            prepare(
                selectedValue: string,
                selectedContractorId: number,
                selectedDate: string
            ) {
                return {
                    payload: { selectedValue, selectedContractorId, selectedDate },
                };
            },
        },
        selectService: {
            reducer(state, action: PayloadAction<SelectedCalanderItem<number>>) {
                state.selectedType = "Service";
                const { selectedValue, selectedContractorId, selectedDate } =
                    action.payload;

                state.selectedValue = selectedValue.toString();
                state.selectedContractorId = selectedContractorId;
                state.selectedDate = selectedDate;
            },
            prepare(
                selectedValue: number,
                selectedContractorId: number,
                selectedDate: string
            ) {
                return {
                    payload: { selectedValue, selectedContractorId, selectedDate },
                };
            },
        },
        deselectAll: (state) => {
            state.selectedType = undefined;
            state.selectedValue = undefined;
        },
        pushPendingMovement: (state, action: PayloadAction<InstallationCalendarMovementResult>) => {
            state.pendingMovements.push(action.payload);
            state.pendingMovementResolution = "Stack";
        },
        popPendingMovement: (state) => {
            state.pendingMovements.pop();
            state.pendingMovementResolution = "Stack";
        },
        setPendingMovementResolution: (state, action: PayloadAction<MovementResolutionOption>) => {
            state.pendingMovementResolution = action.payload
        }
    },
});

export const {
    selectInstallation,
    selectBlocked,
    selectService,
    deselectAll,
    pushPendingMovement,
    popPendingMovement,
    setPendingMovementResolution
} = weeklyInstallationSlice.actions; //Unpacks the actions created in the slice

export const selectIsInstallationSelected = (installationId: number) => (state: RootState) =>
    state.weeklyInstallation.selectedType === "Installation" &&
    state.weeklyInstallation.selectedValue === installationId.toString();

export const selectIsBlockedSelected = (blockedDay: string) => (state: RootState) =>
    state.weeklyInstallation.selectedType === "Blocked" &&
    state.weeklyInstallation.selectedValue === blockedDay;

export const selectIsServiceSelected = (serviceId: number) => (state: RootState) =>
    state.weeklyInstallation.selectedType === "Service" &&
    state.weeklyInstallation.selectedValue === serviceId.toString();

export const selectPendingMovement = (state: RootState) =>
    state.weeklyInstallation.pendingMovements.length > 0
        ? state.weeklyInstallation.pendingMovements[
              state.weeklyInstallation.pendingMovements.length - 1
          ]
        : undefined;

export const selectMovementResolutionOption = (state: RootState) => state.weeklyInstallation.pendingMovementResolution

export default weeklyInstallationSlice.reducer;
