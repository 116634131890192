import { ChatMember, useChatMemberNewMessageStatusUpdatedSubscription, useClearNewMessageStatusMutation, Worker } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import ChatButton from "./ChatButton";
import ChatWindow from "./ChatWindow";

export interface ChatProps {
    chatId: number;
    sendingWorker: Worker;  // the worker using the chat window
    sendingMember: ChatMember;
    actionComponent?: JSX.Element;
    title: string;
    subtitle?: string;
}

// only use this component when just a single chat window needs to appear on a page - otherwise use <ChatDrawer>
export default function Chat({chatId, sendingWorker: thisWorker, sendingMember, title, subtitle, actionComponent }: ChatProps) {
    const [chatOpen, setChatOpen] = useState(false);
    const [hasNewMessage, setHasNewMessage] = useState(sendingMember.hasNewMessage);

    useChatMemberNewMessageStatusUpdatedSubscription({
        variables: {chatId: chatId!, chatPartyId: sendingMember.chatPartyId},
        skip: chatId < 1,
        shouldResubscribe: true,  // resubscribes when chat is replaced after price request is claimed
        onSubscriptionData: (data) => setHasNewMessage(data.subscriptionData.data?.chatMemberNewMessageStatusUpdated ?? false)
    });
    
    const [markMessagesRead] = useClearNewMessageStatusMutation({
        variables: ({chatId: chatId, partyId: sendingMember.chatPartyId})
    });

    function onOpenChat() {
        if (hasNewMessage) {
            markMessagesRead();
        }
        setChatOpen(true);
    }

    return (<>
        {(isNotNullOrUndefined(chatId) && chatOpen) ? (
            <ChatWindow
                chatId={chatId!}
                sendingWorker={thisWorker}
                closeWindow={() => setChatOpen(false)}
                sendingMember={sendingMember}
                actionComponent={actionComponent}
                title={title} subtitle={subtitle}
                hasNewMessage={hasNewMessage}
                disabled={chatId < 1}
            />
        ) : (
            <ChatButton
                id="chat-button-pin-right"
                onClick={onOpenChat}
                icon="single"
                disabled={chatId < 1}
                showNewMessageIcon={hasNewMessage}
            />
        )}
    </>)
}

interface NewMessageIconProps {
    useInvertedVariant?: boolean;
    style?: React.CSSProperties;
}

export function NewMessageIcon({useInvertedVariant=false, style}: NewMessageIconProps) {
    const variantClass = useInvertedVariant ? "new-message-icon-inverted" : "new-message-icon-standard";
    
    return (
        <span style={{...style}} className={`new-message-icon-base ${variantClass}`}>
            <p className="margin-none">!</p>
        </span>
    )
}