import { Calendar, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { Dialog, DialogContent, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import Loading from "Components/Loading";
import { useSetCallCenterNotInterestedMutation } from "generated/graphql";
import { dateToDay, dayToIso } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";

export interface NotInterestedMenuProps {
    jobId: number;
    open: boolean;
    onClose: (submitted: boolean) => void;
}

export default function NotInterestedMenu({ ...props }: NotInterestedMenuProps) {
    return <>{props.open && <NotInterestedMenuInner {...props} />}</>;
}

function NotInterestedMenuInner({ jobId, open, onClose }: NotInterestedMenuProps) {
    const d = new Date();
    const defaultDate = new Date(d.setMonth(d.getMonth() + 3));

    const [date, setDate] = useState<DayValue>(dateToDay(defaultDate));
    const [notes, setNotes] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    const [setNotInterested, { loading }] = useSetCallCenterNotInterestedMutation();

    function submit() {
        if (isNotNullOrUndefined(date)) {
            setNotInterested({
                variables: {
                    jobId: jobId,
                    callbackDate: dayToIso(date!),
                    notes: notes,
                },
                onCompleted() {
                    onClose(true);
                },
                onError(error) {
                    setError(error.message);
                },
            });
        } else {
            setError("Select a date");
        }
    }

    return (
        <Dialog open={open}>
            <DialogContent>
                <div
                    style={{ width: "30rem", height: "35rem", position: "relative" }}
                    className="flex-column flex-centered"
                >
                    <Typography
                        variant="h4"
                        style={{ marginTop: "1rem" }}
                    >
                        Next Attempt Date
                    </Typography>
                    <Calendar
                        calendarClassName={clsx({ "visibility-hidden": loading })}
                        value={date}
                        onChange={setDate}
                        minimumDate={utils("en").getToday()}
                    />
                    <Typography style={{ width: "100%", textAlign: "start" }}>
                        Notes about attempt:
                    </Typography>
                    <TextField
                        disabled={loading}
                        multiline
                        fullWidth
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                    <div className="flex-grow" />
                    <Typography
                        style={{ width: "100%", textAlign: "end" }}
                        className="error-text"
                    >
                        {error}
                    </Typography>

                    {loading && (
                        <div
                            style={{
                                position: "absolute",
                                width: "30rem",
                                height: "35rem",
                                top: 0,
                                left: 0,
                            }}
                        >
                            <Loading />
                        </div>
                    )}

                    <div
                        className="flex-row fill-width"
                        style={{ justifyContent: "flex-end" }}
                    >
                        <SpacedButton
                            disabled={loading}
                            className="cancel-button"
                            onClick={() => onClose(false)}
                        >
                            Cancel
                        </SpacedButton>
                        <SpacedButton
                            disabled={loading}
                            variant="contained"
                            color="secondary"
                            onClick={submit}
                        >
                            Submit
                        </SpacedButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}