import { manualInputId, TypeableSelect } from "Components/Inputs/TypeableSelect"
import { useGetProductSpecNamesQuery } from "generated/graphql"
import { selectColorVariantSpecValues, selectSpecId, selectSpecValue, setColorVariantSpecValues, setSpec } from "Redux/addProductReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"
import SpecificationViewer, { SpecEditorValues } from "../ProductSpecViewer/ProductSpecViewer"

interface ExistingColorSpecEditorProps {
    colorId: number
}

export default function ExistingColorSpecEditor({ colorId }: ExistingColorSpecEditorProps) {
    const dispatch = useAppDispatch()
    const values = useAppSelector(selectColorVariantSpecValues)
    const specId = useAppSelector(selectSpecId)
    const setValues: (updateFunction: (prevValues: SpecEditorValues) => SpecEditorValues) => void =
        (updateFunction) => dispatch(setColorVariantSpecValues(updateFunction(values)))

    // const { data: loadedValues } = useGetProductSpecQuery({
    //     variables: { productSpecId: specId },
    //     skip: specId < 1,
    //     onCompleted(data) {
    //         setValues(_ => bToFProductSpec(data.productSpec))
    //     }
    // })


    return <SpecificationViewer
        values={values}
        setValues={setValues}
        specName={<SpecNameEditor colorId={colorId} />}
        readOnly={specId !== manualInputId} />
}

function SpecNameEditor({ colorId }: { colorId: number }) {
    const dispatch = useAppDispatch()
    const specId = useAppSelector(selectSpecId)
    const specValue = useAppSelector(selectSpecValue)

    const setValue = (id?: number, value?: string) => dispatch(setSpec({ id, value }))

    const { data: names, loading: loadingNames } = useGetProductSpecNamesQuery({
        variables: { colorId: colorId },
        onCompleted(data) {
            //If spec id is not in the loaded set, set to first loaded
            if (!data.productSpecNames.some(n => n.id === specId))
                setValue(data.productSpecNames[0].id)
        }
    })

    return (loadingNames || specId === -1) ? <>Loading...</> : (
        <div className="flex-column margin-side-xsm">
            <label htmlFor="spec-name-typeable">Specification Name:</label>
            <TypeableSelect
                name="spec-name-typeable"
                selectedId={specId}
                typedValue={specValue}
                setValue={setValue}
                options={names!.productSpecNames.map(n => ({ id: n.id, value: n.specName }))}
                revertToId={names!.productSpecNames[0].id}
            />
        </div>

    )

}