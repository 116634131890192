import clsx from "clsx";
import {
    ContractorInstallationCapabilitiesAndDaysOff,
    InstallationAppointment,
    InstallationCalendarBlockIdentifierInput,
    InstallationCalendarType
} from "generated/graphql";
import { dateTimeStrToMdy, ymdDashedToDate } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { formatNameStringLastFirst } from "Globals/StringFormatting";
import { getRatingColor } from "Pages/Admin/CommandCenter/Dashboard/CommandCenterAppointmentCell";
import {
    cityNames,
    createRandomishItems,
    customerNames,
    groupCalendarItems,
    PositionedCalendarItem
} from "Pages/Home/ContractorHome/ContractorAppointmentCalendar";
import { useMemo } from "react";
import { ContractorDaySlot } from "./DaySlot";
import { Tooltip } from "@material-ui/core";

interface ContractorWeeklyInstallationCalendarProps {
    appointments: InstallationAppointment[];
    contractor: ContractorInstallationCapabilitiesAndDaysOff;
    startDate: string;
    daysToShow: number;
    days: string[];
    startPolling: () => void;
    stopPolling: () => void;
}

export function ContractorWeeklyInstallationCalendar({
    appointments,
    contractor,
    startDate,
    daysToShow,
    days,
    startPolling,
    stopPolling,
}: ContractorWeeklyInstallationCalendarProps) {
    const installationItems: PositionedCalendarItem<GenericItemData>[] = useMemo(() => {
        return appointments.map((app) => {
            const orderedMDYDates = app.dates.map((date) => dateTimeStrToMdy(date));

            return {
                id: `A-${app.id}`,
                startDate: orderedMDYDates[0],
                itemSpan: app.totalDaysInRange,
                orderedMDYDates: orderedMDYDates,
                data: InstallationAppointmentToData(app),
            };
        });
    }, [appointments]);

    const blockedItems: PositionedCalendarItem<GenericItemData>[] = useMemo(() => {
        return contractor.daysOff.map((block) => {
            return {
                id: `${contractor.id}-${new Date(block).getTime() / 1000}`,
                startDate: dateTimeStrToMdy(block),
                itemSpan: 1,
                orderedMDYDates: [],
                data: blockDateToData(block),
            };
        });
    }, [contractor]);

    const items = false
        ? createRandomishItems(
              contractor.id * 6969,
              2,
              startDate,
              daysToShow - 10,
              generateLocalItem
          )
        : [...installationItems, ...blockedItems];
    const grouped = groupCalendarItems<GenericItemData>(
        ymdDashedToDate(startDate),
        daysToShow,
        items
    );

    const requiredSlots = Math.max(1, ...grouped.map((gr) => gr.length));

    const slotIndex = [...new Array(requiredSlots)];

    const isUnassigned = contractor.id === 1;

    const filteredCapabilites = contractor.installationCapabilities.filter((cap) =>
        [1, 2, 4].includes(cap.productTypeId)
    );
    filteredCapabilites.sort((cp) => cp.productTypeId);

    var contractorRating = ((contractor.id * 19027 * 7.1) % 8) + 2;

    return (
        <>
            <div
                className="weekly-install-contractor flat-has-customer-rating-indicator flat-font-md flat-font-bold"
                style={{
                    gridRow: `span ${requiredSlots}`,
                    backgroundColor: isUnassigned
                        ? "var(--flat-faded-red)"
                        : "var(--flat-faded-blue)",
                    color: "var(--flat-gray-6)",
                    fontSize: "12pt",
                }}
            >
                <div>
                    {isUnassigned ? (
                        "UNASSIGNED"
                    ) : (
                        <Tooltip
                            title={
                                <div className="flat-colors flat-dark-bkg">
                                    <div
                                        className={clsx(
                                            getRatingColor(contractorRating),
                                            "flat-font-sm flat-customer-rating-indicator"
                                        )}
                                    >
                                        {contractorRating.toFixed(1)}
                                    </div>
                                </div>
                            }
                        >
                            <div>{formatNameStringLastFirst(contractor.contractor)}</div>
                        </Tooltip>
                    )}
                </div>
                {!isUnassigned && (
                    <div className="flex-row flex-centered">
                        {filteredCapabilites.map((cap) => {
                             var label = colorOptions.find((co) => co.id === cap.productTypeId);
                            var colorClass = getProductTypeColorClass(cap.productTypeId);

                            var potential =
                                cap.productTypeId === 4
                                    ? (cap.sqftPotential / 9).toFixed(0)
                                    : cap.sqftPotential.toFixed(0);

                            return (
                                <Tooltip title={<div>{label?.lbl}</div>} key={cap.contractorId + "-" + cap.productTypeId + "-tt"}>
                                    <div
                                        key={cap.contractorId + "-" + cap.productTypeId}
                                        className={clsx(
                                            colorClass,
                                            "flat-font-sm flat-product-capability-indicator"
                                        )}
                                    >
                                        {potential}
                                    </div>
                                </Tooltip>
                            );
                        })}
                    </div>
                )}
            </div>
            {slotIndex.flatMap((u, slotIndex) =>
                grouped.map((day, index) => (
                    <ContractorDaySlot
                        key={`${index}-${slotIndex}-${contractor.id}`}
                        props={day?.[slotIndex]}
                        contractorId={contractor.id}
                        day={days[index]}
                        startPolling={startPolling}
                        stopPolling={stopPolling}
                    />
                ))
            )}
            <div
                style={{
                    gridColumn: `span ${daysToShow + 1}`,
                    height: isUnassigned ? "6px" : "3px",
                    backgroundColor: isUnassigned ? "var(--flat-gray-6)" : "var(--flat-gray-3)",
                }}
            ></div>
        </>
    );
}

function getProductTypeColorClass(pid: number) {
    if (pid === 1) return "wood-theme-color";
    else if (pid === 2) return "lvp-theme-color";
    else return "carpet-theme-color";
}

const colorOptions = [
    { lbl: "WD", id: 1 },
    { lbl: "CPT", id: 4 },
    { lbl: "SPC", id: 2 },
];

var nextId = 0;

function generateLocalItem(getRandom: (upper: number) => number, span: number): GenericItemData {
    if (span === 1 && getRandom(10) - 3 - span <= 0) {
        const result: BlockedDateItemData = {
            type: "Blocked",
            topLabel: "Blocked",
            middleLabel: "Day off",
            date: nextId.toString(),
            colorStripeVariables: ["var(--flat-white)"],
        };

        nextId++;

        return result;
    }

    const colorIter = [...new Array(getRandom(3))];

    const colors =
        colorIter.length === 0
            ? [colorOptions[3]]
            : colorIter
                  .map(() => colorOptions[getRandom(3)])
                  .reduce<{ lbl: string; id: number }[]>((arr, curr) => {
                      if (!arr.some((a) => a === curr)) arr.push(curr);
                      return arr;
                  }, []);

    const customerName = `${
        customerNames[getRandom(customerNames.length)]
    }, Jerry but with a long name`;
    const cityName = cityNames[getRandom(cityNames.length)];

    const materialUpper = span * 400;
    var remainingAllowed = materialUpper;

    function randomInRange(lower: number, upper: number) {
        return getRandom(upper - lower) + lower;
    }

    if (colorIter.length === 0) {
        const result: ServiceItemData = {
            type: "Service",
            topLabel: "Service",
            middleLabel: customerName,
            bottomLabel: cityName,
            serviceId: nextId,
            colorStripeVariables: ["var(--flat-yellow)"],
        };

        nextId++;

        return result;
    }

    const { woodTotal, spcTotal, carpetTotal } = colors.reduce<{
        woodTotal: number;
        spcTotal: number;
        carpetTotal: number;
    }>(
        (total, curr) => {
            const amount = randomInRange(remainingAllowed / 4, remainingAllowed + 1);
            remainingAllowed -= amount;

            if (curr.id === 1) return { ...total, woodTotal: amount };
            else if (curr.id === 2) return { ...total, spcTotal: amount };
            else if (curr.id === 4) return { ...total, carpetTotal: amount };
            else return { ...total };
        },
        { woodTotal: 0, spcTotal: 0, carpetTotal: 0 }
    );
    const result: InstallationItemData = {
        type: "Installation",
        middleLabel: customerName,
        bottomLabel: cityName,
        installationAppointmentId: nextId,
        contractId: -1,
        releasedToContractor: true,
        ...createInstallationLabel(woodTotal, spcTotal, carpetTotal, true),
    };

    nextId++;

    return result;
}

function createInstallationLabel(
    woodTotal: number,
    spcTotal: number,
    carpetTotal: number,
    releasedToContractor: boolean
): Pick<InstallationItemData, "colorStripeVariables" | "topLabel" | "lastCardMiddle"> {
    const hasWood = woodTotal > 0;
    const hasSpc = spcTotal > 0;
    const hasCpt = carpetTotal > 0;

    const topLabel = (
        <>
            {hasWood && (
                <>
                    <div style={{ color: "var(--flat-green)" }}>WOOD</div>
                    <div>{woodTotal.toFixed(0)} sf.</div>
                </>
            )}
            {hasSpc && (
                <>
                    <div style={{ color: "var(--flat-cyan)" }}>SPC</div>
                    <div>{spcTotal.toFixed(0)} sf.</div>
                </>
            )}
            {hasCpt && (
                <>
                    <div style={{ color: "var(--flat-red)" }}>CPT</div>
                    <div>{(carpetTotal / 9).toFixed(0)} yd.</div>
                </>
            )}
        </>
    );

    return {
        topLabel: topLabel,
        colorStripeVariables: [
            hasWood ? "var(--flat-green)" : "",
            hasSpc ? "var(--flat-cyan)" : "",
            hasCpt ? "var(--flat-red)" : "",
        ].filter((val) => val !== ""),
        lastCardMiddle: releasedToContractor ? (
            <div className="flat-font-sm">Released</div>
        ) : (
            <div></div>
        ),
    };
}

function InstallationAppointmentToData(appointment: InstallationAppointment): InstallationItemData {
    return {
        type: "Installation",
        middleLabel: appointment.customerLastName,
        bottomLabel: appointment.customerAddress.city,
        installationAppointmentId: appointment.id,
        contractId: appointment.jobContractId,
        ...createInstallationLabel(
            appointment.woodTotal,
            appointment.spcTotal,
            appointment.carpetTotal,
            isNotNullOrUndefined(appointment.releasedToContractor)
        ),
        releasedToContractor: isNotNullOrUndefined(appointment.releasedToContractor),
    };
}

function blockDateToData(blockedDate: string): BlockedDateItemData {
    return {
        type: "Blocked",
        date: blockedDate,
        topLabel: "Blocked",
        middleLabel: "Day off",
        colorStripeVariables: ["var(--flat-white)"],
    };
}

export interface ServiceItemData extends GenericItemData {
    type: "Service";
    serviceId: number;
}

export interface InstallationItemData extends GenericItemData {
    type: "Installation";
    installationAppointmentId: number;
    contractId: number;
    releasedToContractor: boolean;
}

export interface BlockedDateItemData extends GenericItemData {
    type: "Blocked";
    date: string;
}

export interface GenericItemData {
    type: WeeklyContractorType;
    topLabel: string | JSX.Element;
    middleLabel?: string;
    bottomLabel?: string;
    lastCardMiddle?: string | JSX.Element;
    colorStripeVariables: string[];
}

export function itemDataToInstallationCalendarBlockIdentifier(
    contractorId: number,
    day: string,
    itemData?: GenericItemData
): InstallationCalendarBlockIdentifierInput | undefined {
    if (itemData === undefined) return undefined;
    else if (itemData.type === "Blocked") {
        var blockedItem = itemData as BlockedDateItemData;
        return {
            type: itemData.type.toUpperCase() as InstallationCalendarType,
            identifier: blockedItem.date,
            contractorId,
            startingDate: day,
        };
    } else if (itemData.type === "Installation") {
        var installationItem = itemData as InstallationItemData;
        return {
            type: itemData.type.toUpperCase() as InstallationCalendarType,
            identifier: installationItem.installationAppointmentId.toString(),
            contractorId,
            startingDate: day,
        };
    } else if (itemData.type === "Service") {
        var serviceItem = itemData as ServiceItemData;
        return {
            type: itemData.type.toUpperCase() as InstallationCalendarType,
            identifier: serviceItem.serviceId.toString(),
            contractorId,
            startingDate: day,
        };
    }
}

export type WeeklyContractorType = "Installation" | "Blocked" | "Service";
