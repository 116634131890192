import firebase from "firebase";
import { useAuthToken } from "Globals/FirebaseUtils";
import Login from "Pages/Login/Login";
import { PropsWithChildren } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Loading from "./Loading";

export default function RequireAuth({ children }: PropsWithChildren<{}>) {
  const [user, loading, error] = useAuthState(firebase.auth());
  const token = useAuthToken();
  
  if (loading) {
    return <Loading />;
  } else if (error) {
    return <p>Login Error: {error}</p>;
  } else if (user) {
    if (token) {
      console.log(token);
      return <>{children}</>;
    } else {
      return <Loading />;
    }
  } else {
    return <Login />;
  }
}

export function NotAuthorized() {
  return (
    <div
      style={{
          width: "80vw",
          textAlign: "center",
          height: "10rem",
          lineHeight: "10rem",
          backgroundColor: "var(--flat-gray-2)",
      }}
      className="flat-font-lg">Not authorized</div>
  )
}
