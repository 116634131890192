import NavbarPage from "Components/Page";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";
import { GetAllCallCenterJobsQuery, useGetAllCallCenterJobsQuery } from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { callCenterSchedulerPath, callCenterScriptsPath } from "Globals/PathStrings";
import { useAppHistory } from "Globals/routingHooks";
import RecoveryCenterColumn from "Pages/Admin/RecoveryCenter/VisualComponents/RecoveryColumn";
import { useMemo } from "react";
import "./call-center-styles.css";
import CallCenterCard from "./CallCenterCard";

export type CallCenterJob = GetAllCallCenterJobsQuery["allCallCenterJobs"][number];

const COLUMN_WIDTH = "18em";
const PAGE_WIDTH = "98vw";
const DISPLAY_COLUMN_COUNT = 5;
const targetPageFontSize = `min(1rem, ${PAGE_WIDTH} / (${DISPLAY_COLUMN_COUNT + 0.5} * ${
    COLUMN_WIDTH.split("em")[0]
}))`;

export default function CallCenterDashboard() {
    const { data } = useGetAllCallCenterJobsQuery({ pollInterval: 15000 });
    const history = useAppHistory();

    const validCardsForPage = useMemo(() => data?.allCallCenterJobs ?? [], [data]);

    const { prospects, preQualified, qualified, reminder, recovery } = useMemo(
        () => validCardsForPage.reduce<CardColumns>(sortCardsToColumns, emptyColumns),
        [validCardsForPage]
    );

    return (
        <NavbarPage hideNavbar >
            <FlatNavbar title="Call Center"/>
            <div id="cc-page">
                <div id="cc-page-content" style={{ width: PAGE_WIDTH, fontSize: targetPageFontSize }}>
                    <div
                        className="margin-bottom-sm padding-side-xxsm flex-row flex-space-between"
                        style={{width: "98vw"}}
                    >
                        <FlatButton
                            variant="contained"
                            color="primary"
                            onClick={() => history.push(callCenterSchedulerPath)}
                        >
                            Schedule
                        </FlatButton>

                        <FlatButton
                            variant="contained"
                            onClick={() => history.push(callCenterScriptsPath)}
                            color="primary"
                        >
                            Scripts
                        </FlatButton>
                    </div>

                    <div
                        style={{ width: "98vw"}}
                        className="flex-row"
                    >
                        <RecoveryCenterColumn
                            id="cc-column-header-prospects"
                            title="PROSPECTS"
                        >
                            {prospects.map((card) => (
                                <CallCenterCard
                                    key={card.id}
                                    headerDividerClassName="customer-bar-prospects"
                                    card={card}
                                />
                            ))}
                        </RecoveryCenterColumn>
                        <RecoveryCenterColumn
                            title="PRE-QUALIFIED"
                            id="cc-column-header-pre-qualified"
                        >
                            {preQualified.map((card) => (
                                <CallCenterCard
                                    key={card.id}
                                    headerDividerClassName="customer-bar-pre-qualified"
                                    card={card}
                                />
                            ))}
                        </RecoveryCenterColumn>
                        <RecoveryCenterColumn
                            title="QUALIFIED"
                            id="cc-column-header-qualified"
                        >
                            {qualified.map((card) => (
                                <CallCenterCard
                                    key={card.id}
                                    headerDividerClassName="customer-bar-qualified"
                                    card={card}
                                />
                            ))}
                        </RecoveryCenterColumn>
                        <RecoveryCenterColumn
                            title="REMINDER"
                            id="cc-column-header-reminder"
                        >
                            {reminder.map((card) => (
                                <CallCenterCard
                                    key={card.id}
                                    headerDividerClassName="customer-bar-reminder"
                                    card={card}
                                />
                            ))}
                        </RecoveryCenterColumn>
                        <RecoveryCenterColumn
                            title="RECOVERY"
                            id="cc-column-header-recovery"
                        >
                            {recovery.map((card) => (
                                <CallCenterCard
                                    key={card.id}
                                    headerDividerClassName="customer-bar-recovery"
                                    card={card}
                                />
                            ))}
                        </RecoveryCenterColumn>
                    </div>
                </div>
            </div>
        </NavbarPage>
    );
}

interface CardColumns {
    prospects: CallCenterJob[];
    preQualified: CallCenterJob[];
    qualified: CallCenterJob[];
    reminder: CallCenterJob[];
    recovery: CallCenterJob[];
}

const emptyColumns: CardColumns = {
    prospects: [],
    preQualified: [],
    qualified: [],
    reminder: [],
    recovery: [],
};

function sortCardsToColumns(previousValue: CardColumns, job: CallCenterJob): CardColumns {
    var cards = { ...previousValue };

    if (isNullOrUndefined(job.appointment)) {
        cards.prospects = [...previousValue.prospects, job];
    } else if (job.isInRecovery) {
        cards.recovery = [...previousValue.recovery, job];
    } else if (job.isQualified === false) {
        cards.preQualified = [...previousValue.preQualified, job];
    } else if (
        job.appointment?.remindToday === true &&
        isNullOrUndefined(job.appointment.timeReminderSent)
    ) {
        cards.reminder = [...previousValue.reminder, job];
    } else if (isNullOrUndefined(job.appointment?.timeToSendReminder)) {
        cards.qualified = [...previousValue.qualified, job];
    }

    return cards;
}
