import S3QuotePdfDialogWrapper from "Components/Files/S3QuotePdfDialogWrapper";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import FlatDocumentButton from "FlatComponents/Button/FlatDocumentButton";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { useGetQuoteQuery } from "generated/graphql";
import { useNumericIdParam } from "Globals/Hooks";
import JobSvgViewer from "Pages/Home/ContractorHome/InstallJob/JobSvgViewer";
import { useState } from "react";
import { RoomSvgContainer } from "./ContractDrawingViewer";

export default function QuoteDrawingViewer() {
    const { id: quoteId } = useNumericIdParam();
    const {data: quoteData } = useGetQuoteQuery({
        variables: {quoteId: quoteId!},
        skip: (quoteId ?? -1) < 1}
    );
    const jobConfigurationId = quoteData?.quote.jobConfigurationId ?? -1;
    const quoteNum = quoteData?.quote.quoteNumber ?? "";
    const areas = quoteData?.quote.areas ?? [];
    const [openedQuoteId, setOpenedQuoteId] = useState<number | undefined>(undefined);

    const header = ( 
        <span className="flex-row flex-gap-sm align-items-center">
            Drawing Q-{quoteNum}
            <FlatDocumentButton
                color="var(--flat-blue)"
                onClick={() => setOpenedQuoteId(quoteId)}
            />
        </span>
    );

    return (<>
        <NavbarPage title={header} style={{padding: "0 2.5%"}}>
            {jobConfigurationId > 0 ? (
                <div className="flex-column">
                    <div className="padding-top-sm margin-bottom-sm">
                        <FlatSection header={"Full Drawing"}>
                            <JobSvgViewer jobConfigurationId={jobConfigurationId}/>
                        </FlatSection>
                    </div>

                    <FlatSection
                        header="Individual Rooms"
                        collapsible
                        defaultClosed
                        className="fill-width margin-bottom-md"
                    >
                        <div className="grid-50-50 flex-gap-sm padding-top-sm">
                            {areas.flatMap((a, idx) =>
                                a.rooms.map((r) => (
                                    <RoomSvgContainer
                                        key={r.id}
                                        room={r}
                                        areaIndex={idx}
                                    />
                                ))
                            )}
                        </div>
                    </FlatSection>
                </div>
            ) : (
                <Loading />
            )}
        </NavbarPage>
        
        {openedQuoteId && (
            <S3QuotePdfDialogWrapper
                quoteId={openedQuoteId}
                onClose={() => setOpenedQuoteId(undefined)}
            />
        )}
    </>)
}