import { Address, InventoryEntry, Vehicle } from "generated/graphql";
import { CustomerInfo } from "./DataStructures/Project/CustomerInfo";
import { InvoiceInfo } from "./DataStructures/Project/InvoiceInfo";
import { isNotNullOrUndefined } from "./GenericValidators";

export enum UnitType {
  MeasureUnit,
  StockUnit,
  OrderUnit
}

export function formatFeetIn(value: number, roundToXInches: number = 1) {
  const wholePart = Math.floor(value);
  let decimalPart = value - wholePart;
  decimalPart = Math.round(decimalPart * (12 / roundToXInches)) / 12;

  return `${wholePart}' ${decimalPart}`
}

export function formatProductNameString({ sku, style, color }: { sku: string, style: string, color: string }): string {
  return `${sku}: ${style} (${color})`
}

export function formatPotential(entry: InventoryEntry, targetUnit: UnitType) {
  switch (targetUnit) {
    case UnitType.MeasureUnit:

      break;
  }
}

export function formatNameStringFirstLast(props: { firstName?: string, lastName?: string } | undefined, separator?: string) {
  if (props?.lastName === undefined || props?.firstName === undefined) return ""
  else return `${props.firstName}${separator ?? " "}${props.lastName}`
}

export function formatNameStringLastFirst(props: { firstName?: string, lastName?: string } | undefined) {
  if (props?.lastName === undefined || props?.firstName === undefined) return ""
  else return `${props.lastName}, ${props.firstName}`
}

export function formatCustomerString(customerInfo: CustomerInfo) {
  const { fName, lName, phone } = customerInfo;
  return `${fName} ${lName} (${phone})`;
}

export function formatInvoiceString(invoice: InvoiceInfo) {
  const { invoiceNumber, customer } = invoice;
  return `${invoiceNumber}: ${formatCustomerString(customer)}`
}

export function formatDollarAmount(value?: number, hideDecimal?: boolean) {
  if (value === undefined || isNaN(value)) return "$ -"
  else return `$ ${value.toFixed(hideDecimal ? 0 : 2)}`
}

const monthAbbrev = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "June", "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."]
const monthFull = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export function formatMonthYear(date: Date) {
  return `${monthFull[date.getMonth()]} ${date.getFullYear()}`
}

export function formatSimpleDate(date: Date) {
  return `${monthAbbrev[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export function formatPhoneNumber(value?: string) {
  if (!value) return value;
  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
  )}-${phoneNumber.slice(6, 10)}`;
}

export function formatRawPhoneNumber(value?: string) {
  if (!value) return '';

  // clean the input for any non-digit values.
  return value.replace(/[^\d]/g, '');
}

// if the passed number is an int, return a string with no decimals, otherwise formats the number with nDecimals
export function formatAsIntOrNDecimals(num: number, nDecimals: number = 2) {
  if (Number.isInteger(num)) return num.toString();
  return num.toFixed(nDecimals);
}

export function formatAddress(address: Address) {
  const streetAddress = address.streetAddress;
  const city = address.city;
  const zip = address.zip;
  const apartmentNumber = address.apartmentNumber;
  const addressStr = `${streetAddress},${apartmentNumber ? ` apt. ${apartmentNumber},` : ''} ${city}, ${zip}`;

  return addressStr;
}

export function formatUnitAmount(amount: number | string, unit: string){
  if(unit === "lnft") return (+amount).toFixed(1)
  else return (+amount).toFixed(0)
}

export function formatVehicle(vehicle?: Vehicle) {
  if (!vehicle) return vehicle;
  return [vehicle?.description, vehicle?.year, vehicle?.licensePlateNumber, vehicle?.condition].filter(v => isNotNullOrUndefined(v)).join(" | ");
}

export function formatEIN(ein?: string) {
  if (!ein) return ein;
  return `${ein.slice(0, 2)} ${ein.slice(2, 6)} ${ein.slice(6, 10)}`
}