import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Checkbox, FormControlLabel, IconButton } from "@material-ui/core";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useState } from "react";

interface ProductTypeCheckBoxProps {
    someRoomsSelected: boolean;
    allRoomsSelected: boolean;
    pTypeHasStagedRoom: boolean;
    label: ReactJSXElement;
    productTypeId: number;
    onTogglePtype: (pTypeId: number) => void;
    children: ReactJSXElement[];
    disabled?: boolean;
}

export default function ProductTypeCheckBox({
    someRoomsSelected,
    allRoomsSelected,
    label,
    pTypeHasStagedRoom,
    productTypeId,
    onTogglePtype,
    children: roomCheckboxes,
    disabled
}: ProductTypeCheckBoxProps) {
    const [showRooms, setShowRooms] = useState(false);

    return (
        <div className="flex-column" key={productTypeId}>
            <div className="flex-row">
                <FormControlLabel
                    control={
                        <Checkbox
                            className="flat-font"
                            value={productTypeId}
                            checked={allRoomsSelected}
                            indeterminate={someRoomsSelected}
                            onChange={(_) => {
                                onTogglePtype(productTypeId);
                                setShowRooms(false);
                            }}
                            disabled={disabled}
                            style={{color: disabled ? "var(--flat-gray-4)" : "black"}}
                            size="small"
                        />
                    }
                    label={label}
                />

                {pTypeHasStagedRoom && (
                    <>
                        {showRooms ? (
                            <IconButton
                                className="margin-left-xsm"
                                onClick={() => setShowRooms(false)}
                            >
                                <KeyboardArrowRightIcon style={{rotate: "0.25turn"}} fontSize="small" htmlColor="black"/>
                            </IconButton>
                        ) : (
                            <IconButton
                                className="margin-left-xsm"
                                onClick={() => setShowRooms(true)}
                            >
                                <KeyboardArrowRightIcon fontSize="small" htmlColor="black"/>
                            </IconButton>
                        )}
                    </>
                )}
            </div>

            <div style={(pTypeHasStagedRoom && showRooms) ? {} : { display: "none" }}>
                {roomCheckboxes}
            </div>
        </div>
    );
}
