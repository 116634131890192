import {
    BASE_MOLDING_ID,
    COVE_BASE_MOLDING_MATERIAL_CATEGORY_ID,
    PRIMED_BASE_MOLDING_MATERIAL_CATEGORY_ID,
    PRIMED_SHOE_MOLDING_MATERIAL_CATEGORY_ID,
    SHOE_MOLDING_ID,
    UNFINISHED_BASE_MOLDING_MATERIAL_CATEGORY_ID,
    UNFINISHED_SHOE_MOLDING_MATERIAL_CATEGORY_ID
} from "Globals/globalConstants";
import { numericArraysEq } from "Globals/Helpers";
import { RoomIDAndServicesForRoom } from "../EditInstallationServicesMenu";

export function mapRoomsToFinishMaterials(services: RoomIDAndServicesForRoom[]): {[roomId: number]: number[]} {
    const roomsToMaterialIds: {[roomId: number]: number[]} = {};
    services.forEach(s => {
        if (Object.keys(roomsToMaterialIds).includes(s.roomId.toString())) {
            roomsToMaterialIds[s.roomId].push(s.materialCategoryId!);
        } else {
            roomsToMaterialIds[s.roomId] = [s.materialCategoryId!];
        }
    });

    return roomsToMaterialIds;
}

export function allRoomsHaveSameServices(services: RoomIDAndServicesForRoom[]) {
    const materialLists = Object.values(mapRoomsToFinishMaterials(services));
    if (materialLists.length < 2) return true;

    return materialLists.slice(1).every(matList => numericArraysEq(materialLists[0], matList));
}

export const materialIdToName: {[key: number]: string} = {
    [PRIMED_SHOE_MOLDING_MATERIAL_CATEGORY_ID]: "Prime Shoe Molding",
    [PRIMED_BASE_MOLDING_MATERIAL_CATEGORY_ID]: "Prime Baseboard",
    [UNFINISHED_SHOE_MOLDING_MATERIAL_CATEGORY_ID]: "Unifinished Shoe",
    [UNFINISHED_BASE_MOLDING_MATERIAL_CATEGORY_ID]: "Unifinished Baseboard",
    [COVE_BASE_MOLDING_MATERIAL_CATEGORY_ID]: "Cove Base"
};


export function newWallFinishMaterialCategoryToJobServiceId(materialCategoryId: number) {
    switch (materialCategoryId) {
        case PRIMED_SHOE_MOLDING_MATERIAL_CATEGORY_ID:
        case UNFINISHED_SHOE_MOLDING_MATERIAL_CATEGORY_ID:
            return SHOE_MOLDING_ID;
        case PRIMED_BASE_MOLDING_MATERIAL_CATEGORY_ID:
        case UNFINISHED_BASE_MOLDING_MATERIAL_CATEGORY_ID:
        case COVE_BASE_MOLDING_MATERIAL_CATEGORY_ID:
            return BASE_MOLDING_ID;
        default:
            throw new Error(`Material category id ${materialCategoryId} does not correspond to any job service related to new wall finish`)
    }
}