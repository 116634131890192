import { Typography } from "@material-ui/core";
import { GetJobSearchResultsQuery, useGetJobSearchResultsLazyQuery } from "generated/graphql";
import { dateTimeStrToMdy } from "Globals/DateAndTimeHelpers";
import { isNotEmptyString } from "Globals/GenericValidators";
import { commandCenterPricingPath, jobTrackerPath, readOnlyPricingCenterPath, recoveryCenterPricingPath } from "Globals/PathStrings";
import { formatPhoneNumber, formatNameStringLastFirst } from "Globals/StringFormatting";
import { useState } from "react";
import SearchBarInput from "./SearchBarInput";
import "./search_bar.css";

type JobSearchResult = GetJobSearchResultsQuery['jobSearchResults'][number];

interface JobSearchBarProps {
    className?: string;
    containerStyle?: React.CSSProperties;
    openingFrom: "cc" | "rc" | "pc" // context about which department is using this
}

export default function JobSearchBar({className, containerStyle, openingFrom}: JobSearchBarProps) {
    const pricingCenterPath = (openingFrom === 'cc') ? commandCenterPricingPath :
                              (openingFrom === "rc") ? recoveryCenterPricingPath : readOnlyPricingCenterPath;
    const [searchStr, setSearchStr] = useState('');
    const [searchResultsData, setSearchResultsData] = useState<GetJobSearchResultsQuery['jobSearchResults'] | null>(null);

    const [search, {loading}] = useGetJobSearchResultsLazyQuery({
        onError: () => alert("Could not perform job search"),
        fetchPolicy: "no-cache",
        onCompleted: (data) => setSearchResultsData(data.jobSearchResults)
    });

    function onSearch() {
        const trimmedSearch = searchStr.trim();
        if (isNotEmptyString(trimmedSearch)) {
            search({variables: {searchStr: trimmedSearch}});
        }
    }

    const searchResults = searchResultsData?.map(job => (
        <JobSearchResultTab
            key={job.id}
            pricingCenterPath={pricingCenterPath}
            jobInfo={job}
        />
    )) ?? null;

    return (
        <div className={className} style={{...containerStyle}}>
            <SearchBarInput 
                searchStr={searchStr} setSearchStr={setSearchStr}
                onSearch={onSearch} loadingResults={loading}
                searchResults={searchResults} clearSearchResults={() => setSearchResultsData(null)}
            />
        </div>
    )
}

function JobSearchResultTab({jobInfo, pricingCenterPath}: {jobInfo: JobSearchResult, pricingCenterPath: string}) {
    const fName = jobInfo.firstName;
    const lName = jobInfo.lastName;
    const phoneNum = jobInfo.phoneNumber ? formatPhoneNumber(jobInfo.phoneNumber) : null;
    const city = jobInfo.city;
    const sahNum = jobInfo.sahNumber;
    const contractDate = jobInfo.contractDate;
    const hasContract = sahNum && contractDate;
    const jobConfigurationId = jobInfo.jobConfigurationId;

    function onClick() {
        if (hasContract) {
            window.open(`${jobTrackerPath}/${jobInfo.jobContractId}`);
        } else {
            window.open(`${pricingCenterPath}/${jobConfigurationId}`);
        }
    }

    return (
        <div onClick={onClick}>
            <Typography style={{fontWeight: "bold"}}>{formatNameStringLastFirst({firstName: fName, lastName: lName})}</Typography>
            {phoneNum && <Typography>{phoneNum}</Typography>}
            <Typography>{city}</Typography>
            {(sahNum && contractDate) && <Typography>SAH-{sahNum} - {dateTimeStrToMdy(contractDate)}</Typography>}
        </div>
    )
}