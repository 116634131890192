import clsx from "clsx";
import _ from "lodash";
import { ComponentType, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
    addDataToPnPTable,
    CellAndLocation,
    reinitializePnPTable,
    selectMaxRowIndex,
    TypedPickNPlaceCell,
} from "Redux/pickNPlaceReducer";
import PickNPlaceCell, { ShadedPickNPlaceCell } from "./PickNPlaceCell";
import "./pick_n_place.css";

type CSSColor = string;

export const UNSCHEDULED_APPOINTMENT_TABLE = "unscheduled";
export const ASSIGNED_APPOINTMENT_TABLE = "assigned";

export const PICK_N_PLACE_COLOR = "#0047ab";

interface PickNPlaceTableProps<T> {
    tableName: string;
    columns?: (JSX.Element | string)[];
    rows?: (JSX.Element | string)[];
    data: T[];
    columnWidth?: string;
    assignData: (data: T) => { colIndex: number; rowIndex?: number } | undefined;
    RenderCell: ComponentType<TypedPickNPlaceCell<T>>;
    shadeCellWhenOtherPicked?: (
        selectedCell: CellAndLocation<any>,
        currentCell: CellAndLocation<T>
    ) => CSSColor | undefined;
    canPlaceAtCell?: (
        selectedCell: CellAndLocation<any>,
        currentCell: CellAndLocation<T | undefined>
    ) => boolean;
    canPickCell?: (cell: CellAndLocation<T>) => boolean;
    onCellPlaced?: (oldCell: CellAndLocation<any>, newCell: CellAndLocation<T>) => void;
    onRightClicked?: (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        cell: CellAndLocation<T | undefined>
    ) => void;
    shouldHighlightCell?: (cell: CellAndLocation<any>) => boolean;
    hideHeaderRow?: boolean;
    cellBackgroundColor?: string;
}

export default function PickNPlaceTable<T>({
    tableName,
    columns,
    rows,
    data,
    columnWidth,
    assignData,
    RenderCell,
    shadeCellWhenOtherPicked,
    canPlaceAtCell,
    canPickCell,
    onCellPlaced,
    onRightClicked,
    shouldHighlightCell,
    hideHeaderRow,
    cellBackgroundColor,
}: PickNPlaceTableProps<T>) {
    const dispatch = useAppDispatch();

    var actualRows = rows?.length ?? 1;
    var actualColumns = columns?.length ?? 0;

    const width = "10em";
    const adjustedWidth = `calc(${width} - 1px)`;

    const maxDataRow = Math.max(useAppSelector(selectMaxRowIndex(tableName)) + 1, actualRows);

    var rowRange = [..._.range(maxDataRow)];
    var colRange = [..._.range(actualColumns)];

    useEffect(() => {
        dispatch(reinitializePnPTable(tableName, rows?.length ?? 0));

        data.forEach((d) => {
            const location = assignData(d);
            if (location !== undefined) {
                const { colIndex, rowIndex } = location;
                dispatch(addDataToPnPTable(d, tableName, colIndex, rowIndex));
            }
        });
    }, [data, rows, assignData, dispatch, tableName]);

    const headerBackgroundColor =
        hideHeaderRow === true ? "var(--flat-gray-6)" : "var(--flat-gray-5)";

    return (
        <div style={{ position: "relative" }}>
            <table style={{ position: "relative" }}>
                {/* So that the chat renders on top of the thead.*/}
                {/* You might ask, "Why does setting opacity: 0.99 make the chat render over top?" - https://philipwalton.com/articles/what-no-one-told-you-about-z-index/ */}
                <thead style={{ opacity: 0.99 }}>
                    <tr>
                        <td
                            style={{
                                minWidth: width,
                                width,
                                position: "sticky",
                                top: 0,
                                zIndex: 1000,
                                backgroundColor: headerBackgroundColor,
                            }}
                        ></td>
                        {columns?.map((col) =>
                            hideHeaderRow === true ? (
                                <td
                                    key={`${tableName}-${col}`}
                                    style={{
                                        minWidth: adjustedWidth,
                                        width: adjustedWidth,
                                        height: "6px",
                                        backgroundColor: headerBackgroundColor,
                                    }}
                                ></td>
                            ) : (
                                <td
                                    key={`${tableName}-${col}`}
                                    style={{
                                        width: adjustedWidth,
                                        minWidth: adjustedWidth,
                                        textAlign: "center",
                                        color: "white",
                                        height: "40px",
                                        position: "sticky",
                                        top: 0,
                                        zIndex: 1000,
                                        backgroundColor: headerBackgroundColor,
                                    }}
                                >
                                    <div
                                        style={{ margin: ".5em 0", borderLeft: "solid white 1px" }}
                                    >
                                        {col}
                                    </div>
                                </td>
                            )
                        )}
                    </tr>
                </thead>
                <tbody>
                    {rowRange.map((rowIndex) => {
                        const isAppendedRow = rowIndex >= actualRows;

                        const isUnassigned = rows?.[rowIndex] === undefined;
                        const isLastRow = rowIndex === rowRange.length - 1;

                        return (
                            <tr
                                key={`${tableName}, r-${rowIndex}`}
                                style={{
                                    backgroundColor: "white",
                                    height: "5em",
                                    maxHeight: "5em",
                                }}
                                className={clsx("cell-border-side", {
                                    "cell-border-bottom": !isUnassigned && !isLastRow,
                                })}
                            >
                                {isUnassigned ? (
                                    rowIndex === 0 && (
                                        <td
                                            className="flat-font-sm"
                                            style={{
                                                minWidth: adjustedWidth,
                                                width: adjustedWidth,
                                                textAlign: "center",
                                                height: "1px",
                                                backgroundColor: "var(--flat-faded-red)",
                                                textTransform: "uppercase",
                                                fontSize: "12pt",
                                            }}
                                            rowSpan={rowRange.length}
                                        >
                                            Unassigned
                                        </td>
                                    )
                                ) : (
                                    <td
                                        className="flat-font-sm"
                                        style={{
                                            minWidth: adjustedWidth,
                                            width: adjustedWidth,
                                            textAlign: "center",
                                            height: "5rem",
                                            padding: 0,
                                            backgroundColor: "var(--flat-gray-4)",
                                        }}
                                    >
                                        {rows?.[rowIndex]}
                                    </td>
                                )}

                                {colRange.map((colIndex) => {
                                    if (shadeCellWhenOtherPicked !== undefined) {
                                        return (
                                            <ShadedPickNPlaceCell
                                                key={`${tableName}, ${colIndex}, ${rowIndex}`}
                                                tableName={tableName}
                                                colIndex={colIndex}
                                                rowIndex={rowIndex}
                                                isInAppendedRow={isAppendedRow}
                                                RenderCell={RenderCell}
                                                shadeCellWhenOtherPicked={shadeCellWhenOtherPicked}
                                                canPlaceAtCell={canPlaceAtCell}
                                                canPickCell={canPickCell}
                                                onCellPlaced={onCellPlaced}
                                                onRightClicked={onRightClicked}
                                                shouldHighlightCell={shouldHighlightCell}
                                                cellBackgroundColor={cellBackgroundColor}
                                            />
                                        );
                                    } else {
                                        return (
                                            <PickNPlaceCell
                                                key={`${tableName}, ${colIndex}, ${rowIndex}`}
                                                tableName={tableName}
                                                colIndex={colIndex}
                                                rowIndex={rowIndex}
                                                isInAppendedRow={isAppendedRow}
                                                RenderCell={RenderCell}
                                                canPlaceAtCell={canPlaceAtCell}
                                                canPickCell={canPickCell}
                                                onCellPlaced={onCellPlaced}
                                                onRightClicked={onRightClicked}
                                                shouldHighlightCell={shouldHighlightCell}
                                                cellBackgroundColor={cellBackgroundColor}
                                            />
                                        );
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
