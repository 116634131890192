import { IconButton, Input, MenuItem, Select } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { boundElementToValues } from "Globals/Helpers";
import { PropsWithChildren } from "react";

const unselectedId = -1
export const manualInputId = -2

type TypeableSelectProps = PropsWithChildren<{
    selectedId: number,
    typedValue: string,
    setValue: (id?: number, value?: string) => void,
    options: { id: number, value: string }[],
    revertToId?: number
    disabled?: boolean
    unselectedText?: string,
    manualMode?: string,
    name?: string
}>

export function TypeableSelect({ selectedId, typedValue, setValue, options, revertToId, disabled, unselectedText, manualMode, name }: TypeableSelectProps) {

    const isManual = selectedId === manualInputId

    //When is selector mode, validates that the selected id is in an option included in the option list
    const boundedId = (!isManual) ? boundElementToValues(selectedId, [unselectedId, manualInputId, ...options.map(o=>o.id)]) : manualInputId
    //if(boundedId !== selectedId) setValue?.(boundedId, typedValue)

    const renderId = (boundedId !== selectedId) ? "" : boundedId

    function changeSelect(changeEvent: { target: { value: unknown } }) {
        const newId: number = changeEvent.target.value as number
        if (newId === manualInputId) setValue?.(newId, "")
        else setValue?.(newId)
    }

    function changeTyped(changeEvent: { target: { value: string } }) {
        setValue?.(undefined, changeEvent.target.value)
    }

    function cancelManual() {
        setValue?.(revertToId ?? unselectedId, "")
    }

    return (
        <div className="fill-width">
            {
                !isManual &&
                <Select
                    className="fill-width"
                    value={renderId}
                    name={name}
                    disabled={disabled}
                    onChange={changeSelect}>
                    {revertToId === undefined && <MenuItem value={unselectedId}>{unselectedText ?? "Unselected"}</MenuItem>}
                    {options?.map((elem, index) => <MenuItem value={elem.id} key={`typeable-selector-${index}`}>{elem.value}</MenuItem>)}
                    <MenuItem value={manualInputId}>{manualMode ?? "Add New"}</MenuItem>
                </Select>
            }
            {
                isManual &&
                <Input
                    className="fill-width"
                    autoFocus
                    error={options.some(op=>op.value === typedValue)}
                    value={typedValue}
                    onChange={changeTyped}
                    disabled={disabled}
                    endAdornment={
                        <IconButton size='small' onClick={cancelManual}>
                            <ClearIcon />
                        </IconButton>
                    }
                />
            }
        </div>
    )
}