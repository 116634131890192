import { debounce, IconButton, TextareaAutosize } from "@material-ui/core";
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalPhoneOutlinedIcon from "@material-ui/icons/LocalPhoneOutlined";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import clsx from "clsx";
import {
    GetAllRecoveryLedgerItemsQuery, namedOperations, useAddInternalJobNotesMutation,
    useDeleteInternalJobNotesMutation,
    useDeleteRecoveryCommunicationMutation,
    useGetAllRecoveryLedgerItemsQuery, useUpdateCallCenterActionMutation, useUpdateInternalJobNotesMutation,
    useUpdateRecoveryCommunicationMutation
} from "generated/graphql";
import {
    dateTimeStrToDate,
    dateTimeStrToHHMM12HR,
    dateToPaddedMdy
} from "Globals/DateAndTimeHelpers";
import { useEffect, useMemo, useRef, useState } from "react";
import {
    RECOVERY_COMMUNICATION_OPTION_PHONE_ID,
    RECOVERY_COMMUNICATION_OPTION_TEXT_ID
} from "./ClaimedRecoveryCenterCard";
import './ledger.css';

interface RecoveryLedgerProps {
    jobId: number;
}

function RecoveryLedger({ jobId }: RecoveryLedgerProps) {
    const [addNote] = useAddInternalJobNotesMutation({
        refetchQueries: [namedOperations.Query.GetAllRecoveryLedgerItems],
    });
    const { data } = useGetAllRecoveryLedgerItemsQuery({
        variables: { jobId },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
    });
    const ledgerItems = data?.allRecoveryLedgerItems ?? [];

    function addNewNote() {
        addNote({ variables: { jobId } });
    }

    return (
        <div
            className="flex-column"
            style={{
                height: "fit-content",
                maxHeight: "30rem",
                minWidth: "40rem",
                maxWidth: "40rem",
                alignItems: "flex-end",
            }}
        >
            <table className="fill-width ledger-table">
                <thead>
                    <tr>
                        <th></th>
                        <th className="text-align-center">Action</th>
                        <th className="text-align-center">Date</th>
                        <th className="text-align-center">Time</th>
                        <th className="padding-left-xsm">
                            <span className="flex-row align-items-center">
                                Notes{" "}
                                <button id="ledger-add-note-button" onClick={addNewNote} />
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {ledgerItems.map((item, idx) => (
                        <LedgerRow
                            key={`ledger-row-${idx}`}
                            item={item}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

function LedgerRow({
    item,
}: {
    item: GetAllRecoveryLedgerItemsQuery["allRecoveryLedgerItems"][number];
}) {
    const commId = item.communicationId ?? -1;
    const interId = item.internalNotesId ?? -1;
    const callActionId = item.callCenterActionId ?? -1;

    const isCommunication: boolean = !isNaN(+item.action);
    const [notes, setNotes] = useState(item.notes);

    const [updateCallCenterActionMutation] = useUpdateCallCenterActionMutation();

    const [updateCommMutation] = useUpdateRecoveryCommunicationMutation();
    const [deleteCommMutation] = useDeleteRecoveryCommunicationMutation({
        refetchQueries: [namedOperations.Query.GetAllRecoveryLedgerItems],
    });

    const [updateInterMutation] = useUpdateInternalJobNotesMutation();
    const [deleteInterMutation] = useDeleteInternalJobNotesMutation({
        refetchQueries: [namedOperations.Query.GetAllRecoveryLedgerItems],
    });

    const onUnmount = useRef<() => void>();
    onUnmount.current = () => {
        if (item.notes !== notes) {
            updateNotesMutation(notes);
        }
    };

    const updateNotesMutation = useMemo(
        () => (newNotes: string) => {
            if (commId !== -1) {
                updateCommMutation({
                    variables: { recoveryCommunicationOptionId: commId, notes: newNotes },
                });
            } else if (interId !== -1) {
                updateInterMutation({
                    variables: { internalJobNotesId: interId, notes: newNotes },
                });
            } else if (callActionId !== -1) {
                updateCallCenterActionMutation({
                    variables: { callCenterActionId: callActionId, notes: newNotes },
                });
            }
        },
        [commId, interId, callActionId, updateCommMutation, updateInterMutation, updateCallCenterActionMutation]
    );

    function updateNotes(newValue: string) {
        setNotes(newValue);
        debouncedUpdateNotes(newValue);
    }

    function deleteRow() {
        if (commId !== -1) {
            deleteCommMutation({ variables: { recoveryCommunicationOptionId: commId } });
        } else if (interId !== -1) {
            deleteInterMutation({ variables: { internalJobNotesId: interId } });
        }
    }

    const debouncedUpdateNotes = useMemo(
        () =>
            debounce((value: string) => {
                updateNotesMutation(value);
            }, 1000),
        [updateNotesMutation]
    );

    useEffect(() => {
        return () => {
            debouncedUpdateNotes.clear();
            onUnmount.current?.();
        };
    }, [debouncedUpdateNotes]);

    return (
        <tr style={{ height: "auto" }}>
            <td
                align="center"
                style={{ width: "2rem", minHeight: "2rem" }}
            >
                <IconButton
                    className={clsx({ "visibility-hidden": !item.isDeletable })}
                    onClick={deleteRow}
                >
                    <DeleteIcon htmlColor="black"/>
                </IconButton>
            </td>
            <td className="text-align-center">
                <div className="flex-column align-items-center">
                    {item.action}
                    {isCommunication && (
                        <MethodIcon iconId={+item.action} />
                    )}
                </div>
            </td>
            <td className="text-align-center">
                {dateToPaddedMdy(dateTimeStrToDate(item.timestamp))}
            </td>
            <td className="text-align-center">
                {item.showTime ? dateTimeStrToHHMM12HR(item.timestamp) : "-"}
            </td>
            <td style={{ padding: 0, position: "relative" }}>
                <div className="flex-row">
                    <TextareaAutosize
                        value={notes}
                        onChange={(e) => updateNotes(e.currentTarget.value)}
                        minRows={2}
                        disabled={!item.isEditable}
                        className="fill-width unset-border flat-textarea"
                    />
                </div>
                <div
                    style={{
                        position: "absolute",
                        right: ".7em",
                        bottom: 0,
                        fontSize: ".7em",
                        fontStyle: "italic",
                        fontWeight: "500",
                        fontFamily: "var(--theme-main-text-font-family)",
                        userSelect: "none",
                        msUserSelect: "none",
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                    }}
                >
                    {item.worker}
                </div>
            </td>
        </tr>
    );
}

function MethodIcon({ iconId }: { iconId: number }) {
    switch (iconId) {
        case RECOVERY_COMMUNICATION_OPTION_PHONE_ID:
            return <LocalPhoneOutlinedIcon fontSize="small"/>;
        case RECOVERY_COMMUNICATION_OPTION_TEXT_ID:
            return <TextsmsOutlinedIcon fontSize="small"/>;
        default:
            return <AlternateEmailOutlinedIcon fontSize="small"/>;
    }
}

export default RecoveryLedger;
