import { IconButton, Input, MenuItem, Select } from "@material-ui/core"
import { ServiceIdValue } from "./ServicesByTypeEditor"
import ClearIcon from '@material-ui/icons/Clear';
import { isNumber } from "Globals/GenericValidators";
import MaterialForServiceColumns from "./MaterialEditors/MaterialForServiceColumns";
import { JobServiceOption, ServiceMaterialCategoryOption } from "generated/graphql";

interface JobServiceEditorRowProps {
    showError?: boolean,
    value: ServiceIdValue,
    setValue: (newValue?: string, custDoesService?: boolean, material?: { newMatId?: number, newMatAmount?: string }) => void,
    removeValue: () => void
    options: JobServiceOption[]
}

function sortMaterialOptionList(options: ServiceMaterialCategoryOption[])
    : ServiceMaterialCategoryOption[] {
    const materialOptions = [...options].sort(op => op.isDefaultMaterial ? -1 : 1)

    return materialOptions
}

export default function JobServiceEditorRow({ showError, value, setValue, removeValue, options }: JobServiceEditorRowProps) {
    const dispValue = options.find(op => op.jobServiceId === value.jobServiceId)

    const materialOptions = sortMaterialOptionList(dispValue?.materialOptions ?? [])
    const selectedMaterial = materialOptions.find(mat => mat.materialCategoryId === value.materialCategoryId)
    const hideMaterialAmount = dispValue?.laborPricingUnit !== "each" && dispValue?.laborPricingUnit === selectedMaterial?.materialPriceUnit

    function updateLaborAmount(newAmount: string) {
        if (hideMaterialAmount) {
            setValue(newAmount, undefined, { newMatId: value.materialCategoryId, newMatAmount: newAmount })
        }
        else setValue(newAmount)
    }

    function updateWhoDoes(custDoes: boolean) {
        setValue(undefined, custDoes)
    }

    return (
        <tr>
            <td className="w-15r">{dispValue?.description}</td>
            <td className="w-11r padding-side-xsm">
                <Input
                    error={showError && !isNumber(value.value)}
                    value={value.value}
                    onChange={e => updateLaborAmount(e.target.value)}
                    endAdornment={dispValue?.laborPricingUnit ?? ""} />
            </td>
            <td className="w-6r padding-side-xsm">
                <Select value={value.custDoesService ? 1 : 0} onChange={(e) => updateWhoDoes((e.target.value as number) === 1)}>
                    <MenuItem value={0}>WOF Does</MenuItem>
                    <MenuItem value={1}>Cust Does</MenuItem>

                </Select>
            </td>
            {
                materialOptions.length > 0 &&
                <MaterialForServiceColumns
                    showError={showError}
                    laborUnit={dispValue?.laborPricingUnit ?? "each"}
                    value={value} //value: value.value, materialCategoryId: value.materialCategoryId, materialAmount: value.materialAmount
                    setValue={updatedMaterial => setValue(undefined, undefined, updatedMaterial)}
                    options={materialOptions} />
            }
            {
                materialOptions.length === 0 &&
                <>
                    <td></td>
                    <td></td>
                </>
            }
            <td align="right">
                <IconButton
                    size='small'
                    onClick={removeValue}
                >
                    <ClearIcon style={{ color: 'red' }} />
                </IconButton>
            </td>
        </tr>
    )
}