import { TextField } from "@material-ui/core"
import { selectVendorColor, setVendorColor } from "Redux/addProductReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"

export default function VendorColorEditor() {
    const color = useAppSelector(selectVendorColor)
    const dispatch = useAppDispatch()

    function updateValue(newValue: string) {
        dispatch(setVendorColor(newValue))
    }

    return (
        <TextField
            value={color}
            onChange={(e) => updateValue(e.target.value)}
            className="w-10r"
            margin="none"
            variant="filled"
            label="Vendor Color"
        />
    )
}
