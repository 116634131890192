import React from "react";
import "./recovery-center-components.css";

interface HeaderProps {
    id: string;
    title: string;
}

export type RecoveryCenterColumnProps = React.PropsWithChildren<HeaderProps>;

function RecoveryCenterColumn({ children, title, id }: RecoveryCenterColumnProps) {
    return (
        <div className="rc-column">
            <div className="rc-column-header-base" id={id}>
                <p className="flat-font-md margin-none">{title}</p>
            </div>

            {children}
        </div>
    );
}

export default RecoveryCenterColumn;