import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Button } from "@material-ui/core";
import { RawSahAppointmentDataInput, useAddNewSahAppointmentMutation } from "generated/graphql";
import { isEmptyString, isNotNullOrUndefined } from "Globals/GenericValidators";
import { formatRawPhoneNumber } from "Globals/StringFormatting";
import { useState } from 'react';
import { DayValue } from 'react-modern-calendar-datepicker';
import SchedulingContactInfo, { canSubmit } from "./SchedulingContactInfo";
import SchedulingDateTime, { canMoveToProductSelection } from "./SchedulingDateTime";
import SchedulingProductTypeSelector, { canMoveToContactInfo } from "./SchedulingProductTypeSelector";
import SchedulingStepper from './SchedulingStepper';
import AtomicTimeSlot from "./sched_utils/AtomicTimeSlot";
import ThanksForSchedulingAppointmentPage from "./ThanksForMakingAppointment";


export interface FormValues {
    zip: string,
    city: string,
    state: string,
    isForHome: boolean,
    day: DayValue,
    timeSlotId: number | null,
    productIds: number[],
    numRooms: number,
    firstName: string,
    lastName: string,
    businessName: string,
    primaryPhone: string,
    secondaryPhone: string,
    primaryEmail: string,
    secondaryEmail: string,
    streetAddress: string,
    apartmentSuiteNum: string,
    comments: string,
    promoCodeText: string,
    promoCodeId: number | null,
    receivePromoEmails: boolean,
    howDidYouHearAboutUsId: number | undefined
}

interface PageErrorFlags {
    page1: boolean,
    page2: boolean,
    page3: boolean
}

export type HandleValueChangeFunc = (valueName: keyof FormValues, value: boolean | string | number | DayValue | number[] | AtomicTimeSlot | null) => void

export interface SchedulingPageProps {
    formValues: FormValues;
    handleValueChange: HandleValueChangeFunc;
    errorFlag: boolean;
}

function toISO(day: DayValue): string {
    if (isNotNullOrUndefined(day)) {
        return `${day?.year}-${day?.month}-${day?.day}`
    } else {
        throw new Error("argument 'day' must not be null or undefined");
    }
}

function makeRawSAHAppointmentData(fv: FormValues): RawSahAppointmentDataInput {
    return {
        customer: {
            id: -1, // doesn't get used, but is a required field
            firstName: fv.firstName.trim(),
            lastName: fv.lastName.trim(),
            businessName: isEmptyString(fv.businessName) ? null : fv.businessName.trim(),
            primaryPhone: formatRawPhoneNumber(fv.primaryPhone.trim()),
            email: fv.primaryEmail.trim(),
            primaryAddress: {
                id: -1,  // TODO: how to know if address is not new
                streetAddress: fv.streetAddress.trim(),
                city: fv.city.trim(),
                zip: fv.zip.trim(),
                apartmentNumber: isEmptyString(fv.apartmentSuiteNum) ? null : fv.apartmentSuiteNum.trim()
            },
            receivePromoEmails: fv.receivePromoEmails,
            customerContacts: [{
                id: -1,
                customerId: -1,
                firstName: fv.firstName.trim(),
                lastName: fv.lastName.trim(),
                phone: fv.primaryPhone.trim(),
                email: fv.primaryEmail.trim(),
                receivePromoEmails: fv.receivePromoEmails,
                isSchedulingContact: true,
                isPrimary: true
            }]
        },
        isForHome: fv.isForHome,
        date: toISO(fv.day),
        marketTimeSlotId: fv.timeSlotId!,
        productTypeIds: fv.productIds,
        priorityOptionIds: [],
        colorCategoryIds: [],
        numRooms: fv.numRooms,
        internalNotes: null,
        appointmentNotes: isEmptyString(fv.comments) ? null : fv.comments.trim(),
        promoCodeId: fv.promoCodeId,
        howDidYouHearAboutUsOptionId: fv.howDidYouHearAboutUsId as number
    }
}

const defaultFormValues: FormValues = {
    zip: '',
    city: '',
    state: 'Michigan',
    isForHome: true,
    day: null,
    timeSlotId: null,
    productIds: [],
    numRooms: 1,
    firstName: '',
    lastName: '',
    businessName: '',
    primaryPhone: '',
    secondaryPhone: '',
    primaryEmail: '',
    secondaryEmail: '',
    streetAddress: '',
    apartmentSuiteNum: '',
    comments: '',
    promoCodeText: '',
    promoCodeId: null,
    receivePromoEmails: true,
    howDidYouHearAboutUsId: undefined
};

const defaultPageErrorFlags: PageErrorFlags = {
    page1: false,
    page2: false,
    page3: false
};

export const NEW_APPT_TIME_STEP = 0;
export const NEW_APPT_PRODUCT_STEP = 1;
export const NEW_APPT_CONTACT_INFO_STEP = 2;

export default function NewSAHAppointmentPage() {
    // state for moving between pages
    const [activeStep, setActiveStep] = useState(0);
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [pageErrorFlags, setPageErrorFlags] = useState(defaultPageErrorFlags);

    function handleValueChange(valueName: keyof FormValues, value: boolean | string | number | number[] | DayValue | AtomicTimeSlot | null) {
        setFormValues({ ...formValues, [valueName]: value });
    };

    const [appNumber, setAppNumber] = useState<number | null>(null);

    function onNext() {
        if (activeStep === NEW_APPT_TIME_STEP) {
            var advance = canMoveToProductSelection(formValues);
            setPageErrorFlags({ ...pageErrorFlags, page1: !advance });
            if (advance) {
                setActiveStep(NEW_APPT_PRODUCT_STEP);
            }
        } else if (activeStep === NEW_APPT_PRODUCT_STEP) {
            let advance = canMoveToContactInfo(formValues);
            setPageErrorFlags({ ...pageErrorFlags, page2: !advance });
            if (advance) {
                setActiveStep(NEW_APPT_CONTACT_INFO_STEP);
            }
        }
    }

    function onBack() {
        setActiveStep(activeStep - 1);
    };

    const [addNewSahAppointmentMutation, { loading: submitting }] = useAddNewSahAppointmentMutation();
    function onFinish() {
        if (!submitting && canSubmit(formValues)) {
            addNewSahAppointmentMutation({
                variables: { newSAHAppointment: makeRawSAHAppointmentData(formValues) },
                onCompleted(data) {
                    setAppNumber(data.addNewSAHAppointment)
                }
            });
        }
    };

    return (<>
        {
            appNumber !== null ?
                <ThanksForSchedulingAppointmentPage appointmentNumber={appNumber!} /> :
                <div className="flex-column flex-centered flex-space-around">
                    <h1>Schedule a Free In-Home Estimate</h1>
                    <h3>As easy as 1-2-3</h3>

                    <SchedulingStepper activeStep={activeStep} />

                    {activeStep === NEW_APPT_TIME_STEP && <SchedulingDateTime formValues={formValues} handleValueChange={handleValueChange} setFormValues={setFormValues} errorFlag={pageErrorFlags.page1} />}
                    {activeStep === NEW_APPT_PRODUCT_STEP && <SchedulingProductTypeSelector formValues={formValues} handleValueChange={handleValueChange} errorFlag={pageErrorFlags.page2} />}
                    {activeStep === NEW_APPT_CONTACT_INFO_STEP && <SchedulingContactInfo formValues={formValues} handleValueChange={handleValueChange} errorFlag={pageErrorFlags.page3} setFormValues={setFormValues} />}

                    <div className="margin-xsm">
                        {activeStep !== NEW_APPT_TIME_STEP && <Button onClick={onBack}>Back</Button>}
                        <Button
                            onClick={activeStep === NEW_APPT_CONTACT_INFO_STEP ? onFinish : onNext}
                        // TODO: disable the button when can't move on
                        // disabled={activeStep === 0 : (activeStep === 1 : )}
                        > {activeStep === NEW_APPT_CONTACT_INFO_STEP ? "Finish" : "Next"}</Button>
                    </div>
                </div>
        }
    </>
    )
}