import { useNumericIdParam } from "Globals/Hooks";
import CompletionCertificate from "./Home/ContractorHome/InstallJob/CompletionCertificate";

export default function CompletionCertificatePage() {
    const { id: installationAppointmentId } = useNumericIdParam();

    return (
        <div style={{ height: "95vh" }}>
            <CompletionCertificate
                installationAppointmentId={installationAppointmentId!}
                printMode
            />
        </div>
    );
}
