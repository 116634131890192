import { JobPictureGallery } from "./TypeSpecific/JobPictureGallery";
import JobPictureUploadButton from "./TypeSpecific/JobPictureUploadButton";

export interface RoomGalleryImageBaseProps {
    label: string;
    description: string;
    pictureId: number;
    pictureForRoomId: number;
}

interface RoomPictureViewerProps {
    jobConfigurationId: number;
    rowMax?: number;
    showUploadButton?: boolean;
}

export default function JobPictureViewer({
    jobConfigurationId,
    showUploadButton=true
}: RoomPictureViewerProps) {
    return (
        <div className="fill-width">
            <JobPictureGallery jobConfigurationId={jobConfigurationId} />

            {showUploadButton && (
                <div
                    className="fill-width flex-column flex-centered justify-self-center"
                    style={{ margin: "1em 0", minWidth: "15rem" }}
                >
                    <JobPictureUploadButton
                        jobConfigurationId={jobConfigurationId}
                    />
                </div>
            )}

        </div>
    );
}
