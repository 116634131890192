import { AnimatePresence } from 'framer-motion';
import { useDeleteJobConfigurationMutation } from "generated/graphql";
import { useState } from "react";
import { AlwaysScrollToEnd, QuoteCardState } from "../SellSheet";
import { QuoteAnimatedWrapper } from "./QuoteCard";

const MINIMUM_DISCOUNT_ROW_COUNT = 3;

interface QuoteRowProps {
    setSelectedLocalId: (newId: number | undefined) => void;
    selectedLocalId?: number;
    quoteStates: QuoteCardState[],
    setQuoteStates: (newStates: QuoteCardState[]) => void,
    addNewCard: (newId: number, optionNumber: number, targetIndex: number) => void,
    nextOptionNumber: number,
    showFinancingRow: boolean,
    setShowFinancingRow: (show: boolean) => void
}

export default function QuoteRow({
    selectedLocalId,
    setSelectedLocalId,
    quoteStates,
    setQuoteStates,
    addNewCard,
    nextOptionNumber,
    showFinancingRow,
    setShowFinancingRow
}: QuoteRowProps) {
    const [areaCounts, setAreaCounts] = useState<{ [configId: number]: number }>({})
    const [discountCounts, setDiscountCounts] = useState<{ [configId: number]: number }>({})
    const [deleteMutation] = useDeleteJobConfigurationMutation()

    function updateAreaCount(configId: number, areaCount: number) {
        if (areaCounts[configId] !== areaCount) {
            setAreaCounts({ ...areaCounts, [configId]: areaCount })
        }
    }

    function updateDiscountCount(configId: number, discountCount: number) {
        if (discountCounts[configId] !== discountCount) {
            setDiscountCounts({ ...discountCounts, [configId]: discountCount })
        }
    }

    function deleteQuoteCard(index: number) {
        const { jobConfigurationId, localId, isCopy } = quoteStates[index]

        if (localId === selectedLocalId) {setSelectedLocalId(undefined)}

        setQuoteStates(quoteStates.filter((_, i) => i !== index))

        if (!isCopy) {
            // Need to delete the job configuration from the database
            deleteMutation({ variables: { jobConfigurationId: jobConfigurationId } })
        }
    }
    
    const maxAreaCount = Math.max(...Object.keys(areaCounts).map(key => areaCounts[+key]))
    const maxDiscountCount = Math.max(MINIMUM_DISCOUNT_ROW_COUNT, ...Object.keys(discountCounts).map(key => discountCounts[+key]))

    return (
        <div className="ss-top-gallery ss-snaps-inline hide-scrollbar">
            <AnimatePresence initial={false}>
                {quoteStates.map((quote, index) => 
                    <QuoteAnimatedWrapper
                        key={`quote-local-id-${quote.localId}`}
                        relativeToRoot={Math.sign(0 - index)}
                        selectionPresent={quoteStates.some(qs=>qs.isSelected)}
                        toggleSelect={() => {
                            // second click on selection button enables the financing row
                            if (quote.isSelected) {
                                setShowFinancingRow(!showFinancingRow);
                                return;
                            } else {
                                setShowFinancingRow(false);

                                // removes current selection
                                const copy = quoteStates.map(state => {
                                    if (state.isSelected) return { ...state, isSelected: false }
                                    else return state
                                });

                                copy[index] = {...copy[index], isSelected: true}
                                setSelectedLocalId(copy[index].localId);
                                setQuoteStates(copy);
                            }
                        }}
                        updateQuoteCardChangesState={(newState) => {
                            const copy = [...quoteStates]
                            copy[index] = {...copy[index], productChanges: newState};

                            setQuoteStates(copy)
                        }}
                        deleteCard={() => {
                            deleteQuoteCard(index)
                        }}
                        addCard={(newId, optionNumber) => addNewCard(newId, optionNumber, index + 1)}
                        quoteState={quote}
                        isLocalCopy={index > 0}
                        maxAreaCount={maxAreaCount}
                        reportAreaCount={(count) => updateAreaCount(quote.jobConfigurationId, count)}
                        maxDiscountCount={maxDiscountCount}
                        reportDiscountCount={(count) => updateDiscountCount(quote.jobConfigurationId, count)}
                        reportPriceAndFinancing={(newPrice, newFinOptionId) => {
                            const copy = [...quoteStates]
                            copy[index] = { ...copy[index], jobPrice: newPrice, selectedFinancingOptionId: newFinOptionId }

                            setQuoteStates(copy);
                        }}
                        setQuoteState={(newState: QuoteCardState) => {
                            const copy = [...quoteStates];
                            copy[index] = {...newState};

                            setQuoteStates(copy);
                        }}
                        nextOptionNumber={nextOptionNumber}
                    />)
                }
                <AlwaysScrollToEnd />
            </AnimatePresence>
        </div>
    )
}