import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Calendar, Day, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { IconButton } from "@material-ui/core";
import { TextsmsOutlined } from "@material-ui/icons";
import DateRangeIcon from "@material-ui/icons/DateRange";
import clsx from "clsx";
import FlatInput from "FlatComponents/Inputs/FlatInput";
import { InstallationCodBounds } from "generated/graphql";
import {
    dayBeforeOrEq,
    dayExists,
    dayToMdy,
    mdyFormatValid,
    mdyToDay
} from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { RecoveryCenterCallCustomerPhoneButton } from "Pages/Admin/RecoveryCenter/CallCustomerPhoneButton";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { SchedulerCustomerWillCallButton } from "../../Components/CustomerWillCallButton";
import CustomerWillCallDialog from "./CustomerWillCallDialog";

// does not completely correspond to a contractor, but provides all data necessary for populating the relevant Select component
export interface SelectableContractor {
    id: number;
    firstName: string;
    lastName: string;
}

interface SchedulingPopoutProps {
    selectedDate: DayValue;
    setSelectedDate: (newDay: DayValue) => void;
    cod: number | undefined;
    setCod: (newCod: number | undefined) => void;
    codBounds: InstallationCodBounds;
    calendarId: string;
    popoutId: string;
    submitAdornment?: ReactJSXElement;
    showCwcButton?: boolean;
    cwcNumber?: number | null;
    jobId?: number;
}

export function SchedulingPopout({
    selectedDate,
    setSelectedDate,
    cod,
    setCod,
    codBounds,
    calendarId,
    popoutId,
    submitAdornment,
    showCwcButton = false,
    cwcNumber,
    jobId,
}: SchedulingPopoutProps) {
    // move the popout into view once it render
    useEffect(() => {
        setTimeout(() => document.getElementById(popoutId)?.scrollIntoView({ block: "end" }), 50);
        if (showCwcButton && !jobId) {
            throw new Error("Must pass job ID if showCwcButton is true");
        }
    }, [jobId, popoutId, showCwcButton]);

    // TODO: this will change once we disable holidays, etc...
    const defaultDisabledDays: Day[] = [];
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [calendarDisabledDays] = useState<Day[]>(defaultDisabledDays);
    const [cwcDialogOpen, setCwcDialogOpen] = useState(false);

    function clearSelectedDate() {
        setSelectedDate(null);
        setStartDateText("");
    }

    // used as an intermediate between the actual selected date range (for the Calendar component/data that will be used in query)
    // and the text field inputs that allow the dates to be typed manually
    const [startDateText, setStartDateText] = useState("");

    // used to allow the date input text fields to be editable when clicked in to (allows the "value" prop to be changed depending on whether its being edited)
    const [startDateTextFieldHasFocus, setStartDateTextFieldHasFocus] = useState(false);

    function determineStartDateTextFieldValue() {
        if (startDateTextFieldHasFocus || isNullOrUndefined(selectedDate)) {
            return startDateText;
        } else {
            if (isNotNullOrUndefined(selectedDate)) {
                return dayToMdy(selectedDate!);
            } else {
                return "";
            }
        }
    }

    // the "blur" event is unfocusing
    function onBlurStartDateTextField() {
        setStartDateTextFieldHasFocus(false);
        let newStartDate: Day;

        if (mdyFormatValid(startDateText.trim())) {
            newStartDate = mdyToDay(startDateText.trim());

            if (!dayExists(newStartDate)) {
                if (isNullOrUndefined(selectedDate)) {
                    setStartDateText("");
                }
                return; // don't do anything, the day doesn't exist (e.g., August 32)
            }

            let today = utils("en").getToday();
            if (!dayBeforeOrEq(today, newStartDate)) {
                setStartDateText("");
                return; // don't do anything if user selects day that is before today
            }
        } else {
            // don't change the selected date, but clear the input text
            setStartDateText("");
        }
    }

    function onFocusStartDateTextField() {
        setStartDateTextFieldHasFocus(true);
        setCalendarOpen(true);

        // must set startDateText because the value prop of the text input is dependent on whether the field has focus...
        // once the field has focus, the value is taken from the selectedDateText piece of state, rather than
        // the selectedDateRange piece of state
        if (isNotNullOrUndefined(selectedDate)) {
            setStartDateText(dayToMdy(selectedDate!));
        }
    }

    const codError = codBounds.lowerBound !== -1
        ? ((cod ?? -2) < codBounds.lowerBound || (cod ?? -2) > codBounds.upperBound)
        : false;
    
        const errorHelperText = codError ?
            codBounds.lowerBound === codBounds.upperBound
                ? `COD must be set to $${codBounds.upperBound.toFixed(2)}`
                : `COD must be set between $${codBounds.lowerBound.toFixed(2)} and $${codBounds.upperBound.toFixed(2)}`
            : undefined;

    return (
        <div
            className="flex-row"
            id={popoutId}
        >
            <div className="flex-column">
                <div className="flex-row flex-gap-sm">
                    <RecoveryCenterCallCustomerPhoneButton
                        phoneNumber={""}
                        jobId={0}
                        iconColor="black"
                    />
                    <IconButton>
                        <TextsmsOutlined htmlColor="black" fontSize="small"/>
                    </IconButton>
                </div>

                <div className="flex-row">
                    <NumberFormat
                        className={clsx("margin-bottom-sm w-6r")}
                        customInput={FlatInput}
                        error={codError}
                        prefix="$"
                        displayType="input"
                        placeholder="COD"
                        value={cod}
                        thousandSeparator
                        onValueChange={(val) => setCod(val.floatValue)}
                        decimalScale={2}
                        fixedDecimalScale
                        allowNegative={false}
                    />

                    <p className="margin-vertical-none error-text margin-left-sm">{errorHelperText}</p>
                </div>

                <FlatInput
                    className="margin-bottom-xsm w-6r"
                    readOnly
                    onBlur={onBlurStartDateTextField}
                    onFocus={onFocusStartDateTextField}
                    onChange={(e) => setStartDateText(e.target.value)}
                    value={determineStartDateTextFieldValue()}
                    placeholder="Start Date"
                />

                <IconButton
                    // scrollIntoView make the page scroll to the end of the calendar when it's expanded; otherwise user must manually scroll
                    // use setTimeout to ensure that the calendar has time to expand before scrolling occurs
                    onClick={() => {
                        setCalendarOpen(!calendarOpen);
                        setTimeout(
                            () =>
                                document
                                    .getElementById(calendarId)
                                    ?.scrollIntoView({ block: "end" }),
                            50
                        );
                    }}
                    className="fit-content"
                    style={{marginLeft: "1.65rem"}}
                >
                    <DateRangeIcon fontSize="small" htmlColor="black"/>
                </IconButton>

                {submitAdornment}
            </div>

            {calendarOpen && (
                <div>
                    {showCwcButton && (
                        <SchedulerCustomerWillCallButton onClick={() => setCwcDialogOpen(true)}>
                            CWC
                        </SchedulerCustomerWillCallButton>
                    )}
                    <div
                        id={calendarId}
                        className="margin-left-md"
                        onDoubleClick={() => {
                            clearSelectedDate();
                        }}
                    >
                        <Calendar
                            value={selectedDate}
                            onChange={setSelectedDate}
                            minimumDate={utils("en").getToday()}
                            colorPrimary="var(--wof-red)"
                            colorPrimaryLight="#f79cab"
                            disabledDays={calendarDisabledDays}
                            shouldHighlightWeekends
                        />
                    </div>
                </div>
            )}

            {cwcDialogOpen && jobId && (
                <CustomerWillCallDialog
                    jobId={jobId}
                    onClose={() => setCwcDialogOpen(false)}
                    initialNumWeeks={cwcNumber ?? undefined}
                />
            )}
        </div>
    );
}
