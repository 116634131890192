import { Item, Menu } from "react-contexify";

const ADD_ROOM_CONTEXT_MENU_ID = 'add-room-context';
export const makeAddRoomContextMenuId = (jobServiceId: number) => `${ADD_ROOM_CONTEXT_MENU_ID}-${jobServiceId}`;


interface AddRoomContextMenuProps {
    roomOptions: {id: number, label: string}[];
    jobServiceId: number;
    handleRoomClicked: (roomId: number) => void;
}

export function AddRoomContextMenu({roomOptions, jobServiceId, handleRoomClicked}: AddRoomContextMenuProps) {
    return (
        <Menu id={makeAddRoomContextMenuId(jobServiceId)}>
            {roomOptions.map(opt => (
                <Item
                    key={`ropt-${opt.id}`}
                    onClick={() => handleRoomClicked(opt.id)}
                >{opt.label}</Item>
            ))}
        </Menu>
    )
}