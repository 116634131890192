import { makeStyles, Typography, TypographyProps } from "@material-ui/core";
import clsx from "clsx";
import { PropsWithChildren } from "react";

const useStyles = makeStyles((theme) => ({
    areaHeader: {
        backgroundColor: theme.palette.primary.dark,
        textShadow: 'black 1px 0 10px;',
        color: 'white',
        padding: '0.5rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
    },
}));

interface BlockHeadingProps extends TypographyProps {
    label: JSX.Element | string
    preLabel?: JSX.Element
}

export default function BlockHeading({ preLabel, label, variant, children, ...props }: PropsWithChildren<BlockHeadingProps>) {

    const classes = useStyles();
    variant = variant ?? 'h4';

    return (
        <div className={clsx(classes.areaHeader, 'flex-row', 'fill-width')} style={{ alignItems:"center", backgroundColor: '---wof-red' }}>
            {preLabel}
            <Typography variant={variant} className='margin-v-center margin-side-xxsm' {...props} >{label}</Typography>
            <div className="flex-grow" />
            {children}
        </div>
    );
}
