import { Button } from '@material-ui/core';
import clsx from 'clsx';
import CircleTrashButton from 'Components/Forms/Controls/CircleTrashButton';
import { motion } from 'framer-motion';
import { FinancingUpdatedDocument, FinancingUpdatedSubscription, GetJobConfigurationDocument, GetJobConfigurationQuery, JobConfiguration, JobConfigurationPriceUpdatedDocument, JobConfigurationPriceUpdatedSubscription, namedOperations, OverrideDiscountSentDocument, OverrideDiscountSentSubscription, PriceRequestUpdatedForConfigDocument, PriceRequestUpdatedForConfigSubscription, UpdatedProductForAreaInput, useCalculatePriceForLocalConfigurationQuery, useGetJobConfigurationQuery, useUpdateAreaProductStyleSelectionMutation, useUpdateAreaProductTypeMutation, useUpdateJobDiscountMutation } from 'generated/graphql';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Globals/GenericValidators';
import { OVERRIDE_DISCOUNT_ID, SPC_PRODUCT_ID, WE_PAY_SALES_TAX_DISCOUNT_ID, WOOD_PRODUCT_ID } from 'Globals/globalConstants';
import { buildAppendedId } from 'Globals/Hooks';
import { jobScopePath } from 'Globals/PathStrings';
import { useAppHistory } from 'Globals/routingHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { FullEditInstallationServicesMenu } from '../InstallationDetailsEditor/InstallationDetailsButton';
import { RoomPictureViewerDialog } from '../../CartPage';
import QuoteDetailsDialog from '../QuoteDetailsEditor/QuoteDetailsDialog';
import { QuoteCardChanges, QuoteCardState } from '../SellSheet';
import AddScenarioButton from './AddScenarioButton';
import AddDiscountButton from './DiscountButton';
import DiscountRows from './DiscountRows';
import QuoteCardIconButton from './QuoteCardIconButton';
import RenderAreaLabel, { AreaLabel, generateAreaLabels } from './RenderAreaLabel';
import RenderStyleLabel, { DisplayStyle, generateDisplayStyles } from './RenderStyleLabel';


interface QuoteAnimatedWrapperProps extends QuoteCardProps {
    relativeToRoot: number
}

export function QuoteAnimatedWrapper({ relativeToRoot, ...quoteProps }: QuoteAnimatedWrapperProps) {
    return (
        <motion.div
            style={{ overflow: "hidden" }}
            initial={{ opacity: 0, x: relativeToRoot > 0 ? "20em" : "-10em", width: 0, minWidth: 0, maxWidth: 0 }}
            animate={{ opacity: 1, x: 0, transition: { duration: .8 }, width: "23vw", minWidth: "23vw", maxWidth: "23vw" }}
            exit={{ opacity: 0, width: 0, minWidth: 0, maxWidth: 0, y: "-5em", transition: { duration: .2 } }}>
            <div className="ss-gallery-item">
                <QuoteCard {...quoteProps} />
            </div>
        </motion.div>
    )
}

interface QuoteCardProps {
    selectionPresent: boolean,
    quoteState: QuoteCardState,
    setQuoteState: (newState: QuoteCardState) => void;
    updateQuoteCardChangesState: (newState: QuoteCardChanges) => void,
    maxAreaCount: number,
    reportAreaCount: (count: number) => void,
    maxDiscountCount: number,
    reportDiscountCount: (count: number) => void,
    reportPriceAndFinancing: (price: number, financingOptionId?: number) => void,
    deleteCard: () => void,
    addCard: (newId: number, optionNumber: number) => void,
    isLocalCopy?: boolean,
    toggleSelect: () => void,
    nextOptionNumber: number
}

export default function QuoteCard({
    selectionPresent,
    quoteState,
    setQuoteState,
    updateQuoteCardChangesState,
    reportAreaCount,
    maxAreaCount,
    toggleSelect,
    deleteCard, 
    addCard, 
    reportDiscountCount, 
    maxDiscountCount, 
    reportPriceAndFinancing, 
    nextOptionNumber
}: QuoteCardProps) {
    const history = useAppHistory();
    const appendedId: string = buildAppendedId(quoteState.jobConfigurationId)

    const [uploadOpen, setUploadOpen] = useState<boolean>(false);

    const { data, subscribeToMore: subscribeToConfigChanges } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: quoteState.jobConfigurationId },
        fetchPolicy: "network-only",
        onCompleted(data) {
            reportAreaCount(data?.jobConfiguration.areas.length ?? 0);
            const discounts = data?.jobConfiguration.discounts;
            const appliedPromotion = data.jobConfiguration
            reportDiscountCount((discounts?.discounts.length ?? 0) + (isNotNullOrUndefined(appliedPromotion) ? 1 : 0));
            const priceRequest = quoteState.isCopy ? undefined : data.jobConfiguration.priceRequest;
            const hasPriceRequest = isNotNullOrUndefined(priceRequest)
            const needToUpdateOptionNumber = !quoteState.isCopy && quoteState.optionNumber !== data.jobConfiguration.optionNumber

            const updatedState: QuoteCardState = {
                ...quoteState,
                isDeletable: quoteState.isDeletable && !hasPriceRequest,
                optionNumber: needToUpdateOptionNumber ? data.jobConfiguration.optionNumber : quoteState.optionNumber,
            }

            setQuoteState(updatedState);
        }
    });

    const [quoteDialogOpen, setQuoteDialogOpen] = useState<boolean>(false)
    const jobConfig = data?.jobConfiguration;
    const jobConfigurationId = quoteState.jobConfigurationId;
    const promotion = jobConfig?.promotion;
    let discountsOnJob = data?.jobConfiguration.discounts ?? null;
    // remove the override discount for copied cards
    if (isNotNullOrUndefined(discountsOnJob) && quoteState.isCopy) {
        discountsOnJob = {
            ...discountsOnJob!,
            isOverrideApplied: false,
            discounts: discountsOnJob!.discounts.filter(d => d.discountId !== OVERRIDE_DISCOUNT_ID) ?? []
        }
    }

    useEffect(() => {
        if (subscribeToConfigChanges && jobConfigurationId) {
            return subscribeToConfigChanges({
                document: PriceRequestUpdatedForConfigDocument,
                variables: {
                    jobConfigurationId: jobConfigurationId
                },
                updateQuery: (prev, { subscriptionData }) => {
                    const newRequest = (subscriptionData.data as unknown as PriceRequestUpdatedForConfigSubscription).priceRequestUpdatedForConfig;
                    if (!newRequest) { return prev; }

                    let updatedConfig: GetJobConfigurationQuery = {
                        ...prev,
                        jobConfiguration: {
                            ...prev.jobConfiguration,
                            priceRequest: newRequest
                        }
                    };

                    setQuoteState({ ...quoteState, isDeletable: false });

                    return updatedConfig;
                }
            });
        }
    }, [jobConfigurationId, subscribeToConfigChanges, quoteState, setQuoteState]);

    const listenForOverrideDiscount = useCallback(() => {
        if (subscribeToConfigChanges && jobConfigurationId) {
            subscribeToConfigChanges({
                document: OverrideDiscountSentDocument,
                variables: {
                    jobConfigurationId: jobConfigurationId
                },
                updateQuery: (prev, { subscriptionData }) => {
                    const incomingData = (subscriptionData.data as unknown as OverrideDiscountSentSubscription).overrideDiscountSent;
                    if (!incomingData) { return prev; }

                    // remove a previous override if there was one
                    let updatedDiscounts = [...prev.jobConfiguration.discounts?.discounts?.filter(d => d.discountId !== OVERRIDE_DISCOUNT_ID) ?? []];
                    const newOverride = incomingData.overrideDiscount;
                    updatedDiscounts.push(newOverride);

                    let updatedConfig: GetJobConfigurationQuery = {
                        ...prev,
                        jobConfiguration: {
                            ...prev.jobConfiguration,
                            price: incomingData.updatedPrice,
                            discounts: {
                                ...prev.jobConfiguration.discounts!,
                                isOverrideApplied: true,
                                discounts: updatedDiscounts
                            }
                        },
                    };

                    return updatedConfig;
                }
            });
        }
    }, [jobConfigurationId, subscribeToConfigChanges]);

    // only used in this page for removing override
    const [updateDiscount] = useUpdateJobDiscountMutation({
        refetchQueries: [
            {
                query: GetJobConfigurationDocument,
                variables: { jobConfigurationId },
                fetchPolicy: "network-only"
            },
            namedOperations.Query.CalculatePriceForLocalConfiguration
        ],
        awaitRefetchQueries: true
    });

    function removeOverride() {
        updateDiscount(
            {
                variables:
                {
                    jobConfigurationId: jobConfigurationId ?? 0,
                    discountId: OVERRIDE_DISCOUNT_ID,
                    isRemoval: true // Is Remove flag
                }
            }
        )
    }

    useEffect(() => {
        if (listenForOverrideDiscount) {
            listenForOverrideDiscount();
        }
    }, [listenForOverrideDiscount]);

    const listenForFinancingUpdates = useCallback(() => {
        if (!quoteState.isCopy) {
            subscribeToConfigChanges({
                document: FinancingUpdatedDocument,
                variables: { jobConfigurationId: quoteState.jobConfigurationId },
                updateQuery: (prev, { subscriptionData }) => {
                    const newFinancing = (subscriptionData as unknown as FinancingUpdatedSubscription).financingUpdated;
                    if (newFinancing) {
                        const updatedConfig: GetJobConfigurationQuery = {
                            ...prev,
                            jobConfiguration: {
                                ...prev.jobConfiguration,
                                financing: newFinancing
                            }
                        }

                        return updatedConfig;
                    } else {
                        return prev;
                    }
                }
            });
        }
    }, [quoteState.isCopy, quoteState.jobConfigurationId, subscribeToConfigChanges]);

    useEffect(() => {
        listenForFinancingUpdates();
    }, [listenForFinancingUpdates]);

    const listenForPriceUpdates = useCallback(() => {
        subscribeToConfigChanges({
            document: JobConfigurationPriceUpdatedDocument,
            variables: { jobConfigurationId: jobConfigurationId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const updatedPricing = (subscriptionData.data as unknown as JobConfigurationPriceUpdatedSubscription).jobConfigurationPriceUpdated;
                if (!updatedPricing) return prev;

                const updatedConfig: GetJobConfigurationQuery = {
                    ...prev,
                    jobConfiguration: {
                        ...prev.jobConfiguration,
                        price: updatedPricing.overallPrice
                    }
                }

                return updatedConfig;
            }
        });
    }, [jobConfigurationId, subscribeToConfigChanges]);

    useEffect(() => {
        if (listenForPriceUpdates) {
            listenForPriceUpdates();
        }
    }, [listenForPriceUpdates]);


    const [setProductType] = useUpdateAreaProductTypeMutation({
        refetchQueries: [
            {
                query: GetJobConfigurationDocument,
                variables: { jobConfigurationId: jobConfigurationId },
                fetchPolicy: "network-only"
            }
        ],
        awaitRefetchQueries: true,
        errorPolicy: "none"
    })

    const [setProductStyle] = useUpdateAreaProductStyleSelectionMutation({
        refetchQueries: [
            {
                query: GetJobConfigurationDocument,
                variables: { jobConfigurationId: jobConfigurationId },
                fetchPolicy: "network-only"
            }
        ],
        awaitRefetchQueries: true,
        errorPolicy: "none"
    })

    var updatedProducts: UpdatedProductForAreaInput[] = localChangesToUpdatedProductInput(quoteState, jobConfig)
    const priceRequest = jobConfig?.priceRequest;

    const { data: localPriceData, loading, refetch } = useCalculatePriceForLocalConfigurationQuery({
        variables: {
            originalConfigurationId: jobConfigurationId,
            updatedProducts: updatedProducts,
            includeSalesTax: quoteState.selectedFinancingOptionId === -1
        },
        fetchPolicy: 'cache-and-network',
        skip: !quoteState.isCopy
    })

    const updatedAreas = useMemo(() => {
        const areas = data?.jobConfiguration.areas ?? []

        return areas.map(area => ({ ...area, ...quoteState.productChanges[area.id] }))
    }, [data, quoteState])

    const configHasSalesTaxDiscount = isNotNullOrUndefined(data?.jobConfiguration.discounts?.discounts?.find(dis => dis.discountId === WE_PAY_SALES_TAX_DISCOUNT_ID))
    const financingOptionId = data?.jobConfiguration.financing?.financingOption?.id ?? undefined

    const { mSRP, total } = data?.jobConfiguration.price ?? { mSRP: 0, total: 0 };
    const localPrice = quoteState.isCopy ? localPriceData?.calculatePriceForLocalConfiguration?.price : undefined;
    const localPromoSavings = quoteState.isCopy ?
        localPriceData?.calculatePriceForLocalConfiguration?.promoSavings:
        undefined;

    const displayMSRP = localPrice?.mSRP ?? mSRP;
    // for copies, treat it as though there is no override for the config
    const overrideDiscount = quoteState.isCopy ? undefined : data?.jobConfiguration.discounts?.discounts?.find(dis => dis.discountId === OVERRIDE_DISCOUNT_ID);
    const isOverrideApplied = isNotNullOrUndefined(overrideDiscount);
    const overrideAuthCode = jobConfig?.discounts?.discounts.find(d => d.discountId === OVERRIDE_DISCOUNT_ID)?.authorizationCode;
    const displayTotal = (localPrice?.total ?? total) + (overrideDiscount?.amount ?? 0);
    const actualTotal = (localPrice?.total ?? total);

    const renderAreaCount = Math.max(updatedAreas.length, maxAreaCount, 1)

    const styles: DisplayStyle[] = generateDisplayStyles(updatedAreas, renderAreaCount);
    const areaLabels: AreaLabel[] = generateAreaLabels(updatedAreas, renderAreaCount);

    function updateProductType(areaId: number, newPid: number, name: string) {
        if (quoteState.isCopy) {
            // Update local state
            var updatedQuoteState: QuoteCardChanges = {
                ...quoteState.productChanges,
                [areaId]: {
                    productTypeId: newPid,
                    productTypeName: name,
                    styleId: undefined,
                    styleName: undefined
                }
            }

            // this will cause recalculation of price automatically
            updateQuoteCardChangesState(updatedQuoteState);
        }
        else {
            // Update db state
            const area = jobConfig?.areas.find(area => area.id === areaId)
            const oldProductType = area?.productTypeId

            const isChangingToSSorNull = (newPid === 4 || newPid === undefined)
            const wasHS = oldProductType !== 4 && !isNullOrUndefined(oldProductType)
            const shouldPromptForSpindles = area?.rooms.some(room => room.steps.some(steps => steps.shouldPromptAboutSpindles))

            if (!shouldPromptForSpindles || isChangingToSSorNull || wasHS || window.confirm("If there are spindles present, customer must remove them before installation")) {
                setProductType({ variables: { areaId, productTypeId: newPid } })
            }
        }
    }

    const [areaIdForInstallationEditor, setAreaIdForInstallationEditor] = useState<number | undefined>(undefined);

    function updateStyle(areaId: number, newSid: number, name: string) {
        if (quoteState.isCopy) {
            // Update local state
            const area = jobConfig?.areas.find(area => area.id === areaId)
            
            var updatedQuoteState: QuoteCardChanges = {
                ...quoteState.productChanges,
                [areaId]: {
                    productTypeId: quoteState.productChanges[areaId]?.productTypeId ?? area?.productTypeId ?? undefined,
                    productTypeName: quoteState.productChanges[areaId]?.productTypeName ?? area?.productTypeName ?? undefined,
                    styleId: newSid,
                    styleName: name
                }
            }
            
            // this will cause recalculation of price automatically
            updateQuoteCardChangesState(updatedQuoteState);
            setAreaIdForInstallationEditor(areaId);
        }
        else {
            // Update db state
            setProductStyle({
                variables: { areaId, styleId: newSid } 
            }).then((res) => {
                const hadPrevStyle = isNotNullOrUndefined(styles.find(sty => isNotNullOrUndefined(sty) && sty!.areaId === areaId)?.styleId);
                // if mutation was successful and there was no style before, open the editor
                if (res.data?.updateAreaProductStyleSelection && !hadPrevStyle) {
                    setAreaIdForInstallationEditor(areaId);
                }
            });
        }
    }

    // needed because when a compare is updated when the OG config is updated, price needs to be recalculated
    useEffect(() => {
        if (quoteState.isCopy) {
            refetch();
        }
    }, [updatedAreas, refetch, quoteState.isCopy]);

    const pendingPriceRequestResponse = isNotNullOrUndefined(priceRequest) && isNullOrUndefined(priceRequest?.timeResponded);
    const disableStyleChanges = !quoteState.isCopy && (pendingPriceRequestResponse || isOverrideApplied);

    useEffect(() => {
        // Sales Tax discount must be removed because it should not be considered when determining which
        // financing options this card qualifies for
        const reportFinancingOptionId = !quoteState.isCopy
                                      ? calculateFinancingOptionId(financingOptionId, configHasSalesTaxDiscount)
                                      : quoteState.selectedFinancingOptionId;
        const roundedTotal = +removeSalesTax(actualTotal, reportFinancingOptionId === -1).toFixed(0);
        if (roundedTotal !== quoteState.jobPrice || reportFinancingOptionId !== quoteState.selectedFinancingOptionId) {
            // 1. The total displayed on the card is different from the one reported to the financing options
            // 2. or the reported financing id option differs from what it should be reported as 
            // The (2.) option should really only come up when this card is initally loaded from the database
            // and is still initializing itself on the page
            reportPriceAndFinancing(roundedTotal, reportFinancingOptionId)
        }
    }, [actualTotal, configHasSalesTaxDiscount, financingOptionId, quoteState, reportPriceAndFinancing])

    const shadeDark = selectionPresent && !quoteState.isSelected

    return (<>
        <div className="flex-column fill-height fill-width" >
            <div className={clsx("ss-option-header", { 'visibility-hidden': quoteState.optionNumber === 0 })}>
                Option {quoteState.optionNumber}
            </div>
            <div className={'flex-column-reverse fill-width'}>
                {
                    styles.map((style, index) =>
                        <RenderStyleLabel
                            key={style?.areaId ?? `unused-index-${index}`}
                            shadeDark={shadeDark} style={style}
                            updateStyle={(sid, name) => updateStyle(style!.areaId, sid, name)}
                            disableChanges={disableStyleChanges}
                        />)
                }
            </div>
            <div className={clsx("flex-column fill-height fill-width ss-quote-card", { 'ss-shade': shadeDark })}>
                {areaLabels.map((area, index) =>
                    <RenderAreaLabel
                        key={area?.areaId ?? `unused-index-${index}`}
                        area={area}
                        updatePid={(pid, name) => updateProductType(area!.areaId, pid, name)}
                        disableChanges={pendingPriceRequestResponse || isOverrideApplied || quoteState.isCopy}
                        someAreaHasHardSurface={data?.jobConfiguration.areas
                            .some(a => (a.productTypeId === WOOD_PRODUCT_ID) || (a.productTypeId === SPC_PRODUCT_ID))
                            ?? false
                        }
                    />
                )}
                
                <div className='ss-config-breakdown'>
                    <div>MSRP:</div>
                    <MoneyTextFormat value={loading ? null : displayMSRP} />
                    <DiscountRows
                        hasSalesTax={quoteState.selectedFinancingOptionId === -1}
                        jobConfigurationId={jobConfigurationId}
                        appliedPromotion={promotion?.basePromotion ?? null}
                        promoSavingsAmount={localPromoSavings ?? promotion?.savingsAmount ?? null}
                        discountsOnJob={discountsOnJob ?? null}
                        renderRowCount={maxDiscountCount}
                        pendingPriceRequestResponse={pendingPriceRequestResponse}
                    />
                    <div />
                    <div style={{ paddingBottom: ".25em", width: "100%", textAlign: "end", paddingRight: ".25em" }}>Total:</div>
                    <div style={{ paddingBottom: ".25em" }}><MoneyTextFormat value={loading ? null : displayTotal} /></div>
                </div>
                
                <div className="flex-row" style={{ margin: "0 .25em" }}>
                    <QuoteCardIconButton
                        variant={quoteState.isSelected ? 'contained' : 'outlined'}
                        color="secondary"
                        onClick={toggleSelect}
                    >S</QuoteCardIconButton>

                    <AddDiscountButton
                        jobConfigurationId={jobConfigurationId}
                        discounts={discountsOnJob}
                        pendingPriceRequestResponse={pendingPriceRequestResponse}
                        isPromotionApplied={isNotNullOrUndefined(promotion)}
                    />

                    <AddScenarioButton
                        isCopy={quoteState.isCopy}
                        jobConfigurationId={jobConfigurationId}
                        addCard={(id) => addCard(id, nextOptionNumber)}
                        nextOptionNumber={nextOptionNumber}
                    />

                    <QuoteCardIconButton
                        variant={'outlined'}
                        color="secondary"
                        className={clsx({ 'visibility-hidden': quoteState.isCopy })}
                        onClick={() => setQuoteDialogOpen(true)}>
                        A
                    </QuoteCardIconButton>

                    <QuoteCardIconButton
                        variant={'outlined'}
                        color="secondary"
                        className={clsx({ 'visibility-hidden': quoteState.isCopy })}
                        onClick={() => history.push(jobScopePath + appendedId)}>
                        Q
                    </QuoteCardIconButton>

                    <QuoteCardIconButton
                        variant={'outlined'}
                        color="secondary"
                        className={clsx({ 'visibility-hidden': quoteState.isCopy })}
                        onClick={() => setUploadOpen(true)}>
                        P
                    </QuoteCardIconButton>

                    <RoomPictureViewerDialog
                        jobConfigurationId={quoteState.jobConfigurationId}
                        open={uploadOpen}
                        setOpen={setUploadOpen}
                    />

                    <CircleTrashButton onClick={deleteCard} className={clsx({ 'visibility-hidden': !quoteState.isDeletable })} />
                </div>
            </div>

            <div className={clsx('flex-column', !isOverrideApplied && 'visibility-hidden')} style={{ alignItems: "flex-end" }}>
                <div className='flex-row align-items-center' style={{ fontSize: "2em", color: "white" }}>
                    <Button
                        style={{ minWidth: "0px", width: "2em", minHeight: "0", height: "2em", marginTop: "0.4rem" }}
                        onClick={removeOverride}
                    >
                        X
                    </Button>
                    <div style={{ paddingRight: ".5em" }}>Final:</div>
                    <div>
                        <MoneyTextFormat value={total} />
                    </div>
                </div>
                <div>
                    Auth # {overrideAuthCode}
                </div>
            </div>
            <QuoteDetailsDialog jobConfigurationId={quoteState.jobConfigurationId} optionNumber={quoteState.optionNumber} open={quoteDialogOpen} onClose={() => setQuoteDialogOpen(false)} />
        </div>

        {isNotNullOrUndefined(areaIdForInstallationEditor) && (
            <FullEditInstallationServicesMenu
                open={true}
                onClose={() => setAreaIdForInstallationEditor(undefined)}
                areaId={areaIdForInstallationEditor!}
                productTypeId={styles.find(sty => sty?.areaId === areaIdForInstallationEditor)?.productTypeId ?? -1}
                someAreaHasHardSurface={data?.jobConfiguration.areas
                    .some(a => (a.productTypeId === WOOD_PRODUCT_ID) || (a.productTypeId === SPC_PRODUCT_ID))
                    ?? false
                }
            />
        )}
    </>)
}

function localChangesToUpdatedProductInput(quoteState: QuoteCardState, jobConfig: JobConfiguration | undefined): UpdatedProductForAreaInput[] {
    return Object.keys(quoteState.productChanges).map(areaId => {
        const id = +areaId;
        var { productTypeId, styleId } = quoteState.productChanges[id];

        var ogArea = jobConfig?.areas.find(area => area.id === id);

        const hasProductChanges = productTypeId !== undefined;

        return {
            areaId: ogArea?.id ?? -1,
            productTypeId: productTypeId ?? ogArea?.productTypeId ?? -1,
            styleId: styleId ?? (hasProductChanges ? null : ogArea?.styleId)
        };
    }).filter(data => data.productTypeId !== -1 || data.areaId === -1);
}

export function MoneyTextFormat({ value }: { value: number | string | null }) {
    return <NumberFormat prefix="$" value={value} decimalScale={2} fixedDecimalScale thousandSeparator="," displayType="text" />
}

export function removeSalesTax(price: number, hasSalesTax: boolean) {
    // If it has sales tax, the price is 6% greater then it would be otherwise, so remove this amount
    return hasSalesTax ? price / .94 : price;
}

function calculateFinancingOptionId(selectedFinancingOptionId: number | undefined, hasSalesTax: boolean) {
    // Returns selected financing option id if there is one
    // otherwise, -1 if it has sales tax
    // if neither, then no financing option is selected so undefined
    return selectedFinancingOptionId ?? (hasSalesTax ? -1 : undefined)
}