import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import FlatSection from "FlatComponents/Layout/FlatSection";
import {
    InstallationAppointment,
    namedOperations,
    useGetAllAreaAndRoomNotesQuery,
    useGetAllInstallationNotesQuery,
    useGetAllRoomsForJobQuery,
    useGetJobNotesQuery,
    useUpdateAreaNotesMutation,
    useUpdateInstallationAppointmentNotesMutation,
    useUpdateJobConfigurationNotesMutation,
    useUpdateRoomNotesMutation
} from "generated/graphql";
import {
    dateTimeStrToDay,
    daysEq,
    dayToMdy,
    formatAppointmentDateStringAbbreviatedShortened
} from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { formatNameStringFirstLast } from "Globals/StringFormatting";
import { useState } from "react";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import AddNoteDialog from "./AddNoteDialog";
import EditableNote from "./EditableNote";

interface NotesPaneProps {
    jobConfigurationId: number;
    installationAppointments: InstallationAppointment[];
    //TODO: Update this to make sure the COD field is used (if it needs to be)
}

export default function NotesPane({
    jobConfigurationId,
    installationAppointments,
}: NotesPaneProps) {
    const [addNoteDialogOpen, setAddNoteDialogOpen] = useState(false);

    /* REGION: get notes */
    const { data: jobNotesData } = useGetJobNotesQuery({
        variables: { jobConfigurationId: jobConfigurationId },
    });

    const { data: AreaAndRoomsNotesData } = useGetAllAreaAndRoomNotesQuery({
        variables: { jobConfigurationId: jobConfigurationId },
    });

    const { data: InstallationNotesData } = useGetAllInstallationNotesQuery({
        variables: { jobConfigurationId: jobConfigurationId },
    });

    /* REGION: update/delete notes */
    const [updateJobConfigurationNotes] = useUpdateJobConfigurationNotesMutation({
        refetchQueries: [namedOperations.Query.GetJobNotes],
        awaitRefetchQueries: true,
    });
    const [updateAreaNotes] = useUpdateAreaNotesMutation({
        refetchQueries: [namedOperations.Query.GetAllAreaAndRoomNotes],
        awaitRefetchQueries: true,
    });
    const [updateRoomNotes] = useUpdateRoomNotesMutation({
        refetchQueries: [namedOperations.Query.GetAllAreaAndRoomNotes],
        awaitRefetchQueries: true,
    });
    const [updateInstallationAppointmentNotes] = useUpdateInstallationAppointmentNotesMutation({
        refetchQueries: [namedOperations.Query.GetAllInstallationNotes],
        awaitRefetchQueries: true,
    });

    /* REGION: data for room drop downs */
    const { data: rooms } = useGetAllRoomsForJobQuery({
        variables: { jobConfigurationId: jobConfigurationId },
    });

    return (
        <FlatSection
            header="Notes"
            endAdornment={ <FlatAddButton  style={{padding: "0"}} onClick={() => setAddNoteDialogOpen(true)} /> }
            className="fill-height"
        >
            <div className="flex-column margin-bottom-md">
                {addNoteDialogOpen && (
                    <AddNoteDialog
                        setOpen={setAddNoteDialogOpen}
                        jobConfigurationId={jobConfigurationId}
                        rooms={
                            rooms?.allRoomsForJob.map((r) => ({ labels: r.labels, id: r.id })) ?? []
                        }
                        installationAppointments={installationAppointments.map((appt) => {
                            const summaryText = `${formatNameStringFirstLast({
                                firstName: appt.contractorFirstName ?? "",
                                lastName: appt.contractorLastName ?? "",
                            })}: ${formatAppointmentDateStringAbbreviatedShortened(appt.dates)}`;

                            return { summary: summaryText, id: appt.id };
                        })}
                    />
                )}

                <div>
                    {isNotNullOrUndefined(jobNotesData?.jobNotes) && (
                        <EditableNote
                            key={`job-notes`}
                            noteLabel="Job notes"
                            note={jobNotesData!.jobNotes!}
                            onUpdate={(newNote: string) =>
                                updateJobConfigurationNotes({
                                    variables: {
                                        jobConfigurationId: jobConfigurationId,
                                        notes: newNote.trim(),
                                        replace: true,
                                    },
                                })
                            }
                            onDelete={() =>
                                updateJobConfigurationNotes({
                                    variables: {
                                        jobConfigurationId: jobConfigurationId,
                                        notes: "",
                                        replace: true,
                                    },
                                })
                            }
                        />
                    )}

                    {isNotNullOrUndefined(AreaAndRoomsNotesData?.allAreaAndRoomNotes) &&
                        AreaAndRoomsNotesData!.allAreaAndRoomNotes!.map((aarn) => {
                            let notes: ReactJSXElement[] = [];

                            let areaNotes = aarn.areaNotes;
                            if (isNotNullOrUndefined(areaNotes)) {
                                notes.push(
                                    <EditableNote
                                        key={`area-notes-${areaNotes!.id}`}
                                        noteLabel={`[${getNameOfArea(areaNotes!.labels)}]`}
                                        note={areaNotes!.notes}
                                        onUpdate={(newNote: string) =>
                                            updateAreaNotes({
                                                variables: {
                                                    areaId: areaNotes!.id,
                                                    newNotes: newNote.trim(),
                                                    replace: true,
                                                },
                                            })
                                        }
                                        onDelete={() =>
                                            updateAreaNotes({
                                                variables: {
                                                    areaId: areaNotes!.id,
                                                    newNotes: "",
                                                    replace: true,
                                                },
                                            })
                                        }
                                    />
                                );
                            }

                            let roomNotes = aarn!.roomNotes;
                            roomNotes.forEach((rn) => {
                                notes.push(
                                    <EditableNote
                                        key={`room-notes-${rn.id}`}
                                        noteLabel={`[${getNameOfArea(rn.labels)}]`}
                                        note={rn.notes}
                                        onUpdate={(newNote: string) =>
                                            updateRoomNotes({
                                                variables: {
                                                    roomId: rn!.id,
                                                    newNotes: newNote.trim(),
                                                    replace: true,
                                                },
                                            })
                                        }
                                        onDelete={() =>
                                            updateRoomNotes({
                                                variables: {
                                                    roomId: rn!.id,
                                                    newNotes: "",
                                                    replace: true,
                                                },
                                            })
                                        }
                                    />
                                );
                            });

                            return notes;
                        })}

                    {isNotNullOrUndefined(InstallationNotesData) &&
                        InstallationNotesData!.allInstallationNotes.map((n) => {
                            let labelText = `${n.contractorFirstName} ${
                                n.contractorLastName
                            }: ${dayToMdy(dateTimeStrToDay(n.startDate))}`;
                            if (
                                !daysEq(dateTimeStrToDay(n.startDate), dateTimeStrToDay(n.endDate))
                            ) {
                                labelText += ` to ${dayToMdy(dateTimeStrToDay(n.endDate))}`;
                            }

                            return (
                                <EditableNote
                                    key={`installation-notes-${n.id}`}
                                    noteLabel={labelText}
                                    note={n.notes}
                                    onUpdate={(newNote: string) =>
                                        updateInstallationAppointmentNotes({
                                            variables: {
                                                installationAppointmentId: n.id,
                                                newNotes: newNote.trim(),
                                                replace: true,
                                            },
                                        })
                                    }
                                    onDelete={() =>
                                        updateInstallationAppointmentNotes({
                                            variables: {
                                                installationAppointmentId: n.id,
                                                newNotes: "",
                                                replace: true,
                                            },
                                        })
                                    }
                                />
                            );
                        })}
                </div>
            </div>
        </FlatSection>
    );
}
