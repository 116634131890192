import { DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import { FlatAlertIcon } from "FlatComponents/Button/FlatAlertButton";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { BackgroundCheck, ContractorHelper, namedOperations, useUpsertContractorHelperMutation, WorkerHistoryDates } from "generated/graphql";
import { prepareContractorHelper } from "Globals/dataPreparationUtils";
import { emptyWorkerHistoryDates, makeEmptyBgCheck } from "Globals/DataStructures/EmptyDataStructures";
import { dateTimeStrToMdy, dayValueToIso } from "Globals/DateAndTimeHelpers";
import { isContractorHelperValid, isDocumentOverdue, isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { formatNameStringFirstLast, formatPhoneNumber, formatRawPhoneNumber } from "Globals/StringFormatting";
import { useState } from "react";
import BackgroundCheckDialog from "../GeneralProfileComponents/Dialogs/BackgroundCheckDialog";
import { EditableHistoryDialogRow, EditableWorkerHistoryDateRange, historyDatesToDayRanges } from "../GeneralProfileComponents/Dialogs/WorkerHistoryDialog";

interface ContractorHelperDialogProps {
    onClose: () => void;
    helper: ContractorHelper;
}

export default function ContractorHelperDialog({onClose, helper: originalHelper}: ContractorHelperDialogProps) {
    const newMode = originalHelper.id < 0;
    const [helper, setHelper] = useState({...originalHelper});
    const ranges = historyDatesToDayRanges(helper.history);

    function addHistoryRow() {
        const newIdx = Math.min(...ranges.map(r => r.rangeId), 0) - 1;
        setHelper({
            ...helper,
            history: [
                ...helper.history,
                {...emptyWorkerHistoryDates, id: newIdx}
            ]
        });
    }

    const headerText = (newMode) 
        ? "Add Helper" 
        : `Editing ${formatNameStringFirstLast({firstName: helper.firstName, lastName: helper.lastName})}`;

    function removeRange(rangeId: number) {
        setHelper({
            ...helper,
            history: helper.history.filter(h => h.id !== rangeId)
        });
    }
    
    function setStartDate(rangeId: number, date: DayValue) {
        const rangeIdx = ranges.findIndex(r => r.rangeId === rangeId);
        const oldRange = ranges[rangeIdx];
        const newRange: EditableWorkerHistoryDateRange = {
            ...oldRange,
            range: {
                ...oldRange.range,
                from: date
            }
        };

        const newRanges = [...ranges];
        newRanges.splice(rangeIdx, 1, newRange);
        setHelper({...helper, history: dayRangesToHistoryDates(newRanges)});
    }
    
    function setEndDate(rangeId: number, date: DayValue) {
        const rangeIdx = ranges.findIndex(r => r.rangeId === rangeId);
        const oldRange = ranges[rangeIdx];
        const newRange: EditableWorkerHistoryDateRange = {
            ...oldRange,
            range: {
                ...oldRange.range,
                to: date
            }
        };

        const newRanges = [...ranges];
        newRanges.splice(rangeIdx, 1, newRange);
        setHelper({...helper, history: dayRangesToHistoryDates(newRanges)});
    }

    const [upsertHelper, {loading: upserting}] = useUpsertContractorHelperMutation({
        onError: () => alert(`Could not ${newMode ? "add" : "update"} helper information`),
        refetchQueries: [namedOperations.Query.GetContractor]
    });

    const [bgCheckBeingEdited, setBgCheckBeingEdited] = useState<BackgroundCheck | undefined>(undefined);

    function validateAndPrepareData(): ContractorHelper | undefined {
        const preparedHelper = prepareContractorHelper(helper);
        if (isContractorHelperValid(preparedHelper, true)) {
            return preparedHelper;
        } else {
            return undefined;
        }
    }

    function onSubmit() {
        const preparedHelper = validateAndPrepareData();
        if (isNotNullOrUndefined(preparedHelper)) {
            upsertHelper({
                variables: {helper: preparedHelper!},
                onCompleted: onClose
            });
        }
    }

    const submitButton = (
        <FlatButton
            key={"submit-chd"}
            onClick={onSubmit}
            variant="contained"
            color="secondary"
            disabled={upserting}
        >Submit</FlatButton>
    );

    return (<>
        <FlatDialog
            dialogProps={{open: true, onClose: onClose, maxWidth: "md"}}
            sectionProps={{header: headerText}}
            actionButtons={[submitButton]}
        >
            <div className="grid-50-50 flex-row-gap-xsm">
                <FlatLabeledInput
                    label="First Name"
                    value={helper.firstName}
                    onChange={ (e) => setHelper({...helper, firstName: e.target.value}) }    
                />
                <FlatLabeledInput
                    label="Last Name"
                    value={helper.lastName}
                    onChange={ (e) => setHelper({...helper, lastName: e.target.value}) }
                />
                <FlatLabeledInput
                    label="Phone"
                    value={formatPhoneNumber(helper.phone)}
                    onChange={ (e) => setHelper({...helper, phone: formatRawPhoneNumber(e.target.value)}) }
                />
                <FlatLabeledInput
                    label="License #"
                    value={helper.licenseNumber ?? ""}
                    onChange={ (e) => setHelper({...helper, licenseNumber: e.target.value}) }
                />

                <span className="flex-row flex-gap-xsm grid-colspan-1-3 margin-top-xsm">
                    <p className="flat-font-md margin-none">History</p>
                    <FlatAddButton onClick={addHistoryRow}/>
                </span>
                {ranges.map(range => (
                    <EditableHistoryDialogRow
                        key={range.rangeId}
                        range={range.range}
                        setStartDate={(day: DayValue) => setStartDate(range.rangeId, day)}
                        setEndDate={(day: DayValue) => setEndDate(range.rangeId, day)}
                        removeRange={() => removeRange(range.rangeId)}
                    />
                ))}

                <span className="flex-row flex-gap-xsm grid-colspan-1-3 margin-top-xsm">
                    <p className="flat-font-md margin-none">Background Checks</p>
                    <FlatAddButton onClick={() => setBgCheckBeingEdited(makeEmptyBgCheck(helper.id))}/>
                </span>
                {helper.backgroundChecks.map((bgc, idx) => (
                    <span key={bgc.id} className="flex-row flex-gap-sm align-items-center grid-colspan-1-3">
                        <FlatEditButton onClick={() => setBgCheckBeingEdited(bgc)}/>
                        <p className="flat-font margin-none">
                            {bgc.isInternal ? "Internal" : "3rd Party"}
                        </p>
                        {bgc.number && (
                            <p className="flat-font margin-none">
                                {bgc.number}
                            </p>
                        )}
                        <p className="margin-none flat-font">
                            {`${dateTimeStrToMdy(bgc.datePerformed)} - ${dateTimeStrToMdy(bgc.expirationDate)}`}
                        </p>
                        {(idx === 0 && isDocumentOverdue(bgc.expirationDate)) && (
                            <span className="flex-row flex-gap-xsm align-items-center">
                                <FlatAlertIcon color="var(--flat-orange)"/>
                                <p className="flat-font-xsm margin-none italic-text">Overdue</p>
                            </span>
                        )}
                    </span>
                ))}
            </div>
        </FlatDialog>

        {isNotNullOrUndefined(bgCheckBeingEdited) && (
            <BackgroundCheckDialog
                onClose={() => {setBgCheckBeingEdited(undefined); onClose()}}
                bgCheck={bgCheckBeingEdited!}
                toRefetchOnUpdate={[namedOperations.Query.GetContractor]}    
            />
        )}
    </>)
}

function dayRangesToHistoryDates(ranges: EditableWorkerHistoryDateRange[]): WorkerHistoryDates[] {
    return ranges.map(r => ({
        id: r.rangeId,
        startDate: dayValueToIso(r.range.from),
        endDate: isNullOrUndefined(r.range.to) ? undefined : dayValueToIso(r.range.to),
    }));
}