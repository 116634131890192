import { Button, Menu, MenuItem } from "@material-ui/core"
import clsx from "clsx"
import { Area, useGetAllValidProductStyleOptionsForConfigurationQuery } from "generated/graphql"
import { isNullOrUndefined } from "Globals/GenericValidators"
import { padArray } from "Globals/Helpers"
import { useState } from "react"

export type DisplayStyle = ({
    areaId: number,
    styleId?: number,
    style?: string,
    productTypeId: number | undefined,
    substrateIdsInArea: number[],
} | undefined)

interface RenderStyleLabelProps {
    style: DisplayStyle,
    updateStyle: (styleId: number, name: string) => void,
    shadeDark: boolean,
    disableChanges: boolean
}

export default function RenderStyleLabel({ style: styleProps, updateStyle, shadeDark, disableChanges }: RenderStyleLabelProps) {
    const noPid = isNullOrUndefined(styleProps?.productTypeId)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { data, loading } = useGetAllValidProductStyleOptionsForConfigurationQuery({
        variables: {
            productTypeId: styleProps?.productTypeId!,
            substrateIdsInArea: styleProps?.substrateIdsInArea ?? []
        },
        skip: noPid
    });


    if (styleProps === undefined) return <div className="ss-render-area-style-row" />

    const { style, styleId: sid } = styleProps
    const styleOps = (noPid || loading) ? [] : (data?.allValidProductStyleOptionsForConfiguration ?? [])

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleItemClick = (id: number, style: string) => {
        updateStyle(id, style);
        handleClose();
    }

    const disabled = styleProps.productTypeId === undefined || disableChanges;

    return (<>
        <div className={"ss-render-area-style-row"}>
            <Button className={clsx({ 'ss-shade-btn': shadeDark })} variant='contained' onClick={handleClick} disabled={disabled}>
                {style ?? "Select Style"}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
                {loading && <MenuItem value={sid ?? -1} hidden>Loading...</MenuItem>}
                {styleOps.map(op =>
                    <MenuItem
                        key={`product-style-op-${op.id}`}
                        className={clsx({ 'line-through': !op.valid })}
                        value={op.id}
                        onClick={() => handleItemClick(op.id, op.style)}
                        disabled={!op.valid}>
                        {op.style}
                    </MenuItem>)}
                <MenuItem value={-1} hidden>No Product</MenuItem>
            </Menu>
        </div>
    </>)
}

export function generateDisplayStyles(areas: Area[], targetCount: number): DisplayStyle[] {
    return padArray(areas.map<DisplayStyle>(
        area => (
            {
                areaId: area.id,
                styleId: area.styleId ?? undefined,
                style: area.styleName ?? undefined,
                productTypeId: area.productTypeId ?? undefined,
                substrateIdsInArea: [...new Set(area.rooms.map(room => room.substrateId))].sort()
            })).reverse(),
        targetCount,
        undefined)
}
