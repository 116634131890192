import { Box, Button, CircularProgress, IconButton, InputBase, Tab, Tabs, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import { CallCenterScript, useDeleteCallCenterScriptMutation, useGetCallCenterScriptsQuery, useUpsertCallCenterScriptMutation } from "generated/graphql";
import { dateTimeStrToMdy, todayAsMdy } from "Globals/DateAndTimeHelpers";
import { isEmptyString } from "Globals/GenericValidators";
import { callCenterSchedulerPath } from "Globals/PathStrings";
import { useRef, useState } from "react";
import './tabbed_script_editor.css';

export default function CallCenterScriptsPage() {
    return (
        <NavbarPage title="Call Center" padContent centerHorizontally>
            <TabbedScriptEditor/>
        </NavbarPage>
    )
}

export function TabbedScriptEditor() {
    const NEW_SCRIPT_ID = -1;
    const newScriptItem: CallCenterScript = ({id: NEW_SCRIPT_ID, name: "+",  text: "", lastUpdated: ""});
    const [scripts, setScripts] = useState([newScriptItem]);
    // copy of the script which is kept in case user discards any edits made
    const [openedImmutableScript, setOpenedImmutableScript] = useState<CallCenterScript>(scripts[0]);
    // copy of the script which is used to make changes on
    const [openedMutableScript, setOpenedMutableScript] = useState<CallCenterScript>(scripts[0]);
    const newScriptMode = openedMutableScript.id === -1;

    const { loading } = useGetCallCenterScriptsQuery({
        onError: () => alert("Failed to load scripts"),
        onCompleted: (data) => {
            const queriedScripts = data.callCenterScripts.map(s => ({...s, lastUpdated: dateTimeStrToMdy(s.lastUpdated ?? undefined)}));
            setScripts([...queriedScripts, newScriptItem]);
            if (queriedScripts.length > 0) {
                setOpenedImmutableScript(queriedScripts[0]);
                setOpenedMutableScript(queriedScripts[0]);
            }
        }
    });

    // the loading flags for each of the mutations aren't automatically resetting, so do this manually
    const [ mutating, setMutating ] = useState(false);

    const [ upsertScript ] = useUpsertCallCenterScriptMutation();

    const [ deleteScript ] = useDeleteCallCenterScriptMutation({
        // refetchQueries: [namedOperations.Query.GetCallCenterScripts],
        onError: () => { alert("Failed to delete script"); setMutating(false); }
    });
    
    const [editing, setEditing] = useState(false);
    function changesPresent() {
        let nameChanged = openedMutableScript.name !== openedImmutableScript.name;
        // title for new script does not match the nam
        if (newScriptMode && openedMutableScript.name === "New Script") { nameChanged = false; } 
        const textChanged = (openedMutableScript.text.trim() !== openedImmutableScript.text.trim());
        return nameChanged || textChanged;
    }

    const scriptNameInputRef = useRef<HTMLElement | null>(null);

    // TODO: want to automatically select the name when beginning to add a NEW script... HOW?
    // https://stackoverflow.com/questions/28889826/how-to-set-focus-on-an-input-field-after-rendering
    // https://stackoverflow.com/questions/57962986/react-how-to-programmatically-select-text-on-component-click
    function focusAndSelectNameText() {
        const nameRef = scriptNameInputRef.current;
        if (nameRef) {
            nameRef.focus();

            // let range = document.createRange();
            // range.selectNodeContents(scriptNameInputRef.current.parentElement!);
            // let sel = window.getSelection()!;
            // sel.removeAllRanges();
            // sel.addRange(range);
            // console.log(scriptNameInputRef.current);
        }
    }

    function onTabChange(newId: number) {
        if (changesPresent() && !window.confirm("You have unsaved changes on the current script. Discard changes?")) { return; }
        setEditing(newId === NEW_SCRIPT_ID)
        setOpenedImmutableScript(scripts.find(item => item.id === newId)!);
        if (newId === NEW_SCRIPT_ID) {
            setOpenedMutableScript({...scripts.find(item => item.id === newId)!, name: "New Script"});
            focusAndSelectNameText();
        } else {
            setOpenedMutableScript(scripts.find(item => item.id === newId)!);
        }
    }

    function onNameChange(newTitle: string) {
        const updatedScript = {...openedMutableScript, name: newTitle};
        setOpenedMutableScript(updatedScript);
    }

    function onTextChange(newText: string) {
        const updatedScript = {...openedMutableScript, text: newText};
        setOpenedMutableScript(updatedScript);
    }

    function onCancel() {
        if (changesPresent()) {
            if (window.confirm("Are you sure you wish to discard your changes?")) {
                setOpenedMutableScript(openedImmutableScript);
                setEditing(false);
            } // else do nothing
        } else {
            setEditing(false)
        }
    }

    function onUpdateScript() {
        if (!changesPresent()) {
            setEditing(false);
            return;
        }

        const trimmedName = openedMutableScript.name.trim();
        if (isEmptyString(trimmedName)) {
            alert("Please enter the name of the script");
            return;
        }
        
        const trimmedText = openedMutableScript.text.trim();
        if (isEmptyString(trimmedText)) {
            alert("Please enter script text");
            return;
        }
        
        const toUpdate = {
            id: openedMutableScript.id,
            name: trimmedName,
            text: trimmedText
        }

        setMutating(true);
        upsertScript({
            variables: { script: toUpdate },
            onError: () => { alert("Failed to update script"); setMutating(false) },
            onCompleted: (data) => {
                if (data) {
                    const updatedId = data.upsertCallCenterScript;
                    const indexOfUpdated = scripts.findIndex(s => s.id === updatedId);
                    const updatedScript = {...toUpdate, name: trimmedName, text: trimmedText, lastUpdated: todayAsMdy()}
                    let newScripts = [...scripts];
                    newScripts[indexOfUpdated] = updatedScript;
                    
                    setScripts(newScripts);
                    setOpenedImmutableScript(updatedScript);
                    setOpenedMutableScript(updatedScript);
                    setEditing(false);
                    setMutating(false);
                    alert("Script updated successfully");
                }
            }
        });
    }

    function onDeleteScript() {
        if (window.confirm("Are you sure you want to delete this script?")) {
            setMutating(true);
            deleteScript({
                variables: { scriptId: openedMutableScript.id },
                onCompleted: (res) => {
                    if (res.deleteCallCenterScript) {
                        let newScripts = scripts.filter(s => s.id !== openedMutableScript.id);
                        setScripts(newScripts);
                        setOpenedImmutableScript(newScripts[0]);
                        setOpenedMutableScript(newScripts[0]);
                        setMutating(false);
                    }
                }
            });
        }
    }

    function onCreateScript() {
        const trimmedName = openedMutableScript.name.trim();
        if (isEmptyString(trimmedName) || trimmedName === "New Script") {
            alert("Enter a name for the script");
            return;
        }

        const trimmedText = openedMutableScript.text.trim();
        if (isEmptyString(trimmedText)) {
            alert("Enter text for the script");
            return;
        }
        
        const newScript = {
            id: -1,
            name: trimmedName,
            text: trimmedText
        }

        setMutating(true);
        upsertScript({
            variables: { script: newScript },
            onError: () => { alert("Failed to create script"); setMutating(false); },
            onCompleted: (data) => {
                if (data) {
                    const newId = data.upsertCallCenterScript;
                    const insertedScript = {...newScript, id: newId, lastUpdated: todayAsMdy()}
                    let newScripts = [...scripts];
                    // add the script at the end of the existing scripts
                    newScripts.splice(newScripts.length - 1, 0, insertedScript);
                    // reset the new script tab
                    newScripts[newScripts.length - 1] = newScriptItem;
                    
                    setScripts(newScripts);
                    setOpenedImmutableScript(insertedScript);
                    setOpenedMutableScript(insertedScript);
                    setEditing(false);
                    setMutating(false);
                    alert("Script created successfully");
                }
            }
        });
    }

    return (<>
        {loading ? (
            <Loading />
        ) : (
            <div className="flex-row flex-gap-xsm">
                <Box id="tab-container" className="fit-content">
                    <Tabs
                        orientation="vertical"
                        value={openedImmutableScript.id} onChange={(_, newVal) => onTabChange(newVal)}
                    >
                        {scripts.map(item => (
                            <Tab
                                id={`${item.id === openedImmutableScript.id ? "active-script-tab" : ""}`}
                                key={`script-tab-${item.id}`}
                                className="script-tab"
                                wrapped
                                value={item.id}
                                label={item.name}
                            />
                        ))}
                    </Tabs>
                </Box>
    
                <div className="flex-column">
                    <div className="flex-row flex-space-between">
                        <InputBase
                            className="padding-none"
                            inputProps={{className: "padding-none"}}
                            inputRef={scriptNameInputRef}
                            style={{fontSize: "1.5rem", color: (openedMutableScript.name === "New Script") ? "red" : "black"}}
                            value={openedMutableScript.name} onChange={e => onNameChange(e.target.value)}
                            readOnly={!editing}    
                        />
    
                        <Button
                            className="fit-content" variant="contained"
                            onClick={() => window.open(`${callCenterSchedulerPath}`)} 
                        >Scheduler</Button>
                    </div>
    
                    <InputBase
                        minRows={10} multiline
                        inputProps={{id: "script-text-input"}}
                        value={openedMutableScript.text}
                        onChange={e => onTextChange(e.target.value)}
                        readOnly={!editing}
                    />
    
                    <div className="flex-row margin-top-sm flex-gap-sm justify-content-flex-end align-items-center">
                        {!editing && (
                            <Typography
                                style={{fontWeight: "bold", justifySelf: "flex-start"}}
                            >Last Updated: {openedImmutableScript.lastUpdated}</Typography>
                        )}

                        {newScriptMode ? (
                            <>
                                {mutating ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        className="submit-button" variant="contained"
                                        onClick={onCreateScript}
                                    >Create Script</Button>
                                )}
                            </>
                        ) : (
                            <>
                                {editing ? (
                                    <div className="flex-row flex-gap-sm">
                                        <Button
                                            className="cancel-button"
                                            variant="contained"
                                            onClick={onCancel}
                                        >Cancel</Button>
    
                                        <Button
                                            className="submit-button"
                                            variant="contained"
                                            onClick={onUpdateScript}
                                        >Update Script</Button>
                                    </div>
                                ) : (<>
                                    {mutating ? (
                                        <CircularProgress />
                                    ) : (
                                        <div className="flex-row">
                                            <IconButton
                                                onClick={onDeleteScript}
                                            ><DeleteIcon /></IconButton>
                                            <IconButton
                                                onClick={() => setEditing(true)}
                                            ><EditIcon /></IconButton>
                                        </div>
                                    )}</>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        )}
    </>)
}