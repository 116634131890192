import FlatButton from "FlatComponents/Button/FlatButton";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";

export default function TestPage() {
    return (
        <div>
            <FlatNavbar title="Test" />
            <div className="flex-column" style={{alignItems:"start", textAlign: "left", padding: "1rem"}}>
                <h3>Primary Color</h3>
                <div className="flex-row" style={{alignItems: "start"}}>
                    <FlatButton color="primary">Test Value</FlatButton>
                    <FlatButton color="primary" variant="outlined">Test Value</FlatButton>
                    <FlatButton color="primary" variant="contained">Test Value</FlatButton>
                    <FlatButton color="primary" variant="text">Test Value</FlatButton>
                </div>
                <h3>Secondary Color</h3>
                <div className="flex-row" style={{alignItems: "start"}}>
                    <FlatButton color="secondary">Test Value</FlatButton>
                    <FlatButton color="secondary" variant="outlined">Test Value</FlatButton>
                    <FlatButton color="secondary" variant="contained">Test Value</FlatButton>
                    <FlatButton color="secondary" variant="text">Test Value</FlatButton>
                </div>
                <h3>No Color</h3>
                <div className="flex-row" style={{alignItems: "start"}}>
                    <FlatButton>Test Value</FlatButton>
                    <FlatButton variant="outlined">Test Value</FlatButton>
                    <FlatButton variant="contained">Test Value</FlatButton>
                    <FlatButton variant="text">Test Value</FlatButton>
                </div>
                <h3>Disabled</h3>
                <div className="flex-row" style={{alignItems: "start"}}>
                    <FlatButton disabled>Test Value</FlatButton>
                    <FlatButton variant="outlined" disabled>Test Value</FlatButton>
                    <FlatButton variant="contained" disabled>Test Value</FlatButton>
                    <FlatButton variant="text" disabled>Test Value</FlatButton>
                </div>
            </div>
        </div>
        // <NavbarPage title="Test">
        //     <div style={{ width: "90vw", flexWrap: "wrap" }} className="flex-row">
        //         <FlatSection />
        //     </div>
        // </NavbarPage>
    );
}
