import { TextField } from "@material-ui/core"
import { MeasuredValue } from "Components/Inputs/MeasuredValueInput"
import SubSection from "Components/PageLayout/SubSection"
import { CARPET_PRODUCT_ID } from "Globals/globalConstants"
import { useEffect } from "react"
import { selectTypeId } from "Redux/addProductReducer"
import { useAppSelector } from "Redux/hooks"
import { InstallationMethodTable } from "./InstallationMethodTable"
import ProductDetailTable from "./ProductDetailTable"

export interface LocalProductDetail {
    type: "Unstructured" | "Structured",
    id: number,
    value: MeasuredValue | string
}

export interface IdToIds {
    id: number,
    group: number[]
}

export interface SpecEditorValues {
    sqft: string,
    productDetails: LocalProductDetail[],
    installationMethods: IdToIds[]
}

export interface SpecificationViewerProps {
    specName: JSX.Element | string,
    onChangeSpec?: (newSpec: string) => void,
    values: SpecEditorValues,
    setValues: (updateFunction: (prevValues: SpecEditorValues) => SpecEditorValues) => void,
    readOnly?: boolean
}

export default function SpecificationViewer({ specName, onChangeSpec, values, setValues, readOnly }: SpecificationViewerProps) {
    const typeId = useAppSelector(selectTypeId);

    //#region Event handlers
    function changeSqft(value: string) {
        setValues?.(v => ({ ...v, sqft: value }))
    }

    function addNewDetail(newDetail: LocalProductDetail) {
        setValues?.(v => ({ ...v, productDetails: [...v.productDetails, newDetail] }))
    }

    function removeDetail(detailIndex: number) {
        setValues?.(v => ({ ...v, productDetails: v.productDetails.filter((_, index) => index !== detailIndex) }))
    }

    function editDetail(detailIndex: number, newValue: LocalProductDetail) {
        setValues?.(v => {
            var newProductDetails = [...v.productDetails]
            newProductDetails[detailIndex] = newValue
            return { ...v, productDetails: newProductDetails }
        })
    }

    function toggleCheckbox(newValue: boolean, installId: number, subId: number) {
        setValues?.(v => {
            var updatedInstallMethod: IdToIds[] = [...v.installationMethods]
            if (newValue) {
                var affectedSubList = v.installationMethods.filter(m => m.id === installId)
                var updatedSubItem = { id: installId, group: (affectedSubList.length === 1) ? [...affectedSubList[0].group, subId] : [subId] }
                var replaceIndex = v.installationMethods.findIndex(m => m.id === updatedSubItem.id)

                if (replaceIndex === -1) {
                    updatedInstallMethod = [...updatedInstallMethod, updatedSubItem]
                }
                else {
                    updatedInstallMethod[replaceIndex] = updatedSubItem
                }
            }
            else {
                var targetOuter = v.installationMethods.findIndex(m => m.id === installId)
                if (targetOuter !== -1) {
                    var target = updatedInstallMethod[targetOuter]
                    var updatedTarget = { ...target, group: target.group.filter(g => g !== subId) }
                    if (updatedTarget.group.length === 0) updatedInstallMethod.splice(targetOuter, 1)
                    else updatedInstallMethod[targetOuter] = updatedTarget
                }
            }

            return { ...v, installationMethods: updatedInstallMethod }
        })

    }

    //#endregion

    //Sets defaults for editor
    useEffect(
        () => {
            if (typeId === CARPET_PRODUCT_ID && values.sqft === "") changeSqft("12")
        },
        // eslint-disable-next-line
        []
    )

    return (
        <div
            className="flex-column fill-width padding-xsm"
            style={{ alignItems: "flex-start" }}
        >
            <div className="fill-width solid-border rounded-border padding-xsm">
                <div
                    className="flex-row fill-width flex-gap-md"
                    style={{ alignItems: "flex-end" }}
                >
                    <div className="w-15r">
                        {typeof specName === "string" && <DisplayStringSpecName value={specName} onChange={onChangeSpec} />}
                        {typeof specName !== "string" && specName}
                    </div>

                    <TextField
                        margin="none"
                        variant="filled"
                        label="Sqft"
                        type="number"
                        value={values.sqft}
                        onChange={e => changeSqft(e.target.value)}
                        inputProps={{ readOnly }}
                    />
                </div>

                <div className="margin-top-sm">
                    <SubSection title="Product Details">
                        <ProductDetailTable
                            productDetails={values.productDetails}
                            addNewDetail={addNewDetail}
                            removeDetail={removeDetail}
                            editDetail={editDetail}
                            readOnly={readOnly}
                        />
                    </SubSection>
                </div>
                
                <div className="marin-top-sm">
                    <SubSection title="Installation Methods">
                        <InstallationMethodTable
                            selectedInstallationMethods={values.installationMethods}
                            onToggleCheckbox={toggleCheckbox}
                            readOnly={readOnly}
                        />
                    </SubSection>

                </div>
            </div>
        </div>
    )
}

function DisplayStringSpecName({ value, onChange }: { value: string, onChange?: (newValue: string) => void }) {
    return (
        <div className="flex-column margin-side-xsm">
            <label htmlFor="spec-name">Specification Name:</label>
            <TextField
                autoFocus
                name="spec-name"
                margin="none"
                value={value}
                disabled={onChange === undefined}
                onChange={(e) => onChange?.(e.target.value)} />
        </div>
    )
}
