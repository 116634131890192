import { MenuItem, Select } from "@material-ui/core";
import { useGetVendorsQuery } from "generated/graphql";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { clearAllCosts, selectCostVendorId, setCostsVendorId, selectCostProductTypeId, selectCostPageIsSoft } from "Redux/productCostsReducer";
import { promptUserIfChanges } from "../ProductCostsPage";

export default function FilterVendorEditor() {
    const dispatch = useAppDispatch()
    var vendorId = useAppSelector(selectCostVendorId)
    const productTypeId = useAppSelector(selectCostProductTypeId)
    const isSoft = useAppSelector(selectCostPageIsSoft)

    const pid = isSoft ? 4 : productTypeId

    const { data, loading } = useGetVendorsQuery({
        variables: { hasProductTypeId: pid },
        onCompleted(data) {
            if(data.vendors.length > 0) dispatch(setCostsVendorId(data.vendors[0].id))
        }
    })

    const vendorOptions = data?.vendors ?? []

    function setVendorId(newId: number) {
        if(promptUserIfChanges()){
            dispatch(clearAllCosts())
            dispatch(setCostsVendorId(newId))
        }
    }

    if(!vendorOptions.some(op=>op.id === vendorId)) vendorId = -1;

    return (
        <div className='flex-column w-10r margin-side-xxsm'>
            <label htmlFor='filter-vendor-editor'>For Vendor:</label>
            <Select name='filter-vendor-editor' value={vendorId} onChange={(e) => setVendorId(e.target.value as number)}>
                {
                    loading &&
                    <MenuItem value={-1}>Loading...</MenuItem>
                }
                <MenuItem value={-1} hidden>Please Select</MenuItem>
                {
                    vendorOptions.map(
                        vendor=><MenuItem key={`vendor-option-${vendor.id}`} value={vendor.id}>{vendor.name}</MenuItem>
                    )
                }
            </Select>
        </div>
    )
}