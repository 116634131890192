


interface StatusPillProps extends React.HTMLProps<HTMLDivElement> {
    label: string,
    backgroundColor?: "white" | "orange" | "brown" | "red" | "blue" | "green" | "purple"
    textColor?: "white" | "red" | "black"
    outlineColor?: "green" | "red"
}

function StatusPill({ label, backgroundColor, textColor, outlineColor, style, ...props }: StatusPillProps) {
    const hasOutline = outlineColor !== undefined

    backgroundColor = hasOutline ? undefined : (backgroundColor ?? "blue")
    textColor = textColor ?? "white"

    return (
    <div
        style={{
            backgroundColor: convertColor(backgroundColor),
            color: convertColor(textColor),
            outline: (hasOutline ? `solid min(2px, .05em) ${convertColor(outlineColor)}` : undefined),
            fontSize: ".7em",
            padding:".2em .4em",
            height:"min-content",
            lineHeight:".9em",
            borderRadius:"100vh",
            ...style
        }}
        {...props}
    >{label}</div>);
}

function convertColor(color?: string): string | undefined {
    switch(color) {
        case 'blue':
            return "#5FAAFF"
        case 'green':
            return "#36B54A"
        case 'red':
            return '#CB0000'
        case 'purple':
            return "var(--flat-purple)"
        default:
            return color;
    }
}

export default StatusPill;