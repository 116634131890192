import { Dialog, DialogContent, Typography } from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import FlexGrow from "Components/PageLayout/FlexGrow";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import { numericArraysEq } from "Globals/Helpers";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { Room } from "generated/graphql";
import { useState } from "react";
import { RoomIDAndServicesForRoom } from "../EditInstallationServicesMenu";
import { NewWallFinishOptionSelect } from "./NewWallFinishOptionSelect";
import { materialIdToName } from "./NewWallFinishUtils";
import clsx from "clsx";
import { isNullOrUndefined } from "Globals/GenericValidators";

interface NewWallServiceForRoomEditorProps {
    room: Room;
    services: RoomIDAndServicesForRoom[];  // should just be the services for this room
    setRoomMaterialInfo: (newInfo: {materialCategoryId: number, lnft: number}[]) => void;
    remove?: () => void;
}

export default function NewWallServiceForRoomEditor({
    room,
    services,
    setRoomMaterialInfo,
    remove
}: NewWallServiceForRoomEditorProps) {
    const [lnftEditorOpen, setLnftEditorOpen] = useState(false);

    function onSelectNewMaterials(newMaterialIds: number[]) {
        if (newMaterialIds.length === 0) {
            setRoomMaterialInfo([]);
        }

        // only need to do the update if the materials have changed
        if (!numericArraysEq(newMaterialIds, services.map(s => s.materialCategoryId!))) {
            // if the different services for some reason have different amounts, set the new service(s) to the maximum of these
            // if the previous selection was "None" (will be -Infinity), then set to the amount to the lnftage of the room
            let newLnft = Math.max(...services.map(s => s.laborAmount));
            if (newLnft === -Infinity || newLnft === Infinity) newLnft = room.lnft;
            setRoomMaterialInfo(newMaterialIds.map(matId => ({materialCategoryId: matId, lnft: newLnft})));
        }
    }

    const roomName = getNameOfArea(room.labels);

    const selectionIsNone = isNullOrUndefined(services.find(s => s.roomId === room.id));

    return (<>
        <span
            className={clsx({"clickable": !selectionIsNone})}
            onClick={selectionIsNone ? undefined : () => setLnftEditorOpen(true)}
        >
            <Typography
                style={{ fontSize: "1.15rem", padding: "0 .25rem 0 .75rem" }}
            >
                [{roomName}]
            </Typography>
        </span>

        <NewWallFinishOptionSelect
            keyPrefix={`r-${room.id}`}
            isForEntireArea={false}
            services={services}
            setMaterialCategories={onSelectNewMaterials}
            onSelectNone={remove}
        />

        {lnftEditorOpen && (
            <LnftDialog
                roomName={roomName}
                selectedFinishes={services.map(s => ({
                    id: s.materialCategoryId!,
                    name: materialIdToName[s.materialCategoryId!],
                    lnft: s.materialAmount!
                }))}
                setFinishLnftages={newMaterialInfo => setRoomMaterialInfo(
                    newMaterialInfo.map(nmi => ({materialCategoryId: nmi.id, lnft: nmi.lnft}))
                )}
                closeDialog={() => setLnftEditorOpen(false)}
            />
        )}
    </>)
}

interface FinishMaterialDetails {
    id: number;
    name: string;
    lnft: number;
}

interface LnftDialogProps {
    roomName: string;
    selectedFinishes: FinishMaterialDetails[];
    setFinishLnftages: (newLnftages: FinishMaterialDetails[]) => void;
    closeDialog: () => void;
}

function LnftDialog({
    roomName,
    selectedFinishes: originalFinishes,
    setFinishLnftages,
    closeDialog
}: LnftDialogProps) {
    const [selectedFinishes, setSelectedFinishes] = useState(originalFinishes);

    function onChangeLnft(materialId: number, newLnft: string) {
        let newLnftNum = +newLnft;
        if (isNaN(newLnftNum)) {
            newLnftNum = 0;
        }
        const idx = selectedFinishes.findIndex(sf => sf.id === materialId);
        const newSelectedFinishes = [...selectedFinishes];
        newSelectedFinishes.splice(idx, 1, {...selectedFinishes[idx], lnft: newLnftNum});
        setSelectedFinishes(newSelectedFinishes);
    }

    function onSubmit() {
        setFinishLnftages(selectedFinishes);
        closeDialog();
    }

    return (
        <Dialog open>
            <DialogContent>
                <Typography variant="h6">
                    {roomName}
                </Typography>

                {selectedFinishes.map(sf => (
                    <div className="flex-row" key={sf.id}>
                        <FlatLabeledInput
                            label={sf.name}
                            value={sf.lnft}
                            type="numeric"
                            onChange={e => onChangeLnft(sf.id, e.target.value)}
                            className={"w-2r text-align-center"}
                        />

                        <p className="flat-font margin-none">
                            lnft
                        </p>
                    </div>
                ))}

                <div className="flex-row padding-xsm">
                    <FlexGrow />
                    <SpacedButton
                        variant="outlined"
                        onClick={closeDialog}
                    >
                        Cancel
                    </SpacedButton>
                    <SpacedButton
                        variant="contained"
                        onClick={onSubmit}
                    >
                        Submit
                    </SpacedButton>
                </div>
            </DialogContent>
        </Dialog>
    )
}
