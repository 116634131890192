import { Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import {
    AppliedDiscount, PriceRequestUpdatedForJobDocument, PriceRequestUpdatedForJobSubscription, useGetJobConfigurationAreaIdsQuery,
    useGetJobConfigurationHeaderQuery, useGetOverrideDiscountForJobQuery, useGetPriceRequestQuery
} from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { buildAppendedId, useNumericIdParam } from "Globals/Hooks";
import { projectEditorPath } from "Globals/PathStrings";
import { createContext, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import JobHeader from "../../JobHeader";
import NoQuoteButton from "../NoQuoteButton";
import "../SellSheet.css";
import HeaderColumn from "./HeaderColumn";
import ScrollingAreaColumns from "./ScrollingAreaColumns";
import TotalColumn from "./TotalColumn";


export const PreventChangesContext = createContext(false);

export type SaleSheetStylingClasses = ReturnType<typeof useSaleSheetStyles>
export const useSaleSheetStyles = (props?: any) => makeStyles((theme: Theme) => {

    const mainContentWidthPadding = "2rem"
    const mainContentHeight = "88vh"
    const scrollableColumnWidth = `calc((100vw - 2 * ${mainContentWidthPadding}) * .2)`

    return createStyles({
        mainContent: {
            padding: "0 2em",
            height: mainContentHeight,
            maxHeight: mainContentHeight
        },
        footer: {
            flex: 1,
        },
        outerScrollableColumns: {
            maxWidth: "60%",
            position: "relative"

        },
        innerScrollableColumns: {
            maxWidth: "100%",
            display: 'flex',
            overflowX: 'auto',
            overflowY: 'hidden'
        },
        headerColumn: {
            width: "20%",
            minWidth: "20%",
            height: mainContentHeight,
        },
        areaColumn: {
            width: scrollableColumnWidth,
            minWidth: scrollableColumnWidth,
            height: mainContentHeight
        },
        totalColumn: {
            width: "20%",
            height: mainContentHeight,
            borderLeftStyle: "solid",
            borderLeftColor: "black",
            borderWidth: "1px"
        },
        disabledOverride: {
            color: "rgba(0,0,0,1) !important"
        },
        canScrollArrow: {
            position: "absolute",
            top: "4rem",
            width: "3rem",
            height: "3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: ".5rem",
            backgroundColor: "rgba(0,0,0,.2)",
            opacity: .8,
            userSelect: "none",
        }
    });
})(props);

export function formatDiscount(discount: AppliedDiscount, isTotal?: boolean) {
    const { amount, isScalar } = discount
    if (isScalar) {
        return `- ${((1 - amount) * 100).toFixed(0)}%`
    }
    else {
        return (isTotal) ?
            `- $${(amount).toFixed((amount % 1 === 0) ? 0 : 2)}` :
            "-"
    }
}

export function TypographyDiscount({ discount, isTotal }: { discount: AppliedDiscount, isTotal?: boolean }) {
    const grayOut = !discount.isValid;

    return (
        <Typography
            key={`total-discount-${discount.id}`}
            className={clsx('flex-row-center h-2r', { 'color-gray': grayOut })}>
            {formatDiscount(discount, isTotal)}
        </Typography>
    )
}

export function FlatDiscount({ discount, isTotal }: { discount: AppliedDiscount, isTotal?: boolean }) {
    return (
        <div
            key={`total-discount-${discount.id}`}
            className={clsx('flat-font-sm flex-row-center')}>
            {formatDiscount(discount, isTotal)}
        </div>
    )
}

export default function OldSellSheet() {
    const { id: jobConfigurationId } = useNumericIdParam();
    const { data: jobData } = useGetJobConfigurationHeaderQuery({
        variables: {
            jobConfigurationId: jobConfigurationId!
        },
        skip: isNullOrUndefined(jobConfigurationId)
    });
    let jobId = jobData?.jobConfigurationHeader.id ?? -1;

    const { data, loading, error } =
        useGetJobConfigurationAreaIdsQuery(
            { variables: { jobConfigurationId: jobConfigurationId ?? 0 }, skip: jobConfigurationId === undefined, fetchPolicy: "network-only" }
        )
    const areaIds = data?.jobConfigurationAreaIds ?? []

    const [preventChanges, setPreventChanges] = useState(false);

    const { data: overrideRequestData, subscribeToMore: subscribeToRequestUpdates } = useGetPriceRequestQuery({
        variables: {
            jobConfigurationId: jobConfigurationId!
        },
        skip: isNullOrUndefined(jobConfigurationId),
    });
    const overrideRequest = overrideRequestData?.priceRequest;

    const listenForOverrideRequest = useCallback(() => {
        if (subscribeToRequestUpdates && jobId > 1) {
            subscribeToRequestUpdates({
                document: PriceRequestUpdatedForJobDocument,
                variables: {
                    jobId: jobId
                },
                updateQuery: (prev, { subscriptionData }) => {
                    const newRequest = (subscriptionData.data as unknown as PriceRequestUpdatedForJobSubscription).priceRequestUpdatedForJob;
                    if (!newRequest) { return prev; }
                    return { ...prev, priceOverrideRequest: newRequest }
                }
            });
        }
    }, [subscribeToRequestUpdates, jobId]);

    useEffect(() => {
        if (listenForOverrideRequest) {
            listenForOverrideRequest();
        }
    }, [listenForOverrideRequest]);


    // const { data: overrideDiscountData, subscribeToMore: subscribeToOverrideDiscount } = useGetOverrideDiscountForJobQuery({
    const { data: overrideDiscountData } = useGetOverrideDiscountForJobQuery({
        variables: {
            jobConfigurationId: jobConfigurationId!
        },
        fetchPolicy: "network-only",
        skip: isNullOrUndefined(jobConfigurationId)
    });
    const overrideDiscount = overrideDiscountData?.overrideDiscountForJob;

    // const listenForOverrideDiscount = useCallback(() => {
    //     if (subscribeToOverrideDiscount && jobConfigurationId) {
    //         subscribeToOverrideDiscount({
    //             document: OverrideDiscountSentDocument,
    //             variables: {
    //                 jobConfigurationId: jobConfigurationId
    //             },
    //             updateQuery: (prev, { subscriptionData }) => {
    //                 let newOverrideDiscount = (subscriptionData.data as unknown as OverrideDiscountSentSubscription).overrideDiscountSent;
    //                 if ((newOverrideDiscount?.id ?? 0) > 0 && newOverrideDiscount?.isValid && newOverrideDiscount?.authorizationCode) {
    //                     return { overrideDiscountForJob: newOverrideDiscount };
    //                 }
    //                 return { overrideDiscountForJob: null };
    //             }
    //         });
    //     }
    // }, [subscribeToOverrideDiscount, jobConfigurationId]);

    // useEffect(() => {
    //     if (listenForOverrideDiscount) {
    //         listenForOverrideDiscount();
    //     }
    // }, [listenForOverrideDiscount]);


    // prevent changes if there's a current override, or a request that hasn't been responded to
    useEffect(() => {
        if (overrideDiscount || (overrideRequest && !overrideRequest.timeResponded)) {
            setPreventChanges(true);
        } else {
            setPreventChanges(false);
        }
    }, [overrideRequest, overrideDiscount]);

    const classes = useSaleSheetStyles();
    const history = useHistory();

    const jobValid = !(error || jobConfigurationId === undefined)
    const title = (error || jobConfigurationId === undefined) ? "No Quote Found" : "Total Savings"

    // const { data: chatPartyData, subscribeToMore } = useGetChatPartiesForJobQuery({
    //     variables: { jobId: jobId! },
    //     skip: isNullOrUndefined(jobId) || jobId < 1
    // });

    // const jobParty = chatPartyData?.chatPartiesForJob.jobParty;
    // const jobPartyId = jobParty?.id ?? -1;
    // const commandCenterParty = chatPartyData?.chatPartiesForJob.commandCenterParty;

    // const listenForCcPartyChange = useCallback(() => {
    //     if (subscribeToMore) {
    //         subscribeToMore({
    //             document: JobChatCommandCenterPartyChangedDocument,
    //             variables: { jobId: jobId },
    //             updateQuery: (prev, { subscriptionData }) => {
    //                 const newCcParty = (subscriptionData.data as unknown as JobChatCommandCenterPartyChangedSubscription).jobChatCommandCenterPartyChanged;
    //                 if (!newCcParty) { return prev; }
    //                 return { chatPartiesForJob: { jobParty: prev.chatPartiesForJob.jobParty, commandCenterParty: newCcParty } };
    //             }
    //         });
    //     }
    // }, [subscribeToMore, jobId]);

    // useEffect(() => {
    //     if (listenForCcPartyChange) { listenForCcPartyChange(); }
    // }, [listenForCcPartyChange]);

    // const [chatOpen, setChatOpen] = useState(false);
    // const { data: salespersonData } = useGetAuthenticatedWorkerQuery();
    // let salesperson = salespersonData?.authenticatedWorker ?? undefined;

    // // open the chat when a message is sent from the command center
    // useMessageSentToChatPartySubscription({
    //     variables: {
    //         recipientChatPartyId: jobPartyId
    //     },
    //     skip: jobPartyId < 1,
    //     onSubscriptionData: (data) => {
    //         const thisSenderParty = data.subscriptionData.data?.messageSentToChatParty;
    //         // only open the chat if the sending party is the CC (that's the only party the chat includes)
    //         if (thisSenderParty && commandCenterParty && (thisSenderParty.id === commandCenterParty!.id)) {
    //             setChatOpen(true);
    //         }
    //     }
    // });

    return (
        <NavbarPage hideNavbar>
            <JobHeader title={title} />
            {loading && <Loading />}
            {(areaIds.length === 0 && jobValid) &&
                <div className={clsx(classes.mainContent, "flex-column")} style={{ justifyContent: "flex-start", alignItems: "center" }}>
                    <h1>No Areas Defined for Job</h1>
                    <h3>Please add areas</h3>
                    <Button variant="contained" color="primary" onClick={() => history.push(projectEditorPath + buildAppendedId(jobConfigurationId))}>Go to Editor</Button>
                </div>
            }

            {preventChanges && <Typography>Changes locked while waiting for command center response</Typography>}

            {(areaIds.length > 0 && jobValid) &&
                <div className={classes.mainContent} style={{ display: "flex", justifyContent: "center" }}>
                    <PreventChangesContext.Provider value={preventChanges}>
                        <>
                            <ScrollingAreaColumns areaIds={areaIds} />
                            <HeaderColumn authCode={overrideDiscount?.authorizationCode ?? undefined} />
                            <TotalColumn authCode={overrideDiscount?.authorizationCode ?? undefined} />
                        </>
                    </PreventChangesContext.Provider>
                </div>
            }

            <NoQuoteButton jobConfigurationId={jobConfigurationId ?? -1} />

            {/* {(chatOpen && jobConfigurationId && commandCenterParty && jobParty) ? (
                <ChatWindow
                    sendingParty={jobParty!}
                    receivingParty={commandCenterParty!}
                    sendingWorker={salesperson!}  // won't ever be able to open the chat if the user is not authenticated
                    closeWindow={() => setChatOpen(false)}
                    actionComponent={<PriceRequestInput />}
                />
            ) : (
                <IconButton
                    id="chat-btn"
                    disabled={!commandCenterParty || !jobParty || isNullOrUndefined(jobConfigurationId)}
                    onClick={() => setChatOpen(true)}
                ><ChatBubbleIcon /></IconButton>
            )} */}
        </NavbarPage>
    )
}