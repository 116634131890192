import { Button, Popover, Typography } from "@material-ui/core";
import { forwardRef, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";

interface PopoutSignatureProps {
    isPrintMode: boolean;
    open: boolean;
    renderSignatureInstead?: string;
    setOpen?: (o: boolean) => void;
    locked?: boolean;
    hideXAndLine?: boolean;
}

export const PopoutSignature = forwardRef<HTMLImageElement | null, PopoutSignatureProps>(
    (
        { isPrintMode, open, renderSignatureInstead, setOpen, locked, hideXAndLine }: PopoutSignatureProps,
        signatureRef
    ) => {
        const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);

        const url = renderSignatureInstead ?? imgUrl;

        const popoverRef = useRef<HTMLDivElement>(null);
        const sigPadRef = useRef<SignaturePad | null>(null);

        function onSave() {
            setImgUrl(sigPadRef.current?.getTrimmedCanvas().toDataURL("image/png"));
            setOpen?.(false);
        }

        function onClear() {
            sigPadRef.current?.clear();
        }

        function onClose() {
            setOpen?.(false);
        }

        return (
            <>
                <div
                    ref={popoverRef}
                    className="flex-row fill-height fill-width minh-6r"
                    onClick={() => {
                        if (!locked) setOpen?.(true);
                    }}
                >
                    <Button
                        disabled={locked}
                        style={{ width: "4rem", visibility: isPrintMode ? "hidden" : "visible" }}
                        variant="contained"
                        onClick={() => setOpen?.(true)}       
                    >
                        {imgUrl === undefined ? "Sign" : "Resign"}
                    </Button>

                    {url && (
                        <img
                            ref={signatureRef}
                            src={url}
                            alt="signature"
                            style={{
                                objectFit: "scale-down",
                                maxHeight: "6rem",
                                maxWidth: "calc(100% - 4rem)",
                            }}
                        />
                    )}
                </div>
                <Popover
                    open={open}
                    anchorEl={popoverRef.current}
                    transformOrigin={{ horizontal: "center", vertical: "center" }}
                    anchorOrigin={{ horizontal: "center", vertical: "center" }}
                    onClose={onClose}
                >
                    <div className="flex-column faint-gray-bkg">
                        <SignaturePad
                            ref={sigPadRef}
                            canvasProps={{
                                style: { width: "50rem", height: "10rem", background: "white" },
                            }}
                        />
                        <div className="flex-row flex-space-evenly">
                            <Button
                                onClick={onClear}
                                variant="contained"
                            >
                                Clear
                            </Button>
                            <Button
                                onClick={onSave}
                                variant="contained"
                            >
                                Apply
                            </Button>
                        </div>
                        <Typography
                            hidden={hideXAndLine}
                            style={{
                                position: "absolute",
                                fontSize: "5rem",
                                fontWeight: "lighter",
                                top: "1.25rem",
                                left: "0.25rem",
                                pointerEvents: "none",
                            }}
                        >
                            X
                        </Typography>
                        <div
                            hidden={hideXAndLine}
                            style={{
                                position: "absolute",
                                borderBottom: ".25rem solid black",
                                width: "40rem",
                                top: "8rem",
                                left: "5rem",
                            }}
                        />
                    </div>
                </Popover>
            </>
        );
    }
);
