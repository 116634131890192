import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core"
import Loading from "Components/Loading"
import { HBar } from "Components/pageSpacing"
import { GetSubstrateIdQuery, ServiceForRoom, ServiceTypeOption, SubstrateId, useGetProductTypeOptionsQuery, useGetServiceTypeOptionsQuery, useGetSubstrateIdQuery } from "generated/graphql"
import { isNumber } from "Globals/GenericValidators"
import { CARPET_PRODUCT_ID, INSTALLATION_ID, SPC_PRODUCT_ID, WOOD_PRODUCT_ID } from "Globals/globalConstants"
import { useState } from "react"
import { openServicesForRoomDialog, selectServicesForRoomDialogLabels, selectServicesForRoomDialogOpen, selectServicesForRoomDialogProductTypeId, selectServicesForRoomDialogServices, selectServicesForRoomDialogSqft, selectServicesForRoomDialogSubstrate, updateRoom } from "Redux/genericSalesReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType"
import { store } from "Redux/store"
import './generic-quote-builder.css'
import ServicesByTypeEditor, { ServiceIdValue } from "./ServicesByTypeEditor"
import SubstrateEditorBlock from "./SubstrateEditorBlock"


export default function ServicesForRoomDialog() {
    const editorOpen = useAppSelector(selectServicesForRoomDialogOpen)
    return <>{editorOpen && <ServicesForRoomDialogInner />}</>
}

export type IdMap = { [id: number]: ServiceIdValue[] }
export type SubstrateIdValue = { substrateId: number, value: string }

export function getServiceIdValues(services: ServiceForRoom[], serviceTypeId: number, altTypeIds?: number[]): ServiceIdValue[] {
    const filtered = services.filter(s => s.serviceTypeId === serviceTypeId || altTypeIds?.includes(s.serviceTypeId))
    function toServiceIdValue(s: ServiceForRoom): ServiceIdValue {
        return {
            jobServiceId: s.jobServiceId,
            value: s.laborAmount.toString(),
            custDoesService: s.customerDoesService,
            isActive: s.isActive,
            sourceServiceForRoomId: s.id,
            materialCategoryId: s.materialCategoryId ?? undefined,
            materialAmount: s.materialAmount?.toString()
        }
    }
    return filtered.map(toServiceIdValue)
}

// get the possible service options for the material type, and a function to convert a service ID to a backend compatible format
export function useGetServiceOptionsAndConversionFunction(productTypeId: number) {
    var { data, loading, error } = useGetServiceTypeOptionsQuery({ variables: { productTypeId: productTypeId } });

    function serviceIdValueToServiceForRoom(idValue: ServiceIdValue, overrideRoomId?: number): ServiceForRoom {

        if (!isNumber(idValue.value)) throw new Error("Labor Amount is undefined")
        if ((idValue.materialCategoryId ?? 0) > 0 && !isNumber(idValue.materialAmount ?? "")) throw new Error("Material Amount is invalid")

        var serviceType: ServiceTypeOption | undefined =
            data?.serviceTypeOptions.find(type => type.jobServices.some(js => js.jobServiceId === idValue.jobServiceId))

        if (serviceType === undefined) throw new Error('Could not find job service for service value')

        return {
            id: idValue.sourceServiceForRoomId ?? -1,
            roomId: overrideRoomId ?? -1,
            jobServiceId: idValue.jobServiceId,
            serviceTypeId: serviceType!.serviceTypeId,
            laborAmount: +idValue.value,
            customerDoesService: idValue.custDoesService,
            isActive: idValue.isActive,
            materialCategoryId: idValue.materialCategoryId,
            materialAmount: (idValue.materialAmount !== undefined)
                ? +(idValue.materialAmount!)
                : undefined
        }
    }

    return { data: data, loading: loading, error: error, convertToBE: serviceIdValueToServiceForRoom }
}

function ServicesForRoomDialogInner() {
    const dispatch = useAppDispatch()

    const productTypeId = useAppSelector(selectServicesForRoomDialogProductTypeId)!
    const sqft = useAppSelector(selectServicesForRoomDialogSqft)
    const reduxServices = useAppSelector(selectServicesForRoomDialogServices) ?? []
    const reduxLabels = useAppSelector(selectServicesForRoomDialogLabels) ?? []

    const { data: ptypes } = useGetProductTypeOptionsQuery()
    const { data: subTypes } = useGetSubstrateIdQuery()

    const { data, loading, error, convertToBE: serviceIdValueToFullService } = useGetServiceOptionsAndConversionFunction(productTypeId);

    function onClose() {
        dispatch(openServicesForRoomDialog(-1))
    }

    const allDataLoaded = (!loading && !error) && (subTypes?.substrateId?.length ?? 0) > 0

    const startingServices: IdMap = {}
    var displayAdjustedServiceTypeOptions: ServiceTypeOption[] = []
    if (allDataLoaded) {
        const findServiceTypeIdsWhereDisplayTypeIdEquals = (targetDisplayTypeId?: number): number[] =>
            data?.serviceTypeOptions.filter(type => (type.displayServiceTypeId ?? undefined) === targetDisplayTypeId).map(type => type.serviceTypeId) ?? []

        const findServiceType = (serviceTypeId: number): ServiceTypeOption => data?.serviceTypeOptions.find(type => type.serviceTypeId === serviceTypeId)!

        const uniqueServiceTypeIds = findServiceTypeIdsWhereDisplayTypeIdEquals(undefined)
        const serviceTypeAndSubTypes = uniqueServiceTypeIds.map(serviceTypeId => ({ serviceTypeId, subTypeIds: findServiceTypeIdsWhereDisplayTypeIdEquals(serviceTypeId) }))

        const servicesToAdd = (reduxServices.length === 0)
            ? createDefaultServicesForType(productTypeId, sqft ?? 0)
            : reduxServices

        serviceTypeAndSubTypes.forEach(({ serviceTypeId, subTypeIds }) => {
            startingServices[serviceTypeId] = getServiceIdValues(servicesToAdd, serviceTypeId, subTypeIds)
        })

        displayAdjustedServiceTypeOptions = serviceTypeAndSubTypes.map(
            ({ serviceTypeId, subTypeIds }) => {
                const serviceType = findServiceType(serviceTypeId);

                return {
                    ...serviceType,
                    jobServices: [...serviceType.jobServices, ...subTypeIds.flatMap(id => findServiceType(id).jobServices)]
                }
            })
    }

    return (
        <Dialog
            open={true}
            maxWidth='lg'
            fullScreen
        >
            <DialogTitle>
                <div className='flex-row-center fill-width'>
                    <h3 className='margin-sm'>
                        Installation Detail <br />
                        <b>{ptypes?.options.find(p => p.id === productTypeId)?.type ?? "Loading..."}</b> in <b>{getNameOfArea(reduxLabels)}</b>
                    </h3>
                </div>
                <HBar />
            </DialogTitle>
            {
                !allDataLoaded &&
                <LoadingDialogContent onClose={onClose} />
            }
            {
                allDataLoaded &&
                <ServicesForRoomDialogContent
                    substrateIds={subTypes!.substrateId}
                    displayAdjustedServiceTypeOptions={displayAdjustedServiceTypeOptions}
                    onClose={onClose}
                    serviceIdValueToFullService={serviceIdValueToFullService}
                    defaultServices={startingServices} />
            }
        </Dialog>
    )
}

function LoadingDialogContent({ onClose }: { onClose: () => void }) {
    return (
        <>
            <DialogContent>
                <Loading />
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    className='cancel-button'
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    className='submit-button'
                    disabled={true}
                >
                    Submit
                </Button>
            </DialogActions>
        </>
    )
}

interface ServicesForRoomDialogContentProps {
    substrateIds: GetSubstrateIdQuery['substrateId'],
    displayAdjustedServiceTypeOptions: ServiceTypeOption[]
    onClose: () => void,
    serviceIdValueToFullService: (idValue: ServiceIdValue, overrideRoomId?: number) => ServiceForRoom,
    defaultServices: IdMap
}

function ServicesForRoomDialogContent({ onClose, serviceIdValueToFullService, defaultServices, substrateIds, displayAdjustedServiceTypeOptions }: ServicesForRoomDialogContentProps) {
    const dispatch = useAppDispatch()
    const reduxSubstrate = useAppSelector(selectServicesForRoomDialogSubstrate)

    const [services, setServices] = useState<IdMap>(defaultServices)
    const [substrate, setSubstrate] = useState<number>(reduxSubstrate ?? -1)

    const [err, setErr] = useState<string>("")

    function updateServices(typeId: number, newServices: ServiceIdValue[]) {
        setServices(prev => {
            const copy = { ...prev }
            copy[typeId] = newServices
            return copy
        })
    }

    function setErrorAndFlash(value: string) {
        const textEl = document.getElementById('service-for-room-dialog-error-text')
        if (textEl !== null && !textEl.classList.contains('flash-animation')) {
            textEl.classList.add('flash-animation')
            setTimeout(() => textEl.classList.remove('flash-animation'), 500);
        }
        setErr(value)
    }

    function onSubmit() {
        const sales = store.getState().genericSales
        const roomIndex = sales.servicesEditorDialogIndex
        const areaIndex = sales.selectedAreaIndex

        if (substrate === -1) {
            setErrorAndFlash("Please specify room substrate")
            return;
        }
        else if (services[INSTALLATION_ID].length === 0) {
            setErrorAndFlash("Please add installation method")
            return;
        }

        try {
            const roomId = sales.jobConfiguration?.areas[areaIndex].id ?? undefined
            const flatServices = Object.keys(services)
                .flatMap(key => services[+key].map(ser => serviceIdValueToFullService(ser, roomId)))

            dispatch(updateRoom(areaIndex, roomIndex, { services: flatServices, substrateId: substrate }))
            onClose()
        }
        catch {
            setErrorAndFlash("There is a problem in the highlighted fields")
        }
    }

    return (
        <>
            <DialogContent>
                <div className='margin-sm'>
                    <CurrentInstallationEditor
                        showError={err !== ""}
                        displayAdjustedServiceTypeOptions={displayAdjustedServiceTypeOptions}
                        services={services} setServices={updateServices}
                        substrateOptions={substrateIds}
                        substrate={substrate} setSubstrate={setSubstrate}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Typography id='service-for-room-dialog-error-text'>
                    {err}
                </Typography>
                <Button
                    variant='contained'
                    className='cancel-button'
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    className='submit-button'
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </>
    )
}

function createDefaultServicesForType(productTypeId: number, sqft: number): ServiceForRoom[] {
    if (productTypeId === WOOD_PRODUCT_ID) {
        return [{
            id: -1,
            roomId: -1,
            jobServiceId: 5,
            serviceTypeId: 3,
            laborAmount: sqft,
            customerDoesService: false,
            isActive: true
        }]
    }
    else if (productTypeId === SPC_PRODUCT_ID) {
        return [{
            id: -1,
            roomId: -1,
            jobServiceId: 31,
            serviceTypeId: 3,
            laborAmount: sqft,
            customerDoesService: false,
            isActive: true
        }]
    }
    else if (productTypeId === CARPET_PRODUCT_ID) {
        return [{
            id: -1,
            roomId: -1,
            jobServiceId: 1,
            serviceTypeId: 3,
            laborAmount: sqft,
            customerDoesService: false,
            materialAmount: sqft,
            materialCategoryId: 4,
            isActive: true
        }]
    }
    else return []
}

interface CurrentInstallationEditorProps {
    showError?: boolean
    displayAdjustedServiceTypeOptions: ServiceTypeOption[],
    services: IdMap,
    setServices: (typeId: number, newValues: ServiceIdValue[]) => void,
    substrateOptions: SubstrateId[],
    substrate: number,
    setSubstrate: (newSubstrate: number) => void
}

export function CurrentInstallationEditor({ showError, displayAdjustedServiceTypeOptions, services, setServices, substrateOptions, substrate, setSubstrate }: CurrentInstallationEditorProps) {
    function renderServiceOption(serviceTypeOptions: ServiceTypeOption) {

        return <ServicesByTypeEditor
            showError={showError}
            key={`service-type-opt-${serviceTypeOptions.serviceTypeId}`}
            options={serviceTypeOptions}
            values={services[serviceTypeOptions.serviceTypeId] ?? []}
            setValues={(newValues) => {
                setServices(serviceTypeOptions.serviceTypeId, newValues)
            }} />
    }

    // const displayIds: number[] = []
    // serviceOptions.forEach(off => {
    //     if (!displayIds.includes(off.service.id) && ((off.service.displayTypeId ?? 0) === 0 || !displayIds.includes(off.service.displayTypeId!))) {
    //         displayIds.push(off.service.displayTypeId ?? off.service.id)
    //     }
    // })

    // const secondHalfIds = displayIds.slice(1)

    // function filterServiceOptionsToTypeId(typeId: number) {
    //     return serviceOptions.filter(off => {
    //         return (off.service.displayTypeId ?? off.service.id) === typeId
    //     })
    // }



    return (
        <>
            {
                renderServiceOption(displayAdjustedServiceTypeOptions[0])
            }
            <SubstrateEditorBlock
                showError={showError}
                substrateOptions={substrateOptions}
                substrate={substrate}
                setSubstrate={setSubstrate} />
            {
                displayAdjustedServiceTypeOptions.filter((_, index) => index > 0).map(renderServiceOption)
            }
        </>
    )
}