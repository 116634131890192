import QuoteCardIconButton from "./QuoteCardIconButton";
import { useCopyJobConfigurationMutation } from 'generated/graphql'

interface AddScenarioButtonProps {
    isCopy?: boolean,
    jobConfigurationId: number
    addCard: (newId: number) => void,
    nextOptionNumber: number
}

export default function AddScenarioButton({ isCopy, jobConfigurationId, addCard, nextOptionNumber }: AddScenarioButtonProps) {
    const [copyJob, { loading }] = useCopyJobConfigurationMutation({
        onCompleted(data) {
            addCard(data.copyJobConfiguration)
        }
    })

    function addScenario() {
        copyJob({ variables: { jobConfigurationId, optionNumber: nextOptionNumber } })
    }

    return (
        <QuoteCardIconButton
            hideButton={isCopy}
            disabled={loading}
            variant='outlined'
            color="secondary"
            onClick={addScenario}>
            +
        </QuoteCardIconButton>
    )
}