import Gallery from "Components/ImageGallery/Gallery";
import Loading from "Components/Loading";
import { ToggleableSubSection } from "Components/PageLayout/Section";
import FlatSection from "FlatComponents/Layout/FlatSection";
import {
    namedOperations,
    useDeletePictureForServiceOrderMutation,
    useGetJobConfigurationQuery,
    useGetServiceOrderStubsQuery
} from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { selectJobConfigurationId, selectJobContractId } from "Redux/jobTrackerReducer";
import { getStubText } from "../Services/ServiceOrderPage";
import { RoomGalleryImage } from "./Generics/RoomGalleryImage";
import JobPictureViewer, { RoomGalleryImageBaseProps } from "./JobPictureViewer";
import { AfterPictureGallery } from "./TypeSpecific/AfterPictureGallery";
import AfterPictureUploadButton from "./TypeSpecific/AfterPictureUploadButton";
import { BeforePictureGallery } from "./TypeSpecific/BeforePictureGallery";
import BeforePictureUploadButton from "./TypeSpecific/BeforePictureUploadButton";
import JobPictureUploadButton from "./TypeSpecific/JobPictureUploadButton";

interface ServicePhotosSubsectionProps {
    title: string;
    images: RoomGalleryImageBaseProps[];
    deleteServiceOrderPicture?: (id: number) => void;
}

export default function JobPicturesPage() {
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);

    const { data, loading } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        skip: isNullOrUndefined(jobConfigurationId) && jobConfigurationId! <= 0,
    });

    const contractId = useAppSelector(selectJobContractId);

    const [serviceSubSections, setServiceSubsections] = useState<ServicePhotosSubsectionProps[]>(
        []
    );
    useGetServiceOrderStubsQuery({
        variables: { jobContractId: contractId! },
        skip: isNullOrUndefined(contractId),
        onCompleted: (res) => {
            let subsections: ServicePhotosSubsectionProps[] = [];
            res.serviceOrderStubs.forEach((stub) => {
                let pictures: RoomGalleryImageBaseProps[] = stub.pictures.map((picture) => ({
                    label: "",
                    description: picture.picture?.description ?? "",
                    pictureId: picture.picture?.id ?? -1,
                    pictureForRoomId: picture.id,
                }));

                let subsection: ServicePhotosSubsectionProps = {
                    title: getStubText(stub),
                    images: pictures,
                };

                subsections.push(subsection);
            });
            setServiceSubsections(subsections);
        },
    });

    const [deleteServiceOrderPicture] = useDeletePictureForServiceOrderMutation({
        onError: () => alert("Could not delete image"),
        refetchQueries: [namedOperations.Query.GetServiceOrderStubs],
        awaitRefetchQueries: true,
    });

    return (
        <>
            {isNullOrUndefined(data) || loading ? (
                <Loading />
            ) : (
                <div className="padding-top-sm margin-bottom-md">
                    <div className="flex-column flex-gap-sm">
                        <div className="flex-column flex-gap-sm">
                            <FlatSection
                                header="Job Photos"
                                collapsible
                                endAdornment={
                                    <JobPictureUploadButton
                                        jobConfigurationId={jobConfigurationId}
                                    />}
                                >
                                <JobPictureViewer
                                    jobConfigurationId={jobConfigurationId ?? -1}
                                    rowMax={2}
                                    showUploadButton={false}
                                />
                            </FlatSection>

                            <FlatSection
                                header="Before Photos" collapsible
                                endAdornment={
                                    <BeforePictureUploadButton
                                        jobConfigurationId={jobConfigurationId ?? -1}
                                    />
                                }
                            >
                                <BeforePictureGallery jobConfigurationId={jobConfigurationId ?? -1} />
                            </FlatSection>
                        </div>

                        <div className="flex-gap-sm">
                            <FlatSection
                                header="After Photos"
                                collapsible
                                endAdornment={
                                    <AfterPictureUploadButton
                                        jobConfigurationId={jobConfigurationId ?? -1}
                                    />
                                }
                            >    
                                <AfterPictureGallery jobConfigurationId={jobConfigurationId ?? -1} />
                            </FlatSection>
                        </div>

                        <div className="flex-gap-sm">
                            <FlatSection header="Service Photos" collapsible>
                                {!serviceSubSections.some(sub => sub.images.length > 0) && (
                                    <p className="margin-none flat-font italic-text">No Photos</p>
                                )}

                                {serviceSubSections.map((subsection) => {
                                    if (subsection.images.length > 0) {
                                        return (
                                            <ServicePhotosSubsection
                                                key={subsection.title}
                                                title={subsection.title}
                                                images={subsection.images}
                                                deleteServiceOrderPicture={(id) =>
                                                    deleteServiceOrderPicture({ variables: { id: id } })
                                                }
                                            />
                                        );
                                    } else {
                                        return <></>;
                                    }
                                })}
                            </FlatSection>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function ServicePhotosSubsection({
    title,
    images,
    deleteServiceOrderPicture,
}: ServicePhotosSubsectionProps) {
    return (
        <div className="margin-bottom-sm fill-width">
            <ToggleableSubSection title={title}>
                <Gallery rowMax={2}>
                    {images.map((picture) => (
                        <RoomGalleryImage
                            key={`service-picture-${picture.pictureId}`}
                            {...picture}
                            deleteImage={() => deleteServiceOrderPicture!(picture.pictureForRoomId)}
                        />
                    ))}
                </Gallery>
            </ToggleableSubSection>
        </div>
    );
}
