import { Button, MenuItem, Select } from "@material-ui/core";
import { Typography } from "@mui/material";
import NavbarPage from "Components/Page";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import {
    Area,
    CarpetCut,
    CarpetCutPiece,
    Room,
    RoomTransition,
    useGetJobConfigurationQuery,
} from "generated/graphql";
import { useMemo, useState } from "react";
import { CarpetCutPreviewer } from "./CarpetCutPreviewer";
import { CutBlockListItem } from "./CutBlockListItem";
import { RoomCutPreviewer } from "./RoomCutPreviewer";
import "./seam-planner.css";

export type EditMode = "None" | "ToggleDirection";

export interface RoomWithGroupNumber extends Room {
    roomGroupNumber: number;
}

export interface CarpetCutWithRoomNames extends CarpetCut {
    roomName: string;
}

export interface PiecesWithRoomNames extends CarpetCutPiece {
    stepLength: number | null;
    name: string;
}

export interface CutBlock {
    blockNumber: number;
    length: number;
    footPart: number;
    inchPart: number;
    names: string;
    pieces: PiecesWithRoomNames[];
}

export default function SeamPlanner() {
    const { id } = useNumericIdParam();
    const jobConfigurationId = id;

    const { data } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        skip: jobConfigurationId === null,
    });

    const [selectedPieceIds, setSelectedPieceIds] = useState<number[]>([]);

    function updateSelectedPieceIds(newIds: number[]) {
        if (
            newIds.length === 0 ||
            (newIds.length === selectedPieceIds.length &&
                newIds.sort().join(",") === selectedPieceIds.sort().join(","))
        )
            setSelectedPieceIds([]);
        else setSelectedPieceIds(newIds);
    }

    const [editMode, setEditMode] = useState<EditMode>("None");

    return (
        <NavbarPage
            title="Seam Planner"
            centerHorizontally
            padContent
        >
            <div id="seam-planner-page">
                <div id="seam-planner-mode-container">
                    <StateButton
                        currentState={editMode}
                        setState={setEditMode}
                        stateType="ToggleDirection"
                    >
                        Toggle Direction
                    </StateButton>
                    <Typography>Job Configuration Id: {data?.jobConfiguration.id}</Typography>
                </div>
                {data?.jobConfiguration.areas
                    .filter((area) => area.productTypeId === 4)
                    .map((area) => (
                        <AreaSeamPlanner
                            key={area.id}
                            area={area}
                            roomTransitions={data?.jobConfiguration.roomTransitions!}
                            selectedPieceIds={selectedPieceIds}
                            setSelectedPieceIds={updateSelectedPieceIds}
                            currentState={editMode}
                            setState={setEditMode}
                        />
                    ))}
            </div>
        </NavbarPage>
    );
}

interface AreaSeamPlannerProps extends SeamEditStateProps {
    area: Area;
    roomTransitions: RoomTransition[];
    selectedPieceIds: number[];
    setSelectedPieceIds: (newIds: number[]) => void;
}

function AreaSeamPlanner({
    area,
    roomTransitions,
    currentState: editMode,
    setState: setEditMode,
    selectedPieceIds,
    setSelectedPieceIds,
}: AreaSeamPlannerProps) {
    const [wastePerBlock, setWastePerBlock] = useState<number>(3);

    const { rooms, pieces, blocks, unpaddedLength } = useMemo(
        () => convertAreaToUsefulBlockData(area, roomTransitions),
        [area, roomTransitions]
    );

    const preferredCarpetLength =
        wastePerBlock !== 3
            ? unpaddedLength + (wastePerBlock / 12) * (blocks.length - 1)
            : area.preferredCarpetLength ?? 0;
    const oppositeCarpetLength = area.oppositeCarpetLength ?? 0;

    const carpetPadding = preferredCarpetLength - unpaddedLength;

    const preferredSqft = preferredCarpetLength * 12;
    const oppositeSqft = oppositeCarpetLength * 12;

    const totalUsedSqft =
        rooms?.map((room) => room.sqft).reduce((partial, sqft) => partial + sqft, 0) ?? 0;

    const wastedInPreferred = (((preferredSqft - totalUsedSqft) / preferredSqft) * 100).toFixed(2);
    const wastedInOpposite = (((oppositeSqft - totalUsedSqft) / oppositeSqft) * 100).toFixed(2);

    const { footLength: preferredFoot, inchLength: preferredInch } =
        lengthToFootInch(preferredCarpetLength);
    const { footLength: oppositeFoot, inchLength: oppositeInch } =
        lengthToFootInch(oppositeCarpetLength);

    return (
        <div id="seam-planner-page">
            <div id="seam-planner-mode-container">
                <Typography style={{ fontWeight: "bold" }}>
                    L(p) Waste: {wastedInPreferred}%
                </Typography>
                <Typography style={{ color: "gray" }}>L(o) Waste: {wastedInOpposite}%</Typography>

                <Typography>Waste Between Block</Typography>
                <Select
                    value={wastePerBlock}
                    onChange={(e) => setWastePerBlock(e.target.value as number)}
                >
                    {[...Array(9)].map((v, i) => (
                        <MenuItem
                            key={i}
                            value={i}
                        >
                            {i}"
                        </MenuItem>
                    ))}
                </Select>
                {/* <FlatButton onClick={()=>}>Print</FlatButton> */}
            </div>
            <div className="flex-row">
                <div
                    id="room-seam-preview-container"
                    style={{ flex: 1 }}
                >
                    {rooms?.map((room) => (
                        <RoomCutPreviewer
                            selectedPieceIds={selectedPieceIds}
                            setSelectedPieceIds={setSelectedPieceIds}
                            room={room}
                            areaIndex={0}
                            key={room.id}
                            editState={editMode}
                        />
                    ))}
                </div>
                <div style={{ width: "20rem" }}>
                    <CutBlockListItem
                        totalLength={preferredCarpetLength}
                        blocks={blocks}
                        selectedPieceIds={selectedPieceIds}
                        setSelectedPieceIds={setSelectedPieceIds}
                    />
                </div>
            </div>

            <div>
                <div style={{ fontWeight: "bold" }}>
                    L(p):{" "}
                    <FootInchText
                        foot={preferredFoot}
                        inch={preferredInch}
                    />
                </div>
                <div style={{ color: "gray" }}>
                    L(o):{" "}
                    <FootInchText
                        foot={oppositeFoot}
                        inch={oppositeInch}
                    />
                </div>
            </div>

            <div id="cut-seam-preview-container">
                <CarpetCutPreviewer
                    pieces={pieces}
                    blocks={blocks}
                    endOfUnpaddedLength={unpaddedLength}
                    carpetPadding={carpetPadding}
                    editState={editMode}
                    selectedPieceIds={selectedPieceIds}
                    setSelectedPieceIds={setSelectedPieceIds}
                />
            </div>
        </div>
    );
}

export function convertAreaToUsefulBlockData(
    area: Area,
    roomTransitions: RoomTransition[]
): {
    rooms: RoomWithGroupNumber[];
    cuts: CarpetCutWithRoomNames[];
    pieces: PiecesWithRoomNames[];
    blocks: CutBlock[];
    unpaddedLength: number;
} {
    const rooms = addRoomNumbers(area.rooms, roomTransitions);

    const cuts: CarpetCutWithRoomNames[] = rooms!.flatMap((room) =>
        room.carpetCuts.map((cut) => ({ ...cut, roomName: getNameOfArea(room.labels) }))
    );

    const pieces: PiecesWithRoomNames[] = cuts.flatMap((cut) => {
        return cut.pieces.map((piece) => ({
            ...piece,
            stepLength: cut.stepLength ?? null,
            name: piece.fillName ?? cut.roomName,
        }));
    });

    const blockGroups = pieces.reduce<{ [key: number]: PiecesWithRoomNames[] }>((total, piece) => {
        const blockNum = piece.packingBlockNumber;
        const piecesInBlock = total[blockNum];

        if (piecesInBlock === undefined) {
            return {
                [blockNum]: [piece],
                ...total,
            };
        } else {
            total[blockNum] = [...piecesInBlock, piece];

            return total;
        }
    }, {});

    const blocks: CutBlock[] = Object.keys(blockGroups)
        .map((blk) => +blk)
        .sort()
        .map((blockNumber) => {
            const piecesInBlock = blockGroups[+blockNumber];

            const xPoints = piecesInBlock.flatMap((piece) =>
                piece.packerShape.map((point) => point.x)
            );

            const minX = Math.min(...xPoints);
            const maxX = Math.max(...xPoints);

            const length = maxX - minX;

            var nonFill = piecesInBlock
                .filter((p) => isNullOrUndefined(p.fillName))
                .map((p) => p.name);
            var fill = piecesInBlock
                .filter((p) => !isNullOrUndefined(p.fillName))
                .map((p) => p.name);

            nonFill.sort();
            fill.sort();

            var pieceNames = "";

            if (nonFill.length > 0) {
                pieceNames += nonFill.join(", ");

                if (fill.length > 0) pieceNames += " & FILL: ";
            }

            if (fill.length > 0) pieceNames += fill.join(", ");

            const { footLength, inchLength } = lengthToFootInch(length);

            return {
                blockNumber: blockNumber,
                length: length,
                footPart: footLength,
                inchPart: inchLength,
                names: pieceNames,
                pieces: piecesInBlock,
            };
        });

    const unpaddedLength = Math.max(
        ...pieces.flatMap((piece) => piece.packerShape.map((pos) => pos.x))
    );

    return { rooms, cuts, pieces, blocks, unpaddedLength };
}

interface SeamEditStateProps {
    currentState: EditMode;
    setState: (newState: EditMode) => void;
}

interface StateButtonBaseProps extends SeamEditStateProps {
    stateType: EditMode;
}

type StateButtonProps = React.PropsWithChildren<StateButtonBaseProps>;

function StateButton({ currentState, setState, stateType, children }: StateButtonProps) {
    const isSelected = currentState === stateType;

    return (
        <Button
            variant={isSelected ? "contained" : "outlined"}
            onClick={() => setState(isSelected ? "None" : stateType)}
        >
            {children} [{isSelected ? "Mode Enabled" : "Mode Disabled"}]
        </Button>
    );
}

export function lengthToFootInch(length: number) {
    const lengthInInches = Math.round(length * 12);
    const inchLength = lengthInInches % 12;
    const footLength = Math.floor((lengthInInches - inchLength) / 12);

    return { footLength, inchLength };
}

export function FootInchText({ foot, inch }: { foot: number; inch: number }) {
    return (
        <>
            {foot}
            <sup style={{ textDecorationLine: "underline", fontSize: ".7em" }}>{inch}</sup>
        </>
    );
}

function addRoomNumbers(rooms: Room[], roomTransitions: RoomTransition[]): RoomWithGroupNumber[] {
    var roomsWithTransitions = rooms.map((room) => ({
        room,
        connectedRoomIds: roomTransitions
            .filter(
                (rt) =>
                    (rt.altRoomId === room.id || rt.mainRoomId === room.id) &&
                    isNotNullOrUndefined(rt.altRoomId)
            )
            .map((rt) => (rt.mainRoomId === room.id ? rt.altRoomId! : rt.mainRoomId)),
    }));

    var groupNumberLookup: { [id: number]: number } = {};

    var islandNumber = 0;
    while (roomsWithTransitions.length > 0) {
        var { room, connectedRoomIds } = roomsWithTransitions.shift()!;
        islandNumber++;
        groupNumberLookup[room.id] = islandNumber;

        const remainingIdsToExplore = [...connectedRoomIds];

        while (remainingIdsToExplore.length > 0) {
            const exploreId = remainingIdsToExplore.shift()!;

            // This node has already been visited
            if (groupNumberLookup[exploreId] !== undefined) continue;

            groupNumberLookup[exploreId] = islandNumber;

            const index = roomsWithTransitions.findIndex((rwt) => rwt.room.id === exploreId);

            if (index > -1) {
                const newRoomIds = roomsWithTransitions[index].connectedRoomIds;
                roomsWithTransitions.splice(index, 1);
                remainingIdsToExplore.push(
                    ...newRoomIds.filter((newId) => groupNumberLookup[newId] === undefined)
                );
            }
        }
    }

    return rooms.map((room) => ({ ...room, roomGroupNumber: groupNumberLookup[room.id] }));
}
