import { MenuItem, Select, Typography } from "@material-ui/core";
import { useGetAllMarketsQuery } from "generated/graphql";
import { useEffect } from "react";

interface DashboardFiltersProps {
    marketId: number | undefined;
    setMarketId: (newMarket: number) => void;
}

export function CommandCenterMarketSelect({ marketId, setMarketId }: DashboardFiltersProps) {
    const { data } = useGetAllMarketsQuery();

    useEffect(() => {
        if(marketId === undefined && (data?.allMarkets?.length ?? 0) > 0){
            setMarketId(data!.allMarkets[0].id);
        }
    }, [data, setMarketId, marketId])

    const marketOptions = data?.allMarkets ?? [];

    return (
        <div className={"flex-row flex-centered flex-grow"}>
            <Typography
                variant="body1"
                style={{ paddingRight: ".25rem", color:"var(--flat-gray-3)" }}
            >
                Market:
            </Typography>
            <Select
                style={{ color: "var(--flat-white)"}}
                value={marketId ?? -1}
                onChange={(m) => setMarketId(m.currentTarget.value as number)}
            >
                {marketId === undefined && <MenuItem value={-1}>Loading</MenuItem>}
                {marketOptions.map((market) => (
                    <MenuItem
                        key={`market-option-${market.id}`}
                        value={market.id}
                    >
                        {market.name}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
