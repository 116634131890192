import { Dialog, DialogActions, FormControl, MenuItem, Select } from "@material-ui/core";
import { DialogContent } from "@mui/material";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatTextArea from "FlatComponents/Inputs/FlatTextArea";
import { LabelForRoom, namedOperations, useUpdateInstallationAppointmentNotesMutation, useUpdateJobConfigurationNotesMutation, useUpdateRoomNotesMutation } from "generated/graphql";
import { isEmptyString } from "Globals/GenericValidators";
import { useState } from "react";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { cleanNoteString } from "./EditableNote";

export interface SelectableRoom {
    id: number;
    labels: LabelForRoom[];
}

interface AddNoteDialogProps {
    setOpen: (open: boolean) => void;
    jobConfigurationId: number;
    rooms: SelectableRoom[]
    installationAppointments: {summary: string; id: number;}[]
}

export default function AddNoteDialog({setOpen, jobConfigurationId, rooms, installationAppointments}: AddNoteDialogProps) {
    const [noteCategory, setNoteCategory] = useState("job");
    const [note, newNote] = useState("");

    const [selectedRoomId, setSelectedRoomId] = useState(0);
    const [selectedInstallationId, setSelectedInstallationId] = useState(0);

    const [updateJobConfigurationNotes] = useUpdateJobConfigurationNotesMutation({
        onCompleted: () => setOpen(false),
        onError: () => alert("Notes could not be submitted"),
        refetchQueries: [namedOperations.Query.GetJobNotes],
        awaitRefetchQueries: true
    });
    const [updateRoomNotes] = useUpdateRoomNotesMutation({
        onCompleted: () => setOpen(false),
        onError: () => alert("Notes could not be submitted"),
        refetchQueries: [namedOperations.Query.GetAllAreaAndRoomNotes],
        awaitRefetchQueries: true
    });
    const [updateInstallationAppointmentNotes] = useUpdateInstallationAppointmentNotesMutation({
        onCompleted: () => setOpen(false),
        onError: () => alert("Notes could not be submitted"),
        refetchQueries: [namedOperations.Query.GetAllInstallationNotes],
        awaitRefetchQueries: true
    });

    function onChangeCategory(newCategory: string) {
        setNoteCategory(newCategory);
        // reset any existing selection
        setSelectedRoomId(0);
        setSelectedInstallationId(0);
    }

    function submissionDelegator() {
        if (isEmptyString(note.trim())) {
            alert("Enter text into the note field"); 
            return; 
        }

        switch (noteCategory) {
            case "job":
                updateJobConfigurationNotes({variables: {jobConfigurationId: jobConfigurationId, notes: cleanNoteString(note), replace: false}});
                break;
            case "room":
                if (selectedRoomId > 0) {
                    updateRoomNotes({variables: {roomId: selectedRoomId, newNotes: cleanNoteString(note), replace: false}});
                } else {
                    alert("Select a room");
                }
                break;
            case "installation":
                if (selectedInstallationId > 0) {
                    updateInstallationAppointmentNotes({variables: {installationAppointmentId: selectedInstallationId, newNotes: cleanNoteString(note), replace: false}})
                } else {
                    alert("Select an installation appointment");
                }
                break;
            default:
                alert("Something has gone wrong")
                break;
        }
    }

    // FIXME: fix the FlatSelect component and use it here instead of the MUI one for a more consistent style
    return (
        <Dialog
            open={true}
            PaperProps={{className: "flat-outer-container", id: "ledger-main-container"}}
        >
            <div className="flat-section-header flex-row align-items-center flex-space-between">
                <h2 className="margin-none flat-font-md bold-text">Add Notes</h2>
            </div>

            <DialogContent>
                <div className="flex-column" style={{minWidth: "17rem"}}>
                    <FormControl className="margin-bottom-xsm">
                        <Select
                            label="Category"
                            labelId="select-note-type" id="note-type"
                            value={noteCategory}
                            onChange={e => onChangeCategory(e.target.value as string)}
                        >
                            <MenuItem value={"job"}>Job</MenuItem>
                            {rooms.length > 0 && <MenuItem value={"room"}>Room</MenuItem>}
                            {installationAppointments.length > 0 && <MenuItem value={"installation"}>Installation</MenuItem>}
                        </Select>
                    </FormControl>
                
                    {noteCategory === "room" && (
                        <FormControl className="margin-bottom-xsm">
                            <Select
                                id="room-selector"
                                value={selectedRoomId}
                                onChange={e => setSelectedRoomId(e.target.value as number)}
                            >
                                {(selectedRoomId === 0) && <MenuItem value={0} disabled>Select Room</MenuItem>}
                                {rooms.map(r => <MenuItem value={r.id} key={r.id}>{getNameOfArea(r.labels)}</MenuItem>)}
                            </Select>
                        </FormControl>
                    )}

                    {noteCategory === "installation" && (
                        <FormControl className="margin-bottom-xsm">
                            <Select
                                id="installation-selector"
                                value={selectedInstallationId}
                                onChange={e => setSelectedInstallationId(e.target.value as number)}
                            >
                                {selectedInstallationId === 0 && <MenuItem value={0} disabled>Select Installation Appointment</MenuItem>}
                                {installationAppointments.map(a => <MenuItem value={a.id} key={a.id}>{a.summary}</MenuItem>)}
                            </Select>
                        </FormControl>
                    )}

                    <FlatTextArea
                        className="margin-top-sm flat-textarea"
                        placeholder="Note"
                        value={note} onChange={(e) => newNote(e.target.value)}
                        rows={3}
                    />
                </div>

            </DialogContent>

            <DialogActions>
                <FlatButton variant="outlined" color="primary" onClick={() => setOpen(false)}>Cancel</FlatButton>
                <FlatButton variant="contained" color="secondary"  onClick={submissionDelegator}>Add</FlatButton>
            </DialogActions>
        </Dialog>
    )
}