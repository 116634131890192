import { Select, MenuItem, Button } from "@material-ui/core"
import clsx from "clsx"
import { useGetProductStyleOptionsQuery } from "generated/graphql"
import { selectTypeId, selectStyleId, setNSEMode, setStyleId, setNSEExistingId } from "Redux/addProductReducer"
import { useAppSelector, useAppDispatch } from "Redux/hooks"

export const styleUnselectedId = -1

export default function StyleEditor({ noEdit }: { noEdit?: boolean }) {
    const typeId = useAppSelector(selectTypeId)
    const styleId = useAppSelector(selectStyleId)
    const { data } = useGetProductStyleOptionsQuery({
        variables: { typeId: typeId },
        skip: typeId === -1,
    })
    const dispatch = useAppDispatch()

    const options = data?.productStyleOptions ?? []

    function updateValue(newValue: number) {
        if (newValue === 0) dispatch(setNSEMode("Create"))
        else dispatch(setStyleId(newValue))
    }

    function onEdit() {
        dispatch(setNSEMode("Edit"))
        dispatch(setNSEExistingId(styleId))
    }

    return (

        <div className="flex-column w-10r" >
            <label htmlFor="style">Private Label Style:</label>
            <div className="flex-row">
                <Select
                    disabled={typeId === -1}
                    value={styleId}
                    onChange={(e) => updateValue(e.target.value as number)}
                    className="w-10r"
                >
                    <MenuItem value={styleUnselectedId}>Unselected</MenuItem>
                    {options.map(o => (
                        <MenuItem
                            key={`style-editor-option-${o.id}`}
                            value={o.id}
                        >
                            {o.style}
                        </MenuItem>
                    ))}
                    <MenuItem value={0}>Add New</MenuItem>
                </Select>

                <div className={clsx("flex-column", { 'visibility-hidden': noEdit || styleId <= 0 })} style={{ justifyContent: "flex-end" }} >
                    <Button onClick={onEdit} className="margin-side-xsm" variant="contained">Edit</Button>
                </div>
            </div>
        </div>
    )
}
