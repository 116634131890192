import { useGetVendorSourcedProductIdsForStyleQuery, useGetVendorSourcedProductQuery } from "generated/graphql"
import { useAppSelector } from "Redux/hooks"
import { selectCostVendorId } from "Redux/productCostsReducer"
import ProductCostsCostsColumns from "./ProductCostsCostsColumns"


export default function ProductCostsSourcesRows({ styleId }: { styleId: number }) {

    const vendorId = useAppSelector(selectCostVendorId)
    const { data } = useGetVendorSourcedProductIdsForStyleQuery({ variables: { styleId, vendorId } })
    const namedIds = data?.productSourceIdsForStyle ?? []

    return (
        <>
            {
                namedIds.map(namedIds =>
                    <ProductCostSourceRow key={`product-cost-row-${namedIds.id}`} styleId={styleId} sourceId={namedIds.id} colorName={namedIds.color} />
                )
            }
        </>
    )
}


interface ProductCostSourceRowProps {
    styleId: number,
    sourceId: number,
    colorName: string
}

function ProductCostSourceRow({ styleId, sourceId, colorName }: ProductCostSourceRowProps) {
    const { data, loading } = useGetVendorSourcedProductQuery({ variables: { sourceId } })

    if (loading || data?.vendorSourcedProduct === undefined) return <tr />

    const {
        bulkCostPerSqft,
        singleCostPerSqft,
        oneTimeCostPerSqft        
    } = data?.vendorSourcedProduct!

    return (
        <tr>
            <td />
            <td className="faint-gray-bkg">
                {colorName}
            </td>
            <td className="faint-gray-bkg" />
            <ProductCostsCostsColumns 
                styleId={styleId}
                sourceId={sourceId}
                bulkCostPerSqft={bulkCostPerSqft}
                singleCostPerSqft={singleCostPerSqft}
                oneTimeCostPerSqft={oneTimeCostPerSqft ?? undefined}
                tdClassNames="faint-gray-bkg"
            />
        </tr>
    )
}