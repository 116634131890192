import { CASH_PAYMENT_ID, CHECK_PAYMENT_ID, C_C_PAYMENT_ID } from "Globals/globalConstants"

const sundayDayOfWeek = 0
const businessDateStrings = [
    gds(2023, 1, 1), // New Year's day
    gds(2023, 5, 29), // Memorial Day
    gds(2023, 7, 4), // Independence Daay
    gds(2023, 9, 4), // Labor Day
    gds(2023, 11, 23), // Thanksgiving
    gds(2023, 12, 25) // Xmas
]

function gds(year: number, month: number, day: number): string {
    //Month is 0 indexed
    return (new Date(year, month - 1, day)).toDateString()
}

export default function countBusinessDaysFrom(date: Date, days: number) {
    if (days < 1) return date //Bounds check the function
    const dateCopy = new Date(date)
    return recCountBusinessDays(dateCopy, days);
}

export function isWithin3DayPeriod(originalDate: Date, testDate?: Date) {

    const endDate = countBusinessDaysFrom(originalDate, 3);
    endDate.setDate(endDate.getDate() + 1)
    const compareDate = testDate ?? new Date();

    return compareDate.getTime() < endDate.getTime()
}

function recCountBusinessDays(date: Date, days: number): Date {
    if (days <= 0) return date;
    else {
        date.setDate(date.getDate() + 1)
        // We are closed on sundays and holidays, so this is not a business day
        if (date.getDay() === sundayDayOfWeek || isHoliday(date)) return recCountBusinessDays(date, days)
        // We are open next day, so if only one day left, return tomorrow 
        else if (days === 1) {
            return date
        }
        // More than 1 day left so continue counting down
        else return recCountBusinessDays(date, days - 1)
    }
}

function isHoliday(date: Date): boolean {
    const dateString = date.toDateString();

    for (const index in businessDateStrings) {
        //Uses a string comparison to limit to days only and not time
        if (dateString === businessDateStrings[index]) {
            return true;
        }
    }

    return false;
}

export function depositMethodStringToId(method: string): number | undefined {
    if (method === 'cash') return CASH_PAYMENT_ID
    else if (method === 'check') return CHECK_PAYMENT_ID
    else if (method === 'cc') return C_C_PAYMENT_ID
    else return undefined
}

export function depositMethodIdToString(id?: number): string {
    if (id === CASH_PAYMENT_ID) return 'cash'
    else if (id === CHECK_PAYMENT_ID) return 'check'
    else if (id === C_C_PAYMENT_ID) return 'cc'
    else return ""
}