import {
    dateTimeStrToMdy,
    dateToMdy,
    dayOfWeekNumberToString,
    mdyToMd,
} from "Globals/DateAndTimeHelpers";
import { buildAppendedId } from "Globals/Hooks";
import { installJobPath } from "Globals/PathStrings";
import { formatNameStringLastFirst } from "Globals/StringFormatting";
import { useAppHistory } from "Globals/routingHooks";
import React, { useEffect, useMemo, useRef } from "react";
import { createAmountLabel } from "./ContractorAppointmentCalendar";
import {
    ContractorAppointmentPageProps,
    EventDateProps,
    InstallationAppointmentBlock,
} from "./ContractorHome";
import clsx from "clsx";

interface ScrollTargetProps {
    scrollTarget: string;
}

export function ContractorAppointmentList({
    days,
    eventsByDays,
    scrollTarget,
    blocked,
}: ContractorAppointmentPageProps & ScrollTargetProps) {
    const daysToRender = useMemo(() => {
        const daysWithAppointments = Object.keys(eventsByDays);
        return days.filter((dayString) => daysWithAppointments.some((day) => dayString === day));
    }, [days, eventsByDays]);

    const blockedItems: string[] = useMemo(() => {
        return blocked.map((block) => dateTimeStrToMdy(block.date));
    }, [blocked]);

    return (
        <div className="fill-width contractor-appointment-list">
            {daysToRender.map((day) => (
                <AppointmentListDate
                    key={day}
                    day={day}
                    isBlocked={blockedItems.some((bi) => bi === day)}
                    events={eventsByDays[day]}
                    scrollTarget={scrollTarget}
                />
            ))}
        </div>
    );
}

function AppointmentListDate({
    day,
    events,
    isBlocked,
    scrollTarget,
}: EventDateProps & ScrollTargetProps) {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (scrollTarget === day && ref?.current !== null) {
            ref?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [ref, scrollTarget, day]);

    const date = new Date(day);
    const today = new Date();

    const isToday = day === dateToMdy(today);

    return (
        <>
            <div className={clsx("appointment-list-date", { "background-color-red flat-dark-bkg": isToday })}>
                <div className="flat-font">
                    {dayOfWeekNumberToString(date.getDay())!.substring(0, 3)}
                </div>
                <div className="flat-font-md">
                    {date.getMonth() === today.getMonth() ? day.split("/")[1] : mdyToMd(day)}
                </div>
            </div>

            {isBlocked && (
                <>
                    <BlockedListItem />
                    <div />
                </>
            )}

            {events.map((appt, index) => (
                <React.Fragment key={appt.id}>
                    <AppointmentListItem appointment={appt} />
                    <div />
                </React.Fragment>
            ))}

            <div style={{ height: "15px" }} />
        </>
    );
}

function AppointmentListItem({ appointment }: { appointment: InstallationAppointmentBlock }) {
    const customerName = formatNameStringLastFirst({
        firstName: appointment.customerFirstName,
        lastName: appointment.customerLastName,
    });
    const history = useAppHistory();
    const appendedId = buildAppendedId(appointment.id);

    const darkCard: boolean = appointment.isComplete;

    function onClick() {
        history.push(installJobPath + appendedId);
    }

    const color = useMemo(() => {
        const amounts = [
            { amount: appointment.woodTotal, color: "var(--flat-wood-color)" },
            { amount: appointment.carpetTotal, color: "var(--flat-carpet-color)" },
            { amount: appointment.spcTotal, color: "var(--flat-spc-color)" },
        ];

        const bestAmount = Math.max(...amounts.map((amt) => amt.amount));
        const color = amounts.find((amt) => amt.amount === bestAmount);

        return color!.color;
    }, [appointment.carpetTotal, appointment.woodTotal, appointment.spcTotal]);

    const backgroundColor = darkCard ? "var(--flat-gray-5)" : color;

    return (
        <div>
            <div
                className="fill-width flex-row appointment-list-outer-item"
                style={{ cursor: "pointer", backgroundColor: backgroundColor }}
                onClick={onClick}
            >
                <div
                    hidden={!darkCard}
                    className="contractor-calendar-stripe"
                    style={{ minWidth: "5px", height: "100%", backgroundColor: color }}
                />
                <div className="appointment-list-item flex-grow">
                    <div className="flat-font">{createAmountLabel(appointment)}</div>
                    <div className="flat-font">
                        Day {appointment.daysIntoAppointment}/{appointment.totalDaysInRange}
                    </div>
                    <div className="flat-font">
                        <b style={{ textTransform: "uppercase" }}>
                            {appointment.customerAddress.city}:
                        </b>{" "}
                        {customerName}
                    </div>
                </div>
            </div>
        </div>
    );
}

function BlockedListItem() {
    return (
        <div>
            <div className="fill-width flex-column flex-centered appointment-list-outer-item appointment-blocked-item">
                <div
                    className="flat-font-md disable-selection"
                    style={{ color: "var(--flat-white)" }}
                >
                    Day Blocked
                </div>
            </div>
        </div>
    );
}
