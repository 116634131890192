import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useGetColorCategoriesQuery } from "generated/graphql";
import { selectColorCategoryIds, setSelectedColorCategoryIds } from "Redux/callCenterSchedulingReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";


export default function ColorCategorySelector() {
    const { data } = useGetColorCategoriesQuery();
    const categories = data?.colorCategories ?? [];

    const dispatch = useAppDispatch();
    const selectedCategoryIds = useAppSelector(selectColorCategoryIds);

    return (
        <FormControl className="w-13r whitespace-no-wrap">
            <InputLabel>Color Preference</InputLabel>
            <Select
                multiple
                value={selectedCategoryIds}
                onChange={(e) => dispatch(setSelectedColorCategoryIds(e.target.value as number[]))}
            >
                {categories.map(opt => <MenuItem key={`priority-${opt.id}`} value={opt.id}>{opt.category}</MenuItem>)}
            </Select>
        </FormControl>
    )
}