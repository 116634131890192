import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectJobConfigurationId } from "Redux/jobTrackerReducer";
import { selectCcActualCommissionRate, selectCcStandardCommissionRate, selectContractConfigurationId, selectQuoteConfigToQuoteIdMap, selectRecoveryActualCommissionRate, selectRecoveryStandardCommissionRate, selectUsageContext, setCcActualCommissionRate, setRecoveryActualCommissionRate } from "Redux/pricingCenterReducer";

export function CcOverrideCommissionSelect() {
    const standardCommissionRate = useAppSelector(selectCcStandardCommissionRate); // standard rate for the margin
    const actualCommissionRate = useAppSelector(selectCcActualCommissionRate); // variable rate (overrides standard)
    const usageContext = useAppSelector(selectUsageContext);
    const contractConfigId = useAppSelector(selectContractConfigurationId);
    const quotedConfigIds = Object.keys(useAppSelector(selectQuoteConfigToQuoteIdMap)).map(id => +id);
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const disableInteraction = usageContext === "readonly" || isNotNullOrUndefined(contractConfigId) || quotedConfigIds.includes(jobConfigurationId);
    const dispatch = useAppDispatch();

    return (
        <FormControl>
            <InputLabel id="select-rate" />
            <Select
                readOnly={disableInteraction}
                labelId="select-rate"
                value={actualCommissionRate}
                onChange={(e) => dispatch(setCcActualCommissionRate(e.target.value as number))}
            >
                {standardCommissionRate < 0 ? (
                    <MenuItem disabled value={-1}>Enter price or margin</MenuItem>
                ) : (
                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(rate => {
                        let rateIsStandard = (rate / 100) === standardCommissionRate;
                        return (
                            <MenuItem
                                key={`rate-${rate}`} value={rate / 100}
                                style={{
                                    color: rateIsStandard ? "red" : "black",
                                    border: rateIsStandard ? "1px solid lightgray" : "none"
                                }}
                            >{rate}%</MenuItem>
                        )
                    })
                )}
            </Select>
        </FormControl>
    )
}

export function RecoveryOverrideCommissionSelect() {
    const standardCommissionRate = useAppSelector(selectRecoveryStandardCommissionRate); // standard rate for the margin
    const actualCommissionRate = useAppSelector(selectRecoveryActualCommissionRate); // variable rate (overrides standard)
    const dispatch = useAppDispatch();

    return (
        <FormControl>
            <InputLabel id="select-rate" />
            <Select
                labelId="select-rate"
                value={actualCommissionRate}
                onChange={(e) => dispatch(setRecoveryActualCommissionRate(e.target.value as number))}
            >
                {standardCommissionRate < 0 ? (
                    <MenuItem disabled value={-1}>Enter price or margin</MenuItem>
                ) : (
                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(rate => {
                        let rateIsStandard = (rate / 100) === standardCommissionRate;
                        return (
                            <MenuItem
                                key={`rate-${rate}`} value={rate / 100}
                                style={{
                                    color: rateIsStandard ? "red" : "black",
                                    border: rateIsStandard ? "1px solid lightgray" : "none"
                                }}
                            >{rate}%</MenuItem>
                        )
                    })
                )}
            </Select>
        </FormControl>
    )
}
