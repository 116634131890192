import { GetPartialProductStyleQuery, Maybe, ProductIdentifierWithPriceInput } from "generated/graphql";
import { ChangedValue, KeyedChangedNumber, KeyedChangedString } from "Redux/productCostsReducer";


export function fToB_UpdateProducts(
    changedFactors: KeyedChangedNumber,
    changedShipping: KeyedChangedNumber,
    changedPromoPrices: KeyedChangedNumber,
    changedStartDate: KeyedChangedString,
    changedEndDate: KeyedChangedString): ProductIdentifierWithPriceInput[] {
    const keys =
        [...new Set(
            [
                ...Object.keys(changedFactors),
                ...Object.keys(changedShipping),
                ...Object.keys(changedPromoPrices),
                ...Object.keys(changedStartDate),
                ...Object.keys(changedEndDate)
            ])
        ]
    return keys.map(key =>
        fToB_UpdateProduct(
            key,
            changedFactors[key],
            changedShipping[key],
            changedPromoPrices[key],
            changedStartDate[key],
            changedEndDate[key]))
}

function fToB_UpdateProduct(
    key: string,
    changedFactor: ChangedValue<number>,
    changedShipping: ChangedValue<number>,
    changedPromoPrice: ChangedValue<number>,
    changedStartDate: ChangedValue<string>,
    changedEndDate: ChangedValue<string>): ProductIdentifierWithPriceInput {

    return {
        styleId: +key,
        updatedFactor: (changedFactor?.changed ?? false) ? changedFactor?.value : undefined,
        updatedShipping: (changedShipping?.changed ?? false) ? changedShipping?.value : undefined,
        updatedPromo: fToB_Promo(changedPromoPrice, changedStartDate, changedEndDate)
    }
}

function fToB_Promo(
    changedPromoPrice: ChangedValue<number>,
    changedStartDate: ChangedValue<string>,
    changedEndDate: ChangedValue<string>): ProductIdentifierWithPriceInput["updatedPromo"] {

    function allChanged() {
        return (changedPromoPrice?.changed ?? false) && (changedStartDate?.changed ?? false) && (changedEndDate?.changed ?? false)
    }
    function allSetToNull() {
        return changedPromoPrice?.value === undefined && changedStartDate?.value === undefined && changedEndDate?.value === undefined
    }

    //Check null case
    if (allChanged() && allSetToNull()) return undefined;
    else return {
        promoPrice: (changedPromoPrice?.changed ?? false) ? changedPromoPrice?.value : undefined,
        startDate: (changedStartDate?.changed ?? false) ? changedStartDate?.value : undefined,
        endDate: (changedEndDate?.changed ?? false) ? changedEndDate?.value : undefined
    }
}


interface f_prices {
   pricingFactor: number,
   shippingPrice: number,
   promo: GetPartialProductStyleQuery["partialProductStyle"]["promo"]
}

type MaybeNumber = Maybe<number> | undefined

export function bToF_Prices(factor: MaybeNumber, shipping: MaybeNumber, promo: ProductIdentifierWithPriceInput["updatedPromo"]): f_prices {
    return {
        pricingFactor: factor ?? 0,
        shippingPrice: shipping ?? 0,
        promo: bToF_Promo(promo)
    }
}

function bToF_Promo(promo: ProductIdentifierWithPriceInput["updatedPromo"]) : GetPartialProductStyleQuery["partialProductStyle"]["promo"]{
    return {...promo }
}