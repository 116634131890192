import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { IconButton } from '@material-ui/core';
import FlatTextArea from 'FlatComponents/Inputs/FlatTextArea';
import { useEffect, useState } from 'react';

// takes a string and splits on newline characters to create the <li>s
// keyprefix is used to help create unique keys
export function strToLIs(str: string, keyPrefix: number | string): ReactJSXElement[] {
    let strSplit = str.trim().split("\n");

    return (
        strSplit.map((line, i) => <li key={`${keyPrefix}-${i}`}>{line}</li>)
    );
}

export function cleanNoteString(note: string) {
    return note.trim().split('\n').filter(line => line.length !== 0).join('\n');
}

interface EditableNoteProps {
    noteLabel?: string
    note: string;
    onUpdate: (newNote: string) => void;
    onDelete: () => void;
}

export default function EditableNote({note: noteText, noteLabel, onUpdate, onDelete}: EditableNoteProps) {
    const [editorOpen, setEditorOpen] = useState(false);
    const [newNote, setNewNote] = useState(noteText);
    
    function update() {
        // if there are no changes, no need to update
        (newNote !== noteText) && onUpdate(cleanNoteString(newNote));
        setNewNote(cleanNoteString(newNote)); // trim whitespace even if not submitting
        setEditorOpen(false);
    }

    function onCancelEditing() {
        setEditorOpen(false);
        setNewNote(noteText); // don't keep unsaved changes
    }

    // without this, when notes are ADDED, the text field does not show the added notes the next time the TextField is opened
    useEffect(() => setNewNote(noteText), [noteText]);

    return (
        <div className='flex-column'>
            <div className="flex-row align-items-flex-start">
                {editorOpen ? (
                    <IconButton
                        size='small'
                        className="margin-right-xsm"
                        onClick={onCancelEditing}
                    ><CancelIcon htmlColor='black' fontSize='small'/></IconButton>
                ) : (
                    <IconButton
                        size='small'
                        className="margin-right-xsm"
                        onClick={() => setEditorOpen(true)}
                    ><EditIcon htmlColor='black' fontSize='small'/></IconButton>
                )}

                <p className='margin-none'><b>{noteLabel}</b></p>
            </div>

            <ul className='margin-bottom-xsm' style={{marginLeft: "1.5rem"}}>
                {strToLIs(noteText, "newnotes")}
            </ul>

            {editorOpen && (
                <div className='flex-row margin-left-xmd flex-gap-xsm padding-left-sm'>
                    <IconButton size='small' onClick={() => update()}>
                        <CheckIcon htmlColor='black' fontSize='small'/>
                    </IconButton>
                    <IconButton size='small' onClick={() => onDelete()}>
                        <DeleteIcon htmlColor='black' fontSize='small'/>
                    </IconButton>
                </div>
            )}

            {editorOpen && (
                <FlatTextArea
                    className='margin-bottom-sm'
                    style={{marginLeft: "2.75rem"}}
                    rows={3}
                    value={newNote}
                    onChange={e => setNewNote(e.target.value)}
                />
            )}         
        </div>
    )
}