import { DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DialogTitle } from "@mui/material";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import {
    GetCartPageDataDocument,
    GetCartPageDataQueryVariables,
    useAddQuoteForJobMutation,
    useGetJobActiveContractIdQuery,
    useGetJobConfigurationQuery,
    useGetJobIdQuery,
    useGetJobInvoiceDataQuery,
    useGetMaterialOptionsQuery,
    useGetProductColorOptionsQuery,
    useUpdateAreaProductColorSelectionMutation,
    useUpdateJobConfigurationNotesMutation,
    useUpdateUnderlaymentInAreaMutation,
} from "generated/graphql";
import { dayToIso, dayToMdy, SingleDateCalendarDialog } from "Globals/DateAndTimeHelpers";
import {
    isEmail,
    isEmptyString,
    isNotEmptyString,
    isNotNullOrUndefined,
    isNullOrUndefined,
} from "Globals/GenericValidators";
import { INSTALLATION_ID, OVERRIDE_DISCOUNT_ID } from "Globals/globalConstants";
import { buildAppendedId, useNumericIdParam } from "Globals/Hooks";
import { homePath, invoicePath, originalContractPath, sellSheetPath } from "Globals/PathStrings";
import { useAppHistory } from "Globals/routingHooks";
import { formatDollarAmount } from "Globals/StringFormatting";
import { useState } from "react";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { cleanNoteString } from "./Dashboard/Breakdown/Notes/EditableNote";
import JobPictureViewer from "./Dashboard/JobPictures/JobPictureViewer";
import JobHeader from "./JobHeader";

interface LabelValuePair {
    label: string;
    value: string;
}

export default function Cart() {
    const { id: jobConfigurationId } = useNumericIdParam();

    const { data: jobIdData } = useGetJobIdQuery({
        skip: !jobConfigurationId,
        variables: { jobConfigurationId: jobConfigurationId! }
    });
    const jobId = jobIdData?.jobId ?? -1;

    // if the job already has a contract, take user away from the sell sheet
    useGetJobActiveContractIdQuery({
        variables: {jobId: jobId!},
        skip: jobId < 1,
        onCompleted: (data) => {
            const contractId = data.jobActiveContractId;
            if (contractId > 0) {
                history.push(`${originalContractPath}/${contractId}`)
            }
        }
    });

    const history = useAppHistory();

    const appendedId: string = buildAppendedId(jobConfigurationId);

    const [jobComments, setJobComments] = useState<string>("");
    const [quoteOpen, setQuoteDialogOpen] = useState<boolean>(false);
    const [uploadOpen, setUploadOpen] = useState<boolean>(false);

    const { data: jobData } = useGetJobInvoiceDataQuery({
        variables: { jobConfigurationId: jobConfigurationId ?? 0 },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only",
        skip: (jobConfigurationId ?? 0) < 1,
    });
    const customer = jobData?.jobConfigurationHeader.customer;

    const { data } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId ?? 1 },
        skip: (jobConfigurationId ?? 0) < 1,
        fetchPolicy: "network-only",
        onCompleted(data) {
            setJobComments(data.jobConfiguration.notes);
        },
    });

    const [updateProductColor] = useUpdateAreaProductColorSelectionMutation({
        refetchQueries: [
            {
                query: GetCartPageDataDocument,
                variables: {
                    jobConfigurationId: jobConfigurationId ?? 1,
                } as GetCartPageDataQueryVariables,
            },
        ],
        awaitRefetchQueries: true,
    });

    const [updateUnderlaymentInArea] = useUpdateUnderlaymentInAreaMutation({
        refetchQueries: [
            {
                query: GetCartPageDataDocument,
                variables: {
                    jobConfigurationId: jobConfigurationId ?? 1,
                } as GetCartPageDataQueryVariables,
            },
        ],
        awaitRefetchQueries: true,
    });

    const [updateJobConfigurationNotes] = useUpdateJobConfigurationNotesMutation();

    const renderAreas = (data?.jobConfiguration.areas ?? []).filter(
        (area) => area.includedInQuote && !isNullOrUndefined(area.productTypeId)
    );
    const financing = data?.jobConfiguration.financing;
    const pricing = data?.jobConfiguration.price!;

    const finPeriod = financing?.financingOption?.term ?? undefined;
    const hasFinancing = (finPeriod ?? 0) > 0;
    const perMonth = financing?.perMonthAmount ?? undefined;
    const downPayment = financing?.financingDownPayment ?? undefined;

    const isOverrideApplied = data?.jobConfiguration.discounts?.isOverrideApplied ?? false;

    const overrideDiscount = data?.jobConfiguration?.discounts?.discounts.find(
        (d) => d.discountId === OVERRIDE_DISCOUNT_ID
    );
    const authCode = overrideDiscount?.authorizationCode;
    const overridePrice = data?.jobConfiguration.price?.total;

    const { total, savings } = calculateYourTotalPrice(
        pricing?.total ?? 0,
        pricing?.savings ?? 0,
        overrideDiscount?.amount ?? 0
    );

    function updateJobNotes() {
        return updateJobConfigurationNotes({
            variables: {
                jobConfigurationId: jobConfigurationId!,
                notes: cleanNoteString(jobComments),
                replace: true,
            },
        });
    }

    const updateSelectedColor = (areaId: number, newColorId: number) => {
        const colorId = newColorId < 1 ? undefined : newColorId;
        const areaStyle = renderAreas.find((area) => area.id === areaId);

        if (areaStyle !== undefined && areaStyle.colorId !== colorId)
            updateProductColor({ variables: { areaId, colorId } });
    };

    function updateMaterial(areaId: number, materialCategoryId?: number) {
        const newMatId = materialCategoryId === -1 ? undefined : materialCategoryId;
        updateUnderlaymentInArea({ variables: { areaId, materialCategoryId: newMatId } });
    }

    const summaryPairs: LabelValuePair[] = [
        { label: "MSRP Total:", value: formatDollarAmount(pricing?.mSRP) },
        { label: "Total Savings:", value: formatDollarAmount(savings) },
        { label: "Your Total Price:", value: formatDollarAmount(total) },
    ];

    const handleQuoteFinalize = () => {
        setQuoteDialogOpen(false);
        updateJobNotes();
    };

    function goToInvoice() {
        updateJobNotes().then(() => history.push(invoicePath + appendedId));
    }

    if (renderAreas.length === 0)
        return (
            <NavbarPage hideNavbar>
                <JobHeader title={"Job Summary"} />
                <Container
                    maxWidth="md"
                    className="flex-column flex-centered flex-grow"
                    style={{ paddingTop: "1rem" }}
                >
                    <h1>Cart is Empty</h1>
                    <Button
                        variant="contained"
                        color="secondary"
                        children={"Go to Savings"}
                        onClick={() => history.push(sellSheetPath + appendedId)}
                    />
                </Container>
            </NavbarPage>
        );

    return (
        <NavbarPage hideNavbar>
            <JobHeader title={"Job Summary"} />
            <Container
                maxWidth="md"
                className="flex-column flex-centered flex-grow"
                style={{ paddingTop: "1rem" }}
            >
                <table
                    className="fill-width"
                    style={{ marginBottom: "2rem" }}
                >
                    <thead>
                        <tr style={{ borderBottom: "solid 1px black" }}>
                            <td
                                className="padding-bottom-sm"
                                align="center"
                            >
                                Type
                            </td>
                            <td
                                className="padding-bottom-sm"
                                align="center"
                            >
                                Style
                            </td>
                            <td
                                className="padding-bottom-sm"
                                align="center"
                            >
                                Color
                            </td>
                            <td
                                className="padding-bottom-sm"
                                align="center"
                            >
                                Underlayment
                            </td>
                            <td
                                className="padding-bottom-sm"
                                align="center"
                            >
                                Areas To Cover
                            </td>
                            <td
                                className="padding-bottom-sm"
                                align="right"
                            >
                                Total
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {renderAreas.map((area) => {
                            var installService = area.rooms[0].services.find(
                                (ser) => ser.serviceTypeId === INSTALLATION_ID
                            )!;

                            return (
                                <CartPageRow
                                    areaId={area.id}
                                    productTypeId={area.productTypeId!}
                                    productTypeName={area.productTypeName ?? ""}
                                    styleId={area.styleId ?? undefined}
                                    styleName={area.styleName ?? undefined}
                                    colorId={area.colorId ?? undefined}
                                    updateColor={updateSelectedColor}
                                    installationServiceId={installService.jobServiceId}
                                    underlaymentMaterialId={
                                        installService.materialCategoryId ?? undefined
                                    }
                                    updateUnderlayment={(matId) => updateMaterial(area.id, matId)}
                                    areaName={getNameOfArea(
                                        area.rooms.flatMap((room) => room.labels)
                                    )}
                                    price={area.price?.mSRP ?? 0}
                                    isOverrideApplied={isOverrideApplied ?? false}
                                    key={`total-line-${area.id}`}
                                />
                            );
                        })}
                    </tbody>
                </table>
                <div
                    className="flex-row fill-width flex-grow"
                    style={{ alignItems: "flex-start" }}
                >
                    <div className="flex-grow flex-column">
                        <p>
                            <b>Job Comments</b>
                        </p>
                        <TextField
                            value={jobComments}
                            onChange={(e) => setJobComments(e.target.value)}
                            multiline
                            variant="outlined"
                            rows={5}
                            rowsMax={10}
                        />
                        <div
                            className="flex-row fill-width"
                            style={{ marginTop: ".5rem", justifyContent: "space-between" }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                children={"Back to Savings"}
                                onClick={() => {
                                    updateJobNotes();
                                    history.push(sellSheetPath + appendedId);
                                }}
                            />
                            <Button
                                variant="contained"
                                color="secondary"
                                children={"Pictures"}
                                onClick={() => {
                                    setUploadOpen(true);
                                }}
                            />
                        </div>
                        <div
                            className="flex-row fill-width"
                            style={{ marginTop: ".5rem", justifyContent: "flex-start" }}
                        ></div>
                    </div>
                    <div
                        className="flex-grow flex-column fill-height"
                        style={{ alignItems: "flex-end" }}
                    >
                        <table>
                            <tbody>
                                {summaryPairs.map(({ label, value }, index) => (
                                    <tr key={`cart-row-${index}`}>
                                        <td
                                            style={{ paddingBottom: ".5rem" }}
                                            align="right"
                                        >
                                            <b>{label}</b>
                                        </td>
                                        <td
                                            style={{ width: "8rem", paddingBottom: ".5rem" }}
                                            align="right"
                                        >
                                            {value}
                                        </td>
                                    </tr>
                                ))}
                                <tr hidden={!isOverrideApplied}>
                                    <td
                                        style={{ paddingBottom: ".5rem" }}
                                        align="right"
                                    >
                                        <b>Override Price (</b>
                                        {authCode}
                                        <b>):</b>
                                    </td>
                                    <td
                                        style={{
                                            width: "8rem",
                                            paddingBottom: ".5rem",
                                            color: "red",
                                        }}
                                        align="right"
                                    >
                                        {formatDollarAmount(overridePrice ?? undefined)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            className="flex-row fill-width"
                            style={{ marginTop: ".5rem", justifyContent: "flex-end" }}
                        >
                            <SpacedButton
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    if (
                                        renderAreas.some((area) => isNullOrUndefined(area.colorId))
                                    ) {
                                        alert("Please select a color");
                                    } else {
                                        setQuoteDialogOpen(true);
                                    }
                                }}
                            >
                                Quote
                            </SpacedButton>

                            <SpacedButton
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (
                                        renderAreas.some((area) => isNullOrUndefined(area.colorId))
                                    ) {
                                        alert("Please select a color");
                                    } else {
                                        goToInvoice();
                                    }
                                }}
                            >
                                Finalize
                            </SpacedButton>
                        </div>
                        {hasFinancing && (
                            <div
                                className="flex-column"
                                style={{ marginTop: ".5rem", alignItems: "flex-end" }}
                            >
                                <p>
                                    {formatDollarAmount(perMonth)} / {finPeriod} Months
                                </p>
                                <p>With Downpayment: {formatDollarAmount(downPayment)}</p>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
            <RoomPictureViewerDialog
                jobConfigurationId={jobConfigurationId!}
                open={uploadOpen}
                setOpen={setUploadOpen}
            />
            <QuoteDialog
                open={quoteOpen}
                onClose={handleQuoteFinalize}
                notes={jobComments}
                updateNotes={setJobComments}
                baseEmail={customer?.email}
                jobConfigurationId={jobConfigurationId!}
            />
        </NavbarPage>
    );
}

function calculateYourTotalPrice(
    total: number,
    savings: number,
    overrideAmount: number
): { total: number; savings: number } {
    if (overrideAmount <= 0) return { total, savings };
    // There is an override amount, need to remove it from the total savings
    // Add the override amount back
    const updatedTotal = total + overrideAmount;
    const updatedSavings = savings + overrideAmount;

    return { total: updatedTotal, savings: updatedSavings };
}

interface QuoteDialogProps {
    open: boolean;
    onClose: (sendQuote: boolean) => void;
    notes: string;
    updateNotes: (value: string) => void;
    baseEmail?: string;
    jobConfigurationId: number;
}

function QuoteDialog({
    open,
    onClose,
    notes,
    updateNotes,
    baseEmail,
    jobConfigurationId,
}: QuoteDialogProps) {
    const abortDialog = () => onClose(false);
    const [showErrors, setShowErrors] = useState(false);
    const [additionalEmails, setAdditionalEmails] = useState<string[]>([]);
    const [followUpDate, setFollowUpDate] = useState<DayValue | undefined>(undefined);
    const [dateDialogOpen, setDateDialogOpen] = useState(false);
    const history = useAppHistory();

    const [addQuote, { loading: sending }] = useAddQuoteForJobMutation({
        onCompleted: () => abortDialog(),
        onError: (e) => {
            console.log(e);
            alert("Could not send quote");
        },
    });

    function canSubmit(): boolean {
        if (isNullOrUndefined(followUpDate)) {
            alert("Select a follow-up date");
            setShowErrors(true);
            return false;
        }

        let emails = [baseEmail, ...additionalEmails].filter(
            (email) => isNotNullOrUndefined(email) && isNotEmptyString
        );
        if (emails.length < 1) {
            alert("Enter at least 1 email address");
            setShowErrors(true);
            return false;
        }
        if (!emails.every((email) => isEmail(email!))) {
            alert("Ensure are email addresses are valid");
            setShowErrors(true);
            return false;
        }

        setShowErrors(false);
        return true;
    }

    function sendQuote() {
        if (canSubmit()) {
            addQuote({
                variables: {
                    followUpDate: dayToIso(followUpDate!),
                    jobConfigurationId: jobConfigurationId,
                    notes: isEmptyString(notes.trim()) ? null : notes.trim(),
                    emails: isNotNullOrUndefined(baseEmail)
                        ? [
                              baseEmail!,
                              ...additionalEmails.filter((email) => isNotEmptyString(email)),
                          ]
                        : additionalEmails.filter((email) => isNotEmptyString(email)),
                },
                onCompleted: (data) => {
                    if (data.addQuoteForJob) {
                        alert("Quote successfully sent!");
                        history.push(homePath);
                    } else {
                        alert("Could not send quote");
                    }
                },
            });
        }
    }

    return (
        <Dialog
            open={open}
            onClose={abortDialog}
            maxWidth="md"
        >
            <DialogTitle>
                <h4>Job Quote</h4>
            </DialogTitle>

            <DialogContent>
                <div
                    className="flex-column"
                    style={{ width: "min(30rem, 100vw)" }}
                >
                    <h5>Notes</h5>
                    <TextField
                        multiline
                        variant="outlined"
                        value={notes}
                        maxRows={5}
                        onChange={(x) => updateNotes(x.target.value)}
                    />

                    <h5 className="margin-top-sm">Follow-up Date</h5>
                    <div className="flex-row">
                        {/* FIXME: */}
                        <TextField
                            variant="outlined"
                            inputProps={{ readonly: true }}
                            value={followUpDate ? dayToMdy(followUpDate) : ""}
                        />
                        <IconButton onClick={() => setDateDialogOpen(true)}>
                            <DateRangeIcon />
                        </IconButton>
                    </div>

                    {dateDialogOpen && (
                        <SingleDateCalendarDialog
                            open={true}
                            setOpen={setDateDialogOpen}
                            selectedDate={followUpDate}
                            setSelectedDate={setFollowUpDate}
                        />
                    )}

                    <h5 className="margin-top-sm">Email</h5>
                    {baseEmail && (
                        <TextField
                            value={baseEmail}
                            disabled
                        />
                    )}

                    {additionalEmails.map((email, index) => {
                        return (
                            <div
                                key={`additional-email-${index}`}
                                className="flex-row fill-width"
                                style={{ margin: ".5rem 0" }}
                            >
                                <TextField
                                    error={showErrors && !isEmail(email)}
                                    autoFocus
                                    className="flex-grow"
                                    style={{ paddingRight: ".25rem" }}
                                    value={email}
                                    onChange={(e) => {
                                        var copy = [...additionalEmails];
                                        copy[index] = e.target.value;
                                        setAdditionalEmails(copy);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        setAdditionalEmails(
                                            additionalEmails.filter((v, ind) => ind !== index)
                                        )
                                    }
                                >
                                    X
                                </Button>
                            </div>
                        );
                    })}
                    <Button
                        variant="outlined"
                        onClick={() => setAdditionalEmails([...additionalEmails, ""])}
                    >
                        +
                    </Button>
                </div>
            </DialogContent>

            {sending ? (
                <div
                    style={{
                        backgroundColor: "#cfcfcfb6",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Loading altText="Sending Quote" />
                </div>
            ) : (
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={abortDialog}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={sendQuote}
                    >
                        Send Quote
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}

export function RoomPictureViewerDialog({
    jobConfigurationId,
    open,
    setOpen,
}: {
    jobConfigurationId: number;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="xl"
        >
            <JobPictureViewer
                jobConfigurationId={jobConfigurationId}
                rowMax={2}
            />
        </Dialog>
    );
}

interface CartPageRowProps {
    areaId: number;
    productTypeId: number;
    productTypeName: string;
    styleName?: string;
    styleId?: number;
    colorId?: number;
    installationServiceId: number;
    updateColor: (id: number, selectedId: number) => void;
    underlaymentMaterialId?: number;
    updateUnderlayment: (newId: number) => void;
    areaName: string;
    price: number;
    isOverrideApplied: boolean;
}

function CartPageRow({
    areaId,
    productTypeId,
    productTypeName,
    styleName,
    styleId,
    colorId,
    installationServiceId,
    updateColor,
    underlaymentMaterialId,
    updateUnderlayment,
    areaName,
    price,
    isOverrideApplied,
}: CartPageRowProps) {
    const { data } = useGetProductColorOptionsQuery({
        variables: { styleId: styleId ?? 0 },
        skip: (styleId ?? 0) < 1,
    });
    const { data: underlaymentData } = useGetMaterialOptionsQuery({
        variables: { productTypeId, jobServiceId: installationServiceId },
    });

    const colorOptions = data?.productColorOptions ?? [];
    const underlaymentOptions = underlaymentData?.materialOptions ?? [];

    return (
        <tr key={`total-line-${areaId}`}>
            <td align="center">{productTypeName}</td>
            <td align="center">{styleName}</td>
            <td align="center">
                <Select
                    fullWidth
                    value={colorOptions.length === 0 ? "" : colorId ?? -1}
                    onChange={(x) => updateColor(areaId, x.target.value as number)}
                >
                    {colorOptions.length === 0 && (
                        <MenuItem
                            key={`${styleName}-loading`}
                            value={""}
                        >
                            Loading
                        </MenuItem>
                    )}
                    {colorOptions.length > 0 && (
                        <MenuItem
                            key={`${styleName}-`}
                            value={-1}
                        >
                            No Color
                        </MenuItem>
                    )}
                    {colorOptions.map((color) => (
                        <MenuItem
                            key={`${styleName}-${color.id}`}
                            value={color.id}
                        >
                            {color.color}
                        </MenuItem>
                    ))}
                </Select>
            </td>
            <td align="center">
                <Select
                    fullWidth
                    disabled={isOverrideApplied || underlaymentOptions.length === 0}
                    value={underlaymentMaterialId ?? -1}
                    onChange={(x) => updateUnderlayment(x.target.value as number)}
                >
                    <MenuItem
                        key={`${installationServiceId}-NoUnder`}
                        value={-1}
                    >
                        No Underlayment
                    </MenuItem>
                    {underlaymentOptions.map((material) => (
                        <MenuItem
                            key={`${installationServiceId}-${material.materialCategoryId}`}
                            value={material.materialCategoryId}
                        >
                            {material.materialName}
                        </MenuItem>
                    ))}
                </Select>
            </td>
            <td align="center">{areaName}</td>
            <td align="right">{formatDollarAmount(price)}</td>
        </tr>
    );
}
