import FlatButton from "FlatComponents/Button/FlatButton";
import FlatFileInput from "FlatComponents/Inputs/FlatFileInput";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { useReportS3FileUploadStatusMutation, useUpsertWorkersCompMutation, WorkersComp, WorkersCompInput } from "generated/graphql";
import { uploadFileToS3 } from "Globals/FileUtils";
import { prepareWorkerDocument } from "Globals/dataPreparationUtils";
import { dateTimeStrToMdy, dayValueToMdy, mdyToDay, SingleDateCalendarDialog } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isWorkerDocumentValid } from "Globals/GenericValidators";
import { useState } from "react";

interface WorkersCompDialogProps {
    workersComp: WorkersComp;
    toRefetchOnUpdate: string;
    onClose: () => void;
}

export default function WorkersCompDialog({workersComp: ogWc, toRefetchOnUpdate, onClose}: WorkersCompDialogProps) {
    const [wc, setWc] = useState({
        ...ogWc,
        activeDate: dateTimeStrToMdy(ogWc.activeDate),
        expirationDate: dateTimeStrToMdy(ogWc.expirationDate)
    });
    const [file, setFile] = useState<File | undefined>(undefined);
    const [activeDialogOpen, setActiveDialogOpen] = useState(false);
    const [expirationDialogOpen, setExpirationDialogOpen] = useState(false);

    const [upsertWc, {loading: upserting}] = useUpsertWorkersCompMutation({
        refetchQueries: [toRefetchOnUpdate]
    });
    const [reportUploadStatus, {loading: reportingStatus}] = useReportS3FileUploadStatusMutation();

    function validateAndPrepareData(): WorkersCompInput | undefined {
        const preparedWc = prepareWorkerDocument(wc);
        if (isWorkerDocumentValid(wc, true)) {
            return preparedWc;
        } else {
            return undefined;
        }
    }

    function onSubmit() {
        const preparedWc = validateAndPrepareData();
        if (isNotNullOrUndefined(preparedWc)) {
            const newMode = preparedWc!.id < 0;
            const hasFile = isNotNullOrUndefined(file);
            upsertWc({
                variables: {
                    workersComp: preparedWc!,
                    hasFile: hasFile
                }
            }).then(res => {
                const uploadInfo = res.data?.upsertWorkersComp;
                if (hasFile && isNotNullOrUndefined(uploadInfo)) {
                    uploadFileToS3(file!, uploadInfo!.uploadUrl)
                    .then(res => {
                        reportUploadStatus({
                            variables: {
                                fileId: uploadInfo!.id,
                                succeeded: res.ok
                            }
                        });
                        if (res.ok) {
                            onClose();
                        } else {
                            alert("Something went wrong when uploading the file");
                        }
                    })
                    .catch(_ => {
                        reportUploadStatus({
                            variables: {
                                fileId: uploadInfo!.id,
                                succeeded: false
                            }
                        }).then(_ => onClose());
                    });
                } else {
                    if (hasFile) {
                        // uploadInfo should not be null if hasFile is true
                        alert("Something went wrong when adding the workers' comp file");
                    }
                    onClose();
                }
            })
            .catch(_ => alert(`Could not ${newMode ? "add" : "update"} workers' comp information`));
        }
    }

    const submitButton = (
        <FlatButton
            key={"submit-wc"}
            onClick={onSubmit}
            variant="contained"
            color="secondary"
            disabled={upserting || reportingStatus}
        >Submit</FlatButton>
    );

    return (
        <FlatDialog
            dialogProps={{open: true, onClose: onClose}}
            sectionProps={{header: "Workers' Comp"}}
            actionButtons={[submitButton]}
        >
            <div className="flex-column flex-gap-sm">
                <FlatLabeledInput
                    label="Active Date"
                    value={wc.activeDate}
                    readOnly
                    onClick={() => setActiveDialogOpen(true)}
                />
                <FlatLabeledInput
                    label="Expiration Date"
                    value={wc.expirationDate}
                    readOnly
                    onClick={() => setExpirationDialogOpen(true)}
                />
            
                <FlatFileInput
                    accept=".pdf"
                    setSelectedFile={setFile}
                /> 
            </div>
            
            <SingleDateCalendarDialog
                selectedDate={mdyToDay(wc.activeDate)}
                setSelectedDate={(newDay) => setWc({...wc, activeDate: dayValueToMdy(newDay)})}
                open={activeDialogOpen}
                setOpen={setActiveDialogOpen}
                blockPastDays={false}
                />
            <SingleDateCalendarDialog
                selectedDate={mdyToDay(wc.expirationDate)}
                setSelectedDate={(newDay) => setWc({...wc, expirationDate: dayValueToMdy(newDay)})}
                open={expirationDialogOpen}
                setOpen={setExpirationDialogOpen}
                blockPastDays={false}
            />
        </FlatDialog>
    )
}