import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { formatNameStringLastFirst } from "Globals/StringFormatting";
import {
    InvoiceCustomerInformationTable,
    InvoiceCustomerInformationTableProps
} from "Pages/Admin/ProjectManagement/Invoice/InvoiceDocumentHeader";
import FlatSection from "./FlatSection";

interface FlatCustomerHeaderProps extends InvoiceCustomerInformationTableProps {
    defaultClosed?: boolean;
}

export function FlatCustomerHeader({ defaultClosed, onEdit, ...props }: FlatCustomerHeaderProps) {
    return (
        <FlatSection
            header={
                <>
                    {formatNameStringLastFirst(props) + " - " + (props.date?.toLocaleDateString() ?? (new Date()).toLocaleDateString())}
                    <IconButton
                        style={{ paddingLeft: ".25rem" }}
                        size="small"
                        onClick={onEdit}
                    >
                        <EditIcon />
                    </IconButton>
                </>
            }
            collapsible={true}
            defaultClosed={defaultClosed}
        >
            <InvoiceCustomerInformationTable
                {...props}
                onEdit={onEdit}
                renderExpanded={true}
            />
        </FlatSection>
    );
}
