import { IconButton } from "@material-ui/core";
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatCertifiedButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatCertifiedIcon color={color}/>
        </IconButton>
    )
}

export function FlatCertifiedIcon({color="black"} : {color?: string}) {
    return <VerifiedUserOutlinedIcon htmlColor={color}/>
}