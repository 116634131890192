import Loading from "Components/Loading";
import { useGetCompletionCertificatePdfQuery } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export default function CompletionCertificatePDF({
    installationAppointmentId,
}: {
    installationAppointmentId: number;
}) {
    const { data, loading } = useGetCompletionCertificatePdfQuery({
        variables: { installationAppointmentId },
    });

    return <div>
        {(loading && isNotNullOrUndefined(data)) ? (
                <Loading />
            ) : (
                <Document
                    className="margin-top-sm flex-column flex-centered"
                    file={`data:application/pdf;base64,${data?.completionCertificatePDF}`}
                    loading={<Loading />}
                >
                    <div className="solid-border">
                        <Page
                            className={"flex-column flex-centered"}
                            pageIndex={0}
                        />
                    </div>
                </Document>
            )}
    </div>;
}
