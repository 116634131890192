import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { GetPartialProductStyleDocument, GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables, GetVendorSourcedProductDocument, GetVendorSourcedProductQuery, useUpdateProductCostsMutation } from "generated/graphql";
import { useAppDispatch } from "Redux/hooks";
import { clearAllCosts } from "Redux/productCostsReducer";
import { store } from "Redux/store";
import { bToF_Costs, fToB_ProductCosts } from "../Conversions";


export default function SubmitOrClearButton() {
    const dispatch = useAppDispatch()

    const [updateCosts] = useUpdateProductCostsMutation({
        update(cache, results) {
            results.data?.updateProductCosts.forEach(
                updated => {
                    //Updating a source
                    if (updated.sourceId !== undefined && updated.sourceId !== null) {
                        const data = cache.readQuery<GetVendorSourcedProductQuery>({
                            query: GetVendorSourcedProductDocument,
                            variables: { sourceId: updated.sourceId! }
                        })

                        if (data !== null) {
                            cache.writeQuery<GetVendorSourcedProductQuery>({
                                query: GetVendorSourcedProductDocument,
                                variables: { styleId: updated.styleId },
                                data: {
                                    vendorSourcedProduct:
                                    {
                                        ...data.vendorSourcedProduct,
                                        ...bToF_Costs(updated.bulkCost, updated.singleCost, updated.oneTimeCost)
                                    }
                                }
                            })
                        }
                    } else { //Updating a style

                        const data = cache.readQuery<GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables>({
                            query: GetPartialProductStyleDocument,
                            variables: { styleId: updated.styleId }
                        })

                        if (data !== null) {

                            cache.writeQuery<GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables>({
                                query: GetPartialProductStyleDocument,
                                variables: { styleId: updated.styleId },
                                data: {
                                    partialProductStyle:
                                    {
                                        ...data.partialProductStyle,
                                        ...bToF_Costs(updated.bulkCost, updated.singleCost, undefined)
                                    }
                                }
                            })
                        }
                    }
                }
            )

            dispatch(clearAllCosts())
        }
    });

    function clearPage() {
        dispatch(clearAllCosts())
    }

    function trySubmitPage() {
        const { changedBulks, changedSingles, isSoft, carpetCostMode } = store.getState().productCosts
        function getScaleCost(){
            if(!isSoft) return 1
            else {
                if(carpetCostMode === "sqft") return 1
                else return 1/9
            }
        }

        const scaleCosts = getScaleCost()

        console.log("Submitted data:", changedBulks, changedSingles)

        updateCosts({
            variables:
            {
                products: fToB_ProductCosts(changedBulks, changedSingles, scaleCosts),
                isSSMode: isSoft
            }
        })
    }

    return (
        <div>
            <SpacedButton
                variant='outlined'
                onClick={clearPage}>
                Clear
            </SpacedButton>
            <SpacedButton
                variant='contained'
                color='secondary'
                onClick={trySubmitPage}>
                Submit
            </SpacedButton>
        </div>
    )
}