import FlatButton from "FlatComponents/Button/FlatButton";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { useGetS3FileQuery } from "generated/graphql";
import { useState } from "react";

interface S3ImageViewerProps extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "src"> {
    fileId: number;
    placeholderSrc?: string;
    setImgName?: (name: string) => void; // intended to be used with S3ViewerDialog to set the dialog title
}

export function S3ImageViewer({fileId, placeholderSrc, setImgName, ...imgProps}: S3ImageViewerProps) {
    const [imgSrc, setImgSrc] = useState<string | undefined>(placeholderSrc);
    
    const {data: s3ImageData} = useGetS3FileQuery({
        variables: {fileId: fileId},
        skip: fileId < 1,
        onCompleted: (data) => {
            // when the image is immediately loaded with this component right after it's uploaded, sometimes it's
            // not quite ready for retrieval yet, so the slight delay mitigates this
            setTimeout(() => {
                setImgSrc(data.s3File.signedUrl ?? undefined);
                if (setImgName) {
                    setImgName(data.s3File.fileName)
                }
            }, 500);
        }
    });
    const imageFileName = s3ImageData?.s3File.fileName; 

    return (
        <img
            key={imgSrc ?? placeholderSrc}
            src={imgSrc}
            className="fill-width fill-height"
            alt={imageFileName}
            {...imgProps}
        />
    )
}

interface S3ImageViewerDialogProps extends Omit<S3ImageViewerProps, "setImgName"> {
    open: boolean;
    onClose: () => void;
    header?: string;
    onDelete?: () => void;
    disableDeleteButton?: boolean; // has no effect if onDelete is not passed
}

export function S3ImageViewerDialog({
    open,
    onClose,
    fileId,
    header,
    onDelete,
    disableDeleteButton=true,
    placeholderSrc,
    ...imgProps
}: S3ImageViewerDialogProps) {
    const [imgName, setImgName] = useState<string | undefined>(undefined);

    const deleteButton = onDelete ?
        <FlatButton
            key={1}
            onClick={onDelete}
            color="secondary"
            variant="contained"
            disabled={disableDeleteButton}
        >Delete</FlatButton>
        : undefined;

    return (
        <FlatDialog
            dialogProps={{open: open, onClose: onClose}}
            sectionProps={{header: header ?? imgName}}
            actionButtons={deleteButton ? [deleteButton] : undefined}
        >
            <S3ImageViewer
                fileId={fileId}
                placeholderSrc={placeholderSrc}
                setImgName={setImgName}
                {...imgProps}
            />
        </FlatDialog>
    )
}