import { namedOperations, useUpdateJobInRecoveryMutation } from "generated/graphql";
import { BaseColumnProps } from "./RecoveryCenterPage";
import UnclaimedRecoveryCenterCard from "./UnclaimedRecoveryCenterCard";
import RecoveryCenterColumn from "./VisualComponents/RecoveryColumn";

interface HotColumnProps extends BaseColumnProps {
    workerId?: number,
    disabled?: boolean
}

function HotColumn({ jobs, workerId, disabled }: HotColumnProps) {
    const [updateJob] = useUpdateJobInRecoveryMutation({ refetchQueries: [namedOperations.Query.GetAllJobsInRecovery] })

    function claimJob(job: BaseColumnProps['jobs'][number]) {
        if(workerId !== undefined) {
            updateJob({variables : {
                jobId: job.id,
                claimedWorkerId: workerId
            }})
        } 
    }

    return (
        <RecoveryCenterColumn title="HOT" id="rc-column-header-hot">
            {jobs.map(job => (
                <UnclaimedRecoveryCenterCard
                    key={`unclaimed-${job.id}`}
                    job={job}
                    onClaim={(workerId === undefined || disabled) ? undefined : claimJob} 
                />)
            )}
        </RecoveryCenterColumn>
    );
}



export default HotColumn;