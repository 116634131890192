import { IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import clsx from "clsx";
import {
    namedOperations,
    useDeleteInstallationAppointmentMutation,
    useDeleteRoomForInstallationAppointmentMutation,
    useDeleteRoomsForInstallationAppointmentMutation,
    useGetInstallationCodBoundsForExistingLazyQuery,
    useUpdateInstallationAppointmentMutation
} from "generated/graphql";
import {
    dateTimeStrToDay,
    dayExists,
    dayToIso,
    formatAppointmentDateStringAbbreviated
} from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import { useEffect, useMemo, useState } from "react";
import { DayValue } from "react-modern-calendar-datepicker";
import { SchedulingPopout } from "./SchedulingPopout";
import { roomIdsFromStaged } from "./utils/helpers";
import {
    EditableAppointmentSummaryRow,
    ProductWithRooms,
    Room,
    ScheduledInstallationSummary,
    StagedAppointmentSchedulings
} from "./utils/installationSchedulingTypes";

export function EditableAppointmentSummary({
    scheduled,
}: EditableAppointmentSummaryRow) {
    const { id: jobConfigurationId } = useNumericIdParam();

    const [apptDeleteFailed, setApptDeleteFailed] = useState(false);
    const [deleteInstallationAppointment, { loading: apptDeleting }] =
        useDeleteInstallationAppointmentMutation({
            // query returns false if the number of rows updated was not exactly 1 (would never be more, but could be 0)
            onCompleted: (response) => {
                let success = response.deleteInstallationAppointment.valueOf();
                setApptDeleteFailed(!success);
                setTimeout(() => {
                    setApptDeleteFailed(false);
                }, 5000);
            },
            onError: () => {
                setApptDeleteFailed(true);
                setTimeout(() => setApptDeleteFailed(false), 5000);
            },
            refetchQueries: [
                namedOperations.Query.GetInstallationAppointments,
                namedOperations.Query.GetInstallationAppointmentsAfterDate,
                namedOperations.Query.GetAllInstallationNotes,
            ],
            awaitRefetchQueries: true,
        });

    const [deleteRoomsForInstallation] = useDeleteRoomsForInstallationAppointmentMutation({
        onError: () => alert("Failed to cancel appointment"),
        refetchQueries: [
            namedOperations.Query.GetInstallationAppointments,
            namedOperations.Query.GetInstallationAppointmentsAfterDate,
            namedOperations.Query.GetAllInstallationNotes
        ],
        awaitRefetchQueries: true
    });

    const [deleteRoomForInstallation] = useDeleteRoomForInstallationAppointmentMutation({
        onError: () => alert("Failed to remove room from appointment"),
        refetchQueries: [
            namedOperations.Query.GetInstallationAppointments,
            namedOperations.Query.GetInstallationAppointmentsAfterDate,
            namedOperations.Query.GetAllInstallationNotes
        ],
        awaitRefetchQueries: true
    });

    const [updateFailed, setUpdateFailed] = useState(false);
    const [updateSucceeded, setUpdateSucceeded] = useState(false);
    const [updateInstallationAppointment, { loading: updating }] =
        useUpdateInstallationAppointmentMutation({
            // query returns false if the number of rows updated was not exactly 1 (would never be more, but could be 0)
            onCompleted: (response) => {
                let success = isNotNullOrUndefined(response?.updateInstallationAppointment);
                setUpdateSucceeded(success);
                setUpdateFailed(!success);
                setPopoutOpen(false);
                setTimeout(() => {
                    setUpdateSucceeded(false);
                    setUpdateFailed(false);
                }, 5000);
            },
            // TODO: set all popout values to their originals
            onError: () => {
                setUpdateFailed(true);
                setPopoutOpen(false);
                setTimeout(() => setUpdateFailed(false), 5000);
            },
            refetchQueries: [
                namedOperations.Query.GetInstallationAppointments,
                namedOperations.Query.GetInstallationAppointmentsAfterDate,
            ],
            awaitRefetchQueries: true,
        });

    // for top row of summary text
    const contractorFname = scheduled.contractorFname;
    const contractorLname = scheduled.contractorLname;
    const dateText = formatAppointmentDateStringAbbreviated(scheduled.dates); //`${dayToMdy(scheduled.startDate)} - ${formatNumberOfDays(scheduled.length)}`;

    // for checking whether anything has changed when attempting to submit
    // const originalContractorId = scheduled.contractorId;
    const originalStartDate = useMemo(() => dateTimeStrToDay(scheduled.dates[0]), [scheduled.dates]);
    const originalCod = scheduled.cod;

    // for editing the appointment
    const [popoutOpen, setPopoutOpen] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState<DayValue>(originalStartDate);
    const [cod, setCod] = useState<number | undefined>(originalCod); // TODO: default this to something

    // these useEffects are here so that when updates are refetched from DB, the inputs adjust accordingly
    useEffect(() => {
        setSelectedStartDate(originalStartDate);
    }, [originalStartDate]);

    useEffect(() => {
        setCod(originalCod);
    }, [originalCod]);

    function onClosePopout() {
        setPopoutOpen(false);
        // setSelectedContractorId(originalContractorId);
        setSelectedStartDate(originalStartDate);
        setCod(originalCod);
    }

    function changesPresent() {
        return (
            // originalContractorId !== selectedContractorId ||
            selectedStartDate !== originalStartDate || originalCod !== cod
        );
    }

    function canUpdate() {
        if (!scheduled.apptId) {
            alert("Something has gone wrong - please try again shortly");
            return false;
        }

        // TODO: Determine if we need to validate the selected start date
        if (isNullOrUndefined(selectedStartDate) || !dayExists(selectedStartDate!)) {
            alert("Selected date is invalid");
            return false;
        }

        const lowerBound = codBounds.lowerBound;
        const upperBound = codBounds.upperBound;
        if (!(cod! <= upperBound && cod! >= lowerBound)) {
            if (upperBound === lowerBound) {
                alert(`Installation COD must be set to $${upperBound}`);
            } else {
                alert(`Installation COD must be set between $${lowerBound} and $${upperBound}`);
            }

            return false;
        }

        return true;
    }

    function onUpdate() {
        if (changesPresent()) {
            // a change has occurred, so we can update
            if (canUpdate()) {
                updateInstallationAppointment({
                    variables: {
                        appointmentId: scheduled.apptId,
                        // newContractorId: selectedContractorId,
                        newStartDate: dayToIso(selectedStartDate!),
                        newCod: cod!,
                    },
                });
            }
        } else {
            // else nothing has changed, so no need to update anything
            setPopoutOpen(false);
        }
    }

    // deletes the ENTIRE appointment
    function onDeleteAppointment() {
        if (window.confirm("Are you sure you want to remove this installation appointment?")) {
            deleteInstallationAppointment({ variables: { appointmentId: scheduled.apptId } });
        }
    }

    function onDeleteAreaFromAppointment(roomIds: number[]) {
        if (window.confirm("Are you sure you want to remove this area from the installation appointment?")) {
            deleteRoomsForInstallation({
                variables: { appointmentId: scheduled.apptId, roomIds: roomIds },
            });
        }
    }

    function onDeleteRoomFromAppointment(roomId: number) {
        if (window.confirm("Are you sure you want to remove this room from the installation appointment?")) {
            deleteRoomForInstallation({
                variables: { appointmentId: scheduled.apptId, roomId: roomId },
            });
        }
    }

    // TODO: rewrite filterCapableContractors to take a list of product type IDs present,
    // since that is the only part of the StagedAppointmentSchedulings that is relevant to that
    // is relevant to finding which contractors are capable
    // we actually only care about the product types present, but we need to use StagedAppointmentSchedulings
    // for compatibility with the existing filterCapableContractors
    function scheduledToStaged(
        scheduled: ScheduledInstallationSummary
    ): StagedAppointmentSchedulings {
        let staged: StagedAppointmentSchedulings = {};
        scheduled.productsForAppointment.forEach((pra) => {
            const pTypeId = pra.productTypeId;
            const roomIds = pra.rooms.map((room) => room.id);
            staged[pTypeId] = {
                // we don't care what the key is
                roomIds: roomIds,
                productTypeId: pTypeId,
            };
        });

        return staged;
    }

    const [getCodBounds, { data: boundsData }] = useGetInstallationCodBoundsForExistingLazyQuery({
        onError: () => alert("Could not calculate C.O.D. requirements"),
    });
    const codBounds = boundsData?.installationCodBoundsForExisting ?? {
        lowerBound: -1,
        upperBound: -1,
    };

    useEffect(() => {
        const startDate = selectedStartDate;
        const roomIds = roomIdsFromStaged(scheduledToStaged(scheduled));

        if (startDate && roomIds.length > 0 && jobConfigurationId) {
            getCodBounds({
                variables: {
                    appointmentId: scheduled.apptId,
                    newStartDate: dayToIso(startDate),
                },
            });
        }
    }, [selectedStartDate, getCodBounds, jobConfigurationId, scheduled]);

    return (
        <div className="flex-column">
            <div className="flex-row align-items-flex-start flat-font">
                <p className={clsx("margin-none", {"success-text": updateSucceeded})}>
                    {dateText} {` - ${contractorFname} ${contractorLname}`}
                </p>

                {popoutOpen ? (
                    <>
                        <IconButton
                            className="padding-none margin-left-xsm"
                            onClick={onClosePopout}
                        >
                            <CancelIcon fontSize="small" htmlColor="black"/>
                        </IconButton>

                        <IconButton
                            className="padding-none margin-left-xsm"
                            disabled={isNullOrUndefined(selectedStartDate)}
                            onClick={onUpdate}
                        >
                            <CheckIcon fontSize="small" htmlColor="black"/>
                        </IconButton>
                    </>
                ) : (
                    <>
                        <IconButton
                            onClick={() => setPopoutOpen(true)}
                            className="padding-none margin-left-xsm"
                        >
                            <EditIcon fontSize="small" htmlColor="black"/>
                        </IconButton>
                    </>
                )}

                <IconButton
                    className="padding-none margin-left-xsm"
                    onClick={onDeleteAppointment}
                >
                    <DeleteIcon fontSize="small" htmlColor="black"/>
                </IconButton>
            </div>

            {scheduled.productsForAppointment.map((pfa) => (
                <SummaryProductSubrow
                    productData={pfa}
                    onDeleteAreaFromAppointment={onDeleteAreaFromAppointment}
                    onDeleteRoomFromAppointment={onDeleteRoomFromAppointment}
                    key={`${scheduled.apptId}-${pfa.rooms.map((r) => r.id).join("-")}`}
                />
            ))}

            {popoutOpen && (
                <div className="margin-top-xsm">
                    <SchedulingPopout
                        selectedDate={selectedStartDate}
                        setSelectedDate={setSelectedStartDate}
                        cod={cod}
                        setCod={setCod}
                        codBounds={codBounds}
                        calendarId={`calendar-${scheduled.apptId}`}
                        popoutId={`popout-${scheduled.apptId}`}
                        key={`popout-${scheduled.apptId}`}
                    />
                </div>
            )}

            {updating && <p>Updating...</p>}
            {apptDeleting && <p>Deleting...</p>}

            {updateFailed && <p className="error-text">Failed to update appointment</p>}
            {apptDeleteFailed && <p className="error-text">Failed to delete appointment</p>}
        </div>
    );
}

interface SummaryProductSubrowProps {
    productData: ProductWithRooms;
    onDeleteRoomFromAppointment: (roomId: number) => void;
    onDeleteAreaFromAppointment: (roomIds: number[]) => void;
    key: string;
}

function SummaryProductSubrow({
    productData,
    onDeleteRoomFromAppointment,
    onDeleteAreaFromAppointment,
}: SummaryProductSubrowProps) {
    const [showRooms, setShowRooms] = useState(false);

    let roomIds = productData.rooms.map((r) => +r.id); // IDs for each room being installed with this product

    return (
        <div className="flex-column">
            <span className="flex-row flex-centered">
                {showRooms ? (
                    <IconButton onClick={() => setShowRooms(false)}>
                        <KeyboardArrowRightIcon style={{rotate: "0.25turn"}} fontSize="small" htmlColor="black"/>
                    </IconButton>
                ) : (
                    <IconButton onClick={() => setShowRooms(true)}>
                        <KeyboardArrowRightIcon fontSize="small" htmlColor="black"/>
                    </IconButton>
                )}

                <span className="flex-row flex-gap-sm">
                    <p className="margin-none bold-text">{productData.productType}</p>
                    <p className="margin-none">{productData.installAmt.toFixed(0)} sqft</p>
                </span>

                <IconButton onClick={() => onDeleteAreaFromAppointment(roomIds)}>
                    <DeleteIcon fontSize="small" htmlColor="black"/>
                </IconButton>
            </span>

            {showRooms &&
                productData.rooms.map((room) => (
                    <RoomSubRow
                        room={room}
                        onDeleteRoomFromAppointment={onDeleteRoomFromAppointment}
                        key={room.id}
                    />
                ))}
        </div>
    );
}

interface RoomSubRowProps {
    room: Room;
    onDeleteRoomFromAppointment: (roomId: number) => void;
}

function RoomSubRow({ room, onDeleteRoomFromAppointment }: RoomSubRowProps) {
    return (
        <span
            className="flex-row flex-centered"
            style={{ marginLeft: "3rem" }}
        >
            <p className="margin-none italic-text">{room.name}</p>
            <p className="margin-vertical-none margin-left-xsm">
                {room.sqft.toFixed(0)} sqft
            </p>

            <IconButton onClick={() => onDeleteRoomFromAppointment(room.id)}>
                <DeleteIcon fontSize="small" htmlColor="black"/>
            </IconButton>
        </span>
    );
}
