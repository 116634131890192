import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  /** The built-in `Decimal` scalar type. */
  Decimal: number;
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: string;
};





export type AcceptedPaymentMethod = {
  __typename?: 'AcceptedPaymentMethod';
  id: Scalars['Int'];
  method: Scalars['String'];
};

export type AddContractToJobWithDepositMutationInput = {
  jobConfigurationId: Scalars['Int'];
  contractSignatureBase64: Scalars['String'];
  noticeOfRightSignatureBase64: Scalars['String'];
  payments: Array<JobContractPaymentInput>;
  financingAccountNumber?: Maybe<Scalars['String']>;
  updatedJobNotes: Scalars['String'];
  updatedAreaNotes: Array<AreaNotesAndIdInput>;
  email: Scalars['String'];
};

export type AddLaborRulesInput = {
  serviceTypeId: Scalars['Int'];
  description: Scalars['String'];
  priceUnit: Scalars['String'];
  productValues: Array<ProductAndValuesInput>;
};

export type AddLaborRulesPayload = {
  __typename?: 'AddLaborRulesPayload';
  addedIds: Array<Scalars['Int']>;
  errorProductIds: Array<Scalars['Int']>;
};

export type AddMultipleOfferingsToExistingServiceInput = {
  jobServiceId: Scalars['Int'];
  productValues: Array<ProductAndValuesInput>;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['Int'];
  streetAddress: Scalars['String'];
  city: Scalars['String'];
  zip: Scalars['String'];
  apartmentNumber?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  id: Scalars['Int'];
  streetAddress: Scalars['String'];
  city: Scalars['String'];
  zip: Scalars['String'];
  apartmentNumber?: Maybe<Scalars['String']>;
};

export type AddServiceMaterialCategoryPricingInput = {
  productTypeId: Scalars['Int'];
  price: Scalars['Decimal'];
  cost: Scalars['Decimal'];
};

export type AppliedDiscount = {
  __typename?: 'AppliedDiscount';
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  discountId: Scalars['Int'];
  name: Scalars['String'];
  isScalar: Scalars['Boolean'];
  amount: Scalars['Decimal'];
  authorizationCode?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
};

export type AppliedDiscountAction = {
  __typename?: 'AppliedDiscountAction';
  id: Scalars['Int'];
  discount: AppliedDiscount;
  isRemoval: Scalars['Boolean'];
};

export type AppliedDiscountInput = {
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  discountId: Scalars['Int'];
  name: Scalars['String'];
  isScalar: Scalars['Boolean'];
  amount: Scalars['Decimal'];
  authorizationCode?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}

export type Area = {
  __typename?: 'Area';
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  lineNumber?: Maybe<Scalars['Int']>;
  productTypeId?: Maybe<Scalars['Int']>;
  productTypeName?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['Int']>;
  styleName?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['Int']>;
  colorName?: Maybe<Scalars['String']>;
  notes: Scalars['String'];
  lnftWasteFactor?: Maybe<Scalars['Decimal']>;
  sqftWasteFactor?: Maybe<Scalars['Decimal']>;
  price?: Maybe<Price>;
  includedInQuote?: Maybe<Scalars['Boolean']>;
  preferredCarpetLength?: Maybe<Scalars['Decimal']>;
  oppositeCarpetLength?: Maybe<Scalars['Decimal']>;
  rooms: Array<Room>;
  customServices: Array<CustomService>;
};

export type AreaAndRoomNotes = {
  __typename?: 'AreaAndRoomNotes';
  areaNotes?: Maybe<LabeledNote>;
  roomNotes: Array<LabeledNote>;
};

export type AreaBreakdown = {
  __typename?: 'AreaBreakdown';
  areaId: Scalars['Int'];
  sqftWasteFactor: Scalars['Decimal'];
  lnftWasteFactor: Scalars['Decimal'];
  lineNum: Scalars['Int'];
  areaLabels: Array<LabelForRoom>;
  areaNotes?: Maybe<Scalars['String']>;
  rooms?: Maybe<Array<Room>>;
  product: Product;
  productSqft: Scalars['Decimal'];
  services: Array<ServiceForRoomBreakdown>;
  customServices: Array<CustomService>;
};

export type AreaInput = {
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  lineNumber?: Maybe<Scalars['Int']>;
  productTypeId?: Maybe<Scalars['Int']>;
  productTypeName?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['Int']>;
  styleName?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['Int']>;
  colorName?: Maybe<Scalars['String']>;
  notes: Scalars['String'];
  lnftWasteFactor?: Maybe<Scalars['Decimal']>;
  sqftWasteFactor?: Maybe<Scalars['Decimal']>;
  price?: Maybe<PriceInput>;
  includedInQuote?: Maybe<Scalars['Boolean']>;
  preferredCarpetLength?: Maybe<Scalars['Decimal']>;
  oppositeCarpetLength?: Maybe<Scalars['Decimal']>;
  rooms: Array<RoomInput>;
  customServices: Array<CustomServiceInput>;
};

export type AreaNotesAndId = {
  __typename?: 'AreaNotesAndId';
  areaId: Scalars['Int'];
  areaNotes: Scalars['String'];
};

export type AreaNotesAndIdInput = {
  areaId: Scalars['Int'];
  areaNotes: Scalars['String'];
};

export type AreaProductDetail = {
  __typename?: 'AreaProductDetail';
  id: Scalars['Int'];
  productTypeId?: Maybe<Scalars['Int']>;
  styleId?: Maybe<Scalars['Int']>;
  colorId?: Maybe<Scalars['Int']>;
  includedInQuote: Scalars['Boolean'];
};

export type AtomicTimeSlot = {
  __typename?: 'AtomicTimeSlot';
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
};

export type AtomicTimeSlotInput = {
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
};

export type BackgroundCheck = {
  __typename?: 'BackgroundCheck';
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  isInternal: Scalars['Boolean'];
  number?: Maybe<Scalars['String']>;
  datePerformed: Scalars['DateTime'];
  expirationDate: Scalars['DateTime'];
};

export type BackgroundCheckInput = {
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  isInternal: Scalars['Boolean'];
  number?: Maybe<Scalars['String']>;
  datePerformed: Scalars['DateTime'];
  expirationDate: Scalars['DateTime'];
};

export type BasePromotion = {
  __typename?: 'BasePromotion';
  id: Scalars['Int'];
  name: Scalars['String'];
  promotionTypes: Array<TypeDetailForBasePromotion>;
  isDeleted: Scalars['Boolean'];
};

export type BasePromotionInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  promotionTypes: Array<TypeDetailForBasePromotionInput>;
  isDeleted: Scalars['Boolean'];
};

export type BlockedMarketTimeSlot = {
  __typename?: 'BlockedMarketTimeSlot';
  salespersonId: Scalars['Int'];
  marketId: Scalars['Int'];
  marketTimeSlotId: Scalars['Int'];
  date: Scalars['DateTime'];
  workerBlockedTimeSlotIdIfDeletable?: Maybe<Scalars['Int']>;
};

export type BlockedTimeSlot = {
  __typename?: 'BlockedTimeSlot';
  id: Scalars['Int'];
  marketTimeSlotId: Scalars['Int'];
  marketId: Scalars['Int'];
  date: Scalars['DateTime'];
  timeSlot: AtomicTimeSlot;
};

export type CallCenterAction = {
  __typename?: 'CallCenterAction';
  id: Scalars['Int'];
  callCenterActionOptionId: Scalars['Int'];
  callCenterActionOptionLabel: Scalars['String'];
  notes: Scalars['String'];
  date: Scalars['DateTime'];
  createdByWorkerId: Scalars['Int'];
};

export type CallCenterJob = {
  __typename?: 'CallCenterJob';
  id: Scalars['Int'];
  callCenterWorker?: Maybe<Worker>;
  creationTime: Scalars['DateTime'];
  minutesFromCreation: Scalars['Int'];
  prospectNotes: Scalars['String'];
  customerId: Scalars['Int'];
  customerFirstName: Scalars['String'];
  customerLastName: Scalars['String'];
  customerCity: Scalars['String'];
  customerEmail?: Maybe<Scalars['String']>;
  customerPhone: Scalars['String'];
  callBackTime?: Maybe<Scalars['DateTime']>;
  isQualified: Scalars['Boolean'];
  appointment?: Maybe<CallCenterSahAppointment>;
  lastAction?: Maybe<CallCenterAction>;
  isInRecovery: Scalars['Boolean'];
  recoveryCallWorker?: Maybe<Worker>;
  dateEnteredRecovery?: Maybe<Scalars['DateTime']>;
};

export type CallCenterJobSummary = {
  __typename?: 'CallCenterJobSummary';
  id: Scalars['Int'];
  customer: Customer;
  appointment: SahAppointment;
  mostRecentSalesperson?: Maybe<Salesperson>;
};

export type CallCenterSahAppointment = {
  __typename?: 'CallCenterSahAppointment';
  id: Scalars['Int'];
  jobId: Scalars['Int'];
  appointmentNumber: Scalars['String'];
  scheduledDate: Scalars['DateTime'];
  timeReminderSent?: Maybe<Scalars['DateTime']>;
  timeToSendReminder?: Maybe<Scalars['DateTime']>;
  remindToday: Scalars['Boolean'];
  isCancelled: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
};

export type CallCenterScript = {
  __typename?: 'CallCenterScript';
  id: Scalars['Int'];
  name: Scalars['String'];
  text: Scalars['String'];
  lastUpdated?: Maybe<Scalars['DateTime']>;
};

export type CallCenterScriptInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  text: Scalars['String'];
  lastUpdated?: Maybe<Scalars['DateTime']>;
};

export type CarpetCut = {
  __typename?: 'CarpetCut';
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  roomId: Scalars['Int'];
  directionBias: Scalars['Int'];
  stepLength?: Maybe<Scalars['Decimal']>;
  shape: Array<RoomShapePoint>;
  pieces: Array<CarpetCutPiece>;
};

export type CarpetCutInput = {
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  roomId: Scalars['Int'];
  directionBias: Scalars['Int'];
  stepLength?: Maybe<Scalars['Decimal']>;
  shape: Array<RoomShapePointInput>;
  pieces: Array<CarpetCutPieceInput>;
};

export type CarpetCutPiece = {
  __typename?: 'CarpetCutPiece';
  id: Scalars['Int'];
  carpetCutId: Scalars['Int'];
  fillName?: Maybe<Scalars['String']>;
  packingBlockNumber: Scalars['Int'];
  roomShape: Array<RoomShapePoint>;
  packerShape: Array<RoomShapePoint>;
};

export type CarpetCutPieceInput = {
  id: Scalars['Int'];
  carpetCutId: Scalars['Int'];
  fillName?: Maybe<Scalars['String']>;
  packingBlockNumber: Scalars['Int'];
  roomShape: Array<RoomShapePointInput>;
  packerShape: Array<RoomShapePointInput>;
};

export type ChargeableServicePricingDetails = {
  __typename?: 'ChargeableServicePricingDetails';
  id: Scalars['Int'];
  jobServiceId: Scalars['Int'];
  laborPricePerUnit: Scalars['Decimal'];
  minimumLaborAmount: Scalars['Decimal'];
  materialPricePerUnit: Scalars['Decimal'];
  materialCategoryId?: Maybe<Scalars['Int']>;
  materialPackageSize?: Maybe<Scalars['Decimal']>;
};

export type ChatAndMessages = {
  __typename?: 'ChatAndMessages';
  id: Scalars['Int'];
  messages: Array<ChatMessage>;
};

export type ChatForJob = {
  __typename?: 'ChatForJob';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  jobMember: ChatMember;
  commandCenterMember: ChatMember;
  customerName: Scalars['String'];
};

export type ChatMember = {
  __typename?: 'ChatMember';
  chatPartyId: Scalars['Int'];
  chatId: Scalars['Int'];
  partyName: Scalars['String'];
  hasNewMessage: Scalars['Boolean'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  sendingPartyId: Scalars['Int'];
  sendingWorkerId: Scalars['Int'];
  senderFirstName: Scalars['String'];
  senderLastName: Scalars['String'];
  text: Scalars['String'];
  timeSent?: Maybe<Scalars['DateTime']>;
  received: Scalars['Boolean'];
};

export type ChatMessageInput = {
  id: Scalars['Int'];
  chatId: Scalars['Int'];
  sendingPartyId: Scalars['Int'];
  sendingWorkerId: Scalars['Int'];
  senderFirstName: Scalars['String'];
  senderLastName: Scalars['String'];
  text: Scalars['String'];
  timeSent?: Maybe<Scalars['DateTime']>;
  received: Scalars['Boolean'];
};

export type ChatParty = {
  __typename?: 'ChatParty';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ChatRemoval = {
  __typename?: 'ChatRemoval';
  id: Scalars['Int'];
  retainForCommandCenterWorkerId?: Maybe<Scalars['Int']>;
};

export type ColorCategory = {
  __typename?: 'ColorCategory';
  id: Scalars['Int'];
  category: Scalars['String'];
};

export type ColorIdAndVendors = {
  __typename?: 'ColorIdAndVendors';
  id: Scalars['Int'];
  vendors: Array<VendorName>;
};

export type ColorNamePairInput = {
  privateColor: Scalars['String'];
  vendorColor: Scalars['String'];
};

export type CommissionRate = {
  __typename?: 'CommissionRate';
  id: Scalars['Int'];
  lowerMargin: Scalars['Decimal'];
  upperMargin: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  creationDate: Scalars['DateTime'];
  archiveDate?: Maybe<Scalars['DateTime']>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['Int'];
  name: Scalars['String'];
  address: Address;
  eIN: Scalars['String'];
};

export type CompanyInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  address: AddressInput;
  eIN: Scalars['String'];
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  nin?: Maybe<Array<Scalars['Int']>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
};

export type ComparableSingleOperationFilterInput = {
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
  gt?: Maybe<Scalars['Float']>;
  ngt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  ngte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  nlt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  nlte?: Maybe<Scalars['Float']>;
};

export type CompletionCertificate = {
  __typename?: 'CompletionCertificate';
  id: Scalars['Int'];
  question1Rating: Scalars['Int'];
  overallRating: Scalars['Int'];
  comments?: Maybe<Scalars['String']>;
  signingDate: Scalars['DateTime'];
};

export type ConfigIdsWithLabels = {
  __typename?: 'ConfigIdsWithLabels';
  id: Scalars['Int'];
  labels: Array<Scalars['String']>;
};

export type ContractCodData = {
  __typename?: 'ContractCODData';
  id: Scalars['Int'];
  remaining: Scalars['Decimal'];
  remainingToSchedule: Scalars['Decimal'];
  isFinancing: Scalars['Boolean'];
};

export type Contractor = {
  __typename?: 'Contractor';
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  helpers: Array<ContractorHelper>;
  capabilities: Array<ContractorInstallationCapability>;
  profilePictureFileId?: Maybe<Scalars['Int']>;
};

export type ContractorHelper = {
  __typename?: 'ContractorHelper';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  licenseNumber?: Maybe<Scalars['String']>;
  contractorId: Scalars['Int'];
  history: Array<WorkerHistoryDates>;
  isActive: Scalars['Boolean'];
  backgroundChecks: Array<BackgroundCheck>;
};

export type ContractorHelperInput = {
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  licenseNumber?: Maybe<Scalars['String']>;
  contractorId: Scalars['Int'];
  history: Array<WorkerHistoryDatesInput>;
  isActive: Scalars['Boolean'];
  backgroundChecks: Array<BackgroundCheckInput>;
};

export type ContractorInstallationCapabilitiesAndDaysOff = {
  __typename?: 'ContractorInstallationCapabilitiesAndDaysOff';
  id: Scalars['Int'];
  contractor: Contractor;
  installationCapabilities: Array<ContractorInstallationCapability>;
  daysOff: Array<Scalars['DateTime']>;
};

export type ContractorInstallationCapability = {
  __typename?: 'ContractorInstallationCapability';
  contractorId: Scalars['Int'];
  productTypeId: Scalars['Int'];
  sqftPotential: Scalars['Int'];
  isActive: Scalars['Boolean'];
  certificationIds: Array<Scalars['Int']>;
};

export type ContractorInstallationCapabilityInput = {
  contractorId: Scalars['Int'];
  productTypeId: Scalars['Int'];
  sqftPotential: Scalars['Int'];
  isActive: Scalars['Boolean'];
  certificationIds: Array<Scalars['Int']>;
};

export type ContractorProfile = {
  __typename?: 'ContractorProfile';
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  rating: Scalars['Decimal'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  servicedZips: Array<Scalars['String']>;
  address?: Maybe<Address>;
  driversLicenseNumber?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Vehicle>;
  parentCompany?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  workerHistory?: Maybe<WorkerHistory>;
  helpers: Array<ContractorHelper>;
  backgroundChecks: Array<BackgroundCheck>;
  capabilities: Array<ContractorInstallationCapability>;
  profilePictureFileId?: Maybe<Scalars['Int']>;
  driversLicenseFile?: Maybe<S3File>;
  licenseFile?: Maybe<S3File>;
  w9s: Array<W9>;
  workersComps: Array<WorkersComp>;
  liabilityInsurances: Array<LiabilityInsurance>;
};

export type ContractorProfileInput = {
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  rating: Scalars['Decimal'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  servicedZips: Array<Scalars['String']>;
  address?: Maybe<AddressInput>;
  driversLicenseNumber?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleInput>;
  parentCompany?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyInput>;
  workerHistory?: Maybe<WorkerHistoryInput>;
  helpers: Array<ContractorHelperInput>;
  backgroundChecks: Array<BackgroundCheckInput>;
  capabilities: Array<ContractorInstallationCapabilityInput>;
  profilePictureFileId?: Maybe<Scalars['Int']>;
  driversLicenseFile?: Maybe<S3FileInput>;
  licenseFile?: Maybe<S3FileInput>;
  w9s: Array<W9Input>;
  workersComps: Array<WorkersCompInput>;
  liabilityInsurances: Array<LiabilityInsuranceInput>;
};

export type ContractorRatingParameters = {
  __typename?: 'ContractorRatingParameters';
  id: Scalars['Int'];
  numAppointments: Scalars['Int'];
  numServices: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  calledInAmWeight: Scalars['Decimal'];
  checkedInWeight: Scalars['Decimal'];
  onTimeWeight: Scalars['Decimal'];
  collectedCodWeight: Scalars['Decimal'];
  completedJobWeight: Scalars['Decimal'];
  completionCertificateSignedWeight: Scalars['Decimal'];
  completionCertificateRatingWeight: Scalars['Decimal'];
  serviceRequestsWeight: Scalars['Decimal'];
  servicesCompletedOnTimeWeight: Scalars['Decimal'];
};

export type ContractorRatingParametersInput = {
  id: Scalars['Int'];
  numAppointments: Scalars['Int'];
  numServices: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  calledInAmWeight: Scalars['Decimal'];
  checkedInWeight: Scalars['Decimal'];
  onTimeWeight: Scalars['Decimal'];
  collectedCodWeight: Scalars['Decimal'];
  completedJobWeight: Scalars['Decimal'];
  completionCertificateSignedWeight: Scalars['Decimal'];
  completionCertificateRatingWeight: Scalars['Decimal'];
  serviceRequestsWeight: Scalars['Decimal'];
  servicesCompletedOnTimeWeight: Scalars['Decimal'];
};

export type ContractorRatingSummary = {
  __typename?: 'ContractorRatingSummary';
  id: Scalars['Int'];
  contractorId: Scalars['Int'];
  ratingParametersId: Scalars['Int'];
  calledInAm: Scalars['Int'];
  checkedIn: Scalars['Int'];
  onTime: Scalars['Int'];
  collectedCod: Scalars['Int'];
  completedJob: Scalars['Int'];
  completionCertificateSigned: Scalars['Int'];
  completionCertificateRating: Scalars['Int'];
  serviceRequests: Scalars['Int'];
  numAppointments: Scalars['Int'];
  servicesCompletedOnTime: Scalars['Int'];
  numServices: Scalars['Int'];
  rating: Scalars['Decimal'];
  date: Scalars['DateTime'];
};

export type ContractPdfData = {
  __typename?: 'ContractPdfData';
  contractSignatureBase64: Scalars['String'];
  noticeOfRightSignatureBase64: Scalars['String'];
  payments: Array<JobContractPayment>;
  financingAccountNumber?: Maybe<Scalars['String']>;
  updatedJobNotes: Scalars['String'];
  updatedAreaNotes: Array<AreaNotesAndId>;
  signingDate: Scalars['DateTime'];
};

export type ContractProgress = {
  __typename?: 'ContractProgress';
  id: Scalars['Int'];
  stored: Scalars['Boolean'];
  emailed: Scalars['Boolean'];
};

export type CostAndPrice = {
  __typename?: 'CostAndPrice';
  id: Scalars['Int'];
  cost: Scalars['Decimal'];
  price: Scalars['Decimal'];
};

export type County = {
  __typename?: 'County';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  businessName?: Maybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  primaryPhoneExtension?: Maybe<Scalars['String']>;
  primaryAddress: Address;
  billingAddress?: Maybe<Address>;
  email: Scalars['String'];
  receivePromoEmails: Scalars['Boolean'];
  customerContacts: Array<CustomerContact>;
};

export type CustomerAppointmentSearchResult = {
  __typename?: 'CustomerAppointmentSearchResult';
  id: Scalars['Int'];
  customerId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  appointmentNumber: Scalars['String'];
  appointmentDate: Scalars['DateTime'];
  marketId: Scalars['Int'];
};

export type CustomerCleanupData = {
  __typename?: 'CustomerCleanupData';
  id: Scalars['Int'];
  contactNames: Array<Scalars['String']>;
  contactEmails: Array<Scalars['String']>;
};

/** A connection to a list of items. */
export type CustomerCleanupListConnection = {
  __typename?: 'CustomerCleanupListConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<CustomerCleanupListEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CustomerCleanupData>>;
};

/** An edge in a connection. */
export type CustomerCleanupListEdge = {
  __typename?: 'CustomerCleanupListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CustomerCleanupData;
};

export type CustomerContact = {
  __typename?: 'CustomerContact';
  id: Scalars['Int'];
  customerId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneExtension?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  receivePromoEmails: Scalars['Boolean'];
  isSchedulingContact: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
};

export type CustomerContactInput = {
  id: Scalars['Int'];
  customerId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneExtension?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  receivePromoEmails: Scalars['Boolean'];
  isSchedulingContact: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
};

export type CustomerInput = {
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  businessName?: Maybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  primaryPhoneExtension?: Maybe<Scalars['String']>;
  primaryAddress: AddressInput;
  billingAddress?: Maybe<AddressInput>;
  email: Scalars['String'];
  receivePromoEmails: Scalars['Boolean'];
  customerContacts: Array<CustomerContactInput>;
};

export type CustomerJobHistory = {
  __typename?: 'CustomerJobHistory';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobSummaries: Array<HistoricalJobSummary>;
};

export type CustomerNameAndAppointmentNumber = {
  __typename?: 'CustomerNameAndAppointmentNumber';
  jobConfigurationId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  appointmentNumber: Scalars['String'];
};

export type CustomerSearchParametersInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  emailAddress1?: Maybe<Scalars['String']>;
  emailAddress2?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  phoneNumber1?: Maybe<Scalars['String']>;
  phoneNumber2?: Maybe<Scalars['String']>;
};

export type CustomerSearchResult = {
  __typename?: 'CustomerSearchResult';
  id: Scalars['Int'];
  primaryContact: CustomerContact;
  secondaryContacts: Array<CustomerContact>;
  businessName?: Maybe<Scalars['String']>;
  primaryAddress: Address;
  jobId?: Maybe<Scalars['Int']>;
  prospectNotes?: Maybe<Scalars['String']>;
};

export type CustomService = {
  __typename?: 'CustomService';
  id: Scalars['Int'];
  areaId: Scalars['Int'];
  roomIds: Array<Scalars['Int']>;
  description: Scalars['String'];
  price: Scalars['Decimal'];
  contractorPercentage: Scalars['Decimal'];
};

export type CustomServiceInput = {
  id: Scalars['Int'];
  areaId: Scalars['Int'];
  roomIds: Array<Scalars['Int']>;
  description: Scalars['String'];
  price: Scalars['Decimal'];
  contractorPercentage: Scalars['Decimal'];
};



export type DefaultServiceForRoomLabel = {
  __typename?: 'DefaultServiceForRoomLabel';
  roomLabelId: Scalars['Int'];
  jobServiceId?: Maybe<Scalars['Int']>;
  serviceTypeId?: Maybe<Scalars['Int']>;
  isOptional: Scalars['Boolean'];
  defaultValue?: Maybe<Scalars['Decimal']>;
  isServiceType: Scalars['Boolean'];
};

export type DefaultServiceForRoomLabelInput = {
  roomLabelId: Scalars['Int'];
  jobServiceId?: Maybe<Scalars['Int']>;
  serviceTypeId?: Maybe<Scalars['Int']>;
  isOptional: Scalars['Boolean'];
  defaultValue?: Maybe<Scalars['Decimal']>;
  isServiceType: Scalars['Boolean'];
};

export type Discount = {
  __typename?: 'Discount';
  id: Scalars['Int'];
  name: Scalars['String'];
  isScalar: Scalars['Boolean'];
  defaultValue: Scalars['Decimal'];
  isHidden: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  minimumMSRP?: Maybe<Scalars['Decimal']>;
};

export type DiscountInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  isScalar: Scalars['Boolean'];
  defaultValue: Scalars['Decimal'];
  isHidden: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  minimumMSRP?: Maybe<Scalars['Decimal']>;
};

export type DiscountsOnJob = {
  __typename?: 'DiscountsOnJob';
  id: Scalars['Int'];
  isOverrideApplied: Scalars['Boolean'];
  discounts: Array<AppliedDiscount>;
  availableDiscounts: Array<Discount>;
};

export type DiscountsOnJobInput = {
  id: Scalars['Int'];
  isOverrideApplied: Scalars['Boolean'];
  discounts: Array<AppliedDiscountInput>;
  availableDiscounts: Array<DiscountInput>;
};

export type Drawing = {
  __typename?: 'Drawing';
  id: Scalars['Int'];
  lastModified?: Maybe<Scalars['DateTime']>;
  json: Scalars['String'];
};

export type ExistentialJobConfigurationAction = {
  __typename?: 'ExistentialJobConfigurationAction';
  id: Scalars['Int'];
  optionNumber: Scalars['Int'];
  isDeletion: Scalars['Boolean'];
};

export type Financing = {
  __typename?: 'Financing';
  id: Scalars['Int'];
  financingDownPayment: Scalars['Decimal'];
  financingOption?: Maybe<FinancingOption>;
  financingAccountNumber: Scalars['String'];
  perMonthAmount: Scalars['Decimal'];
};

export type FinancingInput = {
  id: Scalars['Int'];
  financingDownPayment: Scalars['Decimal'];
  financingOption?: Maybe<FinancingOptionInput>;
  financingAccountNumber: Scalars['String'];
  perMonthAmount: Scalars['Decimal'];
};

export type FinancingOption = {
  __typename?: 'FinancingOption';
  id: Scalars['Int'];
  term: Scalars['Int'];
  minAmount: Scalars['Decimal'];
  typeId: Scalars['Int'];
  typeName?: Maybe<Scalars['String']>;
  fee: Scalars['Decimal'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  tranCode: Scalars['String'];
  promoParentTranCode?: Maybe<Scalars['String']>;
  slot: Scalars['Int'];
};

export type FinancingOptionInput = {
  id: Scalars['Int'];
  term: Scalars['Int'];
  minAmount: Scalars['Decimal'];
  typeId: Scalars['Int'];
  typeName?: Maybe<Scalars['String']>;
  fee: Scalars['Decimal'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  tranCode: Scalars['String'];
  promoParentTranCode?: Maybe<Scalars['String']>;
  slot: Scalars['Int'];
};

export type FinancingOverrideInput = {
  jobConfigurationId: Scalars['Int'];
  financingOptionId: Scalars['Int'];
  slot: Scalars['Int'];
};

export type FinancingSlotOptions = {
  __typename?: 'FinancingSlotOptions';
  jobPrice: Scalars['Int'];
  slotA?: Maybe<FinancingOption>;
  slotB?: Maybe<FinancingOption>;
  slotC?: Maybe<FinancingOption>;
};

export type FinancingTableRow = {
  __typename?: 'FinancingTableRow';
  id: Scalars['String'];
  baseOption: FinancingOption;
  baseHistory: Array<FinancingOption>;
  activePromo?: Maybe<FinancingOption>;
  promoHistory: Array<FinancingOption>;
};

export type FinancingType = {
  __typename?: 'FinancingType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type FullServiceMaterialCategoryPricing = {
  __typename?: 'FullServiceMaterialCategoryPricing';
  id: Scalars['Int'];
  serviceMaterialCategoryId: Scalars['Int'];
  serviceMaterialCategory: Scalars['String'];
  packageSize?: Maybe<Scalars['Decimal']>;
  packageSizeIncrement?: Maybe<Scalars['Decimal']>;
  productTypeId: Scalars['Int'];
  productType: Scalars['String'];
  pricePerUnit: Scalars['Decimal'];
  costPerUnit: Scalars['Decimal'];
  priceUnit: Scalars['String'];
};

export type GroupedInstallationAppointment = {
  __typename?: 'GroupedInstallationAppointment';
  id: Scalars['Int'];
  contractorId: Scalars['Int'];
  contractorFirstName?: Maybe<Scalars['String']>;
  contractorLastName?: Maybe<Scalars['String']>;
  dates: Array<Scalars['DateTime']>;
  startDatePassed: Scalars['Boolean'];
  endDatePassed: Scalars['Boolean'];
  isComplete: Scalars['Boolean'];
  productTypeId: Scalars['Int'];
  productType: Scalars['String'];
  styleAmounts: Array<StyleAndSqft>;
  cod?: Maybe<Scalars['Decimal']>;
  collectedCod?: Maybe<Scalars['Decimal']>;
  productTypeIndex?: Maybe<Scalars['Int']>;
};

export type HistoricalJobSummary = {
  __typename?: 'HistoricalJobSummary';
  id: Scalars['Int'];
  latestVisitDate: Scalars['DateTime'];
  productTypes: Array<Scalars['String']>;
  jobStatus: Scalars['String'];
  salesperson?: Maybe<Salesperson>;
};

export type HowDidYouHearAboutUsOption = {
  __typename?: 'HowDidYouHearAboutUsOption';
  id: Scalars['Int'];
  optionText: Scalars['String'];
};

export type InstallationAppointment = {
  __typename?: 'InstallationAppointment';
  id: Scalars['Int'];
  appointmentNumber: Scalars['String'];
  jobContractId: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  contractorId: Scalars['Int'];
  contractorFirstName?: Maybe<Scalars['String']>;
  contractorLastName?: Maybe<Scalars['String']>;
  dates: Array<Scalars['DateTime']>;
  totalDaysInRange: Scalars['Int'];
  customerFirstName: Scalars['String'];
  customerLastName: Scalars['String'];
  customerAddress: Address;
  customerPhone: Scalars['String'];
  notes: Scalars['String'];
  cod: Scalars['Decimal'];
  collectedCod: Scalars['Decimal'];
  releasedToContractor?: Maybe<Scalars['DateTime']>;
  pickedUpMaterialsTime?: Maybe<Scalars['DateTime']>;
  mappedToCustomerTime?: Maybe<Scalars['DateTime']>;
  checkInTime?: Maybe<Scalars['DateTime']>;
  checkOutTime?: Maybe<Scalars['DateTime']>;
  estimatedArrivalTime?: Maybe<Scalars['TimeSpan']>;
  contractorLastContactAttempt?: Maybe<Scalars['DateTime']>;
  customerNotifiedOfEta: Scalars['Boolean'];
  isComplete: Scalars['Boolean'];
  woodTotal: Scalars['Decimal'];
  spcTotal: Scalars['Decimal'];
  carpetTotal: Scalars['Decimal'];
  hasBeforePictures: Scalars['Boolean'];
  hasAfterPictures: Scalars['Boolean'];
  certificateRating?: Maybe<Scalars['Int']>;
};

export type InstallationAppointmentWithRooms = {
  __typename?: 'InstallationAppointmentWithRooms';
  id: Scalars['Int'];
  appointment: InstallationAppointment;
  rooms: Array<RoomForInstallationAppointment>;
};

export type InstallationCalendarBlockIdentifierInput = {
  type: InstallationCalendarType;
  identifier: Scalars['String'];
  startingDate: Scalars['DateTime'];
  contractorId: Scalars['Int'];
};

export type InstallationCalendarMovementResult = {
  __typename?: 'InstallationCalendarMovementResult';
  isAllowed: Scalars['Boolean'];
  isSwapAllowed: Scalars['Boolean'];
  isOutOfContractorServiceArea: Scalars['Boolean'];
  needsOverride: Scalars['Boolean'];
  sourceType: InstallationCalendarType;
  sourceIdentifier: Scalars['String'];
  sourceDate: Scalars['DateTime'];
  sourceContractorId: Scalars['Int'];
  destinationType?: Maybe<InstallationCalendarType>;
  destinationIdentifier?: Maybe<Scalars['String']>;
  destinationDate: Scalars['DateTime'];
  destinationContractorId: Scalars['Int'];
};

export enum InstallationCalendarType {
  Service = 'SERVICE',
  Blocked = 'BLOCKED',
  Installation = 'INSTALLATION'
}

export type InstallationCertification = {
  __typename?: 'InstallationCertification';
  id: Scalars['Int'];
  name: Scalars['String'];
  productTypeId: Scalars['Int'];
};

export type InstallationCodBounds = {
  __typename?: 'InstallationCodBounds';
  lowerBound: Scalars['Decimal'];
  upperBound: Scalars['Decimal'];
};

export type InstallationDetailsValidationData = {
  __typename?: 'InstallationDetailsValidationData';
  carpet: Array<KeyValuePairOfStringAndString__>;
  wood: Array<KeyValuePairOfStringAndString__>;
  lvp: Array<KeyValuePairOfStringAndString__>;
  lvt: Array<KeyValuePairOfStringAndString__>;
  ssrLabor: Array<KeyValuePairOfStringAndString__>;
  ssrLaborMaterial: Array<KeyValuePairOfStringAndString__>;
  other: Array<KeyValuePairOfStringAndString__>;
};

export type InstallationNote = {
  __typename?: 'InstallationNote';
  id: Scalars['Int'];
  contractorFirstName: Scalars['String'];
  contractorLastName: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  notes: Scalars['String'];
};

export type InstallationToSchedule = {
  __typename?: 'InstallationToSchedule';
  id: Scalars['Int'];
  jobId: Scalars['Int'];
  productType: Scalars['String'];
  sqft: Scalars['Decimal'];
  cod?: Maybe<Scalars['Decimal']>;
  productTypeIndex?: Maybe<Scalars['Int']>;
};

export type InventoryEntry = {
  __typename?: 'InventoryEntry';
  sku: Scalars['String'];
  measurementUnit: MeasurementUnit;
  orderType: OrderType;
  measureUnitPerInventoryUnit: Scalars['Float'];
  manufacturer: Scalars['String'];
  style: Scalars['String'];
  color: Scalars['String'];
  productType: ProductTypeOld;
  searchableProductType: Scalars['String'];
  stockUnit: StockUnit;
  newCount: Scalars['Float'];
  damagedCount?: Maybe<Scalars['Int']>;
  heldCount: Scalars['Float'];
  onOrderCountInStockUnits: Scalars['Int'];
  onOrderCountInOrderUnits: Scalars['Int'];
  rolls: Array<RollEntry>;
  available: Scalars['Float'];
  potential: Scalars['Int'];
  stockUnitPerOrderUnit: Scalars['Int'];
  toOrderCountInOrderUnits: Scalars['Int'];
  toOrderCountInStockUnits: Scalars['Int'];
  minimumStockCount: Scalars['Int'];
};

export type InventoryEntryFilterInput = {
  and?: Maybe<Array<InventoryEntryFilterInput>>;
  or?: Maybe<Array<InventoryEntryFilterInput>>;
  sku?: Maybe<StringOperationFilterInput>;
  measurementUnit?: Maybe<MeasurementUnitOperationFilterInput>;
  orderType?: Maybe<OrderTypeOperationFilterInput>;
  measureUnitPerInventoryUnit?: Maybe<ComparableSingleOperationFilterInput>;
  manufacturer?: Maybe<StringOperationFilterInput>;
  style?: Maybe<StringOperationFilterInput>;
  color?: Maybe<StringOperationFilterInput>;
  productType?: Maybe<ProductTypeOldOperationFilterInput>;
  searchableProductType?: Maybe<StringOperationFilterInput>;
  stockUnit?: Maybe<StockUnitOperationFilterInput>;
  newCount?: Maybe<ComparableSingleOperationFilterInput>;
  damagedCount?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  heldCount?: Maybe<ComparableSingleOperationFilterInput>;
  onOrderCountInStockUnits?: Maybe<ComparableInt32OperationFilterInput>;
  onOrderCountInOrderUnits?: Maybe<ComparableInt32OperationFilterInput>;
  rolls?: Maybe<ListFilterInputTypeOfRollEntryFilterInput>;
  available?: Maybe<ComparableSingleOperationFilterInput>;
  potential?: Maybe<ComparableInt32OperationFilterInput>;
  stockUnitPerOrderUnit?: Maybe<ComparableInt32OperationFilterInput>;
  toOrderCountInOrderUnits?: Maybe<ComparableInt32OperationFilterInput>;
  toOrderCountInStockUnits?: Maybe<ComparableInt32OperationFilterInput>;
  minimumStockCount?: Maybe<ComparableInt32OperationFilterInput>;
};

export type InventoryEntrySortInput = {
  sku?: Maybe<SortEnumType>;
  measurementUnit?: Maybe<SortEnumType>;
  orderType?: Maybe<SortEnumType>;
  measureUnitPerInventoryUnit?: Maybe<SortEnumType>;
  manufacturer?: Maybe<SortEnumType>;
  style?: Maybe<SortEnumType>;
  color?: Maybe<SortEnumType>;
  productType?: Maybe<SortEnumType>;
  searchableProductType?: Maybe<SortEnumType>;
  stockUnit?: Maybe<SortEnumType>;
  newCount?: Maybe<SortEnumType>;
  damagedCount?: Maybe<SortEnumType>;
  heldCount?: Maybe<SortEnumType>;
  onOrderCountInStockUnits?: Maybe<SortEnumType>;
  onOrderCountInOrderUnits?: Maybe<SortEnumType>;
  available?: Maybe<SortEnumType>;
  potential?: Maybe<SortEnumType>;
  stockUnitPerOrderUnit?: Maybe<SortEnumType>;
  toOrderCountInOrderUnits?: Maybe<SortEnumType>;
  toOrderCountInStockUnits?: Maybe<SortEnumType>;
  minimumStockCount?: Maybe<SortEnumType>;
};

export type Job = {
  __typename?: 'Job';
  id: Scalars['Int'];
  customer: Customer;
  assignedSalespeople: Array<Salesperson>;
  jobConfiguration?: Maybe<JobConfiguration>;
  callCenterId?: Maybe<Scalars['Int']>;
  projectCoordinatorId?: Maybe<Scalars['Int']>;
  dateClaimed?: Maybe<Scalars['DateTime']>;
  dateClosed?: Maybe<Scalars['DateTime']>;
  cwcDeadline?: Maybe<Scalars['DateTime']>;
  prospectNotes?: Maybe<Scalars['String']>;
};

export type JobBreakdown = {
  __typename?: 'JobBreakdown';
  jobConfigurationId: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  areaBreakdowns: Array<AreaBreakdown>;
};

export type JobConfigAndCustomerName = {
  __typename?: 'JobConfigAndCustomerName';
  jobConfigId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type JobConfiguration = {
  __typename?: 'JobConfiguration';
  id: Scalars['Int'];
  jobId: Scalars['Int'];
  optionNumber: Scalars['Int'];
  notes: Scalars['String'];
  areas: Array<Area>;
  unassignedRooms: Array<Room>;
  roomTransitions: Array<RoomTransition>;
  price?: Maybe<Price>;
  financing?: Maybe<Financing>;
  discounts?: Maybe<DiscountsOnJob>;
  priceRequest?: Maybe<PriceRequest>;
  jobContractId?: Maybe<Scalars['Int']>;
  jobDrawingId?: Maybe<Scalars['Int']>;
  drawingSVG?: Maybe<Scalars['String']>;
  nextRevisionJobConfigurationId?: Maybe<Scalars['Int']>;
  promotion?: Maybe<PromotionOnJob>;
};

export type JobConfigurationIdAndOptionNumber = {
  __typename?: 'JobConfigurationIdAndOptionNumber';
  id: Scalars['Int'];
  optionNumber: Scalars['Int'];
};

export type JobConfigurationIdAndQuoteId = {
  __typename?: 'JobConfigurationIdAndQuoteId';
  id: Scalars['Int'];
  quoteId: Scalars['Int'];
};

export type JobConfigurationInput = {
  id: Scalars['Int'];
  jobId: Scalars['Int'];
  optionNumber: Scalars['Int'];
  notes: Scalars['String'];
  areas: Array<AreaInput>;
  unassignedRooms: Array<RoomInput>;
  roomTransitions: Array<RoomTransitionInput>;
  price?: Maybe<PriceInput>;
  financing?: Maybe<FinancingInput>;
  discounts?: Maybe<DiscountsOnJobInput>;
  priceRequest?: Maybe<PriceRequestInput>;
  jobContractId?: Maybe<Scalars['Int']>;
  jobDrawingId?: Maybe<Scalars['Int']>;
  drawingSVG?: Maybe<Scalars['String']>;
  nextRevisionJobConfigurationId?: Maybe<Scalars['Int']>;
  promotion?: Maybe<PromotionOnJobInput>;
};

export type JobContract = {
  __typename?: 'JobContract';
  id: Scalars['Int'];
  salespersonId: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  totalDepositPaid: Scalars['Decimal'];
  signingDate: Scalars['DateTime'];
  isCancelled: Scalars['Boolean'];
  totalPrice: Scalars['Decimal'];
  sAHNumber: Scalars['String'];
  emailed: Scalars['Boolean'];
  s3FileId?: Maybe<Scalars['Int']>;
};

export type JobContractHistory = {
  __typename?: 'JobContractHistory';
  id: Scalars['Int'];
  jobConfigurationIds: Array<Scalars['Int']>;
};

export type JobContractPayment = {
  __typename?: 'JobContractPayment';
  id: Scalars['Int'];
  jobContractId: Scalars['Int'];
  paymentMethodId: Scalars['Int'];
  amount: Scalars['Decimal'];
  isForDeposit: Scalars['Boolean'];
  authorizationNumber?: Maybe<Scalars['String']>;
};

export type JobContractPaymentInput = {
  id: Scalars['Int'];
  jobContractId: Scalars['Int'];
  paymentMethodId: Scalars['Int'];
  amount: Scalars['Decimal'];
  isForDeposit: Scalars['Boolean'];
  authorizationNumber?: Maybe<Scalars['String']>;
};

export type JobIdAndCwcNumber = {
  __typename?: 'JobIdAndCwcNumber';
  id: Scalars['Int'];
  cwcNumber?: Maybe<Scalars['Int']>;
};

export type JobInRecovery = {
  __typename?: 'JobInRecovery';
  id: Scalars['Int'];
  activeJobConfigurationId?: Maybe<Scalars['Int']>;
  dateEnteredRecovery: Scalars['DateTime'];
  isInRecovery: Scalars['Boolean'];
  isHot: Scalars['Boolean'];
  assignedSalesperson?: Maybe<Salesperson>;
  recoveryWorker?: Maybe<Worker>;
  isQuote: Scalars['Boolean'];
  isContract: Scalars['Boolean'];
  appointmentNumber?: Maybe<Scalars['String']>;
  quoteNumber?: Maybe<Scalars['String']>;
  quoteId?: Maybe<Scalars['Int']>;
  contractNumber?: Maybe<Scalars['String']>;
  latestPrice?: Maybe<Scalars['Decimal']>;
  customerId: Scalars['Int'];
  customerFirstName: Scalars['String'];
  customerLastName: Scalars['String'];
  customerCity: Scalars['String'];
  customerEmail: Scalars['String'];
  customerPhone: Scalars['String'];
  customerZip: Scalars['String'];
  steps: Array<RecoveryStep>;
  reasons: Array<RecoveryReason>;
  communications: Array<RecoveryCommunication>;
};

export type JobMetadataHeader = {
  __typename?: 'JobMetadataHeader';
  id: Scalars['Int'];
  appointmentNumber: Scalars['String'];
  customer: Customer;
  assignedSalespeople: Array<Salesperson>;
  configurationIds: Array<ConfigIdsWithLabels>;
};

export type JobMetadataOffering = {
  __typename?: 'JobMetadataOffering';
  id: Scalars['Int'];
  jobServiceId: Scalars['Int'];
  jobService: Scalars['String'];
  laborPricingUnit: Scalars['String'];
  service: JobMetadataService;
  materialOptions: Array<MaterialForServiceOffering>;
};

export type JobMetadataService = {
  __typename?: 'JobMetadataService';
  id: Scalars['Int'];
  serviceType: Scalars['String'];
  displayTypeId?: Maybe<Scalars['Int']>;
};

export type JobMetadataWithConfigIds = {
  __typename?: 'JobMetadataWithConfigIds';
  id: Scalars['Int'];
  partialJob: Job;
  configIds: Array<ConfigIdsWithLabels>;
};

export type JobSearchResult = {
  __typename?: 'JobSearchResult';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  jobConfigurationId: Scalars['Int'];
  sahNumber?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['DateTime']>;
  jobContractId?: Maybe<Scalars['Int']>;
};

export type JobService = {
  __typename?: 'JobService';
  id: Scalars['Int'];
  serviceTypeId: Scalars['Int'];
  description: Scalars['String'];
  priceUnit: Scalars['String'];
};

export type JobServiceOption = {
  __typename?: 'JobServiceOption';
  jobServiceId: Scalars['Int'];
  productTypeId?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  laborPricingUnit: Scalars['String'];
  materialOptions: Array<ServiceMaterialCategoryOption>;
  validForProductTypeId?: Maybe<Array<Scalars['Int']>>;
};

export type KeyValuePairOfInt32AndListOfString = {
  __typename?: 'KeyValuePairOfInt32AndListOfString';
  key: Scalars['Int'];
  value: Array<Scalars['String']>;
};

export type KeyValuePairOfStringAndString__ = {
  __typename?: 'KeyValuePairOfStringAndString__';
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type LabeledNote = {
  __typename?: 'LabeledNote';
  id: Scalars['Int'];
  labels: Array<LabelForRoom>;
  notes: Scalars['String'];
};

export type LabelForRoom = {
  __typename?: 'LabelForRoom';
  id: Scalars['Int'];
  roomId: Scalars['Int'];
  hasCloset: Scalars['Boolean'];
  customName?: Maybe<Scalars['String']>;
  labelNumber: Scalars['Int'];
  label: RoomLabelOption;
};

export type LabelForRoomInput = {
  id: Scalars['Int'];
  roomId: Scalars['Int'];
  hasCloset: Scalars['Boolean'];
  customName?: Maybe<Scalars['String']>;
  labelNumber: Scalars['Int'];
  label: RoomLabelOptionInput;
};

export type LiabilityInsurance = {
  __typename?: 'LiabilityInsurance';
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  activeDate: Scalars['DateTime'];
  expirationDate: Scalars['DateTime'];
  s3FileId?: Maybe<Scalars['Int']>;
};

export type LiabilityInsuranceInput = {
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  activeDate: Scalars['DateTime'];
  expirationDate: Scalars['DateTime'];
  s3FileId?: Maybe<Scalars['Int']>;
};

export type ListFilterInputTypeOfRollCutFilterInput = {
  all?: Maybe<RollCutFilterInput>;
  none?: Maybe<RollCutFilterInput>;
  some?: Maybe<RollCutFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfRollEntryFilterInput = {
  all?: Maybe<RollEntryFilterInput>;
  none?: Maybe<RollEntryFilterInput>;
  some?: Maybe<RollEntryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type Market = {
  __typename?: 'Market';
  id: Scalars['Int'];
  name: Scalars['String'];
  appointmentHours: Scalars['Int'];
  appointmentMinutes: Scalars['Int'];
  mSRPScalar: Scalars['Decimal'];
};

export type MarketTimeSlot = {
  __typename?: 'MarketTimeSlot';
  id: Scalars['Int'];
  startTime: Scalars['TimeSpan'];
  endTime: Scalars['TimeSpan'];
  marketId: Scalars['Int'];
};

export type MaterialForServiceOffering = {
  __typename?: 'MaterialForServiceOffering';
  id: Scalars['Int'];
  serviceProductOfferingId: Scalars['Int'];
  serviceMaterialCategory: Scalars['String'];
  serviceMaterialCategoryId: Scalars['Int'];
  materialPriceUnit: Scalars['String'];
  defaultAmount: Scalars['Decimal'];
  isDefaultMaterial: Scalars['Boolean'];
};

export enum MeasurementUnit {
  FtInch = 'FT_INCH',
  Ft = 'FT',
  Inch = 'INCH',
  Sqft = 'SQFT',
  LinearFoot = 'LINEAR_FOOT'
}

export type MeasurementUnitOperationFilterInput = {
  eq?: Maybe<MeasurementUnit>;
  neq?: Maybe<MeasurementUnit>;
  in?: Maybe<Array<MeasurementUnit>>;
  nin?: Maybe<Array<MeasurementUnit>>;
};

export type MeasurementUnitOption = {
  __typename?: 'MeasurementUnitOption';
  unitId: Scalars['Int'];
  unit: Scalars['String'];
};

export type MeasurementUnitOptionFull = {
  __typename?: 'MeasurementUnitOptionFull';
  id: Scalars['Int'];
  name: Scalars['String'];
  short: Scalars['String'];
  long: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  setRole?: Maybe<User>;
  test: Scalars['Boolean'];
  addNewStyle: ProductStyle;
  addNewProductSpec: Scalars['Int'];
  addNewProductSource: Scalars['Int'];
  addNewColor: Scalars['Int'];
  bulkAddNewCarpetColors: Array<Scalars['Int']>;
  addNewSourcedProduct: Scalars['Int'];
  updateProductCosts: Array<ProductIdentifiersWithCosts>;
  updateHSProductCosts: Array<ProductIdentifiersWithCosts>;
  updateSSProductCosts: Array<ProductIdentifiersWithCosts>;
  updateProductPrices: Array<ProductIdentifierWithPrice>;
  updateServiceProductOffering: UpdateServiceProductOfferingPayload;
  addMultipleOfferingsToNewService: Array<Scalars['Int']>;
  addMultipleOfferingsToExistingService: AddLaborRulesPayload;
  tryRemoveLaborRule: TryRemoveLaborRulePayload;
  addTestSvg: Scalars['Boolean'];
  createJobWithNewCustomer: JobConfiguration;
  addContractToJobWithDeposit: Scalars['Int'];
  emailContract: Scalars['Boolean'];
  upsertContractPdfPresigned: PreSignedS3FileUpload;
  addQuoteForJob: Scalars['Boolean'];
  emailQuote: Scalars['Boolean'];
  addBeforeAndAfterPicturesForRoom: Scalars['Boolean'];
  addPicture: Scalars['Int'];
  addPicturesForRoom: Scalars['Boolean'];
  upsertJobConfiguration: JobConfiguration;
  upsertJobContractPayments: Scalars['Boolean'];
  copyJobConfiguration: Scalars['Int'];
  trimInvalidFieldsFromJobConfiguration: Scalars['Int'];
  updateJobConfigurationNotes: Scalars['Boolean'];
  updateArea: Scalars['Boolean'];
  reassignRoom: Scalars['Boolean'];
  updateChargeables: Scalars['Boolean'];
  updateDiscount: Scalars['Boolean'];
  updateJobFinancingOption: Scalars['Boolean'];
  updateFinancing: Scalars['Boolean'];
  updateProductInCart: Scalars['Boolean'];
  updateAreaProductStyleSelection: Scalars['Boolean'];
  updateAreaProductType: Scalars['Boolean'];
  updateAreaProductColorSelection: Scalars['Boolean'];
  updateUnderlaymentInArea: Scalars['Boolean'];
  saveDrawing: Scalars['Boolean'];
  cancelContract: Scalars['Boolean'];
  deleteJobConfiguration: Scalars['Int'];
  deleteJobContractPayments: Scalars['Boolean'];
  deletePictureForRoom: Scalars['Boolean'];
  deleteBeforeAndAfterPictureForRoom: Scalars['Boolean'];
  addNewSAHAppointment: Scalars['Int'];
  addRescheduledSAHAppointment: Scalars['Int'];
  blockTimeSlotForAllZips: Scalars['Boolean'];
  blockTimeSlotByMarkets: Scalars['Boolean'];
  deleteBlockedTimeSlots: Array<Scalars['Int']>;
  deleteWorkerBlockedTimeSlot: Scalars['Boolean'];
  deleteMarket: Scalars['Boolean'];
  updateMarketAndServicedForZipAndCity: Scalars['Boolean'];
  updateSAHAppointment: SahAppointment;
  updateSahAppointmentNotes: Scalars['Boolean'];
  updateSAHAppointmentDetails: SahAppointment;
  releaseSAHAppointment: SahAppointment;
  acceptSAHAppointment: SahAppointment;
  checkIntoSAHAppointment: SahAppointment;
  checkOutOfSAHAppointment: SahAppointment;
  customerNotHomeSAHAppointment: SahAppointment;
  waitingForCustomerSAHAppointment: SahAppointment;
  cancelSAHAppointment: SahAppointment;
  addWorkerBlockedTimeSlot: Scalars['Int'];
  addWorkerBlockedTimeSlotRange: Scalars['Boolean'];
  workerPunchIn: Scalars['Boolean'];
  updateWorkerBlockedTimeSlot: Scalars['Boolean'];
  updateWorkerIsActive: Scalars['Boolean'];
  upsertWorkerHistory: Scalars['Boolean'];
  upsertBackgroundCheck: Scalars['Boolean'];
  setWorkerProfilePicture: PreSignedS3FileUpload;
  setWorkerDriversLicenseFile: PreSignedS3FileUpload;
  setWorkerLicenseFile: PreSignedS3FileUpload;
  upsertW9?: Maybe<PreSignedS3FileUpload>;
  upsertWorkersComp?: Maybe<PreSignedS3FileUpload>;
  upsertLiabilityInsurance?: Maybe<PreSignedS3FileUpload>;
  deleteBackgroundCheck: Scalars['Boolean'];
  deleteW9: Scalars['Boolean'];
  deleteWorkersComp: Scalars['Boolean'];
  deleteLiabilityInsurance: Scalars['Boolean'];
  addInstallationAppointment: Scalars['Boolean'];
  updateInstallationAppointment: InstallationAppointment;
  markInstallationAppointmentComplete: Scalars['Boolean'];
  checkInToInstallationAppointment: InstallationAppointment;
  checkOutFromInstallationAppointment: InstallationAppointment;
  pickedUpMaterialsForInstallationAppointment: InstallationAppointment;
  mappedToCustomerForInstallationAppointment: InstallationAppointment;
  callCustomerForInstallationAppointment: InstallationAppointment;
  toggleInstallationAppointmentRelease: InstallationAppointment;
  collectCODForInstallationAppointment: InstallationAppointment;
  updateEstimatedArrivalTimeForInstallationAppointment: InstallationAppointment;
  deleteInstallationAppointment: Scalars['Boolean'];
  deleteRoomsForInstallationAppointment: Scalars['Boolean'];
  deleteRoomForInstallationAppointment: Scalars['Boolean'];
  submitCompletionCertificate: Scalars['Boolean'];
  updateAreaNotes: Scalars['Boolean'];
  updateRoomNotes: Scalars['Boolean'];
  updateInstallationAppointmentNotes: Scalars['Boolean'];
  addServiceOrder: Scalars['Int'];
  deleteServiceOrder: Scalars['Boolean'];
  updateServiceOrder: Scalars['Boolean'];
  updateServiceOrderScheduledDate: Scalars['Boolean'];
  addPicturesForServiceOrder: Scalars['Boolean'];
  deletePictureForServiceOrder: Scalars['Boolean'];
  sendMessage: Scalars['Boolean'];
  clearNewMessageStatus: Scalars['Boolean'];
  sendPriceRequest: PriceRequest;
  recordPriceRequest?: Maybe<PriceRequest>;
  claimAllPriceRequestsForJob: Scalars['Boolean'];
  sendPriceOverride: Scalars['Boolean'];
  updateJobInRecovery: Scalars['Boolean'];
  submitChangesToJobInRecovery: Scalars['Boolean'];
  addRecoveryCommunication: Scalars['Boolean'];
  updateRecoveryCommunication: Scalars['Boolean'];
  deleteRecoveryCommunication: Scalars['Boolean'];
  addInternalJobNotes: Scalars['Boolean'];
  updateInternalJobNotes: Scalars['Boolean'];
  deleteInternalJobNotes: Scalars['Boolean'];
  upsertRecoveryCenterOverride: Scalars['Boolean'];
  claimJob: Scalars['Boolean'];
  assignProjectCoordinator: Scalars['Boolean'];
  updateCWC: Scalars['Boolean'];
  makeCall: Scalars['String'];
  submitChangeOrder: Scalars['Boolean'];
  upsertFinancingOption: Scalars['Boolean'];
  addFinancingOverride: Scalars['Boolean'];
  updateCallCenterAction: CallCenterAction;
  addCallCenterAction: Scalars['Boolean'];
  setCallCenterNotInterested: CallCenterJob;
  setCallCenterCanceledByCustomer: CallCenterJob;
  updateCallCenterProspectNotes: CallCenterJob;
  setCallCenterClaim: CallCenterJob;
  setCallCenterCallback: CallCenterJob;
  setCallCenterQualification: CallCenterJob;
  setCallCenterRecoveryClaim: CallCenterJob;
  setCallCenterReminderSent: CallCenterJob;
  upsertCallCenterScript: Scalars['Int'];
  deleteCallCenterScript: Scalars['Boolean'];
  updateServiceMaterialCategoryPricings: Array<FullServiceMaterialCategoryPricing>;
  addServiceMaterialCategoryPricings: Scalars['Boolean'];
  addMaterialToJobService: Scalars['Boolean'];
  removeMaterialFromJobService: Scalars['Boolean'];
  setDefaultMaterialForJobService: Scalars['Boolean'];
  updateCustomerDetails: Scalars['Boolean'];
  mergeCustomers: Customer;
  upsertBasePromotion: Scalars['Int'];
  deleteBasePromotion: Scalars['Boolean'];
  addScheduledPromotion: Scalars['Boolean'];
  cancelScheduledPromotion: Scalars['Boolean'];
  updateScheduledPromotion: Scalars['Boolean'];
  updateWhichMarketsForScheduledPromotion: Scalars['Boolean'];
  updateAppliedPromotion: Scalars['Boolean'];
  addMarket: Scalars['Boolean'];
  updateMarket: Scalars['Boolean'];
  updateMsrpScalarForMarkets: Scalars['Boolean'];
  upsertDiscount: Scalars['Boolean'];
  updateDiscountVisibility: Scalars['Boolean'];
  deleteDiscount: Scalars['Boolean'];
  addContractorInstallationCapability: Scalars['Boolean'];
  setContractorRatingParameters: Scalars['Boolean'];
  updateContractorZips: Scalars['Boolean'];
  upsertContractorProfile: Scalars['Int'];
  upsertContractorHelper: Scalars['Boolean'];
  upsertContractorInstallationCapability: Scalars['Boolean'];
  deleteContractorInstallationCapability: Scalars['Boolean'];
  reportS3FileUploadStatus: Scalars['Boolean'];
  deleteS3File: Scalars['Boolean'];
  updateSalespersonZips: Scalars['Boolean'];
  upsertSalespersonProfile: Scalars['Int'];
  toggleSeamDirection: Scalars['Boolean'];
  eraseAllCustomerData: Scalars['Boolean'];
};


export type MutationSetRoleArgs = {
  email: Scalars['String'];
  role: Scalars['String'];
};


export type MutationAddNewStyleArgs = {
  productTypeId: Scalars['Int'];
  styleName: Scalars['String'];
  singleCostPerSqft: Scalars['Decimal'];
  bulkCostPerSqft: Scalars['Decimal'];
  specName: Scalars['String'];
  sqft: Scalars['Decimal'];
  structuredProductDetails: Array<StructuredProductDetailInput>;
  unstructuredProductDetails: Array<UnstructuredProductDetailInput>;
  installationConfigurations: Array<ProductCanBeInstalledInput>;
};


export type MutationAddNewProductSpecArgs = {
  colorId: Scalars['Int'];
  specName: Scalars['String'];
  specDetails: ProductSpecificationInput;
  vendor: VendorSourcedProductInput;
};


export type MutationAddNewProductSourceArgs = {
  productSpecId: Scalars['Int'];
  vendor: VendorSourcedProductInput;
};


export type MutationAddNewColorArgs = {
  styleId: Scalars['Int'];
  colorName: Scalars['String'];
  specName: Scalars['String'];
  specDetails: ProductSpecificationInput;
  vendor: VendorSourcedProductInput;
};


export type MutationBulkAddNewCarpetColorsArgs = {
  styleId: Scalars['Int'];
  vendorId: Scalars['Int'];
  vendorStyle: Scalars['String'];
  orderSize: Scalars['Decimal'];
  singleCostPerSqft?: Maybe<Scalars['Decimal']>;
  bulkCostPerSqft?: Maybe<Scalars['Decimal']>;
  pairs: Array<ColorNamePairInput>;
};


export type MutationAddNewSourcedProductArgs = {
  vendor: VendorSourcedProductInput;
  productSpecId: Scalars['Int'];
};


export type MutationUpdateProductCostsArgs = {
  products: Array<ProductIdentifiersWithCostsInput>;
  isSSMode: Scalars['Boolean'];
};


export type MutationUpdateHsProductCostsArgs = {
  products: Array<ProductIdentifiersWithCostsInput>;
};


export type MutationUpdateSsProductCostsArgs = {
  products: Array<ProductIdentifiersWithCostsInput>;
};


export type MutationUpdateProductPricesArgs = {
  products: Array<ProductIdentifierWithPriceInput>;
  interpretCarpetShippingInSqyd?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateServiceProductOfferingArgs = {
  input: UpdateLaborRuleInput;
};


export type MutationAddMultipleOfferingsToNewServiceArgs = {
  input: AddLaborRulesInput;
};


export type MutationAddMultipleOfferingsToExistingServiceArgs = {
  input: AddMultipleOfferingsToExistingServiceInput;
};


export type MutationTryRemoveLaborRuleArgs = {
  id: Scalars['Int'];
};


export type MutationAddTestSvgArgs = {
  svg: Scalars['String'];
};


export type MutationCreateJobWithNewCustomerArgs = {
  newCustomer: CustomerInput;
};


export type MutationAddContractToJobWithDepositArgs = {
  jobContractDetails: AddContractToJobWithDepositMutationInput;
};


export type MutationEmailContractArgs = {
  contractId: Scalars['Int'];
  emailAddresses: Array<Scalars['String']>;
  updatePrimaryEmail: Scalars['Boolean'];
};


export type MutationUpsertContractPdfPresignedArgs = {
  contractId: Scalars['Int'];
};


export type MutationAddQuoteForJobArgs = {
  followUpDate: Scalars['DateTime'];
  jobConfigurationId: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  emails: Array<Scalars['String']>;
};


export type MutationEmailQuoteArgs = {
  quoteId: Scalars['Int'];
  emailAddresses: Array<Scalars['String']>;
  updatePrimaryEmail: Scalars['Boolean'];
};


export type MutationAddBeforeAndAfterPicturesForRoomArgs = {
  roomId: Scalars['Int'];
  pictures: Array<PictureInput>;
  isBefore: Scalars['Boolean'];
};


export type MutationAddPictureArgs = {
  picture: Scalars['String'];
  description: Scalars['String'];
};


export type MutationAddPicturesForRoomArgs = {
  roomId: Scalars['Int'];
  pictures: Array<PictureInput>;
};


export type MutationUpsertJobConfigurationArgs = {
  jobConfiguration: JobConfigurationInput;
  saveAsNew: Scalars['Boolean'];
  copyStyles?: Maybe<Scalars['Boolean']>;
};


export type MutationUpsertJobContractPaymentsArgs = {
  payments: Array<JobContractPaymentInput>;
};


export type MutationCopyJobConfigurationArgs = {
  jobConfigurationId: Scalars['Int'];
  optionNumber: Scalars['Int'];
  updatedProducts?: Maybe<Array<UpdatedProductForAreaInput>>;
  selectedFinancingOption?: Maybe<Scalars['Int']>;
};


export type MutationTrimInvalidFieldsFromJobConfigurationArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type MutationUpdateJobConfigurationNotesArgs = {
  jobConfigurationId: Scalars['Int'];
  notes: Scalars['String'];
  replace: Scalars['Boolean'];
};


export type MutationUpdateAreaArgs = {
  area: AreaInput;
};


export type MutationReassignRoomArgs = {
  roomId: Scalars['Int'];
  areaId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateChargeablesArgs = {
  jobConfigurationId: Scalars['Int'];
  upsertedServices: Array<ServiceForRoomInput>;
  deletedServiceIds: Array<Scalars['Int']>;
  upsertedCustomServices: Array<CustomServiceInput>;
  deletedCustomServiceIds: Array<Scalars['Int']>;
};


export type MutationUpdateDiscountArgs = {
  jobConfigurationId: Scalars['Int'];
  discountId: Scalars['Int'];
  overrideAmount?: Maybe<Scalars['Decimal']>;
  isRemoval: Scalars['Boolean'];
};


export type MutationUpdateJobFinancingOptionArgs = {
  jobConfigurationId: Scalars['Int'];
  financingOptionId: Scalars['Int'];
};


export type MutationUpdateFinancingArgs = {
  jobConfigurationId: Scalars['Int'];
  financingOptionId?: Maybe<Scalars['Int']>;
  downPaymentAmount?: Maybe<Scalars['Decimal']>;
};


export type MutationUpdateProductInCartArgs = {
  areaId: Scalars['Int'];
  inCartStatus: Scalars['Boolean'];
};


export type MutationUpdateAreaProductStyleSelectionArgs = {
  areaId: Scalars['Int'];
  styleId?: Maybe<Scalars['Int']>;
  preventUpdatingProductInCart?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateAreaProductTypeArgs = {
  areaId: Scalars['Int'];
  productTypeId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateAreaProductColorSelectionArgs = {
  areaId: Scalars['Int'];
  colorId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateUnderlaymentInAreaArgs = {
  areaId: Scalars['Int'];
  materialCategoryId?: Maybe<Scalars['Int']>;
};


export type MutationSaveDrawingArgs = {
  jobConfigurationId: Scalars['Int'];
  drawingJSON: Scalars['String'];
};


export type MutationCancelContractArgs = {
  contractId: Scalars['Int'];
};


export type MutationDeleteJobConfigurationArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type MutationDeleteJobContractPaymentsArgs = {
  paymentIds: Array<Scalars['Int']>;
};


export type MutationDeletePictureForRoomArgs = {
  pictureForRoomId: Scalars['Int'];
};


export type MutationDeleteBeforeAndAfterPictureForRoomArgs = {
  beforeAndAfterPictureForRoomId: Scalars['Int'];
};


export type MutationAddNewSahAppointmentArgs = {
  rawData: RawSahAppointmentDataInput;
};


export type MutationAddRescheduledSahAppointmentArgs = {
  jobId: Scalars['Int'];
  date: Scalars['DateTime'];
  marketTimeSlotId: Scalars['Int'];
  salespersonId?: Maybe<Scalars['Int']>;
  isWriteUp?: Maybe<Scalars['Boolean']>;
};


export type MutationBlockTimeSlotForAllZipsArgs = {
  dates: Array<Scalars['DateTime']>;
  ts: AtomicTimeSlotInput;
};


export type MutationBlockTimeSlotByMarketsArgs = {
  dates: Array<Scalars['DateTime']>;
  marketIds: Array<Scalars['Int']>;
  ts: AtomicTimeSlotInput;
};


export type MutationDeleteBlockedTimeSlotsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteWorkerBlockedTimeSlotArgs = {
  workerBlockedTimeSlotId: Scalars['Int'];
};


export type MutationDeleteMarketArgs = {
  marketId: Scalars['Int'];
};


export type MutationUpdateMarketAndServicedForZipAndCityArgs = {
  ids: Array<Scalars['Int']>;
  marketId?: Maybe<Scalars['Int']>;
  serviced: Scalars['Boolean'];
};


export type MutationUpdateSahAppointmentArgs = {
  sahAppointmentId: Scalars['Int'];
  salespersonId?: Maybe<Scalars['Int']>;
  marketTimeSlotIds?: Maybe<Array<Scalars['Int']>>;
  date?: Maybe<Scalars['DateTime']>;
};


export type MutationUpdateSahAppointmentNotesArgs = {
  sahAppointmentId: Scalars['Int'];
  notes: Scalars['String'];
};


export type MutationUpdateSahAppointmentDetailsArgs = {
  sahAppointmentId: Scalars['Int'];
  customerComments: Scalars['String'];
};


export type MutationReleaseSahAppointmentArgs = {
  sahAppointmentId: Scalars['Int'];
};


export type MutationAcceptSahAppointmentArgs = {
  sahAppointmentId: Scalars['Int'];
};


export type MutationCheckIntoSahAppointmentArgs = {
  sahAppointmentId: Scalars['Int'];
};


export type MutationCheckOutOfSahAppointmentArgs = {
  sahAppointmentId: Scalars['Int'];
};


export type MutationCustomerNotHomeSahAppointmentArgs = {
  sahAppointmentId: Scalars['Int'];
};


export type MutationWaitingForCustomerSahAppointmentArgs = {
  sahAppointmentId: Scalars['Int'];
};


export type MutationCancelSahAppointmentArgs = {
  sahAppointmentId: Scalars['Int'];
};


export type MutationAddWorkerBlockedTimeSlotArgs = {
  workerId: Scalars['Int'];
  date: Scalars['DateTime'];
  timeSlot: AtomicTimeSlotInput;
  isBlockedByWorker?: Maybe<Scalars['Boolean']>;
};


export type MutationAddWorkerBlockedTimeSlotRangeArgs = {
  workerId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export type MutationWorkerPunchInArgs = {
  workerId: Scalars['Int'];
};


export type MutationUpdateWorkerBlockedTimeSlotArgs = {
  workerBlockedTimeSlotId: Scalars['Int'];
  date: Scalars['DateTime'];
  timeSlot: AtomicTimeSlotInput;
};


export type MutationUpdateWorkerIsActiveArgs = {
  workerId: Scalars['Int'];
  isActive: Scalars['Boolean'];
};


export type MutationUpsertWorkerHistoryArgs = {
  history: WorkerHistoryInput;
};


export type MutationUpsertBackgroundCheckArgs = {
  backgroundCheck: BackgroundCheckInput;
};


export type MutationSetWorkerProfilePictureArgs = {
  workerId: Scalars['Int'];
  fileExtension: Scalars['String'];
};


export type MutationSetWorkerDriversLicenseFileArgs = {
  workerId: Scalars['Int'];
  fileExtension: Scalars['String'];
};


export type MutationSetWorkerLicenseFileArgs = {
  workerId: Scalars['Int'];
  fileExtension: Scalars['String'];
};


export type MutationUpsertW9Args = {
  w9: W9Input;
  hasFile: Scalars['Boolean'];
};


export type MutationUpsertWorkersCompArgs = {
  workersComp: WorkersCompInput;
  hasFile: Scalars['Boolean'];
};


export type MutationUpsertLiabilityInsuranceArgs = {
  liabilityInsurance: LiabilityInsuranceInput;
  hasFile: Scalars['Boolean'];
};


export type MutationDeleteBackgroundCheckArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteW9Args = {
  id: Scalars['Int'];
};


export type MutationDeleteWorkersCompArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteLiabilityInsuranceArgs = {
  id: Scalars['Int'];
};


export type MutationAddInstallationAppointmentArgs = {
  jobContractId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  notes: Scalars['String'];
  cod: Scalars['Decimal'];
  roomIds: Array<Scalars['Int']>;
};


export type MutationUpdateInstallationAppointmentArgs = {
  appointmentId: Scalars['Int'];
  newContractorId?: Maybe<Scalars['Int']>;
  newStartDate?: Maybe<Scalars['DateTime']>;
  newNotes?: Maybe<Scalars['String']>;
  newCod?: Maybe<Scalars['Decimal']>;
  newCollectedCod?: Maybe<Scalars['Decimal']>;
  appointmentLength?: Maybe<Scalars['Int']>;
};


export type MutationMarkInstallationAppointmentCompleteArgs = {
  installationAppointmentId: Scalars['Int'];
};


export type MutationCheckInToInstallationAppointmentArgs = {
  appointmentId: Scalars['Int'];
};


export type MutationCheckOutFromInstallationAppointmentArgs = {
  appointmentId: Scalars['Int'];
};


export type MutationPickedUpMaterialsForInstallationAppointmentArgs = {
  appointmentId: Scalars['Int'];
};


export type MutationMappedToCustomerForInstallationAppointmentArgs = {
  appointmentId: Scalars['Int'];
};


export type MutationCallCustomerForInstallationAppointmentArgs = {
  appointmentId: Scalars['Int'];
};


export type MutationToggleInstallationAppointmentReleaseArgs = {
  appointmentId: Scalars['Int'];
  releasedToContractor: Scalars['Boolean'];
};


export type MutationCollectCodForInstallationAppointmentArgs = {
  appointmentId: Scalars['Int'];
};


export type MutationUpdateEstimatedArrivalTimeForInstallationAppointmentArgs = {
  appointmentId: Scalars['Int'];
  eta?: Maybe<Scalars['TimeSpan']>;
  didCustomerAnswer?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteInstallationAppointmentArgs = {
  appointmentId: Scalars['Int'];
};


export type MutationDeleteRoomsForInstallationAppointmentArgs = {
  appointmentId: Scalars['Int'];
  roomIds: Array<Scalars['Int']>;
};


export type MutationDeleteRoomForInstallationAppointmentArgs = {
  appointmentId: Scalars['Int'];
  roomId: Scalars['Int'];
};


export type MutationSubmitCompletionCertificateArgs = {
  installationAppointmentId: Scalars['Int'];
  question1Rating: Scalars['Int'];
  overallExperience: Scalars['Int'];
  comments?: Maybe<Scalars['String']>;
  signatureBase64: Scalars['String'];
};


export type MutationUpdateAreaNotesArgs = {
  areaId: Scalars['Int'];
  newNotes: Scalars['String'];
  replace?: Scalars['Boolean'];
};


export type MutationUpdateRoomNotesArgs = {
  roomId: Scalars['Int'];
  newNotes: Scalars['String'];
  replace: Scalars['Boolean'];
};


export type MutationUpdateInstallationAppointmentNotesArgs = {
  installationAppointmentId: Scalars['Int'];
  newNotes: Scalars['String'];
  replace: Scalars['Boolean'];
};


export type MutationAddServiceOrderArgs = {
  newServiceOrder: ServiceOrderInput;
};


export type MutationDeleteServiceOrderArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateServiceOrderArgs = {
  serviceOrder: ServiceOrderInput;
  roomIds: Array<Scalars['Int']>;
};


export type MutationUpdateServiceOrderScheduledDateArgs = {
  serviceOrderId: Scalars['Int'];
  scheduledDate: Scalars['DateTime'];
  contractorId: Scalars['Int'];
};


export type MutationAddPicturesForServiceOrderArgs = {
  serviceOrderId: Scalars['Int'];
  pictures: Array<PictureInput>;
};


export type MutationDeletePictureForServiceOrderArgs = {
  id: Scalars['Int'];
};


export type MutationSendMessageArgs = {
  newMessage: ChatMessageInput;
};


export type MutationClearNewMessageStatusArgs = {
  chatId: Scalars['Int'];
  partyId: Scalars['Int'];
};


export type MutationSendPriceRequestArgs = {
  chatId: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  amount: Scalars['Decimal'];
};


export type MutationRecordPriceRequestArgs = {
  chatId: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  amount: Scalars['Decimal'];
  salespersonId: Scalars['Int'];
};


export type MutationClaimAllPriceRequestsForJobArgs = {
  jobId: Scalars['Int'];
};


export type MutationSendPriceOverrideArgs = {
  jobConfigurationId: Scalars['Int'];
  amount: Scalars['Decimal'];
  commissionRate: Scalars['Decimal'];
};


export type MutationUpdateJobInRecoveryArgs = {
  jobId: Scalars['Int'];
  claimedWorkerId?: Maybe<Scalars['Int']>;
  isInRecovery?: Maybe<Scalars['Boolean']>;
  isHot?: Maybe<Scalars['Boolean']>;
};


export type MutationSubmitChangesToJobInRecoveryArgs = {
  jobId: Scalars['Int'];
  recoveryStepOption: Scalars['Int'];
  stepDate: Scalars['DateTime'];
  recoveryReasonOptionId?: Maybe<Scalars['Int']>;
  otherRecoveryReason?: Maybe<Scalars['String']>;
  updateLatestStep?: Maybe<Scalars['Boolean']>;
  removeFromRecovery?: Maybe<Scalars['Boolean']>;
};


export type MutationAddRecoveryCommunicationArgs = {
  jobId: Scalars['Int'];
  recoveryCommunicationOptionId: Scalars['Int'];
  dialoutId?: Maybe<Scalars['String']>;
};


export type MutationUpdateRecoveryCommunicationArgs = {
  recoveryCommunicationOptionId: Scalars['Int'];
  notes: Scalars['String'];
};


export type MutationDeleteRecoveryCommunicationArgs = {
  recoveryCommunicationOptionId: Scalars['Int'];
};


export type MutationAddInternalJobNotesArgs = {
  jobId: Scalars['Int'];
};


export type MutationUpdateInternalJobNotesArgs = {
  internalJobNotesId: Scalars['Int'];
  notes: Scalars['String'];
};


export type MutationDeleteInternalJobNotesArgs = {
  internalJobNotesId: Scalars['Int'];
};


export type MutationUpsertRecoveryCenterOverrideArgs = {
  jobConfigurationId: Scalars['Int'];
  price: Scalars['Decimal'];
  commissionRate: Scalars['Decimal'];
};


export type MutationClaimJobArgs = {
  jobId: Scalars['Int'];
};


export type MutationAssignProjectCoordinatorArgs = {
  jobId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type MutationUpdateCwcArgs = {
  jobId: Scalars['Int'];
  numWeeks?: Maybe<Scalars['Int']>;
};


export type MutationMakeCallArgs = {
  phoneNumber: Scalars['String'];
  alternativeUserEmail?: Maybe<Scalars['String']>;
};


export type MutationSubmitChangeOrderArgs = {
  originalJobConfigurationId: Scalars['Int'];
  scaledUpdatedJobConfiguration: JobConfigurationInput;
};


export type MutationUpsertFinancingOptionArgs = {
  upsertedOption: FinancingOptionInput;
};


export type MutationAddFinancingOverrideArgs = {
  idOfOptionToReplace?: Maybe<Scalars['Int']>;
  newOverride: FinancingOverrideInput;
};


export type MutationUpdateCallCenterActionArgs = {
  callCenterActionId: Scalars['Int'];
  notes: Scalars['String'];
};


export type MutationAddCallCenterActionArgs = {
  jobId: Scalars['Int'];
  callCenterOptionId: Scalars['Int'];
  notes: Scalars['String'];
};


export type MutationSetCallCenterNotInterestedArgs = {
  jobId: Scalars['Int'];
  callbackDate: Scalars['DateTime'];
  notes: Scalars['String'];
};


export type MutationSetCallCenterCanceledByCustomerArgs = {
  appointmentId: Scalars['Int'];
  callbackDate: Scalars['DateTime'];
  notes: Scalars['String'];
};


export type MutationUpdateCallCenterProspectNotesArgs = {
  jobId: Scalars['Int'];
  prospectNotes: Scalars['String'];
};


export type MutationSetCallCenterClaimArgs = {
  jobId: Scalars['Int'];
  isClaimed: Scalars['Boolean'];
};


export type MutationSetCallCenterCallbackArgs = {
  jobId: Scalars['Int'];
  callBackTime?: Maybe<Scalars['DateTime']>;
};


export type MutationSetCallCenterQualificationArgs = {
  jobId: Scalars['Int'];
  isQualified: Scalars['Boolean'];
  remindCustomerDate: Scalars['DateTime'];
};


export type MutationSetCallCenterRecoveryClaimArgs = {
  jobId: Scalars['Int'];
  claimedRecoveryWorkerId?: Maybe<Scalars['Int']>;
};


export type MutationSetCallCenterReminderSentArgs = {
  sahAppointmentId: Scalars['Int'];
};


export type MutationUpsertCallCenterScriptArgs = {
  script: CallCenterScriptInput;
};


export type MutationDeleteCallCenterScriptArgs = {
  scriptId: Scalars['Int'];
};


export type MutationUpdateServiceMaterialCategoryPricingsArgs = {
  serviceMaterialCategoryProductPricingIds: Array<Scalars['Int']>;
  updatedCost?: Maybe<Scalars['Decimal']>;
  updatedPrice?: Maybe<Scalars['Decimal']>;
};


export type MutationAddServiceMaterialCategoryPricingsArgs = {
  materialCategoryName: Scalars['String'];
  pricingUnit: Scalars['String'];
  packageSize?: Maybe<Scalars['Decimal']>;
  packageSizeIncrement?: Maybe<Scalars['Decimal']>;
  newPricings: Array<AddServiceMaterialCategoryPricingInput>;
};


export type MutationAddMaterialToJobServiceArgs = {
  materialCategoryId: Scalars['Int'];
  jobServiceId: Scalars['Int'];
  productTypeId: Scalars['Int'];
};


export type MutationRemoveMaterialFromJobServiceArgs = {
  materialForServiceOfferingId: Scalars['Int'];
};


export type MutationSetDefaultMaterialForJobServiceArgs = {
  materialForServiceOfferingId: Scalars['Int'];
  isDefault: Scalars['Boolean'];
};


export type MutationUpdateCustomerDetailsArgs = {
  updatedCustomer: CustomerInput;
};


export type MutationMergeCustomersArgs = {
  customerToMergeId: Scalars['Int'];
  customerToMergeInto: CustomerInput;
};


export type MutationUpsertBasePromotionArgs = {
  promotion: BasePromotionInput;
};


export type MutationDeleteBasePromotionArgs = {
  basePromotionId: Scalars['Int'];
};


export type MutationAddScheduledPromotionArgs = {
  basePromotionId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  marketIds: Array<Scalars['Int']>;
};


export type MutationCancelScheduledPromotionArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateScheduledPromotionArgs = {
  scheduledPromotionId: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};


export type MutationUpdateWhichMarketsForScheduledPromotionArgs = {
  scheduledPromotionId: Scalars['Int'];
  addedMarketIds: Array<Scalars['Int']>;
  removedMarketIds: Array<Scalars['Int']>;
};


export type MutationUpdateAppliedPromotionArgs = {
  jobConfigurationId: Scalars['Int'];
  basePromotionId?: Maybe<Scalars['Int']>;
};


export type MutationAddMarketArgs = {
  name: Scalars['String'];
  appointmentHours: Scalars['Int'];
  appointmentMinutes: Scalars['Int'];
  msrpScalar?: Scalars['Decimal'];
};


export type MutationUpdateMarketArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  appointmentHours?: Maybe<Scalars['Int']>;
  appointmentMinutes?: Maybe<Scalars['Int']>;
  msrpScalar?: Maybe<Scalars['Decimal']>;
};


export type MutationUpdateMsrpScalarForMarketsArgs = {
  marketIds: Array<Scalars['Int']>;
  newScalar: Scalars['Decimal'];
};


export type MutationUpsertDiscountArgs = {
  discount: DiscountInput;
};


export type MutationUpdateDiscountVisibilityArgs = {
  discountId: Scalars['Int'];
  isHidden: Scalars['Boolean'];
};


export type MutationDeleteDiscountArgs = {
  discountId: Scalars['Int'];
};


export type MutationAddContractorInstallationCapabilityArgs = {
  capability: ContractorInstallationCapabilityInput;
};


export type MutationSetContractorRatingParametersArgs = {
  parameters: ContractorRatingParametersInput;
};


export type MutationUpdateContractorZipsArgs = {
  contractorId: Scalars['Int'];
  addedZips: Array<Scalars['String']>;
  removedZips: Array<Scalars['String']>;
};


export type MutationUpsertContractorProfileArgs = {
  contractor: ContractorProfileInput;
};


export type MutationUpsertContractorHelperArgs = {
  helper: ContractorHelperInput;
};


export type MutationUpsertContractorInstallationCapabilityArgs = {
  capability: ContractorInstallationCapabilityInput;
};


export type MutationDeleteContractorInstallationCapabilityArgs = {
  contractorId: Scalars['Int'];
  productTypeId: Scalars['Int'];
};


export type MutationReportS3FileUploadStatusArgs = {
  fileId: Scalars['Int'];
  succeeded: Scalars['Boolean'];
};


export type MutationDeleteS3FileArgs = {
  fileId: Scalars['Int'];
};


export type MutationUpdateSalespersonZipsArgs = {
  salespersonId: Scalars['Int'];
  addedZips: Array<Scalars['String']>;
  removedZips: Array<Scalars['String']>;
};


export type MutationUpsertSalespersonProfileArgs = {
  salesperson: SalespersonProfileInput;
};


export type MutationToggleSeamDirectionArgs = {
  roomId: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
};


export type MutationEraseAllCustomerDataArgs = {
  customerIds: Array<Scalars['Int']>;
};

export enum OrderType {
  Pallet = 'PALLET',
  Roll = 'ROLL'
}

export type OrderTypeOperationFilterInput = {
  eq?: Maybe<OrderType>;
  neq?: Maybe<OrderType>;
  in?: Maybe<Array<OrderType>>;
  nin?: Maybe<Array<OrderType>>;
};

export type OverrideDiscountAddedAction = {
  __typename?: 'OverrideDiscountAddedAction';
  id: Scalars['Int'];
  updatedPrice: Price;
  overrideDiscount: AppliedDiscount;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PerformanceSummaryMetadata = {
  __typename?: 'PerformanceSummaryMetadata';
  id: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type Permissions = {
  __typename?: 'Permissions';
  id: Scalars['Int'];
  callCenterAdmin: Scalars['Boolean'];
  callCenterWorker: Scalars['Boolean'];
  projectManager: Scalars['Boolean'];
  projectCoordinator: Scalars['Boolean'];
  viewPricingCenter: Scalars['Boolean'];
  editPricingCenter: Scalars['Boolean'];
  setFinalFinalPrice: Scalars['Boolean'];
};

export type PhoneNumberStatus = {
  __typename?: 'PhoneNumberStatus';
  id: Scalars['String'];
  customerId?: Maybe<Scalars['Int']>;
  activeJobId?: Maybe<Scalars['Int']>;
};

export type Picture = {
  __typename?: 'Picture';
  id: Scalars['Int'];
  uploadDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type PictureForRoom = {
  __typename?: 'PictureForRoom';
  id: Scalars['Int'];
  roomId: Scalars['Int'];
  picture?: Maybe<Picture>;
  isBefore?: Maybe<Scalars['Boolean']>;
};

export type PictureForRoomInput = {
  id: Scalars['Int'];
  roomId: Scalars['Int'];
  picture?: Maybe<PictureInput>;
  isBefore?: Maybe<Scalars['Boolean']>;
};

export type PictureForServiceOrder = {
  __typename?: 'PictureForServiceOrder';
  id: Scalars['Int'];
  serviceOrderId: Scalars['Int'];
  picture?: Maybe<Picture>;
};

export type PictureInput = {
  id: Scalars['Int'];
  uploadDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type PreContractJobConfigIds = {
  __typename?: 'PreContractJobConfigIds';
  id: Scalars['Int'];
  configIds: Array<Scalars['Int']>;
};

export type PreOverrideAndOverallPrice = {
  __typename?: 'PreOverrideAndOverallPrice';
  id: Scalars['Int'];
  preOverridePrice: Scalars['Decimal'];
  overallPrice: Price;
};

export type PreSignedS3FileUpload = {
  __typename?: 'PreSignedS3FileUpload';
  id: Scalars['Int'];
  uploadUrl: Scalars['String'];
};

export type Price = {
  __typename?: 'Price';
  mSRP: Scalars['Decimal'];
  savings: Scalars['Decimal'];
  total: Scalars['Decimal'];
};

export type PriceAndPromoSavings = {
  __typename?: 'PriceAndPromoSavings';
  price: Price;
  promoSavings: Scalars['Decimal'];
};

export type PriceInput = {
  mSRP: Scalars['Decimal'];
  savings: Scalars['Decimal'];
  total: Scalars['Decimal'];
};

export type PriceOverride = {
  __typename?: 'PriceOverride';
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  commandCenterWorkerId: Scalars['Int'];
  amount: Scalars['Decimal'];
  commissionRate: Scalars['Decimal'];
  authorizationCode: Scalars['String'];
  isCurrent: Scalars['Boolean'];
  timeSent: Scalars['DateTime'];
};

export type PriceRequest = {
  __typename?: 'PriceRequest';
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  amount: Scalars['Decimal'];
  salespersonId: Scalars['Int'];
  commandCenterWorkerId?: Maybe<Scalars['Int']>;
  timeRequested?: Maybe<Scalars['DateTime']>;
  timeResponded?: Maybe<Scalars['DateTime']>;
  granted: Scalars['Boolean'];
};

export type PriceRequestInput = {
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  amount: Scalars['Decimal'];
  salespersonId: Scalars['Int'];
  commandCenterWorkerId?: Maybe<Scalars['Int']>;
  timeRequested?: Maybe<Scalars['DateTime']>;
  timeResponded?: Maybe<Scalars['DateTime']>;
  granted: Scalars['Boolean'];
};

export type PriceVerificationFields = {
  __typename?: 'PriceVerificationFields';
  id: Scalars['Int'];
  styles: Array<StyleForJobPrice>;
  services: Array<ServiceForRoomPrice>;
};

export type PricingCenterTabs = {
  __typename?: 'PricingCenterTabs';
  id: Scalars['Int'];
  tabData: Array<JobConfigurationIdAndOptionNumber>;
  contractConfigurationId?: Maybe<Scalars['Int']>;
  quotedConfigurationAndQuoteIds: Array<JobConfigurationIdAndQuoteId>;
};

export type PriorityOption = {
  __typename?: 'PriorityOption';
  id: Scalars['Int'];
  text: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  productTypeId: Scalars['Int'];
  productType: Scalars['String'];
  productStyleId: Scalars['Int'];
  productStyle: Scalars['String'];
  productColorId: Scalars['Int'];
  productColor: Scalars['String'];
};

export type ProductAndValuesInput = {
  productTypeId: Scalars['Int'];
  price: Scalars['Decimal'];
  cost: Scalars['Decimal'];
  minimum?: Maybe<Scalars['Decimal']>;
};

export type ProductCanBeInstalled = {
  __typename?: 'ProductCanBeInstalled';
  jobServiceId: Scalars['Int'];
  substrateId: Scalars['Int'];
};

export type ProductCanBeInstalledInput = {
  jobServiceId: Scalars['Int'];
  substrateId: Scalars['Int'];
};

export type ProductColorOption = {
  __typename?: 'ProductColorOption';
  id: Scalars['Int'];
  color: Scalars['String'];
};

export type ProductIdentifiersWithCosts = {
  __typename?: 'ProductIdentifiersWithCosts';
  styleId: Scalars['Int'];
  sourceId?: Maybe<Scalars['Int']>;
  singleCost?: Maybe<Scalars['Decimal']>;
  bulkCost?: Maybe<Scalars['Decimal']>;
  oneTimeCost?: Maybe<Scalars['Decimal']>;
};

export type ProductIdentifiersWithCostsInput = {
  styleId: Scalars['Int'];
  sourceId?: Maybe<Scalars['Int']>;
  singleCost?: Maybe<Scalars['Decimal']>;
  bulkCost?: Maybe<Scalars['Decimal']>;
  oneTimeCost?: Maybe<Scalars['Decimal']>;
};

export type ProductIdentifierWithPrice = {
  __typename?: 'ProductIdentifierWithPrice';
  styleId: Scalars['Int'];
  updatedFactor?: Maybe<Scalars['Decimal']>;
  updatedShipping?: Maybe<Scalars['Decimal']>;
  updatedPromo?: Maybe<StylePromo>;
};

export type ProductIdentifierWithPriceInput = {
  styleId: Scalars['Int'];
  updatedFactor?: Maybe<Scalars['Decimal']>;
  updatedShipping?: Maybe<Scalars['Decimal']>;
  updatedPromo?: Maybe<StylePromoInput>;
};

export type ProductInput = {
  productTypeId: Scalars['Int'];
  productType: Scalars['String'];
  productStyleId: Scalars['Int'];
  productStyle: Scalars['String'];
  productColorId: Scalars['Int'];
  productColor: Scalars['String'];
};

export type ProductSpecification = {
  __typename?: 'ProductSpecification';
  id: Scalars['Int'];
  specName: Scalars['String'];
  sqft: Scalars['Decimal'];
  structuredProductDetails: Array<StructuredProductDetail>;
  unstructuredProductDetails: Array<UnstructuredProductDetail>;
  installationConfigurations: Array<ProductCanBeInstalled>;
};

export type ProductSpecificationInput = {
  sqft: Scalars['Decimal'];
  structuredProductDetails: Array<StructuredProductDetailInput>;
  unstructuredProductDetails: Array<UnstructuredProductDetailInput>;
  installationConfigurations: Array<ProductCanBeInstalledInput>;
};

export type ProductSpecName = {
  __typename?: 'ProductSpecName';
  id: Scalars['Int'];
  name: Scalars['String'];
  color: Scalars['String'];
  style: Scalars['String'];
  type: Scalars['String'];
};

export type ProductStyle = {
  __typename?: 'ProductStyle';
  id: Scalars['Int'];
  productTypeId: Scalars['Int'];
  style: Scalars['String'];
  singleCostPerSqft: Scalars['Decimal'];
  bulkCostPerSqft: Scalars['Decimal'];
  pricingFactor: Scalars['Decimal'];
  shippingPrice: Scalars['Decimal'];
  spec: ProductSpecification;
  promo?: Maybe<StylePromo>;
};

export type ProductStyleOption = {
  __typename?: 'ProductStyleOption';
  id: Scalars['Int'];
  style: Scalars['String'];
};

export type ProductStyles = {
  __typename?: 'ProductStyles';
  id: Scalars['Int'];
  productTypeId: Scalars['Int'];
  styleName: Scalars['String'];
  productSpecificationId: Scalars['Int'];
  singleCostPerSqft: Scalars['Decimal'];
  bulkCostPerSqft: Scalars['Decimal'];
  pricingFactor: Scalars['Decimal'];
  shippingPrice: Scalars['Decimal'];
};

export type ProductStyleValidForArea = {
  __typename?: 'ProductStyleValidForArea';
  id: Scalars['Int'];
  areaId: Scalars['Int'];
  style: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type ProductStyleValidForConfiguration = {
  __typename?: 'ProductStyleValidForConfiguration';
  id: Scalars['Int'];
  substrateIdsInArea: Array<Scalars['Int']>;
  style: Scalars['String'];
  valid: Scalars['Boolean'];
  defaultInstallJobServiceId?: Maybe<Scalars['Int']>;
  materialForDefaultInstallServiceId?: Maybe<Scalars['Int']>;
};

export enum ProductTypeOld {
  Lvp = 'LVP',
  LvpTm = 'LVP_TM',
  Carpet = 'CARPET'
}

export type ProductTypeOldOperationFilterInput = {
  eq?: Maybe<ProductTypeOld>;
  neq?: Maybe<ProductTypeOld>;
  in?: Maybe<Array<ProductTypeOld>>;
  nin?: Maybe<Array<ProductTypeOld>>;
};

export type ProductTypeOption = {
  __typename?: 'ProductTypeOption';
  id: Scalars['Int'];
  type: Scalars['String'];
};

export type ProductTypeWithSqft = {
  __typename?: 'ProductTypeWithSqft';
  id: Scalars['Int'];
  type: Scalars['String'];
  sqft: Scalars['Int'];
};

export type ProjectCoordinatorJobCard = {
  __typename?: 'ProjectCoordinatorJobCard';
  id: Scalars['Int'];
  projectCoordinator?: Maybe<Worker>;
  dateClaimed?: Maybe<Scalars['DateTime']>;
  cwcDeadline?: Maybe<Scalars['DateTime']>;
  cwcNumber?: Maybe<Scalars['Int']>;
  sAHNumber: Scalars['String'];
  contract: JobContract;
  totalPrice: Scalars['Decimal'];
  financing?: Maybe<Financing>;
  jobCOD: Scalars['Decimal'];
  depositThresholdHit: Scalars['Boolean'];
  totalPaid: Scalars['Decimal'];
  customer: Customer;
  salesperson?: Maybe<Salesperson>;
  installationAppointments: Array<GroupedInstallationAppointment>;
  installationsToSchedule: Array<InstallationToSchedule>;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  id: Scalars['Int'];
  code: Scalars['String'];
  displayText: Scalars['String'];
  expirationDate: Scalars['DateTime'];
};

export type PromotionOnJob = {
  __typename?: 'PromotionOnJob';
  id: Scalars['Int'];
  basePromotion: BasePromotion;
  savingsAmount: Scalars['Decimal'];
};

export type PromotionOnJobAction = {
  __typename?: 'PromotionOnJobAction';
  id: Scalars['Int'];
  promotion?: Maybe<PromotionOnJob>;
  isRemoval: Scalars['Boolean'];
};

export type PromotionOnJobInput = {
  id: Scalars['Int'];
  basePromotion: BasePromotionInput;
  savingsAmount: Scalars['Decimal'];
};

export type PromotionType = {
  __typename?: 'PromotionType';
  id: Scalars['Int'];
  label: Scalars['String'];
};

export type PromotionTypeInput = {
  id: Scalars['Int'];
  label: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  users: Array<User>;
  dummyInventory: Array<InventoryEntry>;
  cuts: Array<RollCut>;
  installationValidationData: InstallationDetailsValidationData;
  testSignature: Scalars['String'];
  testPrintContract: Scalars['Boolean'];
  testSendEmail: Scalars['Boolean'];
  substrateId: Array<SubstrateId>;
  productTypeOptions: Array<ProductTypeOption>;
  productStyleOptions: Array<ProductStyleOption>;
  productColorOptions: Array<ProductColorOption>;
  structuredProductDetailOptions: Array<StructuredProductDetailOption>;
  unstructuredProductDetailOptions: Array<UnstructuredProductDetailOption>;
  productStyle: ProductStyle;
  allProductStyle: Array<ProductStyles>;
  partialProductStyle: ProductStyle;
  partialProductStyles: Array<ProductStyle>;
  productStyleIdsForProductType: Array<Scalars['Int']>;
  productSourceIdsForStyle: Array<SourcedProductIdAndColorName>;
  vendorSourcedProduct: VendorSourcedProduct;
  productColorsAndSources: Array<SourcedProduct>;
  vendorsForColors: Array<ColorIdAndVendors>;
  productSpecForStyle: ProductSpecification;
  productSpec: ProductSpecification;
  productSpecNames: Array<ProductSpecName>;
  vendors: Array<VendorName>;
  measurementUnit: Array<MeasurementUnitOptionFull>;
  serviceTypes: Array<ServiceType>;
  allServiceProductOfferingsForJobService: Array<ServiceProductOffering>;
  chargeableServicePricingDetails: Array<ChargeableServicePricingDetails>;
  servicePricingUnitEnum: Array<Scalars['String']>;
  serviceProductOfferingIds: Array<Scalars['Int']>;
  allServiceProductOfferings: Array<ServiceLaborRule>;
  serviceLaborRule: ServiceLaborRule;
  jobServicesByType: Array<JobService>;
  installationJobServices: Array<JobService>;
  testSvg: Scalars['String'];
  quotePriceForJobChanges: CostAndPrice;
  calculatePriceForLocalConfiguration?: Maybe<PriceAndPromoSavings>;
  productStyleValidOptionsForArea: Array<ProductStyleValidForArea>;
  allValidProductStyleOptionsForConfiguration: Array<ProductStyleValidForConfiguration>;
  sAHSalespeople: Array<Salesperson>;
  stepDetails: Array<StepDetail>;
  roomLabelOptions: Array<RoomLabelOption>;
  jobWithConfiguration: Job;
  area: Area;
  priceVerificationFields: PriceVerificationFields;
  jobConfigurationWithWasteFactor: JobConfiguration;
  fullyScaledJobConfiguration: JobConfiguration;
  jobConfiguration: JobConfiguration;
  msrpScalarForConfiguration: Scalars['Decimal'];
  customerNameAndAppointmentNumber: CustomerNameAndAppointmentNumber;
  sAHAppointmentIdForJob: Scalars['Int'];
  jobConfigurationCost: Scalars['Decimal'];
  serviceMetadataOptions: Array<JobMetadataOffering>;
  serviceTypeOptions: Array<ServiceTypeOption>;
  materialOptions: Array<ServiceMaterialCategoryOption>;
  validInstallationJobServiceIds: Array<Scalars['Int']>;
  jobConfigurationHeader: JobMetadataHeader;
  allJobConfigurationHeaders: Array<JobMetadataHeader>;
  allJobIds: Array<Scalars['Int']>;
  preContractJobSummaryData: PreContractJobConfigIds;
  pricingCenterTabs: PricingCenterTabs;
  jobSummaryData: JobMetadataWithConfigIds;
  allJobSummaries: Array<JobMetadataWithConfigIds>;
  jobConfigurationAreaIds: Array<Scalars['Int']>;
  contractData: ContractPdfData;
  jobId: Scalars['Int'];
  jobContract: JobContract;
  contractProgress: ContractProgress;
  jobActiveContractId: Scalars['Int'];
  jobContractRevisionIds: JobContractHistory;
  jobContractPdfS3File?: Maybe<S3File>;
  quote: Quote;
  allQuotesWithCustomer: Array<QuoteAndCustomer>;
  quotePdfS3File?: Maybe<S3File>;
  customerForQuote: Customer;
  picture: Picture;
  loadDrawing?: Maybe<Drawing>;
  totalPriceBeforeOverride: Scalars['Decimal'];
  financedAmount: Scalars['Decimal'];
  phoneNumberStatus: PhoneNumberStatus;
  marketIdFromZip: Scalars['Int'];
  allMarkets: Array<Market>;
  canDeleteMarket: Scalars['Boolean'];
  marketIdAndZipsDict: Array<KeyValuePairOfInt32AndListOfString>;
  allActiveZipsAndCities: Array<ZipAndCity>;
  allZipsAndCities: Array<ZipAndCity>;
  allCounties: Array<County>;
  availableTimeSlotsForSalesperson: Array<MarketTimeSlot>;
  availableTimeSlots: Array<MarketTimeSlot>;
  allTimeSlotsForZip: Array<MarketTimeSlot>;
  allTimeSlotsForMarket: Array<MarketTimeSlot>;
  allSalespeopleInMarket: Array<Salesperson>;
  allSalespeopleInZip: Array<Salesperson>;
  filteredBlockedTimeSlots: Array<BlockedTimeSlot>;
  allBlockedTimesSlotsOnDay: Array<BlockedMarketTimeSlot>;
  allHowDidYouHearAboutUsOptions: Array<HowDidYouHearAboutUsOption>;
  sAHAppointmentsForDate: Array<SahAppointment>;
  sAHAppointmentsForSalesperson: Array<SahAppointment>;
  priorityOptions: Array<PriorityOption>;
  colorCategories: Array<ColorCategory>;
  activePromosExist: Scalars['Boolean'];
  activePromoCode?: Maybe<PromoCode>;
  productTypeByProductTypeId: Scalars['String'];
  productTypesInConfigurationWithSqft: Array<ProductTypeWithSqft>;
  activeJobsForDashboard: Array<JobConfigAndCustomerName>;
  projectCoordinatorFromConfiguration?: Maybe<Worker>;
  jobPayments: Array<JobContractPayment>;
  jobBreakdown: JobBreakdown;
  appointmentWorkOrder: WorkOrder;
  jobWorkOrders: Array<WorkOrder>;
  jobCOD: ContractCodData;
  jobNotes?: Maybe<Scalars['String']>;
  allAreaAndRoomNotes: Array<AreaAndRoomNotes>;
  allInstallationNotes: Array<InstallationNote>;
  allAreasForJob: Array<Area>;
  allAreaProductDetailsForJob: Array<AreaProductDetail>;
  allRoomsForJob: Array<Room>;
  allAppliedDiscountsForJob: Array<AppliedDiscount>;
  overrideDiscountForJob?: Maybe<AppliedDiscount>;
  allSalespeopleForJob: Array<Salesperson>;
  jobSearchResults: Array<JobSearchResult>;
  allContractorInstallationCapabilitiesAndDaysOff: Array<ContractorInstallationCapabilitiesAndDaysOff>;
  daysContractorIsBusy: Array<Scalars['DateTime']>;
  allContractors: Array<Contractor>;
  contractor?: Maybe<Contractor>;
  contractorProfile: ContractorProfile;
  allInstallationCertifications: Array<InstallationCertification>;
  allContractorRatingParameterSets: Array<ContractorRatingParameters>;
  newestContractorRatingSummary?: Maybe<ContractorRatingSummary>;
  installationAppointments: Array<InstallationAppointmentWithRooms>;
  installationAppointmentWithRooms: InstallationAppointmentWithRooms;
  installationCodBoundsForNew: InstallationCodBounds;
  installationCodBoundsForExisting: InstallationCodBounds;
  installationAppointmentsForJobContract: Array<InstallationAppointment>;
  installationAppointmentsAfterDate: Array<InstallationAppointment>;
  installationAppointmentForContractorAfterDate: Array<InstallationAppointment>;
  installationAppointmentForContractor: InstallationAppointment;
  completionCertificate?: Maybe<CompletionCertificate>;
  completionCertificatePDF: Scalars['String'];
  tempCompletionCertificateData?: Maybe<Scalars['String']>;
  estimateAppointmentLength: Scalars['Int'];
  calculateInstallationCalendarMovement: InstallationCalendarMovementResult;
  serviceOrderTypes: Array<ServiceOrderType>;
  serviceOrderStubs: Array<ServiceOrderStub>;
  serviceOrder?: Maybe<ServiceOrder>;
  picturesForServiceOrder: Array<PictureForServiceOrder>;
  partialServiceOrdersForContractor: Array<ServiceOrder>;
  acceptedPaymentMethods: Array<AcceptedPaymentMethod>;
  authenticatedWorker?: Maybe<Worker>;
  authenticatedCommandCenterWorkerId?: Maybe<Scalars['Int']>;
  generalCommandCenterWorkerId: Scalars['Int'];
  salesperson?: Maybe<Salesperson>;
  workerBlockedTimeSlotsAfterDate: Array<WorkerBlockedTimeSlot>;
  authenticatedWorkerPermissions?: Maybe<Permissions>;
  workerPermissions: Permissions;
  chatMessages: ChatAndMessages;
  chatMembers: Array<ChatMember>;
  commandCenterChatParty: ChatParty;
  chatForJob: ChatForJob;
  chatForJobFromChat: ChatForJob;
  defaultDisplayedJobChats: Array<ChatForJob>;
  jobIdForChat: Scalars['Int'];
  priceRequest?: Maybe<PriceRequest>;
  priceRequestsForJob: Array<PriceRequest>;
  currentPriceOverride?: Maybe<PriceOverride>;
  currentCommissionRates: Array<CommissionRate>;
  allJobsInRecovery: Array<JobInRecovery>;
  allRecoveryStepOptions: Array<RecoveryStepOption>;
  allRecoveryReasonOptions: Array<RecoveryReasonOption>;
  allRecoveryLedgerItems: Array<RecoveryLedgerItem>;
  recoveryCenterOverride?: Maybe<RecoveryCenterOverride>;
  jobIsFail: Scalars['Boolean'];
  jobIsInRecovery: Scalars['Boolean'];
  allJobsForProjectCoordinator: Array<ProjectCoordinatorJobCard>;
  cwcJobsForProjectCoordinator: Array<ProjectCoordinatorJobCard>;
  cWCNumber: JobIdAndCwcNumber;
  allProjectCoordinators: Array<Worker>;
  financingTypes: Array<FinancingType>;
  financingTableOptions: Array<FinancingTableRow>;
  activeFinancingOptions: Array<FinancingOption>;
  jobFinancingOptions: FinancingSlotOptions;
  allCallCenterJobs: Array<CallCenterJob>;
  callCenterJob: CallCenterJob;
  callCenterScripts: Array<CallCenterScript>;
  callCenterJobSummary: CallCenterJobSummary;
  allServiceMaterialCategoryPricings: Array<FullServiceMaterialCategoryPricing>;
  serviceMaterialCategoryHistory: Array<ServiceMaterialCategoryPricingHistory>;
  allServiceMaterialCategoriesForProduct: Array<ServiceMaterialCategory>;
  metadataOfferingsForProductAndService: Array<MaterialForServiceOffering>;
  customer: Customer;
  customerSearchResults: Array<CustomerSearchResult>;
  customerAppointmentSearchResults: Array<CustomerAppointmentSearchResult>;
  customerAsSearchResult: CustomerSearchResult;
  customerHistory: CustomerJobHistory;
  promotionTypes: Array<PromotionType>;
  allBasePromotions: Array<BasePromotion>;
  basePromotionIdsForActiveOrUpcoming: Array<Scalars['Int']>;
  allScheduledPromotions: Array<ScheduledPromotion>;
  promotionOption?: Maybe<BasePromotion>;
  promotionOnJob?: Maybe<PromotionOnJob>;
  checkForPromotionConflicts: Array<Scalars['Int']>;
  allDiscounts: Array<Discount>;
  s3File: S3File;
  allSalespeople: Array<Salesperson>;
  salespersonProfile: SalespersonProfile;
  generatedSalespersonPerformanceSummary: SalespersonPerformanceSummary;
  storedSalespersonPerformanceSummary: SalespersonPerformanceSummary;
  listOfStoredSalespersonPerformanceSummaries: Array<PerformanceSummaryMetadata>;
  customerCleanupList?: Maybe<CustomerCleanupListConnection>;
  makeContractSignatureFile: Scalars['String'];
};


export type QueryDummyInventoryArgs = {
  order?: Maybe<Array<InventoryEntrySortInput>>;
  where?: Maybe<InventoryEntryFilterInput>;
};


export type QueryCutsArgs = {
  order?: Maybe<Array<RollCutSortInput>>;
  where?: Maybe<RollCutFilterInput>;
};


export type QueryTestPrintContractArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryProductTypeOptionsArgs = {
  surface?: Maybe<SurfaceType>;
};


export type QueryProductStyleOptionsArgs = {
  typeId: Scalars['Int'];
};


export type QueryProductColorOptionsArgs = {
  styleId: Scalars['Int'];
};


export type QueryStructuredProductDetailOptionsArgs = {
  productTypeId: Scalars['Int'];
};


export type QueryUnstructuredProductDetailOptionsArgs = {
  productTypeId: Scalars['Int'];
};


export type QueryProductStyleArgs = {
  styleId: Scalars['Int'];
};


export type QueryPartialProductStyleArgs = {
  styleId: Scalars['Int'];
};


export type QueryPartialProductStylesArgs = {
  productId: Scalars['Int'];
  vendorId?: Maybe<Scalars['Int']>;
};


export type QueryProductStyleIdsForProductTypeArgs = {
  productId: Scalars['Int'];
  vendorId?: Maybe<Scalars['Int']>;
};


export type QueryProductSourceIdsForStyleArgs = {
  styleId: Scalars['Int'];
  vendorId?: Maybe<Scalars['Int']>;
};


export type QueryVendorSourcedProductArgs = {
  sourceId: Scalars['Int'];
};


export type QueryProductColorsAndSourcesArgs = {
  styleId: Scalars['Int'];
  vendorId?: Maybe<Scalars['Int']>;
};


export type QueryVendorsForColorsArgs = {
  colorIds: Array<Scalars['Int']>;
};


export type QueryProductSpecForStyleArgs = {
  styleId: Scalars['Int'];
};


export type QueryProductSpecArgs = {
  productSpecId: Scalars['Int'];
};


export type QueryProductSpecNamesArgs = {
  productTypeId?: Scalars['Int'];
  styleId?: Scalars['Int'];
  colorId?: Scalars['Int'];
};


export type QueryVendorsArgs = {
  hasProductTypeId?: Maybe<Scalars['Int']>;
};


export type QueryMeasurementUnitArgs = {
  ids: Array<Scalars['Int']>;
};


export type QueryAllServiceProductOfferingsForJobServiceArgs = {
  jobServiceId: Scalars['Int'];
};


export type QueryServiceProductOfferingIdsArgs = {
  filter: QueryServiceProductIdsFilterInput;
};


export type QueryAllServiceProductOfferingsArgs = {
  filter: QueryServiceProductIdsFilterInput;
};


export type QueryServiceLaborRuleArgs = {
  id: Scalars['Int'];
};


export type QueryJobServicesByTypeArgs = {
  serviceTypeId: Scalars['Int'];
};


export type QueryInstallationJobServicesArgs = {
  productTypeId: Scalars['Int'];
};


export type QueryQuotePriceForJobChangesArgs = {
  originalJobConfigurationId: Scalars['Int'];
  scaledUpdatedJobConfiguration: JobConfigurationInput;
};


export type QueryCalculatePriceForLocalConfigurationArgs = {
  originalConfigurationId: Scalars['Int'];
  updatedProducts: Array<UpdatedProductForAreaInput>;
  includeSalesTax: Scalars['Boolean'];
};


export type QueryProductStyleValidOptionsForAreaArgs = {
  areaId: Scalars['Int'];
};


export type QueryAllValidProductStyleOptionsForConfigurationArgs = {
  substrateIdsInArea: Array<Scalars['Int']>;
  productTypeId: Scalars['Int'];
};


export type QueryJobWithConfigurationArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryAreaArgs = {
  areaId: Scalars['Int'];
};


export type QueryPriceVerificationFieldsArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobConfigurationWithWasteFactorArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryFullyScaledJobConfigurationArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobConfigurationArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryMsrpScalarForConfigurationArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryCustomerNameAndAppointmentNumberArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QuerySAhAppointmentIdForJobArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobConfigurationCostArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryServiceMetadataOptionsArgs = {
  productTypeId: Scalars['Int'];
};


export type QueryServiceTypeOptionsArgs = {
  productTypeId?: Maybe<Scalars['Int']>;
};


export type QueryMaterialOptionsArgs = {
  jobServiceId: Scalars['Int'];
  productTypeId: Scalars['Int'];
};


export type QueryValidInstallationJobServiceIdsArgs = {
  areaId?: Maybe<Scalars['Int']>;
};


export type QueryJobConfigurationHeaderArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryPreContractJobSummaryDataArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryPricingCenterTabsArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobSummaryDataArgs = {
  jobId: Scalars['Int'];
};


export type QueryJobConfigurationAreaIdsArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryContractDataArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobIdArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobContractArgs = {
  jobContractId: Scalars['Int'];
};


export type QueryContractProgressArgs = {
  jobContractId: Scalars['Int'];
};


export type QueryJobActiveContractIdArgs = {
  jobId: Scalars['Int'];
};


export type QueryJobContractRevisionIdsArgs = {
  jobContractId: Scalars['Int'];
};


export type QueryJobContractPdfS3FileArgs = {
  jobContractId: Scalars['Int'];
};


export type QueryQuoteArgs = {
  quoteId: Scalars['Int'];
};


export type QueryQuotePdfS3FileArgs = {
  quoteId: Scalars['Int'];
};


export type QueryCustomerForQuoteArgs = {
  quoteId: Scalars['Int'];
};


export type QueryPictureArgs = {
  pictureId: Scalars['Int'];
};


export type QueryLoadDrawingArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryTotalPriceBeforeOverrideArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryFinancedAmountArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryPhoneNumberStatusArgs = {
  phoneNumber: Scalars['String'];
};


export type QueryMarketIdFromZipArgs = {
  zip: Scalars['String'];
};


export type QueryCanDeleteMarketArgs = {
  marketId: Scalars['Int'];
};


export type QueryAvailableTimeSlotsForSalespersonArgs = {
  zip: Scalars['String'];
  date: Scalars['DateTime'];
  salespersonId: Scalars['Int'];
};


export type QueryAvailableTimeSlotsArgs = {
  zip: Scalars['String'];
  date: Scalars['DateTime'];
};


export type QueryAllTimeSlotsForZipArgs = {
  zip: Scalars['String'];
};


export type QueryAllTimeSlotsForMarketArgs = {
  marketId: Scalars['Int'];
};


export type QueryAllSalespeopleInMarketArgs = {
  marketId: Scalars['Int'];
};


export type QueryAllSalespeopleInZipArgs = {
  zip: Scalars['String'];
};


export type QueryFilteredBlockedTimeSlotsArgs = {
  dates: Array<Scalars['DateTime']>;
  marketId?: Maybe<Scalars['Int']>;
  ts: AtomicTimeSlotInput;
};


export type QueryAllBlockedTimesSlotsOnDayArgs = {
  date: Scalars['DateTime'];
  marketId: Scalars['Int'];
};


export type QuerySAhAppointmentsForDateArgs = {
  date: Scalars['DateTime'];
  marketId: Scalars['Int'];
};


export type QuerySAhAppointmentsForSalespersonArgs = {
  salespersonId: Scalars['Int'];
};


export type QueryActivePromoCodeArgs = {
  code: Scalars['String'];
};


export type QueryProductTypeByProductTypeIdArgs = {
  typeId: Scalars['Int'];
};


export type QueryProductTypesInConfigurationWithSqftArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryProjectCoordinatorFromConfigurationArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobPaymentsArgs = {
  jobContractId: Scalars['Int'];
};


export type QueryJobBreakdownArgs = {
  jobConfigurationId: Scalars['Int'];
  hideNoLaborRAndR?: Scalars['Boolean'];
};


export type QueryAppointmentWorkOrderArgs = {
  installationAppointmentId: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobWorkOrdersArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobCodArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobNotesArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryAllAreaAndRoomNotesArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryAllInstallationNotesArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryAllAreasForJobArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryAllAreaProductDetailsForJobArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryAllRoomsForJobArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryAllAppliedDiscountsForJobArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryOverrideDiscountForJobArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryAllSalespeopleForJobArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobSearchResultsArgs = {
  searchStr: Scalars['String'];
};


export type QueryContractorArgs = {
  workerId: Scalars['Int'];
};


export type QueryContractorProfileArgs = {
  workerId: Scalars['Int'];
};


export type QueryNewestContractorRatingSummaryArgs = {
  contractorId: Scalars['Int'];
};


export type QueryInstallationAppointmentsArgs = {
  jobContractId: Scalars['Int'];
};


export type QueryInstallationAppointmentWithRoomsArgs = {
  installationAppointmentId: Scalars['Int'];
};


export type QueryInstallationCodBoundsForNewArgs = {
  jobConfigurationId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  roomIds: Array<Scalars['Int']>;
};


export type QueryInstallationCodBoundsForExistingArgs = {
  appointmentId: Scalars['Int'];
  newStartDate: Scalars['DateTime'];
};


export type QueryInstallationAppointmentsForJobContractArgs = {
  jobContractId: Scalars['Int'];
};


export type QueryInstallationAppointmentsAfterDateArgs = {
  afterDate: Scalars['DateTime'];
};


export type QueryInstallationAppointmentForContractorAfterDateArgs = {
  contractorId: Scalars['Int'];
  afterDate: Scalars['DateTime'];
};


export type QueryInstallationAppointmentForContractorArgs = {
  installationAppointmentId: Scalars['Int'];
};


export type QueryCompletionCertificateArgs = {
  installationAppointmentId: Scalars['Int'];
};


export type QueryCompletionCertificatePdfArgs = {
  installationAppointmentId: Scalars['Int'];
};


export type QueryTempCompletionCertificateDataArgs = {
  installationAppointmentId: Scalars['Int'];
};


export type QueryEstimateAppointmentLengthArgs = {
  roomIds: Array<Scalars['Int']>;
};


export type QueryCalculateInstallationCalendarMovementArgs = {
  block: InstallationCalendarBlockIdentifierInput;
  destinationBlock?: Maybe<InstallationCalendarBlockIdentifierInput>;
  destinationContractorId: Scalars['Int'];
  destinationDate: Scalars['DateTime'];
};


export type QueryServiceOrderStubsArgs = {
  jobContractId: Scalars['Int'];
};


export type QueryServiceOrderArgs = {
  serviceOrderId: Scalars['Int'];
};


export type QueryPicturesForServiceOrderArgs = {
  id: Scalars['Int'];
};


export type QueryPartialServiceOrdersForContractorArgs = {
  contractorId: Scalars['Int'];
};


export type QuerySalespersonArgs = {
  workerId: Scalars['Int'];
};


export type QueryWorkerBlockedTimeSlotsAfterDateArgs = {
  workerId: Scalars['Int'];
  afterDate: Scalars['DateTime'];
};


export type QueryWorkerPermissionsArgs = {
  workerId: Scalars['Int'];
};


export type QueryChatMessagesArgs = {
  chatId: Scalars['Int'];
};


export type QueryChatMembersArgs = {
  chatId: Scalars['Int'];
};


export type QueryChatForJobArgs = {
  jobId: Scalars['Int'];
};


export type QueryChatForJobFromChatArgs = {
  chatId: Scalars['Int'];
};


export type QueryDefaultDisplayedJobChatsArgs = {
  date: Scalars['DateTime'];
  marketId: Scalars['Int'];
  commandCenterWorkerId: Scalars['Int'];
};


export type QueryJobIdForChatArgs = {
  chatId: Scalars['Int'];
};


export type QueryPriceRequestArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryPriceRequestsForJobArgs = {
  jobId: Scalars['Int'];
};


export type QueryCurrentPriceOverrideArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryAllRecoveryLedgerItemsArgs = {
  jobId: Scalars['Int'];
};


export type QueryRecoveryCenterOverrideArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryJobIsFailArgs = {
  jobId: Scalars['Int'];
};


export type QueryJobIsInRecoveryArgs = {
  jobId: Scalars['Int'];
};


export type QueryCWcNumberArgs = {
  jobId: Scalars['Int'];
};


export type QueryJobFinancingOptionsArgs = {
  jobPrice: Scalars['Int'];
  jobConfigurationId?: Maybe<Scalars['Int']>;
};


export type QueryCallCenterJobArgs = {
  jobId: Scalars['Int'];
};


export type QueryCallCenterJobSummaryArgs = {
  jobId: Scalars['Int'];
};


export type QueryServiceMaterialCategoryHistoryArgs = {
  serviceMaterialCategoryProductPricingId: Scalars['Int'];
};


export type QueryAllServiceMaterialCategoriesForProductArgs = {
  productTypeId: Scalars['Int'];
};


export type QueryMetadataOfferingsForProductAndServiceArgs = {
  productTypeId: Scalars['Int'];
  jobServiceId: Scalars['Int'];
};


export type QueryCustomerArgs = {
  customerId: Scalars['Int'];
};


export type QueryCustomerSearchResultsArgs = {
  searchParameters: CustomerSearchParametersInput;
  shouldAndParameters: Scalars['Boolean'];
  numSearches: Scalars['Int'];
};


export type QueryCustomerAppointmentSearchResultsArgs = {
  searchStr: Scalars['String'];
};


export type QueryCustomerAsSearchResultArgs = {
  customerId: Scalars['Int'];
};


export type QueryCustomerHistoryArgs = {
  customerId: Scalars['Int'];
};


export type QueryAllBasePromotionsArgs = {
  excludeDeleted?: Scalars['Boolean'];
};


export type QueryAllScheduledPromotionsArgs = {
  excludeExpired?: Scalars['Boolean'];
  excludeCanceled?: Scalars['Boolean'];
};


export type QueryPromotionOptionArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryPromotionOnJobArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type QueryCheckForPromotionConflictsArgs = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  marketIds: Array<Scalars['Int']>;
  scheduledIdToIgnore?: Maybe<Scalars['Int']>;
};


export type QueryAllDiscountsArgs = {
  ignoreDeleted?: Scalars['Boolean'];
};


export type QueryS3FileArgs = {
  fileId: Scalars['Int'];
};


export type QuerySalespersonProfileArgs = {
  workerId: Scalars['Int'];
};


export type QueryGeneratedSalespersonPerformanceSummaryArgs = {
  salespersonId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export type QueryStoredSalespersonPerformanceSummaryArgs = {
  id: Scalars['Int'];
};


export type QueryListOfStoredSalespersonPerformanceSummariesArgs = {
  salespersonId: Scalars['Int'];
};


export type QueryCustomerCleanupListArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryMakeContractSignatureFileArgs = {
  contractId: Scalars['Int'];
};

export type QueryServiceProductIdsFilterInput = {
  isDeleted?: Maybe<Scalars['Boolean']>;
  productTypeId?: Maybe<Scalars['Int']>;
  serviceTypeId?: Maybe<Scalars['Int']>;
};

export type Quote = {
  __typename?: 'Quote';
  id: Scalars['Int'];
  salespersonId: Scalars['Int'];
  appointmentId: Scalars['Int'];
  quoteNumber: Scalars['String'];
  date: Scalars['DateTime'];
  followUpDate: Scalars['DateTime'];
  jobConfigurationId: Scalars['Int'];
  s3FileId?: Maybe<Scalars['Int']>;
  totalPrice: Scalars['Decimal'];
  notes?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<Area>>;
};

export type QuoteAndCustomer = {
  __typename?: 'QuoteAndCustomer';
  id: Scalars['Int'];
  quote: Quote;
  customer: Customer;
};

export type RawSahAppointmentDataInput = {
  customer: CustomerInput;
  isForHome: Scalars['Boolean'];
  date: Scalars['DateTime'];
  marketTimeSlotId: Scalars['Int'];
  productTypeIds: Array<Scalars['Int']>;
  priorityOptionIds: Array<Scalars['Int']>;
  colorCategoryIds: Array<Scalars['Int']>;
  numRooms: Scalars['Int'];
  secondaryEmail?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  appointmentNotes?: Maybe<Scalars['String']>;
  promoCodeId?: Maybe<Scalars['Int']>;
  howDidYouHearAboutUsOptionId: Scalars['Int'];
  scheduledByWorkerId?: Maybe<Scalars['Int']>;
  mergeWithCustomerId?: Maybe<Scalars['Int']>;
};

export type RecoveryCenterOverride = {
  __typename?: 'RecoveryCenterOverride';
  id: Scalars['Int'];
  jobId: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  amount: Scalars['Decimal'];
  commissionRate: Scalars['Decimal'];
  workerId: Scalars['Int'];
  timeOverridden: Scalars['DateTime'];
};

export type RecoveryCommunication = {
  __typename?: 'RecoveryCommunication';
  id: Scalars['Int'];
  creationTimestamp: Scalars['DateTime'];
  recoveryCommunicationOptionId: Scalars['Int'];
  notes: Scalars['String'];
  isEditable: Scalars['Boolean'];
  workerFirstName: Scalars['String'];
  workerLastName: Scalars['String'];
};

export type RecoveryLedgerItem = {
  __typename?: 'RecoveryLedgerItem';
  jobId: Scalars['Int'];
  communicationId?: Maybe<Scalars['Int']>;
  reasonId?: Maybe<Scalars['Int']>;
  stepId?: Maybe<Scalars['Int']>;
  overrideId?: Maybe<Scalars['Int']>;
  internalNotesId?: Maybe<Scalars['Int']>;
  callCenterActionId?: Maybe<Scalars['Int']>;
  action: Scalars['String'];
  timestamp: Scalars['DateTime'];
  notes: Scalars['String'];
  worker: Scalars['String'];
  isEditable: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  showTime: Scalars['Boolean'];
};

export type RecoveryReason = {
  __typename?: 'RecoveryReason';
  id: Scalars['Int'];
  recoveryReasonOptionId?: Maybe<Scalars['Int']>;
  reason: Scalars['String'];
  date: Scalars['DateTime'];
  createdByWorkerId: Scalars['Int'];
};

export type RecoveryReasonOption = {
  __typename?: 'RecoveryReasonOption';
  id: Scalars['Int'];
  label: Scalars['String'];
};

export type RecoveryStep = {
  __typename?: 'RecoveryStep';
  id: Scalars['Int'];
  recoveryStepOptionId: Scalars['Int'];
  step: Scalars['String'];
  date: Scalars['DateTime'];
  createdByWorkerId: Scalars['Int'];
};

export type RecoveryStepOption = {
  __typename?: 'RecoveryStepOption';
  id: Scalars['Int'];
  label: Scalars['String'];
};

export type RollCut = {
  __typename?: 'RollCut';
  sku: Scalars['String'];
  rollID: Scalars['String'];
  requestedDyeLot: Scalars['String'];
  cutNumber: Scalars['Int'];
  jobID: Scalars['String'];
  length: Scalars['Float'];
  dueDate: Scalars['DateTime'];
};

export type RollCutFilterInput = {
  and?: Maybe<Array<RollCutFilterInput>>;
  or?: Maybe<Array<RollCutFilterInput>>;
  sku?: Maybe<StringOperationFilterInput>;
  rollID?: Maybe<StringOperationFilterInput>;
  requestedDyeLot?: Maybe<StringOperationFilterInput>;
  cutNumber?: Maybe<ComparableInt32OperationFilterInput>;
  jobID?: Maybe<StringOperationFilterInput>;
  length?: Maybe<ComparableSingleOperationFilterInput>;
  dueDate?: Maybe<ComparableDateTimeOperationFilterInput>;
};

export type RollCutSortInput = {
  sku?: Maybe<SortEnumType>;
  rollID?: Maybe<SortEnumType>;
  requestedDyeLot?: Maybe<SortEnumType>;
  cutNumber?: Maybe<SortEnumType>;
  jobID?: Maybe<SortEnumType>;
  length?: Maybe<SortEnumType>;
  dueDate?: Maybe<SortEnumType>;
};

export type RollEntry = {
  __typename?: 'RollEntry';
  sku: Scalars['String'];
  rollNumber: Scalars['String'];
  dyeLot: Scalars['String'];
  length: Scalars['Float'];
  holding: Scalars['Float'];
  location: Scalars['String'];
  cuts: Array<RollCut>;
};

export type RollEntryFilterInput = {
  and?: Maybe<Array<RollEntryFilterInput>>;
  or?: Maybe<Array<RollEntryFilterInput>>;
  sku?: Maybe<StringOperationFilterInput>;
  rollNumber?: Maybe<StringOperationFilterInput>;
  dyeLot?: Maybe<StringOperationFilterInput>;
  length?: Maybe<ComparableSingleOperationFilterInput>;
  holding?: Maybe<ComparableSingleOperationFilterInput>;
  location?: Maybe<StringOperationFilterInput>;
  cuts?: Maybe<ListFilterInputTypeOfRollCutFilterInput>;
};

export type Room = {
  __typename?: 'Room';
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  areaId?: Maybe<Scalars['Int']>;
  sqft: Scalars['Decimal'];
  lnft: Scalars['Decimal'];
  substrateId: Scalars['Int'];
  price?: Maybe<Price>;
  notes: Scalars['String'];
  svg?: Maybe<Scalars['String']>;
  detailedSvg?: Maybe<Scalars['String']>;
  shape: Array<RoomShapePoint>;
  svgPosition: RoomShapePoint;
  isCarpetVerticallyRun: Scalars['Boolean'];
  labels: Array<LabelForRoom>;
  services: Array<ServiceForRoom>;
  steps: Array<StepForRoom>;
  pictures: Array<PictureForRoom>;
  beforePictures: Array<PictureForRoom>;
  afterPictures: Array<PictureForRoom>;
  carpetCuts: Array<CarpetCut>;
};

export type RoomForInstallationAppointment = {
  __typename?: 'RoomForInstallationAppointment';
  id: Scalars['Int'];
  installationAppointmentId: Scalars['Int'];
  areaId: Scalars['Int'];
  product: Product;
  labels: Array<LabelForRoom>;
  sqft: Scalars['Decimal'];
};

export type RoomInput = {
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  areaId?: Maybe<Scalars['Int']>;
  sqft: Scalars['Decimal'];
  lnft: Scalars['Decimal'];
  substrateId: Scalars['Int'];
  price?: Maybe<PriceInput>;
  notes: Scalars['String'];
  svg?: Maybe<Scalars['String']>;
  detailedSvg?: Maybe<Scalars['String']>;
  shape: Array<RoomShapePointInput>;
  svgPosition: RoomShapePointInput;
  isCarpetVerticallyRun: Scalars['Boolean'];
  labels: Array<LabelForRoomInput>;
  services: Array<ServiceForRoomInput>;
  steps: Array<StepForRoomInput>;
  pictures: Array<PictureForRoomInput>;
  beforePictures: Array<PictureForRoomInput>;
  afterPictures: Array<PictureForRoomInput>;
  carpetCuts: Array<CarpetCutInput>;
};

export type RoomLabelOption = {
  __typename?: 'RoomLabelOption';
  id: Scalars['Int'];
  label: Scalars['String'];
  fullLabel: Scalars['String'];
  defaultServices?: Maybe<Array<DefaultServiceForRoomLabel>>;
};

export type RoomLabelOptionInput = {
  id: Scalars['Int'];
  label: Scalars['String'];
  fullLabel: Scalars['String'];
  defaultServices?: Maybe<Array<DefaultServiceForRoomLabelInput>>;
};

export type RoomShapePoint = {
  __typename?: 'RoomShapePoint';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type RoomShapePointInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type RoomTransition = {
  __typename?: 'RoomTransition';
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  mainRoomId: Scalars['Int'];
  altRoomId?: Maybe<Scalars['Int']>;
  altExistingRoomIds: Array<Scalars['Int']>;
  width: Scalars['Decimal'];
  isDoor: Scalars['Boolean'];
  isExternalDoor: Scalars['Boolean'];
  mainRoomPositionIndex: Scalars['Int'];
  altRoomPositionIndex: Scalars['Int'];
};

export type RoomTransitionInput = {
  id: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  mainRoomId: Scalars['Int'];
  altRoomId?: Maybe<Scalars['Int']>;
  altExistingRoomIds: Array<Scalars['Int']>;
  width: Scalars['Decimal'];
  isDoor: Scalars['Boolean'];
  isExternalDoor?: Scalars['Boolean'];
  mainRoomPositionIndex?: Scalars['Int'];
  altRoomPositionIndex?: Scalars['Int'];
};

export type S3File = {
  __typename?: 'S3File';
  id: Scalars['Int'];
  objectKey: Scalars['String'];
  fileName: Scalars['String'];
  status: Scalars['String'];
  uploadTimestamp?: Maybe<Scalars['DateTime']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type S3FileInput = {
  id: Scalars['Int'];
  objectKey: Scalars['String'];
  fileName: Scalars['String'];
  status: Scalars['String'];
  uploadTimestamp?: Maybe<Scalars['DateTime']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type SahAppointment = {
  __typename?: 'SAHAppointment';
  id: Scalars['Int'];
  appointmentNumber: Scalars['String'];
  jobId: Scalars['Int'];
  numRooms: Scalars['Int'];
  date: Scalars['DateTime'];
  isForCustomerHouse: Scalars['Boolean'];
  isCancelled: Scalars['Boolean'];
  isInRecovery: Scalars['Boolean'];
  comments?: Maybe<Scalars['String']>;
  promoCodeId?: Maybe<Scalars['Int']>;
  howDidYouHearAboutUsOptionId: Scalars['Int'];
  howDidYouHearAboutUsOption: Scalars['String'];
  productTypes: Array<Scalars['String']>;
  productTypeIds: Array<Scalars['Int']>;
  customerId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  primaryAddress: Scalars['String'];
  primaryCity: Scalars['String'];
  primaryZipCode: Scalars['String'];
  primaryPhone: Scalars['String'];
  appointmentStatusId: Scalars['Int'];
  appointmentStatusString: Scalars['String'];
  marketTimeSlot: MarketTimeSlot;
  currentSalesperson?: Maybe<SalespersonAssignedToAppointment>;
  activeJobConfigurationId?: Maybe<Scalars['Int']>;
  handlingCommandCenterWorkerId?: Maybe<Scalars['Int']>;
  contractCancelled: Scalars['Boolean'];
  contractId?: Maybe<Scalars['Int']>;
  contractTotalPrice?: Maybe<Scalars['Decimal']>;
  quoteId?: Maybe<Scalars['Int']>;
  quoteTotalPrice?: Maybe<Scalars['Decimal']>;
  scheduledByWorkerId?: Maybe<Scalars['Int']>;
  priorityOptions?: Maybe<Array<PriorityOption>>;
  colorCategories?: Maybe<Array<ColorCategory>>;
};

export type Salesperson = {
  __typename?: 'Salesperson';
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isCheckedIn?: Maybe<Scalars['Boolean']>;
  serviceZips: Array<Scalars['String']>;
};

export type SalespersonAssignedToAppointment = {
  __typename?: 'SalespersonAssignedToAppointment';
  id: Scalars['Int'];
  sAHAppointmentId: Scalars['Int'];
  salespersonId: Scalars['Int'];
  releasedTime?: Maybe<Scalars['TimeSpan']>;
  acceptedTime?: Maybe<Scalars['TimeSpan']>;
  checkInTime?: Maybe<Scalars['TimeSpan']>;
  checkOutTime?: Maybe<Scalars['TimeSpan']>;
  appointmentStartTime: Scalars['TimeSpan'];
  isWriteUp: Scalars['Boolean'];
};

export type SalespersonPerformanceSummary = {
  __typename?: 'SalespersonPerformanceSummary';
  id: Scalars['Int'];
  salespersonId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  numLeads: Scalars['Int'];
  numDemos: Scalars['Int'];
  saleCount: Scalars['Int'];
  totalSaleDollars: Scalars['Decimal'];
  averageSaleMargin: Scalars['Decimal'];
  numWoodSold: Scalars['Int'];
  numSpcSold: Scalars['Int'];
  numCarpetSold: Scalars['Int'];
  quoteCount: Scalars['Int'];
  totalQuoteDollars: Scalars['Decimal'];
  averageQuoteMargin: Scalars['Decimal'];
  numWoodQuoted: Scalars['Int'];
  numSpcQuoted: Scalars['Int'];
  numCarpetQuoted: Scalars['Int'];
  cancelCount: Scalars['Int'];
  totalCancelDollars: Scalars['Decimal'];
  averageCancelMargin: Scalars['Decimal'];
  numWoodCancelled: Scalars['Int'];
  numSpcCancelled: Scalars['Int'];
  numCarpetCancelled: Scalars['Int'];
  numCarpetDemos: Scalars['Decimal'];
  numSpcDemos: Scalars['Decimal'];
  numWoodDemos: Scalars['Decimal'];
};

export type SalespersonProfile = {
  __typename?: 'SalespersonProfile';
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  servicedZips: Array<Scalars['String']>;
  profilePictureFileId?: Maybe<Scalars['Int']>;
  address?: Maybe<Address>;
  driversLicenseNumber?: Maybe<Scalars['String']>;
  driversLicenseFile?: Maybe<S3File>;
  vehicle?: Maybe<Vehicle>;
  parentCompany?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  workerHistory?: Maybe<WorkerHistory>;
  backgroundChecks: Array<BackgroundCheck>;
  w9s: Array<W9>;
  workersComps: Array<WorkersComp>;
  liabilityInsurances: Array<LiabilityInsurance>;
};

export type SalespersonProfileInput = {
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  servicedZips: Array<Scalars['String']>;
  profilePictureFileId?: Maybe<Scalars['Int']>;
  address?: Maybe<AddressInput>;
  driversLicenseNumber?: Maybe<Scalars['String']>;
  driversLicenseFile?: Maybe<S3FileInput>;
  vehicle?: Maybe<VehicleInput>;
  parentCompany?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyInput>;
  workerHistory?: Maybe<WorkerHistoryInput>;
  backgroundChecks: Array<BackgroundCheckInput>;
  w9s: Array<W9Input>;
  workersComps: Array<WorkersCompInput>;
  liabilityInsurances: Array<LiabilityInsuranceInput>;
};

export type ScheduledPromotion = {
  __typename?: 'ScheduledPromotion';
  id: Scalars['Int'];
  basePromotion: BasePromotion;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  marketIds: Array<Scalars['Int']>;
  isCanceled: Scalars['Boolean'];
};

export type ServiceForRoom = {
  __typename?: 'ServiceForRoom';
  id: Scalars['Int'];
  roomId: Scalars['Int'];
  jobServiceId: Scalars['Int'];
  serviceTypeId: Scalars['Int'];
  laborAmount: Scalars['Decimal'];
  customerDoesService: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  materialCategoryId?: Maybe<Scalars['Int']>;
  materialAmount?: Maybe<Scalars['Decimal']>;
};

export type ServiceForRoomBreakdown = {
  __typename?: 'ServiceForRoomBreakdown';
  id: Scalars['Int'];
  room: Room;
  jobServiceId: Scalars['Int'];
  serviceTypeId: Scalars['Int'];
  serviceType: Scalars['String'];
  serviceDescription: Scalars['String'];
  laborAmount: Scalars['Decimal'];
  minimumLaborAmount?: Maybe<Scalars['Decimal']>;
  sqftScaleFactor: Scalars['Decimal'];
  lnftScaleFactor: Scalars['Decimal'];
  laborPriceUnit: Scalars['String'];
  laborPricePerUnit: Scalars['Decimal'];
  customerDoesService: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  materialCategoryId?: Maybe<Scalars['Int']>;
  materialCategoryName?: Maybe<Scalars['String']>;
  materialPricePerUnit: Scalars['Decimal'];
  materialCategoryPriceUnit?: Maybe<Scalars['String']>;
  materialAmount?: Maybe<Scalars['Decimal']>;
};

export type ServiceForRoomInput = {
  id: Scalars['Int'];
  roomId: Scalars['Int'];
  jobServiceId: Scalars['Int'];
  serviceTypeId: Scalars['Int'];
  laborAmount: Scalars['Decimal'];
  customerDoesService: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  materialCategoryId?: Maybe<Scalars['Int']>;
  materialAmount?: Maybe<Scalars['Decimal']>;
};

export type ServiceForRoomPrice = {
  __typename?: 'ServiceForRoomPrice';
  id: Scalars['Int'];
  price: Scalars['Decimal'];
  cost: Scalars['Decimal'];
  materialPrice: Scalars['Decimal'];
  materialCost: Scalars['Decimal'];
};

export type ServiceLaborRule = {
  __typename?: 'ServiceLaborRule';
  id: Scalars['Int'];
  productType: Scalars['String'];
  serviceType: Scalars['String'];
  description: Scalars['String'];
  jobServiceId: Scalars['Int'];
  priceUnit: Scalars['String'];
  costPerUnit: Scalars['Decimal'];
  pricePerUnit: Scalars['Decimal'];
  minimumInUnit?: Maybe<Scalars['Decimal']>;
  isDeleted: Scalars['Boolean'];
};

export type ServiceMaterialCategory = {
  __typename?: 'ServiceMaterialCategory';
  id: Scalars['Int'];
  name: Scalars['String'];
  priceUnit: Scalars['String'];
  packageSize?: Maybe<Scalars['Decimal']>;
  packageSizeIncrement?: Maybe<Scalars['Decimal']>;
};

export type ServiceMaterialCategoryOption = {
  __typename?: 'ServiceMaterialCategoryOption';
  materialCategoryId: Scalars['Int'];
  productTypeId?: Maybe<Scalars['Int']>;
  materialName?: Maybe<Scalars['String']>;
  materialPriceUnit?: Maybe<Scalars['String']>;
  defaultAmount?: Maybe<Scalars['Decimal']>;
  isDefaultMaterial?: Maybe<Scalars['Boolean']>;
};

export type ServiceMaterialCategoryPricingHistory = {
  __typename?: 'ServiceMaterialCategoryPricingHistory';
  id: Scalars['Int'];
  changeTime: Scalars['DateTime'];
  oldPrice: Scalars['Decimal'];
  oldCost: Scalars['Decimal'];
};

export type ServiceOrder = {
  __typename?: 'ServiceOrder';
  id: Scalars['Int'];
  jobContractId: Scalars['Int'];
  serviceOrderTypeId: Scalars['Int'];
  serviceDescription: Scalars['String'];
  calledInDate?: Maybe<Scalars['DateTime']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  scheduleAsap: Scalars['Boolean'];
  completionDate?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['Int']>;
  contractorId?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  additionalActionRequired: Scalars['Boolean'];
  additionalActionDescription?: Maybe<Scalars['String']>;
  chargeAmount?: Maybe<Scalars['Decimal']>;
  product?: Maybe<Product>;
  areaId?: Maybe<Scalars['Int']>;
  roomIds?: Maybe<Array<Scalars['Int']>>;
};

export type ServiceOrderInput = {
  id: Scalars['Int'];
  jobContractId: Scalars['Int'];
  serviceOrderTypeId: Scalars['Int'];
  serviceDescription: Scalars['String'];
  calledInDate?: Maybe<Scalars['DateTime']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  scheduleAsap: Scalars['Boolean'];
  completionDate?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['Int']>;
  contractorId?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  additionalActionRequired: Scalars['Boolean'];
  additionalActionDescription?: Maybe<Scalars['String']>;
  chargeAmount?: Maybe<Scalars['Decimal']>;
  product?: Maybe<ProductInput>;
  areaId?: Maybe<Scalars['Int']>;
  roomIds?: Maybe<Array<Scalars['Int']>>;
};

export type ServiceOrderStub = {
  __typename?: 'ServiceOrderStub';
  id: Scalars['Int'];
  dateCalledIn: Scalars['DateTime'];
  product: Product;
  pictures: Array<PictureForServiceOrder>;
};

export type ServiceOrderType = {
  __typename?: 'ServiceOrderType';
  id: Scalars['Int'];
  type: Scalars['String'];
};

export type ServiceProductOffering = {
  __typename?: 'ServiceProductOffering';
  id: Scalars['Int'];
  jobServiceId: Scalars['Int'];
  costPerUnit: Scalars['Decimal'];
  pricePerUnit: Scalars['Decimal'];
  minimumInUnit?: Maybe<Scalars['Decimal']>;
  productTypeId: Scalars['Int'];
  productType: Scalars['String'];
  isDeleted: Scalars['Boolean'];
};

export type ServiceType = {
  __typename?: 'ServiceType';
  id: Scalars['Int'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
};

export type ServiceTypeOption = {
  __typename?: 'ServiceTypeOption';
  serviceTypeId: Scalars['Int'];
  productTypeId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  displayServiceTypeId?: Maybe<Scalars['Int']>;
  jobServices: Array<JobServiceOption>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SourcedProduct = {
  __typename?: 'SourcedProduct';
  id: Scalars['Int'];
  color: Scalars['String'];
  source: Array<VendorSourcedProduct>;
};

export type SourcedProductIdAndColorName = {
  __typename?: 'SourcedProductIdAndColorName';
  id: Scalars['Int'];
  color: Scalars['String'];
};

export type StepDetail = {
  __typename?: 'StepDetail';
  id: Scalars['Int'];
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  stairServiceId: Scalars['Int'];
  landingServiceId: Scalars['Int'];
  treadOnlyServiceId: Scalars['Int'];
  treadOnlyLandingServiceId: Scalars['Int'];
  promptAboutSpindles: Scalars['Boolean'];
};

export type StepForRoom = {
  __typename?: 'StepForRoom';
  id: Scalars['Int'];
  stepDetailId: Scalars['Int'];
  roomId: Scalars['Int'];
  amount: Scalars['Int'];
  width: Scalars['Decimal'];
  length: Scalars['Decimal'];
  isTreadOnly: Scalars['Boolean'];
  isLanding: Scalars['Boolean'];
  shouldPromptAboutSpindles?: Maybe<Scalars['Boolean']>;
};

export type StepForRoomInput = {
  id: Scalars['Int'];
  stepDetailId: Scalars['Int'];
  roomId: Scalars['Int'];
  amount: Scalars['Int'];
  width: Scalars['Decimal'];
  length: Scalars['Decimal'];
  isTreadOnly?: Scalars['Boolean'];
  isLanding?: Scalars['Boolean'];
  shouldPromptAboutSpindles?: Maybe<Scalars['Boolean']>;
};

export enum StockUnit {
  Boxes = 'BOXES',
  Rolls = 'ROLLS'
}

export type StockUnitOperationFilterInput = {
  eq?: Maybe<StockUnit>;
  neq?: Maybe<StockUnit>;
  in?: Maybe<Array<StockUnit>>;
  nin?: Maybe<Array<StockUnit>>;
};

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  ncontains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
  nstartsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
};

export type StructuredProductDetail = {
  __typename?: 'StructuredProductDetail';
  structuredProductDetailOptionId: Scalars['Int'];
  measurementValue: Scalars['Decimal'];
  measurementUnitId: Scalars['Int'];
};

export type StructuredProductDetailInput = {
  structuredProductDetailOptionId: Scalars['Int'];
  measurementValue: Scalars['Decimal'];
  measurementUnitId: Scalars['Int'];
};

export type StructuredProductDetailOption = {
  __typename?: 'StructuredProductDetailOption';
  id: Scalars['Int'];
  name: Scalars['String'];
  unitOptions: Array<MeasurementUnitOption>;
};

export type StyleAndSqft = {
  __typename?: 'StyleAndSqft';
  id: Scalars['Int'];
  style: Scalars['String'];
  sqft: Scalars['Decimal'];
};

export type StyleForJobPrice = {
  __typename?: 'StyleForJobPrice';
  id: Scalars['Int'];
  price: Scalars['Decimal'];
  cost: Scalars['Decimal'];
};

export type StylePromo = {
  __typename?: 'StylePromo';
  promoPrice?: Maybe<Scalars['Decimal']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type StylePromoInput = {
  promoPrice?: Maybe<Scalars['Decimal']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatMessageSent: ChatMessage;
  messageSentToChatParty: Scalars['Int'];
  chatMemberNewMessageStatusUpdated: Scalars['Boolean'];
  jobChatCommandCenterMemberChanged: ChatMember;
  removeChatFromDrawer: ChatRemoval;
  priceRequestUpdatedForJob: PriceRequest;
  priceRequestUpdatedForConfig: PriceRequest;
  overrideDiscountSent: OverrideDiscountAddedAction;
  priceOverrideSent: PriceOverride;
  jobConfigurationUpdated: JobConfiguration;
  jobConfigurationPriceUpdated: PreOverrideAndOverallPrice;
  jobConfigurationExistenceChanged: ExistentialJobConfigurationAction;
  jobSold: Scalars['Int'];
  jobQuoted: JobConfigurationIdAndQuoteId;
  discountUpdated: AppliedDiscountAction;
  promotionOnJobUpdated: PromotionOnJobAction;
  financingUpdated?: Maybe<Financing>;
  financingSlotChanged: FinancingOption;
};


export type SubscriptionChatMessageSentArgs = {
  chatId: Scalars['Int'];
};


export type SubscriptionMessageSentToChatPartyArgs = {
  recipientChatPartyId: Scalars['Int'];
};


export type SubscriptionChatMemberNewMessageStatusUpdatedArgs = {
  chatId: Scalars['Int'];
  chatPartyId: Scalars['Int'];
};


export type SubscriptionJobChatCommandCenterMemberChangedArgs = {
  jobId: Scalars['Int'];
};


export type SubscriptionPriceRequestUpdatedForJobArgs = {
  jobId: Scalars['Int'];
};


export type SubscriptionPriceRequestUpdatedForConfigArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type SubscriptionOverrideDiscountSentArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type SubscriptionPriceOverrideSentArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type SubscriptionJobConfigurationUpdatedArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type SubscriptionJobConfigurationPriceUpdatedArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type SubscriptionJobConfigurationExistenceChangedArgs = {
  jobId: Scalars['Int'];
};


export type SubscriptionJobSoldArgs = {
  jobId: Scalars['Int'];
};


export type SubscriptionJobQuotedArgs = {
  jobId: Scalars['Int'];
};


export type SubscriptionDiscountUpdatedArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type SubscriptionPromotionOnJobUpdatedArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type SubscriptionFinancingUpdatedArgs = {
  jobConfigurationId: Scalars['Int'];
};


export type SubscriptionFinancingSlotChangedArgs = {
  jobConfigurationId: Scalars['Int'];
};

export type SubstrateId = {
  __typename?: 'SubstrateId';
  id: Scalars['Int'];
  type: Scalars['String'];
};

export enum SurfaceType {
  Hard = 'HARD',
  Soft = 'SOFT'
}


export type TryRemoveLaborRulePayload = {
  __typename?: 'TryRemoveLaborRulePayload';
  isSuccessful: Scalars['Boolean'];
  message: Scalars['String'];
};

export type TypeDetailForBasePromotion = {
  __typename?: 'TypeDetailForBasePromotion';
  basePromotionId: Scalars['Int'];
  promotionType: PromotionType;
  isScalar: Scalars['Boolean'];
  amount: Scalars['Decimal'];
};

export type TypeDetailForBasePromotionInput = {
  basePromotionId: Scalars['Int'];
  promotionType: PromotionTypeInput;
  isScalar: Scalars['Boolean'];
  amount: Scalars['Decimal'];
};

export type UnstructuredProductDetail = {
  __typename?: 'UnstructuredProductDetail';
  unstructuredProductDetailOptionId: Scalars['Int'];
  value: Scalars['String'];
};

export type UnstructuredProductDetailInput = {
  unstructuredProductDetailOptionId: Scalars['Int'];
  value: Scalars['String'];
};

export type UnstructuredProductDetailOption = {
  __typename?: 'UnstructuredProductDetailOption';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdatedProductForAreaInput = {
  areaId: Scalars['Int'];
  productTypeId: Scalars['Int'];
  styleId?: Maybe<Scalars['Int']>;
};

export type UpdateLaborRuleInput = {
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Decimal']>;
  cost?: Maybe<Scalars['Decimal']>;
  minimum?: Maybe<Scalars['Decimal']>;
};

export type UpdateServiceProductOfferingPayload = {
  __typename?: 'UpdateServiceProductOfferingPayload';
  similar: Array<Scalars['Int']>;
  updated: ServiceProductOffering;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  role: Scalars['String'];
};

export type Vehicle = {
  __typename?: 'Vehicle';
  id: Scalars['Int'];
  description: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
  licensePlateNumber?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
};

export type VehicleInput = {
  id: Scalars['Int'];
  description: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
  licensePlateNumber?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
};

export type VendorName = {
  __typename?: 'VendorName';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type VendorSourcedProduct = {
  __typename?: 'VendorSourcedProduct';
  id: Scalars['Int'];
  vendorId: Scalars['Int'];
  productSpecificationId: Scalars['Int'];
  style: Scalars['String'];
  color: Scalars['String'];
  orderQuantity: Scalars['Decimal'];
  singleCostPerSqft: Scalars['Decimal'];
  bulkCostPerSqft: Scalars['Decimal'];
  oneTimeCostPerSqft?: Maybe<Scalars['Decimal']>;
};

export type VendorSourcedProductInput = {
  vendorId: Scalars['Int'];
  style: Scalars['String'];
  color: Scalars['String'];
  orderQuantity: Scalars['Decimal'];
  singleCostPerSqft: Scalars['Decimal'];
  bulkCostPerSqft: Scalars['Decimal'];
  oneTimeCostPerSqft?: Maybe<Scalars['Decimal']>;
};

export type W9 = {
  __typename?: 'W9';
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  activeDate: Scalars['DateTime'];
  expirationDate: Scalars['DateTime'];
  s3FileId?: Maybe<Scalars['Int']>;
};

export type W9Input = {
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  activeDate: Scalars['DateTime'];
  expirationDate: Scalars['DateTime'];
  s3FileId?: Maybe<Scalars['Int']>;
};

export type Worker = {
  __typename?: 'Worker';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  address?: Maybe<Address>;
  driversLicenseNumber?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Vehicle>;
  licenseNumber?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isCheckedIn: Scalars['Boolean'];
  individualChatParty?: Maybe<ChatParty>;
  isSalesperson: Scalars['Boolean'];
  isCommandCenterWorker: Scalars['Boolean'];
  profilePictureFileId?: Maybe<Scalars['Int']>;
  driversLicenseFiledId?: Maybe<Scalars['Int']>;
  licenseFileId?: Maybe<Scalars['Int']>;
};

export type WorkerBlockedTimeSlot = {
  __typename?: 'WorkerBlockedTimeSlot';
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  date: Scalars['DateTime'];
  startTime: Scalars['TimeSpan'];
  endTime: Scalars['TimeSpan'];
};

export type WorkerHistory = {
  __typename?: 'WorkerHistory';
  id: Scalars['Int'];
  dates: Array<WorkerHistoryDates>;
};

export type WorkerHistoryDates = {
  __typename?: 'WorkerHistoryDates';
  id: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
};

export type WorkerHistoryDatesInput = {
  id: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
};

export type WorkerHistoryInput = {
  id: Scalars['Int'];
  dates: Array<WorkerHistoryDatesInput>;
};

export type WorkersComp = {
  __typename?: 'WorkersComp';
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  activeDate: Scalars['DateTime'];
  expirationDate: Scalars['DateTime'];
  s3FileId?: Maybe<Scalars['Int']>;
};

export type WorkersCompInput = {
  id: Scalars['Int'];
  workerId: Scalars['Int'];
  activeDate: Scalars['DateTime'];
  expirationDate: Scalars['DateTime'];
  s3FileId?: Maybe<Scalars['Int']>;
};

export type WorkOrder = {
  __typename?: 'WorkOrder';
  installationAppointmentId: Scalars['Int'];
  hasFinancing: Scalars['Boolean'];
  remainingCod: Scalars['Decimal'];
  jobNotes?: Maybe<Scalars['String']>;
  installationNotes?: Maybe<Scalars['String']>;
  contractor: Contractor;
  dates: Array<Scalars['DateTime']>;
  areaBreakdowns: Array<AreaBreakdown>;
};

export type ZipAndCity = {
  __typename?: 'ZipAndCity';
  id: Scalars['Int'];
  zip: Scalars['String'];
  city: Scalars['String'];
  countyId?: Maybe<Scalars['Int']>;
  marketId?: Maybe<Scalars['Int']>;
  serviced: Scalars['Boolean'];
};

export type GetAllContractorRatingParameterSetsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllContractorRatingParameterSetsQuery = (
  { __typename?: 'Query' }
  & { allContractorRatingParameterSets: Array<(
    { __typename?: 'ContractorRatingParameters' }
    & Pick<ContractorRatingParameters, 'id' | 'numAppointments' | 'numServices' | 'startDate' | 'endDate' | 'calledInAmWeight' | 'checkedInWeight' | 'onTimeWeight' | 'collectedCodWeight' | 'completedJobWeight' | 'completionCertificateSignedWeight' | 'completionCertificateRatingWeight' | 'serviceRequestsWeight' | 'servicesCompletedOnTimeWeight'>
  )> }
);

export type SetContractorRatingParametersMutationVariables = Exact<{
  parameters: ContractorRatingParametersInput;
}>;


export type SetContractorRatingParametersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setContractorRatingParameters'>
);

export type GetAllDiscountsQueryVariables = Exact<{
  ignoreDeleted?: Maybe<Scalars['Boolean']>;
}>;


export type GetAllDiscountsQuery = (
  { __typename?: 'Query' }
  & { allDiscounts: Array<(
    { __typename?: 'Discount' }
    & DiscountFragment
  )> }
);

export type DiscountFragment = (
  { __typename?: 'Discount' }
  & Pick<Discount, 'id' | 'name' | 'isScalar' | 'defaultValue' | 'isHidden' | 'isDeleted' | 'minimumMSRP'>
);

export type DeleteDiscountMutationVariables = Exact<{
  discountId: Scalars['Int'];
}>;


export type DeleteDiscountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDiscount'>
);

export type UpdateDiscountVisibilityMutationVariables = Exact<{
  discountId: Scalars['Int'];
  isHidden: Scalars['Boolean'];
}>;


export type UpdateDiscountVisibilityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDiscountVisibility'>
);

export type UpsertDiscountMutationVariables = Exact<{
  discount: DiscountInput;
}>;


export type UpsertDiscountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertDiscount'>
);

export type GetFinancingTableDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFinancingTableDataQuery = (
  { __typename?: 'Query' }
  & { financingTypes: Array<(
    { __typename?: 'FinancingType' }
    & Pick<FinancingType, 'id' | 'name'>
  )>, financingTableOptions: Array<(
    { __typename?: 'FinancingTableRow' }
    & Pick<FinancingTableRow, 'id'>
    & { baseOption: (
      { __typename?: 'FinancingOption' }
      & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
    ), baseHistory: Array<(
      { __typename?: 'FinancingOption' }
      & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
    )>, activePromo?: Maybe<(
      { __typename?: 'FinancingOption' }
      & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
    )>, promoHistory: Array<(
      { __typename?: 'FinancingOption' }
      & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
    )> }
  )> }
);

export type UpsertFinancingOptionMutationVariables = Exact<{
  option: FinancingOptionInput;
}>;


export type UpsertFinancingOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertFinancingOption'>
);

export type CheckForPromotionConflictsQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  marketIds: Array<Scalars['Int']> | Scalars['Int'];
  scheduledIdToIgnore?: Maybe<Scalars['Int']>;
}>;


export type CheckForPromotionConflictsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkForPromotionConflicts'>
);

export type GetAllScheduledPromotionsQueryVariables = Exact<{
  excludeExpired?: Maybe<Scalars['Boolean']>;
  excludeCanceled?: Maybe<Scalars['Boolean']>;
}>;


export type GetAllScheduledPromotionsQuery = (
  { __typename?: 'Query' }
  & { allScheduledPromotions: Array<(
    { __typename?: 'ScheduledPromotion' }
    & Pick<ScheduledPromotion, 'id' | 'startDate' | 'endDate' | 'marketIds' | 'isCanceled'>
    & { basePromotion: (
      { __typename?: 'BasePromotion' }
      & Pick<BasePromotion, 'id' | 'name' | 'isDeleted'>
      & { promotionTypes: Array<(
        { __typename?: 'TypeDetailForBasePromotion' }
        & Pick<TypeDetailForBasePromotion, 'basePromotionId' | 'isScalar' | 'amount'>
        & { promotionType: (
          { __typename?: 'PromotionType' }
          & Pick<PromotionType, 'id' | 'label'>
        ) }
      )> }
    ) }
  )> }
);

export type GetBasePromotionsTableDataQueryVariables = Exact<{
  excludeDeleted?: Maybe<Scalars['Boolean']>;
}>;


export type GetBasePromotionsTableDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'basePromotionIdsForActiveOrUpcoming'>
  & { promotionTypes: Array<(
    { __typename?: 'PromotionType' }
    & Pick<PromotionType, 'id' | 'label'>
  )>, allBasePromotions: Array<(
    { __typename?: 'BasePromotion' }
    & Pick<BasePromotion, 'id' | 'name' | 'isDeleted'>
    & { promotionTypes: Array<(
      { __typename?: 'TypeDetailForBasePromotion' }
      & Pick<TypeDetailForBasePromotion, 'basePromotionId' | 'isScalar' | 'amount'>
      & { promotionType: (
        { __typename?: 'PromotionType' }
        & Pick<PromotionType, 'id' | 'label'>
      ) }
    )> }
  )> }
);

export type GetPromotionOptionQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetPromotionOptionQuery = (
  { __typename?: 'Query' }
  & { promotionOption?: Maybe<(
    { __typename?: 'BasePromotion' }
    & Pick<BasePromotion, 'id' | 'name' | 'isDeleted'>
    & { promotionTypes: Array<(
      { __typename?: 'TypeDetailForBasePromotion' }
      & Pick<TypeDetailForBasePromotion, 'basePromotionId' | 'isScalar' | 'amount'>
      & { promotionType: (
        { __typename?: 'PromotionType' }
        & Pick<PromotionType, 'id' | 'label'>
      ) }
    )> }
  )> }
);

export type AddScheduledPromotionMutationVariables = Exact<{
  basePromotionId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  marketIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddScheduledPromotionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addScheduledPromotion'>
);

export type UpsertBasePromotionMutationVariables = Exact<{
  promotion: BasePromotionInput;
}>;


export type UpsertBasePromotionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertBasePromotion'>
);

export type CancelScheduledPromotionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CancelScheduledPromotionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelScheduledPromotion'>
);

export type DeleteBasePromotionMutationVariables = Exact<{
  basePromotionId: Scalars['Int'];
}>;


export type DeleteBasePromotionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBasePromotion'>
);

export type UpdateAppliedPromotionMutationVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  basePromotionId?: Maybe<Scalars['Int']>;
}>;


export type UpdateAppliedPromotionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAppliedPromotion'>
);

export type UpdateScheduledPromotionMutationVariables = Exact<{
  scheduledPromotionId: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateScheduledPromotionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateScheduledPromotion'>
);

export type UpdateWhichMarketsForScheduledPromotionMutationVariables = Exact<{
  scheduledPromotionId: Scalars['Int'];
  addedMarketIds: Array<Scalars['Int']> | Scalars['Int'];
  removedMarketIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type UpdateWhichMarketsForScheduledPromotionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWhichMarketsForScheduledPromotion'>
);

export type GetAllCallCenterJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCallCenterJobsQuery = (
  { __typename?: 'Query' }
  & { allCallCenterJobs: Array<(
    { __typename?: 'CallCenterJob' }
    & Pick<CallCenterJob, 'id' | 'creationTime' | 'minutesFromCreation' | 'prospectNotes' | 'customerId' | 'customerFirstName' | 'customerLastName' | 'customerCity' | 'customerEmail' | 'customerPhone' | 'callBackTime' | 'isQualified' | 'isInRecovery' | 'dateEnteredRecovery'>
    & { callCenterWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email'>
    )>, appointment?: Maybe<(
      { __typename?: 'CallCenterSahAppointment' }
      & Pick<CallCenterSahAppointment, 'id' | 'appointmentNumber' | 'scheduledDate' | 'timeReminderSent' | 'timeToSendReminder' | 'remindToday' | 'isCancelled'>
    )>, lastAction?: Maybe<(
      { __typename?: 'CallCenterAction' }
      & Pick<CallCenterAction, 'id' | 'callCenterActionOptionId' | 'callCenterActionOptionLabel' | 'notes' | 'date' | 'createdByWorkerId'>
    )>, recoveryCallWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type GetCallCenterJobQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type GetCallCenterJobQuery = (
  { __typename?: 'Query' }
  & { callCenterJob: (
    { __typename?: 'CallCenterJob' }
    & Pick<CallCenterJob, 'id' | 'creationTime' | 'minutesFromCreation' | 'prospectNotes' | 'customerId' | 'customerFirstName' | 'customerLastName' | 'customerCity' | 'customerEmail' | 'customerPhone' | 'callBackTime' | 'isQualified' | 'isInRecovery' | 'dateEnteredRecovery'>
    & { callCenterWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email'>
    )>, appointment?: Maybe<(
      { __typename?: 'CallCenterSahAppointment' }
      & Pick<CallCenterSahAppointment, 'id' | 'appointmentNumber' | 'scheduledDate' | 'timeReminderSent' | 'timeToSendReminder' | 'remindToday' | 'isCancelled' | 'notes'>
    )>, lastAction?: Maybe<(
      { __typename?: 'CallCenterAction' }
      & Pick<CallCenterAction, 'id' | 'callCenterActionOptionId' | 'callCenterActionOptionLabel' | 'notes' | 'date' | 'createdByWorkerId'>
    )>, recoveryCallWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email'>
    )> }
  ) }
);

export type GetCallCenterScriptsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCallCenterScriptsQuery = (
  { __typename?: 'Query' }
  & { callCenterScripts: Array<(
    { __typename?: 'CallCenterScript' }
    & Pick<CallCenterScript, 'id' | 'name' | 'text' | 'lastUpdated'>
  )> }
);

export type AddCallCenterActionMutationVariables = Exact<{
  jobId: Scalars['Int'];
  callCenterOptionId: Scalars['Int'];
  notes: Scalars['String'];
}>;


export type AddCallCenterActionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCallCenterAction'>
);

export type DeleteCallCenterScriptMutationVariables = Exact<{
  scriptId: Scalars['Int'];
}>;


export type DeleteCallCenterScriptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCallCenterScript'>
);

export type SetCallCenterCallbackMutationVariables = Exact<{
  jobId: Scalars['Int'];
  callBackTime?: Maybe<Scalars['DateTime']>;
}>;


export type SetCallCenterCallbackMutation = (
  { __typename?: 'Mutation' }
  & { setCallCenterCallback: (
    { __typename?: 'CallCenterJob' }
    & Pick<CallCenterJob, 'id' | 'creationTime' | 'minutesFromCreation' | 'prospectNotes' | 'customerId' | 'customerFirstName' | 'customerLastName' | 'customerCity' | 'customerEmail' | 'customerPhone' | 'callBackTime' | 'isQualified' | 'isInRecovery' | 'dateEnteredRecovery'>
    & { callCenterWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
      & { individualChatParty?: Maybe<(
        { __typename?: 'ChatParty' }
        & Pick<ChatParty, 'id' | 'name'>
      )> }
    )>, appointment?: Maybe<(
      { __typename?: 'CallCenterSahAppointment' }
      & Pick<CallCenterSahAppointment, 'id' | 'appointmentNumber' | 'scheduledDate' | 'timeReminderSent' | 'timeToSendReminder' | 'remindToday' | 'isCancelled'>
    )>, lastAction?: Maybe<(
      { __typename?: 'CallCenterAction' }
      & Pick<CallCenterAction, 'id' | 'callCenterActionOptionId' | 'callCenterActionOptionLabel' | 'notes' | 'date' | 'createdByWorkerId'>
    )>, recoveryCallWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
    )> }
  ) }
);

export type SetCallCenterCanceledByCustomerMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
  callbackDate: Scalars['DateTime'];
  notes: Scalars['String'];
}>;


export type SetCallCenterCanceledByCustomerMutation = (
  { __typename?: 'Mutation' }
  & { setCallCenterCanceledByCustomer: (
    { __typename?: 'CallCenterJob' }
    & Pick<CallCenterJob, 'id' | 'creationTime' | 'minutesFromCreation' | 'prospectNotes' | 'customerId' | 'customerFirstName' | 'customerLastName' | 'customerCity' | 'customerEmail' | 'customerPhone' | 'callBackTime' | 'isQualified' | 'isInRecovery' | 'dateEnteredRecovery'>
    & { callCenterWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
      & { individualChatParty?: Maybe<(
        { __typename?: 'ChatParty' }
        & Pick<ChatParty, 'id' | 'name'>
      )> }
    )>, appointment?: Maybe<(
      { __typename?: 'CallCenterSahAppointment' }
      & Pick<CallCenterSahAppointment, 'id' | 'appointmentNumber' | 'scheduledDate' | 'timeReminderSent' | 'timeToSendReminder' | 'remindToday' | 'isCancelled'>
    )>, lastAction?: Maybe<(
      { __typename?: 'CallCenterAction' }
      & Pick<CallCenterAction, 'id' | 'callCenterActionOptionId' | 'callCenterActionOptionLabel' | 'notes' | 'date' | 'createdByWorkerId'>
    )>, recoveryCallWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
    )> }
  ) }
);

export type SetCallCenterClaimMutationVariables = Exact<{
  jobId: Scalars['Int'];
  isClaimed: Scalars['Boolean'];
}>;


export type SetCallCenterClaimMutation = (
  { __typename?: 'Mutation' }
  & { setCallCenterClaim: (
    { __typename?: 'CallCenterJob' }
    & Pick<CallCenterJob, 'id' | 'creationTime' | 'minutesFromCreation' | 'prospectNotes' | 'customerId' | 'customerFirstName' | 'customerLastName' | 'customerCity' | 'customerEmail' | 'customerPhone' | 'callBackTime' | 'isQualified' | 'isInRecovery' | 'dateEnteredRecovery'>
    & { callCenterWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
      & { individualChatParty?: Maybe<(
        { __typename?: 'ChatParty' }
        & Pick<ChatParty, 'id' | 'name'>
      )> }
    )>, appointment?: Maybe<(
      { __typename?: 'CallCenterSahAppointment' }
      & Pick<CallCenterSahAppointment, 'id' | 'appointmentNumber' | 'scheduledDate' | 'timeReminderSent' | 'timeToSendReminder' | 'remindToday' | 'isCancelled'>
    )>, lastAction?: Maybe<(
      { __typename?: 'CallCenterAction' }
      & Pick<CallCenterAction, 'id' | 'callCenterActionOptionId' | 'callCenterActionOptionLabel' | 'notes' | 'date' | 'createdByWorkerId'>
    )>, recoveryCallWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
    )> }
  ) }
);

export type SetCallCenterNotInterestedMutationVariables = Exact<{
  jobId: Scalars['Int'];
  callbackDate: Scalars['DateTime'];
  notes: Scalars['String'];
}>;


export type SetCallCenterNotInterestedMutation = (
  { __typename?: 'Mutation' }
  & { setCallCenterNotInterested: (
    { __typename?: 'CallCenterJob' }
    & Pick<CallCenterJob, 'id' | 'creationTime' | 'minutesFromCreation' | 'prospectNotes' | 'customerId' | 'customerFirstName' | 'customerLastName' | 'customerCity' | 'customerEmail' | 'customerPhone' | 'callBackTime' | 'isQualified' | 'isInRecovery' | 'dateEnteredRecovery'>
    & { callCenterWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
      & { individualChatParty?: Maybe<(
        { __typename?: 'ChatParty' }
        & Pick<ChatParty, 'id' | 'name'>
      )> }
    )>, appointment?: Maybe<(
      { __typename?: 'CallCenterSahAppointment' }
      & Pick<CallCenterSahAppointment, 'id' | 'appointmentNumber' | 'scheduledDate' | 'timeReminderSent' | 'timeToSendReminder' | 'remindToday' | 'isCancelled'>
    )>, lastAction?: Maybe<(
      { __typename?: 'CallCenterAction' }
      & Pick<CallCenterAction, 'id' | 'callCenterActionOptionId' | 'callCenterActionOptionLabel' | 'notes' | 'date' | 'createdByWorkerId'>
    )>, recoveryCallWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
    )> }
  ) }
);

export type SetCallCenterQualificationMutationVariables = Exact<{
  jobId: Scalars['Int'];
  isQualified: Scalars['Boolean'];
  remindCustomerDate: Scalars['DateTime'];
}>;


export type SetCallCenterQualificationMutation = (
  { __typename?: 'Mutation' }
  & { setCallCenterQualification: (
    { __typename?: 'CallCenterJob' }
    & Pick<CallCenterJob, 'id' | 'creationTime' | 'minutesFromCreation' | 'prospectNotes' | 'customerId' | 'customerFirstName' | 'customerLastName' | 'customerCity' | 'customerEmail' | 'customerPhone' | 'callBackTime' | 'isQualified' | 'isInRecovery' | 'dateEnteredRecovery'>
    & { callCenterWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
      & { individualChatParty?: Maybe<(
        { __typename?: 'ChatParty' }
        & Pick<ChatParty, 'id' | 'name'>
      )> }
    )>, appointment?: Maybe<(
      { __typename?: 'CallCenterSahAppointment' }
      & Pick<CallCenterSahAppointment, 'id' | 'appointmentNumber' | 'scheduledDate' | 'timeReminderSent' | 'timeToSendReminder' | 'remindToday' | 'isCancelled'>
    )>, lastAction?: Maybe<(
      { __typename?: 'CallCenterAction' }
      & Pick<CallCenterAction, 'id' | 'callCenterActionOptionId' | 'callCenterActionOptionLabel' | 'notes' | 'date' | 'createdByWorkerId'>
    )>, recoveryCallWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
    )> }
  ) }
);

export type SetCallCenterRecoveryClaimMutationVariables = Exact<{
  jobId: Scalars['Int'];
  claimedRecoveryWorkerId?: Maybe<Scalars['Int']>;
}>;


export type SetCallCenterRecoveryClaimMutation = (
  { __typename?: 'Mutation' }
  & { setCallCenterRecoveryClaim: (
    { __typename?: 'CallCenterJob' }
    & Pick<CallCenterJob, 'id' | 'creationTime' | 'minutesFromCreation' | 'prospectNotes' | 'customerId' | 'customerFirstName' | 'customerLastName' | 'customerCity' | 'customerEmail' | 'customerPhone' | 'callBackTime' | 'isQualified' | 'isInRecovery' | 'dateEnteredRecovery'>
    & { callCenterWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
      & { individualChatParty?: Maybe<(
        { __typename?: 'ChatParty' }
        & Pick<ChatParty, 'id' | 'name'>
      )> }
    )>, appointment?: Maybe<(
      { __typename?: 'CallCenterSahAppointment' }
      & Pick<CallCenterSahAppointment, 'id' | 'appointmentNumber' | 'scheduledDate' | 'timeReminderSent' | 'timeToSendReminder' | 'remindToday' | 'isCancelled'>
    )>, lastAction?: Maybe<(
      { __typename?: 'CallCenterAction' }
      & Pick<CallCenterAction, 'id' | 'callCenterActionOptionId' | 'callCenterActionOptionLabel' | 'notes' | 'date' | 'createdByWorkerId'>
    )>, recoveryCallWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
    )> }
  ) }
);

export type SetCallCenterReminderSentMutationVariables = Exact<{
  sahAppointmentId: Scalars['Int'];
}>;


export type SetCallCenterReminderSentMutation = (
  { __typename?: 'Mutation' }
  & { setCallCenterReminderSent: (
    { __typename?: 'CallCenterJob' }
    & Pick<CallCenterJob, 'id' | 'creationTime' | 'minutesFromCreation' | 'prospectNotes' | 'customerId' | 'customerFirstName' | 'customerLastName' | 'customerCity' | 'customerEmail' | 'customerPhone' | 'callBackTime' | 'isQualified' | 'isInRecovery' | 'dateEnteredRecovery'>
    & { callCenterWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
      & { individualChatParty?: Maybe<(
        { __typename?: 'ChatParty' }
        & Pick<ChatParty, 'id' | 'name'>
      )> }
    )>, appointment?: Maybe<(
      { __typename?: 'CallCenterSahAppointment' }
      & Pick<CallCenterSahAppointment, 'id' | 'appointmentNumber' | 'scheduledDate' | 'timeReminderSent' | 'timeToSendReminder' | 'remindToday' | 'isCancelled'>
    )>, lastAction?: Maybe<(
      { __typename?: 'CallCenterAction' }
      & Pick<CallCenterAction, 'id' | 'callCenterActionOptionId' | 'callCenterActionOptionLabel' | 'notes' | 'date' | 'createdByWorkerId'>
    )>, recoveryCallWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
    )> }
  ) }
);

export type UpdateCallCenterActionMutationVariables = Exact<{
  callCenterActionId: Scalars['Int'];
  notes: Scalars['String'];
}>;


export type UpdateCallCenterActionMutation = (
  { __typename?: 'Mutation' }
  & { updateCallCenterAction: (
    { __typename?: 'CallCenterAction' }
    & Pick<CallCenterAction, 'id' | 'callCenterActionOptionId' | 'callCenterActionOptionLabel' | 'notes' | 'date' | 'createdByWorkerId'>
  ) }
);

export type UpdateCallCenterProspectNotesMutationVariables = Exact<{
  jobId: Scalars['Int'];
  prospectNotes: Scalars['String'];
}>;


export type UpdateCallCenterProspectNotesMutation = (
  { __typename?: 'Mutation' }
  & { updateCallCenterProspectNotes: (
    { __typename?: 'CallCenterJob' }
    & Pick<CallCenterJob, 'id' | 'creationTime' | 'minutesFromCreation' | 'prospectNotes' | 'customerId' | 'customerFirstName' | 'customerLastName' | 'customerCity' | 'customerEmail' | 'customerPhone' | 'callBackTime' | 'isQualified' | 'isInRecovery' | 'dateEnteredRecovery'>
    & { callCenterWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
      & { individualChatParty?: Maybe<(
        { __typename?: 'ChatParty' }
        & Pick<ChatParty, 'id' | 'name'>
      )> }
    )>, appointment?: Maybe<(
      { __typename?: 'CallCenterSahAppointment' }
      & Pick<CallCenterSahAppointment, 'id' | 'appointmentNumber' | 'scheduledDate' | 'timeReminderSent' | 'timeToSendReminder' | 'remindToday' | 'isCancelled'>
    )>, lastAction?: Maybe<(
      { __typename?: 'CallCenterAction' }
      & Pick<CallCenterAction, 'id' | 'callCenterActionOptionId' | 'callCenterActionOptionLabel' | 'notes' | 'date' | 'createdByWorkerId'>
    )>, recoveryCallWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'isSalesperson' | 'isCommandCenterWorker'>
    )> }
  ) }
);

export type UpsertCallCenterScriptMutationVariables = Exact<{
  script: CallCenterScriptInput;
}>;


export type UpsertCallCenterScriptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertCallCenterScript'>
);

export type GetChatQueryVariables = Exact<{
  chatId: Scalars['Int'];
}>;


export type GetChatQuery = (
  { __typename?: 'Query' }
  & { chatMessages: (
    { __typename?: 'ChatAndMessages' }
    & Pick<ChatAndMessages, 'id'>
    & { messages: Array<(
      { __typename?: 'ChatMessage' }
      & Pick<ChatMessage, 'id' | 'chatId' | 'sendingPartyId' | 'sendingWorkerId' | 'senderFirstName' | 'senderLastName' | 'text' | 'timeSent' | 'received'>
    )> }
  ), chatMembers: Array<(
    { __typename?: 'ChatMember' }
    & ChatMemberFragment
  )> }
);

export type GetChatForJobQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type GetChatForJobQuery = (
  { __typename?: 'Query' }
  & { chatForJob: (
    { __typename?: 'ChatForJob' }
    & ChatForJobFragment
  ) }
);

export type GetChatForJobFromChatQueryVariables = Exact<{
  chatId: Scalars['Int'];
}>;


export type GetChatForJobFromChatQuery = (
  { __typename?: 'Query' }
  & { chatForJobFromChat: (
    { __typename?: 'ChatForJob' }
    & ChatForJobFragment
  ) }
);

export type GetCommandCenterChatPartyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCommandCenterChatPartyQuery = (
  { __typename?: 'Query' }
  & { commandCenterChatParty: (
    { __typename?: 'ChatParty' }
    & Pick<ChatParty, 'id' | 'name'>
  ) }
);

export type GetDefaultDisplayedJobChatsQueryVariables = Exact<{
  date: Scalars['DateTime'];
  marketId: Scalars['Int'];
  commandCenterWorkerId: Scalars['Int'];
}>;


export type GetDefaultDisplayedJobChatsQuery = (
  { __typename?: 'Query' }
  & { defaultDisplayedJobChats: Array<(
    { __typename?: 'ChatForJob' }
    & ChatForJobFragment
  )> }
);

export type GetJobIdForChatQueryVariables = Exact<{
  chatId: Scalars['Int'];
}>;


export type GetJobIdForChatQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'jobIdForChat'>
);

export type ChatForJobFragment = (
  { __typename?: 'ChatForJob' }
  & Pick<ChatForJob, 'id' | 'chatId' | 'customerName'>
  & { jobMember: (
    { __typename?: 'ChatMember' }
    & ChatMemberFragment
  ), commandCenterMember: (
    { __typename?: 'ChatMember' }
    & ChatMemberFragment
  ) }
);

export type ChatMemberFragment = (
  { __typename?: 'ChatMember' }
  & Pick<ChatMember, 'chatPartyId' | 'chatId' | 'partyName' | 'hasNewMessage'>
);

export type ClearNewMessageStatusMutationVariables = Exact<{
  chatId: Scalars['Int'];
  partyId: Scalars['Int'];
}>;


export type ClearNewMessageStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clearNewMessageStatus'>
);

export type SendMessageMutationVariables = Exact<{
  message: ChatMessageInput;
}>;


export type SendMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendMessage'>
);

export type ChatMemberNewMessageStatusUpdatedSubscriptionVariables = Exact<{
  chatId: Scalars['Int'];
  chatPartyId: Scalars['Int'];
}>;


export type ChatMemberNewMessageStatusUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'chatMemberNewMessageStatusUpdated'>
);

export type JobChatCommandCenterMemberChangedSubscriptionVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type JobChatCommandCenterMemberChangedSubscription = (
  { __typename?: 'Subscription' }
  & { jobChatCommandCenterMemberChanged: (
    { __typename?: 'ChatMember' }
    & ChatMemberFragment
  ) }
);

export type ChatMessageSentSubscriptionVariables = Exact<{
  chatId: Scalars['Int'];
}>;


export type ChatMessageSentSubscription = (
  { __typename?: 'Subscription' }
  & { chatMessageSent: (
    { __typename?: 'ChatMessage' }
    & Pick<ChatMessage, 'id' | 'chatId' | 'sendingPartyId' | 'sendingWorkerId' | 'senderFirstName' | 'senderLastName' | 'text' | 'timeSent' | 'received'>
  ) }
);

export type MessageSentToChatPartySubscriptionVariables = Exact<{
  recipientChatPartyId: Scalars['Int'];
}>;


export type MessageSentToChatPartySubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'messageSentToChatParty'>
);

export type RemoveChatFromDrawerSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type RemoveChatFromDrawerSubscription = (
  { __typename?: 'Subscription' }
  & { removeChatFromDrawer: (
    { __typename?: 'ChatRemoval' }
    & Pick<ChatRemoval, 'id' | 'retainForCommandCenterWorkerId'>
  ) }
);

export type GetPricingCenterTabsQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetPricingCenterTabsQuery = (
  { __typename?: 'Query' }
  & { pricingCenterTabs: (
    { __typename?: 'PricingCenterTabs' }
    & Pick<PricingCenterTabs, 'id' | 'contractConfigurationId'>
    & { tabData: Array<(
      { __typename?: 'JobConfigurationIdAndOptionNumber' }
      & Pick<JobConfigurationIdAndOptionNumber, 'id' | 'optionNumber'>
    )>, quotedConfigurationAndQuoteIds: Array<(
      { __typename?: 'JobConfigurationIdAndQuoteId' }
      & Pick<JobConfigurationIdAndQuoteId, 'id' | 'quoteId'>
    )> }
  ) }
);

export type GetPricingSummaryQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetPricingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalPriceBeforeOverride' | 'jobConfigurationCost'>
  & { currentPriceOverride?: Maybe<(
    { __typename?: 'PriceOverride' }
    & Pick<PriceOverride, 'id' | 'jobConfigurationId' | 'amount' | 'commissionRate' | 'authorizationCode' | 'commandCenterWorkerId' | 'timeSent' | 'isCurrent'>
  )>, jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id'>
    & { price?: Maybe<(
      { __typename?: 'Price' }
      & Pick<Price, 'mSRP' | 'savings' | 'total'>
    )>, priceRequest?: Maybe<(
      { __typename?: 'PriceRequest' }
      & Pick<PriceRequest, 'id' | 'jobConfigurationId' | 'amount' | 'salespersonId' | 'commandCenterWorkerId' | 'timeRequested' | 'timeResponded' | 'granted'>
    )> }
  ), currentCommissionRates: Array<(
    { __typename?: 'CommissionRate' }
    & Pick<CommissionRate, 'id' | 'lowerMargin' | 'upperMargin' | 'rate' | 'creationDate' | 'archiveDate'>
  )> }
);

export type GetPriceRequestQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetPriceRequestQuery = (
  { __typename?: 'Query' }
  & { priceRequest?: Maybe<(
    { __typename?: 'PriceRequest' }
    & Pick<PriceRequest, 'id' | 'jobConfigurationId' | 'amount' | 'salespersonId' | 'commandCenterWorkerId' | 'timeRequested' | 'timeResponded' | 'granted'>
  )> }
);

export type GetPriceRequestsForJobQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type GetPriceRequestsForJobQuery = (
  { __typename?: 'Query' }
  & { priceRequestsForJob: Array<(
    { __typename?: 'PriceRequest' }
    & Pick<PriceRequest, 'id' | 'jobConfigurationId' | 'amount' | 'salespersonId' | 'commandCenterWorkerId' | 'timeRequested' | 'timeResponded' | 'granted'>
  )> }
);

export type ClaimAllPriceRequestsForJobMutationVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type ClaimAllPriceRequestsForJobMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'claimAllPriceRequestsForJob'>
);

export type RecordPriceRequestMutationVariables = Exact<{
  chatId: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  amount: Scalars['Decimal'];
  salespersonId: Scalars['Int'];
}>;


export type RecordPriceRequestMutation = (
  { __typename?: 'Mutation' }
  & { recordPriceRequest?: Maybe<(
    { __typename?: 'PriceRequest' }
    & Pick<PriceRequest, 'id' | 'jobConfigurationId' | 'salespersonId' | 'commandCenterWorkerId' | 'amount'>
  )> }
);

export type SendPriceOverrideMutationVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  amount: Scalars['Decimal'];
  commissionRate: Scalars['Decimal'];
}>;


export type SendPriceOverrideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendPriceOverride'>
);

export type SendPriceRequestMutationVariables = Exact<{
  chatId: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
  amount: Scalars['Decimal'];
}>;


export type SendPriceRequestMutation = (
  { __typename?: 'Mutation' }
  & { sendPriceRequest: (
    { __typename?: 'PriceRequest' }
    & Pick<PriceRequest, 'id' | 'jobConfigurationId' | 'amount' | 'salespersonId' | 'commandCenterWorkerId' | 'timeRequested' | 'timeResponded' | 'granted'>
  ) }
);

export type OverrideDiscountSentSubscriptionVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type OverrideDiscountSentSubscription = (
  { __typename?: 'Subscription' }
  & { overrideDiscountSent: (
    { __typename?: 'OverrideDiscountAddedAction' }
    & Pick<OverrideDiscountAddedAction, 'id'>
    & { updatedPrice: (
      { __typename?: 'Price' }
      & Pick<Price, 'mSRP' | 'savings' | 'total'>
    ), overrideDiscount: (
      { __typename?: 'AppliedDiscount' }
      & Pick<AppliedDiscount, 'id' | 'amount' | 'discountId' | 'authorizationCode' | 'isScalar' | 'isValid' | 'jobConfigurationId' | 'name'>
    ) }
  ) }
);

export type PriceOverrideSentSubscriptionVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type PriceOverrideSentSubscription = (
  { __typename?: 'Subscription' }
  & { priceOverrideSent: (
    { __typename?: 'PriceOverride' }
    & Pick<PriceOverride, 'id' | 'jobConfigurationId' | 'amount' | 'commissionRate' | 'authorizationCode' | 'commandCenterWorkerId' | 'timeSent' | 'isCurrent'>
  ) }
);

export type PriceRequestUpdatedForConfigSubscriptionVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type PriceRequestUpdatedForConfigSubscription = (
  { __typename?: 'Subscription' }
  & { priceRequestUpdatedForConfig: (
    { __typename?: 'PriceRequest' }
    & Pick<PriceRequest, 'id' | 'jobConfigurationId' | 'amount' | 'salespersonId' | 'commandCenterWorkerId' | 'timeRequested' | 'timeResponded' | 'granted'>
  ) }
);

export type PriceRequestUpdatedForJobSubscriptionVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type PriceRequestUpdatedForJobSubscription = (
  { __typename?: 'Subscription' }
  & { priceRequestUpdatedForJob: (
    { __typename?: 'PriceRequest' }
    & Pick<PriceRequest, 'id' | 'jobConfigurationId' | 'amount' | 'salespersonId' | 'commandCenterWorkerId' | 'timeRequested' | 'timeResponded' | 'granted'>
  ) }
);

export type JobConfigurationExistenceChangedSubscriptionVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type JobConfigurationExistenceChangedSubscription = (
  { __typename?: 'Subscription' }
  & { jobConfigurationExistenceChanged: (
    { __typename?: 'ExistentialJobConfigurationAction' }
    & Pick<ExistentialJobConfigurationAction, 'id' | 'optionNumber' | 'isDeletion'>
  ) }
);

export type JobQuotedSubscriptionVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type JobQuotedSubscription = (
  { __typename?: 'Subscription' }
  & { jobQuoted: (
    { __typename?: 'JobConfigurationIdAndQuoteId' }
    & Pick<JobConfigurationIdAndQuoteId, 'id' | 'quoteId'>
  ) }
);

export type JobSoldSubscriptionVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type JobSoldSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'jobSold'>
);

export type AddFinancingOverrideMutationVariables = Exact<{
  idOfOptionToReplace?: Maybe<Scalars['Int']>;
  financingOverride: FinancingOverrideInput;
}>;


export type AddFinancingOverrideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addFinancingOverride'>
);

export type GetCompletionCertificateQueryVariables = Exact<{
  installationAppointmentId: Scalars['Int'];
}>;


export type GetCompletionCertificateQuery = (
  { __typename?: 'Query' }
  & { completionCertificate?: Maybe<(
    { __typename?: 'CompletionCertificate' }
    & Pick<CompletionCertificate, 'id' | 'question1Rating' | 'overallRating' | 'comments' | 'signingDate'>
  )> }
);

export type GetCompletionCertificatePdfQueryVariables = Exact<{
  installationAppointmentId: Scalars['Int'];
}>;


export type GetCompletionCertificatePdfQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'completionCertificatePDF'>
);

export type GetDaysContractorIsBusyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDaysContractorIsBusyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'daysContractorIsBusy'>
);

export type GetInstallationAppointmentForContractorQueryVariables = Exact<{
  installationAppointmentId: Scalars['Int'];
}>;


export type GetInstallationAppointmentForContractorQuery = (
  { __typename?: 'Query' }
  & { installationAppointmentForContractor: (
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  ) }
);

export type GetInstallationAppointmentForContractorAfterDateQueryVariables = Exact<{
  contractorId: Scalars['Int'];
  afterDate: Scalars['DateTime'];
}>;


export type GetInstallationAppointmentForContractorAfterDateQuery = (
  { __typename?: 'Query' }
  & { installationAppointmentForContractorAfterDate: Array<(
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  )> }
);

export type GetPartialServiceOrdersForContractorQueryVariables = Exact<{
  contractorId: Scalars['Int'];
}>;


export type GetPartialServiceOrdersForContractorQuery = (
  { __typename?: 'Query' }
  & { partialServiceOrdersForContractor: Array<(
    { __typename?: 'ServiceOrder' }
    & Pick<ServiceOrder, 'id' | 'jobContractId' | 'serviceOrderTypeId' | 'serviceDescription' | 'calledInDate' | 'scheduledDate' | 'scheduleAsap' | 'completionDate' | 'vendorId' | 'contractorId' | 'comments' | 'additionalActionRequired' | 'additionalActionDescription' | 'chargeAmount'>
  )> }
);

export type GetTempCompletionCertificateDataQueryVariables = Exact<{
  installationAppointmentId: Scalars['Int'];
}>;


export type GetTempCompletionCertificateDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'tempCompletionCertificateData'>
);

export type MarkInstallationAppointmentCompleteMutationVariables = Exact<{
  installationAppointmentId: Scalars['Int'];
}>;


export type MarkInstallationAppointmentCompleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markInstallationAppointmentComplete'>
);

export type CallCustomerForInstallationAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
}>;


export type CallCustomerForInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { callCustomerForInstallationAppointment: (
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  ) }
);

export type CheckInToInstallationAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
}>;


export type CheckInToInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { checkInToInstallationAppointment: (
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  ) }
);

export type CheckOutFromInstallationAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
}>;


export type CheckOutFromInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { checkOutFromInstallationAppointment: (
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  ) }
);

export type CollectCodForInstallationAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
}>;


export type CollectCodForInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { collectCODForInstallationAppointment: (
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  ) }
);

export type MappedToCustomerForInstallationAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
}>;


export type MappedToCustomerForInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { mappedToCustomerForInstallationAppointment: (
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  ) }
);

export type PickedUpMaterialsForInstallationAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
}>;


export type PickedUpMaterialsForInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { pickedUpMaterialsForInstallationAppointment: (
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  ) }
);

export type SubmitCompletionCertificateMutationVariables = Exact<{
  installationAppointmentId: Scalars['Int'];
  question1Rating: Scalars['Int'];
  overallExperience: Scalars['Int'];
  comments?: Maybe<Scalars['String']>;
  signatureBase64: Scalars['String'];
}>;


export type SubmitCompletionCertificateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitCompletionCertificate'>
);

export type UpdateEstimatedArrivalTimeForInstallationAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
  eta?: Maybe<Scalars['TimeSpan']>;
  didCustomerAnswer?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateEstimatedArrivalTimeForInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { updateEstimatedArrivalTimeForInstallationAppointment: (
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  ) }
);

export type GetContractProgressQueryVariables = Exact<{
  jobContractId: Scalars['Int'];
}>;


export type GetContractProgressQuery = (
  { __typename?: 'Query' }
  & { contractProgress: (
    { __typename?: 'ContractProgress' }
    & Pick<ContractProgress, 'id' | 'emailed' | 'stored'>
  ) }
);

export type GetJobCodQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetJobCodQuery = (
  { __typename?: 'Query' }
  & { jobCOD: (
    { __typename?: 'ContractCODData' }
    & Pick<ContractCodData, 'id' | 'remaining' | 'remainingToSchedule' | 'isFinancing'>
  ) }
);

export type GetJobContractQueryVariables = Exact<{
  jobContractId: Scalars['Int'];
}>;


export type GetJobContractQuery = (
  { __typename?: 'Query' }
  & { jobContract: (
    { __typename?: 'JobContract' }
    & Pick<JobContract, 'id' | 'jobConfigurationId' | 'totalDepositPaid' | 'signingDate' | 'isCancelled' | 'totalPrice' | 'sAHNumber'>
  ) }
);

export type GetJobContractPdfS3FileQueryVariables = Exact<{
  jobContractId: Scalars['Int'];
}>;


export type GetJobContractPdfS3FileQuery = (
  { __typename?: 'Query' }
  & { jobContractPdfS3File?: Maybe<(
    { __typename?: 'S3File' }
    & S3FileFragment
  )> }
);

export type GetJobContractRevisionIdsQueryVariables = Exact<{
  jobContractId: Scalars['Int'];
}>;


export type GetJobContractRevisionIdsQuery = (
  { __typename?: 'Query' }
  & { jobContractRevisionIds: (
    { __typename?: 'JobContractHistory' }
    & Pick<JobContractHistory, 'id' | 'jobConfigurationIds'>
  ) }
);

export type GetJobActiveContractIdQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type GetJobActiveContractIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'jobActiveContractId'>
);

export type GetJobPaymentsQueryVariables = Exact<{
  jobContractId: Scalars['Int'];
}>;


export type GetJobPaymentsQuery = (
  { __typename?: 'Query' }
  & { jobPayments: Array<(
    { __typename?: 'JobContractPayment' }
    & Pick<JobContractPayment, 'id' | 'jobContractId' | 'paymentMethodId' | 'amount' | 'isForDeposit' | 'authorizationNumber'>
  )> }
);

export type CancelContractMutationVariables = Exact<{
  contractId: Scalars['Int'];
}>;


export type CancelContractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContract'>
);

export type DeleteJobContractPaymentsMutationVariables = Exact<{
  paymentIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteJobContractPaymentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteJobContractPayments'>
);

export type UpsertContractPdfPresignedMutationVariables = Exact<{
  contractId: Scalars['Int'];
}>;


export type UpsertContractPdfPresignedMutation = (
  { __typename?: 'Mutation' }
  & { upsertContractPdfPresigned: (
    { __typename?: 'PreSignedS3FileUpload' }
    & PreSignedS3FileUploadFragment
  ) }
);

export type UpsertJobContractPaymentsMutationVariables = Exact<{
  payments: Array<JobContractPaymentInput> | JobContractPaymentInput;
}>;


export type UpsertJobContractPaymentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertJobContractPayments'>
);

export type AddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
);

export type CustomerContactFragment = (
  { __typename?: 'CustomerContact' }
  & Pick<CustomerContact, 'id' | 'customerId' | 'firstName' | 'lastName' | 'phone' | 'phoneExtension' | 'email' | 'receivePromoEmails' | 'isSchedulingContact' | 'isPrimary'>
);

export type GetCustomerAppointmentSearchResultsQueryVariables = Exact<{
  searchStr: Scalars['String'];
}>;


export type GetCustomerAppointmentSearchResultsQuery = (
  { __typename?: 'Query' }
  & { customerAppointmentSearchResults: Array<(
    { __typename?: 'CustomerAppointmentSearchResult' }
    & Pick<CustomerAppointmentSearchResult, 'id' | 'customerId' | 'firstName' | 'lastName' | 'businessName' | 'phoneNumber' | 'city' | 'appointmentNumber' | 'appointmentDate' | 'marketId'>
  )> }
);

export type GetCustomerAsSearchResultQueryVariables = Exact<{
  customerId: Scalars['Int'];
}>;


export type GetCustomerAsSearchResultQuery = (
  { __typename?: 'Query' }
  & { customerAsSearchResult: (
    { __typename?: 'CustomerSearchResult' }
    & Pick<CustomerSearchResult, 'id' | 'businessName' | 'jobId' | 'prospectNotes'>
    & { primaryContact: (
      { __typename?: 'CustomerContact' }
      & CustomerContactFragment
    ), secondaryContacts: Array<(
      { __typename?: 'CustomerContact' }
      & CustomerContactFragment
    )>, primaryAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
    ) }
  ) }
);

export type GetCustomerCleanupListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetCustomerCleanupListQuery = (
  { __typename?: 'Query' }
  & { customerCleanupList?: Maybe<(
    { __typename?: 'CustomerCleanupListConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CustomerCleanupListEdge' }
      & Pick<CustomerCleanupListEdge, 'cursor'>
      & { node: (
        { __typename?: 'CustomerCleanupData' }
        & Pick<CustomerCleanupData, 'id' | 'contactNames' | 'contactEmails'>
      ) }
    )>>, nodes?: Maybe<Array<(
      { __typename?: 'CustomerCleanupData' }
      & Pick<CustomerCleanupData, 'id' | 'contactNames' | 'contactEmails'>
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage'>
    ) }
  )> }
);

export type GetCustomerHistoryQueryVariables = Exact<{
  customerId: Scalars['Int'];
}>;


export type GetCustomerHistoryQuery = (
  { __typename?: 'Query' }
  & { customerHistory: (
    { __typename?: 'CustomerJobHistory' }
    & Pick<CustomerJobHistory, 'id' | 'firstName' | 'lastName'>
    & { jobSummaries: Array<(
      { __typename?: 'HistoricalJobSummary' }
      & Pick<HistoricalJobSummary, 'id' | 'latestVisitDate' | 'productTypes' | 'jobStatus'>
      & { salesperson?: Maybe<(
        { __typename?: 'Salesperson' }
        & Pick<Salesperson, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
  ) }
);

export type GetCustomerSearchResultsQueryVariables = Exact<{
  searchParameters: CustomerSearchParametersInput;
  shouldAndParameters: Scalars['Boolean'];
  numSearches: Scalars['Int'];
}>;


export type GetCustomerSearchResultsQuery = (
  { __typename?: 'Query' }
  & { customerSearchResults: Array<(
    { __typename?: 'CustomerSearchResult' }
    & Pick<CustomerSearchResult, 'id' | 'businessName' | 'jobId' | 'prospectNotes'>
    & { primaryContact: (
      { __typename?: 'CustomerContact' }
      & CustomerContactFragment
    ), secondaryContacts: Array<(
      { __typename?: 'CustomerContact' }
      & CustomerContactFragment
    )>, primaryAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
    ) }
  )> }
);

export type EraseAllCustomerDataMutationVariables = Exact<{
  customerIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type EraseAllCustomerDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'eraseAllCustomerData'>
);

export type MergeCustomersMutationVariables = Exact<{
  customerToMergeId: Scalars['Int'];
  customerToMergeInto: CustomerInput;
}>;


export type MergeCustomersMutation = (
  { __typename?: 'Mutation' }
  & { mergeCustomers: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'businessName' | 'primaryPhone' | 'email' | 'receivePromoEmails'>
    & { primaryAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
    ), billingAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
    )>, customerContacts: Array<(
      { __typename?: 'CustomerContact' }
      & CustomerContactFragment
    )> }
  ) }
);

export type GetS3FileQueryVariables = Exact<{
  fileId: Scalars['Int'];
}>;


export type GetS3FileQuery = (
  { __typename?: 'Query' }
  & { s3File: (
    { __typename?: 'S3File' }
    & Pick<S3File, 'id' | 'objectKey' | 'fileName' | 'status' | 'uploadTimestamp' | 'signedUrl'>
  ) }
);

export type PreSignedS3FileUploadFragment = (
  { __typename?: 'PreSignedS3FileUpload' }
  & Pick<PreSignedS3FileUpload, 'id' | 'uploadUrl'>
);

export type DeleteS3FileMutationVariables = Exact<{
  fileId: Scalars['Int'];
}>;


export type DeleteS3FileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteS3File'>
);

export type ReportS3FileUploadStatusMutationVariables = Exact<{
  fileId: Scalars['Int'];
  succeeded: Scalars['Boolean'];
}>;


export type ReportS3FileUploadStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportS3FileUploadStatus'>
);

export type GetDummyInventoryQueryVariables = Exact<{
  filter?: Maybe<InventoryEntryFilterInput>;
  order?: Maybe<Array<InventoryEntrySortInput> | InventoryEntrySortInput>;
}>;


export type GetDummyInventoryQuery = (
  { __typename?: 'Query' }
  & { dummyInventory: Array<(
    { __typename?: 'InventoryEntry' }
    & Pick<InventoryEntry, 'sku' | 'measurementUnit' | 'orderType' | 'measureUnitPerInventoryUnit' | 'manufacturer' | 'style' | 'color' | 'productType' | 'newCount' | 'damagedCount' | 'heldCount' | 'onOrderCountInOrderUnits' | 'onOrderCountInStockUnits' | 'toOrderCountInOrderUnits' | 'toOrderCountInStockUnits' | 'stockUnitPerOrderUnit' | 'available' | 'potential' | 'minimumStockCount' | 'searchableProductType' | 'stockUnit'>
    & { rolls: Array<(
      { __typename?: 'RollEntry' }
      & Pick<RollEntry, 'sku' | 'rollNumber' | 'dyeLot' | 'length' | 'holding' | 'location'>
      & { cuts: Array<(
        { __typename?: 'RollCut' }
        & Pick<RollCut, 'sku' | 'rollID' | 'cutNumber' | 'requestedDyeLot' | 'jobID' | 'length' | 'dueDate'>
      )> }
    )> }
  )> }
);

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'role'>
  )> }
);

export type CalculateInstallationCalendarMovementQueryVariables = Exact<{
  block: InstallationCalendarBlockIdentifierInput;
  destinationContractorId: Scalars['Int'];
  destinationDate: Scalars['DateTime'];
  destinationBlock?: Maybe<InstallationCalendarBlockIdentifierInput>;
}>;


export type CalculateInstallationCalendarMovementQuery = (
  { __typename?: 'Query' }
  & { calculateInstallationCalendarMovement: (
    { __typename?: 'InstallationCalendarMovementResult' }
    & Pick<InstallationCalendarMovementResult, 'isAllowed' | 'isSwapAllowed' | 'isOutOfContractorServiceArea' | 'needsOverride' | 'sourceType' | 'sourceIdentifier' | 'sourceDate' | 'sourceContractorId' | 'destinationType' | 'destinationIdentifier' | 'destinationDate' | 'destinationContractorId'>
  ) }
);

export type GetInstallationAppointmentsAfterDateQueryVariables = Exact<{
  afterDate: Scalars['DateTime'];
}>;


export type GetInstallationAppointmentsAfterDateQuery = (
  { __typename?: 'Query' }
  & { installationAppointmentsAfterDate: Array<(
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  )> }
);

export type GetInstallationAppointmentsForJobContractQueryVariables = Exact<{
  jobContractId: Scalars['Int'];
}>;


export type GetInstallationAppointmentsForJobContractQuery = (
  { __typename?: 'Query' }
  & { installationAppointmentsForJobContract: Array<(
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  )> }
);

export type ToggleInstallationAppointmentReleaseMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
  releasedToContractor: Scalars['Boolean'];
}>;


export type ToggleInstallationAppointmentReleaseMutation = (
  { __typename?: 'Mutation' }
  & { toggleInstallationAppointmentRelease: (
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  ) }
);

export type CalculatePriceForLocalConfigurationQueryVariables = Exact<{
  originalConfigurationId: Scalars['Int'];
  updatedProducts: Array<UpdatedProductForAreaInput> | UpdatedProductForAreaInput;
  includeSalesTax: Scalars['Boolean'];
}>;


export type CalculatePriceForLocalConfigurationQuery = (
  { __typename?: 'Query' }
  & { calculatePriceForLocalConfiguration?: Maybe<(
    { __typename?: 'PriceAndPromoSavings' }
    & Pick<PriceAndPromoSavings, 'promoSavings'>
    & { price: (
      { __typename?: 'Price' }
      & Pick<Price, 'mSRP' | 'savings' | 'total'>
    ) }
  )> }
);

export type CopyJobConfigurationMutationVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  optionNumber: Scalars['Int'];
  updatedProducts?: Maybe<Array<UpdatedProductForAreaInput> | UpdatedProductForAreaInput>;
  selectedFinancingOption?: Maybe<Scalars['Int']>;
}>;


export type CopyJobConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'copyJobConfiguration'>
);

export type GetActiveFinancingOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveFinancingOptionsQuery = (
  { __typename?: 'Query' }
  & { activeFinancingOptions: Array<(
    { __typename?: 'FinancingOption' }
    & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'typeName' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
  )> }
);

export type GetAllAppliedDiscountsForJobQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetAllAppliedDiscountsForJobQuery = (
  { __typename?: 'Query' }
  & { allAppliedDiscountsForJob: Array<(
    { __typename?: 'AppliedDiscount' }
    & Pick<AppliedDiscount, 'id' | 'jobConfigurationId' | 'discountId' | 'authorizationCode' | 'amount' | 'name' | 'isScalar' | 'isValid'>
  )> }
);

export type GetAllJobIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllJobIdsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'allJobIds'>
);

export type GetAllValidProductStyleOptionsForConfigurationQueryVariables = Exact<{
  substrateIdsInArea: Array<Scalars['Int']> | Scalars['Int'];
  productTypeId: Scalars['Int'];
}>;


export type GetAllValidProductStyleOptionsForConfigurationQuery = (
  { __typename?: 'Query' }
  & { allValidProductStyleOptionsForConfiguration: Array<(
    { __typename?: 'ProductStyleValidForConfiguration' }
    & Pick<ProductStyleValidForConfiguration, 'id' | 'substrateIdsInArea' | 'style' | 'valid' | 'defaultInstallJobServiceId' | 'materialForDefaultInstallServiceId'>
  )> }
);

export type GetAllSahSalespeopleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSahSalespeopleQuery = (
  { __typename?: 'Query' }
  & { sAHSalespeople: Array<(
    { __typename?: 'Salesperson' }
    & Pick<Salesperson, 'id' | 'firstName' | 'lastName' | 'email'>
  )> }
);

export type GetAllSalespeopleForJobQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetAllSalespeopleForJobQuery = (
  { __typename?: 'Query' }
  & { allSalespeopleForJob: Array<(
    { __typename?: 'Salesperson' }
    & Pick<Salesperson, 'id' | 'firstName' | 'lastName' | 'email' | 'serviceZips'>
  )> }
);

export type GetAreaQueryVariables = Exact<{
  areaId: Scalars['Int'];
}>;


export type GetAreaQuery = (
  { __typename?: 'Query' }
  & { area: (
    { __typename?: 'Area' }
    & AreaFragment
  ) }
);

export type GetCallCenterJobSummaryQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type GetCallCenterJobSummaryQuery = (
  { __typename?: 'Query' }
  & { callCenterJobSummary: (
    { __typename?: 'CallCenterJobSummary' }
    & Pick<CallCenterJobSummary, 'id'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'businessName' | 'primaryPhone' | 'email' | 'receivePromoEmails'>
      & { primaryAddress: (
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
      ), billingAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
      )>, customerContacts: Array<(
        { __typename?: 'CustomerContact' }
        & CustomerContactFragment
      )> }
    ), appointment: (
      { __typename?: 'SAHAppointment' }
      & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'contractCancelled' | 'contractTotalPrice' | 'quoteTotalPrice'>
      & { marketTimeSlot: (
        { __typename?: 'MarketTimeSlot' }
        & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
      ), currentSalesperson?: Maybe<(
        { __typename?: 'SalespersonAssignedToAppointment' }
        & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
      )>, priorityOptions?: Maybe<Array<(
        { __typename?: 'PriorityOption' }
        & Pick<PriorityOption, 'id' | 'text'>
      )>>, colorCategories?: Maybe<Array<(
        { __typename?: 'ColorCategory' }
        & Pick<ColorCategory, 'id' | 'category'>
      )>> }
    ), mostRecentSalesperson?: Maybe<(
      { __typename?: 'Salesperson' }
      & Pick<Salesperson, 'id' | 'firstName' | 'lastName'>
    )> }
  ) }
);

export type GetContractDataQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetContractDataQuery = (
  { __typename?: 'Query' }
  & { contractData: (
    { __typename?: 'ContractPdfData' }
    & Pick<ContractPdfData, 'contractSignatureBase64' | 'noticeOfRightSignatureBase64' | 'financingAccountNumber' | 'updatedJobNotes' | 'signingDate'>
    & { payments: Array<(
      { __typename?: 'JobContractPayment' }
      & Pick<JobContractPayment, 'paymentMethodId' | 'amount'>
    )>, updatedAreaNotes: Array<(
      { __typename?: 'AreaNotesAndId' }
      & Pick<AreaNotesAndId, 'areaId' | 'areaNotes'>
    )> }
  ) }
);

export type GetFullyScaledJobConfigurationQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetFullyScaledJobConfigurationQuery = (
  { __typename?: 'Query' }
  & { fullyScaledJobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & JobConfigurationFragment
  ) }
);

export type GetJobConfigurationQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetJobConfigurationQuery = (
  { __typename?: 'Query' }
  & { jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & JobConfigurationFragment
  ) }
);

export type GetJobConfigurationAreaIdsQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetJobConfigurationAreaIdsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'jobConfigurationAreaIds'>
);

export type GetJobConfigurationFinancingQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetJobConfigurationFinancingQuery = (
  { __typename?: 'Query' }
  & { jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id'>
    & { financing?: Maybe<(
      { __typename?: 'Financing' }
      & Pick<Financing, 'id' | 'financingAccountNumber' | 'financingDownPayment' | 'perMonthAmount'>
      & { financingOption?: Maybe<(
        { __typename?: 'FinancingOption' }
        & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
      )> }
    )> }
  ) }
);

export type GetJobConfigurationHeaderQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetJobConfigurationHeaderQuery = (
  { __typename?: 'Query' }
  & { jobConfigurationHeader: (
    { __typename?: 'JobMetadataHeader' }
    & Pick<JobMetadataHeader, 'id' | 'appointmentNumber'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'firstName' | 'lastName'>
    ), configurationIds: Array<(
      { __typename?: 'ConfigIdsWithLabels' }
      & Pick<ConfigIdsWithLabels, 'id' | 'labels'>
    )> }
  ) }
);

export type GetJobConfigurationWithWasteFactorQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetJobConfigurationWithWasteFactorQuery = (
  { __typename?: 'Query' }
  & { jobConfigurationWithWasteFactor: (
    { __typename?: 'JobConfiguration' }
    & JobConfigurationFragment
  ) }
);

export type GetJobFinancingOptionsQueryVariables = Exact<{
  jobPrice: Scalars['Int'];
  jobConfigurationId?: Maybe<Scalars['Int']>;
}>;


export type GetJobFinancingOptionsQuery = (
  { __typename?: 'Query' }
  & { jobFinancingOptions: (
    { __typename?: 'FinancingSlotOptions' }
    & Pick<FinancingSlotOptions, 'jobPrice'>
    & { slotA?: Maybe<(
      { __typename?: 'FinancingOption' }
      & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'typeName' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
    )>, slotB?: Maybe<(
      { __typename?: 'FinancingOption' }
      & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'typeName' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
    )>, slotC?: Maybe<(
      { __typename?: 'FinancingOption' }
      & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'typeName' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
    )> }
  ) }
);

export type GetJobIdQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetJobIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'jobId'>
);

export type GetJobIsFailQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type GetJobIsFailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'jobIsFail' | 'jobIsInRecovery'>
);

export type GetJobPriceQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetJobPriceQuery = (
  { __typename?: 'Query' }
  & { jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & { price?: Maybe<(
      { __typename?: 'Price' }
      & Pick<Price, 'mSRP' | 'savings' | 'total'>
    )> }
  ) }
);

export type GetJobSearchResultsQueryVariables = Exact<{
  searchStr: Scalars['String'];
}>;


export type GetJobSearchResultsQuery = (
  { __typename?: 'Query' }
  & { jobSearchResults: Array<(
    { __typename?: 'JobSearchResult' }
    & Pick<JobSearchResult, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'city' | 'jobConfigurationId' | 'sahNumber' | 'contractDate' | 'jobContractId'>
  )> }
);

export type GetJobSummaryDataQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type GetJobSummaryDataQuery = (
  { __typename?: 'Query' }
  & { jobSummaryData: (
    { __typename?: 'JobMetadataWithConfigIds' }
    & Pick<JobMetadataWithConfigIds, 'id'>
    & { partialJob: (
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { customer: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'firstName' | 'lastName'>
      ) }
    ), configIds: Array<(
      { __typename?: 'ConfigIdsWithLabels' }
      & Pick<ConfigIdsWithLabels, 'id' | 'labels'>
    )> }
  ) }
);

export type GetMaterialOptionsQueryVariables = Exact<{
  jobServiceId: Scalars['Int'];
  productTypeId: Scalars['Int'];
}>;


export type GetMaterialOptionsQuery = (
  { __typename?: 'Query' }
  & { materialOptions: Array<(
    { __typename?: 'ServiceMaterialCategoryOption' }
    & Pick<ServiceMaterialCategoryOption, 'materialCategoryId' | 'productTypeId' | 'materialName' | 'materialPriceUnit' | 'defaultAmount' | 'isDefaultMaterial'>
  )> }
);

export type GetMsrpScalarForConfigurationQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetMsrpScalarForConfigurationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'msrpScalarForConfiguration'>
);

export type GetOverrideDiscountForJobQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetOverrideDiscountForJobQuery = (
  { __typename?: 'Query' }
  & { overrideDiscountForJob?: Maybe<(
    { __typename?: 'AppliedDiscount' }
    & Pick<AppliedDiscount, 'id' | 'jobConfigurationId' | 'discountId' | 'authorizationCode' | 'amount' | 'name' | 'isScalar' | 'isValid'>
  )> }
);

export type GetPictureQueryVariables = Exact<{
  pictureId: Scalars['Int'];
}>;


export type GetPictureQuery = (
  { __typename?: 'Query' }
  & { picture: (
    { __typename?: 'Picture' }
    & Pick<Picture, 'id' | 'uploadDate' | 'description' | 'image'>
  ) }
);

export type GetPreContractJobSummaryDataQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetPreContractJobSummaryDataQuery = (
  { __typename?: 'Query' }
  & { preContractJobSummaryData: (
    { __typename?: 'PreContractJobConfigIds' }
    & Pick<PreContractJobConfigIds, 'id' | 'configIds'>
  ) }
);

export type GetPriceVerificationFieldsQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetPriceVerificationFieldsQuery = (
  { __typename?: 'Query' }
  & { priceVerificationFields: (
    { __typename?: 'PriceVerificationFields' }
    & Pick<PriceVerificationFields, 'id'>
    & { styles: Array<(
      { __typename?: 'StyleForJobPrice' }
      & Pick<StyleForJobPrice, 'id' | 'price' | 'cost'>
    )>, services: Array<(
      { __typename?: 'ServiceForRoomPrice' }
      & Pick<ServiceForRoomPrice, 'id' | 'price' | 'cost' | 'materialPrice' | 'materialCost'>
    )> }
  ) }
);

export type GetProductStyleValidOptionsForAreaQueryVariables = Exact<{
  areaId: Scalars['Int'];
}>;


export type GetProductStyleValidOptionsForAreaQuery = (
  { __typename?: 'Query' }
  & { productStyleValidOptionsForArea: Array<(
    { __typename?: 'ProductStyleValidForArea' }
    & Pick<ProductStyleValidForArea, 'id' | 'areaId' | 'style' | 'valid'>
  )> }
);

export type GetPromotionOnJobQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetPromotionOnJobQuery = (
  { __typename?: 'Query' }
  & { promotionOnJob?: Maybe<(
    { __typename?: 'PromotionOnJob' }
    & Pick<PromotionOnJob, 'id' | 'savingsAmount'>
    & { basePromotion: (
      { __typename?: 'BasePromotion' }
      & Pick<BasePromotion, 'id' | 'name'>
    ) }
  )> }
);

export type GetQuotePriceForJobChangesQueryVariables = Exact<{
  originalJobConfigurationId: Scalars['Int'];
  scaledUpdatedJobConfiguration: JobConfigurationInput;
}>;


export type GetQuotePriceForJobChangesQuery = (
  { __typename?: 'Query' }
  & { quotePriceForJobChanges: (
    { __typename?: 'CostAndPrice' }
    & Pick<CostAndPrice, 'id' | 'cost' | 'price'>
  ) }
);

export type GetRoomLabelOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRoomLabelOptionsQuery = (
  { __typename?: 'Query' }
  & { roomLabelOptions: Array<(
    { __typename?: 'RoomLabelOption' }
    & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
  )> }
);

export type GetServiceTypeOptionsQueryVariables = Exact<{
  productTypeId?: Maybe<Scalars['Int']>;
  areaId?: Maybe<Scalars['Int']>;
}>;


export type GetServiceTypeOptionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'validInstallationJobServiceIds'>
  & { serviceTypeOptions: Array<(
    { __typename?: 'ServiceTypeOption' }
    & Pick<ServiceTypeOption, 'serviceTypeId' | 'productTypeId' | 'name' | 'displayServiceTypeId'>
    & { jobServices: Array<(
      { __typename?: 'JobServiceOption' }
      & Pick<JobServiceOption, 'jobServiceId' | 'productTypeId' | 'description' | 'laborPricingUnit'>
      & { materialOptions: Array<(
        { __typename?: 'ServiceMaterialCategoryOption' }
        & Pick<ServiceMaterialCategoryOption, 'materialCategoryId' | 'productTypeId' | 'materialName' | 'materialPriceUnit' | 'defaultAmount' | 'isDefaultMaterial'>
      )> }
    )> }
  )> }
);

export type GetTestSvgQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTestSvgQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'testSvg'>
);

export type AreaFragment = (
  { __typename?: 'Area' }
  & Pick<Area, 'id' | 'jobConfigurationId' | 'productTypeId' | 'productTypeName' | 'styleId' | 'styleName' | 'colorId' | 'colorName' | 'lnftWasteFactor' | 'sqftWasteFactor' | 'preferredCarpetLength' | 'oppositeCarpetLength' | 'includedInQuote' | 'notes' | 'lineNumber'>
  & { price?: Maybe<(
    { __typename?: 'Price' }
    & Pick<Price, 'mSRP' | 'savings' | 'total'>
  )>, rooms: Array<(
    { __typename?: 'Room' }
    & RoomFragment
  )>, customServices: Array<(
    { __typename?: 'CustomService' }
    & Pick<CustomService, 'id' | 'areaId' | 'roomIds' | 'description' | 'price' | 'contractorPercentage'>
  )> }
);

export type CarpetCutFragment = (
  { __typename?: 'CarpetCut' }
  & Pick<CarpetCut, 'id' | 'jobConfigurationId' | 'roomId' | 'directionBias' | 'stepLength'>
  & { shape: Array<(
    { __typename?: 'RoomShapePoint' }
    & Pick<RoomShapePoint, 'x' | 'y'>
  )>, pieces: Array<(
    { __typename?: 'CarpetCutPiece' }
    & Pick<CarpetCutPiece, 'id' | 'carpetCutId' | 'fillName' | 'packingBlockNumber'>
    & { roomShape: Array<(
      { __typename?: 'RoomShapePoint' }
      & Pick<RoomShapePoint, 'x' | 'y'>
    )>, packerShape: Array<(
      { __typename?: 'RoomShapePoint' }
      & Pick<RoomShapePoint, 'x' | 'y'>
    )> }
  )> }
);

export type JobConfigurationFragment = (
  { __typename?: 'JobConfiguration' }
  & Pick<JobConfiguration, 'id' | 'jobId' | 'optionNumber' | 'notes' | 'drawingSVG'>
  & { areas: Array<(
    { __typename?: 'Area' }
    & AreaFragment
  )>, unassignedRooms: Array<(
    { __typename?: 'Room' }
    & RoomFragment
  )>, roomTransitions: Array<(
    { __typename?: 'RoomTransition' }
    & Pick<RoomTransition, 'id' | 'jobConfigurationId' | 'mainRoomId' | 'altRoomId' | 'altExistingRoomIds' | 'width' | 'isDoor' | 'isExternalDoor' | 'mainRoomPositionIndex' | 'altRoomPositionIndex'>
  )>, price?: Maybe<(
    { __typename?: 'Price' }
    & Pick<Price, 'mSRP' | 'savings' | 'total'>
  )>, financing?: Maybe<(
    { __typename?: 'Financing' }
    & Pick<Financing, 'id' | 'financingAccountNumber' | 'financingDownPayment' | 'perMonthAmount'>
    & { financingOption?: Maybe<(
      { __typename?: 'FinancingOption' }
      & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
    )> }
  )>, discounts?: Maybe<(
    { __typename?: 'DiscountsOnJob' }
    & Pick<DiscountsOnJob, 'id' | 'isOverrideApplied'>
    & { availableDiscounts: Array<(
      { __typename?: 'Discount' }
      & DiscountFragment
    )>, discounts: Array<(
      { __typename?: 'AppliedDiscount' }
      & Pick<AppliedDiscount, 'id' | 'amount' | 'discountId' | 'authorizationCode' | 'isScalar' | 'isValid' | 'jobConfigurationId' | 'name'>
    )> }
  )>, priceRequest?: Maybe<(
    { __typename?: 'PriceRequest' }
    & Pick<PriceRequest, 'id' | 'jobConfigurationId' | 'amount' | 'salespersonId' | 'commandCenterWorkerId' | 'timeRequested' | 'timeResponded' | 'granted'>
  )>, promotion?: Maybe<(
    { __typename?: 'PromotionOnJob' }
    & Pick<PromotionOnJob, 'id' | 'savingsAmount'>
    & { basePromotion: (
      { __typename?: 'BasePromotion' }
      & Pick<BasePromotion, 'id' | 'name' | 'isDeleted'>
      & { promotionTypes: Array<(
        { __typename?: 'TypeDetailForBasePromotion' }
        & Pick<TypeDetailForBasePromotion, 'basePromotionId' | 'isScalar' | 'amount'>
        & { promotionType: (
          { __typename?: 'PromotionType' }
          & Pick<PromotionType, 'id' | 'label'>
        ) }
      )> }
    ) }
  )> }
);

export type RoomFragment = (
  { __typename?: 'Room' }
  & Pick<Room, 'id' | 'areaId' | 'jobConfigurationId' | 'svg' | 'detailedSvg' | 'isCarpetVerticallyRun' | 'notes' | 'sqft' | 'lnft' | 'substrateId'>
  & { svgPosition: (
    { __typename?: 'RoomShapePoint' }
    & Pick<RoomShapePoint, 'x' | 'y'>
  ), shape: Array<(
    { __typename?: 'RoomShapePoint' }
    & Pick<RoomShapePoint, 'x' | 'y'>
  )>, carpetCuts: Array<(
    { __typename?: 'CarpetCut' }
    & CarpetCutFragment
  )>, labels: Array<(
    { __typename?: 'LabelForRoom' }
    & Pick<LabelForRoom, 'id' | 'customName' | 'hasCloset' | 'labelNumber' | 'roomId'>
    & { label: (
      { __typename?: 'RoomLabelOption' }
      & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
    ) }
  )>, price?: Maybe<(
    { __typename?: 'Price' }
    & Pick<Price, 'mSRP' | 'savings' | 'total'>
  )>, services: Array<(
    { __typename?: 'ServiceForRoom' }
    & Pick<ServiceForRoom, 'id' | 'customerDoesService' | 'serviceTypeId' | 'jobServiceId' | 'laborAmount' | 'materialAmount' | 'materialCategoryId' | 'roomId' | 'isActive'>
  )>, steps: Array<(
    { __typename?: 'StepForRoom' }
    & Pick<StepForRoom, 'id' | 'stepDetailId' | 'roomId' | 'amount' | 'width' | 'length' | 'isTreadOnly' | 'isLanding' | 'shouldPromptAboutSpindles'>
  )>, pictures: Array<(
    { __typename?: 'PictureForRoom' }
    & Pick<PictureForRoom, 'id' | 'roomId'>
    & { picture?: Maybe<(
      { __typename?: 'Picture' }
      & Pick<Picture, 'id' | 'description'>
    )> }
  )>, beforePictures: Array<(
    { __typename?: 'PictureForRoom' }
    & Pick<PictureForRoom, 'id' | 'roomId'>
    & { picture?: Maybe<(
      { __typename?: 'Picture' }
      & Pick<Picture, 'id' | 'description'>
    )> }
  )>, afterPictures: Array<(
    { __typename?: 'PictureForRoom' }
    & Pick<PictureForRoom, 'id' | 'roomId'>
    & { picture?: Maybe<(
      { __typename?: 'Picture' }
      & Pick<Picture, 'id' | 'description'>
    )> }
  )> }
);

export type AddBeforeAndAfterPicturesForRoomMutationVariables = Exact<{
  roomId: Scalars['Int'];
  pictures: Array<PictureInput> | PictureInput;
  isBefore: Scalars['Boolean'];
}>;


export type AddBeforeAndAfterPicturesForRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addBeforeAndAfterPicturesForRoom'>
);

export type AddContractToJobWithDepositMutationVariables = Exact<{
  jobContractDetails: AddContractToJobWithDepositMutationInput;
}>;


export type AddContractToJobWithDepositMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addContractToJobWithDeposit'>
);

export type AddPictureMutationVariables = Exact<{
  picture: Scalars['String'];
  description: Scalars['String'];
}>;


export type AddPictureMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addPicture'>
);

export type AddPicturesForRoomMutationVariables = Exact<{
  roomId: Scalars['Int'];
  pictures: Array<PictureInput> | PictureInput;
}>;


export type AddPicturesForRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addPicturesForRoom'>
);

export type CreateJobWithNewCustomerMutationVariables = Exact<{
  customer: CustomerInput;
}>;


export type CreateJobWithNewCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createJobWithNewCustomer: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id' | 'jobId'>
  ) }
);

export type DeleteBeforeAndAfterPictureForRoomMutationVariables = Exact<{
  beforeAndAfterPictureForRoomId: Scalars['Int'];
}>;


export type DeleteBeforeAndAfterPictureForRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBeforeAndAfterPictureForRoom'>
);

export type DeleteJobConfigurationMutationVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type DeleteJobConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteJobConfiguration'>
);

export type DeletePictureForRoomMutationVariables = Exact<{
  pictureForRoomId: Scalars['Int'];
}>;


export type DeletePictureForRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePictureForRoom'>
);

export type EmailContractMutationVariables = Exact<{
  contractId: Scalars['Int'];
  emailAddresses: Array<Scalars['String']> | Scalars['String'];
  updatePrimaryEmail: Scalars['Boolean'];
}>;


export type EmailContractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'emailContract'>
);

export type EmailQuoteMutationVariables = Exact<{
  quoteId: Scalars['Int'];
  emailAddresses: Array<Scalars['String']> | Scalars['String'];
  updatePrimaryEmail: Scalars['Boolean'];
}>;


export type EmailQuoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'emailQuote'>
);

export type ReassignRoomMutationVariables = Exact<{
  roomId: Scalars['Int'];
  areaId?: Maybe<Scalars['Int']>;
}>;


export type ReassignRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reassignRoom'>
);

export type ToggleSeamDirectionMutationVariables = Exact<{
  roomId: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
}>;


export type ToggleSeamDirectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleSeamDirection'>
);

export type UpdateAreaMutationVariables = Exact<{
  area: AreaInput;
}>;


export type UpdateAreaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateArea'>
);

export type UpdateAreaProductColorSelectionMutationVariables = Exact<{
  areaId: Scalars['Int'];
  colorId?: Maybe<Scalars['Int']>;
}>;


export type UpdateAreaProductColorSelectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAreaProductColorSelection'>
);

export type UpdateAreaProductStyleSelectionMutationVariables = Exact<{
  areaId: Scalars['Int'];
  styleId?: Maybe<Scalars['Int']>;
  preventUpdatingProductInCart?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateAreaProductStyleSelectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAreaProductStyleSelection'>
);

export type UpdateAreaProductTypeMutationVariables = Exact<{
  areaId: Scalars['Int'];
  productTypeId?: Maybe<Scalars['Int']>;
}>;


export type UpdateAreaProductTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAreaProductType'>
);

export type UpdateChargeablesMutationVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  upsertedServices: Array<ServiceForRoomInput> | ServiceForRoomInput;
  deletedServiceIds: Array<Scalars['Int']> | Scalars['Int'];
  upsertedCustomServices: Array<CustomServiceInput> | CustomServiceInput;
  deletedCustomServiceIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type UpdateChargeablesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateChargeables'>
);

export type UpdateJobFinancingOptionMutationVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  financingOptionId: Scalars['Int'];
}>;


export type UpdateJobFinancingOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateJobFinancingOption'>
);

export type UpdateProductInCartMutationVariables = Exact<{
  areaId: Scalars['Int'];
  inCartStatus: Scalars['Boolean'];
}>;


export type UpdateProductInCartMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProductInCart'>
);

export type UpdateUnderlaymentInAreaMutationVariables = Exact<{
  areaId: Scalars['Int'];
  materialCategoryId?: Maybe<Scalars['Int']>;
}>;


export type UpdateUnderlaymentInAreaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUnderlaymentInArea'>
);

export type UpsertJobConfigurationMutationVariables = Exact<{
  jobConfiguration: JobConfigurationInput;
  saveAsNew: Scalars['Boolean'];
}>;


export type UpsertJobConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { upsertJobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id' | 'jobId'>
  ) }
);

export type DiscountUpdatedSubscriptionVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type DiscountUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { discountUpdated: (
    { __typename?: 'AppliedDiscountAction' }
    & Pick<AppliedDiscountAction, 'id' | 'isRemoval'>
    & { discount: (
      { __typename?: 'AppliedDiscount' }
      & Pick<AppliedDiscount, 'id' | 'jobConfigurationId' | 'discountId' | 'authorizationCode' | 'amount' | 'name' | 'isScalar' | 'isValid'>
    ) }
  ) }
);

export type FinancingSlotChangedSubscriptionVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type FinancingSlotChangedSubscription = (
  { __typename?: 'Subscription' }
  & { financingSlotChanged: (
    { __typename?: 'FinancingOption' }
    & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'typeName' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
  ) }
);

export type FinancingUpdatedSubscriptionVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type FinancingUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { financingUpdated?: Maybe<(
    { __typename?: 'Financing' }
    & Pick<Financing, 'id' | 'financingAccountNumber' | 'financingDownPayment' | 'perMonthAmount'>
    & { financingOption?: Maybe<(
      { __typename?: 'FinancingOption' }
      & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
    )> }
  )> }
);

export type JobConfigurationPriceUpdatedSubscriptionVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type JobConfigurationPriceUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { jobConfigurationPriceUpdated: (
    { __typename?: 'PreOverrideAndOverallPrice' }
    & Pick<PreOverrideAndOverallPrice, 'id' | 'preOverridePrice'>
    & { overallPrice: (
      { __typename?: 'Price' }
      & Pick<Price, 'mSRP' | 'savings' | 'total'>
    ) }
  ) }
);

export type JobConfigurationUpdatedSubscriptionVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type JobConfigurationUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { jobConfigurationUpdated: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id' | 'jobId'>
    & { areas: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'id' | 'productTypeId' | 'productTypeName' | 'styleId' | 'includedInQuote' | 'preferredCarpetLength' | 'oppositeCarpetLength'>
      & { price?: Maybe<(
        { __typename?: 'Price' }
        & Pick<Price, 'mSRP' | 'savings' | 'total'>
      )>, rooms: Array<(
        { __typename?: 'Room' }
        & Pick<Room, 'id'>
        & { labels: Array<(
          { __typename?: 'LabelForRoom' }
          & Pick<LabelForRoom, 'id' | 'customName' | 'hasCloset' | 'labelNumber' | 'roomId'>
          & { label: (
            { __typename?: 'RoomLabelOption' }
            & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
          ) }
        )>, steps: Array<(
          { __typename?: 'StepForRoom' }
          & Pick<StepForRoom, 'id' | 'stepDetailId' | 'roomId' | 'amount' | 'length' | 'width' | 'isTreadOnly' | 'isLanding' | 'shouldPromptAboutSpindles'>
        )> }
      )> }
    )>, price?: Maybe<(
      { __typename?: 'Price' }
      & Pick<Price, 'mSRP' | 'savings' | 'total'>
    )>, discounts?: Maybe<(
      { __typename?: 'DiscountsOnJob' }
      & Pick<DiscountsOnJob, 'id' | 'isOverrideApplied'>
      & { discounts: Array<(
        { __typename?: 'AppliedDiscount' }
        & Pick<AppliedDiscount, 'id' | 'amount' | 'discountId' | 'isScalar' | 'isValid' | 'jobConfigurationId' | 'name'>
      )> }
    )> }
  ) }
);

export type PromotionOnJobUpdatedSubscriptionVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type PromotionOnJobUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { promotionOnJobUpdated: (
    { __typename?: 'PromotionOnJobAction' }
    & Pick<PromotionOnJobAction, 'id' | 'isRemoval'>
    & { promotion?: Maybe<(
      { __typename?: 'PromotionOnJob' }
      & Pick<PromotionOnJob, 'id' | 'savingsAmount'>
      & { basePromotion: (
        { __typename?: 'BasePromotion' }
        & Pick<BasePromotion, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type GetAllQuotesWithCustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllQuotesWithCustomerQuery = (
  { __typename?: 'Query' }
  & { allQuotesWithCustomer: Array<(
    { __typename?: 'QuoteAndCustomer' }
    & Pick<QuoteAndCustomer, 'id'>
    & { quote: (
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'quoteNumber'>
    ), customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type GetCartPageDataQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetCartPageDataQuery = (
  { __typename?: 'Query' }
  & { jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id' | 'jobId' | 'notes'>
    & { areas: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'id' | 'productTypeId' | 'productTypeName' | 'styleId' | 'styleName' | 'colorId' | 'colorName' | 'includedInQuote' | 'preferredCarpetLength' | 'oppositeCarpetLength'>
      & { price?: Maybe<(
        { __typename?: 'Price' }
        & Pick<Price, 'mSRP' | 'savings' | 'total'>
      )>, rooms: Array<(
        { __typename?: 'Room' }
        & Pick<Room, 'id' | 'sqft'>
        & { labels: Array<(
          { __typename?: 'LabelForRoom' }
          & Pick<LabelForRoom, 'id' | 'customName' | 'hasCloset' | 'labelNumber' | 'roomId'>
          & { label: (
            { __typename?: 'RoomLabelOption' }
            & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
          ) }
        )>, services: Array<(
          { __typename?: 'ServiceForRoom' }
          & Pick<ServiceForRoom, 'id' | 'customerDoesService' | 'isActive' | 'serviceTypeId' | 'jobServiceId' | 'laborAmount' | 'materialAmount' | 'materialCategoryId' | 'roomId'>
        )> }
      )> }
    )>, roomTransitions: Array<(
      { __typename?: 'RoomTransition' }
      & Pick<RoomTransition, 'id' | 'jobConfigurationId' | 'mainRoomId' | 'altRoomId' | 'altExistingRoomIds' | 'width' | 'isDoor' | 'isExternalDoor' | 'mainRoomPositionIndex' | 'altRoomPositionIndex'>
    )>, price?: Maybe<(
      { __typename?: 'Price' }
      & Pick<Price, 'mSRP' | 'savings' | 'total'>
    )>, financing?: Maybe<(
      { __typename?: 'Financing' }
      & Pick<Financing, 'id' | 'perMonthAmount' | 'financingDownPayment'>
      & { financingOption?: Maybe<(
        { __typename?: 'FinancingOption' }
        & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
      )> }
    )>, discounts?: Maybe<(
      { __typename?: 'DiscountsOnJob' }
      & Pick<DiscountsOnJob, 'id' | 'isOverrideApplied'>
    )> }
  ) }
);

export type GetJobInvoiceDataQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetJobInvoiceDataQuery = (
  { __typename?: 'Query' }
  & { jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id' | 'jobId' | 'notes'>
    & { areas: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'id' | 'jobConfigurationId' | 'productTypeId' | 'productTypeName' | 'styleName' | 'colorName' | 'notes' | 'includedInQuote' | 'preferredCarpetLength' | 'oppositeCarpetLength'>
      & { price?: Maybe<(
        { __typename?: 'Price' }
        & Pick<Price, 'mSRP' | 'savings' | 'total'>
      )>, rooms: Array<(
        { __typename?: 'Room' }
        & Pick<Room, 'id' | 'sqft' | 'substrateId'>
        & { labels: Array<(
          { __typename?: 'LabelForRoom' }
          & Pick<LabelForRoom, 'id' | 'customName' | 'hasCloset' | 'labelNumber' | 'roomId'>
          & { label: (
            { __typename?: 'RoomLabelOption' }
            & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
          ) }
        )>, services: Array<(
          { __typename?: 'ServiceForRoom' }
          & Pick<ServiceForRoom, 'id' | 'customerDoesService' | 'isActive' | 'serviceTypeId' | 'jobServiceId' | 'laborAmount' | 'materialAmount' | 'materialCategoryId' | 'roomId'>
        )>, steps: Array<(
          { __typename?: 'StepForRoom' }
          & Pick<StepForRoom, 'id' | 'stepDetailId' | 'roomId' | 'amount' | 'width' | 'length' | 'isTreadOnly' | 'isLanding' | 'shouldPromptAboutSpindles'>
        )> }
      )>, customServices: Array<(
        { __typename?: 'CustomService' }
        & Pick<CustomService, 'id' | 'areaId' | 'roomIds' | 'description' | 'price' | 'contractorPercentage'>
      )> }
    )>, roomTransitions: Array<(
      { __typename?: 'RoomTransition' }
      & Pick<RoomTransition, 'id' | 'jobConfigurationId' | 'mainRoomId' | 'altRoomId' | 'altExistingRoomIds' | 'width' | 'isDoor' | 'isExternalDoor' | 'mainRoomPositionIndex' | 'altRoomPositionIndex'>
    )>, price?: Maybe<(
      { __typename?: 'Price' }
      & Pick<Price, 'mSRP' | 'savings' | 'total'>
    )>, financing?: Maybe<(
      { __typename?: 'Financing' }
      & Pick<Financing, 'id' | 'financingAccountNumber' | 'financingDownPayment' | 'perMonthAmount'>
      & { financingOption?: Maybe<(
        { __typename?: 'FinancingOption' }
        & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
      )> }
    )> }
  ), jobConfigurationHeader: (
    { __typename?: 'JobMetadataHeader' }
    & Pick<JobMetadataHeader, 'id' | 'appointmentNumber'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'businessName' | 'primaryPhone' | 'email' | 'receivePromoEmails'>
      & { primaryAddress: (
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
      ), billingAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
      )>, customerContacts: Array<(
        { __typename?: 'CustomerContact' }
        & CustomerContactFragment
      )> }
    ), assignedSalespeople: Array<(
      { __typename?: 'Salesperson' }
      & Pick<Salesperson, 'id' | 'workerId' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn' | 'serviceZips'>
    )> }
  ) }
);

export type GetQuoteQueryVariables = Exact<{
  quoteId: Scalars['Int'];
}>;


export type GetQuoteQuery = (
  { __typename?: 'Query' }
  & { quote: (
    { __typename?: 'Quote' }
    & Pick<Quote, 'id' | 'quoteNumber' | 'jobConfigurationId' | 'date' | 'followUpDate' | 'totalPrice' | 'notes'>
    & { areas?: Maybe<Array<(
      { __typename?: 'Area' }
      & AreaFragment
    )>> }
  ) }
);

export type GetQuotePdfS3FileQueryVariables = Exact<{
  quoteId: Scalars['Int'];
}>;


export type GetQuotePdfS3FileQuery = (
  { __typename?: 'Query' }
  & { customerForQuote: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'email'>
  ), quotePdfS3File?: Maybe<(
    { __typename?: 'S3File' }
    & S3FileFragment
  )> }
);

export type GetSellSheetAreaColumnQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetSellSheetAreaColumnQuery = (
  { __typename?: 'Query' }
  & { jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id' | 'jobId'>
    & { areas: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'id' | 'productTypeId' | 'productTypeName' | 'styleId' | 'includedInQuote' | 'preferredCarpetLength' | 'oppositeCarpetLength'>
      & { price?: Maybe<(
        { __typename?: 'Price' }
        & Pick<Price, 'mSRP' | 'savings' | 'total'>
      )>, rooms: Array<(
        { __typename?: 'Room' }
        & Pick<Room, 'id'>
        & { labels: Array<(
          { __typename?: 'LabelForRoom' }
          & Pick<LabelForRoom, 'id' | 'customName' | 'hasCloset' | 'labelNumber' | 'roomId'>
          & { label: (
            { __typename?: 'RoomLabelOption' }
            & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
          ) }
        )>, steps: Array<(
          { __typename?: 'StepForRoom' }
          & Pick<StepForRoom, 'id' | 'stepDetailId' | 'roomId' | 'amount' | 'length' | 'width' | 'isTreadOnly' | 'isLanding' | 'shouldPromptAboutSpindles'>
        )> }
      )> }
    )>, price?: Maybe<(
      { __typename?: 'Price' }
      & Pick<Price, 'mSRP' | 'savings' | 'total'>
    )>, discounts?: Maybe<(
      { __typename?: 'DiscountsOnJob' }
      & Pick<DiscountsOnJob, 'id' | 'isOverrideApplied'>
      & { discounts: Array<(
        { __typename?: 'AppliedDiscount' }
        & Pick<AppliedDiscount, 'id' | 'amount' | 'discountId' | 'isScalar' | 'isValid' | 'jobConfigurationId' | 'name'>
      )> }
    )> }
  ) }
);

export type GetSellSheetHeaderColumnQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetSellSheetHeaderColumnQuery = (
  { __typename?: 'Query' }
  & { jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id' | 'jobId'>
    & { price?: Maybe<(
      { __typename?: 'Price' }
      & Pick<Price, 'total'>
    )>, financing?: Maybe<(
      { __typename?: 'Financing' }
      & Pick<Financing, 'id' | 'financingDownPayment' | 'perMonthAmount'>
      & { financingOption?: Maybe<(
        { __typename?: 'FinancingOption' }
        & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
      )> }
    )>, discounts?: Maybe<(
      { __typename?: 'DiscountsOnJob' }
      & Pick<DiscountsOnJob, 'id' | 'isOverrideApplied'>
      & { availableDiscounts: Array<(
        { __typename?: 'Discount' }
        & DiscountFragment
      )>, discounts: Array<(
        { __typename?: 'AppliedDiscount' }
        & Pick<AppliedDiscount, 'id' | 'amount' | 'discountId' | 'isScalar' | 'isValid' | 'jobConfigurationId' | 'name'>
      )> }
    )> }
  ) }
);

export type GetSellSheetRenderColumnQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetSellSheetRenderColumnQuery = (
  { __typename?: 'Query' }
  & { jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id' | 'jobId'>
    & { discounts?: Maybe<(
      { __typename?: 'DiscountsOnJob' }
      & Pick<DiscountsOnJob, 'id' | 'isOverrideApplied'>
      & { availableDiscounts: Array<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'id' | 'defaultValue' | 'isScalar' | 'name'>
      )>, discounts: Array<(
        { __typename?: 'AppliedDiscount' }
        & Pick<AppliedDiscount, 'id' | 'amount' | 'discountId' | 'isScalar' | 'isValid' | 'jobConfigurationId' | 'name'>
      )> }
    )> }
  ) }
);

export type GetSellSheetTotalAreaComboColumnQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetSellSheetTotalAreaComboColumnQuery = (
  { __typename?: 'Query' }
  & { jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id' | 'jobId'>
    & { areas: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'id' | 'productTypeId' | 'productTypeName' | 'styleId' | 'includedInQuote' | 'preferredCarpetLength' | 'oppositeCarpetLength'>
      & { price?: Maybe<(
        { __typename?: 'Price' }
        & Pick<Price, 'mSRP' | 'savings' | 'total'>
      )>, rooms: Array<(
        { __typename?: 'Room' }
        & Pick<Room, 'id'>
        & { labels: Array<(
          { __typename?: 'LabelForRoom' }
          & Pick<LabelForRoom, 'id' | 'customName' | 'hasCloset' | 'labelNumber' | 'roomId'>
          & { label: (
            { __typename?: 'RoomLabelOption' }
            & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
          ) }
        )> }
      )> }
    )>, price?: Maybe<(
      { __typename?: 'Price' }
      & Pick<Price, 'mSRP' | 'savings' | 'total'>
    )>, financing?: Maybe<(
      { __typename?: 'Financing' }
      & Pick<Financing, 'id' | 'financingDownPayment' | 'perMonthAmount'>
      & { financingOption?: Maybe<(
        { __typename?: 'FinancingOption' }
        & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
      )> }
    )>, discounts?: Maybe<(
      { __typename?: 'DiscountsOnJob' }
      & Pick<DiscountsOnJob, 'id' | 'isOverrideApplied'>
      & { discounts: Array<(
        { __typename?: 'AppliedDiscount' }
        & Pick<AppliedDiscount, 'id' | 'amount' | 'discountId' | 'isScalar' | 'isValid' | 'jobConfigurationId' | 'name'>
      )> }
    )> }
  ) }
);

export type GetSellSheetTotalColumnQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetSellSheetTotalColumnQuery = (
  { __typename?: 'Query' }
  & { jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & Pick<JobConfiguration, 'id' | 'jobId'>
    & { price?: Maybe<(
      { __typename?: 'Price' }
      & Pick<Price, 'mSRP' | 'savings' | 'total'>
    )>, financing?: Maybe<(
      { __typename?: 'Financing' }
      & Pick<Financing, 'id' | 'financingDownPayment' | 'perMonthAmount'>
      & { financingOption?: Maybe<(
        { __typename?: 'FinancingOption' }
        & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
      )> }
    )>, discounts?: Maybe<(
      { __typename?: 'DiscountsOnJob' }
      & Pick<DiscountsOnJob, 'id' | 'isOverrideApplied'>
      & { discounts: Array<(
        { __typename?: 'AppliedDiscount' }
        & Pick<AppliedDiscount, 'id' | 'amount' | 'discountId' | 'isScalar' | 'isValid' | 'jobConfigurationId' | 'name'>
      )> }
    )> }
  ) }
);

export type AddQuoteForJobMutationVariables = Exact<{
  followUpDate: Scalars['DateTime'];
  jobConfigurationId: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  emails: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddQuoteForJobMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addQuoteForJob'>
);

export type TrimInvalidFieldsFromJobConfigurationMutationVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type TrimInvalidFieldsFromJobConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'trimInvalidFieldsFromJobConfiguration'>
);

export type UpdateCustomerDetailsMutationVariables = Exact<{
  updatedCustomer: CustomerInput;
}>;


export type UpdateCustomerDetailsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCustomerDetails'>
);

export type UpdateJobConfigurationNotesMutationVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  notes: Scalars['String'];
  replace: Scalars['Boolean'];
}>;


export type UpdateJobConfigurationNotesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateJobConfigurationNotes'>
);

export type UpdateJobDiscountMutationVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  discountId: Scalars['Int'];
  overrideAmount?: Maybe<Scalars['Decimal']>;
  isRemoval: Scalars['Boolean'];
}>;


export type UpdateJobDiscountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDiscount'>
);

export type UpdateJobFinancingMutationVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  financingOptionId?: Maybe<Scalars['Int']>;
  downPaymentAmount?: Maybe<Scalars['Decimal']>;
}>;


export type UpdateJobFinancingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFinancing'>
);

export type GetAllServiceMaterialCategoriesForProductQueryVariables = Exact<{
  productTypeId: Scalars['Int'];
}>;


export type GetAllServiceMaterialCategoriesForProductQuery = (
  { __typename?: 'Query' }
  & { allServiceMaterialCategoriesForProduct: Array<(
    { __typename?: 'ServiceMaterialCategory' }
    & Pick<ServiceMaterialCategory, 'id' | 'name' | 'priceUnit' | 'packageSize' | 'packageSizeIncrement'>
  )> }
);

export type GetAllServiceProductOfferingsQueryVariables = Exact<{
  isDeleted?: Maybe<Scalars['Boolean']>;
  productTypeId?: Maybe<Scalars['Int']>;
  serviceTypeId?: Maybe<Scalars['Int']>;
}>;


export type GetAllServiceProductOfferingsQuery = (
  { __typename?: 'Query' }
  & { allServiceProductOfferings: Array<(
    { __typename?: 'ServiceLaborRule' }
    & Pick<ServiceLaborRule, 'id' | 'productType' | 'serviceType' | 'description' | 'jobServiceId' | 'priceUnit' | 'costPerUnit' | 'pricePerUnit' | 'minimumInUnit' | 'isDeleted'>
  )> }
);

export type GetAllServiceProductOfferingsForJobServiceQueryVariables = Exact<{
  jobServiceId: Scalars['Int'];
}>;


export type GetAllServiceProductOfferingsForJobServiceQuery = (
  { __typename?: 'Query' }
  & { allServiceProductOfferingsForJobService: Array<(
    { __typename?: 'ServiceProductOffering' }
    & Pick<ServiceProductOffering, 'id' | 'jobServiceId' | 'costPerUnit' | 'pricePerUnit' | 'minimumInUnit' | 'productTypeId' | 'productType' | 'isDeleted'>
  )> }
);

export type GetChargeableServicePricingDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetChargeableServicePricingDetailsQuery = (
  { __typename?: 'Query' }
  & { chargeableServicePricingDetails: Array<(
    { __typename?: 'ChargeableServicePricingDetails' }
    & Pick<ChargeableServicePricingDetails, 'id' | 'jobServiceId' | 'laborPricePerUnit' | 'minimumLaborAmount' | 'materialPricePerUnit' | 'materialCategoryId' | 'materialPackageSize'>
  )> }
);

export type GetInstallationJobServicesQueryVariables = Exact<{
  productTypeId: Scalars['Int'];
}>;


export type GetInstallationJobServicesQuery = (
  { __typename?: 'Query' }
  & { installationJobServices: Array<(
    { __typename?: 'JobService' }
    & Pick<JobService, 'id' | 'serviceTypeId' | 'description' | 'priceUnit'>
  )> }
);

export type GetJobServicesByTypeQueryVariables = Exact<{
  serviceTypeId: Scalars['Int'];
}>;


export type GetJobServicesByTypeQuery = (
  { __typename?: 'Query' }
  & { jobServicesByType: Array<(
    { __typename?: 'JobService' }
    & Pick<JobService, 'id' | 'serviceTypeId' | 'description' | 'priceUnit'>
  )> }
);

export type GetMetadataOfferingsForProductAndServiceQueryVariables = Exact<{
  productTypeId: Scalars['Int'];
  jobServiceId: Scalars['Int'];
}>;


export type GetMetadataOfferingsForProductAndServiceQuery = (
  { __typename?: 'Query' }
  & { metadataOfferingsForProductAndService: Array<(
    { __typename?: 'MaterialForServiceOffering' }
    & Pick<MaterialForServiceOffering, 'id' | 'serviceProductOfferingId' | 'serviceMaterialCategory' | 'serviceMaterialCategoryId' | 'materialPriceUnit' | 'defaultAmount' | 'isDefaultMaterial'>
  )> }
);

export type GetServiceLaborRuleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetServiceLaborRuleQuery = (
  { __typename?: 'Query' }
  & { serviceLaborRule: (
    { __typename?: 'ServiceLaborRule' }
    & Pick<ServiceLaborRule, 'id' | 'productType' | 'serviceType' | 'description' | 'jobServiceId' | 'priceUnit' | 'costPerUnit' | 'pricePerUnit' | 'minimumInUnit' | 'isDeleted'>
  ) }
);

export type GetServicePricingUnitNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServicePricingUnitNamesQuery = (
  { __typename?: 'Query' }
  & { names: Query['servicePricingUnitEnum'] }
);

export type GetServiceProductOfferingIdsQueryVariables = Exact<{
  isDeleted?: Maybe<Scalars['Boolean']>;
  productTypeId?: Maybe<Scalars['Int']>;
  serviceTypeId?: Maybe<Scalars['Int']>;
}>;


export type GetServiceProductOfferingIdsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'serviceProductOfferingIds'>
);

export type GetServiceTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServiceTypesQuery = (
  { __typename?: 'Query' }
  & { options: Array<(
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'id' | 'name'>
  )> }
);

export type AddMaterialToJobServiceMutationVariables = Exact<{
  materialCategoryId: Scalars['Int'];
  jobServiceId: Scalars['Int'];
  productTypeId: Scalars['Int'];
}>;


export type AddMaterialToJobServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addMaterialToJobService'>
);

export type AddMultipleOfferingsToExistingServiceMutationVariables = Exact<{
  jobServiceId: Scalars['Int'];
  productValues: Array<ProductAndValuesInput> | ProductAndValuesInput;
}>;


export type AddMultipleOfferingsToExistingServiceMutation = (
  { __typename?: 'Mutation' }
  & { addMultipleOfferingsToExistingService: (
    { __typename?: 'AddLaborRulesPayload' }
    & Pick<AddLaborRulesPayload, 'addedIds' | 'errorProductIds'>
  ) }
);

export type AddMultipleOfferingsToNewServiceMutationVariables = Exact<{
  serviceTypeId: Scalars['Int'];
  description: Scalars['String'];
  priceUnit: Scalars['String'];
  productValues: Array<ProductAndValuesInput> | ProductAndValuesInput;
}>;


export type AddMultipleOfferingsToNewServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addMultipleOfferingsToNewService'>
);

export type RemoveMaterialFromJobServiceMutationVariables = Exact<{
  materialForServiceOfferingId: Scalars['Int'];
}>;


export type RemoveMaterialFromJobServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeMaterialFromJobService'>
);

export type SetDefaultMaterialForJobServiceMutationVariables = Exact<{
  materialForServiceOfferingId: Scalars['Int'];
  isDefault: Scalars['Boolean'];
}>;


export type SetDefaultMaterialForJobServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDefaultMaterialForJobService'>
);

export type UpdateServiceProductOfferingMutationVariables = Exact<{
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Decimal']>;
  cost?: Maybe<Scalars['Decimal']>;
  minimum?: Maybe<Scalars['Decimal']>;
}>;


export type UpdateServiceProductOfferingMutation = (
  { __typename?: 'Mutation' }
  & { updateServiceProductOffering: (
    { __typename?: 'UpdateServiceProductOfferingPayload' }
    & Pick<UpdateServiceProductOfferingPayload, 'similar'>
    & { updated: (
      { __typename?: 'ServiceProductOffering' }
      & Pick<ServiceProductOffering, 'id' | 'isDeleted' | 'pricePerUnit' | 'costPerUnit' | 'minimumInUnit'>
    ) }
  ) }
);

export type GetSubstrateIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubstrateIdQuery = (
  { __typename?: 'Query' }
  & { substrateId: Array<(
    { __typename?: 'SubstrateId' }
    & Pick<SubstrateId, 'id' | 'type'>
  )> }
);

export type TryRemoveLaborRuleMutationVariables = Exact<{
  ruleId: Scalars['Int'];
}>;


export type TryRemoveLaborRuleMutation = (
  { __typename?: 'Mutation' }
  & { tryRemoveLaborRule: (
    { __typename?: 'TryRemoveLaborRulePayload' }
    & Pick<TryRemoveLaborRulePayload, 'isSuccessful' | 'message'>
  ) }
);

export type GetMeasurementUnitQueryVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetMeasurementUnitQuery = (
  { __typename?: 'Query' }
  & { measurementUnit: Array<(
    { __typename?: 'MeasurementUnitOptionFull' }
    & Pick<MeasurementUnitOptionFull, 'id' | 'name' | 'short' | 'long'>
  )> }
);

export type GetAcceptedPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAcceptedPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { acceptedPaymentMethods: Array<(
    { __typename?: 'AcceptedPaymentMethod' }
    & Pick<AcceptedPaymentMethod, 'id' | 'method'>
  )> }
);

export type GetAllProductStyleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProductStyleQuery = (
  { __typename?: 'Query' }
  & { allProductStyle: Array<(
    { __typename?: 'ProductStyles' }
    & Pick<ProductStyles, 'id' | 'productTypeId' | 'styleName' | 'productSpecificationId' | 'singleCostPerSqft' | 'bulkCostPerSqft' | 'pricingFactor' | 'shippingPrice'>
  )> }
);

export type GetPartialProductStyleQueryVariables = Exact<{
  styleId: Scalars['Int'];
}>;


export type GetPartialProductStyleQuery = (
  { __typename?: 'Query' }
  & { partialProductStyle: (
    { __typename?: 'ProductStyle' }
    & Pick<ProductStyle, 'id' | 'style' | 'singleCostPerSqft' | 'bulkCostPerSqft' | 'pricingFactor' | 'shippingPrice'>
    & { spec: (
      { __typename?: 'ProductSpecification' }
      & Pick<ProductSpecification, 'id'>
    ), promo?: Maybe<(
      { __typename?: 'StylePromo' }
      & Pick<StylePromo, 'promoPrice' | 'startDate' | 'endDate'>
    )> }
  ) }
);

export type GetPartialProductStylesQueryVariables = Exact<{
  productTypeId: Scalars['Int'];
  vendorId?: Maybe<Scalars['Int']>;
}>;


export type GetPartialProductStylesQuery = (
  { __typename?: 'Query' }
  & { partialProductStyles: Array<(
    { __typename?: 'ProductStyle' }
    & Pick<ProductStyle, 'id' | 'style' | 'singleCostPerSqft' | 'bulkCostPerSqft' | 'pricingFactor' | 'shippingPrice'>
    & { spec: (
      { __typename?: 'ProductSpecification' }
      & Pick<ProductSpecification, 'id'>
    ), promo?: Maybe<(
      { __typename?: 'StylePromo' }
      & Pick<StylePromo, 'promoPrice' | 'startDate' | 'endDate'>
    )> }
  )> }
);

export type GetProductColorOptionsQueryVariables = Exact<{
  styleId: Scalars['Int'];
}>;


export type GetProductColorOptionsQuery = (
  { __typename?: 'Query' }
  & { productColorOptions: Array<(
    { __typename?: 'ProductColorOption' }
    & Pick<ProductColorOption, 'id' | 'color'>
  )> }
);

export type GetProductColorsAndSourcesQueryVariables = Exact<{
  styleId: Scalars['Int'];
  vendorId?: Maybe<Scalars['Int']>;
}>;


export type GetProductColorsAndSourcesQuery = (
  { __typename?: 'Query' }
  & { productColorsAndSources: Array<(
    { __typename?: 'SourcedProduct' }
    & Pick<SourcedProduct, 'id' | 'color'>
    & { source: Array<(
      { __typename?: 'VendorSourcedProduct' }
      & Pick<VendorSourcedProduct, 'id' | 'vendorId' | 'productSpecificationId' | 'style' | 'color' | 'orderQuantity' | 'singleCostPerSqft' | 'bulkCostPerSqft' | 'oneTimeCostPerSqft'>
    )> }
  )> }
);

export type GetProductSpecQueryVariables = Exact<{
  productSpecId: Scalars['Int'];
}>;


export type GetProductSpecQuery = (
  { __typename?: 'Query' }
  & { productSpec: (
    { __typename?: 'ProductSpecification' }
    & Pick<ProductSpecification, 'id' | 'specName' | 'sqft'>
    & { structuredProductDetails: Array<(
      { __typename?: 'StructuredProductDetail' }
      & Pick<StructuredProductDetail, 'structuredProductDetailOptionId' | 'measurementValue' | 'measurementUnitId'>
    )>, unstructuredProductDetails: Array<(
      { __typename?: 'UnstructuredProductDetail' }
      & Pick<UnstructuredProductDetail, 'unstructuredProductDetailOptionId' | 'value'>
    )>, installationConfigurations: Array<(
      { __typename?: 'ProductCanBeInstalled' }
      & Pick<ProductCanBeInstalled, 'jobServiceId' | 'substrateId'>
    )> }
  ) }
);

export type GetProductSpecForStyleQueryVariables = Exact<{
  styleId: Scalars['Int'];
}>;


export type GetProductSpecForStyleQuery = (
  { __typename?: 'Query' }
  & { productSpecForStyle: (
    { __typename?: 'ProductSpecification' }
    & Pick<ProductSpecification, 'id' | 'specName' | 'sqft'>
    & { structuredProductDetails: Array<(
      { __typename?: 'StructuredProductDetail' }
      & Pick<StructuredProductDetail, 'structuredProductDetailOptionId' | 'measurementValue' | 'measurementUnitId'>
    )>, unstructuredProductDetails: Array<(
      { __typename?: 'UnstructuredProductDetail' }
      & Pick<UnstructuredProductDetail, 'unstructuredProductDetailOptionId' | 'value'>
    )>, installationConfigurations: Array<(
      { __typename?: 'ProductCanBeInstalled' }
      & Pick<ProductCanBeInstalled, 'jobServiceId' | 'substrateId'>
    )> }
  ) }
);

export type GetProductSpecNamesQueryVariables = Exact<{
  productTypeId?: Scalars['Int'];
  styleId?: Scalars['Int'];
  colorId?: Scalars['Int'];
}>;


export type GetProductSpecNamesQuery = (
  { __typename?: 'Query' }
  & { productSpecNames: Array<(
    { __typename?: 'ProductSpecName' }
    & Pick<ProductSpecName, 'id' | 'color' | 'style' | 'type'>
    & { specName: ProductSpecName['name'] }
  )> }
);

export type GetProductStyleQueryVariables = Exact<{
  styleId: Scalars['Int'];
}>;


export type GetProductStyleQuery = (
  { __typename?: 'Query' }
  & { productStyle: (
    { __typename?: 'ProductStyle' }
    & Pick<ProductStyle, 'id' | 'style' | 'singleCostPerSqft' | 'bulkCostPerSqft'>
    & { spec: (
      { __typename?: 'ProductSpecification' }
      & Pick<ProductSpecification, 'id' | 'specName' | 'sqft'>
      & { structuredProductDetails: Array<(
        { __typename?: 'StructuredProductDetail' }
        & Pick<StructuredProductDetail, 'structuredProductDetailOptionId' | 'measurementValue' | 'measurementUnitId'>
      )>, unstructuredProductDetails: Array<(
        { __typename?: 'UnstructuredProductDetail' }
        & Pick<UnstructuredProductDetail, 'unstructuredProductDetailOptionId' | 'value'>
      )>, installationConfigurations: Array<(
        { __typename?: 'ProductCanBeInstalled' }
        & Pick<ProductCanBeInstalled, 'jobServiceId' | 'substrateId'>
      )> }
    ) }
  ) }
);

export type GetProductStyleIdsQueryVariables = Exact<{
  productTypeId: Scalars['Int'];
  vendorId?: Maybe<Scalars['Int']>;
}>;


export type GetProductStyleIdsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'productStyleIdsForProductType'>
);

export type GetProductStyleOptionsQueryVariables = Exact<{
  typeId: Scalars['Int'];
}>;


export type GetProductStyleOptionsQuery = (
  { __typename?: 'Query' }
  & { productStyleOptions: Array<(
    { __typename?: 'ProductStyleOption' }
    & Pick<ProductStyleOption, 'id' | 'style'>
  )> }
);

export type GetProductTypeOptionsQueryVariables = Exact<{
  surface?: Maybe<SurfaceType>;
}>;


export type GetProductTypeOptionsQuery = (
  { __typename?: 'Query' }
  & { options: Array<(
    { __typename?: 'ProductTypeOption' }
    & Pick<ProductTypeOption, 'id' | 'type'>
  )> }
);

export type GetStructuredProductDetailOptionsQueryVariables = Exact<{
  productTypeId: Scalars['Int'];
}>;


export type GetStructuredProductDetailOptionsQuery = (
  { __typename?: 'Query' }
  & { structuredProductDetailOptions: Array<(
    { __typename?: 'StructuredProductDetailOption' }
    & Pick<StructuredProductDetailOption, 'id' | 'name'>
    & { unitOptions: Array<(
      { __typename?: 'MeasurementUnitOption' }
      & Pick<MeasurementUnitOption, 'unitId' | 'unit'>
    )> }
  )> }
);

export type GetUnstructuredProductDetailOptionsQueryVariables = Exact<{
  productTypeId: Scalars['Int'];
}>;


export type GetUnstructuredProductDetailOptionsQuery = (
  { __typename?: 'Query' }
  & { unstructuredProductDetailOptions: Array<(
    { __typename?: 'UnstructuredProductDetailOption' }
    & Pick<UnstructuredProductDetailOption, 'id' | 'name'>
  )> }
);

export type GetVendorsForColorQueryVariables = Exact<{
  colorIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetVendorsForColorQuery = (
  { __typename?: 'Query' }
  & { vendorsForColors: Array<(
    { __typename?: 'ColorIdAndVendors' }
    & Pick<ColorIdAndVendors, 'id'>
    & { vendors: Array<(
      { __typename?: 'VendorName' }
      & Pick<VendorName, 'id' | 'name'>
    )> }
  )> }
);

export type AddNewColorMutationVariables = Exact<{
  styleId: Scalars['Int'];
  colorName: Scalars['String'];
  specName: Scalars['String'];
  specDetails: ProductSpecificationInput;
  vendor: VendorSourcedProductInput;
}>;


export type AddNewColorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addNewColor'>
);

export type AddNewProductSourceMutationVariables = Exact<{
  productSpecId: Scalars['Int'];
  vendor: VendorSourcedProductInput;
}>;


export type AddNewProductSourceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addNewProductSource'>
);

export type AddNewProductSpecMutationVariables = Exact<{
  colorId: Scalars['Int'];
  specName: Scalars['String'];
  specDetails: ProductSpecificationInput;
  vendor: VendorSourcedProductInput;
}>;


export type AddNewProductSpecMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addNewProductSpec'>
);

export type AddNewStyleMutationVariables = Exact<{
  productTypeId: Scalars['Int'];
  styleName: Scalars['String'];
  singleCostPerSqft: Scalars['Decimal'];
  bulkCostPerSqft: Scalars['Decimal'];
  specName: Scalars['String'];
  sqft: Scalars['Decimal'];
  structuredProductDetails: Array<StructuredProductDetailInput> | StructuredProductDetailInput;
  unstructuredProductDetails: Array<UnstructuredProductDetailInput> | UnstructuredProductDetailInput;
  installationConfigurations: Array<ProductCanBeInstalledInput> | ProductCanBeInstalledInput;
}>;


export type AddNewStyleMutation = (
  { __typename?: 'Mutation' }
  & { addNewStyle: (
    { __typename?: 'ProductStyle' }
    & Pick<ProductStyle, 'id' | 'style' | 'singleCostPerSqft' | 'bulkCostPerSqft'>
    & { spec: (
      { __typename?: 'ProductSpecification' }
      & Pick<ProductSpecification, 'id' | 'specName'>
    ) }
  ) }
);

export type BulkAddNewCarpetColorsMutationVariables = Exact<{
  styleId: Scalars['Int'];
  vendorId: Scalars['Int'];
  vendorStyle: Scalars['String'];
  orderSize: Scalars['Decimal'];
  pairs: Array<ColorNamePairInput> | ColorNamePairInput;
  singleCost?: Maybe<Scalars['Decimal']>;
  bulkCost?: Maybe<Scalars['Decimal']>;
}>;


export type BulkAddNewCarpetColorsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bulkAddNewCarpetColors'>
);

export type UpdateProductCostsMutationVariables = Exact<{
  products: Array<ProductIdentifiersWithCostsInput> | ProductIdentifiersWithCostsInput;
  isSSMode: Scalars['Boolean'];
}>;


export type UpdateProductCostsMutation = (
  { __typename?: 'Mutation' }
  & { updateProductCosts: Array<(
    { __typename?: 'ProductIdentifiersWithCosts' }
    & Pick<ProductIdentifiersWithCosts, 'styleId' | 'sourceId' | 'singleCost' | 'bulkCost' | 'oneTimeCost'>
  )> }
);

export type UpdateProductPricesMutationVariables = Exact<{
  products: Array<ProductIdentifierWithPriceInput> | ProductIdentifierWithPriceInput;
  interpretCarpetShippingInSqyd?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateProductPricesMutation = (
  { __typename?: 'Mutation' }
  & { updateProductPrices: Array<(
    { __typename?: 'ProductIdentifierWithPrice' }
    & Pick<ProductIdentifierWithPrice, 'styleId' | 'updatedFactor' | 'updatedShipping'>
    & { updatedPromo?: Maybe<(
      { __typename?: 'StylePromo' }
      & Pick<StylePromo, 'promoPrice' | 'startDate' | 'endDate'>
    )> }
  )> }
);

export type GetActiveJobsForDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveJobsForDashboardQuery = (
  { __typename?: 'Query' }
  & { activeJobsForDashboard: Array<(
    { __typename?: 'JobConfigAndCustomerName' }
    & Pick<JobConfigAndCustomerName, 'jobConfigId' | 'firstName' | 'lastName'>
  )> }
);

export type GetAllAreaAndRoomNotesQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetAllAreaAndRoomNotesQuery = (
  { __typename?: 'Query' }
  & { allAreaAndRoomNotes: Array<(
    { __typename?: 'AreaAndRoomNotes' }
    & { areaNotes?: Maybe<(
      { __typename?: 'LabeledNote' }
      & Pick<LabeledNote, 'id' | 'notes'>
      & { labels: Array<(
        { __typename?: 'LabelForRoom' }
        & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'labelNumber' | 'customName'>
        & { label: (
          { __typename?: 'RoomLabelOption' }
          & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
        ) }
      )> }
    )>, roomNotes: Array<(
      { __typename?: 'LabeledNote' }
      & Pick<LabeledNote, 'id' | 'notes'>
      & { labels: Array<(
        { __typename?: 'LabelForRoom' }
        & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'labelNumber' | 'customName'>
        & { label: (
          { __typename?: 'RoomLabelOption' }
          & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
        ) }
      )> }
    )> }
  )> }
);

export type GetAllAreaProductDetailsForJobQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetAllAreaProductDetailsForJobQuery = (
  { __typename?: 'Query' }
  & { allAreaProductDetailsForJob: Array<(
    { __typename?: 'AreaProductDetail' }
    & Pick<AreaProductDetail, 'id' | 'productTypeId' | 'styleId' | 'colorId' | 'includedInQuote'>
  )> }
);

export type GetAllAreasForJobQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetAllAreasForJobQuery = (
  { __typename?: 'Query' }
  & { allAreasForJob: Array<(
    { __typename?: 'Area' }
    & Pick<Area, 'id' | 'productTypeId' | 'productTypeName' | 'colorId' | 'colorName' | 'styleId' | 'styleName' | 'sqftWasteFactor' | 'lnftWasteFactor'>
    & { rooms: Array<(
      { __typename?: 'Room' }
      & Pick<Room, 'id'>
      & { labels: Array<(
        { __typename?: 'LabelForRoom' }
        & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'customName' | 'labelNumber'>
        & { label: (
          { __typename?: 'RoomLabelOption' }
          & Pick<RoomLabelOption, 'id' | 'fullLabel' | 'label'>
        ) }
      )> }
    )> }
  )> }
);

export type GetAllInstallationNotesQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetAllInstallationNotesQuery = (
  { __typename?: 'Query' }
  & { allInstallationNotes: Array<(
    { __typename?: 'InstallationNote' }
    & Pick<InstallationNote, 'id' | 'contractorFirstName' | 'contractorLastName' | 'startDate' | 'endDate' | 'notes'>
  )> }
);

export type GetAllJobsForProjectCoordinatorQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllJobsForProjectCoordinatorQuery = (
  { __typename?: 'Query' }
  & { allJobsForProjectCoordinator: Array<(
    { __typename?: 'ProjectCoordinatorJobCard' }
    & Pick<ProjectCoordinatorJobCard, 'id' | 'dateClaimed' | 'sAHNumber' | 'jobCOD' | 'depositThresholdHit' | 'totalPaid' | 'totalPrice' | 'cwcDeadline' | 'cwcNumber'>
    & { projectCoordinator?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName'>
    )>, contract: (
      { __typename?: 'JobContract' }
      & Pick<JobContract, 'id' | 'totalDepositPaid' | 'jobConfigurationId' | 'signingDate' | 'totalPrice'>
    ), financing?: Maybe<(
      { __typename?: 'Financing' }
      & Pick<Financing, 'id' | 'perMonthAmount' | 'financingDownPayment'>
      & { financingOption?: Maybe<(
        { __typename?: 'FinancingOption' }
        & Pick<FinancingOption, 'id'>
      )> }
    )>, customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'businessName' | 'primaryPhone' | 'email'>
      & { primaryAddress: (
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'city'>
      ) }
    ), salesperson?: Maybe<(
      { __typename?: 'Salesperson' }
      & Pick<Salesperson, 'id' | 'workerId' | 'firstName' | 'lastName'>
    )>, installationAppointments: Array<(
      { __typename?: 'GroupedInstallationAppointment' }
      & Pick<GroupedInstallationAppointment, 'id' | 'contractorId' | 'contractorFirstName' | 'contractorLastName' | 'dates' | 'startDatePassed' | 'endDatePassed' | 'isComplete' | 'cod' | 'collectedCod' | 'productTypeId' | 'productType' | 'productTypeIndex'>
      & { styleAmounts: Array<(
        { __typename?: 'StyleAndSqft' }
        & Pick<StyleAndSqft, 'id' | 'style' | 'sqft'>
      )> }
    )>, installationsToSchedule: Array<(
      { __typename?: 'InstallationToSchedule' }
      & Pick<InstallationToSchedule, 'id' | 'productType' | 'sqft' | 'cod' | 'productTypeIndex' | 'jobId'>
    )> }
  )> }
);

export type GetAllProjectCoordinatorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProjectCoordinatorsQuery = (
  { __typename?: 'Query' }
  & { allProjectCoordinators: Array<(
    { __typename?: 'Worker' }
    & WorkerFragment
  )> }
);

export type GetAllRoomsForJobQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetAllRoomsForJobQuery = (
  { __typename?: 'Query' }
  & { allRoomsForJob: Array<(
    { __typename?: 'Room' }
    & Pick<Room, 'id' | 'areaId' | 'sqft' | 'lnft' | 'substrateId'>
    & { labels: Array<(
      { __typename?: 'LabelForRoom' }
      & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'customName' | 'labelNumber'>
      & { label: (
        { __typename?: 'RoomLabelOption' }
        & Pick<RoomLabelOption, 'id' | 'fullLabel' | 'label'>
      ) }
    )> }
  )> }
);

export type GetAppointmentWorkOrderQueryVariables = Exact<{
  installationAppointmentId: Scalars['Int'];
  jobConfigurationId: Scalars['Int'];
}>;


export type GetAppointmentWorkOrderQuery = (
  { __typename?: 'Query' }
  & { appointmentWorkOrder: (
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'jobNotes' | 'installationNotes' | 'installationAppointmentId' | 'hasFinancing' | 'remainingCod' | 'dates'>
    & { contractor: (
      { __typename?: 'Contractor' }
      & Pick<Contractor, 'id' | 'firstName' | 'lastName'>
    ), areaBreakdowns: Array<(
      { __typename?: 'AreaBreakdown' }
      & Pick<AreaBreakdown, 'areaId' | 'sqftWasteFactor' | 'lnftWasteFactor' | 'lineNum' | 'areaNotes' | 'productSqft'>
      & { areaLabels: Array<(
        { __typename?: 'LabelForRoom' }
        & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'customName' | 'labelNumber'>
        & { label: (
          { __typename?: 'RoomLabelOption' }
          & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
        ) }
      )>, rooms?: Maybe<Array<(
        { __typename?: 'Room' }
        & Pick<Room, 'id' | 'sqft' | 'notes'>
        & { labels: Array<(
          { __typename?: 'LabelForRoom' }
          & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'labelNumber' | 'customName'>
          & { label: (
            { __typename?: 'RoomLabelOption' }
            & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
          ) }
        )> }
      )>>, product: (
        { __typename?: 'Product' }
        & Pick<Product, 'productTypeId' | 'productType' | 'productStyle' | 'productColor'>
      ), services: Array<(
        { __typename?: 'ServiceForRoomBreakdown' }
        & Pick<ServiceForRoomBreakdown, 'id' | 'jobServiceId' | 'serviceTypeId' | 'serviceType' | 'serviceDescription' | 'customerDoesService' | 'isActive' | 'laborAmount' | 'minimumLaborAmount' | 'sqftScaleFactor' | 'lnftScaleFactor' | 'laborPriceUnit' | 'laborPricePerUnit' | 'materialAmount' | 'materialCategoryId' | 'materialPricePerUnit' | 'materialCategoryName' | 'materialCategoryPriceUnit'>
        & { room: (
          { __typename?: 'Room' }
          & Pick<Room, 'id' | 'notes' | 'sqft'>
          & { labels: Array<(
            { __typename?: 'LabelForRoom' }
            & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'labelNumber' | 'customName'>
            & { label: (
              { __typename?: 'RoomLabelOption' }
              & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
            ) }
          )> }
        ) }
      )>, customServices: Array<(
        { __typename?: 'CustomService' }
        & Pick<CustomService, 'id' | 'areaId' | 'roomIds' | 'description' | 'price' | 'contractorPercentage'>
      )> }
    )> }
  ) }
);

export type GetCwcNumberQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type GetCwcNumberQuery = (
  { __typename?: 'Query' }
  & { cWCNumber: (
    { __typename?: 'JobIdAndCwcNumber' }
    & Pick<JobIdAndCwcNumber, 'id' | 'cwcNumber'>
  ) }
);

export type GetCwcJobsForProjectCoordinatorQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCwcJobsForProjectCoordinatorQuery = (
  { __typename?: 'Query' }
  & { cwcJobsForProjectCoordinator: Array<(
    { __typename?: 'ProjectCoordinatorJobCard' }
    & Pick<ProjectCoordinatorJobCard, 'id' | 'dateClaimed' | 'sAHNumber' | 'jobCOD' | 'depositThresholdHit' | 'totalPaid' | 'totalPrice' | 'cwcDeadline' | 'cwcNumber'>
    & { projectCoordinator?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn'>
    )>, contract: (
      { __typename?: 'JobContract' }
      & Pick<JobContract, 'totalDepositPaid' | 'id' | 'jobConfigurationId' | 'signingDate' | 'isCancelled' | 'totalPrice'>
    ), financing?: Maybe<(
      { __typename?: 'Financing' }
      & Pick<Financing, 'id' | 'financingDownPayment' | 'financingAccountNumber' | 'perMonthAmount'>
      & { financingOption?: Maybe<(
        { __typename?: 'FinancingOption' }
        & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
      )> }
    )>, customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'businessName' | 'primaryPhone' | 'email'>
      & { primaryAddress: (
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
      ), customerContacts: Array<(
        { __typename?: 'CustomerContact' }
        & CustomerContactFragment
      )> }
    ), salesperson?: Maybe<(
      { __typename?: 'Salesperson' }
      & Pick<Salesperson, 'id' | 'workerId' | 'firstName' | 'email' | 'lastName' | 'isCheckedIn'>
    )>, installationAppointments: Array<(
      { __typename?: 'GroupedInstallationAppointment' }
      & Pick<GroupedInstallationAppointment, 'id' | 'contractorId' | 'contractorFirstName' | 'contractorLastName' | 'dates' | 'startDatePassed' | 'endDatePassed' | 'isComplete' | 'productType' | 'cod' | 'collectedCod' | 'productTypeIndex' | 'productTypeId'>
      & { styleAmounts: Array<(
        { __typename?: 'StyleAndSqft' }
        & Pick<StyleAndSqft, 'id' | 'style' | 'sqft'>
      )> }
    )>, installationsToSchedule: Array<(
      { __typename?: 'InstallationToSchedule' }
      & Pick<InstallationToSchedule, 'id' | 'productType' | 'sqft' | 'cod' | 'productTypeIndex' | 'jobId'>
    )> }
  )> }
);

export type GetInstallationCodBoundsForExistingQueryVariables = Exact<{
  appointmentId: Scalars['Int'];
  newStartDate: Scalars['DateTime'];
}>;


export type GetInstallationCodBoundsForExistingQuery = (
  { __typename?: 'Query' }
  & { installationCodBoundsForExisting: (
    { __typename?: 'InstallationCodBounds' }
    & Pick<InstallationCodBounds, 'lowerBound' | 'upperBound'>
  ) }
);

export type GetInstallationCodBoundsForNewQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  roomIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetInstallationCodBoundsForNewQuery = (
  { __typename?: 'Query' }
  & { installationCodBoundsForNew: (
    { __typename?: 'InstallationCodBounds' }
    & Pick<InstallationCodBounds, 'lowerBound' | 'upperBound'>
  ) }
);

export type GetJobBreakdownQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  hideNoLaborRAndR?: Maybe<Scalars['Boolean']>;
}>;


export type GetJobBreakdownQuery = (
  { __typename?: 'Query' }
  & { jobBreakdown: (
    { __typename?: 'JobBreakdown' }
    & Pick<JobBreakdown, 'jobConfigurationId' | 'notes'>
    & { areaBreakdowns: Array<(
      { __typename?: 'AreaBreakdown' }
      & Pick<AreaBreakdown, 'areaId' | 'lineNum' | 'areaNotes' | 'productSqft'>
      & { areaLabels: Array<(
        { __typename?: 'LabelForRoom' }
        & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'customName' | 'labelNumber'>
        & { label: (
          { __typename?: 'RoomLabelOption' }
          & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
        ) }
      )>, rooms?: Maybe<Array<(
        { __typename?: 'Room' }
        & Pick<Room, 'id' | 'areaId' | 'sqft' | 'notes'>
        & { price?: Maybe<(
          { __typename?: 'Price' }
          & Pick<Price, 'mSRP' | 'savings' | 'total'>
        )>, labels: Array<(
          { __typename?: 'LabelForRoom' }
          & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'labelNumber' | 'customName'>
          & { label: (
            { __typename?: 'RoomLabelOption' }
            & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
          ) }
        )> }
      )>>, product: (
        { __typename?: 'Product' }
        & Pick<Product, 'productTypeId' | 'productType' | 'productStyle' | 'productColor'>
      ), services: Array<(
        { __typename?: 'ServiceForRoomBreakdown' }
        & Pick<ServiceForRoomBreakdown, 'id' | 'jobServiceId' | 'serviceTypeId' | 'serviceType' | 'serviceDescription' | 'customerDoesService' | 'isActive' | 'laborAmount' | 'minimumLaborAmount' | 'sqftScaleFactor' | 'lnftScaleFactor' | 'laborPriceUnit' | 'laborPricePerUnit' | 'materialAmount' | 'materialCategoryId' | 'materialPricePerUnit' | 'materialCategoryName' | 'materialCategoryPriceUnit'>
        & { room: (
          { __typename?: 'Room' }
          & Pick<Room, 'id' | 'notes' | 'sqft'>
          & { labels: Array<(
            { __typename?: 'LabelForRoom' }
            & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'labelNumber' | 'customName'>
            & { label: (
              { __typename?: 'RoomLabelOption' }
              & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
            ) }
          )> }
        ) }
      )>, customServices: Array<(
        { __typename?: 'CustomService' }
        & Pick<CustomService, 'id' | 'areaId' | 'roomIds' | 'description' | 'price' | 'contractorPercentage'>
      )> }
    )> }
  ) }
);

export type GetJobNotesQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetJobNotesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'jobNotes'>
);

export type GetJobWorkOrdersQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetJobWorkOrdersQuery = (
  { __typename?: 'Query' }
  & { jobWorkOrders: Array<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'jobNotes' | 'installationNotes' | 'installationAppointmentId' | 'hasFinancing' | 'remainingCod' | 'dates'>
    & { contractor: (
      { __typename?: 'Contractor' }
      & Pick<Contractor, 'id' | 'firstName' | 'lastName'>
    ), areaBreakdowns: Array<(
      { __typename?: 'AreaBreakdown' }
      & Pick<AreaBreakdown, 'areaId' | 'sqftWasteFactor' | 'lnftWasteFactor' | 'lineNum' | 'areaNotes' | 'productSqft'>
      & { areaLabels: Array<(
        { __typename?: 'LabelForRoom' }
        & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'customName' | 'labelNumber'>
        & { label: (
          { __typename?: 'RoomLabelOption' }
          & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
        ) }
      )>, rooms?: Maybe<Array<(
        { __typename?: 'Room' }
        & Pick<Room, 'id' | 'sqft' | 'notes'>
        & { labels: Array<(
          { __typename?: 'LabelForRoom' }
          & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'labelNumber' | 'customName'>
          & { label: (
            { __typename?: 'RoomLabelOption' }
            & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
          ) }
        )> }
      )>>, product: (
        { __typename?: 'Product' }
        & Pick<Product, 'productTypeId' | 'productType' | 'productStyle' | 'productColor'>
      ), services: Array<(
        { __typename?: 'ServiceForRoomBreakdown' }
        & Pick<ServiceForRoomBreakdown, 'id' | 'jobServiceId' | 'serviceTypeId' | 'serviceType' | 'serviceDescription' | 'customerDoesService' | 'isActive' | 'laborAmount' | 'minimumLaborAmount' | 'sqftScaleFactor' | 'lnftScaleFactor' | 'laborPriceUnit' | 'laborPricePerUnit' | 'materialAmount' | 'materialCategoryId' | 'materialPricePerUnit' | 'materialCategoryName' | 'materialCategoryPriceUnit'>
        & { room: (
          { __typename?: 'Room' }
          & Pick<Room, 'id' | 'notes' | 'sqft'>
          & { labels: Array<(
            { __typename?: 'LabelForRoom' }
            & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'labelNumber' | 'customName'>
            & { label: (
              { __typename?: 'RoomLabelOption' }
              & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
            ) }
          )> }
        ) }
      )>, customServices: Array<(
        { __typename?: 'CustomService' }
        & Pick<CustomService, 'id' | 'areaId' | 'roomIds' | 'description' | 'price' | 'contractorPercentage'>
      )> }
    )> }
  )> }
);

export type GetPaymentsPaneInfoQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  jobContractId: Scalars['Int'];
}>;


export type GetPaymentsPaneInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'financedAmount'>
  & { jobCOD: (
    { __typename?: 'ContractCODData' }
    & Pick<ContractCodData, 'id' | 'remaining'>
  ), jobConfiguration: (
    { __typename?: 'JobConfiguration' }
    & { price?: Maybe<(
      { __typename?: 'Price' }
      & Pick<Price, 'total'>
    )>, financing?: Maybe<(
      { __typename?: 'Financing' }
      & Pick<Financing, 'id' | 'perMonthAmount'>
      & { financingOption?: Maybe<(
        { __typename?: 'FinancingOption' }
        & Pick<FinancingOption, 'id' | 'term' | 'minAmount' | 'typeId' | 'fee' | 'startDate' | 'endDate' | 'tranCode' | 'promoParentTranCode' | 'slot'>
      )> }
    )> }
  ), jobPayments: Array<(
    { __typename?: 'JobContractPayment' }
    & Pick<JobContractPayment, 'id' | 'amount'>
  )> }
);

export type GetPicturesForServiceOrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPicturesForServiceOrderQuery = (
  { __typename?: 'Query' }
  & { picturesForServiceOrder: Array<(
    { __typename?: 'PictureForServiceOrder' }
    & Pick<PictureForServiceOrder, 'id' | 'serviceOrderId'>
    & { picture?: Maybe<(
      { __typename?: 'Picture' }
      & Pick<Picture, 'id' | 'description'>
    )> }
  )> }
);

export type GetProductTypesInConfigurationWithSqftQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetProductTypesInConfigurationWithSqftQuery = (
  { __typename?: 'Query' }
  & { productTypesInConfigurationWithSqft: Array<(
    { __typename?: 'ProductTypeWithSqft' }
    & Pick<ProductTypeWithSqft, 'id' | 'type' | 'sqft'>
  )> }
);

export type GetProjectCoordinatorFromConfigurationQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetProjectCoordinatorFromConfigurationQuery = (
  { __typename?: 'Query' }
  & { projectCoordinatorFromConfiguration?: Maybe<(
    { __typename?: 'Worker' }
    & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email'>
  )> }
);

export type GetServiceOrderQueryVariables = Exact<{
  serviceOrderId: Scalars['Int'];
}>;


export type GetServiceOrderQuery = (
  { __typename?: 'Query' }
  & { serviceOrder?: Maybe<(
    { __typename?: 'ServiceOrder' }
    & Pick<ServiceOrder, 'id' | 'jobContractId' | 'serviceOrderTypeId' | 'serviceDescription' | 'calledInDate' | 'scheduledDate' | 'scheduleAsap' | 'completionDate' | 'vendorId' | 'contractorId' | 'comments' | 'additionalActionRequired' | 'additionalActionDescription' | 'chargeAmount' | 'areaId' | 'roomIds'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'productTypeId' | 'productType' | 'productStyleId' | 'productStyle' | 'productColorId' | 'productColor'>
    )> }
  )> }
);

export type GetServiceOrderStubsQueryVariables = Exact<{
  jobContractId: Scalars['Int'];
}>;


export type GetServiceOrderStubsQuery = (
  { __typename?: 'Query' }
  & { serviceOrderStubs: Array<(
    { __typename?: 'ServiceOrderStub' }
    & Pick<ServiceOrderStub, 'id' | 'dateCalledIn'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'productType' | 'productStyle' | 'productColor'>
    ), pictures: Array<(
      { __typename?: 'PictureForServiceOrder' }
      & Pick<PictureForServiceOrder, 'id'>
      & { picture?: Maybe<(
        { __typename?: 'Picture' }
        & Pick<Picture, 'id' | 'description'>
      )> }
    )> }
  )> }
);

export type GetServiceOrderTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServiceOrderTypesQuery = (
  { __typename?: 'Query' }
  & { serviceOrderTypes: Array<(
    { __typename?: 'ServiceOrderType' }
    & Pick<ServiceOrderType, 'id' | 'type'>
  )> }
);

export type AddPicturesForServiceOrderMutationVariables = Exact<{
  serviceOrderId: Scalars['Int'];
  pictures: Array<PictureInput> | PictureInput;
}>;


export type AddPicturesForServiceOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addPicturesForServiceOrder'>
);

export type AddServiceOrderMutationVariables = Exact<{
  serviceOrder: ServiceOrderInput;
}>;


export type AddServiceOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addServiceOrder'>
);

export type AssignProjectCoordinatorMutationVariables = Exact<{
  workerId: Scalars['Int'];
  jobId: Scalars['Int'];
}>;


export type AssignProjectCoordinatorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignProjectCoordinator'>
);

export type ClaimJobMutationVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type ClaimJobMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'claimJob'>
);

export type DeletePictureForServiceOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePictureForServiceOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePictureForServiceOrder'>
);

export type DeleteServiceOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteServiceOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteServiceOrder'>
);

export type MakeCallMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
  alternativeUserEmail?: Maybe<Scalars['String']>;
}>;


export type MakeCallMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'makeCall'>
);

export type SubmitChangeOrderMutationVariables = Exact<{
  originalJobConfigurationId: Scalars['Int'];
  scaledUpdatedJobConfiguration: JobConfigurationInput;
}>;


export type SubmitChangeOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitChangeOrder'>
);

export type UpdateCwcMutationVariables = Exact<{
  jobId: Scalars['Int'];
  numWeeks?: Maybe<Scalars['Int']>;
}>;


export type UpdateCwcMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCWC'>
);

export type UpdateServiceOrderMutationVariables = Exact<{
  serviceOrder: ServiceOrderInput;
  roomIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type UpdateServiceOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateServiceOrder'>
);

export type UpdateServiceOrderScheduledDateMutationVariables = Exact<{
  serviceOrderId: Scalars['Int'];
  scheduledDate: Scalars['DateTime'];
  contractorId: Scalars['Int'];
}>;


export type UpdateServiceOrderScheduledDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateServiceOrderScheduledDate'>
);

export type GetAllJobsInRecoveryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllJobsInRecoveryQuery = (
  { __typename?: 'Query' }
  & { allJobsInRecovery: Array<(
    { __typename?: 'JobInRecovery' }
    & Pick<JobInRecovery, 'id' | 'activeJobConfigurationId' | 'dateEnteredRecovery' | 'isInRecovery' | 'isHot' | 'isQuote' | 'isContract' | 'appointmentNumber' | 'quoteNumber' | 'quoteId' | 'contractNumber' | 'latestPrice' | 'customerId' | 'customerFirstName' | 'customerLastName' | 'customerCity' | 'customerEmail' | 'customerPhone' | 'customerZip'>
    & { assignedSalesperson?: Maybe<(
      { __typename?: 'Salesperson' }
      & Pick<Salesperson, 'id' | 'workerId' | 'firstName' | 'lastName' | 'email'>
    )>, recoveryWorker?: Maybe<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'email'>
    )>, steps: Array<(
      { __typename?: 'RecoveryStep' }
      & Pick<RecoveryStep, 'id' | 'recoveryStepOptionId' | 'step' | 'date' | 'createdByWorkerId'>
    )>, reasons: Array<(
      { __typename?: 'RecoveryReason' }
      & Pick<RecoveryReason, 'id' | 'recoveryReasonOptionId' | 'reason' | 'date' | 'createdByWorkerId'>
    )>, communications: Array<(
      { __typename?: 'RecoveryCommunication' }
      & Pick<RecoveryCommunication, 'id' | 'recoveryCommunicationOptionId' | 'creationTimestamp' | 'notes' | 'isEditable' | 'workerFirstName' | 'workerLastName'>
    )> }
  )> }
);

export type GetAllRecoveryLedgerItemsQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type GetAllRecoveryLedgerItemsQuery = (
  { __typename?: 'Query' }
  & { allRecoveryLedgerItems: Array<(
    { __typename?: 'RecoveryLedgerItem' }
    & Pick<RecoveryLedgerItem, 'jobId' | 'communicationId' | 'reasonId' | 'stepId' | 'overrideId' | 'internalNotesId' | 'callCenterActionId' | 'action' | 'timestamp' | 'notes' | 'worker' | 'isEditable' | 'isDeletable' | 'showTime'>
  )> }
);

export type GetAllRecoveryReasonOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRecoveryReasonOptionsQuery = (
  { __typename?: 'Query' }
  & { allRecoveryReasonOptions: Array<(
    { __typename?: 'RecoveryReasonOption' }
    & Pick<RecoveryReasonOption, 'id' | 'label'>
  )> }
);

export type GetAllRecoveryStepOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRecoveryStepOptionsQuery = (
  { __typename?: 'Query' }
  & { allRecoveryStepOptions: Array<(
    { __typename?: 'RecoveryStepOption' }
    & Pick<RecoveryStepOption, 'id' | 'label'>
  )> }
);

export type GetRecoveryCenterOverrideQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetRecoveryCenterOverrideQuery = (
  { __typename?: 'Query' }
  & { recoveryCenterOverride?: Maybe<(
    { __typename?: 'RecoveryCenterOverride' }
    & Pick<RecoveryCenterOverride, 'id' | 'jobId' | 'jobConfigurationId' | 'amount' | 'commissionRate' | 'workerId' | 'timeOverridden'>
  )> }
);

export type AddInternalJobNotesMutationVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type AddInternalJobNotesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addInternalJobNotes'>
);

export type AddRecoveryCommunicationMutationVariables = Exact<{
  jobId: Scalars['Int'];
  recoveryCommunicationOptionId: Scalars['Int'];
  dialoutId?: Maybe<Scalars['String']>;
}>;


export type AddRecoveryCommunicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addRecoveryCommunication'>
);

export type DeleteInternalJobNotesMutationVariables = Exact<{
  internalJobNotesId: Scalars['Int'];
}>;


export type DeleteInternalJobNotesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInternalJobNotes'>
);

export type DeleteRecoveryCommunicationMutationVariables = Exact<{
  recoveryCommunicationOptionId: Scalars['Int'];
}>;


export type DeleteRecoveryCommunicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRecoveryCommunication'>
);

export type SubmitChangesToJobInRecoveryMutationVariables = Exact<{
  jobId: Scalars['Int'];
  recoveryStepOption: Scalars['Int'];
  stepDate: Scalars['DateTime'];
  recoveryReasonOptionId?: Maybe<Scalars['Int']>;
  otherRecoveryReason?: Maybe<Scalars['String']>;
  updateLatestStep?: Maybe<Scalars['Boolean']>;
  removeFromRecovery?: Maybe<Scalars['Boolean']>;
}>;


export type SubmitChangesToJobInRecoveryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitChangesToJobInRecovery'>
);

export type UpdateInternalJobNotesMutationVariables = Exact<{
  internalJobNotesId: Scalars['Int'];
  notes: Scalars['String'];
}>;


export type UpdateInternalJobNotesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateInternalJobNotes'>
);

export type UpdateJobInRecoveryMutationVariables = Exact<{
  jobId: Scalars['Int'];
  claimedWorkerId?: Maybe<Scalars['Int']>;
  isInRecovery?: Maybe<Scalars['Boolean']>;
  isHot?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateJobInRecoveryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateJobInRecovery'>
);

export type UpdateRecoveryCommunicationMutationVariables = Exact<{
  recoveryCommunicationOptionId: Scalars['Int'];
  notes: Scalars['String'];
}>;


export type UpdateRecoveryCommunicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRecoveryCommunication'>
);

export type UpsertRecoveryCenterOverrideMutationVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
  price: Scalars['Decimal'];
  commissionRate: Scalars['Decimal'];
}>;


export type UpsertRecoveryCenterOverrideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertRecoveryCenterOverride'>
);

export type CanDeleteMarketQueryVariables = Exact<{
  marketId: Scalars['Int'];
}>;


export type CanDeleteMarketQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'canDeleteMarket'>
);

export type EstimateAppointmentLengthQueryVariables = Exact<{
  roomIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type EstimateAppointmentLengthQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'estimateAppointmentLength'>
);

export type GetActivePromoCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type GetActivePromoCodeQuery = (
  { __typename?: 'Query' }
  & { activePromoCode?: Maybe<(
    { __typename?: 'PromoCode' }
    & Pick<PromoCode, 'id' | 'code' | 'displayText'>
  )> }
);

export type GetActivePromosExistQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActivePromosExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'activePromosExist'>
);

export type GetAllActiveZipsAndCitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllActiveZipsAndCitiesQuery = (
  { __typename?: 'Query' }
  & { allActiveZipsAndCities: Array<(
    { __typename?: 'ZipAndCity' }
    & Pick<ZipAndCity, 'id' | 'zip' | 'city'>
  )> }
);

export type GetAllBlockedTimeSlotsOnDayQueryVariables = Exact<{
  date: Scalars['DateTime'];
  marketId: Scalars['Int'];
}>;


export type GetAllBlockedTimeSlotsOnDayQuery = (
  { __typename?: 'Query' }
  & { allBlockedTimesSlotsOnDay: Array<(
    { __typename?: 'BlockedMarketTimeSlot' }
    & Pick<BlockedMarketTimeSlot, 'salespersonId' | 'marketId' | 'marketTimeSlotId' | 'date'>
  )> }
);

export type GetAllCountiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCountiesQuery = (
  { __typename?: 'Query' }
  & { allCounties: Array<(
    { __typename?: 'County' }
    & Pick<County, 'id' | 'name'>
  )> }
);

export type GetAllHowDidYouHearAboutUsOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllHowDidYouHearAboutUsOptionsQuery = (
  { __typename?: 'Query' }
  & { allHowDidYouHearAboutUsOptions: Array<(
    { __typename?: 'HowDidYouHearAboutUsOption' }
    & Pick<HowDidYouHearAboutUsOption, 'id' | 'optionText'>
  )> }
);

export type GetAllMarketsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMarketsQuery = (
  { __typename?: 'Query' }
  & { allMarkets: Array<(
    { __typename?: 'Market' }
    & Pick<Market, 'id' | 'name' | 'appointmentHours' | 'appointmentMinutes' | 'mSRPScalar'>
  )> }
);

export type GetAllSalespeopleInMarketQueryVariables = Exact<{
  marketId: Scalars['Int'];
}>;


export type GetAllSalespeopleInMarketQuery = (
  { __typename?: 'Query' }
  & { allSalespeopleInMarket: Array<(
    { __typename?: 'Salesperson' }
    & Pick<Salesperson, 'id' | 'workerId' | 'firstName' | 'lastName' | 'email' | 'isActive' | 'isCheckedIn' | 'serviceZips'>
  )> }
);

export type GetAllSalespeopleInZipQueryVariables = Exact<{
  zip: Scalars['String'];
}>;


export type GetAllSalespeopleInZipQuery = (
  { __typename?: 'Query' }
  & { allSalespeopleInZip: Array<(
    { __typename?: 'Salesperson' }
    & Pick<Salesperson, 'id' | 'workerId' | 'firstName' | 'lastName' | 'email' | 'isCheckedIn'>
  )> }
);

export type GetAllServicedLocationInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllServicedLocationInformationQuery = (
  { __typename?: 'Query' }
  & { allActiveZipsAndCities: Array<(
    { __typename?: 'ZipAndCity' }
    & Pick<ZipAndCity, 'id' | 'zip' | 'city' | 'countyId' | 'marketId' | 'serviced'>
  )>, allMarkets: Array<(
    { __typename?: 'Market' }
    & Pick<Market, 'id' | 'name'>
  )>, allCounties: Array<(
    { __typename?: 'County' }
    & Pick<County, 'id' | 'name'>
  )> }
);

export type GetAllTimeSlotsForMarketQueryVariables = Exact<{
  marketId: Scalars['Int'];
}>;


export type GetAllTimeSlotsForMarketQuery = (
  { __typename?: 'Query' }
  & { allTimeSlotsForMarket: Array<(
    { __typename?: 'MarketTimeSlot' }
    & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
  )> }
);

export type GetAllZipsAndCitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllZipsAndCitiesQuery = (
  { __typename?: 'Query' }
  & { allZipsAndCities: Array<(
    { __typename?: 'ZipAndCity' }
    & Pick<ZipAndCity, 'id' | 'zip' | 'city' | 'countyId' | 'marketId' | 'serviced'>
  )> }
);

export type GetAvailableTimeSlotsQueryVariables = Exact<{
  zip: Scalars['String'];
  date: Scalars['DateTime'];
}>;


export type GetAvailableTimeSlotsQuery = (
  { __typename?: 'Query' }
  & { allTimeSlotsForZip: Array<(
    { __typename?: 'MarketTimeSlot' }
    & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
  )>, availableTimeSlots: Array<(
    { __typename?: 'MarketTimeSlot' }
    & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
  )> }
);

export type GetAvailableTimeSlotsForSalespersonQueryVariables = Exact<{
  zip: Scalars['String'];
  date: Scalars['DateTime'];
  salespersonId: Scalars['Int'];
}>;


export type GetAvailableTimeSlotsForSalespersonQuery = (
  { __typename?: 'Query' }
  & { allTimeSlotsForZip: Array<(
    { __typename?: 'MarketTimeSlot' }
    & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
  )>, availableTimeSlotsForSalesperson: Array<(
    { __typename?: 'MarketTimeSlot' }
    & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
  )> }
);

export type GetColorCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetColorCategoriesQuery = (
  { __typename?: 'Query' }
  & { colorCategories: Array<(
    { __typename?: 'ColorCategory' }
    & Pick<ColorCategory, 'id' | 'category'>
  )> }
);

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['Int'];
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'businessName' | 'primaryPhone' | 'email' | 'receivePromoEmails'>
    & { primaryAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
    ), billingAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
    )>, customerContacts: Array<(
      { __typename?: 'CustomerContact' }
      & CustomerContactFragment
    )> }
  ) }
);

export type GetFilteredBlockedTimeSlotsQueryVariables = Exact<{
  dates: Array<Scalars['DateTime']> | Scalars['DateTime'];
  marketId?: Maybe<Scalars['Int']>;
  ts: AtomicTimeSlotInput;
}>;


export type GetFilteredBlockedTimeSlotsQuery = (
  { __typename?: 'Query' }
  & { filteredBlockedTimeSlots: Array<(
    { __typename?: 'BlockedTimeSlot' }
    & Pick<BlockedTimeSlot, 'id' | 'date' | 'marketTimeSlotId' | 'marketId'>
    & { timeSlot: (
      { __typename?: 'AtomicTimeSlot' }
      & Pick<AtomicTimeSlot, 'startHour' | 'startMinute' | 'endHour' | 'endMinute'>
    ) }
  )> }
);

export type GetInstallationAppointmentWithRoomsQueryVariables = Exact<{
  installationAppointmentId: Scalars['Int'];
}>;


export type GetInstallationAppointmentWithRoomsQuery = (
  { __typename?: 'Query' }
  & { installationAppointmentWithRooms: (
    { __typename?: 'InstallationAppointmentWithRooms' }
    & Pick<InstallationAppointmentWithRooms, 'id'>
    & { appointment: (
      { __typename?: 'InstallationAppointment' }
      & InstallationAppointmentFragment
    ), rooms: Array<(
      { __typename?: 'RoomForInstallationAppointment' }
      & Pick<RoomForInstallationAppointment, 'id' | 'areaId' | 'installationAppointmentId' | 'sqft'>
      & { labels: Array<(
        { __typename?: 'LabelForRoom' }
        & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'customName' | 'labelNumber'>
        & { label: (
          { __typename?: 'RoomLabelOption' }
          & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
        ) }
      )>, product: (
        { __typename?: 'Product' }
        & Pick<Product, 'productTypeId' | 'productType' | 'productStyleId' | 'productStyle'>
      ) }
    )> }
  ) }
);

export type GetInstallationAppointmentsQueryVariables = Exact<{
  jobContractId: Scalars['Int'];
}>;


export type GetInstallationAppointmentsQuery = (
  { __typename?: 'Query' }
  & { installationAppointments: Array<(
    { __typename?: 'InstallationAppointmentWithRooms' }
    & Pick<InstallationAppointmentWithRooms, 'id'>
    & { appointment: (
      { __typename?: 'InstallationAppointment' }
      & InstallationAppointmentFragment
    ), rooms: Array<(
      { __typename?: 'RoomForInstallationAppointment' }
      & Pick<RoomForInstallationAppointment, 'id' | 'areaId' | 'installationAppointmentId' | 'sqft'>
      & { labels: Array<(
        { __typename?: 'LabelForRoom' }
        & Pick<LabelForRoom, 'id' | 'roomId' | 'hasCloset' | 'customName' | 'labelNumber'>
        & { label: (
          { __typename?: 'RoomLabelOption' }
          & Pick<RoomLabelOption, 'id' | 'label' | 'fullLabel'>
        ) }
      )>, product: (
        { __typename?: 'Product' }
        & Pick<Product, 'productTypeId' | 'productType' | 'productStyleId' | 'productStyle'>
      ) }
    )> }
  )> }
);

export type GetPhoneNumberStatusQueryVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;


export type GetPhoneNumberStatusQuery = (
  { __typename?: 'Query' }
  & { phoneNumberStatus: (
    { __typename?: 'PhoneNumberStatus' }
    & Pick<PhoneNumberStatus, 'id' | 'customerId' | 'activeJobId'>
  ) }
);

export type GetPriorityOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPriorityOptionsQuery = (
  { __typename?: 'Query' }
  & { priorityOptions: Array<(
    { __typename?: 'PriorityOption' }
    & Pick<PriorityOption, 'id' | 'text'>
  )> }
);

export type GetSahAppointmentIdForJobQueryVariables = Exact<{
  jobConfigurationId: Scalars['Int'];
}>;


export type GetSahAppointmentIdForJobQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sAHAppointmentIdForJob'>
);

export type GetSahAppointmentsForDateQueryVariables = Exact<{
  date: Scalars['DateTime'];
  marketId: Scalars['Int'];
}>;


export type GetSahAppointmentsForDateQuery = (
  { __typename?: 'Query' }
  & { sAHAppointmentsForDate: Array<(
    { __typename?: 'SAHAppointment' }
    & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'activeJobConfigurationId' | 'contractCancelled' | 'contractId' | 'contractTotalPrice' | 'quoteId' | 'quoteTotalPrice'>
    & { marketTimeSlot: (
      { __typename?: 'MarketTimeSlot' }
      & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
    ), currentSalesperson?: Maybe<(
      { __typename?: 'SalespersonAssignedToAppointment' }
      & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
    )>, priorityOptions?: Maybe<Array<(
      { __typename?: 'PriorityOption' }
      & Pick<PriorityOption, 'id' | 'text'>
    )>>, colorCategories?: Maybe<Array<(
      { __typename?: 'ColorCategory' }
      & Pick<ColorCategory, 'id' | 'category'>
    )>> }
  )>, allBlockedTimesSlotsOnDay: Array<(
    { __typename?: 'BlockedMarketTimeSlot' }
    & Pick<BlockedMarketTimeSlot, 'salespersonId' | 'marketId' | 'marketTimeSlotId' | 'date' | 'workerBlockedTimeSlotIdIfDeletable'>
  )> }
);

export type GetSahAppointmentsForSalespersonQueryVariables = Exact<{
  salespersonId: Scalars['Int'];
}>;


export type GetSahAppointmentsForSalespersonQuery = (
  { __typename?: 'Query' }
  & { sAHAppointmentsForSalesperson: Array<(
    { __typename?: 'SAHAppointment' }
    & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'contractCancelled' | 'contractTotalPrice' | 'quoteTotalPrice'>
    & { marketTimeSlot: (
      { __typename?: 'MarketTimeSlot' }
      & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
    ), currentSalesperson?: Maybe<(
      { __typename?: 'SalespersonAssignedToAppointment' }
      & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
    )>, priorityOptions?: Maybe<Array<(
      { __typename?: 'PriorityOption' }
      & Pick<PriorityOption, 'id' | 'text'>
    )>>, colorCategories?: Maybe<Array<(
      { __typename?: 'ColorCategory' }
      & Pick<ColorCategory, 'id' | 'category'>
    )>> }
  )> }
);

export type InstallationAppointmentFragment = (
  { __typename?: 'InstallationAppointment' }
  & Pick<InstallationAppointment, 'id' | 'appointmentNumber' | 'jobContractId' | 'jobConfigurationId' | 'contractorId' | 'contractorFirstName' | 'contractorLastName' | 'dates' | 'totalDaysInRange' | 'customerFirstName' | 'customerLastName' | 'customerPhone' | 'notes' | 'cod' | 'collectedCod' | 'releasedToContractor' | 'pickedUpMaterialsTime' | 'mappedToCustomerTime' | 'checkInTime' | 'checkOutTime' | 'estimatedArrivalTime' | 'contractorLastContactAttempt' | 'customerNotifiedOfEta' | 'isComplete' | 'woodTotal' | 'spcTotal' | 'carpetTotal' | 'hasBeforePictures' | 'hasAfterPictures' | 'certificateRating'>
  & { customerAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'streetAddress' | 'city' | 'zip' | 'apartmentNumber'>
  ) }
);

export type AcceptSahAppointmentMutationVariables = Exact<{
  sahAppointmentId: Scalars['Int'];
}>;


export type AcceptSahAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { acceptSAHAppointment: (
    { __typename?: 'SAHAppointment' }
    & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'contractCancelled' | 'contractTotalPrice' | 'quoteTotalPrice'>
    & { marketTimeSlot: (
      { __typename?: 'MarketTimeSlot' }
      & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
    ), currentSalesperson?: Maybe<(
      { __typename?: 'SalespersonAssignedToAppointment' }
      & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
    )>, priorityOptions?: Maybe<Array<(
      { __typename?: 'PriorityOption' }
      & Pick<PriorityOption, 'id' | 'text'>
    )>>, colorCategories?: Maybe<Array<(
      { __typename?: 'ColorCategory' }
      & Pick<ColorCategory, 'id' | 'category'>
    )>> }
  ) }
);

export type AddInstallationAppointmentMutationVariables = Exact<{
  jobContractId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  notes: Scalars['String'];
  cod: Scalars['Decimal'];
  roomIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addInstallationAppointment'>
);

export type AddMarketMutationVariables = Exact<{
  name: Scalars['String'];
  appointmentHours: Scalars['Int'];
  appointmentMinutes: Scalars['Int'];
  msrpScalar?: Maybe<Scalars['Decimal']>;
}>;


export type AddMarketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addMarket'>
);

export type AddNewSahAppointmentMutationVariables = Exact<{
  newSAHAppointment: RawSahAppointmentDataInput;
}>;


export type AddNewSahAppointmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addNewSAHAppointment'>
);

export type AddRescheduledSahAppointmentMutationVariables = Exact<{
  jobId: Scalars['Int'];
  date: Scalars['DateTime'];
  marketTimeSlotId: Scalars['Int'];
  salespersonId?: Maybe<Scalars['Int']>;
  isWriteUp?: Maybe<Scalars['Boolean']>;
}>;


export type AddRescheduledSahAppointmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addRescheduledSAHAppointment'>
);

export type BlockTimeSlotByMarketsMutationVariables = Exact<{
  dates: Array<Scalars['DateTime']> | Scalars['DateTime'];
  marketIds: Array<Scalars['Int']> | Scalars['Int'];
  ts: AtomicTimeSlotInput;
}>;


export type BlockTimeSlotByMarketsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'blockTimeSlotByMarkets'>
);

export type BlockTimeSlotForAllZipsMutationVariables = Exact<{
  dates: Array<Scalars['DateTime']> | Scalars['DateTime'];
  ts: AtomicTimeSlotInput;
}>;


export type BlockTimeSlotForAllZipsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'blockTimeSlotForAllZips'>
);

export type CancelSahAppointmentMutationVariables = Exact<{
  sahAppointmentId: Scalars['Int'];
}>;


export type CancelSahAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { cancelSAHAppointment: (
    { __typename?: 'SAHAppointment' }
    & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'contractCancelled' | 'contractTotalPrice' | 'quoteTotalPrice'>
    & { marketTimeSlot: (
      { __typename?: 'MarketTimeSlot' }
      & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
    ), currentSalesperson?: Maybe<(
      { __typename?: 'SalespersonAssignedToAppointment' }
      & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
    )>, priorityOptions?: Maybe<Array<(
      { __typename?: 'PriorityOption' }
      & Pick<PriorityOption, 'id' | 'text'>
    )>>, colorCategories?: Maybe<Array<(
      { __typename?: 'ColorCategory' }
      & Pick<ColorCategory, 'id' | 'category'>
    )>> }
  ) }
);

export type CheckIntoSahAppointmentMutationVariables = Exact<{
  sahAppointmentId: Scalars['Int'];
}>;


export type CheckIntoSahAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { checkIntoSAHAppointment: (
    { __typename?: 'SAHAppointment' }
    & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'contractCancelled' | 'contractTotalPrice' | 'quoteTotalPrice'>
    & { marketTimeSlot: (
      { __typename?: 'MarketTimeSlot' }
      & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
    ), currentSalesperson?: Maybe<(
      { __typename?: 'SalespersonAssignedToAppointment' }
      & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
    )>, priorityOptions?: Maybe<Array<(
      { __typename?: 'PriorityOption' }
      & Pick<PriorityOption, 'id' | 'text'>
    )>>, colorCategories?: Maybe<Array<(
      { __typename?: 'ColorCategory' }
      & Pick<ColorCategory, 'id' | 'category'>
    )>> }
  ) }
);

export type CheckOutOfSahAppointmentMutationVariables = Exact<{
  sahAppointmentId: Scalars['Int'];
}>;


export type CheckOutOfSahAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { checkOutOfSAHAppointment: (
    { __typename?: 'SAHAppointment' }
    & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'contractCancelled' | 'contractTotalPrice' | 'quoteTotalPrice'>
    & { marketTimeSlot: (
      { __typename?: 'MarketTimeSlot' }
      & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
    ), currentSalesperson?: Maybe<(
      { __typename?: 'SalespersonAssignedToAppointment' }
      & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
    )>, priorityOptions?: Maybe<Array<(
      { __typename?: 'PriorityOption' }
      & Pick<PriorityOption, 'id' | 'text'>
    )>>, colorCategories?: Maybe<Array<(
      { __typename?: 'ColorCategory' }
      & Pick<ColorCategory, 'id' | 'category'>
    )>> }
  ) }
);

export type DeleteBlockedTimeSlotsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteBlockedTimeSlotsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBlockedTimeSlots'>
);

export type DeleteInstallationAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
}>;


export type DeleteInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInstallationAppointment'>
);

export type DeleteMarketMutationVariables = Exact<{
  marketId: Scalars['Int'];
}>;


export type DeleteMarketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMarket'>
);

export type DeleteRoomForInstallationAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
  roomId: Scalars['Int'];
}>;


export type DeleteRoomForInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRoomForInstallationAppointment'>
);

export type DeleteRoomsForInstallationAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
  roomIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteRoomsForInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRoomsForInstallationAppointment'>
);

export type DeleteWorkerBlockedTimeSlotMutationVariables = Exact<{
  workerBlockedTimeSlotId: Scalars['Int'];
}>;


export type DeleteWorkerBlockedTimeSlotMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWorkerBlockedTimeSlot'>
);

export type ReleaseSahAppointmentMutationVariables = Exact<{
  sahAppointmentId: Scalars['Int'];
}>;


export type ReleaseSahAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { releaseSAHAppointment: (
    { __typename?: 'SAHAppointment' }
    & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'contractCancelled' | 'contractTotalPrice' | 'quoteTotalPrice'>
    & { marketTimeSlot: (
      { __typename?: 'MarketTimeSlot' }
      & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
    ), currentSalesperson?: Maybe<(
      { __typename?: 'SalespersonAssignedToAppointment' }
      & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
    )>, priorityOptions?: Maybe<Array<(
      { __typename?: 'PriorityOption' }
      & Pick<PriorityOption, 'id' | 'text'>
    )>>, colorCategories?: Maybe<Array<(
      { __typename?: 'ColorCategory' }
      & Pick<ColorCategory, 'id' | 'category'>
    )>> }
  ) }
);

export type ReportCustomerNotHomeSahAppointmentMutationVariables = Exact<{
  sahAppointmentId: Scalars['Int'];
}>;


export type ReportCustomerNotHomeSahAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { customerNotHomeSAHAppointment: (
    { __typename?: 'SAHAppointment' }
    & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'contractCancelled' | 'contractTotalPrice' | 'quoteTotalPrice'>
    & { marketTimeSlot: (
      { __typename?: 'MarketTimeSlot' }
      & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
    ), currentSalesperson?: Maybe<(
      { __typename?: 'SalespersonAssignedToAppointment' }
      & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
    )>, priorityOptions?: Maybe<Array<(
      { __typename?: 'PriorityOption' }
      & Pick<PriorityOption, 'id' | 'text'>
    )>>, colorCategories?: Maybe<Array<(
      { __typename?: 'ColorCategory' }
      & Pick<ColorCategory, 'id' | 'category'>
    )>> }
  ) }
);

export type UpdateAreaNotesMutationVariables = Exact<{
  areaId: Scalars['Int'];
  newNotes: Scalars['String'];
  replace: Scalars['Boolean'];
}>;


export type UpdateAreaNotesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAreaNotes'>
);

export type UpdateInstallationAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
  newContractorId?: Maybe<Scalars['Int']>;
  newStartDate?: Maybe<Scalars['DateTime']>;
  newNotes?: Maybe<Scalars['String']>;
  newCod?: Maybe<Scalars['Decimal']>;
  newCollectedCod?: Maybe<Scalars['Decimal']>;
  appointmentLength?: Maybe<Scalars['Int']>;
}>;


export type UpdateInstallationAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { updateInstallationAppointment: (
    { __typename?: 'InstallationAppointment' }
    & InstallationAppointmentFragment
  ) }
);

export type UpdateInstallationAppointmentNotesMutationVariables = Exact<{
  installationAppointmentId: Scalars['Int'];
  newNotes: Scalars['String'];
  replace: Scalars['Boolean'];
}>;


export type UpdateInstallationAppointmentNotesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateInstallationAppointmentNotes'>
);

export type UpdateMarketMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  appointmentHours?: Maybe<Scalars['Int']>;
  appointmentMinutes?: Maybe<Scalars['Int']>;
  msrpScalar?: Maybe<Scalars['Decimal']>;
}>;


export type UpdateMarketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMarket'>
);

export type UpdateMarketAndServicedForZipAndCityMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
  marketId?: Maybe<Scalars['Int']>;
  serviced: Scalars['Boolean'];
}>;


export type UpdateMarketAndServicedForZipAndCityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMarketAndServicedForZipAndCity'>
);

export type UpdateMsrpScalarForMarketsMutationVariables = Exact<{
  marketIds: Array<Scalars['Int']> | Scalars['Int'];
  newScalar: Scalars['Decimal'];
}>;


export type UpdateMsrpScalarForMarketsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMsrpScalarForMarkets'>
);

export type UpdateRoomNotesMutationVariables = Exact<{
  roomId: Scalars['Int'];
  newNotes: Scalars['String'];
  replace: Scalars['Boolean'];
}>;


export type UpdateRoomNotesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRoomNotes'>
);

export type UpdateSahAppointmentMutationVariables = Exact<{
  sahAppointmentId: Scalars['Int'];
  salespersonId?: Maybe<Scalars['Int']>;
  marketTimeSlotIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateSahAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { updateSAHAppointment: (
    { __typename?: 'SAHAppointment' }
    & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'contractCancelled' | 'contractTotalPrice' | 'quoteTotalPrice'>
    & { marketTimeSlot: (
      { __typename?: 'MarketTimeSlot' }
      & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
    ), currentSalesperson?: Maybe<(
      { __typename?: 'SalespersonAssignedToAppointment' }
      & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
    )>, priorityOptions?: Maybe<Array<(
      { __typename?: 'PriorityOption' }
      & Pick<PriorityOption, 'id' | 'text'>
    )>>, colorCategories?: Maybe<Array<(
      { __typename?: 'ColorCategory' }
      & Pick<ColorCategory, 'id' | 'category'>
    )>> }
  ) }
);

export type UpdateSahAppointmentDetailsMutationVariables = Exact<{
  sahAppointmentId: Scalars['Int'];
  customerComments: Scalars['String'];
}>;


export type UpdateSahAppointmentDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateSAHAppointmentDetails: (
    { __typename?: 'SAHAppointment' }
    & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'contractCancelled' | 'contractTotalPrice' | 'quoteTotalPrice'>
    & { marketTimeSlot: (
      { __typename?: 'MarketTimeSlot' }
      & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
    ), currentSalesperson?: Maybe<(
      { __typename?: 'SalespersonAssignedToAppointment' }
      & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
    )>, priorityOptions?: Maybe<Array<(
      { __typename?: 'PriorityOption' }
      & Pick<PriorityOption, 'id' | 'text'>
    )>>, colorCategories?: Maybe<Array<(
      { __typename?: 'ColorCategory' }
      & Pick<ColorCategory, 'id' | 'category'>
    )>> }
  ) }
);

export type UpdateSahAppointmentNotesMutationVariables = Exact<{
  sahAppointmentId: Scalars['Int'];
  notes: Scalars['String'];
}>;


export type UpdateSahAppointmentNotesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSahAppointmentNotes'>
);

export type WaitingForCustomerSahAppointmentMutationVariables = Exact<{
  sahAppointmentId: Scalars['Int'];
}>;


export type WaitingForCustomerSahAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { waitingForCustomerSAHAppointment: (
    { __typename?: 'SAHAppointment' }
    & Pick<SahAppointment, 'id' | 'appointmentNumber' | 'jobId' | 'numRooms' | 'date' | 'isForCustomerHouse' | 'isCancelled' | 'isInRecovery' | 'comments' | 'promoCodeId' | 'howDidYouHearAboutUsOptionId' | 'howDidYouHearAboutUsOption' | 'productTypes' | 'productTypeIds' | 'customerId' | 'firstName' | 'lastName' | 'primaryAddress' | 'primaryCity' | 'primaryZipCode' | 'primaryPhone' | 'appointmentStatusId' | 'appointmentStatusString' | 'contractCancelled' | 'contractTotalPrice' | 'quoteTotalPrice'>
    & { marketTimeSlot: (
      { __typename?: 'MarketTimeSlot' }
      & Pick<MarketTimeSlot, 'id' | 'startTime' | 'endTime' | 'marketId'>
    ), currentSalesperson?: Maybe<(
      { __typename?: 'SalespersonAssignedToAppointment' }
      & Pick<SalespersonAssignedToAppointment, 'id' | 'sAHAppointmentId' | 'salespersonId' | 'releasedTime' | 'acceptedTime' | 'checkInTime' | 'checkOutTime' | 'appointmentStartTime' | 'isWriteUp'>
    )>, priorityOptions?: Maybe<Array<(
      { __typename?: 'PriorityOption' }
      & Pick<PriorityOption, 'id' | 'text'>
    )>>, colorCategories?: Maybe<Array<(
      { __typename?: 'ColorCategory' }
      & Pick<ColorCategory, 'id' | 'category'>
    )>> }
  ) }
);

export type GetAllServiceMaterialCategoryPricingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllServiceMaterialCategoryPricingsQuery = (
  { __typename?: 'Query' }
  & { allServiceMaterialCategoryPricings: Array<(
    { __typename?: 'FullServiceMaterialCategoryPricing' }
    & Pick<FullServiceMaterialCategoryPricing, 'id' | 'serviceMaterialCategoryId' | 'serviceMaterialCategory' | 'packageSize' | 'packageSizeIncrement' | 'productTypeId' | 'productType' | 'pricePerUnit' | 'costPerUnit' | 'priceUnit'>
  )> }
);

export type GetServiceMaterialCategoryHistoryQueryVariables = Exact<{
  serviceMaterialCategoryProductPricingId: Scalars['Int'];
}>;


export type GetServiceMaterialCategoryHistoryQuery = (
  { __typename?: 'Query' }
  & { serviceMaterialCategoryHistory: Array<(
    { __typename?: 'ServiceMaterialCategoryPricingHistory' }
    & Pick<ServiceMaterialCategoryPricingHistory, 'id' | 'changeTime' | 'oldPrice' | 'oldCost'>
  )> }
);

export type AddServiceMaterialCategoryPricingsMutationVariables = Exact<{
  materialCategoryName: Scalars['String'];
  pricingUnit: Scalars['String'];
  newPricings: Array<AddServiceMaterialCategoryPricingInput> | AddServiceMaterialCategoryPricingInput;
  packageSize?: Maybe<Scalars['Decimal']>;
  packageSizeIncrement?: Maybe<Scalars['Decimal']>;
}>;


export type AddServiceMaterialCategoryPricingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addServiceMaterialCategoryPricings'>
);

export type UpdateServiceMaterialCategoryPricingsMutationVariables = Exact<{
  serviceMaterialCategoryProductPricingIds: Array<Scalars['Int']> | Scalars['Int'];
  updatedCost?: Maybe<Scalars['Decimal']>;
  updatedPrice?: Maybe<Scalars['Decimal']>;
}>;


export type UpdateServiceMaterialCategoryPricingsMutation = (
  { __typename?: 'Mutation' }
  & { updateServiceMaterialCategoryPricings: Array<(
    { __typename?: 'FullServiceMaterialCategoryPricing' }
    & Pick<FullServiceMaterialCategoryPricing, 'id' | 'serviceMaterialCategoryId' | 'serviceMaterialCategory' | 'packageSize' | 'packageSizeIncrement' | 'productTypeId' | 'productType' | 'pricePerUnit' | 'costPerUnit' | 'priceUnit'>
  )> }
);

export type SetRoleMutationVariables = Exact<{
  userEmail: Scalars['String'];
  userRole: Scalars['String'];
}>;


export type SetRoleMutation = (
  { __typename?: 'Mutation' }
  & { setRole?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'role'>
  )> }
);

export type TestMutationVariables = Exact<{ [key: string]: never; }>;


export type TestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'test'>
);

export type GetVendorSourcedProductQueryVariables = Exact<{
  sourceId: Scalars['Int'];
}>;


export type GetVendorSourcedProductQuery = (
  { __typename?: 'Query' }
  & { vendorSourcedProduct: (
    { __typename?: 'VendorSourcedProduct' }
    & Pick<VendorSourcedProduct, 'id' | 'vendorId' | 'productSpecificationId' | 'style' | 'color' | 'orderQuantity' | 'singleCostPerSqft' | 'bulkCostPerSqft' | 'oneTimeCostPerSqft'>
  ) }
);

export type GetVendorSourcedProductIdsForStyleQueryVariables = Exact<{
  styleId: Scalars['Int'];
  vendorId?: Maybe<Scalars['Int']>;
}>;


export type GetVendorSourcedProductIdsForStyleQuery = (
  { __typename?: 'Query' }
  & { productSourceIdsForStyle: Array<(
    { __typename?: 'SourcedProductIdAndColorName' }
    & Pick<SourcedProductIdAndColorName, 'id' | 'color'>
  )> }
);

export type GetVendorsQueryVariables = Exact<{
  hasProductTypeId?: Maybe<Scalars['Int']>;
}>;


export type GetVendorsQuery = (
  { __typename?: 'Query' }
  & { vendors: Array<(
    { __typename?: 'VendorName' }
    & Pick<VendorName, 'id' | 'name'>
  )> }
);

export type GetAllContractorInstallationCapabilitiesAndDaysOffQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllContractorInstallationCapabilitiesAndDaysOffQuery = (
  { __typename?: 'Query' }
  & { allContractorInstallationCapabilitiesAndDaysOff: Array<(
    { __typename?: 'ContractorInstallationCapabilitiesAndDaysOff' }
    & Pick<ContractorInstallationCapabilitiesAndDaysOff, 'id' | 'daysOff'>
    & { contractor: (
      { __typename?: 'Contractor' }
      & ContractorFragment
    ), installationCapabilities: Array<(
      { __typename?: 'ContractorInstallationCapability' }
      & ContractorInstallationCapabilityFragment
    )> }
  )> }
);

export type GetAllInstallationCertificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllInstallationCertificationsQuery = (
  { __typename?: 'Query' }
  & { allInstallationCertifications: Array<(
    { __typename?: 'InstallationCertification' }
    & Pick<InstallationCertification, 'id' | 'name' | 'productTypeId'>
  )> }
);

export type GetAuthenticatedCommandCenterWorkerIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthenticatedCommandCenterWorkerIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'authenticatedCommandCenterWorkerId'>
);

export type GetAuthenticatedWorkerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthenticatedWorkerQuery = (
  { __typename?: 'Query' }
  & { authenticatedWorker?: Maybe<(
    { __typename?: 'Worker' }
    & WorkerFragment
  )> }
);

export type GetAuthenticatedWorkerPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthenticatedWorkerPermissionsQuery = (
  { __typename?: 'Query' }
  & { authenticatedWorkerPermissions?: Maybe<(
    { __typename?: 'Permissions' }
    & Pick<Permissions, 'id' | 'callCenterAdmin' | 'callCenterWorker' | 'projectManager' | 'projectCoordinator' | 'viewPricingCenter' | 'editPricingCenter' | 'setFinalFinalPrice'>
  )> }
);

export type GetContractorQueryVariables = Exact<{
  workerId: Scalars['Int'];
}>;


export type GetContractorQuery = (
  { __typename?: 'Query' }
  & { contractor?: Maybe<(
    { __typename?: 'Contractor' }
    & ContractorFragment
  )> }
);

export type GetContractorListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContractorListQuery = (
  { __typename?: 'Query' }
  & { allContractors: Array<(
    { __typename?: 'Contractor' }
    & Pick<Contractor, 'id' | 'workerId' | 'firstName' | 'lastName'>
  )> }
);

export type GetContractorProfileQueryVariables = Exact<{
  workerId: Scalars['Int'];
}>;


export type GetContractorProfileQuery = (
  { __typename?: 'Query' }
  & { contractorProfile: (
    { __typename?: 'ContractorProfile' }
    & Pick<ContractorProfile, 'id' | 'workerId' | 'isActive' | 'firstName' | 'lastName' | 'email' | 'phone' | 'servicedZips' | 'driversLicenseNumber' | 'licenseNumber' | 'parentCompany' | 'profilePictureFileId' | 'rating'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & AddressFragment
    )>, driversLicenseFile?: Maybe<(
      { __typename?: 'S3File' }
      & S3FileFragment
    )>, licenseFile?: Maybe<(
      { __typename?: 'S3File' }
      & S3FileFragment
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & VehicleFragment
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & CompanyFragment
    )>, workerHistory?: Maybe<(
      { __typename?: 'WorkerHistory' }
      & WorkerHistoryFragment
    )>, helpers: Array<(
      { __typename?: 'ContractorHelper' }
      & Pick<ContractorHelper, 'id' | 'firstName' | 'lastName' | 'contractorId' | 'phone' | 'licenseNumber' | 'isActive'>
      & { history: Array<(
        { __typename?: 'WorkerHistoryDates' }
        & Pick<WorkerHistoryDates, 'id' | 'startDate' | 'endDate'>
      )>, backgroundChecks: Array<(
        { __typename?: 'BackgroundCheck' }
        & BackgroundCheckFragment
      )> }
    )>, backgroundChecks: Array<(
      { __typename?: 'BackgroundCheck' }
      & BackgroundCheckFragment
    )>, capabilities: Array<(
      { __typename?: 'ContractorInstallationCapability' }
      & ContractorInstallationCapabilityFragment
    )>, w9s: Array<(
      { __typename?: 'W9' }
      & W9Fragment
    )>, workersComps: Array<(
      { __typename?: 'WorkersComp' }
      & WorkersCompFragment
    )>, liabilityInsurances: Array<(
      { __typename?: 'LiabilityInsurance' }
      & LiabilityInsuranceFragment
    )> }
  ) }
);

export type GetGeneralCommandCenterWorkerIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralCommandCenterWorkerIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'generalCommandCenterWorkerId'>
);

export type GetGeneratedSalespersonPerformanceSummaryQueryVariables = Exact<{
  salespersonId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type GetGeneratedSalespersonPerformanceSummaryQuery = (
  { __typename?: 'Query' }
  & { generatedSalespersonPerformanceSummary: (
    { __typename?: 'SalespersonPerformanceSummary' }
    & SalespersonPerformanceSummaryFragment
  ) }
);

export type GetListOfStoredSalespersonPerformanceSummariesQueryVariables = Exact<{
  salespersonId: Scalars['Int'];
}>;


export type GetListOfStoredSalespersonPerformanceSummariesQuery = (
  { __typename?: 'Query' }
  & { listOfStoredSalespersonPerformanceSummaries: Array<(
    { __typename?: 'PerformanceSummaryMetadata' }
    & Pick<PerformanceSummaryMetadata, 'id' | 'startDate' | 'endDate'>
  )> }
);

export type GetNewestContractorRatingSummaryQueryVariables = Exact<{
  contractorId: Scalars['Int'];
}>;


export type GetNewestContractorRatingSummaryQuery = (
  { __typename?: 'Query' }
  & { newestContractorRatingSummary?: Maybe<(
    { __typename?: 'ContractorRatingSummary' }
    & Pick<ContractorRatingSummary, 'id' | 'contractorId' | 'ratingParametersId' | 'calledInAm' | 'checkedIn' | 'onTime' | 'collectedCod' | 'completedJob' | 'completionCertificateSigned' | 'completionCertificateRating' | 'serviceRequests' | 'numAppointments' | 'servicesCompletedOnTime' | 'numServices' | 'rating' | 'date'>
  )> }
);

export type GetSalespersonQueryVariables = Exact<{
  workerId: Scalars['Int'];
}>;


export type GetSalespersonQuery = (
  { __typename?: 'Query' }
  & { salesperson?: Maybe<(
    { __typename?: 'Salesperson' }
    & Pick<Salesperson, 'id' | 'workerId' | 'firstName' | 'lastName' | 'email' | 'isActive' | 'isCheckedIn' | 'serviceZips'>
  )> }
);

export type GetSalespersonListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSalespersonListQuery = (
  { __typename?: 'Query' }
  & { allSalespeople: Array<(
    { __typename?: 'Salesperson' }
    & Pick<Salesperson, 'id' | 'workerId' | 'firstName' | 'lastName'>
  )> }
);

export type GetSalespersonProfileQueryVariables = Exact<{
  workerId: Scalars['Int'];
}>;


export type GetSalespersonProfileQuery = (
  { __typename?: 'Query' }
  & { salespersonProfile: (
    { __typename?: 'SalespersonProfile' }
    & Pick<SalespersonProfile, 'id' | 'workerId' | 'isActive' | 'firstName' | 'lastName' | 'email' | 'phone' | 'servicedZips' | 'profilePictureFileId' | 'driversLicenseNumber' | 'parentCompany'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & AddressFragment
    )>, driversLicenseFile?: Maybe<(
      { __typename?: 'S3File' }
      & S3FileFragment
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & VehicleFragment
    )>, workerHistory?: Maybe<(
      { __typename?: 'WorkerHistory' }
      & WorkerHistoryFragment
    )>, backgroundChecks: Array<(
      { __typename?: 'BackgroundCheck' }
      & BackgroundCheckFragment
    )>, w9s: Array<(
      { __typename?: 'W9' }
      & W9Fragment
    )>, liabilityInsurances: Array<(
      { __typename?: 'LiabilityInsurance' }
      & LiabilityInsuranceFragment
    )>, workersComps: Array<(
      { __typename?: 'WorkersComp' }
      & WorkersCompFragment
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & CompanyFragment
    )> }
  ) }
);

export type GetStoredSalespersonPerformanceSummaryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetStoredSalespersonPerformanceSummaryQuery = (
  { __typename?: 'Query' }
  & { storedSalespersonPerformanceSummary: (
    { __typename?: 'SalespersonPerformanceSummary' }
    & SalespersonPerformanceSummaryFragment
  ) }
);

export type GetWorkerBlockedTimeSlotsAfterDateQueryVariables = Exact<{
  workerId: Scalars['Int'];
  afterDate: Scalars['DateTime'];
}>;


export type GetWorkerBlockedTimeSlotsAfterDateQuery = (
  { __typename?: 'Query' }
  & { workerBlockedTimeSlotsAfterDate: Array<(
    { __typename?: 'WorkerBlockedTimeSlot' }
    & Pick<WorkerBlockedTimeSlot, 'id' | 'workerId' | 'date' | 'startTime' | 'endTime'>
  )> }
);

export type GetWorkerPermissionsQueryVariables = Exact<{
  workerId: Scalars['Int'];
}>;


export type GetWorkerPermissionsQuery = (
  { __typename?: 'Query' }
  & { workerPermissions: (
    { __typename?: 'Permissions' }
    & Pick<Permissions, 'id' | 'callCenterAdmin' | 'callCenterWorker'>
  ) }
);

export type BackgroundCheckFragment = (
  { __typename?: 'BackgroundCheck' }
  & Pick<BackgroundCheck, 'id' | 'workerId' | 'isInternal' | 'number' | 'datePerformed' | 'expirationDate'>
);

export type CompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'eIN'>
  & { address: (
    { __typename?: 'Address' }
    & AddressFragment
  ) }
);

export type ContractorFragment = (
  { __typename?: 'Contractor' }
  & Pick<Contractor, 'id' | 'workerId' | 'isActive' | 'firstName' | 'lastName' | 'email' | 'phone' | 'profilePictureFileId'>
  & { helpers: Array<(
    { __typename?: 'ContractorHelper' }
    & Pick<ContractorHelper, 'id' | 'firstName' | 'lastName' | 'contractorId' | 'phone' | 'licenseNumber' | 'isActive'>
    & { history: Array<(
      { __typename?: 'WorkerHistoryDates' }
      & Pick<WorkerHistoryDates, 'id' | 'startDate' | 'endDate'>
    )>, backgroundChecks: Array<(
      { __typename?: 'BackgroundCheck' }
      & BackgroundCheckFragment
    )> }
  )>, capabilities: Array<(
    { __typename?: 'ContractorInstallationCapability' }
    & ContractorInstallationCapabilityFragment
  )> }
);

export type ContractorInstallationCapabilityFragment = (
  { __typename?: 'ContractorInstallationCapability' }
  & Pick<ContractorInstallationCapability, 'contractorId' | 'productTypeId' | 'sqftPotential' | 'isActive' | 'certificationIds'>
);

export type LiabilityInsuranceFragment = (
  { __typename?: 'LiabilityInsurance' }
  & Pick<LiabilityInsurance, 'id' | 'workerId' | 'activeDate' | 'expirationDate' | 's3FileId'>
);

export type PartialContractorFragment = (
  { __typename?: 'Contractor' }
  & Pick<Contractor, 'id' | 'firstName' | 'lastName' | 'workerId'>
);

export type S3FileFragment = (
  { __typename?: 'S3File' }
  & Pick<S3File, 'id' | 'objectKey' | 'fileName' | 'status' | 'uploadTimestamp'>
);

export type SalespersonPerformanceSummaryFragment = (
  { __typename?: 'SalespersonPerformanceSummary' }
  & Pick<SalespersonPerformanceSummary, 'id' | 'salespersonId' | 'startDate' | 'endDate' | 'numLeads' | 'numDemos' | 'saleCount' | 'totalSaleDollars' | 'averageSaleMargin' | 'numWoodSold' | 'numSpcSold' | 'numCarpetSold' | 'quoteCount' | 'totalQuoteDollars' | 'averageQuoteMargin' | 'numWoodQuoted' | 'numSpcQuoted' | 'numCarpetQuoted' | 'cancelCount' | 'totalCancelDollars' | 'averageCancelMargin' | 'numWoodCancelled' | 'numSpcCancelled' | 'numCarpetCancelled' | 'numCarpetDemos' | 'numSpcDemos' | 'numWoodDemos'>
);

export type VehicleFragment = (
  { __typename?: 'Vehicle' }
  & Pick<Vehicle, 'id' | 'description' | 'year' | 'licensePlateNumber' | 'condition'>
);

export type W9Fragment = (
  { __typename?: 'W9' }
  & Pick<W9, 'id' | 'workerId' | 'activeDate' | 'expirationDate' | 's3FileId'>
);

export type WorkerFragment = (
  { __typename?: 'Worker' }
  & Pick<Worker, 'id' | 'firstName' | 'lastName' | 'phone' | 'email' | 'driversLicenseNumber' | 'licenseNumber' | 'isActive' | 'isCheckedIn' | 'profilePictureFileId' | 'isCommandCenterWorker' | 'isSalesperson'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & AddressFragment
  )>, vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & VehicleFragment
  )>, individualChatParty?: Maybe<(
    { __typename?: 'ChatParty' }
    & Pick<ChatParty, 'id' | 'name'>
  )> }
);

export type WorkerHistoryFragment = (
  { __typename?: 'WorkerHistory' }
  & Pick<WorkerHistory, 'id'>
  & { dates: Array<(
    { __typename?: 'WorkerHistoryDates' }
    & Pick<WorkerHistoryDates, 'id' | 'startDate' | 'endDate'>
  )> }
);

export type WorkersCompFragment = (
  { __typename?: 'WorkersComp' }
  & Pick<WorkersComp, 'id' | 'workerId' | 'activeDate' | 'expirationDate' | 's3FileId'>
);

export type AddWorkerBlockedTimeSlotMutationVariables = Exact<{
  workerId: Scalars['Int'];
  date: Scalars['DateTime'];
  timeSlot: AtomicTimeSlotInput;
  isBlockedByWorker?: Maybe<Scalars['Boolean']>;
}>;


export type AddWorkerBlockedTimeSlotMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addWorkerBlockedTimeSlot'>
);

export type AddWorkerBlockedTimeSlotRangeMutationVariables = Exact<{
  workerId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type AddWorkerBlockedTimeSlotRangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addWorkerBlockedTimeSlotRange'>
);

export type DeleteBackgroundCheckMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBackgroundCheckMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBackgroundCheck'>
);

export type DeleteContractorInstallationCapablilityMutationVariables = Exact<{
  contractorId: Scalars['Int'];
  productTypeId: Scalars['Int'];
}>;


export type DeleteContractorInstallationCapablilityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractorInstallationCapability'>
);

export type DeleteLiabilityInsuranceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteLiabilityInsuranceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLiabilityInsurance'>
);

export type DeleteW9MutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteW9Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteW9'>
);

export type DeleteWorkersCompMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteWorkersCompMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWorkersComp'>
);

export type SetWorkerDriversLicenseFileMutationVariables = Exact<{
  workerId: Scalars['Int'];
  fileExtension: Scalars['String'];
}>;


export type SetWorkerDriversLicenseFileMutation = (
  { __typename?: 'Mutation' }
  & { setWorkerDriversLicenseFile: (
    { __typename?: 'PreSignedS3FileUpload' }
    & PreSignedS3FileUploadFragment
  ) }
);

export type SetWorkerLicenseFileMutationVariables = Exact<{
  workerId: Scalars['Int'];
  fileExtension: Scalars['String'];
}>;


export type SetWorkerLicenseFileMutation = (
  { __typename?: 'Mutation' }
  & { setWorkerLicenseFile: (
    { __typename?: 'PreSignedS3FileUpload' }
    & PreSignedS3FileUploadFragment
  ) }
);

export type SetWorkerProfilePictureMutationVariables = Exact<{
  workerId: Scalars['Int'];
  fileExtension: Scalars['String'];
}>;


export type SetWorkerProfilePictureMutation = (
  { __typename?: 'Mutation' }
  & { setWorkerProfilePicture: (
    { __typename?: 'PreSignedS3FileUpload' }
    & PreSignedS3FileUploadFragment
  ) }
);

export type UpdateContractorZipsMutationVariables = Exact<{
  contractorId: Scalars['Int'];
  addedZips: Array<Scalars['String']> | Scalars['String'];
  removedZips: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateContractorZipsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateContractorZips'>
);

export type UpdateSalespersonZipsMutationVariables = Exact<{
  salespersonId: Scalars['Int'];
  addedZips: Array<Scalars['String']> | Scalars['String'];
  removedZips: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateSalespersonZipsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSalespersonZips'>
);

export type UpdateWorkerBlockedTimeSlotMutationVariables = Exact<{
  workerBlockedTimeSlotId: Scalars['Int'];
  date: Scalars['DateTime'];
  timeSlot: AtomicTimeSlotInput;
}>;


export type UpdateWorkerBlockedTimeSlotMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWorkerBlockedTimeSlot'>
);

export type UpdateWorkerIsActiveMutationVariables = Exact<{
  workerId: Scalars['Int'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateWorkerIsActiveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWorkerIsActive'>
);

export type UpsertBackgroundCheckMutationVariables = Exact<{
  backgroundCheck: BackgroundCheckInput;
}>;


export type UpsertBackgroundCheckMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertBackgroundCheck'>
);

export type UpsertContractorProfileMutationVariables = Exact<{
  contractor: ContractorProfileInput;
}>;


export type UpsertContractorProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertContractorProfile'>
);

export type UpsertContractorHelperMutationVariables = Exact<{
  helper: ContractorHelperInput;
}>;


export type UpsertContractorHelperMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertContractorHelper'>
);

export type UpsertContractorInstallationCapabilityMutationVariables = Exact<{
  capability: ContractorInstallationCapabilityInput;
}>;


export type UpsertContractorInstallationCapabilityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertContractorInstallationCapability'>
);

export type UpsertLiabilityInsuranceMutationVariables = Exact<{
  liabilityInsurance: LiabilityInsuranceInput;
  hasFile: Scalars['Boolean'];
}>;


export type UpsertLiabilityInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { upsertLiabilityInsurance?: Maybe<(
    { __typename?: 'PreSignedS3FileUpload' }
    & PreSignedS3FileUploadFragment
  )> }
);

export type UpsertSalespersonProfileMutationVariables = Exact<{
  salesperson: SalespersonProfileInput;
}>;


export type UpsertSalespersonProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertSalespersonProfile'>
);

export type UpsertW9MutationVariables = Exact<{
  w9: W9Input;
  hasFile: Scalars['Boolean'];
}>;


export type UpsertW9Mutation = (
  { __typename?: 'Mutation' }
  & { upsertW9?: Maybe<(
    { __typename?: 'PreSignedS3FileUpload' }
    & PreSignedS3FileUploadFragment
  )> }
);

export type UpsertWorkerHistoryMutationVariables = Exact<{
  history: WorkerHistoryInput;
}>;


export type UpsertWorkerHistoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertWorkerHistory'>
);

export type UpsertWorkersCompMutationVariables = Exact<{
  workersComp: WorkersCompInput;
  hasFile: Scalars['Boolean'];
}>;


export type UpsertWorkersCompMutation = (
  { __typename?: 'Mutation' }
  & { upsertWorkersComp?: Maybe<(
    { __typename?: 'PreSignedS3FileUpload' }
    & PreSignedS3FileUploadFragment
  )> }
);

export type WorkerPunchInMutationVariables = Exact<{
  workerId: Scalars['Int'];
}>;


export type WorkerPunchInMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'workerPunchIn'>
);

export const ChatMemberFragmentDoc = gql`
    fragment ChatMember on ChatMember {
  chatPartyId
  chatId
  partyName
  hasNewMessage
}
    `;
export const ChatForJobFragmentDoc = gql`
    fragment ChatForJob on ChatForJob {
  id
  chatId
  jobMember {
    ...ChatMember
  }
  commandCenterMember {
    ...ChatMember
  }
  customerName
}
    ${ChatMemberFragmentDoc}`;
export const CustomerContactFragmentDoc = gql`
    fragment CustomerContact on CustomerContact {
  id
  customerId
  firstName
  lastName
  phone
  phoneExtension
  email
  receivePromoEmails
  isSchedulingContact
  isPrimary
}
    `;
export const PreSignedS3FileUploadFragmentDoc = gql`
    fragment PreSignedS3FileUpload on PreSignedS3FileUpload {
  id
  uploadUrl
}
    `;
export const CarpetCutFragmentDoc = gql`
    fragment CarpetCut on CarpetCut {
  id
  jobConfigurationId
  roomId
  directionBias
  stepLength
  shape {
    x
    y
  }
  pieces {
    id
    carpetCutId
    fillName
    packingBlockNumber
    roomShape {
      x
      y
    }
    packerShape {
      x
      y
    }
  }
}
    `;
export const RoomFragmentDoc = gql`
    fragment Room on Room {
  id
  areaId
  jobConfigurationId
  svg
  detailedSvg
  svgPosition {
    x
    y
  }
  shape {
    x
    y
  }
  isCarpetVerticallyRun
  carpetCuts {
    ...CarpetCut
  }
  labels {
    id
    customName
    hasCloset
    labelNumber
    label {
      id
      label
      fullLabel
    }
    roomId
  }
  notes
  price {
    mSRP
    savings
    total
  }
  services {
    id
    customerDoesService
    serviceTypeId
    jobServiceId
    laborAmount
    materialAmount
    materialCategoryId
    roomId
    isActive
  }
  steps {
    id
    stepDetailId
    roomId
    amount
    width
    length
    isTreadOnly
    isLanding
    shouldPromptAboutSpindles
  }
  pictures {
    id
    roomId
    picture {
      id
      description
    }
  }
  beforePictures {
    id
    roomId
    picture {
      id
      description
    }
  }
  afterPictures {
    id
    roomId
    picture {
      id
      description
    }
  }
  sqft
  lnft
  substrateId
}
    ${CarpetCutFragmentDoc}`;
export const AreaFragmentDoc = gql`
    fragment Area on Area {
  id
  jobConfigurationId
  productTypeId
  productTypeName
  styleId
  styleName
  colorId
  colorName
  lnftWasteFactor
  sqftWasteFactor
  price {
    mSRP
    savings
    total
  }
  preferredCarpetLength
  oppositeCarpetLength
  includedInQuote
  rooms {
    ...Room
  }
  customServices {
    id
    areaId
    roomIds
    description
    price
    contractorPercentage
  }
  notes
  includedInQuote
  preferredCarpetLength
  oppositeCarpetLength
  lineNumber
}
    ${RoomFragmentDoc}`;
export const DiscountFragmentDoc = gql`
    fragment Discount on Discount {
  id
  name
  isScalar
  defaultValue
  isHidden
  isDeleted
  minimumMSRP
}
    `;
export const JobConfigurationFragmentDoc = gql`
    fragment JobConfiguration on JobConfiguration {
  id
  jobId
  optionNumber
  notes
  areas {
    ...Area
  }
  unassignedRooms {
    ...Room
  }
  roomTransitions {
    id
    jobConfigurationId
    mainRoomId
    altRoomId
    altExistingRoomIds
    width
    isDoor
    isExternalDoor
    mainRoomPositionIndex
    altRoomPositionIndex
  }
  price {
    mSRP
    savings
    total
  }
  financing {
    id
    financingAccountNumber
    financingDownPayment
    perMonthAmount
    financingOption {
      id
      term
      minAmount
      typeId
      fee
      startDate
      endDate
      tranCode
      promoParentTranCode
      slot
    }
  }
  discounts {
    id
    isOverrideApplied
    availableDiscounts {
      ...Discount
    }
    discounts {
      id
      amount
      discountId
      authorizationCode
      isScalar
      isValid
      jobConfigurationId
      name
    }
  }
  priceRequest {
    id
    jobConfigurationId
    amount
    salespersonId
    commandCenterWorkerId
    timeRequested
    timeResponded
    granted
  }
  drawingSVG
  promotion {
    id
    basePromotion {
      id
      name
      promotionTypes {
        basePromotionId
        promotionType {
          id
          label
        }
        isScalar
        amount
      }
      isDeleted
    }
    savingsAmount
  }
}
    ${AreaFragmentDoc}
${RoomFragmentDoc}
${DiscountFragmentDoc}`;
export const InstallationAppointmentFragmentDoc = gql`
    fragment InstallationAppointment on InstallationAppointment {
  id
  appointmentNumber
  jobContractId
  jobConfigurationId
  contractorId
  contractorFirstName
  contractorLastName
  dates
  totalDaysInRange
  customerFirstName
  customerLastName
  customerAddress {
    id
    streetAddress
    city
    zip
    apartmentNumber
  }
  customerPhone
  notes
  cod
  collectedCod
  releasedToContractor
  pickedUpMaterialsTime
  mappedToCustomerTime
  checkInTime
  checkOutTime
  estimatedArrivalTime
  contractorLastContactAttempt
  customerNotifiedOfEta
  isComplete
  woodTotal
  spcTotal
  carpetTotal
  hasBeforePictures
  hasAfterPictures
  certificateRating
}
    `;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
  id
  streetAddress
  city
  zip
  apartmentNumber
}
    `;
export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  id
  name
  address {
    ...Address
  }
  eIN
}
    ${AddressFragmentDoc}`;
export const BackgroundCheckFragmentDoc = gql`
    fragment BackgroundCheck on BackgroundCheck {
  id
  workerId
  isInternal
  number
  datePerformed
  expirationDate
}
    `;
export const ContractorInstallationCapabilityFragmentDoc = gql`
    fragment ContractorInstallationCapability on ContractorInstallationCapability {
  contractorId
  productTypeId
  sqftPotential
  isActive
  certificationIds
}
    `;
export const ContractorFragmentDoc = gql`
    fragment Contractor on Contractor {
  id
  workerId
  isActive
  firstName
  lastName
  email
  phone
  helpers {
    id
    firstName
    lastName
    contractorId
    phone
    licenseNumber
    history {
      id
      startDate
      endDate
    }
    isActive
    backgroundChecks {
      ...BackgroundCheck
    }
  }
  capabilities {
    ...ContractorInstallationCapability
  }
  profilePictureFileId
}
    ${BackgroundCheckFragmentDoc}
${ContractorInstallationCapabilityFragmentDoc}`;
export const LiabilityInsuranceFragmentDoc = gql`
    fragment LiabilityInsurance on LiabilityInsurance {
  id
  workerId
  activeDate
  expirationDate
  s3FileId
}
    `;
export const PartialContractorFragmentDoc = gql`
    fragment PartialContractor on Contractor {
  id
  firstName
  lastName
  workerId
}
    `;
export const S3FileFragmentDoc = gql`
    fragment S3File on S3File {
  id
  objectKey
  fileName
  status
  uploadTimestamp
}
    `;
export const SalespersonPerformanceSummaryFragmentDoc = gql`
    fragment SalespersonPerformanceSummary on SalespersonPerformanceSummary {
  id
  salespersonId
  startDate
  endDate
  numLeads
  numDemos
  saleCount
  totalSaleDollars
  averageSaleMargin
  numWoodSold
  numSpcSold
  numCarpetSold
  quoteCount
  totalQuoteDollars
  averageQuoteMargin
  numWoodQuoted
  numSpcQuoted
  numCarpetQuoted
  cancelCount
  totalCancelDollars
  averageCancelMargin
  numWoodCancelled
  numSpcCancelled
  numCarpetCancelled
  numCarpetDemos
  numSpcDemos
  numWoodDemos
}
    `;
export const W9FragmentDoc = gql`
    fragment W9 on W9 {
  id
  workerId
  activeDate
  expirationDate
  s3FileId
}
    `;
export const VehicleFragmentDoc = gql`
    fragment Vehicle on Vehicle {
  id
  description
  year
  licensePlateNumber
  condition
}
    `;
export const WorkerFragmentDoc = gql`
    fragment Worker on Worker {
  id
  firstName
  lastName
  phone
  email
  address {
    ...Address
  }
  driversLicenseNumber
  vehicle {
    ...Vehicle
  }
  licenseNumber
  isActive
  isCheckedIn
  individualChatParty {
    id
    name
  }
  profilePictureFileId
  isCommandCenterWorker
  isSalesperson
}
    ${AddressFragmentDoc}
${VehicleFragmentDoc}`;
export const WorkerHistoryFragmentDoc = gql`
    fragment WorkerHistory on WorkerHistory {
  id
  dates {
    id
    startDate
    endDate
  }
}
    `;
export const WorkersCompFragmentDoc = gql`
    fragment WorkersComp on WorkersComp {
  id
  workerId
  activeDate
  expirationDate
  s3FileId
}
    `;
export const GetAllContractorRatingParameterSetsDocument = gql`
    query GetAllContractorRatingParameterSets {
  allContractorRatingParameterSets {
    id
    numAppointments
    numServices
    startDate
    endDate
    calledInAmWeight
    checkedInWeight
    onTimeWeight
    collectedCodWeight
    completedJobWeight
    completionCertificateSignedWeight
    completionCertificateRatingWeight
    serviceRequestsWeight
    servicesCompletedOnTimeWeight
  }
}
    `;

/**
 * __useGetAllContractorRatingParameterSetsQuery__
 *
 * To run a query within a React component, call `useGetAllContractorRatingParameterSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContractorRatingParameterSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllContractorRatingParameterSetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllContractorRatingParameterSetsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllContractorRatingParameterSetsQuery, GetAllContractorRatingParameterSetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllContractorRatingParameterSetsQuery, GetAllContractorRatingParameterSetsQueryVariables>(GetAllContractorRatingParameterSetsDocument, options);
      }
export function useGetAllContractorRatingParameterSetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllContractorRatingParameterSetsQuery, GetAllContractorRatingParameterSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllContractorRatingParameterSetsQuery, GetAllContractorRatingParameterSetsQueryVariables>(GetAllContractorRatingParameterSetsDocument, options);
        }
export type GetAllContractorRatingParameterSetsQueryHookResult = ReturnType<typeof useGetAllContractorRatingParameterSetsQuery>;
export type GetAllContractorRatingParameterSetsLazyQueryHookResult = ReturnType<typeof useGetAllContractorRatingParameterSetsLazyQuery>;
export type GetAllContractorRatingParameterSetsQueryResult = Apollo.QueryResult<GetAllContractorRatingParameterSetsQuery, GetAllContractorRatingParameterSetsQueryVariables>;
export const SetContractorRatingParametersDocument = gql`
    mutation SetContractorRatingParameters($parameters: ContractorRatingParametersInput!) {
  setContractorRatingParameters(parameters: $parameters)
}
    `;
export type SetContractorRatingParametersMutationFn = Apollo.MutationFunction<SetContractorRatingParametersMutation, SetContractorRatingParametersMutationVariables>;

/**
 * __useSetContractorRatingParametersMutation__
 *
 * To run a mutation, you first call `useSetContractorRatingParametersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetContractorRatingParametersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setContractorRatingParametersMutation, { data, loading, error }] = useSetContractorRatingParametersMutation({
 *   variables: {
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useSetContractorRatingParametersMutation(baseOptions?: Apollo.MutationHookOptions<SetContractorRatingParametersMutation, SetContractorRatingParametersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetContractorRatingParametersMutation, SetContractorRatingParametersMutationVariables>(SetContractorRatingParametersDocument, options);
      }
export type SetContractorRatingParametersMutationHookResult = ReturnType<typeof useSetContractorRatingParametersMutation>;
export type SetContractorRatingParametersMutationResult = Apollo.MutationResult<SetContractorRatingParametersMutation>;
export type SetContractorRatingParametersMutationOptions = Apollo.BaseMutationOptions<SetContractorRatingParametersMutation, SetContractorRatingParametersMutationVariables>;
export const GetAllDiscountsDocument = gql`
    query GetAllDiscounts($ignoreDeleted: Boolean) {
  allDiscounts(ignoreDeleted: $ignoreDeleted) {
    ...Discount
  }
}
    ${DiscountFragmentDoc}`;

/**
 * __useGetAllDiscountsQuery__
 *
 * To run a query within a React component, call `useGetAllDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDiscountsQuery({
 *   variables: {
 *      ignoreDeleted: // value for 'ignoreDeleted'
 *   },
 * });
 */
export function useGetAllDiscountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDiscountsQuery, GetAllDiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDiscountsQuery, GetAllDiscountsQueryVariables>(GetAllDiscountsDocument, options);
      }
export function useGetAllDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDiscountsQuery, GetAllDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDiscountsQuery, GetAllDiscountsQueryVariables>(GetAllDiscountsDocument, options);
        }
export type GetAllDiscountsQueryHookResult = ReturnType<typeof useGetAllDiscountsQuery>;
export type GetAllDiscountsLazyQueryHookResult = ReturnType<typeof useGetAllDiscountsLazyQuery>;
export type GetAllDiscountsQueryResult = Apollo.QueryResult<GetAllDiscountsQuery, GetAllDiscountsQueryVariables>;
export const DeleteDiscountDocument = gql`
    mutation DeleteDiscount($discountId: Int!) {
  deleteDiscount(discountId: $discountId)
}
    `;
export type DeleteDiscountMutationFn = Apollo.MutationFunction<DeleteDiscountMutation, DeleteDiscountMutationVariables>;

/**
 * __useDeleteDiscountMutation__
 *
 * To run a mutation, you first call `useDeleteDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscountMutation, { data, loading, error }] = useDeleteDiscountMutation({
 *   variables: {
 *      discountId: // value for 'discountId'
 *   },
 * });
 */
export function useDeleteDiscountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDiscountMutation, DeleteDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDiscountMutation, DeleteDiscountMutationVariables>(DeleteDiscountDocument, options);
      }
export type DeleteDiscountMutationHookResult = ReturnType<typeof useDeleteDiscountMutation>;
export type DeleteDiscountMutationResult = Apollo.MutationResult<DeleteDiscountMutation>;
export type DeleteDiscountMutationOptions = Apollo.BaseMutationOptions<DeleteDiscountMutation, DeleteDiscountMutationVariables>;
export const UpdateDiscountVisibilityDocument = gql`
    mutation UpdateDiscountVisibility($discountId: Int!, $isHidden: Boolean!) {
  updateDiscountVisibility(discountId: $discountId, isHidden: $isHidden)
}
    `;
export type UpdateDiscountVisibilityMutationFn = Apollo.MutationFunction<UpdateDiscountVisibilityMutation, UpdateDiscountVisibilityMutationVariables>;

/**
 * __useUpdateDiscountVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdateDiscountVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscountVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscountVisibilityMutation, { data, loading, error }] = useUpdateDiscountVisibilityMutation({
 *   variables: {
 *      discountId: // value for 'discountId'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useUpdateDiscountVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiscountVisibilityMutation, UpdateDiscountVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiscountVisibilityMutation, UpdateDiscountVisibilityMutationVariables>(UpdateDiscountVisibilityDocument, options);
      }
export type UpdateDiscountVisibilityMutationHookResult = ReturnType<typeof useUpdateDiscountVisibilityMutation>;
export type UpdateDiscountVisibilityMutationResult = Apollo.MutationResult<UpdateDiscountVisibilityMutation>;
export type UpdateDiscountVisibilityMutationOptions = Apollo.BaseMutationOptions<UpdateDiscountVisibilityMutation, UpdateDiscountVisibilityMutationVariables>;
export const UpsertDiscountDocument = gql`
    mutation UpsertDiscount($discount: DiscountInput!) {
  upsertDiscount(discount: $discount)
}
    `;
export type UpsertDiscountMutationFn = Apollo.MutationFunction<UpsertDiscountMutation, UpsertDiscountMutationVariables>;

/**
 * __useUpsertDiscountMutation__
 *
 * To run a mutation, you first call `useUpsertDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDiscountMutation, { data, loading, error }] = useUpsertDiscountMutation({
 *   variables: {
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useUpsertDiscountMutation(baseOptions?: Apollo.MutationHookOptions<UpsertDiscountMutation, UpsertDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertDiscountMutation, UpsertDiscountMutationVariables>(UpsertDiscountDocument, options);
      }
export type UpsertDiscountMutationHookResult = ReturnType<typeof useUpsertDiscountMutation>;
export type UpsertDiscountMutationResult = Apollo.MutationResult<UpsertDiscountMutation>;
export type UpsertDiscountMutationOptions = Apollo.BaseMutationOptions<UpsertDiscountMutation, UpsertDiscountMutationVariables>;
export const GetFinancingTableDataDocument = gql`
    query GetFinancingTableData {
  financingTypes {
    id
    name
  }
  financingTableOptions {
    id
    baseOption {
      id
      term
      minAmount
      typeId
      fee
      startDate
      endDate
      tranCode
      promoParentTranCode
      slot
    }
    baseHistory {
      id
      term
      minAmount
      typeId
      fee
      startDate
      endDate
      tranCode
      promoParentTranCode
      slot
    }
    activePromo {
      id
      term
      minAmount
      typeId
      fee
      startDate
      endDate
      tranCode
      promoParentTranCode
      slot
    }
    promoHistory {
      id
      term
      minAmount
      typeId
      fee
      startDate
      endDate
      tranCode
      promoParentTranCode
      slot
    }
  }
}
    `;

/**
 * __useGetFinancingTableDataQuery__
 *
 * To run a query within a React component, call `useGetFinancingTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancingTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancingTableDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFinancingTableDataQuery(baseOptions?: Apollo.QueryHookOptions<GetFinancingTableDataQuery, GetFinancingTableDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinancingTableDataQuery, GetFinancingTableDataQueryVariables>(GetFinancingTableDataDocument, options);
      }
export function useGetFinancingTableDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinancingTableDataQuery, GetFinancingTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinancingTableDataQuery, GetFinancingTableDataQueryVariables>(GetFinancingTableDataDocument, options);
        }
export type GetFinancingTableDataQueryHookResult = ReturnType<typeof useGetFinancingTableDataQuery>;
export type GetFinancingTableDataLazyQueryHookResult = ReturnType<typeof useGetFinancingTableDataLazyQuery>;
export type GetFinancingTableDataQueryResult = Apollo.QueryResult<GetFinancingTableDataQuery, GetFinancingTableDataQueryVariables>;
export const UpsertFinancingOptionDocument = gql`
    mutation UpsertFinancingOption($option: FinancingOptionInput!) {
  upsertFinancingOption(upsertedOption: $option)
}
    `;
export type UpsertFinancingOptionMutationFn = Apollo.MutationFunction<UpsertFinancingOptionMutation, UpsertFinancingOptionMutationVariables>;

/**
 * __useUpsertFinancingOptionMutation__
 *
 * To run a mutation, you first call `useUpsertFinancingOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFinancingOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFinancingOptionMutation, { data, loading, error }] = useUpsertFinancingOptionMutation({
 *   variables: {
 *      option: // value for 'option'
 *   },
 * });
 */
export function useUpsertFinancingOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpsertFinancingOptionMutation, UpsertFinancingOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertFinancingOptionMutation, UpsertFinancingOptionMutationVariables>(UpsertFinancingOptionDocument, options);
      }
export type UpsertFinancingOptionMutationHookResult = ReturnType<typeof useUpsertFinancingOptionMutation>;
export type UpsertFinancingOptionMutationResult = Apollo.MutationResult<UpsertFinancingOptionMutation>;
export type UpsertFinancingOptionMutationOptions = Apollo.BaseMutationOptions<UpsertFinancingOptionMutation, UpsertFinancingOptionMutationVariables>;
export const CheckForPromotionConflictsDocument = gql`
    query CheckForPromotionConflicts($startDate: DateTime!, $endDate: DateTime!, $marketIds: [Int!]!, $scheduledIdToIgnore: Int) {
  checkForPromotionConflicts(startDate: $startDate, endDate: $endDate, marketIds: $marketIds, scheduledIdToIgnore: $scheduledIdToIgnore)
}
    `;

/**
 * __useCheckForPromotionConflictsQuery__
 *
 * To run a query within a React component, call `useCheckForPromotionConflictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckForPromotionConflictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckForPromotionConflictsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      marketIds: // value for 'marketIds'
 *      scheduledIdToIgnore: // value for 'scheduledIdToIgnore'
 *   },
 * });
 */
export function useCheckForPromotionConflictsQuery(baseOptions: Apollo.QueryHookOptions<CheckForPromotionConflictsQuery, CheckForPromotionConflictsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckForPromotionConflictsQuery, CheckForPromotionConflictsQueryVariables>(CheckForPromotionConflictsDocument, options);
      }
export function useCheckForPromotionConflictsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckForPromotionConflictsQuery, CheckForPromotionConflictsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckForPromotionConflictsQuery, CheckForPromotionConflictsQueryVariables>(CheckForPromotionConflictsDocument, options);
        }
export type CheckForPromotionConflictsQueryHookResult = ReturnType<typeof useCheckForPromotionConflictsQuery>;
export type CheckForPromotionConflictsLazyQueryHookResult = ReturnType<typeof useCheckForPromotionConflictsLazyQuery>;
export type CheckForPromotionConflictsQueryResult = Apollo.QueryResult<CheckForPromotionConflictsQuery, CheckForPromotionConflictsQueryVariables>;
export const GetAllScheduledPromotionsDocument = gql`
    query GetAllScheduledPromotions($excludeExpired: Boolean, $excludeCanceled: Boolean) {
  allScheduledPromotions(excludeExpired: $excludeExpired, excludeCanceled: $excludeCanceled) {
    id
    basePromotion {
      id
      name
      promotionTypes {
        basePromotionId
        promotionType {
          id
          label
        }
        isScalar
        amount
      }
      isDeleted
    }
    startDate
    endDate
    marketIds
    isCanceled
  }
}
    `;

/**
 * __useGetAllScheduledPromotionsQuery__
 *
 * To run a query within a React component, call `useGetAllScheduledPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllScheduledPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllScheduledPromotionsQuery({
 *   variables: {
 *      excludeExpired: // value for 'excludeExpired'
 *      excludeCanceled: // value for 'excludeCanceled'
 *   },
 * });
 */
export function useGetAllScheduledPromotionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllScheduledPromotionsQuery, GetAllScheduledPromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllScheduledPromotionsQuery, GetAllScheduledPromotionsQueryVariables>(GetAllScheduledPromotionsDocument, options);
      }
export function useGetAllScheduledPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllScheduledPromotionsQuery, GetAllScheduledPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllScheduledPromotionsQuery, GetAllScheduledPromotionsQueryVariables>(GetAllScheduledPromotionsDocument, options);
        }
export type GetAllScheduledPromotionsQueryHookResult = ReturnType<typeof useGetAllScheduledPromotionsQuery>;
export type GetAllScheduledPromotionsLazyQueryHookResult = ReturnType<typeof useGetAllScheduledPromotionsLazyQuery>;
export type GetAllScheduledPromotionsQueryResult = Apollo.QueryResult<GetAllScheduledPromotionsQuery, GetAllScheduledPromotionsQueryVariables>;
export const GetBasePromotionsTableDataDocument = gql`
    query GetBasePromotionsTableData($excludeDeleted: Boolean) {
  promotionTypes {
    id
    label
  }
  allBasePromotions(excludeDeleted: $excludeDeleted) {
    id
    name
    promotionTypes {
      basePromotionId
      promotionType {
        id
        label
      }
      isScalar
      amount
    }
    isDeleted
  }
  basePromotionIdsForActiveOrUpcoming
}
    `;

/**
 * __useGetBasePromotionsTableDataQuery__
 *
 * To run a query within a React component, call `useGetBasePromotionsTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasePromotionsTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasePromotionsTableDataQuery({
 *   variables: {
 *      excludeDeleted: // value for 'excludeDeleted'
 *   },
 * });
 */
export function useGetBasePromotionsTableDataQuery(baseOptions?: Apollo.QueryHookOptions<GetBasePromotionsTableDataQuery, GetBasePromotionsTableDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasePromotionsTableDataQuery, GetBasePromotionsTableDataQueryVariables>(GetBasePromotionsTableDataDocument, options);
      }
export function useGetBasePromotionsTableDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasePromotionsTableDataQuery, GetBasePromotionsTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasePromotionsTableDataQuery, GetBasePromotionsTableDataQueryVariables>(GetBasePromotionsTableDataDocument, options);
        }
export type GetBasePromotionsTableDataQueryHookResult = ReturnType<typeof useGetBasePromotionsTableDataQuery>;
export type GetBasePromotionsTableDataLazyQueryHookResult = ReturnType<typeof useGetBasePromotionsTableDataLazyQuery>;
export type GetBasePromotionsTableDataQueryResult = Apollo.QueryResult<GetBasePromotionsTableDataQuery, GetBasePromotionsTableDataQueryVariables>;
export const GetPromotionOptionDocument = gql`
    query GetPromotionOption($jobConfigurationId: Int!) {
  promotionOption(jobConfigurationId: $jobConfigurationId) {
    id
    name
    promotionTypes {
      basePromotionId
      promotionType {
        id
        label
      }
      isScalar
      amount
    }
    isDeleted
  }
}
    `;

/**
 * __useGetPromotionOptionQuery__
 *
 * To run a query within a React component, call `useGetPromotionOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionOptionQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetPromotionOptionQuery(baseOptions: Apollo.QueryHookOptions<GetPromotionOptionQuery, GetPromotionOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromotionOptionQuery, GetPromotionOptionQueryVariables>(GetPromotionOptionDocument, options);
      }
export function useGetPromotionOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionOptionQuery, GetPromotionOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromotionOptionQuery, GetPromotionOptionQueryVariables>(GetPromotionOptionDocument, options);
        }
export type GetPromotionOptionQueryHookResult = ReturnType<typeof useGetPromotionOptionQuery>;
export type GetPromotionOptionLazyQueryHookResult = ReturnType<typeof useGetPromotionOptionLazyQuery>;
export type GetPromotionOptionQueryResult = Apollo.QueryResult<GetPromotionOptionQuery, GetPromotionOptionQueryVariables>;
export const AddScheduledPromotionDocument = gql`
    mutation AddScheduledPromotion($basePromotionId: Int!, $startDate: DateTime!, $endDate: DateTime!, $marketIds: [Int!]!) {
  addScheduledPromotion(basePromotionId: $basePromotionId, startDate: $startDate, endDate: $endDate, marketIds: $marketIds)
}
    `;
export type AddScheduledPromotionMutationFn = Apollo.MutationFunction<AddScheduledPromotionMutation, AddScheduledPromotionMutationVariables>;

/**
 * __useAddScheduledPromotionMutation__
 *
 * To run a mutation, you first call `useAddScheduledPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddScheduledPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addScheduledPromotionMutation, { data, loading, error }] = useAddScheduledPromotionMutation({
 *   variables: {
 *      basePromotionId: // value for 'basePromotionId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      marketIds: // value for 'marketIds'
 *   },
 * });
 */
export function useAddScheduledPromotionMutation(baseOptions?: Apollo.MutationHookOptions<AddScheduledPromotionMutation, AddScheduledPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddScheduledPromotionMutation, AddScheduledPromotionMutationVariables>(AddScheduledPromotionDocument, options);
      }
export type AddScheduledPromotionMutationHookResult = ReturnType<typeof useAddScheduledPromotionMutation>;
export type AddScheduledPromotionMutationResult = Apollo.MutationResult<AddScheduledPromotionMutation>;
export type AddScheduledPromotionMutationOptions = Apollo.BaseMutationOptions<AddScheduledPromotionMutation, AddScheduledPromotionMutationVariables>;
export const UpsertBasePromotionDocument = gql`
    mutation UpsertBasePromotion($promotion: BasePromotionInput!) {
  upsertBasePromotion(promotion: $promotion)
}
    `;
export type UpsertBasePromotionMutationFn = Apollo.MutationFunction<UpsertBasePromotionMutation, UpsertBasePromotionMutationVariables>;

/**
 * __useUpsertBasePromotionMutation__
 *
 * To run a mutation, you first call `useUpsertBasePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBasePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBasePromotionMutation, { data, loading, error }] = useUpsertBasePromotionMutation({
 *   variables: {
 *      promotion: // value for 'promotion'
 *   },
 * });
 */
export function useUpsertBasePromotionMutation(baseOptions?: Apollo.MutationHookOptions<UpsertBasePromotionMutation, UpsertBasePromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertBasePromotionMutation, UpsertBasePromotionMutationVariables>(UpsertBasePromotionDocument, options);
      }
export type UpsertBasePromotionMutationHookResult = ReturnType<typeof useUpsertBasePromotionMutation>;
export type UpsertBasePromotionMutationResult = Apollo.MutationResult<UpsertBasePromotionMutation>;
export type UpsertBasePromotionMutationOptions = Apollo.BaseMutationOptions<UpsertBasePromotionMutation, UpsertBasePromotionMutationVariables>;
export const CancelScheduledPromotionDocument = gql`
    mutation CancelScheduledPromotion($id: Int!) {
  cancelScheduledPromotion(id: $id)
}
    `;
export type CancelScheduledPromotionMutationFn = Apollo.MutationFunction<CancelScheduledPromotionMutation, CancelScheduledPromotionMutationVariables>;

/**
 * __useCancelScheduledPromotionMutation__
 *
 * To run a mutation, you first call `useCancelScheduledPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduledPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduledPromotionMutation, { data, loading, error }] = useCancelScheduledPromotionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelScheduledPromotionMutation(baseOptions?: Apollo.MutationHookOptions<CancelScheduledPromotionMutation, CancelScheduledPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelScheduledPromotionMutation, CancelScheduledPromotionMutationVariables>(CancelScheduledPromotionDocument, options);
      }
export type CancelScheduledPromotionMutationHookResult = ReturnType<typeof useCancelScheduledPromotionMutation>;
export type CancelScheduledPromotionMutationResult = Apollo.MutationResult<CancelScheduledPromotionMutation>;
export type CancelScheduledPromotionMutationOptions = Apollo.BaseMutationOptions<CancelScheduledPromotionMutation, CancelScheduledPromotionMutationVariables>;
export const DeleteBasePromotionDocument = gql`
    mutation DeleteBasePromotion($basePromotionId: Int!) {
  deleteBasePromotion(basePromotionId: $basePromotionId)
}
    `;
export type DeleteBasePromotionMutationFn = Apollo.MutationFunction<DeleteBasePromotionMutation, DeleteBasePromotionMutationVariables>;

/**
 * __useDeleteBasePromotionMutation__
 *
 * To run a mutation, you first call `useDeleteBasePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBasePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBasePromotionMutation, { data, loading, error }] = useDeleteBasePromotionMutation({
 *   variables: {
 *      basePromotionId: // value for 'basePromotionId'
 *   },
 * });
 */
export function useDeleteBasePromotionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBasePromotionMutation, DeleteBasePromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBasePromotionMutation, DeleteBasePromotionMutationVariables>(DeleteBasePromotionDocument, options);
      }
export type DeleteBasePromotionMutationHookResult = ReturnType<typeof useDeleteBasePromotionMutation>;
export type DeleteBasePromotionMutationResult = Apollo.MutationResult<DeleteBasePromotionMutation>;
export type DeleteBasePromotionMutationOptions = Apollo.BaseMutationOptions<DeleteBasePromotionMutation, DeleteBasePromotionMutationVariables>;
export const UpdateAppliedPromotionDocument = gql`
    mutation UpdateAppliedPromotion($jobConfigurationId: Int!, $basePromotionId: Int) {
  updateAppliedPromotion(jobConfigurationId: $jobConfigurationId, basePromotionId: $basePromotionId)
}
    `;
export type UpdateAppliedPromotionMutationFn = Apollo.MutationFunction<UpdateAppliedPromotionMutation, UpdateAppliedPromotionMutationVariables>;

/**
 * __useUpdateAppliedPromotionMutation__
 *
 * To run a mutation, you first call `useUpdateAppliedPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppliedPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppliedPromotionMutation, { data, loading, error }] = useUpdateAppliedPromotionMutation({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      basePromotionId: // value for 'basePromotionId'
 *   },
 * });
 */
export function useUpdateAppliedPromotionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppliedPromotionMutation, UpdateAppliedPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppliedPromotionMutation, UpdateAppliedPromotionMutationVariables>(UpdateAppliedPromotionDocument, options);
      }
export type UpdateAppliedPromotionMutationHookResult = ReturnType<typeof useUpdateAppliedPromotionMutation>;
export type UpdateAppliedPromotionMutationResult = Apollo.MutationResult<UpdateAppliedPromotionMutation>;
export type UpdateAppliedPromotionMutationOptions = Apollo.BaseMutationOptions<UpdateAppliedPromotionMutation, UpdateAppliedPromotionMutationVariables>;
export const UpdateScheduledPromotionDocument = gql`
    mutation UpdateScheduledPromotion($scheduledPromotionId: Int!, $startDate: DateTime, $endDate: DateTime) {
  updateScheduledPromotion(scheduledPromotionId: $scheduledPromotionId, startDate: $startDate, endDate: $endDate)
}
    `;
export type UpdateScheduledPromotionMutationFn = Apollo.MutationFunction<UpdateScheduledPromotionMutation, UpdateScheduledPromotionMutationVariables>;

/**
 * __useUpdateScheduledPromotionMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledPromotionMutation, { data, loading, error }] = useUpdateScheduledPromotionMutation({
 *   variables: {
 *      scheduledPromotionId: // value for 'scheduledPromotionId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdateScheduledPromotionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduledPromotionMutation, UpdateScheduledPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduledPromotionMutation, UpdateScheduledPromotionMutationVariables>(UpdateScheduledPromotionDocument, options);
      }
export type UpdateScheduledPromotionMutationHookResult = ReturnType<typeof useUpdateScheduledPromotionMutation>;
export type UpdateScheduledPromotionMutationResult = Apollo.MutationResult<UpdateScheduledPromotionMutation>;
export type UpdateScheduledPromotionMutationOptions = Apollo.BaseMutationOptions<UpdateScheduledPromotionMutation, UpdateScheduledPromotionMutationVariables>;
export const UpdateWhichMarketsForScheduledPromotionDocument = gql`
    mutation UpdateWhichMarketsForScheduledPromotion($scheduledPromotionId: Int!, $addedMarketIds: [Int!]!, $removedMarketIds: [Int!]!) {
  updateWhichMarketsForScheduledPromotion(scheduledPromotionId: $scheduledPromotionId, addedMarketIds: $addedMarketIds, removedMarketIds: $removedMarketIds)
}
    `;
export type UpdateWhichMarketsForScheduledPromotionMutationFn = Apollo.MutationFunction<UpdateWhichMarketsForScheduledPromotionMutation, UpdateWhichMarketsForScheduledPromotionMutationVariables>;

/**
 * __useUpdateWhichMarketsForScheduledPromotionMutation__
 *
 * To run a mutation, you first call `useUpdateWhichMarketsForScheduledPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWhichMarketsForScheduledPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWhichMarketsForScheduledPromotionMutation, { data, loading, error }] = useUpdateWhichMarketsForScheduledPromotionMutation({
 *   variables: {
 *      scheduledPromotionId: // value for 'scheduledPromotionId'
 *      addedMarketIds: // value for 'addedMarketIds'
 *      removedMarketIds: // value for 'removedMarketIds'
 *   },
 * });
 */
export function useUpdateWhichMarketsForScheduledPromotionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWhichMarketsForScheduledPromotionMutation, UpdateWhichMarketsForScheduledPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWhichMarketsForScheduledPromotionMutation, UpdateWhichMarketsForScheduledPromotionMutationVariables>(UpdateWhichMarketsForScheduledPromotionDocument, options);
      }
export type UpdateWhichMarketsForScheduledPromotionMutationHookResult = ReturnType<typeof useUpdateWhichMarketsForScheduledPromotionMutation>;
export type UpdateWhichMarketsForScheduledPromotionMutationResult = Apollo.MutationResult<UpdateWhichMarketsForScheduledPromotionMutation>;
export type UpdateWhichMarketsForScheduledPromotionMutationOptions = Apollo.BaseMutationOptions<UpdateWhichMarketsForScheduledPromotionMutation, UpdateWhichMarketsForScheduledPromotionMutationVariables>;
export const GetAllCallCenterJobsDocument = gql`
    query GetAllCallCenterJobs {
  allCallCenterJobs {
    id
    callCenterWorker {
      id
      firstName
      lastName
      email
    }
    creationTime
    minutesFromCreation
    prospectNotes
    customerId
    customerFirstName
    customerLastName
    customerCity
    customerEmail
    customerPhone
    callBackTime
    isQualified
    appointment {
      id
      appointmentNumber
      scheduledDate
      timeReminderSent
      timeToSendReminder
      remindToday
      isCancelled
    }
    lastAction {
      id
      callCenterActionOptionId
      callCenterActionOptionLabel
      notes
      date
      createdByWorkerId
    }
    isInRecovery
    recoveryCallWorker {
      id
      firstName
      lastName
      email
    }
    dateEnteredRecovery
  }
}
    `;

/**
 * __useGetAllCallCenterJobsQuery__
 *
 * To run a query within a React component, call `useGetAllCallCenterJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCallCenterJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCallCenterJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCallCenterJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCallCenterJobsQuery, GetAllCallCenterJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCallCenterJobsQuery, GetAllCallCenterJobsQueryVariables>(GetAllCallCenterJobsDocument, options);
      }
export function useGetAllCallCenterJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCallCenterJobsQuery, GetAllCallCenterJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCallCenterJobsQuery, GetAllCallCenterJobsQueryVariables>(GetAllCallCenterJobsDocument, options);
        }
export type GetAllCallCenterJobsQueryHookResult = ReturnType<typeof useGetAllCallCenterJobsQuery>;
export type GetAllCallCenterJobsLazyQueryHookResult = ReturnType<typeof useGetAllCallCenterJobsLazyQuery>;
export type GetAllCallCenterJobsQueryResult = Apollo.QueryResult<GetAllCallCenterJobsQuery, GetAllCallCenterJobsQueryVariables>;
export const GetCallCenterJobDocument = gql`
    query GetCallCenterJob($jobId: Int!) {
  callCenterJob(jobId: $jobId) {
    id
    callCenterWorker {
      id
      firstName
      lastName
      email
    }
    creationTime
    minutesFromCreation
    prospectNotes
    customerId
    customerFirstName
    customerLastName
    customerCity
    customerEmail
    customerPhone
    callBackTime
    isQualified
    appointment {
      id
      appointmentNumber
      scheduledDate
      timeReminderSent
      timeToSendReminder
      remindToday
      isCancelled
      notes
    }
    lastAction {
      id
      callCenterActionOptionId
      callCenterActionOptionLabel
      notes
      date
      createdByWorkerId
    }
    isInRecovery
    recoveryCallWorker {
      id
      firstName
      lastName
      email
    }
    dateEnteredRecovery
  }
}
    `;

/**
 * __useGetCallCenterJobQuery__
 *
 * To run a query within a React component, call `useGetCallCenterJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallCenterJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallCenterJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetCallCenterJobQuery(baseOptions: Apollo.QueryHookOptions<GetCallCenterJobQuery, GetCallCenterJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCallCenterJobQuery, GetCallCenterJobQueryVariables>(GetCallCenterJobDocument, options);
      }
export function useGetCallCenterJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCallCenterJobQuery, GetCallCenterJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCallCenterJobQuery, GetCallCenterJobQueryVariables>(GetCallCenterJobDocument, options);
        }
export type GetCallCenterJobQueryHookResult = ReturnType<typeof useGetCallCenterJobQuery>;
export type GetCallCenterJobLazyQueryHookResult = ReturnType<typeof useGetCallCenterJobLazyQuery>;
export type GetCallCenterJobQueryResult = Apollo.QueryResult<GetCallCenterJobQuery, GetCallCenterJobQueryVariables>;
export const GetCallCenterScriptsDocument = gql`
    query GetCallCenterScripts {
  callCenterScripts {
    id
    name
    text
    lastUpdated
  }
}
    `;

/**
 * __useGetCallCenterScriptsQuery__
 *
 * To run a query within a React component, call `useGetCallCenterScriptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallCenterScriptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallCenterScriptsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCallCenterScriptsQuery(baseOptions?: Apollo.QueryHookOptions<GetCallCenterScriptsQuery, GetCallCenterScriptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCallCenterScriptsQuery, GetCallCenterScriptsQueryVariables>(GetCallCenterScriptsDocument, options);
      }
export function useGetCallCenterScriptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCallCenterScriptsQuery, GetCallCenterScriptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCallCenterScriptsQuery, GetCallCenterScriptsQueryVariables>(GetCallCenterScriptsDocument, options);
        }
export type GetCallCenterScriptsQueryHookResult = ReturnType<typeof useGetCallCenterScriptsQuery>;
export type GetCallCenterScriptsLazyQueryHookResult = ReturnType<typeof useGetCallCenterScriptsLazyQuery>;
export type GetCallCenterScriptsQueryResult = Apollo.QueryResult<GetCallCenterScriptsQuery, GetCallCenterScriptsQueryVariables>;
export const AddCallCenterActionDocument = gql`
    mutation AddCallCenterAction($jobId: Int!, $callCenterOptionId: Int!, $notes: String!) {
  addCallCenterAction(jobId: $jobId, callCenterOptionId: $callCenterOptionId, notes: $notes)
}
    `;
export type AddCallCenterActionMutationFn = Apollo.MutationFunction<AddCallCenterActionMutation, AddCallCenterActionMutationVariables>;

/**
 * __useAddCallCenterActionMutation__
 *
 * To run a mutation, you first call `useAddCallCenterActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCallCenterActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCallCenterActionMutation, { data, loading, error }] = useAddCallCenterActionMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      callCenterOptionId: // value for 'callCenterOptionId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useAddCallCenterActionMutation(baseOptions?: Apollo.MutationHookOptions<AddCallCenterActionMutation, AddCallCenterActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCallCenterActionMutation, AddCallCenterActionMutationVariables>(AddCallCenterActionDocument, options);
      }
export type AddCallCenterActionMutationHookResult = ReturnType<typeof useAddCallCenterActionMutation>;
export type AddCallCenterActionMutationResult = Apollo.MutationResult<AddCallCenterActionMutation>;
export type AddCallCenterActionMutationOptions = Apollo.BaseMutationOptions<AddCallCenterActionMutation, AddCallCenterActionMutationVariables>;
export const DeleteCallCenterScriptDocument = gql`
    mutation DeleteCallCenterScript($scriptId: Int!) {
  deleteCallCenterScript(scriptId: $scriptId)
}
    `;
export type DeleteCallCenterScriptMutationFn = Apollo.MutationFunction<DeleteCallCenterScriptMutation, DeleteCallCenterScriptMutationVariables>;

/**
 * __useDeleteCallCenterScriptMutation__
 *
 * To run a mutation, you first call `useDeleteCallCenterScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCallCenterScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCallCenterScriptMutation, { data, loading, error }] = useDeleteCallCenterScriptMutation({
 *   variables: {
 *      scriptId: // value for 'scriptId'
 *   },
 * });
 */
export function useDeleteCallCenterScriptMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCallCenterScriptMutation, DeleteCallCenterScriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCallCenterScriptMutation, DeleteCallCenterScriptMutationVariables>(DeleteCallCenterScriptDocument, options);
      }
export type DeleteCallCenterScriptMutationHookResult = ReturnType<typeof useDeleteCallCenterScriptMutation>;
export type DeleteCallCenterScriptMutationResult = Apollo.MutationResult<DeleteCallCenterScriptMutation>;
export type DeleteCallCenterScriptMutationOptions = Apollo.BaseMutationOptions<DeleteCallCenterScriptMutation, DeleteCallCenterScriptMutationVariables>;
export const SetCallCenterCallbackDocument = gql`
    mutation SetCallCenterCallback($jobId: Int!, $callBackTime: DateTime) {
  setCallCenterCallback(jobId: $jobId, callBackTime: $callBackTime) {
    id
    callCenterWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      individualChatParty {
        id
        name
      }
      isSalesperson
      isCommandCenterWorker
    }
    creationTime
    minutesFromCreation
    prospectNotes
    customerId
    customerFirstName
    customerLastName
    customerCity
    customerEmail
    customerPhone
    callBackTime
    isQualified
    appointment {
      id
      appointmentNumber
      scheduledDate
      timeReminderSent
      timeToSendReminder
      remindToday
      isCancelled
    }
    lastAction {
      id
      callCenterActionOptionId
      callCenterActionOptionLabel
      notes
      date
      createdByWorkerId
    }
    isInRecovery
    recoveryCallWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      isSalesperson
      isCommandCenterWorker
    }
    dateEnteredRecovery
  }
}
    `;
export type SetCallCenterCallbackMutationFn = Apollo.MutationFunction<SetCallCenterCallbackMutation, SetCallCenterCallbackMutationVariables>;

/**
 * __useSetCallCenterCallbackMutation__
 *
 * To run a mutation, you first call `useSetCallCenterCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCallCenterCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCallCenterCallbackMutation, { data, loading, error }] = useSetCallCenterCallbackMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      callBackTime: // value for 'callBackTime'
 *   },
 * });
 */
export function useSetCallCenterCallbackMutation(baseOptions?: Apollo.MutationHookOptions<SetCallCenterCallbackMutation, SetCallCenterCallbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCallCenterCallbackMutation, SetCallCenterCallbackMutationVariables>(SetCallCenterCallbackDocument, options);
      }
export type SetCallCenterCallbackMutationHookResult = ReturnType<typeof useSetCallCenterCallbackMutation>;
export type SetCallCenterCallbackMutationResult = Apollo.MutationResult<SetCallCenterCallbackMutation>;
export type SetCallCenterCallbackMutationOptions = Apollo.BaseMutationOptions<SetCallCenterCallbackMutation, SetCallCenterCallbackMutationVariables>;
export const SetCallCenterCanceledByCustomerDocument = gql`
    mutation SetCallCenterCanceledByCustomer($appointmentId: Int!, $callbackDate: DateTime!, $notes: String!) {
  setCallCenterCanceledByCustomer(appointmentId: $appointmentId, callbackDate: $callbackDate, notes: $notes) {
    id
    callCenterWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      individualChatParty {
        id
        name
      }
      isSalesperson
      isCommandCenterWorker
    }
    creationTime
    minutesFromCreation
    prospectNotes
    customerId
    customerFirstName
    customerLastName
    customerCity
    customerEmail
    customerPhone
    callBackTime
    isQualified
    appointment {
      id
      appointmentNumber
      scheduledDate
      timeReminderSent
      timeToSendReminder
      remindToday
      isCancelled
    }
    lastAction {
      id
      callCenterActionOptionId
      callCenterActionOptionLabel
      notes
      date
      createdByWorkerId
    }
    isInRecovery
    recoveryCallWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      isSalesperson
      isCommandCenterWorker
    }
    dateEnteredRecovery
  }
}
    `;
export type SetCallCenterCanceledByCustomerMutationFn = Apollo.MutationFunction<SetCallCenterCanceledByCustomerMutation, SetCallCenterCanceledByCustomerMutationVariables>;

/**
 * __useSetCallCenterCanceledByCustomerMutation__
 *
 * To run a mutation, you first call `useSetCallCenterCanceledByCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCallCenterCanceledByCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCallCenterCanceledByCustomerMutation, { data, loading, error }] = useSetCallCenterCanceledByCustomerMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      callbackDate: // value for 'callbackDate'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useSetCallCenterCanceledByCustomerMutation(baseOptions?: Apollo.MutationHookOptions<SetCallCenterCanceledByCustomerMutation, SetCallCenterCanceledByCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCallCenterCanceledByCustomerMutation, SetCallCenterCanceledByCustomerMutationVariables>(SetCallCenterCanceledByCustomerDocument, options);
      }
export type SetCallCenterCanceledByCustomerMutationHookResult = ReturnType<typeof useSetCallCenterCanceledByCustomerMutation>;
export type SetCallCenterCanceledByCustomerMutationResult = Apollo.MutationResult<SetCallCenterCanceledByCustomerMutation>;
export type SetCallCenterCanceledByCustomerMutationOptions = Apollo.BaseMutationOptions<SetCallCenterCanceledByCustomerMutation, SetCallCenterCanceledByCustomerMutationVariables>;
export const SetCallCenterClaimDocument = gql`
    mutation SetCallCenterClaim($jobId: Int!, $isClaimed: Boolean!) {
  setCallCenterClaim(jobId: $jobId, isClaimed: $isClaimed) {
    id
    callCenterWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      individualChatParty {
        id
        name
      }
      isSalesperson
      isCommandCenterWorker
    }
    creationTime
    minutesFromCreation
    prospectNotes
    customerId
    customerFirstName
    customerLastName
    customerCity
    customerEmail
    customerPhone
    callBackTime
    isQualified
    appointment {
      id
      appointmentNumber
      scheduledDate
      timeReminderSent
      timeToSendReminder
      remindToday
      isCancelled
    }
    lastAction {
      id
      callCenterActionOptionId
      callCenterActionOptionLabel
      notes
      date
      createdByWorkerId
    }
    isInRecovery
    recoveryCallWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      isSalesperson
      isCommandCenterWorker
    }
    dateEnteredRecovery
  }
}
    `;
export type SetCallCenterClaimMutationFn = Apollo.MutationFunction<SetCallCenterClaimMutation, SetCallCenterClaimMutationVariables>;

/**
 * __useSetCallCenterClaimMutation__
 *
 * To run a mutation, you first call `useSetCallCenterClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCallCenterClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCallCenterClaimMutation, { data, loading, error }] = useSetCallCenterClaimMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      isClaimed: // value for 'isClaimed'
 *   },
 * });
 */
export function useSetCallCenterClaimMutation(baseOptions?: Apollo.MutationHookOptions<SetCallCenterClaimMutation, SetCallCenterClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCallCenterClaimMutation, SetCallCenterClaimMutationVariables>(SetCallCenterClaimDocument, options);
      }
export type SetCallCenterClaimMutationHookResult = ReturnType<typeof useSetCallCenterClaimMutation>;
export type SetCallCenterClaimMutationResult = Apollo.MutationResult<SetCallCenterClaimMutation>;
export type SetCallCenterClaimMutationOptions = Apollo.BaseMutationOptions<SetCallCenterClaimMutation, SetCallCenterClaimMutationVariables>;
export const SetCallCenterNotInterestedDocument = gql`
    mutation SetCallCenterNotInterested($jobId: Int!, $callbackDate: DateTime!, $notes: String!) {
  setCallCenterNotInterested(jobId: $jobId, callbackDate: $callbackDate, notes: $notes) {
    id
    callCenterWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      individualChatParty {
        id
        name
      }
      isSalesperson
      isCommandCenterWorker
    }
    creationTime
    minutesFromCreation
    prospectNotes
    customerId
    customerFirstName
    customerLastName
    customerCity
    customerEmail
    customerPhone
    callBackTime
    isQualified
    appointment {
      id
      appointmentNumber
      scheduledDate
      timeReminderSent
      timeToSendReminder
      remindToday
      isCancelled
    }
    lastAction {
      id
      callCenterActionOptionId
      callCenterActionOptionLabel
      notes
      date
      createdByWorkerId
    }
    isInRecovery
    recoveryCallWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      isSalesperson
      isCommandCenterWorker
    }
    dateEnteredRecovery
  }
}
    `;
export type SetCallCenterNotInterestedMutationFn = Apollo.MutationFunction<SetCallCenterNotInterestedMutation, SetCallCenterNotInterestedMutationVariables>;

/**
 * __useSetCallCenterNotInterestedMutation__
 *
 * To run a mutation, you first call `useSetCallCenterNotInterestedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCallCenterNotInterestedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCallCenterNotInterestedMutation, { data, loading, error }] = useSetCallCenterNotInterestedMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      callbackDate: // value for 'callbackDate'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useSetCallCenterNotInterestedMutation(baseOptions?: Apollo.MutationHookOptions<SetCallCenterNotInterestedMutation, SetCallCenterNotInterestedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCallCenterNotInterestedMutation, SetCallCenterNotInterestedMutationVariables>(SetCallCenterNotInterestedDocument, options);
      }
export type SetCallCenterNotInterestedMutationHookResult = ReturnType<typeof useSetCallCenterNotInterestedMutation>;
export type SetCallCenterNotInterestedMutationResult = Apollo.MutationResult<SetCallCenterNotInterestedMutation>;
export type SetCallCenterNotInterestedMutationOptions = Apollo.BaseMutationOptions<SetCallCenterNotInterestedMutation, SetCallCenterNotInterestedMutationVariables>;
export const SetCallCenterQualificationDocument = gql`
    mutation SetCallCenterQualification($jobId: Int!, $isQualified: Boolean!, $remindCustomerDate: DateTime!) {
  setCallCenterQualification(jobId: $jobId, isQualified: $isQualified, remindCustomerDate: $remindCustomerDate) {
    id
    callCenterWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      individualChatParty {
        id
        name
      }
      isSalesperson
      isCommandCenterWorker
    }
    creationTime
    minutesFromCreation
    prospectNotes
    customerId
    customerFirstName
    customerLastName
    customerCity
    customerEmail
    customerPhone
    callBackTime
    isQualified
    appointment {
      id
      appointmentNumber
      scheduledDate
      timeReminderSent
      timeToSendReminder
      remindToday
      isCancelled
    }
    lastAction {
      id
      callCenterActionOptionId
      callCenterActionOptionLabel
      notes
      date
      createdByWorkerId
    }
    isInRecovery
    recoveryCallWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      isSalesperson
      isCommandCenterWorker
    }
    dateEnteredRecovery
  }
}
    `;
export type SetCallCenterQualificationMutationFn = Apollo.MutationFunction<SetCallCenterQualificationMutation, SetCallCenterQualificationMutationVariables>;

/**
 * __useSetCallCenterQualificationMutation__
 *
 * To run a mutation, you first call `useSetCallCenterQualificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCallCenterQualificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCallCenterQualificationMutation, { data, loading, error }] = useSetCallCenterQualificationMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      isQualified: // value for 'isQualified'
 *      remindCustomerDate: // value for 'remindCustomerDate'
 *   },
 * });
 */
export function useSetCallCenterQualificationMutation(baseOptions?: Apollo.MutationHookOptions<SetCallCenterQualificationMutation, SetCallCenterQualificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCallCenterQualificationMutation, SetCallCenterQualificationMutationVariables>(SetCallCenterQualificationDocument, options);
      }
export type SetCallCenterQualificationMutationHookResult = ReturnType<typeof useSetCallCenterQualificationMutation>;
export type SetCallCenterQualificationMutationResult = Apollo.MutationResult<SetCallCenterQualificationMutation>;
export type SetCallCenterQualificationMutationOptions = Apollo.BaseMutationOptions<SetCallCenterQualificationMutation, SetCallCenterQualificationMutationVariables>;
export const SetCallCenterRecoveryClaimDocument = gql`
    mutation SetCallCenterRecoveryClaim($jobId: Int!, $claimedRecoveryWorkerId: Int) {
  setCallCenterRecoveryClaim(jobId: $jobId, claimedRecoveryWorkerId: $claimedRecoveryWorkerId) {
    id
    callCenterWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      individualChatParty {
        id
        name
      }
      isSalesperson
      isCommandCenterWorker
    }
    creationTime
    minutesFromCreation
    prospectNotes
    customerId
    customerFirstName
    customerLastName
    customerCity
    customerEmail
    customerPhone
    callBackTime
    isQualified
    appointment {
      id
      appointmentNumber
      scheduledDate
      timeReminderSent
      timeToSendReminder
      remindToday
      isCancelled
    }
    lastAction {
      id
      callCenterActionOptionId
      callCenterActionOptionLabel
      notes
      date
      createdByWorkerId
    }
    isInRecovery
    recoveryCallWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      isSalesperson
      isCommandCenterWorker
    }
    dateEnteredRecovery
  }
}
    `;
export type SetCallCenterRecoveryClaimMutationFn = Apollo.MutationFunction<SetCallCenterRecoveryClaimMutation, SetCallCenterRecoveryClaimMutationVariables>;

/**
 * __useSetCallCenterRecoveryClaimMutation__
 *
 * To run a mutation, you first call `useSetCallCenterRecoveryClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCallCenterRecoveryClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCallCenterRecoveryClaimMutation, { data, loading, error }] = useSetCallCenterRecoveryClaimMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      claimedRecoveryWorkerId: // value for 'claimedRecoveryWorkerId'
 *   },
 * });
 */
export function useSetCallCenterRecoveryClaimMutation(baseOptions?: Apollo.MutationHookOptions<SetCallCenterRecoveryClaimMutation, SetCallCenterRecoveryClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCallCenterRecoveryClaimMutation, SetCallCenterRecoveryClaimMutationVariables>(SetCallCenterRecoveryClaimDocument, options);
      }
export type SetCallCenterRecoveryClaimMutationHookResult = ReturnType<typeof useSetCallCenterRecoveryClaimMutation>;
export type SetCallCenterRecoveryClaimMutationResult = Apollo.MutationResult<SetCallCenterRecoveryClaimMutation>;
export type SetCallCenterRecoveryClaimMutationOptions = Apollo.BaseMutationOptions<SetCallCenterRecoveryClaimMutation, SetCallCenterRecoveryClaimMutationVariables>;
export const SetCallCenterReminderSentDocument = gql`
    mutation SetCallCenterReminderSent($sahAppointmentId: Int!) {
  setCallCenterReminderSent(sahAppointmentId: $sahAppointmentId) {
    id
    callCenterWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      individualChatParty {
        id
        name
      }
      isSalesperson
      isCommandCenterWorker
    }
    creationTime
    minutesFromCreation
    prospectNotes
    customerId
    customerFirstName
    customerLastName
    customerCity
    customerEmail
    customerPhone
    callBackTime
    isQualified
    appointment {
      id
      appointmentNumber
      scheduledDate
      timeReminderSent
      timeToSendReminder
      remindToday
      isCancelled
    }
    lastAction {
      id
      callCenterActionOptionId
      callCenterActionOptionLabel
      notes
      date
      createdByWorkerId
    }
    isInRecovery
    recoveryCallWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      isSalesperson
      isCommandCenterWorker
    }
    dateEnteredRecovery
  }
}
    `;
export type SetCallCenterReminderSentMutationFn = Apollo.MutationFunction<SetCallCenterReminderSentMutation, SetCallCenterReminderSentMutationVariables>;

/**
 * __useSetCallCenterReminderSentMutation__
 *
 * To run a mutation, you first call `useSetCallCenterReminderSentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCallCenterReminderSentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCallCenterReminderSentMutation, { data, loading, error }] = useSetCallCenterReminderSentMutation({
 *   variables: {
 *      sahAppointmentId: // value for 'sahAppointmentId'
 *   },
 * });
 */
export function useSetCallCenterReminderSentMutation(baseOptions?: Apollo.MutationHookOptions<SetCallCenterReminderSentMutation, SetCallCenterReminderSentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCallCenterReminderSentMutation, SetCallCenterReminderSentMutationVariables>(SetCallCenterReminderSentDocument, options);
      }
export type SetCallCenterReminderSentMutationHookResult = ReturnType<typeof useSetCallCenterReminderSentMutation>;
export type SetCallCenterReminderSentMutationResult = Apollo.MutationResult<SetCallCenterReminderSentMutation>;
export type SetCallCenterReminderSentMutationOptions = Apollo.BaseMutationOptions<SetCallCenterReminderSentMutation, SetCallCenterReminderSentMutationVariables>;
export const UpdateCallCenterActionDocument = gql`
    mutation UpdateCallCenterAction($callCenterActionId: Int!, $notes: String!) {
  updateCallCenterAction(callCenterActionId: $callCenterActionId, notes: $notes) {
    id
    callCenterActionOptionId
    callCenterActionOptionLabel
    notes
    date
    createdByWorkerId
  }
}
    `;
export type UpdateCallCenterActionMutationFn = Apollo.MutationFunction<UpdateCallCenterActionMutation, UpdateCallCenterActionMutationVariables>;

/**
 * __useUpdateCallCenterActionMutation__
 *
 * To run a mutation, you first call `useUpdateCallCenterActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallCenterActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallCenterActionMutation, { data, loading, error }] = useUpdateCallCenterActionMutation({
 *   variables: {
 *      callCenterActionId: // value for 'callCenterActionId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateCallCenterActionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCallCenterActionMutation, UpdateCallCenterActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCallCenterActionMutation, UpdateCallCenterActionMutationVariables>(UpdateCallCenterActionDocument, options);
      }
export type UpdateCallCenterActionMutationHookResult = ReturnType<typeof useUpdateCallCenterActionMutation>;
export type UpdateCallCenterActionMutationResult = Apollo.MutationResult<UpdateCallCenterActionMutation>;
export type UpdateCallCenterActionMutationOptions = Apollo.BaseMutationOptions<UpdateCallCenterActionMutation, UpdateCallCenterActionMutationVariables>;
export const UpdateCallCenterProspectNotesDocument = gql`
    mutation UpdateCallCenterProspectNotes($jobId: Int!, $prospectNotes: String!) {
  updateCallCenterProspectNotes(jobId: $jobId, prospectNotes: $prospectNotes) {
    id
    callCenterWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      individualChatParty {
        id
        name
      }
      isSalesperson
      isCommandCenterWorker
    }
    creationTime
    minutesFromCreation
    prospectNotes
    customerId
    customerFirstName
    customerLastName
    customerCity
    customerEmail
    customerPhone
    callBackTime
    isQualified
    appointment {
      id
      appointmentNumber
      scheduledDate
      timeReminderSent
      timeToSendReminder
      remindToday
      isCancelled
    }
    lastAction {
      id
      callCenterActionOptionId
      callCenterActionOptionLabel
      notes
      date
      createdByWorkerId
    }
    isInRecovery
    recoveryCallWorker {
      id
      firstName
      lastName
      email
      isCheckedIn
      isSalesperson
      isCommandCenterWorker
    }
    dateEnteredRecovery
  }
}
    `;
export type UpdateCallCenterProspectNotesMutationFn = Apollo.MutationFunction<UpdateCallCenterProspectNotesMutation, UpdateCallCenterProspectNotesMutationVariables>;

/**
 * __useUpdateCallCenterProspectNotesMutation__
 *
 * To run a mutation, you first call `useUpdateCallCenterProspectNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallCenterProspectNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallCenterProspectNotesMutation, { data, loading, error }] = useUpdateCallCenterProspectNotesMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      prospectNotes: // value for 'prospectNotes'
 *   },
 * });
 */
export function useUpdateCallCenterProspectNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCallCenterProspectNotesMutation, UpdateCallCenterProspectNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCallCenterProspectNotesMutation, UpdateCallCenterProspectNotesMutationVariables>(UpdateCallCenterProspectNotesDocument, options);
      }
export type UpdateCallCenterProspectNotesMutationHookResult = ReturnType<typeof useUpdateCallCenterProspectNotesMutation>;
export type UpdateCallCenterProspectNotesMutationResult = Apollo.MutationResult<UpdateCallCenterProspectNotesMutation>;
export type UpdateCallCenterProspectNotesMutationOptions = Apollo.BaseMutationOptions<UpdateCallCenterProspectNotesMutation, UpdateCallCenterProspectNotesMutationVariables>;
export const UpsertCallCenterScriptDocument = gql`
    mutation UpsertCallCenterScript($script: CallCenterScriptInput!) {
  upsertCallCenterScript(script: $script)
}
    `;
export type UpsertCallCenterScriptMutationFn = Apollo.MutationFunction<UpsertCallCenterScriptMutation, UpsertCallCenterScriptMutationVariables>;

/**
 * __useUpsertCallCenterScriptMutation__
 *
 * To run a mutation, you first call `useUpsertCallCenterScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCallCenterScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCallCenterScriptMutation, { data, loading, error }] = useUpsertCallCenterScriptMutation({
 *   variables: {
 *      script: // value for 'script'
 *   },
 * });
 */
export function useUpsertCallCenterScriptMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCallCenterScriptMutation, UpsertCallCenterScriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCallCenterScriptMutation, UpsertCallCenterScriptMutationVariables>(UpsertCallCenterScriptDocument, options);
      }
export type UpsertCallCenterScriptMutationHookResult = ReturnType<typeof useUpsertCallCenterScriptMutation>;
export type UpsertCallCenterScriptMutationResult = Apollo.MutationResult<UpsertCallCenterScriptMutation>;
export type UpsertCallCenterScriptMutationOptions = Apollo.BaseMutationOptions<UpsertCallCenterScriptMutation, UpsertCallCenterScriptMutationVariables>;
export const GetChatDocument = gql`
    query GetChat($chatId: Int!) {
  chatMessages(chatId: $chatId) {
    id
    messages {
      id
      chatId
      sendingPartyId
      sendingWorkerId
      senderFirstName
      senderLastName
      text
      timeSent
      received
    }
  }
  chatMembers(chatId: $chatId) {
    ...ChatMember
  }
}
    ${ChatMemberFragmentDoc}`;

/**
 * __useGetChatQuery__
 *
 * To run a query within a React component, call `useGetChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useGetChatQuery(baseOptions: Apollo.QueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options);
      }
export function useGetChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options);
        }
export type GetChatQueryHookResult = ReturnType<typeof useGetChatQuery>;
export type GetChatLazyQueryHookResult = ReturnType<typeof useGetChatLazyQuery>;
export type GetChatQueryResult = Apollo.QueryResult<GetChatQuery, GetChatQueryVariables>;
export const GetChatForJobDocument = gql`
    query GetChatForJob($jobId: Int!) {
  chatForJob(jobId: $jobId) {
    ...ChatForJob
  }
}
    ${ChatForJobFragmentDoc}`;

/**
 * __useGetChatForJobQuery__
 *
 * To run a query within a React component, call `useGetChatForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatForJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetChatForJobQuery(baseOptions: Apollo.QueryHookOptions<GetChatForJobQuery, GetChatForJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatForJobQuery, GetChatForJobQueryVariables>(GetChatForJobDocument, options);
      }
export function useGetChatForJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatForJobQuery, GetChatForJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatForJobQuery, GetChatForJobQueryVariables>(GetChatForJobDocument, options);
        }
export type GetChatForJobQueryHookResult = ReturnType<typeof useGetChatForJobQuery>;
export type GetChatForJobLazyQueryHookResult = ReturnType<typeof useGetChatForJobLazyQuery>;
export type GetChatForJobQueryResult = Apollo.QueryResult<GetChatForJobQuery, GetChatForJobQueryVariables>;
export const GetChatForJobFromChatDocument = gql`
    query GetChatForJobFromChat($chatId: Int!) {
  chatForJobFromChat(chatId: $chatId) {
    ...ChatForJob
  }
}
    ${ChatForJobFragmentDoc}`;

/**
 * __useGetChatForJobFromChatQuery__
 *
 * To run a query within a React component, call `useGetChatForJobFromChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatForJobFromChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatForJobFromChatQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useGetChatForJobFromChatQuery(baseOptions: Apollo.QueryHookOptions<GetChatForJobFromChatQuery, GetChatForJobFromChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatForJobFromChatQuery, GetChatForJobFromChatQueryVariables>(GetChatForJobFromChatDocument, options);
      }
export function useGetChatForJobFromChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatForJobFromChatQuery, GetChatForJobFromChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatForJobFromChatQuery, GetChatForJobFromChatQueryVariables>(GetChatForJobFromChatDocument, options);
        }
export type GetChatForJobFromChatQueryHookResult = ReturnType<typeof useGetChatForJobFromChatQuery>;
export type GetChatForJobFromChatLazyQueryHookResult = ReturnType<typeof useGetChatForJobFromChatLazyQuery>;
export type GetChatForJobFromChatQueryResult = Apollo.QueryResult<GetChatForJobFromChatQuery, GetChatForJobFromChatQueryVariables>;
export const GetCommandCenterChatPartyDocument = gql`
    query GetCommandCenterChatParty {
  commandCenterChatParty {
    id
    name
  }
}
    `;

/**
 * __useGetCommandCenterChatPartyQuery__
 *
 * To run a query within a React component, call `useGetCommandCenterChatPartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommandCenterChatPartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommandCenterChatPartyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommandCenterChatPartyQuery(baseOptions?: Apollo.QueryHookOptions<GetCommandCenterChatPartyQuery, GetCommandCenterChatPartyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommandCenterChatPartyQuery, GetCommandCenterChatPartyQueryVariables>(GetCommandCenterChatPartyDocument, options);
      }
export function useGetCommandCenterChatPartyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommandCenterChatPartyQuery, GetCommandCenterChatPartyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommandCenterChatPartyQuery, GetCommandCenterChatPartyQueryVariables>(GetCommandCenterChatPartyDocument, options);
        }
export type GetCommandCenterChatPartyQueryHookResult = ReturnType<typeof useGetCommandCenterChatPartyQuery>;
export type GetCommandCenterChatPartyLazyQueryHookResult = ReturnType<typeof useGetCommandCenterChatPartyLazyQuery>;
export type GetCommandCenterChatPartyQueryResult = Apollo.QueryResult<GetCommandCenterChatPartyQuery, GetCommandCenterChatPartyQueryVariables>;
export const GetDefaultDisplayedJobChatsDocument = gql`
    query GetDefaultDisplayedJobChats($date: DateTime!, $marketId: Int!, $commandCenterWorkerId: Int!) {
  defaultDisplayedJobChats(date: $date, marketId: $marketId, commandCenterWorkerId: $commandCenterWorkerId) {
    ...ChatForJob
  }
}
    ${ChatForJobFragmentDoc}`;

/**
 * __useGetDefaultDisplayedJobChatsQuery__
 *
 * To run a query within a React component, call `useGetDefaultDisplayedJobChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultDisplayedJobChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultDisplayedJobChatsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      marketId: // value for 'marketId'
 *      commandCenterWorkerId: // value for 'commandCenterWorkerId'
 *   },
 * });
 */
export function useGetDefaultDisplayedJobChatsQuery(baseOptions: Apollo.QueryHookOptions<GetDefaultDisplayedJobChatsQuery, GetDefaultDisplayedJobChatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultDisplayedJobChatsQuery, GetDefaultDisplayedJobChatsQueryVariables>(GetDefaultDisplayedJobChatsDocument, options);
      }
export function useGetDefaultDisplayedJobChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultDisplayedJobChatsQuery, GetDefaultDisplayedJobChatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultDisplayedJobChatsQuery, GetDefaultDisplayedJobChatsQueryVariables>(GetDefaultDisplayedJobChatsDocument, options);
        }
export type GetDefaultDisplayedJobChatsQueryHookResult = ReturnType<typeof useGetDefaultDisplayedJobChatsQuery>;
export type GetDefaultDisplayedJobChatsLazyQueryHookResult = ReturnType<typeof useGetDefaultDisplayedJobChatsLazyQuery>;
export type GetDefaultDisplayedJobChatsQueryResult = Apollo.QueryResult<GetDefaultDisplayedJobChatsQuery, GetDefaultDisplayedJobChatsQueryVariables>;
export const GetJobIdForChatDocument = gql`
    query GetJobIdForChat($chatId: Int!) {
  jobIdForChat(chatId: $chatId)
}
    `;

/**
 * __useGetJobIdForChatQuery__
 *
 * To run a query within a React component, call `useGetJobIdForChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobIdForChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobIdForChatQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useGetJobIdForChatQuery(baseOptions: Apollo.QueryHookOptions<GetJobIdForChatQuery, GetJobIdForChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobIdForChatQuery, GetJobIdForChatQueryVariables>(GetJobIdForChatDocument, options);
      }
export function useGetJobIdForChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobIdForChatQuery, GetJobIdForChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobIdForChatQuery, GetJobIdForChatQueryVariables>(GetJobIdForChatDocument, options);
        }
export type GetJobIdForChatQueryHookResult = ReturnType<typeof useGetJobIdForChatQuery>;
export type GetJobIdForChatLazyQueryHookResult = ReturnType<typeof useGetJobIdForChatLazyQuery>;
export type GetJobIdForChatQueryResult = Apollo.QueryResult<GetJobIdForChatQuery, GetJobIdForChatQueryVariables>;
export const ClearNewMessageStatusDocument = gql`
    mutation ClearNewMessageStatus($chatId: Int!, $partyId: Int!) {
  clearNewMessageStatus(chatId: $chatId, partyId: $partyId)
}
    `;
export type ClearNewMessageStatusMutationFn = Apollo.MutationFunction<ClearNewMessageStatusMutation, ClearNewMessageStatusMutationVariables>;

/**
 * __useClearNewMessageStatusMutation__
 *
 * To run a mutation, you first call `useClearNewMessageStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearNewMessageStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearNewMessageStatusMutation, { data, loading, error }] = useClearNewMessageStatusMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useClearNewMessageStatusMutation(baseOptions?: Apollo.MutationHookOptions<ClearNewMessageStatusMutation, ClearNewMessageStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearNewMessageStatusMutation, ClearNewMessageStatusMutationVariables>(ClearNewMessageStatusDocument, options);
      }
export type ClearNewMessageStatusMutationHookResult = ReturnType<typeof useClearNewMessageStatusMutation>;
export type ClearNewMessageStatusMutationResult = Apollo.MutationResult<ClearNewMessageStatusMutation>;
export type ClearNewMessageStatusMutationOptions = Apollo.BaseMutationOptions<ClearNewMessageStatusMutation, ClearNewMessageStatusMutationVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($message: ChatMessageInput!) {
  sendMessage(newMessage: $message)
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const ChatMemberNewMessageStatusUpdatedDocument = gql`
    subscription ChatMemberNewMessageStatusUpdated($chatId: Int!, $chatPartyId: Int!) {
  chatMemberNewMessageStatusUpdated(chatId: $chatId, chatPartyId: $chatPartyId)
}
    `;

/**
 * __useChatMemberNewMessageStatusUpdatedSubscription__
 *
 * To run a query within a React component, call `useChatMemberNewMessageStatusUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatMemberNewMessageStatusUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMemberNewMessageStatusUpdatedSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      chatPartyId: // value for 'chatPartyId'
 *   },
 * });
 */
export function useChatMemberNewMessageStatusUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChatMemberNewMessageStatusUpdatedSubscription, ChatMemberNewMessageStatusUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatMemberNewMessageStatusUpdatedSubscription, ChatMemberNewMessageStatusUpdatedSubscriptionVariables>(ChatMemberNewMessageStatusUpdatedDocument, options);
      }
export type ChatMemberNewMessageStatusUpdatedSubscriptionHookResult = ReturnType<typeof useChatMemberNewMessageStatusUpdatedSubscription>;
export type ChatMemberNewMessageStatusUpdatedSubscriptionResult = Apollo.SubscriptionResult<ChatMemberNewMessageStatusUpdatedSubscription>;
export const JobChatCommandCenterMemberChangedDocument = gql`
    subscription JobChatCommandCenterMemberChanged($jobId: Int!) {
  jobChatCommandCenterMemberChanged(jobId: $jobId) {
    ...ChatMember
  }
}
    ${ChatMemberFragmentDoc}`;

/**
 * __useJobChatCommandCenterMemberChangedSubscription__
 *
 * To run a query within a React component, call `useJobChatCommandCenterMemberChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useJobChatCommandCenterMemberChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobChatCommandCenterMemberChangedSubscription({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobChatCommandCenterMemberChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<JobChatCommandCenterMemberChangedSubscription, JobChatCommandCenterMemberChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<JobChatCommandCenterMemberChangedSubscription, JobChatCommandCenterMemberChangedSubscriptionVariables>(JobChatCommandCenterMemberChangedDocument, options);
      }
export type JobChatCommandCenterMemberChangedSubscriptionHookResult = ReturnType<typeof useJobChatCommandCenterMemberChangedSubscription>;
export type JobChatCommandCenterMemberChangedSubscriptionResult = Apollo.SubscriptionResult<JobChatCommandCenterMemberChangedSubscription>;
export const ChatMessageSentDocument = gql`
    subscription ChatMessageSent($chatId: Int!) {
  chatMessageSent(chatId: $chatId) {
    id
    chatId
    sendingPartyId
    sendingWorkerId
    senderFirstName
    senderLastName
    text
    timeSent
    received
  }
}
    `;

/**
 * __useChatMessageSentSubscription__
 *
 * To run a query within a React component, call `useChatMessageSentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatMessageSentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessageSentSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useChatMessageSentSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChatMessageSentSubscription, ChatMessageSentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatMessageSentSubscription, ChatMessageSentSubscriptionVariables>(ChatMessageSentDocument, options);
      }
export type ChatMessageSentSubscriptionHookResult = ReturnType<typeof useChatMessageSentSubscription>;
export type ChatMessageSentSubscriptionResult = Apollo.SubscriptionResult<ChatMessageSentSubscription>;
export const MessageSentToChatPartyDocument = gql`
    subscription MessageSentToChatParty($recipientChatPartyId: Int!) {
  messageSentToChatParty(recipientChatPartyId: $recipientChatPartyId)
}
    `;

/**
 * __useMessageSentToChatPartySubscription__
 *
 * To run a query within a React component, call `useMessageSentToChatPartySubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageSentToChatPartySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageSentToChatPartySubscription({
 *   variables: {
 *      recipientChatPartyId: // value for 'recipientChatPartyId'
 *   },
 * });
 */
export function useMessageSentToChatPartySubscription(baseOptions: Apollo.SubscriptionHookOptions<MessageSentToChatPartySubscription, MessageSentToChatPartySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MessageSentToChatPartySubscription, MessageSentToChatPartySubscriptionVariables>(MessageSentToChatPartyDocument, options);
      }
export type MessageSentToChatPartySubscriptionHookResult = ReturnType<typeof useMessageSentToChatPartySubscription>;
export type MessageSentToChatPartySubscriptionResult = Apollo.SubscriptionResult<MessageSentToChatPartySubscription>;
export const RemoveChatFromDrawerDocument = gql`
    subscription RemoveChatFromDrawer {
  removeChatFromDrawer {
    id
    retainForCommandCenterWorkerId
  }
}
    `;

/**
 * __useRemoveChatFromDrawerSubscription__
 *
 * To run a query within a React component, call `useRemoveChatFromDrawerSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRemoveChatFromDrawerSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoveChatFromDrawerSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRemoveChatFromDrawerSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RemoveChatFromDrawerSubscription, RemoveChatFromDrawerSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RemoveChatFromDrawerSubscription, RemoveChatFromDrawerSubscriptionVariables>(RemoveChatFromDrawerDocument, options);
      }
export type RemoveChatFromDrawerSubscriptionHookResult = ReturnType<typeof useRemoveChatFromDrawerSubscription>;
export type RemoveChatFromDrawerSubscriptionResult = Apollo.SubscriptionResult<RemoveChatFromDrawerSubscription>;
export const GetPricingCenterTabsDocument = gql`
    query GetPricingCenterTabs($jobConfigurationId: Int!) {
  pricingCenterTabs(jobConfigurationId: $jobConfigurationId) {
    id
    tabData {
      id
      optionNumber
    }
    contractConfigurationId
    quotedConfigurationAndQuoteIds {
      id
      quoteId
    }
  }
}
    `;

/**
 * __useGetPricingCenterTabsQuery__
 *
 * To run a query within a React component, call `useGetPricingCenterTabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricingCenterTabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricingCenterTabsQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetPricingCenterTabsQuery(baseOptions: Apollo.QueryHookOptions<GetPricingCenterTabsQuery, GetPricingCenterTabsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPricingCenterTabsQuery, GetPricingCenterTabsQueryVariables>(GetPricingCenterTabsDocument, options);
      }
export function useGetPricingCenterTabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPricingCenterTabsQuery, GetPricingCenterTabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPricingCenterTabsQuery, GetPricingCenterTabsQueryVariables>(GetPricingCenterTabsDocument, options);
        }
export type GetPricingCenterTabsQueryHookResult = ReturnType<typeof useGetPricingCenterTabsQuery>;
export type GetPricingCenterTabsLazyQueryHookResult = ReturnType<typeof useGetPricingCenterTabsLazyQuery>;
export type GetPricingCenterTabsQueryResult = Apollo.QueryResult<GetPricingCenterTabsQuery, GetPricingCenterTabsQueryVariables>;
export const GetPricingSummaryDocument = gql`
    query GetPricingSummary($jobConfigurationId: Int!) {
  totalPriceBeforeOverride(jobConfigurationId: $jobConfigurationId)
  currentPriceOverride(jobConfigurationId: $jobConfigurationId) {
    id
    jobConfigurationId
    amount
    commissionRate
    authorizationCode
    commandCenterWorkerId
    timeSent
    isCurrent
  }
  jobConfigurationCost(jobConfigurationId: $jobConfigurationId)
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    id
    price {
      mSRP
      savings
      total
    }
    priceRequest {
      id
      jobConfigurationId
      amount
      salespersonId
      commandCenterWorkerId
      timeRequested
      timeResponded
      granted
    }
  }
  currentCommissionRates {
    id
    lowerMargin
    upperMargin
    rate
    creationDate
    archiveDate
  }
}
    `;

/**
 * __useGetPricingSummaryQuery__
 *
 * To run a query within a React component, call `useGetPricingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricingSummaryQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetPricingSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetPricingSummaryQuery, GetPricingSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPricingSummaryQuery, GetPricingSummaryQueryVariables>(GetPricingSummaryDocument, options);
      }
export function useGetPricingSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPricingSummaryQuery, GetPricingSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPricingSummaryQuery, GetPricingSummaryQueryVariables>(GetPricingSummaryDocument, options);
        }
export type GetPricingSummaryQueryHookResult = ReturnType<typeof useGetPricingSummaryQuery>;
export type GetPricingSummaryLazyQueryHookResult = ReturnType<typeof useGetPricingSummaryLazyQuery>;
export type GetPricingSummaryQueryResult = Apollo.QueryResult<GetPricingSummaryQuery, GetPricingSummaryQueryVariables>;
export const GetPriceRequestDocument = gql`
    query GetPriceRequest($jobConfigurationId: Int!) {
  priceRequest(jobConfigurationId: $jobConfigurationId) {
    id
    jobConfigurationId
    amount
    salespersonId
    commandCenterWorkerId
    timeRequested
    timeResponded
    granted
  }
}
    `;

/**
 * __useGetPriceRequestQuery__
 *
 * To run a query within a React component, call `useGetPriceRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceRequestQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetPriceRequestQuery(baseOptions: Apollo.QueryHookOptions<GetPriceRequestQuery, GetPriceRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPriceRequestQuery, GetPriceRequestQueryVariables>(GetPriceRequestDocument, options);
      }
export function useGetPriceRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPriceRequestQuery, GetPriceRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPriceRequestQuery, GetPriceRequestQueryVariables>(GetPriceRequestDocument, options);
        }
export type GetPriceRequestQueryHookResult = ReturnType<typeof useGetPriceRequestQuery>;
export type GetPriceRequestLazyQueryHookResult = ReturnType<typeof useGetPriceRequestLazyQuery>;
export type GetPriceRequestQueryResult = Apollo.QueryResult<GetPriceRequestQuery, GetPriceRequestQueryVariables>;
export const GetPriceRequestsForJobDocument = gql`
    query GetPriceRequestsForJob($jobId: Int!) {
  priceRequestsForJob(jobId: $jobId) {
    id
    jobConfigurationId
    amount
    salespersonId
    commandCenterWorkerId
    timeRequested
    timeResponded
    granted
  }
}
    `;

/**
 * __useGetPriceRequestsForJobQuery__
 *
 * To run a query within a React component, call `useGetPriceRequestsForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceRequestsForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceRequestsForJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetPriceRequestsForJobQuery(baseOptions: Apollo.QueryHookOptions<GetPriceRequestsForJobQuery, GetPriceRequestsForJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPriceRequestsForJobQuery, GetPriceRequestsForJobQueryVariables>(GetPriceRequestsForJobDocument, options);
      }
export function useGetPriceRequestsForJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPriceRequestsForJobQuery, GetPriceRequestsForJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPriceRequestsForJobQuery, GetPriceRequestsForJobQueryVariables>(GetPriceRequestsForJobDocument, options);
        }
export type GetPriceRequestsForJobQueryHookResult = ReturnType<typeof useGetPriceRequestsForJobQuery>;
export type GetPriceRequestsForJobLazyQueryHookResult = ReturnType<typeof useGetPriceRequestsForJobLazyQuery>;
export type GetPriceRequestsForJobQueryResult = Apollo.QueryResult<GetPriceRequestsForJobQuery, GetPriceRequestsForJobQueryVariables>;
export const ClaimAllPriceRequestsForJobDocument = gql`
    mutation ClaimAllPriceRequestsForJob($jobId: Int!) {
  claimAllPriceRequestsForJob(jobId: $jobId)
}
    `;
export type ClaimAllPriceRequestsForJobMutationFn = Apollo.MutationFunction<ClaimAllPriceRequestsForJobMutation, ClaimAllPriceRequestsForJobMutationVariables>;

/**
 * __useClaimAllPriceRequestsForJobMutation__
 *
 * To run a mutation, you first call `useClaimAllPriceRequestsForJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimAllPriceRequestsForJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimAllPriceRequestsForJobMutation, { data, loading, error }] = useClaimAllPriceRequestsForJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useClaimAllPriceRequestsForJobMutation(baseOptions?: Apollo.MutationHookOptions<ClaimAllPriceRequestsForJobMutation, ClaimAllPriceRequestsForJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimAllPriceRequestsForJobMutation, ClaimAllPriceRequestsForJobMutationVariables>(ClaimAllPriceRequestsForJobDocument, options);
      }
export type ClaimAllPriceRequestsForJobMutationHookResult = ReturnType<typeof useClaimAllPriceRequestsForJobMutation>;
export type ClaimAllPriceRequestsForJobMutationResult = Apollo.MutationResult<ClaimAllPriceRequestsForJobMutation>;
export type ClaimAllPriceRequestsForJobMutationOptions = Apollo.BaseMutationOptions<ClaimAllPriceRequestsForJobMutation, ClaimAllPriceRequestsForJobMutationVariables>;
export const RecordPriceRequestDocument = gql`
    mutation RecordPriceRequest($chatId: Int!, $jobConfigurationId: Int!, $amount: Decimal!, $salespersonId: Int!) {
  recordPriceRequest(chatId: $chatId, jobConfigurationId: $jobConfigurationId, amount: $amount, salespersonId: $salespersonId) {
    id
    jobConfigurationId
    salespersonId
    commandCenterWorkerId
    amount
  }
}
    `;
export type RecordPriceRequestMutationFn = Apollo.MutationFunction<RecordPriceRequestMutation, RecordPriceRequestMutationVariables>;

/**
 * __useRecordPriceRequestMutation__
 *
 * To run a mutation, you first call `useRecordPriceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordPriceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordPriceRequestMutation, { data, loading, error }] = useRecordPriceRequestMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      amount: // value for 'amount'
 *      salespersonId: // value for 'salespersonId'
 *   },
 * });
 */
export function useRecordPriceRequestMutation(baseOptions?: Apollo.MutationHookOptions<RecordPriceRequestMutation, RecordPriceRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordPriceRequestMutation, RecordPriceRequestMutationVariables>(RecordPriceRequestDocument, options);
      }
export type RecordPriceRequestMutationHookResult = ReturnType<typeof useRecordPriceRequestMutation>;
export type RecordPriceRequestMutationResult = Apollo.MutationResult<RecordPriceRequestMutation>;
export type RecordPriceRequestMutationOptions = Apollo.BaseMutationOptions<RecordPriceRequestMutation, RecordPriceRequestMutationVariables>;
export const SendPriceOverrideDocument = gql`
    mutation SendPriceOverride($jobConfigurationId: Int!, $amount: Decimal!, $commissionRate: Decimal!) {
  sendPriceOverride(jobConfigurationId: $jobConfigurationId, amount: $amount, commissionRate: $commissionRate)
}
    `;
export type SendPriceOverrideMutationFn = Apollo.MutationFunction<SendPriceOverrideMutation, SendPriceOverrideMutationVariables>;

/**
 * __useSendPriceOverrideMutation__
 *
 * To run a mutation, you first call `useSendPriceOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPriceOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPriceOverrideMutation, { data, loading, error }] = useSendPriceOverrideMutation({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      amount: // value for 'amount'
 *      commissionRate: // value for 'commissionRate'
 *   },
 * });
 */
export function useSendPriceOverrideMutation(baseOptions?: Apollo.MutationHookOptions<SendPriceOverrideMutation, SendPriceOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPriceOverrideMutation, SendPriceOverrideMutationVariables>(SendPriceOverrideDocument, options);
      }
export type SendPriceOverrideMutationHookResult = ReturnType<typeof useSendPriceOverrideMutation>;
export type SendPriceOverrideMutationResult = Apollo.MutationResult<SendPriceOverrideMutation>;
export type SendPriceOverrideMutationOptions = Apollo.BaseMutationOptions<SendPriceOverrideMutation, SendPriceOverrideMutationVariables>;
export const SendPriceRequestDocument = gql`
    mutation SendPriceRequest($chatId: Int!, $jobConfigurationId: Int!, $amount: Decimal!) {
  sendPriceRequest(chatId: $chatId, jobConfigurationId: $jobConfigurationId, amount: $amount) {
    id
    jobConfigurationId
    amount
    salespersonId
    commandCenterWorkerId
    timeRequested
    timeResponded
    granted
  }
}
    `;
export type SendPriceRequestMutationFn = Apollo.MutationFunction<SendPriceRequestMutation, SendPriceRequestMutationVariables>;

/**
 * __useSendPriceRequestMutation__
 *
 * To run a mutation, you first call `useSendPriceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPriceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPriceRequestMutation, { data, loading, error }] = useSendPriceRequestMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useSendPriceRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendPriceRequestMutation, SendPriceRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPriceRequestMutation, SendPriceRequestMutationVariables>(SendPriceRequestDocument, options);
      }
export type SendPriceRequestMutationHookResult = ReturnType<typeof useSendPriceRequestMutation>;
export type SendPriceRequestMutationResult = Apollo.MutationResult<SendPriceRequestMutation>;
export type SendPriceRequestMutationOptions = Apollo.BaseMutationOptions<SendPriceRequestMutation, SendPriceRequestMutationVariables>;
export const OverrideDiscountSentDocument = gql`
    subscription OverrideDiscountSent($jobConfigurationId: Int!) {
  overrideDiscountSent(jobConfigurationId: $jobConfigurationId) {
    id
    updatedPrice {
      mSRP
      savings
      total
    }
    overrideDiscount {
      id
      amount
      discountId
      authorizationCode
      isScalar
      isValid
      jobConfigurationId
      name
    }
  }
}
    `;

/**
 * __useOverrideDiscountSentSubscription__
 *
 * To run a query within a React component, call `useOverrideDiscountSentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOverrideDiscountSentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverrideDiscountSentSubscription({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useOverrideDiscountSentSubscription(baseOptions: Apollo.SubscriptionHookOptions<OverrideDiscountSentSubscription, OverrideDiscountSentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OverrideDiscountSentSubscription, OverrideDiscountSentSubscriptionVariables>(OverrideDiscountSentDocument, options);
      }
export type OverrideDiscountSentSubscriptionHookResult = ReturnType<typeof useOverrideDiscountSentSubscription>;
export type OverrideDiscountSentSubscriptionResult = Apollo.SubscriptionResult<OverrideDiscountSentSubscription>;
export const PriceOverrideSentDocument = gql`
    subscription PriceOverrideSent($jobConfigurationId: Int!) {
  priceOverrideSent(jobConfigurationId: $jobConfigurationId) {
    id
    jobConfigurationId
    amount
    commissionRate
    authorizationCode
    commandCenterWorkerId
    timeSent
    isCurrent
  }
}
    `;

/**
 * __usePriceOverrideSentSubscription__
 *
 * To run a query within a React component, call `usePriceOverrideSentSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePriceOverrideSentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceOverrideSentSubscription({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function usePriceOverrideSentSubscription(baseOptions: Apollo.SubscriptionHookOptions<PriceOverrideSentSubscription, PriceOverrideSentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PriceOverrideSentSubscription, PriceOverrideSentSubscriptionVariables>(PriceOverrideSentDocument, options);
      }
export type PriceOverrideSentSubscriptionHookResult = ReturnType<typeof usePriceOverrideSentSubscription>;
export type PriceOverrideSentSubscriptionResult = Apollo.SubscriptionResult<PriceOverrideSentSubscription>;
export const PriceRequestUpdatedForConfigDocument = gql`
    subscription PriceRequestUpdatedForConfig($jobConfigurationId: Int!) {
  priceRequestUpdatedForConfig(jobConfigurationId: $jobConfigurationId) {
    id
    jobConfigurationId
    amount
    salespersonId
    commandCenterWorkerId
    timeRequested
    timeResponded
    granted
  }
}
    `;

/**
 * __usePriceRequestUpdatedForConfigSubscription__
 *
 * To run a query within a React component, call `usePriceRequestUpdatedForConfigSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePriceRequestUpdatedForConfigSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceRequestUpdatedForConfigSubscription({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function usePriceRequestUpdatedForConfigSubscription(baseOptions: Apollo.SubscriptionHookOptions<PriceRequestUpdatedForConfigSubscription, PriceRequestUpdatedForConfigSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PriceRequestUpdatedForConfigSubscription, PriceRequestUpdatedForConfigSubscriptionVariables>(PriceRequestUpdatedForConfigDocument, options);
      }
export type PriceRequestUpdatedForConfigSubscriptionHookResult = ReturnType<typeof usePriceRequestUpdatedForConfigSubscription>;
export type PriceRequestUpdatedForConfigSubscriptionResult = Apollo.SubscriptionResult<PriceRequestUpdatedForConfigSubscription>;
export const PriceRequestUpdatedForJobDocument = gql`
    subscription PriceRequestUpdatedForJob($jobId: Int!) {
  priceRequestUpdatedForJob(jobId: $jobId) {
    id
    jobConfigurationId
    amount
    salespersonId
    commandCenterWorkerId
    timeRequested
    timeResponded
    granted
  }
}
    `;

/**
 * __usePriceRequestUpdatedForJobSubscription__
 *
 * To run a query within a React component, call `usePriceRequestUpdatedForJobSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePriceRequestUpdatedForJobSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceRequestUpdatedForJobSubscription({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function usePriceRequestUpdatedForJobSubscription(baseOptions: Apollo.SubscriptionHookOptions<PriceRequestUpdatedForJobSubscription, PriceRequestUpdatedForJobSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PriceRequestUpdatedForJobSubscription, PriceRequestUpdatedForJobSubscriptionVariables>(PriceRequestUpdatedForJobDocument, options);
      }
export type PriceRequestUpdatedForJobSubscriptionHookResult = ReturnType<typeof usePriceRequestUpdatedForJobSubscription>;
export type PriceRequestUpdatedForJobSubscriptionResult = Apollo.SubscriptionResult<PriceRequestUpdatedForJobSubscription>;
export const JobConfigurationExistenceChangedDocument = gql`
    subscription JobConfigurationExistenceChanged($jobId: Int!) {
  jobConfigurationExistenceChanged(jobId: $jobId) {
    id
    optionNumber
    isDeletion
  }
}
    `;

/**
 * __useJobConfigurationExistenceChangedSubscription__
 *
 * To run a query within a React component, call `useJobConfigurationExistenceChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useJobConfigurationExistenceChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobConfigurationExistenceChangedSubscription({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobConfigurationExistenceChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<JobConfigurationExistenceChangedSubscription, JobConfigurationExistenceChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<JobConfigurationExistenceChangedSubscription, JobConfigurationExistenceChangedSubscriptionVariables>(JobConfigurationExistenceChangedDocument, options);
      }
export type JobConfigurationExistenceChangedSubscriptionHookResult = ReturnType<typeof useJobConfigurationExistenceChangedSubscription>;
export type JobConfigurationExistenceChangedSubscriptionResult = Apollo.SubscriptionResult<JobConfigurationExistenceChangedSubscription>;
export const JobQuotedDocument = gql`
    subscription JobQuoted($jobId: Int!) {
  jobQuoted(jobId: $jobId) {
    id
    quoteId
  }
}
    `;

/**
 * __useJobQuotedSubscription__
 *
 * To run a query within a React component, call `useJobQuotedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useJobQuotedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuotedSubscription({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobQuotedSubscription(baseOptions: Apollo.SubscriptionHookOptions<JobQuotedSubscription, JobQuotedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<JobQuotedSubscription, JobQuotedSubscriptionVariables>(JobQuotedDocument, options);
      }
export type JobQuotedSubscriptionHookResult = ReturnType<typeof useJobQuotedSubscription>;
export type JobQuotedSubscriptionResult = Apollo.SubscriptionResult<JobQuotedSubscription>;
export const JobSoldDocument = gql`
    subscription JobSold($jobId: Int!) {
  jobSold(jobId: $jobId)
}
    `;

/**
 * __useJobSoldSubscription__
 *
 * To run a query within a React component, call `useJobSoldSubscription` and pass it any options that fit your needs.
 * When your component renders, `useJobSoldSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSoldSubscription({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobSoldSubscription(baseOptions: Apollo.SubscriptionHookOptions<JobSoldSubscription, JobSoldSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<JobSoldSubscription, JobSoldSubscriptionVariables>(JobSoldDocument, options);
      }
export type JobSoldSubscriptionHookResult = ReturnType<typeof useJobSoldSubscription>;
export type JobSoldSubscriptionResult = Apollo.SubscriptionResult<JobSoldSubscription>;
export const AddFinancingOverrideDocument = gql`
    mutation AddFinancingOverride($idOfOptionToReplace: Int, $financingOverride: FinancingOverrideInput!) {
  addFinancingOverride(idOfOptionToReplace: $idOfOptionToReplace, newOverride: $financingOverride)
}
    `;
export type AddFinancingOverrideMutationFn = Apollo.MutationFunction<AddFinancingOverrideMutation, AddFinancingOverrideMutationVariables>;

/**
 * __useAddFinancingOverrideMutation__
 *
 * To run a mutation, you first call `useAddFinancingOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFinancingOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFinancingOverrideMutation, { data, loading, error }] = useAddFinancingOverrideMutation({
 *   variables: {
 *      idOfOptionToReplace: // value for 'idOfOptionToReplace'
 *      financingOverride: // value for 'financingOverride'
 *   },
 * });
 */
export function useAddFinancingOverrideMutation(baseOptions?: Apollo.MutationHookOptions<AddFinancingOverrideMutation, AddFinancingOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFinancingOverrideMutation, AddFinancingOverrideMutationVariables>(AddFinancingOverrideDocument, options);
      }
export type AddFinancingOverrideMutationHookResult = ReturnType<typeof useAddFinancingOverrideMutation>;
export type AddFinancingOverrideMutationResult = Apollo.MutationResult<AddFinancingOverrideMutation>;
export type AddFinancingOverrideMutationOptions = Apollo.BaseMutationOptions<AddFinancingOverrideMutation, AddFinancingOverrideMutationVariables>;
export const GetCompletionCertificateDocument = gql`
    query GetCompletionCertificate($installationAppointmentId: Int!) {
  completionCertificate(installationAppointmentId: $installationAppointmentId) {
    id
    question1Rating
    overallRating
    comments
    signingDate
  }
}
    `;

/**
 * __useGetCompletionCertificateQuery__
 *
 * To run a query within a React component, call `useGetCompletionCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletionCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletionCertificateQuery({
 *   variables: {
 *      installationAppointmentId: // value for 'installationAppointmentId'
 *   },
 * });
 */
export function useGetCompletionCertificateQuery(baseOptions: Apollo.QueryHookOptions<GetCompletionCertificateQuery, GetCompletionCertificateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompletionCertificateQuery, GetCompletionCertificateQueryVariables>(GetCompletionCertificateDocument, options);
      }
export function useGetCompletionCertificateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompletionCertificateQuery, GetCompletionCertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompletionCertificateQuery, GetCompletionCertificateQueryVariables>(GetCompletionCertificateDocument, options);
        }
export type GetCompletionCertificateQueryHookResult = ReturnType<typeof useGetCompletionCertificateQuery>;
export type GetCompletionCertificateLazyQueryHookResult = ReturnType<typeof useGetCompletionCertificateLazyQuery>;
export type GetCompletionCertificateQueryResult = Apollo.QueryResult<GetCompletionCertificateQuery, GetCompletionCertificateQueryVariables>;
export const GetCompletionCertificatePdfDocument = gql`
    query GetCompletionCertificatePDF($installationAppointmentId: Int!) {
  completionCertificatePDF(installationAppointmentId: $installationAppointmentId)
}
    `;

/**
 * __useGetCompletionCertificatePdfQuery__
 *
 * To run a query within a React component, call `useGetCompletionCertificatePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletionCertificatePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletionCertificatePdfQuery({
 *   variables: {
 *      installationAppointmentId: // value for 'installationAppointmentId'
 *   },
 * });
 */
export function useGetCompletionCertificatePdfQuery(baseOptions: Apollo.QueryHookOptions<GetCompletionCertificatePdfQuery, GetCompletionCertificatePdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompletionCertificatePdfQuery, GetCompletionCertificatePdfQueryVariables>(GetCompletionCertificatePdfDocument, options);
      }
export function useGetCompletionCertificatePdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompletionCertificatePdfQuery, GetCompletionCertificatePdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompletionCertificatePdfQuery, GetCompletionCertificatePdfQueryVariables>(GetCompletionCertificatePdfDocument, options);
        }
export type GetCompletionCertificatePdfQueryHookResult = ReturnType<typeof useGetCompletionCertificatePdfQuery>;
export type GetCompletionCertificatePdfLazyQueryHookResult = ReturnType<typeof useGetCompletionCertificatePdfLazyQuery>;
export type GetCompletionCertificatePdfQueryResult = Apollo.QueryResult<GetCompletionCertificatePdfQuery, GetCompletionCertificatePdfQueryVariables>;
export const GetDaysContractorIsBusyDocument = gql`
    query GetDaysContractorIsBusy {
  daysContractorIsBusy
}
    `;

/**
 * __useGetDaysContractorIsBusyQuery__
 *
 * To run a query within a React component, call `useGetDaysContractorIsBusyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDaysContractorIsBusyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDaysContractorIsBusyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDaysContractorIsBusyQuery(baseOptions?: Apollo.QueryHookOptions<GetDaysContractorIsBusyQuery, GetDaysContractorIsBusyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDaysContractorIsBusyQuery, GetDaysContractorIsBusyQueryVariables>(GetDaysContractorIsBusyDocument, options);
      }
export function useGetDaysContractorIsBusyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDaysContractorIsBusyQuery, GetDaysContractorIsBusyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDaysContractorIsBusyQuery, GetDaysContractorIsBusyQueryVariables>(GetDaysContractorIsBusyDocument, options);
        }
export type GetDaysContractorIsBusyQueryHookResult = ReturnType<typeof useGetDaysContractorIsBusyQuery>;
export type GetDaysContractorIsBusyLazyQueryHookResult = ReturnType<typeof useGetDaysContractorIsBusyLazyQuery>;
export type GetDaysContractorIsBusyQueryResult = Apollo.QueryResult<GetDaysContractorIsBusyQuery, GetDaysContractorIsBusyQueryVariables>;
export const GetInstallationAppointmentForContractorDocument = gql`
    query GetInstallationAppointmentForContractor($installationAppointmentId: Int!) {
  installationAppointmentForContractor(installationAppointmentId: $installationAppointmentId) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;

/**
 * __useGetInstallationAppointmentForContractorQuery__
 *
 * To run a query within a React component, call `useGetInstallationAppointmentForContractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallationAppointmentForContractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallationAppointmentForContractorQuery({
 *   variables: {
 *      installationAppointmentId: // value for 'installationAppointmentId'
 *   },
 * });
 */
export function useGetInstallationAppointmentForContractorQuery(baseOptions: Apollo.QueryHookOptions<GetInstallationAppointmentForContractorQuery, GetInstallationAppointmentForContractorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstallationAppointmentForContractorQuery, GetInstallationAppointmentForContractorQueryVariables>(GetInstallationAppointmentForContractorDocument, options);
      }
export function useGetInstallationAppointmentForContractorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstallationAppointmentForContractorQuery, GetInstallationAppointmentForContractorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstallationAppointmentForContractorQuery, GetInstallationAppointmentForContractorQueryVariables>(GetInstallationAppointmentForContractorDocument, options);
        }
export type GetInstallationAppointmentForContractorQueryHookResult = ReturnType<typeof useGetInstallationAppointmentForContractorQuery>;
export type GetInstallationAppointmentForContractorLazyQueryHookResult = ReturnType<typeof useGetInstallationAppointmentForContractorLazyQuery>;
export type GetInstallationAppointmentForContractorQueryResult = Apollo.QueryResult<GetInstallationAppointmentForContractorQuery, GetInstallationAppointmentForContractorQueryVariables>;
export const GetInstallationAppointmentForContractorAfterDateDocument = gql`
    query GetInstallationAppointmentForContractorAfterDate($contractorId: Int!, $afterDate: DateTime!) {
  installationAppointmentForContractorAfterDate(contractorId: $contractorId, afterDate: $afterDate) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;

/**
 * __useGetInstallationAppointmentForContractorAfterDateQuery__
 *
 * To run a query within a React component, call `useGetInstallationAppointmentForContractorAfterDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallationAppointmentForContractorAfterDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallationAppointmentForContractorAfterDateQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *      afterDate: // value for 'afterDate'
 *   },
 * });
 */
export function useGetInstallationAppointmentForContractorAfterDateQuery(baseOptions: Apollo.QueryHookOptions<GetInstallationAppointmentForContractorAfterDateQuery, GetInstallationAppointmentForContractorAfterDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstallationAppointmentForContractorAfterDateQuery, GetInstallationAppointmentForContractorAfterDateQueryVariables>(GetInstallationAppointmentForContractorAfterDateDocument, options);
      }
export function useGetInstallationAppointmentForContractorAfterDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstallationAppointmentForContractorAfterDateQuery, GetInstallationAppointmentForContractorAfterDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstallationAppointmentForContractorAfterDateQuery, GetInstallationAppointmentForContractorAfterDateQueryVariables>(GetInstallationAppointmentForContractorAfterDateDocument, options);
        }
export type GetInstallationAppointmentForContractorAfterDateQueryHookResult = ReturnType<typeof useGetInstallationAppointmentForContractorAfterDateQuery>;
export type GetInstallationAppointmentForContractorAfterDateLazyQueryHookResult = ReturnType<typeof useGetInstallationAppointmentForContractorAfterDateLazyQuery>;
export type GetInstallationAppointmentForContractorAfterDateQueryResult = Apollo.QueryResult<GetInstallationAppointmentForContractorAfterDateQuery, GetInstallationAppointmentForContractorAfterDateQueryVariables>;
export const GetPartialServiceOrdersForContractorDocument = gql`
    query GetPartialServiceOrdersForContractor($contractorId: Int!) {
  partialServiceOrdersForContractor(contractorId: $contractorId) {
    id
    jobContractId
    serviceOrderTypeId
    serviceDescription
    calledInDate
    scheduledDate
    scheduleAsap
    completionDate
    vendorId
    contractorId
    comments
    additionalActionRequired
    additionalActionDescription
    chargeAmount
  }
}
    `;

/**
 * __useGetPartialServiceOrdersForContractorQuery__
 *
 * To run a query within a React component, call `useGetPartialServiceOrdersForContractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartialServiceOrdersForContractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartialServiceOrdersForContractorQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *   },
 * });
 */
export function useGetPartialServiceOrdersForContractorQuery(baseOptions: Apollo.QueryHookOptions<GetPartialServiceOrdersForContractorQuery, GetPartialServiceOrdersForContractorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartialServiceOrdersForContractorQuery, GetPartialServiceOrdersForContractorQueryVariables>(GetPartialServiceOrdersForContractorDocument, options);
      }
export function useGetPartialServiceOrdersForContractorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartialServiceOrdersForContractorQuery, GetPartialServiceOrdersForContractorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartialServiceOrdersForContractorQuery, GetPartialServiceOrdersForContractorQueryVariables>(GetPartialServiceOrdersForContractorDocument, options);
        }
export type GetPartialServiceOrdersForContractorQueryHookResult = ReturnType<typeof useGetPartialServiceOrdersForContractorQuery>;
export type GetPartialServiceOrdersForContractorLazyQueryHookResult = ReturnType<typeof useGetPartialServiceOrdersForContractorLazyQuery>;
export type GetPartialServiceOrdersForContractorQueryResult = Apollo.QueryResult<GetPartialServiceOrdersForContractorQuery, GetPartialServiceOrdersForContractorQueryVariables>;
export const GetTempCompletionCertificateDataDocument = gql`
    query GetTempCompletionCertificateData($installationAppointmentId: Int!) {
  tempCompletionCertificateData(installationAppointmentId: $installationAppointmentId)
}
    `;

/**
 * __useGetTempCompletionCertificateDataQuery__
 *
 * To run a query within a React component, call `useGetTempCompletionCertificateDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTempCompletionCertificateDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTempCompletionCertificateDataQuery({
 *   variables: {
 *      installationAppointmentId: // value for 'installationAppointmentId'
 *   },
 * });
 */
export function useGetTempCompletionCertificateDataQuery(baseOptions: Apollo.QueryHookOptions<GetTempCompletionCertificateDataQuery, GetTempCompletionCertificateDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTempCompletionCertificateDataQuery, GetTempCompletionCertificateDataQueryVariables>(GetTempCompletionCertificateDataDocument, options);
      }
export function useGetTempCompletionCertificateDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTempCompletionCertificateDataQuery, GetTempCompletionCertificateDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTempCompletionCertificateDataQuery, GetTempCompletionCertificateDataQueryVariables>(GetTempCompletionCertificateDataDocument, options);
        }
export type GetTempCompletionCertificateDataQueryHookResult = ReturnType<typeof useGetTempCompletionCertificateDataQuery>;
export type GetTempCompletionCertificateDataLazyQueryHookResult = ReturnType<typeof useGetTempCompletionCertificateDataLazyQuery>;
export type GetTempCompletionCertificateDataQueryResult = Apollo.QueryResult<GetTempCompletionCertificateDataQuery, GetTempCompletionCertificateDataQueryVariables>;
export const MarkInstallationAppointmentCompleteDocument = gql`
    mutation MarkInstallationAppointmentComplete($installationAppointmentId: Int!) {
  markInstallationAppointmentComplete(installationAppointmentId: $installationAppointmentId)
}
    `;
export type MarkInstallationAppointmentCompleteMutationFn = Apollo.MutationFunction<MarkInstallationAppointmentCompleteMutation, MarkInstallationAppointmentCompleteMutationVariables>;

/**
 * __useMarkInstallationAppointmentCompleteMutation__
 *
 * To run a mutation, you first call `useMarkInstallationAppointmentCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInstallationAppointmentCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInstallationAppointmentCompleteMutation, { data, loading, error }] = useMarkInstallationAppointmentCompleteMutation({
 *   variables: {
 *      installationAppointmentId: // value for 'installationAppointmentId'
 *   },
 * });
 */
export function useMarkInstallationAppointmentCompleteMutation(baseOptions?: Apollo.MutationHookOptions<MarkInstallationAppointmentCompleteMutation, MarkInstallationAppointmentCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkInstallationAppointmentCompleteMutation, MarkInstallationAppointmentCompleteMutationVariables>(MarkInstallationAppointmentCompleteDocument, options);
      }
export type MarkInstallationAppointmentCompleteMutationHookResult = ReturnType<typeof useMarkInstallationAppointmentCompleteMutation>;
export type MarkInstallationAppointmentCompleteMutationResult = Apollo.MutationResult<MarkInstallationAppointmentCompleteMutation>;
export type MarkInstallationAppointmentCompleteMutationOptions = Apollo.BaseMutationOptions<MarkInstallationAppointmentCompleteMutation, MarkInstallationAppointmentCompleteMutationVariables>;
export const CallCustomerForInstallationAppointmentDocument = gql`
    mutation CallCustomerForInstallationAppointment($appointmentId: Int!) {
  callCustomerForInstallationAppointment(appointmentId: $appointmentId) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;
export type CallCustomerForInstallationAppointmentMutationFn = Apollo.MutationFunction<CallCustomerForInstallationAppointmentMutation, CallCustomerForInstallationAppointmentMutationVariables>;

/**
 * __useCallCustomerForInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `useCallCustomerForInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallCustomerForInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callCustomerForInstallationAppointmentMutation, { data, loading, error }] = useCallCustomerForInstallationAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useCallCustomerForInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CallCustomerForInstallationAppointmentMutation, CallCustomerForInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallCustomerForInstallationAppointmentMutation, CallCustomerForInstallationAppointmentMutationVariables>(CallCustomerForInstallationAppointmentDocument, options);
      }
export type CallCustomerForInstallationAppointmentMutationHookResult = ReturnType<typeof useCallCustomerForInstallationAppointmentMutation>;
export type CallCustomerForInstallationAppointmentMutationResult = Apollo.MutationResult<CallCustomerForInstallationAppointmentMutation>;
export type CallCustomerForInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<CallCustomerForInstallationAppointmentMutation, CallCustomerForInstallationAppointmentMutationVariables>;
export const CheckInToInstallationAppointmentDocument = gql`
    mutation CheckInToInstallationAppointment($appointmentId: Int!) {
  checkInToInstallationAppointment(appointmentId: $appointmentId) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;
export type CheckInToInstallationAppointmentMutationFn = Apollo.MutationFunction<CheckInToInstallationAppointmentMutation, CheckInToInstallationAppointmentMutationVariables>;

/**
 * __useCheckInToInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `useCheckInToInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInToInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInToInstallationAppointmentMutation, { data, loading, error }] = useCheckInToInstallationAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useCheckInToInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CheckInToInstallationAppointmentMutation, CheckInToInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckInToInstallationAppointmentMutation, CheckInToInstallationAppointmentMutationVariables>(CheckInToInstallationAppointmentDocument, options);
      }
export type CheckInToInstallationAppointmentMutationHookResult = ReturnType<typeof useCheckInToInstallationAppointmentMutation>;
export type CheckInToInstallationAppointmentMutationResult = Apollo.MutationResult<CheckInToInstallationAppointmentMutation>;
export type CheckInToInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<CheckInToInstallationAppointmentMutation, CheckInToInstallationAppointmentMutationVariables>;
export const CheckOutFromInstallationAppointmentDocument = gql`
    mutation CheckOutFromInstallationAppointment($appointmentId: Int!) {
  checkOutFromInstallationAppointment(appointmentId: $appointmentId) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;
export type CheckOutFromInstallationAppointmentMutationFn = Apollo.MutationFunction<CheckOutFromInstallationAppointmentMutation, CheckOutFromInstallationAppointmentMutationVariables>;

/**
 * __useCheckOutFromInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `useCheckOutFromInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOutFromInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOutFromInstallationAppointmentMutation, { data, loading, error }] = useCheckOutFromInstallationAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useCheckOutFromInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CheckOutFromInstallationAppointmentMutation, CheckOutFromInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckOutFromInstallationAppointmentMutation, CheckOutFromInstallationAppointmentMutationVariables>(CheckOutFromInstallationAppointmentDocument, options);
      }
export type CheckOutFromInstallationAppointmentMutationHookResult = ReturnType<typeof useCheckOutFromInstallationAppointmentMutation>;
export type CheckOutFromInstallationAppointmentMutationResult = Apollo.MutationResult<CheckOutFromInstallationAppointmentMutation>;
export type CheckOutFromInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<CheckOutFromInstallationAppointmentMutation, CheckOutFromInstallationAppointmentMutationVariables>;
export const CollectCodForInstallationAppointmentDocument = gql`
    mutation CollectCODForInstallationAppointment($appointmentId: Int!) {
  collectCODForInstallationAppointment(appointmentId: $appointmentId) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;
export type CollectCodForInstallationAppointmentMutationFn = Apollo.MutationFunction<CollectCodForInstallationAppointmentMutation, CollectCodForInstallationAppointmentMutationVariables>;

/**
 * __useCollectCodForInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `useCollectCodForInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectCodForInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectCodForInstallationAppointmentMutation, { data, loading, error }] = useCollectCodForInstallationAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useCollectCodForInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CollectCodForInstallationAppointmentMutation, CollectCodForInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CollectCodForInstallationAppointmentMutation, CollectCodForInstallationAppointmentMutationVariables>(CollectCodForInstallationAppointmentDocument, options);
      }
export type CollectCodForInstallationAppointmentMutationHookResult = ReturnType<typeof useCollectCodForInstallationAppointmentMutation>;
export type CollectCodForInstallationAppointmentMutationResult = Apollo.MutationResult<CollectCodForInstallationAppointmentMutation>;
export type CollectCodForInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<CollectCodForInstallationAppointmentMutation, CollectCodForInstallationAppointmentMutationVariables>;
export const MappedToCustomerForInstallationAppointmentDocument = gql`
    mutation MappedToCustomerForInstallationAppointment($appointmentId: Int!) {
  mappedToCustomerForInstallationAppointment(appointmentId: $appointmentId) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;
export type MappedToCustomerForInstallationAppointmentMutationFn = Apollo.MutationFunction<MappedToCustomerForInstallationAppointmentMutation, MappedToCustomerForInstallationAppointmentMutationVariables>;

/**
 * __useMappedToCustomerForInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `useMappedToCustomerForInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMappedToCustomerForInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mappedToCustomerForInstallationAppointmentMutation, { data, loading, error }] = useMappedToCustomerForInstallationAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useMappedToCustomerForInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<MappedToCustomerForInstallationAppointmentMutation, MappedToCustomerForInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MappedToCustomerForInstallationAppointmentMutation, MappedToCustomerForInstallationAppointmentMutationVariables>(MappedToCustomerForInstallationAppointmentDocument, options);
      }
export type MappedToCustomerForInstallationAppointmentMutationHookResult = ReturnType<typeof useMappedToCustomerForInstallationAppointmentMutation>;
export type MappedToCustomerForInstallationAppointmentMutationResult = Apollo.MutationResult<MappedToCustomerForInstallationAppointmentMutation>;
export type MappedToCustomerForInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<MappedToCustomerForInstallationAppointmentMutation, MappedToCustomerForInstallationAppointmentMutationVariables>;
export const PickedUpMaterialsForInstallationAppointmentDocument = gql`
    mutation PickedUpMaterialsForInstallationAppointment($appointmentId: Int!) {
  pickedUpMaterialsForInstallationAppointment(appointmentId: $appointmentId) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;
export type PickedUpMaterialsForInstallationAppointmentMutationFn = Apollo.MutationFunction<PickedUpMaterialsForInstallationAppointmentMutation, PickedUpMaterialsForInstallationAppointmentMutationVariables>;

/**
 * __usePickedUpMaterialsForInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `usePickedUpMaterialsForInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePickedUpMaterialsForInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pickedUpMaterialsForInstallationAppointmentMutation, { data, loading, error }] = usePickedUpMaterialsForInstallationAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function usePickedUpMaterialsForInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<PickedUpMaterialsForInstallationAppointmentMutation, PickedUpMaterialsForInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PickedUpMaterialsForInstallationAppointmentMutation, PickedUpMaterialsForInstallationAppointmentMutationVariables>(PickedUpMaterialsForInstallationAppointmentDocument, options);
      }
export type PickedUpMaterialsForInstallationAppointmentMutationHookResult = ReturnType<typeof usePickedUpMaterialsForInstallationAppointmentMutation>;
export type PickedUpMaterialsForInstallationAppointmentMutationResult = Apollo.MutationResult<PickedUpMaterialsForInstallationAppointmentMutation>;
export type PickedUpMaterialsForInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<PickedUpMaterialsForInstallationAppointmentMutation, PickedUpMaterialsForInstallationAppointmentMutationVariables>;
export const SubmitCompletionCertificateDocument = gql`
    mutation SubmitCompletionCertificate($installationAppointmentId: Int!, $question1Rating: Int!, $overallExperience: Int!, $comments: String, $signatureBase64: String!) {
  submitCompletionCertificate(installationAppointmentId: $installationAppointmentId, question1Rating: $question1Rating, overallExperience: $overallExperience, comments: $comments, signatureBase64: $signatureBase64)
}
    `;
export type SubmitCompletionCertificateMutationFn = Apollo.MutationFunction<SubmitCompletionCertificateMutation, SubmitCompletionCertificateMutationVariables>;

/**
 * __useSubmitCompletionCertificateMutation__
 *
 * To run a mutation, you first call `useSubmitCompletionCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCompletionCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCompletionCertificateMutation, { data, loading, error }] = useSubmitCompletionCertificateMutation({
 *   variables: {
 *      installationAppointmentId: // value for 'installationAppointmentId'
 *      question1Rating: // value for 'question1Rating'
 *      overallExperience: // value for 'overallExperience'
 *      comments: // value for 'comments'
 *      signatureBase64: // value for 'signatureBase64'
 *   },
 * });
 */
export function useSubmitCompletionCertificateMutation(baseOptions?: Apollo.MutationHookOptions<SubmitCompletionCertificateMutation, SubmitCompletionCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitCompletionCertificateMutation, SubmitCompletionCertificateMutationVariables>(SubmitCompletionCertificateDocument, options);
      }
export type SubmitCompletionCertificateMutationHookResult = ReturnType<typeof useSubmitCompletionCertificateMutation>;
export type SubmitCompletionCertificateMutationResult = Apollo.MutationResult<SubmitCompletionCertificateMutation>;
export type SubmitCompletionCertificateMutationOptions = Apollo.BaseMutationOptions<SubmitCompletionCertificateMutation, SubmitCompletionCertificateMutationVariables>;
export const UpdateEstimatedArrivalTimeForInstallationAppointmentDocument = gql`
    mutation UpdateEstimatedArrivalTimeForInstallationAppointment($appointmentId: Int!, $eta: TimeSpan, $didCustomerAnswer: Boolean) {
  updateEstimatedArrivalTimeForInstallationAppointment(appointmentId: $appointmentId, eta: $eta, didCustomerAnswer: $didCustomerAnswer) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;
export type UpdateEstimatedArrivalTimeForInstallationAppointmentMutationFn = Apollo.MutationFunction<UpdateEstimatedArrivalTimeForInstallationAppointmentMutation, UpdateEstimatedArrivalTimeForInstallationAppointmentMutationVariables>;

/**
 * __useUpdateEstimatedArrivalTimeForInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateEstimatedArrivalTimeForInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstimatedArrivalTimeForInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstimatedArrivalTimeForInstallationAppointmentMutation, { data, loading, error }] = useUpdateEstimatedArrivalTimeForInstallationAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      eta: // value for 'eta'
 *      didCustomerAnswer: // value for 'didCustomerAnswer'
 *   },
 * });
 */
export function useUpdateEstimatedArrivalTimeForInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEstimatedArrivalTimeForInstallationAppointmentMutation, UpdateEstimatedArrivalTimeForInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEstimatedArrivalTimeForInstallationAppointmentMutation, UpdateEstimatedArrivalTimeForInstallationAppointmentMutationVariables>(UpdateEstimatedArrivalTimeForInstallationAppointmentDocument, options);
      }
export type UpdateEstimatedArrivalTimeForInstallationAppointmentMutationHookResult = ReturnType<typeof useUpdateEstimatedArrivalTimeForInstallationAppointmentMutation>;
export type UpdateEstimatedArrivalTimeForInstallationAppointmentMutationResult = Apollo.MutationResult<UpdateEstimatedArrivalTimeForInstallationAppointmentMutation>;
export type UpdateEstimatedArrivalTimeForInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateEstimatedArrivalTimeForInstallationAppointmentMutation, UpdateEstimatedArrivalTimeForInstallationAppointmentMutationVariables>;
export const GetContractProgressDocument = gql`
    query GetContractProgress($jobContractId: Int!) {
  contractProgress(jobContractId: $jobContractId) {
    id
    emailed
    stored
  }
}
    `;

/**
 * __useGetContractProgressQuery__
 *
 * To run a query within a React component, call `useGetContractProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractProgressQuery({
 *   variables: {
 *      jobContractId: // value for 'jobContractId'
 *   },
 * });
 */
export function useGetContractProgressQuery(baseOptions: Apollo.QueryHookOptions<GetContractProgressQuery, GetContractProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractProgressQuery, GetContractProgressQueryVariables>(GetContractProgressDocument, options);
      }
export function useGetContractProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractProgressQuery, GetContractProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractProgressQuery, GetContractProgressQueryVariables>(GetContractProgressDocument, options);
        }
export type GetContractProgressQueryHookResult = ReturnType<typeof useGetContractProgressQuery>;
export type GetContractProgressLazyQueryHookResult = ReturnType<typeof useGetContractProgressLazyQuery>;
export type GetContractProgressQueryResult = Apollo.QueryResult<GetContractProgressQuery, GetContractProgressQueryVariables>;
export const GetJobCodDocument = gql`
    query GetJobCOD($jobConfigurationId: Int!) {
  jobCOD(jobConfigurationId: $jobConfigurationId) {
    id
    remaining
    remainingToSchedule
    isFinancing
  }
}
    `;

/**
 * __useGetJobCodQuery__
 *
 * To run a query within a React component, call `useGetJobCodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobCodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobCodQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobCodQuery(baseOptions: Apollo.QueryHookOptions<GetJobCodQuery, GetJobCodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobCodQuery, GetJobCodQueryVariables>(GetJobCodDocument, options);
      }
export function useGetJobCodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobCodQuery, GetJobCodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobCodQuery, GetJobCodQueryVariables>(GetJobCodDocument, options);
        }
export type GetJobCodQueryHookResult = ReturnType<typeof useGetJobCodQuery>;
export type GetJobCodLazyQueryHookResult = ReturnType<typeof useGetJobCodLazyQuery>;
export type GetJobCodQueryResult = Apollo.QueryResult<GetJobCodQuery, GetJobCodQueryVariables>;
export const GetJobContractDocument = gql`
    query GetJobContract($jobContractId: Int!) {
  jobContract(jobContractId: $jobContractId) {
    id
    jobConfigurationId
    totalDepositPaid
    signingDate
    isCancelled
    totalPrice
    sAHNumber
  }
}
    `;

/**
 * __useGetJobContractQuery__
 *
 * To run a query within a React component, call `useGetJobContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobContractQuery({
 *   variables: {
 *      jobContractId: // value for 'jobContractId'
 *   },
 * });
 */
export function useGetJobContractQuery(baseOptions: Apollo.QueryHookOptions<GetJobContractQuery, GetJobContractQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobContractQuery, GetJobContractQueryVariables>(GetJobContractDocument, options);
      }
export function useGetJobContractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobContractQuery, GetJobContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobContractQuery, GetJobContractQueryVariables>(GetJobContractDocument, options);
        }
export type GetJobContractQueryHookResult = ReturnType<typeof useGetJobContractQuery>;
export type GetJobContractLazyQueryHookResult = ReturnType<typeof useGetJobContractLazyQuery>;
export type GetJobContractQueryResult = Apollo.QueryResult<GetJobContractQuery, GetJobContractQueryVariables>;
export const GetJobContractPdfS3FileDocument = gql`
    query GetJobContractPdfS3File($jobContractId: Int!) {
  jobContractPdfS3File(jobContractId: $jobContractId) {
    ...S3File
  }
}
    ${S3FileFragmentDoc}`;

/**
 * __useGetJobContractPdfS3FileQuery__
 *
 * To run a query within a React component, call `useGetJobContractPdfS3FileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobContractPdfS3FileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobContractPdfS3FileQuery({
 *   variables: {
 *      jobContractId: // value for 'jobContractId'
 *   },
 * });
 */
export function useGetJobContractPdfS3FileQuery(baseOptions: Apollo.QueryHookOptions<GetJobContractPdfS3FileQuery, GetJobContractPdfS3FileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobContractPdfS3FileQuery, GetJobContractPdfS3FileQueryVariables>(GetJobContractPdfS3FileDocument, options);
      }
export function useGetJobContractPdfS3FileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobContractPdfS3FileQuery, GetJobContractPdfS3FileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobContractPdfS3FileQuery, GetJobContractPdfS3FileQueryVariables>(GetJobContractPdfS3FileDocument, options);
        }
export type GetJobContractPdfS3FileQueryHookResult = ReturnType<typeof useGetJobContractPdfS3FileQuery>;
export type GetJobContractPdfS3FileLazyQueryHookResult = ReturnType<typeof useGetJobContractPdfS3FileLazyQuery>;
export type GetJobContractPdfS3FileQueryResult = Apollo.QueryResult<GetJobContractPdfS3FileQuery, GetJobContractPdfS3FileQueryVariables>;
export const GetJobContractRevisionIdsDocument = gql`
    query GetJobContractRevisionIds($jobContractId: Int!) {
  jobContractRevisionIds(jobContractId: $jobContractId) {
    id
    jobConfigurationIds
  }
}
    `;

/**
 * __useGetJobContractRevisionIdsQuery__
 *
 * To run a query within a React component, call `useGetJobContractRevisionIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobContractRevisionIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobContractRevisionIdsQuery({
 *   variables: {
 *      jobContractId: // value for 'jobContractId'
 *   },
 * });
 */
export function useGetJobContractRevisionIdsQuery(baseOptions: Apollo.QueryHookOptions<GetJobContractRevisionIdsQuery, GetJobContractRevisionIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobContractRevisionIdsQuery, GetJobContractRevisionIdsQueryVariables>(GetJobContractRevisionIdsDocument, options);
      }
export function useGetJobContractRevisionIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobContractRevisionIdsQuery, GetJobContractRevisionIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobContractRevisionIdsQuery, GetJobContractRevisionIdsQueryVariables>(GetJobContractRevisionIdsDocument, options);
        }
export type GetJobContractRevisionIdsQueryHookResult = ReturnType<typeof useGetJobContractRevisionIdsQuery>;
export type GetJobContractRevisionIdsLazyQueryHookResult = ReturnType<typeof useGetJobContractRevisionIdsLazyQuery>;
export type GetJobContractRevisionIdsQueryResult = Apollo.QueryResult<GetJobContractRevisionIdsQuery, GetJobContractRevisionIdsQueryVariables>;
export const GetJobActiveContractIdDocument = gql`
    query GetJobActiveContractId($jobId: Int!) {
  jobActiveContractId(jobId: $jobId)
}
    `;

/**
 * __useGetJobActiveContractIdQuery__
 *
 * To run a query within a React component, call `useGetJobActiveContractIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobActiveContractIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobActiveContractIdQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobActiveContractIdQuery(baseOptions: Apollo.QueryHookOptions<GetJobActiveContractIdQuery, GetJobActiveContractIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobActiveContractIdQuery, GetJobActiveContractIdQueryVariables>(GetJobActiveContractIdDocument, options);
      }
export function useGetJobActiveContractIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobActiveContractIdQuery, GetJobActiveContractIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobActiveContractIdQuery, GetJobActiveContractIdQueryVariables>(GetJobActiveContractIdDocument, options);
        }
export type GetJobActiveContractIdQueryHookResult = ReturnType<typeof useGetJobActiveContractIdQuery>;
export type GetJobActiveContractIdLazyQueryHookResult = ReturnType<typeof useGetJobActiveContractIdLazyQuery>;
export type GetJobActiveContractIdQueryResult = Apollo.QueryResult<GetJobActiveContractIdQuery, GetJobActiveContractIdQueryVariables>;
export const GetJobPaymentsDocument = gql`
    query GetJobPayments($jobContractId: Int!) {
  jobPayments(jobContractId: $jobContractId) {
    id
    jobContractId
    paymentMethodId
    amount
    isForDeposit
    authorizationNumber
  }
}
    `;

/**
 * __useGetJobPaymentsQuery__
 *
 * To run a query within a React component, call `useGetJobPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobPaymentsQuery({
 *   variables: {
 *      jobContractId: // value for 'jobContractId'
 *   },
 * });
 */
export function useGetJobPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetJobPaymentsQuery, GetJobPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobPaymentsQuery, GetJobPaymentsQueryVariables>(GetJobPaymentsDocument, options);
      }
export function useGetJobPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobPaymentsQuery, GetJobPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobPaymentsQuery, GetJobPaymentsQueryVariables>(GetJobPaymentsDocument, options);
        }
export type GetJobPaymentsQueryHookResult = ReturnType<typeof useGetJobPaymentsQuery>;
export type GetJobPaymentsLazyQueryHookResult = ReturnType<typeof useGetJobPaymentsLazyQuery>;
export type GetJobPaymentsQueryResult = Apollo.QueryResult<GetJobPaymentsQuery, GetJobPaymentsQueryVariables>;
export const CancelContractDocument = gql`
    mutation CancelContract($contractId: Int!) {
  cancelContract(contractId: $contractId)
}
    `;
export type CancelContractMutationFn = Apollo.MutationFunction<CancelContractMutation, CancelContractMutationVariables>;

/**
 * __useCancelContractMutation__
 *
 * To run a mutation, you first call `useCancelContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractMutation, { data, loading, error }] = useCancelContractMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useCancelContractMutation(baseOptions?: Apollo.MutationHookOptions<CancelContractMutation, CancelContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelContractMutation, CancelContractMutationVariables>(CancelContractDocument, options);
      }
export type CancelContractMutationHookResult = ReturnType<typeof useCancelContractMutation>;
export type CancelContractMutationResult = Apollo.MutationResult<CancelContractMutation>;
export type CancelContractMutationOptions = Apollo.BaseMutationOptions<CancelContractMutation, CancelContractMutationVariables>;
export const DeleteJobContractPaymentsDocument = gql`
    mutation DeleteJobContractPayments($paymentIds: [Int!]!) {
  deleteJobContractPayments(paymentIds: $paymentIds)
}
    `;
export type DeleteJobContractPaymentsMutationFn = Apollo.MutationFunction<DeleteJobContractPaymentsMutation, DeleteJobContractPaymentsMutationVariables>;

/**
 * __useDeleteJobContractPaymentsMutation__
 *
 * To run a mutation, you first call `useDeleteJobContractPaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobContractPaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobContractPaymentsMutation, { data, loading, error }] = useDeleteJobContractPaymentsMutation({
 *   variables: {
 *      paymentIds: // value for 'paymentIds'
 *   },
 * });
 */
export function useDeleteJobContractPaymentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobContractPaymentsMutation, DeleteJobContractPaymentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJobContractPaymentsMutation, DeleteJobContractPaymentsMutationVariables>(DeleteJobContractPaymentsDocument, options);
      }
export type DeleteJobContractPaymentsMutationHookResult = ReturnType<typeof useDeleteJobContractPaymentsMutation>;
export type DeleteJobContractPaymentsMutationResult = Apollo.MutationResult<DeleteJobContractPaymentsMutation>;
export type DeleteJobContractPaymentsMutationOptions = Apollo.BaseMutationOptions<DeleteJobContractPaymentsMutation, DeleteJobContractPaymentsMutationVariables>;
export const UpsertContractPdfPresignedDocument = gql`
    mutation UpsertContractPdfPresigned($contractId: Int!) {
  upsertContractPdfPresigned(contractId: $contractId) {
    ...PreSignedS3FileUpload
  }
}
    ${PreSignedS3FileUploadFragmentDoc}`;
export type UpsertContractPdfPresignedMutationFn = Apollo.MutationFunction<UpsertContractPdfPresignedMutation, UpsertContractPdfPresignedMutationVariables>;

/**
 * __useUpsertContractPdfPresignedMutation__
 *
 * To run a mutation, you first call `useUpsertContractPdfPresignedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContractPdfPresignedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContractPdfPresignedMutation, { data, loading, error }] = useUpsertContractPdfPresignedMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useUpsertContractPdfPresignedMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContractPdfPresignedMutation, UpsertContractPdfPresignedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContractPdfPresignedMutation, UpsertContractPdfPresignedMutationVariables>(UpsertContractPdfPresignedDocument, options);
      }
export type UpsertContractPdfPresignedMutationHookResult = ReturnType<typeof useUpsertContractPdfPresignedMutation>;
export type UpsertContractPdfPresignedMutationResult = Apollo.MutationResult<UpsertContractPdfPresignedMutation>;
export type UpsertContractPdfPresignedMutationOptions = Apollo.BaseMutationOptions<UpsertContractPdfPresignedMutation, UpsertContractPdfPresignedMutationVariables>;
export const UpsertJobContractPaymentsDocument = gql`
    mutation UpsertJobContractPayments($payments: [JobContractPaymentInput!]!) {
  upsertJobContractPayments(payments: $payments)
}
    `;
export type UpsertJobContractPaymentsMutationFn = Apollo.MutationFunction<UpsertJobContractPaymentsMutation, UpsertJobContractPaymentsMutationVariables>;

/**
 * __useUpsertJobContractPaymentsMutation__
 *
 * To run a mutation, you first call `useUpsertJobContractPaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobContractPaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobContractPaymentsMutation, { data, loading, error }] = useUpsertJobContractPaymentsMutation({
 *   variables: {
 *      payments: // value for 'payments'
 *   },
 * });
 */
export function useUpsertJobContractPaymentsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertJobContractPaymentsMutation, UpsertJobContractPaymentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertJobContractPaymentsMutation, UpsertJobContractPaymentsMutationVariables>(UpsertJobContractPaymentsDocument, options);
      }
export type UpsertJobContractPaymentsMutationHookResult = ReturnType<typeof useUpsertJobContractPaymentsMutation>;
export type UpsertJobContractPaymentsMutationResult = Apollo.MutationResult<UpsertJobContractPaymentsMutation>;
export type UpsertJobContractPaymentsMutationOptions = Apollo.BaseMutationOptions<UpsertJobContractPaymentsMutation, UpsertJobContractPaymentsMutationVariables>;
export const GetCustomerAppointmentSearchResultsDocument = gql`
    query GetCustomerAppointmentSearchResults($searchStr: String!) {
  customerAppointmentSearchResults(searchStr: $searchStr) {
    id
    customerId
    firstName
    lastName
    businessName
    phoneNumber
    city
    customerId
    appointmentNumber
    appointmentDate
    marketId
  }
}
    `;

/**
 * __useGetCustomerAppointmentSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetCustomerAppointmentSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAppointmentSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAppointmentSearchResultsQuery({
 *   variables: {
 *      searchStr: // value for 'searchStr'
 *   },
 * });
 */
export function useGetCustomerAppointmentSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerAppointmentSearchResultsQuery, GetCustomerAppointmentSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAppointmentSearchResultsQuery, GetCustomerAppointmentSearchResultsQueryVariables>(GetCustomerAppointmentSearchResultsDocument, options);
      }
export function useGetCustomerAppointmentSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAppointmentSearchResultsQuery, GetCustomerAppointmentSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAppointmentSearchResultsQuery, GetCustomerAppointmentSearchResultsQueryVariables>(GetCustomerAppointmentSearchResultsDocument, options);
        }
export type GetCustomerAppointmentSearchResultsQueryHookResult = ReturnType<typeof useGetCustomerAppointmentSearchResultsQuery>;
export type GetCustomerAppointmentSearchResultsLazyQueryHookResult = ReturnType<typeof useGetCustomerAppointmentSearchResultsLazyQuery>;
export type GetCustomerAppointmentSearchResultsQueryResult = Apollo.QueryResult<GetCustomerAppointmentSearchResultsQuery, GetCustomerAppointmentSearchResultsQueryVariables>;
export const GetCustomerAsSearchResultDocument = gql`
    query GetCustomerAsSearchResult($customerId: Int!) {
  customerAsSearchResult(customerId: $customerId) {
    id
    primaryContact {
      ...CustomerContact
    }
    secondaryContacts {
      ...CustomerContact
    }
    businessName
    primaryAddress {
      id
      streetAddress
      city
      zip
      apartmentNumber
    }
    jobId
    prospectNotes
  }
}
    ${CustomerContactFragmentDoc}`;

/**
 * __useGetCustomerAsSearchResultQuery__
 *
 * To run a query within a React component, call `useGetCustomerAsSearchResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAsSearchResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAsSearchResultQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerAsSearchResultQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerAsSearchResultQuery, GetCustomerAsSearchResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAsSearchResultQuery, GetCustomerAsSearchResultQueryVariables>(GetCustomerAsSearchResultDocument, options);
      }
export function useGetCustomerAsSearchResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAsSearchResultQuery, GetCustomerAsSearchResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAsSearchResultQuery, GetCustomerAsSearchResultQueryVariables>(GetCustomerAsSearchResultDocument, options);
        }
export type GetCustomerAsSearchResultQueryHookResult = ReturnType<typeof useGetCustomerAsSearchResultQuery>;
export type GetCustomerAsSearchResultLazyQueryHookResult = ReturnType<typeof useGetCustomerAsSearchResultLazyQuery>;
export type GetCustomerAsSearchResultQueryResult = Apollo.QueryResult<GetCustomerAsSearchResultQuery, GetCustomerAsSearchResultQueryVariables>;
export const GetCustomerCleanupListDocument = gql`
    query GetCustomerCleanupList($first: Int, $after: String) {
  customerCleanupList(first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        contactNames
        contactEmails
      }
    }
    nodes {
      id
      contactNames
      contactEmails
    }
    pageInfo {
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetCustomerCleanupListQuery__
 *
 * To run a query within a React component, call `useGetCustomerCleanupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerCleanupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerCleanupListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetCustomerCleanupListQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerCleanupListQuery, GetCustomerCleanupListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerCleanupListQuery, GetCustomerCleanupListQueryVariables>(GetCustomerCleanupListDocument, options);
      }
export function useGetCustomerCleanupListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerCleanupListQuery, GetCustomerCleanupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerCleanupListQuery, GetCustomerCleanupListQueryVariables>(GetCustomerCleanupListDocument, options);
        }
export type GetCustomerCleanupListQueryHookResult = ReturnType<typeof useGetCustomerCleanupListQuery>;
export type GetCustomerCleanupListLazyQueryHookResult = ReturnType<typeof useGetCustomerCleanupListLazyQuery>;
export type GetCustomerCleanupListQueryResult = Apollo.QueryResult<GetCustomerCleanupListQuery, GetCustomerCleanupListQueryVariables>;
export const GetCustomerHistoryDocument = gql`
    query GetCustomerHistory($customerId: Int!) {
  customerHistory(customerId: $customerId) {
    id
    firstName
    lastName
    jobSummaries {
      id
      latestVisitDate
      productTypes
      jobStatus
      salesperson {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetCustomerHistoryQuery__
 *
 * To run a query within a React component, call `useGetCustomerHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerHistoryQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerHistoryQuery, GetCustomerHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerHistoryQuery, GetCustomerHistoryQueryVariables>(GetCustomerHistoryDocument, options);
      }
export function useGetCustomerHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerHistoryQuery, GetCustomerHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerHistoryQuery, GetCustomerHistoryQueryVariables>(GetCustomerHistoryDocument, options);
        }
export type GetCustomerHistoryQueryHookResult = ReturnType<typeof useGetCustomerHistoryQuery>;
export type GetCustomerHistoryLazyQueryHookResult = ReturnType<typeof useGetCustomerHistoryLazyQuery>;
export type GetCustomerHistoryQueryResult = Apollo.QueryResult<GetCustomerHistoryQuery, GetCustomerHistoryQueryVariables>;
export const GetCustomerSearchResultsDocument = gql`
    query GetCustomerSearchResults($searchParameters: CustomerSearchParametersInput!, $shouldAndParameters: Boolean!, $numSearches: Int!) {
  customerSearchResults(searchParameters: $searchParameters, shouldAndParameters: $shouldAndParameters, numSearches: $numSearches) {
    id
    primaryContact {
      ...CustomerContact
    }
    secondaryContacts {
      ...CustomerContact
    }
    businessName
    primaryAddress {
      id
      streetAddress
      city
      zip
      apartmentNumber
    }
    jobId
    prospectNotes
  }
}
    ${CustomerContactFragmentDoc}`;

/**
 * __useGetCustomerSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetCustomerSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerSearchResultsQuery({
 *   variables: {
 *      searchParameters: // value for 'searchParameters'
 *      shouldAndParameters: // value for 'shouldAndParameters'
 *      numSearches: // value for 'numSearches'
 *   },
 * });
 */
export function useGetCustomerSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerSearchResultsQuery, GetCustomerSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerSearchResultsQuery, GetCustomerSearchResultsQueryVariables>(GetCustomerSearchResultsDocument, options);
      }
export function useGetCustomerSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerSearchResultsQuery, GetCustomerSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerSearchResultsQuery, GetCustomerSearchResultsQueryVariables>(GetCustomerSearchResultsDocument, options);
        }
export type GetCustomerSearchResultsQueryHookResult = ReturnType<typeof useGetCustomerSearchResultsQuery>;
export type GetCustomerSearchResultsLazyQueryHookResult = ReturnType<typeof useGetCustomerSearchResultsLazyQuery>;
export type GetCustomerSearchResultsQueryResult = Apollo.QueryResult<GetCustomerSearchResultsQuery, GetCustomerSearchResultsQueryVariables>;
export const EraseAllCustomerDataDocument = gql`
    mutation EraseAllCustomerData($customerIds: [Int!]!) {
  eraseAllCustomerData(customerIds: $customerIds)
}
    `;
export type EraseAllCustomerDataMutationFn = Apollo.MutationFunction<EraseAllCustomerDataMutation, EraseAllCustomerDataMutationVariables>;

/**
 * __useEraseAllCustomerDataMutation__
 *
 * To run a mutation, you first call `useEraseAllCustomerDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEraseAllCustomerDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eraseAllCustomerDataMutation, { data, loading, error }] = useEraseAllCustomerDataMutation({
 *   variables: {
 *      customerIds: // value for 'customerIds'
 *   },
 * });
 */
export function useEraseAllCustomerDataMutation(baseOptions?: Apollo.MutationHookOptions<EraseAllCustomerDataMutation, EraseAllCustomerDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EraseAllCustomerDataMutation, EraseAllCustomerDataMutationVariables>(EraseAllCustomerDataDocument, options);
      }
export type EraseAllCustomerDataMutationHookResult = ReturnType<typeof useEraseAllCustomerDataMutation>;
export type EraseAllCustomerDataMutationResult = Apollo.MutationResult<EraseAllCustomerDataMutation>;
export type EraseAllCustomerDataMutationOptions = Apollo.BaseMutationOptions<EraseAllCustomerDataMutation, EraseAllCustomerDataMutationVariables>;
export const MergeCustomersDocument = gql`
    mutation MergeCustomers($customerToMergeId: Int!, $customerToMergeInto: CustomerInput!) {
  mergeCustomers(customerToMergeId: $customerToMergeId, customerToMergeInto: $customerToMergeInto) {
    id
    firstName
    lastName
    businessName
    primaryPhone
    primaryAddress {
      id
      streetAddress
      city
      zip
      apartmentNumber
    }
    billingAddress {
      id
      streetAddress
      city
      zip
      apartmentNumber
    }
    email
    receivePromoEmails
    customerContacts {
      ...CustomerContact
    }
  }
}
    ${CustomerContactFragmentDoc}`;
export type MergeCustomersMutationFn = Apollo.MutationFunction<MergeCustomersMutation, MergeCustomersMutationVariables>;

/**
 * __useMergeCustomersMutation__
 *
 * To run a mutation, you first call `useMergeCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeCustomersMutation, { data, loading, error }] = useMergeCustomersMutation({
 *   variables: {
 *      customerToMergeId: // value for 'customerToMergeId'
 *      customerToMergeInto: // value for 'customerToMergeInto'
 *   },
 * });
 */
export function useMergeCustomersMutation(baseOptions?: Apollo.MutationHookOptions<MergeCustomersMutation, MergeCustomersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeCustomersMutation, MergeCustomersMutationVariables>(MergeCustomersDocument, options);
      }
export type MergeCustomersMutationHookResult = ReturnType<typeof useMergeCustomersMutation>;
export type MergeCustomersMutationResult = Apollo.MutationResult<MergeCustomersMutation>;
export type MergeCustomersMutationOptions = Apollo.BaseMutationOptions<MergeCustomersMutation, MergeCustomersMutationVariables>;
export const GetS3FileDocument = gql`
    query GetS3File($fileId: Int!) {
  s3File(fileId: $fileId) {
    id
    objectKey
    fileName
    status
    uploadTimestamp
    signedUrl
  }
}
    `;

/**
 * __useGetS3FileQuery__
 *
 * To run a query within a React component, call `useGetS3FileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetS3FileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetS3FileQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetS3FileQuery(baseOptions: Apollo.QueryHookOptions<GetS3FileQuery, GetS3FileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetS3FileQuery, GetS3FileQueryVariables>(GetS3FileDocument, options);
      }
export function useGetS3FileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetS3FileQuery, GetS3FileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetS3FileQuery, GetS3FileQueryVariables>(GetS3FileDocument, options);
        }
export type GetS3FileQueryHookResult = ReturnType<typeof useGetS3FileQuery>;
export type GetS3FileLazyQueryHookResult = ReturnType<typeof useGetS3FileLazyQuery>;
export type GetS3FileQueryResult = Apollo.QueryResult<GetS3FileQuery, GetS3FileQueryVariables>;
export const DeleteS3FileDocument = gql`
    mutation DeleteS3File($fileId: Int!) {
  deleteS3File(fileId: $fileId)
}
    `;
export type DeleteS3FileMutationFn = Apollo.MutationFunction<DeleteS3FileMutation, DeleteS3FileMutationVariables>;

/**
 * __useDeleteS3FileMutation__
 *
 * To run a mutation, you first call `useDeleteS3FileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteS3FileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteS3FileMutation, { data, loading, error }] = useDeleteS3FileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteS3FileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteS3FileMutation, DeleteS3FileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteS3FileMutation, DeleteS3FileMutationVariables>(DeleteS3FileDocument, options);
      }
export type DeleteS3FileMutationHookResult = ReturnType<typeof useDeleteS3FileMutation>;
export type DeleteS3FileMutationResult = Apollo.MutationResult<DeleteS3FileMutation>;
export type DeleteS3FileMutationOptions = Apollo.BaseMutationOptions<DeleteS3FileMutation, DeleteS3FileMutationVariables>;
export const ReportS3FileUploadStatusDocument = gql`
    mutation ReportS3FileUploadStatus($fileId: Int!, $succeeded: Boolean!) {
  reportS3FileUploadStatus(fileId: $fileId, succeeded: $succeeded)
}
    `;
export type ReportS3FileUploadStatusMutationFn = Apollo.MutationFunction<ReportS3FileUploadStatusMutation, ReportS3FileUploadStatusMutationVariables>;

/**
 * __useReportS3FileUploadStatusMutation__
 *
 * To run a mutation, you first call `useReportS3FileUploadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportS3FileUploadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportS3FileUploadStatusMutation, { data, loading, error }] = useReportS3FileUploadStatusMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      succeeded: // value for 'succeeded'
 *   },
 * });
 */
export function useReportS3FileUploadStatusMutation(baseOptions?: Apollo.MutationHookOptions<ReportS3FileUploadStatusMutation, ReportS3FileUploadStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportS3FileUploadStatusMutation, ReportS3FileUploadStatusMutationVariables>(ReportS3FileUploadStatusDocument, options);
      }
export type ReportS3FileUploadStatusMutationHookResult = ReturnType<typeof useReportS3FileUploadStatusMutation>;
export type ReportS3FileUploadStatusMutationResult = Apollo.MutationResult<ReportS3FileUploadStatusMutation>;
export type ReportS3FileUploadStatusMutationOptions = Apollo.BaseMutationOptions<ReportS3FileUploadStatusMutation, ReportS3FileUploadStatusMutationVariables>;
export const GetDummyInventoryDocument = gql`
    query GetDummyInventory($filter: InventoryEntryFilterInput, $order: [InventoryEntrySortInput!]) {
  dummyInventory(where: $filter, order: $order) {
    sku
    measurementUnit
    orderType
    measureUnitPerInventoryUnit
    manufacturer
    style
    color
    productType
    newCount
    damagedCount
    heldCount
    onOrderCountInOrderUnits
    onOrderCountInStockUnits
    toOrderCountInOrderUnits
    toOrderCountInStockUnits
    stockUnitPerOrderUnit
    available
    potential
    minimumStockCount
    searchableProductType
    stockUnit
    rolls {
      sku
      rollNumber
      dyeLot
      length
      holding
      location
      cuts {
        sku
        rollID
        cutNumber
        requestedDyeLot
        jobID
        length
        dueDate
      }
    }
  }
}
    `;

/**
 * __useGetDummyInventoryQuery__
 *
 * To run a query within a React component, call `useGetDummyInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDummyInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDummyInventoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetDummyInventoryQuery(baseOptions?: Apollo.QueryHookOptions<GetDummyInventoryQuery, GetDummyInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDummyInventoryQuery, GetDummyInventoryQueryVariables>(GetDummyInventoryDocument, options);
      }
export function useGetDummyInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDummyInventoryQuery, GetDummyInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDummyInventoryQuery, GetDummyInventoryQueryVariables>(GetDummyInventoryDocument, options);
        }
export type GetDummyInventoryQueryHookResult = ReturnType<typeof useGetDummyInventoryQuery>;
export type GetDummyInventoryLazyQueryHookResult = ReturnType<typeof useGetDummyInventoryLazyQuery>;
export type GetDummyInventoryQueryResult = Apollo.QueryResult<GetDummyInventoryQuery, GetDummyInventoryQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    email
    role
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const CalculateInstallationCalendarMovementDocument = gql`
    query CalculateInstallationCalendarMovement($block: InstallationCalendarBlockIdentifierInput!, $destinationContractorId: Int!, $destinationDate: DateTime!, $destinationBlock: InstallationCalendarBlockIdentifierInput) {
  calculateInstallationCalendarMovement(block: $block, destinationContractorId: $destinationContractorId, destinationDate: $destinationDate, destinationBlock: $destinationBlock) {
    isAllowed
    isSwapAllowed
    isOutOfContractorServiceArea
    needsOverride
    sourceType
    sourceIdentifier
    sourceDate
    sourceContractorId
    destinationType
    destinationIdentifier
    destinationDate
    destinationContractorId
  }
}
    `;

/**
 * __useCalculateInstallationCalendarMovementQuery__
 *
 * To run a query within a React component, call `useCalculateInstallationCalendarMovementQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateInstallationCalendarMovementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateInstallationCalendarMovementQuery({
 *   variables: {
 *      block: // value for 'block'
 *      destinationContractorId: // value for 'destinationContractorId'
 *      destinationDate: // value for 'destinationDate'
 *      destinationBlock: // value for 'destinationBlock'
 *   },
 * });
 */
export function useCalculateInstallationCalendarMovementQuery(baseOptions: Apollo.QueryHookOptions<CalculateInstallationCalendarMovementQuery, CalculateInstallationCalendarMovementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculateInstallationCalendarMovementQuery, CalculateInstallationCalendarMovementQueryVariables>(CalculateInstallationCalendarMovementDocument, options);
      }
export function useCalculateInstallationCalendarMovementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateInstallationCalendarMovementQuery, CalculateInstallationCalendarMovementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculateInstallationCalendarMovementQuery, CalculateInstallationCalendarMovementQueryVariables>(CalculateInstallationCalendarMovementDocument, options);
        }
export type CalculateInstallationCalendarMovementQueryHookResult = ReturnType<typeof useCalculateInstallationCalendarMovementQuery>;
export type CalculateInstallationCalendarMovementLazyQueryHookResult = ReturnType<typeof useCalculateInstallationCalendarMovementLazyQuery>;
export type CalculateInstallationCalendarMovementQueryResult = Apollo.QueryResult<CalculateInstallationCalendarMovementQuery, CalculateInstallationCalendarMovementQueryVariables>;
export const GetInstallationAppointmentsAfterDateDocument = gql`
    query GetInstallationAppointmentsAfterDate($afterDate: DateTime!) {
  installationAppointmentsAfterDate(afterDate: $afterDate) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;

/**
 * __useGetInstallationAppointmentsAfterDateQuery__
 *
 * To run a query within a React component, call `useGetInstallationAppointmentsAfterDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallationAppointmentsAfterDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallationAppointmentsAfterDateQuery({
 *   variables: {
 *      afterDate: // value for 'afterDate'
 *   },
 * });
 */
export function useGetInstallationAppointmentsAfterDateQuery(baseOptions: Apollo.QueryHookOptions<GetInstallationAppointmentsAfterDateQuery, GetInstallationAppointmentsAfterDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstallationAppointmentsAfterDateQuery, GetInstallationAppointmentsAfterDateQueryVariables>(GetInstallationAppointmentsAfterDateDocument, options);
      }
export function useGetInstallationAppointmentsAfterDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstallationAppointmentsAfterDateQuery, GetInstallationAppointmentsAfterDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstallationAppointmentsAfterDateQuery, GetInstallationAppointmentsAfterDateQueryVariables>(GetInstallationAppointmentsAfterDateDocument, options);
        }
export type GetInstallationAppointmentsAfterDateQueryHookResult = ReturnType<typeof useGetInstallationAppointmentsAfterDateQuery>;
export type GetInstallationAppointmentsAfterDateLazyQueryHookResult = ReturnType<typeof useGetInstallationAppointmentsAfterDateLazyQuery>;
export type GetInstallationAppointmentsAfterDateQueryResult = Apollo.QueryResult<GetInstallationAppointmentsAfterDateQuery, GetInstallationAppointmentsAfterDateQueryVariables>;
export const GetInstallationAppointmentsForJobContractDocument = gql`
    query GetInstallationAppointmentsForJobContract($jobContractId: Int!) {
  installationAppointmentsForJobContract(jobContractId: $jobContractId) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;

/**
 * __useGetInstallationAppointmentsForJobContractQuery__
 *
 * To run a query within a React component, call `useGetInstallationAppointmentsForJobContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallationAppointmentsForJobContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallationAppointmentsForJobContractQuery({
 *   variables: {
 *      jobContractId: // value for 'jobContractId'
 *   },
 * });
 */
export function useGetInstallationAppointmentsForJobContractQuery(baseOptions: Apollo.QueryHookOptions<GetInstallationAppointmentsForJobContractQuery, GetInstallationAppointmentsForJobContractQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstallationAppointmentsForJobContractQuery, GetInstallationAppointmentsForJobContractQueryVariables>(GetInstallationAppointmentsForJobContractDocument, options);
      }
export function useGetInstallationAppointmentsForJobContractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstallationAppointmentsForJobContractQuery, GetInstallationAppointmentsForJobContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstallationAppointmentsForJobContractQuery, GetInstallationAppointmentsForJobContractQueryVariables>(GetInstallationAppointmentsForJobContractDocument, options);
        }
export type GetInstallationAppointmentsForJobContractQueryHookResult = ReturnType<typeof useGetInstallationAppointmentsForJobContractQuery>;
export type GetInstallationAppointmentsForJobContractLazyQueryHookResult = ReturnType<typeof useGetInstallationAppointmentsForJobContractLazyQuery>;
export type GetInstallationAppointmentsForJobContractQueryResult = Apollo.QueryResult<GetInstallationAppointmentsForJobContractQuery, GetInstallationAppointmentsForJobContractQueryVariables>;
export const ToggleInstallationAppointmentReleaseDocument = gql`
    mutation ToggleInstallationAppointmentRelease($appointmentId: Int!, $releasedToContractor: Boolean!) {
  toggleInstallationAppointmentRelease(appointmentId: $appointmentId, releasedToContractor: $releasedToContractor) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;
export type ToggleInstallationAppointmentReleaseMutationFn = Apollo.MutationFunction<ToggleInstallationAppointmentReleaseMutation, ToggleInstallationAppointmentReleaseMutationVariables>;

/**
 * __useToggleInstallationAppointmentReleaseMutation__
 *
 * To run a mutation, you first call `useToggleInstallationAppointmentReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleInstallationAppointmentReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleInstallationAppointmentReleaseMutation, { data, loading, error }] = useToggleInstallationAppointmentReleaseMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      releasedToContractor: // value for 'releasedToContractor'
 *   },
 * });
 */
export function useToggleInstallationAppointmentReleaseMutation(baseOptions?: Apollo.MutationHookOptions<ToggleInstallationAppointmentReleaseMutation, ToggleInstallationAppointmentReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleInstallationAppointmentReleaseMutation, ToggleInstallationAppointmentReleaseMutationVariables>(ToggleInstallationAppointmentReleaseDocument, options);
      }
export type ToggleInstallationAppointmentReleaseMutationHookResult = ReturnType<typeof useToggleInstallationAppointmentReleaseMutation>;
export type ToggleInstallationAppointmentReleaseMutationResult = Apollo.MutationResult<ToggleInstallationAppointmentReleaseMutation>;
export type ToggleInstallationAppointmentReleaseMutationOptions = Apollo.BaseMutationOptions<ToggleInstallationAppointmentReleaseMutation, ToggleInstallationAppointmentReleaseMutationVariables>;
export const CalculatePriceForLocalConfigurationDocument = gql`
    query CalculatePriceForLocalConfiguration($originalConfigurationId: Int!, $updatedProducts: [UpdatedProductForAreaInput!]!, $includeSalesTax: Boolean!) {
  calculatePriceForLocalConfiguration(originalConfigurationId: $originalConfigurationId, updatedProducts: $updatedProducts, includeSalesTax: $includeSalesTax) {
    price {
      mSRP
      savings
      total
    }
    promoSavings
  }
}
    `;

/**
 * __useCalculatePriceForLocalConfigurationQuery__
 *
 * To run a query within a React component, call `useCalculatePriceForLocalConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculatePriceForLocalConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculatePriceForLocalConfigurationQuery({
 *   variables: {
 *      originalConfigurationId: // value for 'originalConfigurationId'
 *      updatedProducts: // value for 'updatedProducts'
 *      includeSalesTax: // value for 'includeSalesTax'
 *   },
 * });
 */
export function useCalculatePriceForLocalConfigurationQuery(baseOptions: Apollo.QueryHookOptions<CalculatePriceForLocalConfigurationQuery, CalculatePriceForLocalConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculatePriceForLocalConfigurationQuery, CalculatePriceForLocalConfigurationQueryVariables>(CalculatePriceForLocalConfigurationDocument, options);
      }
export function useCalculatePriceForLocalConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculatePriceForLocalConfigurationQuery, CalculatePriceForLocalConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculatePriceForLocalConfigurationQuery, CalculatePriceForLocalConfigurationQueryVariables>(CalculatePriceForLocalConfigurationDocument, options);
        }
export type CalculatePriceForLocalConfigurationQueryHookResult = ReturnType<typeof useCalculatePriceForLocalConfigurationQuery>;
export type CalculatePriceForLocalConfigurationLazyQueryHookResult = ReturnType<typeof useCalculatePriceForLocalConfigurationLazyQuery>;
export type CalculatePriceForLocalConfigurationQueryResult = Apollo.QueryResult<CalculatePriceForLocalConfigurationQuery, CalculatePriceForLocalConfigurationQueryVariables>;
export const CopyJobConfigurationDocument = gql`
    mutation CopyJobConfiguration($jobConfigurationId: Int!, $optionNumber: Int!, $updatedProducts: [UpdatedProductForAreaInput!], $selectedFinancingOption: Int) {
  copyJobConfiguration(jobConfigurationId: $jobConfigurationId, optionNumber: $optionNumber, updatedProducts: $updatedProducts, selectedFinancingOption: $selectedFinancingOption)
}
    `;
export type CopyJobConfigurationMutationFn = Apollo.MutationFunction<CopyJobConfigurationMutation, CopyJobConfigurationMutationVariables>;

/**
 * __useCopyJobConfigurationMutation__
 *
 * To run a mutation, you first call `useCopyJobConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyJobConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyJobConfigurationMutation, { data, loading, error }] = useCopyJobConfigurationMutation({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      optionNumber: // value for 'optionNumber'
 *      updatedProducts: // value for 'updatedProducts'
 *      selectedFinancingOption: // value for 'selectedFinancingOption'
 *   },
 * });
 */
export function useCopyJobConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<CopyJobConfigurationMutation, CopyJobConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyJobConfigurationMutation, CopyJobConfigurationMutationVariables>(CopyJobConfigurationDocument, options);
      }
export type CopyJobConfigurationMutationHookResult = ReturnType<typeof useCopyJobConfigurationMutation>;
export type CopyJobConfigurationMutationResult = Apollo.MutationResult<CopyJobConfigurationMutation>;
export type CopyJobConfigurationMutationOptions = Apollo.BaseMutationOptions<CopyJobConfigurationMutation, CopyJobConfigurationMutationVariables>;
export const GetActiveFinancingOptionsDocument = gql`
    query GetActiveFinancingOptions {
  activeFinancingOptions {
    id
    term
    minAmount
    typeId
    typeName
    fee
    startDate
    endDate
    tranCode
    promoParentTranCode
    slot
  }
}
    `;

/**
 * __useGetActiveFinancingOptionsQuery__
 *
 * To run a query within a React component, call `useGetActiveFinancingOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveFinancingOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveFinancingOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveFinancingOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveFinancingOptionsQuery, GetActiveFinancingOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveFinancingOptionsQuery, GetActiveFinancingOptionsQueryVariables>(GetActiveFinancingOptionsDocument, options);
      }
export function useGetActiveFinancingOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveFinancingOptionsQuery, GetActiveFinancingOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveFinancingOptionsQuery, GetActiveFinancingOptionsQueryVariables>(GetActiveFinancingOptionsDocument, options);
        }
export type GetActiveFinancingOptionsQueryHookResult = ReturnType<typeof useGetActiveFinancingOptionsQuery>;
export type GetActiveFinancingOptionsLazyQueryHookResult = ReturnType<typeof useGetActiveFinancingOptionsLazyQuery>;
export type GetActiveFinancingOptionsQueryResult = Apollo.QueryResult<GetActiveFinancingOptionsQuery, GetActiveFinancingOptionsQueryVariables>;
export const GetAllAppliedDiscountsForJobDocument = gql`
    query GetAllAppliedDiscountsForJob($jobConfigurationId: Int!) {
  allAppliedDiscountsForJob(jobConfigurationId: $jobConfigurationId) {
    id
    jobConfigurationId
    discountId
    authorizationCode
    amount
    name
    isScalar
    isValid
  }
}
    `;

/**
 * __useGetAllAppliedDiscountsForJobQuery__
 *
 * To run a query within a React component, call `useGetAllAppliedDiscountsForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAppliedDiscountsForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAppliedDiscountsForJobQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetAllAppliedDiscountsForJobQuery(baseOptions: Apollo.QueryHookOptions<GetAllAppliedDiscountsForJobQuery, GetAllAppliedDiscountsForJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAppliedDiscountsForJobQuery, GetAllAppliedDiscountsForJobQueryVariables>(GetAllAppliedDiscountsForJobDocument, options);
      }
export function useGetAllAppliedDiscountsForJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAppliedDiscountsForJobQuery, GetAllAppliedDiscountsForJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAppliedDiscountsForJobQuery, GetAllAppliedDiscountsForJobQueryVariables>(GetAllAppliedDiscountsForJobDocument, options);
        }
export type GetAllAppliedDiscountsForJobQueryHookResult = ReturnType<typeof useGetAllAppliedDiscountsForJobQuery>;
export type GetAllAppliedDiscountsForJobLazyQueryHookResult = ReturnType<typeof useGetAllAppliedDiscountsForJobLazyQuery>;
export type GetAllAppliedDiscountsForJobQueryResult = Apollo.QueryResult<GetAllAppliedDiscountsForJobQuery, GetAllAppliedDiscountsForJobQueryVariables>;
export const GetAllJobIdsDocument = gql`
    query GetAllJobIds {
  allJobIds
}
    `;

/**
 * __useGetAllJobIdsQuery__
 *
 * To run a query within a React component, call `useGetAllJobIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllJobIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllJobIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllJobIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllJobIdsQuery, GetAllJobIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllJobIdsQuery, GetAllJobIdsQueryVariables>(GetAllJobIdsDocument, options);
      }
export function useGetAllJobIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllJobIdsQuery, GetAllJobIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllJobIdsQuery, GetAllJobIdsQueryVariables>(GetAllJobIdsDocument, options);
        }
export type GetAllJobIdsQueryHookResult = ReturnType<typeof useGetAllJobIdsQuery>;
export type GetAllJobIdsLazyQueryHookResult = ReturnType<typeof useGetAllJobIdsLazyQuery>;
export type GetAllJobIdsQueryResult = Apollo.QueryResult<GetAllJobIdsQuery, GetAllJobIdsQueryVariables>;
export const GetAllValidProductStyleOptionsForConfigurationDocument = gql`
    query GetAllValidProductStyleOptionsForConfiguration($substrateIdsInArea: [Int!]!, $productTypeId: Int!) {
  allValidProductStyleOptionsForConfiguration(substrateIdsInArea: $substrateIdsInArea, productTypeId: $productTypeId) {
    id
    substrateIdsInArea
    style
    valid
    defaultInstallJobServiceId
    materialForDefaultInstallServiceId
  }
}
    `;

/**
 * __useGetAllValidProductStyleOptionsForConfigurationQuery__
 *
 * To run a query within a React component, call `useGetAllValidProductStyleOptionsForConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllValidProductStyleOptionsForConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllValidProductStyleOptionsForConfigurationQuery({
 *   variables: {
 *      substrateIdsInArea: // value for 'substrateIdsInArea'
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useGetAllValidProductStyleOptionsForConfigurationQuery(baseOptions: Apollo.QueryHookOptions<GetAllValidProductStyleOptionsForConfigurationQuery, GetAllValidProductStyleOptionsForConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllValidProductStyleOptionsForConfigurationQuery, GetAllValidProductStyleOptionsForConfigurationQueryVariables>(GetAllValidProductStyleOptionsForConfigurationDocument, options);
      }
export function useGetAllValidProductStyleOptionsForConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllValidProductStyleOptionsForConfigurationQuery, GetAllValidProductStyleOptionsForConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllValidProductStyleOptionsForConfigurationQuery, GetAllValidProductStyleOptionsForConfigurationQueryVariables>(GetAllValidProductStyleOptionsForConfigurationDocument, options);
        }
export type GetAllValidProductStyleOptionsForConfigurationQueryHookResult = ReturnType<typeof useGetAllValidProductStyleOptionsForConfigurationQuery>;
export type GetAllValidProductStyleOptionsForConfigurationLazyQueryHookResult = ReturnType<typeof useGetAllValidProductStyleOptionsForConfigurationLazyQuery>;
export type GetAllValidProductStyleOptionsForConfigurationQueryResult = Apollo.QueryResult<GetAllValidProductStyleOptionsForConfigurationQuery, GetAllValidProductStyleOptionsForConfigurationQueryVariables>;
export const GetAllSahSalespeopleDocument = gql`
    query GetAllSAHSalespeople {
  sAHSalespeople {
    id
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useGetAllSahSalespeopleQuery__
 *
 * To run a query within a React component, call `useGetAllSahSalespeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSahSalespeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSahSalespeopleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSahSalespeopleQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSahSalespeopleQuery, GetAllSahSalespeopleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSahSalespeopleQuery, GetAllSahSalespeopleQueryVariables>(GetAllSahSalespeopleDocument, options);
      }
export function useGetAllSahSalespeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSahSalespeopleQuery, GetAllSahSalespeopleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSahSalespeopleQuery, GetAllSahSalespeopleQueryVariables>(GetAllSahSalespeopleDocument, options);
        }
export type GetAllSahSalespeopleQueryHookResult = ReturnType<typeof useGetAllSahSalespeopleQuery>;
export type GetAllSahSalespeopleLazyQueryHookResult = ReturnType<typeof useGetAllSahSalespeopleLazyQuery>;
export type GetAllSahSalespeopleQueryResult = Apollo.QueryResult<GetAllSahSalespeopleQuery, GetAllSahSalespeopleQueryVariables>;
export const GetAllSalespeopleForJobDocument = gql`
    query GetAllSalespeopleForJob($jobConfigurationId: Int!) {
  allSalespeopleForJob(jobConfigurationId: $jobConfigurationId) {
    id
    firstName
    lastName
    email
    serviceZips
  }
}
    `;

/**
 * __useGetAllSalespeopleForJobQuery__
 *
 * To run a query within a React component, call `useGetAllSalespeopleForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSalespeopleForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSalespeopleForJobQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetAllSalespeopleForJobQuery(baseOptions: Apollo.QueryHookOptions<GetAllSalespeopleForJobQuery, GetAllSalespeopleForJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSalespeopleForJobQuery, GetAllSalespeopleForJobQueryVariables>(GetAllSalespeopleForJobDocument, options);
      }
export function useGetAllSalespeopleForJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSalespeopleForJobQuery, GetAllSalespeopleForJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSalespeopleForJobQuery, GetAllSalespeopleForJobQueryVariables>(GetAllSalespeopleForJobDocument, options);
        }
export type GetAllSalespeopleForJobQueryHookResult = ReturnType<typeof useGetAllSalespeopleForJobQuery>;
export type GetAllSalespeopleForJobLazyQueryHookResult = ReturnType<typeof useGetAllSalespeopleForJobLazyQuery>;
export type GetAllSalespeopleForJobQueryResult = Apollo.QueryResult<GetAllSalespeopleForJobQuery, GetAllSalespeopleForJobQueryVariables>;
export const GetAreaDocument = gql`
    query GetArea($areaId: Int!) {
  area(areaId: $areaId) {
    ...Area
  }
}
    ${AreaFragmentDoc}`;

/**
 * __useGetAreaQuery__
 *
 * To run a query within a React component, call `useGetAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreaQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useGetAreaQuery(baseOptions: Apollo.QueryHookOptions<GetAreaQuery, GetAreaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAreaQuery, GetAreaQueryVariables>(GetAreaDocument, options);
      }
export function useGetAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAreaQuery, GetAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAreaQuery, GetAreaQueryVariables>(GetAreaDocument, options);
        }
export type GetAreaQueryHookResult = ReturnType<typeof useGetAreaQuery>;
export type GetAreaLazyQueryHookResult = ReturnType<typeof useGetAreaLazyQuery>;
export type GetAreaQueryResult = Apollo.QueryResult<GetAreaQuery, GetAreaQueryVariables>;
export const GetCallCenterJobSummaryDocument = gql`
    query GetCallCenterJobSummary($jobId: Int!) {
  callCenterJobSummary(jobId: $jobId) {
    id
    customer {
      id
      firstName
      lastName
      businessName
      primaryPhone
      primaryAddress {
        id
        streetAddress
        city
        zip
        apartmentNumber
      }
      billingAddress {
        id
        streetAddress
        city
        zip
        apartmentNumber
      }
      email
      receivePromoEmails
      customerContacts {
        ...CustomerContact
      }
    }
    appointment {
      id
      appointmentNumber
      jobId
      numRooms
      date
      isForCustomerHouse
      isCancelled
      isInRecovery
      comments
      promoCodeId
      howDidYouHearAboutUsOptionId
      howDidYouHearAboutUsOption
      productTypes
      productTypeIds
      customerId
      firstName
      lastName
      primaryAddress
      primaryCity
      primaryZipCode
      primaryPhone
      appointmentStatusId
      appointmentStatusString
      contractCancelled
      contractTotalPrice
      quoteTotalPrice
      marketTimeSlot {
        id
        startTime
        endTime
        marketId
      }
      currentSalesperson {
        id
        sAHAppointmentId
        salespersonId
        releasedTime
        acceptedTime
        checkInTime
        checkOutTime
        appointmentStartTime
        isWriteUp
      }
      priorityOptions {
        id
        text
      }
      colorCategories {
        id
        category
      }
    }
    mostRecentSalesperson {
      id
      firstName
      lastName
    }
  }
}
    ${CustomerContactFragmentDoc}`;

/**
 * __useGetCallCenterJobSummaryQuery__
 *
 * To run a query within a React component, call `useGetCallCenterJobSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallCenterJobSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallCenterJobSummaryQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetCallCenterJobSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetCallCenterJobSummaryQuery, GetCallCenterJobSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCallCenterJobSummaryQuery, GetCallCenterJobSummaryQueryVariables>(GetCallCenterJobSummaryDocument, options);
      }
export function useGetCallCenterJobSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCallCenterJobSummaryQuery, GetCallCenterJobSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCallCenterJobSummaryQuery, GetCallCenterJobSummaryQueryVariables>(GetCallCenterJobSummaryDocument, options);
        }
export type GetCallCenterJobSummaryQueryHookResult = ReturnType<typeof useGetCallCenterJobSummaryQuery>;
export type GetCallCenterJobSummaryLazyQueryHookResult = ReturnType<typeof useGetCallCenterJobSummaryLazyQuery>;
export type GetCallCenterJobSummaryQueryResult = Apollo.QueryResult<GetCallCenterJobSummaryQuery, GetCallCenterJobSummaryQueryVariables>;
export const GetContractDataDocument = gql`
    query GetContractData($jobConfigurationId: Int!) {
  contractData(jobConfigurationId: $jobConfigurationId) {
    contractSignatureBase64
    noticeOfRightSignatureBase64
    payments {
      paymentMethodId
      amount
    }
    financingAccountNumber
    updatedAreaNotes {
      areaId
      areaNotes
    }
    updatedJobNotes
    signingDate
  }
}
    `;

/**
 * __useGetContractDataQuery__
 *
 * To run a query within a React component, call `useGetContractDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractDataQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetContractDataQuery(baseOptions: Apollo.QueryHookOptions<GetContractDataQuery, GetContractDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractDataQuery, GetContractDataQueryVariables>(GetContractDataDocument, options);
      }
export function useGetContractDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractDataQuery, GetContractDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractDataQuery, GetContractDataQueryVariables>(GetContractDataDocument, options);
        }
export type GetContractDataQueryHookResult = ReturnType<typeof useGetContractDataQuery>;
export type GetContractDataLazyQueryHookResult = ReturnType<typeof useGetContractDataLazyQuery>;
export type GetContractDataQueryResult = Apollo.QueryResult<GetContractDataQuery, GetContractDataQueryVariables>;
export const GetFullyScaledJobConfigurationDocument = gql`
    query GetFullyScaledJobConfiguration($jobConfigurationId: Int!) {
  fullyScaledJobConfiguration(jobConfigurationId: $jobConfigurationId) {
    ...JobConfiguration
  }
}
    ${JobConfigurationFragmentDoc}`;

/**
 * __useGetFullyScaledJobConfigurationQuery__
 *
 * To run a query within a React component, call `useGetFullyScaledJobConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullyScaledJobConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullyScaledJobConfigurationQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetFullyScaledJobConfigurationQuery(baseOptions: Apollo.QueryHookOptions<GetFullyScaledJobConfigurationQuery, GetFullyScaledJobConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFullyScaledJobConfigurationQuery, GetFullyScaledJobConfigurationQueryVariables>(GetFullyScaledJobConfigurationDocument, options);
      }
export function useGetFullyScaledJobConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFullyScaledJobConfigurationQuery, GetFullyScaledJobConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFullyScaledJobConfigurationQuery, GetFullyScaledJobConfigurationQueryVariables>(GetFullyScaledJobConfigurationDocument, options);
        }
export type GetFullyScaledJobConfigurationQueryHookResult = ReturnType<typeof useGetFullyScaledJobConfigurationQuery>;
export type GetFullyScaledJobConfigurationLazyQueryHookResult = ReturnType<typeof useGetFullyScaledJobConfigurationLazyQuery>;
export type GetFullyScaledJobConfigurationQueryResult = Apollo.QueryResult<GetFullyScaledJobConfigurationQuery, GetFullyScaledJobConfigurationQueryVariables>;
export const GetJobConfigurationDocument = gql`
    query GetJobConfiguration($jobConfigurationId: Int!) {
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    ...JobConfiguration
  }
}
    ${JobConfigurationFragmentDoc}`;

/**
 * __useGetJobConfigurationQuery__
 *
 * To run a query within a React component, call `useGetJobConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobConfigurationQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobConfigurationQuery(baseOptions: Apollo.QueryHookOptions<GetJobConfigurationQuery, GetJobConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobConfigurationQuery, GetJobConfigurationQueryVariables>(GetJobConfigurationDocument, options);
      }
export function useGetJobConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobConfigurationQuery, GetJobConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobConfigurationQuery, GetJobConfigurationQueryVariables>(GetJobConfigurationDocument, options);
        }
export type GetJobConfigurationQueryHookResult = ReturnType<typeof useGetJobConfigurationQuery>;
export type GetJobConfigurationLazyQueryHookResult = ReturnType<typeof useGetJobConfigurationLazyQuery>;
export type GetJobConfigurationQueryResult = Apollo.QueryResult<GetJobConfigurationQuery, GetJobConfigurationQueryVariables>;
export const GetJobConfigurationAreaIdsDocument = gql`
    query GetJobConfigurationAreaIds($jobConfigurationId: Int!) {
  jobConfigurationAreaIds(jobConfigurationId: $jobConfigurationId)
}
    `;

/**
 * __useGetJobConfigurationAreaIdsQuery__
 *
 * To run a query within a React component, call `useGetJobConfigurationAreaIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobConfigurationAreaIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobConfigurationAreaIdsQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobConfigurationAreaIdsQuery(baseOptions: Apollo.QueryHookOptions<GetJobConfigurationAreaIdsQuery, GetJobConfigurationAreaIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobConfigurationAreaIdsQuery, GetJobConfigurationAreaIdsQueryVariables>(GetJobConfigurationAreaIdsDocument, options);
      }
export function useGetJobConfigurationAreaIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobConfigurationAreaIdsQuery, GetJobConfigurationAreaIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobConfigurationAreaIdsQuery, GetJobConfigurationAreaIdsQueryVariables>(GetJobConfigurationAreaIdsDocument, options);
        }
export type GetJobConfigurationAreaIdsQueryHookResult = ReturnType<typeof useGetJobConfigurationAreaIdsQuery>;
export type GetJobConfigurationAreaIdsLazyQueryHookResult = ReturnType<typeof useGetJobConfigurationAreaIdsLazyQuery>;
export type GetJobConfigurationAreaIdsQueryResult = Apollo.QueryResult<GetJobConfigurationAreaIdsQuery, GetJobConfigurationAreaIdsQueryVariables>;
export const GetJobConfigurationFinancingDocument = gql`
    query GetJobConfigurationFinancing($jobConfigurationId: Int!) {
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    id
    financing {
      id
      financingAccountNumber
      financingDownPayment
      perMonthAmount
      financingOption {
        id
        term
        minAmount
        typeId
        fee
        startDate
        endDate
        tranCode
        promoParentTranCode
        slot
      }
    }
  }
}
    `;

/**
 * __useGetJobConfigurationFinancingQuery__
 *
 * To run a query within a React component, call `useGetJobConfigurationFinancingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobConfigurationFinancingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobConfigurationFinancingQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobConfigurationFinancingQuery(baseOptions: Apollo.QueryHookOptions<GetJobConfigurationFinancingQuery, GetJobConfigurationFinancingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobConfigurationFinancingQuery, GetJobConfigurationFinancingQueryVariables>(GetJobConfigurationFinancingDocument, options);
      }
export function useGetJobConfigurationFinancingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobConfigurationFinancingQuery, GetJobConfigurationFinancingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobConfigurationFinancingQuery, GetJobConfigurationFinancingQueryVariables>(GetJobConfigurationFinancingDocument, options);
        }
export type GetJobConfigurationFinancingQueryHookResult = ReturnType<typeof useGetJobConfigurationFinancingQuery>;
export type GetJobConfigurationFinancingLazyQueryHookResult = ReturnType<typeof useGetJobConfigurationFinancingLazyQuery>;
export type GetJobConfigurationFinancingQueryResult = Apollo.QueryResult<GetJobConfigurationFinancingQuery, GetJobConfigurationFinancingQueryVariables>;
export const GetJobConfigurationHeaderDocument = gql`
    query GetJobConfigurationHeader($jobConfigurationId: Int!) {
  jobConfigurationHeader(jobConfigurationId: $jobConfigurationId) {
    id
    appointmentNumber
    customer {
      id
      firstName
      lastName
    }
    configurationIds {
      id
      labels
    }
  }
}
    `;

/**
 * __useGetJobConfigurationHeaderQuery__
 *
 * To run a query within a React component, call `useGetJobConfigurationHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobConfigurationHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobConfigurationHeaderQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobConfigurationHeaderQuery(baseOptions: Apollo.QueryHookOptions<GetJobConfigurationHeaderQuery, GetJobConfigurationHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobConfigurationHeaderQuery, GetJobConfigurationHeaderQueryVariables>(GetJobConfigurationHeaderDocument, options);
      }
export function useGetJobConfigurationHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobConfigurationHeaderQuery, GetJobConfigurationHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobConfigurationHeaderQuery, GetJobConfigurationHeaderQueryVariables>(GetJobConfigurationHeaderDocument, options);
        }
export type GetJobConfigurationHeaderQueryHookResult = ReturnType<typeof useGetJobConfigurationHeaderQuery>;
export type GetJobConfigurationHeaderLazyQueryHookResult = ReturnType<typeof useGetJobConfigurationHeaderLazyQuery>;
export type GetJobConfigurationHeaderQueryResult = Apollo.QueryResult<GetJobConfigurationHeaderQuery, GetJobConfigurationHeaderQueryVariables>;
export const GetJobConfigurationWithWasteFactorDocument = gql`
    query GetJobConfigurationWithWasteFactor($jobConfigurationId: Int!) {
  jobConfigurationWithWasteFactor(jobConfigurationId: $jobConfigurationId) {
    ...JobConfiguration
  }
}
    ${JobConfigurationFragmentDoc}`;

/**
 * __useGetJobConfigurationWithWasteFactorQuery__
 *
 * To run a query within a React component, call `useGetJobConfigurationWithWasteFactorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobConfigurationWithWasteFactorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobConfigurationWithWasteFactorQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobConfigurationWithWasteFactorQuery(baseOptions: Apollo.QueryHookOptions<GetJobConfigurationWithWasteFactorQuery, GetJobConfigurationWithWasteFactorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobConfigurationWithWasteFactorQuery, GetJobConfigurationWithWasteFactorQueryVariables>(GetJobConfigurationWithWasteFactorDocument, options);
      }
export function useGetJobConfigurationWithWasteFactorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobConfigurationWithWasteFactorQuery, GetJobConfigurationWithWasteFactorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobConfigurationWithWasteFactorQuery, GetJobConfigurationWithWasteFactorQueryVariables>(GetJobConfigurationWithWasteFactorDocument, options);
        }
export type GetJobConfigurationWithWasteFactorQueryHookResult = ReturnType<typeof useGetJobConfigurationWithWasteFactorQuery>;
export type GetJobConfigurationWithWasteFactorLazyQueryHookResult = ReturnType<typeof useGetJobConfigurationWithWasteFactorLazyQuery>;
export type GetJobConfigurationWithWasteFactorQueryResult = Apollo.QueryResult<GetJobConfigurationWithWasteFactorQuery, GetJobConfigurationWithWasteFactorQueryVariables>;
export const GetJobFinancingOptionsDocument = gql`
    query GetJobFinancingOptions($jobPrice: Int!, $jobConfigurationId: Int) {
  jobFinancingOptions(jobPrice: $jobPrice, jobConfigurationId: $jobConfigurationId) {
    slotA {
      id
      term
      minAmount
      typeId
      typeName
      fee
      startDate
      endDate
      tranCode
      promoParentTranCode
      slot
    }
    slotB {
      id
      term
      minAmount
      typeId
      typeName
      fee
      startDate
      endDate
      tranCode
      promoParentTranCode
      slot
    }
    slotC {
      id
      term
      minAmount
      typeId
      typeName
      fee
      startDate
      endDate
      tranCode
      promoParentTranCode
      slot
    }
    jobPrice
  }
}
    `;

/**
 * __useGetJobFinancingOptionsQuery__
 *
 * To run a query within a React component, call `useGetJobFinancingOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobFinancingOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobFinancingOptionsQuery({
 *   variables: {
 *      jobPrice: // value for 'jobPrice'
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobFinancingOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetJobFinancingOptionsQuery, GetJobFinancingOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobFinancingOptionsQuery, GetJobFinancingOptionsQueryVariables>(GetJobFinancingOptionsDocument, options);
      }
export function useGetJobFinancingOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobFinancingOptionsQuery, GetJobFinancingOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobFinancingOptionsQuery, GetJobFinancingOptionsQueryVariables>(GetJobFinancingOptionsDocument, options);
        }
export type GetJobFinancingOptionsQueryHookResult = ReturnType<typeof useGetJobFinancingOptionsQuery>;
export type GetJobFinancingOptionsLazyQueryHookResult = ReturnType<typeof useGetJobFinancingOptionsLazyQuery>;
export type GetJobFinancingOptionsQueryResult = Apollo.QueryResult<GetJobFinancingOptionsQuery, GetJobFinancingOptionsQueryVariables>;
export const GetJobIdDocument = gql`
    query GetJobId($jobConfigurationId: Int!) {
  jobId(jobConfigurationId: $jobConfigurationId)
}
    `;

/**
 * __useGetJobIdQuery__
 *
 * To run a query within a React component, call `useGetJobIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobIdQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobIdQuery(baseOptions: Apollo.QueryHookOptions<GetJobIdQuery, GetJobIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobIdQuery, GetJobIdQueryVariables>(GetJobIdDocument, options);
      }
export function useGetJobIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobIdQuery, GetJobIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobIdQuery, GetJobIdQueryVariables>(GetJobIdDocument, options);
        }
export type GetJobIdQueryHookResult = ReturnType<typeof useGetJobIdQuery>;
export type GetJobIdLazyQueryHookResult = ReturnType<typeof useGetJobIdLazyQuery>;
export type GetJobIdQueryResult = Apollo.QueryResult<GetJobIdQuery, GetJobIdQueryVariables>;
export const GetJobIsFailDocument = gql`
    query GetJobIsFail($jobId: Int!) {
  jobIsFail(jobId: $jobId)
  jobIsInRecovery(jobId: $jobId)
}
    `;

/**
 * __useGetJobIsFailQuery__
 *
 * To run a query within a React component, call `useGetJobIsFailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobIsFailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobIsFailQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobIsFailQuery(baseOptions: Apollo.QueryHookOptions<GetJobIsFailQuery, GetJobIsFailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobIsFailQuery, GetJobIsFailQueryVariables>(GetJobIsFailDocument, options);
      }
export function useGetJobIsFailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobIsFailQuery, GetJobIsFailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobIsFailQuery, GetJobIsFailQueryVariables>(GetJobIsFailDocument, options);
        }
export type GetJobIsFailQueryHookResult = ReturnType<typeof useGetJobIsFailQuery>;
export type GetJobIsFailLazyQueryHookResult = ReturnType<typeof useGetJobIsFailLazyQuery>;
export type GetJobIsFailQueryResult = Apollo.QueryResult<GetJobIsFailQuery, GetJobIsFailQueryVariables>;
export const GetJobPriceDocument = gql`
    query GetJobPrice($jobConfigurationId: Int!) {
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    price {
      mSRP
      savings
      total
    }
  }
}
    `;

/**
 * __useGetJobPriceQuery__
 *
 * To run a query within a React component, call `useGetJobPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobPriceQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobPriceQuery(baseOptions: Apollo.QueryHookOptions<GetJobPriceQuery, GetJobPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobPriceQuery, GetJobPriceQueryVariables>(GetJobPriceDocument, options);
      }
export function useGetJobPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobPriceQuery, GetJobPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobPriceQuery, GetJobPriceQueryVariables>(GetJobPriceDocument, options);
        }
export type GetJobPriceQueryHookResult = ReturnType<typeof useGetJobPriceQuery>;
export type GetJobPriceLazyQueryHookResult = ReturnType<typeof useGetJobPriceLazyQuery>;
export type GetJobPriceQueryResult = Apollo.QueryResult<GetJobPriceQuery, GetJobPriceQueryVariables>;
export const GetJobSearchResultsDocument = gql`
    query GetJobSearchResults($searchStr: String!) {
  jobSearchResults(searchStr: $searchStr) {
    id
    firstName
    lastName
    phoneNumber
    city
    jobConfigurationId
    sahNumber
    contractDate
    jobContractId
  }
}
    `;

/**
 * __useGetJobSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetJobSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobSearchResultsQuery({
 *   variables: {
 *      searchStr: // value for 'searchStr'
 *   },
 * });
 */
export function useGetJobSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<GetJobSearchResultsQuery, GetJobSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobSearchResultsQuery, GetJobSearchResultsQueryVariables>(GetJobSearchResultsDocument, options);
      }
export function useGetJobSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobSearchResultsQuery, GetJobSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobSearchResultsQuery, GetJobSearchResultsQueryVariables>(GetJobSearchResultsDocument, options);
        }
export type GetJobSearchResultsQueryHookResult = ReturnType<typeof useGetJobSearchResultsQuery>;
export type GetJobSearchResultsLazyQueryHookResult = ReturnType<typeof useGetJobSearchResultsLazyQuery>;
export type GetJobSearchResultsQueryResult = Apollo.QueryResult<GetJobSearchResultsQuery, GetJobSearchResultsQueryVariables>;
export const GetJobSummaryDataDocument = gql`
    query GetJobSummaryData($jobId: Int!) {
  jobSummaryData(jobId: $jobId) {
    id
    partialJob {
      id
      customer {
        id
        firstName
        lastName
      }
    }
    configIds {
      id
      labels
    }
  }
}
    `;

/**
 * __useGetJobSummaryDataQuery__
 *
 * To run a query within a React component, call `useGetJobSummaryDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobSummaryDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobSummaryDataQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobSummaryDataQuery(baseOptions: Apollo.QueryHookOptions<GetJobSummaryDataQuery, GetJobSummaryDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobSummaryDataQuery, GetJobSummaryDataQueryVariables>(GetJobSummaryDataDocument, options);
      }
export function useGetJobSummaryDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobSummaryDataQuery, GetJobSummaryDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobSummaryDataQuery, GetJobSummaryDataQueryVariables>(GetJobSummaryDataDocument, options);
        }
export type GetJobSummaryDataQueryHookResult = ReturnType<typeof useGetJobSummaryDataQuery>;
export type GetJobSummaryDataLazyQueryHookResult = ReturnType<typeof useGetJobSummaryDataLazyQuery>;
export type GetJobSummaryDataQueryResult = Apollo.QueryResult<GetJobSummaryDataQuery, GetJobSummaryDataQueryVariables>;
export const GetMaterialOptionsDocument = gql`
    query GetMaterialOptions($jobServiceId: Int!, $productTypeId: Int!) {
  materialOptions(jobServiceId: $jobServiceId, productTypeId: $productTypeId) {
    materialCategoryId
    productTypeId
    materialName
    materialPriceUnit
    defaultAmount
    isDefaultMaterial
  }
}
    `;

/**
 * __useGetMaterialOptionsQuery__
 *
 * To run a query within a React component, call `useGetMaterialOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaterialOptionsQuery({
 *   variables: {
 *      jobServiceId: // value for 'jobServiceId'
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useGetMaterialOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetMaterialOptionsQuery, GetMaterialOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaterialOptionsQuery, GetMaterialOptionsQueryVariables>(GetMaterialOptionsDocument, options);
      }
export function useGetMaterialOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaterialOptionsQuery, GetMaterialOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaterialOptionsQuery, GetMaterialOptionsQueryVariables>(GetMaterialOptionsDocument, options);
        }
export type GetMaterialOptionsQueryHookResult = ReturnType<typeof useGetMaterialOptionsQuery>;
export type GetMaterialOptionsLazyQueryHookResult = ReturnType<typeof useGetMaterialOptionsLazyQuery>;
export type GetMaterialOptionsQueryResult = Apollo.QueryResult<GetMaterialOptionsQuery, GetMaterialOptionsQueryVariables>;
export const GetMsrpScalarForConfigurationDocument = gql`
    query GetMsrpScalarForConfiguration($jobConfigurationId: Int!) {
  msrpScalarForConfiguration(jobConfigurationId: $jobConfigurationId)
}
    `;

/**
 * __useGetMsrpScalarForConfigurationQuery__
 *
 * To run a query within a React component, call `useGetMsrpScalarForConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsrpScalarForConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsrpScalarForConfigurationQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetMsrpScalarForConfigurationQuery(baseOptions: Apollo.QueryHookOptions<GetMsrpScalarForConfigurationQuery, GetMsrpScalarForConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMsrpScalarForConfigurationQuery, GetMsrpScalarForConfigurationQueryVariables>(GetMsrpScalarForConfigurationDocument, options);
      }
export function useGetMsrpScalarForConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMsrpScalarForConfigurationQuery, GetMsrpScalarForConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMsrpScalarForConfigurationQuery, GetMsrpScalarForConfigurationQueryVariables>(GetMsrpScalarForConfigurationDocument, options);
        }
export type GetMsrpScalarForConfigurationQueryHookResult = ReturnType<typeof useGetMsrpScalarForConfigurationQuery>;
export type GetMsrpScalarForConfigurationLazyQueryHookResult = ReturnType<typeof useGetMsrpScalarForConfigurationLazyQuery>;
export type GetMsrpScalarForConfigurationQueryResult = Apollo.QueryResult<GetMsrpScalarForConfigurationQuery, GetMsrpScalarForConfigurationQueryVariables>;
export const GetOverrideDiscountForJobDocument = gql`
    query GetOverrideDiscountForJob($jobConfigurationId: Int!) {
  overrideDiscountForJob(jobConfigurationId: $jobConfigurationId) {
    id
    jobConfigurationId
    discountId
    authorizationCode
    amount
    name
    isScalar
    isValid
  }
}
    `;

/**
 * __useGetOverrideDiscountForJobQuery__
 *
 * To run a query within a React component, call `useGetOverrideDiscountForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverrideDiscountForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverrideDiscountForJobQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetOverrideDiscountForJobQuery(baseOptions: Apollo.QueryHookOptions<GetOverrideDiscountForJobQuery, GetOverrideDiscountForJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverrideDiscountForJobQuery, GetOverrideDiscountForJobQueryVariables>(GetOverrideDiscountForJobDocument, options);
      }
export function useGetOverrideDiscountForJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverrideDiscountForJobQuery, GetOverrideDiscountForJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverrideDiscountForJobQuery, GetOverrideDiscountForJobQueryVariables>(GetOverrideDiscountForJobDocument, options);
        }
export type GetOverrideDiscountForJobQueryHookResult = ReturnType<typeof useGetOverrideDiscountForJobQuery>;
export type GetOverrideDiscountForJobLazyQueryHookResult = ReturnType<typeof useGetOverrideDiscountForJobLazyQuery>;
export type GetOverrideDiscountForJobQueryResult = Apollo.QueryResult<GetOverrideDiscountForJobQuery, GetOverrideDiscountForJobQueryVariables>;
export const GetPictureDocument = gql`
    query GetPicture($pictureId: Int!) {
  picture(pictureId: $pictureId) {
    id
    uploadDate
    description
    image
  }
}
    `;

/**
 * __useGetPictureQuery__
 *
 * To run a query within a React component, call `useGetPictureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPictureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPictureQuery({
 *   variables: {
 *      pictureId: // value for 'pictureId'
 *   },
 * });
 */
export function useGetPictureQuery(baseOptions: Apollo.QueryHookOptions<GetPictureQuery, GetPictureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPictureQuery, GetPictureQueryVariables>(GetPictureDocument, options);
      }
export function useGetPictureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPictureQuery, GetPictureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPictureQuery, GetPictureQueryVariables>(GetPictureDocument, options);
        }
export type GetPictureQueryHookResult = ReturnType<typeof useGetPictureQuery>;
export type GetPictureLazyQueryHookResult = ReturnType<typeof useGetPictureLazyQuery>;
export type GetPictureQueryResult = Apollo.QueryResult<GetPictureQuery, GetPictureQueryVariables>;
export const GetPreContractJobSummaryDataDocument = gql`
    query GetPreContractJobSummaryData($jobConfigurationId: Int!) {
  preContractJobSummaryData(jobConfigurationId: $jobConfigurationId) {
    id
    configIds
  }
}
    `;

/**
 * __useGetPreContractJobSummaryDataQuery__
 *
 * To run a query within a React component, call `useGetPreContractJobSummaryDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreContractJobSummaryDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreContractJobSummaryDataQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetPreContractJobSummaryDataQuery(baseOptions: Apollo.QueryHookOptions<GetPreContractJobSummaryDataQuery, GetPreContractJobSummaryDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreContractJobSummaryDataQuery, GetPreContractJobSummaryDataQueryVariables>(GetPreContractJobSummaryDataDocument, options);
      }
export function useGetPreContractJobSummaryDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreContractJobSummaryDataQuery, GetPreContractJobSummaryDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreContractJobSummaryDataQuery, GetPreContractJobSummaryDataQueryVariables>(GetPreContractJobSummaryDataDocument, options);
        }
export type GetPreContractJobSummaryDataQueryHookResult = ReturnType<typeof useGetPreContractJobSummaryDataQuery>;
export type GetPreContractJobSummaryDataLazyQueryHookResult = ReturnType<typeof useGetPreContractJobSummaryDataLazyQuery>;
export type GetPreContractJobSummaryDataQueryResult = Apollo.QueryResult<GetPreContractJobSummaryDataQuery, GetPreContractJobSummaryDataQueryVariables>;
export const GetPriceVerificationFieldsDocument = gql`
    query GetPriceVerificationFields($jobConfigurationId: Int!) {
  priceVerificationFields(jobConfigurationId: $jobConfigurationId) {
    id
    styles {
      id
      price
      cost
    }
    services {
      id
      price
      cost
      materialPrice
      materialCost
    }
  }
}
    `;

/**
 * __useGetPriceVerificationFieldsQuery__
 *
 * To run a query within a React component, call `useGetPriceVerificationFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceVerificationFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceVerificationFieldsQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetPriceVerificationFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetPriceVerificationFieldsQuery, GetPriceVerificationFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPriceVerificationFieldsQuery, GetPriceVerificationFieldsQueryVariables>(GetPriceVerificationFieldsDocument, options);
      }
export function useGetPriceVerificationFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPriceVerificationFieldsQuery, GetPriceVerificationFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPriceVerificationFieldsQuery, GetPriceVerificationFieldsQueryVariables>(GetPriceVerificationFieldsDocument, options);
        }
export type GetPriceVerificationFieldsQueryHookResult = ReturnType<typeof useGetPriceVerificationFieldsQuery>;
export type GetPriceVerificationFieldsLazyQueryHookResult = ReturnType<typeof useGetPriceVerificationFieldsLazyQuery>;
export type GetPriceVerificationFieldsQueryResult = Apollo.QueryResult<GetPriceVerificationFieldsQuery, GetPriceVerificationFieldsQueryVariables>;
export const GetProductStyleValidOptionsForAreaDocument = gql`
    query GetProductStyleValidOptionsForArea($areaId: Int!) {
  productStyleValidOptionsForArea(areaId: $areaId) {
    id
    areaId
    style
    valid
  }
}
    `;

/**
 * __useGetProductStyleValidOptionsForAreaQuery__
 *
 * To run a query within a React component, call `useGetProductStyleValidOptionsForAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductStyleValidOptionsForAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductStyleValidOptionsForAreaQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useGetProductStyleValidOptionsForAreaQuery(baseOptions: Apollo.QueryHookOptions<GetProductStyleValidOptionsForAreaQuery, GetProductStyleValidOptionsForAreaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductStyleValidOptionsForAreaQuery, GetProductStyleValidOptionsForAreaQueryVariables>(GetProductStyleValidOptionsForAreaDocument, options);
      }
export function useGetProductStyleValidOptionsForAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductStyleValidOptionsForAreaQuery, GetProductStyleValidOptionsForAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductStyleValidOptionsForAreaQuery, GetProductStyleValidOptionsForAreaQueryVariables>(GetProductStyleValidOptionsForAreaDocument, options);
        }
export type GetProductStyleValidOptionsForAreaQueryHookResult = ReturnType<typeof useGetProductStyleValidOptionsForAreaQuery>;
export type GetProductStyleValidOptionsForAreaLazyQueryHookResult = ReturnType<typeof useGetProductStyleValidOptionsForAreaLazyQuery>;
export type GetProductStyleValidOptionsForAreaQueryResult = Apollo.QueryResult<GetProductStyleValidOptionsForAreaQuery, GetProductStyleValidOptionsForAreaQueryVariables>;
export const GetPromotionOnJobDocument = gql`
    query GetPromotionOnJob($jobConfigurationId: Int!) {
  promotionOnJob(jobConfigurationId: $jobConfigurationId) {
    id
    basePromotion {
      id
      name
    }
    savingsAmount
  }
}
    `;

/**
 * __useGetPromotionOnJobQuery__
 *
 * To run a query within a React component, call `useGetPromotionOnJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionOnJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionOnJobQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetPromotionOnJobQuery(baseOptions: Apollo.QueryHookOptions<GetPromotionOnJobQuery, GetPromotionOnJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromotionOnJobQuery, GetPromotionOnJobQueryVariables>(GetPromotionOnJobDocument, options);
      }
export function useGetPromotionOnJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionOnJobQuery, GetPromotionOnJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromotionOnJobQuery, GetPromotionOnJobQueryVariables>(GetPromotionOnJobDocument, options);
        }
export type GetPromotionOnJobQueryHookResult = ReturnType<typeof useGetPromotionOnJobQuery>;
export type GetPromotionOnJobLazyQueryHookResult = ReturnType<typeof useGetPromotionOnJobLazyQuery>;
export type GetPromotionOnJobQueryResult = Apollo.QueryResult<GetPromotionOnJobQuery, GetPromotionOnJobQueryVariables>;
export const GetQuotePriceForJobChangesDocument = gql`
    query GetQuotePriceForJobChanges($originalJobConfigurationId: Int!, $scaledUpdatedJobConfiguration: JobConfigurationInput!) {
  quotePriceForJobChanges(originalJobConfigurationId: $originalJobConfigurationId, scaledUpdatedJobConfiguration: $scaledUpdatedJobConfiguration) {
    id
    cost
    price
  }
}
    `;

/**
 * __useGetQuotePriceForJobChangesQuery__
 *
 * To run a query within a React component, call `useGetQuotePriceForJobChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotePriceForJobChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotePriceForJobChangesQuery({
 *   variables: {
 *      originalJobConfigurationId: // value for 'originalJobConfigurationId'
 *      scaledUpdatedJobConfiguration: // value for 'scaledUpdatedJobConfiguration'
 *   },
 * });
 */
export function useGetQuotePriceForJobChangesQuery(baseOptions: Apollo.QueryHookOptions<GetQuotePriceForJobChangesQuery, GetQuotePriceForJobChangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuotePriceForJobChangesQuery, GetQuotePriceForJobChangesQueryVariables>(GetQuotePriceForJobChangesDocument, options);
      }
export function useGetQuotePriceForJobChangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotePriceForJobChangesQuery, GetQuotePriceForJobChangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuotePriceForJobChangesQuery, GetQuotePriceForJobChangesQueryVariables>(GetQuotePriceForJobChangesDocument, options);
        }
export type GetQuotePriceForJobChangesQueryHookResult = ReturnType<typeof useGetQuotePriceForJobChangesQuery>;
export type GetQuotePriceForJobChangesLazyQueryHookResult = ReturnType<typeof useGetQuotePriceForJobChangesLazyQuery>;
export type GetQuotePriceForJobChangesQueryResult = Apollo.QueryResult<GetQuotePriceForJobChangesQuery, GetQuotePriceForJobChangesQueryVariables>;
export const GetRoomLabelOptionsDocument = gql`
    query GetRoomLabelOptions {
  roomLabelOptions {
    id
    label
    fullLabel
  }
}
    `;

/**
 * __useGetRoomLabelOptionsQuery__
 *
 * To run a query within a React component, call `useGetRoomLabelOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomLabelOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomLabelOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoomLabelOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetRoomLabelOptionsQuery, GetRoomLabelOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomLabelOptionsQuery, GetRoomLabelOptionsQueryVariables>(GetRoomLabelOptionsDocument, options);
      }
export function useGetRoomLabelOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomLabelOptionsQuery, GetRoomLabelOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomLabelOptionsQuery, GetRoomLabelOptionsQueryVariables>(GetRoomLabelOptionsDocument, options);
        }
export type GetRoomLabelOptionsQueryHookResult = ReturnType<typeof useGetRoomLabelOptionsQuery>;
export type GetRoomLabelOptionsLazyQueryHookResult = ReturnType<typeof useGetRoomLabelOptionsLazyQuery>;
export type GetRoomLabelOptionsQueryResult = Apollo.QueryResult<GetRoomLabelOptionsQuery, GetRoomLabelOptionsQueryVariables>;
export const GetServiceTypeOptionsDocument = gql`
    query GetServiceTypeOptions($productTypeId: Int, $areaId: Int) {
  serviceTypeOptions(productTypeId: $productTypeId) {
    serviceTypeId
    productTypeId
    name
    displayServiceTypeId
    jobServices {
      jobServiceId
      productTypeId
      description
      laborPricingUnit
      materialOptions {
        materialCategoryId
        productTypeId
        materialName
        materialPriceUnit
        defaultAmount
        isDefaultMaterial
      }
    }
  }
  validInstallationJobServiceIds(areaId: $areaId)
}
    `;

/**
 * __useGetServiceTypeOptionsQuery__
 *
 * To run a query within a React component, call `useGetServiceTypeOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceTypeOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceTypeOptionsQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useGetServiceTypeOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceTypeOptionsQuery, GetServiceTypeOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceTypeOptionsQuery, GetServiceTypeOptionsQueryVariables>(GetServiceTypeOptionsDocument, options);
      }
export function useGetServiceTypeOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceTypeOptionsQuery, GetServiceTypeOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceTypeOptionsQuery, GetServiceTypeOptionsQueryVariables>(GetServiceTypeOptionsDocument, options);
        }
export type GetServiceTypeOptionsQueryHookResult = ReturnType<typeof useGetServiceTypeOptionsQuery>;
export type GetServiceTypeOptionsLazyQueryHookResult = ReturnType<typeof useGetServiceTypeOptionsLazyQuery>;
export type GetServiceTypeOptionsQueryResult = Apollo.QueryResult<GetServiceTypeOptionsQuery, GetServiceTypeOptionsQueryVariables>;
export const GetTestSvgDocument = gql`
    query GetTestSVG {
  testSvg
}
    `;

/**
 * __useGetTestSvgQuery__
 *
 * To run a query within a React component, call `useGetTestSvgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestSvgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestSvgQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTestSvgQuery(baseOptions?: Apollo.QueryHookOptions<GetTestSvgQuery, GetTestSvgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestSvgQuery, GetTestSvgQueryVariables>(GetTestSvgDocument, options);
      }
export function useGetTestSvgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestSvgQuery, GetTestSvgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestSvgQuery, GetTestSvgQueryVariables>(GetTestSvgDocument, options);
        }
export type GetTestSvgQueryHookResult = ReturnType<typeof useGetTestSvgQuery>;
export type GetTestSvgLazyQueryHookResult = ReturnType<typeof useGetTestSvgLazyQuery>;
export type GetTestSvgQueryResult = Apollo.QueryResult<GetTestSvgQuery, GetTestSvgQueryVariables>;
export const AddBeforeAndAfterPicturesForRoomDocument = gql`
    mutation AddBeforeAndAfterPicturesForRoom($roomId: Int!, $pictures: [PictureInput!]!, $isBefore: Boolean!) {
  addBeforeAndAfterPicturesForRoom(roomId: $roomId, pictures: $pictures, isBefore: $isBefore)
}
    `;
export type AddBeforeAndAfterPicturesForRoomMutationFn = Apollo.MutationFunction<AddBeforeAndAfterPicturesForRoomMutation, AddBeforeAndAfterPicturesForRoomMutationVariables>;

/**
 * __useAddBeforeAndAfterPicturesForRoomMutation__
 *
 * To run a mutation, you first call `useAddBeforeAndAfterPicturesForRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBeforeAndAfterPicturesForRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBeforeAndAfterPicturesForRoomMutation, { data, loading, error }] = useAddBeforeAndAfterPicturesForRoomMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      pictures: // value for 'pictures'
 *      isBefore: // value for 'isBefore'
 *   },
 * });
 */
export function useAddBeforeAndAfterPicturesForRoomMutation(baseOptions?: Apollo.MutationHookOptions<AddBeforeAndAfterPicturesForRoomMutation, AddBeforeAndAfterPicturesForRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBeforeAndAfterPicturesForRoomMutation, AddBeforeAndAfterPicturesForRoomMutationVariables>(AddBeforeAndAfterPicturesForRoomDocument, options);
      }
export type AddBeforeAndAfterPicturesForRoomMutationHookResult = ReturnType<typeof useAddBeforeAndAfterPicturesForRoomMutation>;
export type AddBeforeAndAfterPicturesForRoomMutationResult = Apollo.MutationResult<AddBeforeAndAfterPicturesForRoomMutation>;
export type AddBeforeAndAfterPicturesForRoomMutationOptions = Apollo.BaseMutationOptions<AddBeforeAndAfterPicturesForRoomMutation, AddBeforeAndAfterPicturesForRoomMutationVariables>;
export const AddContractToJobWithDepositDocument = gql`
    mutation AddContractToJobWithDeposit($jobContractDetails: AddContractToJobWithDepositMutationInput!) {
  addContractToJobWithDeposit(jobContractDetails: $jobContractDetails)
}
    `;
export type AddContractToJobWithDepositMutationFn = Apollo.MutationFunction<AddContractToJobWithDepositMutation, AddContractToJobWithDepositMutationVariables>;

/**
 * __useAddContractToJobWithDepositMutation__
 *
 * To run a mutation, you first call `useAddContractToJobWithDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContractToJobWithDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContractToJobWithDepositMutation, { data, loading, error }] = useAddContractToJobWithDepositMutation({
 *   variables: {
 *      jobContractDetails: // value for 'jobContractDetails'
 *   },
 * });
 */
export function useAddContractToJobWithDepositMutation(baseOptions?: Apollo.MutationHookOptions<AddContractToJobWithDepositMutation, AddContractToJobWithDepositMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddContractToJobWithDepositMutation, AddContractToJobWithDepositMutationVariables>(AddContractToJobWithDepositDocument, options);
      }
export type AddContractToJobWithDepositMutationHookResult = ReturnType<typeof useAddContractToJobWithDepositMutation>;
export type AddContractToJobWithDepositMutationResult = Apollo.MutationResult<AddContractToJobWithDepositMutation>;
export type AddContractToJobWithDepositMutationOptions = Apollo.BaseMutationOptions<AddContractToJobWithDepositMutation, AddContractToJobWithDepositMutationVariables>;
export const AddPictureDocument = gql`
    mutation AddPicture($picture: String!, $description: String!) {
  addPicture(picture: $picture, description: $description)
}
    `;
export type AddPictureMutationFn = Apollo.MutationFunction<AddPictureMutation, AddPictureMutationVariables>;

/**
 * __useAddPictureMutation__
 *
 * To run a mutation, you first call `useAddPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPictureMutation, { data, loading, error }] = useAddPictureMutation({
 *   variables: {
 *      picture: // value for 'picture'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddPictureMutation(baseOptions?: Apollo.MutationHookOptions<AddPictureMutation, AddPictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPictureMutation, AddPictureMutationVariables>(AddPictureDocument, options);
      }
export type AddPictureMutationHookResult = ReturnType<typeof useAddPictureMutation>;
export type AddPictureMutationResult = Apollo.MutationResult<AddPictureMutation>;
export type AddPictureMutationOptions = Apollo.BaseMutationOptions<AddPictureMutation, AddPictureMutationVariables>;
export const AddPicturesForRoomDocument = gql`
    mutation AddPicturesForRoom($roomId: Int!, $pictures: [PictureInput!]!) {
  addPicturesForRoom(roomId: $roomId, pictures: $pictures)
}
    `;
export type AddPicturesForRoomMutationFn = Apollo.MutationFunction<AddPicturesForRoomMutation, AddPicturesForRoomMutationVariables>;

/**
 * __useAddPicturesForRoomMutation__
 *
 * To run a mutation, you first call `useAddPicturesForRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPicturesForRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPicturesForRoomMutation, { data, loading, error }] = useAddPicturesForRoomMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      pictures: // value for 'pictures'
 *   },
 * });
 */
export function useAddPicturesForRoomMutation(baseOptions?: Apollo.MutationHookOptions<AddPicturesForRoomMutation, AddPicturesForRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPicturesForRoomMutation, AddPicturesForRoomMutationVariables>(AddPicturesForRoomDocument, options);
      }
export type AddPicturesForRoomMutationHookResult = ReturnType<typeof useAddPicturesForRoomMutation>;
export type AddPicturesForRoomMutationResult = Apollo.MutationResult<AddPicturesForRoomMutation>;
export type AddPicturesForRoomMutationOptions = Apollo.BaseMutationOptions<AddPicturesForRoomMutation, AddPicturesForRoomMutationVariables>;
export const CreateJobWithNewCustomerDocument = gql`
    mutation CreateJobWithNewCustomer($customer: CustomerInput!) {
  createJobWithNewCustomer(newCustomer: $customer) {
    id
    jobId
  }
}
    `;
export type CreateJobWithNewCustomerMutationFn = Apollo.MutationFunction<CreateJobWithNewCustomerMutation, CreateJobWithNewCustomerMutationVariables>;

/**
 * __useCreateJobWithNewCustomerMutation__
 *
 * To run a mutation, you first call `useCreateJobWithNewCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobWithNewCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobWithNewCustomerMutation, { data, loading, error }] = useCreateJobWithNewCustomerMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useCreateJobWithNewCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobWithNewCustomerMutation, CreateJobWithNewCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobWithNewCustomerMutation, CreateJobWithNewCustomerMutationVariables>(CreateJobWithNewCustomerDocument, options);
      }
export type CreateJobWithNewCustomerMutationHookResult = ReturnType<typeof useCreateJobWithNewCustomerMutation>;
export type CreateJobWithNewCustomerMutationResult = Apollo.MutationResult<CreateJobWithNewCustomerMutation>;
export type CreateJobWithNewCustomerMutationOptions = Apollo.BaseMutationOptions<CreateJobWithNewCustomerMutation, CreateJobWithNewCustomerMutationVariables>;
export const DeleteBeforeAndAfterPictureForRoomDocument = gql`
    mutation DeleteBeforeAndAfterPictureForRoom($beforeAndAfterPictureForRoomId: Int!) {
  deleteBeforeAndAfterPictureForRoom(beforeAndAfterPictureForRoomId: $beforeAndAfterPictureForRoomId)
}
    `;
export type DeleteBeforeAndAfterPictureForRoomMutationFn = Apollo.MutationFunction<DeleteBeforeAndAfterPictureForRoomMutation, DeleteBeforeAndAfterPictureForRoomMutationVariables>;

/**
 * __useDeleteBeforeAndAfterPictureForRoomMutation__
 *
 * To run a mutation, you first call `useDeleteBeforeAndAfterPictureForRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBeforeAndAfterPictureForRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBeforeAndAfterPictureForRoomMutation, { data, loading, error }] = useDeleteBeforeAndAfterPictureForRoomMutation({
 *   variables: {
 *      beforeAndAfterPictureForRoomId: // value for 'beforeAndAfterPictureForRoomId'
 *   },
 * });
 */
export function useDeleteBeforeAndAfterPictureForRoomMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBeforeAndAfterPictureForRoomMutation, DeleteBeforeAndAfterPictureForRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBeforeAndAfterPictureForRoomMutation, DeleteBeforeAndAfterPictureForRoomMutationVariables>(DeleteBeforeAndAfterPictureForRoomDocument, options);
      }
export type DeleteBeforeAndAfterPictureForRoomMutationHookResult = ReturnType<typeof useDeleteBeforeAndAfterPictureForRoomMutation>;
export type DeleteBeforeAndAfterPictureForRoomMutationResult = Apollo.MutationResult<DeleteBeforeAndAfterPictureForRoomMutation>;
export type DeleteBeforeAndAfterPictureForRoomMutationOptions = Apollo.BaseMutationOptions<DeleteBeforeAndAfterPictureForRoomMutation, DeleteBeforeAndAfterPictureForRoomMutationVariables>;
export const DeleteJobConfigurationDocument = gql`
    mutation DeleteJobConfiguration($jobConfigurationId: Int!) {
  deleteJobConfiguration(jobConfigurationId: $jobConfigurationId)
}
    `;
export type DeleteJobConfigurationMutationFn = Apollo.MutationFunction<DeleteJobConfigurationMutation, DeleteJobConfigurationMutationVariables>;

/**
 * __useDeleteJobConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteJobConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobConfigurationMutation, { data, loading, error }] = useDeleteJobConfigurationMutation({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useDeleteJobConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobConfigurationMutation, DeleteJobConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJobConfigurationMutation, DeleteJobConfigurationMutationVariables>(DeleteJobConfigurationDocument, options);
      }
export type DeleteJobConfigurationMutationHookResult = ReturnType<typeof useDeleteJobConfigurationMutation>;
export type DeleteJobConfigurationMutationResult = Apollo.MutationResult<DeleteJobConfigurationMutation>;
export type DeleteJobConfigurationMutationOptions = Apollo.BaseMutationOptions<DeleteJobConfigurationMutation, DeleteJobConfigurationMutationVariables>;
export const DeletePictureForRoomDocument = gql`
    mutation DeletePictureForRoom($pictureForRoomId: Int!) {
  deletePictureForRoom(pictureForRoomId: $pictureForRoomId)
}
    `;
export type DeletePictureForRoomMutationFn = Apollo.MutationFunction<DeletePictureForRoomMutation, DeletePictureForRoomMutationVariables>;

/**
 * __useDeletePictureForRoomMutation__
 *
 * To run a mutation, you first call `useDeletePictureForRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePictureForRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePictureForRoomMutation, { data, loading, error }] = useDeletePictureForRoomMutation({
 *   variables: {
 *      pictureForRoomId: // value for 'pictureForRoomId'
 *   },
 * });
 */
export function useDeletePictureForRoomMutation(baseOptions?: Apollo.MutationHookOptions<DeletePictureForRoomMutation, DeletePictureForRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePictureForRoomMutation, DeletePictureForRoomMutationVariables>(DeletePictureForRoomDocument, options);
      }
export type DeletePictureForRoomMutationHookResult = ReturnType<typeof useDeletePictureForRoomMutation>;
export type DeletePictureForRoomMutationResult = Apollo.MutationResult<DeletePictureForRoomMutation>;
export type DeletePictureForRoomMutationOptions = Apollo.BaseMutationOptions<DeletePictureForRoomMutation, DeletePictureForRoomMutationVariables>;
export const EmailContractDocument = gql`
    mutation EmailContract($contractId: Int!, $emailAddresses: [String!]!, $updatePrimaryEmail: Boolean!) {
  emailContract(contractId: $contractId, emailAddresses: $emailAddresses, updatePrimaryEmail: $updatePrimaryEmail)
}
    `;
export type EmailContractMutationFn = Apollo.MutationFunction<EmailContractMutation, EmailContractMutationVariables>;

/**
 * __useEmailContractMutation__
 *
 * To run a mutation, you first call `useEmailContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailContractMutation, { data, loading, error }] = useEmailContractMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      emailAddresses: // value for 'emailAddresses'
 *      updatePrimaryEmail: // value for 'updatePrimaryEmail'
 *   },
 * });
 */
export function useEmailContractMutation(baseOptions?: Apollo.MutationHookOptions<EmailContractMutation, EmailContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailContractMutation, EmailContractMutationVariables>(EmailContractDocument, options);
      }
export type EmailContractMutationHookResult = ReturnType<typeof useEmailContractMutation>;
export type EmailContractMutationResult = Apollo.MutationResult<EmailContractMutation>;
export type EmailContractMutationOptions = Apollo.BaseMutationOptions<EmailContractMutation, EmailContractMutationVariables>;
export const EmailQuoteDocument = gql`
    mutation EmailQuote($quoteId: Int!, $emailAddresses: [String!]!, $updatePrimaryEmail: Boolean!) {
  emailQuote(quoteId: $quoteId, emailAddresses: $emailAddresses, updatePrimaryEmail: $updatePrimaryEmail)
}
    `;
export type EmailQuoteMutationFn = Apollo.MutationFunction<EmailQuoteMutation, EmailQuoteMutationVariables>;

/**
 * __useEmailQuoteMutation__
 *
 * To run a mutation, you first call `useEmailQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailQuoteMutation, { data, loading, error }] = useEmailQuoteMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *      emailAddresses: // value for 'emailAddresses'
 *      updatePrimaryEmail: // value for 'updatePrimaryEmail'
 *   },
 * });
 */
export function useEmailQuoteMutation(baseOptions?: Apollo.MutationHookOptions<EmailQuoteMutation, EmailQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailQuoteMutation, EmailQuoteMutationVariables>(EmailQuoteDocument, options);
      }
export type EmailQuoteMutationHookResult = ReturnType<typeof useEmailQuoteMutation>;
export type EmailQuoteMutationResult = Apollo.MutationResult<EmailQuoteMutation>;
export type EmailQuoteMutationOptions = Apollo.BaseMutationOptions<EmailQuoteMutation, EmailQuoteMutationVariables>;
export const ReassignRoomDocument = gql`
    mutation ReassignRoom($roomId: Int!, $areaId: Int) {
  reassignRoom(roomId: $roomId, areaId: $areaId)
}
    `;
export type ReassignRoomMutationFn = Apollo.MutationFunction<ReassignRoomMutation, ReassignRoomMutationVariables>;

/**
 * __useReassignRoomMutation__
 *
 * To run a mutation, you first call `useReassignRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignRoomMutation, { data, loading, error }] = useReassignRoomMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useReassignRoomMutation(baseOptions?: Apollo.MutationHookOptions<ReassignRoomMutation, ReassignRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReassignRoomMutation, ReassignRoomMutationVariables>(ReassignRoomDocument, options);
      }
export type ReassignRoomMutationHookResult = ReturnType<typeof useReassignRoomMutation>;
export type ReassignRoomMutationResult = Apollo.MutationResult<ReassignRoomMutation>;
export type ReassignRoomMutationOptions = Apollo.BaseMutationOptions<ReassignRoomMutation, ReassignRoomMutationVariables>;
export const ToggleSeamDirectionDocument = gql`
    mutation ToggleSeamDirection($roomId: Int!, $jobConfigurationId: Int!) {
  toggleSeamDirection(roomId: $roomId, jobConfigurationId: $jobConfigurationId)
}
    `;
export type ToggleSeamDirectionMutationFn = Apollo.MutationFunction<ToggleSeamDirectionMutation, ToggleSeamDirectionMutationVariables>;

/**
 * __useToggleSeamDirectionMutation__
 *
 * To run a mutation, you first call `useToggleSeamDirectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSeamDirectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSeamDirectionMutation, { data, loading, error }] = useToggleSeamDirectionMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useToggleSeamDirectionMutation(baseOptions?: Apollo.MutationHookOptions<ToggleSeamDirectionMutation, ToggleSeamDirectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleSeamDirectionMutation, ToggleSeamDirectionMutationVariables>(ToggleSeamDirectionDocument, options);
      }
export type ToggleSeamDirectionMutationHookResult = ReturnType<typeof useToggleSeamDirectionMutation>;
export type ToggleSeamDirectionMutationResult = Apollo.MutationResult<ToggleSeamDirectionMutation>;
export type ToggleSeamDirectionMutationOptions = Apollo.BaseMutationOptions<ToggleSeamDirectionMutation, ToggleSeamDirectionMutationVariables>;
export const UpdateAreaDocument = gql`
    mutation UpdateArea($area: AreaInput!) {
  updateArea(area: $area)
}
    `;
export type UpdateAreaMutationFn = Apollo.MutationFunction<UpdateAreaMutation, UpdateAreaMutationVariables>;

/**
 * __useUpdateAreaMutation__
 *
 * To run a mutation, you first call `useUpdateAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaMutation, { data, loading, error }] = useUpdateAreaMutation({
 *   variables: {
 *      area: // value for 'area'
 *   },
 * });
 */
export function useUpdateAreaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAreaMutation, UpdateAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAreaMutation, UpdateAreaMutationVariables>(UpdateAreaDocument, options);
      }
export type UpdateAreaMutationHookResult = ReturnType<typeof useUpdateAreaMutation>;
export type UpdateAreaMutationResult = Apollo.MutationResult<UpdateAreaMutation>;
export type UpdateAreaMutationOptions = Apollo.BaseMutationOptions<UpdateAreaMutation, UpdateAreaMutationVariables>;
export const UpdateAreaProductColorSelectionDocument = gql`
    mutation UpdateAreaProductColorSelection($areaId: Int!, $colorId: Int) {
  updateAreaProductColorSelection(areaId: $areaId, colorId: $colorId)
}
    `;
export type UpdateAreaProductColorSelectionMutationFn = Apollo.MutationFunction<UpdateAreaProductColorSelectionMutation, UpdateAreaProductColorSelectionMutationVariables>;

/**
 * __useUpdateAreaProductColorSelectionMutation__
 *
 * To run a mutation, you first call `useUpdateAreaProductColorSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaProductColorSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaProductColorSelectionMutation, { data, loading, error }] = useUpdateAreaProductColorSelectionMutation({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      colorId: // value for 'colorId'
 *   },
 * });
 */
export function useUpdateAreaProductColorSelectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAreaProductColorSelectionMutation, UpdateAreaProductColorSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAreaProductColorSelectionMutation, UpdateAreaProductColorSelectionMutationVariables>(UpdateAreaProductColorSelectionDocument, options);
      }
export type UpdateAreaProductColorSelectionMutationHookResult = ReturnType<typeof useUpdateAreaProductColorSelectionMutation>;
export type UpdateAreaProductColorSelectionMutationResult = Apollo.MutationResult<UpdateAreaProductColorSelectionMutation>;
export type UpdateAreaProductColorSelectionMutationOptions = Apollo.BaseMutationOptions<UpdateAreaProductColorSelectionMutation, UpdateAreaProductColorSelectionMutationVariables>;
export const UpdateAreaProductStyleSelectionDocument = gql`
    mutation UpdateAreaProductStyleSelection($areaId: Int!, $styleId: Int, $preventUpdatingProductInCart: Boolean) {
  updateAreaProductStyleSelection(areaId: $areaId, styleId: $styleId, preventUpdatingProductInCart: $preventUpdatingProductInCart)
}
    `;
export type UpdateAreaProductStyleSelectionMutationFn = Apollo.MutationFunction<UpdateAreaProductStyleSelectionMutation, UpdateAreaProductStyleSelectionMutationVariables>;

/**
 * __useUpdateAreaProductStyleSelectionMutation__
 *
 * To run a mutation, you first call `useUpdateAreaProductStyleSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaProductStyleSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaProductStyleSelectionMutation, { data, loading, error }] = useUpdateAreaProductStyleSelectionMutation({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      styleId: // value for 'styleId'
 *      preventUpdatingProductInCart: // value for 'preventUpdatingProductInCart'
 *   },
 * });
 */
export function useUpdateAreaProductStyleSelectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAreaProductStyleSelectionMutation, UpdateAreaProductStyleSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAreaProductStyleSelectionMutation, UpdateAreaProductStyleSelectionMutationVariables>(UpdateAreaProductStyleSelectionDocument, options);
      }
export type UpdateAreaProductStyleSelectionMutationHookResult = ReturnType<typeof useUpdateAreaProductStyleSelectionMutation>;
export type UpdateAreaProductStyleSelectionMutationResult = Apollo.MutationResult<UpdateAreaProductStyleSelectionMutation>;
export type UpdateAreaProductStyleSelectionMutationOptions = Apollo.BaseMutationOptions<UpdateAreaProductStyleSelectionMutation, UpdateAreaProductStyleSelectionMutationVariables>;
export const UpdateAreaProductTypeDocument = gql`
    mutation UpdateAreaProductType($areaId: Int!, $productTypeId: Int) {
  updateAreaProductType(areaId: $areaId, productTypeId: $productTypeId)
}
    `;
export type UpdateAreaProductTypeMutationFn = Apollo.MutationFunction<UpdateAreaProductTypeMutation, UpdateAreaProductTypeMutationVariables>;

/**
 * __useUpdateAreaProductTypeMutation__
 *
 * To run a mutation, you first call `useUpdateAreaProductTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaProductTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaProductTypeMutation, { data, loading, error }] = useUpdateAreaProductTypeMutation({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useUpdateAreaProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAreaProductTypeMutation, UpdateAreaProductTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAreaProductTypeMutation, UpdateAreaProductTypeMutationVariables>(UpdateAreaProductTypeDocument, options);
      }
export type UpdateAreaProductTypeMutationHookResult = ReturnType<typeof useUpdateAreaProductTypeMutation>;
export type UpdateAreaProductTypeMutationResult = Apollo.MutationResult<UpdateAreaProductTypeMutation>;
export type UpdateAreaProductTypeMutationOptions = Apollo.BaseMutationOptions<UpdateAreaProductTypeMutation, UpdateAreaProductTypeMutationVariables>;
export const UpdateChargeablesDocument = gql`
    mutation UpdateChargeables($jobConfigurationId: Int!, $upsertedServices: [ServiceForRoomInput!]!, $deletedServiceIds: [Int!]!, $upsertedCustomServices: [CustomServiceInput!]!, $deletedCustomServiceIds: [Int!]!) {
  updateChargeables(jobConfigurationId: $jobConfigurationId, upsertedServices: $upsertedServices, deletedServiceIds: $deletedServiceIds, upsertedCustomServices: $upsertedCustomServices, deletedCustomServiceIds: $deletedCustomServiceIds)
}
    `;
export type UpdateChargeablesMutationFn = Apollo.MutationFunction<UpdateChargeablesMutation, UpdateChargeablesMutationVariables>;

/**
 * __useUpdateChargeablesMutation__
 *
 * To run a mutation, you first call `useUpdateChargeablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargeablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargeablesMutation, { data, loading, error }] = useUpdateChargeablesMutation({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      upsertedServices: // value for 'upsertedServices'
 *      deletedServiceIds: // value for 'deletedServiceIds'
 *      upsertedCustomServices: // value for 'upsertedCustomServices'
 *      deletedCustomServiceIds: // value for 'deletedCustomServiceIds'
 *   },
 * });
 */
export function useUpdateChargeablesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChargeablesMutation, UpdateChargeablesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChargeablesMutation, UpdateChargeablesMutationVariables>(UpdateChargeablesDocument, options);
      }
export type UpdateChargeablesMutationHookResult = ReturnType<typeof useUpdateChargeablesMutation>;
export type UpdateChargeablesMutationResult = Apollo.MutationResult<UpdateChargeablesMutation>;
export type UpdateChargeablesMutationOptions = Apollo.BaseMutationOptions<UpdateChargeablesMutation, UpdateChargeablesMutationVariables>;
export const UpdateJobFinancingOptionDocument = gql`
    mutation UpdateJobFinancingOption($jobConfigurationId: Int!, $financingOptionId: Int!) {
  updateJobFinancingOption(jobConfigurationId: $jobConfigurationId, financingOptionId: $financingOptionId)
}
    `;
export type UpdateJobFinancingOptionMutationFn = Apollo.MutationFunction<UpdateJobFinancingOptionMutation, UpdateJobFinancingOptionMutationVariables>;

/**
 * __useUpdateJobFinancingOptionMutation__
 *
 * To run a mutation, you first call `useUpdateJobFinancingOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobFinancingOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobFinancingOptionMutation, { data, loading, error }] = useUpdateJobFinancingOptionMutation({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      financingOptionId: // value for 'financingOptionId'
 *   },
 * });
 */
export function useUpdateJobFinancingOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobFinancingOptionMutation, UpdateJobFinancingOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobFinancingOptionMutation, UpdateJobFinancingOptionMutationVariables>(UpdateJobFinancingOptionDocument, options);
      }
export type UpdateJobFinancingOptionMutationHookResult = ReturnType<typeof useUpdateJobFinancingOptionMutation>;
export type UpdateJobFinancingOptionMutationResult = Apollo.MutationResult<UpdateJobFinancingOptionMutation>;
export type UpdateJobFinancingOptionMutationOptions = Apollo.BaseMutationOptions<UpdateJobFinancingOptionMutation, UpdateJobFinancingOptionMutationVariables>;
export const UpdateProductInCartDocument = gql`
    mutation UpdateProductInCart($areaId: Int!, $inCartStatus: Boolean!) {
  updateProductInCart(areaId: $areaId, inCartStatus: $inCartStatus)
}
    `;
export type UpdateProductInCartMutationFn = Apollo.MutationFunction<UpdateProductInCartMutation, UpdateProductInCartMutationVariables>;

/**
 * __useUpdateProductInCartMutation__
 *
 * To run a mutation, you first call `useUpdateProductInCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductInCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductInCartMutation, { data, loading, error }] = useUpdateProductInCartMutation({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      inCartStatus: // value for 'inCartStatus'
 *   },
 * });
 */
export function useUpdateProductInCartMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductInCartMutation, UpdateProductInCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductInCartMutation, UpdateProductInCartMutationVariables>(UpdateProductInCartDocument, options);
      }
export type UpdateProductInCartMutationHookResult = ReturnType<typeof useUpdateProductInCartMutation>;
export type UpdateProductInCartMutationResult = Apollo.MutationResult<UpdateProductInCartMutation>;
export type UpdateProductInCartMutationOptions = Apollo.BaseMutationOptions<UpdateProductInCartMutation, UpdateProductInCartMutationVariables>;
export const UpdateUnderlaymentInAreaDocument = gql`
    mutation UpdateUnderlaymentInArea($areaId: Int!, $materialCategoryId: Int) {
  updateUnderlaymentInArea(areaId: $areaId, materialCategoryId: $materialCategoryId)
}
    `;
export type UpdateUnderlaymentInAreaMutationFn = Apollo.MutationFunction<UpdateUnderlaymentInAreaMutation, UpdateUnderlaymentInAreaMutationVariables>;

/**
 * __useUpdateUnderlaymentInAreaMutation__
 *
 * To run a mutation, you first call `useUpdateUnderlaymentInAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnderlaymentInAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnderlaymentInAreaMutation, { data, loading, error }] = useUpdateUnderlaymentInAreaMutation({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      materialCategoryId: // value for 'materialCategoryId'
 *   },
 * });
 */
export function useUpdateUnderlaymentInAreaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnderlaymentInAreaMutation, UpdateUnderlaymentInAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnderlaymentInAreaMutation, UpdateUnderlaymentInAreaMutationVariables>(UpdateUnderlaymentInAreaDocument, options);
      }
export type UpdateUnderlaymentInAreaMutationHookResult = ReturnType<typeof useUpdateUnderlaymentInAreaMutation>;
export type UpdateUnderlaymentInAreaMutationResult = Apollo.MutationResult<UpdateUnderlaymentInAreaMutation>;
export type UpdateUnderlaymentInAreaMutationOptions = Apollo.BaseMutationOptions<UpdateUnderlaymentInAreaMutation, UpdateUnderlaymentInAreaMutationVariables>;
export const UpsertJobConfigurationDocument = gql`
    mutation UpsertJobConfiguration($jobConfiguration: JobConfigurationInput!, $saveAsNew: Boolean!) {
  upsertJobConfiguration(jobConfiguration: $jobConfiguration, saveAsNew: $saveAsNew) {
    id
    jobId
  }
}
    `;
export type UpsertJobConfigurationMutationFn = Apollo.MutationFunction<UpsertJobConfigurationMutation, UpsertJobConfigurationMutationVariables>;

/**
 * __useUpsertJobConfigurationMutation__
 *
 * To run a mutation, you first call `useUpsertJobConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobConfigurationMutation, { data, loading, error }] = useUpsertJobConfigurationMutation({
 *   variables: {
 *      jobConfiguration: // value for 'jobConfiguration'
 *      saveAsNew: // value for 'saveAsNew'
 *   },
 * });
 */
export function useUpsertJobConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertJobConfigurationMutation, UpsertJobConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertJobConfigurationMutation, UpsertJobConfigurationMutationVariables>(UpsertJobConfigurationDocument, options);
      }
export type UpsertJobConfigurationMutationHookResult = ReturnType<typeof useUpsertJobConfigurationMutation>;
export type UpsertJobConfigurationMutationResult = Apollo.MutationResult<UpsertJobConfigurationMutation>;
export type UpsertJobConfigurationMutationOptions = Apollo.BaseMutationOptions<UpsertJobConfigurationMutation, UpsertJobConfigurationMutationVariables>;
export const DiscountUpdatedDocument = gql`
    subscription DiscountUpdated($jobConfigurationId: Int!) {
  discountUpdated(jobConfigurationId: $jobConfigurationId) {
    id
    discount {
      id
      jobConfigurationId
      discountId
      authorizationCode
      amount
      name
      isScalar
      isValid
    }
    isRemoval
  }
}
    `;

/**
 * __useDiscountUpdatedSubscription__
 *
 * To run a query within a React component, call `useDiscountUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDiscountUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountUpdatedSubscription({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useDiscountUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<DiscountUpdatedSubscription, DiscountUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DiscountUpdatedSubscription, DiscountUpdatedSubscriptionVariables>(DiscountUpdatedDocument, options);
      }
export type DiscountUpdatedSubscriptionHookResult = ReturnType<typeof useDiscountUpdatedSubscription>;
export type DiscountUpdatedSubscriptionResult = Apollo.SubscriptionResult<DiscountUpdatedSubscription>;
export const FinancingSlotChangedDocument = gql`
    subscription FinancingSlotChanged($jobConfigurationId: Int!) {
  financingSlotChanged(jobConfigurationId: $jobConfigurationId) {
    id
    term
    minAmount
    typeId
    typeName
    fee
    startDate
    endDate
    tranCode
    promoParentTranCode
    slot
  }
}
    `;

/**
 * __useFinancingSlotChangedSubscription__
 *
 * To run a query within a React component, call `useFinancingSlotChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFinancingSlotChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancingSlotChangedSubscription({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useFinancingSlotChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<FinancingSlotChangedSubscription, FinancingSlotChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<FinancingSlotChangedSubscription, FinancingSlotChangedSubscriptionVariables>(FinancingSlotChangedDocument, options);
      }
export type FinancingSlotChangedSubscriptionHookResult = ReturnType<typeof useFinancingSlotChangedSubscription>;
export type FinancingSlotChangedSubscriptionResult = Apollo.SubscriptionResult<FinancingSlotChangedSubscription>;
export const FinancingUpdatedDocument = gql`
    subscription FinancingUpdated($jobConfigurationId: Int!) {
  financingUpdated(jobConfigurationId: $jobConfigurationId) {
    id
    financingAccountNumber
    financingDownPayment
    perMonthAmount
    financingOption {
      id
      term
      minAmount
      typeId
      fee
      startDate
      endDate
      tranCode
      promoParentTranCode
      slot
    }
  }
}
    `;

/**
 * __useFinancingUpdatedSubscription__
 *
 * To run a query within a React component, call `useFinancingUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFinancingUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancingUpdatedSubscription({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useFinancingUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<FinancingUpdatedSubscription, FinancingUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<FinancingUpdatedSubscription, FinancingUpdatedSubscriptionVariables>(FinancingUpdatedDocument, options);
      }
export type FinancingUpdatedSubscriptionHookResult = ReturnType<typeof useFinancingUpdatedSubscription>;
export type FinancingUpdatedSubscriptionResult = Apollo.SubscriptionResult<FinancingUpdatedSubscription>;
export const JobConfigurationPriceUpdatedDocument = gql`
    subscription JobConfigurationPriceUpdated($jobConfigurationId: Int!) {
  jobConfigurationPriceUpdated(jobConfigurationId: $jobConfigurationId) {
    id
    preOverridePrice
    overallPrice {
      mSRP
      savings
      total
    }
  }
}
    `;

/**
 * __useJobConfigurationPriceUpdatedSubscription__
 *
 * To run a query within a React component, call `useJobConfigurationPriceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useJobConfigurationPriceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobConfigurationPriceUpdatedSubscription({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useJobConfigurationPriceUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<JobConfigurationPriceUpdatedSubscription, JobConfigurationPriceUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<JobConfigurationPriceUpdatedSubscription, JobConfigurationPriceUpdatedSubscriptionVariables>(JobConfigurationPriceUpdatedDocument, options);
      }
export type JobConfigurationPriceUpdatedSubscriptionHookResult = ReturnType<typeof useJobConfigurationPriceUpdatedSubscription>;
export type JobConfigurationPriceUpdatedSubscriptionResult = Apollo.SubscriptionResult<JobConfigurationPriceUpdatedSubscription>;
export const JobConfigurationUpdatedDocument = gql`
    subscription JobConfigurationUpdated($jobConfigurationId: Int!) {
  jobConfigurationUpdated(jobConfigurationId: $jobConfigurationId) {
    id
    jobId
    areas {
      id
      productTypeId
      productTypeName
      styleId
      price {
        mSRP
        savings
        total
      }
      rooms {
        id
        labels {
          id
          customName
          hasCloset
          labelNumber
          label {
            id
            label
            fullLabel
          }
          roomId
        }
        steps {
          id
          stepDetailId
          roomId
          amount
          length
          width
          isTreadOnly
          isLanding
          shouldPromptAboutSpindles
        }
      }
      includedInQuote
      preferredCarpetLength
      oppositeCarpetLength
    }
    price {
      mSRP
      savings
      total
    }
    discounts {
      id
      isOverrideApplied
      discounts {
        id
        amount
        discountId
        isScalar
        isValid
        jobConfigurationId
        name
      }
    }
  }
}
    `;

/**
 * __useJobConfigurationUpdatedSubscription__
 *
 * To run a query within a React component, call `useJobConfigurationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useJobConfigurationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobConfigurationUpdatedSubscription({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useJobConfigurationUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<JobConfigurationUpdatedSubscription, JobConfigurationUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<JobConfigurationUpdatedSubscription, JobConfigurationUpdatedSubscriptionVariables>(JobConfigurationUpdatedDocument, options);
      }
export type JobConfigurationUpdatedSubscriptionHookResult = ReturnType<typeof useJobConfigurationUpdatedSubscription>;
export type JobConfigurationUpdatedSubscriptionResult = Apollo.SubscriptionResult<JobConfigurationUpdatedSubscription>;
export const PromotionOnJobUpdatedDocument = gql`
    subscription PromotionOnJobUpdated($jobConfigurationId: Int!) {
  promotionOnJobUpdated(jobConfigurationId: $jobConfigurationId) {
    id
    promotion {
      id
      basePromotion {
        id
        name
      }
      savingsAmount
    }
    isRemoval
  }
}
    `;

/**
 * __usePromotionOnJobUpdatedSubscription__
 *
 * To run a query within a React component, call `usePromotionOnJobUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePromotionOnJobUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionOnJobUpdatedSubscription({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function usePromotionOnJobUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<PromotionOnJobUpdatedSubscription, PromotionOnJobUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PromotionOnJobUpdatedSubscription, PromotionOnJobUpdatedSubscriptionVariables>(PromotionOnJobUpdatedDocument, options);
      }
export type PromotionOnJobUpdatedSubscriptionHookResult = ReturnType<typeof usePromotionOnJobUpdatedSubscription>;
export type PromotionOnJobUpdatedSubscriptionResult = Apollo.SubscriptionResult<PromotionOnJobUpdatedSubscription>;
export const GetAllQuotesWithCustomerDocument = gql`
    query GetAllQuotesWithCustomer {
  allQuotesWithCustomer {
    id
    quote {
      id
      quoteNumber
    }
    customer {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetAllQuotesWithCustomerQuery__
 *
 * To run a query within a React component, call `useGetAllQuotesWithCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllQuotesWithCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllQuotesWithCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllQuotesWithCustomerQuery(baseOptions?: Apollo.QueryHookOptions<GetAllQuotesWithCustomerQuery, GetAllQuotesWithCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllQuotesWithCustomerQuery, GetAllQuotesWithCustomerQueryVariables>(GetAllQuotesWithCustomerDocument, options);
      }
export function useGetAllQuotesWithCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllQuotesWithCustomerQuery, GetAllQuotesWithCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllQuotesWithCustomerQuery, GetAllQuotesWithCustomerQueryVariables>(GetAllQuotesWithCustomerDocument, options);
        }
export type GetAllQuotesWithCustomerQueryHookResult = ReturnType<typeof useGetAllQuotesWithCustomerQuery>;
export type GetAllQuotesWithCustomerLazyQueryHookResult = ReturnType<typeof useGetAllQuotesWithCustomerLazyQuery>;
export type GetAllQuotesWithCustomerQueryResult = Apollo.QueryResult<GetAllQuotesWithCustomerQuery, GetAllQuotesWithCustomerQueryVariables>;
export const GetCartPageDataDocument = gql`
    query GetCartPageData($jobConfigurationId: Int!) {
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    id
    jobId
    notes
    areas {
      id
      productTypeId
      productTypeName
      styleId
      styleName
      colorId
      colorName
      price {
        mSRP
        savings
        total
      }
      rooms {
        id
        labels {
          id
          customName
          hasCloset
          labelNumber
          label {
            id
            label
            fullLabel
          }
          roomId
        }
        services {
          id
          customerDoesService
          isActive
          serviceTypeId
          jobServiceId
          laborAmount
          materialAmount
          materialCategoryId
          roomId
        }
        sqft
      }
      includedInQuote
      preferredCarpetLength
      oppositeCarpetLength
    }
    roomTransitions {
      id
      jobConfigurationId
      mainRoomId
      altRoomId
      altExistingRoomIds
      width
      isDoor
      isExternalDoor
      mainRoomPositionIndex
      altRoomPositionIndex
    }
    price {
      mSRP
      savings
      total
    }
    financing {
      id
      perMonthAmount
      financingDownPayment
      financingOption {
        id
        term
        minAmount
        typeId
        fee
        startDate
        endDate
        tranCode
        promoParentTranCode
        slot
      }
    }
    discounts {
      id
      isOverrideApplied
    }
  }
}
    `;

/**
 * __useGetCartPageDataQuery__
 *
 * To run a query within a React component, call `useGetCartPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartPageDataQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetCartPageDataQuery(baseOptions: Apollo.QueryHookOptions<GetCartPageDataQuery, GetCartPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCartPageDataQuery, GetCartPageDataQueryVariables>(GetCartPageDataDocument, options);
      }
export function useGetCartPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCartPageDataQuery, GetCartPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCartPageDataQuery, GetCartPageDataQueryVariables>(GetCartPageDataDocument, options);
        }
export type GetCartPageDataQueryHookResult = ReturnType<typeof useGetCartPageDataQuery>;
export type GetCartPageDataLazyQueryHookResult = ReturnType<typeof useGetCartPageDataLazyQuery>;
export type GetCartPageDataQueryResult = Apollo.QueryResult<GetCartPageDataQuery, GetCartPageDataQueryVariables>;
export const GetJobInvoiceDataDocument = gql`
    query GetJobInvoiceData($jobConfigurationId: Int!) {
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    id
    jobId
    notes
    areas {
      id
      jobConfigurationId
      productTypeId
      productTypeName
      styleName
      colorName
      price {
        mSRP
        savings
        total
      }
      rooms {
        id
        labels {
          id
          customName
          hasCloset
          labelNumber
          label {
            id
            label
            fullLabel
          }
          roomId
        }
        services {
          id
          customerDoesService
          isActive
          serviceTypeId
          jobServiceId
          laborAmount
          materialAmount
          materialCategoryId
          roomId
        }
        sqft
        substrateId
        steps {
          id
          stepDetailId
          roomId
          amount
          width
          length
          isTreadOnly
          isLanding
          shouldPromptAboutSpindles
        }
      }
      notes
      includedInQuote
      preferredCarpetLength
      oppositeCarpetLength
      customServices {
        id
        areaId
        roomIds
        description
        price
        contractorPercentage
      }
    }
    roomTransitions {
      id
      jobConfigurationId
      mainRoomId
      altRoomId
      altExistingRoomIds
      width
      isDoor
      isExternalDoor
      mainRoomPositionIndex
      altRoomPositionIndex
    }
    price {
      mSRP
      savings
      total
    }
    financing {
      id
      financingAccountNumber
      financingDownPayment
      perMonthAmount
      financingOption {
        id
        term
        minAmount
        typeId
        fee
        startDate
        endDate
        tranCode
        promoParentTranCode
        slot
      }
    }
  }
  jobConfigurationHeader(jobConfigurationId: $jobConfigurationId) {
    id
    appointmentNumber
    customer {
      id
      firstName
      lastName
      businessName
      primaryPhone
      primaryAddress {
        id
        streetAddress
        city
        zip
        apartmentNumber
      }
      billingAddress {
        id
        streetAddress
        city
        zip
        apartmentNumber
      }
      email
      receivePromoEmails
      customerContacts {
        ...CustomerContact
      }
    }
    assignedSalespeople {
      id
      workerId
      firstName
      lastName
      email
      isCheckedIn
      serviceZips
    }
  }
}
    ${CustomerContactFragmentDoc}`;

/**
 * __useGetJobInvoiceDataQuery__
 *
 * To run a query within a React component, call `useGetJobInvoiceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobInvoiceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobInvoiceDataQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobInvoiceDataQuery(baseOptions: Apollo.QueryHookOptions<GetJobInvoiceDataQuery, GetJobInvoiceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobInvoiceDataQuery, GetJobInvoiceDataQueryVariables>(GetJobInvoiceDataDocument, options);
      }
export function useGetJobInvoiceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobInvoiceDataQuery, GetJobInvoiceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobInvoiceDataQuery, GetJobInvoiceDataQueryVariables>(GetJobInvoiceDataDocument, options);
        }
export type GetJobInvoiceDataQueryHookResult = ReturnType<typeof useGetJobInvoiceDataQuery>;
export type GetJobInvoiceDataLazyQueryHookResult = ReturnType<typeof useGetJobInvoiceDataLazyQuery>;
export type GetJobInvoiceDataQueryResult = Apollo.QueryResult<GetJobInvoiceDataQuery, GetJobInvoiceDataQueryVariables>;
export const GetQuoteDocument = gql`
    query GetQuote($quoteId: Int!) {
  quote(quoteId: $quoteId) {
    id
    quoteNumber
    jobConfigurationId
    date
    followUpDate
    totalPrice
    notes
    areas {
      ...Area
    }
  }
}
    ${AreaFragmentDoc}`;

/**
 * __useGetQuoteQuery__
 *
 * To run a query within a React component, call `useGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGetQuoteQuery(baseOptions: Apollo.QueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
      }
export function useGetQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
        }
export type GetQuoteQueryHookResult = ReturnType<typeof useGetQuoteQuery>;
export type GetQuoteLazyQueryHookResult = ReturnType<typeof useGetQuoteLazyQuery>;
export type GetQuoteQueryResult = Apollo.QueryResult<GetQuoteQuery, GetQuoteQueryVariables>;
export const GetQuotePdfS3FileDocument = gql`
    query GetQuotePdfS3File($quoteId: Int!) {
  customerForQuote(quoteId: $quoteId) {
    email
  }
  quotePdfS3File(quoteId: $quoteId) {
    ...S3File
  }
}
    ${S3FileFragmentDoc}`;

/**
 * __useGetQuotePdfS3FileQuery__
 *
 * To run a query within a React component, call `useGetQuotePdfS3FileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotePdfS3FileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotePdfS3FileQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGetQuotePdfS3FileQuery(baseOptions: Apollo.QueryHookOptions<GetQuotePdfS3FileQuery, GetQuotePdfS3FileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuotePdfS3FileQuery, GetQuotePdfS3FileQueryVariables>(GetQuotePdfS3FileDocument, options);
      }
export function useGetQuotePdfS3FileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotePdfS3FileQuery, GetQuotePdfS3FileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuotePdfS3FileQuery, GetQuotePdfS3FileQueryVariables>(GetQuotePdfS3FileDocument, options);
        }
export type GetQuotePdfS3FileQueryHookResult = ReturnType<typeof useGetQuotePdfS3FileQuery>;
export type GetQuotePdfS3FileLazyQueryHookResult = ReturnType<typeof useGetQuotePdfS3FileLazyQuery>;
export type GetQuotePdfS3FileQueryResult = Apollo.QueryResult<GetQuotePdfS3FileQuery, GetQuotePdfS3FileQueryVariables>;
export const GetSellSheetAreaColumnDocument = gql`
    query GetSellSheetAreaColumn($jobConfigurationId: Int!) {
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    id
    jobId
    areas {
      id
      productTypeId
      productTypeName
      styleId
      price {
        mSRP
        savings
        total
      }
      rooms {
        id
        labels {
          id
          customName
          hasCloset
          labelNumber
          label {
            id
            label
            fullLabel
          }
          roomId
        }
        steps {
          id
          stepDetailId
          roomId
          amount
          length
          width
          isTreadOnly
          isLanding
          shouldPromptAboutSpindles
        }
      }
      includedInQuote
      preferredCarpetLength
      oppositeCarpetLength
    }
    price {
      mSRP
      savings
      total
    }
    discounts {
      id
      isOverrideApplied
      discounts {
        id
        amount
        discountId
        isScalar
        isValid
        jobConfigurationId
        name
      }
    }
  }
}
    `;

/**
 * __useGetSellSheetAreaColumnQuery__
 *
 * To run a query within a React component, call `useGetSellSheetAreaColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellSheetAreaColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellSheetAreaColumnQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetSellSheetAreaColumnQuery(baseOptions: Apollo.QueryHookOptions<GetSellSheetAreaColumnQuery, GetSellSheetAreaColumnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellSheetAreaColumnQuery, GetSellSheetAreaColumnQueryVariables>(GetSellSheetAreaColumnDocument, options);
      }
export function useGetSellSheetAreaColumnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellSheetAreaColumnQuery, GetSellSheetAreaColumnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellSheetAreaColumnQuery, GetSellSheetAreaColumnQueryVariables>(GetSellSheetAreaColumnDocument, options);
        }
export type GetSellSheetAreaColumnQueryHookResult = ReturnType<typeof useGetSellSheetAreaColumnQuery>;
export type GetSellSheetAreaColumnLazyQueryHookResult = ReturnType<typeof useGetSellSheetAreaColumnLazyQuery>;
export type GetSellSheetAreaColumnQueryResult = Apollo.QueryResult<GetSellSheetAreaColumnQuery, GetSellSheetAreaColumnQueryVariables>;
export const GetSellSheetHeaderColumnDocument = gql`
    query GetSellSheetHeaderColumn($jobConfigurationId: Int!) {
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    id
    jobId
    price {
      total
    }
    financing {
      id
      financingDownPayment
      perMonthAmount
      financingOption {
        id
        term
        minAmount
        typeId
        fee
        startDate
        endDate
        tranCode
        promoParentTranCode
        slot
      }
    }
    discounts {
      id
      isOverrideApplied
      availableDiscounts {
        ...Discount
      }
      discounts {
        id
        amount
        discountId
        isScalar
        isValid
        jobConfigurationId
        name
      }
    }
  }
}
    ${DiscountFragmentDoc}`;

/**
 * __useGetSellSheetHeaderColumnQuery__
 *
 * To run a query within a React component, call `useGetSellSheetHeaderColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellSheetHeaderColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellSheetHeaderColumnQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetSellSheetHeaderColumnQuery(baseOptions: Apollo.QueryHookOptions<GetSellSheetHeaderColumnQuery, GetSellSheetHeaderColumnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellSheetHeaderColumnQuery, GetSellSheetHeaderColumnQueryVariables>(GetSellSheetHeaderColumnDocument, options);
      }
export function useGetSellSheetHeaderColumnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellSheetHeaderColumnQuery, GetSellSheetHeaderColumnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellSheetHeaderColumnQuery, GetSellSheetHeaderColumnQueryVariables>(GetSellSheetHeaderColumnDocument, options);
        }
export type GetSellSheetHeaderColumnQueryHookResult = ReturnType<typeof useGetSellSheetHeaderColumnQuery>;
export type GetSellSheetHeaderColumnLazyQueryHookResult = ReturnType<typeof useGetSellSheetHeaderColumnLazyQuery>;
export type GetSellSheetHeaderColumnQueryResult = Apollo.QueryResult<GetSellSheetHeaderColumnQuery, GetSellSheetHeaderColumnQueryVariables>;
export const GetSellSheetRenderColumnDocument = gql`
    query GetSellSheetRenderColumn($jobConfigurationId: Int!) {
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    id
    jobId
    discounts {
      id
      isOverrideApplied
      availableDiscounts {
        id
        defaultValue
        isScalar
        name
      }
      discounts {
        id
        amount
        discountId
        isScalar
        isValid
        jobConfigurationId
        name
      }
    }
  }
}
    `;

/**
 * __useGetSellSheetRenderColumnQuery__
 *
 * To run a query within a React component, call `useGetSellSheetRenderColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellSheetRenderColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellSheetRenderColumnQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetSellSheetRenderColumnQuery(baseOptions: Apollo.QueryHookOptions<GetSellSheetRenderColumnQuery, GetSellSheetRenderColumnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellSheetRenderColumnQuery, GetSellSheetRenderColumnQueryVariables>(GetSellSheetRenderColumnDocument, options);
      }
export function useGetSellSheetRenderColumnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellSheetRenderColumnQuery, GetSellSheetRenderColumnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellSheetRenderColumnQuery, GetSellSheetRenderColumnQueryVariables>(GetSellSheetRenderColumnDocument, options);
        }
export type GetSellSheetRenderColumnQueryHookResult = ReturnType<typeof useGetSellSheetRenderColumnQuery>;
export type GetSellSheetRenderColumnLazyQueryHookResult = ReturnType<typeof useGetSellSheetRenderColumnLazyQuery>;
export type GetSellSheetRenderColumnQueryResult = Apollo.QueryResult<GetSellSheetRenderColumnQuery, GetSellSheetRenderColumnQueryVariables>;
export const GetSellSheetTotalAreaComboColumnDocument = gql`
    query GetSellSheetTotalAreaComboColumn($jobConfigurationId: Int!) {
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    id
    jobId
    areas {
      id
      productTypeId
      productTypeName
      styleId
      price {
        mSRP
        savings
        total
      }
      rooms {
        id
        labels {
          id
          customName
          hasCloset
          labelNumber
          label {
            id
            label
            fullLabel
          }
          roomId
        }
      }
      includedInQuote
      preferredCarpetLength
      oppositeCarpetLength
    }
    price {
      mSRP
      savings
      total
    }
    financing {
      id
      financingDownPayment
      perMonthAmount
      financingOption {
        id
        term
        minAmount
        typeId
        fee
        startDate
        endDate
        tranCode
        promoParentTranCode
        slot
      }
    }
    discounts {
      id
      isOverrideApplied
      discounts {
        id
        amount
        discountId
        isScalar
        isValid
        jobConfigurationId
        name
      }
    }
  }
}
    `;

/**
 * __useGetSellSheetTotalAreaComboColumnQuery__
 *
 * To run a query within a React component, call `useGetSellSheetTotalAreaComboColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellSheetTotalAreaComboColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellSheetTotalAreaComboColumnQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetSellSheetTotalAreaComboColumnQuery(baseOptions: Apollo.QueryHookOptions<GetSellSheetTotalAreaComboColumnQuery, GetSellSheetTotalAreaComboColumnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellSheetTotalAreaComboColumnQuery, GetSellSheetTotalAreaComboColumnQueryVariables>(GetSellSheetTotalAreaComboColumnDocument, options);
      }
export function useGetSellSheetTotalAreaComboColumnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellSheetTotalAreaComboColumnQuery, GetSellSheetTotalAreaComboColumnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellSheetTotalAreaComboColumnQuery, GetSellSheetTotalAreaComboColumnQueryVariables>(GetSellSheetTotalAreaComboColumnDocument, options);
        }
export type GetSellSheetTotalAreaComboColumnQueryHookResult = ReturnType<typeof useGetSellSheetTotalAreaComboColumnQuery>;
export type GetSellSheetTotalAreaComboColumnLazyQueryHookResult = ReturnType<typeof useGetSellSheetTotalAreaComboColumnLazyQuery>;
export type GetSellSheetTotalAreaComboColumnQueryResult = Apollo.QueryResult<GetSellSheetTotalAreaComboColumnQuery, GetSellSheetTotalAreaComboColumnQueryVariables>;
export const GetSellSheetTotalColumnDocument = gql`
    query GetSellSheetTotalColumn($jobConfigurationId: Int!) {
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    id
    jobId
    price {
      mSRP
      savings
      total
    }
    financing {
      id
      financingDownPayment
      perMonthAmount
      financingOption {
        id
        term
        minAmount
        typeId
        fee
        startDate
        endDate
        tranCode
        promoParentTranCode
        slot
      }
    }
    discounts {
      id
      isOverrideApplied
      discounts {
        id
        amount
        discountId
        isScalar
        isValid
        jobConfigurationId
        name
      }
    }
  }
}
    `;

/**
 * __useGetSellSheetTotalColumnQuery__
 *
 * To run a query within a React component, call `useGetSellSheetTotalColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellSheetTotalColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellSheetTotalColumnQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetSellSheetTotalColumnQuery(baseOptions: Apollo.QueryHookOptions<GetSellSheetTotalColumnQuery, GetSellSheetTotalColumnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellSheetTotalColumnQuery, GetSellSheetTotalColumnQueryVariables>(GetSellSheetTotalColumnDocument, options);
      }
export function useGetSellSheetTotalColumnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellSheetTotalColumnQuery, GetSellSheetTotalColumnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellSheetTotalColumnQuery, GetSellSheetTotalColumnQueryVariables>(GetSellSheetTotalColumnDocument, options);
        }
export type GetSellSheetTotalColumnQueryHookResult = ReturnType<typeof useGetSellSheetTotalColumnQuery>;
export type GetSellSheetTotalColumnLazyQueryHookResult = ReturnType<typeof useGetSellSheetTotalColumnLazyQuery>;
export type GetSellSheetTotalColumnQueryResult = Apollo.QueryResult<GetSellSheetTotalColumnQuery, GetSellSheetTotalColumnQueryVariables>;
export const AddQuoteForJobDocument = gql`
    mutation AddQuoteForJob($followUpDate: DateTime!, $jobConfigurationId: Int!, $notes: String, $emails: [String!]!) {
  addQuoteForJob(followUpDate: $followUpDate, jobConfigurationId: $jobConfigurationId, notes: $notes, emails: $emails)
}
    `;
export type AddQuoteForJobMutationFn = Apollo.MutationFunction<AddQuoteForJobMutation, AddQuoteForJobMutationVariables>;

/**
 * __useAddQuoteForJobMutation__
 *
 * To run a mutation, you first call `useAddQuoteForJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuoteForJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuoteForJobMutation, { data, loading, error }] = useAddQuoteForJobMutation({
 *   variables: {
 *      followUpDate: // value for 'followUpDate'
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      notes: // value for 'notes'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useAddQuoteForJobMutation(baseOptions?: Apollo.MutationHookOptions<AddQuoteForJobMutation, AddQuoteForJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddQuoteForJobMutation, AddQuoteForJobMutationVariables>(AddQuoteForJobDocument, options);
      }
export type AddQuoteForJobMutationHookResult = ReturnType<typeof useAddQuoteForJobMutation>;
export type AddQuoteForJobMutationResult = Apollo.MutationResult<AddQuoteForJobMutation>;
export type AddQuoteForJobMutationOptions = Apollo.BaseMutationOptions<AddQuoteForJobMutation, AddQuoteForJobMutationVariables>;
export const TrimInvalidFieldsFromJobConfigurationDocument = gql`
    mutation TrimInvalidFieldsFromJobConfiguration($jobConfigurationId: Int!) {
  trimInvalidFieldsFromJobConfiguration(jobConfigurationId: $jobConfigurationId)
}
    `;
export type TrimInvalidFieldsFromJobConfigurationMutationFn = Apollo.MutationFunction<TrimInvalidFieldsFromJobConfigurationMutation, TrimInvalidFieldsFromJobConfigurationMutationVariables>;

/**
 * __useTrimInvalidFieldsFromJobConfigurationMutation__
 *
 * To run a mutation, you first call `useTrimInvalidFieldsFromJobConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrimInvalidFieldsFromJobConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trimInvalidFieldsFromJobConfigurationMutation, { data, loading, error }] = useTrimInvalidFieldsFromJobConfigurationMutation({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useTrimInvalidFieldsFromJobConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<TrimInvalidFieldsFromJobConfigurationMutation, TrimInvalidFieldsFromJobConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrimInvalidFieldsFromJobConfigurationMutation, TrimInvalidFieldsFromJobConfigurationMutationVariables>(TrimInvalidFieldsFromJobConfigurationDocument, options);
      }
export type TrimInvalidFieldsFromJobConfigurationMutationHookResult = ReturnType<typeof useTrimInvalidFieldsFromJobConfigurationMutation>;
export type TrimInvalidFieldsFromJobConfigurationMutationResult = Apollo.MutationResult<TrimInvalidFieldsFromJobConfigurationMutation>;
export type TrimInvalidFieldsFromJobConfigurationMutationOptions = Apollo.BaseMutationOptions<TrimInvalidFieldsFromJobConfigurationMutation, TrimInvalidFieldsFromJobConfigurationMutationVariables>;
export const UpdateCustomerDetailsDocument = gql`
    mutation UpdateCustomerDetails($updatedCustomer: CustomerInput!) {
  updateCustomerDetails(updatedCustomer: $updatedCustomer)
}
    `;
export type UpdateCustomerDetailsMutationFn = Apollo.MutationFunction<UpdateCustomerDetailsMutation, UpdateCustomerDetailsMutationVariables>;

/**
 * __useUpdateCustomerDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerDetailsMutation, { data, loading, error }] = useUpdateCustomerDetailsMutation({
 *   variables: {
 *      updatedCustomer: // value for 'updatedCustomer'
 *   },
 * });
 */
export function useUpdateCustomerDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerDetailsMutation, UpdateCustomerDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerDetailsMutation, UpdateCustomerDetailsMutationVariables>(UpdateCustomerDetailsDocument, options);
      }
export type UpdateCustomerDetailsMutationHookResult = ReturnType<typeof useUpdateCustomerDetailsMutation>;
export type UpdateCustomerDetailsMutationResult = Apollo.MutationResult<UpdateCustomerDetailsMutation>;
export type UpdateCustomerDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerDetailsMutation, UpdateCustomerDetailsMutationVariables>;
export const UpdateJobConfigurationNotesDocument = gql`
    mutation UpdateJobConfigurationNotes($jobConfigurationId: Int!, $notes: String!, $replace: Boolean!) {
  updateJobConfigurationNotes(jobConfigurationId: $jobConfigurationId, notes: $notes, replace: $replace)
}
    `;
export type UpdateJobConfigurationNotesMutationFn = Apollo.MutationFunction<UpdateJobConfigurationNotesMutation, UpdateJobConfigurationNotesMutationVariables>;

/**
 * __useUpdateJobConfigurationNotesMutation__
 *
 * To run a mutation, you first call `useUpdateJobConfigurationNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobConfigurationNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobConfigurationNotesMutation, { data, loading, error }] = useUpdateJobConfigurationNotesMutation({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      notes: // value for 'notes'
 *      replace: // value for 'replace'
 *   },
 * });
 */
export function useUpdateJobConfigurationNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobConfigurationNotesMutation, UpdateJobConfigurationNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobConfigurationNotesMutation, UpdateJobConfigurationNotesMutationVariables>(UpdateJobConfigurationNotesDocument, options);
      }
export type UpdateJobConfigurationNotesMutationHookResult = ReturnType<typeof useUpdateJobConfigurationNotesMutation>;
export type UpdateJobConfigurationNotesMutationResult = Apollo.MutationResult<UpdateJobConfigurationNotesMutation>;
export type UpdateJobConfigurationNotesMutationOptions = Apollo.BaseMutationOptions<UpdateJobConfigurationNotesMutation, UpdateJobConfigurationNotesMutationVariables>;
export const UpdateJobDiscountDocument = gql`
    mutation UpdateJobDiscount($jobConfigurationId: Int!, $discountId: Int!, $overrideAmount: Decimal, $isRemoval: Boolean!) {
  updateDiscount(jobConfigurationId: $jobConfigurationId, discountId: $discountId, overrideAmount: $overrideAmount, isRemoval: $isRemoval)
}
    `;
export type UpdateJobDiscountMutationFn = Apollo.MutationFunction<UpdateJobDiscountMutation, UpdateJobDiscountMutationVariables>;

/**
 * __useUpdateJobDiscountMutation__
 *
 * To run a mutation, you first call `useUpdateJobDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobDiscountMutation, { data, loading, error }] = useUpdateJobDiscountMutation({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      discountId: // value for 'discountId'
 *      overrideAmount: // value for 'overrideAmount'
 *      isRemoval: // value for 'isRemoval'
 *   },
 * });
 */
export function useUpdateJobDiscountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobDiscountMutation, UpdateJobDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobDiscountMutation, UpdateJobDiscountMutationVariables>(UpdateJobDiscountDocument, options);
      }
export type UpdateJobDiscountMutationHookResult = ReturnType<typeof useUpdateJobDiscountMutation>;
export type UpdateJobDiscountMutationResult = Apollo.MutationResult<UpdateJobDiscountMutation>;
export type UpdateJobDiscountMutationOptions = Apollo.BaseMutationOptions<UpdateJobDiscountMutation, UpdateJobDiscountMutationVariables>;
export const UpdateJobFinancingDocument = gql`
    mutation UpdateJobFinancing($jobConfigurationId: Int!, $financingOptionId: Int, $downPaymentAmount: Decimal) {
  updateFinancing(jobConfigurationId: $jobConfigurationId, financingOptionId: $financingOptionId, downPaymentAmount: $downPaymentAmount)
}
    `;
export type UpdateJobFinancingMutationFn = Apollo.MutationFunction<UpdateJobFinancingMutation, UpdateJobFinancingMutationVariables>;

/**
 * __useUpdateJobFinancingMutation__
 *
 * To run a mutation, you first call `useUpdateJobFinancingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobFinancingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobFinancingMutation, { data, loading, error }] = useUpdateJobFinancingMutation({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      financingOptionId: // value for 'financingOptionId'
 *      downPaymentAmount: // value for 'downPaymentAmount'
 *   },
 * });
 */
export function useUpdateJobFinancingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobFinancingMutation, UpdateJobFinancingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobFinancingMutation, UpdateJobFinancingMutationVariables>(UpdateJobFinancingDocument, options);
      }
export type UpdateJobFinancingMutationHookResult = ReturnType<typeof useUpdateJobFinancingMutation>;
export type UpdateJobFinancingMutationResult = Apollo.MutationResult<UpdateJobFinancingMutation>;
export type UpdateJobFinancingMutationOptions = Apollo.BaseMutationOptions<UpdateJobFinancingMutation, UpdateJobFinancingMutationVariables>;
export const GetAllServiceMaterialCategoriesForProductDocument = gql`
    query GetAllServiceMaterialCategoriesForProduct($productTypeId: Int!) {
  allServiceMaterialCategoriesForProduct(productTypeId: $productTypeId) {
    id
    name
    priceUnit
    packageSize
    packageSizeIncrement
  }
}
    `;

/**
 * __useGetAllServiceMaterialCategoriesForProductQuery__
 *
 * To run a query within a React component, call `useGetAllServiceMaterialCategoriesForProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllServiceMaterialCategoriesForProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllServiceMaterialCategoriesForProductQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useGetAllServiceMaterialCategoriesForProductQuery(baseOptions: Apollo.QueryHookOptions<GetAllServiceMaterialCategoriesForProductQuery, GetAllServiceMaterialCategoriesForProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllServiceMaterialCategoriesForProductQuery, GetAllServiceMaterialCategoriesForProductQueryVariables>(GetAllServiceMaterialCategoriesForProductDocument, options);
      }
export function useGetAllServiceMaterialCategoriesForProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllServiceMaterialCategoriesForProductQuery, GetAllServiceMaterialCategoriesForProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllServiceMaterialCategoriesForProductQuery, GetAllServiceMaterialCategoriesForProductQueryVariables>(GetAllServiceMaterialCategoriesForProductDocument, options);
        }
export type GetAllServiceMaterialCategoriesForProductQueryHookResult = ReturnType<typeof useGetAllServiceMaterialCategoriesForProductQuery>;
export type GetAllServiceMaterialCategoriesForProductLazyQueryHookResult = ReturnType<typeof useGetAllServiceMaterialCategoriesForProductLazyQuery>;
export type GetAllServiceMaterialCategoriesForProductQueryResult = Apollo.QueryResult<GetAllServiceMaterialCategoriesForProductQuery, GetAllServiceMaterialCategoriesForProductQueryVariables>;
export const GetAllServiceProductOfferingsDocument = gql`
    query GetAllServiceProductOfferings($isDeleted: Boolean, $productTypeId: Int, $serviceTypeId: Int) {
  allServiceProductOfferings(filter: {isDeleted: $isDeleted, productTypeId: $productTypeId, serviceTypeId: $serviceTypeId}) {
    id
    productType
    serviceType
    description
    jobServiceId
    priceUnit
    costPerUnit
    pricePerUnit
    minimumInUnit
    isDeleted
  }
}
    `;

/**
 * __useGetAllServiceProductOfferingsQuery__
 *
 * To run a query within a React component, call `useGetAllServiceProductOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllServiceProductOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllServiceProductOfferingsQuery({
 *   variables: {
 *      isDeleted: // value for 'isDeleted'
 *      productTypeId: // value for 'productTypeId'
 *      serviceTypeId: // value for 'serviceTypeId'
 *   },
 * });
 */
export function useGetAllServiceProductOfferingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllServiceProductOfferingsQuery, GetAllServiceProductOfferingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllServiceProductOfferingsQuery, GetAllServiceProductOfferingsQueryVariables>(GetAllServiceProductOfferingsDocument, options);
      }
export function useGetAllServiceProductOfferingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllServiceProductOfferingsQuery, GetAllServiceProductOfferingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllServiceProductOfferingsQuery, GetAllServiceProductOfferingsQueryVariables>(GetAllServiceProductOfferingsDocument, options);
        }
export type GetAllServiceProductOfferingsQueryHookResult = ReturnType<typeof useGetAllServiceProductOfferingsQuery>;
export type GetAllServiceProductOfferingsLazyQueryHookResult = ReturnType<typeof useGetAllServiceProductOfferingsLazyQuery>;
export type GetAllServiceProductOfferingsQueryResult = Apollo.QueryResult<GetAllServiceProductOfferingsQuery, GetAllServiceProductOfferingsQueryVariables>;
export const GetAllServiceProductOfferingsForJobServiceDocument = gql`
    query GetAllServiceProductOfferingsForJobService($jobServiceId: Int!) {
  allServiceProductOfferingsForJobService(jobServiceId: $jobServiceId) {
    id
    jobServiceId
    costPerUnit
    pricePerUnit
    minimumInUnit
    productTypeId
    productType
    isDeleted
  }
}
    `;

/**
 * __useGetAllServiceProductOfferingsForJobServiceQuery__
 *
 * To run a query within a React component, call `useGetAllServiceProductOfferingsForJobServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllServiceProductOfferingsForJobServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllServiceProductOfferingsForJobServiceQuery({
 *   variables: {
 *      jobServiceId: // value for 'jobServiceId'
 *   },
 * });
 */
export function useGetAllServiceProductOfferingsForJobServiceQuery(baseOptions: Apollo.QueryHookOptions<GetAllServiceProductOfferingsForJobServiceQuery, GetAllServiceProductOfferingsForJobServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllServiceProductOfferingsForJobServiceQuery, GetAllServiceProductOfferingsForJobServiceQueryVariables>(GetAllServiceProductOfferingsForJobServiceDocument, options);
      }
export function useGetAllServiceProductOfferingsForJobServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllServiceProductOfferingsForJobServiceQuery, GetAllServiceProductOfferingsForJobServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllServiceProductOfferingsForJobServiceQuery, GetAllServiceProductOfferingsForJobServiceQueryVariables>(GetAllServiceProductOfferingsForJobServiceDocument, options);
        }
export type GetAllServiceProductOfferingsForJobServiceQueryHookResult = ReturnType<typeof useGetAllServiceProductOfferingsForJobServiceQuery>;
export type GetAllServiceProductOfferingsForJobServiceLazyQueryHookResult = ReturnType<typeof useGetAllServiceProductOfferingsForJobServiceLazyQuery>;
export type GetAllServiceProductOfferingsForJobServiceQueryResult = Apollo.QueryResult<GetAllServiceProductOfferingsForJobServiceQuery, GetAllServiceProductOfferingsForJobServiceQueryVariables>;
export const GetChargeableServicePricingDetailsDocument = gql`
    query GetChargeableServicePricingDetails {
  chargeableServicePricingDetails {
    id
    jobServiceId
    laborPricePerUnit
    minimumLaborAmount
    materialPricePerUnit
    materialCategoryId
    materialPackageSize
  }
}
    `;

/**
 * __useGetChargeableServicePricingDetailsQuery__
 *
 * To run a query within a React component, call `useGetChargeableServicePricingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargeableServicePricingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargeableServicePricingDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChargeableServicePricingDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetChargeableServicePricingDetailsQuery, GetChargeableServicePricingDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChargeableServicePricingDetailsQuery, GetChargeableServicePricingDetailsQueryVariables>(GetChargeableServicePricingDetailsDocument, options);
      }
export function useGetChargeableServicePricingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChargeableServicePricingDetailsQuery, GetChargeableServicePricingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChargeableServicePricingDetailsQuery, GetChargeableServicePricingDetailsQueryVariables>(GetChargeableServicePricingDetailsDocument, options);
        }
export type GetChargeableServicePricingDetailsQueryHookResult = ReturnType<typeof useGetChargeableServicePricingDetailsQuery>;
export type GetChargeableServicePricingDetailsLazyQueryHookResult = ReturnType<typeof useGetChargeableServicePricingDetailsLazyQuery>;
export type GetChargeableServicePricingDetailsQueryResult = Apollo.QueryResult<GetChargeableServicePricingDetailsQuery, GetChargeableServicePricingDetailsQueryVariables>;
export const GetInstallationJobServicesDocument = gql`
    query GetInstallationJobServices($productTypeId: Int!) {
  installationJobServices(productTypeId: $productTypeId) {
    id
    serviceTypeId
    description
    priceUnit
  }
}
    `;

/**
 * __useGetInstallationJobServicesQuery__
 *
 * To run a query within a React component, call `useGetInstallationJobServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallationJobServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallationJobServicesQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useGetInstallationJobServicesQuery(baseOptions: Apollo.QueryHookOptions<GetInstallationJobServicesQuery, GetInstallationJobServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstallationJobServicesQuery, GetInstallationJobServicesQueryVariables>(GetInstallationJobServicesDocument, options);
      }
export function useGetInstallationJobServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstallationJobServicesQuery, GetInstallationJobServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstallationJobServicesQuery, GetInstallationJobServicesQueryVariables>(GetInstallationJobServicesDocument, options);
        }
export type GetInstallationJobServicesQueryHookResult = ReturnType<typeof useGetInstallationJobServicesQuery>;
export type GetInstallationJobServicesLazyQueryHookResult = ReturnType<typeof useGetInstallationJobServicesLazyQuery>;
export type GetInstallationJobServicesQueryResult = Apollo.QueryResult<GetInstallationJobServicesQuery, GetInstallationJobServicesQueryVariables>;
export const GetJobServicesByTypeDocument = gql`
    query GetJobServicesByType($serviceTypeId: Int!) {
  jobServicesByType(serviceTypeId: $serviceTypeId) {
    id
    serviceTypeId
    description
    priceUnit
  }
}
    `;

/**
 * __useGetJobServicesByTypeQuery__
 *
 * To run a query within a React component, call `useGetJobServicesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobServicesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobServicesByTypeQuery({
 *   variables: {
 *      serviceTypeId: // value for 'serviceTypeId'
 *   },
 * });
 */
export function useGetJobServicesByTypeQuery(baseOptions: Apollo.QueryHookOptions<GetJobServicesByTypeQuery, GetJobServicesByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobServicesByTypeQuery, GetJobServicesByTypeQueryVariables>(GetJobServicesByTypeDocument, options);
      }
export function useGetJobServicesByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobServicesByTypeQuery, GetJobServicesByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobServicesByTypeQuery, GetJobServicesByTypeQueryVariables>(GetJobServicesByTypeDocument, options);
        }
export type GetJobServicesByTypeQueryHookResult = ReturnType<typeof useGetJobServicesByTypeQuery>;
export type GetJobServicesByTypeLazyQueryHookResult = ReturnType<typeof useGetJobServicesByTypeLazyQuery>;
export type GetJobServicesByTypeQueryResult = Apollo.QueryResult<GetJobServicesByTypeQuery, GetJobServicesByTypeQueryVariables>;
export const GetMetadataOfferingsForProductAndServiceDocument = gql`
    query GetMetadataOfferingsForProductAndService($productTypeId: Int!, $jobServiceId: Int!) {
  metadataOfferingsForProductAndService(productTypeId: $productTypeId, jobServiceId: $jobServiceId) {
    id
    serviceProductOfferingId
    serviceMaterialCategory
    serviceMaterialCategoryId
    materialPriceUnit
    defaultAmount
    isDefaultMaterial
  }
}
    `;

/**
 * __useGetMetadataOfferingsForProductAndServiceQuery__
 *
 * To run a query within a React component, call `useGetMetadataOfferingsForProductAndServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetadataOfferingsForProductAndServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetadataOfferingsForProductAndServiceQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *      jobServiceId: // value for 'jobServiceId'
 *   },
 * });
 */
export function useGetMetadataOfferingsForProductAndServiceQuery(baseOptions: Apollo.QueryHookOptions<GetMetadataOfferingsForProductAndServiceQuery, GetMetadataOfferingsForProductAndServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetadataOfferingsForProductAndServiceQuery, GetMetadataOfferingsForProductAndServiceQueryVariables>(GetMetadataOfferingsForProductAndServiceDocument, options);
      }
export function useGetMetadataOfferingsForProductAndServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetadataOfferingsForProductAndServiceQuery, GetMetadataOfferingsForProductAndServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetadataOfferingsForProductAndServiceQuery, GetMetadataOfferingsForProductAndServiceQueryVariables>(GetMetadataOfferingsForProductAndServiceDocument, options);
        }
export type GetMetadataOfferingsForProductAndServiceQueryHookResult = ReturnType<typeof useGetMetadataOfferingsForProductAndServiceQuery>;
export type GetMetadataOfferingsForProductAndServiceLazyQueryHookResult = ReturnType<typeof useGetMetadataOfferingsForProductAndServiceLazyQuery>;
export type GetMetadataOfferingsForProductAndServiceQueryResult = Apollo.QueryResult<GetMetadataOfferingsForProductAndServiceQuery, GetMetadataOfferingsForProductAndServiceQueryVariables>;
export const GetServiceLaborRuleDocument = gql`
    query GetServiceLaborRule($id: Int!) {
  serviceLaborRule(id: $id) {
    id
    productType
    serviceType
    description
    jobServiceId
    priceUnit
    costPerUnit
    pricePerUnit
    minimumInUnit
    isDeleted
  }
}
    `;

/**
 * __useGetServiceLaborRuleQuery__
 *
 * To run a query within a React component, call `useGetServiceLaborRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceLaborRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceLaborRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceLaborRuleQuery(baseOptions: Apollo.QueryHookOptions<GetServiceLaborRuleQuery, GetServiceLaborRuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceLaborRuleQuery, GetServiceLaborRuleQueryVariables>(GetServiceLaborRuleDocument, options);
      }
export function useGetServiceLaborRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceLaborRuleQuery, GetServiceLaborRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceLaborRuleQuery, GetServiceLaborRuleQueryVariables>(GetServiceLaborRuleDocument, options);
        }
export type GetServiceLaborRuleQueryHookResult = ReturnType<typeof useGetServiceLaborRuleQuery>;
export type GetServiceLaborRuleLazyQueryHookResult = ReturnType<typeof useGetServiceLaborRuleLazyQuery>;
export type GetServiceLaborRuleQueryResult = Apollo.QueryResult<GetServiceLaborRuleQuery, GetServiceLaborRuleQueryVariables>;
export const GetServicePricingUnitNamesDocument = gql`
    query GetServicePricingUnitNames {
  names: servicePricingUnitEnum
}
    `;

/**
 * __useGetServicePricingUnitNamesQuery__
 *
 * To run a query within a React component, call `useGetServicePricingUnitNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicePricingUnitNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicePricingUnitNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServicePricingUnitNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetServicePricingUnitNamesQuery, GetServicePricingUnitNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicePricingUnitNamesQuery, GetServicePricingUnitNamesQueryVariables>(GetServicePricingUnitNamesDocument, options);
      }
export function useGetServicePricingUnitNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicePricingUnitNamesQuery, GetServicePricingUnitNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicePricingUnitNamesQuery, GetServicePricingUnitNamesQueryVariables>(GetServicePricingUnitNamesDocument, options);
        }
export type GetServicePricingUnitNamesQueryHookResult = ReturnType<typeof useGetServicePricingUnitNamesQuery>;
export type GetServicePricingUnitNamesLazyQueryHookResult = ReturnType<typeof useGetServicePricingUnitNamesLazyQuery>;
export type GetServicePricingUnitNamesQueryResult = Apollo.QueryResult<GetServicePricingUnitNamesQuery, GetServicePricingUnitNamesQueryVariables>;
export const GetServiceProductOfferingIdsDocument = gql`
    query GetServiceProductOfferingIds($isDeleted: Boolean, $productTypeId: Int, $serviceTypeId: Int) {
  serviceProductOfferingIds(filter: {isDeleted: $isDeleted, productTypeId: $productTypeId, serviceTypeId: $serviceTypeId})
}
    `;

/**
 * __useGetServiceProductOfferingIdsQuery__
 *
 * To run a query within a React component, call `useGetServiceProductOfferingIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceProductOfferingIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceProductOfferingIdsQuery({
 *   variables: {
 *      isDeleted: // value for 'isDeleted'
 *      productTypeId: // value for 'productTypeId'
 *      serviceTypeId: // value for 'serviceTypeId'
 *   },
 * });
 */
export function useGetServiceProductOfferingIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceProductOfferingIdsQuery, GetServiceProductOfferingIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceProductOfferingIdsQuery, GetServiceProductOfferingIdsQueryVariables>(GetServiceProductOfferingIdsDocument, options);
      }
export function useGetServiceProductOfferingIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceProductOfferingIdsQuery, GetServiceProductOfferingIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceProductOfferingIdsQuery, GetServiceProductOfferingIdsQueryVariables>(GetServiceProductOfferingIdsDocument, options);
        }
export type GetServiceProductOfferingIdsQueryHookResult = ReturnType<typeof useGetServiceProductOfferingIdsQuery>;
export type GetServiceProductOfferingIdsLazyQueryHookResult = ReturnType<typeof useGetServiceProductOfferingIdsLazyQuery>;
export type GetServiceProductOfferingIdsQueryResult = Apollo.QueryResult<GetServiceProductOfferingIdsQuery, GetServiceProductOfferingIdsQueryVariables>;
export const GetServiceTypesDocument = gql`
    query GetServiceTypes {
  options: serviceTypes {
    id
    name
  }
}
    `;

/**
 * __useGetServiceTypesQuery__
 *
 * To run a query within a React component, call `useGetServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServiceTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceTypesQuery, GetServiceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceTypesQuery, GetServiceTypesQueryVariables>(GetServiceTypesDocument, options);
      }
export function useGetServiceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceTypesQuery, GetServiceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceTypesQuery, GetServiceTypesQueryVariables>(GetServiceTypesDocument, options);
        }
export type GetServiceTypesQueryHookResult = ReturnType<typeof useGetServiceTypesQuery>;
export type GetServiceTypesLazyQueryHookResult = ReturnType<typeof useGetServiceTypesLazyQuery>;
export type GetServiceTypesQueryResult = Apollo.QueryResult<GetServiceTypesQuery, GetServiceTypesQueryVariables>;
export const AddMaterialToJobServiceDocument = gql`
    mutation AddMaterialToJobService($materialCategoryId: Int!, $jobServiceId: Int!, $productTypeId: Int!) {
  addMaterialToJobService(materialCategoryId: $materialCategoryId, jobServiceId: $jobServiceId, productTypeId: $productTypeId)
}
    `;
export type AddMaterialToJobServiceMutationFn = Apollo.MutationFunction<AddMaterialToJobServiceMutation, AddMaterialToJobServiceMutationVariables>;

/**
 * __useAddMaterialToJobServiceMutation__
 *
 * To run a mutation, you first call `useAddMaterialToJobServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMaterialToJobServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMaterialToJobServiceMutation, { data, loading, error }] = useAddMaterialToJobServiceMutation({
 *   variables: {
 *      materialCategoryId: // value for 'materialCategoryId'
 *      jobServiceId: // value for 'jobServiceId'
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useAddMaterialToJobServiceMutation(baseOptions?: Apollo.MutationHookOptions<AddMaterialToJobServiceMutation, AddMaterialToJobServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMaterialToJobServiceMutation, AddMaterialToJobServiceMutationVariables>(AddMaterialToJobServiceDocument, options);
      }
export type AddMaterialToJobServiceMutationHookResult = ReturnType<typeof useAddMaterialToJobServiceMutation>;
export type AddMaterialToJobServiceMutationResult = Apollo.MutationResult<AddMaterialToJobServiceMutation>;
export type AddMaterialToJobServiceMutationOptions = Apollo.BaseMutationOptions<AddMaterialToJobServiceMutation, AddMaterialToJobServiceMutationVariables>;
export const AddMultipleOfferingsToExistingServiceDocument = gql`
    mutation AddMultipleOfferingsToExistingService($jobServiceId: Int!, $productValues: [ProductAndValuesInput!]!) {
  addMultipleOfferingsToExistingService(input: {jobServiceId: $jobServiceId, productValues: $productValues}) {
    addedIds
    errorProductIds
  }
}
    `;
export type AddMultipleOfferingsToExistingServiceMutationFn = Apollo.MutationFunction<AddMultipleOfferingsToExistingServiceMutation, AddMultipleOfferingsToExistingServiceMutationVariables>;

/**
 * __useAddMultipleOfferingsToExistingServiceMutation__
 *
 * To run a mutation, you first call `useAddMultipleOfferingsToExistingServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMultipleOfferingsToExistingServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMultipleOfferingsToExistingServiceMutation, { data, loading, error }] = useAddMultipleOfferingsToExistingServiceMutation({
 *   variables: {
 *      jobServiceId: // value for 'jobServiceId'
 *      productValues: // value for 'productValues'
 *   },
 * });
 */
export function useAddMultipleOfferingsToExistingServiceMutation(baseOptions?: Apollo.MutationHookOptions<AddMultipleOfferingsToExistingServiceMutation, AddMultipleOfferingsToExistingServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMultipleOfferingsToExistingServiceMutation, AddMultipleOfferingsToExistingServiceMutationVariables>(AddMultipleOfferingsToExistingServiceDocument, options);
      }
export type AddMultipleOfferingsToExistingServiceMutationHookResult = ReturnType<typeof useAddMultipleOfferingsToExistingServiceMutation>;
export type AddMultipleOfferingsToExistingServiceMutationResult = Apollo.MutationResult<AddMultipleOfferingsToExistingServiceMutation>;
export type AddMultipleOfferingsToExistingServiceMutationOptions = Apollo.BaseMutationOptions<AddMultipleOfferingsToExistingServiceMutation, AddMultipleOfferingsToExistingServiceMutationVariables>;
export const AddMultipleOfferingsToNewServiceDocument = gql`
    mutation AddMultipleOfferingsToNewService($serviceTypeId: Int!, $description: String!, $priceUnit: String!, $productValues: [ProductAndValuesInput!]!) {
  addMultipleOfferingsToNewService(input: {serviceTypeId: $serviceTypeId, description: $description, priceUnit: $priceUnit, productValues: $productValues})
}
    `;
export type AddMultipleOfferingsToNewServiceMutationFn = Apollo.MutationFunction<AddMultipleOfferingsToNewServiceMutation, AddMultipleOfferingsToNewServiceMutationVariables>;

/**
 * __useAddMultipleOfferingsToNewServiceMutation__
 *
 * To run a mutation, you first call `useAddMultipleOfferingsToNewServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMultipleOfferingsToNewServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMultipleOfferingsToNewServiceMutation, { data, loading, error }] = useAddMultipleOfferingsToNewServiceMutation({
 *   variables: {
 *      serviceTypeId: // value for 'serviceTypeId'
 *      description: // value for 'description'
 *      priceUnit: // value for 'priceUnit'
 *      productValues: // value for 'productValues'
 *   },
 * });
 */
export function useAddMultipleOfferingsToNewServiceMutation(baseOptions?: Apollo.MutationHookOptions<AddMultipleOfferingsToNewServiceMutation, AddMultipleOfferingsToNewServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMultipleOfferingsToNewServiceMutation, AddMultipleOfferingsToNewServiceMutationVariables>(AddMultipleOfferingsToNewServiceDocument, options);
      }
export type AddMultipleOfferingsToNewServiceMutationHookResult = ReturnType<typeof useAddMultipleOfferingsToNewServiceMutation>;
export type AddMultipleOfferingsToNewServiceMutationResult = Apollo.MutationResult<AddMultipleOfferingsToNewServiceMutation>;
export type AddMultipleOfferingsToNewServiceMutationOptions = Apollo.BaseMutationOptions<AddMultipleOfferingsToNewServiceMutation, AddMultipleOfferingsToNewServiceMutationVariables>;
export const RemoveMaterialFromJobServiceDocument = gql`
    mutation RemoveMaterialFromJobService($materialForServiceOfferingId: Int!) {
  removeMaterialFromJobService(materialForServiceOfferingId: $materialForServiceOfferingId)
}
    `;
export type RemoveMaterialFromJobServiceMutationFn = Apollo.MutationFunction<RemoveMaterialFromJobServiceMutation, RemoveMaterialFromJobServiceMutationVariables>;

/**
 * __useRemoveMaterialFromJobServiceMutation__
 *
 * To run a mutation, you first call `useRemoveMaterialFromJobServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMaterialFromJobServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMaterialFromJobServiceMutation, { data, loading, error }] = useRemoveMaterialFromJobServiceMutation({
 *   variables: {
 *      materialForServiceOfferingId: // value for 'materialForServiceOfferingId'
 *   },
 * });
 */
export function useRemoveMaterialFromJobServiceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMaterialFromJobServiceMutation, RemoveMaterialFromJobServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMaterialFromJobServiceMutation, RemoveMaterialFromJobServiceMutationVariables>(RemoveMaterialFromJobServiceDocument, options);
      }
export type RemoveMaterialFromJobServiceMutationHookResult = ReturnType<typeof useRemoveMaterialFromJobServiceMutation>;
export type RemoveMaterialFromJobServiceMutationResult = Apollo.MutationResult<RemoveMaterialFromJobServiceMutation>;
export type RemoveMaterialFromJobServiceMutationOptions = Apollo.BaseMutationOptions<RemoveMaterialFromJobServiceMutation, RemoveMaterialFromJobServiceMutationVariables>;
export const SetDefaultMaterialForJobServiceDocument = gql`
    mutation SetDefaultMaterialForJobService($materialForServiceOfferingId: Int!, $isDefault: Boolean!) {
  setDefaultMaterialForJobService(materialForServiceOfferingId: $materialForServiceOfferingId, isDefault: $isDefault)
}
    `;
export type SetDefaultMaterialForJobServiceMutationFn = Apollo.MutationFunction<SetDefaultMaterialForJobServiceMutation, SetDefaultMaterialForJobServiceMutationVariables>;

/**
 * __useSetDefaultMaterialForJobServiceMutation__
 *
 * To run a mutation, you first call `useSetDefaultMaterialForJobServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultMaterialForJobServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultMaterialForJobServiceMutation, { data, loading, error }] = useSetDefaultMaterialForJobServiceMutation({
 *   variables: {
 *      materialForServiceOfferingId: // value for 'materialForServiceOfferingId'
 *      isDefault: // value for 'isDefault'
 *   },
 * });
 */
export function useSetDefaultMaterialForJobServiceMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultMaterialForJobServiceMutation, SetDefaultMaterialForJobServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultMaterialForJobServiceMutation, SetDefaultMaterialForJobServiceMutationVariables>(SetDefaultMaterialForJobServiceDocument, options);
      }
export type SetDefaultMaterialForJobServiceMutationHookResult = ReturnType<typeof useSetDefaultMaterialForJobServiceMutation>;
export type SetDefaultMaterialForJobServiceMutationResult = Apollo.MutationResult<SetDefaultMaterialForJobServiceMutation>;
export type SetDefaultMaterialForJobServiceMutationOptions = Apollo.BaseMutationOptions<SetDefaultMaterialForJobServiceMutation, SetDefaultMaterialForJobServiceMutationVariables>;
export const UpdateServiceProductOfferingDocument = gql`
    mutation UpdateServiceProductOffering($id: Int!, $isDeleted: Boolean, $price: Decimal, $cost: Decimal, $minimum: Decimal) {
  updateServiceProductOffering(input: {id: $id, isDeleted: $isDeleted, price: $price, cost: $cost, minimum: $minimum}) {
    similar
    updated {
      id
      isDeleted
      pricePerUnit
      costPerUnit
      minimumInUnit
    }
  }
}
    `;
export type UpdateServiceProductOfferingMutationFn = Apollo.MutationFunction<UpdateServiceProductOfferingMutation, UpdateServiceProductOfferingMutationVariables>;

/**
 * __useUpdateServiceProductOfferingMutation__
 *
 * To run a mutation, you first call `useUpdateServiceProductOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceProductOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceProductOfferingMutation, { data, loading, error }] = useUpdateServiceProductOfferingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDeleted: // value for 'isDeleted'
 *      price: // value for 'price'
 *      cost: // value for 'cost'
 *      minimum: // value for 'minimum'
 *   },
 * });
 */
export function useUpdateServiceProductOfferingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceProductOfferingMutation, UpdateServiceProductOfferingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceProductOfferingMutation, UpdateServiceProductOfferingMutationVariables>(UpdateServiceProductOfferingDocument, options);
      }
export type UpdateServiceProductOfferingMutationHookResult = ReturnType<typeof useUpdateServiceProductOfferingMutation>;
export type UpdateServiceProductOfferingMutationResult = Apollo.MutationResult<UpdateServiceProductOfferingMutation>;
export type UpdateServiceProductOfferingMutationOptions = Apollo.BaseMutationOptions<UpdateServiceProductOfferingMutation, UpdateServiceProductOfferingMutationVariables>;
export const GetSubstrateIdDocument = gql`
    query GetSubstrateId {
  substrateId {
    id
    type
  }
}
    `;

/**
 * __useGetSubstrateIdQuery__
 *
 * To run a query within a React component, call `useGetSubstrateIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubstrateIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubstrateIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubstrateIdQuery(baseOptions?: Apollo.QueryHookOptions<GetSubstrateIdQuery, GetSubstrateIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubstrateIdQuery, GetSubstrateIdQueryVariables>(GetSubstrateIdDocument, options);
      }
export function useGetSubstrateIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubstrateIdQuery, GetSubstrateIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubstrateIdQuery, GetSubstrateIdQueryVariables>(GetSubstrateIdDocument, options);
        }
export type GetSubstrateIdQueryHookResult = ReturnType<typeof useGetSubstrateIdQuery>;
export type GetSubstrateIdLazyQueryHookResult = ReturnType<typeof useGetSubstrateIdLazyQuery>;
export type GetSubstrateIdQueryResult = Apollo.QueryResult<GetSubstrateIdQuery, GetSubstrateIdQueryVariables>;
export const TryRemoveLaborRuleDocument = gql`
    mutation TryRemoveLaborRule($ruleId: Int!) {
  tryRemoveLaborRule(id: $ruleId) {
    isSuccessful
    message
  }
}
    `;
export type TryRemoveLaborRuleMutationFn = Apollo.MutationFunction<TryRemoveLaborRuleMutation, TryRemoveLaborRuleMutationVariables>;

/**
 * __useTryRemoveLaborRuleMutation__
 *
 * To run a mutation, you first call `useTryRemoveLaborRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTryRemoveLaborRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tryRemoveLaborRuleMutation, { data, loading, error }] = useTryRemoveLaborRuleMutation({
 *   variables: {
 *      ruleId: // value for 'ruleId'
 *   },
 * });
 */
export function useTryRemoveLaborRuleMutation(baseOptions?: Apollo.MutationHookOptions<TryRemoveLaborRuleMutation, TryRemoveLaborRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TryRemoveLaborRuleMutation, TryRemoveLaborRuleMutationVariables>(TryRemoveLaborRuleDocument, options);
      }
export type TryRemoveLaborRuleMutationHookResult = ReturnType<typeof useTryRemoveLaborRuleMutation>;
export type TryRemoveLaborRuleMutationResult = Apollo.MutationResult<TryRemoveLaborRuleMutation>;
export type TryRemoveLaborRuleMutationOptions = Apollo.BaseMutationOptions<TryRemoveLaborRuleMutation, TryRemoveLaborRuleMutationVariables>;
export const GetMeasurementUnitDocument = gql`
    query GetMeasurementUnit($ids: [Int!]!) {
  measurementUnit(ids: $ids) {
    id
    name
    short
    long
  }
}
    `;

/**
 * __useGetMeasurementUnitQuery__
 *
 * To run a query within a React component, call `useGetMeasurementUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasurementUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasurementUnitQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMeasurementUnitQuery(baseOptions: Apollo.QueryHookOptions<GetMeasurementUnitQuery, GetMeasurementUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeasurementUnitQuery, GetMeasurementUnitQueryVariables>(GetMeasurementUnitDocument, options);
      }
export function useGetMeasurementUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeasurementUnitQuery, GetMeasurementUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeasurementUnitQuery, GetMeasurementUnitQueryVariables>(GetMeasurementUnitDocument, options);
        }
export type GetMeasurementUnitQueryHookResult = ReturnType<typeof useGetMeasurementUnitQuery>;
export type GetMeasurementUnitLazyQueryHookResult = ReturnType<typeof useGetMeasurementUnitLazyQuery>;
export type GetMeasurementUnitQueryResult = Apollo.QueryResult<GetMeasurementUnitQuery, GetMeasurementUnitQueryVariables>;
export const GetAcceptedPaymentMethodsDocument = gql`
    query GetAcceptedPaymentMethods {
  acceptedPaymentMethods {
    id
    method
  }
}
    `;

/**
 * __useGetAcceptedPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetAcceptedPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcceptedPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcceptedPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAcceptedPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GetAcceptedPaymentMethodsQuery, GetAcceptedPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAcceptedPaymentMethodsQuery, GetAcceptedPaymentMethodsQueryVariables>(GetAcceptedPaymentMethodsDocument, options);
      }
export function useGetAcceptedPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAcceptedPaymentMethodsQuery, GetAcceptedPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAcceptedPaymentMethodsQuery, GetAcceptedPaymentMethodsQueryVariables>(GetAcceptedPaymentMethodsDocument, options);
        }
export type GetAcceptedPaymentMethodsQueryHookResult = ReturnType<typeof useGetAcceptedPaymentMethodsQuery>;
export type GetAcceptedPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetAcceptedPaymentMethodsLazyQuery>;
export type GetAcceptedPaymentMethodsQueryResult = Apollo.QueryResult<GetAcceptedPaymentMethodsQuery, GetAcceptedPaymentMethodsQueryVariables>;
export const GetAllProductStyleDocument = gql`
    query GetAllProductStyle {
  allProductStyle {
    id
    productTypeId
    styleName
    productSpecificationId
    singleCostPerSqft
    bulkCostPerSqft
    pricingFactor
    shippingPrice
  }
}
    `;

/**
 * __useGetAllProductStyleQuery__
 *
 * To run a query within a React component, call `useGetAllProductStyleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductStyleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductStyleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductStyleQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProductStyleQuery, GetAllProductStyleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProductStyleQuery, GetAllProductStyleQueryVariables>(GetAllProductStyleDocument, options);
      }
export function useGetAllProductStyleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProductStyleQuery, GetAllProductStyleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProductStyleQuery, GetAllProductStyleQueryVariables>(GetAllProductStyleDocument, options);
        }
export type GetAllProductStyleQueryHookResult = ReturnType<typeof useGetAllProductStyleQuery>;
export type GetAllProductStyleLazyQueryHookResult = ReturnType<typeof useGetAllProductStyleLazyQuery>;
export type GetAllProductStyleQueryResult = Apollo.QueryResult<GetAllProductStyleQuery, GetAllProductStyleQueryVariables>;
export const GetPartialProductStyleDocument = gql`
    query GetPartialProductStyle($styleId: Int!) {
  partialProductStyle(styleId: $styleId) {
    id
    style
    singleCostPerSqft
    bulkCostPerSqft
    pricingFactor
    shippingPrice
    spec {
      id
    }
    promo {
      promoPrice
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useGetPartialProductStyleQuery__
 *
 * To run a query within a React component, call `useGetPartialProductStyleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartialProductStyleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartialProductStyleQuery({
 *   variables: {
 *      styleId: // value for 'styleId'
 *   },
 * });
 */
export function useGetPartialProductStyleQuery(baseOptions: Apollo.QueryHookOptions<GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables>(GetPartialProductStyleDocument, options);
      }
export function useGetPartialProductStyleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables>(GetPartialProductStyleDocument, options);
        }
export type GetPartialProductStyleQueryHookResult = ReturnType<typeof useGetPartialProductStyleQuery>;
export type GetPartialProductStyleLazyQueryHookResult = ReturnType<typeof useGetPartialProductStyleLazyQuery>;
export type GetPartialProductStyleQueryResult = Apollo.QueryResult<GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables>;
export const GetPartialProductStylesDocument = gql`
    query GetPartialProductStyles($productTypeId: Int!, $vendorId: Int) {
  partialProductStyles(productId: $productTypeId, vendorId: $vendorId) {
    id
    style
    singleCostPerSqft
    bulkCostPerSqft
    pricingFactor
    shippingPrice
    spec {
      id
    }
    promo {
      promoPrice
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useGetPartialProductStylesQuery__
 *
 * To run a query within a React component, call `useGetPartialProductStylesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartialProductStylesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartialProductStylesQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useGetPartialProductStylesQuery(baseOptions: Apollo.QueryHookOptions<GetPartialProductStylesQuery, GetPartialProductStylesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartialProductStylesQuery, GetPartialProductStylesQueryVariables>(GetPartialProductStylesDocument, options);
      }
export function useGetPartialProductStylesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartialProductStylesQuery, GetPartialProductStylesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartialProductStylesQuery, GetPartialProductStylesQueryVariables>(GetPartialProductStylesDocument, options);
        }
export type GetPartialProductStylesQueryHookResult = ReturnType<typeof useGetPartialProductStylesQuery>;
export type GetPartialProductStylesLazyQueryHookResult = ReturnType<typeof useGetPartialProductStylesLazyQuery>;
export type GetPartialProductStylesQueryResult = Apollo.QueryResult<GetPartialProductStylesQuery, GetPartialProductStylesQueryVariables>;
export const GetProductColorOptionsDocument = gql`
    query GetProductColorOptions($styleId: Int!) {
  productColorOptions(styleId: $styleId) {
    id
    color
  }
}
    `;

/**
 * __useGetProductColorOptionsQuery__
 *
 * To run a query within a React component, call `useGetProductColorOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductColorOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductColorOptionsQuery({
 *   variables: {
 *      styleId: // value for 'styleId'
 *   },
 * });
 */
export function useGetProductColorOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetProductColorOptionsQuery, GetProductColorOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductColorOptionsQuery, GetProductColorOptionsQueryVariables>(GetProductColorOptionsDocument, options);
      }
export function useGetProductColorOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductColorOptionsQuery, GetProductColorOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductColorOptionsQuery, GetProductColorOptionsQueryVariables>(GetProductColorOptionsDocument, options);
        }
export type GetProductColorOptionsQueryHookResult = ReturnType<typeof useGetProductColorOptionsQuery>;
export type GetProductColorOptionsLazyQueryHookResult = ReturnType<typeof useGetProductColorOptionsLazyQuery>;
export type GetProductColorOptionsQueryResult = Apollo.QueryResult<GetProductColorOptionsQuery, GetProductColorOptionsQueryVariables>;
export const GetProductColorsAndSourcesDocument = gql`
    query GetProductColorsAndSources($styleId: Int!, $vendorId: Int) {
  productColorsAndSources(styleId: $styleId, vendorId: $vendorId) {
    id
    color
    source {
      id
      vendorId
      productSpecificationId
      style
      color
      orderQuantity
      singleCostPerSqft
      bulkCostPerSqft
      oneTimeCostPerSqft
    }
  }
}
    `;

/**
 * __useGetProductColorsAndSourcesQuery__
 *
 * To run a query within a React component, call `useGetProductColorsAndSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductColorsAndSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductColorsAndSourcesQuery({
 *   variables: {
 *      styleId: // value for 'styleId'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useGetProductColorsAndSourcesQuery(baseOptions: Apollo.QueryHookOptions<GetProductColorsAndSourcesQuery, GetProductColorsAndSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductColorsAndSourcesQuery, GetProductColorsAndSourcesQueryVariables>(GetProductColorsAndSourcesDocument, options);
      }
export function useGetProductColorsAndSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductColorsAndSourcesQuery, GetProductColorsAndSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductColorsAndSourcesQuery, GetProductColorsAndSourcesQueryVariables>(GetProductColorsAndSourcesDocument, options);
        }
export type GetProductColorsAndSourcesQueryHookResult = ReturnType<typeof useGetProductColorsAndSourcesQuery>;
export type GetProductColorsAndSourcesLazyQueryHookResult = ReturnType<typeof useGetProductColorsAndSourcesLazyQuery>;
export type GetProductColorsAndSourcesQueryResult = Apollo.QueryResult<GetProductColorsAndSourcesQuery, GetProductColorsAndSourcesQueryVariables>;
export const GetProductSpecDocument = gql`
    query GetProductSpec($productSpecId: Int!) {
  productSpec(productSpecId: $productSpecId) {
    id
    specName
    sqft
    structuredProductDetails {
      structuredProductDetailOptionId
      measurementValue
      measurementUnitId
    }
    unstructuredProductDetails {
      unstructuredProductDetailOptionId
      value
    }
    installationConfigurations {
      jobServiceId
      substrateId
    }
  }
}
    `;

/**
 * __useGetProductSpecQuery__
 *
 * To run a query within a React component, call `useGetProductSpecQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSpecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSpecQuery({
 *   variables: {
 *      productSpecId: // value for 'productSpecId'
 *   },
 * });
 */
export function useGetProductSpecQuery(baseOptions: Apollo.QueryHookOptions<GetProductSpecQuery, GetProductSpecQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductSpecQuery, GetProductSpecQueryVariables>(GetProductSpecDocument, options);
      }
export function useGetProductSpecLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductSpecQuery, GetProductSpecQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductSpecQuery, GetProductSpecQueryVariables>(GetProductSpecDocument, options);
        }
export type GetProductSpecQueryHookResult = ReturnType<typeof useGetProductSpecQuery>;
export type GetProductSpecLazyQueryHookResult = ReturnType<typeof useGetProductSpecLazyQuery>;
export type GetProductSpecQueryResult = Apollo.QueryResult<GetProductSpecQuery, GetProductSpecQueryVariables>;
export const GetProductSpecForStyleDocument = gql`
    query GetProductSpecForStyle($styleId: Int!) {
  productSpecForStyle(styleId: $styleId) {
    id
    specName
    sqft
    structuredProductDetails {
      structuredProductDetailOptionId
      measurementValue
      measurementUnitId
    }
    unstructuredProductDetails {
      unstructuredProductDetailOptionId
      value
    }
    installationConfigurations {
      jobServiceId
      substrateId
    }
  }
}
    `;

/**
 * __useGetProductSpecForStyleQuery__
 *
 * To run a query within a React component, call `useGetProductSpecForStyleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSpecForStyleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSpecForStyleQuery({
 *   variables: {
 *      styleId: // value for 'styleId'
 *   },
 * });
 */
export function useGetProductSpecForStyleQuery(baseOptions: Apollo.QueryHookOptions<GetProductSpecForStyleQuery, GetProductSpecForStyleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductSpecForStyleQuery, GetProductSpecForStyleQueryVariables>(GetProductSpecForStyleDocument, options);
      }
export function useGetProductSpecForStyleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductSpecForStyleQuery, GetProductSpecForStyleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductSpecForStyleQuery, GetProductSpecForStyleQueryVariables>(GetProductSpecForStyleDocument, options);
        }
export type GetProductSpecForStyleQueryHookResult = ReturnType<typeof useGetProductSpecForStyleQuery>;
export type GetProductSpecForStyleLazyQueryHookResult = ReturnType<typeof useGetProductSpecForStyleLazyQuery>;
export type GetProductSpecForStyleQueryResult = Apollo.QueryResult<GetProductSpecForStyleQuery, GetProductSpecForStyleQueryVariables>;
export const GetProductSpecNamesDocument = gql`
    query GetProductSpecNames($productTypeId: Int! = -1, $styleId: Int! = -1, $colorId: Int! = -1) {
  productSpecNames(productTypeId: $productTypeId, styleId: $styleId, colorId: $colorId) {
    id
    specName: name
    color
    style
    type
  }
}
    `;

/**
 * __useGetProductSpecNamesQuery__
 *
 * To run a query within a React component, call `useGetProductSpecNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSpecNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSpecNamesQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *      styleId: // value for 'styleId'
 *      colorId: // value for 'colorId'
 *   },
 * });
 */
export function useGetProductSpecNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductSpecNamesQuery, GetProductSpecNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductSpecNamesQuery, GetProductSpecNamesQueryVariables>(GetProductSpecNamesDocument, options);
      }
export function useGetProductSpecNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductSpecNamesQuery, GetProductSpecNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductSpecNamesQuery, GetProductSpecNamesQueryVariables>(GetProductSpecNamesDocument, options);
        }
export type GetProductSpecNamesQueryHookResult = ReturnType<typeof useGetProductSpecNamesQuery>;
export type GetProductSpecNamesLazyQueryHookResult = ReturnType<typeof useGetProductSpecNamesLazyQuery>;
export type GetProductSpecNamesQueryResult = Apollo.QueryResult<GetProductSpecNamesQuery, GetProductSpecNamesQueryVariables>;
export const GetProductStyleDocument = gql`
    query GetProductStyle($styleId: Int!) {
  productStyle(styleId: $styleId) {
    id
    style
    singleCostPerSqft
    bulkCostPerSqft
    spec {
      id
      specName
      sqft
      structuredProductDetails {
        structuredProductDetailOptionId
        measurementValue
        measurementUnitId
      }
      unstructuredProductDetails {
        unstructuredProductDetailOptionId
        value
      }
      installationConfigurations {
        jobServiceId
        substrateId
      }
    }
  }
}
    `;

/**
 * __useGetProductStyleQuery__
 *
 * To run a query within a React component, call `useGetProductStyleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductStyleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductStyleQuery({
 *   variables: {
 *      styleId: // value for 'styleId'
 *   },
 * });
 */
export function useGetProductStyleQuery(baseOptions: Apollo.QueryHookOptions<GetProductStyleQuery, GetProductStyleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductStyleQuery, GetProductStyleQueryVariables>(GetProductStyleDocument, options);
      }
export function useGetProductStyleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductStyleQuery, GetProductStyleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductStyleQuery, GetProductStyleQueryVariables>(GetProductStyleDocument, options);
        }
export type GetProductStyleQueryHookResult = ReturnType<typeof useGetProductStyleQuery>;
export type GetProductStyleLazyQueryHookResult = ReturnType<typeof useGetProductStyleLazyQuery>;
export type GetProductStyleQueryResult = Apollo.QueryResult<GetProductStyleQuery, GetProductStyleQueryVariables>;
export const GetProductStyleIdsDocument = gql`
    query GetProductStyleIds($productTypeId: Int!, $vendorId: Int) {
  productStyleIdsForProductType(productId: $productTypeId, vendorId: $vendorId)
}
    `;

/**
 * __useGetProductStyleIdsQuery__
 *
 * To run a query within a React component, call `useGetProductStyleIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductStyleIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductStyleIdsQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useGetProductStyleIdsQuery(baseOptions: Apollo.QueryHookOptions<GetProductStyleIdsQuery, GetProductStyleIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductStyleIdsQuery, GetProductStyleIdsQueryVariables>(GetProductStyleIdsDocument, options);
      }
export function useGetProductStyleIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductStyleIdsQuery, GetProductStyleIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductStyleIdsQuery, GetProductStyleIdsQueryVariables>(GetProductStyleIdsDocument, options);
        }
export type GetProductStyleIdsQueryHookResult = ReturnType<typeof useGetProductStyleIdsQuery>;
export type GetProductStyleIdsLazyQueryHookResult = ReturnType<typeof useGetProductStyleIdsLazyQuery>;
export type GetProductStyleIdsQueryResult = Apollo.QueryResult<GetProductStyleIdsQuery, GetProductStyleIdsQueryVariables>;
export const GetProductStyleOptionsDocument = gql`
    query GetProductStyleOptions($typeId: Int!) {
  productStyleOptions(typeId: $typeId) {
    id
    style
  }
}
    `;

/**
 * __useGetProductStyleOptionsQuery__
 *
 * To run a query within a React component, call `useGetProductStyleOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductStyleOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductStyleOptionsQuery({
 *   variables: {
 *      typeId: // value for 'typeId'
 *   },
 * });
 */
export function useGetProductStyleOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetProductStyleOptionsQuery, GetProductStyleOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductStyleOptionsQuery, GetProductStyleOptionsQueryVariables>(GetProductStyleOptionsDocument, options);
      }
export function useGetProductStyleOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductStyleOptionsQuery, GetProductStyleOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductStyleOptionsQuery, GetProductStyleOptionsQueryVariables>(GetProductStyleOptionsDocument, options);
        }
export type GetProductStyleOptionsQueryHookResult = ReturnType<typeof useGetProductStyleOptionsQuery>;
export type GetProductStyleOptionsLazyQueryHookResult = ReturnType<typeof useGetProductStyleOptionsLazyQuery>;
export type GetProductStyleOptionsQueryResult = Apollo.QueryResult<GetProductStyleOptionsQuery, GetProductStyleOptionsQueryVariables>;
export const GetProductTypeOptionsDocument = gql`
    query GetProductTypeOptions($surface: SurfaceType) {
  options: productTypeOptions(surface: $surface) {
    id
    type
  }
}
    `;

/**
 * __useGetProductTypeOptionsQuery__
 *
 * To run a query within a React component, call `useGetProductTypeOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductTypeOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductTypeOptionsQuery({
 *   variables: {
 *      surface: // value for 'surface'
 *   },
 * });
 */
export function useGetProductTypeOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductTypeOptionsQuery, GetProductTypeOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductTypeOptionsQuery, GetProductTypeOptionsQueryVariables>(GetProductTypeOptionsDocument, options);
      }
export function useGetProductTypeOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductTypeOptionsQuery, GetProductTypeOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductTypeOptionsQuery, GetProductTypeOptionsQueryVariables>(GetProductTypeOptionsDocument, options);
        }
export type GetProductTypeOptionsQueryHookResult = ReturnType<typeof useGetProductTypeOptionsQuery>;
export type GetProductTypeOptionsLazyQueryHookResult = ReturnType<typeof useGetProductTypeOptionsLazyQuery>;
export type GetProductTypeOptionsQueryResult = Apollo.QueryResult<GetProductTypeOptionsQuery, GetProductTypeOptionsQueryVariables>;
export const GetStructuredProductDetailOptionsDocument = gql`
    query GetStructuredProductDetailOptions($productTypeId: Int!) {
  structuredProductDetailOptions(productTypeId: $productTypeId) {
    id
    name
    unitOptions {
      unitId
      unit
    }
  }
}
    `;

/**
 * __useGetStructuredProductDetailOptionsQuery__
 *
 * To run a query within a React component, call `useGetStructuredProductDetailOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStructuredProductDetailOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStructuredProductDetailOptionsQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useGetStructuredProductDetailOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetStructuredProductDetailOptionsQuery, GetStructuredProductDetailOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStructuredProductDetailOptionsQuery, GetStructuredProductDetailOptionsQueryVariables>(GetStructuredProductDetailOptionsDocument, options);
      }
export function useGetStructuredProductDetailOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStructuredProductDetailOptionsQuery, GetStructuredProductDetailOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStructuredProductDetailOptionsQuery, GetStructuredProductDetailOptionsQueryVariables>(GetStructuredProductDetailOptionsDocument, options);
        }
export type GetStructuredProductDetailOptionsQueryHookResult = ReturnType<typeof useGetStructuredProductDetailOptionsQuery>;
export type GetStructuredProductDetailOptionsLazyQueryHookResult = ReturnType<typeof useGetStructuredProductDetailOptionsLazyQuery>;
export type GetStructuredProductDetailOptionsQueryResult = Apollo.QueryResult<GetStructuredProductDetailOptionsQuery, GetStructuredProductDetailOptionsQueryVariables>;
export const GetUnstructuredProductDetailOptionsDocument = gql`
    query GetUnstructuredProductDetailOptions($productTypeId: Int!) {
  unstructuredProductDetailOptions(productTypeId: $productTypeId) {
    id
    name
  }
}
    `;

/**
 * __useGetUnstructuredProductDetailOptionsQuery__
 *
 * To run a query within a React component, call `useGetUnstructuredProductDetailOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnstructuredProductDetailOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnstructuredProductDetailOptionsQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useGetUnstructuredProductDetailOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetUnstructuredProductDetailOptionsQuery, GetUnstructuredProductDetailOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnstructuredProductDetailOptionsQuery, GetUnstructuredProductDetailOptionsQueryVariables>(GetUnstructuredProductDetailOptionsDocument, options);
      }
export function useGetUnstructuredProductDetailOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnstructuredProductDetailOptionsQuery, GetUnstructuredProductDetailOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnstructuredProductDetailOptionsQuery, GetUnstructuredProductDetailOptionsQueryVariables>(GetUnstructuredProductDetailOptionsDocument, options);
        }
export type GetUnstructuredProductDetailOptionsQueryHookResult = ReturnType<typeof useGetUnstructuredProductDetailOptionsQuery>;
export type GetUnstructuredProductDetailOptionsLazyQueryHookResult = ReturnType<typeof useGetUnstructuredProductDetailOptionsLazyQuery>;
export type GetUnstructuredProductDetailOptionsQueryResult = Apollo.QueryResult<GetUnstructuredProductDetailOptionsQuery, GetUnstructuredProductDetailOptionsQueryVariables>;
export const GetVendorsForColorDocument = gql`
    query GetVendorsForColor($colorIds: [Int!]!) {
  vendorsForColors(colorIds: $colorIds) {
    id
    vendors {
      id
      name
    }
  }
}
    `;

/**
 * __useGetVendorsForColorQuery__
 *
 * To run a query within a React component, call `useGetVendorsForColorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorsForColorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorsForColorQuery({
 *   variables: {
 *      colorIds: // value for 'colorIds'
 *   },
 * });
 */
export function useGetVendorsForColorQuery(baseOptions: Apollo.QueryHookOptions<GetVendorsForColorQuery, GetVendorsForColorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorsForColorQuery, GetVendorsForColorQueryVariables>(GetVendorsForColorDocument, options);
      }
export function useGetVendorsForColorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorsForColorQuery, GetVendorsForColorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorsForColorQuery, GetVendorsForColorQueryVariables>(GetVendorsForColorDocument, options);
        }
export type GetVendorsForColorQueryHookResult = ReturnType<typeof useGetVendorsForColorQuery>;
export type GetVendorsForColorLazyQueryHookResult = ReturnType<typeof useGetVendorsForColorLazyQuery>;
export type GetVendorsForColorQueryResult = Apollo.QueryResult<GetVendorsForColorQuery, GetVendorsForColorQueryVariables>;
export const AddNewColorDocument = gql`
    mutation AddNewColor($styleId: Int!, $colorName: String!, $specName: String!, $specDetails: ProductSpecificationInput!, $vendor: VendorSourcedProductInput!) {
  addNewColor(styleId: $styleId, colorName: $colorName, specName: $specName, specDetails: $specDetails, vendor: $vendor)
}
    `;
export type AddNewColorMutationFn = Apollo.MutationFunction<AddNewColorMutation, AddNewColorMutationVariables>;

/**
 * __useAddNewColorMutation__
 *
 * To run a mutation, you first call `useAddNewColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewColorMutation, { data, loading, error }] = useAddNewColorMutation({
 *   variables: {
 *      styleId: // value for 'styleId'
 *      colorName: // value for 'colorName'
 *      specName: // value for 'specName'
 *      specDetails: // value for 'specDetails'
 *      vendor: // value for 'vendor'
 *   },
 * });
 */
export function useAddNewColorMutation(baseOptions?: Apollo.MutationHookOptions<AddNewColorMutation, AddNewColorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewColorMutation, AddNewColorMutationVariables>(AddNewColorDocument, options);
      }
export type AddNewColorMutationHookResult = ReturnType<typeof useAddNewColorMutation>;
export type AddNewColorMutationResult = Apollo.MutationResult<AddNewColorMutation>;
export type AddNewColorMutationOptions = Apollo.BaseMutationOptions<AddNewColorMutation, AddNewColorMutationVariables>;
export const AddNewProductSourceDocument = gql`
    mutation AddNewProductSource($productSpecId: Int!, $vendor: VendorSourcedProductInput!) {
  addNewProductSource(productSpecId: $productSpecId, vendor: $vendor)
}
    `;
export type AddNewProductSourceMutationFn = Apollo.MutationFunction<AddNewProductSourceMutation, AddNewProductSourceMutationVariables>;

/**
 * __useAddNewProductSourceMutation__
 *
 * To run a mutation, you first call `useAddNewProductSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewProductSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewProductSourceMutation, { data, loading, error }] = useAddNewProductSourceMutation({
 *   variables: {
 *      productSpecId: // value for 'productSpecId'
 *      vendor: // value for 'vendor'
 *   },
 * });
 */
export function useAddNewProductSourceMutation(baseOptions?: Apollo.MutationHookOptions<AddNewProductSourceMutation, AddNewProductSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewProductSourceMutation, AddNewProductSourceMutationVariables>(AddNewProductSourceDocument, options);
      }
export type AddNewProductSourceMutationHookResult = ReturnType<typeof useAddNewProductSourceMutation>;
export type AddNewProductSourceMutationResult = Apollo.MutationResult<AddNewProductSourceMutation>;
export type AddNewProductSourceMutationOptions = Apollo.BaseMutationOptions<AddNewProductSourceMutation, AddNewProductSourceMutationVariables>;
export const AddNewProductSpecDocument = gql`
    mutation AddNewProductSpec($colorId: Int!, $specName: String!, $specDetails: ProductSpecificationInput!, $vendor: VendorSourcedProductInput!) {
  addNewProductSpec(colorId: $colorId, specName: $specName, specDetails: $specDetails, vendor: $vendor)
}
    `;
export type AddNewProductSpecMutationFn = Apollo.MutationFunction<AddNewProductSpecMutation, AddNewProductSpecMutationVariables>;

/**
 * __useAddNewProductSpecMutation__
 *
 * To run a mutation, you first call `useAddNewProductSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewProductSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewProductSpecMutation, { data, loading, error }] = useAddNewProductSpecMutation({
 *   variables: {
 *      colorId: // value for 'colorId'
 *      specName: // value for 'specName'
 *      specDetails: // value for 'specDetails'
 *      vendor: // value for 'vendor'
 *   },
 * });
 */
export function useAddNewProductSpecMutation(baseOptions?: Apollo.MutationHookOptions<AddNewProductSpecMutation, AddNewProductSpecMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewProductSpecMutation, AddNewProductSpecMutationVariables>(AddNewProductSpecDocument, options);
      }
export type AddNewProductSpecMutationHookResult = ReturnType<typeof useAddNewProductSpecMutation>;
export type AddNewProductSpecMutationResult = Apollo.MutationResult<AddNewProductSpecMutation>;
export type AddNewProductSpecMutationOptions = Apollo.BaseMutationOptions<AddNewProductSpecMutation, AddNewProductSpecMutationVariables>;
export const AddNewStyleDocument = gql`
    mutation AddNewStyle($productTypeId: Int!, $styleName: String!, $singleCostPerSqft: Decimal!, $bulkCostPerSqft: Decimal!, $specName: String!, $sqft: Decimal!, $structuredProductDetails: [StructuredProductDetailInput!]!, $unstructuredProductDetails: [UnstructuredProductDetailInput!]!, $installationConfigurations: [ProductCanBeInstalledInput!]!) {
  addNewStyle(productTypeId: $productTypeId, styleName: $styleName, singleCostPerSqft: $singleCostPerSqft, bulkCostPerSqft: $bulkCostPerSqft, specName: $specName, sqft: $sqft, structuredProductDetails: $structuredProductDetails, unstructuredProductDetails: $unstructuredProductDetails, installationConfigurations: $installationConfigurations) {
    id
    style
    singleCostPerSqft
    bulkCostPerSqft
    spec {
      id
      specName
    }
  }
}
    `;
export type AddNewStyleMutationFn = Apollo.MutationFunction<AddNewStyleMutation, AddNewStyleMutationVariables>;

/**
 * __useAddNewStyleMutation__
 *
 * To run a mutation, you first call `useAddNewStyleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewStyleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewStyleMutation, { data, loading, error }] = useAddNewStyleMutation({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *      styleName: // value for 'styleName'
 *      singleCostPerSqft: // value for 'singleCostPerSqft'
 *      bulkCostPerSqft: // value for 'bulkCostPerSqft'
 *      specName: // value for 'specName'
 *      sqft: // value for 'sqft'
 *      structuredProductDetails: // value for 'structuredProductDetails'
 *      unstructuredProductDetails: // value for 'unstructuredProductDetails'
 *      installationConfigurations: // value for 'installationConfigurations'
 *   },
 * });
 */
export function useAddNewStyleMutation(baseOptions?: Apollo.MutationHookOptions<AddNewStyleMutation, AddNewStyleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewStyleMutation, AddNewStyleMutationVariables>(AddNewStyleDocument, options);
      }
export type AddNewStyleMutationHookResult = ReturnType<typeof useAddNewStyleMutation>;
export type AddNewStyleMutationResult = Apollo.MutationResult<AddNewStyleMutation>;
export type AddNewStyleMutationOptions = Apollo.BaseMutationOptions<AddNewStyleMutation, AddNewStyleMutationVariables>;
export const BulkAddNewCarpetColorsDocument = gql`
    mutation BulkAddNewCarpetColors($styleId: Int!, $vendorId: Int!, $vendorStyle: String!, $orderSize: Decimal!, $pairs: [ColorNamePairInput!]!, $singleCost: Decimal, $bulkCost: Decimal) {
  bulkAddNewCarpetColors(styleId: $styleId, vendorId: $vendorId, vendorStyle: $vendorStyle, orderSize: $orderSize, singleCostPerSqft: $singleCost, bulkCostPerSqft: $bulkCost, pairs: $pairs)
}
    `;
export type BulkAddNewCarpetColorsMutationFn = Apollo.MutationFunction<BulkAddNewCarpetColorsMutation, BulkAddNewCarpetColorsMutationVariables>;

/**
 * __useBulkAddNewCarpetColorsMutation__
 *
 * To run a mutation, you first call `useBulkAddNewCarpetColorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAddNewCarpetColorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAddNewCarpetColorsMutation, { data, loading, error }] = useBulkAddNewCarpetColorsMutation({
 *   variables: {
 *      styleId: // value for 'styleId'
 *      vendorId: // value for 'vendorId'
 *      vendorStyle: // value for 'vendorStyle'
 *      orderSize: // value for 'orderSize'
 *      pairs: // value for 'pairs'
 *      singleCost: // value for 'singleCost'
 *      bulkCost: // value for 'bulkCost'
 *   },
 * });
 */
export function useBulkAddNewCarpetColorsMutation(baseOptions?: Apollo.MutationHookOptions<BulkAddNewCarpetColorsMutation, BulkAddNewCarpetColorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkAddNewCarpetColorsMutation, BulkAddNewCarpetColorsMutationVariables>(BulkAddNewCarpetColorsDocument, options);
      }
export type BulkAddNewCarpetColorsMutationHookResult = ReturnType<typeof useBulkAddNewCarpetColorsMutation>;
export type BulkAddNewCarpetColorsMutationResult = Apollo.MutationResult<BulkAddNewCarpetColorsMutation>;
export type BulkAddNewCarpetColorsMutationOptions = Apollo.BaseMutationOptions<BulkAddNewCarpetColorsMutation, BulkAddNewCarpetColorsMutationVariables>;
export const UpdateProductCostsDocument = gql`
    mutation UpdateProductCosts($products: [ProductIdentifiersWithCostsInput!]!, $isSSMode: Boolean!) {
  updateProductCosts(products: $products, isSSMode: $isSSMode) {
    styleId
    sourceId
    singleCost
    bulkCost
    oneTimeCost
  }
}
    `;
export type UpdateProductCostsMutationFn = Apollo.MutationFunction<UpdateProductCostsMutation, UpdateProductCostsMutationVariables>;

/**
 * __useUpdateProductCostsMutation__
 *
 * To run a mutation, you first call `useUpdateProductCostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCostsMutation, { data, loading, error }] = useUpdateProductCostsMutation({
 *   variables: {
 *      products: // value for 'products'
 *      isSSMode: // value for 'isSSMode'
 *   },
 * });
 */
export function useUpdateProductCostsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCostsMutation, UpdateProductCostsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductCostsMutation, UpdateProductCostsMutationVariables>(UpdateProductCostsDocument, options);
      }
export type UpdateProductCostsMutationHookResult = ReturnType<typeof useUpdateProductCostsMutation>;
export type UpdateProductCostsMutationResult = Apollo.MutationResult<UpdateProductCostsMutation>;
export type UpdateProductCostsMutationOptions = Apollo.BaseMutationOptions<UpdateProductCostsMutation, UpdateProductCostsMutationVariables>;
export const UpdateProductPricesDocument = gql`
    mutation UpdateProductPrices($products: [ProductIdentifierWithPriceInput!]!, $interpretCarpetShippingInSqyd: Boolean) {
  updateProductPrices(products: $products, interpretCarpetShippingInSqyd: $interpretCarpetShippingInSqyd) {
    styleId
    updatedFactor
    updatedShipping
    updatedPromo {
      promoPrice
      startDate
      endDate
    }
  }
}
    `;
export type UpdateProductPricesMutationFn = Apollo.MutationFunction<UpdateProductPricesMutation, UpdateProductPricesMutationVariables>;

/**
 * __useUpdateProductPricesMutation__
 *
 * To run a mutation, you first call `useUpdateProductPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductPricesMutation, { data, loading, error }] = useUpdateProductPricesMutation({
 *   variables: {
 *      products: // value for 'products'
 *      interpretCarpetShippingInSqyd: // value for 'interpretCarpetShippingInSqyd'
 *   },
 * });
 */
export function useUpdateProductPricesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductPricesMutation, UpdateProductPricesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductPricesMutation, UpdateProductPricesMutationVariables>(UpdateProductPricesDocument, options);
      }
export type UpdateProductPricesMutationHookResult = ReturnType<typeof useUpdateProductPricesMutation>;
export type UpdateProductPricesMutationResult = Apollo.MutationResult<UpdateProductPricesMutation>;
export type UpdateProductPricesMutationOptions = Apollo.BaseMutationOptions<UpdateProductPricesMutation, UpdateProductPricesMutationVariables>;
export const GetActiveJobsForDashboardDocument = gql`
    query GetActiveJobsForDashboard {
  activeJobsForDashboard {
    jobConfigId
    firstName
    lastName
  }
}
    `;

/**
 * __useGetActiveJobsForDashboardQuery__
 *
 * To run a query within a React component, call `useGetActiveJobsForDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveJobsForDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveJobsForDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveJobsForDashboardQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveJobsForDashboardQuery, GetActiveJobsForDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveJobsForDashboardQuery, GetActiveJobsForDashboardQueryVariables>(GetActiveJobsForDashboardDocument, options);
      }
export function useGetActiveJobsForDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveJobsForDashboardQuery, GetActiveJobsForDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveJobsForDashboardQuery, GetActiveJobsForDashboardQueryVariables>(GetActiveJobsForDashboardDocument, options);
        }
export type GetActiveJobsForDashboardQueryHookResult = ReturnType<typeof useGetActiveJobsForDashboardQuery>;
export type GetActiveJobsForDashboardLazyQueryHookResult = ReturnType<typeof useGetActiveJobsForDashboardLazyQuery>;
export type GetActiveJobsForDashboardQueryResult = Apollo.QueryResult<GetActiveJobsForDashboardQuery, GetActiveJobsForDashboardQueryVariables>;
export const GetAllAreaAndRoomNotesDocument = gql`
    query GetAllAreaAndRoomNotes($jobConfigurationId: Int!) {
  allAreaAndRoomNotes(jobConfigurationId: $jobConfigurationId) {
    areaNotes {
      id
      notes
      labels {
        id
        roomId
        hasCloset
        labelNumber
        label {
          id
          label
          fullLabel
        }
        customName
      }
    }
    roomNotes {
      id
      notes
      labels {
        id
        roomId
        hasCloset
        labelNumber
        label {
          id
          label
          fullLabel
        }
        customName
      }
    }
  }
}
    `;

/**
 * __useGetAllAreaAndRoomNotesQuery__
 *
 * To run a query within a React component, call `useGetAllAreaAndRoomNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAreaAndRoomNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAreaAndRoomNotesQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetAllAreaAndRoomNotesQuery(baseOptions: Apollo.QueryHookOptions<GetAllAreaAndRoomNotesQuery, GetAllAreaAndRoomNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAreaAndRoomNotesQuery, GetAllAreaAndRoomNotesQueryVariables>(GetAllAreaAndRoomNotesDocument, options);
      }
export function useGetAllAreaAndRoomNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAreaAndRoomNotesQuery, GetAllAreaAndRoomNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAreaAndRoomNotesQuery, GetAllAreaAndRoomNotesQueryVariables>(GetAllAreaAndRoomNotesDocument, options);
        }
export type GetAllAreaAndRoomNotesQueryHookResult = ReturnType<typeof useGetAllAreaAndRoomNotesQuery>;
export type GetAllAreaAndRoomNotesLazyQueryHookResult = ReturnType<typeof useGetAllAreaAndRoomNotesLazyQuery>;
export type GetAllAreaAndRoomNotesQueryResult = Apollo.QueryResult<GetAllAreaAndRoomNotesQuery, GetAllAreaAndRoomNotesQueryVariables>;
export const GetAllAreaProductDetailsForJobDocument = gql`
    query GetAllAreaProductDetailsForJob($jobConfigurationId: Int!) {
  allAreaProductDetailsForJob(jobConfigurationId: $jobConfigurationId) {
    id
    productTypeId
    styleId
    colorId
    includedInQuote
  }
}
    `;

/**
 * __useGetAllAreaProductDetailsForJobQuery__
 *
 * To run a query within a React component, call `useGetAllAreaProductDetailsForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAreaProductDetailsForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAreaProductDetailsForJobQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetAllAreaProductDetailsForJobQuery(baseOptions: Apollo.QueryHookOptions<GetAllAreaProductDetailsForJobQuery, GetAllAreaProductDetailsForJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAreaProductDetailsForJobQuery, GetAllAreaProductDetailsForJobQueryVariables>(GetAllAreaProductDetailsForJobDocument, options);
      }
export function useGetAllAreaProductDetailsForJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAreaProductDetailsForJobQuery, GetAllAreaProductDetailsForJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAreaProductDetailsForJobQuery, GetAllAreaProductDetailsForJobQueryVariables>(GetAllAreaProductDetailsForJobDocument, options);
        }
export type GetAllAreaProductDetailsForJobQueryHookResult = ReturnType<typeof useGetAllAreaProductDetailsForJobQuery>;
export type GetAllAreaProductDetailsForJobLazyQueryHookResult = ReturnType<typeof useGetAllAreaProductDetailsForJobLazyQuery>;
export type GetAllAreaProductDetailsForJobQueryResult = Apollo.QueryResult<GetAllAreaProductDetailsForJobQuery, GetAllAreaProductDetailsForJobQueryVariables>;
export const GetAllAreasForJobDocument = gql`
    query GetAllAreasForJob($jobConfigurationId: Int!) {
  allAreasForJob(jobConfigurationId: $jobConfigurationId) {
    id
    productTypeId
    productTypeName
    colorId
    colorName
    styleId
    styleName
    sqftWasteFactor
    lnftWasteFactor
    rooms {
      id
      labels {
        id
        roomId
        hasCloset
        customName
        labelNumber
        label {
          id
          fullLabel
          label
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllAreasForJobQuery__
 *
 * To run a query within a React component, call `useGetAllAreasForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAreasForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAreasForJobQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetAllAreasForJobQuery(baseOptions: Apollo.QueryHookOptions<GetAllAreasForJobQuery, GetAllAreasForJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAreasForJobQuery, GetAllAreasForJobQueryVariables>(GetAllAreasForJobDocument, options);
      }
export function useGetAllAreasForJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAreasForJobQuery, GetAllAreasForJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAreasForJobQuery, GetAllAreasForJobQueryVariables>(GetAllAreasForJobDocument, options);
        }
export type GetAllAreasForJobQueryHookResult = ReturnType<typeof useGetAllAreasForJobQuery>;
export type GetAllAreasForJobLazyQueryHookResult = ReturnType<typeof useGetAllAreasForJobLazyQuery>;
export type GetAllAreasForJobQueryResult = Apollo.QueryResult<GetAllAreasForJobQuery, GetAllAreasForJobQueryVariables>;
export const GetAllInstallationNotesDocument = gql`
    query GetAllInstallationNotes($jobConfigurationId: Int!) {
  allInstallationNotes(jobConfigurationId: $jobConfigurationId) {
    id
    contractorFirstName
    contractorLastName
    startDate
    endDate
    notes
  }
}
    `;

/**
 * __useGetAllInstallationNotesQuery__
 *
 * To run a query within a React component, call `useGetAllInstallationNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInstallationNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInstallationNotesQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetAllInstallationNotesQuery(baseOptions: Apollo.QueryHookOptions<GetAllInstallationNotesQuery, GetAllInstallationNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInstallationNotesQuery, GetAllInstallationNotesQueryVariables>(GetAllInstallationNotesDocument, options);
      }
export function useGetAllInstallationNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInstallationNotesQuery, GetAllInstallationNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInstallationNotesQuery, GetAllInstallationNotesQueryVariables>(GetAllInstallationNotesDocument, options);
        }
export type GetAllInstallationNotesQueryHookResult = ReturnType<typeof useGetAllInstallationNotesQuery>;
export type GetAllInstallationNotesLazyQueryHookResult = ReturnType<typeof useGetAllInstallationNotesLazyQuery>;
export type GetAllInstallationNotesQueryResult = Apollo.QueryResult<GetAllInstallationNotesQuery, GetAllInstallationNotesQueryVariables>;
export const GetAllJobsForProjectCoordinatorDocument = gql`
    query GetAllJobsForProjectCoordinator {
  allJobsForProjectCoordinator {
    id
    projectCoordinator {
      id
      firstName
      lastName
    }
    dateClaimed
    sAHNumber
    contract {
      id
      totalDepositPaid
      jobConfigurationId
      signingDate
      totalPrice
    }
    financing {
      id
      perMonthAmount
      financingDownPayment
      financingOption {
        id
      }
    }
    jobCOD
    depositThresholdHit
    totalPaid
    customer {
      id
      firstName
      lastName
      businessName
      primaryPhone
      primaryAddress {
        id
        city
      }
      email
    }
    salesperson {
      id
      workerId
      firstName
      lastName
    }
    installationAppointments {
      id
      contractorId
      contractorFirstName
      contractorLastName
      dates
      startDatePassed
      endDatePassed
      isComplete
      cod
      collectedCod
      productTypeId
      productType
      styleAmounts {
        id
        style
        sqft
      }
      productTypeIndex
    }
    installationsToSchedule {
      id
      productType
      sqft
      cod
      productTypeIndex
      jobId
    }
    totalPrice
    cwcDeadline
    cwcNumber
  }
}
    `;

/**
 * __useGetAllJobsForProjectCoordinatorQuery__
 *
 * To run a query within a React component, call `useGetAllJobsForProjectCoordinatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllJobsForProjectCoordinatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllJobsForProjectCoordinatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllJobsForProjectCoordinatorQuery(baseOptions?: Apollo.QueryHookOptions<GetAllJobsForProjectCoordinatorQuery, GetAllJobsForProjectCoordinatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllJobsForProjectCoordinatorQuery, GetAllJobsForProjectCoordinatorQueryVariables>(GetAllJobsForProjectCoordinatorDocument, options);
      }
export function useGetAllJobsForProjectCoordinatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllJobsForProjectCoordinatorQuery, GetAllJobsForProjectCoordinatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllJobsForProjectCoordinatorQuery, GetAllJobsForProjectCoordinatorQueryVariables>(GetAllJobsForProjectCoordinatorDocument, options);
        }
export type GetAllJobsForProjectCoordinatorQueryHookResult = ReturnType<typeof useGetAllJobsForProjectCoordinatorQuery>;
export type GetAllJobsForProjectCoordinatorLazyQueryHookResult = ReturnType<typeof useGetAllJobsForProjectCoordinatorLazyQuery>;
export type GetAllJobsForProjectCoordinatorQueryResult = Apollo.QueryResult<GetAllJobsForProjectCoordinatorQuery, GetAllJobsForProjectCoordinatorQueryVariables>;
export const GetAllProjectCoordinatorsDocument = gql`
    query GetAllProjectCoordinators {
  allProjectCoordinators {
    ...Worker
  }
}
    ${WorkerFragmentDoc}`;

/**
 * __useGetAllProjectCoordinatorsQuery__
 *
 * To run a query within a React component, call `useGetAllProjectCoordinatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProjectCoordinatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProjectCoordinatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProjectCoordinatorsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProjectCoordinatorsQuery, GetAllProjectCoordinatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProjectCoordinatorsQuery, GetAllProjectCoordinatorsQueryVariables>(GetAllProjectCoordinatorsDocument, options);
      }
export function useGetAllProjectCoordinatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProjectCoordinatorsQuery, GetAllProjectCoordinatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProjectCoordinatorsQuery, GetAllProjectCoordinatorsQueryVariables>(GetAllProjectCoordinatorsDocument, options);
        }
export type GetAllProjectCoordinatorsQueryHookResult = ReturnType<typeof useGetAllProjectCoordinatorsQuery>;
export type GetAllProjectCoordinatorsLazyQueryHookResult = ReturnType<typeof useGetAllProjectCoordinatorsLazyQuery>;
export type GetAllProjectCoordinatorsQueryResult = Apollo.QueryResult<GetAllProjectCoordinatorsQuery, GetAllProjectCoordinatorsQueryVariables>;
export const GetAllRoomsForJobDocument = gql`
    query GetAllRoomsForJob($jobConfigurationId: Int!) {
  allRoomsForJob(jobConfigurationId: $jobConfigurationId) {
    id
    areaId
    sqft
    lnft
    substrateId
    labels {
      id
      roomId
      hasCloset
      customName
      labelNumber
      label {
        id
        fullLabel
        label
      }
    }
  }
}
    `;

/**
 * __useGetAllRoomsForJobQuery__
 *
 * To run a query within a React component, call `useGetAllRoomsForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRoomsForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRoomsForJobQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetAllRoomsForJobQuery(baseOptions: Apollo.QueryHookOptions<GetAllRoomsForJobQuery, GetAllRoomsForJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRoomsForJobQuery, GetAllRoomsForJobQueryVariables>(GetAllRoomsForJobDocument, options);
      }
export function useGetAllRoomsForJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRoomsForJobQuery, GetAllRoomsForJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRoomsForJobQuery, GetAllRoomsForJobQueryVariables>(GetAllRoomsForJobDocument, options);
        }
export type GetAllRoomsForJobQueryHookResult = ReturnType<typeof useGetAllRoomsForJobQuery>;
export type GetAllRoomsForJobLazyQueryHookResult = ReturnType<typeof useGetAllRoomsForJobLazyQuery>;
export type GetAllRoomsForJobQueryResult = Apollo.QueryResult<GetAllRoomsForJobQuery, GetAllRoomsForJobQueryVariables>;
export const GetAppointmentWorkOrderDocument = gql`
    query GetAppointmentWorkOrder($installationAppointmentId: Int!, $jobConfigurationId: Int!) {
  appointmentWorkOrder(installationAppointmentId: $installationAppointmentId, jobConfigurationId: $jobConfigurationId) {
    contractor {
      id
      firstName
      lastName
    }
    jobNotes
    installationNotes
    installationAppointmentId
    hasFinancing
    remainingCod
    dates
    areaBreakdowns {
      areaId
      sqftWasteFactor
      lnftWasteFactor
      lineNum
      areaNotes
      areaLabels {
        id
        roomId
        hasCloset
        customName
        labelNumber
        label {
          id
          label
          fullLabel
        }
      }
      rooms {
        id
        sqft
        notes
        labels {
          id
          roomId
          hasCloset
          labelNumber
          label {
            id
            label
            fullLabel
          }
          customName
        }
      }
      product {
        productTypeId
        productType
        productStyle
        productColor
      }
      productSqft
      services {
        id
        jobServiceId
        serviceTypeId
        serviceType
        serviceDescription
        customerDoesService
        isActive
        laborAmount
        minimumLaborAmount
        sqftScaleFactor
        lnftScaleFactor
        laborPriceUnit
        laborPricePerUnit
        materialAmount
        materialCategoryId
        materialPricePerUnit
        materialCategoryName
        materialCategoryPriceUnit
        room {
          id
          notes
          sqft
          labels {
            id
            roomId
            hasCloset
            labelNumber
            label {
              id
              label
              fullLabel
            }
            customName
          }
        }
      }
      customServices {
        id
        areaId
        roomIds
        description
        price
        contractorPercentage
      }
    }
  }
}
    `;

/**
 * __useGetAppointmentWorkOrderQuery__
 *
 * To run a query within a React component, call `useGetAppointmentWorkOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentWorkOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentWorkOrderQuery({
 *   variables: {
 *      installationAppointmentId: // value for 'installationAppointmentId'
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetAppointmentWorkOrderQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentWorkOrderQuery, GetAppointmentWorkOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentWorkOrderQuery, GetAppointmentWorkOrderQueryVariables>(GetAppointmentWorkOrderDocument, options);
      }
export function useGetAppointmentWorkOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentWorkOrderQuery, GetAppointmentWorkOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentWorkOrderQuery, GetAppointmentWorkOrderQueryVariables>(GetAppointmentWorkOrderDocument, options);
        }
export type GetAppointmentWorkOrderQueryHookResult = ReturnType<typeof useGetAppointmentWorkOrderQuery>;
export type GetAppointmentWorkOrderLazyQueryHookResult = ReturnType<typeof useGetAppointmentWorkOrderLazyQuery>;
export type GetAppointmentWorkOrderQueryResult = Apollo.QueryResult<GetAppointmentWorkOrderQuery, GetAppointmentWorkOrderQueryVariables>;
export const GetCwcNumberDocument = gql`
    query GetCwcNumber($jobId: Int!) {
  cWCNumber(jobId: $jobId) {
    id
    cwcNumber
  }
}
    `;

/**
 * __useGetCwcNumberQuery__
 *
 * To run a query within a React component, call `useGetCwcNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCwcNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCwcNumberQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetCwcNumberQuery(baseOptions: Apollo.QueryHookOptions<GetCwcNumberQuery, GetCwcNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCwcNumberQuery, GetCwcNumberQueryVariables>(GetCwcNumberDocument, options);
      }
export function useGetCwcNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCwcNumberQuery, GetCwcNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCwcNumberQuery, GetCwcNumberQueryVariables>(GetCwcNumberDocument, options);
        }
export type GetCwcNumberQueryHookResult = ReturnType<typeof useGetCwcNumberQuery>;
export type GetCwcNumberLazyQueryHookResult = ReturnType<typeof useGetCwcNumberLazyQuery>;
export type GetCwcNumberQueryResult = Apollo.QueryResult<GetCwcNumberQuery, GetCwcNumberQueryVariables>;
export const GetCwcJobsForProjectCoordinatorDocument = gql`
    query GetCwcJobsForProjectCoordinator {
  cwcJobsForProjectCoordinator {
    id
    projectCoordinator {
      id
      firstName
      lastName
      email
      isCheckedIn
    }
    dateClaimed
    sAHNumber
    contract {
      totalDepositPaid
      id
      jobConfigurationId
      signingDate
      isCancelled
      totalPrice
    }
    financing {
      id
      financingDownPayment
      financingAccountNumber
      perMonthAmount
      financingOption {
        id
        term
        minAmount
        typeId
        fee
        startDate
        endDate
        tranCode
        promoParentTranCode
        slot
      }
    }
    jobCOD
    depositThresholdHit
    totalPaid
    customer {
      id
      firstName
      lastName
      businessName
      primaryPhone
      primaryAddress {
        id
        streetAddress
        city
        zip
        apartmentNumber
      }
      email
      customerContacts {
        ...CustomerContact
      }
    }
    salesperson {
      id
      workerId
      firstName
      email
      lastName
      isCheckedIn
    }
    installationAppointments {
      id
      contractorId
      contractorFirstName
      contractorLastName
      dates
      startDatePassed
      endDatePassed
      isComplete
      productType
      styleAmounts {
        id
        style
        sqft
      }
      cod
      collectedCod
      productTypeIndex
      productTypeId
    }
    installationsToSchedule {
      id
      productType
      sqft
      cod
      productTypeIndex
      jobId
    }
    totalPrice
    cwcDeadline
    cwcNumber
  }
}
    ${CustomerContactFragmentDoc}`;

/**
 * __useGetCwcJobsForProjectCoordinatorQuery__
 *
 * To run a query within a React component, call `useGetCwcJobsForProjectCoordinatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCwcJobsForProjectCoordinatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCwcJobsForProjectCoordinatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCwcJobsForProjectCoordinatorQuery(baseOptions?: Apollo.QueryHookOptions<GetCwcJobsForProjectCoordinatorQuery, GetCwcJobsForProjectCoordinatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCwcJobsForProjectCoordinatorQuery, GetCwcJobsForProjectCoordinatorQueryVariables>(GetCwcJobsForProjectCoordinatorDocument, options);
      }
export function useGetCwcJobsForProjectCoordinatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCwcJobsForProjectCoordinatorQuery, GetCwcJobsForProjectCoordinatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCwcJobsForProjectCoordinatorQuery, GetCwcJobsForProjectCoordinatorQueryVariables>(GetCwcJobsForProjectCoordinatorDocument, options);
        }
export type GetCwcJobsForProjectCoordinatorQueryHookResult = ReturnType<typeof useGetCwcJobsForProjectCoordinatorQuery>;
export type GetCwcJobsForProjectCoordinatorLazyQueryHookResult = ReturnType<typeof useGetCwcJobsForProjectCoordinatorLazyQuery>;
export type GetCwcJobsForProjectCoordinatorQueryResult = Apollo.QueryResult<GetCwcJobsForProjectCoordinatorQuery, GetCwcJobsForProjectCoordinatorQueryVariables>;
export const GetInstallationCodBoundsForExistingDocument = gql`
    query GetInstallationCodBoundsForExisting($appointmentId: Int!, $newStartDate: DateTime!) {
  installationCodBoundsForExisting(appointmentId: $appointmentId, newStartDate: $newStartDate) {
    lowerBound
    upperBound
  }
}
    `;

/**
 * __useGetInstallationCodBoundsForExistingQuery__
 *
 * To run a query within a React component, call `useGetInstallationCodBoundsForExistingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallationCodBoundsForExistingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallationCodBoundsForExistingQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      newStartDate: // value for 'newStartDate'
 *   },
 * });
 */
export function useGetInstallationCodBoundsForExistingQuery(baseOptions: Apollo.QueryHookOptions<GetInstallationCodBoundsForExistingQuery, GetInstallationCodBoundsForExistingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstallationCodBoundsForExistingQuery, GetInstallationCodBoundsForExistingQueryVariables>(GetInstallationCodBoundsForExistingDocument, options);
      }
export function useGetInstallationCodBoundsForExistingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstallationCodBoundsForExistingQuery, GetInstallationCodBoundsForExistingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstallationCodBoundsForExistingQuery, GetInstallationCodBoundsForExistingQueryVariables>(GetInstallationCodBoundsForExistingDocument, options);
        }
export type GetInstallationCodBoundsForExistingQueryHookResult = ReturnType<typeof useGetInstallationCodBoundsForExistingQuery>;
export type GetInstallationCodBoundsForExistingLazyQueryHookResult = ReturnType<typeof useGetInstallationCodBoundsForExistingLazyQuery>;
export type GetInstallationCodBoundsForExistingQueryResult = Apollo.QueryResult<GetInstallationCodBoundsForExistingQuery, GetInstallationCodBoundsForExistingQueryVariables>;
export const GetInstallationCodBoundsForNewDocument = gql`
    query GetInstallationCodBoundsForNew($jobConfigurationId: Int!, $startDate: DateTime!, $roomIds: [Int!]!) {
  installationCodBoundsForNew(jobConfigurationId: $jobConfigurationId, startDate: $startDate, roomIds: $roomIds) {
    lowerBound
    upperBound
  }
}
    `;

/**
 * __useGetInstallationCodBoundsForNewQuery__
 *
 * To run a query within a React component, call `useGetInstallationCodBoundsForNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallationCodBoundsForNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallationCodBoundsForNewQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      startDate: // value for 'startDate'
 *      roomIds: // value for 'roomIds'
 *   },
 * });
 */
export function useGetInstallationCodBoundsForNewQuery(baseOptions: Apollo.QueryHookOptions<GetInstallationCodBoundsForNewQuery, GetInstallationCodBoundsForNewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstallationCodBoundsForNewQuery, GetInstallationCodBoundsForNewQueryVariables>(GetInstallationCodBoundsForNewDocument, options);
      }
export function useGetInstallationCodBoundsForNewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstallationCodBoundsForNewQuery, GetInstallationCodBoundsForNewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstallationCodBoundsForNewQuery, GetInstallationCodBoundsForNewQueryVariables>(GetInstallationCodBoundsForNewDocument, options);
        }
export type GetInstallationCodBoundsForNewQueryHookResult = ReturnType<typeof useGetInstallationCodBoundsForNewQuery>;
export type GetInstallationCodBoundsForNewLazyQueryHookResult = ReturnType<typeof useGetInstallationCodBoundsForNewLazyQuery>;
export type GetInstallationCodBoundsForNewQueryResult = Apollo.QueryResult<GetInstallationCodBoundsForNewQuery, GetInstallationCodBoundsForNewQueryVariables>;
export const GetJobBreakdownDocument = gql`
    query GetJobBreakdown($jobConfigurationId: Int!, $hideNoLaborRAndR: Boolean) {
  jobBreakdown(jobConfigurationId: $jobConfigurationId, hideNoLaborRAndR: $hideNoLaborRAndR) {
    jobConfigurationId
    notes
    areaBreakdowns {
      areaId
      lineNum
      areaNotes
      areaLabels {
        id
        roomId
        hasCloset
        customName
        labelNumber
        label {
          id
          label
          fullLabel
        }
      }
      rooms {
        id
        areaId
        sqft
        notes
        price {
          mSRP
          savings
          total
        }
        labels {
          id
          roomId
          hasCloset
          labelNumber
          label {
            id
            label
            fullLabel
          }
          customName
        }
      }
      product {
        productTypeId
        productType
        productStyle
        productColor
      }
      productSqft
      services {
        id
        jobServiceId
        serviceTypeId
        serviceType
        serviceDescription
        customerDoesService
        isActive
        laborAmount
        minimumLaborAmount
        sqftScaleFactor
        lnftScaleFactor
        laborPriceUnit
        laborPricePerUnit
        materialAmount
        materialCategoryId
        materialPricePerUnit
        materialCategoryName
        materialCategoryPriceUnit
        room {
          id
          notes
          sqft
          labels {
            id
            roomId
            hasCloset
            labelNumber
            label {
              id
              label
              fullLabel
            }
            customName
          }
        }
      }
      customServices {
        id
        areaId
        roomIds
        description
        price
        contractorPercentage
      }
    }
  }
}
    `;

/**
 * __useGetJobBreakdownQuery__
 *
 * To run a query within a React component, call `useGetJobBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobBreakdownQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      hideNoLaborRAndR: // value for 'hideNoLaborRAndR'
 *   },
 * });
 */
export function useGetJobBreakdownQuery(baseOptions: Apollo.QueryHookOptions<GetJobBreakdownQuery, GetJobBreakdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobBreakdownQuery, GetJobBreakdownQueryVariables>(GetJobBreakdownDocument, options);
      }
export function useGetJobBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobBreakdownQuery, GetJobBreakdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobBreakdownQuery, GetJobBreakdownQueryVariables>(GetJobBreakdownDocument, options);
        }
export type GetJobBreakdownQueryHookResult = ReturnType<typeof useGetJobBreakdownQuery>;
export type GetJobBreakdownLazyQueryHookResult = ReturnType<typeof useGetJobBreakdownLazyQuery>;
export type GetJobBreakdownQueryResult = Apollo.QueryResult<GetJobBreakdownQuery, GetJobBreakdownQueryVariables>;
export const GetJobNotesDocument = gql`
    query GetJobNotes($jobConfigurationId: Int!) {
  jobNotes(jobConfigurationId: $jobConfigurationId)
}
    `;

/**
 * __useGetJobNotesQuery__
 *
 * To run a query within a React component, call `useGetJobNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobNotesQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobNotesQuery(baseOptions: Apollo.QueryHookOptions<GetJobNotesQuery, GetJobNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobNotesQuery, GetJobNotesQueryVariables>(GetJobNotesDocument, options);
      }
export function useGetJobNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobNotesQuery, GetJobNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobNotesQuery, GetJobNotesQueryVariables>(GetJobNotesDocument, options);
        }
export type GetJobNotesQueryHookResult = ReturnType<typeof useGetJobNotesQuery>;
export type GetJobNotesLazyQueryHookResult = ReturnType<typeof useGetJobNotesLazyQuery>;
export type GetJobNotesQueryResult = Apollo.QueryResult<GetJobNotesQuery, GetJobNotesQueryVariables>;
export const GetJobWorkOrdersDocument = gql`
    query GetJobWorkOrders($jobConfigurationId: Int!) {
  jobWorkOrders(jobConfigurationId: $jobConfigurationId) {
    contractor {
      id
      firstName
      lastName
    }
    jobNotes
    installationNotes
    installationAppointmentId
    hasFinancing
    remainingCod
    dates
    areaBreakdowns {
      areaId
      sqftWasteFactor
      lnftWasteFactor
      lineNum
      areaNotes
      areaLabels {
        id
        roomId
        hasCloset
        customName
        labelNumber
        label {
          id
          label
          fullLabel
        }
      }
      rooms {
        id
        sqft
        notes
        labels {
          id
          roomId
          hasCloset
          labelNumber
          label {
            id
            label
            fullLabel
          }
          customName
        }
      }
      product {
        productTypeId
        productType
        productStyle
        productColor
      }
      productSqft
      services {
        id
        jobServiceId
        serviceTypeId
        serviceType
        serviceDescription
        customerDoesService
        isActive
        laborAmount
        minimumLaborAmount
        sqftScaleFactor
        lnftScaleFactor
        laborPriceUnit
        laborPricePerUnit
        materialAmount
        materialCategoryId
        materialPricePerUnit
        materialCategoryName
        materialCategoryPriceUnit
        room {
          id
          notes
          sqft
          labels {
            id
            roomId
            hasCloset
            labelNumber
            label {
              id
              label
              fullLabel
            }
            customName
          }
        }
      }
      customServices {
        id
        areaId
        roomIds
        description
        price
        contractorPercentage
      }
    }
  }
}
    `;

/**
 * __useGetJobWorkOrdersQuery__
 *
 * To run a query within a React component, call `useGetJobWorkOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobWorkOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobWorkOrdersQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetJobWorkOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetJobWorkOrdersQuery, GetJobWorkOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobWorkOrdersQuery, GetJobWorkOrdersQueryVariables>(GetJobWorkOrdersDocument, options);
      }
export function useGetJobWorkOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobWorkOrdersQuery, GetJobWorkOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobWorkOrdersQuery, GetJobWorkOrdersQueryVariables>(GetJobWorkOrdersDocument, options);
        }
export type GetJobWorkOrdersQueryHookResult = ReturnType<typeof useGetJobWorkOrdersQuery>;
export type GetJobWorkOrdersLazyQueryHookResult = ReturnType<typeof useGetJobWorkOrdersLazyQuery>;
export type GetJobWorkOrdersQueryResult = Apollo.QueryResult<GetJobWorkOrdersQuery, GetJobWorkOrdersQueryVariables>;
export const GetPaymentsPaneInfoDocument = gql`
    query GetPaymentsPaneInfo($jobConfigurationId: Int!, $jobContractId: Int!) {
  jobCOD(jobConfigurationId: $jobConfigurationId) {
    id
    remaining
  }
  jobConfiguration(jobConfigurationId: $jobConfigurationId) {
    price {
      total
    }
    financing {
      id
      perMonthAmount
      financingOption {
        id
        term
        minAmount
        typeId
        fee
        startDate
        endDate
        tranCode
        promoParentTranCode
        slot
      }
    }
  }
  financedAmount(jobConfigurationId: $jobConfigurationId)
  jobPayments(jobContractId: $jobContractId) {
    id
    amount
  }
}
    `;

/**
 * __useGetPaymentsPaneInfoQuery__
 *
 * To run a query within a React component, call `useGetPaymentsPaneInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsPaneInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsPaneInfoQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      jobContractId: // value for 'jobContractId'
 *   },
 * });
 */
export function useGetPaymentsPaneInfoQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentsPaneInfoQuery, GetPaymentsPaneInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentsPaneInfoQuery, GetPaymentsPaneInfoQueryVariables>(GetPaymentsPaneInfoDocument, options);
      }
export function useGetPaymentsPaneInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsPaneInfoQuery, GetPaymentsPaneInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentsPaneInfoQuery, GetPaymentsPaneInfoQueryVariables>(GetPaymentsPaneInfoDocument, options);
        }
export type GetPaymentsPaneInfoQueryHookResult = ReturnType<typeof useGetPaymentsPaneInfoQuery>;
export type GetPaymentsPaneInfoLazyQueryHookResult = ReturnType<typeof useGetPaymentsPaneInfoLazyQuery>;
export type GetPaymentsPaneInfoQueryResult = Apollo.QueryResult<GetPaymentsPaneInfoQuery, GetPaymentsPaneInfoQueryVariables>;
export const GetPicturesForServiceOrderDocument = gql`
    query GetPicturesForServiceOrder($id: Int!) {
  picturesForServiceOrder(id: $id) {
    id
    serviceOrderId
    picture {
      id
      description
    }
  }
}
    `;

/**
 * __useGetPicturesForServiceOrderQuery__
 *
 * To run a query within a React component, call `useGetPicturesForServiceOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPicturesForServiceOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPicturesForServiceOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPicturesForServiceOrderQuery(baseOptions: Apollo.QueryHookOptions<GetPicturesForServiceOrderQuery, GetPicturesForServiceOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPicturesForServiceOrderQuery, GetPicturesForServiceOrderQueryVariables>(GetPicturesForServiceOrderDocument, options);
      }
export function useGetPicturesForServiceOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPicturesForServiceOrderQuery, GetPicturesForServiceOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPicturesForServiceOrderQuery, GetPicturesForServiceOrderQueryVariables>(GetPicturesForServiceOrderDocument, options);
        }
export type GetPicturesForServiceOrderQueryHookResult = ReturnType<typeof useGetPicturesForServiceOrderQuery>;
export type GetPicturesForServiceOrderLazyQueryHookResult = ReturnType<typeof useGetPicturesForServiceOrderLazyQuery>;
export type GetPicturesForServiceOrderQueryResult = Apollo.QueryResult<GetPicturesForServiceOrderQuery, GetPicturesForServiceOrderQueryVariables>;
export const GetProductTypesInConfigurationWithSqftDocument = gql`
    query getProductTypesInConfigurationWithSqft($jobConfigurationId: Int!) {
  productTypesInConfigurationWithSqft(jobConfigurationId: $jobConfigurationId) {
    id
    type
    sqft
  }
}
    `;

/**
 * __useGetProductTypesInConfigurationWithSqftQuery__
 *
 * To run a query within a React component, call `useGetProductTypesInConfigurationWithSqftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductTypesInConfigurationWithSqftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductTypesInConfigurationWithSqftQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetProductTypesInConfigurationWithSqftQuery(baseOptions: Apollo.QueryHookOptions<GetProductTypesInConfigurationWithSqftQuery, GetProductTypesInConfigurationWithSqftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductTypesInConfigurationWithSqftQuery, GetProductTypesInConfigurationWithSqftQueryVariables>(GetProductTypesInConfigurationWithSqftDocument, options);
      }
export function useGetProductTypesInConfigurationWithSqftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductTypesInConfigurationWithSqftQuery, GetProductTypesInConfigurationWithSqftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductTypesInConfigurationWithSqftQuery, GetProductTypesInConfigurationWithSqftQueryVariables>(GetProductTypesInConfigurationWithSqftDocument, options);
        }
export type GetProductTypesInConfigurationWithSqftQueryHookResult = ReturnType<typeof useGetProductTypesInConfigurationWithSqftQuery>;
export type GetProductTypesInConfigurationWithSqftLazyQueryHookResult = ReturnType<typeof useGetProductTypesInConfigurationWithSqftLazyQuery>;
export type GetProductTypesInConfigurationWithSqftQueryResult = Apollo.QueryResult<GetProductTypesInConfigurationWithSqftQuery, GetProductTypesInConfigurationWithSqftQueryVariables>;
export const GetProjectCoordinatorFromConfigurationDocument = gql`
    query GetProjectCoordinatorFromConfiguration($jobConfigurationId: Int!) {
  projectCoordinatorFromConfiguration(jobConfigurationId: $jobConfigurationId) {
    id
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useGetProjectCoordinatorFromConfigurationQuery__
 *
 * To run a query within a React component, call `useGetProjectCoordinatorFromConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCoordinatorFromConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCoordinatorFromConfigurationQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetProjectCoordinatorFromConfigurationQuery(baseOptions: Apollo.QueryHookOptions<GetProjectCoordinatorFromConfigurationQuery, GetProjectCoordinatorFromConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectCoordinatorFromConfigurationQuery, GetProjectCoordinatorFromConfigurationQueryVariables>(GetProjectCoordinatorFromConfigurationDocument, options);
      }
export function useGetProjectCoordinatorFromConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectCoordinatorFromConfigurationQuery, GetProjectCoordinatorFromConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectCoordinatorFromConfigurationQuery, GetProjectCoordinatorFromConfigurationQueryVariables>(GetProjectCoordinatorFromConfigurationDocument, options);
        }
export type GetProjectCoordinatorFromConfigurationQueryHookResult = ReturnType<typeof useGetProjectCoordinatorFromConfigurationQuery>;
export type GetProjectCoordinatorFromConfigurationLazyQueryHookResult = ReturnType<typeof useGetProjectCoordinatorFromConfigurationLazyQuery>;
export type GetProjectCoordinatorFromConfigurationQueryResult = Apollo.QueryResult<GetProjectCoordinatorFromConfigurationQuery, GetProjectCoordinatorFromConfigurationQueryVariables>;
export const GetServiceOrderDocument = gql`
    query GetServiceOrder($serviceOrderId: Int!) {
  serviceOrder(serviceOrderId: $serviceOrderId) {
    id
    jobContractId
    serviceOrderTypeId
    serviceDescription
    calledInDate
    scheduledDate
    scheduleAsap
    completionDate
    vendorId
    contractorId
    comments
    additionalActionRequired
    additionalActionDescription
    chargeAmount
    product {
      productTypeId
      productType
      productStyleId
      productStyle
      productColorId
      productColor
    }
    areaId
    roomIds
  }
}
    `;

/**
 * __useGetServiceOrderQuery__
 *
 * To run a query within a React component, call `useGetServiceOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceOrderQuery({
 *   variables: {
 *      serviceOrderId: // value for 'serviceOrderId'
 *   },
 * });
 */
export function useGetServiceOrderQuery(baseOptions: Apollo.QueryHookOptions<GetServiceOrderQuery, GetServiceOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceOrderQuery, GetServiceOrderQueryVariables>(GetServiceOrderDocument, options);
      }
export function useGetServiceOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceOrderQuery, GetServiceOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceOrderQuery, GetServiceOrderQueryVariables>(GetServiceOrderDocument, options);
        }
export type GetServiceOrderQueryHookResult = ReturnType<typeof useGetServiceOrderQuery>;
export type GetServiceOrderLazyQueryHookResult = ReturnType<typeof useGetServiceOrderLazyQuery>;
export type GetServiceOrderQueryResult = Apollo.QueryResult<GetServiceOrderQuery, GetServiceOrderQueryVariables>;
export const GetServiceOrderStubsDocument = gql`
    query GetServiceOrderStubs($jobContractId: Int!) {
  serviceOrderStubs(jobContractId: $jobContractId) {
    id
    dateCalledIn
    product {
      productType
      productStyle
      productColor
    }
    pictures {
      id
      picture {
        id
        description
      }
    }
  }
}
    `;

/**
 * __useGetServiceOrderStubsQuery__
 *
 * To run a query within a React component, call `useGetServiceOrderStubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceOrderStubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceOrderStubsQuery({
 *   variables: {
 *      jobContractId: // value for 'jobContractId'
 *   },
 * });
 */
export function useGetServiceOrderStubsQuery(baseOptions: Apollo.QueryHookOptions<GetServiceOrderStubsQuery, GetServiceOrderStubsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceOrderStubsQuery, GetServiceOrderStubsQueryVariables>(GetServiceOrderStubsDocument, options);
      }
export function useGetServiceOrderStubsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceOrderStubsQuery, GetServiceOrderStubsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceOrderStubsQuery, GetServiceOrderStubsQueryVariables>(GetServiceOrderStubsDocument, options);
        }
export type GetServiceOrderStubsQueryHookResult = ReturnType<typeof useGetServiceOrderStubsQuery>;
export type GetServiceOrderStubsLazyQueryHookResult = ReturnType<typeof useGetServiceOrderStubsLazyQuery>;
export type GetServiceOrderStubsQueryResult = Apollo.QueryResult<GetServiceOrderStubsQuery, GetServiceOrderStubsQueryVariables>;
export const GetServiceOrderTypesDocument = gql`
    query GetServiceOrderTypes {
  serviceOrderTypes {
    id
    type
  }
}
    `;

/**
 * __useGetServiceOrderTypesQuery__
 *
 * To run a query within a React component, call `useGetServiceOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceOrderTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServiceOrderTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceOrderTypesQuery, GetServiceOrderTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceOrderTypesQuery, GetServiceOrderTypesQueryVariables>(GetServiceOrderTypesDocument, options);
      }
export function useGetServiceOrderTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceOrderTypesQuery, GetServiceOrderTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceOrderTypesQuery, GetServiceOrderTypesQueryVariables>(GetServiceOrderTypesDocument, options);
        }
export type GetServiceOrderTypesQueryHookResult = ReturnType<typeof useGetServiceOrderTypesQuery>;
export type GetServiceOrderTypesLazyQueryHookResult = ReturnType<typeof useGetServiceOrderTypesLazyQuery>;
export type GetServiceOrderTypesQueryResult = Apollo.QueryResult<GetServiceOrderTypesQuery, GetServiceOrderTypesQueryVariables>;
export const AddPicturesForServiceOrderDocument = gql`
    mutation AddPicturesForServiceOrder($serviceOrderId: Int!, $pictures: [PictureInput!]!) {
  addPicturesForServiceOrder(serviceOrderId: $serviceOrderId, pictures: $pictures)
}
    `;
export type AddPicturesForServiceOrderMutationFn = Apollo.MutationFunction<AddPicturesForServiceOrderMutation, AddPicturesForServiceOrderMutationVariables>;

/**
 * __useAddPicturesForServiceOrderMutation__
 *
 * To run a mutation, you first call `useAddPicturesForServiceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPicturesForServiceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPicturesForServiceOrderMutation, { data, loading, error }] = useAddPicturesForServiceOrderMutation({
 *   variables: {
 *      serviceOrderId: // value for 'serviceOrderId'
 *      pictures: // value for 'pictures'
 *   },
 * });
 */
export function useAddPicturesForServiceOrderMutation(baseOptions?: Apollo.MutationHookOptions<AddPicturesForServiceOrderMutation, AddPicturesForServiceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPicturesForServiceOrderMutation, AddPicturesForServiceOrderMutationVariables>(AddPicturesForServiceOrderDocument, options);
      }
export type AddPicturesForServiceOrderMutationHookResult = ReturnType<typeof useAddPicturesForServiceOrderMutation>;
export type AddPicturesForServiceOrderMutationResult = Apollo.MutationResult<AddPicturesForServiceOrderMutation>;
export type AddPicturesForServiceOrderMutationOptions = Apollo.BaseMutationOptions<AddPicturesForServiceOrderMutation, AddPicturesForServiceOrderMutationVariables>;
export const AddServiceOrderDocument = gql`
    mutation AddServiceOrder($serviceOrder: ServiceOrderInput!) {
  addServiceOrder(newServiceOrder: $serviceOrder)
}
    `;
export type AddServiceOrderMutationFn = Apollo.MutationFunction<AddServiceOrderMutation, AddServiceOrderMutationVariables>;

/**
 * __useAddServiceOrderMutation__
 *
 * To run a mutation, you first call `useAddServiceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddServiceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addServiceOrderMutation, { data, loading, error }] = useAddServiceOrderMutation({
 *   variables: {
 *      serviceOrder: // value for 'serviceOrder'
 *   },
 * });
 */
export function useAddServiceOrderMutation(baseOptions?: Apollo.MutationHookOptions<AddServiceOrderMutation, AddServiceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddServiceOrderMutation, AddServiceOrderMutationVariables>(AddServiceOrderDocument, options);
      }
export type AddServiceOrderMutationHookResult = ReturnType<typeof useAddServiceOrderMutation>;
export type AddServiceOrderMutationResult = Apollo.MutationResult<AddServiceOrderMutation>;
export type AddServiceOrderMutationOptions = Apollo.BaseMutationOptions<AddServiceOrderMutation, AddServiceOrderMutationVariables>;
export const AssignProjectCoordinatorDocument = gql`
    mutation AssignProjectCoordinator($workerId: Int!, $jobId: Int!) {
  assignProjectCoordinator(workerId: $workerId, jobId: $jobId)
}
    `;
export type AssignProjectCoordinatorMutationFn = Apollo.MutationFunction<AssignProjectCoordinatorMutation, AssignProjectCoordinatorMutationVariables>;

/**
 * __useAssignProjectCoordinatorMutation__
 *
 * To run a mutation, you first call `useAssignProjectCoordinatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignProjectCoordinatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignProjectCoordinatorMutation, { data, loading, error }] = useAssignProjectCoordinatorMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useAssignProjectCoordinatorMutation(baseOptions?: Apollo.MutationHookOptions<AssignProjectCoordinatorMutation, AssignProjectCoordinatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignProjectCoordinatorMutation, AssignProjectCoordinatorMutationVariables>(AssignProjectCoordinatorDocument, options);
      }
export type AssignProjectCoordinatorMutationHookResult = ReturnType<typeof useAssignProjectCoordinatorMutation>;
export type AssignProjectCoordinatorMutationResult = Apollo.MutationResult<AssignProjectCoordinatorMutation>;
export type AssignProjectCoordinatorMutationOptions = Apollo.BaseMutationOptions<AssignProjectCoordinatorMutation, AssignProjectCoordinatorMutationVariables>;
export const ClaimJobDocument = gql`
    mutation ClaimJob($jobId: Int!) {
  claimJob(jobId: $jobId)
}
    `;
export type ClaimJobMutationFn = Apollo.MutationFunction<ClaimJobMutation, ClaimJobMutationVariables>;

/**
 * __useClaimJobMutation__
 *
 * To run a mutation, you first call `useClaimJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimJobMutation, { data, loading, error }] = useClaimJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useClaimJobMutation(baseOptions?: Apollo.MutationHookOptions<ClaimJobMutation, ClaimJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimJobMutation, ClaimJobMutationVariables>(ClaimJobDocument, options);
      }
export type ClaimJobMutationHookResult = ReturnType<typeof useClaimJobMutation>;
export type ClaimJobMutationResult = Apollo.MutationResult<ClaimJobMutation>;
export type ClaimJobMutationOptions = Apollo.BaseMutationOptions<ClaimJobMutation, ClaimJobMutationVariables>;
export const DeletePictureForServiceOrderDocument = gql`
    mutation DeletePictureForServiceOrder($id: Int!) {
  deletePictureForServiceOrder(id: $id)
}
    `;
export type DeletePictureForServiceOrderMutationFn = Apollo.MutationFunction<DeletePictureForServiceOrderMutation, DeletePictureForServiceOrderMutationVariables>;

/**
 * __useDeletePictureForServiceOrderMutation__
 *
 * To run a mutation, you first call `useDeletePictureForServiceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePictureForServiceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePictureForServiceOrderMutation, { data, loading, error }] = useDeletePictureForServiceOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePictureForServiceOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeletePictureForServiceOrderMutation, DeletePictureForServiceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePictureForServiceOrderMutation, DeletePictureForServiceOrderMutationVariables>(DeletePictureForServiceOrderDocument, options);
      }
export type DeletePictureForServiceOrderMutationHookResult = ReturnType<typeof useDeletePictureForServiceOrderMutation>;
export type DeletePictureForServiceOrderMutationResult = Apollo.MutationResult<DeletePictureForServiceOrderMutation>;
export type DeletePictureForServiceOrderMutationOptions = Apollo.BaseMutationOptions<DeletePictureForServiceOrderMutation, DeletePictureForServiceOrderMutationVariables>;
export const DeleteServiceOrderDocument = gql`
    mutation DeleteServiceOrder($id: Int!) {
  deleteServiceOrder(id: $id)
}
    `;
export type DeleteServiceOrderMutationFn = Apollo.MutationFunction<DeleteServiceOrderMutation, DeleteServiceOrderMutationVariables>;

/**
 * __useDeleteServiceOrderMutation__
 *
 * To run a mutation, you first call `useDeleteServiceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceOrderMutation, { data, loading, error }] = useDeleteServiceOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServiceOrderMutation, DeleteServiceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServiceOrderMutation, DeleteServiceOrderMutationVariables>(DeleteServiceOrderDocument, options);
      }
export type DeleteServiceOrderMutationHookResult = ReturnType<typeof useDeleteServiceOrderMutation>;
export type DeleteServiceOrderMutationResult = Apollo.MutationResult<DeleteServiceOrderMutation>;
export type DeleteServiceOrderMutationOptions = Apollo.BaseMutationOptions<DeleteServiceOrderMutation, DeleteServiceOrderMutationVariables>;
export const MakeCallDocument = gql`
    mutation MakeCall($phoneNumber: String!, $alternativeUserEmail: String) {
  makeCall(phoneNumber: $phoneNumber, alternativeUserEmail: $alternativeUserEmail)
}
    `;
export type MakeCallMutationFn = Apollo.MutationFunction<MakeCallMutation, MakeCallMutationVariables>;

/**
 * __useMakeCallMutation__
 *
 * To run a mutation, you first call `useMakeCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeCallMutation, { data, loading, error }] = useMakeCallMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      alternativeUserEmail: // value for 'alternativeUserEmail'
 *   },
 * });
 */
export function useMakeCallMutation(baseOptions?: Apollo.MutationHookOptions<MakeCallMutation, MakeCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeCallMutation, MakeCallMutationVariables>(MakeCallDocument, options);
      }
export type MakeCallMutationHookResult = ReturnType<typeof useMakeCallMutation>;
export type MakeCallMutationResult = Apollo.MutationResult<MakeCallMutation>;
export type MakeCallMutationOptions = Apollo.BaseMutationOptions<MakeCallMutation, MakeCallMutationVariables>;
export const SubmitChangeOrderDocument = gql`
    mutation SubmitChangeOrder($originalJobConfigurationId: Int!, $scaledUpdatedJobConfiguration: JobConfigurationInput!) {
  submitChangeOrder(originalJobConfigurationId: $originalJobConfigurationId, scaledUpdatedJobConfiguration: $scaledUpdatedJobConfiguration)
}
    `;
export type SubmitChangeOrderMutationFn = Apollo.MutationFunction<SubmitChangeOrderMutation, SubmitChangeOrderMutationVariables>;

/**
 * __useSubmitChangeOrderMutation__
 *
 * To run a mutation, you first call `useSubmitChangeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitChangeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitChangeOrderMutation, { data, loading, error }] = useSubmitChangeOrderMutation({
 *   variables: {
 *      originalJobConfigurationId: // value for 'originalJobConfigurationId'
 *      scaledUpdatedJobConfiguration: // value for 'scaledUpdatedJobConfiguration'
 *   },
 * });
 */
export function useSubmitChangeOrderMutation(baseOptions?: Apollo.MutationHookOptions<SubmitChangeOrderMutation, SubmitChangeOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitChangeOrderMutation, SubmitChangeOrderMutationVariables>(SubmitChangeOrderDocument, options);
      }
export type SubmitChangeOrderMutationHookResult = ReturnType<typeof useSubmitChangeOrderMutation>;
export type SubmitChangeOrderMutationResult = Apollo.MutationResult<SubmitChangeOrderMutation>;
export type SubmitChangeOrderMutationOptions = Apollo.BaseMutationOptions<SubmitChangeOrderMutation, SubmitChangeOrderMutationVariables>;
export const UpdateCwcDocument = gql`
    mutation UpdateCWC($jobId: Int!, $numWeeks: Int) {
  updateCWC(jobId: $jobId, numWeeks: $numWeeks)
}
    `;
export type UpdateCwcMutationFn = Apollo.MutationFunction<UpdateCwcMutation, UpdateCwcMutationVariables>;

/**
 * __useUpdateCwcMutation__
 *
 * To run a mutation, you first call `useUpdateCwcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCwcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCwcMutation, { data, loading, error }] = useUpdateCwcMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      numWeeks: // value for 'numWeeks'
 *   },
 * });
 */
export function useUpdateCwcMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCwcMutation, UpdateCwcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCwcMutation, UpdateCwcMutationVariables>(UpdateCwcDocument, options);
      }
export type UpdateCwcMutationHookResult = ReturnType<typeof useUpdateCwcMutation>;
export type UpdateCwcMutationResult = Apollo.MutationResult<UpdateCwcMutation>;
export type UpdateCwcMutationOptions = Apollo.BaseMutationOptions<UpdateCwcMutation, UpdateCwcMutationVariables>;
export const UpdateServiceOrderDocument = gql`
    mutation UpdateServiceOrder($serviceOrder: ServiceOrderInput!, $roomIds: [Int!]!) {
  updateServiceOrder(serviceOrder: $serviceOrder, roomIds: $roomIds)
}
    `;
export type UpdateServiceOrderMutationFn = Apollo.MutationFunction<UpdateServiceOrderMutation, UpdateServiceOrderMutationVariables>;

/**
 * __useUpdateServiceOrderMutation__
 *
 * To run a mutation, you first call `useUpdateServiceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceOrderMutation, { data, loading, error }] = useUpdateServiceOrderMutation({
 *   variables: {
 *      serviceOrder: // value for 'serviceOrder'
 *      roomIds: // value for 'roomIds'
 *   },
 * });
 */
export function useUpdateServiceOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceOrderMutation, UpdateServiceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceOrderMutation, UpdateServiceOrderMutationVariables>(UpdateServiceOrderDocument, options);
      }
export type UpdateServiceOrderMutationHookResult = ReturnType<typeof useUpdateServiceOrderMutation>;
export type UpdateServiceOrderMutationResult = Apollo.MutationResult<UpdateServiceOrderMutation>;
export type UpdateServiceOrderMutationOptions = Apollo.BaseMutationOptions<UpdateServiceOrderMutation, UpdateServiceOrderMutationVariables>;
export const UpdateServiceOrderScheduledDateDocument = gql`
    mutation UpdateServiceOrderScheduledDate($serviceOrderId: Int!, $scheduledDate: DateTime!, $contractorId: Int!) {
  updateServiceOrderScheduledDate(serviceOrderId: $serviceOrderId, scheduledDate: $scheduledDate, contractorId: $contractorId)
}
    `;
export type UpdateServiceOrderScheduledDateMutationFn = Apollo.MutationFunction<UpdateServiceOrderScheduledDateMutation, UpdateServiceOrderScheduledDateMutationVariables>;

/**
 * __useUpdateServiceOrderScheduledDateMutation__
 *
 * To run a mutation, you first call `useUpdateServiceOrderScheduledDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceOrderScheduledDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceOrderScheduledDateMutation, { data, loading, error }] = useUpdateServiceOrderScheduledDateMutation({
 *   variables: {
 *      serviceOrderId: // value for 'serviceOrderId'
 *      scheduledDate: // value for 'scheduledDate'
 *      contractorId: // value for 'contractorId'
 *   },
 * });
 */
export function useUpdateServiceOrderScheduledDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceOrderScheduledDateMutation, UpdateServiceOrderScheduledDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceOrderScheduledDateMutation, UpdateServiceOrderScheduledDateMutationVariables>(UpdateServiceOrderScheduledDateDocument, options);
      }
export type UpdateServiceOrderScheduledDateMutationHookResult = ReturnType<typeof useUpdateServiceOrderScheduledDateMutation>;
export type UpdateServiceOrderScheduledDateMutationResult = Apollo.MutationResult<UpdateServiceOrderScheduledDateMutation>;
export type UpdateServiceOrderScheduledDateMutationOptions = Apollo.BaseMutationOptions<UpdateServiceOrderScheduledDateMutation, UpdateServiceOrderScheduledDateMutationVariables>;
export const GetAllJobsInRecoveryDocument = gql`
    query GetAllJobsInRecovery {
  allJobsInRecovery {
    id
    activeJobConfigurationId
    dateEnteredRecovery
    isInRecovery
    isHot
    assignedSalesperson {
      id
      workerId
      firstName
      lastName
      email
    }
    recoveryWorker {
      id
      firstName
      lastName
      email
    }
    isQuote
    isContract
    appointmentNumber
    quoteNumber
    quoteId
    contractNumber
    latestPrice
    customerId
    customerFirstName
    customerLastName
    customerCity
    customerEmail
    customerPhone
    customerZip
    steps {
      id
      recoveryStepOptionId
      step
      date
      createdByWorkerId
    }
    reasons {
      id
      recoveryReasonOptionId
      reason
      date
      createdByWorkerId
    }
    communications {
      id
      recoveryCommunicationOptionId
      creationTimestamp
      notes
      isEditable
      workerFirstName
      workerLastName
    }
  }
}
    `;

/**
 * __useGetAllJobsInRecoveryQuery__
 *
 * To run a query within a React component, call `useGetAllJobsInRecoveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllJobsInRecoveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllJobsInRecoveryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllJobsInRecoveryQuery(baseOptions?: Apollo.QueryHookOptions<GetAllJobsInRecoveryQuery, GetAllJobsInRecoveryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllJobsInRecoveryQuery, GetAllJobsInRecoveryQueryVariables>(GetAllJobsInRecoveryDocument, options);
      }
export function useGetAllJobsInRecoveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllJobsInRecoveryQuery, GetAllJobsInRecoveryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllJobsInRecoveryQuery, GetAllJobsInRecoveryQueryVariables>(GetAllJobsInRecoveryDocument, options);
        }
export type GetAllJobsInRecoveryQueryHookResult = ReturnType<typeof useGetAllJobsInRecoveryQuery>;
export type GetAllJobsInRecoveryLazyQueryHookResult = ReturnType<typeof useGetAllJobsInRecoveryLazyQuery>;
export type GetAllJobsInRecoveryQueryResult = Apollo.QueryResult<GetAllJobsInRecoveryQuery, GetAllJobsInRecoveryQueryVariables>;
export const GetAllRecoveryLedgerItemsDocument = gql`
    query GetAllRecoveryLedgerItems($jobId: Int!) {
  allRecoveryLedgerItems(jobId: $jobId) {
    jobId
    communicationId
    reasonId
    stepId
    overrideId
    internalNotesId
    callCenterActionId
    action
    timestamp
    notes
    worker
    isEditable
    isDeletable
    showTime
  }
}
    `;

/**
 * __useGetAllRecoveryLedgerItemsQuery__
 *
 * To run a query within a React component, call `useGetAllRecoveryLedgerItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRecoveryLedgerItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRecoveryLedgerItemsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetAllRecoveryLedgerItemsQuery(baseOptions: Apollo.QueryHookOptions<GetAllRecoveryLedgerItemsQuery, GetAllRecoveryLedgerItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRecoveryLedgerItemsQuery, GetAllRecoveryLedgerItemsQueryVariables>(GetAllRecoveryLedgerItemsDocument, options);
      }
export function useGetAllRecoveryLedgerItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRecoveryLedgerItemsQuery, GetAllRecoveryLedgerItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRecoveryLedgerItemsQuery, GetAllRecoveryLedgerItemsQueryVariables>(GetAllRecoveryLedgerItemsDocument, options);
        }
export type GetAllRecoveryLedgerItemsQueryHookResult = ReturnType<typeof useGetAllRecoveryLedgerItemsQuery>;
export type GetAllRecoveryLedgerItemsLazyQueryHookResult = ReturnType<typeof useGetAllRecoveryLedgerItemsLazyQuery>;
export type GetAllRecoveryLedgerItemsQueryResult = Apollo.QueryResult<GetAllRecoveryLedgerItemsQuery, GetAllRecoveryLedgerItemsQueryVariables>;
export const GetAllRecoveryReasonOptionsDocument = gql`
    query GetAllRecoveryReasonOptions {
  allRecoveryReasonOptions {
    id
    label
  }
}
    `;

/**
 * __useGetAllRecoveryReasonOptionsQuery__
 *
 * To run a query within a React component, call `useGetAllRecoveryReasonOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRecoveryReasonOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRecoveryReasonOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRecoveryReasonOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRecoveryReasonOptionsQuery, GetAllRecoveryReasonOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRecoveryReasonOptionsQuery, GetAllRecoveryReasonOptionsQueryVariables>(GetAllRecoveryReasonOptionsDocument, options);
      }
export function useGetAllRecoveryReasonOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRecoveryReasonOptionsQuery, GetAllRecoveryReasonOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRecoveryReasonOptionsQuery, GetAllRecoveryReasonOptionsQueryVariables>(GetAllRecoveryReasonOptionsDocument, options);
        }
export type GetAllRecoveryReasonOptionsQueryHookResult = ReturnType<typeof useGetAllRecoveryReasonOptionsQuery>;
export type GetAllRecoveryReasonOptionsLazyQueryHookResult = ReturnType<typeof useGetAllRecoveryReasonOptionsLazyQuery>;
export type GetAllRecoveryReasonOptionsQueryResult = Apollo.QueryResult<GetAllRecoveryReasonOptionsQuery, GetAllRecoveryReasonOptionsQueryVariables>;
export const GetAllRecoveryStepOptionsDocument = gql`
    query GetAllRecoveryStepOptions {
  allRecoveryStepOptions {
    id
    label
  }
}
    `;

/**
 * __useGetAllRecoveryStepOptionsQuery__
 *
 * To run a query within a React component, call `useGetAllRecoveryStepOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRecoveryStepOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRecoveryStepOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRecoveryStepOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRecoveryStepOptionsQuery, GetAllRecoveryStepOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRecoveryStepOptionsQuery, GetAllRecoveryStepOptionsQueryVariables>(GetAllRecoveryStepOptionsDocument, options);
      }
export function useGetAllRecoveryStepOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRecoveryStepOptionsQuery, GetAllRecoveryStepOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRecoveryStepOptionsQuery, GetAllRecoveryStepOptionsQueryVariables>(GetAllRecoveryStepOptionsDocument, options);
        }
export type GetAllRecoveryStepOptionsQueryHookResult = ReturnType<typeof useGetAllRecoveryStepOptionsQuery>;
export type GetAllRecoveryStepOptionsLazyQueryHookResult = ReturnType<typeof useGetAllRecoveryStepOptionsLazyQuery>;
export type GetAllRecoveryStepOptionsQueryResult = Apollo.QueryResult<GetAllRecoveryStepOptionsQuery, GetAllRecoveryStepOptionsQueryVariables>;
export const GetRecoveryCenterOverrideDocument = gql`
    query GetRecoveryCenterOverride($jobConfigurationId: Int!) {
  recoveryCenterOverride(jobConfigurationId: $jobConfigurationId) {
    id
    jobId
    jobConfigurationId
    amount
    commissionRate
    workerId
    timeOverridden
  }
}
    `;

/**
 * __useGetRecoveryCenterOverrideQuery__
 *
 * To run a query within a React component, call `useGetRecoveryCenterOverrideQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecoveryCenterOverrideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecoveryCenterOverrideQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetRecoveryCenterOverrideQuery(baseOptions: Apollo.QueryHookOptions<GetRecoveryCenterOverrideQuery, GetRecoveryCenterOverrideQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecoveryCenterOverrideQuery, GetRecoveryCenterOverrideQueryVariables>(GetRecoveryCenterOverrideDocument, options);
      }
export function useGetRecoveryCenterOverrideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecoveryCenterOverrideQuery, GetRecoveryCenterOverrideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecoveryCenterOverrideQuery, GetRecoveryCenterOverrideQueryVariables>(GetRecoveryCenterOverrideDocument, options);
        }
export type GetRecoveryCenterOverrideQueryHookResult = ReturnType<typeof useGetRecoveryCenterOverrideQuery>;
export type GetRecoveryCenterOverrideLazyQueryHookResult = ReturnType<typeof useGetRecoveryCenterOverrideLazyQuery>;
export type GetRecoveryCenterOverrideQueryResult = Apollo.QueryResult<GetRecoveryCenterOverrideQuery, GetRecoveryCenterOverrideQueryVariables>;
export const AddInternalJobNotesDocument = gql`
    mutation AddInternalJobNotes($jobId: Int!) {
  addInternalJobNotes(jobId: $jobId)
}
    `;
export type AddInternalJobNotesMutationFn = Apollo.MutationFunction<AddInternalJobNotesMutation, AddInternalJobNotesMutationVariables>;

/**
 * __useAddInternalJobNotesMutation__
 *
 * To run a mutation, you first call `useAddInternalJobNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInternalJobNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInternalJobNotesMutation, { data, loading, error }] = useAddInternalJobNotesMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useAddInternalJobNotesMutation(baseOptions?: Apollo.MutationHookOptions<AddInternalJobNotesMutation, AddInternalJobNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInternalJobNotesMutation, AddInternalJobNotesMutationVariables>(AddInternalJobNotesDocument, options);
      }
export type AddInternalJobNotesMutationHookResult = ReturnType<typeof useAddInternalJobNotesMutation>;
export type AddInternalJobNotesMutationResult = Apollo.MutationResult<AddInternalJobNotesMutation>;
export type AddInternalJobNotesMutationOptions = Apollo.BaseMutationOptions<AddInternalJobNotesMutation, AddInternalJobNotesMutationVariables>;
export const AddRecoveryCommunicationDocument = gql`
    mutation AddRecoveryCommunication($jobId: Int!, $recoveryCommunicationOptionId: Int!, $dialoutId: String) {
  addRecoveryCommunication(jobId: $jobId, recoveryCommunicationOptionId: $recoveryCommunicationOptionId, dialoutId: $dialoutId)
}
    `;
export type AddRecoveryCommunicationMutationFn = Apollo.MutationFunction<AddRecoveryCommunicationMutation, AddRecoveryCommunicationMutationVariables>;

/**
 * __useAddRecoveryCommunicationMutation__
 *
 * To run a mutation, you first call `useAddRecoveryCommunicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecoveryCommunicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecoveryCommunicationMutation, { data, loading, error }] = useAddRecoveryCommunicationMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      recoveryCommunicationOptionId: // value for 'recoveryCommunicationOptionId'
 *      dialoutId: // value for 'dialoutId'
 *   },
 * });
 */
export function useAddRecoveryCommunicationMutation(baseOptions?: Apollo.MutationHookOptions<AddRecoveryCommunicationMutation, AddRecoveryCommunicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRecoveryCommunicationMutation, AddRecoveryCommunicationMutationVariables>(AddRecoveryCommunicationDocument, options);
      }
export type AddRecoveryCommunicationMutationHookResult = ReturnType<typeof useAddRecoveryCommunicationMutation>;
export type AddRecoveryCommunicationMutationResult = Apollo.MutationResult<AddRecoveryCommunicationMutation>;
export type AddRecoveryCommunicationMutationOptions = Apollo.BaseMutationOptions<AddRecoveryCommunicationMutation, AddRecoveryCommunicationMutationVariables>;
export const DeleteInternalJobNotesDocument = gql`
    mutation DeleteInternalJobNotes($internalJobNotesId: Int!) {
  deleteInternalJobNotes(internalJobNotesId: $internalJobNotesId)
}
    `;
export type DeleteInternalJobNotesMutationFn = Apollo.MutationFunction<DeleteInternalJobNotesMutation, DeleteInternalJobNotesMutationVariables>;

/**
 * __useDeleteInternalJobNotesMutation__
 *
 * To run a mutation, you first call `useDeleteInternalJobNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInternalJobNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInternalJobNotesMutation, { data, loading, error }] = useDeleteInternalJobNotesMutation({
 *   variables: {
 *      internalJobNotesId: // value for 'internalJobNotesId'
 *   },
 * });
 */
export function useDeleteInternalJobNotesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInternalJobNotesMutation, DeleteInternalJobNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInternalJobNotesMutation, DeleteInternalJobNotesMutationVariables>(DeleteInternalJobNotesDocument, options);
      }
export type DeleteInternalJobNotesMutationHookResult = ReturnType<typeof useDeleteInternalJobNotesMutation>;
export type DeleteInternalJobNotesMutationResult = Apollo.MutationResult<DeleteInternalJobNotesMutation>;
export type DeleteInternalJobNotesMutationOptions = Apollo.BaseMutationOptions<DeleteInternalJobNotesMutation, DeleteInternalJobNotesMutationVariables>;
export const DeleteRecoveryCommunicationDocument = gql`
    mutation DeleteRecoveryCommunication($recoveryCommunicationOptionId: Int!) {
  deleteRecoveryCommunication(recoveryCommunicationOptionId: $recoveryCommunicationOptionId)
}
    `;
export type DeleteRecoveryCommunicationMutationFn = Apollo.MutationFunction<DeleteRecoveryCommunicationMutation, DeleteRecoveryCommunicationMutationVariables>;

/**
 * __useDeleteRecoveryCommunicationMutation__
 *
 * To run a mutation, you first call `useDeleteRecoveryCommunicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecoveryCommunicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecoveryCommunicationMutation, { data, loading, error }] = useDeleteRecoveryCommunicationMutation({
 *   variables: {
 *      recoveryCommunicationOptionId: // value for 'recoveryCommunicationOptionId'
 *   },
 * });
 */
export function useDeleteRecoveryCommunicationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRecoveryCommunicationMutation, DeleteRecoveryCommunicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRecoveryCommunicationMutation, DeleteRecoveryCommunicationMutationVariables>(DeleteRecoveryCommunicationDocument, options);
      }
export type DeleteRecoveryCommunicationMutationHookResult = ReturnType<typeof useDeleteRecoveryCommunicationMutation>;
export type DeleteRecoveryCommunicationMutationResult = Apollo.MutationResult<DeleteRecoveryCommunicationMutation>;
export type DeleteRecoveryCommunicationMutationOptions = Apollo.BaseMutationOptions<DeleteRecoveryCommunicationMutation, DeleteRecoveryCommunicationMutationVariables>;
export const SubmitChangesToJobInRecoveryDocument = gql`
    mutation SubmitChangesToJobInRecovery($jobId: Int!, $recoveryStepOption: Int!, $stepDate: DateTime!, $recoveryReasonOptionId: Int, $otherRecoveryReason: String, $updateLatestStep: Boolean, $removeFromRecovery: Boolean) {
  submitChangesToJobInRecovery(jobId: $jobId, recoveryReasonOptionId: $recoveryReasonOptionId, stepDate: $stepDate, otherRecoveryReason: $otherRecoveryReason, recoveryStepOption: $recoveryStepOption, updateLatestStep: $updateLatestStep, removeFromRecovery: $removeFromRecovery)
}
    `;
export type SubmitChangesToJobInRecoveryMutationFn = Apollo.MutationFunction<SubmitChangesToJobInRecoveryMutation, SubmitChangesToJobInRecoveryMutationVariables>;

/**
 * __useSubmitChangesToJobInRecoveryMutation__
 *
 * To run a mutation, you first call `useSubmitChangesToJobInRecoveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitChangesToJobInRecoveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitChangesToJobInRecoveryMutation, { data, loading, error }] = useSubmitChangesToJobInRecoveryMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      recoveryStepOption: // value for 'recoveryStepOption'
 *      stepDate: // value for 'stepDate'
 *      recoveryReasonOptionId: // value for 'recoveryReasonOptionId'
 *      otherRecoveryReason: // value for 'otherRecoveryReason'
 *      updateLatestStep: // value for 'updateLatestStep'
 *      removeFromRecovery: // value for 'removeFromRecovery'
 *   },
 * });
 */
export function useSubmitChangesToJobInRecoveryMutation(baseOptions?: Apollo.MutationHookOptions<SubmitChangesToJobInRecoveryMutation, SubmitChangesToJobInRecoveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitChangesToJobInRecoveryMutation, SubmitChangesToJobInRecoveryMutationVariables>(SubmitChangesToJobInRecoveryDocument, options);
      }
export type SubmitChangesToJobInRecoveryMutationHookResult = ReturnType<typeof useSubmitChangesToJobInRecoveryMutation>;
export type SubmitChangesToJobInRecoveryMutationResult = Apollo.MutationResult<SubmitChangesToJobInRecoveryMutation>;
export type SubmitChangesToJobInRecoveryMutationOptions = Apollo.BaseMutationOptions<SubmitChangesToJobInRecoveryMutation, SubmitChangesToJobInRecoveryMutationVariables>;
export const UpdateInternalJobNotesDocument = gql`
    mutation UpdateInternalJobNotes($internalJobNotesId: Int!, $notes: String!) {
  updateInternalJobNotes(internalJobNotesId: $internalJobNotesId, notes: $notes)
}
    `;
export type UpdateInternalJobNotesMutationFn = Apollo.MutationFunction<UpdateInternalJobNotesMutation, UpdateInternalJobNotesMutationVariables>;

/**
 * __useUpdateInternalJobNotesMutation__
 *
 * To run a mutation, you first call `useUpdateInternalJobNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInternalJobNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInternalJobNotesMutation, { data, loading, error }] = useUpdateInternalJobNotesMutation({
 *   variables: {
 *      internalJobNotesId: // value for 'internalJobNotesId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateInternalJobNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInternalJobNotesMutation, UpdateInternalJobNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInternalJobNotesMutation, UpdateInternalJobNotesMutationVariables>(UpdateInternalJobNotesDocument, options);
      }
export type UpdateInternalJobNotesMutationHookResult = ReturnType<typeof useUpdateInternalJobNotesMutation>;
export type UpdateInternalJobNotesMutationResult = Apollo.MutationResult<UpdateInternalJobNotesMutation>;
export type UpdateInternalJobNotesMutationOptions = Apollo.BaseMutationOptions<UpdateInternalJobNotesMutation, UpdateInternalJobNotesMutationVariables>;
export const UpdateJobInRecoveryDocument = gql`
    mutation UpdateJobInRecovery($jobId: Int!, $claimedWorkerId: Int, $isInRecovery: Boolean, $isHot: Boolean) {
  updateJobInRecovery(jobId: $jobId, claimedWorkerId: $claimedWorkerId, isInRecovery: $isInRecovery, isHot: $isHot)
}
    `;
export type UpdateJobInRecoveryMutationFn = Apollo.MutationFunction<UpdateJobInRecoveryMutation, UpdateJobInRecoveryMutationVariables>;

/**
 * __useUpdateJobInRecoveryMutation__
 *
 * To run a mutation, you first call `useUpdateJobInRecoveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobInRecoveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobInRecoveryMutation, { data, loading, error }] = useUpdateJobInRecoveryMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      claimedWorkerId: // value for 'claimedWorkerId'
 *      isInRecovery: // value for 'isInRecovery'
 *      isHot: // value for 'isHot'
 *   },
 * });
 */
export function useUpdateJobInRecoveryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobInRecoveryMutation, UpdateJobInRecoveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobInRecoveryMutation, UpdateJobInRecoveryMutationVariables>(UpdateJobInRecoveryDocument, options);
      }
export type UpdateJobInRecoveryMutationHookResult = ReturnType<typeof useUpdateJobInRecoveryMutation>;
export type UpdateJobInRecoveryMutationResult = Apollo.MutationResult<UpdateJobInRecoveryMutation>;
export type UpdateJobInRecoveryMutationOptions = Apollo.BaseMutationOptions<UpdateJobInRecoveryMutation, UpdateJobInRecoveryMutationVariables>;
export const UpdateRecoveryCommunicationDocument = gql`
    mutation UpdateRecoveryCommunication($recoveryCommunicationOptionId: Int!, $notes: String!) {
  updateRecoveryCommunication(recoveryCommunicationOptionId: $recoveryCommunicationOptionId, notes: $notes)
}
    `;
export type UpdateRecoveryCommunicationMutationFn = Apollo.MutationFunction<UpdateRecoveryCommunicationMutation, UpdateRecoveryCommunicationMutationVariables>;

/**
 * __useUpdateRecoveryCommunicationMutation__
 *
 * To run a mutation, you first call `useUpdateRecoveryCommunicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecoveryCommunicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecoveryCommunicationMutation, { data, loading, error }] = useUpdateRecoveryCommunicationMutation({
 *   variables: {
 *      recoveryCommunicationOptionId: // value for 'recoveryCommunicationOptionId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateRecoveryCommunicationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecoveryCommunicationMutation, UpdateRecoveryCommunicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecoveryCommunicationMutation, UpdateRecoveryCommunicationMutationVariables>(UpdateRecoveryCommunicationDocument, options);
      }
export type UpdateRecoveryCommunicationMutationHookResult = ReturnType<typeof useUpdateRecoveryCommunicationMutation>;
export type UpdateRecoveryCommunicationMutationResult = Apollo.MutationResult<UpdateRecoveryCommunicationMutation>;
export type UpdateRecoveryCommunicationMutationOptions = Apollo.BaseMutationOptions<UpdateRecoveryCommunicationMutation, UpdateRecoveryCommunicationMutationVariables>;
export const UpsertRecoveryCenterOverrideDocument = gql`
    mutation UpsertRecoveryCenterOverride($jobConfigurationId: Int!, $price: Decimal!, $commissionRate: Decimal!) {
  upsertRecoveryCenterOverride(jobConfigurationId: $jobConfigurationId, price: $price, commissionRate: $commissionRate)
}
    `;
export type UpsertRecoveryCenterOverrideMutationFn = Apollo.MutationFunction<UpsertRecoveryCenterOverrideMutation, UpsertRecoveryCenterOverrideMutationVariables>;

/**
 * __useUpsertRecoveryCenterOverrideMutation__
 *
 * To run a mutation, you first call `useUpsertRecoveryCenterOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRecoveryCenterOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRecoveryCenterOverrideMutation, { data, loading, error }] = useUpsertRecoveryCenterOverrideMutation({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *      price: // value for 'price'
 *      commissionRate: // value for 'commissionRate'
 *   },
 * });
 */
export function useUpsertRecoveryCenterOverrideMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRecoveryCenterOverrideMutation, UpsertRecoveryCenterOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRecoveryCenterOverrideMutation, UpsertRecoveryCenterOverrideMutationVariables>(UpsertRecoveryCenterOverrideDocument, options);
      }
export type UpsertRecoveryCenterOverrideMutationHookResult = ReturnType<typeof useUpsertRecoveryCenterOverrideMutation>;
export type UpsertRecoveryCenterOverrideMutationResult = Apollo.MutationResult<UpsertRecoveryCenterOverrideMutation>;
export type UpsertRecoveryCenterOverrideMutationOptions = Apollo.BaseMutationOptions<UpsertRecoveryCenterOverrideMutation, UpsertRecoveryCenterOverrideMutationVariables>;
export const CanDeleteMarketDocument = gql`
    query CanDeleteMarket($marketId: Int!) {
  canDeleteMarket(marketId: $marketId)
}
    `;

/**
 * __useCanDeleteMarketQuery__
 *
 * To run a query within a React component, call `useCanDeleteMarketQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanDeleteMarketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanDeleteMarketQuery({
 *   variables: {
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useCanDeleteMarketQuery(baseOptions: Apollo.QueryHookOptions<CanDeleteMarketQuery, CanDeleteMarketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanDeleteMarketQuery, CanDeleteMarketQueryVariables>(CanDeleteMarketDocument, options);
      }
export function useCanDeleteMarketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanDeleteMarketQuery, CanDeleteMarketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanDeleteMarketQuery, CanDeleteMarketQueryVariables>(CanDeleteMarketDocument, options);
        }
export type CanDeleteMarketQueryHookResult = ReturnType<typeof useCanDeleteMarketQuery>;
export type CanDeleteMarketLazyQueryHookResult = ReturnType<typeof useCanDeleteMarketLazyQuery>;
export type CanDeleteMarketQueryResult = Apollo.QueryResult<CanDeleteMarketQuery, CanDeleteMarketQueryVariables>;
export const EstimateAppointmentLengthDocument = gql`
    query EstimateAppointmentLength($roomIds: [Int!]!) {
  estimateAppointmentLength(roomIds: $roomIds)
}
    `;

/**
 * __useEstimateAppointmentLengthQuery__
 *
 * To run a query within a React component, call `useEstimateAppointmentLengthQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimateAppointmentLengthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimateAppointmentLengthQuery({
 *   variables: {
 *      roomIds: // value for 'roomIds'
 *   },
 * });
 */
export function useEstimateAppointmentLengthQuery(baseOptions: Apollo.QueryHookOptions<EstimateAppointmentLengthQuery, EstimateAppointmentLengthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EstimateAppointmentLengthQuery, EstimateAppointmentLengthQueryVariables>(EstimateAppointmentLengthDocument, options);
      }
export function useEstimateAppointmentLengthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstimateAppointmentLengthQuery, EstimateAppointmentLengthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EstimateAppointmentLengthQuery, EstimateAppointmentLengthQueryVariables>(EstimateAppointmentLengthDocument, options);
        }
export type EstimateAppointmentLengthQueryHookResult = ReturnType<typeof useEstimateAppointmentLengthQuery>;
export type EstimateAppointmentLengthLazyQueryHookResult = ReturnType<typeof useEstimateAppointmentLengthLazyQuery>;
export type EstimateAppointmentLengthQueryResult = Apollo.QueryResult<EstimateAppointmentLengthQuery, EstimateAppointmentLengthQueryVariables>;
export const GetActivePromoCodeDocument = gql`
    query GetActivePromoCode($code: String!) {
  activePromoCode(code: $code) {
    id
    code
    displayText
  }
}
    `;

/**
 * __useGetActivePromoCodeQuery__
 *
 * To run a query within a React component, call `useGetActivePromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivePromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivePromoCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetActivePromoCodeQuery(baseOptions: Apollo.QueryHookOptions<GetActivePromoCodeQuery, GetActivePromoCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivePromoCodeQuery, GetActivePromoCodeQueryVariables>(GetActivePromoCodeDocument, options);
      }
export function useGetActivePromoCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivePromoCodeQuery, GetActivePromoCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivePromoCodeQuery, GetActivePromoCodeQueryVariables>(GetActivePromoCodeDocument, options);
        }
export type GetActivePromoCodeQueryHookResult = ReturnType<typeof useGetActivePromoCodeQuery>;
export type GetActivePromoCodeLazyQueryHookResult = ReturnType<typeof useGetActivePromoCodeLazyQuery>;
export type GetActivePromoCodeQueryResult = Apollo.QueryResult<GetActivePromoCodeQuery, GetActivePromoCodeQueryVariables>;
export const GetActivePromosExistDocument = gql`
    query GetActivePromosExist {
  activePromosExist
}
    `;

/**
 * __useGetActivePromosExistQuery__
 *
 * To run a query within a React component, call `useGetActivePromosExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivePromosExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivePromosExistQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActivePromosExistQuery(baseOptions?: Apollo.QueryHookOptions<GetActivePromosExistQuery, GetActivePromosExistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivePromosExistQuery, GetActivePromosExistQueryVariables>(GetActivePromosExistDocument, options);
      }
export function useGetActivePromosExistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivePromosExistQuery, GetActivePromosExistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivePromosExistQuery, GetActivePromosExistQueryVariables>(GetActivePromosExistDocument, options);
        }
export type GetActivePromosExistQueryHookResult = ReturnType<typeof useGetActivePromosExistQuery>;
export type GetActivePromosExistLazyQueryHookResult = ReturnType<typeof useGetActivePromosExistLazyQuery>;
export type GetActivePromosExistQueryResult = Apollo.QueryResult<GetActivePromosExistQuery, GetActivePromosExistQueryVariables>;
export const GetAllActiveZipsAndCitiesDocument = gql`
    query GetAllActiveZipsAndCities {
  allActiveZipsAndCities {
    id
    zip
    city
  }
}
    `;

/**
 * __useGetAllActiveZipsAndCitiesQuery__
 *
 * To run a query within a React component, call `useGetAllActiveZipsAndCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllActiveZipsAndCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllActiveZipsAndCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllActiveZipsAndCitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllActiveZipsAndCitiesQuery, GetAllActiveZipsAndCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllActiveZipsAndCitiesQuery, GetAllActiveZipsAndCitiesQueryVariables>(GetAllActiveZipsAndCitiesDocument, options);
      }
export function useGetAllActiveZipsAndCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllActiveZipsAndCitiesQuery, GetAllActiveZipsAndCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllActiveZipsAndCitiesQuery, GetAllActiveZipsAndCitiesQueryVariables>(GetAllActiveZipsAndCitiesDocument, options);
        }
export type GetAllActiveZipsAndCitiesQueryHookResult = ReturnType<typeof useGetAllActiveZipsAndCitiesQuery>;
export type GetAllActiveZipsAndCitiesLazyQueryHookResult = ReturnType<typeof useGetAllActiveZipsAndCitiesLazyQuery>;
export type GetAllActiveZipsAndCitiesQueryResult = Apollo.QueryResult<GetAllActiveZipsAndCitiesQuery, GetAllActiveZipsAndCitiesQueryVariables>;
export const GetAllBlockedTimeSlotsOnDayDocument = gql`
    query GetAllBlockedTimeSlotsOnDay($date: DateTime!, $marketId: Int!) {
  allBlockedTimesSlotsOnDay(date: $date, marketId: $marketId) {
    salespersonId
    marketId
    marketTimeSlotId
    date
  }
}
    `;

/**
 * __useGetAllBlockedTimeSlotsOnDayQuery__
 *
 * To run a query within a React component, call `useGetAllBlockedTimeSlotsOnDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlockedTimeSlotsOnDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBlockedTimeSlotsOnDayQuery({
 *   variables: {
 *      date: // value for 'date'
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useGetAllBlockedTimeSlotsOnDayQuery(baseOptions: Apollo.QueryHookOptions<GetAllBlockedTimeSlotsOnDayQuery, GetAllBlockedTimeSlotsOnDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBlockedTimeSlotsOnDayQuery, GetAllBlockedTimeSlotsOnDayQueryVariables>(GetAllBlockedTimeSlotsOnDayDocument, options);
      }
export function useGetAllBlockedTimeSlotsOnDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBlockedTimeSlotsOnDayQuery, GetAllBlockedTimeSlotsOnDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBlockedTimeSlotsOnDayQuery, GetAllBlockedTimeSlotsOnDayQueryVariables>(GetAllBlockedTimeSlotsOnDayDocument, options);
        }
export type GetAllBlockedTimeSlotsOnDayQueryHookResult = ReturnType<typeof useGetAllBlockedTimeSlotsOnDayQuery>;
export type GetAllBlockedTimeSlotsOnDayLazyQueryHookResult = ReturnType<typeof useGetAllBlockedTimeSlotsOnDayLazyQuery>;
export type GetAllBlockedTimeSlotsOnDayQueryResult = Apollo.QueryResult<GetAllBlockedTimeSlotsOnDayQuery, GetAllBlockedTimeSlotsOnDayQueryVariables>;
export const GetAllCountiesDocument = gql`
    query GetAllCounties {
  allCounties {
    id
    name
  }
}
    `;

/**
 * __useGetAllCountiesQuery__
 *
 * To run a query within a React component, call `useGetAllCountiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCountiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCountiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCountiesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCountiesQuery, GetAllCountiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCountiesQuery, GetAllCountiesQueryVariables>(GetAllCountiesDocument, options);
      }
export function useGetAllCountiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCountiesQuery, GetAllCountiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCountiesQuery, GetAllCountiesQueryVariables>(GetAllCountiesDocument, options);
        }
export type GetAllCountiesQueryHookResult = ReturnType<typeof useGetAllCountiesQuery>;
export type GetAllCountiesLazyQueryHookResult = ReturnType<typeof useGetAllCountiesLazyQuery>;
export type GetAllCountiesQueryResult = Apollo.QueryResult<GetAllCountiesQuery, GetAllCountiesQueryVariables>;
export const GetAllHowDidYouHearAboutUsOptionsDocument = gql`
    query GetAllHowDidYouHearAboutUsOptions {
  allHowDidYouHearAboutUsOptions {
    id
    optionText
  }
}
    `;

/**
 * __useGetAllHowDidYouHearAboutUsOptionsQuery__
 *
 * To run a query within a React component, call `useGetAllHowDidYouHearAboutUsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllHowDidYouHearAboutUsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllHowDidYouHearAboutUsOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllHowDidYouHearAboutUsOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllHowDidYouHearAboutUsOptionsQuery, GetAllHowDidYouHearAboutUsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllHowDidYouHearAboutUsOptionsQuery, GetAllHowDidYouHearAboutUsOptionsQueryVariables>(GetAllHowDidYouHearAboutUsOptionsDocument, options);
      }
export function useGetAllHowDidYouHearAboutUsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllHowDidYouHearAboutUsOptionsQuery, GetAllHowDidYouHearAboutUsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllHowDidYouHearAboutUsOptionsQuery, GetAllHowDidYouHearAboutUsOptionsQueryVariables>(GetAllHowDidYouHearAboutUsOptionsDocument, options);
        }
export type GetAllHowDidYouHearAboutUsOptionsQueryHookResult = ReturnType<typeof useGetAllHowDidYouHearAboutUsOptionsQuery>;
export type GetAllHowDidYouHearAboutUsOptionsLazyQueryHookResult = ReturnType<typeof useGetAllHowDidYouHearAboutUsOptionsLazyQuery>;
export type GetAllHowDidYouHearAboutUsOptionsQueryResult = Apollo.QueryResult<GetAllHowDidYouHearAboutUsOptionsQuery, GetAllHowDidYouHearAboutUsOptionsQueryVariables>;
export const GetAllMarketsDocument = gql`
    query GetAllMarkets {
  allMarkets {
    id
    name
    appointmentHours
    appointmentMinutes
    mSRPScalar
  }
}
    `;

/**
 * __useGetAllMarketsQuery__
 *
 * To run a query within a React component, call `useGetAllMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMarketsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMarketsQuery, GetAllMarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMarketsQuery, GetAllMarketsQueryVariables>(GetAllMarketsDocument, options);
      }
export function useGetAllMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMarketsQuery, GetAllMarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMarketsQuery, GetAllMarketsQueryVariables>(GetAllMarketsDocument, options);
        }
export type GetAllMarketsQueryHookResult = ReturnType<typeof useGetAllMarketsQuery>;
export type GetAllMarketsLazyQueryHookResult = ReturnType<typeof useGetAllMarketsLazyQuery>;
export type GetAllMarketsQueryResult = Apollo.QueryResult<GetAllMarketsQuery, GetAllMarketsQueryVariables>;
export const GetAllSalespeopleInMarketDocument = gql`
    query GetAllSalespeopleInMarket($marketId: Int!) {
  allSalespeopleInMarket(marketId: $marketId) {
    id
    workerId
    firstName
    lastName
    email
    isActive
    isCheckedIn
    serviceZips
  }
}
    `;

/**
 * __useGetAllSalespeopleInMarketQuery__
 *
 * To run a query within a React component, call `useGetAllSalespeopleInMarketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSalespeopleInMarketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSalespeopleInMarketQuery({
 *   variables: {
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useGetAllSalespeopleInMarketQuery(baseOptions: Apollo.QueryHookOptions<GetAllSalespeopleInMarketQuery, GetAllSalespeopleInMarketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSalespeopleInMarketQuery, GetAllSalespeopleInMarketQueryVariables>(GetAllSalespeopleInMarketDocument, options);
      }
export function useGetAllSalespeopleInMarketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSalespeopleInMarketQuery, GetAllSalespeopleInMarketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSalespeopleInMarketQuery, GetAllSalespeopleInMarketQueryVariables>(GetAllSalespeopleInMarketDocument, options);
        }
export type GetAllSalespeopleInMarketQueryHookResult = ReturnType<typeof useGetAllSalespeopleInMarketQuery>;
export type GetAllSalespeopleInMarketLazyQueryHookResult = ReturnType<typeof useGetAllSalespeopleInMarketLazyQuery>;
export type GetAllSalespeopleInMarketQueryResult = Apollo.QueryResult<GetAllSalespeopleInMarketQuery, GetAllSalespeopleInMarketQueryVariables>;
export const GetAllSalespeopleInZipDocument = gql`
    query GetAllSalespeopleInZip($zip: String!) {
  allSalespeopleInZip(zip: $zip) {
    id
    workerId
    firstName
    lastName
    email
    isCheckedIn
  }
}
    `;

/**
 * __useGetAllSalespeopleInZipQuery__
 *
 * To run a query within a React component, call `useGetAllSalespeopleInZipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSalespeopleInZipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSalespeopleInZipQuery({
 *   variables: {
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useGetAllSalespeopleInZipQuery(baseOptions: Apollo.QueryHookOptions<GetAllSalespeopleInZipQuery, GetAllSalespeopleInZipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSalespeopleInZipQuery, GetAllSalespeopleInZipQueryVariables>(GetAllSalespeopleInZipDocument, options);
      }
export function useGetAllSalespeopleInZipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSalespeopleInZipQuery, GetAllSalespeopleInZipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSalespeopleInZipQuery, GetAllSalespeopleInZipQueryVariables>(GetAllSalespeopleInZipDocument, options);
        }
export type GetAllSalespeopleInZipQueryHookResult = ReturnType<typeof useGetAllSalespeopleInZipQuery>;
export type GetAllSalespeopleInZipLazyQueryHookResult = ReturnType<typeof useGetAllSalespeopleInZipLazyQuery>;
export type GetAllSalespeopleInZipQueryResult = Apollo.QueryResult<GetAllSalespeopleInZipQuery, GetAllSalespeopleInZipQueryVariables>;
export const GetAllServicedLocationInformationDocument = gql`
    query GetAllServicedLocationInformation {
  allActiveZipsAndCities {
    id
    zip
    city
    countyId
    marketId
    serviced
  }
  allMarkets {
    id
    name
  }
  allCounties {
    id
    name
  }
}
    `;

/**
 * __useGetAllServicedLocationInformationQuery__
 *
 * To run a query within a React component, call `useGetAllServicedLocationInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllServicedLocationInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllServicedLocationInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllServicedLocationInformationQuery(baseOptions?: Apollo.QueryHookOptions<GetAllServicedLocationInformationQuery, GetAllServicedLocationInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllServicedLocationInformationQuery, GetAllServicedLocationInformationQueryVariables>(GetAllServicedLocationInformationDocument, options);
      }
export function useGetAllServicedLocationInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllServicedLocationInformationQuery, GetAllServicedLocationInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllServicedLocationInformationQuery, GetAllServicedLocationInformationQueryVariables>(GetAllServicedLocationInformationDocument, options);
        }
export type GetAllServicedLocationInformationQueryHookResult = ReturnType<typeof useGetAllServicedLocationInformationQuery>;
export type GetAllServicedLocationInformationLazyQueryHookResult = ReturnType<typeof useGetAllServicedLocationInformationLazyQuery>;
export type GetAllServicedLocationInformationQueryResult = Apollo.QueryResult<GetAllServicedLocationInformationQuery, GetAllServicedLocationInformationQueryVariables>;
export const GetAllTimeSlotsForMarketDocument = gql`
    query GetAllTimeSlotsForMarket($marketId: Int!) {
  allTimeSlotsForMarket(marketId: $marketId) {
    id
    startTime
    endTime
    marketId
  }
}
    `;

/**
 * __useGetAllTimeSlotsForMarketQuery__
 *
 * To run a query within a React component, call `useGetAllTimeSlotsForMarketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTimeSlotsForMarketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTimeSlotsForMarketQuery({
 *   variables: {
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useGetAllTimeSlotsForMarketQuery(baseOptions: Apollo.QueryHookOptions<GetAllTimeSlotsForMarketQuery, GetAllTimeSlotsForMarketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTimeSlotsForMarketQuery, GetAllTimeSlotsForMarketQueryVariables>(GetAllTimeSlotsForMarketDocument, options);
      }
export function useGetAllTimeSlotsForMarketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTimeSlotsForMarketQuery, GetAllTimeSlotsForMarketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTimeSlotsForMarketQuery, GetAllTimeSlotsForMarketQueryVariables>(GetAllTimeSlotsForMarketDocument, options);
        }
export type GetAllTimeSlotsForMarketQueryHookResult = ReturnType<typeof useGetAllTimeSlotsForMarketQuery>;
export type GetAllTimeSlotsForMarketLazyQueryHookResult = ReturnType<typeof useGetAllTimeSlotsForMarketLazyQuery>;
export type GetAllTimeSlotsForMarketQueryResult = Apollo.QueryResult<GetAllTimeSlotsForMarketQuery, GetAllTimeSlotsForMarketQueryVariables>;
export const GetAllZipsAndCitiesDocument = gql`
    query GetAllZipsAndCities {
  allZipsAndCities {
    id
    zip
    city
    countyId
    marketId
    serviced
  }
}
    `;

/**
 * __useGetAllZipsAndCitiesQuery__
 *
 * To run a query within a React component, call `useGetAllZipsAndCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllZipsAndCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllZipsAndCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllZipsAndCitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllZipsAndCitiesQuery, GetAllZipsAndCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllZipsAndCitiesQuery, GetAllZipsAndCitiesQueryVariables>(GetAllZipsAndCitiesDocument, options);
      }
export function useGetAllZipsAndCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllZipsAndCitiesQuery, GetAllZipsAndCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllZipsAndCitiesQuery, GetAllZipsAndCitiesQueryVariables>(GetAllZipsAndCitiesDocument, options);
        }
export type GetAllZipsAndCitiesQueryHookResult = ReturnType<typeof useGetAllZipsAndCitiesQuery>;
export type GetAllZipsAndCitiesLazyQueryHookResult = ReturnType<typeof useGetAllZipsAndCitiesLazyQuery>;
export type GetAllZipsAndCitiesQueryResult = Apollo.QueryResult<GetAllZipsAndCitiesQuery, GetAllZipsAndCitiesQueryVariables>;
export const GetAvailableTimeSlotsDocument = gql`
    query GetAvailableTimeSlots($zip: String!, $date: DateTime!) {
  allTimeSlotsForZip(zip: $zip) {
    id
    startTime
    endTime
    marketId
  }
  availableTimeSlots(zip: $zip, date: $date) {
    id
    startTime
    endTime
    marketId
  }
}
    `;

/**
 * __useGetAvailableTimeSlotsQuery__
 *
 * To run a query within a React component, call `useGetAvailableTimeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableTimeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableTimeSlotsQuery({
 *   variables: {
 *      zip: // value for 'zip'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetAvailableTimeSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableTimeSlotsQuery, GetAvailableTimeSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableTimeSlotsQuery, GetAvailableTimeSlotsQueryVariables>(GetAvailableTimeSlotsDocument, options);
      }
export function useGetAvailableTimeSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableTimeSlotsQuery, GetAvailableTimeSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableTimeSlotsQuery, GetAvailableTimeSlotsQueryVariables>(GetAvailableTimeSlotsDocument, options);
        }
export type GetAvailableTimeSlotsQueryHookResult = ReturnType<typeof useGetAvailableTimeSlotsQuery>;
export type GetAvailableTimeSlotsLazyQueryHookResult = ReturnType<typeof useGetAvailableTimeSlotsLazyQuery>;
export type GetAvailableTimeSlotsQueryResult = Apollo.QueryResult<GetAvailableTimeSlotsQuery, GetAvailableTimeSlotsQueryVariables>;
export const GetAvailableTimeSlotsForSalespersonDocument = gql`
    query GetAvailableTimeSlotsForSalesperson($zip: String!, $date: DateTime!, $salespersonId: Int!) {
  allTimeSlotsForZip(zip: $zip) {
    id
    startTime
    endTime
    marketId
  }
  availableTimeSlotsForSalesperson(zip: $zip, date: $date, salespersonId: $salespersonId) {
    id
    startTime
    endTime
    marketId
  }
}
    `;

/**
 * __useGetAvailableTimeSlotsForSalespersonQuery__
 *
 * To run a query within a React component, call `useGetAvailableTimeSlotsForSalespersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableTimeSlotsForSalespersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableTimeSlotsForSalespersonQuery({
 *   variables: {
 *      zip: // value for 'zip'
 *      date: // value for 'date'
 *      salespersonId: // value for 'salespersonId'
 *   },
 * });
 */
export function useGetAvailableTimeSlotsForSalespersonQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableTimeSlotsForSalespersonQuery, GetAvailableTimeSlotsForSalespersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableTimeSlotsForSalespersonQuery, GetAvailableTimeSlotsForSalespersonQueryVariables>(GetAvailableTimeSlotsForSalespersonDocument, options);
      }
export function useGetAvailableTimeSlotsForSalespersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableTimeSlotsForSalespersonQuery, GetAvailableTimeSlotsForSalespersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableTimeSlotsForSalespersonQuery, GetAvailableTimeSlotsForSalespersonQueryVariables>(GetAvailableTimeSlotsForSalespersonDocument, options);
        }
export type GetAvailableTimeSlotsForSalespersonQueryHookResult = ReturnType<typeof useGetAvailableTimeSlotsForSalespersonQuery>;
export type GetAvailableTimeSlotsForSalespersonLazyQueryHookResult = ReturnType<typeof useGetAvailableTimeSlotsForSalespersonLazyQuery>;
export type GetAvailableTimeSlotsForSalespersonQueryResult = Apollo.QueryResult<GetAvailableTimeSlotsForSalespersonQuery, GetAvailableTimeSlotsForSalespersonQueryVariables>;
export const GetColorCategoriesDocument = gql`
    query GetColorCategories {
  colorCategories {
    id
    category
  }
}
    `;

/**
 * __useGetColorCategoriesQuery__
 *
 * To run a query within a React component, call `useGetColorCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColorCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColorCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetColorCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetColorCategoriesQuery, GetColorCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetColorCategoriesQuery, GetColorCategoriesQueryVariables>(GetColorCategoriesDocument, options);
      }
export function useGetColorCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetColorCategoriesQuery, GetColorCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetColorCategoriesQuery, GetColorCategoriesQueryVariables>(GetColorCategoriesDocument, options);
        }
export type GetColorCategoriesQueryHookResult = ReturnType<typeof useGetColorCategoriesQuery>;
export type GetColorCategoriesLazyQueryHookResult = ReturnType<typeof useGetColorCategoriesLazyQuery>;
export type GetColorCategoriesQueryResult = Apollo.QueryResult<GetColorCategoriesQuery, GetColorCategoriesQueryVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($customerId: Int!) {
  customer(customerId: $customerId) {
    id
    firstName
    lastName
    businessName
    primaryPhone
    primaryAddress {
      id
      streetAddress
      city
      zip
      apartmentNumber
    }
    billingAddress {
      id
      streetAddress
      city
      zip
      apartmentNumber
    }
    email
    receivePromoEmails
    customerContacts {
      ...CustomerContact
    }
  }
}
    ${CustomerContactFragmentDoc}`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetFilteredBlockedTimeSlotsDocument = gql`
    query GetFilteredBlockedTimeSlots($dates: [DateTime!]!, $marketId: Int, $ts: AtomicTimeSlotInput!) {
  filteredBlockedTimeSlots(dates: $dates, marketId: $marketId, ts: $ts) {
    id
    date
    marketTimeSlotId
    marketId
    timeSlot {
      startHour
      startMinute
      endHour
      endMinute
    }
  }
}
    `;

/**
 * __useGetFilteredBlockedTimeSlotsQuery__
 *
 * To run a query within a React component, call `useGetFilteredBlockedTimeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredBlockedTimeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredBlockedTimeSlotsQuery({
 *   variables: {
 *      dates: // value for 'dates'
 *      marketId: // value for 'marketId'
 *      ts: // value for 'ts'
 *   },
 * });
 */
export function useGetFilteredBlockedTimeSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetFilteredBlockedTimeSlotsQuery, GetFilteredBlockedTimeSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilteredBlockedTimeSlotsQuery, GetFilteredBlockedTimeSlotsQueryVariables>(GetFilteredBlockedTimeSlotsDocument, options);
      }
export function useGetFilteredBlockedTimeSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilteredBlockedTimeSlotsQuery, GetFilteredBlockedTimeSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilteredBlockedTimeSlotsQuery, GetFilteredBlockedTimeSlotsQueryVariables>(GetFilteredBlockedTimeSlotsDocument, options);
        }
export type GetFilteredBlockedTimeSlotsQueryHookResult = ReturnType<typeof useGetFilteredBlockedTimeSlotsQuery>;
export type GetFilteredBlockedTimeSlotsLazyQueryHookResult = ReturnType<typeof useGetFilteredBlockedTimeSlotsLazyQuery>;
export type GetFilteredBlockedTimeSlotsQueryResult = Apollo.QueryResult<GetFilteredBlockedTimeSlotsQuery, GetFilteredBlockedTimeSlotsQueryVariables>;
export const GetInstallationAppointmentWithRoomsDocument = gql`
    query GetInstallationAppointmentWithRooms($installationAppointmentId: Int!) {
  installationAppointmentWithRooms(installationAppointmentId: $installationAppointmentId) {
    id
    appointment {
      ...InstallationAppointment
    }
    rooms {
      id
      areaId
      installationAppointmentId
      labels {
        id
        roomId
        hasCloset
        customName
        labelNumber
        label {
          id
          label
          fullLabel
        }
      }
      sqft
      product {
        productTypeId
        productType
        productStyleId
        productStyle
      }
    }
  }
}
    ${InstallationAppointmentFragmentDoc}`;

/**
 * __useGetInstallationAppointmentWithRoomsQuery__
 *
 * To run a query within a React component, call `useGetInstallationAppointmentWithRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallationAppointmentWithRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallationAppointmentWithRoomsQuery({
 *   variables: {
 *      installationAppointmentId: // value for 'installationAppointmentId'
 *   },
 * });
 */
export function useGetInstallationAppointmentWithRoomsQuery(baseOptions: Apollo.QueryHookOptions<GetInstallationAppointmentWithRoomsQuery, GetInstallationAppointmentWithRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstallationAppointmentWithRoomsQuery, GetInstallationAppointmentWithRoomsQueryVariables>(GetInstallationAppointmentWithRoomsDocument, options);
      }
export function useGetInstallationAppointmentWithRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstallationAppointmentWithRoomsQuery, GetInstallationAppointmentWithRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstallationAppointmentWithRoomsQuery, GetInstallationAppointmentWithRoomsQueryVariables>(GetInstallationAppointmentWithRoomsDocument, options);
        }
export type GetInstallationAppointmentWithRoomsQueryHookResult = ReturnType<typeof useGetInstallationAppointmentWithRoomsQuery>;
export type GetInstallationAppointmentWithRoomsLazyQueryHookResult = ReturnType<typeof useGetInstallationAppointmentWithRoomsLazyQuery>;
export type GetInstallationAppointmentWithRoomsQueryResult = Apollo.QueryResult<GetInstallationAppointmentWithRoomsQuery, GetInstallationAppointmentWithRoomsQueryVariables>;
export const GetInstallationAppointmentsDocument = gql`
    query GetInstallationAppointments($jobContractId: Int!) {
  installationAppointments(jobContractId: $jobContractId) {
    id
    appointment {
      ...InstallationAppointment
    }
    rooms {
      id
      areaId
      installationAppointmentId
      labels {
        id
        roomId
        hasCloset
        customName
        labelNumber
        label {
          id
          label
          fullLabel
        }
      }
      sqft
      product {
        productTypeId
        productType
        productStyleId
        productStyle
      }
    }
  }
}
    ${InstallationAppointmentFragmentDoc}`;

/**
 * __useGetInstallationAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetInstallationAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallationAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallationAppointmentsQuery({
 *   variables: {
 *      jobContractId: // value for 'jobContractId'
 *   },
 * });
 */
export function useGetInstallationAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<GetInstallationAppointmentsQuery, GetInstallationAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstallationAppointmentsQuery, GetInstallationAppointmentsQueryVariables>(GetInstallationAppointmentsDocument, options);
      }
export function useGetInstallationAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstallationAppointmentsQuery, GetInstallationAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstallationAppointmentsQuery, GetInstallationAppointmentsQueryVariables>(GetInstallationAppointmentsDocument, options);
        }
export type GetInstallationAppointmentsQueryHookResult = ReturnType<typeof useGetInstallationAppointmentsQuery>;
export type GetInstallationAppointmentsLazyQueryHookResult = ReturnType<typeof useGetInstallationAppointmentsLazyQuery>;
export type GetInstallationAppointmentsQueryResult = Apollo.QueryResult<GetInstallationAppointmentsQuery, GetInstallationAppointmentsQueryVariables>;
export const GetPhoneNumberStatusDocument = gql`
    query GetPhoneNumberStatus($phoneNumber: String!) {
  phoneNumberStatus(phoneNumber: $phoneNumber) {
    id
    customerId
    activeJobId
  }
}
    `;

/**
 * __useGetPhoneNumberStatusQuery__
 *
 * To run a query within a React component, call `useGetPhoneNumberStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhoneNumberStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhoneNumberStatusQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useGetPhoneNumberStatusQuery(baseOptions: Apollo.QueryHookOptions<GetPhoneNumberStatusQuery, GetPhoneNumberStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhoneNumberStatusQuery, GetPhoneNumberStatusQueryVariables>(GetPhoneNumberStatusDocument, options);
      }
export function useGetPhoneNumberStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhoneNumberStatusQuery, GetPhoneNumberStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhoneNumberStatusQuery, GetPhoneNumberStatusQueryVariables>(GetPhoneNumberStatusDocument, options);
        }
export type GetPhoneNumberStatusQueryHookResult = ReturnType<typeof useGetPhoneNumberStatusQuery>;
export type GetPhoneNumberStatusLazyQueryHookResult = ReturnType<typeof useGetPhoneNumberStatusLazyQuery>;
export type GetPhoneNumberStatusQueryResult = Apollo.QueryResult<GetPhoneNumberStatusQuery, GetPhoneNumberStatusQueryVariables>;
export const GetPriorityOptionsDocument = gql`
    query GetPriorityOptions {
  priorityOptions {
    id
    text
  }
}
    `;

/**
 * __useGetPriorityOptionsQuery__
 *
 * To run a query within a React component, call `useGetPriorityOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriorityOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriorityOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPriorityOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPriorityOptionsQuery, GetPriorityOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPriorityOptionsQuery, GetPriorityOptionsQueryVariables>(GetPriorityOptionsDocument, options);
      }
export function useGetPriorityOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPriorityOptionsQuery, GetPriorityOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPriorityOptionsQuery, GetPriorityOptionsQueryVariables>(GetPriorityOptionsDocument, options);
        }
export type GetPriorityOptionsQueryHookResult = ReturnType<typeof useGetPriorityOptionsQuery>;
export type GetPriorityOptionsLazyQueryHookResult = ReturnType<typeof useGetPriorityOptionsLazyQuery>;
export type GetPriorityOptionsQueryResult = Apollo.QueryResult<GetPriorityOptionsQuery, GetPriorityOptionsQueryVariables>;
export const GetSahAppointmentIdForJobDocument = gql`
    query GetSAHAppointmentIdForJob($jobConfigurationId: Int!) {
  sAHAppointmentIdForJob(jobConfigurationId: $jobConfigurationId)
}
    `;

/**
 * __useGetSahAppointmentIdForJobQuery__
 *
 * To run a query within a React component, call `useGetSahAppointmentIdForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSahAppointmentIdForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSahAppointmentIdForJobQuery({
 *   variables: {
 *      jobConfigurationId: // value for 'jobConfigurationId'
 *   },
 * });
 */
export function useGetSahAppointmentIdForJobQuery(baseOptions: Apollo.QueryHookOptions<GetSahAppointmentIdForJobQuery, GetSahAppointmentIdForJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSahAppointmentIdForJobQuery, GetSahAppointmentIdForJobQueryVariables>(GetSahAppointmentIdForJobDocument, options);
      }
export function useGetSahAppointmentIdForJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSahAppointmentIdForJobQuery, GetSahAppointmentIdForJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSahAppointmentIdForJobQuery, GetSahAppointmentIdForJobQueryVariables>(GetSahAppointmentIdForJobDocument, options);
        }
export type GetSahAppointmentIdForJobQueryHookResult = ReturnType<typeof useGetSahAppointmentIdForJobQuery>;
export type GetSahAppointmentIdForJobLazyQueryHookResult = ReturnType<typeof useGetSahAppointmentIdForJobLazyQuery>;
export type GetSahAppointmentIdForJobQueryResult = Apollo.QueryResult<GetSahAppointmentIdForJobQuery, GetSahAppointmentIdForJobQueryVariables>;
export const GetSahAppointmentsForDateDocument = gql`
    query GetSahAppointmentsForDate($date: DateTime!, $marketId: Int!) {
  sAHAppointmentsForDate(date: $date, marketId: $marketId) {
    id
    appointmentNumber
    jobId
    numRooms
    date
    isForCustomerHouse
    isCancelled
    isInRecovery
    comments
    promoCodeId
    howDidYouHearAboutUsOptionId
    howDidYouHearAboutUsOption
    productTypes
    productTypeIds
    customerId
    firstName
    lastName
    primaryAddress
    primaryCity
    primaryZipCode
    primaryPhone
    appointmentStatusId
    appointmentStatusString
    activeJobConfigurationId
    contractCancelled
    contractId
    contractTotalPrice
    quoteId
    quoteTotalPrice
    marketTimeSlot {
      id
      startTime
      endTime
      marketId
    }
    currentSalesperson {
      id
      sAHAppointmentId
      salespersonId
      releasedTime
      acceptedTime
      checkInTime
      checkOutTime
      appointmentStartTime
      isWriteUp
    }
    priorityOptions {
      id
      text
    }
    colorCategories {
      id
      category
    }
  }
  allBlockedTimesSlotsOnDay(date: $date, marketId: $marketId) {
    salespersonId
    marketId
    marketTimeSlotId
    date
    workerBlockedTimeSlotIdIfDeletable
  }
}
    `;

/**
 * __useGetSahAppointmentsForDateQuery__
 *
 * To run a query within a React component, call `useGetSahAppointmentsForDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSahAppointmentsForDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSahAppointmentsForDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useGetSahAppointmentsForDateQuery(baseOptions: Apollo.QueryHookOptions<GetSahAppointmentsForDateQuery, GetSahAppointmentsForDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSahAppointmentsForDateQuery, GetSahAppointmentsForDateQueryVariables>(GetSahAppointmentsForDateDocument, options);
      }
export function useGetSahAppointmentsForDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSahAppointmentsForDateQuery, GetSahAppointmentsForDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSahAppointmentsForDateQuery, GetSahAppointmentsForDateQueryVariables>(GetSahAppointmentsForDateDocument, options);
        }
export type GetSahAppointmentsForDateQueryHookResult = ReturnType<typeof useGetSahAppointmentsForDateQuery>;
export type GetSahAppointmentsForDateLazyQueryHookResult = ReturnType<typeof useGetSahAppointmentsForDateLazyQuery>;
export type GetSahAppointmentsForDateQueryResult = Apollo.QueryResult<GetSahAppointmentsForDateQuery, GetSahAppointmentsForDateQueryVariables>;
export const GetSahAppointmentsForSalespersonDocument = gql`
    query GetSahAppointmentsForSalesperson($salespersonId: Int!) {
  sAHAppointmentsForSalesperson(salespersonId: $salespersonId) {
    id
    appointmentNumber
    jobId
    numRooms
    date
    isForCustomerHouse
    isCancelled
    isInRecovery
    comments
    promoCodeId
    howDidYouHearAboutUsOptionId
    howDidYouHearAboutUsOption
    productTypes
    productTypeIds
    customerId
    firstName
    lastName
    primaryAddress
    primaryCity
    primaryZipCode
    primaryPhone
    appointmentStatusId
    appointmentStatusString
    contractCancelled
    contractTotalPrice
    quoteTotalPrice
    marketTimeSlot {
      id
      startTime
      endTime
      marketId
    }
    currentSalesperson {
      id
      sAHAppointmentId
      salespersonId
      releasedTime
      acceptedTime
      checkInTime
      checkOutTime
      appointmentStartTime
      isWriteUp
    }
    priorityOptions {
      id
      text
    }
    colorCategories {
      id
      category
    }
  }
}
    `;

/**
 * __useGetSahAppointmentsForSalespersonQuery__
 *
 * To run a query within a React component, call `useGetSahAppointmentsForSalespersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSahAppointmentsForSalespersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSahAppointmentsForSalespersonQuery({
 *   variables: {
 *      salespersonId: // value for 'salespersonId'
 *   },
 * });
 */
export function useGetSahAppointmentsForSalespersonQuery(baseOptions: Apollo.QueryHookOptions<GetSahAppointmentsForSalespersonQuery, GetSahAppointmentsForSalespersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSahAppointmentsForSalespersonQuery, GetSahAppointmentsForSalespersonQueryVariables>(GetSahAppointmentsForSalespersonDocument, options);
      }
export function useGetSahAppointmentsForSalespersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSahAppointmentsForSalespersonQuery, GetSahAppointmentsForSalespersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSahAppointmentsForSalespersonQuery, GetSahAppointmentsForSalespersonQueryVariables>(GetSahAppointmentsForSalespersonDocument, options);
        }
export type GetSahAppointmentsForSalespersonQueryHookResult = ReturnType<typeof useGetSahAppointmentsForSalespersonQuery>;
export type GetSahAppointmentsForSalespersonLazyQueryHookResult = ReturnType<typeof useGetSahAppointmentsForSalespersonLazyQuery>;
export type GetSahAppointmentsForSalespersonQueryResult = Apollo.QueryResult<GetSahAppointmentsForSalespersonQuery, GetSahAppointmentsForSalespersonQueryVariables>;
export const AcceptSahAppointmentDocument = gql`
    mutation AcceptSAHAppointment($sahAppointmentId: Int!) {
  acceptSAHAppointment(sahAppointmentId: $sahAppointmentId) {
    id
    appointmentNumber
    jobId
    numRooms
    date
    isForCustomerHouse
    isCancelled
    isInRecovery
    comments
    promoCodeId
    howDidYouHearAboutUsOptionId
    howDidYouHearAboutUsOption
    productTypes
    productTypeIds
    customerId
    firstName
    lastName
    primaryAddress
    primaryCity
    primaryZipCode
    primaryPhone
    appointmentStatusId
    appointmentStatusString
    contractCancelled
    contractTotalPrice
    quoteTotalPrice
    marketTimeSlot {
      id
      startTime
      endTime
      marketId
    }
    currentSalesperson {
      id
      sAHAppointmentId
      salespersonId
      releasedTime
      acceptedTime
      checkInTime
      checkOutTime
      appointmentStartTime
      isWriteUp
    }
    priorityOptions {
      id
      text
    }
    colorCategories {
      id
      category
    }
  }
}
    `;
export type AcceptSahAppointmentMutationFn = Apollo.MutationFunction<AcceptSahAppointmentMutation, AcceptSahAppointmentMutationVariables>;

/**
 * __useAcceptSahAppointmentMutation__
 *
 * To run a mutation, you first call `useAcceptSahAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSahAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSahAppointmentMutation, { data, loading, error }] = useAcceptSahAppointmentMutation({
 *   variables: {
 *      sahAppointmentId: // value for 'sahAppointmentId'
 *   },
 * });
 */
export function useAcceptSahAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<AcceptSahAppointmentMutation, AcceptSahAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptSahAppointmentMutation, AcceptSahAppointmentMutationVariables>(AcceptSahAppointmentDocument, options);
      }
export type AcceptSahAppointmentMutationHookResult = ReturnType<typeof useAcceptSahAppointmentMutation>;
export type AcceptSahAppointmentMutationResult = Apollo.MutationResult<AcceptSahAppointmentMutation>;
export type AcceptSahAppointmentMutationOptions = Apollo.BaseMutationOptions<AcceptSahAppointmentMutation, AcceptSahAppointmentMutationVariables>;
export const AddInstallationAppointmentDocument = gql`
    mutation AddInstallationAppointment($jobContractId: Int!, $startDate: DateTime!, $notes: String!, $cod: Decimal!, $roomIds: [Int!]!) {
  addInstallationAppointment(jobContractId: $jobContractId, startDate: $startDate, notes: $notes, cod: $cod, roomIds: $roomIds)
}
    `;
export type AddInstallationAppointmentMutationFn = Apollo.MutationFunction<AddInstallationAppointmentMutation, AddInstallationAppointmentMutationVariables>;

/**
 * __useAddInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `useAddInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInstallationAppointmentMutation, { data, loading, error }] = useAddInstallationAppointmentMutation({
 *   variables: {
 *      jobContractId: // value for 'jobContractId'
 *      startDate: // value for 'startDate'
 *      notes: // value for 'notes'
 *      cod: // value for 'cod'
 *      roomIds: // value for 'roomIds'
 *   },
 * });
 */
export function useAddInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<AddInstallationAppointmentMutation, AddInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInstallationAppointmentMutation, AddInstallationAppointmentMutationVariables>(AddInstallationAppointmentDocument, options);
      }
export type AddInstallationAppointmentMutationHookResult = ReturnType<typeof useAddInstallationAppointmentMutation>;
export type AddInstallationAppointmentMutationResult = Apollo.MutationResult<AddInstallationAppointmentMutation>;
export type AddInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<AddInstallationAppointmentMutation, AddInstallationAppointmentMutationVariables>;
export const AddMarketDocument = gql`
    mutation AddMarket($name: String!, $appointmentHours: Int!, $appointmentMinutes: Int!, $msrpScalar: Decimal) {
  addMarket(name: $name, appointmentHours: $appointmentHours, appointmentMinutes: $appointmentMinutes, msrpScalar: $msrpScalar)
}
    `;
export type AddMarketMutationFn = Apollo.MutationFunction<AddMarketMutation, AddMarketMutationVariables>;

/**
 * __useAddMarketMutation__
 *
 * To run a mutation, you first call `useAddMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMarketMutation, { data, loading, error }] = useAddMarketMutation({
 *   variables: {
 *      name: // value for 'name'
 *      appointmentHours: // value for 'appointmentHours'
 *      appointmentMinutes: // value for 'appointmentMinutes'
 *      msrpScalar: // value for 'msrpScalar'
 *   },
 * });
 */
export function useAddMarketMutation(baseOptions?: Apollo.MutationHookOptions<AddMarketMutation, AddMarketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMarketMutation, AddMarketMutationVariables>(AddMarketDocument, options);
      }
export type AddMarketMutationHookResult = ReturnType<typeof useAddMarketMutation>;
export type AddMarketMutationResult = Apollo.MutationResult<AddMarketMutation>;
export type AddMarketMutationOptions = Apollo.BaseMutationOptions<AddMarketMutation, AddMarketMutationVariables>;
export const AddNewSahAppointmentDocument = gql`
    mutation AddNewSAHAppointment($newSAHAppointment: RawSAHAppointmentDataInput!) {
  addNewSAHAppointment(rawData: $newSAHAppointment)
}
    `;
export type AddNewSahAppointmentMutationFn = Apollo.MutationFunction<AddNewSahAppointmentMutation, AddNewSahAppointmentMutationVariables>;

/**
 * __useAddNewSahAppointmentMutation__
 *
 * To run a mutation, you first call `useAddNewSahAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewSahAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewSahAppointmentMutation, { data, loading, error }] = useAddNewSahAppointmentMutation({
 *   variables: {
 *      newSAHAppointment: // value for 'newSAHAppointment'
 *   },
 * });
 */
export function useAddNewSahAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<AddNewSahAppointmentMutation, AddNewSahAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewSahAppointmentMutation, AddNewSahAppointmentMutationVariables>(AddNewSahAppointmentDocument, options);
      }
export type AddNewSahAppointmentMutationHookResult = ReturnType<typeof useAddNewSahAppointmentMutation>;
export type AddNewSahAppointmentMutationResult = Apollo.MutationResult<AddNewSahAppointmentMutation>;
export type AddNewSahAppointmentMutationOptions = Apollo.BaseMutationOptions<AddNewSahAppointmentMutation, AddNewSahAppointmentMutationVariables>;
export const AddRescheduledSahAppointmentDocument = gql`
    mutation AddRescheduledSAHAppointment($jobId: Int!, $date: DateTime!, $marketTimeSlotId: Int!, $salespersonId: Int, $isWriteUp: Boolean) {
  addRescheduledSAHAppointment(jobId: $jobId, date: $date, marketTimeSlotId: $marketTimeSlotId, salespersonId: $salespersonId, isWriteUp: $isWriteUp)
}
    `;
export type AddRescheduledSahAppointmentMutationFn = Apollo.MutationFunction<AddRescheduledSahAppointmentMutation, AddRescheduledSahAppointmentMutationVariables>;

/**
 * __useAddRescheduledSahAppointmentMutation__
 *
 * To run a mutation, you first call `useAddRescheduledSahAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRescheduledSahAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRescheduledSahAppointmentMutation, { data, loading, error }] = useAddRescheduledSahAppointmentMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      date: // value for 'date'
 *      marketTimeSlotId: // value for 'marketTimeSlotId'
 *      salespersonId: // value for 'salespersonId'
 *      isWriteUp: // value for 'isWriteUp'
 *   },
 * });
 */
export function useAddRescheduledSahAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<AddRescheduledSahAppointmentMutation, AddRescheduledSahAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRescheduledSahAppointmentMutation, AddRescheduledSahAppointmentMutationVariables>(AddRescheduledSahAppointmentDocument, options);
      }
export type AddRescheduledSahAppointmentMutationHookResult = ReturnType<typeof useAddRescheduledSahAppointmentMutation>;
export type AddRescheduledSahAppointmentMutationResult = Apollo.MutationResult<AddRescheduledSahAppointmentMutation>;
export type AddRescheduledSahAppointmentMutationOptions = Apollo.BaseMutationOptions<AddRescheduledSahAppointmentMutation, AddRescheduledSahAppointmentMutationVariables>;
export const BlockTimeSlotByMarketsDocument = gql`
    mutation BlockTimeSlotByMarkets($dates: [DateTime!]!, $marketIds: [Int!]!, $ts: AtomicTimeSlotInput!) {
  blockTimeSlotByMarkets(dates: $dates, marketIds: $marketIds, ts: $ts)
}
    `;
export type BlockTimeSlotByMarketsMutationFn = Apollo.MutationFunction<BlockTimeSlotByMarketsMutation, BlockTimeSlotByMarketsMutationVariables>;

/**
 * __useBlockTimeSlotByMarketsMutation__
 *
 * To run a mutation, you first call `useBlockTimeSlotByMarketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockTimeSlotByMarketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockTimeSlotByMarketsMutation, { data, loading, error }] = useBlockTimeSlotByMarketsMutation({
 *   variables: {
 *      dates: // value for 'dates'
 *      marketIds: // value for 'marketIds'
 *      ts: // value for 'ts'
 *   },
 * });
 */
export function useBlockTimeSlotByMarketsMutation(baseOptions?: Apollo.MutationHookOptions<BlockTimeSlotByMarketsMutation, BlockTimeSlotByMarketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockTimeSlotByMarketsMutation, BlockTimeSlotByMarketsMutationVariables>(BlockTimeSlotByMarketsDocument, options);
      }
export type BlockTimeSlotByMarketsMutationHookResult = ReturnType<typeof useBlockTimeSlotByMarketsMutation>;
export type BlockTimeSlotByMarketsMutationResult = Apollo.MutationResult<BlockTimeSlotByMarketsMutation>;
export type BlockTimeSlotByMarketsMutationOptions = Apollo.BaseMutationOptions<BlockTimeSlotByMarketsMutation, BlockTimeSlotByMarketsMutationVariables>;
export const BlockTimeSlotForAllZipsDocument = gql`
    mutation BlockTimeSlotForAllZips($dates: [DateTime!]!, $ts: AtomicTimeSlotInput!) {
  blockTimeSlotForAllZips(dates: $dates, ts: $ts)
}
    `;
export type BlockTimeSlotForAllZipsMutationFn = Apollo.MutationFunction<BlockTimeSlotForAllZipsMutation, BlockTimeSlotForAllZipsMutationVariables>;

/**
 * __useBlockTimeSlotForAllZipsMutation__
 *
 * To run a mutation, you first call `useBlockTimeSlotForAllZipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockTimeSlotForAllZipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockTimeSlotForAllZipsMutation, { data, loading, error }] = useBlockTimeSlotForAllZipsMutation({
 *   variables: {
 *      dates: // value for 'dates'
 *      ts: // value for 'ts'
 *   },
 * });
 */
export function useBlockTimeSlotForAllZipsMutation(baseOptions?: Apollo.MutationHookOptions<BlockTimeSlotForAllZipsMutation, BlockTimeSlotForAllZipsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockTimeSlotForAllZipsMutation, BlockTimeSlotForAllZipsMutationVariables>(BlockTimeSlotForAllZipsDocument, options);
      }
export type BlockTimeSlotForAllZipsMutationHookResult = ReturnType<typeof useBlockTimeSlotForAllZipsMutation>;
export type BlockTimeSlotForAllZipsMutationResult = Apollo.MutationResult<BlockTimeSlotForAllZipsMutation>;
export type BlockTimeSlotForAllZipsMutationOptions = Apollo.BaseMutationOptions<BlockTimeSlotForAllZipsMutation, BlockTimeSlotForAllZipsMutationVariables>;
export const CancelSahAppointmentDocument = gql`
    mutation CancelSAHAppointment($sahAppointmentId: Int!) {
  cancelSAHAppointment(sahAppointmentId: $sahAppointmentId) {
    id
    appointmentNumber
    jobId
    numRooms
    date
    isForCustomerHouse
    isCancelled
    isInRecovery
    comments
    promoCodeId
    howDidYouHearAboutUsOptionId
    howDidYouHearAboutUsOption
    productTypes
    productTypeIds
    customerId
    firstName
    lastName
    primaryAddress
    primaryCity
    primaryZipCode
    primaryPhone
    appointmentStatusId
    appointmentStatusString
    contractCancelled
    contractTotalPrice
    quoteTotalPrice
    marketTimeSlot {
      id
      startTime
      endTime
      marketId
    }
    currentSalesperson {
      id
      sAHAppointmentId
      salespersonId
      releasedTime
      acceptedTime
      checkInTime
      checkOutTime
      appointmentStartTime
      isWriteUp
    }
    priorityOptions {
      id
      text
    }
    colorCategories {
      id
      category
    }
  }
}
    `;
export type CancelSahAppointmentMutationFn = Apollo.MutationFunction<CancelSahAppointmentMutation, CancelSahAppointmentMutationVariables>;

/**
 * __useCancelSahAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelSahAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSahAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSahAppointmentMutation, { data, loading, error }] = useCancelSahAppointmentMutation({
 *   variables: {
 *      sahAppointmentId: // value for 'sahAppointmentId'
 *   },
 * });
 */
export function useCancelSahAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelSahAppointmentMutation, CancelSahAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSahAppointmentMutation, CancelSahAppointmentMutationVariables>(CancelSahAppointmentDocument, options);
      }
export type CancelSahAppointmentMutationHookResult = ReturnType<typeof useCancelSahAppointmentMutation>;
export type CancelSahAppointmentMutationResult = Apollo.MutationResult<CancelSahAppointmentMutation>;
export type CancelSahAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelSahAppointmentMutation, CancelSahAppointmentMutationVariables>;
export const CheckIntoSahAppointmentDocument = gql`
    mutation CheckIntoSAHAppointment($sahAppointmentId: Int!) {
  checkIntoSAHAppointment(sahAppointmentId: $sahAppointmentId) {
    id
    appointmentNumber
    jobId
    numRooms
    date
    isForCustomerHouse
    isCancelled
    isInRecovery
    comments
    promoCodeId
    howDidYouHearAboutUsOptionId
    howDidYouHearAboutUsOption
    productTypes
    productTypeIds
    customerId
    firstName
    lastName
    primaryAddress
    primaryCity
    primaryZipCode
    primaryPhone
    appointmentStatusId
    appointmentStatusString
    contractCancelled
    contractTotalPrice
    quoteTotalPrice
    marketTimeSlot {
      id
      startTime
      endTime
      marketId
    }
    currentSalesperson {
      id
      sAHAppointmentId
      salespersonId
      releasedTime
      acceptedTime
      checkInTime
      checkOutTime
      appointmentStartTime
      isWriteUp
    }
    priorityOptions {
      id
      text
    }
    colorCategories {
      id
      category
    }
  }
}
    `;
export type CheckIntoSahAppointmentMutationFn = Apollo.MutationFunction<CheckIntoSahAppointmentMutation, CheckIntoSahAppointmentMutationVariables>;

/**
 * __useCheckIntoSahAppointmentMutation__
 *
 * To run a mutation, you first call `useCheckIntoSahAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIntoSahAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIntoSahAppointmentMutation, { data, loading, error }] = useCheckIntoSahAppointmentMutation({
 *   variables: {
 *      sahAppointmentId: // value for 'sahAppointmentId'
 *   },
 * });
 */
export function useCheckIntoSahAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CheckIntoSahAppointmentMutation, CheckIntoSahAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckIntoSahAppointmentMutation, CheckIntoSahAppointmentMutationVariables>(CheckIntoSahAppointmentDocument, options);
      }
export type CheckIntoSahAppointmentMutationHookResult = ReturnType<typeof useCheckIntoSahAppointmentMutation>;
export type CheckIntoSahAppointmentMutationResult = Apollo.MutationResult<CheckIntoSahAppointmentMutation>;
export type CheckIntoSahAppointmentMutationOptions = Apollo.BaseMutationOptions<CheckIntoSahAppointmentMutation, CheckIntoSahAppointmentMutationVariables>;
export const CheckOutOfSahAppointmentDocument = gql`
    mutation CheckOutOfSAHAppointment($sahAppointmentId: Int!) {
  checkOutOfSAHAppointment(sahAppointmentId: $sahAppointmentId) {
    id
    appointmentNumber
    jobId
    numRooms
    date
    isForCustomerHouse
    isCancelled
    isInRecovery
    comments
    promoCodeId
    howDidYouHearAboutUsOptionId
    howDidYouHearAboutUsOption
    productTypes
    productTypeIds
    customerId
    firstName
    lastName
    primaryAddress
    primaryCity
    primaryZipCode
    primaryPhone
    appointmentStatusId
    appointmentStatusString
    contractCancelled
    contractTotalPrice
    quoteTotalPrice
    marketTimeSlot {
      id
      startTime
      endTime
      marketId
    }
    currentSalesperson {
      id
      sAHAppointmentId
      salespersonId
      releasedTime
      acceptedTime
      checkInTime
      checkOutTime
      appointmentStartTime
      isWriteUp
    }
    priorityOptions {
      id
      text
    }
    colorCategories {
      id
      category
    }
  }
}
    `;
export type CheckOutOfSahAppointmentMutationFn = Apollo.MutationFunction<CheckOutOfSahAppointmentMutation, CheckOutOfSahAppointmentMutationVariables>;

/**
 * __useCheckOutOfSahAppointmentMutation__
 *
 * To run a mutation, you first call `useCheckOutOfSahAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOutOfSahAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOutOfSahAppointmentMutation, { data, loading, error }] = useCheckOutOfSahAppointmentMutation({
 *   variables: {
 *      sahAppointmentId: // value for 'sahAppointmentId'
 *   },
 * });
 */
export function useCheckOutOfSahAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CheckOutOfSahAppointmentMutation, CheckOutOfSahAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckOutOfSahAppointmentMutation, CheckOutOfSahAppointmentMutationVariables>(CheckOutOfSahAppointmentDocument, options);
      }
export type CheckOutOfSahAppointmentMutationHookResult = ReturnType<typeof useCheckOutOfSahAppointmentMutation>;
export type CheckOutOfSahAppointmentMutationResult = Apollo.MutationResult<CheckOutOfSahAppointmentMutation>;
export type CheckOutOfSahAppointmentMutationOptions = Apollo.BaseMutationOptions<CheckOutOfSahAppointmentMutation, CheckOutOfSahAppointmentMutationVariables>;
export const DeleteBlockedTimeSlotsDocument = gql`
    mutation DeleteBlockedTimeSlots($ids: [Int!]!) {
  deleteBlockedTimeSlots(ids: $ids)
}
    `;
export type DeleteBlockedTimeSlotsMutationFn = Apollo.MutationFunction<DeleteBlockedTimeSlotsMutation, DeleteBlockedTimeSlotsMutationVariables>;

/**
 * __useDeleteBlockedTimeSlotsMutation__
 *
 * To run a mutation, you first call `useDeleteBlockedTimeSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlockedTimeSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlockedTimeSlotsMutation, { data, loading, error }] = useDeleteBlockedTimeSlotsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteBlockedTimeSlotsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlockedTimeSlotsMutation, DeleteBlockedTimeSlotsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlockedTimeSlotsMutation, DeleteBlockedTimeSlotsMutationVariables>(DeleteBlockedTimeSlotsDocument, options);
      }
export type DeleteBlockedTimeSlotsMutationHookResult = ReturnType<typeof useDeleteBlockedTimeSlotsMutation>;
export type DeleteBlockedTimeSlotsMutationResult = Apollo.MutationResult<DeleteBlockedTimeSlotsMutation>;
export type DeleteBlockedTimeSlotsMutationOptions = Apollo.BaseMutationOptions<DeleteBlockedTimeSlotsMutation, DeleteBlockedTimeSlotsMutationVariables>;
export const DeleteInstallationAppointmentDocument = gql`
    mutation DeleteInstallationAppointment($appointmentId: Int!) {
  deleteInstallationAppointment(appointmentId: $appointmentId)
}
    `;
export type DeleteInstallationAppointmentMutationFn = Apollo.MutationFunction<DeleteInstallationAppointmentMutation, DeleteInstallationAppointmentMutationVariables>;

/**
 * __useDeleteInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `useDeleteInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstallationAppointmentMutation, { data, loading, error }] = useDeleteInstallationAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useDeleteInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstallationAppointmentMutation, DeleteInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstallationAppointmentMutation, DeleteInstallationAppointmentMutationVariables>(DeleteInstallationAppointmentDocument, options);
      }
export type DeleteInstallationAppointmentMutationHookResult = ReturnType<typeof useDeleteInstallationAppointmentMutation>;
export type DeleteInstallationAppointmentMutationResult = Apollo.MutationResult<DeleteInstallationAppointmentMutation>;
export type DeleteInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<DeleteInstallationAppointmentMutation, DeleteInstallationAppointmentMutationVariables>;
export const DeleteMarketDocument = gql`
    mutation DeleteMarket($marketId: Int!) {
  deleteMarket(marketId: $marketId)
}
    `;
export type DeleteMarketMutationFn = Apollo.MutationFunction<DeleteMarketMutation, DeleteMarketMutationVariables>;

/**
 * __useDeleteMarketMutation__
 *
 * To run a mutation, you first call `useDeleteMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMarketMutation, { data, loading, error }] = useDeleteMarketMutation({
 *   variables: {
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useDeleteMarketMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMarketMutation, DeleteMarketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMarketMutation, DeleteMarketMutationVariables>(DeleteMarketDocument, options);
      }
export type DeleteMarketMutationHookResult = ReturnType<typeof useDeleteMarketMutation>;
export type DeleteMarketMutationResult = Apollo.MutationResult<DeleteMarketMutation>;
export type DeleteMarketMutationOptions = Apollo.BaseMutationOptions<DeleteMarketMutation, DeleteMarketMutationVariables>;
export const DeleteRoomForInstallationAppointmentDocument = gql`
    mutation DeleteRoomForInstallationAppointment($appointmentId: Int!, $roomId: Int!) {
  deleteRoomForInstallationAppointment(appointmentId: $appointmentId, roomId: $roomId)
}
    `;
export type DeleteRoomForInstallationAppointmentMutationFn = Apollo.MutationFunction<DeleteRoomForInstallationAppointmentMutation, DeleteRoomForInstallationAppointmentMutationVariables>;

/**
 * __useDeleteRoomForInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `useDeleteRoomForInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoomForInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoomForInstallationAppointmentMutation, { data, loading, error }] = useDeleteRoomForInstallationAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useDeleteRoomForInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoomForInstallationAppointmentMutation, DeleteRoomForInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoomForInstallationAppointmentMutation, DeleteRoomForInstallationAppointmentMutationVariables>(DeleteRoomForInstallationAppointmentDocument, options);
      }
export type DeleteRoomForInstallationAppointmentMutationHookResult = ReturnType<typeof useDeleteRoomForInstallationAppointmentMutation>;
export type DeleteRoomForInstallationAppointmentMutationResult = Apollo.MutationResult<DeleteRoomForInstallationAppointmentMutation>;
export type DeleteRoomForInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<DeleteRoomForInstallationAppointmentMutation, DeleteRoomForInstallationAppointmentMutationVariables>;
export const DeleteRoomsForInstallationAppointmentDocument = gql`
    mutation DeleteRoomsForInstallationAppointment($appointmentId: Int!, $roomIds: [Int!]!) {
  deleteRoomsForInstallationAppointment(appointmentId: $appointmentId, roomIds: $roomIds)
}
    `;
export type DeleteRoomsForInstallationAppointmentMutationFn = Apollo.MutationFunction<DeleteRoomsForInstallationAppointmentMutation, DeleteRoomsForInstallationAppointmentMutationVariables>;

/**
 * __useDeleteRoomsForInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `useDeleteRoomsForInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoomsForInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoomsForInstallationAppointmentMutation, { data, loading, error }] = useDeleteRoomsForInstallationAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      roomIds: // value for 'roomIds'
 *   },
 * });
 */
export function useDeleteRoomsForInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoomsForInstallationAppointmentMutation, DeleteRoomsForInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoomsForInstallationAppointmentMutation, DeleteRoomsForInstallationAppointmentMutationVariables>(DeleteRoomsForInstallationAppointmentDocument, options);
      }
export type DeleteRoomsForInstallationAppointmentMutationHookResult = ReturnType<typeof useDeleteRoomsForInstallationAppointmentMutation>;
export type DeleteRoomsForInstallationAppointmentMutationResult = Apollo.MutationResult<DeleteRoomsForInstallationAppointmentMutation>;
export type DeleteRoomsForInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<DeleteRoomsForInstallationAppointmentMutation, DeleteRoomsForInstallationAppointmentMutationVariables>;
export const DeleteWorkerBlockedTimeSlotDocument = gql`
    mutation DeleteWorkerBlockedTimeSlot($workerBlockedTimeSlotId: Int!) {
  deleteWorkerBlockedTimeSlot(workerBlockedTimeSlotId: $workerBlockedTimeSlotId)
}
    `;
export type DeleteWorkerBlockedTimeSlotMutationFn = Apollo.MutationFunction<DeleteWorkerBlockedTimeSlotMutation, DeleteWorkerBlockedTimeSlotMutationVariables>;

/**
 * __useDeleteWorkerBlockedTimeSlotMutation__
 *
 * To run a mutation, you first call `useDeleteWorkerBlockedTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkerBlockedTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkerBlockedTimeSlotMutation, { data, loading, error }] = useDeleteWorkerBlockedTimeSlotMutation({
 *   variables: {
 *      workerBlockedTimeSlotId: // value for 'workerBlockedTimeSlotId'
 *   },
 * });
 */
export function useDeleteWorkerBlockedTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkerBlockedTimeSlotMutation, DeleteWorkerBlockedTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkerBlockedTimeSlotMutation, DeleteWorkerBlockedTimeSlotMutationVariables>(DeleteWorkerBlockedTimeSlotDocument, options);
      }
export type DeleteWorkerBlockedTimeSlotMutationHookResult = ReturnType<typeof useDeleteWorkerBlockedTimeSlotMutation>;
export type DeleteWorkerBlockedTimeSlotMutationResult = Apollo.MutationResult<DeleteWorkerBlockedTimeSlotMutation>;
export type DeleteWorkerBlockedTimeSlotMutationOptions = Apollo.BaseMutationOptions<DeleteWorkerBlockedTimeSlotMutation, DeleteWorkerBlockedTimeSlotMutationVariables>;
export const ReleaseSahAppointmentDocument = gql`
    mutation ReleaseSAHAppointment($sahAppointmentId: Int!) {
  releaseSAHAppointment(sahAppointmentId: $sahAppointmentId) {
    id
    appointmentNumber
    jobId
    numRooms
    date
    isForCustomerHouse
    isCancelled
    isInRecovery
    comments
    promoCodeId
    howDidYouHearAboutUsOptionId
    howDidYouHearAboutUsOption
    productTypes
    productTypeIds
    customerId
    firstName
    lastName
    primaryAddress
    primaryCity
    primaryZipCode
    primaryPhone
    appointmentStatusId
    appointmentStatusString
    contractCancelled
    contractTotalPrice
    quoteTotalPrice
    marketTimeSlot {
      id
      startTime
      endTime
      marketId
    }
    currentSalesperson {
      id
      sAHAppointmentId
      salespersonId
      releasedTime
      acceptedTime
      checkInTime
      checkOutTime
      appointmentStartTime
      isWriteUp
    }
    priorityOptions {
      id
      text
    }
    colorCategories {
      id
      category
    }
  }
}
    `;
export type ReleaseSahAppointmentMutationFn = Apollo.MutationFunction<ReleaseSahAppointmentMutation, ReleaseSahAppointmentMutationVariables>;

/**
 * __useReleaseSahAppointmentMutation__
 *
 * To run a mutation, you first call `useReleaseSahAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseSahAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseSahAppointmentMutation, { data, loading, error }] = useReleaseSahAppointmentMutation({
 *   variables: {
 *      sahAppointmentId: // value for 'sahAppointmentId'
 *   },
 * });
 */
export function useReleaseSahAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseSahAppointmentMutation, ReleaseSahAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseSahAppointmentMutation, ReleaseSahAppointmentMutationVariables>(ReleaseSahAppointmentDocument, options);
      }
export type ReleaseSahAppointmentMutationHookResult = ReturnType<typeof useReleaseSahAppointmentMutation>;
export type ReleaseSahAppointmentMutationResult = Apollo.MutationResult<ReleaseSahAppointmentMutation>;
export type ReleaseSahAppointmentMutationOptions = Apollo.BaseMutationOptions<ReleaseSahAppointmentMutation, ReleaseSahAppointmentMutationVariables>;
export const ReportCustomerNotHomeSahAppointmentDocument = gql`
    mutation ReportCustomerNotHomeSAHAppointment($sahAppointmentId: Int!) {
  customerNotHomeSAHAppointment(sahAppointmentId: $sahAppointmentId) {
    id
    appointmentNumber
    jobId
    numRooms
    date
    isForCustomerHouse
    isCancelled
    isInRecovery
    comments
    promoCodeId
    howDidYouHearAboutUsOptionId
    howDidYouHearAboutUsOption
    productTypes
    productTypeIds
    customerId
    firstName
    lastName
    primaryAddress
    primaryCity
    primaryZipCode
    primaryPhone
    appointmentStatusId
    appointmentStatusString
    contractCancelled
    contractTotalPrice
    quoteTotalPrice
    marketTimeSlot {
      id
      startTime
      endTime
      marketId
    }
    currentSalesperson {
      id
      sAHAppointmentId
      salespersonId
      releasedTime
      acceptedTime
      checkInTime
      checkOutTime
      appointmentStartTime
      isWriteUp
    }
    priorityOptions {
      id
      text
    }
    colorCategories {
      id
      category
    }
  }
}
    `;
export type ReportCustomerNotHomeSahAppointmentMutationFn = Apollo.MutationFunction<ReportCustomerNotHomeSahAppointmentMutation, ReportCustomerNotHomeSahAppointmentMutationVariables>;

/**
 * __useReportCustomerNotHomeSahAppointmentMutation__
 *
 * To run a mutation, you first call `useReportCustomerNotHomeSahAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCustomerNotHomeSahAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCustomerNotHomeSahAppointmentMutation, { data, loading, error }] = useReportCustomerNotHomeSahAppointmentMutation({
 *   variables: {
 *      sahAppointmentId: // value for 'sahAppointmentId'
 *   },
 * });
 */
export function useReportCustomerNotHomeSahAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<ReportCustomerNotHomeSahAppointmentMutation, ReportCustomerNotHomeSahAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportCustomerNotHomeSahAppointmentMutation, ReportCustomerNotHomeSahAppointmentMutationVariables>(ReportCustomerNotHomeSahAppointmentDocument, options);
      }
export type ReportCustomerNotHomeSahAppointmentMutationHookResult = ReturnType<typeof useReportCustomerNotHomeSahAppointmentMutation>;
export type ReportCustomerNotHomeSahAppointmentMutationResult = Apollo.MutationResult<ReportCustomerNotHomeSahAppointmentMutation>;
export type ReportCustomerNotHomeSahAppointmentMutationOptions = Apollo.BaseMutationOptions<ReportCustomerNotHomeSahAppointmentMutation, ReportCustomerNotHomeSahAppointmentMutationVariables>;
export const UpdateAreaNotesDocument = gql`
    mutation UpdateAreaNotes($areaId: Int!, $newNotes: String!, $replace: Boolean!) {
  updateAreaNotes(areaId: $areaId, newNotes: $newNotes, replace: $replace)
}
    `;
export type UpdateAreaNotesMutationFn = Apollo.MutationFunction<UpdateAreaNotesMutation, UpdateAreaNotesMutationVariables>;

/**
 * __useUpdateAreaNotesMutation__
 *
 * To run a mutation, you first call `useUpdateAreaNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaNotesMutation, { data, loading, error }] = useUpdateAreaNotesMutation({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      newNotes: // value for 'newNotes'
 *      replace: // value for 'replace'
 *   },
 * });
 */
export function useUpdateAreaNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAreaNotesMutation, UpdateAreaNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAreaNotesMutation, UpdateAreaNotesMutationVariables>(UpdateAreaNotesDocument, options);
      }
export type UpdateAreaNotesMutationHookResult = ReturnType<typeof useUpdateAreaNotesMutation>;
export type UpdateAreaNotesMutationResult = Apollo.MutationResult<UpdateAreaNotesMutation>;
export type UpdateAreaNotesMutationOptions = Apollo.BaseMutationOptions<UpdateAreaNotesMutation, UpdateAreaNotesMutationVariables>;
export const UpdateInstallationAppointmentDocument = gql`
    mutation UpdateInstallationAppointment($appointmentId: Int!, $newContractorId: Int, $newStartDate: DateTime, $newNotes: String, $newCod: Decimal, $newCollectedCod: Decimal, $appointmentLength: Int) {
  updateInstallationAppointment(appointmentId: $appointmentId, newContractorId: $newContractorId, newStartDate: $newStartDate, newNotes: $newNotes, newCod: $newCod, newCollectedCod: $newCollectedCod, appointmentLength: $appointmentLength) {
    ...InstallationAppointment
  }
}
    ${InstallationAppointmentFragmentDoc}`;
export type UpdateInstallationAppointmentMutationFn = Apollo.MutationFunction<UpdateInstallationAppointmentMutation, UpdateInstallationAppointmentMutationVariables>;

/**
 * __useUpdateInstallationAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateInstallationAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstallationAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstallationAppointmentMutation, { data, loading, error }] = useUpdateInstallationAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      newContractorId: // value for 'newContractorId'
 *      newStartDate: // value for 'newStartDate'
 *      newNotes: // value for 'newNotes'
 *      newCod: // value for 'newCod'
 *      newCollectedCod: // value for 'newCollectedCod'
 *      appointmentLength: // value for 'appointmentLength'
 *   },
 * });
 */
export function useUpdateInstallationAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstallationAppointmentMutation, UpdateInstallationAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstallationAppointmentMutation, UpdateInstallationAppointmentMutationVariables>(UpdateInstallationAppointmentDocument, options);
      }
export type UpdateInstallationAppointmentMutationHookResult = ReturnType<typeof useUpdateInstallationAppointmentMutation>;
export type UpdateInstallationAppointmentMutationResult = Apollo.MutationResult<UpdateInstallationAppointmentMutation>;
export type UpdateInstallationAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateInstallationAppointmentMutation, UpdateInstallationAppointmentMutationVariables>;
export const UpdateInstallationAppointmentNotesDocument = gql`
    mutation UpdateInstallationAppointmentNotes($installationAppointmentId: Int!, $newNotes: String!, $replace: Boolean!) {
  updateInstallationAppointmentNotes(installationAppointmentId: $installationAppointmentId, newNotes: $newNotes, replace: $replace)
}
    `;
export type UpdateInstallationAppointmentNotesMutationFn = Apollo.MutationFunction<UpdateInstallationAppointmentNotesMutation, UpdateInstallationAppointmentNotesMutationVariables>;

/**
 * __useUpdateInstallationAppointmentNotesMutation__
 *
 * To run a mutation, you first call `useUpdateInstallationAppointmentNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstallationAppointmentNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstallationAppointmentNotesMutation, { data, loading, error }] = useUpdateInstallationAppointmentNotesMutation({
 *   variables: {
 *      installationAppointmentId: // value for 'installationAppointmentId'
 *      newNotes: // value for 'newNotes'
 *      replace: // value for 'replace'
 *   },
 * });
 */
export function useUpdateInstallationAppointmentNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstallationAppointmentNotesMutation, UpdateInstallationAppointmentNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstallationAppointmentNotesMutation, UpdateInstallationAppointmentNotesMutationVariables>(UpdateInstallationAppointmentNotesDocument, options);
      }
export type UpdateInstallationAppointmentNotesMutationHookResult = ReturnType<typeof useUpdateInstallationAppointmentNotesMutation>;
export type UpdateInstallationAppointmentNotesMutationResult = Apollo.MutationResult<UpdateInstallationAppointmentNotesMutation>;
export type UpdateInstallationAppointmentNotesMutationOptions = Apollo.BaseMutationOptions<UpdateInstallationAppointmentNotesMutation, UpdateInstallationAppointmentNotesMutationVariables>;
export const UpdateMarketDocument = gql`
    mutation UpdateMarket($id: Int!, $name: String, $appointmentHours: Int, $appointmentMinutes: Int, $msrpScalar: Decimal) {
  updateMarket(id: $id, name: $name, appointmentHours: $appointmentHours, appointmentMinutes: $appointmentMinutes, msrpScalar: $msrpScalar)
}
    `;
export type UpdateMarketMutationFn = Apollo.MutationFunction<UpdateMarketMutation, UpdateMarketMutationVariables>;

/**
 * __useUpdateMarketMutation__
 *
 * To run a mutation, you first call `useUpdateMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketMutation, { data, loading, error }] = useUpdateMarketMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      appointmentHours: // value for 'appointmentHours'
 *      appointmentMinutes: // value for 'appointmentMinutes'
 *      msrpScalar: // value for 'msrpScalar'
 *   },
 * });
 */
export function useUpdateMarketMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarketMutation, UpdateMarketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarketMutation, UpdateMarketMutationVariables>(UpdateMarketDocument, options);
      }
export type UpdateMarketMutationHookResult = ReturnType<typeof useUpdateMarketMutation>;
export type UpdateMarketMutationResult = Apollo.MutationResult<UpdateMarketMutation>;
export type UpdateMarketMutationOptions = Apollo.BaseMutationOptions<UpdateMarketMutation, UpdateMarketMutationVariables>;
export const UpdateMarketAndServicedForZipAndCityDocument = gql`
    mutation UpdateMarketAndServicedForZipAndCity($ids: [Int!]!, $marketId: Int, $serviced: Boolean!) {
  updateMarketAndServicedForZipAndCity(ids: $ids, marketId: $marketId, serviced: $serviced)
}
    `;
export type UpdateMarketAndServicedForZipAndCityMutationFn = Apollo.MutationFunction<UpdateMarketAndServicedForZipAndCityMutation, UpdateMarketAndServicedForZipAndCityMutationVariables>;

/**
 * __useUpdateMarketAndServicedForZipAndCityMutation__
 *
 * To run a mutation, you first call `useUpdateMarketAndServicedForZipAndCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketAndServicedForZipAndCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketAndServicedForZipAndCityMutation, { data, loading, error }] = useUpdateMarketAndServicedForZipAndCityMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      marketId: // value for 'marketId'
 *      serviced: // value for 'serviced'
 *   },
 * });
 */
export function useUpdateMarketAndServicedForZipAndCityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarketAndServicedForZipAndCityMutation, UpdateMarketAndServicedForZipAndCityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarketAndServicedForZipAndCityMutation, UpdateMarketAndServicedForZipAndCityMutationVariables>(UpdateMarketAndServicedForZipAndCityDocument, options);
      }
export type UpdateMarketAndServicedForZipAndCityMutationHookResult = ReturnType<typeof useUpdateMarketAndServicedForZipAndCityMutation>;
export type UpdateMarketAndServicedForZipAndCityMutationResult = Apollo.MutationResult<UpdateMarketAndServicedForZipAndCityMutation>;
export type UpdateMarketAndServicedForZipAndCityMutationOptions = Apollo.BaseMutationOptions<UpdateMarketAndServicedForZipAndCityMutation, UpdateMarketAndServicedForZipAndCityMutationVariables>;
export const UpdateMsrpScalarForMarketsDocument = gql`
    mutation UpdateMsrpScalarForMarkets($marketIds: [Int!]!, $newScalar: Decimal!) {
  updateMsrpScalarForMarkets(marketIds: $marketIds, newScalar: $newScalar)
}
    `;
export type UpdateMsrpScalarForMarketsMutationFn = Apollo.MutationFunction<UpdateMsrpScalarForMarketsMutation, UpdateMsrpScalarForMarketsMutationVariables>;

/**
 * __useUpdateMsrpScalarForMarketsMutation__
 *
 * To run a mutation, you first call `useUpdateMsrpScalarForMarketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMsrpScalarForMarketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMsrpScalarForMarketsMutation, { data, loading, error }] = useUpdateMsrpScalarForMarketsMutation({
 *   variables: {
 *      marketIds: // value for 'marketIds'
 *      newScalar: // value for 'newScalar'
 *   },
 * });
 */
export function useUpdateMsrpScalarForMarketsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMsrpScalarForMarketsMutation, UpdateMsrpScalarForMarketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMsrpScalarForMarketsMutation, UpdateMsrpScalarForMarketsMutationVariables>(UpdateMsrpScalarForMarketsDocument, options);
      }
export type UpdateMsrpScalarForMarketsMutationHookResult = ReturnType<typeof useUpdateMsrpScalarForMarketsMutation>;
export type UpdateMsrpScalarForMarketsMutationResult = Apollo.MutationResult<UpdateMsrpScalarForMarketsMutation>;
export type UpdateMsrpScalarForMarketsMutationOptions = Apollo.BaseMutationOptions<UpdateMsrpScalarForMarketsMutation, UpdateMsrpScalarForMarketsMutationVariables>;
export const UpdateRoomNotesDocument = gql`
    mutation UpdateRoomNotes($roomId: Int!, $newNotes: String!, $replace: Boolean!) {
  updateRoomNotes(roomId: $roomId, newNotes: $newNotes, replace: $replace)
}
    `;
export type UpdateRoomNotesMutationFn = Apollo.MutationFunction<UpdateRoomNotesMutation, UpdateRoomNotesMutationVariables>;

/**
 * __useUpdateRoomNotesMutation__
 *
 * To run a mutation, you first call `useUpdateRoomNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomNotesMutation, { data, loading, error }] = useUpdateRoomNotesMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      newNotes: // value for 'newNotes'
 *      replace: // value for 'replace'
 *   },
 * });
 */
export function useUpdateRoomNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomNotesMutation, UpdateRoomNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoomNotesMutation, UpdateRoomNotesMutationVariables>(UpdateRoomNotesDocument, options);
      }
export type UpdateRoomNotesMutationHookResult = ReturnType<typeof useUpdateRoomNotesMutation>;
export type UpdateRoomNotesMutationResult = Apollo.MutationResult<UpdateRoomNotesMutation>;
export type UpdateRoomNotesMutationOptions = Apollo.BaseMutationOptions<UpdateRoomNotesMutation, UpdateRoomNotesMutationVariables>;
export const UpdateSahAppointmentDocument = gql`
    mutation UpdateSAHAppointment($sahAppointmentId: Int!, $salespersonId: Int, $marketTimeSlotIds: [Int!], $date: DateTime) {
  updateSAHAppointment(sahAppointmentId: $sahAppointmentId, salespersonId: $salespersonId, marketTimeSlotIds: $marketTimeSlotIds, date: $date) {
    id
    appointmentNumber
    jobId
    numRooms
    date
    isForCustomerHouse
    isCancelled
    isInRecovery
    comments
    promoCodeId
    howDidYouHearAboutUsOptionId
    howDidYouHearAboutUsOption
    productTypes
    productTypeIds
    customerId
    firstName
    lastName
    primaryAddress
    primaryCity
    primaryZipCode
    primaryPhone
    appointmentStatusId
    appointmentStatusString
    contractCancelled
    contractTotalPrice
    quoteTotalPrice
    marketTimeSlot {
      id
      startTime
      endTime
      marketId
    }
    currentSalesperson {
      id
      sAHAppointmentId
      salespersonId
      releasedTime
      acceptedTime
      checkInTime
      checkOutTime
      appointmentStartTime
      isWriteUp
    }
    priorityOptions {
      id
      text
    }
    colorCategories {
      id
      category
    }
  }
}
    `;
export type UpdateSahAppointmentMutationFn = Apollo.MutationFunction<UpdateSahAppointmentMutation, UpdateSahAppointmentMutationVariables>;

/**
 * __useUpdateSahAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateSahAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSahAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSahAppointmentMutation, { data, loading, error }] = useUpdateSahAppointmentMutation({
 *   variables: {
 *      sahAppointmentId: // value for 'sahAppointmentId'
 *      salespersonId: // value for 'salespersonId'
 *      marketTimeSlotIds: // value for 'marketTimeSlotIds'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdateSahAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSahAppointmentMutation, UpdateSahAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSahAppointmentMutation, UpdateSahAppointmentMutationVariables>(UpdateSahAppointmentDocument, options);
      }
export type UpdateSahAppointmentMutationHookResult = ReturnType<typeof useUpdateSahAppointmentMutation>;
export type UpdateSahAppointmentMutationResult = Apollo.MutationResult<UpdateSahAppointmentMutation>;
export type UpdateSahAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateSahAppointmentMutation, UpdateSahAppointmentMutationVariables>;
export const UpdateSahAppointmentDetailsDocument = gql`
    mutation UpdateSAHAppointmentDetails($sahAppointmentId: Int!, $customerComments: String!) {
  updateSAHAppointmentDetails(sahAppointmentId: $sahAppointmentId, customerComments: $customerComments) {
    id
    appointmentNumber
    jobId
    numRooms
    date
    isForCustomerHouse
    isCancelled
    isInRecovery
    comments
    promoCodeId
    howDidYouHearAboutUsOptionId
    howDidYouHearAboutUsOption
    productTypes
    productTypeIds
    customerId
    firstName
    lastName
    primaryAddress
    primaryCity
    primaryZipCode
    primaryPhone
    appointmentStatusId
    appointmentStatusString
    contractCancelled
    contractTotalPrice
    quoteTotalPrice
    marketTimeSlot {
      id
      startTime
      endTime
      marketId
    }
    currentSalesperson {
      id
      sAHAppointmentId
      salespersonId
      releasedTime
      acceptedTime
      checkInTime
      checkOutTime
      appointmentStartTime
      isWriteUp
    }
    priorityOptions {
      id
      text
    }
    colorCategories {
      id
      category
    }
  }
}
    `;
export type UpdateSahAppointmentDetailsMutationFn = Apollo.MutationFunction<UpdateSahAppointmentDetailsMutation, UpdateSahAppointmentDetailsMutationVariables>;

/**
 * __useUpdateSahAppointmentDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateSahAppointmentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSahAppointmentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSahAppointmentDetailsMutation, { data, loading, error }] = useUpdateSahAppointmentDetailsMutation({
 *   variables: {
 *      sahAppointmentId: // value for 'sahAppointmentId'
 *      customerComments: // value for 'customerComments'
 *   },
 * });
 */
export function useUpdateSahAppointmentDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSahAppointmentDetailsMutation, UpdateSahAppointmentDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSahAppointmentDetailsMutation, UpdateSahAppointmentDetailsMutationVariables>(UpdateSahAppointmentDetailsDocument, options);
      }
export type UpdateSahAppointmentDetailsMutationHookResult = ReturnType<typeof useUpdateSahAppointmentDetailsMutation>;
export type UpdateSahAppointmentDetailsMutationResult = Apollo.MutationResult<UpdateSahAppointmentDetailsMutation>;
export type UpdateSahAppointmentDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateSahAppointmentDetailsMutation, UpdateSahAppointmentDetailsMutationVariables>;
export const UpdateSahAppointmentNotesDocument = gql`
    mutation UpdateSahAppointmentNotes($sahAppointmentId: Int!, $notes: String!) {
  updateSahAppointmentNotes(sahAppointmentId: $sahAppointmentId, notes: $notes)
}
    `;
export type UpdateSahAppointmentNotesMutationFn = Apollo.MutationFunction<UpdateSahAppointmentNotesMutation, UpdateSahAppointmentNotesMutationVariables>;

/**
 * __useUpdateSahAppointmentNotesMutation__
 *
 * To run a mutation, you first call `useUpdateSahAppointmentNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSahAppointmentNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSahAppointmentNotesMutation, { data, loading, error }] = useUpdateSahAppointmentNotesMutation({
 *   variables: {
 *      sahAppointmentId: // value for 'sahAppointmentId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateSahAppointmentNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSahAppointmentNotesMutation, UpdateSahAppointmentNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSahAppointmentNotesMutation, UpdateSahAppointmentNotesMutationVariables>(UpdateSahAppointmentNotesDocument, options);
      }
export type UpdateSahAppointmentNotesMutationHookResult = ReturnType<typeof useUpdateSahAppointmentNotesMutation>;
export type UpdateSahAppointmentNotesMutationResult = Apollo.MutationResult<UpdateSahAppointmentNotesMutation>;
export type UpdateSahAppointmentNotesMutationOptions = Apollo.BaseMutationOptions<UpdateSahAppointmentNotesMutation, UpdateSahAppointmentNotesMutationVariables>;
export const WaitingForCustomerSahAppointmentDocument = gql`
    mutation WaitingForCustomerSAHAppointment($sahAppointmentId: Int!) {
  waitingForCustomerSAHAppointment(sahAppointmentId: $sahAppointmentId) {
    id
    appointmentNumber
    jobId
    numRooms
    date
    isForCustomerHouse
    isCancelled
    isInRecovery
    comments
    promoCodeId
    howDidYouHearAboutUsOptionId
    howDidYouHearAboutUsOption
    productTypes
    productTypeIds
    customerId
    firstName
    lastName
    primaryAddress
    primaryCity
    primaryZipCode
    primaryPhone
    appointmentStatusId
    appointmentStatusString
    contractCancelled
    contractTotalPrice
    quoteTotalPrice
    marketTimeSlot {
      id
      startTime
      endTime
      marketId
    }
    currentSalesperson {
      id
      sAHAppointmentId
      salespersonId
      releasedTime
      acceptedTime
      checkInTime
      checkOutTime
      appointmentStartTime
      isWriteUp
    }
    priorityOptions {
      id
      text
    }
    colorCategories {
      id
      category
    }
  }
}
    `;
export type WaitingForCustomerSahAppointmentMutationFn = Apollo.MutationFunction<WaitingForCustomerSahAppointmentMutation, WaitingForCustomerSahAppointmentMutationVariables>;

/**
 * __useWaitingForCustomerSahAppointmentMutation__
 *
 * To run a mutation, you first call `useWaitingForCustomerSahAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWaitingForCustomerSahAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [waitingForCustomerSahAppointmentMutation, { data, loading, error }] = useWaitingForCustomerSahAppointmentMutation({
 *   variables: {
 *      sahAppointmentId: // value for 'sahAppointmentId'
 *   },
 * });
 */
export function useWaitingForCustomerSahAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<WaitingForCustomerSahAppointmentMutation, WaitingForCustomerSahAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WaitingForCustomerSahAppointmentMutation, WaitingForCustomerSahAppointmentMutationVariables>(WaitingForCustomerSahAppointmentDocument, options);
      }
export type WaitingForCustomerSahAppointmentMutationHookResult = ReturnType<typeof useWaitingForCustomerSahAppointmentMutation>;
export type WaitingForCustomerSahAppointmentMutationResult = Apollo.MutationResult<WaitingForCustomerSahAppointmentMutation>;
export type WaitingForCustomerSahAppointmentMutationOptions = Apollo.BaseMutationOptions<WaitingForCustomerSahAppointmentMutation, WaitingForCustomerSahAppointmentMutationVariables>;
export const GetAllServiceMaterialCategoryPricingsDocument = gql`
    query GetAllServiceMaterialCategoryPricings {
  allServiceMaterialCategoryPricings {
    id
    serviceMaterialCategoryId
    serviceMaterialCategory
    packageSize
    packageSizeIncrement
    productTypeId
    productType
    pricePerUnit
    costPerUnit
    priceUnit
  }
}
    `;

/**
 * __useGetAllServiceMaterialCategoryPricingsQuery__
 *
 * To run a query within a React component, call `useGetAllServiceMaterialCategoryPricingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllServiceMaterialCategoryPricingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllServiceMaterialCategoryPricingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllServiceMaterialCategoryPricingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllServiceMaterialCategoryPricingsQuery, GetAllServiceMaterialCategoryPricingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllServiceMaterialCategoryPricingsQuery, GetAllServiceMaterialCategoryPricingsQueryVariables>(GetAllServiceMaterialCategoryPricingsDocument, options);
      }
export function useGetAllServiceMaterialCategoryPricingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllServiceMaterialCategoryPricingsQuery, GetAllServiceMaterialCategoryPricingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllServiceMaterialCategoryPricingsQuery, GetAllServiceMaterialCategoryPricingsQueryVariables>(GetAllServiceMaterialCategoryPricingsDocument, options);
        }
export type GetAllServiceMaterialCategoryPricingsQueryHookResult = ReturnType<typeof useGetAllServiceMaterialCategoryPricingsQuery>;
export type GetAllServiceMaterialCategoryPricingsLazyQueryHookResult = ReturnType<typeof useGetAllServiceMaterialCategoryPricingsLazyQuery>;
export type GetAllServiceMaterialCategoryPricingsQueryResult = Apollo.QueryResult<GetAllServiceMaterialCategoryPricingsQuery, GetAllServiceMaterialCategoryPricingsQueryVariables>;
export const GetServiceMaterialCategoryHistoryDocument = gql`
    query GetServiceMaterialCategoryHistory($serviceMaterialCategoryProductPricingId: Int!) {
  serviceMaterialCategoryHistory(serviceMaterialCategoryProductPricingId: $serviceMaterialCategoryProductPricingId) {
    id
    changeTime
    oldPrice
    oldCost
  }
}
    `;

/**
 * __useGetServiceMaterialCategoryHistoryQuery__
 *
 * To run a query within a React component, call `useGetServiceMaterialCategoryHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceMaterialCategoryHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceMaterialCategoryHistoryQuery({
 *   variables: {
 *      serviceMaterialCategoryProductPricingId: // value for 'serviceMaterialCategoryProductPricingId'
 *   },
 * });
 */
export function useGetServiceMaterialCategoryHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetServiceMaterialCategoryHistoryQuery, GetServiceMaterialCategoryHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceMaterialCategoryHistoryQuery, GetServiceMaterialCategoryHistoryQueryVariables>(GetServiceMaterialCategoryHistoryDocument, options);
      }
export function useGetServiceMaterialCategoryHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceMaterialCategoryHistoryQuery, GetServiceMaterialCategoryHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceMaterialCategoryHistoryQuery, GetServiceMaterialCategoryHistoryQueryVariables>(GetServiceMaterialCategoryHistoryDocument, options);
        }
export type GetServiceMaterialCategoryHistoryQueryHookResult = ReturnType<typeof useGetServiceMaterialCategoryHistoryQuery>;
export type GetServiceMaterialCategoryHistoryLazyQueryHookResult = ReturnType<typeof useGetServiceMaterialCategoryHistoryLazyQuery>;
export type GetServiceMaterialCategoryHistoryQueryResult = Apollo.QueryResult<GetServiceMaterialCategoryHistoryQuery, GetServiceMaterialCategoryHistoryQueryVariables>;
export const AddServiceMaterialCategoryPricingsDocument = gql`
    mutation AddServiceMaterialCategoryPricings($materialCategoryName: String!, $pricingUnit: String!, $newPricings: [AddServiceMaterialCategoryPricingInput!]!, $packageSize: Decimal, $packageSizeIncrement: Decimal) {
  addServiceMaterialCategoryPricings(materialCategoryName: $materialCategoryName, pricingUnit: $pricingUnit, newPricings: $newPricings, packageSize: $packageSize, packageSizeIncrement: $packageSizeIncrement)
}
    `;
export type AddServiceMaterialCategoryPricingsMutationFn = Apollo.MutationFunction<AddServiceMaterialCategoryPricingsMutation, AddServiceMaterialCategoryPricingsMutationVariables>;

/**
 * __useAddServiceMaterialCategoryPricingsMutation__
 *
 * To run a mutation, you first call `useAddServiceMaterialCategoryPricingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddServiceMaterialCategoryPricingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addServiceMaterialCategoryPricingsMutation, { data, loading, error }] = useAddServiceMaterialCategoryPricingsMutation({
 *   variables: {
 *      materialCategoryName: // value for 'materialCategoryName'
 *      pricingUnit: // value for 'pricingUnit'
 *      newPricings: // value for 'newPricings'
 *      packageSize: // value for 'packageSize'
 *      packageSizeIncrement: // value for 'packageSizeIncrement'
 *   },
 * });
 */
export function useAddServiceMaterialCategoryPricingsMutation(baseOptions?: Apollo.MutationHookOptions<AddServiceMaterialCategoryPricingsMutation, AddServiceMaterialCategoryPricingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddServiceMaterialCategoryPricingsMutation, AddServiceMaterialCategoryPricingsMutationVariables>(AddServiceMaterialCategoryPricingsDocument, options);
      }
export type AddServiceMaterialCategoryPricingsMutationHookResult = ReturnType<typeof useAddServiceMaterialCategoryPricingsMutation>;
export type AddServiceMaterialCategoryPricingsMutationResult = Apollo.MutationResult<AddServiceMaterialCategoryPricingsMutation>;
export type AddServiceMaterialCategoryPricingsMutationOptions = Apollo.BaseMutationOptions<AddServiceMaterialCategoryPricingsMutation, AddServiceMaterialCategoryPricingsMutationVariables>;
export const UpdateServiceMaterialCategoryPricingsDocument = gql`
    mutation UpdateServiceMaterialCategoryPricings($serviceMaterialCategoryProductPricingIds: [Int!]!, $updatedCost: Decimal, $updatedPrice: Decimal) {
  updateServiceMaterialCategoryPricings(serviceMaterialCategoryProductPricingIds: $serviceMaterialCategoryProductPricingIds, updatedCost: $updatedCost, updatedPrice: $updatedPrice) {
    id
    serviceMaterialCategoryId
    serviceMaterialCategory
    packageSize
    packageSizeIncrement
    productTypeId
    productType
    pricePerUnit
    costPerUnit
    priceUnit
  }
}
    `;
export type UpdateServiceMaterialCategoryPricingsMutationFn = Apollo.MutationFunction<UpdateServiceMaterialCategoryPricingsMutation, UpdateServiceMaterialCategoryPricingsMutationVariables>;

/**
 * __useUpdateServiceMaterialCategoryPricingsMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMaterialCategoryPricingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMaterialCategoryPricingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMaterialCategoryPricingsMutation, { data, loading, error }] = useUpdateServiceMaterialCategoryPricingsMutation({
 *   variables: {
 *      serviceMaterialCategoryProductPricingIds: // value for 'serviceMaterialCategoryProductPricingIds'
 *      updatedCost: // value for 'updatedCost'
 *      updatedPrice: // value for 'updatedPrice'
 *   },
 * });
 */
export function useUpdateServiceMaterialCategoryPricingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceMaterialCategoryPricingsMutation, UpdateServiceMaterialCategoryPricingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceMaterialCategoryPricingsMutation, UpdateServiceMaterialCategoryPricingsMutationVariables>(UpdateServiceMaterialCategoryPricingsDocument, options);
      }
export type UpdateServiceMaterialCategoryPricingsMutationHookResult = ReturnType<typeof useUpdateServiceMaterialCategoryPricingsMutation>;
export type UpdateServiceMaterialCategoryPricingsMutationResult = Apollo.MutationResult<UpdateServiceMaterialCategoryPricingsMutation>;
export type UpdateServiceMaterialCategoryPricingsMutationOptions = Apollo.BaseMutationOptions<UpdateServiceMaterialCategoryPricingsMutation, UpdateServiceMaterialCategoryPricingsMutationVariables>;
export const SetRoleDocument = gql`
    mutation SetRole($userEmail: String!, $userRole: String!) {
  setRole(email: $userEmail, role: $userRole) {
    email
    role
  }
}
    `;
export type SetRoleMutationFn = Apollo.MutationFunction<SetRoleMutation, SetRoleMutationVariables>;

/**
 * __useSetRoleMutation__
 *
 * To run a mutation, you first call `useSetRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRoleMutation, { data, loading, error }] = useSetRoleMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *      userRole: // value for 'userRole'
 *   },
 * });
 */
export function useSetRoleMutation(baseOptions?: Apollo.MutationHookOptions<SetRoleMutation, SetRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRoleMutation, SetRoleMutationVariables>(SetRoleDocument, options);
      }
export type SetRoleMutationHookResult = ReturnType<typeof useSetRoleMutation>;
export type SetRoleMutationResult = Apollo.MutationResult<SetRoleMutation>;
export type SetRoleMutationOptions = Apollo.BaseMutationOptions<SetRoleMutation, SetRoleMutationVariables>;
export const TestDocument = gql`
    mutation Test {
  test
}
    `;
export type TestMutationFn = Apollo.MutationFunction<TestMutation, TestMutationVariables>;

/**
 * __useTestMutation__
 *
 * To run a mutation, you first call `useTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testMutation, { data, loading, error }] = useTestMutation({
 *   variables: {
 *   },
 * });
 */
export function useTestMutation(baseOptions?: Apollo.MutationHookOptions<TestMutation, TestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestMutation, TestMutationVariables>(TestDocument, options);
      }
export type TestMutationHookResult = ReturnType<typeof useTestMutation>;
export type TestMutationResult = Apollo.MutationResult<TestMutation>;
export type TestMutationOptions = Apollo.BaseMutationOptions<TestMutation, TestMutationVariables>;
export const GetVendorSourcedProductDocument = gql`
    query GetVendorSourcedProduct($sourceId: Int!) {
  vendorSourcedProduct(sourceId: $sourceId) {
    id
    vendorId
    productSpecificationId
    style
    color
    orderQuantity
    singleCostPerSqft
    bulkCostPerSqft
    oneTimeCostPerSqft
  }
}
    `;

/**
 * __useGetVendorSourcedProductQuery__
 *
 * To run a query within a React component, call `useGetVendorSourcedProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorSourcedProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorSourcedProductQuery({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *   },
 * });
 */
export function useGetVendorSourcedProductQuery(baseOptions: Apollo.QueryHookOptions<GetVendorSourcedProductQuery, GetVendorSourcedProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorSourcedProductQuery, GetVendorSourcedProductQueryVariables>(GetVendorSourcedProductDocument, options);
      }
export function useGetVendorSourcedProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorSourcedProductQuery, GetVendorSourcedProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorSourcedProductQuery, GetVendorSourcedProductQueryVariables>(GetVendorSourcedProductDocument, options);
        }
export type GetVendorSourcedProductQueryHookResult = ReturnType<typeof useGetVendorSourcedProductQuery>;
export type GetVendorSourcedProductLazyQueryHookResult = ReturnType<typeof useGetVendorSourcedProductLazyQuery>;
export type GetVendorSourcedProductQueryResult = Apollo.QueryResult<GetVendorSourcedProductQuery, GetVendorSourcedProductQueryVariables>;
export const GetVendorSourcedProductIdsForStyleDocument = gql`
    query GetVendorSourcedProductIdsForStyle($styleId: Int!, $vendorId: Int) {
  productSourceIdsForStyle(styleId: $styleId, vendorId: $vendorId) {
    id
    color
  }
}
    `;

/**
 * __useGetVendorSourcedProductIdsForStyleQuery__
 *
 * To run a query within a React component, call `useGetVendorSourcedProductIdsForStyleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorSourcedProductIdsForStyleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorSourcedProductIdsForStyleQuery({
 *   variables: {
 *      styleId: // value for 'styleId'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useGetVendorSourcedProductIdsForStyleQuery(baseOptions: Apollo.QueryHookOptions<GetVendorSourcedProductIdsForStyleQuery, GetVendorSourcedProductIdsForStyleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorSourcedProductIdsForStyleQuery, GetVendorSourcedProductIdsForStyleQueryVariables>(GetVendorSourcedProductIdsForStyleDocument, options);
      }
export function useGetVendorSourcedProductIdsForStyleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorSourcedProductIdsForStyleQuery, GetVendorSourcedProductIdsForStyleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorSourcedProductIdsForStyleQuery, GetVendorSourcedProductIdsForStyleQueryVariables>(GetVendorSourcedProductIdsForStyleDocument, options);
        }
export type GetVendorSourcedProductIdsForStyleQueryHookResult = ReturnType<typeof useGetVendorSourcedProductIdsForStyleQuery>;
export type GetVendorSourcedProductIdsForStyleLazyQueryHookResult = ReturnType<typeof useGetVendorSourcedProductIdsForStyleLazyQuery>;
export type GetVendorSourcedProductIdsForStyleQueryResult = Apollo.QueryResult<GetVendorSourcedProductIdsForStyleQuery, GetVendorSourcedProductIdsForStyleQueryVariables>;
export const GetVendorsDocument = gql`
    query GetVendors($hasProductTypeId: Int) {
  vendors(hasProductTypeId: $hasProductTypeId) {
    id
    name
  }
}
    `;

/**
 * __useGetVendorsQuery__
 *
 * To run a query within a React component, call `useGetVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorsQuery({
 *   variables: {
 *      hasProductTypeId: // value for 'hasProductTypeId'
 *   },
 * });
 */
export function useGetVendorsQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorsQuery, GetVendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorsQuery, GetVendorsQueryVariables>(GetVendorsDocument, options);
      }
export function useGetVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorsQuery, GetVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorsQuery, GetVendorsQueryVariables>(GetVendorsDocument, options);
        }
export type GetVendorsQueryHookResult = ReturnType<typeof useGetVendorsQuery>;
export type GetVendorsLazyQueryHookResult = ReturnType<typeof useGetVendorsLazyQuery>;
export type GetVendorsQueryResult = Apollo.QueryResult<GetVendorsQuery, GetVendorsQueryVariables>;
export const GetAllContractorInstallationCapabilitiesAndDaysOffDocument = gql`
    query GetAllContractorInstallationCapabilitiesAndDaysOff {
  allContractorInstallationCapabilitiesAndDaysOff {
    id
    contractor {
      ...Contractor
    }
    installationCapabilities {
      ...ContractorInstallationCapability
    }
    daysOff
  }
}
    ${ContractorFragmentDoc}
${ContractorInstallationCapabilityFragmentDoc}`;

/**
 * __useGetAllContractorInstallationCapabilitiesAndDaysOffQuery__
 *
 * To run a query within a React component, call `useGetAllContractorInstallationCapabilitiesAndDaysOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContractorInstallationCapabilitiesAndDaysOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllContractorInstallationCapabilitiesAndDaysOffQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllContractorInstallationCapabilitiesAndDaysOffQuery(baseOptions?: Apollo.QueryHookOptions<GetAllContractorInstallationCapabilitiesAndDaysOffQuery, GetAllContractorInstallationCapabilitiesAndDaysOffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllContractorInstallationCapabilitiesAndDaysOffQuery, GetAllContractorInstallationCapabilitiesAndDaysOffQueryVariables>(GetAllContractorInstallationCapabilitiesAndDaysOffDocument, options);
      }
export function useGetAllContractorInstallationCapabilitiesAndDaysOffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllContractorInstallationCapabilitiesAndDaysOffQuery, GetAllContractorInstallationCapabilitiesAndDaysOffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllContractorInstallationCapabilitiesAndDaysOffQuery, GetAllContractorInstallationCapabilitiesAndDaysOffQueryVariables>(GetAllContractorInstallationCapabilitiesAndDaysOffDocument, options);
        }
export type GetAllContractorInstallationCapabilitiesAndDaysOffQueryHookResult = ReturnType<typeof useGetAllContractorInstallationCapabilitiesAndDaysOffQuery>;
export type GetAllContractorInstallationCapabilitiesAndDaysOffLazyQueryHookResult = ReturnType<typeof useGetAllContractorInstallationCapabilitiesAndDaysOffLazyQuery>;
export type GetAllContractorInstallationCapabilitiesAndDaysOffQueryResult = Apollo.QueryResult<GetAllContractorInstallationCapabilitiesAndDaysOffQuery, GetAllContractorInstallationCapabilitiesAndDaysOffQueryVariables>;
export const GetAllInstallationCertificationsDocument = gql`
    query GetAllInstallationCertifications {
  allInstallationCertifications {
    id
    name
    productTypeId
  }
}
    `;

/**
 * __useGetAllInstallationCertificationsQuery__
 *
 * To run a query within a React component, call `useGetAllInstallationCertificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInstallationCertificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInstallationCertificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllInstallationCertificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInstallationCertificationsQuery, GetAllInstallationCertificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInstallationCertificationsQuery, GetAllInstallationCertificationsQueryVariables>(GetAllInstallationCertificationsDocument, options);
      }
export function useGetAllInstallationCertificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInstallationCertificationsQuery, GetAllInstallationCertificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInstallationCertificationsQuery, GetAllInstallationCertificationsQueryVariables>(GetAllInstallationCertificationsDocument, options);
        }
export type GetAllInstallationCertificationsQueryHookResult = ReturnType<typeof useGetAllInstallationCertificationsQuery>;
export type GetAllInstallationCertificationsLazyQueryHookResult = ReturnType<typeof useGetAllInstallationCertificationsLazyQuery>;
export type GetAllInstallationCertificationsQueryResult = Apollo.QueryResult<GetAllInstallationCertificationsQuery, GetAllInstallationCertificationsQueryVariables>;
export const GetAuthenticatedCommandCenterWorkerIdDocument = gql`
    query GetAuthenticatedCommandCenterWorkerId {
  authenticatedCommandCenterWorkerId
}
    `;

/**
 * __useGetAuthenticatedCommandCenterWorkerIdQuery__
 *
 * To run a query within a React component, call `useGetAuthenticatedCommandCenterWorkerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticatedCommandCenterWorkerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthenticatedCommandCenterWorkerIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthenticatedCommandCenterWorkerIdQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthenticatedCommandCenterWorkerIdQuery, GetAuthenticatedCommandCenterWorkerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthenticatedCommandCenterWorkerIdQuery, GetAuthenticatedCommandCenterWorkerIdQueryVariables>(GetAuthenticatedCommandCenterWorkerIdDocument, options);
      }
export function useGetAuthenticatedCommandCenterWorkerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthenticatedCommandCenterWorkerIdQuery, GetAuthenticatedCommandCenterWorkerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthenticatedCommandCenterWorkerIdQuery, GetAuthenticatedCommandCenterWorkerIdQueryVariables>(GetAuthenticatedCommandCenterWorkerIdDocument, options);
        }
export type GetAuthenticatedCommandCenterWorkerIdQueryHookResult = ReturnType<typeof useGetAuthenticatedCommandCenterWorkerIdQuery>;
export type GetAuthenticatedCommandCenterWorkerIdLazyQueryHookResult = ReturnType<typeof useGetAuthenticatedCommandCenterWorkerIdLazyQuery>;
export type GetAuthenticatedCommandCenterWorkerIdQueryResult = Apollo.QueryResult<GetAuthenticatedCommandCenterWorkerIdQuery, GetAuthenticatedCommandCenterWorkerIdQueryVariables>;
export const GetAuthenticatedWorkerDocument = gql`
    query GetAuthenticatedWorker {
  authenticatedWorker {
    ...Worker
  }
}
    ${WorkerFragmentDoc}`;

/**
 * __useGetAuthenticatedWorkerQuery__
 *
 * To run a query within a React component, call `useGetAuthenticatedWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticatedWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthenticatedWorkerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthenticatedWorkerQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthenticatedWorkerQuery, GetAuthenticatedWorkerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthenticatedWorkerQuery, GetAuthenticatedWorkerQueryVariables>(GetAuthenticatedWorkerDocument, options);
      }
export function useGetAuthenticatedWorkerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthenticatedWorkerQuery, GetAuthenticatedWorkerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthenticatedWorkerQuery, GetAuthenticatedWorkerQueryVariables>(GetAuthenticatedWorkerDocument, options);
        }
export type GetAuthenticatedWorkerQueryHookResult = ReturnType<typeof useGetAuthenticatedWorkerQuery>;
export type GetAuthenticatedWorkerLazyQueryHookResult = ReturnType<typeof useGetAuthenticatedWorkerLazyQuery>;
export type GetAuthenticatedWorkerQueryResult = Apollo.QueryResult<GetAuthenticatedWorkerQuery, GetAuthenticatedWorkerQueryVariables>;
export const GetAuthenticatedWorkerPermissionsDocument = gql`
    query GetAuthenticatedWorkerPermissions {
  authenticatedWorkerPermissions {
    id
    callCenterAdmin
    callCenterWorker
    projectManager
    projectCoordinator
    viewPricingCenter
    editPricingCenter
    setFinalFinalPrice
  }
}
    `;

/**
 * __useGetAuthenticatedWorkerPermissionsQuery__
 *
 * To run a query within a React component, call `useGetAuthenticatedWorkerPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticatedWorkerPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthenticatedWorkerPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthenticatedWorkerPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthenticatedWorkerPermissionsQuery, GetAuthenticatedWorkerPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthenticatedWorkerPermissionsQuery, GetAuthenticatedWorkerPermissionsQueryVariables>(GetAuthenticatedWorkerPermissionsDocument, options);
      }
export function useGetAuthenticatedWorkerPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthenticatedWorkerPermissionsQuery, GetAuthenticatedWorkerPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthenticatedWorkerPermissionsQuery, GetAuthenticatedWorkerPermissionsQueryVariables>(GetAuthenticatedWorkerPermissionsDocument, options);
        }
export type GetAuthenticatedWorkerPermissionsQueryHookResult = ReturnType<typeof useGetAuthenticatedWorkerPermissionsQuery>;
export type GetAuthenticatedWorkerPermissionsLazyQueryHookResult = ReturnType<typeof useGetAuthenticatedWorkerPermissionsLazyQuery>;
export type GetAuthenticatedWorkerPermissionsQueryResult = Apollo.QueryResult<GetAuthenticatedWorkerPermissionsQuery, GetAuthenticatedWorkerPermissionsQueryVariables>;
export const GetContractorDocument = gql`
    query GetContractor($workerId: Int!) {
  contractor(workerId: $workerId) {
    ...Contractor
  }
}
    ${ContractorFragmentDoc}`;

/**
 * __useGetContractorQuery__
 *
 * To run a query within a React component, call `useGetContractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useGetContractorQuery(baseOptions: Apollo.QueryHookOptions<GetContractorQuery, GetContractorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractorQuery, GetContractorQueryVariables>(GetContractorDocument, options);
      }
export function useGetContractorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractorQuery, GetContractorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractorQuery, GetContractorQueryVariables>(GetContractorDocument, options);
        }
export type GetContractorQueryHookResult = ReturnType<typeof useGetContractorQuery>;
export type GetContractorLazyQueryHookResult = ReturnType<typeof useGetContractorLazyQuery>;
export type GetContractorQueryResult = Apollo.QueryResult<GetContractorQuery, GetContractorQueryVariables>;
export const GetContractorListDocument = gql`
    query GetContractorList {
  allContractors {
    id
    workerId
    firstName
    lastName
  }
}
    `;

/**
 * __useGetContractorListQuery__
 *
 * To run a query within a React component, call `useGetContractorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContractorListQuery(baseOptions?: Apollo.QueryHookOptions<GetContractorListQuery, GetContractorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractorListQuery, GetContractorListQueryVariables>(GetContractorListDocument, options);
      }
export function useGetContractorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractorListQuery, GetContractorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractorListQuery, GetContractorListQueryVariables>(GetContractorListDocument, options);
        }
export type GetContractorListQueryHookResult = ReturnType<typeof useGetContractorListQuery>;
export type GetContractorListLazyQueryHookResult = ReturnType<typeof useGetContractorListLazyQuery>;
export type GetContractorListQueryResult = Apollo.QueryResult<GetContractorListQuery, GetContractorListQueryVariables>;
export const GetContractorProfileDocument = gql`
    query GetContractorProfile($workerId: Int!) {
  contractorProfile(workerId: $workerId) {
    id
    workerId
    isActive
    firstName
    lastName
    email
    phone
    servicedZips
    address {
      ...Address
    }
    driversLicenseNumber
    driversLicenseFile {
      ...S3File
    }
    licenseNumber
    licenseFile {
      ...S3File
    }
    vehicle {
      ...Vehicle
    }
    parentCompany
    company {
      ...Company
    }
    workerHistory {
      ...WorkerHistory
    }
    helpers {
      id
      firstName
      lastName
      contractorId
      phone
      licenseNumber
      history {
        id
        startDate
        endDate
      }
      isActive
      backgroundChecks {
        ...BackgroundCheck
      }
    }
    backgroundChecks {
      ...BackgroundCheck
    }
    capabilities {
      ...ContractorInstallationCapability
    }
    profilePictureFileId
    w9s {
      ...W9
    }
    workersComps {
      ...WorkersComp
    }
    liabilityInsurances {
      ...LiabilityInsurance
    }
    rating
  }
}
    ${AddressFragmentDoc}
${S3FileFragmentDoc}
${VehicleFragmentDoc}
${CompanyFragmentDoc}
${WorkerHistoryFragmentDoc}
${BackgroundCheckFragmentDoc}
${ContractorInstallationCapabilityFragmentDoc}
${W9FragmentDoc}
${WorkersCompFragmentDoc}
${LiabilityInsuranceFragmentDoc}`;

/**
 * __useGetContractorProfileQuery__
 *
 * To run a query within a React component, call `useGetContractorProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorProfileQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useGetContractorProfileQuery(baseOptions: Apollo.QueryHookOptions<GetContractorProfileQuery, GetContractorProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractorProfileQuery, GetContractorProfileQueryVariables>(GetContractorProfileDocument, options);
      }
export function useGetContractorProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractorProfileQuery, GetContractorProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractorProfileQuery, GetContractorProfileQueryVariables>(GetContractorProfileDocument, options);
        }
export type GetContractorProfileQueryHookResult = ReturnType<typeof useGetContractorProfileQuery>;
export type GetContractorProfileLazyQueryHookResult = ReturnType<typeof useGetContractorProfileLazyQuery>;
export type GetContractorProfileQueryResult = Apollo.QueryResult<GetContractorProfileQuery, GetContractorProfileQueryVariables>;
export const GetGeneralCommandCenterWorkerIdDocument = gql`
    query GetGeneralCommandCenterWorkerId {
  generalCommandCenterWorkerId
}
    `;

/**
 * __useGetGeneralCommandCenterWorkerIdQuery__
 *
 * To run a query within a React component, call `useGetGeneralCommandCenterWorkerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralCommandCenterWorkerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralCommandCenterWorkerIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralCommandCenterWorkerIdQuery(baseOptions?: Apollo.QueryHookOptions<GetGeneralCommandCenterWorkerIdQuery, GetGeneralCommandCenterWorkerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGeneralCommandCenterWorkerIdQuery, GetGeneralCommandCenterWorkerIdQueryVariables>(GetGeneralCommandCenterWorkerIdDocument, options);
      }
export function useGetGeneralCommandCenterWorkerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneralCommandCenterWorkerIdQuery, GetGeneralCommandCenterWorkerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGeneralCommandCenterWorkerIdQuery, GetGeneralCommandCenterWorkerIdQueryVariables>(GetGeneralCommandCenterWorkerIdDocument, options);
        }
export type GetGeneralCommandCenterWorkerIdQueryHookResult = ReturnType<typeof useGetGeneralCommandCenterWorkerIdQuery>;
export type GetGeneralCommandCenterWorkerIdLazyQueryHookResult = ReturnType<typeof useGetGeneralCommandCenterWorkerIdLazyQuery>;
export type GetGeneralCommandCenterWorkerIdQueryResult = Apollo.QueryResult<GetGeneralCommandCenterWorkerIdQuery, GetGeneralCommandCenterWorkerIdQueryVariables>;
export const GetGeneratedSalespersonPerformanceSummaryDocument = gql`
    query GetGeneratedSalespersonPerformanceSummary($salespersonId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  generatedSalespersonPerformanceSummary(salespersonId: $salespersonId, startDate: $startDate, endDate: $endDate) {
    ...SalespersonPerformanceSummary
  }
}
    ${SalespersonPerformanceSummaryFragmentDoc}`;

/**
 * __useGetGeneratedSalespersonPerformanceSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneratedSalespersonPerformanceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedSalespersonPerformanceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedSalespersonPerformanceSummaryQuery({
 *   variables: {
 *      salespersonId: // value for 'salespersonId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetGeneratedSalespersonPerformanceSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetGeneratedSalespersonPerformanceSummaryQuery, GetGeneratedSalespersonPerformanceSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGeneratedSalespersonPerformanceSummaryQuery, GetGeneratedSalespersonPerformanceSummaryQueryVariables>(GetGeneratedSalespersonPerformanceSummaryDocument, options);
      }
export function useGetGeneratedSalespersonPerformanceSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneratedSalespersonPerformanceSummaryQuery, GetGeneratedSalespersonPerformanceSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGeneratedSalespersonPerformanceSummaryQuery, GetGeneratedSalespersonPerformanceSummaryQueryVariables>(GetGeneratedSalespersonPerformanceSummaryDocument, options);
        }
export type GetGeneratedSalespersonPerformanceSummaryQueryHookResult = ReturnType<typeof useGetGeneratedSalespersonPerformanceSummaryQuery>;
export type GetGeneratedSalespersonPerformanceSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneratedSalespersonPerformanceSummaryLazyQuery>;
export type GetGeneratedSalespersonPerformanceSummaryQueryResult = Apollo.QueryResult<GetGeneratedSalespersonPerformanceSummaryQuery, GetGeneratedSalespersonPerformanceSummaryQueryVariables>;
export const GetListOfStoredSalespersonPerformanceSummariesDocument = gql`
    query GetListOfStoredSalespersonPerformanceSummaries($salespersonId: Int!) {
  listOfStoredSalespersonPerformanceSummaries(salespersonId: $salespersonId) {
    id
    startDate
    endDate
  }
}
    `;

/**
 * __useGetListOfStoredSalespersonPerformanceSummariesQuery__
 *
 * To run a query within a React component, call `useGetListOfStoredSalespersonPerformanceSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfStoredSalespersonPerformanceSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfStoredSalespersonPerformanceSummariesQuery({
 *   variables: {
 *      salespersonId: // value for 'salespersonId'
 *   },
 * });
 */
export function useGetListOfStoredSalespersonPerformanceSummariesQuery(baseOptions: Apollo.QueryHookOptions<GetListOfStoredSalespersonPerformanceSummariesQuery, GetListOfStoredSalespersonPerformanceSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfStoredSalespersonPerformanceSummariesQuery, GetListOfStoredSalespersonPerformanceSummariesQueryVariables>(GetListOfStoredSalespersonPerformanceSummariesDocument, options);
      }
export function useGetListOfStoredSalespersonPerformanceSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfStoredSalespersonPerformanceSummariesQuery, GetListOfStoredSalespersonPerformanceSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfStoredSalespersonPerformanceSummariesQuery, GetListOfStoredSalespersonPerformanceSummariesQueryVariables>(GetListOfStoredSalespersonPerformanceSummariesDocument, options);
        }
export type GetListOfStoredSalespersonPerformanceSummariesQueryHookResult = ReturnType<typeof useGetListOfStoredSalespersonPerformanceSummariesQuery>;
export type GetListOfStoredSalespersonPerformanceSummariesLazyQueryHookResult = ReturnType<typeof useGetListOfStoredSalespersonPerformanceSummariesLazyQuery>;
export type GetListOfStoredSalespersonPerformanceSummariesQueryResult = Apollo.QueryResult<GetListOfStoredSalespersonPerformanceSummariesQuery, GetListOfStoredSalespersonPerformanceSummariesQueryVariables>;
export const GetNewestContractorRatingSummaryDocument = gql`
    query GetNewestContractorRatingSummary($contractorId: Int!) {
  newestContractorRatingSummary(contractorId: $contractorId) {
    id
    contractorId
    ratingParametersId
    calledInAm
    checkedIn
    onTime
    collectedCod
    completedJob
    completionCertificateSigned
    completionCertificateRating
    serviceRequests
    numAppointments
    servicesCompletedOnTime
    numServices
    rating
    date
  }
}
    `;

/**
 * __useGetNewestContractorRatingSummaryQuery__
 *
 * To run a query within a React component, call `useGetNewestContractorRatingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewestContractorRatingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewestContractorRatingSummaryQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *   },
 * });
 */
export function useGetNewestContractorRatingSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetNewestContractorRatingSummaryQuery, GetNewestContractorRatingSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewestContractorRatingSummaryQuery, GetNewestContractorRatingSummaryQueryVariables>(GetNewestContractorRatingSummaryDocument, options);
      }
export function useGetNewestContractorRatingSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewestContractorRatingSummaryQuery, GetNewestContractorRatingSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewestContractorRatingSummaryQuery, GetNewestContractorRatingSummaryQueryVariables>(GetNewestContractorRatingSummaryDocument, options);
        }
export type GetNewestContractorRatingSummaryQueryHookResult = ReturnType<typeof useGetNewestContractorRatingSummaryQuery>;
export type GetNewestContractorRatingSummaryLazyQueryHookResult = ReturnType<typeof useGetNewestContractorRatingSummaryLazyQuery>;
export type GetNewestContractorRatingSummaryQueryResult = Apollo.QueryResult<GetNewestContractorRatingSummaryQuery, GetNewestContractorRatingSummaryQueryVariables>;
export const GetSalespersonDocument = gql`
    query GetSalesperson($workerId: Int!) {
  salesperson(workerId: $workerId) {
    id
    workerId
    firstName
    lastName
    email
    isActive
    isCheckedIn
    serviceZips
  }
}
    `;

/**
 * __useGetSalespersonQuery__
 *
 * To run a query within a React component, call `useGetSalespersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalespersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalespersonQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useGetSalespersonQuery(baseOptions: Apollo.QueryHookOptions<GetSalespersonQuery, GetSalespersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalespersonQuery, GetSalespersonQueryVariables>(GetSalespersonDocument, options);
      }
export function useGetSalespersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalespersonQuery, GetSalespersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalespersonQuery, GetSalespersonQueryVariables>(GetSalespersonDocument, options);
        }
export type GetSalespersonQueryHookResult = ReturnType<typeof useGetSalespersonQuery>;
export type GetSalespersonLazyQueryHookResult = ReturnType<typeof useGetSalespersonLazyQuery>;
export type GetSalespersonQueryResult = Apollo.QueryResult<GetSalespersonQuery, GetSalespersonQueryVariables>;
export const GetSalespersonListDocument = gql`
    query GetSalespersonList {
  allSalespeople {
    id
    workerId
    firstName
    lastName
  }
}
    `;

/**
 * __useGetSalespersonListQuery__
 *
 * To run a query within a React component, call `useGetSalespersonListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalespersonListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalespersonListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSalespersonListQuery(baseOptions?: Apollo.QueryHookOptions<GetSalespersonListQuery, GetSalespersonListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalespersonListQuery, GetSalespersonListQueryVariables>(GetSalespersonListDocument, options);
      }
export function useGetSalespersonListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalespersonListQuery, GetSalespersonListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalespersonListQuery, GetSalespersonListQueryVariables>(GetSalespersonListDocument, options);
        }
export type GetSalespersonListQueryHookResult = ReturnType<typeof useGetSalespersonListQuery>;
export type GetSalespersonListLazyQueryHookResult = ReturnType<typeof useGetSalespersonListLazyQuery>;
export type GetSalespersonListQueryResult = Apollo.QueryResult<GetSalespersonListQuery, GetSalespersonListQueryVariables>;
export const GetSalespersonProfileDocument = gql`
    query GetSalespersonProfile($workerId: Int!) {
  salespersonProfile(workerId: $workerId) {
    id
    workerId
    isActive
    firstName
    lastName
    email
    phone
    servicedZips
    profilePictureFileId
    address {
      ...Address
    }
    driversLicenseNumber
    driversLicenseFile {
      ...S3File
    }
    vehicle {
      ...Vehicle
    }
    workerHistory {
      ...WorkerHistory
    }
    backgroundChecks {
      ...BackgroundCheck
    }
    w9s {
      ...W9
    }
    liabilityInsurances {
      ...LiabilityInsurance
    }
    workersComps {
      ...WorkersComp
    }
    parentCompany
    company {
      ...Company
    }
  }
}
    ${AddressFragmentDoc}
${S3FileFragmentDoc}
${VehicleFragmentDoc}
${WorkerHistoryFragmentDoc}
${BackgroundCheckFragmentDoc}
${W9FragmentDoc}
${LiabilityInsuranceFragmentDoc}
${WorkersCompFragmentDoc}
${CompanyFragmentDoc}`;

/**
 * __useGetSalespersonProfileQuery__
 *
 * To run a query within a React component, call `useGetSalespersonProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalespersonProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalespersonProfileQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useGetSalespersonProfileQuery(baseOptions: Apollo.QueryHookOptions<GetSalespersonProfileQuery, GetSalespersonProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalespersonProfileQuery, GetSalespersonProfileQueryVariables>(GetSalespersonProfileDocument, options);
      }
export function useGetSalespersonProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalespersonProfileQuery, GetSalespersonProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalespersonProfileQuery, GetSalespersonProfileQueryVariables>(GetSalespersonProfileDocument, options);
        }
export type GetSalespersonProfileQueryHookResult = ReturnType<typeof useGetSalespersonProfileQuery>;
export type GetSalespersonProfileLazyQueryHookResult = ReturnType<typeof useGetSalespersonProfileLazyQuery>;
export type GetSalespersonProfileQueryResult = Apollo.QueryResult<GetSalespersonProfileQuery, GetSalespersonProfileQueryVariables>;
export const GetStoredSalespersonPerformanceSummaryDocument = gql`
    query GetStoredSalespersonPerformanceSummary($id: Int!) {
  storedSalespersonPerformanceSummary(id: $id) {
    ...SalespersonPerformanceSummary
  }
}
    ${SalespersonPerformanceSummaryFragmentDoc}`;

/**
 * __useGetStoredSalespersonPerformanceSummaryQuery__
 *
 * To run a query within a React component, call `useGetStoredSalespersonPerformanceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoredSalespersonPerformanceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoredSalespersonPerformanceSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStoredSalespersonPerformanceSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetStoredSalespersonPerformanceSummaryQuery, GetStoredSalespersonPerformanceSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoredSalespersonPerformanceSummaryQuery, GetStoredSalespersonPerformanceSummaryQueryVariables>(GetStoredSalespersonPerformanceSummaryDocument, options);
      }
export function useGetStoredSalespersonPerformanceSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoredSalespersonPerformanceSummaryQuery, GetStoredSalespersonPerformanceSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoredSalespersonPerformanceSummaryQuery, GetStoredSalespersonPerformanceSummaryQueryVariables>(GetStoredSalespersonPerformanceSummaryDocument, options);
        }
export type GetStoredSalespersonPerformanceSummaryQueryHookResult = ReturnType<typeof useGetStoredSalespersonPerformanceSummaryQuery>;
export type GetStoredSalespersonPerformanceSummaryLazyQueryHookResult = ReturnType<typeof useGetStoredSalespersonPerformanceSummaryLazyQuery>;
export type GetStoredSalespersonPerformanceSummaryQueryResult = Apollo.QueryResult<GetStoredSalespersonPerformanceSummaryQuery, GetStoredSalespersonPerformanceSummaryQueryVariables>;
export const GetWorkerBlockedTimeSlotsAfterDateDocument = gql`
    query GetWorkerBlockedTimeSlotsAfterDate($workerId: Int!, $afterDate: DateTime!) {
  workerBlockedTimeSlotsAfterDate(workerId: $workerId, afterDate: $afterDate) {
    id
    workerId
    date
    startTime
    endTime
  }
}
    `;

/**
 * __useGetWorkerBlockedTimeSlotsAfterDateQuery__
 *
 * To run a query within a React component, call `useGetWorkerBlockedTimeSlotsAfterDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerBlockedTimeSlotsAfterDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerBlockedTimeSlotsAfterDateQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      afterDate: // value for 'afterDate'
 *   },
 * });
 */
export function useGetWorkerBlockedTimeSlotsAfterDateQuery(baseOptions: Apollo.QueryHookOptions<GetWorkerBlockedTimeSlotsAfterDateQuery, GetWorkerBlockedTimeSlotsAfterDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkerBlockedTimeSlotsAfterDateQuery, GetWorkerBlockedTimeSlotsAfterDateQueryVariables>(GetWorkerBlockedTimeSlotsAfterDateDocument, options);
      }
export function useGetWorkerBlockedTimeSlotsAfterDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkerBlockedTimeSlotsAfterDateQuery, GetWorkerBlockedTimeSlotsAfterDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkerBlockedTimeSlotsAfterDateQuery, GetWorkerBlockedTimeSlotsAfterDateQueryVariables>(GetWorkerBlockedTimeSlotsAfterDateDocument, options);
        }
export type GetWorkerBlockedTimeSlotsAfterDateQueryHookResult = ReturnType<typeof useGetWorkerBlockedTimeSlotsAfterDateQuery>;
export type GetWorkerBlockedTimeSlotsAfterDateLazyQueryHookResult = ReturnType<typeof useGetWorkerBlockedTimeSlotsAfterDateLazyQuery>;
export type GetWorkerBlockedTimeSlotsAfterDateQueryResult = Apollo.QueryResult<GetWorkerBlockedTimeSlotsAfterDateQuery, GetWorkerBlockedTimeSlotsAfterDateQueryVariables>;
export const GetWorkerPermissionsDocument = gql`
    query GetWorkerPermissions($workerId: Int!) {
  workerPermissions(workerId: $workerId) {
    id
    callCenterAdmin
    callCenterWorker
  }
}
    `;

/**
 * __useGetWorkerPermissionsQuery__
 *
 * To run a query within a React component, call `useGetWorkerPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerPermissionsQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useGetWorkerPermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkerPermissionsQuery, GetWorkerPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkerPermissionsQuery, GetWorkerPermissionsQueryVariables>(GetWorkerPermissionsDocument, options);
      }
export function useGetWorkerPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkerPermissionsQuery, GetWorkerPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkerPermissionsQuery, GetWorkerPermissionsQueryVariables>(GetWorkerPermissionsDocument, options);
        }
export type GetWorkerPermissionsQueryHookResult = ReturnType<typeof useGetWorkerPermissionsQuery>;
export type GetWorkerPermissionsLazyQueryHookResult = ReturnType<typeof useGetWorkerPermissionsLazyQuery>;
export type GetWorkerPermissionsQueryResult = Apollo.QueryResult<GetWorkerPermissionsQuery, GetWorkerPermissionsQueryVariables>;
export const AddWorkerBlockedTimeSlotDocument = gql`
    mutation AddWorkerBlockedTimeSlot($workerId: Int!, $date: DateTime!, $timeSlot: AtomicTimeSlotInput!, $isBlockedByWorker: Boolean) {
  addWorkerBlockedTimeSlot(workerId: $workerId, date: $date, timeSlot: $timeSlot, isBlockedByWorker: $isBlockedByWorker)
}
    `;
export type AddWorkerBlockedTimeSlotMutationFn = Apollo.MutationFunction<AddWorkerBlockedTimeSlotMutation, AddWorkerBlockedTimeSlotMutationVariables>;

/**
 * __useAddWorkerBlockedTimeSlotMutation__
 *
 * To run a mutation, you first call `useAddWorkerBlockedTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkerBlockedTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkerBlockedTimeSlotMutation, { data, loading, error }] = useAddWorkerBlockedTimeSlotMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      date: // value for 'date'
 *      timeSlot: // value for 'timeSlot'
 *      isBlockedByWorker: // value for 'isBlockedByWorker'
 *   },
 * });
 */
export function useAddWorkerBlockedTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<AddWorkerBlockedTimeSlotMutation, AddWorkerBlockedTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWorkerBlockedTimeSlotMutation, AddWorkerBlockedTimeSlotMutationVariables>(AddWorkerBlockedTimeSlotDocument, options);
      }
export type AddWorkerBlockedTimeSlotMutationHookResult = ReturnType<typeof useAddWorkerBlockedTimeSlotMutation>;
export type AddWorkerBlockedTimeSlotMutationResult = Apollo.MutationResult<AddWorkerBlockedTimeSlotMutation>;
export type AddWorkerBlockedTimeSlotMutationOptions = Apollo.BaseMutationOptions<AddWorkerBlockedTimeSlotMutation, AddWorkerBlockedTimeSlotMutationVariables>;
export const AddWorkerBlockedTimeSlotRangeDocument = gql`
    mutation AddWorkerBlockedTimeSlotRange($workerId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  addWorkerBlockedTimeSlotRange(workerId: $workerId, startDate: $startDate, endDate: $endDate)
}
    `;
export type AddWorkerBlockedTimeSlotRangeMutationFn = Apollo.MutationFunction<AddWorkerBlockedTimeSlotRangeMutation, AddWorkerBlockedTimeSlotRangeMutationVariables>;

/**
 * __useAddWorkerBlockedTimeSlotRangeMutation__
 *
 * To run a mutation, you first call `useAddWorkerBlockedTimeSlotRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkerBlockedTimeSlotRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkerBlockedTimeSlotRangeMutation, { data, loading, error }] = useAddWorkerBlockedTimeSlotRangeMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAddWorkerBlockedTimeSlotRangeMutation(baseOptions?: Apollo.MutationHookOptions<AddWorkerBlockedTimeSlotRangeMutation, AddWorkerBlockedTimeSlotRangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWorkerBlockedTimeSlotRangeMutation, AddWorkerBlockedTimeSlotRangeMutationVariables>(AddWorkerBlockedTimeSlotRangeDocument, options);
      }
export type AddWorkerBlockedTimeSlotRangeMutationHookResult = ReturnType<typeof useAddWorkerBlockedTimeSlotRangeMutation>;
export type AddWorkerBlockedTimeSlotRangeMutationResult = Apollo.MutationResult<AddWorkerBlockedTimeSlotRangeMutation>;
export type AddWorkerBlockedTimeSlotRangeMutationOptions = Apollo.BaseMutationOptions<AddWorkerBlockedTimeSlotRangeMutation, AddWorkerBlockedTimeSlotRangeMutationVariables>;
export const DeleteBackgroundCheckDocument = gql`
    mutation DeleteBackgroundCheck($id: Int!) {
  deleteBackgroundCheck(id: $id)
}
    `;
export type DeleteBackgroundCheckMutationFn = Apollo.MutationFunction<DeleteBackgroundCheckMutation, DeleteBackgroundCheckMutationVariables>;

/**
 * __useDeleteBackgroundCheckMutation__
 *
 * To run a mutation, you first call `useDeleteBackgroundCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBackgroundCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBackgroundCheckMutation, { data, loading, error }] = useDeleteBackgroundCheckMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBackgroundCheckMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBackgroundCheckMutation, DeleteBackgroundCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBackgroundCheckMutation, DeleteBackgroundCheckMutationVariables>(DeleteBackgroundCheckDocument, options);
      }
export type DeleteBackgroundCheckMutationHookResult = ReturnType<typeof useDeleteBackgroundCheckMutation>;
export type DeleteBackgroundCheckMutationResult = Apollo.MutationResult<DeleteBackgroundCheckMutation>;
export type DeleteBackgroundCheckMutationOptions = Apollo.BaseMutationOptions<DeleteBackgroundCheckMutation, DeleteBackgroundCheckMutationVariables>;
export const DeleteContractorInstallationCapablilityDocument = gql`
    mutation DeleteContractorInstallationCapablility($contractorId: Int!, $productTypeId: Int!) {
  deleteContractorInstallationCapability(contractorId: $contractorId, productTypeId: $productTypeId)
}
    `;
export type DeleteContractorInstallationCapablilityMutationFn = Apollo.MutationFunction<DeleteContractorInstallationCapablilityMutation, DeleteContractorInstallationCapablilityMutationVariables>;

/**
 * __useDeleteContractorInstallationCapablilityMutation__
 *
 * To run a mutation, you first call `useDeleteContractorInstallationCapablilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractorInstallationCapablilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractorInstallationCapablilityMutation, { data, loading, error }] = useDeleteContractorInstallationCapablilityMutation({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useDeleteContractorInstallationCapablilityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContractorInstallationCapablilityMutation, DeleteContractorInstallationCapablilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContractorInstallationCapablilityMutation, DeleteContractorInstallationCapablilityMutationVariables>(DeleteContractorInstallationCapablilityDocument, options);
      }
export type DeleteContractorInstallationCapablilityMutationHookResult = ReturnType<typeof useDeleteContractorInstallationCapablilityMutation>;
export type DeleteContractorInstallationCapablilityMutationResult = Apollo.MutationResult<DeleteContractorInstallationCapablilityMutation>;
export type DeleteContractorInstallationCapablilityMutationOptions = Apollo.BaseMutationOptions<DeleteContractorInstallationCapablilityMutation, DeleteContractorInstallationCapablilityMutationVariables>;
export const DeleteLiabilityInsuranceDocument = gql`
    mutation DeleteLiabilityInsurance($id: Int!) {
  deleteLiabilityInsurance(id: $id)
}
    `;
export type DeleteLiabilityInsuranceMutationFn = Apollo.MutationFunction<DeleteLiabilityInsuranceMutation, DeleteLiabilityInsuranceMutationVariables>;

/**
 * __useDeleteLiabilityInsuranceMutation__
 *
 * To run a mutation, you first call `useDeleteLiabilityInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLiabilityInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLiabilityInsuranceMutation, { data, loading, error }] = useDeleteLiabilityInsuranceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLiabilityInsuranceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLiabilityInsuranceMutation, DeleteLiabilityInsuranceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLiabilityInsuranceMutation, DeleteLiabilityInsuranceMutationVariables>(DeleteLiabilityInsuranceDocument, options);
      }
export type DeleteLiabilityInsuranceMutationHookResult = ReturnType<typeof useDeleteLiabilityInsuranceMutation>;
export type DeleteLiabilityInsuranceMutationResult = Apollo.MutationResult<DeleteLiabilityInsuranceMutation>;
export type DeleteLiabilityInsuranceMutationOptions = Apollo.BaseMutationOptions<DeleteLiabilityInsuranceMutation, DeleteLiabilityInsuranceMutationVariables>;
export const DeleteW9Document = gql`
    mutation DeleteW9($id: Int!) {
  deleteW9(id: $id)
}
    `;
export type DeleteW9MutationFn = Apollo.MutationFunction<DeleteW9Mutation, DeleteW9MutationVariables>;

/**
 * __useDeleteW9Mutation__
 *
 * To run a mutation, you first call `useDeleteW9Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteW9Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteW9Mutation, { data, loading, error }] = useDeleteW9Mutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteW9Mutation(baseOptions?: Apollo.MutationHookOptions<DeleteW9Mutation, DeleteW9MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteW9Mutation, DeleteW9MutationVariables>(DeleteW9Document, options);
      }
export type DeleteW9MutationHookResult = ReturnType<typeof useDeleteW9Mutation>;
export type DeleteW9MutationResult = Apollo.MutationResult<DeleteW9Mutation>;
export type DeleteW9MutationOptions = Apollo.BaseMutationOptions<DeleteW9Mutation, DeleteW9MutationVariables>;
export const DeleteWorkersCompDocument = gql`
    mutation DeleteWorkersComp($id: Int!) {
  deleteWorkersComp(id: $id)
}
    `;
export type DeleteWorkersCompMutationFn = Apollo.MutationFunction<DeleteWorkersCompMutation, DeleteWorkersCompMutationVariables>;

/**
 * __useDeleteWorkersCompMutation__
 *
 * To run a mutation, you first call `useDeleteWorkersCompMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkersCompMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkersCompMutation, { data, loading, error }] = useDeleteWorkersCompMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkersCompMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkersCompMutation, DeleteWorkersCompMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkersCompMutation, DeleteWorkersCompMutationVariables>(DeleteWorkersCompDocument, options);
      }
export type DeleteWorkersCompMutationHookResult = ReturnType<typeof useDeleteWorkersCompMutation>;
export type DeleteWorkersCompMutationResult = Apollo.MutationResult<DeleteWorkersCompMutation>;
export type DeleteWorkersCompMutationOptions = Apollo.BaseMutationOptions<DeleteWorkersCompMutation, DeleteWorkersCompMutationVariables>;
export const SetWorkerDriversLicenseFileDocument = gql`
    mutation SetWorkerDriversLicenseFile($workerId: Int!, $fileExtension: String!) {
  setWorkerDriversLicenseFile(workerId: $workerId, fileExtension: $fileExtension) {
    ...PreSignedS3FileUpload
  }
}
    ${PreSignedS3FileUploadFragmentDoc}`;
export type SetWorkerDriversLicenseFileMutationFn = Apollo.MutationFunction<SetWorkerDriversLicenseFileMutation, SetWorkerDriversLicenseFileMutationVariables>;

/**
 * __useSetWorkerDriversLicenseFileMutation__
 *
 * To run a mutation, you first call `useSetWorkerDriversLicenseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkerDriversLicenseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkerDriversLicenseFileMutation, { data, loading, error }] = useSetWorkerDriversLicenseFileMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      fileExtension: // value for 'fileExtension'
 *   },
 * });
 */
export function useSetWorkerDriversLicenseFileMutation(baseOptions?: Apollo.MutationHookOptions<SetWorkerDriversLicenseFileMutation, SetWorkerDriversLicenseFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWorkerDriversLicenseFileMutation, SetWorkerDriversLicenseFileMutationVariables>(SetWorkerDriversLicenseFileDocument, options);
      }
export type SetWorkerDriversLicenseFileMutationHookResult = ReturnType<typeof useSetWorkerDriversLicenseFileMutation>;
export type SetWorkerDriversLicenseFileMutationResult = Apollo.MutationResult<SetWorkerDriversLicenseFileMutation>;
export type SetWorkerDriversLicenseFileMutationOptions = Apollo.BaseMutationOptions<SetWorkerDriversLicenseFileMutation, SetWorkerDriversLicenseFileMutationVariables>;
export const SetWorkerLicenseFileDocument = gql`
    mutation SetWorkerLicenseFile($workerId: Int!, $fileExtension: String!) {
  setWorkerLicenseFile(workerId: $workerId, fileExtension: $fileExtension) {
    ...PreSignedS3FileUpload
  }
}
    ${PreSignedS3FileUploadFragmentDoc}`;
export type SetWorkerLicenseFileMutationFn = Apollo.MutationFunction<SetWorkerLicenseFileMutation, SetWorkerLicenseFileMutationVariables>;

/**
 * __useSetWorkerLicenseFileMutation__
 *
 * To run a mutation, you first call `useSetWorkerLicenseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkerLicenseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkerLicenseFileMutation, { data, loading, error }] = useSetWorkerLicenseFileMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      fileExtension: // value for 'fileExtension'
 *   },
 * });
 */
export function useSetWorkerLicenseFileMutation(baseOptions?: Apollo.MutationHookOptions<SetWorkerLicenseFileMutation, SetWorkerLicenseFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWorkerLicenseFileMutation, SetWorkerLicenseFileMutationVariables>(SetWorkerLicenseFileDocument, options);
      }
export type SetWorkerLicenseFileMutationHookResult = ReturnType<typeof useSetWorkerLicenseFileMutation>;
export type SetWorkerLicenseFileMutationResult = Apollo.MutationResult<SetWorkerLicenseFileMutation>;
export type SetWorkerLicenseFileMutationOptions = Apollo.BaseMutationOptions<SetWorkerLicenseFileMutation, SetWorkerLicenseFileMutationVariables>;
export const SetWorkerProfilePictureDocument = gql`
    mutation SetWorkerProfilePicture($workerId: Int!, $fileExtension: String!) {
  setWorkerProfilePicture(workerId: $workerId, fileExtension: $fileExtension) {
    ...PreSignedS3FileUpload
  }
}
    ${PreSignedS3FileUploadFragmentDoc}`;
export type SetWorkerProfilePictureMutationFn = Apollo.MutationFunction<SetWorkerProfilePictureMutation, SetWorkerProfilePictureMutationVariables>;

/**
 * __useSetWorkerProfilePictureMutation__
 *
 * To run a mutation, you first call `useSetWorkerProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkerProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkerProfilePictureMutation, { data, loading, error }] = useSetWorkerProfilePictureMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      fileExtension: // value for 'fileExtension'
 *   },
 * });
 */
export function useSetWorkerProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<SetWorkerProfilePictureMutation, SetWorkerProfilePictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWorkerProfilePictureMutation, SetWorkerProfilePictureMutationVariables>(SetWorkerProfilePictureDocument, options);
      }
export type SetWorkerProfilePictureMutationHookResult = ReturnType<typeof useSetWorkerProfilePictureMutation>;
export type SetWorkerProfilePictureMutationResult = Apollo.MutationResult<SetWorkerProfilePictureMutation>;
export type SetWorkerProfilePictureMutationOptions = Apollo.BaseMutationOptions<SetWorkerProfilePictureMutation, SetWorkerProfilePictureMutationVariables>;
export const UpdateContractorZipsDocument = gql`
    mutation UpdateContractorZips($contractorId: Int!, $addedZips: [String!]!, $removedZips: [String!]!) {
  updateContractorZips(contractorId: $contractorId, addedZips: $addedZips, removedZips: $removedZips)
}
    `;
export type UpdateContractorZipsMutationFn = Apollo.MutationFunction<UpdateContractorZipsMutation, UpdateContractorZipsMutationVariables>;

/**
 * __useUpdateContractorZipsMutation__
 *
 * To run a mutation, you first call `useUpdateContractorZipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractorZipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractorZipsMutation, { data, loading, error }] = useUpdateContractorZipsMutation({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *      addedZips: // value for 'addedZips'
 *      removedZips: // value for 'removedZips'
 *   },
 * });
 */
export function useUpdateContractorZipsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContractorZipsMutation, UpdateContractorZipsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContractorZipsMutation, UpdateContractorZipsMutationVariables>(UpdateContractorZipsDocument, options);
      }
export type UpdateContractorZipsMutationHookResult = ReturnType<typeof useUpdateContractorZipsMutation>;
export type UpdateContractorZipsMutationResult = Apollo.MutationResult<UpdateContractorZipsMutation>;
export type UpdateContractorZipsMutationOptions = Apollo.BaseMutationOptions<UpdateContractorZipsMutation, UpdateContractorZipsMutationVariables>;
export const UpdateSalespersonZipsDocument = gql`
    mutation UpdateSalespersonZips($salespersonId: Int!, $addedZips: [String!]!, $removedZips: [String!]!) {
  updateSalespersonZips(salespersonId: $salespersonId, addedZips: $addedZips, removedZips: $removedZips)
}
    `;
export type UpdateSalespersonZipsMutationFn = Apollo.MutationFunction<UpdateSalespersonZipsMutation, UpdateSalespersonZipsMutationVariables>;

/**
 * __useUpdateSalespersonZipsMutation__
 *
 * To run a mutation, you first call `useUpdateSalespersonZipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalespersonZipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalespersonZipsMutation, { data, loading, error }] = useUpdateSalespersonZipsMutation({
 *   variables: {
 *      salespersonId: // value for 'salespersonId'
 *      addedZips: // value for 'addedZips'
 *      removedZips: // value for 'removedZips'
 *   },
 * });
 */
export function useUpdateSalespersonZipsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalespersonZipsMutation, UpdateSalespersonZipsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSalespersonZipsMutation, UpdateSalespersonZipsMutationVariables>(UpdateSalespersonZipsDocument, options);
      }
export type UpdateSalespersonZipsMutationHookResult = ReturnType<typeof useUpdateSalespersonZipsMutation>;
export type UpdateSalespersonZipsMutationResult = Apollo.MutationResult<UpdateSalespersonZipsMutation>;
export type UpdateSalespersonZipsMutationOptions = Apollo.BaseMutationOptions<UpdateSalespersonZipsMutation, UpdateSalespersonZipsMutationVariables>;
export const UpdateWorkerBlockedTimeSlotDocument = gql`
    mutation UpdateWorkerBlockedTimeSlot($workerBlockedTimeSlotId: Int!, $date: DateTime!, $timeSlot: AtomicTimeSlotInput!) {
  updateWorkerBlockedTimeSlot(workerBlockedTimeSlotId: $workerBlockedTimeSlotId, date: $date, timeSlot: $timeSlot)
}
    `;
export type UpdateWorkerBlockedTimeSlotMutationFn = Apollo.MutationFunction<UpdateWorkerBlockedTimeSlotMutation, UpdateWorkerBlockedTimeSlotMutationVariables>;

/**
 * __useUpdateWorkerBlockedTimeSlotMutation__
 *
 * To run a mutation, you first call `useUpdateWorkerBlockedTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkerBlockedTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkerBlockedTimeSlotMutation, { data, loading, error }] = useUpdateWorkerBlockedTimeSlotMutation({
 *   variables: {
 *      workerBlockedTimeSlotId: // value for 'workerBlockedTimeSlotId'
 *      date: // value for 'date'
 *      timeSlot: // value for 'timeSlot'
 *   },
 * });
 */
export function useUpdateWorkerBlockedTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkerBlockedTimeSlotMutation, UpdateWorkerBlockedTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkerBlockedTimeSlotMutation, UpdateWorkerBlockedTimeSlotMutationVariables>(UpdateWorkerBlockedTimeSlotDocument, options);
      }
export type UpdateWorkerBlockedTimeSlotMutationHookResult = ReturnType<typeof useUpdateWorkerBlockedTimeSlotMutation>;
export type UpdateWorkerBlockedTimeSlotMutationResult = Apollo.MutationResult<UpdateWorkerBlockedTimeSlotMutation>;
export type UpdateWorkerBlockedTimeSlotMutationOptions = Apollo.BaseMutationOptions<UpdateWorkerBlockedTimeSlotMutation, UpdateWorkerBlockedTimeSlotMutationVariables>;
export const UpdateWorkerIsActiveDocument = gql`
    mutation UpdateWorkerIsActive($workerId: Int!, $isActive: Boolean!) {
  updateWorkerIsActive(workerId: $workerId, isActive: $isActive)
}
    `;
export type UpdateWorkerIsActiveMutationFn = Apollo.MutationFunction<UpdateWorkerIsActiveMutation, UpdateWorkerIsActiveMutationVariables>;

/**
 * __useUpdateWorkerIsActiveMutation__
 *
 * To run a mutation, you first call `useUpdateWorkerIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkerIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkerIsActiveMutation, { data, loading, error }] = useUpdateWorkerIsActiveMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateWorkerIsActiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkerIsActiveMutation, UpdateWorkerIsActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkerIsActiveMutation, UpdateWorkerIsActiveMutationVariables>(UpdateWorkerIsActiveDocument, options);
      }
export type UpdateWorkerIsActiveMutationHookResult = ReturnType<typeof useUpdateWorkerIsActiveMutation>;
export type UpdateWorkerIsActiveMutationResult = Apollo.MutationResult<UpdateWorkerIsActiveMutation>;
export type UpdateWorkerIsActiveMutationOptions = Apollo.BaseMutationOptions<UpdateWorkerIsActiveMutation, UpdateWorkerIsActiveMutationVariables>;
export const UpsertBackgroundCheckDocument = gql`
    mutation UpsertBackgroundCheck($backgroundCheck: BackgroundCheckInput!) {
  upsertBackgroundCheck(backgroundCheck: $backgroundCheck)
}
    `;
export type UpsertBackgroundCheckMutationFn = Apollo.MutationFunction<UpsertBackgroundCheckMutation, UpsertBackgroundCheckMutationVariables>;

/**
 * __useUpsertBackgroundCheckMutation__
 *
 * To run a mutation, you first call `useUpsertBackgroundCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBackgroundCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBackgroundCheckMutation, { data, loading, error }] = useUpsertBackgroundCheckMutation({
 *   variables: {
 *      backgroundCheck: // value for 'backgroundCheck'
 *   },
 * });
 */
export function useUpsertBackgroundCheckMutation(baseOptions?: Apollo.MutationHookOptions<UpsertBackgroundCheckMutation, UpsertBackgroundCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertBackgroundCheckMutation, UpsertBackgroundCheckMutationVariables>(UpsertBackgroundCheckDocument, options);
      }
export type UpsertBackgroundCheckMutationHookResult = ReturnType<typeof useUpsertBackgroundCheckMutation>;
export type UpsertBackgroundCheckMutationResult = Apollo.MutationResult<UpsertBackgroundCheckMutation>;
export type UpsertBackgroundCheckMutationOptions = Apollo.BaseMutationOptions<UpsertBackgroundCheckMutation, UpsertBackgroundCheckMutationVariables>;
export const UpsertContractorProfileDocument = gql`
    mutation UpsertContractorProfile($contractor: ContractorProfileInput!) {
  upsertContractorProfile(contractor: $contractor)
}
    `;
export type UpsertContractorProfileMutationFn = Apollo.MutationFunction<UpsertContractorProfileMutation, UpsertContractorProfileMutationVariables>;

/**
 * __useUpsertContractorProfileMutation__
 *
 * To run a mutation, you first call `useUpsertContractorProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContractorProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContractorProfileMutation, { data, loading, error }] = useUpsertContractorProfileMutation({
 *   variables: {
 *      contractor: // value for 'contractor'
 *   },
 * });
 */
export function useUpsertContractorProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContractorProfileMutation, UpsertContractorProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContractorProfileMutation, UpsertContractorProfileMutationVariables>(UpsertContractorProfileDocument, options);
      }
export type UpsertContractorProfileMutationHookResult = ReturnType<typeof useUpsertContractorProfileMutation>;
export type UpsertContractorProfileMutationResult = Apollo.MutationResult<UpsertContractorProfileMutation>;
export type UpsertContractorProfileMutationOptions = Apollo.BaseMutationOptions<UpsertContractorProfileMutation, UpsertContractorProfileMutationVariables>;
export const UpsertContractorHelperDocument = gql`
    mutation UpsertContractorHelper($helper: ContractorHelperInput!) {
  upsertContractorHelper(helper: $helper)
}
    `;
export type UpsertContractorHelperMutationFn = Apollo.MutationFunction<UpsertContractorHelperMutation, UpsertContractorHelperMutationVariables>;

/**
 * __useUpsertContractorHelperMutation__
 *
 * To run a mutation, you first call `useUpsertContractorHelperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContractorHelperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContractorHelperMutation, { data, loading, error }] = useUpsertContractorHelperMutation({
 *   variables: {
 *      helper: // value for 'helper'
 *   },
 * });
 */
export function useUpsertContractorHelperMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContractorHelperMutation, UpsertContractorHelperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContractorHelperMutation, UpsertContractorHelperMutationVariables>(UpsertContractorHelperDocument, options);
      }
export type UpsertContractorHelperMutationHookResult = ReturnType<typeof useUpsertContractorHelperMutation>;
export type UpsertContractorHelperMutationResult = Apollo.MutationResult<UpsertContractorHelperMutation>;
export type UpsertContractorHelperMutationOptions = Apollo.BaseMutationOptions<UpsertContractorHelperMutation, UpsertContractorHelperMutationVariables>;
export const UpsertContractorInstallationCapabilityDocument = gql`
    mutation UpsertContractorInstallationCapability($capability: ContractorInstallationCapabilityInput!) {
  upsertContractorInstallationCapability(capability: $capability)
}
    `;
export type UpsertContractorInstallationCapabilityMutationFn = Apollo.MutationFunction<UpsertContractorInstallationCapabilityMutation, UpsertContractorInstallationCapabilityMutationVariables>;

/**
 * __useUpsertContractorInstallationCapabilityMutation__
 *
 * To run a mutation, you first call `useUpsertContractorInstallationCapabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContractorInstallationCapabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContractorInstallationCapabilityMutation, { data, loading, error }] = useUpsertContractorInstallationCapabilityMutation({
 *   variables: {
 *      capability: // value for 'capability'
 *   },
 * });
 */
export function useUpsertContractorInstallationCapabilityMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContractorInstallationCapabilityMutation, UpsertContractorInstallationCapabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContractorInstallationCapabilityMutation, UpsertContractorInstallationCapabilityMutationVariables>(UpsertContractorInstallationCapabilityDocument, options);
      }
export type UpsertContractorInstallationCapabilityMutationHookResult = ReturnType<typeof useUpsertContractorInstallationCapabilityMutation>;
export type UpsertContractorInstallationCapabilityMutationResult = Apollo.MutationResult<UpsertContractorInstallationCapabilityMutation>;
export type UpsertContractorInstallationCapabilityMutationOptions = Apollo.BaseMutationOptions<UpsertContractorInstallationCapabilityMutation, UpsertContractorInstallationCapabilityMutationVariables>;
export const UpsertLiabilityInsuranceDocument = gql`
    mutation UpsertLiabilityInsurance($liabilityInsurance: LiabilityInsuranceInput!, $hasFile: Boolean!) {
  upsertLiabilityInsurance(liabilityInsurance: $liabilityInsurance, hasFile: $hasFile) {
    ...PreSignedS3FileUpload
  }
}
    ${PreSignedS3FileUploadFragmentDoc}`;
export type UpsertLiabilityInsuranceMutationFn = Apollo.MutationFunction<UpsertLiabilityInsuranceMutation, UpsertLiabilityInsuranceMutationVariables>;

/**
 * __useUpsertLiabilityInsuranceMutation__
 *
 * To run a mutation, you first call `useUpsertLiabilityInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLiabilityInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLiabilityInsuranceMutation, { data, loading, error }] = useUpsertLiabilityInsuranceMutation({
 *   variables: {
 *      liabilityInsurance: // value for 'liabilityInsurance'
 *      hasFile: // value for 'hasFile'
 *   },
 * });
 */
export function useUpsertLiabilityInsuranceMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLiabilityInsuranceMutation, UpsertLiabilityInsuranceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLiabilityInsuranceMutation, UpsertLiabilityInsuranceMutationVariables>(UpsertLiabilityInsuranceDocument, options);
      }
export type UpsertLiabilityInsuranceMutationHookResult = ReturnType<typeof useUpsertLiabilityInsuranceMutation>;
export type UpsertLiabilityInsuranceMutationResult = Apollo.MutationResult<UpsertLiabilityInsuranceMutation>;
export type UpsertLiabilityInsuranceMutationOptions = Apollo.BaseMutationOptions<UpsertLiabilityInsuranceMutation, UpsertLiabilityInsuranceMutationVariables>;
export const UpsertSalespersonProfileDocument = gql`
    mutation UpsertSalespersonProfile($salesperson: SalespersonProfileInput!) {
  upsertSalespersonProfile(salesperson: $salesperson)
}
    `;
export type UpsertSalespersonProfileMutationFn = Apollo.MutationFunction<UpsertSalespersonProfileMutation, UpsertSalespersonProfileMutationVariables>;

/**
 * __useUpsertSalespersonProfileMutation__
 *
 * To run a mutation, you first call `useUpsertSalespersonProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSalespersonProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSalespersonProfileMutation, { data, loading, error }] = useUpsertSalespersonProfileMutation({
 *   variables: {
 *      salesperson: // value for 'salesperson'
 *   },
 * });
 */
export function useUpsertSalespersonProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSalespersonProfileMutation, UpsertSalespersonProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSalespersonProfileMutation, UpsertSalespersonProfileMutationVariables>(UpsertSalespersonProfileDocument, options);
      }
export type UpsertSalespersonProfileMutationHookResult = ReturnType<typeof useUpsertSalespersonProfileMutation>;
export type UpsertSalespersonProfileMutationResult = Apollo.MutationResult<UpsertSalespersonProfileMutation>;
export type UpsertSalespersonProfileMutationOptions = Apollo.BaseMutationOptions<UpsertSalespersonProfileMutation, UpsertSalespersonProfileMutationVariables>;
export const UpsertW9Document = gql`
    mutation UpsertW9($w9: W9Input!, $hasFile: Boolean!) {
  upsertW9(w9: $w9, hasFile: $hasFile) {
    ...PreSignedS3FileUpload
  }
}
    ${PreSignedS3FileUploadFragmentDoc}`;
export type UpsertW9MutationFn = Apollo.MutationFunction<UpsertW9Mutation, UpsertW9MutationVariables>;

/**
 * __useUpsertW9Mutation__
 *
 * To run a mutation, you first call `useUpsertW9Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertW9Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertW9Mutation, { data, loading, error }] = useUpsertW9Mutation({
 *   variables: {
 *      w9: // value for 'w9'
 *      hasFile: // value for 'hasFile'
 *   },
 * });
 */
export function useUpsertW9Mutation(baseOptions?: Apollo.MutationHookOptions<UpsertW9Mutation, UpsertW9MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertW9Mutation, UpsertW9MutationVariables>(UpsertW9Document, options);
      }
export type UpsertW9MutationHookResult = ReturnType<typeof useUpsertW9Mutation>;
export type UpsertW9MutationResult = Apollo.MutationResult<UpsertW9Mutation>;
export type UpsertW9MutationOptions = Apollo.BaseMutationOptions<UpsertW9Mutation, UpsertW9MutationVariables>;
export const UpsertWorkerHistoryDocument = gql`
    mutation UpsertWorkerHistory($history: WorkerHistoryInput!) {
  upsertWorkerHistory(history: $history)
}
    `;
export type UpsertWorkerHistoryMutationFn = Apollo.MutationFunction<UpsertWorkerHistoryMutation, UpsertWorkerHistoryMutationVariables>;

/**
 * __useUpsertWorkerHistoryMutation__
 *
 * To run a mutation, you first call `useUpsertWorkerHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertWorkerHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertWorkerHistoryMutation, { data, loading, error }] = useUpsertWorkerHistoryMutation({
 *   variables: {
 *      history: // value for 'history'
 *   },
 * });
 */
export function useUpsertWorkerHistoryMutation(baseOptions?: Apollo.MutationHookOptions<UpsertWorkerHistoryMutation, UpsertWorkerHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertWorkerHistoryMutation, UpsertWorkerHistoryMutationVariables>(UpsertWorkerHistoryDocument, options);
      }
export type UpsertWorkerHistoryMutationHookResult = ReturnType<typeof useUpsertWorkerHistoryMutation>;
export type UpsertWorkerHistoryMutationResult = Apollo.MutationResult<UpsertWorkerHistoryMutation>;
export type UpsertWorkerHistoryMutationOptions = Apollo.BaseMutationOptions<UpsertWorkerHistoryMutation, UpsertWorkerHistoryMutationVariables>;
export const UpsertWorkersCompDocument = gql`
    mutation UpsertWorkersComp($workersComp: WorkersCompInput!, $hasFile: Boolean!) {
  upsertWorkersComp(workersComp: $workersComp, hasFile: $hasFile) {
    ...PreSignedS3FileUpload
  }
}
    ${PreSignedS3FileUploadFragmentDoc}`;
export type UpsertWorkersCompMutationFn = Apollo.MutationFunction<UpsertWorkersCompMutation, UpsertWorkersCompMutationVariables>;

/**
 * __useUpsertWorkersCompMutation__
 *
 * To run a mutation, you first call `useUpsertWorkersCompMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertWorkersCompMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertWorkersCompMutation, { data, loading, error }] = useUpsertWorkersCompMutation({
 *   variables: {
 *      workersComp: // value for 'workersComp'
 *      hasFile: // value for 'hasFile'
 *   },
 * });
 */
export function useUpsertWorkersCompMutation(baseOptions?: Apollo.MutationHookOptions<UpsertWorkersCompMutation, UpsertWorkersCompMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertWorkersCompMutation, UpsertWorkersCompMutationVariables>(UpsertWorkersCompDocument, options);
      }
export type UpsertWorkersCompMutationHookResult = ReturnType<typeof useUpsertWorkersCompMutation>;
export type UpsertWorkersCompMutationResult = Apollo.MutationResult<UpsertWorkersCompMutation>;
export type UpsertWorkersCompMutationOptions = Apollo.BaseMutationOptions<UpsertWorkersCompMutation, UpsertWorkersCompMutationVariables>;
export const WorkerPunchInDocument = gql`
    mutation WorkerPunchIn($workerId: Int!) {
  workerPunchIn(workerId: $workerId)
}
    `;
export type WorkerPunchInMutationFn = Apollo.MutationFunction<WorkerPunchInMutation, WorkerPunchInMutationVariables>;

/**
 * __useWorkerPunchInMutation__
 *
 * To run a mutation, you first call `useWorkerPunchInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkerPunchInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workerPunchInMutation, { data, loading, error }] = useWorkerPunchInMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useWorkerPunchInMutation(baseOptions?: Apollo.MutationHookOptions<WorkerPunchInMutation, WorkerPunchInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkerPunchInMutation, WorkerPunchInMutationVariables>(WorkerPunchInDocument, options);
      }
export type WorkerPunchInMutationHookResult = ReturnType<typeof useWorkerPunchInMutation>;
export type WorkerPunchInMutationResult = Apollo.MutationResult<WorkerPunchInMutation>;
export type WorkerPunchInMutationOptions = Apollo.BaseMutationOptions<WorkerPunchInMutation, WorkerPunchInMutationVariables>;
export const namedOperations = {
  Query: {
    GetAllContractorRatingParameterSets: 'GetAllContractorRatingParameterSets',
    GetAllDiscounts: 'GetAllDiscounts',
    GetFinancingTableData: 'GetFinancingTableData',
    CheckForPromotionConflicts: 'CheckForPromotionConflicts',
    GetAllScheduledPromotions: 'GetAllScheduledPromotions',
    GetBasePromotionsTableData: 'GetBasePromotionsTableData',
    GetPromotionOption: 'GetPromotionOption',
    GetAllCallCenterJobs: 'GetAllCallCenterJobs',
    GetCallCenterJob: 'GetCallCenterJob',
    GetCallCenterScripts: 'GetCallCenterScripts',
    GetChat: 'GetChat',
    GetChatForJob: 'GetChatForJob',
    GetChatForJobFromChat: 'GetChatForJobFromChat',
    GetCommandCenterChatParty: 'GetCommandCenterChatParty',
    GetDefaultDisplayedJobChats: 'GetDefaultDisplayedJobChats',
    GetJobIdForChat: 'GetJobIdForChat',
    GetPricingCenterTabs: 'GetPricingCenterTabs',
    GetPricingSummary: 'GetPricingSummary',
    GetPriceRequest: 'GetPriceRequest',
    GetPriceRequestsForJob: 'GetPriceRequestsForJob',
    GetCompletionCertificate: 'GetCompletionCertificate',
    GetCompletionCertificatePDF: 'GetCompletionCertificatePDF',
    GetDaysContractorIsBusy: 'GetDaysContractorIsBusy',
    GetInstallationAppointmentForContractor: 'GetInstallationAppointmentForContractor',
    GetInstallationAppointmentForContractorAfterDate: 'GetInstallationAppointmentForContractorAfterDate',
    GetPartialServiceOrdersForContractor: 'GetPartialServiceOrdersForContractor',
    GetTempCompletionCertificateData: 'GetTempCompletionCertificateData',
    GetContractProgress: 'GetContractProgress',
    GetJobCOD: 'GetJobCOD',
    GetJobContract: 'GetJobContract',
    GetJobContractPdfS3File: 'GetJobContractPdfS3File',
    GetJobContractRevisionIds: 'GetJobContractRevisionIds',
    GetJobActiveContractId: 'GetJobActiveContractId',
    GetJobPayments: 'GetJobPayments',
    GetCustomerAppointmentSearchResults: 'GetCustomerAppointmentSearchResults',
    GetCustomerAsSearchResult: 'GetCustomerAsSearchResult',
    GetCustomerCleanupList: 'GetCustomerCleanupList',
    GetCustomerHistory: 'GetCustomerHistory',
    GetCustomerSearchResults: 'GetCustomerSearchResults',
    GetS3File: 'GetS3File',
    GetDummyInventory: 'GetDummyInventory',
    GetUsers: 'GetUsers',
    CalculateInstallationCalendarMovement: 'CalculateInstallationCalendarMovement',
    GetInstallationAppointmentsAfterDate: 'GetInstallationAppointmentsAfterDate',
    GetInstallationAppointmentsForJobContract: 'GetInstallationAppointmentsForJobContract',
    CalculatePriceForLocalConfiguration: 'CalculatePriceForLocalConfiguration',
    GetActiveFinancingOptions: 'GetActiveFinancingOptions',
    GetAllAppliedDiscountsForJob: 'GetAllAppliedDiscountsForJob',
    GetAllJobIds: 'GetAllJobIds',
    GetAllValidProductStyleOptionsForConfiguration: 'GetAllValidProductStyleOptionsForConfiguration',
    GetAllSAHSalespeople: 'GetAllSAHSalespeople',
    GetAllSalespeopleForJob: 'GetAllSalespeopleForJob',
    GetArea: 'GetArea',
    GetCallCenterJobSummary: 'GetCallCenterJobSummary',
    GetContractData: 'GetContractData',
    GetFullyScaledJobConfiguration: 'GetFullyScaledJobConfiguration',
    GetJobConfiguration: 'GetJobConfiguration',
    GetJobConfigurationAreaIds: 'GetJobConfigurationAreaIds',
    GetJobConfigurationFinancing: 'GetJobConfigurationFinancing',
    GetJobConfigurationHeader: 'GetJobConfigurationHeader',
    GetJobConfigurationWithWasteFactor: 'GetJobConfigurationWithWasteFactor',
    GetJobFinancingOptions: 'GetJobFinancingOptions',
    GetJobId: 'GetJobId',
    GetJobIsFail: 'GetJobIsFail',
    GetJobPrice: 'GetJobPrice',
    GetJobSearchResults: 'GetJobSearchResults',
    GetJobSummaryData: 'GetJobSummaryData',
    GetMaterialOptions: 'GetMaterialOptions',
    GetMsrpScalarForConfiguration: 'GetMsrpScalarForConfiguration',
    GetOverrideDiscountForJob: 'GetOverrideDiscountForJob',
    GetPicture: 'GetPicture',
    GetPreContractJobSummaryData: 'GetPreContractJobSummaryData',
    GetPriceVerificationFields: 'GetPriceVerificationFields',
    GetProductStyleValidOptionsForArea: 'GetProductStyleValidOptionsForArea',
    GetPromotionOnJob: 'GetPromotionOnJob',
    GetQuotePriceForJobChanges: 'GetQuotePriceForJobChanges',
    GetRoomLabelOptions: 'GetRoomLabelOptions',
    GetServiceTypeOptions: 'GetServiceTypeOptions',
    GetTestSVG: 'GetTestSVG',
    GetAllQuotesWithCustomer: 'GetAllQuotesWithCustomer',
    GetCartPageData: 'GetCartPageData',
    GetJobInvoiceData: 'GetJobInvoiceData',
    GetQuote: 'GetQuote',
    GetQuotePdfS3File: 'GetQuotePdfS3File',
    GetSellSheetAreaColumn: 'GetSellSheetAreaColumn',
    GetSellSheetHeaderColumn: 'GetSellSheetHeaderColumn',
    GetSellSheetRenderColumn: 'GetSellSheetRenderColumn',
    GetSellSheetTotalAreaComboColumn: 'GetSellSheetTotalAreaComboColumn',
    GetSellSheetTotalColumn: 'GetSellSheetTotalColumn',
    GetAllServiceMaterialCategoriesForProduct: 'GetAllServiceMaterialCategoriesForProduct',
    GetAllServiceProductOfferings: 'GetAllServiceProductOfferings',
    GetAllServiceProductOfferingsForJobService: 'GetAllServiceProductOfferingsForJobService',
    GetChargeableServicePricingDetails: 'GetChargeableServicePricingDetails',
    GetInstallationJobServices: 'GetInstallationJobServices',
    GetJobServicesByType: 'GetJobServicesByType',
    GetMetadataOfferingsForProductAndService: 'GetMetadataOfferingsForProductAndService',
    GetServiceLaborRule: 'GetServiceLaborRule',
    GetServicePricingUnitNames: 'GetServicePricingUnitNames',
    GetServiceProductOfferingIds: 'GetServiceProductOfferingIds',
    GetServiceTypes: 'GetServiceTypes',
    GetSubstrateId: 'GetSubstrateId',
    GetMeasurementUnit: 'GetMeasurementUnit',
    GetAcceptedPaymentMethods: 'GetAcceptedPaymentMethods',
    GetAllProductStyle: 'GetAllProductStyle',
    GetPartialProductStyle: 'GetPartialProductStyle',
    GetPartialProductStyles: 'GetPartialProductStyles',
    GetProductColorOptions: 'GetProductColorOptions',
    GetProductColorsAndSources: 'GetProductColorsAndSources',
    GetProductSpec: 'GetProductSpec',
    GetProductSpecForStyle: 'GetProductSpecForStyle',
    GetProductSpecNames: 'GetProductSpecNames',
    GetProductStyle: 'GetProductStyle',
    GetProductStyleIds: 'GetProductStyleIds',
    GetProductStyleOptions: 'GetProductStyleOptions',
    GetProductTypeOptions: 'GetProductTypeOptions',
    GetStructuredProductDetailOptions: 'GetStructuredProductDetailOptions',
    GetUnstructuredProductDetailOptions: 'GetUnstructuredProductDetailOptions',
    GetVendorsForColor: 'GetVendorsForColor',
    GetActiveJobsForDashboard: 'GetActiveJobsForDashboard',
    GetAllAreaAndRoomNotes: 'GetAllAreaAndRoomNotes',
    GetAllAreaProductDetailsForJob: 'GetAllAreaProductDetailsForJob',
    GetAllAreasForJob: 'GetAllAreasForJob',
    GetAllInstallationNotes: 'GetAllInstallationNotes',
    GetAllJobsForProjectCoordinator: 'GetAllJobsForProjectCoordinator',
    GetAllProjectCoordinators: 'GetAllProjectCoordinators',
    GetAllRoomsForJob: 'GetAllRoomsForJob',
    GetAppointmentWorkOrder: 'GetAppointmentWorkOrder',
    GetCwcNumber: 'GetCwcNumber',
    GetCwcJobsForProjectCoordinator: 'GetCwcJobsForProjectCoordinator',
    GetInstallationCodBoundsForExisting: 'GetInstallationCodBoundsForExisting',
    GetInstallationCodBoundsForNew: 'GetInstallationCodBoundsForNew',
    GetJobBreakdown: 'GetJobBreakdown',
    GetJobNotes: 'GetJobNotes',
    GetJobWorkOrders: 'GetJobWorkOrders',
    GetPaymentsPaneInfo: 'GetPaymentsPaneInfo',
    GetPicturesForServiceOrder: 'GetPicturesForServiceOrder',
    getProductTypesInConfigurationWithSqft: 'getProductTypesInConfigurationWithSqft',
    GetProjectCoordinatorFromConfiguration: 'GetProjectCoordinatorFromConfiguration',
    GetServiceOrder: 'GetServiceOrder',
    GetServiceOrderStubs: 'GetServiceOrderStubs',
    GetServiceOrderTypes: 'GetServiceOrderTypes',
    GetAllJobsInRecovery: 'GetAllJobsInRecovery',
    GetAllRecoveryLedgerItems: 'GetAllRecoveryLedgerItems',
    GetAllRecoveryReasonOptions: 'GetAllRecoveryReasonOptions',
    GetAllRecoveryStepOptions: 'GetAllRecoveryStepOptions',
    GetRecoveryCenterOverride: 'GetRecoveryCenterOverride',
    CanDeleteMarket: 'CanDeleteMarket',
    EstimateAppointmentLength: 'EstimateAppointmentLength',
    GetActivePromoCode: 'GetActivePromoCode',
    GetActivePromosExist: 'GetActivePromosExist',
    GetAllActiveZipsAndCities: 'GetAllActiveZipsAndCities',
    GetAllBlockedTimeSlotsOnDay: 'GetAllBlockedTimeSlotsOnDay',
    GetAllCounties: 'GetAllCounties',
    GetAllHowDidYouHearAboutUsOptions: 'GetAllHowDidYouHearAboutUsOptions',
    GetAllMarkets: 'GetAllMarkets',
    GetAllSalespeopleInMarket: 'GetAllSalespeopleInMarket',
    GetAllSalespeopleInZip: 'GetAllSalespeopleInZip',
    GetAllServicedLocationInformation: 'GetAllServicedLocationInformation',
    GetAllTimeSlotsForMarket: 'GetAllTimeSlotsForMarket',
    GetAllZipsAndCities: 'GetAllZipsAndCities',
    GetAvailableTimeSlots: 'GetAvailableTimeSlots',
    GetAvailableTimeSlotsForSalesperson: 'GetAvailableTimeSlotsForSalesperson',
    GetColorCategories: 'GetColorCategories',
    GetCustomer: 'GetCustomer',
    GetFilteredBlockedTimeSlots: 'GetFilteredBlockedTimeSlots',
    GetInstallationAppointmentWithRooms: 'GetInstallationAppointmentWithRooms',
    GetInstallationAppointments: 'GetInstallationAppointments',
    GetPhoneNumberStatus: 'GetPhoneNumberStatus',
    GetPriorityOptions: 'GetPriorityOptions',
    GetSAHAppointmentIdForJob: 'GetSAHAppointmentIdForJob',
    GetSahAppointmentsForDate: 'GetSahAppointmentsForDate',
    GetSahAppointmentsForSalesperson: 'GetSahAppointmentsForSalesperson',
    GetAllServiceMaterialCategoryPricings: 'GetAllServiceMaterialCategoryPricings',
    GetServiceMaterialCategoryHistory: 'GetServiceMaterialCategoryHistory',
    GetVendorSourcedProduct: 'GetVendorSourcedProduct',
    GetVendorSourcedProductIdsForStyle: 'GetVendorSourcedProductIdsForStyle',
    GetVendors: 'GetVendors',
    GetAllContractorInstallationCapabilitiesAndDaysOff: 'GetAllContractorInstallationCapabilitiesAndDaysOff',
    GetAllInstallationCertifications: 'GetAllInstallationCertifications',
    GetAuthenticatedCommandCenterWorkerId: 'GetAuthenticatedCommandCenterWorkerId',
    GetAuthenticatedWorker: 'GetAuthenticatedWorker',
    GetAuthenticatedWorkerPermissions: 'GetAuthenticatedWorkerPermissions',
    GetContractor: 'GetContractor',
    GetContractorList: 'GetContractorList',
    GetContractorProfile: 'GetContractorProfile',
    GetGeneralCommandCenterWorkerId: 'GetGeneralCommandCenterWorkerId',
    GetGeneratedSalespersonPerformanceSummary: 'GetGeneratedSalespersonPerformanceSummary',
    GetListOfStoredSalespersonPerformanceSummaries: 'GetListOfStoredSalespersonPerformanceSummaries',
    GetNewestContractorRatingSummary: 'GetNewestContractorRatingSummary',
    GetSalesperson: 'GetSalesperson',
    GetSalespersonList: 'GetSalespersonList',
    GetSalespersonProfile: 'GetSalespersonProfile',
    GetStoredSalespersonPerformanceSummary: 'GetStoredSalespersonPerformanceSummary',
    GetWorkerBlockedTimeSlotsAfterDate: 'GetWorkerBlockedTimeSlotsAfterDate',
    GetWorkerPermissions: 'GetWorkerPermissions'
  },
  Mutation: {
    SetContractorRatingParameters: 'SetContractorRatingParameters',
    DeleteDiscount: 'DeleteDiscount',
    UpdateDiscountVisibility: 'UpdateDiscountVisibility',
    UpsertDiscount: 'UpsertDiscount',
    UpsertFinancingOption: 'UpsertFinancingOption',
    AddScheduledPromotion: 'AddScheduledPromotion',
    UpsertBasePromotion: 'UpsertBasePromotion',
    CancelScheduledPromotion: 'CancelScheduledPromotion',
    DeleteBasePromotion: 'DeleteBasePromotion',
    UpdateAppliedPromotion: 'UpdateAppliedPromotion',
    UpdateScheduledPromotion: 'UpdateScheduledPromotion',
    UpdateWhichMarketsForScheduledPromotion: 'UpdateWhichMarketsForScheduledPromotion',
    AddCallCenterAction: 'AddCallCenterAction',
    DeleteCallCenterScript: 'DeleteCallCenterScript',
    SetCallCenterCallback: 'SetCallCenterCallback',
    SetCallCenterCanceledByCustomer: 'SetCallCenterCanceledByCustomer',
    SetCallCenterClaim: 'SetCallCenterClaim',
    SetCallCenterNotInterested: 'SetCallCenterNotInterested',
    SetCallCenterQualification: 'SetCallCenterQualification',
    SetCallCenterRecoveryClaim: 'SetCallCenterRecoveryClaim',
    SetCallCenterReminderSent: 'SetCallCenterReminderSent',
    UpdateCallCenterAction: 'UpdateCallCenterAction',
    UpdateCallCenterProspectNotes: 'UpdateCallCenterProspectNotes',
    UpsertCallCenterScript: 'UpsertCallCenterScript',
    ClearNewMessageStatus: 'ClearNewMessageStatus',
    SendMessage: 'SendMessage',
    ClaimAllPriceRequestsForJob: 'ClaimAllPriceRequestsForJob',
    RecordPriceRequest: 'RecordPriceRequest',
    SendPriceOverride: 'SendPriceOverride',
    SendPriceRequest: 'SendPriceRequest',
    AddFinancingOverride: 'AddFinancingOverride',
    MarkInstallationAppointmentComplete: 'MarkInstallationAppointmentComplete',
    CallCustomerForInstallationAppointment: 'CallCustomerForInstallationAppointment',
    CheckInToInstallationAppointment: 'CheckInToInstallationAppointment',
    CheckOutFromInstallationAppointment: 'CheckOutFromInstallationAppointment',
    CollectCODForInstallationAppointment: 'CollectCODForInstallationAppointment',
    MappedToCustomerForInstallationAppointment: 'MappedToCustomerForInstallationAppointment',
    PickedUpMaterialsForInstallationAppointment: 'PickedUpMaterialsForInstallationAppointment',
    SubmitCompletionCertificate: 'SubmitCompletionCertificate',
    UpdateEstimatedArrivalTimeForInstallationAppointment: 'UpdateEstimatedArrivalTimeForInstallationAppointment',
    CancelContract: 'CancelContract',
    DeleteJobContractPayments: 'DeleteJobContractPayments',
    UpsertContractPdfPresigned: 'UpsertContractPdfPresigned',
    UpsertJobContractPayments: 'UpsertJobContractPayments',
    EraseAllCustomerData: 'EraseAllCustomerData',
    MergeCustomers: 'MergeCustomers',
    DeleteS3File: 'DeleteS3File',
    ReportS3FileUploadStatus: 'ReportS3FileUploadStatus',
    ToggleInstallationAppointmentRelease: 'ToggleInstallationAppointmentRelease',
    CopyJobConfiguration: 'CopyJobConfiguration',
    AddBeforeAndAfterPicturesForRoom: 'AddBeforeAndAfterPicturesForRoom',
    AddContractToJobWithDeposit: 'AddContractToJobWithDeposit',
    AddPicture: 'AddPicture',
    AddPicturesForRoom: 'AddPicturesForRoom',
    CreateJobWithNewCustomer: 'CreateJobWithNewCustomer',
    DeleteBeforeAndAfterPictureForRoom: 'DeleteBeforeAndAfterPictureForRoom',
    DeleteJobConfiguration: 'DeleteJobConfiguration',
    DeletePictureForRoom: 'DeletePictureForRoom',
    EmailContract: 'EmailContract',
    EmailQuote: 'EmailQuote',
    ReassignRoom: 'ReassignRoom',
    ToggleSeamDirection: 'ToggleSeamDirection',
    UpdateArea: 'UpdateArea',
    UpdateAreaProductColorSelection: 'UpdateAreaProductColorSelection',
    UpdateAreaProductStyleSelection: 'UpdateAreaProductStyleSelection',
    UpdateAreaProductType: 'UpdateAreaProductType',
    UpdateChargeables: 'UpdateChargeables',
    UpdateJobFinancingOption: 'UpdateJobFinancingOption',
    UpdateProductInCart: 'UpdateProductInCart',
    UpdateUnderlaymentInArea: 'UpdateUnderlaymentInArea',
    UpsertJobConfiguration: 'UpsertJobConfiguration',
    AddQuoteForJob: 'AddQuoteForJob',
    TrimInvalidFieldsFromJobConfiguration: 'TrimInvalidFieldsFromJobConfiguration',
    UpdateCustomerDetails: 'UpdateCustomerDetails',
    UpdateJobConfigurationNotes: 'UpdateJobConfigurationNotes',
    UpdateJobDiscount: 'UpdateJobDiscount',
    UpdateJobFinancing: 'UpdateJobFinancing',
    AddMaterialToJobService: 'AddMaterialToJobService',
    AddMultipleOfferingsToExistingService: 'AddMultipleOfferingsToExistingService',
    AddMultipleOfferingsToNewService: 'AddMultipleOfferingsToNewService',
    RemoveMaterialFromJobService: 'RemoveMaterialFromJobService',
    SetDefaultMaterialForJobService: 'SetDefaultMaterialForJobService',
    UpdateServiceProductOffering: 'UpdateServiceProductOffering',
    TryRemoveLaborRule: 'TryRemoveLaborRule',
    AddNewColor: 'AddNewColor',
    AddNewProductSource: 'AddNewProductSource',
    AddNewProductSpec: 'AddNewProductSpec',
    AddNewStyle: 'AddNewStyle',
    BulkAddNewCarpetColors: 'BulkAddNewCarpetColors',
    UpdateProductCosts: 'UpdateProductCosts',
    UpdateProductPrices: 'UpdateProductPrices',
    AddPicturesForServiceOrder: 'AddPicturesForServiceOrder',
    AddServiceOrder: 'AddServiceOrder',
    AssignProjectCoordinator: 'AssignProjectCoordinator',
    ClaimJob: 'ClaimJob',
    DeletePictureForServiceOrder: 'DeletePictureForServiceOrder',
    DeleteServiceOrder: 'DeleteServiceOrder',
    MakeCall: 'MakeCall',
    SubmitChangeOrder: 'SubmitChangeOrder',
    UpdateCWC: 'UpdateCWC',
    UpdateServiceOrder: 'UpdateServiceOrder',
    UpdateServiceOrderScheduledDate: 'UpdateServiceOrderScheduledDate',
    AddInternalJobNotes: 'AddInternalJobNotes',
    AddRecoveryCommunication: 'AddRecoveryCommunication',
    DeleteInternalJobNotes: 'DeleteInternalJobNotes',
    DeleteRecoveryCommunication: 'DeleteRecoveryCommunication',
    SubmitChangesToJobInRecovery: 'SubmitChangesToJobInRecovery',
    UpdateInternalJobNotes: 'UpdateInternalJobNotes',
    UpdateJobInRecovery: 'UpdateJobInRecovery',
    UpdateRecoveryCommunication: 'UpdateRecoveryCommunication',
    UpsertRecoveryCenterOverride: 'UpsertRecoveryCenterOverride',
    AcceptSAHAppointment: 'AcceptSAHAppointment',
    AddInstallationAppointment: 'AddInstallationAppointment',
    AddMarket: 'AddMarket',
    AddNewSAHAppointment: 'AddNewSAHAppointment',
    AddRescheduledSAHAppointment: 'AddRescheduledSAHAppointment',
    BlockTimeSlotByMarkets: 'BlockTimeSlotByMarkets',
    BlockTimeSlotForAllZips: 'BlockTimeSlotForAllZips',
    CancelSAHAppointment: 'CancelSAHAppointment',
    CheckIntoSAHAppointment: 'CheckIntoSAHAppointment',
    CheckOutOfSAHAppointment: 'CheckOutOfSAHAppointment',
    DeleteBlockedTimeSlots: 'DeleteBlockedTimeSlots',
    DeleteInstallationAppointment: 'DeleteInstallationAppointment',
    DeleteMarket: 'DeleteMarket',
    DeleteRoomForInstallationAppointment: 'DeleteRoomForInstallationAppointment',
    DeleteRoomsForInstallationAppointment: 'DeleteRoomsForInstallationAppointment',
    DeleteWorkerBlockedTimeSlot: 'DeleteWorkerBlockedTimeSlot',
    ReleaseSAHAppointment: 'ReleaseSAHAppointment',
    ReportCustomerNotHomeSAHAppointment: 'ReportCustomerNotHomeSAHAppointment',
    UpdateAreaNotes: 'UpdateAreaNotes',
    UpdateInstallationAppointment: 'UpdateInstallationAppointment',
    UpdateInstallationAppointmentNotes: 'UpdateInstallationAppointmentNotes',
    UpdateMarket: 'UpdateMarket',
    UpdateMarketAndServicedForZipAndCity: 'UpdateMarketAndServicedForZipAndCity',
    UpdateMsrpScalarForMarkets: 'UpdateMsrpScalarForMarkets',
    UpdateRoomNotes: 'UpdateRoomNotes',
    UpdateSAHAppointment: 'UpdateSAHAppointment',
    UpdateSAHAppointmentDetails: 'UpdateSAHAppointmentDetails',
    UpdateSahAppointmentNotes: 'UpdateSahAppointmentNotes',
    WaitingForCustomerSAHAppointment: 'WaitingForCustomerSAHAppointment',
    AddServiceMaterialCategoryPricings: 'AddServiceMaterialCategoryPricings',
    UpdateServiceMaterialCategoryPricings: 'UpdateServiceMaterialCategoryPricings',
    SetRole: 'SetRole',
    Test: 'Test',
    AddWorkerBlockedTimeSlot: 'AddWorkerBlockedTimeSlot',
    AddWorkerBlockedTimeSlotRange: 'AddWorkerBlockedTimeSlotRange',
    DeleteBackgroundCheck: 'DeleteBackgroundCheck',
    DeleteContractorInstallationCapablility: 'DeleteContractorInstallationCapablility',
    DeleteLiabilityInsurance: 'DeleteLiabilityInsurance',
    DeleteW9: 'DeleteW9',
    DeleteWorkersComp: 'DeleteWorkersComp',
    SetWorkerDriversLicenseFile: 'SetWorkerDriversLicenseFile',
    SetWorkerLicenseFile: 'SetWorkerLicenseFile',
    SetWorkerProfilePicture: 'SetWorkerProfilePicture',
    UpdateContractorZips: 'UpdateContractorZips',
    UpdateSalespersonZips: 'UpdateSalespersonZips',
    UpdateWorkerBlockedTimeSlot: 'UpdateWorkerBlockedTimeSlot',
    UpdateWorkerIsActive: 'UpdateWorkerIsActive',
    UpsertBackgroundCheck: 'UpsertBackgroundCheck',
    UpsertContractorProfile: 'UpsertContractorProfile',
    UpsertContractorHelper: 'UpsertContractorHelper',
    UpsertContractorInstallationCapability: 'UpsertContractorInstallationCapability',
    UpsertLiabilityInsurance: 'UpsertLiabilityInsurance',
    UpsertSalespersonProfile: 'UpsertSalespersonProfile',
    UpsertW9: 'UpsertW9',
    UpsertWorkerHistory: 'UpsertWorkerHistory',
    UpsertWorkersComp: 'UpsertWorkersComp',
    WorkerPunchIn: 'WorkerPunchIn'
  },
  Subscription: {
    ChatMemberNewMessageStatusUpdated: 'ChatMemberNewMessageStatusUpdated',
    JobChatCommandCenterMemberChanged: 'JobChatCommandCenterMemberChanged',
    ChatMessageSent: 'ChatMessageSent',
    MessageSentToChatParty: 'MessageSentToChatParty',
    RemoveChatFromDrawer: 'RemoveChatFromDrawer',
    OverrideDiscountSent: 'OverrideDiscountSent',
    PriceOverrideSent: 'PriceOverrideSent',
    PriceRequestUpdatedForConfig: 'PriceRequestUpdatedForConfig',
    PriceRequestUpdatedForJob: 'PriceRequestUpdatedForJob',
    JobConfigurationExistenceChanged: 'JobConfigurationExistenceChanged',
    JobQuoted: 'JobQuoted',
    JobSold: 'JobSold',
    DiscountUpdated: 'DiscountUpdated',
    FinancingSlotChanged: 'FinancingSlotChanged',
    FinancingUpdated: 'FinancingUpdated',
    JobConfigurationPriceUpdated: 'JobConfigurationPriceUpdated',
    JobConfigurationUpdated: 'JobConfigurationUpdated',
    PromotionOnJobUpdated: 'PromotionOnJobUpdated'
  },
  Fragment: {
    Discount: 'Discount',
    ChatForJob: 'ChatForJob',
    ChatMember: 'ChatMember',
    Address: 'Address',
    CustomerContact: 'CustomerContact',
    PreSignedS3FileUpload: 'PreSignedS3FileUpload',
    Area: 'Area',
    CarpetCut: 'CarpetCut',
    JobConfiguration: 'JobConfiguration',
    Room: 'Room',
    InstallationAppointment: 'InstallationAppointment',
    BackgroundCheck: 'BackgroundCheck',
    Company: 'Company',
    Contractor: 'Contractor',
    ContractorInstallationCapability: 'ContractorInstallationCapability',
    LiabilityInsurance: 'LiabilityInsurance',
    PartialContractor: 'PartialContractor',
    S3File: 'S3File',
    SalespersonPerformanceSummary: 'SalespersonPerformanceSummary',
    Vehicle: 'Vehicle',
    W9: 'W9',
    Worker: 'Worker',
    WorkerHistory: 'WorkerHistory',
    WorkersComp: 'WorkersComp'
  }
}