import { Dialog, Typography } from "@material-ui/core";
import FlatButton from "FlatComponents/Button/FlatButton";
import { formatMilitaryToHHMM12HR } from "Globals/DateAndTimeHelpers";
import { useUpdateEstimatedArrivalTimeForInstallationAppointmentMutation } from "generated/graphql";
import { useState } from "react";

interface ArrivalTimeReportingProps {
    installationAppointmentId: number;
    showPage: boolean;
    formattedAddress: string;
    phoneNumber: string;
}

export default function ArrivalTimeReporting({
    installationAppointmentId,
    showPage,
    formattedAddress,
    phoneNumber,
}: ArrivalTimeReportingProps) {
    const urlFormattedAddress = formattedAddress.replaceAll(" ", "+");

    const [option, setOption] = useState<string | null>(null);
    const [noAnswer, setNoAnswer] = useState<boolean>(false);

    const [updateEstimate] = useUpdateEstimatedArrivalTimeForInstallationAppointmentMutation();

    const times = generate30MinuteTimes(21, 0);

    const renderOptions: TimeOption[] = times.slice(0, -2).map((time, index) => {
        return {
            time: `${time.time} - ${times[index + 2].time}`,
            timeSpan: time.timeSpan,
        };
    });

    function submitSelection() {
        updateEstimate({
            variables: {
                appointmentId: installationAppointmentId,
                eta: option,
                didCustomerAnswer: !noAnswer,
            },
        });
    }

    return (
        <Dialog open={showPage}>
            <div
                className="flex-column flex-centered"
                style={{ padding: "16px" }}
            >
                <Typography variant="h5">Estimated ETA</Typography>
                <Typography style={{ marginBottom: ".5em" }}>
                    Address:{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href={`https://www.google.com/maps/place/${urlFormattedAddress}`}
                    >
                        {formattedAddress}
                    </a>
                </Typography>
                <FlatButton
                    color="secondary"
                    onClick={() => window.open("tel:" + phoneNumber)}
                >
                    Redial Customer
                </FlatButton>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        width: "100%",
                        gap: ".5em",
                        maxHeight: "14rem",
                        padding: "16px 8px",
                        marginTop: "16px",
                        overflowY: "auto",
                    }}
                >
                    {renderOptions.map((time) => (
                        <SelectedOption
                            key={time.timeSpan}
                            selectedValue={option}
                            myValue={time.timeSpan}
                            myText={time.time}
                            onClick={setOption}
                        />
                    ))}
                </div>

                <div className="flex-row fill-width" style={{padding: "8px"}}>
                    <SelectedOption
                        selectedValue={noAnswer}
                        myValue={true}
                        myText={"No Answer"}
                        color="primary"
                        onClick={() => setNoAnswer(!noAnswer)}
                    />

                    <div className="flex-grow" />

                    <FlatButton
                        hidden={option === null}
                        variant="contained"
                        color="primary"
                        onClick={submitSelection}
                    >
                        Submit
                    </FlatButton>
                </div>
            </div>
        </Dialog>
    );
}

export interface TimeOption {
    time: string;
    timeSpan: string;
}

function hmToStepCount(hour: number, minute: number) {
    return hour * 2 + (minute < 30 ? 0 : 1);
}

export function generate30MinuteTimes(
    lastMilitaryHour: number,
    lastMilitaryMinute: number,
    startingHourMinute?: number,
    showPartOfDay?: boolean
): TimeOption[] {
    const currentTime = new Date();

    let hours = currentTime.getHours();
    let mins = currentTime.getMinutes();

    if (startingHourMinute !== undefined) {
        hours = Math.floor(startingHourMinute);
        mins = (startingHourMinute - hours) * 60;
    }

    const startingHour = hours;
    const startingMinute = mins < 30 ? 0 : 30;

    // How many half hour increments make up the current time (rounded down)
    const startStepCount = hmToStepCount(startingHour, startingMinute);
    // How many half hour increments make up the target time (rounded down)
    const endingStepCount = hmToStepCount(Math.min(23, lastMilitaryHour), lastMilitaryMinute);

    // How many half hour increments between them (needs to include the end amount so 1 is added)
    const targetAmount = endingStepCount - startStepCount + 1;

    const hidePartOfDay = !(showPartOfDay ?? false);

    return [...new Array(targetAmount)].map((v, index) => {
        const totalMinutes = startingMinute + index * 30;
        const hour = startingHour + Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;

        return {
            time: formatMilitaryToHHMM12HR(hour, minute, hidePartOfDay),
            timeSpan: `PT${hour}H${minute}M`,
        };
    });
}

interface SelectedOptionProps<T> {
    selectedValue: T | null;
    myValue: T;
    myText: string;
    onClick: (v: T) => void;
    color?: "primary" | "secondary";
}

export function SelectedOption<T>({
    selectedValue,
    myValue,
    myText,
    onClick,
    color,
}: SelectedOptionProps<T>) {
    return (
        <FlatButton
            variant={selectedValue === myValue ? "contained" : "outlined"}
            color={color ?? "secondary"}
            onClick={() => onClick(myValue)}
        >
            {myText}
        </FlatButton>
    );
}
