import { IconButton } from "@material-ui/core"
import { FlatIconButtonProps } from "./flatButtonUtils"
import EditIcon from '@mui/icons-material/Edit';

export default function FlatEditButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return (
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatEditIcon color={color}/>
        </IconButton>
    )
}

export function FlatEditIcon({color="black"} : {color?: string}) {
    return <EditIcon htmlColor={color}/>
}