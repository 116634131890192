import { S3GenericFileViewerDialog } from "Components/Files/S3GenericFileViewers";
import FlatDocumentButton from "FlatComponents/Button/FlatDocumentButton";
import FlatUploadFileButton from "FlatComponents/Button/FlatUploadFileButton";
import FlatFileUploadDialog from "FlatComponents/Inputs/FlatFileUploadDialog";
import { Address, Company, ContractorProfile, Maybe, S3File, useDeleteS3FileMutation, useReportS3FileUploadStatusMutation, useSetWorkerDriversLicenseFileMutation, useSetWorkerLicenseFileMutation, Vehicle, WorkerHistory } from "generated/graphql";
import { dateTimeStrToMdy } from "Globals/DateAndTimeHelpers";
import { extractFileExtension, IMG_EXTENSIONS, PDF_EXT, uploadFileToS3 } from "Globals/FileUtils";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { formatAddress, formatEIN, formatPhoneNumber, formatVehicle } from "Globals/StringFormatting";
import { useState } from "react";
import ProfileImage from "./ProfileImage";

interface Profile {
    workerId: number;
    profilePictureFileId?: number | null;
    phone: string;
    address?: Maybe<Address> | Address;
    email: string;
    driversLicenseNumber?: Maybe<string> | string;
    driversLicenseFile?: Maybe<S3File> | S3File;
    licenseNumber?: Maybe<string> | string;
    licenseFile?: Maybe<S3File> | S3File;
    vehicle?: Maybe<Vehicle> | Vehicle;
    parentCompany?: Maybe<string> | string;
    company?: Maybe<Company> | Company;
    workerHistory?: Maybe<WorkerHistory> | WorkerHistory;
    rating?: number;
}

interface ProfileSummaryColumnProps {
    profile: Profile;
    toRefetch: string; // query to refetch on updates
    showLicenseField?: boolean; // whether the business license section should be shown
}

export function ProfileSummaryColumn({profile, toRefetch, showLicenseField=false}: ProfileSummaryColumnProps) {
    const phone = formatPhoneNumber(profile.phone);
    const address = isNotNullOrUndefined(profile.address) ? formatAddress(profile.address!) : "-";
    const email = profile.email;
    const dlNum = profile.driversLicenseNumber ?? "-";
    const dlFile = profile.driversLicenseFile;
    const licenseNum = profile.licenseNumber ?? "-";
    const licenseFile = profile.licenseFile;
    const vehicle = formatVehicle(profile.vehicle ?? undefined) ?? "-";

    return (<>
        <ProfileImage
            toRefetch={toRefetch}
            rating={profile.rating}
            workerId={profile.workerId}
            photoId={profile.profilePictureFileId ?? undefined}
            editable
        />
        <div className={"grid-30-70 flat-font fit-content-height flex-row-gap-xsm"}>
            <p>Phone</p>
            <p className="less-bold-text">{phone}</p>
            <p>Address</p>
            <p className="less-bold-text">{address}</p>
            <p>Email</p>
            <p className="less-bold-text">{email}</p>

            <p>DL #</p>
            <DriversLicenseInfo
                workerId={profile.workerId}
                num={dlNum}
                toRefetch={toRefetch}
                file={dlFile ?? undefined}    
            />

            {showLicenseField && (<>
                <p>License #</p>
                <LicenseInfo
                    toRefetch={toRefetch}
                    workerId={profile.workerId}
                    num={licenseNum}
                    file={licenseFile ?? undefined}
                />
            </>)}

            <p>Vehicle</p>
            <p className="less-bold-text">{vehicle}</p>
        </div>
    </>)
}

interface LicenseInfoProps {
    workerId: number;
    num: string;
    toRefetch: string; // query to refetch on updates
    file?: S3File;
}

// passed to the file inputs
const acceptedFileTypes = [`.${PDF_EXT}`, ...IMG_EXTENSIONS.map(e => `.${e}`)].join(",");

function DriversLicenseInfo({workerId, num, toRefetch, file}: LicenseInfoProps) {
    const [viewerOpen, setViewerOpen] = useState(false);
    const [uploaderOpen, setUploaderOpen] = useState(false);

    const [reportUploadStatus, {loading: reportingUploadStatus}] = useReportS3FileUploadStatusMutation();
    const [setDriversLicenseFile, {loading: settingDlFile}] = useSetWorkerDriversLicenseFileMutation({
        refetchQueries: [toRefetch]
    });
    function onSubmitDlFile(f: File) {
        let fileExtension: string;
        try {
            // throws an exception when there is no file extension in the name
            fileExtension = extractFileExtension(f.name);
        } catch {
            alert("File must have an extension");
            return;
        }

        setDriversLicenseFile({ variables: {workerId, fileExtension} }).then(fileData => {
            const uploadInfo = fileData.data?.setWorkerDriversLicenseFile;
            if (isNotNullOrUndefined(uploadInfo)) {
                const {id: fileId, uploadUrl} = uploadInfo!;
                uploadFileToS3(f!, uploadUrl).then(result => {
                    reportUploadStatus({variables:
                        {fileId: fileId, succeeded: result.ok}
                    }).then(_ => setUploaderOpen(false));

                    if (!result.ok) {
                        alert("Failed to upload file");
                    }
                }).catch(_ => {
                    reportUploadStatus({variables:
                        {fileId: fileId, succeeded: false}
                    });

                    alert("Failed to upload file");
                });
            }
        });
    }

    const [deleteFile, {loading: deleting}] = useDeleteS3FileMutation({
        variables: {fileId: file?.id ?? -1},
        onCompleted: (res) => {
            if (res.deleteS3File) {
                setViewerOpen(false);
            } else {
                alert("Failed to delete file")
            }
        },
        onError: () => alert("Failed to delete file"),
        refetchQueries: [toRefetch]
    });
    
    return (<>
        <span className="flex-row flex-space-between">
            <p className="less-bold-text">{num}</p>
            <span className="flex-row flex-gap-xsm">
                {file && (
                    <FlatDocumentButton
                        color={"var(--flat-green)"}
                        onClick={() => setViewerOpen(true)}
                    />
                )}
                <FlatUploadFileButton
                    color={"var(--flat-red)"}
                    onClick={() => setUploaderOpen(true)}    
                />
            </span>
        </span>

        <FlatFileUploadDialog
            open={uploaderOpen}
            onClose={() => setUploaderOpen(false)}
            onSubmit={onSubmitDlFile}
            accept={acceptedFileTypes}
            header="Drivers License"
            disableSubmitButton={settingDlFile || reportingUploadStatus}
        />

        {file && (
            <S3GenericFileViewerDialog
                file={file}
                open={viewerOpen}
                onClose={() => setViewerOpen(false)}
                header="Driver's License"
                onDelete={deleteFile}
                disableDeleteButton={deleting}
            />
        )}
    </>)
}

function LicenseInfo({workerId, num, toRefetch, file}: LicenseInfoProps) {
    const [viewerOpen, setViewerOpen] = useState(false);
    const [uploaderOpen, setUploaderOpen] = useState(false);

    const [reportUploadStatus, {loading: reportingUploadStatus}] = useReportS3FileUploadStatusMutation();
    const [setLicenseFile, {loading: settingLicenseFile}] = useSetWorkerLicenseFileMutation({
        refetchQueries: [toRefetch]
    });
    function onSubmitLicenseFile(file: File) {
        let fileExtension: string;
        try {
            // throws an exception when there is no file extension in the name
            fileExtension = extractFileExtension(file.name);
        } catch {
            alert("File must have an extension");
            return;
        }

        setLicenseFile({ variables: {workerId, fileExtension} })
        .then(fileData => {
            const uploadInfo = fileData.data?.setWorkerLicenseFile;
            if (isNotNullOrUndefined(uploadInfo)) {
                const {id: fileId, uploadUrl} = uploadInfo!;
                uploadFileToS3(file!, uploadUrl).then(result => {
                    reportUploadStatus({variables:
                        {fileId: fileId, succeeded: result.ok}
                    }).then(_ => setUploaderOpen(false));

                    if (!result.ok) {
                        alert("Failed to upload file");
                    }
                }).catch(_ => {
                    reportUploadStatus({variables:
                        {fileId: fileId, succeeded: false}
                    });

                    alert("Failed to upload file");
                });
            }
        });
    }

    const [deleteFile, {loading: deleting}] = useDeleteS3FileMutation({
        variables: {fileId: file?.id ?? -1},
        onCompleted: (res) => {
            if (res.deleteS3File) {
                setViewerOpen(false);
            } else {
                alert("Failed to delete file")
            }
        },
        onError: () => alert("Failed to delete file"),
        refetchQueries: [toRefetch]
    });

    return (<>
        <span className="flex-row flex-space-between">
            <p className="less-bold-text">{num}</p>
            <span className="flex-row flex-gap-xsm">
                {file && (
                    <FlatDocumentButton
                        color={"var(--flat-green)"}
                        onClick={() => setViewerOpen(true)}
                    />
                )}
                <FlatUploadFileButton
                    color={"var(--flat-red)"}
                    onClick={() => setUploaderOpen(true)}    
                />
            </span>
        </span>

        <FlatFileUploadDialog
            open={uploaderOpen}
            onClose={() => setUploaderOpen(false)}
            onSubmit={onSubmitLicenseFile}
            accept={acceptedFileTypes}
            header="License"
            disableSubmitButton={settingLicenseFile || reportingUploadStatus}
        />

        {file && (
            <S3GenericFileViewerDialog
                file={file}
                open={viewerOpen}
                onClose={() => setViewerOpen(false)}
                header="License"
                onDelete={deleteFile}
                disableDeleteButton={deleting}
            />
        )}
    </>)
}


export function BubbleSummaryColumn({contractor}: {contractor: ContractorProfile}) {
    const startDate = ((contractor.workerHistory?.dates ?? []).length > 0) ? dateTimeStrToMdy(contractor.workerHistory!.dates[0].startDate) : "-";

    return (
        <div className="grid-50-50">
            <p>Cell</p>
            <p className="less-bold-text">{formatPhoneNumber(contractor.phone)}</p>
            <p>City</p>
            <p className="less-bold-text">{contractor.address?.city ?? "-"}</p>
            <p>Vehicle</p>
            <p className="less-bold-text">{formatVehicle(contractor.vehicle ?? undefined) ?? "-"}</p>
            <p>Start Date</p>
            <p className="less-bold-text">{startDate}</p>
        </div>
    )
}

export function ProfileCompanyInfoColumn({profile}: {profile: Profile}) {
    const parentCompany = profile.parentCompany ?? "-";
    const companyName = profile.company?.name ?? "-";
    const ein = formatEIN(profile.company?.eIN) ?? "-";
    const companyAddress = isNotNullOrUndefined(profile.company?.address) ? formatAddress(profile.company!.address) : "-";
    const startDate = ((profile.workerHistory?.dates ?? []).length > 0) ? dateTimeStrToMdy(profile.workerHistory!.dates[0].startDate) : "-";

    return (
        <div className="grid-30-70 flat-font fit-content-height flex-row-gap-xsm">
            <p>Parent Company</p>
            <p className="less-bold-text">{parentCompany}</p>
            <p>Company</p>
            <p className="less-bold-text">{companyName}</p>
            <p>EIN</p>
            <p className="less-bold-text">{ein}</p>
            <p>Company Address</p>
            <p className="less-bold-text">{companyAddress}</p>
            <p style={{visibility: "hidden"}}>Taking up row space</p>
            <p style={{visibility: "hidden"}}>Taking up row space</p>
            <p>Starting Date</p>
            <p className="less-bold-text">{startDate}</p>
            <p style={{visibility: "hidden"}}>Taking up row space</p>
            <p style={{visibility: "hidden"}}>Taking up row space</p>
        </div>
    )
}