import { FlatLabeledCheckbox } from "FlatComponents/Inputs/FlatCheckbox";
import { AreaAndZips } from "./WorkerServiceAreaEditor";

interface WorkerServiceAreaSelectorProps {
    servicedZips: string[];
    setServicedZips: (newZips: string[]) => void;
    areas: AreaAndZips[];
}

export default function WorkerServiceAreaSelector({servicedZips, setServicedZips, areas}: WorkerServiceAreaSelectorProps) {
    return (
        <div className="flex-row-wrap flex-gap-sm" style={{height: "fit-content"}}>
            {areas.map(a => (
                <AreaSelectorCheckbox
                    key={`market-selector-${a.areaName}`}
                    servicedZips={servicedZips}
                    setServicedZips={setServicedZips}
                    area={a}/>
                )
            )}
        </div>
    );
}

interface AreaSelectorCheckboxProps extends Omit<WorkerServiceAreaSelectorProps, "areas"> {
    area: AreaAndZips;
}

function AreaSelectorCheckbox({servicedZips, setServicedZips, area}: AreaSelectorCheckboxProps) {
    const numAreaZipsInServiced = area.zipsInArea.filter(areaZip => servicedZips.includes(areaZip)).length;
    const status: "unchecked" | "indeterminate" | "checked" =
        (numAreaZipsInServiced === area.zipsInArea.length) ? "checked" 
            : (numAreaZipsInServiced > 0) ? "indeterminate" : "unchecked";

    function onClick() {
        if (status === "checked") {
            // remove all zips in this area from the list
            const newZips = servicedZips.filter(z => !area.zipsInArea.includes(z));
            setServicedZips(newZips);
        } else {
            // ensure all zips in the area are in the list of all zips
            // gets the unique zip values (if some are already selected, this prevents duplicates)
            const newZips = [...new Set([...servicedZips, ...area.zipsInArea])];
            setServicedZips(newZips);
        }
    }

    return (
        <FlatLabeledCheckbox
            label={area.areaName}
            checked={status === "checked"}
            indeterminate={status === "indeterminate"}
            onClick={onClick}
            justification={"left"}
            containerProps={{
                className: "flex-gap-xsm w-15r align-items-flex-start",
                style: {alignItems: "flex-start", height: "fit-content"}
            }}
            labelLocation={"right"}
        />
    )
}