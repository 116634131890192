import { Typography } from "@material-ui/core";
import {
    CustomerAppointmentSearchResult,
    GetCustomerAppointmentSearchResultsQuery,
    useGetCustomerAppointmentSearchResultsLazyQuery,
} from "generated/graphql";
import { dateTimeStrToDate, dateTimeStrToMdy, dateToYmd } from "Globals/DateAndTimeHelpers";
import { isNotEmptyString } from "Globals/GenericValidators";
import { formatPhoneNumber, formatNameStringLastFirst } from "Globals/StringFormatting";
import { useState } from "react";
import SearchBarInput from "./SearchBarInput";

interface CustomerAppointmentSearchBarProps {
    setDate: (dateYmd: string) => void;
    setMarketId: (marketId: number) => void;
    setHighlightedAppointmentId: (id: number | null) => void;
}

export default function CustomerAppointmentSearchBar({
    setDate,
    setMarketId,
    setHighlightedAppointmentId,
}: CustomerAppointmentSearchBarProps) {
    const [searchStr, setSearchStr] = useState("");
    const [searchResultsData, setSearchResultsData] = useState<
        GetCustomerAppointmentSearchResultsQuery["customerAppointmentSearchResults"] | null
    >(null);

    const [search, { loading }] = useGetCustomerAppointmentSearchResultsLazyQuery({
        onError: () => alert("Could not perform customer appointment search"),
        fetchPolicy: "no-cache",
        onCompleted: (data) => setSearchResultsData(data.customerAppointmentSearchResults),
    });

    function onSearch() {
        const trimmedSearch = searchStr.trim();
        if (isNotEmptyString(trimmedSearch)) {
            search({ variables: { searchStr: trimmedSearch } });
        }
    }

    function onClear() {
        setHighlightedAppointmentId(null);
        setSearchResultsData(null);
    }

    const searchResults =
        searchResultsData?.map((result) => (
            <CustomerAppointmentSearchResultTab
                apptResult={result}
                setDate={setDate}
                setMarketId={setMarketId}
                setHighlightedAppointmentId={setHighlightedAppointmentId}
                key={result.id}
            />
        )) ?? null;

    return (
        <div
            className="flex-grow flex-row make-input-white"
            style={{ justifyContent: "flex-end"}}
        >
            <SearchBarInput
                searchStr={searchStr}
                setSearchStr={setSearchStr}
                onSearch={onSearch}
                loadingResults={loading}
                searchResults={searchResults}
                clearSearchResults={onClear}
            />
        </div>
    );
}

interface CustomerAppointmentSearchResultTabProps extends CustomerAppointmentSearchBarProps {
    apptResult: CustomerAppointmentSearchResult;
}

function CustomerAppointmentSearchResultTab({
    apptResult,
    setDate,
    setMarketId,
    setHighlightedAppointmentId,
}: CustomerAppointmentSearchResultTabProps) {
    const fName = apptResult.firstName;
    const lName = apptResult.lastName;
    const phoneNum = apptResult.phoneNumber ? formatPhoneNumber(apptResult.phoneNumber) : null;
    const city = apptResult.city;
    const apptNum = apptResult.appointmentNumber;
    const apptDate = apptResult.appointmentDate;
    const apptId = apptResult.id;
    const marketId = apptResult.marketId;

    function onClick() {
        setDate(dateToYmd(dateTimeStrToDate(apptDate)));
        setMarketId(marketId);
        setHighlightedAppointmentId(apptId);
    }

    return (
        <div onClick={onClick}>
            <Typography style={{fontWeight: "bold"}}>{formatNameStringLastFirst({firstName: fName, lastName: lName ?? undefined})}</Typography>
            {phoneNum && <Typography>{phoneNum}</Typography>}
            <Typography>{city}</Typography>
            <Typography>
                A-{apptNum} - {dateTimeStrToMdy(apptDate)}
            </Typography>
        </div>
    );
}
