import { ButtonProps, IconButton, IconButtonProps } from "@material-ui/core";
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import { useRef } from "react";
import SpacedButton from "./SpacedButton";

interface FileUploadButtonProps extends Omit<ButtonProps, "onClick">, BaseFileUploadButton {}

interface BaseFileUploadButton {
    onUploadEvent: React.ChangeEventHandler<HTMLInputElement>
    preventUpload?: () => boolean
    accept?: React.InputHTMLAttributes<HTMLInputElement>["accept"]
}

export default function FileUploadButton({ onUploadEvent, preventUpload, accept, ...props }: FileUploadButtonProps) {
    const inputRef = useRef<HTMLInputElement>(null)

    return (
        <>
            <input ref={inputRef} hidden multiple={false} type="file" onChange={onUploadEvent} accept={accept} />
            <SpacedButton onClick={(e) => {
                if (preventUpload?.()) return;
                inputRef.current?.click();
            }} {...props} />
        </>
    )
}

interface FileUploadImageButtonProps extends Omit<IconButtonProps, "onClick">, BaseFileUploadButton {

}

export function FileUploadImageButton({ onUploadEvent, preventUpload, accept, children, ...props }: FileUploadImageButtonProps) {
    const inputRef = useRef<HTMLInputElement>(null)

    return (
        <>
            <input ref={inputRef} hidden multiple={false} type="file" onChange={onUploadEvent} accept={accept} />
            <IconButton
                onClick={(e) => {
                    if (preventUpload?.()) return;
                    inputRef.current?.click();
                }}
                {...props} >
                <div className="flex-column" style={{alignItems:"center", fontSize:"inherit"}}>
                    <InsertPhotoIcon fontSize="inherit" style={{fontSize:"2em"}}/>
                    {children}
                </div>
            </IconButton>
        </>
    )
}