import { AddressInput, BackgroundCheckInput, CompanyInput, ContractorHelperInput, ContractorInstallationCapabilityInput, ContractorProfileInput, SalespersonProfileInput, VehicleInput, WorkerHistoryDatesInput } from "generated/graphql";
import { WorkerDocumentInput } from "./dataPreparationUtils";
import { dayStrictlyBefore, isoToDay, todayAsDay } from "./DateAndTimeHelpers";
import { formatRawPhoneNumber } from "./StringFormatting";

export function isNotEmptyString(value: string) {
    return value !== ""
}

export function isEmptyString(value: string) {
    return value === ""
}

export function isAllLetters(value: string) {
    return !/[^a-zA-Z]/.test(value);
}

export function containsAtLeastOneLetter(value: string) {
    return value.split('').some(char => isAllLetters(char));
}

export function isValidName(name: string) {
    if (isNullOrUndefined(name)) {
        return false;
    } else {
        // it is valid for a name to contain "." or "'"
        const nameWithoutSpacesOrPunctuation = name.split(' ').join('').split("'").join('').split(".").join('');
        return isNotEmptyString(nameWithoutSpacesOrPunctuation) && isAllLetters(nameWithoutSpacesOrPunctuation) && containsAtLeastOneLetter(nameWithoutSpacesOrPunctuation);
    }
}

export function isNumber(value: string) {
    return isNotEmptyString(value) && !isNaN(+value)
}

export function zipIsValid(zip: string, showAlerts=false): boolean  {
    if (isNullOrUndefined(zip) || isEmptyString(zip)) {
        if (showAlerts) { alert("Enter a zip code"); }
        return false;
    }

    if (!isNumber(zip)) {
        if (showAlerts) { alert("Zip code must only contain numeric charcters"); }
        return false;
    }

    if (zip.length !== 5) {
        if (showAlerts) { alert("Zip code must consist of 5 numbers"); }
        return false;
    }

    return true;
}

export function isNumberOrUndefined(value: string) {
    return isEmptyString(value) || !isNaN(+value)
}

export function isNumberWithNoTrailingDecimal(value: string) {
    return isNumber(value) && value.slice(-1) !== "."
}

export function isPositiveNumber(value: string) {
    return isNumber(value) && +value >= 0
}

export function allTrue(values: boolean[]) {
    return values.every(v => v === true)
}

export function allFalse(values: boolean[]) {
    return values.every(v => v === false)
}

export function isNotNullOrUndefined(value: any) {
    return value !== null && value !== undefined
}

export function isNullOrUndefined(value: any) {
    return value === undefined || value === null
}

// Email format {Recipient Name}@{Domain Name (optional subdomains)}.{Top Level domain}
// Does not fully verify that email is correct. Merely that is matches the rough pattern of an email
// Ignores character requirements as well as the possiblity of subdomains (ie more than one period in the domain name)
export function isEmail(value: string) {
    const split = value.split("@")
    if (split.length <= 1 // Verifies it has an @ sign and is not empty string
        || isEmptyString(split[0])) // Ensures Recipient Name is not empty
        return false

    const subSplit = split[1].split(".")
    if (subSplit.length <= 1 // Either missing the Domain name or top level name
        || isEmptyString(subSplit[0]) // Ensures Domain Name is not empty 
        || isEmptyString(subSplit[1])) // Top level domain not empty
        return false

    return true
}

const PHONE_NUMBER_LENGTH = 10;
export function isPhoneNumber(value: string) {
    const strippedNum = formatRawPhoneNumber(value);
    return (strippedNum.length === PHONE_NUMBER_LENGTH);
}

/**
 * The address should first be prepared with prepareAddress() before being passed here.
 */
export function isAddressValid(address: AddressInput, showAlerts=false) {    
    if (isNullOrUndefined(address.streetAddress) || isEmptyString(address.streetAddress)) {
        if (showAlerts) { alert("Enter a street address"); }
        return false;
    }

    if (isEmptyString(address.city)) {
        if (showAlerts) { alert("Enter a city"); }
        return false;
    }

    if (!zipIsValid(address.zip, showAlerts)) {
        return false;
    }

    return true;
}

/**
 * The vehicle should first be prepared with prepareVehicle() before being passed here.
 */
export function isVehicleValid(vehicle: VehicleInput, showAlerts=false) {
    if (isEmptyString(vehicle.description)) {
        if (showAlerts) { alert("Enter vehicle description") }
        return false;
    }

    if (isNotEmptyString(vehicle.licensePlateNumber ?? "") && vehicle.licensePlateNumber!.length > 8) {
        if (showAlerts) { alert("License plate number is too long - must not exceed 8 characters") }
        return false;
    }

    return true;
}

/**
 * The company should first be prepared with prepareCompany() before being passed here.
 */
export function isCompanyValid(company: CompanyInput, showAlerts=false) {
    if (isEmptyString(company.name)) {
        if (showAlerts) { alert("Enter company name"); }
        return false;
    }
    
    if (isEmptyString(company.eIN)) {
        if (showAlerts) { alert("Enter company EIN"); }
        return false;
    } else if (company.eIN.length !== 9) {
        if (showAlerts) { alert("Company EIN must be exactly 9 digits"); }
        return false;
    }

    if (!isAddressValid(company.address, false)) {
        if (showAlerts) { alert("Company address is invalid"); }
        return false;
    }

    return true;
}

/**
 * The profile should first be prepared with prepareContractorProfile() before being passed here.
 */
export function isContractorProfileValid(contractor: ContractorProfileInput, showAlerts=false) {
    if (isEmptyString(contractor.firstName) || isEmptyString(contractor.lastName)) {
        if (showAlerts) { alert("Enter contractor name"); }
        return false;    
    }

    if (!isPhoneNumber(contractor.phone)) {
        if (showAlerts) { alert("Phone number contains invalid data"); }
        return false;
    }
    
    if (!isEmail(contractor.email)) {
        if (showAlerts) { alert("Email contains invalid data"); }
        return false;
    }

    if (isNotNullOrUndefined(contractor.address) && !isAddressValid(contractor.address!, showAlerts)) {
        return false;
    }

    if (isNotNullOrUndefined(contractor.vehicle) && !isVehicleValid(contractor.vehicle!, showAlerts)) {
        return false;
    }

    if (isNotNullOrUndefined(contractor.company) && !isCompanyValid(contractor.company!, showAlerts)) {
        return false;
    }

    return true;
}

/**
 * The profile should first be prepared with prepareSalespersonProfile() before being passed here.
 */
export function isSalespersonProfileValid(salesperson: SalespersonProfileInput, showAlerts=false) {
    if (isEmptyString(salesperson.firstName) || isEmptyString(salesperson.lastName)) {
        if (showAlerts) { alert("Enter salesperson name"); }
        return false;    
    }

    if (!isPhoneNumber(salesperson.phone)) {
        if (showAlerts) { alert("Phone number contains invalid data"); }
        return false;
    }
    
    if (!isEmail(salesperson.email)) {
        if (showAlerts) { alert("Email contains invalid data"); }
        return false;
    }

    if (isNotNullOrUndefined(salesperson.address) && !isAddressValid(salesperson.address!, showAlerts)) {
        return false;
    }

    if (isNotNullOrUndefined(salesperson.vehicle) && !isVehicleValid(salesperson.vehicle!, showAlerts)) {
        return false;
    }

    if (isNotNullOrUndefined(salesperson.company) && !isCompanyValid(salesperson.company!, showAlerts)) {
        return false;
    }

    return true;
}

export function areWorkerHistoryDatesValid(dates: WorkerHistoryDatesInput[], showAlerts=false) {
    if (dates.length === 0) {
        if (showAlerts) { alert("Must specify at least one date range"); }
        return false;
    }
    
    if (dates.filter(dates => isNullOrUndefined(dates.endDate)).length > 1) {
        if (showAlerts) { alert("Only one date range may not have an end date"); }
        return false;
    }

    if (dates.some(dates => {
        if (dates.endDate) {
            return !dayStrictlyBefore(isoToDay(dates.startDate), isoToDay(dates.endDate))
        } else {
            return false;
        }
    })) {
        if (showAlerts) { alert("Start date must come before end date"); }
        return false;
    }

    return true;
}

/**
 * The helper should first be prepared with prepareContractorHelper() before being passed here.
 */
export function isContractorHelperValid(helper: ContractorHelperInput, showAlerts=false) {
    if (isEmptyString(helper.firstName) || isEmptyString(helper.lastName)) {
        if (showAlerts) { alert("Enter helper name"); }
        return false;    
    }
    
    if (!isPhoneNumber(helper.phone)) {
        if (showAlerts) { alert("Phone number contains invalid data"); }
        return false;
    }
    
    return (areWorkerHistoryDatesValid(helper.history, showAlerts));
}

/**
 * The background check should first be prepared with prepareBackgroundCheck() before being passed here.
 */
export function isBackgroundCheckValid(backgroundCheck: BackgroundCheckInput, showAlerts=false) {
    if (!dayStrictlyBefore(isoToDay(backgroundCheck.datePerformed), isoToDay(backgroundCheck.expirationDate))) {
        if (showAlerts) { alert("Expiration date must be after the date the background check was performed") }
        return false;
    }

    return true;
}

export function isInstallationCapabilityValid(capability: ContractorInstallationCapabilityInput, showAlerts=false) {
    if (capability.sqftPotential <= 0) {
        if (showAlerts) { alert("Enter a daily capacity for the skill"); }
        return false;
    }

    return true;
}

/**
 * The document check should first be prepared with prepareWorkerDocument() before being passed here.
 */
export function isWorkerDocumentValid(document: WorkerDocumentInput, showAlerts=false) {
    if (isNullOrUndefined(document.activeDate) || isNullOrUndefined(document.expirationDate)) {
        if (showAlerts) { alert("Ensure the active and expiration dates are both filled out"); }
        return false;
    }

    if (!dayStrictlyBefore(isoToDay(document.activeDate), isoToDay(document.expirationDate))) {
        if (showAlerts) { alert("Expiration date must come after active date"); }
        return false;
    }

    return true;
}

export function isDocumentOverdue(documentIsoExpirationDate: string) {
    return dayStrictlyBefore(isoToDay(documentIsoExpirationDate), todayAsDay())
}