import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"
import { Button, Checkbox, Dialog, DialogActions, DialogContent } from "@material-ui/core"
import FlatButton from "FlatComponents/Button/FlatButton"
import FlatInput from "FlatComponents/Inputs/FlatInput"
import { Customer, CustomerContact } from "generated/graphql"
import { emptyAddress, emptyContact } from "Globals/DataStructures/EmptyDataStructures"
import { isNotNullOrUndefined } from "Globals/GenericValidators"
import { formatPhoneNumber, formatRawPhoneNumber } from "Globals/StringFormatting"
import { useState } from "react"


interface UpdateCustomerDetailDialogProps {
    open: boolean,
    defaultCustomer: Customer,
    onCancel: () => void,
    onSubmit: (customer: Customer) => void,
    canChangePrimary?: boolean
}

export default function UpdateCustomerDetailDialog({ open, defaultCustomer, onCancel, onSubmit, canChangePrimary }: UpdateCustomerDetailDialogProps) {
    const [customer, setCustomer] = useState(defaultCustomer)

    return (
        <Dialog open={open} onClose={onCancel} maxWidth="xl">
            <DialogContent>
                <div className="flex-column" style={{ width: "min(55rem, 100vw)", margin: "1rem", alignItems: "flex-start" }}>
                    <CustomerDialogView primaryIsEditable={canChangePrimary ?? false} customer={customer} setCustomer={setCustomer} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={onCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={() => onSubmit(customer)}>Update Customer</Button>
            </DialogActions>
        </Dialog>
    )
}

interface CustomerDialogViewProps {
    primaryIsEditable: boolean;
    customer: Customer;
    setCustomer: (customer: Customer) => void;
    howDidYouHearAboutUsOption?: string;
}

export function CustomerDialogView({ primaryIsEditable, customer, setCustomer, howDidYouHearAboutUsOption }: CustomerDialogViewProps) {
    function updateContact(contact: Omit<CustomerContact, "isPrimary">, index: number) {
        const updatedContactList = [...customer.customerContacts]
        updatedContactList[index] = { ...updatedContactList[index], ...contact }
        setCustomer({ ...customer, customerContacts: updatedContactList })
    }

    function setPrimary(primaryIndex: number) {
        const updatedContactList = customer.customerContacts.map((contact, index) => ({ ...contact, isPrimary: index === primaryIndex }))
        setCustomer({ ...customer, customerContacts: updatedContactList })
    }

    return (
        <div style={{padding: "var(--flat-outer-container-padding) var(--flat-outer-container-radius)", marginTop: "16px"}}>
            <table>
                <thead>
                    <tr>
                        <th style={{ padding: "0 1rem" }}>{primaryIsEditable ? "Primary" : ""}</th>
                        <th className="flat-label-base flat-label-primary-sm">First Name</th>
                        <th className="flat-label-base flat-label-primary-sm">Last Name</th>
                        <th className="flat-label-base flat-label-primary-sm w-8r">Phone #</th>
                        <th className="flat-label-base flat-label-primary-sm">Email Address</th>
                        <th className="flat-label-base flat-label-bold w-12r whitespace-no-wrap">Scheduling Contact</th>
                    </tr>
                </thead>
                <tbody>
                    {customer.customerContacts.map((contact, index) => (
                        <ContactRow
                            key={`customer-contact-${index}`}
                            contact={contact}
                            updateContact={(updated) => updateContact(updated, index)}
                            canSetPrimary={primaryIsEditable}
                            setPrimary={() => setPrimary(index)} 
                            onAddContact={(index === (customer.customerContacts.length - 1)) ?
                                () => {
                                    const newIdx = Math.min(...customer.customerContacts.map(c => c.id - 1), -1);
                                    setCustomer({
                                        ...customer,
                                        customerContacts: [
                                            ...customer.customerContacts, 
                                            {...emptyContact, id: newIdx, lastName: customer.customerContacts[0].lastName}
                                        ]
                                    })
                                } : undefined
                            }
                            onRemoveContact={() => setCustomer({
                                ...customer,
                                customerContacts: customer.customerContacts.filter(c => c.id !== contact.id)})
                            }
                        />
                    ))}
                </tbody>
            </table>
            <div className="flex-row flex-gap-lg margin-top-sm">
                <div>
                    <table className="flat-table col-spacing-10px">
                        <thead>
                            <tr>
                                <th className="flat-label-base flat-label-primary-sm">Address</th>
                                <th className="flat-label-base flat-label-primary-sm">City</th>
                                <th className="flat-label-base flat-label-primary-sm">Zip</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <FlatInput
                                        value={customer.primaryAddress.streetAddress}
                                        onChange={e => 
                                            setCustomer({
                                                ...customer,
                                                primaryAddress: {...customer.primaryAddress, streetAddress: e.target.value}
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <FlatInput
                                        value={customer.primaryAddress.city}
                                        onChange={e =>
                                            setCustomer({
                                                ...customer,
                                                primaryAddress: {...customer.primaryAddress, city: e.target.value}
                                            })
                                        } 
                                    />
                                </td>
                                <td>
                                    <FlatInput
                                        value={customer.primaryAddress.zip}
                                        onChange={e => 
                                            setCustomer({
                                                ...customer,
                                                primaryAddress: {...customer.primaryAddress, zip: e.target.value}
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{paddingTop: "10px"}}>
                                    <FlatInput
                                        placeholder="Billing Address"
                                        value={customer.billingAddress?.streetAddress ?? ""}
                                        onChange={e =>
                                            setCustomer({
                                                ...customer,
                                                billingAddress: customer.billingAddress 
                                                    ? {...customer.billingAddress, streetAddress: e.target.value} 
                                                    : {...emptyAddress, streetAddress: e.target.value} 
                                            })
                                        }
                                    />
                                </td>
                                <td style={{paddingTop: "10px"}}>
                                    <FlatInput
                                        placeholder="Billing City"
                                        value={customer.billingAddress?.city ?? ""}
                                        onChange={e =>
                                            setCustomer({
                                                ...customer,
                                                billingAddress: customer.billingAddress
                                                    ? {...customer.billingAddress, city: e.target.value}
                                                    : {...emptyAddress, city: e.target.value}
                                            })
                                        } 
                                    />
                                </td>
                                <td style={{paddingTop: "10px"}}>
                                    <FlatInput
                                        placeholder="Billing Zip"
                                        value={customer.billingAddress?.zip ?? ""}
                                        onChange={e =>
                                            setCustomer({
                                                ...customer,
                                                billingAddress: customer.billingAddress
                                                    ? {...customer.billingAddress, zip: e.target.value}
                                                    : {...emptyAddress, zip: e.target.value}
                                            })
                                        } 
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {isNotNullOrUndefined(howDidYouHearAboutUsOption) && (
                    <div className="flex-column" style={{marginTop: "12pt"}}>
                        <p className="flat-label-base flat-label-primary-sm margin-none">How Did You Hear About Us?</p>
                        <p className="flat-label-base flat-label-bold margin-none" style={{textTransform: "none", fontSize: "14pt"}}>{howDidYouHearAboutUsOption}</p>
                    </div>
                )}
            </div>
        </div>
    )
}


interface ContactRowProps {
    contact: CustomerContact,
    updateContact: (updated: Omit<CustomerContact, "isPrimary">) => void,
    canSetPrimary: boolean,
    setPrimary: () => void,
    onAddContact?: () => void;
    onRemoveContact: () => void;
}

export function ContactRow({ contact, updateContact, canSetPrimary, setPrimary, onAddContact, onRemoveContact }: ContactRowProps) {
    const showAddContact = isNotNullOrUndefined(onAddContact);

    const firstColumnElements: ReactJSXElement[] = [];
    if (showAddContact) {
        firstColumnElements.push((
            <FlatButton
                style={{color: "var(--flat-gray-4)", fontFamily: "var(--theme-main-text-font-family)"}}
                className="whitespace-no-wrap flat-font-sm"
                variant="text"
                onClick={onAddContact}
            >ADD</FlatButton>
        ))
    }
    if (contact.isPrimary) {
        if (canSetPrimary) {
            firstColumnElements.push((
                <Checkbox
                    checked={contact.isPrimary}
                    onChange={() => setPrimary()}
                />
            ))
        } else {
            firstColumnElements.push((
                <p
                    style={{padding: "0 8px"}}
                    className="flat-label-base flat-label-bold margin-none"    
                >Primary</p>
            ))
        }
    } else {
        // can only remove secondary contacts
        firstColumnElements.push((
            <FlatButton
                style={{color: "var(--flat-gray-4)", fontFamily: "var(--theme-main-text-font-family)"}}
                className="whitespace-no-wrap flat-font-sm"
                variant="text"
                onClick={onRemoveContact}
            >Remove</FlatButton>
        ))
    }

    return (
        <tr>
            <td style={{ padding: "0 1rem" }}>
                <span className="flex-row justify-content-flex-end align-items-baseline">
                    {firstColumnElements}
                </span>
            </td>

            <td style={{ padding: "0 .25rem" }}>
                <FlatInput
                    style={{width: "8rem"}}
                    value={contact.firstName}
                    onChange={e => updateContact({ ...contact, firstName: e.target.value })}
                />
            </td>
            <td style={{ padding: "0 .25rem" }}>
                <FlatInput
                    style={{width: "8rem"}}
                    value={contact.lastName ?? ""}
                    onChange={e => updateContact({ ...contact, lastName: e.target.value })}    
                />
            </td>
            <td style={{ padding: "0 .25rem" }}>
                <FlatInput
                    style={{width: "8rem"}}
                    value={formatPhoneNumber(contact.phone ?? "")}
                    onChange={e => updateContact({ ...contact, phone: formatRawPhoneNumber(e.target.value) })}
                />
            </td>
            <td style={{ padding: "0 .25rem" }}>
                <FlatInput
                    style={{width: "12rem"}}
                    value={contact.email ?? ""}
                    onChange={e => updateContact({ ...contact, email: e.target.value })}
                />
            </td>
            <td style={{ textAlign: "center" }}>
                <Checkbox
                    checked={contact.isSchedulingContact}
                    onChange={(e, c) => updateContact({ ...contact, isSchedulingContact: c })}
                    style={{color: "black"}}
                />
            </td>
        </tr>
    )
}