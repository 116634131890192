import { Calendar, Day, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import clsx from "clsx";
import { daysEq, dayToIso } from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { useEffect, useMemo } from "react";
import AvailableTimeSlots from "../AvailableTimeSlots";

interface TimeSlotCalendarProps {
    zip: string;
    zipIsServiced: boolean;
    day: DayValue;
    setDay: (newDay: DayValue) => void;
    timeSlotId: number | null;
    setTimeSlotId: (id: number | null) => void;
    landscape?: boolean;
    onSubmit?: () => void;
    submitting?: boolean;
}

export default function TimeSlotCalendar({zip, zipIsServiced, day, setDay, timeSlotId, setTimeSlotId, landscape=false, onSubmit, submitting=false}: TimeSlotCalendarProps) {
    const today = utils('en').getToday();
    const disabled = !zipIsServiced || zip.length !== 5;

    const timeSlotDivContents = useMemo(() => {
        if (zip.length !== 5) return <Typography className="error-text">Enter a valid zip code to select a date and time</Typography>
        else if (!zipIsServiced && zip.length === 5) return <Typography className="error-text">We do not currently service {zip}</Typography>;
        else if (!day) return <Typography className="error-text">Must select a day before selecting a time slot</Typography>;
        else return (
            <AvailableTimeSlots
                selectedSlotId={timeSlotId} onSelectSlot={setTimeSlotId}
                dayISO={dayToIso(day)}
                zip={zip}
                fullWidth={landscape}
            />        
        )
    }, [day, zip, timeSlotId, setTimeSlotId, zipIsServiced, landscape]);

    useEffect(() => {
        if (zip.length !== 5) {
            setDay(null);
            setTimeSlotId(null);
        } else if (isNullOrUndefined(day)) {
            setTimeSlotId(null);
        }
    }, [zip, day, setDay, setTimeSlotId]);

    function onChangeDay(newDay: Day) {
        if (disabled) {
            alert("Must enter a valid and serviced zip before selecting a date")
        } else {
            if (day && daysEq(day as Day, newDay)) { setDay(null); }
            else setDay(newDay);
            setTimeSlotId(null);
        }
    }

    return (
        <div className={landscape ? "flex-row" : "flex-column align-items-center"} >
            <div
                className={clsx(landscape ? "half-width padding-vertical-sm" : "padding-sm")}  
            >
                <Calendar value={day} onChange={onChangeDay} minimumDate={today} />
            </div>

            <div className={clsx(disabled && "disabled", landscape ? "half-width justify-content-center padding-vertical-sm" : "padding-sm", "flex-column align-items-center")} >
                {timeSlotDivContents}
                {(onSubmit && !submitting) && <Button variant="contained" className="margin-top-md fit-content" onClick={onSubmit}>Finish</Button>}
                {submitting && <CircularProgress  className="margin-top-md"/>}
            </div>
        </div>
    )
}