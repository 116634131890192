import { Button, Menu, MenuItem } from "@material-ui/core";
import { Area, useGetProductTypeOptionsQuery } from "generated/graphql";
import { LAMINATE_PRODUCT_ID, VINYL_PRODUCT_ID } from "Globals/globalConstants";
import { padArray } from "Globals/Helpers";
import { useState } from "react";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { FullEditInstallationServicesMenu } from "../InstallationDetailsEditor/InstallationDetailsButton";
import QuoteCardIconButton from "./QuoteCardIconButton";


export type AreaLabel = ({ areaId: number, productTypeId?: number, productType?: string, label: string } | undefined)

interface RenderAreaLabelProps {
    area: AreaLabel;
    updatePid: (newPid: number, name: string) => void;
    disableChanges: boolean;
    someAreaHasHardSurface: boolean;
}

export default function RenderAreaLabel({
    area,
    updatePid,
    disableChanges,
    someAreaHasHardSurface
}: RenderAreaLabelProps) {
    const { data: productOptionsData } = useGetProductTypeOptionsQuery({ fetchPolicy: "cache-first" })
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    if (area === undefined) return <div className="ss-render-area-label-row" />

    const { productType, label, productTypeId, areaId } = area

    const typeOps = productOptionsData?.options.filter(opt => (opt.id !== LAMINATE_PRODUCT_ID) && (opt.id !== VINYL_PRODUCT_ID)) ?? []

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleItemClick = (id: number, name: string) => {
        updatePid(id, name);
        handleClose();
    }

    function closeMenu() {
        setMenuOpen(false);
    }
    const installMenuClick = () => {
        setMenuOpen(true);
    };

    return (
        <div className="ss-render-area-label-row">
            <Button variant='contained' color="secondary" onClick={handleClick} disabled={disableChanges}>
                {abbrvProductType(productType) ?? "?"}
            </Button>
            <div>
                {label}
            </div>
            
            <QuoteCardIconButton
                style={{ marginBottom: "unset" }}
                variant='contained'
                color="secondary"
                onClick={installMenuClick}
                disabled={disableChanges}
            >
                D
            </QuoteCardIconButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
                {
                    typeOps.map(op => {
                        return <MenuItem
                            key={`product-button-menu-${op.id}`}
                            onClick={() => handleItemClick(op.id, op.type)}>
                            {op.type}
                        </MenuItem>
                    })
                }
            </Menu>

            <FullEditInstallationServicesMenu
                open={menuOpen}
                onClose={closeMenu}
                areaId={areaId}
                productTypeId={productTypeId ?? 0}
                someAreaHasHardSurface={someAreaHasHardSurface}
            />
        </div>
    )
}

export function generateAreaLabels(areas: Area[], targetCount: number): AreaLabel[] {
    return padArray(areas.map<AreaLabel>(
        area => ({
            areaId: area.id,
            productTypeId: area.productTypeId ?? undefined,
            productType: area.productTypeName ?? undefined,
            label: getNameOfArea(area.rooms.flatMap(r => r.labels))
        })),
        targetCount,
        undefined)
}


function abbrvProductType(productType?: string) {
    switch (productType) {
        case 'Carpet':
            return "CPT";
        case 'Wood':
            return "WD";
        default:
            return productType;
    }
}