import { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address, CustomerContact, CustomerSearchResult } from 'generated/graphql';
import { emptyAddress } from 'Globals/DataStructures/EmptyDataStructures';
import { isEmptyString } from 'Globals/GenericValidators';
import { formatPhoneNumber } from 'Globals/StringFormatting';
import { RootState } from './store';

export const blankContact: CustomerContact = {
    id: -1,
    customerId: -1,
    firstName: "",
    lastName: "",
    phone: "",
    phoneExtension: "",
    email: "",
    receivePromoEmails: true,
    isSchedulingContact: false,
    isPrimary: false
}
    
interface SchedulingState {
    autofilledAddress: Address;  // used when submitting address after an autofill to see if it has changed
    customerId: number;
    recentJobId?: number;
    mergeWithCustomerId: number;
    isForHome: boolean;
    roomCount: number;
    internalNotes: string;
    appointmentNotes: string;
    pTypeIds: number[];
    selectedPriorityItemIds: number[];
    selectedColorCategoryIds: number[];
    businessName: string;
    primaryContact: CustomerContact;
    secondaryContacts: CustomerContact[];
    streetAddress: string;
    city: string;
    zip: string;
    aptNo: string;
    howDidYouHearId: number;
    promoCodeText: string;
    promoCodeId: number | null;
    day: DayValue,
    timeSlotId: number | null;
}

const initialState: SchedulingState = {
    autofilledAddress: emptyAddress,
    customerId: -1,
    mergeWithCustomerId: -1,
    isForHome: true,
    roomCount: 1,
    internalNotes: '',
    appointmentNotes: '',
    pTypeIds: [],
    selectedPriorityItemIds: [],
    selectedColorCategoryIds: [],
    businessName: '',
    primaryContact: {
        ...blankContact,
        isPrimary: true,
        isSchedulingContact: true
    },
    secondaryContacts: [],
    streetAddress: '',
    zip: '',
    city: '',
    aptNo: '',
    howDidYouHearId: -1,
    promoCodeText: '',
    promoCodeId: null,
    day: undefined,
    timeSlotId: null
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const slice = createSlice({
    name: "callCenterScheduling",
    initialState, 
    reducers: {
        clearAutofilledAddress: (state) => {state.autofilledAddress = emptyAddress},
        setCustomerId: (state, action: PayloadAction<number>) => {state.customerId = action.payload},
        setMergeWithCustomerId: (state, action: PayloadAction<number>) => {state.mergeWithCustomerId = action.payload},
        unmerge: (state) => {
            // only secondary contacts get merged in - primary contact is ignored
            state.secondaryContacts = state.secondaryContacts.filter(c => c.customerId !== state.mergeWithCustomerId)
            state.mergeWithCustomerId = -1;
        },
        setIsForHome: (state, action: PayloadAction<boolean>) => {state.isForHome = action.payload},
        setRoomCount: (state, action: PayloadAction<number>) => {state.roomCount = action.payload},
        setInternalNotes: (state, action: PayloadAction<string>) => {state.internalNotes = action.payload},
        setAppointmentNotes: (state, action: PayloadAction<string>) => {state.appointmentNotes = action.payload},
        setPtypeIds: (state, action: PayloadAction<number[]>) => {state.pTypeIds = action.payload},
        setSelectedPriorityItemIds: (state, action: PayloadAction<number[]>) => {state.selectedPriorityItemIds = action.payload},
        setSelectedColorCategoryIds: (state, action: PayloadAction<number[]>) => {state.selectedColorCategoryIds = action.payload},
        setBusinessName: (state, action: PayloadAction<string>) => {state.businessName = action.payload},
        setPrimaryContactFname: (state, action: PayloadAction<string>) => {state.primaryContact = {...state.primaryContact, firstName: action.payload}},
        setPrimaryContactLname: (state, action: PayloadAction<string>) => {state.primaryContact = {...state.primaryContact, lastName: action.payload}},
        setPrimaryContactId: (state, action: PayloadAction<number>) => {state.primaryContact = {...state.primaryContact, id: action.payload}},
        setPrimaryPhone: (state, action: PayloadAction<string>) => {state.primaryContact = {...state.primaryContact, phone: action.payload}},
        setPrimaryEmail: (state, action: PayloadAction<string>) => {state.primaryContact = {...state.primaryContact, email: action.payload}},
        addSecondaryContact: (state, action: PayloadAction<CustomerContact>) => {
            let newId = -2;  // -1 reserved for new primary contact
            // if there are other secondary contacts, and at least one of them is not in the db (has - ID), set new ID based on that
            if (state.secondaryContacts.length > 0) {
                const lowestId = Math.min(...state.secondaryContacts.map(c => c.id));
                if (lowestId < 0) {
                    newId = lowestId - 1;
                }
            }
            state.secondaryContacts = [...state.secondaryContacts, {...action.payload, customerId: state.customerId, id: newId}]
        },
        removeSecondaryContact: (state, action: PayloadAction<number>) => {state.secondaryContacts = state.secondaryContacts.filter(sc => sc.id !== action.payload)},
        setSecondaryContactFirstName: (state, action: PayloadAction<{contactId: number, fName: string, deleteWhenEmpty?: boolean}>) => {
            const updateIdx = state.secondaryContacts.findIndex(sc => sc.id === action.payload.contactId);
            const updatedContact: CustomerContact = {...state.secondaryContacts[updateIdx], firstName: action.payload.fName};
            const updatedContacts = [...state.secondaryContacts];
            updatedContacts.splice(updateIdx, 1, updatedContact);

            // deletes the contact when all pieces of information for it are empty strings
            if (action.payload.deleteWhenEmpty ?? false) {
                if (isEmptyString(updatedContact.firstName.trim())
                    && isEmptyString((updatedContact.lastName ?? '').trim())
                    && isEmptyString((updatedContact.email ?? '').trim())
                    && isEmptyString((updatedContact.phone ?? '').trim())) {
                        updatedContacts.splice(updateIdx, 1);
                    }
            }

            state.secondaryContacts = updatedContacts;
        },
        setSecondaryContactLastName: (state, action: PayloadAction<{contactId: number, lName: string, deleteWhenEmpty?: boolean}>) => {
            const updateIdx = state.secondaryContacts.findIndex(sc => sc.id === action.payload.contactId);
            const updatedContact: CustomerContact = {...state.secondaryContacts[updateIdx], lastName: action.payload.lName};
            const updatedContacts = [...state.secondaryContacts];
            updatedContacts.splice(updateIdx, 1, updatedContact);

            // deletes the contact when all pieces of information for it are empty strings
            if (action.payload.deleteWhenEmpty ?? false) {
                if (isEmptyString(updatedContact.firstName.trim())
                    && isEmptyString((updatedContact.lastName ?? '').trim())
                    && isEmptyString((updatedContact.email ?? '').trim())
                    && isEmptyString((updatedContact.phone ?? '').trim())) {
                        updatedContacts.splice(updateIdx, 1);
                    }
            }

            state.secondaryContacts = updatedContacts;
        },
        setSecondaryPhone: (state, action: PayloadAction<{contactId: number, phone: string, deleteWhenEmpty?: boolean}>) => {
            const updateIdx = state.secondaryContacts.findIndex(sc => sc.id === action.payload.contactId);
            const updatedContact: CustomerContact = {...state.secondaryContacts[updateIdx], phone: action.payload.phone};
            const updatedContacts = [...state.secondaryContacts];
            updatedContacts.splice(updateIdx, 1, updatedContact);

            // deletes the contact when all pieces of information for it are empty strings
            if (action.payload.deleteWhenEmpty ?? false) {
                if (isEmptyString(updatedContact.firstName.trim())
                    && isEmptyString((updatedContact.lastName ?? '').trim())
                    && isEmptyString((updatedContact.email ?? '').trim())
                    && isEmptyString((updatedContact.phone ?? '').trim())) {
                        updatedContacts.splice(updateIdx, 1);
                    }
            }
            
            state.secondaryContacts = updatedContacts;
        },
        setSecondaryEmail: (state, action: PayloadAction<{contactId: number, email: string, deleteWhenEmpty?: boolean}>) => {
            const updateIdx = state.secondaryContacts.findIndex(sc => sc.id === action.payload.contactId);
            const updatedContact: CustomerContact = {...state.secondaryContacts[updateIdx], email: action.payload.email};
            const updatedContacts = [...state.secondaryContacts];
            updatedContacts.splice(updateIdx, 1, updatedContact);

            // deletes the contact when all pieces of information for it are empty strings
            if (action.payload.deleteWhenEmpty ?? false) {
                if (isEmptyString(updatedContact.firstName.trim())
                    && isEmptyString((updatedContact.lastName ?? '').trim())
                    && isEmptyString((updatedContact.email ?? '').trim())
                    && isEmptyString((updatedContact.phone ?? '').trim())) {
                        updatedContacts.splice(updateIdx, 1);
                    }
            }
            
            state.secondaryContacts = updatedContacts;
        },
        setSecondaryIsScheduling: (state, action: PayloadAction<{contactId: number, isSchedulingContact: boolean}>) => {
            const updateIdx = state.secondaryContacts.findIndex(sc => sc.id === action.payload.contactId);
            const updatedContact: CustomerContact = {...state.secondaryContacts[updateIdx], isSchedulingContact: action.payload.isSchedulingContact};
            const updatedContacts = [...state.secondaryContacts];
            updatedContacts.splice(updateIdx, 1, updatedContact);
            state.secondaryContacts = updatedContacts;
        },
        setStreetAddress: (state, action: PayloadAction<string>) => {state.streetAddress = action.payload},
        setCity: (state, action: PayloadAction<string>) => {state.city = action.payload},
        setZip: (state, action: PayloadAction<string>) => {state.zip = action.payload},
        setAptNo: (state, action: PayloadAction<string>) => {state.aptNo = action.payload},
        setHowDidYouHearId: (state, action: PayloadAction<number>) => {state.howDidYouHearId = action.payload},
        setPromoCodeText: (state, action: PayloadAction<string>) => {state.promoCodeText = action.payload},
        setPromoCodeId: (state, action: PayloadAction<number>) => {state.promoCodeId = action.payload},
        setDay: (state, action: PayloadAction<DayValue>) => {state.day = action.payload},
        setTimeSlotId: (state, action: PayloadAction<number | null>) => {state.timeSlotId = action.payload},
        setJobId: (state, action: PayloadAction<number>) => {state.timeSlotId = action.payload},
        // for dumping customer information into the form when there's a customer ID in the page URL
        dumpCustomerInformation: (state, action: PayloadAction<CustomerSearchResult>) => {
            // set primary information
            const customer = action.payload;
            const primaryContact = customer.primaryContact;
            state.secondaryContacts = customer.secondaryContacts.map(c => ({...c, phone: formatPhoneNumber(c.phone ?? "")}));

            state.customerId = customer.id;
            state.recentJobId = customer.jobId ?? undefined;
            state.internalNotes = customer.prospectNotes ?? '';
            state.primaryContact = {
                ...state.primaryContact,
                id: primaryContact.id,
                firstName: primaryContact.firstName,
                lastName: primaryContact.lastName,
                phone: formatPhoneNumber(primaryContact.phone ?? undefined) ?? '',
                email: primaryContact.email
            };
            if (customer.businessName) {
                state.isForHome = false;
                state.businessName = customer.businessName;
            } else {
                state.isForHome = true;
                state.businessName = '';
            }
    
            // set location information
            state.streetAddress = customer.primaryAddress.streetAddress;
            state.zip = customer.primaryAddress.zip;
            state.city = customer.primaryAddress.city;
            state.aptNo = customer.primaryAddress.apartmentNumber ?? '';
            state.autofilledAddress = customer.primaryAddress;
        },
        // for dumping search results into the form (merges with info already on the page)
        mergeCustomerInformation: (state, action: PayloadAction<CustomerSearchResult>) => {
            // set primary information
            const incomingCustomer = action.payload;
            
            state.mergeWithCustomerId = incomingCustomer.id;
            state.internalNotes = incomingCustomer.prospectNotes ?? '';
            
            if (incomingCustomer.businessName) {
                if (isEmptyString(state.businessName)) {
                    state.isForHome = false;
                    state.businessName = incomingCustomer.businessName;
                }
            } else {
                state.isForHome = true;
                state.businessName = '';
            }
            
            // primary contact of incoming is now a secondary contact
            const incomingPrimaryContact = {...incomingCustomer.primaryContact, isPrimary: false};
            const incomingSecondaryContacts = incomingCustomer.secondaryContacts;
            state.secondaryContacts = [...state.secondaryContacts, {...incomingPrimaryContact, isPrimary: false},  ...incomingSecondaryContacts];
    
            // set location information
            if (isEmptyString(state.streetAddress)) { state.streetAddress = incomingCustomer.primaryAddress.streetAddress; }
            if (isEmptyString(state.zip)) { state.zip = incomingCustomer.primaryAddress.zip; }
            if (isEmptyString(state.city)) { state.city = incomingCustomer.primaryAddress.city; }
            if (isEmptyString(state.aptNo)) { state.aptNo = incomingCustomer.primaryAddress.apartmentNumber ?? ''; }
        },
        resetCustomerAndContactIds: (state) => {
            state.customerId = -1;
            state.mergeWithCustomerId = -1;
            state.primaryContact.id = -1;
            state.secondaryContacts = state.secondaryContacts.map((c, i) => ({...c, id: -i}));
        },
        clearForm: (state) => {
            state.autofilledAddress = emptyAddress;
            state.customerId = -1;
            state.mergeWithCustomerId = -1;
            state.isForHome = true;
            state.roomCount = 1;
            state.internalNotes = '';
            state.appointmentNotes = '';
            state.pTypeIds = [];
            state.selectedPriorityItemIds = [];
            state.selectedColorCategoryIds = [];
            state.businessName = '';
            state.primaryContact = {
                ...blankContact,
                isPrimary: true,
                isSchedulingContact: true
            };
            state.secondaryContacts = [];
            state.streetAddress = '';
            state.zip = '';
            state.city = '';
            state.aptNo = '';
            state.howDidYouHearId = -1;
            state.promoCodeText = '';
            state.promoCodeId = null;
            state.day = undefined;
            state.timeSlotId = null;
        }
    }
});

export const {clearAutofilledAddress, setCustomerId, setMergeWithCustomerId, setIsForHome, setRoomCount, setInternalNotes, setAppointmentNotes, setPtypeIds, setSelectedPriorityItemIds,
    setSelectedColorCategoryIds, setPrimaryContactFname, setPrimaryContactLname, setPrimaryContactId, setBusinessName, setPrimaryPhone, setPrimaryEmail, addSecondaryContact, setSecondaryContactFirstName, setSecondaryContactLastName,
    setSecondaryPhone, setSecondaryEmail, setSecondaryIsScheduling, removeSecondaryContact, setZip, setCity, setStreetAddress, setAptNo, setHowDidYouHearId, setPromoCodeText,
    setPromoCodeId, setDay, setTimeSlotId, dumpCustomerInformation, resetCustomerAndContactIds, mergeCustomerInformation, unmerge, clearForm} = slice.actions //Unpacks the actions created in the slice

export const selectAutofilledAddress = (state: RootState) => state.callCenterScheduling.autofilledAddress;
export const selectCustomerId = (state: RootState) => state.callCenterScheduling.customerId;
export const selectMergeBaseCustomerId = (state: RootState) => state.callCenterScheduling.mergeWithCustomerId;
export const selectIsForHome = (state: RootState) => state.callCenterScheduling.isForHome;
export const selectRoomCount = (state: RootState) => state.callCenterScheduling.roomCount;
export const selectInternalNotes = (state: RootState) => state.callCenterScheduling.internalNotes;
export const selectAppointmentNotes = (state: RootState) => state.callCenterScheduling.appointmentNotes;
export const selectRecentJobId = (state: RootState) => state.callCenterScheduling.recentJobId;
export const selectPtypeIds = (state: RootState) => state.callCenterScheduling.pTypeIds;
export const selectPriorityItemIds = (state: RootState) => state.callCenterScheduling.selectedPriorityItemIds;
export const selectColorCategoryIds = (state: RootState) => state.callCenterScheduling.selectedColorCategoryIds;
export const selectFname = (state: RootState) => state.callCenterScheduling.primaryContact.firstName;
export const selectLname = (state: RootState) => state.callCenterScheduling.primaryContact.lastName;
export const selectSpouseName = (state: RootState) => state.callCenterScheduling.secondaryContacts[0]?.firstName ?? '';
export const selectBusinessName = (state: RootState) => state.callCenterScheduling.businessName;
export const selectPrimaryContact = (state: RootState) => state.callCenterScheduling.primaryContact;
export const selectSecondaryContacts = (state: RootState) => state.callCenterScheduling.secondaryContacts;
export const selectZip = (state: RootState) => state.callCenterScheduling.zip;
export const selectCity = (state: RootState) => state.callCenterScheduling.city;
export const selectStreetAddress = (state: RootState) => state.callCenterScheduling.streetAddress;
export const selectAptNo = (state: RootState) => state.callCenterScheduling.aptNo;
export const selectHowDidYouHearId = (state: RootState) => state.callCenterScheduling.howDidYouHearId;
export const selectPromoCodeText = (state: RootState) => state.callCenterScheduling.promoCodeText;
export const selectPromoCodeId = (state: RootState) => state.callCenterScheduling.promoCodeId;
export const selectDay = (state: RootState) => state.callCenterScheduling.day;
export const selectTimeSlotId = (state: RootState) => state.callCenterScheduling.timeSlotId;

export default slice.reducer;