import { DayRange, DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import Loading from "Components/Loading";
import FlatButton from "FlatComponents/Button/FlatButton";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import { FlatLabeledSelect, FlatSelectOption } from "FlatComponents/Inputs/FlatSelect";
import { SalespersonPerformanceSummary, useGetGeneratedSalespersonPerformanceSummaryLazyQuery, useGetListOfStoredSalespersonPerformanceSummariesQuery, useGetStoredSalespersonPerformanceSummaryLazyQuery } from "generated/graphql";
import { dateTimeStrToDay, dateTimeStrToMdy, dayValueToIso, dayValueToMdy, getStartOfMonthToToday, SingleDateCalendarDialog } from "Globals/DateAndTimeHelpers";
import { useCallback, useEffect, useState } from "react";
import ProductTypeStatisticsTable from "../ProductTypeStatisticsTable";
import "./salesperson_profile.css";

export default function SalespersonPerformanceSummaryTable({salespersonId}: {salespersonId: number}) {
    // the summary may come from two different queries, so use state to simplify this process
    const [summary, setSummary] = useState<FullyCalculatedPerformanceSummary | undefined>(undefined);
    // this range is used purely for display purposes and setting is shared between generated/stored summaries
    const [dateRange, setDateRange] = useState<DayRange>(getStartOfMonthToToday());
    // this range is used to accept values from the date range dialog for generation purposes and is NOT shared
    const [customRange, setCustomRange] = useState<DayRange>({from: undefined, to: undefined});
    const [startDateOpen, setStartDateOpen] = useState(false);
    const [endDateOpen, setEndDateOpen] = useState(false);

    const {data: storedSummaryData} = useGetListOfStoredSalespersonPerformanceSummariesQuery({
        variables: {salespersonId: salespersonId},
        onError: () => alert("Couldn't retrieve list of stored summaries")
    });
    const storedSummaryList = storedSummaryData?.listOfStoredSalespersonPerformanceSummaries ?? [];
    
    const summaryOptions: FlatSelectOption[] = storedSummaryList.map(summary => ({
        value: summary.id,
        label: `${dateTimeStrToMdy(summary.startDate)} - ${dateTimeStrToMdy(summary.endDate)}`
    }));

    const [generateSummary] = useGetGeneratedSalespersonPerformanceSummaryLazyQuery();
    const [retrieveSummary] = useGetStoredSalespersonPerformanceSummaryLazyQuery();

    const onGenerateSummary = useCallback((range: DayRange) => {
        if (salespersonId > 0 && range.from && range.to) {
            generateSummary({
                variables: {
                    salespersonId: salespersonId,
                    startDate: dayValueToIso(range.from),
                    endDate: dayValueToIso(range.to)
                }
            }).then(summaryData => {
                const generatedSummary = summaryData.data?.generatedSalespersonPerformanceSummary;
                if (generatedSummary) {
                    setCustomRange({from: undefined, to: undefined})
                    setDateRange({from: dateTimeStrToDay(generatedSummary.startDate), to: dateTimeStrToDay(generatedSummary.endDate)})
                    setSummary(calculateFullPerformanceSummary(generatedSummary));
                }
            }).catch(_ => alert("Couldn't generate salesperson performance summary"));
        }
    }, [salespersonId, generateSummary]);

    // when the page loads, generate the summary for the month
    useEffect(() => {
        onGenerateSummary(getStartOfMonthToToday());
    }, [onGenerateSummary]);

    function onRetrieveSummary(summaryId: number) {
        retrieveSummary({
            variables: {id: summaryId}
        }).then(summaryData => {
            const retrievedSummary = summaryData.data?.storedSalespersonPerformanceSummary;
            if (retrievedSummary) {
                setDateRange({from: dateTimeStrToDay(retrievedSummary.startDate), to: dateTimeStrToDay(retrievedSummary.endDate)})
                setSummary(calculateFullPerformanceSummary(retrievedSummary));
            }
        }).catch(_ => alert("Couldn't retrieve salesperson performance summary"));
    }
    
    return (<>
        {summary ? (
            <div id="sp-performance-summary">
                <div className="thin-horizontal-bar" />
                <div className="flex-column flex-gap-xsm margin-bottom-sm">
                    <p className="flat-font margin-none">Viewing Performance Summary for <b>{`${dayValueToMdy(dateRange.from)} - ${dayValueToMdy(dateRange.to)}`}</b></p>
                    <div className="flex-row flex-gap-lg flex-space-between">
                        <FlatLabeledSelect
                            label="Stored summaries"
                            options={summaryOptions}
                            onChange={(selection => {
                                if (selection) {
                                    onRetrieveSummary((selection as FlatSelectOption).value as number);
                                }
                            })}
                        />
                        <span className="flex-row flex-gap-sm align-items-center">
                            <p className="flat-font margin-none bold-text">Generate for custom range</p>
                            <FlatLabeledInput
                                label="From"
                                className="w-5r text-align-center"
                                value={dayValueToMdy(customRange?.from)}
                                onClick={() => setStartDateOpen(true)}
                                readOnly
                            />
                            <FlatLabeledInput
                                label="To"
                                className="w-5r text-align-center"
                                value={dayValueToMdy(customRange?.to)}
                                onClick={() => setEndDateOpen(true)}
                                readOnly
                            />
                            <FlatButton
                                className="fit-content"
                                variant="contained"
                                color="secondary"
                                disabled={!(customRange && customRange.from && customRange.to)}
                                onClick={() => onGenerateSummary(customRange)}
                            >Generate</FlatButton>
                        </span>
                    </div>
                </div>

                <div className="flex-row" style={{borderBottom: "1px solid var(--flat-gray-4)"}}>
                    <div id="sp-summary-table-1-2-3-container">
                        <table className="sp-summary-table" id="sp-summary-table-1">
                            <colgroup>
                                <col style={{width: "72%"}}/>
                                <col style={{width: "7%"}}/>
                                <col style={{width: "7%"}}/>
                                <col style={{width: "7%"}}/>
                                <col style={{width: "7%"}}/>
                            </colgroup>
                            <tbody>
                                <tr className="sp-summary-table-pseudo-header fill-width">
                                    <td className="flat-font" colSpan={2}>
                                        <span className="flex-row flex-space-between">
                                            <span className="flex-row flex-gap-xsm">
                                                <p className="bold-text margin-none"># Lead w/o Demo:</p>
                                                <p className="margin-none">{summary.numLeadNoDemo}</p>
                                            </span>
                                            <span className="flex-row flex-gap-xsm">
                                                <p className="bold-text margin-none"># No quote:</p>
                                                <p className="margin-none">{summary.numNoQuotes}</p>
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Total Leads and Demos</td>
                                    <td className="flat-font sp-summary-blue-text" style={{borderRight: "none"}}>{summary.numLeads}</td>
                                    <td className="flat-font sp-summary-blue-text" style={{visibility: "hidden"}}>spacer</td>
                                    <td className="flat-font sp-summary-blue-text">{summary.numDemos}</td>
                                    <td className="flat-font ">{summary.demoToLeadRatio.toFixed(0)}%</td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Sales Against Lead & Demo</td>
                                    <td className="flat-font sp-summary-blue-text">{summary.saleCount}</td>
                                    <td className="flat-font sp-summary-red-text">{summary.saleCountAgainstLeadsPctg.toFixed(0)}%</td>
                                    <td className="flat-font sp-summary-blue-text">{summary.saleCount}</td>
                                    <td className="flat-font">{summary.saleCountAgainstDemosPctg.toFixed(0)}%</td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Quote Against Lead & Demo</td>
                                    <td className="flat-font sp-summary-blue-text">{summary.quoteCount}</td>
                                    <td className="flat-font">{summary.quoteCountAgainstLeadsPctg.toFixed(0)}%</td>
                                    <td className="flat-font sp-summary-blue-text">{summary.quoteCount}</td>
                                    <td className="flat-font">{summary.quoteCountAgainstDemosPctg.toFixed(0)}%</td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Cancel Against Lead & Demo</td>
                                    <td className="flat-font sp-summary-blue-text">{summary.cancelCount}</td>
                                    <td className="flat-font">{summary.cancelCountAgainstLeadsPctg.toFixed(0)}%</td>
                                    <td className="flat-font sp-summary-blue-text">{summary.cancelCount}</td>
                                    <td className="flat-font">{summary.cancelCountAgainstDemosPctg.toFixed(0)}%</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="sp-summary-table" id="sp-summary-table-2">
                            <colgroup>
                                <col style={{width: "75%"}}/>
                                <col style={{width: "15%"}}/>
                                <col style={{width: "10%"}}/>
                            </colgroup>
                            <tbody>
                                <tr className="sp-summary-table-pseudo-header">
                                    <td colSpan={2} className="flat-font sp-summary-table-row-spacer">spacer</td>
                                </tr>
                                <tr className="sp-summary-table-pseudo-header">
                                    <td className="flat-font bold-text">Total Opportunity</td>
                                    <td colSpan={2} className="flat-font sp-summary-blue-text">${summary.totalOpportunity.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Sales Totals</td>
                                    <td className="flat-font sp-summary-blue-text">${summary.totalSaleDollars.toFixed(2)}</td>
                                    <td className="flat-font">{summary.saleDollarPctg.toFixed(0)}%</td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Quote Totals</td>
                                    <td className="flat-font sp-summary-blue-text">${summary.totalQuoteDollars.toFixed(2)}</td>
                                    <td className="flat-font">{summary.quoteDollarPctg.toFixed(0)}%</td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Cancel Totals</td>
                                    <td className="flat-font sp-summary-blue-text">${summary.totalCancelDollars.toFixed(2)}</td>
                                    <td className="flat-font">{summary.cancelDollarPctg.toFixed(0)}%</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="sp-summary-table" id="sp-summary-table-3">
                            <colgroup>
                                <col style={{width: "60%"}}/>
                                <col style={{width: "40%"}}/>
                            </colgroup>
                            <tbody>
                                <tr className="sp-summary-table-pseudo-header">
                                    <td colSpan={2} className="flat-font sp-summary-table-row-spacer">spacer</td>
                                </tr>
                                <tr className="sp-summary-table-pseudo-header fill-width">
                                    <td className="flat-font" colSpan={2}>
                                        <span className="flex-row flex-space-between">
                                            <span className="flex-row flex-gap-xsm">
                                                <p className="bold-text margin-none"># Sold:</p>
                                                <p className="margin-none">{summary.saleCount}</p>
                                            </span>
                                            <span className="flex-row flex-gap-xsm">
                                                <p className="bold-text margin-none"># Quoted:</p>
                                                <p className="margin-none">{summary.quoteCount}</p>
                                            </span>
                                            <span className="flex-row flex-gap-xsm">
                                                <p className="bold-text margin-none"># Canceled:</p>
                                                <p className="margin-none">{summary.cancelCount}</p>
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Avg. Sale $</td>
                                    <td className="flat-font sp-summary-red-text">${summary.averageSaleDollar.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Avg. Quote $</td>
                                    <td className="flat-font">${summary.averageQuoteDollar.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Avg. Cancel $</td>
                                    <td className="flat-font">${summary.averageCancelDollar.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id="sp-summary-table-4-container">
                        <table className="sp-summary-table" id="sp-summary-table-4">
                            <colgroup>
                                <col style={{width: "65%"}}/>
                                <col style={{width: "35%"}}/>
                            </colgroup>
                            <tbody>
                                <tr className="sp-summary-table-pseudo-header">
                                    <td colSpan={2} className="flat-font sp-summary-table-row-spacer">spacer</td>
                                </tr>
                                <tr className="sp-summary-table-pseudo-header">
                                    <td colSpan={2} className="flat-font sp-summary-table-row-spacer">spacer</td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Avg. Sale Margin</td>
                                    <td className="flat-font sp-summary-blue-text">{summary.averageSaleMargin.toFixed(0)}%</td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Avg. Quote Margin</td>
                                    <td className="flat-font sp-summary-blue-text">{summary.averageQuoteMargin.toFixed(0)}%</td>
                                </tr>
                                <tr>
                                    <td className="flat-font bold-text">Avg. Cancel Margin</td>
                                    <td className="flat-font sp-summary-blue-text">{summary.averageCancelMargin.toFixed(0)}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <ProductTypeStatisticsTable summary={summary}/>
            </div>
        ) : (
            <Loading />
        )}

        <SingleDateCalendarDialog
            selectedDate={customRange?.from}
            setSelectedDate={(day: DayValue) => setCustomRange({...customRange, from: day})}
            open={startDateOpen}
            setOpen={setStartDateOpen}
            blockPastDays={false}
        />

        <SingleDateCalendarDialog
            selectedDate={customRange?.to}
            setSelectedDate={(day: DayValue) => setCustomRange({...customRange, to: day})}
            open={endDateOpen}
            setOpen={setEndDateOpen}
            blockPastDays={false}
        />
    </>)
}

export interface FullyCalculatedPerformanceSummary extends SalespersonPerformanceSummary {
    demoToLeadRatio: number;
    numNoQuotes: number;  // count the number of demos that did not end in quote or sale (or cancellation)
    numLeadNoDemo: number;  // count the number of leads that never had a demo  
    saleCountAgainstLeadsPctg: number;
    saleCountAgainstDemosPctg: number;
    averageSaleDollar: number;
    saleDollarPctg: number;
    quoteCountAgainstLeadsPctg: number;
    quoteCountAgainstDemosPctg: number;
    averageQuoteDollar: number;
    quoteDollarPctg: number;
    cancelCountAgainstLeadsPctg: number;
    cancelCountAgainstDemosPctg: number;
    averageCancelDollar : number;
    cancelDollarPctg: number;
    totalOpportunity: number;
}


/**
 * Derives metrics from the base performance summary data
 * @param baseSummary The data from which the full summary information is derived
 * @returns The fully derived performance data
 */
function calculateFullPerformanceSummary(baseSummary: SalespersonPerformanceSummary): FullyCalculatedPerformanceSummary {
    const totalOpportunity = baseSummary.totalSaleDollars + baseSummary.totalQuoteDollars + baseSummary.totalCancelDollars;

    return {
        ...baseSummary,
        demoToLeadRatio: (baseSummary.numLeads === 0) ? 0: (baseSummary.numDemos / baseSummary.numLeads) * 100,
        numNoQuotes: baseSummary.numDemos - (baseSummary.saleCount + baseSummary.quoteCount + baseSummary.cancelCount),
        numLeadNoDemo: baseSummary.numLeads - baseSummary.numDemos,
        saleCountAgainstLeadsPctg: (baseSummary.numLeads === 0) ? 0 : (baseSummary.saleCount / baseSummary.numLeads) * 100,
        saleCountAgainstDemosPctg: (baseSummary.numDemos === 0) ? 0 : (baseSummary.saleCount / baseSummary.numDemos) * 100,
        saleDollarPctg: (totalOpportunity === 0) ? 0 : (baseSummary.totalSaleDollars / totalOpportunity) * 100,
        averageSaleDollar: (baseSummary.saleCount === 0) ? 0 : baseSummary.totalSaleDollars / baseSummary.saleCount,
        quoteCountAgainstLeadsPctg: (baseSummary.numLeads === 0) ? 0 : (baseSummary.quoteCount / baseSummary.numLeads) * 100,
        quoteCountAgainstDemosPctg: (baseSummary.numDemos === 0) ? 0 : (baseSummary.quoteCount / baseSummary.numDemos) * 100,
        quoteDollarPctg: (totalOpportunity === 0) ? 0 :(baseSummary.totalQuoteDollars / totalOpportunity) * 100,
        averageQuoteDollar: (baseSummary.quoteCount === 0) ? 0 : baseSummary.totalQuoteDollars / baseSummary.quoteCount,
        cancelCountAgainstLeadsPctg: (baseSummary.numLeads === 0) ? 0 : (baseSummary.cancelCount / baseSummary.numLeads) * 100,
        cancelCountAgainstDemosPctg: (baseSummary.numDemos === 0) ? 0 : (baseSummary.cancelCount / baseSummary.numDemos) * 100,
        cancelDollarPctg: (totalOpportunity === 0) ? 0 : (baseSummary.totalCancelDollars / totalOpportunity) * 100,
        averageCancelDollar: (baseSummary.cancelCount === 0) ? 0 : baseSummary.totalCancelDollars / baseSummary.cancelCount,
        totalOpportunity: totalOpportunity
    };
}