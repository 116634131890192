import { Typography } from "@material-ui/core";
import Loading from "Components/Loading";
import { useGetAuthenticatedWorkerQuery, useGetDaysContractorIsBusyQuery } from "generated/graphql";
import { dateTimeStrToDay } from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { BlockTimeBody } from "Pages/BlockTime/BlockTimePage";
import { useMemo } from "react";
import ContractorNavbarPage from "../ContractorNavbarPage";


export default function ContractorBlockTime() {
    const { data, loading } = useGetAuthenticatedWorkerQuery({ fetchPolicy: "network-only" });

    const { data: blockedData, loading: loadingBlocked } = useGetDaysContractorIsBusyQuery({ fetchPolicy: "network-only", pollInterval: 5000 }) 

    const stillLoading = loading || loadingBlocked

    const blockedDays = useMemo(() => {
        return blockedData?.daysContractorIsBusy.map(day=>dateTimeStrToDay(day))
    }, [blockedData])

    return (
        <ContractorNavbarPage>
            {
                (stillLoading) ?
                    <Loading /> :
                    (isNullOrUndefined(data?.authenticatedWorker?.id)) ?
                        <Typography>Worker Not Found</Typography> :
                        <BlockTimeBody workerId={data?.authenticatedWorker?.id!} blockWholeDaysOnly blockedDays={blockedDays} />
            }
        </ContractorNavbarPage>
    )
}