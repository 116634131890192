import { isNullOrUndefined } from "Globals/GenericValidators";
import { AtomicTimeSlot as GraphQLAtomicTimeSlot, MarketTimeSlot } from "generated/graphql";
import { timeSpanStrToTime } from "Globals/DateAndTimeHelpers";

export default class AtomicTimeSlot {
    startHour: number;
    startMinute: number;
    endHour: number;
    endMinute: number;

    constructor(startHour: number, startMinute: number, endHour: number, endMinute: number) {
        this.startHour = startHour;
        this.startMinute = startMinute;
        this.endHour = endHour;
        this.endMinute = endMinute;
    }

    static fromGraphQLAtomicTimeSlot(ts: GraphQLAtomicTimeSlot) {
        return new AtomicTimeSlot(ts.startHour, ts.startMinute, ts.endHour, ts.endMinute)
    }

    static fromMarketTimeSlot(ts: MarketTimeSlot) {
        var start = timeSpanStrToTime(ts.startTime)
        var end = timeSpanStrToTime(ts.endTime)

        return new AtomicTimeSlot(start.hour, start.minute, end.hour, end.minute)
    }

    static fromStartTime(startHour: number, startMinute: number, apptLengthHours: number, apptLengthMinutes: number) {
        let endHour = startHour + apptLengthHours;
        let endMinute = startMinute + apptLengthMinutes;
        if (endMinute >= 60) {
            endHour += 1;
            endMinute -= 60;
        }

        return new AtomicTimeSlot(startHour, startMinute, endHour, endMinute);
    }

    // used to convert from Date objects
    static fromDates(start: Date, end: Date) {
        let startHour: number = start.getHours();
        let startMinute: number = start.getMinutes();
        let endHour: number = end.getHours();
        let endMinute: number = end.getMinutes();

        return new AtomicTimeSlot(startHour, startMinute, endHour, endMinute);
    }

    static to12hour(hour: number) {
        if (hour > 12) {
            return hour - 12;
        } else if (hour === 0) {
            return 12;
        } else {
            return hour;
        }
    }

    // ensure that times print minutes with two digits
    static ensure2digits(mins: number) {
        if (mins < 10) { return `0${mins}`; }
        else { return `${mins}`; }
    }

    // can't rely on the built-in Array implementation of this because it won't properly compare AtomicTimeSlot objects
    // returns -1 if not found
    static indexOf(target: AtomicTimeSlot, tsList: AtomicTimeSlot[]): number {
        for (let i = 0; i < tsList.length; i++) {
            if (target.equals(tsList[i])) {
                return i;
            }
        }

        return -1;
    }

    equals(other: AtomicTimeSlot | null | undefined): boolean {
        if (isNullOrUndefined(other)) { return false; }
        return (this.startHour === other?.startHour) && (this.startMinute === other.startMinute)
            && (this.endHour === other?.endHour) && (this.endMinute === other.endMinute);
    }

    endsAtOrBefore(endHour: number, endMinute: number) {
        if (this.endHour < endHour) { return true; }
        else if (this.endHour === endHour) { return this.endMinute <= endMinute }
        else { return false; }
    }

    startsAtOrBefore(startHour: number, startMinute: number) {
        if (this.startHour < startHour) { return true; }
        else if (this.startHour === startHour) { return this.startMinute <= startMinute; }
        else { return false; }
    }

    startTimeToString() {
        let amPm: string;
        if (this.startHour >= 12) {
            amPm = "pm";
        } else {
            amPm = "am";
        }

        return `${AtomicTimeSlot.to12hour(this.startHour)}:${AtomicTimeSlot.ensure2digits(this.startMinute)} ${amPm}`
    }

    endTimeToString() {
        let amPm: string;
        if (this.endHour >= 12) {
            amPm = "pm";
        } else {
            amPm = "am";
        }

        return `${AtomicTimeSlot.to12hour(this.endHour)}:${AtomicTimeSlot.ensure2digits(this.endMinute)} ${amPm}`
    }

    toString() {
        var timeStr: string = ''
        if (this.startHour === 12) { // distinct from next condition because we don't want to subtract 12 in this case
            timeStr += `${this.startHour}:${AtomicTimeSlot.ensure2digits(this.startMinute)} PM`
        }
        else if (this.startHour > 12) {
            timeStr += `${this.startHour - 12}:${AtomicTimeSlot.ensure2digits(this.startMinute)} PM`
        } else {
            timeStr += `${this.startHour}:${AtomicTimeSlot.ensure2digits(this.startMinute)} AM`
        }
        timeStr += " - "
        if (this.endHour === 12) { // distinct from next condition because we don't want to subtract 12 in this case
            timeStr += `${this.endHour}:${AtomicTimeSlot.ensure2digits(this.endMinute)} PM`
        } else if (this.endHour > 12) {
            timeStr += `${this.endHour - 12}:${AtomicTimeSlot.ensure2digits(this.endMinute)} PM`
        } else {
            timeStr += `${this.endHour}:${AtomicTimeSlot.ensure2digits(this.endMinute)} AM`
        }

        return timeStr;
    }

    static nineToSevenThirty() {
        return new AtomicTimeSlot(9, 0, 19, 30);
    }

    static nineToNine() {
        return new AtomicTimeSlot(9, 0, 21, 0);
    }
}