import FlatButton, { FlatButtonProps } from "FlatComponents/Button/FlatButton";
import { useState } from "react";
import FlatSection, { FlatSectionProps } from "./FlatSection";

interface TabLabelAndContents {
    headerLabel?: string;  // if null, just use tabLabel
    tabLabel: string | JSX.Element;
    bodyContent: JSX.Element;
}

// omit header because buttons take place of header
interface FlatTabbedSectionProps extends FlatSectionProps {
    tabs: TabLabelAndContents[];
}

export default function FlatTabbedSection({tabs, ...sectionProps}: Omit<FlatTabbedSectionProps, "header">) {
    const [openedTabIdx, setOpenedTabIdx] = useState(0);

    const tabSelectors = (
        <FlatTabButtonGroup
            activeIdx={openedTabIdx}
            buttons={tabs.map((t, idx) => ({
                    label: t.tabLabel,
                    onClick: () => setOpenedTabIdx(idx)
            }))}
        />
    );

    const headerText = tabs[openedTabIdx].headerLabel ?? tabs[openedTabIdx].tabLabel;

    return(
        <FlatSection header={headerText} centerAdornment={tabSelectors} {...sectionProps}>
            {tabs[openedTabIdx].bodyContent}
        </FlatSection>
    )
}

export interface TabbedSectionButton {
    label: string | JSX.Element;
    onClick?: () => void;
    buttonProps?: Omit<FlatButtonProps, "onClick" | "color" | "variant" | "disableElevation">
}

interface FlatTabButtonGroupProps {
    activeIdx?: number;
    buttons: TabbedSectionButton[]
}

export function FlatTabButtonGroup({activeIdx, buttons}: FlatTabButtonGroupProps) {
    function determineSelectorBorderStyle(tabIdx: number): React.CSSProperties | undefined {
        if (buttons.length > 1) {
            if (tabIdx === 0) {
                return {
                    borderRadius: "8px 0 0 8px",
                    borderRight: 0,
                    whiteSpace: "nowrap"
                }
            } else if (tabIdx > 0 && tabIdx < buttons.length - 1) {
                return {
                    borderRadius: 0,
                    borderRight: 0,
                    whiteSpace: "nowrap"
                }
            } else if (tabIdx === buttons.length - 1) {
                return {borderRadius: "0 8px 8px 0"};
            }
        } // else use default styling;
    }

    return (
        <span className="flex-row">
            {buttons.map((button, idx) => (
                <FlatButton
                    key={`t-${button.label}`}
                    variant={idx === activeIdx ? "contained" : "outlined"}
                    color="secondary"
                    style={{...determineSelectorBorderStyle(idx), ...button.buttonProps?.style, whiteSpace: "nowrap"}}
                    disableElevation
                    onClick={button.onClick}
                >{button.label}</FlatButton>
            ))}
        </span>
    )
}
