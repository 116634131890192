import { useGetJobBreakdownQuery, useGetJobContractRevisionIdsQuery } from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useAppSelector } from "Redux/hooks";
import { selectJobConfigurationId, selectJobContractId } from "Redux/jobTrackerReducer";
import CurrentSection from "./CurrentSection";
import HistorySection from "./HistorySection";
import { testReturns } from "./ReturnsPane";

export default function ChangeOrderPage() {

    const jobContractId = useAppSelector(selectJobContractId)
    const jobConfigurationId = useAppSelector(selectJobConfigurationId)

    const { data: breakdownData } = useGetJobBreakdownQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        skip: isNullOrUndefined(jobConfigurationId)
    });

    const { data: revisionData } = useGetJobContractRevisionIdsQuery({
        variables: { jobContractId: jobContractId! }
    })

    return (

        <div className="margin-top-sm">
            {isNotNullOrUndefined(breakdownData?.jobBreakdown?.areaBreakdowns) && (<>
                <HistorySection
                    oldestToNewestConfigIds={revisionData?.jobContractRevisionIds?.jobConfigurationIds ?? []}
                />
            </>)}

            <div className="padding-top-sm">
                <CurrentSection
                    returns={testReturns}
                    currentBreakdowns={breakdownData?.jobBreakdown?.areaBreakdowns}
                />
            </div>
        </div>
    )
}