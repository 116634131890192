import { Input, IconButton, Button, Dialog, DialogActions, DialogContent } from "@material-ui/core"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import Loading from "Components/Loading"
import ClearIcon from '@material-ui/icons/Clear';
import { getLabelForRoomFullName, getLabelForRoomName } from "Redux/JobReducerDataStructures/AreaType";
import { useState } from "react";
import { openLabelForRoomDialog, selectAllLabelForRoomLabels, selectLabelForRoomDialogLabels, selectLabelForRoomDialogOpen, setLabelsForRoom } from "Redux/genericSalesReducer";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { store } from "Redux/store";
import { LabelForRoom, RoomLabelOption, useGetRoomLabelOptionsQuery } from "generated/graphql";


export default function RoomLabelsEditorPopup() {
    const isPopupOpen = useAppSelector(selectLabelForRoomDialogOpen)
    return (<>{isPopupOpen && <RoomLabelsEditorInnerWrap />}</>)
}

function RoomLabelsEditorInnerWrap() {
    const reduxLabels = useAppSelector(selectLabelForRoomDialogLabels)
    const allLabels = useAppSelector(selectAllLabelForRoomLabels)
    const dispatch = useAppDispatch()
    const [labels, setLabels] = useState<LabelForRoom[]>(reduxLabels)

    const filteredLabels = allLabels.filter(lbl=>!reduxLabels.some(redux=>getLabelForRoomFullName(redux) === getLabelForRoomFullName(lbl)));

    function onClose() {
        dispatch(openLabelForRoomDialog(-1))
    }

    function handleSubmit() {
        const roomIndex = store.getState().genericSales.labelEditorDialogIndex
        dispatch(setLabelsForRoom(roomIndex, labels))
        onClose()
    }

    return (
        <Dialog open={true} onClose={onClose} maxWidth='lg'>
            <DialogContent style={{ height: '100%' }}>
                <div className='margin-sm'>
                    <div className='flex-row-center fill-width'>
                        <h1>Please Enter Area Type</h1>
                    </div>
                    <LabelForRoomEditor labels={labels} setLabels={setLabels} allLabels={[...labels, ...filteredLabels]} includeClo={true} />
                </div>
            </DialogContent>
            <DialogActions>
                <div className='flex-row-reverse' style={{ width: '100%' }}>
                    <Button
                        className='submit-button'
                        onClick={e => { handleSubmit() }}
                        children={"Submit"}
                    />
                    <Button
                        className='cancel-button'
                        onClick={onClose}
                        children={"Cancel"}
                    />
                    <div style={{ margin: 'auto' }} />
                </div>
            </DialogActions>
        </Dialog>
    )
}


type LabelCountTotalMap = { [key: string]: { count: number, total: number } }
function makeLabelKeyMap(labels: LabelForRoom[]): LabelCountTotalMap {
    const labelMap: LabelCountTotalMap = {}
    labels.forEach(label => {
        const key = getLabelForRoomFullName(label)
        const cntTotal = labelMap[key]?.total ?? 0
        labelMap[key] = { total: cntTotal + 1, count: 0 }
    })

    return labelMap
}

function findNextNumberForLabel(labelName: string, allLabels: LabelForRoom[]) {
    const takenNumbers = allLabels
        .filter(label => getLabelForRoomName(label) === labelName)
        .map(label => label.labelNumber)

    return findNextAvailableNumber(takenNumbers)
}

function findNextAvailableNumber(takenNumbers: number[]) {
    // If there is no numbers yet, start with 1
    if (takenNumbers.length === 0) return 1;
    const max = Math.max(...takenNumbers);

    // Tries every number less than max to see if it's been taken yet
    for (let tryNumber = 1; tryNumber < max; tryNumber++) {
        if (!takenNumbers.includes(tryNumber)) return tryNumber;
    }

    // No gaps within the available number set, max + 1
    return max + 1;
}

interface LabelForRoomEditorProps {
    labels: LabelForRoom[],
    setLabels: (newLabels: LabelForRoom[]) => void,
    allLabels: LabelForRoom[],
    includeClo: boolean
}
export function LabelForRoomEditor({ labels, setLabels, allLabels, includeClo }: LabelForRoomEditorProps) {
    const { data, loading } = useGetRoomLabelOptionsQuery()
    
    let loadedOptions: RoomLabelOption[];
    if (includeClo) {
        loadedOptions = [...(data?.roomLabelOptions ?? [])]
    } else {
        loadedOptions = [...(data?.roomLabelOptions.filter(l => l.label !== "CLO") ?? [])]
    }

    function handleAddAreaClick(option: RoomLabelOption) {
        const newOption: LabelForRoom = {
            id: -1,
            roomId: -1,
            label: option,
            customName: null,
            hasCloset: false,
            labelNumber: findNextNumberForLabel(option.label, allLabels)
        }
        setLabels([...labels, newOption])
    }

    function updateSingleLabel(index: number, newValue?: LabelForRoom) {
        if (newValue === undefined) { //Deleting
            setLabels(labels.filter((l, ind) => ind !== index))
        }
        else { //Updating
            var copy = [...labels]
            copy[index] = { ...newValue, __typename: undefined }
            setLabels(copy)
        }
    }

    const labelMap = makeLabelKeyMap(labels)

    return (
        <div className='flex-row' style={{ width: '40rem' }}>
            <div className='flex-column' style={{ width: '30%', height: '40vh', marginBottom: '0.25rem', overflow: 'hidden scroll' }}>
                {
                    loading &&
                    <Loading />
                }
                {
                    !loading &&
                    loadedOptions.map(
                        x =>
                            <Button
                                onClick={(e) => handleAddAreaClick(x)}
                                style={{ font: 'helvetica', margin: '0.25rem 0' }}
                                variant='contained'
                                key={x.fullLabel}>
                                {x.label}
                            </Button>
                    )
                }
            </div>
            <div className='flex-column' style={{ width: '70%' }}>
                {labels.length > 0 &&

                    <table>
                        <thead>
                            <tr>
                                <th align='center'>
                                    <div className='flex-row-center fill-width'>
                                        <h3>
                                            Room Type
                                        </h3>
                                    </div>
                                </th>
                                {includeClo && (
                                    <th align='center'>
                                        <div className='flex-row-center fill-width'>
                                            <h3>
                                                Closets?
                                            </h3>
                                        </div>
                                    </th>
                                )}
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {labels.map((label, index) => {
                                const key = getLabelForRoomFullName(label)
                                const { count, total } = labelMap[key]
                                const incrCount = count + 1
                                labelMap[key].count = incrCount


                                return <SingleAreaLabelEditor
                                    displayNumber={total > 1 ? incrCount : 0}
                                    setLabel={(value) => updateSingleLabel(index, value)}
                                    label={label}
                                    key={`area-sub-type-list-${index}`} 
                                    includeClo={includeClo}    
                                />
                            })}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

interface SingleAreaLabelEditorProps {
    label: LabelForRoom,
    setLabel: (newLabel?: LabelForRoom) => void,
    displayNumber: number,
    includeClo: boolean
}

function SingleAreaLabelEditor({ label, setLabel, includeClo }: SingleAreaLabelEditorProps) {

    const { hasCloset, customName, label: loadedLabel } = label;
    const isCustom = loadedLabel.label === 'OTH'
    const endAdornment = `${(label.labelNumber > 1 ? `${label.labelNumber} ` : "")}${hasCloset ? "+CLO" : ""}`

    function handleChange(updatedFields: Partial<LabelForRoom>) {

        setLabel({ ...label, ...updatedFields, __typename: undefined });
    }


    return (
        <tr>
            <td align='center' style={{ width: '10rem', padding: '0 1rem' }}>
                <>
                    {
                        isCustom &&
                        <Input
                            value={customName}
                            placeholder='Enter Name'
                            endAdornment={<div style={{ whiteSpace: "nowrap" }}>{endAdornment}</div>}
                            onChange={(x) => handleChange({ customName: x.target.value.toUpperCase() })}
                        />
                    }
                    {
                        !isCustom &&
                        <h4>{loadedLabel?.label}{endAdornment}</h4>
                    }
                </>
            </td>
            {includeClo && (
                <td align='center'>
                    <ToggleButtonGroup value={hasCloset} >
                        <ToggleButton value={true} onClick={() => handleChange({ hasCloset: true })} >Yes</ToggleButton>
                        <ToggleButton value={false} onClick={() => handleChange({ hasCloset: false })} >No</ToggleButton>
                    </ToggleButtonGroup>
                </td>
            )}
            <td>
                <IconButton onClick={() => setLabel(undefined)} ><ClearIcon /></IconButton>
            </td>
        </tr>
    )
}

