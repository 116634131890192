import { manualInputId, TypeableSelect } from "Components/Inputs/TypeableSelect"
import { useGetProductColorOptionsQuery, useGetProductSpecForStyleLazyQuery } from "generated/graphql"
import { selectStyleId, selectColor, setColor, setColorVariantSpecValues } from "Redux/addProductReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"
import { bToFProductSpec } from "../Conversions"

export default function ColorEditor() {
    const dispatch = useAppDispatch()
    const styleId = useAppSelector(selectStyleId)
    const { id, value } = useAppSelector(selectColor)
    const { data } = useGetProductColorOptionsQuery({ variables: { styleId }, skip: styleId <= 0 })
    const [loadStyleSpec] = useGetProductSpecForStyleLazyQuery({onCompleted(data) { 
        dispatch(setColorVariantSpecValues(bToFProductSpec(data.productSpecForStyle)))
    } })

    function updateValue(newId?: number, newValue?: string) {
        if (newId === manualInputId && id !== manualInputId) {
            //Transitioning into "add new color mode"
            if(styleId > 0) loadStyleSpec({ variables: { styleId }})
        }
        dispatch(setColor({ id: newId, value: newValue }))
    }

    const options = data?.productColorOptions ?? []

    return (
        <div className="flex-column" style={{ minWidth: "10rem" }}>
            <label htmlFor="color">Private Label Color:</label>
            <TypeableSelect
                selectedId={id}
                typedValue={value}
                setValue={updateValue}
                options={options.map(o => ({ id: o.id, value: o.color }))}
                disabled={styleId <= 0}
                name="color"
            />
        </div>
    )
}