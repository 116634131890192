import { sumArray } from "Globals/Helpers";
import { RoomServiceDetail } from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/BreakdownTableUtils";
import { ServiceMaterialDetails } from "./BuildUpServiceEditorForRoom";

interface ServiceForRoomPriceTextProps {
    originalService: RoomServiceDetail | null; // will be null if service is newly added
    editableService: RoomServiceDetail;
}

export default function ServiceForRoomPriceText({
    originalService,
    editableService
}: ServiceForRoomPriceTextProps) {
    const effectiveOriginalPrice = originalService
        ? (originalService.customerDoesService ? 0 : originalService.price)
        : 0;
    const effectiveEditablePrice = editableService.customerDoesService ? 0 : editableService.price;
    const deltaPrice = +(effectiveEditablePrice - effectiveOriginalPrice).toFixed(2);
    
    let displayPrice, prefix;
    let textClassName = "flat-font margin-none"
    if (deltaPrice === 0) {
        displayPrice = editableService.price;
        prefix = "$";
    } else if (deltaPrice > 0) {
        displayPrice = deltaPrice;
        prefix = "+$";
        textClassName += " success-text-important";
    } else {
        displayPrice = deltaPrice * -1;  // make it positive
        prefix = "-$";
        textClassName += " error-text-important";
    }
    
    const displayText = `${prefix}${displayPrice.toFixed(2)}`;
    
    return (
        <div style={{ position: "relative", zIndex: "9px" }}>
            <p className={textClassName}>{displayText}</p>
        </div>
    );
}

interface BuildUpServiceForRoomPriceTextProps {
    originalServices: ServiceMaterialDetails[] | null; // will be null if service is newly added
    editableServices: ServiceMaterialDetails[];
}

export function BuildUpServiceForRoomPriceText({
    originalServices,
    editableServices
}: BuildUpServiceForRoomPriceTextProps) {
    const effectiveOriginalPrice = originalServices
        ? sumArray(originalServices.filter(s => !s.isDeleted).map(s => s.price))
        : 0;
    const effectiveEditablePrice = sumArray(editableServices.filter(s => !s.isDeleted).map(s => s.price));
    const deltaPrice = +(effectiveEditablePrice - effectiveOriginalPrice).toFixed(2);
    
    let displayPrice, prefix;
    let textClassName = "flat-font margin-none"
    if (deltaPrice === 0) {
        displayPrice = effectiveEditablePrice;
        prefix = "$";
    } else if (deltaPrice > 0) {
        displayPrice = deltaPrice;
        prefix = "+$";
        textClassName += " success-text-important";
    } else {
        displayPrice = deltaPrice * -1;  // make it positive
        prefix = "-$";
        textClassName += " error-text-important";
    }

    const displayText = `${prefix}${displayPrice.toFixed(2)}`;

    return (
        <div style={{ position: "relative", zIndex: "9px" }}>
            <p className={textClassName}>{displayText}</p>
        </div>
    );
}