import { CircularProgress, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import { ChatMessageInput, ChatMessageSentDocument, ChatMessageSentSubscription, GetChatQuery, useChatMessageSentSubscription, useClearNewMessageStatusMutation, useGetChatQuery, useSendMessageMutation } from "generated/graphql";
import { isEmptyString, isNotNullOrUndefined } from "Globals/GenericValidators";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import ChatActions from "./ActionComponents/ChatActions";
import { ChatProps, NewMessageIcon } from "./Chat";
import './chat.css';
import { SendMessageButton } from "./ChatButton";
import ChatMessageDisplay, { ChatMessageDisplayProps, prepareMessageForDisplay } from "./ChatMessage";

export interface ChatWindowProps extends ChatProps {
    chatId: number;
    closeWindow: () => void;
    disabled: boolean;
    hasNewMessage: boolean;
    inDrawer?: boolean
}

export const ChatIdContext = createContext(-1);

export default function ChatWindow({
    chatId,
    sendingWorker: thisWorker,
    actionComponent,
    closeWindow,
    sendingMember: thisMember,
    title,
    subtitle,
    disabled,
    hasNewMessage,
    inDrawer = false
}: ChatWindowProps) {
    const [newMessageText, setNewMessageText] = useState('');
    const {data: chatData, subscribeToMore: subscribeToMessages} = useGetChatQuery({
        variables: {chatId: chatId},
        skip: chatId < 0,
        fetchPolicy: "network-only"
    });
    const messages: ChatMessageDisplayProps[] =
        chatData?.chatMessages.messages.map(message => prepareMessageForDisplay(message, thisMember.chatPartyId, thisWorker.id)) ?? [];

    const hasSubtitle = isNotNullOrUndefined(subtitle);

    // scroll to bottom on initial load and when a new message is received
    useEffect(() => {
        if (chatData?.chatMessages) {
            const chatBody = document.getElementById(`chat-body-${chatId}`)
            if (chatBody) {
                setTimeout(() => { chatBody!.scrollTop = chatBody!.scrollHeight }, 250);
            }
        }
    }, [chatData?.chatMessages, chatId])

    // the "loading" var from the mutation doesn't get unset when it's done, so do it manually
    const [sending, setSending] = useState(false);
    const [sendMessage] = useSendMessageMutation({
        onError: () => alert("Could not send message")
    });

    useChatMessageSentSubscription({
        variables: {
            chatId: chatId
        },
        skip: chatId < 1
    });

    const listenForMessages = useCallback(() => {
        if (subscribeToMessages && chatId > 0) {
            subscribeToMessages({
                document: ChatMessageSentDocument,
                variables: { chatId: chatId },
                updateQuery: (prev, { subscriptionData }) => {
                    const newMessage = (subscriptionData.data as unknown as ChatMessageSentSubscription).chatMessageSent;
                    if (!newMessage) { return prev; }
                    const updatedQuery: GetChatQuery = {
                        ...prev!,
                        chatMessages: {
                            ...prev.chatMessages,
                            messages: [...prev.chatMessages.messages, {...newMessage}]
                        },
                    };

                    return updatedQuery;
                },
            });
        }
    }, [subscribeToMessages, chatId]);

    useEffect(() => {
        if (listenForMessages) {
            listenForMessages();
        }
    }, [listenForMessages]);

    function canSendMessage() {
        let trimmedText = newMessageText.trim();
        if (isEmptyString(trimmedText)) {
            alert("Enter a message");
            return false;
        }

        // don't want operation strings being manually typed by user
        let startsWithOperation = false;
        Object.values(ChatActions).forEach(op => {
            if (trimmedText.toLowerCase().startsWith(op.toLowerCase())) {
                alert(`Message may not begin with "${op}"`);
                startsWithOperation = true;
            }
        });

        if (chatId < 1) {
            alert("Waiting for connection - try sending message again");
            return false;
        }

        return !startsWithOperation;
    }

    function onSendMessage() {
        if (canSendMessage()) {
            let newMsg: ChatMessageInput = {
                id: -1,
                chatId: chatId,
                sendingPartyId: thisMember.chatPartyId,
                sendingWorkerId: -1, // determined on backend through authentication
                senderFirstName: "", // name is only used when retrieving messages
                senderLastName: "",
                text: newMessageText.trim(),
                received: false
            };
            setSending(true);
            sendMessage({
                variables: { message: newMsg }
            }).then(res => {
                setSending(false);
                if (res.data?.sendMessage) {
                    // message is added to the chat window because it is retrieved from the subscription                
                    setNewMessageText("");
                }
            });
        }
    }

    const [markMessagesRead] = useClearNewMessageStatusMutation({
        variables: ({chatId: chatId, partyId: thisMember.chatPartyId}),
    });
    function onInteract() {
        if (hasNewMessage) {
            markMessagesRead();
        }
    }

    // for <Draggable>
    const nodeRef = useRef(null);

    return (
        // https://blog.logrocket.com/create-draggable-components-react-draggable/
        <Draggable handle=".drag-handle" nodeRef={nodeRef}>
            <div
                className={clsx("chat-window flat-box-shadow", {"chat-window-free": !inDrawer, "chat-window-contained": inDrawer})}
                ref={nodeRef}
                onClick={onInteract}
            >
                <div className="chat-header align-items-center drag-handle">
                    <span
                        className="chat-header-new-message-icon-container fit-content"
                        style={{visibility: hasNewMessage ? "visible" : "hidden"}}
                    >
                        <NewMessageIcon style={{width: "35px", height: "35px", boxShadow: "none", border: "1px solid var(--flat-gray-3)"}}/>
                    </span>
                    
                    {hasSubtitle ? (
                        <span className="flex-column margin-top-sm align-items-center">
                            <p className="chat-title-condensed">{title}</p>
                            <p className="chat-subtitle">{subtitle}</p>
                        </span>
                    ) : (
                        <p className="chat-title-full margin-top-sm">{title}</p>
                    )}
                    
                    <span id="chat-close-button" className="clickable fit-content" onClick={closeWindow} onTouchStart={closeWindow}>
                        <ExpandMoreIcon/>
                    </span>
                </div>

                {(actionComponent && (chatId > 0) && !disabled) && (
                    <ChatIdContext.Provider value={chatId}>
                        {actionComponent}                
                    </ChatIdContext.Provider>
                )}

                <div className="chat-body" id={`chat-body-${chatId}`}>
                    {messages.length > 0 ? (
                        <>
                            {messages.sort((m1, m2) => m1.id - m2.id)
                                .map(message => <ChatMessageDisplay {...message} key={message.id}/>)
                            }
                        </>
                    ) : (
                        <Typography>No messages</Typography>
                    )}
                </div>

                <div className="message-input-container">
                    <input
                        disabled={disabled || sending}
                        placeholder="Enter message..."
                        className="chat-input"
                        value={newMessageText}
                        onChange={(e) => setNewMessageText(e.target.value)}
                    />

                    {sending ? (
                        <CircularProgress />
                    ) : (
                        <SendMessageButton
                            onClick={onSendMessage}
                            disabled={isEmptyString(newMessageText.trim()) || disabled}
                        />
                    )}
                </div>
            </div>
        </Draggable>
    )
}