import GalleryImage from "Components/ImageGallery/GalleryImage";
import { buildGetPictureURL } from "Globals/restAPIHelpers";
import { RoomGalleryImageBaseProps } from "../JobPictureViewer";


interface RoomGalleryImageProps extends RoomGalleryImageBaseProps {
    deleteImage?: () => void;
    mobile?: boolean;
}

export function RoomGalleryImage({ pictureId, deleteImage, mobile, ...passThrough}: RoomGalleryImageProps) {
    return (
        <GalleryImage {...passThrough} imageSrc={buildGetPictureURL(pictureId)} onDeleteImage={deleteImage} mobile={mobile} />
    )
}