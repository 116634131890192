import clsx from "clsx";
import NavbarPage from "Components/Page";
import FlatButton from "FlatComponents/Button/FlatButton";
import { FlatCircleCheckIcon } from "FlatComponents/Button/FlatCircleCheckButton";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import { FlatLabeledPercentInput } from "FlatComponents/Inputs/FlatPercentInput";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { ContractorRatingParameters, ContractorRatingParametersInput, namedOperations, useGetAllContractorRatingParameterSetsQuery, useSetContractorRatingParametersMutation } from "generated/graphql";
import { removeTypeName } from "Globals/dataPreparationUtils";
import { dateTimeStrToMdy, todayAsIso } from "Globals/DateAndTimeHelpers";
import { isEmptyString } from "Globals/GenericValidators";
import { useState } from "react";
import NumberFormat from "react-number-format";

const emptyRatingParameters: EditableContractorRatingParameters = {
    numAppointments: "0",
    numServices: "0",
    calledInAmWeight: "0",
    checkedInWeight: "0",
    onTimeWeight: "0",
    collectedCodWeight: "0",
    completedJobWeight: "0",
    completionCertificateSignedWeight: "0",
    completionCertificateRatingWeight: "0",
    serviceRequestsWeight: "0",
    servicesCompletedOnTimeWeight: "0"
}

interface EditableContractorRatingParameters {
    numAppointments: string;
    numServices:  string;
    calledInAmWeight: string;
    checkedInWeight: string;
    onTimeWeight: string;
    collectedCodWeight: string;
    completedJobWeight: string;
    completionCertificateSignedWeight: string;
    completionCertificateRatingWeight: string;
    serviceRequestsWeight: string;
    servicesCompletedOnTimeWeight: string;
};

function paramsToEditable(params: ContractorRatingParameters): EditableContractorRatingParameters {
    return {
        numAppointments: params.numAppointments.toString(),
        numServices: params.numServices.toString(),
        calledInAmWeight: (params.calledInAmWeight * 100).toString(),
        checkedInWeight: (params.checkedInWeight * 100).toString(),
        onTimeWeight: (params.onTimeWeight * 100).toString(),
        collectedCodWeight: (params.collectedCodWeight * 100).toString(),
        completedJobWeight: (params.completedJobWeight * 100).toString(),
        completionCertificateSignedWeight: (params.completionCertificateSignedWeight * 100).toString(),
        completionCertificateRatingWeight: (params.completionCertificateRatingWeight * 100).toString(),
        serviceRequestsWeight: (params.serviceRequestsWeight * 100).toString(),
        servicesCompletedOnTimeWeight: (params.servicesCompletedOnTimeWeight * 100).toString()
    };
}

function editableToParams(params: EditableContractorRatingParameters): ContractorRatingParameters {
    return {
        id: -1,
        startDate: todayAsIso(), // not used (defaults to NOW() on insert), but this field isn't nullable
        numAppointments: isEmptyString(params.numAppointments) ? 0 : +params.numAppointments,
        numServices:  isEmptyString(params.numServices) ? 0 : +params.numServices,
        calledInAmWeight: isEmptyString(params.calledInAmWeight) ? 0 : (+params.calledInAmWeight / 100),
        checkedInWeight: isEmptyString(params.checkedInWeight) ? 0 : (+params.checkedInWeight / 100),
        onTimeWeight: isEmptyString(params.onTimeWeight) ? 0 : (+params.onTimeWeight / 100),
        collectedCodWeight: isEmptyString(params.collectedCodWeight) ? 0 : (+params.collectedCodWeight / 100),
        completedJobWeight: isEmptyString(params.completedJobWeight) ? 0 : (+params.completedJobWeight / 100),
        completionCertificateSignedWeight: isEmptyString(params.completionCertificateSignedWeight) ? 0 : (+params.completionCertificateSignedWeight / 100),
        completionCertificateRatingWeight: isEmptyString(params.completionCertificateRatingWeight) ? 0 : (+params.completionCertificateRatingWeight / 100),
        serviceRequestsWeight: isEmptyString(params.serviceRequestsWeight) ? 0 : (+params.serviceRequestsWeight / 100),
        servicesCompletedOnTimeWeight: isEmptyString(params.servicesCompletedOnTimeWeight) ? 0 : (+params.servicesCompletedOnTimeWeight / 100)
    };
}

function sumWeights(params: ContractorRatingParameters): number {
    const {id, numAppointments, numServices, startDate, endDate, ...weights} = params;
    return +((Object.values(weights).reduce((prev, next) => (+prev) + (+next)) as number).toFixed(2));
}

export default function ContractorRatingParameterEditor() {
    const [currentParamSet, setCurrentParamSet] = useState(emptyRatingParameters);
    const {data: paramsData} = useGetAllContractorRatingParameterSetsQuery({
        onError: () => alert("Failed to load rating parameters"),
        onCompleted: (data) => {
            const paramSets = data.allContractorRatingParameterSets;
            if (paramSets.length > 0) {
                setCurrentParamSet(paramsToEditable(paramSets[0]))
            }
        }
    });
    const historicParamSets = (paramsData?.allContractorRatingParameterSets ?? []).slice(1);

    const weightSum = sumWeights(editableToParams(currentParamSet));

    const [updateSucceeded, setUpdateSucceeded] = useState(false);
    // the "loading" flag from the mutation doesn't get set to false on complete for some reason, so do it manually
    const [settingParams, setSettingParams] = useState(false);
    const [setParams] = useSetContractorRatingParametersMutation({
        onCompleted: (res) => {
            if (res.setContractorRatingParameters) {
                setSettingParams(false);
                setUpdateSucceeded(true);
                setTimeout(() => setUpdateSucceeded(false), 3000);
            }
        },
        onError: () => alert("Failed to set contractor rating parameters"),
        refetchQueries: [namedOperations.Query.GetAllContractorRatingParameterSets],
    });

    function validateAndPrepareData(): ContractorRatingParametersInput | undefined {
        const preparedParams = editableToParams(currentParamSet);

        if (preparedParams.numAppointments === 0) {
            alert("Choose the number of appointments used to calculate the rating");
            return undefined
        }

        if (preparedParams.numServices === 0) {
            alert("Choose the number of services used to calculate the rating");
            return undefined
        }

        const weightSum = sumWeights(preparedParams);
        if (weightSum !== 1) {
            alert(`Parameters weights must add up to 100% - they currently add up to ${weightSum * 100}%`);
            return undefined;
        }

        return removeTypeName(preparedParams);
    }

    function onSubmit() {
        const preparedParams = validateAndPrepareData();
        if (preparedParams) {
            setSettingParams(true);
            setParams({variables: {parameters: preparedParams}});
        }
    }

    return (
        <NavbarPage hideNavbar className="flat-page" centerHorizontally>
            <FlatNavbar title="Contractor Profile"/>
            <div className="flat-page-content padding-top-sm padding-bottom-sm flex-column flex-gap-sm">
                <FlatSection header={"Contractor Rating Settings"}>
                    <div className="grid-33-33-33 flex-row-gap-sm">
                        <NumberFormat
                            customInput={FlatLabeledInput}
                            label="Number of appointments"
                            value={currentParamSet.numAppointments}
                            onValueChange={v => setCurrentParamSet({...currentParamSet, numAppointments: v.value})}
                            className="w-2r text-align-center"
                            decimalScale={0} fixedDecimalScale
                            allowNegative={false}
                        />
                        <NumberFormat
                            customInput={FlatLabeledInput}
                            label="Number of services"
                            value={currentParamSet.numServices}
                            onValueChange={v => setCurrentParamSet({...currentParamSet, numServices: v.value})}
                            className="w-2r text-align-center"
                            decimalScale={0} fixedDecimalScale
                            allowNegative={false}
                        />
                        <FlatLabeledPercentInput
                            value={(weightSum * 100).toString()}
                            label="Sum of Weights"
                            className={clsx("w-3r text-align-center", weightSum === 1 ? "success-text" : "error-text")}
                            bold
                        />
                        <FlatLabeledPercentInput
                            label={"Called In Morning"}
                            value={currentParamSet.calledInAmWeight}
                            setValue={v => setCurrentParamSet({...currentParamSet, calledInAmWeight: v ?? "0"})}
                            className={"w-2r text-align-center"}
                            bold
                        />
                        <FlatLabeledPercentInput
                            label={"Checked In"}
                            value={currentParamSet.checkedInWeight}
                            setValue={v => setCurrentParamSet({...currentParamSet, checkedInWeight: v ?? "0"})}
                            className={"w-2r text-align-center"}
                            bold
                        />
                        <FlatLabeledPercentInput
                            label={"On Time"}
                            value={currentParamSet.onTimeWeight}
                            setValue={v => setCurrentParamSet({...currentParamSet, onTimeWeight: v ?? "0"})}
                            className={"w-2r text-align-center"}
                            bold
                        />
                        <FlatLabeledPercentInput
                            label={"Collected COD"}
                            value={currentParamSet.collectedCodWeight}
                            setValue={v => setCurrentParamSet({...currentParamSet, collectedCodWeight: v ?? "0"})}
                            className={"w-2r text-align-center"}
                            bold
                        />
                        <FlatLabeledPercentInput
                            label={"Completed Job"}
                            value={currentParamSet.completedJobWeight}
                            setValue={v => setCurrentParamSet({...currentParamSet, completedJobWeight: v ?? "0"})}
                            className={"w-2r text-align-center"}
                            bold
                        />
                        <FlatLabeledPercentInput
                            label={"Completion Certificate Signed"}
                            value={currentParamSet.completionCertificateSignedWeight}
                            setValue={v => setCurrentParamSet({...currentParamSet, completionCertificateSignedWeight: v ?? "0"})}
                            className={"w-2r text-align-center"}
                            bold
                        />
                        <FlatLabeledPercentInput
                            label={"Completion Certificate Rating"}
                            value={currentParamSet.completionCertificateRatingWeight}
                            setValue={v => setCurrentParamSet({...currentParamSet, completionCertificateRatingWeight: v ?? "0"})}
                            className={"w-2r text-align-center"}
                            bold
                        />
                        <FlatLabeledPercentInput
                            label={"Service Requests"}
                            value={currentParamSet.serviceRequestsWeight}
                            setValue={v => setCurrentParamSet({...currentParamSet, serviceRequestsWeight: v ?? "0"})}
                            className={"w-2r text-align-center"}
                            bold
                        />
                        <FlatLabeledPercentInput
                            label={"Services Completed On Time"}
                            value={currentParamSet.servicesCompletedOnTimeWeight}
                            setValue={v => setCurrentParamSet({...currentParamSet, servicesCompletedOnTimeWeight: v ?? "0"})}
                            className={"w-2r text-align-center"}
                            bold
                        />
                    </div>
                    <div className="flex-row align-items-center margin-top-sm flex-gap-sm">
                        <FlatButton
                            onClick={onSubmit}
                            disabled={settingParams}
                            variant="contained"
                            color="secondary"
                        >Update Parameters</FlatButton>

                        {updateSucceeded && <FlatCircleCheckIcon color="green"/>}
                    </div>
                </FlatSection>

                <ParameterHistorySection history={historicParamSets}/>
            </div>
        </NavbarPage>
    )
}

function ParameterHistorySection({history}: {history: ContractorRatingParameters[]}) {
    return (
        <FlatSection header={"Setting History"} collapsible defaultClosed>
            {history.length === 0 ? (
                <p>No setting history</p>
            ) : (
                <div id="profile-rating-parameter-history">
                    {history.map(h => <ParamHistorySummary key={h.id} params={h}/>)}
                </div>
            )}
        </FlatSection>
    )
}
function ParamHistorySummary({params}: {params: ContractorRatingParameters}) {
    return (
        <div>
            <p className="flat-font-md">
                {dateTimeStrToMdy(params.startDate)} - {dateTimeStrToMdy(params.endDate!)}
            </p>
            <div className="grid-33-33-33 flex-row-gap-sm">
                <NumberFormat
                    customInput={FlatLabeledInput}
                    label="Number of appointments"
                    value={params.numAppointments}
                    className="w-2r text-align-center"
                    decimalScale={0}
                />
                <NumberFormat
                    customInput={FlatLabeledInput}
                    label="Number of services"
                    value={params.numServices}
                    className="w-2r text-align-center"
                    decimalScale={0}
                />
                <div />
                <FlatLabeledPercentInput
                    label={"Called In Morning"}
                    value={(params.calledInAmWeight * 100).toString()}
                    className={"w-2r text-align-center"}
                    bold
                />
                <FlatLabeledPercentInput
                    label={"Checked In"}
                    value={(params.checkedInWeight * 100).toString()}
                    className={"w-2r text-align-center"}
                    bold
                />
                <FlatLabeledPercentInput
                    label={"On Time"}
                    value={(params.onTimeWeight * 100).toString()}
                    className={"w-2r text-align-center"}
                    bold
                />
                <FlatLabeledPercentInput
                    label={"Collected COD"}
                    value={(params.collectedCodWeight * 100).toString()}
                    className={"w-2r text-align-center"}
                    bold
                />
                <FlatLabeledPercentInput
                    label={"Completed Job"}
                    value={(params.completedJobWeight * 100).toString()}
                    className={"w-2r text-align-center"}
                    bold
                />
                <FlatLabeledPercentInput
                    label={"Completion Certificate Signed"}
                    value={(params.completionCertificateSignedWeight * 100).toString()}
                    className={"w-2r text-align-center"}
                    bold
                />
                <FlatLabeledPercentInput
                    label={"Completion Certificate Rating"}
                    value={(params.completionCertificateRatingWeight * 100).toString()}
                    className={"w-2r text-align-center"}
                    bold
                />
                <FlatLabeledPercentInput
                    label={"Service Requests"}
                    value={(params.serviceRequestsWeight * 100).toString()}
                    className={"w-2r text-align-center"}
                    bold
                />
                <FlatLabeledPercentInput
                    label={"Services Completed On Time"}
                    value={(params.servicesCompletedOnTimeWeight * 100).toString()}
                    className={"w-2r text-align-center"}
                    bold
                />
            </div>
        </div>
    )
}