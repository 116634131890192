import FlatSection, { FlatSubSection } from "FlatComponents/Layout/FlatSection";
import { useGetJobConfigurationQuery } from "generated/graphql";
import JobSvgViewer from "Pages/Home/ContractorHome/InstallJob/JobSvgViewer";
import { useAppSelector } from "Redux/hooks";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { selectJobConfigurationId, selectJobContractId } from "Redux/jobTrackerReducer";
import { RoomSvgProps, DetailedRoomSvg } from "../SellSheet/QuoteDetailsEditor/RoomSvg";
import { CARPET_PRODUCT_ID } from "Globals/globalConstants";
import FlatButton from "FlatComponents/Button/FlatButton";
import { useAppHistory } from "Globals/routingHooks";
import { buildAppendedId } from "Globals/Hooks";
import { printMeasurePath, seamPlannerPath } from "Globals/PathStrings";

export default function ContractDrawingViewer() {
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const jobContractId = useAppSelector(selectJobContractId);
    const { data } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId },
        skip: jobConfigurationId < 1,
    });

    const history = useAppHistory();

    const areas = data?.jobConfiguration.areas ?? [];

    const hasCarpet = areas.some((area) => area.productTypeId === CARPET_PRODUCT_ID);

    return (
        <div className="margin-bottom-sm">
            <div className="padding-top-sm margin-bottom-sm">
                <FlatSection
                    removePadding
                    header="Full Drawing"
                    endAdornment={
                        <>
                            <FlatButton
                                onClick={() =>
                                    history.push(
                                        printMeasurePath + buildAppendedId(jobContractId)
                                    )
                                }
                            >
                                Print
                            </FlatButton>
                            {hasCarpet && (
                                <FlatButton
                                    color="secondary"
                                    onClick={() =>
                                        history.push(
                                            seamPlannerPath + buildAppendedId(jobConfigurationId)
                                        )
                                    }
                                >
                                    Seam Planner
                                </FlatButton>
                            )}
                        </>
                    }
                    collapsible
                    className="fill-width"
                >
                    <JobSvgViewer jobConfigurationId={jobConfigurationId}/>
                </FlatSection>
            </div>
            <FlatSection
                header="Individual Rooms"
                collapsible
                defaultClosed
                className="fill-width"
            >
                <div className="grid-50-50 flex-gap-sm padding-top-sm">
                    {areas.flatMap((a, idx) =>
                        a.rooms.map((r) => (
                            <RoomSvgContainer
                                key={r.id}
                                room={r}
                                areaIndex={idx}
                            />
                        ))
                    )}
                </div>
            </FlatSection>
        </div>
    );
}

export function RoomSvgContainer({ room, areaIndex }: RoomSvgProps) {
    return (
        <FlatSubSection header={getNameOfArea(room.labels)}>
            <div className="flex-row align-items-center justify-content-center">
                <DetailedRoomSvg
                    room={room}
                    areaIndex={areaIndex}
                />
            </div>
        </FlatSubSection>
    );
}
