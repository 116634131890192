import { Box, Dialog, IconButton, Tab, Tabs, Typography } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { AfterPictureGallery } from "Pages/Admin/ProjectManagement/Dashboard/JobPictures/TypeSpecific/AfterPictureGallery";
import AfterPictureUploadButton from "Pages/Admin/ProjectManagement/Dashboard/JobPictures/TypeSpecific/AfterPictureUploadButton";
import { BeforePictureGallery } from "Pages/Admin/ProjectManagement/Dashboard/JobPictures/TypeSpecific/BeforePictureGallery";
import BeforePictureUploadButton from "Pages/Admin/ProjectManagement/Dashboard/JobPictures/TypeSpecific/BeforePictureUploadButton";
import { JobPictureGallery } from "Pages/Admin/ProjectManagement/Dashboard/JobPictures/TypeSpecific/JobPictureGallery";
import { useState } from "react";

export type PhotoMode = "Before" | "After" | "Sale";

interface ContractorBeforeAndAfterEditorProps {
    jobConfigurationId: number;
    onUploadPhoto: (mode: PhotoMode) => void;
    openWithPhotoMode: PhotoMode | null;
    onClose: () => void;
}

export default function ContractorBeforeAndAfterEditor({
    jobConfigurationId,
    onUploadPhoto,
    openWithPhotoMode,
    onClose,
}: ContractorBeforeAndAfterEditorProps) {
    const [tabIndex, setTabIndex] = useState<number | null>(null);

    const openToPhotoIndex =
        openWithPhotoMode === "After" ? 2 : openWithPhotoMode === "Before" ? 1 : 0;
    const displayIndex = tabIndex ?? openToPhotoIndex;

    function closeDialog() {
        setTabIndex(null);
        onClose();
    }

    return (
        <Dialog
            open={openWithPhotoMode !== null}
            maxWidth="xl"
            fullScreen
            onClose={closeDialog}
        >
            <div
                style={{ maxHeight: "100vh" }}
                className="fill-width fill-height flex-column"
            >
                <div
                    style={{
                        flex: "0 1 auto",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: ".4em",
                    }}
                >
                    <TabPanel
                        index={0}
                        selectedIndex={displayIndex}
                    >
                        <Typography variant="h5">Sale</Typography>
                    </TabPanel>
                    <TabPanel
                        index={1}
                        selectedIndex={displayIndex}
                    >
                        <Typography variant="h5">Before</Typography>
                    </TabPanel>
                    <TabPanel
                        index={2}
                        selectedIndex={displayIndex}
                    >
                        <Typography variant="h5">After</Typography>
                    </TabPanel>
                    <IconButton
                        onClick={(e) => {
                            closeDialog();
                            e.stopPropagation();
                        }}
                        size="medium"
                    >
                        <HighlightOffIcon
                            style={{ backgroundColor: "#aaaa", borderRadius: "1rem" }}
                        />
                    </IconButton>
                </div>

                <div
                    style={{
                        flex: "2 1 auto",
                        alignItems: "center",
                        overflowY: "auto",
                        msFlex: 1,
                        WebkitFlex: 1,
                    }}
                    className="flex-column"
                >
                    <TabPanel
                        index={0}
                        selectedIndex={displayIndex}
                    >
                        <JobPictureGallery
                            jobConfigurationId={jobConfigurationId}
                            mobile
                            preventDelete
                        />
                    </TabPanel>
                    <TabPanel
                        index={1}
                        selectedIndex={displayIndex}
                    >
                        <BeforePictureGallery
                            jobConfigurationId={jobConfigurationId}
                            mobile
                            preventDelete={openToPhotoIndex !== 1}
                        />
                    </TabPanel>
                    <TabPanel
                        index={2}
                        selectedIndex={displayIndex}
                    >
                        <AfterPictureGallery
                            jobConfigurationId={jobConfigurationId}
                            mobile
                            preventDelete={openToPhotoIndex !== 2}
                        />
                    </TabPanel>
                </div>
                <div
                    className="fill-width flex-column flex-centered"
                    style={{ flex: "0 1 auto", margin: ".5em 0" }}
                >
                    {openToPhotoIndex === 1 && (
                        <TabPanel
                            index={1}
                            selectedIndex={displayIndex}
                        >
                            <BeforePictureUploadButton
                                jobConfigurationId={jobConfigurationId}
                                singlePhoto
                                onPhotosUploaded={(count) => {
                                    if (count > 0) {
                                        onUploadPhoto("Before");
                                    }
                                }}
                            />
                        </TabPanel>
                    )}
                    {openToPhotoIndex === 2 && (
                        <TabPanel
                            index={2}
                            selectedIndex={displayIndex}
                        >
                            <AfterPictureUploadButton
                                jobConfigurationId={jobConfigurationId}
                                singlePhoto
                                onPhotosUploaded={(count) => {
                                    if (count > 0) {
                                        onUploadPhoto("After");
                                    }
                                }}
                            />
                        </TabPanel>
                    )}
                </div>
                <Box
                    sx={{ borderTop: "2px solid gray" }}
                    style={{ flex: "0 1 auto", backgroundColor: "#eee" }}
                >
                    <Tabs
                        style={{ padding: ".25rem" }}
                        value={displayIndex}
                        onChange={(e, index) => setTabIndex(index)}
                        variant="fullWidth"
                    >
                        <Tab label="Sale" />
                        <Tab label="Before" />
                        <Tab label="After" />
                    </Tabs>
                </Box>
            </div>
        </Dialog>
    );
}

function TabPanel({
    selectedIndex,
    index,
    children,
}: React.PropsWithChildren<{ selectedIndex?: number; index: number }>) {
    return <>{selectedIndex === index && children}</>;
}
