export default function DummyLoc() {

    return (
        <>
            {/* <Dialog open={true} maxWidth='lg' scroll='body'>
                <div style={{ padding: '2rem' }}>
                    <PerProductDetailsEditor initialValue={createDetailsPerProductType('Carpet')} />
                </div>
            </Dialog>
            <ProjectDetailsEditor /> */}
            <h1>Dummy Loc</h1>
            <p>This message is here for debugging.</p>
        </>
    );
}