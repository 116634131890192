import { useAppSelector } from "Redux/hooks";
import { selectMSRPScalar } from "Redux/pricingCenterReducer";
import { useGetAllProductStyleQuery, useGetAllServiceMaterialCategoryPricingsQuery, useGetFullyScaledJobConfigurationQuery, useGetJobBreakdownQuery } from "generated/graphql";
import "../Chargeables/FormulaCardStyle.css";

interface BreakdownHoverProps {
    jobConfigurationId: number;
    show: boolean;
}

export default function BreakdownHover({
    jobConfigurationId,
    show
}: BreakdownHoverProps) {

    const { data: fullyScaledJobConfiguration } = useGetFullyScaledJobConfigurationQuery({
        variables: {
            jobConfigurationId
        }
    }) ?? [];

    const { data: areaBreakdowns } = useGetJobBreakdownQuery({
        variables: {
            jobConfigurationId: jobConfigurationId
        }
    }) ?? [];

    const msrpScalar = useAppSelector(selectMSRPScalar);
    const accessoryPricing = useGetAllServiceMaterialCategoryPricingsQuery();
    const productStyles = useGetAllProductStyleQuery();
    //const job = data?.fullyScaledJobConfiguration

    let newWallSubtotal = 0;
    let materialSubtotal = 0;
    let fixedLaborSubtotal = 0;

    return (
        <div className='formula-popper'
            style={{
                visibility: show ? 'visible' : 'hidden',
                color: 'black',
                fontSize: 'smaller',
                fontWeight: 'lighter',
                top: 0,
                left: '25%'
            }}>
            {areaBreakdowns?.jobBreakdown.areaBreakdowns.map((area, areaIndex) => {
                const fullyScaledArea = fullyScaledJobConfiguration?.fullyScaledJobConfiguration.areas.find(fas => fas.id === area.areaId);
                let sqftWasteFactor = fullyScaledArea?.sqftWasteFactor ?? 0;

                let sqft = 0;
                area?.rooms?.map((room) => sqft += room.sqft);
                const product = productStyles.data?.allProductStyle.filter(style => style.productTypeId === area.product.productTypeId && style.styleName === area.product.productStyle)[0];
                const newWallFinishServices = area.services?.filter(service => service.serviceTypeId === 7 );
                const fixedLaborServices = area.services?.filter(service => service.serviceTypeId !== 7 && service.serviceTypeId !== 34 && service.customerDoesService === false);

                materialSubtotal = ((area.productSqft * ((product?.singleCostPerSqft ?? 0) * (product?.pricingFactor ?? 0) + (product?.shippingPrice ?? 0))) * msrpScalar);

                let transitionTotalLnft = fullyScaledJobConfiguration?.fullyScaledJobConfiguration.roomTransitions.reduce((ps, rt) => ps + rt.width, 0) ?? 0;
                const roomTransitionColumns = area.services.find(service => service.serviceTypeId === 34 && service.customerDoesService === false);
                materialSubtotal += (Math.ceil(transitionTotalLnft / (roomTransitionColumns?.materialAmount ?? 0)) * (roomTransitionColumns?.materialAmount ?? 0) * (roomTransitionColumns?.materialPricePerUnit ?? 0) * msrpScalar);
                return (
                    <table key={areaIndex} style={{width: '100%'}}>
                        <thead>
                            <tr style={{backgroundColor: 'black', color: 'white'}}>
                                <th style={{width: '2em', textAlign: 'center'}}>Line</th>
                                <th style={{width: '5em', textAlign: 'center'}}>Room</th>
                                <th style={{width: '10em', textAlign: 'center'}}>Product</th>
                                <th style={{width: '10em', textAlign: 'center'}}>Description</th>
                                <th style={{width: '7em', textAlign: 'center'}}>Unit (sf./pc)</th>
                                <th style={{width: '15em', textAlign: 'center'}}>Formula</th>
                                <th style={{width: '5em', textAlign: 'center'}}>Scale</th>
                                <th style={{width: '5em', textAlign: 'center'}}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th colSpan={8} style={{textAlign: 'center'}}>Product</th>
                            </tr>
                            <tr>
                                <td>{area.lineNum}</td>
                                <td>
                                    <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                        {area.rooms?.map((room, roomIndex) => <li key={roomIndex}>{room.labels[0].label.label}{room.labels[0].labelNumber}</li>)}
                                    </ul>
                                </td>
                                <td>{area.product.productType}</td>
                                <td>{area.product.productStyle}</td>
                                <td style={{textAlign: 'right'}}>{(area.productSqft / (sqftWasteFactor + 1))} + {(sqftWasteFactor * 100).toFixed(0)}% wf</td>
                                <td style={{textAlign: 'center'}}>${((product?.singleCostPerSqft ?? 0) * (product?.pricingFactor ?? 0) + (product?.shippingPrice ?? 0)).toFixed(3)} / sqft</td>
                                <td style={{textAlign: 'center'}}>{msrpScalar}</td>
                                <td style={{textAlign: 'right'}}>${((area.productSqft * ((product?.singleCostPerSqft ?? 0) * (product?.pricingFactor ?? 0) + (product?.shippingPrice ?? 0))) * msrpScalar).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>{area.lineNum}</td>
                                <td>
                                    <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                        {area.rooms?.map((room, roomIndex) => <li key={roomIndex}>{room.labels[0].label.label}{room.labels[0].labelNumber}</li>)}
                                    </ul>
                                </td>
                                <td>{roomTransitionColumns?.serviceType}</td>
                                <td>{roomTransitionColumns?.serviceDescription}</td>
                                <td style={{textAlign: 'right'}}>{transitionTotalLnft} lnft</td>
                                <td style={{textAlign: 'center'}}>{(Math.ceil(transitionTotalLnft / (roomTransitionColumns?.materialAmount ?? 0)) * (roomTransitionColumns?.materialAmount ?? 0))}' * ${roomTransitionColumns?.materialPricePerUnit}</td>
                                <td style={{textAlign: 'center'}}>{msrpScalar}</td>
                                <td style={{textAlign: 'right'}}>${(Math.ceil(transitionTotalLnft / (roomTransitionColumns?.materialAmount ?? 0)) * (roomTransitionColumns?.materialAmount ?? 0) * (roomTransitionColumns?.materialPricePerUnit ?? 0) * msrpScalar).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <th colSpan={7} style={{textAlign: 'right'}}>Product Subtotal</th>
                                <th style={{textAlign: 'right'}}>${materialSubtotal.toFixed(2)}</th>
                            </tr>
                            {/* Materials */}
                            {newWallFinishServices.length > 0 && <tr>
                                <th colSpan={8} style={{textAlign: 'center'}}>Materials</th>
                            </tr>}
                            {newWallFinishServices.map((newWall, newWallIndex) => {
                                newWallSubtotal += newWall.laborAmount * (newWall.customerDoesService ? newWall.materialPricePerUnit : newWall.materialPricePerUnit + newWall.laborPricePerUnit) * msrpScalar;
                                return (
                                    <tr key={newWallIndex}>
                                        <td>{area.lineNum}</td>
                                        <td>{newWall.room.labels[0].label.label}{newWall.room.labels[0].labelNumber}</td>
                                        <td>{newWall.serviceType}</td>
                                        <td>{newWall.serviceDescription}</td>
                                        <td style={{textAlign: 'right'}}>{(newWall.laborAmount / (1 + newWall.lnftScaleFactor))} + {(newWall.lnftScaleFactor * 100)}% wf</td>
                                        <td style={{textAlign: 'center'}}>${newWall.materialPricePerUnit.toFixed(2)} {!newWall.customerDoesService && "+ $" + newWall.laborPricePerUnit.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{msrpScalar}</td>
                                        <td style={{textAlign: 'right'}}>${(newWall.laborAmount * (newWall.customerDoesService ? newWall.materialPricePerUnit : newWall.materialPricePerUnit + newWall.laborPricePerUnit) * msrpScalar).toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                            {newWallFinishServices.length > 0 && <tr>
                                <th colSpan={7} style={{textAlign: 'right'}}>Material Subtotal</th>
                                <th style={{textAlign: 'right'}}>${newWallSubtotal.toFixed(2)}</th>
                            </tr>}
                            {/* Fixed Labor */}
                            {fixedLaborServices.length > 0 && <tr>
                                <th colSpan={8} style={{textAlign: 'center'}}>Fixed Labor</th>
                            </tr>}
                            {fixedLaborServices.map((fixedLabor, fixedLaborIndex) => {
                                let laborPricePerUnit: number = fixedLabor.laborPricePerUnit ?? 0;
                                if (laborPricePerUnit <= 0) {
                                    return null;
                                }
                                if (laborPricePerUnit <= 0) {
                                    const accessoryCost = accessoryPricing.data?.allServiceMaterialCategoryPricings.filter(accessory => (
                                        accessory.serviceMaterialCategoryId === fixedLabor.materialCategoryId &&
                                        accessory.serviceMaterialCategory === fixedLabor.materialCategoryName &&
                                        accessory.productType === area.product.productType
                                    ))[0];
                                    laborPricePerUnit = accessoryCost?.pricePerUnit ?? 0;
                                }
                                fixedLaborSubtotal += (fixedLabor.laborAmount * laborPricePerUnit * msrpScalar);
                                return (
                                    <tr key={fixedLaborIndex}>
                                        <td>{area.lineNum}</td>
                                        <td>{fixedLabor.room.labels[0].label.label}{fixedLabor.room.labels[0].labelNumber}</td>
                                        <td>{fixedLabor.serviceType}</td>
                                        <td>{fixedLabor.serviceDescription}</td>
                                        <td style={{textAlign: 'right'}}>{fixedLabor.laborAmount}</td>
                                        <td style={{textAlign: 'center'}}>{fixedLabor.laborAmount} * ${laborPricePerUnit.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{msrpScalar}</td>
                                        <td style={{textAlign: 'right'}}>${(fixedLabor.laborAmount * laborPricePerUnit * msrpScalar).toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <th colSpan={7} style={{textAlign: 'right'}}>Fixed Labor Subtotal</th>
                                <th style={{textAlign: 'right'}}>${fixedLaborSubtotal.toFixed(2)}</th>
                            </tr>
                        </tbody>
                    </table>
                );
            })}
            <div style={{textAlign: 'right', fontWeight: 'bolder'}}>MSRP = ${(materialSubtotal + newWallSubtotal + fixedLaborSubtotal).toFixed(2)}</div>
        </div>
    );
}