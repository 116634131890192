import { FullyCalculatedPerformanceSummary } from "./SalesPersonProfile/SalespersonPerformanceSummaryTable";

export default function ProductTypeStatisticsTable({summary}: {summary: FullyCalculatedPerformanceSummary}) {
    return (
        <table className="sp-summary-product-type-statistics-table">
            <thead>
            <tr>
                <td></td>
                <td>
                    <p className="flat-font margin-none bold-text"># Demos</p>
                </td>
                <td>
                    <p className="flat-font margin-none bold-text"># Sold</p>
                </td>
                <td>
                    <p className="flat-font margin-none bold-text"># Quoted</p>
                </td>
                <td>
                    <p className="flat-font margin-none bold-text"># Cancelled</p>
                </td>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p className="flat-font margin-none bold-text">Wood</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numWoodDemos}</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numWoodSold}</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numWoodQuoted}</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numWoodCancelled}</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className="flat-font margin-none bold-text">SPC</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numSpcDemos}</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numSpcSold}</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numSpcQuoted}</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numSpcCancelled}</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className="flat-font margin-none bold-text">Carpet</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numCarpetDemos}</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numCarpetSold}</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numCarpetQuoted}</p>
                    </td>
                    <td>
                        <p className="flat-font margin-none">{summary.numCarpetCancelled}</p>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}