import clsx from "clsx";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { ContractorProfile, namedOperations, useGetContractorProfileQuery } from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import { formatNameStringFirstLast, formatNameStringLastFirst } from "Globals/StringFormatting";
import { useState } from "react";
import ProfileImage from "../GeneralProfileComponents/ProfileImage";
import { BubbleSummaryColumn, ProfileCompanyInfoColumn, ProfileSummaryColumn } from "../GeneralProfileComponents/SummaryTables";
import { BackgroundCheckTable } from "../GeneralProfileComponents/Tables/BackgroundCheckTable";
import LiabilityInsuranceTable from "../GeneralProfileComponents/Tables/LiabilityInsuranceTable";
import W9Table from "../GeneralProfileComponents/Tables/W9Table";
import WorkerHistoryTable from "../GeneralProfileComponents/Tables/WorkerHistoryTable";
import WorkersCompTable from "../GeneralProfileComponents/Tables/WorkersCompTable";
import WorkerActiveSwitch from "../GeneralProfileComponents/WorkerActiveSwitch";
import ContractorServiceAreaEditor from "./ContractorServiceAreaEditor";
import EditContractorDialog from "./EditContractorDialog";
import ContractorRatingHistoryTable from "./Tables/ContractorRatingHistoryTable";
import HelpersTable from "./Tables/HelpersTable";
import SkillsTable, { BubbleSkillsTable } from "./Tables/SkillsTable";

export default function ContractorProfilePage() {
    const workerId = useNumericIdParam().id;

    const {data} = useGetContractorProfileQuery({
        variables: {workerId: workerId!},
        skip: isNullOrUndefined(workerId),
        onError: () => alert("Could not load contractor information"),
        fetchPolicy: "network-only"
    });
    const contractor = data?.contractorProfile;
    const contractorName = isNullOrUndefined(contractor) ? "" : formatNameStringLastFirst({firstName: contractor!.firstName, lastName: contractor!.lastName});

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const sectionHeader = (
        isNotNullOrUndefined(contractor) ? (
            <span className="flex-row flex-gap-xsm">
                <p className="margin-none">{contractorName}</p>
                <FlatEditButton onClick={() => setEditDialogOpen(true)}/>
            </span>
        ) : (<></>)
    );

    const activeSwitch = (
        isNotNullOrUndefined(contractor) ? (
            <WorkerActiveSwitch
                workerId={contractor!.workerId}
                isActive={contractor!.isActive}
                toRefetch={namedOperations.Query.GetContractorProfile}
            />
        ) : (<></>)
    );

    return (<>
        <NavbarPage hideNavbar className="flat-page" centerHorizontally>
            <FlatNavbar title="Contractor Profile"/>
            <div className="flat-page-content padding-top-sm padding-bottom-sm flex-column flex-gap-sm">
                <FlatSection
                    header={sectionHeader}
                    endAdornment={activeSwitch}
                >
                    {isNullOrUndefined(contractor) ? (
                        <Loading />
                    ) : (
                        <div>
                            <div id="profile-grid">
                                <div id="profile-grid-left-column">
                                    <ProfileSummaryColumn
                                        profile={contractor!}
                                        toRefetch={namedOperations.Query.GetContractorProfile}
                                        showLicenseField
                                    />
                                    <BackgroundCheckTable
                                        backgroundChecks={contractor!.backgroundChecks}
                                        workerId={contractor!.workerId}
                                        toRefetchOnUpdate={[namedOperations.Query.GetContractorProfile]}
                                    />
                                    <SkillsTable
                                        contractorId={contractor!.id}
                                        capabilities={contractor?.capabilities ?? []}
                                    />
                                    <ContractorRatingHistoryTable contractorId={contractor!.id}/>
                                </div>

                                <div id="profile-grid-right-column">
                                    <ProfileCompanyInfoColumn profile={contractor!}/>
                                    <W9Table
                                        workerId={contractor!.workerId}
                                        w9s={contractor?.w9s ?? []}
                                        toRefetch={namedOperations.Query.GetContractorProfile}
                                    />
                                    <WorkersCompTable
                                        workerId={contractor!.workerId}
                                        wcs={contractor?.workersComps ?? []}
                                        toRefetch={namedOperations.Query.GetContractorProfile}
                                    />
                                    <LiabilityInsuranceTable
                                        workerId={contractor!.workerId}
                                        liabilityInsurances={contractor?.liabilityInsurances ?? []}
                                        toRefetch={namedOperations.Query.GetContractorProfile}
                                    />
                                    <WorkerHistoryTable
                                        history={contractor?.workerHistory ?? undefined}
                                        workerName={contractorName}
                                        toRefetch={[namedOperations.Query.GetContractorProfile]}
                                    />
                                    <HelpersTable helpers={contractor!.helpers} contractorId={contractor!.id}/>
                                </div>
                            </div>
                            
                            <ContractorServiceAreaEditor contractorId={contractor!.id} servicedZips={contractor!.servicedZips} />
                        </div>
                    )}
                </FlatSection>
            </div>
        </NavbarPage>

        {(editDialogOpen && isNotNullOrUndefined(contractor)) && 
            <EditContractorDialog contractor={contractor!} onClose={() => setEditDialogOpen(false)}/>
        }
    </>)
}

export function ContractorProfileBubble({contractor}: {contractor: ContractorProfile}) {
    const helperString = contractor.helpers.length === 0
        ? "-"
        : contractor.helpers.map(h => formatNameStringFirstLast({firstName: h.firstName, lastName: h.lastName})).join(", ");

    return (
        <div style={{width: "50vw", height: "fit-content"}}>
            <FlatSection
                header={formatNameStringLastFirst({firstName: contractor.firstName, lastName: contractor.lastName})}
                centerAdornment={contractor.company?.name ?? ""}
                endAdornment={<WorkerStatusIcon active={contractor.isActive}/>}
            >
                <div className="flex-row flex-gap-md flex-grow justify-content-center">
                    <ProfileImage
                        workerId={contractor.workerId}
                        photoId={contractor.profilePictureFileId ?? undefined}
                        rating={contractor.rating}
                        toRefetch={namedOperations.Query.GetContractorProfile}
                    />
                    <div className="flex-column flex-grow">
                        <BubbleSummaryColumn contractor={contractor!}/>
                        <BubbleSkillsTable
                            contractorId={contractor.id}
                            capabilities={contractor.capabilities.filter(capability => capability.isActive)}
                        />
                        <div className="flex-row flex-gap-sm">
                            <p className="flat-font flex-gap-sm">Helpers</p>
                            <p className="flat-font">{helperString}</p>
                        </div>
                    </div>
                </div>
            </FlatSection>
        </div>
    )
}

function WorkerStatusIcon({active}: {active: boolean}) {
    return (
        <span className={clsx("worker-status-icon", {
                "worker-status-icon-active": active,
                "worker-status-icon-inactive": !active}
            )
        }/>
    )
}