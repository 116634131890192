import { StandardTextFieldProps, Typography } from "@material-ui/core"
import clsx from "clsx"

interface ReadonlyMoneyTextFieldProps extends Omit<StandardTextFieldProps, "value">{
    value: number | undefined
}

export default function ReadonlyMoneyTextField({value, className} : ReadonlyMoneyTextFieldProps){

    const display = (value ?? 0)

    return (
        <Typography className={clsx(className)}>
            {`$${display.toFixed(2)}`}
        </Typography>
    )
}