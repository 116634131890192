import { AppliedDiscount, GetJobBreakdownQuery, LabelForRoom, ServiceForRoomInput } from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { INSTALLATION_ID } from "Globals/globalConstants";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { Diff, DiffTypeEnum } from "../ChangeOrder/DiffList";

export type Breakdowns = GetJobBreakdownQuery['jobBreakdown']['areaBreakdowns'];

export interface BreakdownTableProps {
    title?: string
    areaBreakdowns: Breakdowns;
}

export type AreaServices = GetJobBreakdownQuery['jobBreakdown']['areaBreakdowns'][number]['services'];

export interface ServiceMaterialGroups {
    [key: number]: {name: string, pricingUnit: string, materialAmount: number};
}

interface ServiceGroupAmount {
    serviceType: string;
    laborAmount: number;
    laborUnit: string
    description: string;
    labels: LabelForRoom[];
}

interface WhoDoesGroupByParty {
    WOF?: ServiceGroupAmount;
    Cust?: ServiceGroupAmount;
    None?: ServiceGroupAmount;
    OverExisting?: ServiceGroupAmount;
}

export interface WhoDoesServiceGroups {
    [jobServiceId: number]: WhoDoesGroupByParty
}

function groupServicesByWhoDoes(services: AreaServices): WhoDoesServiceGroups {
    let groupings: WhoDoesServiceGroups = {};

    services.forEach(s => {
        let jsId = s.jobServiceId;
        let whoDoes: keyof WhoDoesGroupByParty = s.serviceDescription === "None" ? "None" : (s.customerDoesService ? "Cust" : "WOF");
        let laborAmt = s.laborAmount;
        if (Object.keys(groupings).includes(jsId.toString())) {
            let jobServiceEntry = groupings[jsId];
            if (jobServiceEntry[whoDoes] !== undefined) {
                jobServiceEntry[whoDoes]!.laborAmount += laborAmt;
                // prevent adding duplicate labels
                const labelsToAdd = s.room.labels.filter(label => isNullOrUndefined(jobServiceEntry[whoDoes]!.labels.find(existingLabel => existingLabel.id === label.id)))
                jobServiceEntry[whoDoes]!.labels = jobServiceEntry[whoDoes]!.labels.concat(labelsToAdd);
            } else {
                jobServiceEntry[whoDoes] = {
                    serviceType: s.serviceType,
                    laborAmount: laborAmt,
                    laborUnit: s.laborPriceUnit,
                    description: s.serviceDescription,
                    labels: s.room.labels
                }
            }
        } else {
            groupings[jsId] = {
                [whoDoes] : {
                    serviceType: s.serviceType,
                    laborAmount: laborAmt,
                    laborUnit: s.laborPriceUnit,
                    description: s.serviceDescription,
                    labels: s.room.labels
                }
            };
        }
    });

    return groupings;
}

export interface RoomServiceDetail extends ServiceForRoomInput {
    price: number;  // price that this room contributes to the whole group, including discounts
    isDeleted: boolean;  // can only ever be true for new services (added locally, but not saved to DB)
    sqftScaleFactor: number;
    lnftScaleFactor: number;
}

export interface RoomForWhoDoesService {
    id: number;
    labels: LabelForRoom[];
    service: RoomServiceDetail;
}

export interface JobServiceGroup {
    laborAmount: number;  // amount of labor for the group as a whole
    laborPriceUnit: string;
    serviceTypeId: number;
    serviceType: string;
    serviceDescription: string;
    jobServiceId: number;
    price: number;  // price of the grouping as a whole, including discounts
    rooms: RoomForWhoDoesService[]
}

// groups all job services by ID, regardless of the room they're for
export interface JobServiceGroups {
    [jobServiceId: number]: JobServiceGroup
}

interface ServicePriceCalculationDetails {
    laborAmount: number;
    minimumLaborAmount?: number | null;
    laborPricePerUnit: number;
    materialAmount?: number | null;
    materialPricePerUnit?: number | null;
    materialPackageSize?: number | null;
    discounts: AppliedDiscount[];
    msrpScalar: number;
    log?: boolean
}

export function calculateServicePrice({
    laborAmount,
    minimumLaborAmount,
    laborPricePerUnit,
    materialAmount,
    materialPricePerUnit,
    materialPackageSize,
    discounts,
    msrpScalar
}: ServicePriceCalculationDetails) {
    // round up the amount of material to conform to the number of packages needed
    // this will only be used when calculating for a newly added service - those coming from the
    // database will already have performed this (see JobTransformation.DeterminePackagedAmount on the BE)
    let materialAmtForCalc = materialAmount ?? 0;
    if ((materialAmtForCalc > 0) && materialPackageSize) {
        materialAmtForCalc = Math.ceil(materialAmtForCalc / materialPackageSize) * materialPackageSize;
    }
    
    const rawLaborPrice = Math.max(laborAmount, minimumLaborAmount ?? 0) * laborPricePerUnit;
    const rawMaterialPrice = (materialAmtForCalc ?? 0) * (materialPricePerUnit ?? 0);
    const rawServPrice = rawLaborPrice + rawMaterialPrice;
    
    // only scalar discounts are applied to services, so this collects the scalar discount amounts and finds their product
    const discountScalar = discounts
        .filter(d => d.isScalar)
        .map(d => d.amount)
        .reduce((acc, next) => acc * next, 1);

    return rawServPrice * discountScalar * msrpScalar;
}

export function groupServicesByJobService(breakdowns: Breakdowns, discounts: AppliedDiscount[], msrpScalar: number): JobServiceGroups {
    let grouped: JobServiceGroups = {};
    breakdowns.forEach(breakdown => {
        breakdown.services.forEach(service => {
            let jsId = service.jobServiceId;
            
            // no scaling by waste factor because this is performed automatically when retrieved from DB
            let thisServicePrice = calculateServicePrice({
                laborAmount: service.laborAmount,
                minimumLaborAmount: service.minimumLaborAmount,
                laborPricePerUnit: service.laborPricePerUnit,
                materialAmount: service.materialAmount,
                materialPricePerUnit: service.materialPricePerUnit,
                discounts: discounts,
                msrpScalar: msrpScalar
            });
            
            if (Object.keys(grouped).includes(jsId.toString())) {
                grouped[jsId].laborAmount += service.laborAmount;
                grouped[jsId].price += thisServicePrice;
            } else {
                grouped[jsId] = {
                    laborAmount: service.laborAmount,
                    laborPriceUnit: service.laborPriceUnit,
                    serviceTypeId: service.serviceTypeId,
                    serviceType: service.serviceType,
                    jobServiceId: jsId,
                    serviceDescription: service.serviceDescription,
                    rooms: [],
                    price: thisServicePrice
                }
            }
            
            let roomForJs = service.room;
            grouped[jsId].rooms.push({
                id: roomForJs.id,
                labels: roomForJs.labels,
                service: {
                    id: service.id,
                    customerDoesService: service.customerDoesService,
                    isActive: service.isActive,
                    materialCategoryId: service.materialCategoryId,
                    materialAmount: service.materialAmount,
                    laborAmount: service.laborAmount,
                    roomId: roomForJs.id,
                    jobServiceId: service.jobServiceId,
                    serviceTypeId: service.serviceTypeId,
                    price: thisServicePrice,
                    isDeleted: false,
                    sqftScaleFactor: service.sqftScaleFactor,
                    lnftScaleFactor: service.lnftScaleFactor
                }
            });
        });
    });

    return grouped;
}

export interface LaborBreakdownRowProps {
    line?: number;
    type: string;
    amount: number;
    amountUnit: string;
    description: string;
    area: string;
}

export function makeLaborBreakdownRows(areaBreakdowns: Breakdowns, forWorkOrder=false): LaborBreakdownRowProps[] {
    let rows: LaborBreakdownRowProps[] = [];
    
    areaBreakdowns.forEach(ab => {
        let areaRows: LaborBreakdownRowProps[] = [];
        let lineNo = ab.lineNum;
        let installationRow: LaborBreakdownRowProps | undefined = undefined;
        
        let groupedServices = groupServicesByWhoDoes(ab.services);
        Object.keys(groupedServices).forEach(jsId => {
            // four possible entries for this service type: WOF does, customer does, nobody does, or over existing 
            let serviceForWOF = groupedServices[+jsId]['WOF'];
            if (isNotNullOrUndefined(serviceForWOF)) {
                let descriptionStr = serviceForWOF!.description;
                // workorders only show WOF services, so "- WOF" is not needed
                if (!forWorkOrder) { descriptionStr += " - WOF"; }
                let serviceForWOFRow: LaborBreakdownRowProps = {
                    line: lineNo,
                    type: serviceForWOF!.serviceType,
                    amount: serviceForWOF!.laborAmount,
                    amountUnit: serviceForWOF!.laborUnit,
                    description: descriptionStr,
                    area: getNameOfArea(serviceForWOF!.labels)
                }
                
                if (serviceForWOF!.serviceType === "Installation") {
                    serviceForWOFRow.type = `${ab.product.productType} Installation`
                    installationRow = serviceForWOFRow;
                } else {
                    // don't want to show line number for anything but installation row
                    areaRows.push({...serviceForWOFRow, line: undefined});
                }
            }            
            
            const serviceForOverExisting = groupedServices[+jsId]["OverExisting"];
            if (isNotNullOrUndefined(serviceForOverExisting)) {
                let descriptionStr = serviceForOverExisting!.description + " - Over Existing";
                let serviceForOverExistingRow: LaborBreakdownRowProps = {
                    line: lineNo,
                    type: serviceForWOF!.serviceType,
                    amount: serviceForWOF!.laborAmount,
                    amountUnit: serviceForWOF!.laborUnit,
                    description: descriptionStr,
                    area: getNameOfArea(serviceForWOF!.labels)
                }

                areaRows.push({...serviceForOverExistingRow, line: undefined});                
            }

            const serviceForNone = groupedServices[+jsId]["None"];
            if (isNotNullOrUndefined(serviceForNone)) {
                let descriptionStr = serviceForNone!.description;
                let serviceForOverExistingRow: LaborBreakdownRowProps = {
                    line: lineNo,
                    type: serviceForNone!.serviceType,
                    amount: 0,
                    amountUnit: serviceForNone!.laborUnit,
                    description: descriptionStr,
                    area: getNameOfArea(serviceForNone!.labels)
                }

                areaRows.push({...serviceForOverExistingRow, line: undefined});                
            }

            // don't show services customer performs on the work order
            if (!forWorkOrder) {
                let serviceForCust = groupedServices[+jsId]['Cust'];
                if (isNotNullOrUndefined(serviceForCust)) {
                    let serviceForCustRow: LaborBreakdownRowProps = {
                        line: lineNo,
                        type: serviceForCust!.serviceType,
                        amount: serviceForCust!.laborAmount,
                        amountUnit: serviceForCust!.laborUnit,
                        description: `${serviceForCust!.description} - CUST`,
                        area: getNameOfArea(serviceForCust!.labels)
                    };
    
                    if (serviceForCust!.serviceType === "Installation") {
                        installationRow = serviceForCustRow;
                    } else {
                        // don't want to show line number for anything but installation row
                        areaRows.push({...serviceForCustRow, line: undefined});
                    }
                }
            }
        });
        
        ab.customServices.forEach(cs => {
            const roomsForService = ab.rooms?.filter(r => cs.roomIds.includes(r.id)) ?? [];
            const roomLabels = roomsForService.flatMap(rfs => rfs.labels);
            let description = cs.description;
            if (!forWorkOrder) description += " - WOF";

            areaRows.push({
                line: undefined,
                type: "Custom",
                amount: 0,
                amountUnit: "",
                description: description,
                area: getNameOfArea(roomLabels)
            });
        });
        
        // installation should be the first row displayed for each area breakdown
        if (isNotNullOrUndefined(installationRow)) { // should never be null
            areaRows.unshift(installationRow!);
        }
        rows.push(...areaRows);
    });

    return rows;
}

export interface MaterialBreakdownRowProps {
    line?: number;
    productType: string;
    amount: number;
    amountUnit: string;
    style?: string;
    color?: string;
    area: string;
}

// don't want duplicate lines when multiple services use the same materials
function groupServicesByMaterialCategory(services: AreaServices, forWorkOrder=false) {
    // we don't care about services that don't have materials or aren't being done
    let filtered = services.filter(s => (isNotNullOrUndefined(s.materialCategoryId) && s.materialCategoryId! > 0 && s.isActive));
    // QUESTION: is this the proper behavior when the customer is doing a service themselves?
    // don't care about materials for services that the customer is performing
    if (forWorkOrder) {filtered = filtered.filter(s => !s.customerDoesService)}
    let groups: ServiceMaterialGroups = {};
    filtered.forEach(s => {
        if (Object.keys(groups).includes(`${s.materialCategoryId!}`)) {
            groups[s.materialCategoryId!]!.materialAmount += s.materialAmount!; 
        } else {
            groups[s.materialCategoryId!] = {name: s.materialCategoryName!, pricingUnit: s.materialCategoryPriceUnit!, materialAmount: s.materialAmount!}
        }
    });

    return groups;
}

export function makeMaterialBreakdownRows(areaBreakdowns: Breakdowns, forWorkOrder=false): MaterialBreakdownRowProps[] {
    let rows: MaterialBreakdownRowProps[] = [];

    areaBreakdowns.forEach(ab => {
        let primaryProductType = ab.product.productType;
        let areaName = getNameOfArea(ab.areaLabels);
        let lineNo = ab.lineNum;

        // this section of the foreach pertains to the actual product type (carpet, wood, etc.)
        let primaryRow: MaterialBreakdownRowProps = {
            line: lineNo,
            productType: primaryProductType,
            amount: ab.productSqft,
            amountUnit: "sqft",
            style: ab.product.productStyle,
            color: ab.product.productColor,
            area: areaName
        };
        // can use iteration index because these rows are static
        rows.push(primaryRow);
        
        let materialGroupedServices = groupServicesByMaterialCategory(ab.services, forWorkOrder);
        // inner foreach pertains to materials for that service
        Object.keys(materialGroupedServices).forEach(categoryId => {
            let group = materialGroupedServices[+categoryId];
            let secondaryRow: MaterialBreakdownRowProps;
            // TODO: need a more concrete way to determine if something is underlayment
            if (group.name.includes("Pad")) {
                secondaryRow = {
                    productType: "Underlayment",
                    amount: group.materialAmount,
                    amountUnit: group.pricingUnit,
                    style: group.name,
                    area: areaName
                };
            } else {
                secondaryRow = {
                    productType: group.name,
                    amount: group.materialAmount,
                    amountUnit: group.pricingUnit,
                    area: areaName
                };
            }

            rows.push(secondaryRow);
        });
    });

    return rows;
}

export function generateBreakdownDiffs(currentBreakdown: Breakdowns, nextBreakdown: Breakdowns): Diff[] {
    var areaLineNumber = new Set<number>();

    var output: Diff[] = []

    currentBreakdown.forEach(curr => {
        if (areaLineNumber.has(curr.lineNum)) return;

        areaLineNumber.add(curr.lineNum)
        const next = nextBreakdown.find(n => n.lineNum === curr.lineNum);
        output.push(...generateDiffsForLine(curr, next));
    })

    nextBreakdown.forEach(curr => {
        if (areaLineNumber.has(curr.lineNum)) return;

        areaLineNumber.add(curr.lineNum)
        const next = currentBreakdown.find(n => n.lineNum === curr.lineNum);
        output.push(...generateDiffsForLine(curr, next));
    })

    return output
}

function generateDiffsForLine(currentBreakdown?: Breakdowns[number], nextBreakdown?: Breakdowns[number]): Diff[] {
    return [
        ...generateProductChange(currentBreakdown?.product, currentBreakdown?.productSqft, nextBreakdown?.product, nextBreakdown?.productSqft),
        ...generateAllServiceChanges(currentBreakdown?.services ?? [], nextBreakdown?.services ?? [], currentBreakdown?.product.productTypeId !== nextBreakdown?.product.productTypeId)
    ]
}

function generateProductChange(
    currentProduct?: Breakdowns[number]['product'],
    currentSqft?: Breakdowns[number]['productSqft'],
    nextProduct?: Breakdowns[number]['product'],
    nextSqft?: Breakdowns[number]['productSqft']): Diff[] {
    if (isNullOrUndefined(currentProduct)) {
        return isNotNullOrUndefined(nextProduct) ? createProductAddition(nextProduct!, nextSqft ?? 0) : []
    }
    else if (isNullOrUndefined(nextProduct)) {
        return isNotNullOrUndefined(currentProduct) ? createProductRemoval(currentProduct!, currentSqft ?? 0) : []
    }
    else {
        // Try finding diff
        const typeDiff = currentProduct?.productType !== nextProduct?.productType
        const styleDiff = currentProduct?.productStyle !== nextProduct?.productStyle
        const colorDiff = currentProduct?.productColor !== nextProduct?.productColor
        const sqftDiff = currentSqft !== nextSqft

        const isChanges = typeDiff || styleDiff || colorDiff || sqftDiff

        if (!isChanges) return []
        else return [
            {
                type: DiffTypeEnum.Update,
                preChangeDescription: <p className="margin-none">
                    {
                        createDiffableText(currentProduct?.productType, typeDiff)
                    } - {
                        createDiffableText(currentProduct?.productStyle, styleDiff)
                    } - {
                        createDiffableText(currentProduct?.productColor, colorDiff)
                    } - {
                        createDiffableText(currentSqft?.toFixed(0), sqftDiff)
                    } sqft
                </p>,
                postChangeDescription: <p className="margin-none">
                    {
                        createDiffableText(nextProduct?.productType, typeDiff)
                    } - {
                        createDiffableText(nextProduct?.productStyle, styleDiff)
                    } - {
                        createDiffableText(nextProduct?.productColor, colorDiff)
                    } - {
                        createDiffableText(nextSqft?.toFixed(0), sqftDiff)
                    } sqft
                </p>
            }
        ]

    }
}

function createProductAddition(product: Breakdowns[number]['product'], sqft: Breakdowns[number]['productSqft']): Diff[] {
    return [{ type: DiffTypeEnum.Addition, postChangeDescription: <p className="margin-none">{product.productType} - {product.productStyle} - {product.productColor} - {sqft} sqft</p> }]
}

function createProductRemoval(product: Breakdowns[number]['product'], sqft: Breakdowns[number]['productSqft']): Diff[] {
    return [{ type: DiffTypeEnum.Deletion, postChangeDescription: <p className="margin-none">{product.productType} - {product.productStyle} - {product.productColor} - {sqft} sqft</p> }]
}

function generateAllServiceChanges(currentServices: Breakdowns[number]['services'], nextServices: Breakdowns[number]['services'], isProductTypeDifferent: boolean): Diff[] {
    var jobServiceIds = new Set<number>();

    var output: Diff[] = []

    currentServices.forEach(curr => {
        if (jobServiceIds.has(curr.jobServiceId)) return;

        jobServiceIds.add(curr.jobServiceId)
        const next = (isProductTypeDifferent && curr.serviceTypeId === INSTALLATION_ID) ? undefined : nextServices.find(n => n.jobServiceId === curr.jobServiceId);
        output.push(...generateServiceChanges(curr, next));
    })

    nextServices.forEach(next => {
        if (jobServiceIds.has(next.jobServiceId)) return;

        jobServiceIds.add(next.jobServiceId)
        const curr = (isProductTypeDifferent && next.serviceTypeId === INSTALLATION_ID) ? undefined : currentServices.find(n => n.jobServiceId === next.jobServiceId);
        output.push(...generateServiceChanges(curr, next));
    })

    return output
}

function generateServiceChanges(currentService?: Breakdowns[number]['services'][number], nextService?: Breakdowns[number]['services'][number]): Diff[] {
    if (isNullOrUndefined(currentService)) {
        return isNotNullOrUndefined(nextService) ? createServiceAddition(nextService!) : []
    }
    else if (isNullOrUndefined(nextService)) {
        return isNotNullOrUndefined(currentService) ? createServiceRemoval(currentService!) : []
    }
    else {
        // Try finding diff
        const laborDiff = currentService?.laborAmount !== nextService?.laborAmount
        const materialDiff = currentService?.materialAmount !== nextService?.materialAmount
        const whoDoesDiff = currentService?.customerDoesService !== nextService?.customerDoesService

        const isChanges = laborDiff || materialDiff || whoDoesDiff
        const hasMaterial = isNotNullOrUndefined(currentService?.materialAmount) || isNotNullOrUndefined(nextService?.materialAmount)

        if (!isChanges) return []
        else if (hasMaterial) return [
            {
                type: DiffTypeEnum.Update,
                preChangeDescription: <p className="margin-none">
                    {
                        currentService?.serviceType
                    } - {
                        currentService?.serviceDescription
                    } - {
                        createDiffableText(currentService?.laborAmount.toFixed(0), laborDiff)} {currentService?.laborPriceUnit
                    } - {
                        currentService?.materialCategoryName
                    } {
                        createDiffableText(currentService?.materialAmount?.toFixed(0) ?? "None", materialDiff)} {currentService?.materialCategoryPriceUnit
                    } - {
                        createDiffableText(currentService?.customerDoesService ? "Cust" : "WOF", whoDoesDiff)
                    }
                </p>,
                postChangeDescription: <p className="margin-none">
                    {
                        nextService?.serviceType
                    } - {
                        nextService?.serviceDescription
                    } - {
                        createDiffableText(nextService?.laborAmount.toFixed(0), laborDiff)} {nextService?.laborPriceUnit
                    } - {
                        nextService?.materialCategoryName
                    } {
                        createDiffableText(nextService?.materialAmount?.toFixed(0) ?? "None", materialDiff)} {nextService?.materialCategoryPriceUnit
                    } - {
                        createDiffableText(nextService?.customerDoesService ? "Cust" : "WOF", whoDoesDiff)
                    }
                </p>
            }
        ]
        else return [
            {
                type: DiffTypeEnum.Update,
                preChangeDescription: <p className="margin-none">
                    {
                        currentService?.serviceType
                    } - {
                        currentService?.serviceDescription
                    } - {
                        createDiffableText(currentService?.laborAmount.toFixed(0), laborDiff)} {currentService?.laborPriceUnit
                    } - {
                        createDiffableText(currentService?.customerDoesService ? "Cust" : "WOF", whoDoesDiff)
                    }
                </p>,
                postChangeDescription: <p className="margin-none">
                    {
                        nextService?.serviceType
                    } - {
                        nextService?.serviceDescription
                    } - {
                        createDiffableText(nextService?.laborAmount.toFixed(0), laborDiff)} {nextService?.laborPriceUnit
                    } - {
                        createDiffableText(nextService?.customerDoesService ? "Cust" : "WOF", whoDoesDiff)
                    }
                </p>
            }
        ]
    }
}

function createServiceRemoval(service: Breakdowns[number]['services'][number]): Diff[] {
    if (isNotNullOrUndefined(service.materialAmount)) {
        return [{
            type: DiffTypeEnum.Deletion,
            postChangeDescription:
                <p className="margin-none">Rem
                    {service.serviceType} - {service.serviceDescription} - {service.laborAmount} {service.laborPriceUnit} - {service.materialAmount} {service.materialCategoryPriceUnit}
                </p>
        }]
    }
    else return [{
        type: DiffTypeEnum.Deletion,
        postChangeDescription:
            <p className="margin-none">Rem{service.serviceType} - {service.serviceDescription} - {service.laborAmount} {service.laborPriceUnit}</p>
    }]
}

function createServiceAddition(service: Breakdowns[number]['services'][number]): Diff[] {
    if (isNotNullOrUndefined(service.materialAmount)) {
        return [{
            type: DiffTypeEnum.Addition,
            postChangeDescription:
                <p className="margin-none">
                    Add{service.serviceType} - {service.serviceDescription} - {service.laborAmount} {service.laborPriceUnit} - {service.materialAmount} {service.materialCategoryPriceUnit}
                </p>
        }]
    }
    else return [{
        type: DiffTypeEnum.Addition,
        postChangeDescription:
            <p className="margin-none">Add{service.serviceType} - {service.serviceDescription} - {service.laborAmount} {service.laborPriceUnit}</p>
    }]
}

function createDiffableText(text: string | undefined, bold: boolean) {
    return <>{bold ? <b>{text}</b> : <span>{text}</span>}</>
}
