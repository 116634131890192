import { Typography } from "@material-ui/core";
import { useGetAuthenticatedWorkerPermissionsQuery, Permissions } from "generated/graphql";
import { useMemo } from "react";
import Loading from "./Loading";
import NavbarPage from "./Page";

export interface RequirePermissionProps {
    permissions: (keyof Omit<Permissions, "id" | "__typename">)[]
}

export default function RequirePermission({ permissions, children }: React.PropsWithChildren<RequirePermissionProps>) {
    const { data, loading, error } = useGetAuthenticatedWorkerPermissionsQuery()

    const hasPermission = useMemo(() => {
        return permissions.map(perKey => data?.authenticatedWorkerPermissions?.[perKey]).some(perValue => perValue)
    }, [permissions, data])

    if (loading) {
        return <Loading />;
    } else if (error) {
        return <p>Error loading permissions: {error.message}</p>;
    } else if (hasPermission) {
        return <>{children}</>
    } else {
        return (
            <NavbarPage centerHorizontally>
                <Typography variant="h3" style={{ paddingTop: "2rem" }}>
                    You do not have permission to view this page
                </Typography>
            </NavbarPage>
        )
    }
}