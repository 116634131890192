import clsx from 'clsx';
import { Button, createStyles, Drawer, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import Navbar from "Components/Navbar/Navbar";
import { useEffect, useState } from "react";
import SearchBar from 'material-ui-search-bar';
import ReactDataGrid, { Column, FormatterProps, SelectCellFormatter, SELECT_COLUMN_KEY, useRowSelection } from 'react-data-grid';
import { generateLoadServerRowsEffect } from 'Globals/Helpers';
import OrderTotalView from './Components/OrderTotalView';
import { InventoryEntry, useGetDummyInventoryQuery } from 'generated/graphql';
import { useAppDispatch } from 'Redux/hooks';
import { useAuthenticatedQuery } from 'Globals/API';
import { addToSelected, removeFromSelected } from 'Redux/orderingReducer';
import Loading from 'Components/Loading';
import NavbarPage from 'Components/Page';

interface ColumnEntry extends Column<Row> {
    key: keyof Row | typeof SELECT_COLUMN_KEY
    name: string
}

export type OrderingViewRow = {
    isSelected: boolean
} & InventoryEntry

type Row = OrderingViewRow;

const useStyles = (drawerWidth: string, props?: any) => makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: "column",
            height: "100vh"
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            padding: theme.spacing(3),
            width: drawerWidth,
        },
        drawerContainer: {
            overflow: 'auto',
        },
        content: {
            flex: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginRight: 0,
            display:"flex",
            flexDirection:"column"
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: drawerWidth,
        },
        toggleButton: {
            zIndex: theme.zIndex.drawer + 1,
            position: "fixed",
            transition: theme.transitions.create('right', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            right: 0,
            minWidth: 0
        },
        toggleButtonShift: {
            transition: theme.transitions.create('right', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            right: drawerWidth,
        },
        fullscreenToggleButton: {
            right: "calc(100vw - 40px)",
        },
    }),
)(props);

function mapEntriesResultToGridRow(entries: InventoryEntry[]): Row[] {
    return entries.map((entry) => {
        return {
            isSelected: false,
            ...entry
        }
    });
}

export default function Ordering() {
    //Drawer layout and animation
    const matches = useMediaQuery('(max-width:800px)');
    const fullscreen = matches.valueOf();
    const drawerWidth = fullscreen ? '100vw' : 'max(40vw, 35rem)'
    const classes = useStyles(drawerWidth);

    //Drawer open/close handler and state
    const [open, setOpen] = useState(false);

    const dispatch = useAppDispatch();

    const [selectedRows, setSelectedRows] = useState<Set<string>>(() => new Set());
    const [searchQuery, setSearchQuery] = useState<string>("");
    const getDummyInventory = useAuthenticatedQuery(useGetDummyInventoryQuery); //Memoize this to fix lint
    const [orderingRows, setOrderingRows] = useState<Row[]>([]);
    const [filteredManufacturer, setFilteredManufacturer] = useState<string>("");


    const [isLoading, setLoading] = useState<boolean>(true);

    const [mainColumn] = useState<ColumnEntry[]>([
        { key: SELECT_COLUMN_KEY, name: "Sel", width: 35, maxWidth: 35, resizable: false, sortable: false, frozen: true, formatter: SelectFormatter },
        { key: 'sku', name: 'SKU', width: 100 },
        { key: 'style', name: 'Style' },
        { key: 'color', name: 'Color' },
        { key: 'manufacturer', name: 'Manufacturer' },
        { key: 'minimumStockCount', name: 'Minimum', width: 80 },
        { key: 'potential', name: 'Potential', width: 80 },
        { key: 'newCount', name: 'Actual', width: 80 },
        { key: 'toOrderCountInOrderUnits', name: 'Suggested', width: 90 },
    ])

    function SelectFormatter(props: FormatterProps<Row>) {
        const [isRowSelected, onRowSelectionChange] = useRowSelection();
        return (
            <SelectCellFormatter
                aria-label="Select"
                tabIndex={-1}
                isCellSelected={props.isCellSelected}
                value={isRowSelected}
                onClick={(event: any) => event.stopPropagation()}
                onChange={(checked, isShiftClick) => { 
                    if(checked){
                        if(!open) setOpen(true);
                        dispatch(addToSelected(props.row))
                    } else dispatch(removeFromSelected(props.row.sku))
                    onRowSelectionChange({ checked, isShiftClick: false, rowIdx: props.rowIdx }) 
                }}
            />
        );
    }

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            generateLoadServerRowsEffect(
                getDummyInventory,
                searchQuery,
                [{ columnKey: "toOrderCountInOrderUnits", direction: "DESC" }],
                (filteredManufacturer !== "") ? { manufacturer: { eq: filteredManufacturer } } : undefined)
                .then((rows) => {
                    const newRows = mapEntriesResultToGridRow(rows)

                    if (!active) {
                        return;
                    }

                    setOrderingRows(newRows);
                    setLoading(false);
                })
        })();

        return () => {
            active = false;
        };
    }, [searchQuery, filteredManufacturer, getDummyInventory])

    const fullSelRows = orderingRows.filter(row=>selectedRows.has(row.sku));

    const uniqueManufacturers = new Set(fullSelRows.map(row=>row.manufacturer))
    let rowsByManufacturer : {name: string, rows: OrderingViewRow[]}[] = []
    uniqueManufacturers.forEach(manu => rowsByManufacturer = [...rowsByManufacturer, {name: manu, rows: fullSelRows.filter(row=>row.manufacturer===manu)}])

    return (
        <NavbarPage hideNavbar>
            <div className={classes.appBar}>
                <Navbar />
            </div>
            <div
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}>
                <Button
                    className={clsx(classes.toggleButton, { [classes.toggleButtonShift]: open }, { [classes.fullscreenToggleButton]: (open && fullscreen) })}
                    color="primary"
                    variant="contained"
                    onClick={() => setOpen(!open)}
                >
                    {open ? ">" : "<"}
                </Button>
                <h1>Ordering</h1>
                <SearchBar
                    key='ordering-viewer-search-bar'
                    style={{ width: "min(100%, 600px)", margin: 10 }}
                    onChange={setSearchQuery}
                    onCancelSearch={() => setSearchQuery("")} />
                {filteredManufacturer !== "" ? (<div style={{display:"flex", flexDirection:"row", marginBottom:".5rem"}}>
                        <h2 style={{margin:"auto 1rem"}}>Showing only {filteredManufacturer}</h2>
                        <Button style={{margin:"auto 1rem"}} variant="contained" color="primary" onClick={()=>setFilteredManufacturer("")}>Clear Filter</Button></div>):<></>}
                <ReactDataGrid
                    style={{flex:(isLoading ? 0 : 1)}}
                    columns={mainColumn}
                    rows={orderingRows}
                    rowKeyGetter={(row) => row.sku}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                    className='rdg-light'
                />
                {isLoading && <Loading/>}
            </div>

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <Navbar/>
                <div className={classes.drawerPaper}>
                    <OrderTotalView setFilteredManufacturer={(value: string) => setFilteredManufacturer(value)}/>
                </div>
            </Drawer> 
        </NavbarPage>
    )
}
