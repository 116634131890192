import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { manualInputId } from 'Components/Inputs/TypeableSelect'
import { SpecEditorValues } from 'Pages/Admin/AddProduct/ProductSpecViewer/ProductSpecViewer'
import { styleUnselectedId } from 'Pages/Admin/AddProduct/SimpleEditors/StyleEditor'
import { RootState } from './store'

interface CreateNewStyleEditor {
    editorMode: "Create" | "Edit" | "None",
    errorText: string,
    existingId?: number
    newStyleName: string,
    singleCost: string,
    bulkCost: string
}

const initialStyleEditor: CreateNewStyleEditor = {
    errorText: "",
    editorMode: "None",
    newStyleName: "",
    singleCost: "",
    bulkCost: ""
}

interface ProductReducerState { //Defined so that create slice can infer the type
    typeId: number,
    styleId: number,
    color: { id: number, value: string },
    spec: { id: number, value: string },
    singleCost: string,
    bulkCost: string,
    isOneTimeCost: boolean,
    oneTimeCost: string,
    vendorId: number,
    vendorStyle: string,
    vendorColor: string,
    vendorOrderSize: string,
    styleEditor: CreateNewStyleEditor,
    colorVariantSpecValues: SpecEditorValues
}


const initialState: ProductReducerState = {
    typeId: -1,
    styleId: -1,
    color: { id: -1, value: "" },
    spec: { id: -1, value: "" },
    singleCost: "",
    bulkCost: "",
    isOneTimeCost: false,
    oneTimeCost: "",
    vendorId: -1,
    vendorStyle: "",
    vendorColor: "",
    vendorOrderSize: "",
    styleEditor: initialStyleEditor,
    colorVariantSpecValues: {
        sqft: "",
        productDetails: [],
        installationMethods: []
    }
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store

export const addProductSlice = createSlice({
    name: "addProduct",
    initialState,
    reducers: {
        setTypeId(state, action: PayloadAction<number>) {
            state.typeId = action.payload
            state.styleId = -1
            state.color = { id: -1, value: "" }
        },
        setStyleId(state, action: PayloadAction<number>) {
            state.styleId = action.payload
            state.color = { id: -1, value: "" }
        },
        setColor(state, action: PayloadAction<{ id?: number, value?: string }>) {
            const { id, value } = action.payload
            state.color = { id: id ?? state.color.id, value: value ?? state.color.value }
        },
        setColorVariantSpecValues(state, action: PayloadAction<SpecEditorValues>) {
            state.colorVariantSpecValues = action.payload
        },
        setSpec(state, action: PayloadAction<{ id?: number, value?: string }>) {
            const { id, value } = action.payload
            state.spec = { id: id ?? state.spec.id, value: value ?? state.spec.value }
        },
        setSingleCost(state, action: PayloadAction<string>) {
            state.singleCost = action.payload
        },
        setBulkCost(state, action: PayloadAction<string>) {
            state.bulkCost = action.payload
        },
        setOneTimeCost(state, action: PayloadAction<string>) {
            state.oneTimeCost = action.payload
        },
        setIsOneTimeCost(state, action: PayloadAction<boolean>) {
            state.isOneTimeCost = action.payload
        },
        setVendorId(state, action: PayloadAction<number>) {
            state.vendorId = action.payload
        },
        setVendorStyle(state, action: PayloadAction<string>) {
            state.vendorStyle = action.payload
        },
        setVendorColor(state, action: PayloadAction<string>) {
            state.vendorColor = action.payload
        },
        setVendorOrderSize(state, action: PayloadAction<string>) {
            state.vendorOrderSize = action.payload
        },
        setNSEMode(state, action: PayloadAction<"None" | "Edit" | "Create">) {
            state.styleEditor.editorMode = action.payload
        },
        setNSEStyle(state, action: PayloadAction<string>) {
            state.styleEditor.newStyleName = action.payload
        },
        setNSEExistingId(state, action: PayloadAction<number>) {
            state.styleEditor.existingId = action.payload
        },
        setNSEError(state, action: PayloadAction<string>) {
            state.styleEditor.errorText = action.payload
        },
        setNSESingleCost(state, action: PayloadAction<string>) {
            state.styleEditor.singleCost = action.payload
        },
        setNSEBulkCost(state, action: PayloadAction<string>) {
            state.styleEditor.bulkCost = action.payload
        },
        clearNSE(state) {
            state.styleEditor = initialStyleEditor
        },
        resetAddProduct: () => initialState,
    }
})

export const {
    setTypeId,
    setStyleId,
    setColor,
    setColorVariantSpecValues,
    setSpec,
    setSingleCost,
    setBulkCost,
    setOneTimeCost,
    setIsOneTimeCost,
    setVendorId,
    setVendorStyle,
    setVendorColor,
    setVendorOrderSize,
    setNSEMode,
    setNSEStyle,
    setNSEExistingId,
    setNSEError,
    setNSESingleCost,
    setNSEBulkCost,
    clearNSE,
    resetAddProduct
} = addProductSlice.actions //Unpacks the actions created in the slice

export const selectTypeId = (state: RootState) => state.addProduct.typeId
export const selectStyleId = (state: RootState) => state.addProduct.styleId
export const selectIsStyleUnselected = (state: RootState) => state.addProduct.styleId === styleUnselectedId
export const selectColor = (state: RootState) => state.addProduct.color
export const selectColorId = (state: RootState) => state.addProduct.color.id
export const selectColorValue = (state: RootState) => state.addProduct.color.value
export const selectSpecId = (state: RootState) => state.addProduct.spec.id
export const selectSpecValue = (state: RootState) => state.addProduct.spec.value
export const selectSingleCost = (state: RootState) => state.addProduct.singleCost
export const selectBulkCost = (state: RootState) => state.addProduct.bulkCost
export const selectOneTimeCost = (state: RootState) => state.addProduct.oneTimeCost
export const selectIsOneTimeCost = (state: RootState) => state.addProduct.isOneTimeCost
export const selectIsNewSpecMode = (state: RootState) => state.addProduct.spec.id === manualInputId
export const selectIsNewColorMode = (state: RootState) => state.addProduct.color.id === manualInputId && state.addProduct.color.value !== ""
export const selectColorVariantSpecValues = (state: RootState) => state.addProduct.colorVariantSpecValues
export const selectVendorId = (state: RootState) => state.addProduct.vendorId
export const selectVendorStyle = (state: RootState) => state.addProduct.vendorStyle
export const selectVendorColor = (state: RootState) => state.addProduct.vendorColor
export const selectVendorOrderSize = (state: RootState) => state.addProduct.vendorOrderSize
export const selectIsVendorFilledOut = (state: RootState) => state.addProduct.vendorId !== -1
    && (state.addProduct.vendorStyle !== "" || state.addProduct.vendorColor !== "")
    && state.addProduct.vendorOrderSize !== ""

export const selectNSEMode = (state: RootState) => state.addProduct.styleEditor.editorMode
export const selectNSEStyle = (state: RootState) => state.addProduct.styleEditor.newStyleName
export const selectNSESingleCost = (state: RootState) => state.addProduct.styleEditor.singleCost
export const selectNSEBulkCost = (state: RootState) => state.addProduct.styleEditor.bulkCost
export const selectNSEExistingId = (state: RootState) => state.addProduct.styleEditor.existingId
export const selectNSEError = (state: RootState) => state.addProduct.styleEditor.errorText

export const selectProductSpecified = (state: RootState) => state.addProduct.vendorId > 0 
&& state.addProduct.vendorStyle !== "" 
&& state.addProduct.vendorOrderSize !== "" 
&& state.addProduct.styleId > 0

export default addProductSlice.reducer