import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import clsx from "clsx";
import { PromotionType, useGetBasePromotionsTableDataQuery } from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { PercentInput } from "Pages/Admin/Financing/FinancingRow";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { EditableBasePromotion, EditableTypeForBasePromotion } from "./BasePromoEditorDialog";

interface PromoTypeDetailEditorProps {
    promo: EditableBasePromotion;
    setPromo: (newPromo: EditableBasePromotion) => void;
}

function allPromoDetailsEq(promoTypeDetails: EditableTypeForBasePromotion[], promoTypes: PromotionType[]) {
    if (promoTypes.length === promoTypeDetails.length && promoTypeDetails.length > 0) {
        let allSame = true;
        const detailsForComparison = promoTypeDetails[0];
        promoTypeDetails.slice(1).forEach(ptd => {
            if ((ptd.isScalar !== detailsForComparison.isScalar) || (ptd.amount !== detailsForComparison.amount)) {
                allSame = false;
            }
        });
        return allSame;
    } else {
        if (promoTypeDetails.length === 0) {
            // no types are active in the promo yet, so they're all the same
            return true;
        } else {
            // some of promo types are active and some aren't, so they're not all equal
            return false;
        }
    }
}

export default function PromoTypeDetailEditor({promo, setPromo}: PromoTypeDetailEditorProps) {
    const { data: basePromoData } =  useGetBasePromotionsTableDataQuery({
        onError: () => alert("Could not retrieve promotion information"),
        fetchPolicy: "cache-only"
    });
    const promoTypes = basePromoData?.promotionTypes ?? [];

    const [allMode, setAllMode] = useState(allPromoDetailsEq(promo.promotionTypeDetails, promoTypes));
    function onToggleAllMode(isChecked: boolean) {
        setAllMode(isChecked);
        
        if (isChecked) {
            setAllMode(true);
            // use the first category (if there is one) as the value - otherwise just give it an empty detail object
            const newDetailObj: PromoTypeDetails = promo.promotionTypeDetails.length > 0
                ? {isScalar: promo.promotionTypeDetails[0].isScalar, amount: promo.promotionTypeDetails[0].amount}
                : {...emptyTypeDetails}
            setAllDetails(newDetailObj);
        }
    }

    // intented for partial application when passing to PromoCategoryContainer (https://en.wikipedia.org/wiki/Partial_application)
    // function setCategoryDetail(categoryName: "all" | "product" | "labor" | "accessory"): (newDetails: PromoCategoryDetails) => void {
    function makeSetPromoTypeDetail(promoTypeId: number): (newDetails: PromoTypeDetails) => void {
        switch (promoTypeId) {
            case -1:  // to update all categories
                return (newDetails: PromoTypeDetails) => {
                    setPromo({
                        ...promo,
                        promotionTypeDetails: promoTypes.map(pt => ({
                            promotionTypeId: pt.id,
                            typeLabel: pt.label,
                            isScalar: newDetails.isScalar,
                            amount: newDetails.amount
                        }))
                    });
                }
            default:
                return (newDetails: PromoTypeDetails) => {
                    let updatedPromoTypeDetails = [...promo.promotionTypeDetails];
                    const thisTypeIdx = promo.promotionTypeDetails.findIndex(ptd => ptd.promotionTypeId === promoTypeId);
                    if (thisTypeIdx === -1) {  // must add it
                        const thisLabel = promoTypes.find(pt => pt.id === promoTypeId)?.label ?? "";
                        updatedPromoTypeDetails.push({typeLabel: thisLabel, promotionTypeId: promoTypeId, isScalar: newDetails.isScalar, amount: newDetails.amount});
                    } else {
                        const thisUpdatedDetail: EditableTypeForBasePromotion = {...promo.promotionTypeDetails[thisTypeIdx], isScalar: newDetails.isScalar, amount: newDetails.amount}
                        updatedPromoTypeDetails.splice(thisTypeIdx, 1, thisUpdatedDetail);
                    }

                    setPromo({...promo, promotionTypeDetails: updatedPromoTypeDetails})
                }
        }
    }

    const allDetails: PromoTypeDetails = promo.promotionTypeDetails.length > 0 
        ? {
            isScalar: promo.promotionTypeDetails[0].isScalar,
            amount: promo.promotionTypeDetails[0].amount
        } : {...emptyTypeDetails}
    const setAllDetails = makeSetPromoTypeDetail(-1);

    // const 

    return (
        <div className="flex-column margin-top-xsm">
            <FormControlLabel
                label="Adjust All Categories"
                control={<Checkbox checked={allMode} onChange={e => onToggleAllMode(e.target.checked)} />}
            />

            {allMode ? (
                <PromoTypeContainer
                    typeName="All" hideCheckBox
                    typeDetails={allDetails} setTypeDetails={setAllDetails}
                />
            ) : (
                <div className="flex-row-wrap flex-gap-sm">
                    {promoTypes.map(promoType => {
                        let thisTypeDetails = promo.promotionTypeDetails.find(ptd => ptd.promotionTypeId === promoType.id);
                        if (isNullOrUndefined(thisTypeDetails)) {
                            thisTypeDetails = {
                                promotionTypeId: promoType.id,
                                typeLabel: promoType.label,
                                isScalar: null,
                                amount: ""
                            }
                        }

                        return (
                            <PromoTypeContainer
                                key={thisTypeDetails!.promotionTypeId}
                                typeName={thisTypeDetails!.typeLabel}
                                typeDetails={thisTypeDetails!}
                                setTypeDetails={makeSetPromoTypeDetail(thisTypeDetails!.promotionTypeId)}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}

interface PromoTypeDetails {
    isScalar: boolean | null;
    amount: string;
}

const emptyTypeDetails: PromoTypeDetails = {isScalar: null, amount: ""};

interface PromoTypeContainerProps {
    typeName: string;
    typeDetails: PromoTypeDetails;
    setTypeDetails: (details: PromoTypeDetails) => void;
    hideCheckBox?: boolean
}

function PromoTypeContainer({typeName, typeDetails, setTypeDetails, hideCheckBox=false}: PromoTypeContainerProps) {
    // NOTE: if the isScalar field is null, we consider this category inactive

    // if the isScalar field is null, set to true - otherwise leave it alone
    function ensureCategoryActive() {
        const newIsScalar = isNullOrUndefined(typeDetails.isScalar) ? true : typeDetails.isScalar;
        setTypeDetails({...typeDetails, isScalar: newIsScalar});
    }
    
    // toggling between scalar/dollar amount, OR if the category is inactive, activating it
    function onToggleScalar() {
        const catWasPreviouslyInactive = isNullOrUndefined(typeDetails.isScalar);
        // if it was previously inactive, default it to true but otherwise toggle it
        const newIsScalar = catWasPreviouslyInactive ? true : !typeDetails.isScalar;
        setTypeDetails({...typeDetails, isScalar: newIsScalar});
    };
    
    // this is when activating/deactivating the category
    function onToggleCategoryActive() {
        const togglingCategoryOff = isNotNullOrUndefined(typeDetails.isScalar);
        if (togglingCategoryOff) {
            setTypeDetails(emptyTypeDetails);
        } else {
            setTypeDetails({...typeDetails, isScalar:  !typeDetails.isScalar});
        }
    }
    
    const active = isNotNullOrUndefined(typeDetails.isScalar);

    return (
        // gray-bkg to make the box look disabled when it's not active
        <div className={clsx(("flex-column padding-sm w-14r solid-border"), {"gray-bkg": !active})} style={{borderRadius: "5px"}} >
            {!hideCheckBox && (
                <FormControlLabel
                    label={typeName}
                    control={<Checkbox checked={active} onChange={onToggleCategoryActive} />}
                />
            )}

            <div className="flex-row align-items-center">
                <Button
                    variant="contained" className="fit-content padding-none margin-right-sm"
                    onClick={onToggleScalar}
                >{(typeDetails.isScalar ?? true) ? "%" : "$"}</Button>
                
                {typeDetails.isScalar ? (
                    // only need the <span> for the onClick
                    <span className="fit-content" onClick={ensureCategoryActive}>
                        <PercentInput
                            className="w-5r"
                            value={typeDetails.amount} setValue={(newVal) => setTypeDetails({...typeDetails, amount: newVal})}
                        />
                    </span>
                ) : (
                    <NumberFormat
                        onClick={ensureCategoryActive}  // don't force user to click the check box before entering value
                        value={typeDetails.amount}
                        onValueChange={(newVal) => setTypeDetails({...typeDetails, amount: newVal.value })}
                        displayType="input" allowNegative={false}
                        prefix="$"
                        decimalScale={2} fixedDecimalScale
                        className="w-5r"
                    />
                )}
            </div>
        </div>
    )
}