import { Button, Typography } from "@material-ui/core";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import {
    ServiceForRoom,
    useGetAllServiceMaterialCategoryPricingsQuery,
    useGetAllServiceProductOfferingsQuery,
    useGetFullyScaledJobConfigurationQuery,
    useGetJobConfigurationQuery,
    useGetPriceVerificationFieldsQuery
} from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { buildAppendedId, useNumericIdParam } from "Globals/Hooks";
import { seamPlannerPath } from "Globals/PathStrings";
import { useAppHistory } from "Globals/routingHooks";
import { formatAsIntOrNDecimals } from "Globals/StringFormatting";
import { useMemo } from "react";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";

interface AdditionalServiceForRoom extends ServiceForRoom {
    rawLabor?: number;
    rawMaterial?: number;
    laborPrice?: number;
    laborCost?: number;
    materialPrice?: number;
    materialCost?: number;
}

const inital: { ser: AdditionalServiceForRoom[]; mat: AdditionalServiceForRoom[] } = {
    ser: [],
    mat: [],
};

export default function JobScope() {
    const history = useAppHistory();
    const { id: jobConfigurationId } = useNumericIdParam();

    const { data, loading: l1 } = useGetFullyScaledJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        fetchPolicy: "no-cache",
    });
    const { data: unscaledData, loading: l2 } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        fetchPolicy: "no-cache",
    });
    const { data: serData, loading: l3 } = useGetAllServiceProductOfferingsQuery();
    const { data: matData, loading: l4 } = useGetAllServiceMaterialCategoryPricingsQuery();
    const { data: priceData, loading: l5 } = useGetPriceVerificationFieldsQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
    });

    const groupedServices: { ser: AdditionalServiceForRoom[]; mat: AdditionalServiceForRoom[] } =
        useMemo(() => {
            const services =
            data?.fullyScaledJobConfiguration.areas?.flatMap((area) =>
                area.rooms.flatMap((room) => room.services)
            ) ?? [];
            const rawServices =
            unscaledData?.jobConfiguration.areas?.flatMap((area) =>
                area.rooms.flatMap((room) => room.services)
            ) ?? [];
            
            if (services.length !== rawServices.length || isNullOrUndefined(priceData)) {
                return inital;            
            }
            
            return services.reduce((total, curr, index) => {
                const rawCurr = rawServices[index];
                const matchedPrice = priceData?.priceVerificationFields.services.find(
                    (ser) => ser.id === curr.id
                );

                const updatedSer: AdditionalServiceForRoom[] = [...total.ser];
                const serIndex = total.ser.findIndex((t) => t.jobServiceId === curr.jobServiceId);
                if (serIndex === -1) {
                    updatedSer.push({
                        ...curr,
                        rawLabor: rawCurr.laborAmount,
                        laborPrice: matchedPrice?.price ?? 0,
                        laborCost: matchedPrice?.cost ?? 0,
                    });
                } else {
                    const foundSer = updatedSer[serIndex];
                    updatedSer[serIndex] = {
                        ...updatedSer[serIndex],
                        laborAmount: foundSer.laborAmount + curr.laborAmount,
                        rawLabor: foundSer.rawLabor! + rawCurr.laborAmount,
                        laborPrice: foundSer.laborPrice! + (matchedPrice?.price ?? 0),
                        laborCost: foundSer.laborCost! + (matchedPrice?.cost ?? 0),
                    };
                }

                const updatedMat: AdditionalServiceForRoom[] = [...total.mat];
                if (isNotNullOrUndefined(curr.materialAmount)) {
                    const matIndex = total.mat.findIndex(
                        (t) => t.materialCategoryId === curr.materialCategoryId
                    );
                    if (matIndex === -1) {
                        updatedMat.push({
                            ...curr,
                            rawMaterial: rawCurr.materialAmount!,
                            materialPrice: matchedPrice?.materialPrice ?? 0,
                            materialCost: matchedPrice?.materialCost ?? 0,
                        });
                    } else {
                        const foundMat = updatedMat[matIndex];
                        updatedMat[matIndex] = {
                            ...foundMat,
                            materialAmount: foundMat.materialAmount! + curr.materialAmount!,
                            rawMaterial: foundMat.rawMaterial! + rawCurr.materialAmount!,
                            materialPrice:
                                foundMat.materialPrice! + (matchedPrice?.materialPrice ?? 0),
                            materialCost:
                                foundMat.materialCost! + (matchedPrice?.materialCost ?? 0),
                        };
                    }
                }

                return { ser: updatedSer, mat: updatedMat };
            }, inital);
        }, [data, unscaledData, priceData]);

    if (l1 || l2 || l3 || l4 || l5) return <Loading />;

    const { styles, services } = priceData?.priceVerificationFields!;

    return (
        <NavbarPage
            title="Job Scope"
            centerHorizontally
        >
            <Button
                variant="contained"
                onClick={() => history.goBack()}
            >
                Back
            </Button>
            <Button
                variant="contained"
                onClick={() => history.push(seamPlannerPath + buildAppendedId(jobConfigurationId))}
            >
                Seam Planner
            </Button>
            <div
                style={{ width: "95vw" }}
                className="flex-column"
            >
                <table
                    className="table-fixed-new fill-width"
                    style={{ marginBottom: "1em" }}
                >
                    <thead>
                        <tr style={{ textAlign: "center" }}>
                            <th>Room</th>
                            <th>Area #</th>
                            <th>Labor</th>
                            <th>Scaled Amount</th>
                            <th>Unscaled Amount</th>
                            <th>Price</th>
                            <th>Cost</th>
                            <th>Material</th>
                            <th>Scaled Amount</th>
                            <th>Unscaled Amount</th>
                            <th>Price</th>
                            <th>Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.fullyScaledJobConfiguration.areas.flatMap((area, areaIndex) =>
                            area.rooms.flatMap((room, roomIndex) =>
                                room.services.map((service, serviceIndex) => {
                                    const unscaledService =
                                        unscaledData?.jobConfiguration.areas[areaIndex].rooms[
                                            roomIndex
                                        ].services[serviceIndex];

                                    const matchedPrice =
                                        priceData?.priceVerificationFields.services.find(
                                            (ser) => ser.id === service.id
                                        );
                                    const matchedService = serData?.allServiceProductOfferings.find(
                                        (js) => js.jobServiceId === service.jobServiceId
                                    );
                                    const matchedMaterial =
                                        matData?.allServiceMaterialCategoryPricings.find(
                                            (mat) =>
                                                mat.serviceMaterialCategoryId ===
                                                service.materialCategoryId
                                        );
                                    const jsName = matchedService?.description ?? "";
                                    const serName =
                                        service.isActive === false
                                            ? "Over Existing"
                                            : matchedService?.serviceType ?? "";

                                    const matName = matchedMaterial?.serviceMaterialCategory ?? "";

                                    const unscaledMatAmount =
                                        matchedMaterial !== undefined
                                            ? `${scaleAmount(
                                                  unscaledService?.materialAmount!,
                                                  matchedMaterial.priceUnit
                                              )} ${matchedMaterial.priceUnit}`
                                            : "";

                                    const matAmount =
                                        matchedMaterial !== undefined
                                            ? `${service.materialAmount} ${matchedMaterial.priceUnit}`
                                            : "";

                                    return (
                                        <tr
                                            key={service.id}
                                            style={{ textAlign: "center" }}
                                        >
                                            <td>{getNameOfArea(room.labels)}</td>
                                            <td>{areaIndex + 1}</td>
                                            <td>
                                                {serName} - {jsName}
                                            </td>
                                            <td>
                                                {service.laborAmount} {matchedService?.priceUnit}
                                            </td>
                                            <td>
                                                {scaleAmount(
                                                    unscaledService?.laborAmount!,
                                                    matchedService?.priceUnit ?? ""
                                                )}{" "}
                                                {matchedService?.priceUnit}
                                            </td>
                                            <td>
                                                {service.customerDoesService
                                                    ? "CUST"
                                                    : `$${matchedPrice?.price?.toFixed(2) ?? "-"}`}
                                            </td>
                                            <td>
                                                {service.customerDoesService
                                                    ? "CUST"
                                                    : `$${matchedPrice?.cost?.toFixed(2) ?? "-"}`}
                                            </td>
                                            <td>{matName}</td>
                                            <td>{matAmount}</td>
                                            <td>{unscaledMatAmount}</td>
                                            <td>
                                                {matchedPrice?.materialPrice === 0
                                                    ? ""
                                                    : `$${
                                                          matchedPrice?.materialPrice?.toFixed(2) ??
                                                          "-"
                                                      }`}
                                            </td>
                                            <td>
                                                {matchedPrice?.materialCost === 0
                                                    ? ""
                                                    : `$${
                                                          matchedPrice?.materialCost?.toFixed(2) ??
                                                          "-"
                                                      }`}
                                            </td>
                                        </tr>
                                    );
                                })
                            )
                        )}
                    </tbody>
                </table>
                <Typography
                    className="fill-width"
                    style={{ textAlign: "center" }}
                    variant="h4"
                >
                    Totals
                </Typography>
                <div
                    className="flex-row fill-width"
                    style={{ justifyContent: "center", alignItems: "start", paddingBottom: "2rem" }}
                >
                    <table
                        className="table-fixed-new unset-height"
                        style={{ marginRight: "2rem", minWidth: "35rem" }}
                    >
                        <thead>
                            <tr style={{ textAlign: "center" }}>
                                <th>Labor</th>
                                <th>Total Amount</th>
                                <th>Unscaled Amount</th>
                                <th>Price</th>
                                <th>Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedServices.ser.map((service) => {
                                const matchedService = serData?.allServiceProductOfferings.find(
                                    (js) => js.jobServiceId === service.jobServiceId
                                );
                                const jsName = matchedService?.description ?? "";
                                const serName = matchedService?.serviceType ?? "";
                                const totalAmount =
                                    matchedService?.priceUnit === "lnft"
                                        ? formatAsIntOrNDecimals(service.laborAmount, 1)
                                        : service.laborAmount.toFixed(0);

                                return (
                                    <tr
                                        key={service.id}
                                        style={{ textAlign: "center" }}
                                    >
                                        <td>
                                            {serName} - {jsName}
                                        </td>
                                        <td>
                                            {totalAmount} {matchedService?.priceUnit}
                                        </td>
                                        <td>
                                            {scaleAmount(
                                                service.rawLabor!,
                                                matchedService?.priceUnit ?? ""
                                            )}{" "}
                                            {matchedService?.priceUnit}
                                        </td>
                                        <td>
                                            {isNullOrUndefined(service?.laborPrice)
                                                ? "CUST"
                                                : `$${service?.laborPrice?.toFixed(2) ?? "-"}`}
                                        </td>
                                        <td style={{ width: "5rem" }}>
                                            {isNullOrUndefined(service?.laborCost)
                                                ? "CUST"
                                                : `$${service?.laborCost?.toFixed(2) ?? "-"}`}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div
                        className="flex-column"
                        style={{ minWidth: "30rem" }}
                    >
                        <table className="table-fixed-new  unset-height">
                            <thead>
                                <tr style={{ textAlign: "center" }}>
                                    <th>Material</th>
                                    <th>Material Amount</th>
                                    <th>Unscaled Amount</th>
                                    <th>Price</th>
                                    <th>Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                {groupedServices.mat.map((service) => {
                                    const matchedMaterial =
                                        matData?.allServiceMaterialCategoryPricings.find(
                                            (mat) =>
                                                mat.serviceMaterialCategoryId ===
                                                service.materialCategoryId
                                        );

                                    const matName = matchedMaterial?.serviceMaterialCategory ?? "-";
                                    const matAmount =
                                        matchedMaterial !== undefined
                                            ? `${service.materialAmount} ${matchedMaterial.priceUnit}`
                                            : "";
                                    const unscaledMatAmount =
                                        matchedMaterial !== undefined
                                            ? `${scaleAmount(
                                                  service.rawMaterial!,
                                                  matchedMaterial.priceUnit
                                              )} ${matchedMaterial.priceUnit}`
                                            : "";

                                    return (
                                        <tr
                                            key={service.id}
                                            style={{ textAlign: "center" }}
                                        >
                                            <td>{matName}</td>
                                            <td>{matAmount}</td>
                                            <td>{unscaledMatAmount}</td>
                                            <td>
                                                {isNullOrUndefined(service?.materialPrice)
                                                    ? "CUST"
                                                    : `$${
                                                          service?.materialPrice?.toFixed(2) ?? "-"
                                                      }`}
                                            </td>
                                            <td style={{ width: "5rem" }}>
                                                {isNullOrUndefined(service?.materialCost)
                                                    ? "CUST"
                                                    : `$${
                                                          service?.materialCost?.toFixed(2) ?? "-"
                                                      }`}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <table
                            className="table-fixed-new unset-height"
                            style={{ marginTop: "2rem" }}
                        >
                            <thead>
                                <tr style={{ textAlign: "center" }}>
                                    <th>Area</th>
                                    <th>#</th>
                                    <th>Style</th>
                                    <th>Color</th>
                                    <th>Amount</th>
                                    <th>Waste</th>
                                    <th>Price</th>
                                    <th>Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.fullyScaledJobConfiguration.areas.map((area, index) => {
                                    const areaSqft = area.rooms
                                        .map((room) => room.sqft)
                                        .reduce((t, v) => t + v, 0);

                                    const wasteFactor = area.sqftWasteFactor ?? 0;

                                    const stylePricing = styles.find(
                                        (style) => style.id === area.id
                                    );

                                    return (
                                        <tr
                                            key={area.id}
                                            style={{ textAlign: "center" }}
                                        >
                                            <td>
                                                {getNameOfArea(
                                                    area.rooms.flatMap((room) => room.labels)
                                                )}
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>{area.styleName}</td>
                                            <td>{area.colorName}</td>
                                            <td>{areaSqft.toFixed(0)} sqft</td>
                                            <td>{(wasteFactor * 100).toFixed(0)}%</td>
                                            <td>
                                                {isNullOrUndefined(stylePricing?.price)
                                                    ? "CUST"
                                                    : `$${stylePricing?.price?.toFixed(2) ?? "-"}`}
                                            </td>
                                            <td style={{ width: "5rem" }}>
                                                {isNullOrUndefined(stylePricing?.cost)
                                                    ? "CUST"
                                                    : `$${stylePricing?.cost?.toFixed(2) ?? "-"}`}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <table
                            className="table-fixed-new unset-height"
                            style={{ marginTop: "2rem" }}
                        >
                            <thead>
                                <tr style={{ textAlign: "center" }}>
                                    <th>Total Price</th>
                                    <th>Total Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ textAlign: "center" }}>
                                    <td>
                                        $
                                        {styles
                                            .reduce(
                                                (tot, sty) => tot + sty.price,
                                                services.reduce(
                                                    (tot, ser) =>
                                                        tot + ser.price + ser.materialPrice,
                                                    0
                                                )
                                            )
                                            .toFixed(2)}
                                    </td>
                                    <td>
                                        $
                                        {styles
                                            .reduce(
                                                (tot, sty) => tot + sty.cost,
                                                services.reduce(
                                                    (tot, ser) => tot + ser.cost + ser.materialCost,
                                                    0
                                                )
                                            )
                                            .toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </NavbarPage>
    );
}

function scaleAmount(amount: number, unit: string) {
    if (unit === "lnft") return amount.toFixed(1);
    else return amount.toFixed(0);
}

/* Old Code 


    // // gives us the data for the material/labor breakdown for the job
    // const { data: breakdownData, loading: breakdownDataLoading } = useGetJobBreakdownQuery({
    //     variables: { jobConfigurationId: jobConfigurationId! },
    //     skip: isNullOrUndefined(jobConfigurationId)
    // });

    // function breakdownDataOk() {
    //     return !breakdownDataLoading && isNotNullOrUndefined(breakdownData) && isNotNullOrUndefined(breakdownData?.jobBreakdown.areaBreakdowns);
    // }


                { {breakdownDataOk() ? (
                    <>
                        <MaterialBreakdownTable
                            areaBreakdowns={breakdownData!.jobBreakdown.areaBreakdowns}
                        />
                        <LaborBreakdownTable
                            topMargin="md"
                            areaBreakdowns={breakdownData!.jobBreakdown.areaBreakdowns}
                        />
                    </>) :
                    <Loading />
                } 
*/
