import { Button, FormControl, FormLabel, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ToggleableSection } from "Components/PageLayout/Section";
import { Market, namedOperations, useAddMarketMutation, useCanDeleteMarketQuery, useDeleteMarketMutation, useUpdateMarketMutation } from "generated/graphql";
import { isEmptyString } from "Globals/GenericValidators";
import { useState } from "react";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import NumberFormat from "react-number-format";

export default function MarketManagementSection({markets}: {markets: Market[]}) {
    const [newMarketName, setNewMarketName] = useState('');
    const [newMarketHours, setNewMarketHours] = useState(1);
    const [newMarketMinutes, setNewMarketMinutes] = useState(30);
    
    function onChangeHours(newHours: number) {
        if (newHours >= 0) {
            setNewMarketHours(newHours);
        }
    }

    function onChangeMinutes(newMinutes: number) {
        if (newMinutes >= 0 && newMinutes < 60) {
            setNewMarketMinutes(newMinutes);
        }
    }
    
    const [addMarketSucceeded, setAddMarketSucceeded] = useState(false);
    const [addMarket] = useAddMarketMutation({
        onCompleted: (res) => {
            if (res.addMarket) {
                setNewMarketName('');
                setNewMarketHours(1);
                setNewMarketMinutes(30);
                setAddMarketSucceeded(true);
                setTimeout(() => setAddMarketSucceeded(false), 3000);
            } else {
                alert("Could not add market")
            }
        },
        onError: () => alert("Could not add market"),
        refetchQueries: [namedOperations.Query.GetAllMarkets]
    });

    function onSubmitMarket() {
        addMarket({
            variables: {
                name: newMarketName,
                appointmentHours: newMarketHours,
                appointmentMinutes: newMarketMinutes
            }
        });
    }

    return (
        <ToggleableSection column alignTop title="Manage Markets">
            <div className="flex-column">
                <h5>All Markets</h5>
                <MarketTable markets={markets}/>
            </div>


            <div className="flex-column margin-top-sm">
                <h5>Add Market</h5>
                <div className="flex-row flex-gap-sm">    
                    <FormControl>
                        <FormLabel component="legend">Market Name</FormLabel>
                        <TextField
                            style={{width: 150}}
                            value={newMarketName} onChange={e => setNewMarketName(e.target.value)}
                        />
                    </FormControl>
                    
                    <FormControl>
                        <FormLabel component="legend">Duration (Hours)</FormLabel>
                        <TextField
                            style={{width: 50}}
                            type="number"
                            value={newMarketHours} onChange={e => onChangeHours(+e.target.value)}
                        />
                    </FormControl>
                    
                    <FormControl>
                        <FormLabel component="legend">Duration (Minutes)</FormLabel>
                        <TextField
                            style={{width: 50}}
                            type="number"
                            inputProps={{step: 15}}
                            value={newMarketMinutes} onChange={e => onChangeMinutes(+e.target.value)}
                        />
                    </FormControl>
                </div>

                <div className="flex-row align-items-center">    
                    <Button
                        style={{height: "fit-content", width: "fit-content"}}
                        variant="contained"
                        disabled={isEmptyString(newMarketName) || (newMarketHours === 0 && newMarketMinutes === 0)}
                        className="margin-vertical-sm"
                        onClick={onSubmitMarket}
                    >Add Market</Button>

                    {addMarketSucceeded && (
                        <Typography className="success-text margin-left-xsm">Market successfully added</Typography>
                    )}
                </div>
            </div>
        </ToggleableSection>
    )
}

function MarketTable({markets}: {markets: Market[]}) {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell><Typography><b>Market Name</b></Typography></TableCell>
                        <TableCell><Typography><b>Appointment Length</b></Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {markets.map(m => (
                        <MarketTableRow market={m}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function MarketTableRow({market}: {market: Market}) {
    const [editing, setEditing] = useState(false);
    const [editedName, setEditedName] = useState(market.name);
    const [editedHours, setEditedHours] = useState(market.appointmentHours.toString());
    const [editedMins, setEditedMins] = useState(market.appointmentMinutes.toString());

    const {data} = useCanDeleteMarketQuery({
        variables: {marketId: market.id}
    });
    const canDelete = data?.canDeleteMarket ?? false;

    const [updateMarket, {loading: updating}] = useUpdateMarketMutation({
        refetchQueries: [namedOperations.Query.GetAllMarkets],
        onError: () => alert("Could not update market")
    });

    function canUpdate() {
        if (isEmptyString(editedName)) {
            alert("Enter a name for the market");
            return false;
        }

        if (isEmptyString(editedHours) || isEmptyString(editedMins)) {
            alert("Enter a value for the appointment duration");
            return false;
        }

        return true;
    }

    function onCancelChanges() {
        setEditing(false);
        setEditedName(market.name);
        setEditedHours(market.appointmentHours.toString());
        setEditedMins(market.appointmentMinutes.toString());
    }

    function onUpdate() {
        const changes =  [editedName !== market.name, editedHours !== market.appointmentHours.toString(), editedMins !== market.appointmentMinutes.toString()];
        if (canUpdate() && changes.some(isChanged => isChanged)) {
            updateMarket({
                variables: {
                    id: market.id,
                    name: changes[0] ? editedName : null,
                    appointmentHours: (changes[1] || changes[2]) ? +editedHours : null,
                    appointmentMinutes: (changes[1] || changes[2]) ? +editedMins : null
                }
            }).then(() => setEditing(false));
        }
    }

    const [deleteMarket, {loading: deleting}] = useDeleteMarketMutation({
        onError: () => alert("Could not delete market"),
        refetchQueries: [namedOperations.Query.GetAllMarkets]
    });

    function onDelete() {
        if (canDelete && window.confirm("Are you sure you want to delete this market?")) {
            deleteMarket({variables: {marketId: market.id}});
        }
    }

    return (
        <TableRow key={`mkt-detail-${market.id}`}>
            {editing ? (
                <TableCell>
                    <IconButton onClick={onCancelChanges} disabled={updating}><CancelIcon /></IconButton>
                    <IconButton onClick={onUpdate} disabled={updating}><CheckIcon /></IconButton>
                </TableCell>
            ) : (
                <TableCell>
                    <IconButton onClick={() => setEditing(true)} disabled={deleting}><EditIcon /></IconButton>
                    {canDelete ? (
                        <IconButton onClick={onDelete} disabled={deleting}><DeleteIcon /></IconButton>
                    ) : (
                        <Tooltip title="This market can't be deleted">
                            {/* https://mui.com/material-ui/react-tooltip/#disabled-elements */}
                            <span>
                                <IconButton disabled><DeleteIcon /></IconButton>
                            </span>
                        </Tooltip>
                    )}
                </TableCell>
            )}

            <TableCell className="w-10r">
                {editing ? (
                    <TextField value={editedName} onChange={e => setEditedName(e.target.value)} />
                ) : (
                    <Typography>{market.name}</Typography>
                )}
            </TableCell>
            
            {/* TODO: update the input for the new market to have teh same isAllowed functions */}
            <TableCell className="w-15r">
                {editing ? (
                    <div className="flex-row align-items-center">
                        <NumberFormat
                            style={{width: ".7rem"}}
                            value={editedHours} onValueChange={newVal => setEditedHours(newVal.value)}
                            decimalScale={0} fixedDecimalScale
                            allowNegative={false}
                            customInput={TextField}
                            isAllowed={(values) => values.value.length <= 1}
                        />
                        <Typography className="margin-right-xsm">h</Typography>

                        <NumberFormat
                            style={{width: "1.3rem"}}
                            value={editedMins} onValueChange={newVal => setEditedMins(newVal.value)}
                            decimalScale={0} fixedDecimalScale
                            allowNegative={false}
                            customInput={TextField}
                            // max allowed value is 59
                            isAllowed={(values) => {
                                const value = values.value;
                                if (value.length === 0) return true;
                                else if (value.length < 3) {
                                    return +value < 60;
                                } else {
                                    return false;
                                }
                            }}
                        />
                        <Typography>m</Typography>
                    </div>
                ) : (
                    <Typography>{market.appointmentHours}h {market.appointmentMinutes < 10 ? `0${market.appointmentMinutes}` : market.appointmentMinutes}m</Typography>
                )}
            </TableCell>
        </TableRow>
    )
}