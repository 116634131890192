import { Typography } from "@material-ui/core";
import { useGetJobInvoiceDataQuery, useGetQuoteQuery } from "generated/graphql";
import { dateTimeStrToDate, dateTimeStrToMdy, dateToMdy } from "Globals/DateAndTimeHelpers";
import { useTwoNumericIdParams } from "Globals/Hooks";
import { useEffect, useState } from "react";
import { AreaInvoiceRow } from "./AreaInvoiceRow";
import InvoiceDocumentHeader from "./InvoiceDocumentHeader";

/**
 * This component is used for PDF generation.
 */
export default function QuotePdfPage() {
    const { id1: quoteId, id2: jobConfigurationId } = useTwoNumericIdParams();

    const { data } = useGetJobInvoiceDataQuery({
        variables: { jobConfigurationId: jobConfigurationId ?? 0 },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only",
        skip: (jobConfigurationId ?? 0) < 1
    });

    const customer = data?.jobConfigurationHeader.customer;

    const { data: quoteData } = useGetQuoteQuery({
        variables: {
            quoteId: quoteId
        }
    });
    const quote = quoteData?.quote;

    // quote expires 10 days after it is given
    function getQuoteExpirationDate(quotedDate: Date) {
        let exp = new Date(quotedDate);
        exp.setDate(exp.getDate() + 10);
        return exp;
    }

    const [pdfHook, setPdfHook] = useState("");
    // wait 10 seconds to set the hook to ensure all queries load
    useEffect(() => {
        setTimeout(() => setPdfHook("quote-pdf-hook"), 10000);
    }, []);

    return (
        <>
        {(customer && quote && data) && (
            <div
                className="padding-sm"
                id={pdfHook}
                style={{ maxWidth: "80rem", margin: "0 auto" }}
            >
                <InvoiceDocumentHeader
                    documentTitle="Quote"
                    sahOrQuoteNumber={`Q-${quote.quoteNumber}`}
                    {...customer!}
                    date={dateTimeStrToDate(quote.date)}
                    receivePromoEmails={false} // not relevant, but is a required prop
                    salesperson1={data?.jobConfigurationHeader.assignedSalespeople?.[0]}
                    salesperson2={data?.jobConfigurationHeader.assignedSalespeople?.[1]}      
                    onEdit={() => {}}  // quote sheet is only to be printed
                    renderExpanded={true}
                />

                <table className="margin-bottom-sm fill-width">
                    <tbody>
                        {quote.areas!.map((area) => {
                            return (
                                <AreaInvoiceRow
                                    key={`area-index-${area.id}`}
                                    area={area}
                                    notes={area.notes ?? ""}
                                    // this page doesn't allow editing
                                    onChangeNotes={(value) => {return}}
                                    printMode={true}
                                />
                            );
                        })}
                    </tbody>
                </table>

                <div className="flex-column">
                    <Typography>Total Price: <b>${quote.totalPrice!.toFixed(2)}</b></Typography>
                    <Typography>Offer valid until <b>{dateToMdy(getQuoteExpirationDate(dateTimeStrToDate(quote.date)))}</b></Typography>
                </div>

                <Typography><b>Notes:</b></Typography>
                <div className="flex-column solid-border padding-side-xsm">
                    <pre>Will call to follow up on <b>{`${dateTimeStrToMdy(quote.followUpDate)}\n`}</b>{quote.notes ?? ''}</pre>
                </div>
            </div>
        )}
        </>
    )
}