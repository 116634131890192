import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Tooltip } from "@mui/material";
import FlatButton from "FlatComponents/Button/FlatButton";
import { FlatCircleCheckIcon } from "FlatComponents/Button/FlatCircleCheckButton";
import FlatHistoryButton from "FlatComponents/Button/FlatHistoryIcon";
import { FlatLabeledRadioGroup, FlatRadioGroupOption } from "FlatComponents/Inputs/FlatRadioGroup";
import { useGetAllServicedLocationInformationQuery } from "generated/graphql";
import { useMemo, useState } from "react";
import WorkerServiceAreaSelector from "./WorkerServiceAreaSelector";
import WorkerServicedZipEditor from "./WorkerServicedZipEditor";

interface WorkerServiceAreaEditorProps {
    servicedZips: string[];
    // parent component must decide how DB mutation works, since this component
    // doesn't know what type of worker it's editing (contractor or salesperson)
    onSubmit: (added: string[], removed: string[]) => void;
    disableSubmit: boolean; // disable when db mutation is loading
    updateSucceeded: boolean; // to let the user know everything went alright in the mutation
}

function determineAddedAndRemovedZips(original: string[], current: string[]): {added: string[], removed: string[]} {
    const added = current.filter(currZip => !original.includes(currZip));
    const removed = original.filter(ogZip => !current.includes(ogZip));
    return {added: added, removed: removed}
}

type Granularity = "market" | "county" | "city" | "zip";

export default function WorkerServiceAreaEditor({
    servicedZips: originalServicedZips,
    onSubmit,
    disableSubmit,
    updateSucceeded
}: WorkerServiceAreaEditorProps ) {
    const [servicedZips, setServicedZips] = useState(originalServicedZips);
    
    const granularityOptions: FlatRadioGroupOption[] = [
        {label: "Market", value: "market"},
        {label: "County", value: "county"},
        {label: "City", value: "city"},
        {label: "Zip", value: "zip"}
    ];
    const [granularity, setGranularity] = useState<Granularity>("market");
    
    const {data: serviceAreaData} = useGetAllServicedLocationInformationQuery({
        onError: () => alert("Failed to load service area information")
    });
    // consists of zips and their markets/counties/cities
    const rawZipRelationData = useMemo(() => serviceAreaData?.allActiveZipsAndCities.filter(zrd => zrd.serviced) ?? [], [serviceAreaData?.allActiveZipsAndCities]);
    const rawMarketData = useMemo(() => serviceAreaData?.allMarkets ?? [], [serviceAreaData?.allMarkets]);
    const rawCountyData = useMemo(() => serviceAreaData?.allCounties ?? [], [serviceAreaData?.allCounties]);
    const allZips = useMemo(() => {
        const zips = [...new Set(rawZipRelationData.map(rzd => rzd.zip))];
        zips.sort();
        return zips;
    }, [rawZipRelationData]);
    
    const markets: AreaAndZips[] = useMemo(() => {
        const marketList: AreaAndZips[] = [];
        rawMarketData.forEach(mkt => {
            const zipsForThisMarket = [...new Set(rawZipRelationData.filter(zrd => zrd.marketId === mkt.id).map(zcd => zcd.zip))];
            marketList.push({areaName: mkt.name, zipsInArea: zipsForThisMarket});
        });
        
        return marketList.filter(m => m.zipsInArea.length > 0);
    }, [rawMarketData, rawZipRelationData]);

    const counties: AreaAndZips[] = useMemo(() => {
        const countyList: AreaAndZips[] = [];
        rawCountyData.forEach(county => {
            const zipsForThisCounty = [...new Set(rawZipRelationData.filter(zrd => zrd.countyId === county.id).map(zcd => zcd.zip))];
            countyList.push({areaName: county.name, zipsInArea: zipsForThisCounty});
        });
        
        return countyList.filter(c => c.zipsInArea.length > 0);
    }, [rawCountyData, rawZipRelationData]);

    const cities: AreaAndZips[] = useMemo(() => {
        const cityToZipsMap: {[city: string]: string[]} = {};
        rawZipRelationData.forEach(zrd => {
            const cityName = zrd.city;
            if (Object.keys(cityToZipsMap).includes(cityName)) {
                cityToZipsMap[cityName].push(zrd.zip);
            } else {
                cityToZipsMap[cityName] = [zrd.zip];
            }
        });

        let cityList: AreaAndZips[] = [];
        Object.keys(cityToZipsMap).forEach(cityName => {
            const zipsForThisCity = [...new Set(cityToZipsMap[cityName])];
            cityList.push({areaName: cityName, zipsInArea: zipsForThisCity});
        });
        
        cityList = cityList.filter(c => c.zipsInArea.length > 0);
        cityList.sort((c1, c2) => c1.areaName > c2.areaName ? 1 : -1);
        return cityList;
    }, [rawZipRelationData]);

    const selectorBody: ReactJSXElement = useMemo(() => {
        switch (granularity) {
            case "market": 
                return <WorkerServiceAreaSelector servicedZips={servicedZips} setServicedZips={setServicedZips} areas={markets}/>
            case "county":
                return <WorkerServiceAreaSelector servicedZips={servicedZips} setServicedZips={setServicedZips} areas={counties}/>
            case "city":
                return <WorkerServiceAreaSelector servicedZips={servicedZips} setServicedZips={setServicedZips} areas={cities}/>
            case "zip":
                return <WorkerServicedZipEditor servicedZips={servicedZips} setServicedZips={setServicedZips} allZips={allZips}/>
            default:
                return <></>
        }
    }, [granularity, markets, servicedZips, cities, counties, allZips]);

    function submit() {
        const {added, removed} = determineAddedAndRemovedZips(originalServicedZips, servicedZips);
        onSubmit(added, removed);
    }
    
    return (
        <div className="flex-row fill-width">
            <div className="flex-column fill-width">
                <p className="flat-font bold-text">
                    Edit Service Area
                </p>

            <div className="flex-column flex-gap-xsm">
                    <FlatLabeledRadioGroup
                        label="Granularity"
                        options={granularityOptions}
                        value={granularity}
                        onChange={(newGranularity) => setGranularity(newGranularity as Granularity)}
                        size="small"
                    />

                    <div className="flex-row flex-gap-sm align-items-center margin-left-xsm">
                        <Tooltip title="Reset Changes">
                            <div>
                                <FlatHistoryButton onClick={() => setServicedZips(originalServicedZips)}/>
                            </div>
                        </Tooltip>
                        
                        <FlatButton
                            className="fit-content"
                            color="secondary"
                            onClick={submit}
                            disabled={disableSubmit}
                        >
                            Submit Changes
                        </FlatButton>

                        {updateSucceeded && (
                            <FlatCircleCheckIcon color="green"/>
                        )}
                    </div>


                    {selectorBody}
                </div>
            </div>
        </div>
    )
}

// area may be market, county, or city
export interface AreaAndZips {
    areaName: string;
    zipsInArea: string[];
}
