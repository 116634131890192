import clsx from "clsx";
import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import { FlatAlertIcon } from "FlatComponents/Button/FlatAlertButton";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import { BackgroundCheck } from "generated/graphql";
import { makeEmptyBgCheck } from "Globals/DataStructures/EmptyDataStructures";
import { dateTimeStrToMdy } from "Globals/DateAndTimeHelpers";
import { isDocumentOverdue, isNotNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import BackgroundCheckDialog from "../Dialogs/BackgroundCheckDialog";

interface BackgroundCheckTableProps {
    workerId: number;
    backgroundChecks: BackgroundCheck[];
    toRefetchOnUpdate: string[]; // the queries to refetch when updates are made to bg checks
}

export function BackgroundCheckTable({workerId, backgroundChecks, toRefetchOnUpdate}: BackgroundCheckTableProps) {
    const [dialogOpen, setDialogOpen] = useState(false);
    return (<>
        <div className="flat-font profile-left-column-table-container">
            <div>
                <div className="thin-horizontal-bar grid-colspan-1-3" />
                <p>Background Check</p>
                <div className="grid-33-33-33 margin-bottom-sm">
                    <span className="profile-table-cell profile-table-add-row grid-colspan-1-4">
                        <FlatAddButton onClick={() => setDialogOpen(true)}/>
                    </span>
                    {backgroundChecks.length === 0 && <p className="grid-colspan-1-3">Worker has no background checks</p>}
                    {backgroundChecks.map((bgc, idx) => (
                        <BackgroundCheckTableRow
                            key={bgc.id}
                            backgroundCheck={bgc}
                            toRefetchOnUpdate={toRefetchOnUpdate}
                        />
                    ))}
                </div>
            </div>
        </div>

        {dialogOpen && (
            <BackgroundCheckDialog
                bgCheck={makeEmptyBgCheck(workerId)}
                onClose={() => setDialogOpen(false)}
                toRefetchOnUpdate={toRefetchOnUpdate}
            />)
        }
    </>)
}

interface BackgroundCheckTableRowProps {
    backgroundCheck: BackgroundCheck;
    toRefetchOnUpdate: string[]; // the queries to refetch when updates are made to bg checks
}

function BackgroundCheckTableRow({backgroundCheck, toRefetchOnUpdate}: BackgroundCheckTableRowProps) {
    const [editorOpen, setEditorOpen] = useState(false);
    let bgCheckDescription = backgroundCheck.isInternal ? "Internal" : "3rd Party";
    if (isNotNullOrUndefined(backgroundCheck.number)) {
        bgCheckDescription += ` - ${backgroundCheck.number}`
    }
    
    const isOverdue = isDocumentOverdue(backgroundCheck.expirationDate);

    return (<>
        <p className="profile-table-cell profile-table-label-cell profile-table-cell-inner">
            {bgCheckDescription}
        </p>
        <p className="profile-table-cell profile-table-date-cell profile-table-cell-inner">
            {`${dateTimeStrToMdy(backgroundCheck.datePerformed)} - ${dateTimeStrToMdy(backgroundCheck.expirationDate)}`}
        </p>
        <span className="profile-table-cell profile-table-action-cell">
            <span className={clsx({"visibility-hidden": !isOverdue})}>
                <FlatAlertIcon color="var(--flat-orange)"/>
                <p className="flat-font-xsm">Overdue</p>
            </span>
            <FlatEditButton onClick={() => setEditorOpen(true)}/>
        </span>

        {editorOpen && (
            <BackgroundCheckDialog
                bgCheck={backgroundCheck}
                onClose={() => setEditorOpen(false)}
                toRefetchOnUpdate={toRefetchOnUpdate}
            />
        )}
    </>)
}
