import { Input } from "@material-ui/core"
import { isNumberWithNoTrailingDecimal } from "Globals/GenericValidators"
import _ from "lodash"
import { MaterialAmountEditorProps } from "./MaterialForServiceColumns"



interface BiasMaterialAmountEditorProps extends MaterialAmountEditorProps {
    addedBias: number
}

function BiasMaterialAmountEditor({ addedBias, value, onChange, endAdornment, ...otherProps }: BiasMaterialAmountEditorProps) {
    const valAsNumber = isNumberWithNoTrailingDecimal(value ?? "") ? _.round(+(value!) - addedBias, 4) : undefined

    function updateAmount(newValue?: string) {
        if (isNumberWithNoTrailingDecimal(newValue ?? "")) {
            const biasedNumber = _.round(+(newValue!) + addedBias, 4)
            onChange(biasedNumber.toString())
        }
        else {
            onChange(newValue)
        }
    }

    return (
        <Input
            className="whitespace-no-wrap"
            startAdornment={<div style={{paddingRight:".25rem"}}>Width:</div>}
            value={valAsNumber ?? value}
            onChange={e => updateAmount(e.target.value)}
            endAdornment={`ft`}
            {...otherProps}
        />
    )
}


export function ClosedEndsMaterialAmountEditor({ ...props }: MaterialAmountEditorProps) {
    return <BiasMaterialAmountEditor addedBias={0} {...props} />
}

export function SingleCapMaterialAmountEditor({ ...props }: MaterialAmountEditorProps) {
    return <BiasMaterialAmountEditor addedBias={1} {...props} />
}

export function DoubleCapMaterialAmountEditor({ ...props }: MaterialAmountEditorProps) {
    return <BiasMaterialAmountEditor addedBias={2} {...props} />
}