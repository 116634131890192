import { Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useState } from "react";
import Loading from "../Loading";
import './gallery.css';

const useStyles = makeStyles({ paper: { backgroundColor: '#555' }, fullPaper: { backgroundColor: '#555', width: "100vw" } })

interface GalleryImageProps {
    label: string,
    imageSrc?: string,
    description?: string,
    onDeleteImage?: () => void,
    askForDeletePermission?: boolean,
    mobile?: boolean 
}

export default function GalleryImage({ label, imageSrc, description, onDeleteImage, askForDeletePermission, mobile }: GalleryImageProps) {
    const [open, setOpen] = useState<boolean>(false)
    const [hide, setHide] = useState<boolean>(false)

    const classes = useStyles()

    const imageLoaded = imageSrc !== undefined
    const showDelete = onDeleteImage !== undefined

    function deleteImage() {
        const askToDelete = askForDeletePermission ?? true
        if (askToDelete && window.confirm("Are you sure you wish to delete this photo?")) {
            onDeleteImage!();
        }
    }

    return (
        <div
            onClick={() => { if (imageLoaded && !open) { setOpen(true) } }}
            style={{
                    height: "14rem",
                    width: mobile ? "100vw" : "19rem",
                    backgroundColor: "#4b4f4e",
                    margin: ".5rem", 
                    position: "relative",
                    border: "10px solid black",
                    borderRadius: "16px"
                }
            }
            className="gallery-image flex-row flex-centered flex-space-around">
            <div style={{ position: "absolute", top: 0, width: "100%", backgroundColor: "#00000044" }}
                className='flex-row flex-space-between'>
                <Typography style={{ color: 'whitesmoke', paddingLeft: ".5em", fontSize: "2em" }}>{label}</Typography>
                <IconButton
                    hidden={!showDelete}
                    style={{ color: 'whitesmoke' }}
                    onClick={e => { e.stopPropagation(); deleteImage(); }}>
                    <DeleteIcon />
                </IconButton>
            </div>

            {imageLoaded ? (
                <img
                    draggable={false}
                    src={imageSrc}
                    alt={description ?? "No description given"}
                    style={{ maxWidth: "calc(19rem - 20px)", maxHeight: "calc(14rem - 20px)", backgroundColor: "white" }}
                />
            ) : (
                <Loading />
            )}

            <Dialog
                fullScreen={mobile}
                fullWidth={mobile}
                maxWidth={false}
                open={open}
                onClose={() => { setOpen(false); setHide(false); }}
                className='flex-column flex-centered flex-space-around'
                classes={{ paper:  mobile ? classes.fullPaper : classes.paper }}>
                <div
                    onClick={() => setHide(!hide)}
                    style={{
                        position: "relative",
                        minHeight: "70vh",
                        minWidth: "50vw",
                        backgroundColor: "#555",
                        backfaceVisibility: "hidden",
                        height: mobile ? "100%" : undefined
                    }}
                    className="flex-column flex-space-around ">
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            width: "100%",
                            backgroundColor: "#3339",
                            justifyContent: "space-between"
                        }}
                        className='flex-row'>
                        <Typography style={{ color: 'whitesmoke', paddingLeft: ".5em", fontSize: "2em" }}>{label}</Typography>
                        <IconButton
                            onClick={(e) => { setOpen(false); setHide(false); e.stopPropagation() }}
                            size="medium">
                            <HighlightOffIcon style={{ backgroundColor: "#aaaa", borderRadius: "1rem" }} />
                        </IconButton>
                    </div>
                    <div hidden={hide} style={{ position: "absolute", bottom: 0, width: "100%", backgroundColor: "#3339" }}>
                        <Typography
                            style={{
                                color: 'whitesmoke',
                                paddingLeft: ".5em",
                                fontSize: `calc(1.5em * ${4 / Math.max(4, (description?.length ?? 75) / 75)})`
                            }}>
                            {description}
                        </Typography>
                    </div>
                    <div className="flex-row flex-space-around" style={{ maxWidth: "100vw", maxHeight: "95vh" }} >
                        <img
                            src={imageSrc}
                            alt={description ?? "No description given"}
                            style={{ maxWidth: "100vw", maxHeight: "95vh", backgroundColor: "white" }} />
                    </div>
                </div>
            </Dialog>
        </div>
    )
}