import { S3File } from "generated/graphql";
import { extractFileExtension, isImageExtension, PDF_EXT } from "Globals/FileUtils";
import { useEffect, useMemo } from "react";
import { S3ImageViewer, S3ImageViewerDialog } from "./S3ImageViewers";
import { S3PdfViewer, S3PdfViewerDialog } from "./S3PdfViewers";

/**
 * This should be used when the file to be viewed may be of different types (e.g., the file may be a PDF or an image).
 * This component will determine which component should be used to display the file based on its extension.
 * The file passed to this function MUST have a file extension, or an Error will be thrown.
 */
export function S3GenericFileViewer({file}: {file: S3File}) {
    const fileExtension = extractFileExtension(file.fileName);

    if (fileExtension === PDF_EXT) {
        return <S3PdfViewer fileId={file.id}/>
    } else if (isImageExtension(fileExtension)) {
        return <S3ImageViewer fileId={file.id}/>
    } else {
        alert(`Attempting to load file with unknown or unsupported extension ".${fileExtension}"`);
        return <></>;
    }
}

export interface S3GenericFileViewerDialogProps {
    file: S3File;
    open: boolean;
    onClose: () => void;
    header?: string;
    onDelete?: () => void;
    disableDeleteButton?: boolean;  // has no effect if onDelete is not passed
}

/**
 * This should be used when the file to be viewed may be of different types (e.g., the file may be a PDF or an image).
 * This component will determine which component should be used to display the file based on its extension.
 * The file passed to this function MUST have a file extension, or an Error will be thrown.
 */
export function S3GenericFileViewerDialog({
    file,
    open,
    onClose,
    header,
    onDelete,
    disableDeleteButton=true
}: S3GenericFileViewerDialogProps) {
    const fileExtension = useMemo(() => extractFileExtension(file.fileName), [file.fileName]);
    useEffect(() => {
        if (fileExtension !== PDF_EXT && !isImageExtension(fileExtension)) {
            alert(`Attempting to load file with unknown or unsupported extension ".${fileExtension}"`);
        }
    }, [fileExtension])

    if (fileExtension === PDF_EXT && open) {
        return <S3PdfViewerDialog
            fileId={file.id}
            onClose={onClose}
            onDelete={onDelete}
            disableDeleteButton={disableDeleteButton}
        />
    } else if (isImageExtension(fileExtension)) {
        return <S3ImageViewerDialog
            fileId={file.id}
            open={open}
            onClose={onClose}
            header={header}
            onDelete={onDelete}
            disableDeleteButton={disableDeleteButton}
        />
    } else {
        return <></>;
    }
}