import { MenuItem, Select } from "@material-ui/core";
import NavbarPage from "Components/Page";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";
import FlatDatePicker from "FlatComponents/Visual/FlatDatePicker";
import { addDaysToDate, dateToYmd } from "Globals/DateAndTimeHelpers";
import { useMemo, useState } from "react";
import WeeklyInstallationCalendar from "./WeeklyInstallationCalendar";

export default function InstallationCalendarPage() {
    const [date, setDate] = useState<Date>(new Date());
    const [daysBefore, setDaysBefore] = useState<number>(3);
    const [daysAfter, setDaysAfter] = useState<number>(21);

    const ymdDate = useMemo(() => dateToYmd(date), [date])

    const totalDays = daysBefore + daysAfter
    const startDay = addDaysToDate(ymdDate, 1 - daysBefore)

    return (
        <NavbarPage hideNavbar>
            <FlatNavbar title="Installation Calendar" />
            <div
                style={{
                    width: "100%",
                    backgroundColor: "var(--flat-gray-6)",
                    alignItems: "center",
                    height: "3rem",
                }}
                className="flex-row flat-dark-bkg flat-font-md"
            >
                <div className="flex-grow"></div>
                <div>Show Before:</div>
                <Select
                    className="flat-force-white-text"
                    style={{ marginRight: "5rem", padding: "0 5px" }}
                    inputProps={{ className: "flat-force-white-text" }}
                    required
                    value={daysBefore}
                    onChange={v=> setDaysBefore(v.target.value as number)}
                >
                    <MenuItem value={1}>1 Day</MenuItem>
                    <MenuItem value={2}>2 Days</MenuItem>
                    <MenuItem value={3}>3 Days</MenuItem>
                    <MenuItem value={4}>4 Days</MenuItem>
                    <MenuItem value={5}>5 Days</MenuItem>
                    <MenuItem value={6}>6 Days</MenuItem>
                    <MenuItem value={7}>1 Week</MenuItem>
                    <MenuItem value={7 * 2}>2 Weeks</MenuItem>
                    <MenuItem value={7 * 3}>3 Weeks</MenuItem>
                    <MenuItem value={7 * 4}>4 Weeks</MenuItem>
                </Select>
                <div
                    className="flex-row flat-dark-bkg"
                    style={{ alignItems: "center" }}
                >
                    <FlatDatePicker selected={date} onChange={(d) => setDate(d ?? new Date())}/>
                </div>
                <div style={{ marginLeft: "5rem" }}>Show After:</div>
                <Select
                    className="flat-force-white-text"
                    style={{ padding: "0 5px" }}
                    inputProps={{ className: "flat-force-white-text" }}
                    required
                    value={daysAfter}
                    onChange={v=> setDaysAfter(v.target.value as number)}
                >
                    <MenuItem value={7}>1 Week</MenuItem>
                    <MenuItem value={7 * 2}>2 Weeks</MenuItem>
                    <MenuItem value={7 * 3}>3 Weeks</MenuItem>
                    <MenuItem value={7 * 4}>4 Weeks</MenuItem>
                    <MenuItem value={7 * 5}>5 Weeks</MenuItem>
                </Select>
                <div className="flex-grow"></div>
            </div>
            <div style={{ height: "calc(100vh - 72px - 3rem)" }}>
                <WeeklyInstallationCalendar startDate={startDay} totalDaysFromStart={totalDays} />
            </div>
        </NavbarPage>
    );
}
