import { Typography } from "@material-ui/core";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import { ToggleableSection } from "Components/PageLayout/Section";
import { GetAllZipsAndCitiesQuery, useGetAllCountiesQuery, useGetAllMarketsQuery, useGetAllZipsAndCitiesQuery } from "generated/graphql";
import { useCallback, useMemo, useState } from "react";
import ReactDataGrid, { Column, SelectColumn, SortColumn } from "react-data-grid";
import MarketManagementSection from "./MarketManagementSection";
import RowUpdatesSection from "./RowUpdatesSection";
import TableFilters from "./TableFiltersSection";

export type ServiceArea = GetAllZipsAndCitiesQuery['allZipsAndCities'][number];

export interface Row {
    id: number;
    market: string;
    marketId: number;
    county: string;
    countyId: number;
    city: string;
    zip: string;
    serviced: string;
}

export default function ServiceAreasPage() {
    const [displayRowIds, setDisplayRowIds] = useState<number[]>([]);
    const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(new Set<number>());
    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([])

    // the counties which will be shown in the table
    const { data: marketData } = useGetAllMarketsQuery();
    // warning given if not wrapped in useMemo
    const markets = useMemo(() => marketData?.allMarkets ?? [], [marketData]);
    
    const { data: countyData } = useGetAllCountiesQuery();
    // warning given if not wrapped in useMemo
    const counties = useMemo(() => countyData?.allCounties ?? [], [countyData]);
    
    const { data: zipAndCityData } = useGetAllZipsAndCitiesQuery({
        onCompleted: (r) => setDisplayRowIds(r.allZipsAndCities.map(zc => zc.id))
    });
    // warning given if not wrapped in useMemo
    const serviceAreas: ServiceArea[] = useMemo(() => zipAndCityData?.allZipsAndCities ?? [], [zipAndCityData]);

    const columns: readonly Column<Row>[] = useMemo(() => [
        SelectColumn,
        {
            key: "market",
            name: "Market",
            type: "string",
        },
        {
            key: "county",
            name: "County",
            type: "string"
        },
        {
            key: "city",
            name: "City",
            type: "string"
        },
        {
            key: "zip",
            name: "Zip",
            type: "string"
        },
        {
            key: "serviced",
            name: "Serviced",
            type: "string"
        },
    ], []);

    const allRows: readonly Row[] = useMemo(() => serviceAreas.map(sa => ({
        id: sa.id,
        zip: sa.zip,
        city: sa.city,
        county: counties.find(c => c.id === sa.countyId)?.name ?? "Unknown",
        countyId: sa.countyId ?? -1,
        market: markets.find(m => m.id === sa.marketId)?.name ?? "Unassigned",
        marketId: sa.marketId ?? -1,
        serviced: sa.serviced ? "Yes" : "No"
    })), [serviceAreas, counties, markets]);

    
    const filteredRows: Row[] = useMemo(() => {
        // find should never return undefined here, so cast as Row[]
        return displayRowIds.map(id => allRows.find(row => row.id === id)) as Row[];
    }, [displayRowIds, allRows]);

    type Comparator = (a: Row, b: Row) => number;
    function getComparator(sortColumn: keyof Omit<Row, "id" | "marketId" | "countyId">): Comparator {
        return (a: Row, b: Row) => {
            return a[sortColumn].localeCompare(b[sortColumn]);
        };
    }
    const getComparatorMemoized = useCallback(getComparator, []);

    // https://github.com/adazzle/react-data-grid/blob/main/website/demos/CommonFeatures.tsx
    const sortedAndFilteredRows: readonly Row[] = useMemo(() => {
        if (sortColumns.length === 0) return filteredRows;

        return [...filteredRows].sort((a, b) => {
            for (const sort of sortColumns) {
              const comparator = getComparatorMemoized(sort.columnKey as keyof Omit<Row, "id" | "marketId" | "countyId">);
              const compResult = comparator(a, b);
              if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
              }
            }
            return 0;
          });
    }, [sortColumns, filteredRows, getComparatorMemoized]);
    
    function rowKeyGetter(row: Row) {
        return row.id;
    }

    return (
        <NavbarPage title={"Service Areas"}>
            {(marketData && countyData && zipAndCityData) ? (
               <div className="flex-column align-self-center margin-top-sm margin-bottom-md" style={{width: "fit-content"}}>
                   <MarketManagementSection markets={markets}/>

                    <ToggleableSection alignTop column title="Manage Service Areas">
                        <TableFilters
                            serviceAreas={serviceAreas}
                            markets={markets}
                            counties={counties}
                            setDisplayRowIds={setDisplayRowIds}
                            allRows={allRows}
                            selectedRows={selectedRows}     
                            setSelectedRows={setSelectedRows} 
                        />

                        {selectedRows.size > 0 && (
                            <RowUpdatesSection
                                markets={markets}
                                selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
                        )}

                        <div className={`flex-column margin-top-${selectedRows.size > 0 ? "xsm" : "sm"}`}>
                            <Typography>Showing <b>{sortedAndFilteredRows.length}</b> of <b>{allRows.length}</b> rows</Typography>
                            <Typography><b>{selectedRows.size}</b> row{selectedRows.size !== 1 ? "s" : ''} selected</Typography>
                        </div>
                     
                        {/* TODO: style this in a manner consistent with the rest of the page */}
                        <ReactDataGrid
                            style={{width:800}}
                            columns={columns}
                            rows={sortedAndFilteredRows}
                            className={"rdg-light margin-top-xsm"}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            rowKeyGetter={rowKeyGetter}
                            sortColumns={sortColumns}
                            onSortColumnsChange={setSortColumns}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true
                            }}
                        />
                    </ToggleableSection>
                </div>
            ) : (
                <Loading />
            )}
        </NavbarPage>
    )
}
