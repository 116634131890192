import { useGetProductStyleQuery } from "generated/graphql";
import { selectSingleCost, selectStyleId, setSingleCost } from "Redux/addProductReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { SingleCostEditor } from "./CostEditors";


export default function ProductSingleCostEditor() {
    const singleCost = useAppSelector(selectSingleCost)
    const styleId = useAppSelector(selectStyleId)
    const dispatch = useAppDispatch();

    function updateCost(newValue: string) {
        dispatch(setSingleCost(newValue))
    }

    const { loading } = useGetProductStyleQuery({
        variables: { styleId },
        skip: styleId < 1,
        onCompleted(data) {
            updateCost(data.productStyle.singleCostPerSqft.toString())
        }
    })


    return (
        <div className="flex-column">
            <label>Ctn. Cost:</label>
            <SingleCostEditor cost={singleCost} setCost={updateCost} readOnly={loading} />
        </div>
    ) 
}