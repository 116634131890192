import { Button, Typography } from "@material-ui/core";
import { adminPath } from "Globals/PathStrings";
import { useHistory } from "react-router";

function ThanksForSchedulingAppointmentPage({appointmentNumber} : {appointmentNumber: number}) {
    const history = useHistory()

    return ( <div style={{width:"100vw", height:"100vh", justifyContent:"center", alignItems:"center", display:"flex", flexDirection:"column"}}>
            <Typography>
                Thanks for creating an appointment with us
            </Typography>
            <Typography>
                This is a temp page
            </Typography>
            <Typography>
                Appointment Number: A-{400000 + appointmentNumber}
            </Typography>
            <Button onClick={()=>history.push(adminPath)} variant="contained">
                Return to Admin
            </Button>
        </div> );
}

export default ThanksForSchedulingAppointmentPage;