import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Typography } from "@material-ui/core";
import { CustomerSearchParametersInput, CustomerSearchResult, useGetCustomerSearchResultsLazyQuery } from "generated/graphql";
import { isNotEmptyString, isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { resetCustomerAndContactIds, selectMergeBaseCustomerId, unmerge } from "Redux/callCenterSchedulingReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import CustomerMatchRow from "./CustomerMatchRow";
import "./customer_match_section.css";

export default function CustomerMatchSection({searchParameters}: {searchParameters: CustomerSearchParametersInput, urlCustomerId: number | undefined}) {
    // get directly from URL because this is used to determine the button that appears
    // the button should only appear if we got to this page WITH a customer ID, not if we select a matching customer from the search results
    const { id: urlCustomerId } = useNumericIdParam();
    // disable once a customer has been merged or autofilled (will need to be set by the row that autofills/merges data)
    const [disabled, setDisabled] = useState(false);
    // when true, all non-empty fields must match to give a result
    const [shouldAndParameters, setShouldAndParameters] = useState(true);
    /**
     * This is just a dummy var to pass the search query. it is just used to be able to pass different variables
     * to the query each time a search is done.
     * Why? If a person searches, clears the results, then searches again, the query results get ignored by Apollo
     * (regardless of telling it not to cache anything) because the variables are the same. So this is a way to get around
     * that fact.
     * See https://stackoverflow.com/a/71838828/8057105
     */
    const [numSearches, setNumSearches] = useState(0);

    const [searchForCustomer, {data: customerSearchData, loading: searching}] = useGetCustomerSearchResultsLazyQuery({
        onError: () => alert("Failed to search for customer"),
        onCompleted: () => {
            setNumSearches(numSearches + 1);
            // filter out the result for the ID that was passed in the URL (don't want to merge a customer with themselves)
            const customerSearchResults = customerSearchData?.customerSearchResults.filter(c => c.id !== urlCustomerId);
            setSearchResults(customerSearchResults);
        },
        fetchPolicy: "no-cache"
    });

    // can be undefined to differentiate having not searched from having not found anything from the search
    const [searchResults, setSearchResults] = useState<CustomerSearchResult[] | undefined>(undefined);

    useEffect(() => {
        if (!Object.values(searchParameters).some(value => (value && isNotEmptyString(value)))) {
            setSearchResults(undefined);
            setDisabled(false);
        }
    }, [searchParameters, setSearchResults])

    const canSearch = useCallback((params: CustomerSearchParametersInput) => {
        // can search if at least one search field is not null
        return !disabled && Object.values(params).some(value => (value && isNotEmptyString(value)));
    }, [disabled]);

    function search() {
        if (canSearch(searchParameters)) {
            searchForCustomer({
                variables: {searchParameters, shouldAndParameters: shouldAndParameters, numSearches: numSearches}
            });
        }
    }

    const mergeWithCustomerId = useAppSelector(selectMergeBaseCustomerId);

    // triggered when form is cleared - disabled flag also needs to be cleared
    useEffect(() => {
        if (mergeWithCustomerId === -1) {
            setDisabled(false);
        }
    }, [mergeWithCustomerId, setDisabled])

    const dispatch = useAppDispatch();
    const onUnlink = useCallback(() => {
        // if the URL contains an ID, then we can only clear the merge customer ID and remove contacts not associated with that customer
        if (isNotNullOrUndefined(urlCustomerId)) {
            dispatch(unmerge())
        } else {
            dispatch(resetCustomerAndContactIds());
        }
        setDisabled(false);
    }, [dispatch, urlCustomerId]);

    const displayComponent: ReactJSXElement = useMemo(() => {
        if (disabled) {
            return (
                <button
                    onClick={() => onUnlink()}
                    className="flat-button-base flat-primary-major-button"   
                >
                    {(mergeWithCustomerId > 0) ? "Cancel merge" : "Unlink autofilled customer"}
                </button>
            );
        } else if(isNullOrUndefined(searchResults)) {
            // nothing has been searched yet, or all search fields have been cleared
            return <></>
        } else if (searching) {
            return <Typography>Searching...</Typography>
        } else if ((searchResults ?? []).length > 0) {
            return (<>
                <Typography className="margin-bottom-xsm" variant="h5">Potential Customer Matches</Typography>
                <div className="flex-row-wrap">
                    {searchResults?.map(c => <CustomerMatchRow key={c.id} customer={c} disableCustomerSearch={() => setDisabled(true)} urlCustomerId={urlCustomerId}/>)}
                </div>
            </>)
        } else {
            return <Typography>No results found</Typography>
        }
    }, [disabled, searchResults, mergeWithCustomerId, onUnlink, searching, urlCustomerId]);

    return (
        <div className="flex-column margin-bottom-sm">
            <div className="flex-row flex-gap-sm">
                <button
                    style={{alignSelf: "flex-start"}}
                    className="margin-bottom-xsm flat-button-base flat-primary-major-button"
                    onClick={() => setShouldAndParameters(!shouldAndParameters)}
                >{shouldAndParameters ? "Matching All Fields" : "Matching Any Field"}</button>

                <button
                    style={{alignSelf: "flex-start"}}
                    className="margin-bottom-xsm flat-button-base flat-primary-major-button"
                    onClick={search}
                >Search</button>

                <button
                    style={{alignSelf: "flex-start"}}
                    className="margin-bottom-xsm flat-button-base flat-primary-major-button"
                    onClick={() => setSearchResults(undefined)}
                >Clear Results</button>
            </div>

            {displayComponent}
        </div>
    )
}