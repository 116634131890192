import { isNotNullOrUndefined } from "Globals/GenericValidators";
import AtomicTimeSlot from "./AtomicTimeSlot";

export function generateStartAndEndTimes(entireDay: AtomicTimeSlot, interval: number) {
    let startTimes: Date[] = [];
    let endTimes: Date[] = [];
    
    let initialStartTime = new Date(0, 0, 0, entireDay.startHour, entireDay.startMinute, 0)
    let initialEndTime = addMinutesToTime(initialStartTime, interval);
    let currentStartAndEnd = AtomicTimeSlot.fromDates(initialStartTime, initialEndTime);

    while (currentStartAndEnd.endsAtOrBefore(entireDay.endHour, entireDay.endMinute)) {
        let startTime = new Date(0, 0, 0, currentStartAndEnd.startHour, currentStartAndEnd.startMinute, 0);
        let endTime = new Date(0, 0, 0, currentStartAndEnd.endHour, currentStartAndEnd.endMinute, 0);
        // only push start times that are at or before 7:30
        if (startTime.getHours() < 19) {
            startTimes.push(startTime);
        } else if (startTime.getHours() === 19) {
            if (startTime.getMinutes() <= 30) {
                startTimes.push(startTime);
            }
        }
        endTimes.push(endTime);

        currentStartAndEnd = AtomicTimeSlot.fromDates(addMinutesToTime(startTime, interval), addMinutesToTime(endTime, interval));
    }

    return ({
        startTimes: startTimes,
        endTimes: endTimes
    })
}

export function generateStartAndEndTimeStrings(entireDay: AtomicTimeSlot, interval: number) {
    const {startTimes, endTimes } = generateStartAndEndTimes(entireDay, interval);

    return ({
        startTimes: startTimes.map(t => timeToAmPmString(t)),
        endTimes: endTimes.map(t => timeToAmPmString(t))
    })
}

export function timeStringToTime(timeStr: string) {
    let colonSplit = timeStr.split(':');
    let hour = parseInt(colonSplit[0]);
    let minute = parseInt(colonSplit[1][0]);
    let am = colonSplit[1][1] === "am";
    if (am) {
        if (hour === 12) {
            hour = 0
        }
    } else {
        if (hour !== 12) {
            hour += 12;
        }
    }

    return new Date(0, 0, 0, hour, minute, 0);
}

// since there is no "Time" type, we actually add to a Date object, but ignore everything but the hours and minutes
function addMinutesToTime(original: Date, minutes: number) {
    let minutesToAdd = minutes % 60;
    let newMinutes = original.getMinutes() + minutesToAdd;
    let hoursToAdd = Math.floor(minutes / 60);
    let newHours = original.getHours() + hoursToAdd;

    return new Date(0, 0, 0, newHours, newMinutes, 0)
}

export function timeToAmPmString(time: Date | null | undefined) {
     if (isNotNullOrUndefined(time)) {
         // make use of an existing method in AtomicTimeSlot to do this; only need the start time, so 0 for last 2 args
         return (new AtomicTimeSlot((time as Date).getHours(), (time as Date).getMinutes(), 0, 0)).startTimeToString();
     } else {
         return '';
     }
}

export function timeSlotToStartTimeStr(ts: AtomicTimeSlot) {
    let amPm: string;
    if (ts.startHour >= 12) {
        amPm = "pm";
    } else {
        amPm = "am";
    }

    return `${AtomicTimeSlot.to12hour(ts.startHour)}:${AtomicTimeSlot.ensure2digits(ts.startMinute)} ${amPm}`
}

export function timeSlotToEndTimeStr(ts: AtomicTimeSlot) {
    let amPm: string;
    if (ts.endHour >= 12) {
        amPm = "pm";
    } else {
        amPm = "am";
    }

    return `${AtomicTimeSlot.to12hour(ts.endHour)}:${AtomicTimeSlot.ensure2digits(ts.endMinute)} ${amPm}`
}
