import { Button } from "@material-ui/core"
import { GetServiceProductOfferingIdsQueryVariables } from "generated/graphql"
import { useState } from "react"
import { LaborTypeSelect as ServiceTypeSelect, ProductSelect } from "../NewLaborRateBuilder"
import LaborRatesTable from "./LaborRatesTable"


export default function LaborRatesView({height}: {height?: string}) {
    const useHeight = height ?? "30rem"

    const [filters, setFilters] = useState<GetServiceProductOfferingIdsQueryVariables>({ isDeleted: false })

    const productIdFilter = filters.productTypeId ?? 0
    const serviceTypeIdFilter = filters.serviceTypeId ?? 0

    function changeSelectedProduct(event: React.ChangeEvent<{ value: unknown }>) {
        const value = event.target.value as number
        setFilters({ ...filters, productTypeId: (value === 0) ? undefined : value })
    }

    function changeSelectedServiceType(event: React.ChangeEvent<{ value: unknown }>) {
        const value = event.target.value as number
        setFilters({ ...filters, serviceTypeId: (value === 0) ? undefined : value })
    }

    function toggleDeletedStatus() {
        setFilters({ ...filters, isDeleted: !(filters.isDeleted ?? true) })
    }

    //TODO: Manually calculate height and insert into table

    return (
        <div className="flex-column" style={{ margin: "0 1rem" }}>
            <div className="margin-sm flex-row h-2r">
                <Button
                    variant="contained"
                    className="margin-side-xsm"
                    onClick={toggleDeletedStatus}>
                    {(filters.isDeleted ?? false) ? "Hide Trash" : "Show Trash"}
                </Button>

                <ProductSelect
                    value={productIdFilter}
                    onChange={changeSelectedProduct}
                    unselectedOptionText="All Products"
                    className="margin-side-xsm"
                />
                
                <ServiceTypeSelect
                    value={serviceTypeIdFilter}
                    onChange={changeSelectedServiceType}
                    unselectedOptionText="All Labor Types"
                    className="margin-side-xsm"
                    />
                <div className="flex-grow" />
                
                <Button variant="contained" className="margin-side-xsm" >Edit Contractor Prices</Button>
            </div>
            
            <LaborRatesTable filters={filters} height={`calc(${useHeight} - 2rem)`} /> 
        </div>
    )
}
