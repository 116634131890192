import { Dialog, DialogActions, IconButton, MenuItem, Select } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MoneyInput from "Components/MoneyInput";
import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { GetJobPaymentsQuery, JobContractPayment, JobContractPaymentInput, namedOperations, useDeleteJobContractPaymentsMutation, useGetAcceptedPaymentMethodsQuery, useGetJobInvoiceDataQuery, useGetJobPaymentsQuery, useUpsertJobContractPaymentsMutation } from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

interface PaymentsDialogProps {
    initialPayments: JobContractPayment[];
    minimumTargetDeposit: number;
    totalPrice: number;
    setOpen: (open: boolean, changesMade: boolean) => void;
    contractId: number;
}

function convertPayment(payment: GetJobPaymentsQuery['jobPayments'][number]): JobContractPaymentInput {
    let { __typename, amount, ...fields } = payment;
    amount = to2digits(amount);  // prevents fractions of a cent from being added
    return { ...fields, amount }
}

function to2digits(n: number) {
    return +n.toFixed(2);
}

export default function PaymentsDialog({ initialPayments, minimumTargetDeposit, totalPrice, setOpen, contractId }: PaymentsDialogProps) {
    const { data: paymentMethodData } = useGetAcceptedPaymentMethodsQuery();
    let paymentMethods = paymentMethodData?.acceptedPaymentMethods.filter(m => m.method !== "Financing") ?? [];

    // controls whether existing payments can be edited
    const [editingLocked, setEditingLocked] = useState(true);
    const [existingPayments, setExistingPayments] = useState<JobContractPayment[]>(initialPayments);
    // keeps track of which existing payments need to be deleted/updated
    const [paymentIdsToDelete, setPaymentIdsToDelete] = useState<number[]>([]);
    const [paymentIdsToUpdate, setPaymentIdsToUpdate] = useState<number[]>([]);

    // amount that was paid when the dialog was opened
    let paidOnOpen = initialPayments.map(pmt => pmt.amount).reduce((sum, nextVal) => sum + nextVal, 0);
    // amount paid from all payments entered into the dialog (including adding, as well as editing/removing existing payments)
    const [paymentSum, setPaymentSum] = useState(paidOnOpen);
    // amount to pop up when a new payment is being added
    const [newPaymentDefaultAmount, setNewPaymentDefaultAmount] = useState(paymentSum < minimumTargetDeposit ? (minimumTargetDeposit - paymentSum) : (totalPrice - paymentSum));
    const [nextNewPmtId, setNextNewPmtId] = useState(-1);
    function makeDefaultPayment() {
        let defaultPmt: JobContractPayment = {
            id: nextNewPmtId,
            jobContractId: contractId,
            amount: newPaymentDefaultAmount,
            paymentMethodId: 1,
            isForDeposit: false,
            authorizationNumber: null
        }

        setNextNewPmtId(nextNewPmtId - 1);
        return defaultPmt;
    }

    // since state is slow to update, just pass in how much the total payment should change by
    // pass a negative number to decrease the amount paid, and vice versa
    function recalculatePayments(changeBy: number) {
        let newPaymentSum = to2digits(paymentSum + changeBy);
        setPaymentSum(newPaymentSum);

        if (newPaymentSum < minimumTargetDeposit) {
            setNewPaymentDefaultAmount(to2digits(minimumTargetDeposit - newPaymentSum));
        } else {
            setNewPaymentDefaultAmount(to2digits(totalPrice - newPaymentSum));
        }
    }


    function onUpdateExistingPaymentMethod(paymentId: number, newMethodId: number) {
        if (newMethodId === -1) {  // -1 is to remove the payment
            if (window.confirm("Are you sure you want to delete this payment? Note that the payment won't be deleted until you click \"Submit\".")) {
                let deleteAmt = existingPayments.find(pmt => pmt.id === paymentId)?.amount ?? 0;
                setPaymentIdsToDelete([...paymentIdsToDelete, paymentId]);
                // in case it was updated before being removed
                setPaymentIdsToUpdate(paymentIdsToUpdate.filter(pmtId => pmtId !== paymentId));
                setExistingPayments(existingPayments.filter(pmt => pmt.id !== paymentId));
                recalculatePayments(-deleteAmt);
            }
        } else {
            let existingPaymentsCopy = [...existingPayments];
            let thisPaymentIdx = existingPaymentsCopy.findIndex(pmt => pmt.id === paymentId);
            let thisPayment = existingPaymentsCopy[thisPaymentIdx];
            existingPaymentsCopy[thisPaymentIdx] = { ...thisPayment, paymentMethodId: newMethodId }
            if (!paymentIdsToUpdate.includes(paymentId)) {
                setPaymentIdsToUpdate([...paymentIdsToUpdate, paymentId]);
            }
            setExistingPayments(existingPaymentsCopy);
        }
    }

    function onUpdateExistingPaymentAmount(paymentId: number, newAmount: number | undefined) {
        if (!newAmount) { newAmount = 0; }
        let existingPaymentsCopy = [...existingPayments];
        let thisPaymentIdx = existingPaymentsCopy.findIndex(pmt => pmt.id === paymentId);
        let thisPayment = existingPaymentsCopy[thisPaymentIdx];
        let originalAmt = thisPayment.amount;
        existingPaymentsCopy[thisPaymentIdx] = { ...thisPayment, amount: newAmount! }
        if (!paymentIdsToUpdate.includes(paymentId)) {
            setPaymentIdsToUpdate([...paymentIdsToUpdate, paymentId]);
        }
        setExistingPayments(existingPaymentsCopy);

        recalculatePayments(newAmount - originalAmt);
    }

    const [newPayments, setNewPayments] = useState<JobContractPayment[]>([]);

    function onUpdateNewPaymentMethod(paymentId: number, newMethodId: number) {
        if (newMethodId === -1) {  // -1 is to remove the payment
            // payment hasn't been created in DB yet, so don't need to confirm as we did with existing
            let deleteAmount = newPayments.find(pmt => pmt.id === paymentId)?.amount ?? 0;
            setNewPayments(newPayments.filter(pmt => pmt.id !== paymentId));
            recalculatePayments(-deleteAmount)
        } else {
            let newPaymentsCopy = [...newPayments];
            let thisPaymentIdx = newPaymentsCopy.findIndex(pmt => pmt.id === paymentId);
            let thisPayment = newPaymentsCopy[thisPaymentIdx];
            newPaymentsCopy[thisPaymentIdx] = { ...thisPayment, paymentMethodId: newMethodId }
            setNewPayments(newPaymentsCopy);
        }
    }

    function onUpdateNewPaymentAmount(paymentId: number, newAmount: number | undefined) {
        if (!newAmount) { newAmount = 0; }
        let newPaymentsCopy = [...newPayments];
        let thisPaymentIdx = newPaymentsCopy.findIndex(pmt => pmt.id === paymentId);
        let thisPayment = newPaymentsCopy[thisPaymentIdx];
        let originalAmount = thisPayment.amount;
        newPaymentsCopy[thisPaymentIdx] = { ...thisPayment, amount: newAmount! }
        setNewPayments(newPaymentsCopy);

        recalculatePayments(newAmount - originalAmount);
    }

    function onAddNewPayment() {
        if (+(totalPrice - paymentSum).toFixed(2) > 0) {
            setNewPayments([...newPayments, makeDefaultPayment()]);
            recalculatePayments(newPaymentDefaultAmount);
        }
    }

    const [upsertSucceeded, setUpsertSucceeded] = useState(false);
    const [upsertJobContractPayments] = useUpsertJobContractPaymentsMutation({
        refetchQueries: [namedOperations.Query.GetJobPayments, namedOperations.Query.GetPaymentsPaneInfo],
        onCompleted: (res) => {
            if (res.upsertJobContractPayments) {
                setUpsertSucceeded(true);
            } else {
                alert("Could not submit payment information");
            }
        },
        onError: () => alert("Could not submit payment information")
    });

    const [deleteSucceeded, setDeleteSucceeded] = useState(false);
    const [deleteJobContractPayments] = useDeleteJobContractPaymentsMutation({
        refetchQueries: [namedOperations.Query.GetJobPayments, namedOperations.Query.GetPaymentsPaneInfo],
        onCompleted: (res) => {
            if (res.deleteJobContractPayments) {
                setDeleteSucceeded(true);
            } else {
                alert("Could not delete payments");
            }
        },
        onError: () => alert("Could not delete payments")
    });

    useEffect(() => {
        // close the dialog once both the upsert and delete succeed
        if (deleteSucceeded && upsertSucceeded) {
            setOpen(false, true);
        }
    }, [deleteSucceeded, upsertSucceeded, setOpen]);

    function canSubmit() {
        let existingPaymentsOk = existingPayments.every(pmt => (pmt.amount > 0 && pmt.paymentMethodId > 0));
        let newPaymentsOk = newPayments.every(pmt => (pmt.amount > 0 && pmt.paymentMethodId > 0));

        if (!existingPaymentsOk || !newPaymentsOk) {
            alert('All payments must be greater than $0.00');
            return false;
        }

        if (to2digits(paymentSum) > to2digits(totalPrice)) {
            alert("Payments exceed total cost of job");
            return false;
        }

        return true;
    }

    function paymentHasChanged(payment: JobContractPayment) {
        let thisOriginalPayment = initialPayments.find(initPmt => initPmt.id === payment.id)!;
        return (thisOriginalPayment.amount !== payment.amount) || (thisOriginalPayment.paymentMethodId !== payment.paymentMethodId);
    }

    function onSubmit() {
        if (canSubmit()) {
            // some payments may have been edited, but returned to their original values, so they don't require updates
            let paymentsRequiringUpdate = paymentIdsToUpdate.map(id => existingPayments.find(pmt => pmt.id === id)).filter(pmt => isNotNullOrUndefined(pmt)).filter(pmt => paymentHasChanged(pmt!)) as JobContractPayment[];
            let allPayments: JobContractPaymentInput[] = [...paymentsRequiringUpdate ?? [], ...newPayments ?? []].map(pmt => convertPayment(pmt));

            upsertJobContractPayments({
                variables: { payments: allPayments }
            });

            deleteJobContractPayments({
                variables: { paymentIds: paymentIdsToDelete }
            });
        }
    }

    // builds rows for the grid - React complains about keys when mapping fragments, so build a list here instead
    const existingPmtRows: JSX.Element[] = 
        existingPayments.flatMap(pmt => [
            // first column (placeholder in grid)
            <div className="fill-width"/>,
            // second column
            <div className="flex-row fill-width padding-right-sm">
                <MoneyInput
                    initialValue={pmt.amount}
                    setValue={(newValue) => onUpdateExistingPaymentAmount(pmt.id, newValue)}
                    variant="standard"
                    key={`existing-${pmt.id}`}
                    disabled={editingLocked}
                    error={pmt.amount <= 0}
                    disableUnderline
                />
            </div>,
            // third column
            <div className="flex-row fill-width padding-right-sm">
                <Select
                    onChange={e => onUpdateExistingPaymentMethod(pmt.id, e.target.value as number)}
                    value={pmt.paymentMethodId}
                    fullWidth
                    disabled={editingLocked}
                    disableUnderline
                >
                    <MenuItem value={-1}>Remove Payment</MenuItem>
                    {paymentMethods.map(pm => (
                        <MenuItem value={pm.id} key={`remove-payment-${pm.id}`}>{pm.method === "C/C" ? "Credit Card" : pm.method}</MenuItem>
                    ))}
                    {
                        paymentMethods.length === 0 &&
                        <MenuItem value={pmt.paymentMethodId}>Loading...</MenuItem>
                    }
                </Select>
            </div>
        ]
    );

    const newPmtRows: JSX.Element[] = 
        newPayments.flatMap(pmt => [
            // first column (placeholder in grid)
            <div className="fill-width"/>,
            // second column
            <div className="flex-row fill-width padding-right-sm">
                <MoneyInput
                    initialValue={pmt.amount}
                    setValue={(newValue) => onUpdateNewPaymentAmount(pmt.id, newValue)}
                    variant="standard"
                    key={`new-${pmt.id}`}
                    error={pmt.amount <= 0}
                    disableUnderline
                />
            </div>,
            // third column
            <div className="flex-row fill-width padding-right-sm">
                <Select
                    onChange={e => onUpdateNewPaymentMethod(pmt.id, e.target.value as number)}
                    value={pmt.paymentMethodId}
                    fullWidth
                    disableUnderline
                >
                    <MenuItem value={-1}>Remove Payment</MenuItem>
                    {paymentMethods.map(pm => (
                        <MenuItem value={pm.id} key={`remove-payment-${pm.id}`}>{pm.method === "C/C" ? "Credit Card" : pm.method}</MenuItem>
                    ))}
                    {
                        paymentMethods.length === 0 &&
                        <MenuItem value={pmt.paymentMethodId}>Loading...</MenuItem>
                    }
                </Select>
            </div>
        ]
    );

    return (
        <Dialog
            open={true}
            PaperProps={{
                className: "flat-outer-container",
                style: { minWidth: "25%" }
            }}
            maxWidth="xl"
        >
            <FlatSection header="Payments">
                <div className="flat-inner-container">
                    <div className="flex-column">
                        <p className="margin-none flat-font less-bold-text" style={{color: "black"}}>Existing Payments</p>

                        <div className="grid-10-45-45">
                            {editingLocked ? (<>
                                <div className="flex-row flex-grow align-items-center"  >
                                    <IconButton
                                        size="small"
                                        onClick={() => setEditingLocked(false)}
                                        className="padding-none fit-content"
                                        style={{width: "14pt"}}
                                    ><LockIcon htmlColor="black" style={{height: "14pt"}}/></IconButton>
                                </div>

                                <div
                                    className="flex-row flex-grow align-items-center"
                                    style={{gridColumnStart: 2, gridColumnEnd: 4}}      
                                >
                                    <p className="flat-font margin-none">Payment Editing Locked</p>
                                </div>
                            </>) : (<>
                                <div className="flex-row flex-grow align-items-center">
                                    <IconButton
                                        size="small"
                                        onClick={() => setEditingLocked(true)}
                                        className="padding-none fit-content"
                                        style={{width: "14pt"}}
                                    ><LockOpenIcon htmlColor="black" style={{height: "14pt"}}/></IconButton>
                                </div>

                                <div
                                    className="flex-row flex-grow align-items-center"
                                    style={{gridColumnStart: 2, gridColumnEnd: 4}}       
                                >
                                    <p className="flat-font margin-none">Payment Editing Unlocked</p>
                                </div>
                            </>)}

                            {existingPmtRows}
                        </div>

                        {newPayments.length > 0 && (<>
                            <div className="flat-horizontal-bar margin-top-sm margin-bottom-xsm" />
                            
                            <div className="grid-10-45-45">
                                <p
                                    style={{color: "black", gridColumnStart: 1, gridColumnEnd: 4}}
                                    className="flat-font less-bold-text margin-vertical-none" 
                                >New Payments</p>

                                {newPmtRows}
                            </div>
                        </>)}
                        
                    </div>

                    {+(totalPrice - paymentSum).toFixed(2) ? (<>
                        <div className="flat-horizontal-bar margin-top-sm margin-bottom-xsm"/>

                        <div className="flex-row align-items-center flex-gap-lg">
                            <p className="margin-none less-bold-text flat-font">Add Payment</p>
                            <FlatAddButton onClick={() => onAddNewPayment()} />
                        </div>
                    </>) : <></>}

                    <div className="flat-horizontal-bar margin-top-sm margin-bottom-xsm" />

                    {to2digits(paymentSum) < to2digits(minimumTargetDeposit) && (
                        <span className="flex-row flex-space-between flex-gap-sm">
                            <p className="margin-none flat-font">Additional Minimum Payment Required: </p>
                            <p className="bold-text flat-font margin-none" style={{color: "var(--flat-red)"}}>
                                <NumberFormat
                                    prefix={'$'} thousandSeparator={true}
                                    displayType="text"
                                    decimalScale={2} fixedDecimalScale
                                    value={minimumTargetDeposit - paymentSum}
                                />
                            </p>
                        </span>
                    )}

                    {to2digits(paymentSum) < totalPrice && (
                        <span className="flex-row flex-space-between flex-gap-sm">
                            <p className="margin-none flat-font">Total Additional Payment Required: </p>
                            <p className="bold-text flat-font margin-none" style={{color: "var(--flat-red)"}}>
                                <NumberFormat
                                    prefix={'$'} thousandSeparator={true}
                                    displayType="text"
                                    decimalScale={2} fixedDecimalScale
                                    value={totalPrice - paymentSum}
                                />
                            </p>
                        </span>
                    )}

                    {to2digits(paymentSum) === totalPrice && (
                        <span className="flex-row">
                            <p className="flat-font margin-none" style={{color: "var(--flat-dark-green)"}}>No additional payments required</p>
                        </span>
                    )}

                    {(to2digits(paymentSum - totalPrice) > 0) && (
                        <span className="flex-row flex-space-between flex-gap-sm">
                            <p className="margin-none flat-font">Payments exceed job total by: </p>

                            <p className="bold-text flat-font margin-none" style={{color: "var(--flat-red)"}}>
                                <NumberFormat
                                    prefix={'$'} thousandSeparator={true}
                                    displayType="text"
                                    decimalScale={2} fixedDecimalScale
                                    value={paymentSum - totalPrice}
                                />
                            </p>
                        </span>
                    )}
                </div>
                
                <DialogActions>
                    <FlatButton variant="outlined" color="primary" onClick={() => setOpen(false, false)}>Cancel</FlatButton>
                    <FlatButton variant="contained" color='primary' onClick={() => onSubmit()}>Submit</FlatButton>
                </DialogActions>
            </FlatSection>
        </Dialog>

            

    );
}

interface ContractPaymentsDialogProps {
    setOpen: (open: boolean, changesMade: boolean) => void,
    contractId: number,
    jobConfigurationId: number
}

export function ContractPaymentsDialog({ setOpen, contractId, jobConfigurationId }: ContractPaymentsDialogProps) {
    const { data: paymentData, loading: paymentLoading } = useGetJobPaymentsQuery({
        variables: { jobContractId: (contractId!) },
        skip: !contractId,
        fetchPolicy: "network-only"
    });
    const existingPayments = paymentData?.jobPayments ?? [];

    const { data: jobData, loading: jobLoading } = useGetJobInvoiceDataQuery({
        variables: { jobConfigurationId: jobConfigurationId ?? 0 },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only",
        skip: (jobConfigurationId ?? 0) < 1
    });

    const financing = jobData?.jobConfiguration.financing;
    const totalPrice = jobData?.jobConfiguration.price?.total ?? 0;
    const minimumTargetDeposit = (isNullOrUndefined(financing?.financingOption)
        ? .5 * totalPrice ?? 0
        : (financing?.financingDownPayment ?? 0))

    return (
        <>
            {
                (!paymentLoading && !jobLoading) &&
                <PaymentsDialog
                    minimumTargetDeposit={+((minimumTargetDeposit as number).toFixed(2))}
                    totalPrice={+(totalPrice ?? -1).toFixed(2)}
                    setOpen={setOpen}
                    initialPayments={existingPayments}
                    contractId={contractId}
                />
            }
        </>
    )
}