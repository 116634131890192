import FlatButton from "FlatComponents/Button/FlatButton";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import FlatFileInput from "./FlatFileInput";

interface FlatFileUploadDialogProps {
    open: boolean;
    header?: string;
    onSubmit: (file: File) => void;
    onClose: () => void;
    disableSubmitButton?: boolean; // should be used while the onSubmit mutation is loading
    accept?: string;
}

export default function FlatFileUploadDialog({
    open,
    onClose,
    onSubmit,
    header,
    accept,
    disableSubmitButton: externalDisableSubmit=false
}: FlatFileUploadDialogProps) {
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    
    function submit() {
        if (isNullOrUndefined(selectedFile)) {
            alert("Select a file");
        } else {
            onSubmit(selectedFile!);
        }
    }

    const submitButton = (
        <FlatButton
            key={1}
            onClick={submit}
            color="secondary"
            variant="contained"
            disabled={externalDisableSubmit || isNullOrUndefined(selectedFile)}
        >Upload</FlatButton>
    );

    return (
        <FlatDialog
            dialogProps={{open: open, onClose: onClose}}
            sectionProps={{header: header}}
            actionButtons={[submitButton]}
        >
            <FlatFileInput setSelectedFile={setSelectedFile} accept={accept}/>
        </FlatDialog>
    )
}