import clsx from "clsx";
import React from "react";
import "./project-card-components.css"

type BlankProjectCardProps = React.PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;

export default function BaseProjectCard({className ,...props} : BlankProjectCardProps) {
    return (
        <div
            {...props}
            className={clsx("base-project-card flat-container-box-shadow", className)} 
        />
    );
}