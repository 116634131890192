import { CircularProgress, Tooltip } from "@material-ui/core";
import { useEstimateAppointmentLengthQuery } from "generated/graphql";
import CloseIcon from "@material-ui/icons/Close";
import { formatNumberOfDays } from "Globals/DateAndTimeHelpers";

export default function AppointLengthEstimator({ roomIds }: { roomIds: number[] }) {
    const { data } = useEstimateAppointmentLengthQuery({ variables: { roomIds: roomIds } });

    const estimatedLength = data?.estimateAppointmentLength ?? null;

    return (
        <div
            className="flex-row"
            style={{ height: "2.5rem", alignItems: "center" }}
        >
            <div className="flat-font-md">Estimate: </div>
            <div
                className="flex-grow flex-row"
                style={{ justifyContent: "center", alignItems: "center" }}
            >
                <AppointmentLengthIndicator length={estimatedLength} />
            </div>
        </div>
    );
}

function AppointmentLengthIndicator({ length }: { length: number | null }) {
    if (length === null) return <CircularProgress style={{color:"var(--flat-gray-5)"}} />;
    else if (length < 1)
        return (
            <Tooltip
                title="Cannot give estimate for selected work"
                style={{ color: "var(--flat-red)" }}
            >
                <CloseIcon />
            </Tooltip>
        );
    else return <div className="flat-font-md">{formatNumberOfDays(length, true)}</div>;
}
