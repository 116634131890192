import firebase from 'firebase';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

axios.interceptors.request.use(
  async config => {
    config.headers.token = await firebase.auth().currentUser?.getIdToken();
    return config;
  },
  (error) => Promise.reject(error));

const doRedirect = process.env.NODE_ENV === 'production'
ReactDOM.render(
  <React.StrictMode>
    {doRedirect && (
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
    )}

    {!doRedirect && <App />}
  </React.StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

