import { Button } from "@material-ui/core";
import {
    ChatForJob,
    ChatMember, GetPriceRequestsForJobQuery, PriceRequestUpdatedForJobDocument, PriceRequestUpdatedForJobSubscription,
    useClaimAllPriceRequestsForJobMutation,
    useGetAuthenticatedCommandCenterWorkerIdQuery, useGetAuthenticatedWorkerQuery,
    useGetChatForJobQuery,
    useGetCommandCenterChatPartyQuery, useGetGeneralCommandCenterWorkerIdQuery,
    useGetJobIdForChatQuery, useGetJobIdQuery, useGetPriceRequestsForJobQuery
} from "generated/graphql";
import { isNotEmptyString, isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { commandCenterPricingPath, recoveryCenterPricingPath } from "Globals/PathStrings";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { replaceChat } from "Redux/chatDrawerReducer";
import { useAppDispatch } from "Redux/hooks";
import { ChatIdContext } from "../ChatWindow";

interface PriceRequestResponseInputProps {
    usageContext: "cc" | "pc";  // command center | pricing center
    optionNumber?: number;
    jobConfigurationId?: number;
}

export default function PriceRequestResponseBox({optionNumber, jobConfigurationId, usageContext}: PriceRequestResponseInputProps) {
    const { data: authCcWorkerData } = useGetAuthenticatedCommandCenterWorkerIdQuery();
    const { data: generalCcWorkerData } = useGetGeneralCommandCenterWorkerIdQuery();
    const authCcWorkerId = authCcWorkerData?.authenticatedCommandCenterWorkerId;
    const generalCcWorkerId = generalCcWorkerData?.generalCommandCenterWorkerId;

    if ((optionNumber && !jobConfigurationId) || (!optionNumber && jobConfigurationId)) {
        throw new Error("Props 'optionNumber' and 'jobConfigurationId' must either both be omitted or both be passed");
    }

    // buttons should only be shown from the command center, in which case there will be no option number or config passed
    const beingUsedInCc = usageContext === "cc";
    const pricingCenterPath = beingUsedInCc ? commandCenterPricingPath : recoveryCenterPricingPath;
    
    const chatId = useContext(ChatIdContext);
    // needed because when used from the chat tray, we don't know job IDs
    const { data: jobIdData } = useGetJobIdForChatQuery({
        variables: {chatId: chatId}
    });
    const jobId = jobIdData?.jobIdForChat;

    const { data: priceRequestData, subscribeToMore: subscribeToPriceRequests } = useGetPriceRequestsForJobQuery({
        skip: isNullOrUndefined(jobId),
        variables: { jobId: jobId! }
    });

    const listenForPriceRequests = useCallback(() => {
        if (jobId) {
            subscribeToPriceRequests({
                document: PriceRequestUpdatedForJobDocument,
                variables: {
                    jobId: jobId!
                },
                updateQuery: (prev, {subscriptionData}) => {
                    const newRequest = (subscriptionData.data as unknown as PriceRequestUpdatedForJobSubscription).priceRequestUpdatedForJob;
                    if (!newRequest) { return prev; }
                    
                    // replace the existing version of the request if necessary
                    const filteredRequests = prev.priceRequestsForJob.filter(req => req.id !== newRequest.id);
                    const updatedQuery: GetPriceRequestsForJobQuery = {
                        ...prev,
                        priceRequestsForJob: [ ...filteredRequests, newRequest ]
                    };
                    
                    return updatedQuery;
                }
            });
        }
    }, [subscribeToPriceRequests, jobId]);

    useEffect(() => {
        if (listenForPriceRequests) {
            listenForPriceRequests();
        }
    }, [listenForPriceRequests]);

    const priceRequests = priceRequestData?.priceRequestsForJob ?? [];
    // only one CC worker can claim requests for each job (each config must be claimed by same CC worker)
    const claimedByCcWorkerId = priceRequests.find(r => isNotNullOrUndefined(r.commandCenterWorkerId))?.commandCenterWorkerId;
    const requestsPendingResponse = priceRequests.filter(r => isNullOrUndefined(r.timeResponded));
    const numPending = requestsPendingResponse.length;
    const pendingResponseStr = (numPending > 0) ?  `${numPending} request${numPending === 1 ? ' is' : 's'} awaiting response` : 'All requests have been responded to';
    // used when navigating to the pricing center from the chat
    let configIdForNavigation = -1;
    if ((requestsPendingResponse?.length ?? 0) > 0) {
        configIdForNavigation = requestsPendingResponse[0].jobConfigurationId;
    } else if ((priceRequests?.length ?? 0) > 0) {
        configIdForNavigation = priceRequests[0].jobConfigurationId;
    }

    const goToPricingCenter = useCallback(() => {
        window.open(`${pricingCenterPath}/${configIdForNavigation}`);
    }, [configIdForNavigation, pricingCenterPath]);

    const content = useMemo(() => {
        if (priceRequests.length > 0) {
            if (claimedByCcWorkerId === authCcWorkerId) {
                return (
                    <div className="action-box">
                        { isNotEmptyString(pendingResponseStr) && <p className="margin-bottom-xsm">{pendingResponseStr}</p> }
                        { beingUsedInCc && <button onClick={(goToPricingCenter)}>Go to pricing center</button> }
                    </div>
                )
            } else if ((!claimedByCcWorkerId && authCcWorkerId) || (authCcWorkerId === generalCcWorkerId)) {
                if ((authCcWorkerId === generalCcWorkerId)) {
                    return (
                        <div className="action-box">
                            <p className="margin-bottom-xsm">Log in to your account to handle price requests for this job</p>
                        </div>
                    )
                } else {
                    return (
                        <div className="action-box">
                            <p className="margin-bottom-xsm">{pendingResponseStr}</p>
                            <ClaimPriceRequestButton jobConfigurationId={configIdForNavigation} newWindow={beingUsedInCc}/>
                        </div>
                    )
                }
            }
        } else {
            return (
                <div className="action-box">
                    <p>No price requests have been made</p>
                </div>
            )
        }

        return <></>
    }, [authCcWorkerId, claimedByCcWorkerId, configIdForNavigation, generalCcWorkerId, goToPricingCenter, priceRequests.length, beingUsedInCc, pendingResponseStr]);

    return content;
}

interface ClaimPriceRequestButtonProps {
    jobConfigurationId: number;
    newWindow?: boolean,
    useDefaultStyling?: boolean;  // false when used in the chat
}


export function ClaimPriceRequestButton({ jobConfigurationId, newWindow=true, useDefaultStyling=false }: ClaimPriceRequestButtonProps) {
    const { data: authWorkerData } = useGetAuthenticatedWorkerQuery();
    const individualCcWorkerParty = authWorkerData?.authenticatedWorker?.individualChatParty;
    
    const { data: jobIdData } = useGetJobIdQuery({variables: {jobConfigurationId: jobConfigurationId}});
    const jobId = jobIdData?.jobId ?? -1;
    
    const { data: chatData } = useGetChatForJobQuery({
        variables: {jobId: jobId},
        skip: jobId < 1
    });
    const chatForJob = chatData?.chatForJob;
    const jobChatMember = chatForJob?.jobMember;
    const commandCenterChatMember = chatForJob?.commandCenterMember;

    const { data: ccPartyData } = useGetCommandCenterChatPartyQuery();
    const commandCenterChatParty = ccPartyData?.commandCenterChatParty;

    const dispatch = useAppDispatch();

    const [claimQuoteRequests] = useClaimAllPriceRequestsForJobMutation({
        variables: {jobId: jobId},
        onError: () => alert("Could not claim price request"),
        onCompleted: (data) => {
            if (data.claimAllPriceRequestsForJob) {
                // swap out general CC worker for this job with the worker that claimed it
                const newCommandCenterMember: ChatMember = {
                    ...commandCenterChatMember!,
                    chatPartyId: commandCenterChatMember!.chatPartyId,
                    partyName: commandCenterChatMember!.partyName,
                }
                const newChatForJob: ChatForJob = {...chatForJob!, commandCenterMember: newCommandCenterMember}
                dispatch(replaceChat(newChatForJob))

                if (newWindow) {
                    window.open(`${commandCenterPricingPath}/${jobConfigurationId}`);
                }
            } else {
                alert("Could not claim price request")
            }
        }
    });

    return (
        <>
            {useDefaultStyling ? (
                <Button 
                    variant="contained"
                    onClick={() => claimQuoteRequests()}
                    disabled={!individualCcWorkerParty || !jobChatMember || !commandCenterChatParty}
                >Claim Request</Button>
            ) : (
                <button 
                    onClick={() => claimQuoteRequests()}
                    disabled={!individualCcWorkerParty || !jobChatMember || !commandCenterChatParty}
                >Claim Request</button>
            )}
        </>
    )
}