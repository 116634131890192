import { Switch, SwitchProps } from "@material-ui/core";
import clsx from "clsx";
import { FlatLabeledInputBase } from "./flatInputUtils";

interface FlatSwitchProps extends Omit<SwitchProps, "color"> {
    checkedColor?: string;
    uncheckedColor?: string;
}

export default function FlatSwitch({
    checkedColor: onColor="var(--flat-green)",
    uncheckedColor: offColor="gray",
    size="small",
    checked,
    style,
    ...props
}: FlatSwitchProps) {
    return (
        <Switch
            style={{color: checked ? onColor : offColor, ...style}}
            size={size}
            checked={checked}
            {...props}
        />
    );
}

type FlatLabeledSwitchProps = FlatLabeledInputBase<FlatSwitchProps>;

export function FlatLabeledSwitch({
    label,
    bold=true,
    labelLocation="left",
    labelSize="standard",
    labelStyle,
    containerProps,
    ...checkboxProps
}: FlatLabeledSwitchProps) {
    const containerClassName = clsx("align-items-center", containerProps?.className, {"flex-row": labelLocation === "left", "flex-row-reverse": labelLocation === "right"});
    const labelClassName = clsx("margin-none", {
        "flat-font-xsm": labelSize === "xsm",
        "flat-font-sm": labelSize === "sm",
        "flat-font": labelSize === "standard",
        "flat-font-md": labelSize === "md",
        "flat-font-lg": labelSize === "lg",
        "bold-text": bold
    });

    // remove className from the spread props because it will overwrite the directly passed className prop
    const {className, ...cleanedContainerProps} = {...containerProps};
    
    return (
        <span className={containerClassName} {...cleanedContainerProps}>
            <p
                className={labelClassName}
                style={{...labelStyle}}
            >{label}</p>
            <FlatSwitch {...checkboxProps} style={{...checkboxProps.style, width: "fit-content"}}/>
        </span>
    )
}