import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { GetCustomerHistoryQuery, useGetCustomerHistoryQuery } from "generated/graphql";
import { dateTimeStrToMdy } from "Globals/DateAndTimeHelpers";

interface CustomerHistoryDialogProps {
    customerId: number;
    onClose: () => void;
}

export default function CustomerHistoryDialog({customerId, onClose}: CustomerHistoryDialogProps) {
    const {data, loading} = useGetCustomerHistoryQuery({
        variables: {customerId},
        onError: () => alert("Could not retrieve customer history")
    });
    const cHistory = data?.customerHistory;
    const cName = `${cHistory?.firstName} ${cHistory?.lastName}`;
    const jobSummaries = cHistory?.jobSummaries ?? [];

    return (
        <Dialog open={true}>
            <DialogTitle>{loading ? "Loading" : `${cName}'s History`}</DialogTitle>

            <DialogContent>
                {jobSummaries.length === 0 && <Typography>No jobs found</Typography>}
                <div className="flex-column flex-gap-sm padding-sm">
                    {jobSummaries.map(js => <JobSummaryTile key={js.id} summary={js} />)}
                </div>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={onClose}>Done</Button>
            </DialogActions>
        </Dialog>
    )
}

function JobSummaryTile({summary}: {summary: GetCustomerHistoryQuery['customerHistory']['jobSummaries'][number]}) {
    const dateStr = dateTimeStrToMdy(summary.latestVisitDate);
    const salespersonName = summary.salesperson ? `${summary.salesperson.firstName} ${summary.salesperson.lastName}` : "Unassigned";
    const productTypes = summary.productTypes.join(', ');
    const productTypeLabel = summary.productTypes.length === 1 ? "Product Type" : "Product Types";

    return (
        <div className="flex-column flex-gap-sm padding-xsm" style={{border: "2px solid black", borderRadius: "5px"}}>
            <Typography><b>Status:</b> {summary.jobStatus}</Typography>
            <Typography><b>Last Appointment Date:</b> {dateStr}</Typography>
            <Typography><b>Salesperson:</b> {salespersonName}</Typography>
            <Typography><b>{productTypeLabel}:</b> {productTypes}</Typography>
        </div>
    )
}