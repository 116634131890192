import { LabelForRoom } from "generated/graphql";
import { join } from "lodash";

function formatLabelFullName(label: string, number: number | number[], ending?: string){
    var numberEnd = ""
    if(typeof number === 'number') {
        numberEnd = number as number > 1 ? ` ${number}` : ""
    }
    else {
        numberEnd = " " + (number as number[]).join('\u200A&\u200A') // Thin space \u200A, dot \u00B7, thin-spaced & \u200A&\u200A
        if(ending !== undefined && ending !== ''){
            ending += 's'
        }
    }        

    return `${label}${numberEnd}${(ending ?? "")}`
}

export function getLabelForRoomFullName(label: LabelForRoom, includeClo?: boolean): string {
    const roomLabel = formatLabelFullName(getLabelForRoomName(label), label.labelNumber)

    if(includeClo && label.hasCloset) {
        return roomLabel + '+clo'
    }
    else return roomLabel
}

export function getLabelForRoomName(label: LabelForRoom): string {
    return label.customName ?? label.label.label
}


// export function getNameOfArea(rooms: Pick<Room, 'id' | 'labels'>[], transitions: RoomTransition[]) {
//     // TODO: Implement logic for making name of area based on rooms + transitions
//     // Need to implement closet grouping as hasCloset has been deprecated
// }


export function getNameOfArea(areaTypes: LabelForRoom[], separator?: string, noClosetPostfix?: string): string {
    if (areaTypes.length === 0) return 'NONE'
    else {
        const noClosetCountMap = new Map<string, number[]>();
        const closetCountMap = new Map<string, number[]>();

        const uniqueNames: string[] = []

        for (let label of areaTypes) {

            const name = getLabelForRoomName(label)
            const map = label.hasCloset ? closetCountMap : noClosetCountMap;

            const existingCount = map.get(name)
            if (existingCount === undefined && !uniqueNames.includes(name)) uniqueNames.push(name)

            map.set(name, [...(map.get(name) ?? []), label.labelNumber]);
        }

        const output: string[] = [];

        for (let key of uniqueNames) {
            appendKeyToOutputArr(key, noClosetCountMap, output, noClosetPostfix ?? '');
            appendKeyToOutputArr(key, closetCountMap, output, '+clo');
        }

        return join(output, separator ?? ', ');
    }
}

function appendKeyToOutputArr(key: string, map: Map<string, number[]>, output: string[], ending: string) {
    const roomNumbers = map.get(key) ?? [];

    if (roomNumbers.length > 0) {
        if (roomNumbers.length > 1) {
            output.push(formatLabelFullName(key, roomNumbers.sort(), ending));
        }
        else {
            output.push(formatLabelFullName(key, roomNumbers[0], ending));
        }
    }
}