
import { Typography } from "@material-ui/core";
import logo from "Assets/Images/WorldOfFloorsLogoSmall.png";

export default function InvoiceWOFLogo() {
    return (
        <div className="flex-row-center">
            <img
                width="200"
                height="82"
                src={logo}
                alt="World of Floors Logo"
            />
            <div className="flex-column" style={{alignItems:"flex-start"}}>
                <Typography style={{fontSize:".75rem"}}>50327 Hayes Road</Typography>
                <Typography style={{fontSize:".75rem"}}>Shelby Twp., MI 48315</Typography>
                <Typography style={{fontSize:".75rem"}}>Tel: (586) 353-1790</Typography>
            </div>
        </div>
    )
}