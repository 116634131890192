import { IconButton } from "@material-ui/core";
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatAlertButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton 
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatAlertIcon color={color}/>
        </IconButton>
    )
}

export function FlatAlertIcon({color="black"} : {color?: string}) {
    return <NotificationImportantOutlinedIcon htmlColor={color}/>
}