import { Tooltip } from "@material-ui/core";
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import clsx from "clsx";
import ProjectCard from "Components/ProjectDashboards/ProjectCard";
import StatusPill from "Components/StatusPill";
import FlatCloseButton from "FlatComponents/Button/FlatCloseButton";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { GetAllJobsForProjectCoordinatorQuery, GroupedInstallationAppointment, InstallationToSchedule, namedOperations, useAddRecoveryCommunicationMutation, useClaimJobMutation, useGetAuthenticatedWorkerQuery } from "generated/graphql";
import { calculateWholeDaysBetweenDates, dateTimeStrToDate, dateTimeStrToMdy, todaysDateWithoutTime } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { CARPET_PRODUCT_ID } from "Globals/globalConstants";
import { jobTrackerPath } from "Globals/PathStrings";
import { formatNameStringFirstLast, formatPhoneNumber } from "Globals/StringFormatting";
import { RecoveryCenterCallCustomerPhoneButton } from "Pages/Admin/RecoveryCenter/CallCustomerPhoneButton";
import {
    RECOVERY_COMMUNICATION_OPTION_EMAIL_ID,
    RECOVERY_COMMUNICATION_OPTION_TEXT_ID
} from "Pages/Admin/RecoveryCenter/ClaimedRecoveryCenterCard";
import RecoveryLedger from "Pages/Admin/RecoveryCenter/RecoveryLedger";
import { useState } from "react";
import { Item, Menu, useContextMenu } from "react-contexify";
import NumberFormat from "react-number-format";
import { ProjectCoordinatorMenuProps, PROJECT_COORDINATOR_MENU_ID } from "../ProjectCoordinatorPage";
import InstallationSubCard, { InstallationSubCardProps } from "./InstallationSubCard";

const makeSelectorMenuId = (jobId: number) => `pc-selector-menu-${jobId}`;

// used to determine how to sort installation sub cards
export enum Column {
    UNCLAIMED,
    TO_SCHEDULE,
    SCHEDULED,
    INSTALLING,
    ACTIVE_ORDER
}

interface ProjectCoordinatorCardProps {
    job: GetAllJobsForProjectCoordinatorQuery['allJobsForProjectCoordinator'][number];
    headerDividerClassName: string;
    additionalStatus?: string[];
    disableMenu?: boolean;
    column: Column;
}

export default function ProjectCoordinatorCard({
    job,
    headerDividerClassName,
    column
}: ProjectCoordinatorCardProps) {
    const customerName = `${job.customer.firstName} ${job.customer.lastName}`;
    const [ledgerOpen, setLedgerOpen] = useState(false);
    function closeDialog() {
        setLedgerOpen(false);
    }

    const [addCommunication] = useAddRecoveryCommunicationMutation({
        refetchQueries: [namedOperations.Query.GetAllRecoveryLedgerItems],
    });

    function handleEmailClicked() {
        addCommunication({
            variables: {
                jobId: job.id,
                recoveryCommunicationOptionId: RECOVERY_COMMUNICATION_OPTION_EMAIL_ID,
            },
        });
        window.open(
            `mailto:${job.customer.email}?subject=Subject%20Goes%20Here&body=Body%20goes%20here`
        );
    }

    function handleTextClicked() {
        addCommunication({
            variables: {
                jobId: job.id,
                recoveryCommunicationOptionId: RECOVERY_COMMUNICATION_OPTION_TEXT_ID,
            },
        });
        window.alert(
            "Customer Phone: " + formatPhoneNumber(job.customer.primaryPhone) ??
                job.customer.primaryPhone
        );
    }

    const installationCards: InstallationSubCardProps[] = sortInstallationsAccordingToColumn(column, job.installationAppointments, job.installationsToSchedule);
    const financedAmount = isNullOrUndefined(job.financing?.financingOption) ? undefined : job.contract!.totalPrice - job.financing!.financingDownPayment;

    const jobSalesperson = isNotNullOrUndefined(job.salesperson)
        ? `${job.salesperson!.firstName} ${job.salesperson!.lastName}`
        : "No Salesperson";

    const chips: React.ReactNode[] = [];
    let chipIdx = 0; // for component key generation
    
    if (isNotNullOrUndefined(job.financing?.financingOption)) {
        chips.push(
            <StatusPill
                key={chipIdx++}
                label="Financing"
                backgroundColor="purple"
            />
        )
    }

    const projectCoordinator = job.projectCoordinator;
    const coordinatorName = isNotNullOrUndefined(projectCoordinator) ? `${projectCoordinator?.firstName} ${projectCoordinator?.lastName}` : '';

    const { show: showPcSelector } = useContextMenu({
        id: makeSelectorMenuId(job.id),
      });

    const openJobTracker = () => window.open(`${jobTrackerPath}/${job.contract.id}`);

    let daysSinceContractSigned: number | undefined = undefined;
    const contractSigningDate = job.contract.signingDate;
    if (isNotNullOrUndefined(contractSigningDate)) {
        const contractDate = dateTimeStrToDate(contractSigningDate!);
        const today = todaysDateWithoutTime();
        daysSinceContractSigned = calculateWholeDaysBetweenDates(contractDate, today);
    }
    const showTooltip = daysSinceContractSigned && daysSinceContractSigned < 3;
    const tooltipTitle = showTooltip ? (
        <StatusPill
            style={{fontSize: "1em"}}    
            label={`${3 - daysSinceContractSigned!}-day`}
            backgroundColor="red"
        />
    ) : "";

    const dateComponent = (
        <Tooltip
            title={tooltipTitle}
            className="fit-content"
        >
            <span
                className="fill-width flex-row justify-content-flex-end"
                style={{width: "100%"}}
            >
    
                <p
                    className="flat-font-sm bold-text margin-none text-align-right"
                    style={{paddingRight: "4pt", color: showTooltip ? "var(--flat-red)" : undefined}}
                >
                    {dateTimeStrToMdy(contractSigningDate)}
                </p>
            </span>
        </Tooltip>
    );

    const jobId = job.id;
    const configId = job.contract.jobConfigurationId;
    const contractId = job.contract.id;
    function buildContextMenuProps(): ProjectCoordinatorMenuProps {
        return {
            jobId: jobId!,
            jobConfigurationId: configId,
            contractId: contractId,
            sahNumber: job.sAHNumber!,
            isIn3day: (daysSinceContractSigned ?? -1) <= 2,
            needsPayment: true,
            needsScheduling: true
        };
    }
    const { show } = useContextMenu({ id: PROJECT_COORDINATOR_MENU_ID });
    const onShowMore = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => show(e, {props: buildContextMenuProps()});

    return (
        <ProjectCard
            key={`pc-${job.id}-${column}`}
            onCustomerClick={openJobTracker}
            chips={chips}
            cardNumber={job.sAHNumber}
            customerName={`${job.customer.firstName} ${job.customer.lastName}`}
            customerCity={job.customer.primaryAddress.city}
            dividerClass={headerDividerClassName}
            jobId={job.id}
            jobConfigurationId={job.contract.jobConfigurationId}
            contractId={job.contract.id}
            date={dateComponent}
            sahNumber={job.sAHNumber}
            onShowMore={onShowMore}
        >
            <div className="pc-card-sale-row">
                <p className="flat-font-sm margin-none bold-text">
                    {jobSalesperson}
                </p>
                
                <span className="flex-row flex-gap-xxsm">
                    <NumberFormat
                        className={clsx("flat-font-sm", {"error-text": !job.depositThresholdHit})}
                        displayType="text"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        value={job.totalPaid.toFixed(2)}
                        prefix="$"
                    />

                    <p className="flat-font-sm margin-none">/</p>
                    
                    <NumberFormat
                        className="flat-font-sm"
                        displayType="text"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        value={job.totalPrice.toFixed(2)}
                        prefix="$"
                    />
                </span>
            </div>

            <div className="project-install-region">
                {installationCards.map((ic, idx) => {
                    // function producing props doesn't know about job CWC #, so it's set to -1 for installations that need to be scheduled
                    const cwcNumber = isNotNullOrUndefined(ic.cwcNumber) ? job.cwcNumber! : undefined;

                    return (
                        <InstallationSubCard
                            key={`isc-${column}-${job.contract.id}-${idx}`}
                            {...
                                {...ic, contractId: job.contract.id, financedAmount: financedAmount, cwcNumber: cwcNumber}
                            }
                        />
                    )
                })}
            </div>

            <div className="project-card-bottom-row">
                <div className="half-width flat-dark-bkg">
                    <p className="margin-none flat-font-xsm bold-text">
                        Coordinator:
                    </p>

                    <span
                        onClick={(e) => showPcSelector(e)}
                        className={clsx("project-coordinator-selector", {"project-coordinator-selector-undefined": isNullOrUndefined(projectCoordinator)})}
                    >
                        <p className="margin-none flat-font-xsm">
                            {coordinatorName}
                        </p>
                    </span>
                </div>

                <div className="half-width flat-darker-bkg padding-vertical-xsm">
                    <RecoveryCenterCallCustomerPhoneButton
                        phoneNumber={job.customer.primaryPhone}
                        jobId={job.id}
                        className="project-card-action-icon"
                        asIconButton={false}
                    />
                    <TextsmsOutlinedIcon className="project-card-action-icon" onClick={handleTextClicked}/>
                    <AlternateEmailOutlinedIcon className="project-card-action-icon" onClick={handleEmailClicked}/>
                    <LibraryBooks className="project-card-action-icon" onClick={() => setLedgerOpen(true)}/>
                    {/* TODO: was asked to remove this since it's the same as clicking on contractor name, but was told to wait to actually delete this code until confimed this isn't needed */}
                    {/* <BusinessCenterIcon className="project-card-action-icon" onClick={openJobTracker}/> */}
                </div>
            </div>

            <LedgerDialog
                open={ledgerOpen}
                onClose={() => closeDialog()}
                jobId={job.id}
                customerName={customerName}
                sahNumber={job.sAHNumber}
            />

            <ProjectCoordinatorSelectorMenu selectedId={projectCoordinator?.id} jobId={job.id}/>
        </ProjectCard>
    );
}

interface LedgerDialogProps {
    open: boolean;
    onClose: () => void;
    customerName: string;
    sahNumber: string;
    jobId: number;
}

function LedgerDialog({open, onClose, customerName, sahNumber, jobId}: LedgerDialogProps) {
    return (
        <FlatDialog
            dialogProps={{
                PaperProps: {className: "flat-outer-container", id: "ledger-main-container"},
                open: open,
                onClose: onClose,
                maxWidth: "xl"
            }}
            sectionProps={{
                header: `${customerName} - [${sahNumber}]`,
                endAdornment: <FlatCloseButton onClick={onClose}/>
            }}
        >
            <RecoveryLedger jobId={jobId}/>
        </FlatDialog>
    )
}

interface ProjectCoordinatorSelectorMenuProps {
    selectedId: number | undefined;
    jobId: number;
}

// TODO: this will need to be reconfigured to show all project coordinators once we have a separate view for
// the project coordinator and project manager
function ProjectCoordinatorSelectorMenu({selectedId, jobId}: ProjectCoordinatorSelectorMenuProps) {
    // const {data} = useGetAllProjectCoordinatorsQuery();
    // let selectableCoordinators = data?.allProjectCoordinators ?? [];

    // const [setProjectCoordinator] = useAssignProjectCoordinatorMutation({
    //     onError: () => alert("Could not update project coordinator assignment"),
    //     refetchQueries: [namedOperations.Query.GetAllJobsForProjectCoordinator]
    // });
    
    // function onClick(projectCoordinatorId: number) {
    //     setProjectCoordinator({
    //         variables: {
    //             jobId: jobId,
    //             workerId: projectCoordinatorId
    //         }
    //     });
    // }

    // temporary, until we have different views for PM/PC
    const { data: thisPcData } = useGetAuthenticatedWorkerQuery();
    const thisWorker = thisPcData?.authenticatedWorker;

    const [claimJob] = useClaimJobMutation({
        refetchQueries: [namedOperations.Query.GetAllJobsForProjectCoordinator],
    });

    function onClaim() {
        claimJob({variables: 
            {jobId: jobId}
        });
    }

    return (
        <Menu
            id={makeSelectorMenuId(jobId)}
            style={{ minWidth: "unset" }}
        >
            {/* {selectableCoordinators.map(sc => (
                <Item
                    key={sc.id}
                    id={`selectable-${sc.id}`}
                    onClick={() => onClick(sc.id)}
                >
                    {`${sc.firstName} ${sc.lastName}`}
                </Item>
            ))} */}
            {isNotNullOrUndefined(thisWorker) && (
                <Item
                    key={thisWorker!.id}
                    id={`selectable-${thisWorker!.id}`}
                    onClick={onClaim}
                >
                    {`${thisWorker!.firstName} ${thisWorker!.lastName}`}
                </Item>
            )}
        </Menu>
    )
}

function formatProductArea(amount: number, productTypeId: number) {
    const unit = productTypeId === CARPET_PRODUCT_ID ? "sy." : "sf.";
    amount = productTypeId === CARPET_PRODUCT_ID ? amount / 9 : amount;

    return `${amount.toFixed(0)} ${unit}`;
}

function scheduledToProps(appt: GroupedInstallationAppointment, deemphasize: boolean) {
    const props: InstallationSubCardProps = {
        contractId: -1,  // will be set by the card itself when rendering
        productType: appt.productType,
        materialNumber: appt.productTypeIndex ?? undefined,
        isInstalled: appt.isComplete,
        styleAmounts: appt.styleAmounts.map((amt) =>
            formatProductArea(amt.sqft, appt.productTypeId)
        ),
        installationDates: appt.dates,
        remainingCod: (isNullOrUndefined(appt.cod) || isNullOrUndefined(appt.collectedCod)) ? undefined : (appt.cod! - appt.collectedCod!),
        financedAmount: -1, // will be set by the card itself when rendering
        installerName: formatNameStringFirstLast({
            firstName: appt.contractorFirstName ?? "",
            lastName: appt.contractorLastName ?? "",
            }, "\u00A0"
        ),
        deemphasize: deemphasize
    }
    
    return props;
}

function toScheduleToProps(installation: InstallationToSchedule, deemphasize: boolean) {
    const props: InstallationSubCardProps = {
        contractId: -1, // will be set by the card itself when rendering
        productType: installation.productType,
        materialNumber: installation.productTypeIndex ?? undefined,
        isInstalled: false,
        styleAmounts: [formatProductArea(installation.sqft, installation.id)],
        remainingCod: installation.cod ?? undefined,
        financedAmount: -1, // will be set by the card itself when rendering
        cwcNumber: -1, // will be set by the card itself when rendering
        deemphasize: deemphasize
    }
    
    return props;
}

function sortInstallationsForScheduledColumn(scheduled: GroupedInstallationAppointment[], toSchedule: InstallationToSchedule[]): InstallationSubCardProps[] {
    const relevantScheduled = scheduled.filter(s => !s.startDatePassed).map(s => scheduledToProps(s, false));
    const irrelevantScheduled = scheduled.filter(s => s.startDatePassed).map(s => scheduledToProps(s, true));
    const notScheduled = toSchedule.map(ts => toScheduleToProps(ts, true));

    return [...relevantScheduled, ...irrelevantScheduled, ...notScheduled];
}

function isCurrentlyInstalling(appt: GroupedInstallationAppointment) {
    return appt.startDatePassed && !appt.endDatePassed;
}

function sortInstallationsForInstallingColumn(scheduled: GroupedInstallationAppointment[], toSchedule: InstallationToSchedule[]): InstallationSubCardProps[] {
    const currentlyInstalling = scheduled.filter(s => isCurrentlyInstalling(s)).map(s => scheduledToProps(s, false));
    const notCurrentlyInstalling = scheduled.filter(s => !isCurrentlyInstalling(s)).map(s => scheduledToProps(s, true));
    const notScheduled = toSchedule.map(ts => toScheduleToProps(ts, true));

    return [...currentlyInstalling, ...notCurrentlyInstalling, ...notScheduled];
}

// used to emphasize installations where the end date has passed, but it hasn't been completed, or there is an outstanding COD
function installHasProblem(appt: GroupedInstallationAppointment) {
    return appt.endDatePassed && (!appt.isComplete || ((appt.cod ?? 0) !== (appt.collectedCod ?? 0)));
}

function sortInstallationsForActiveOrderColumn(scheduled: GroupedInstallationAppointment[], toSchedule: InstallationToSchedule[]): InstallationSubCardProps[] {
    const problemticInstalls = scheduled.filter(s => installHasProblem(s)).map(s => scheduledToProps(s, false));
    const okInstalls = scheduled.filter(s => !installHasProblem(s)).map(s => scheduledToProps(s, true));
    const notScheduled = toSchedule.map(ts => toScheduleToProps(ts, true));
    
    return [...problemticInstalls, ...okInstalls, ...notScheduled];
}

function sortInstallationsAccordingToColumn(
    column: Column,
    scheduled: GroupedInstallationAppointment[],
    toSchedule: InstallationToSchedule[]
): InstallationSubCardProps[] {
    switch (column) {
        case Column.UNCLAIMED:
            return [...scheduled.map((s) => scheduledToProps(s, false)), ...toSchedule.map(ts => toScheduleToProps(ts, false))];
        case Column.TO_SCHEDULE:
            return [...toSchedule.map(ts => toScheduleToProps(ts, false)), ...scheduled.map(s => scheduledToProps(s, true))];
        case Column.SCHEDULED:
            return sortInstallationsForScheduledColumn(scheduled, toSchedule);
        case Column.INSTALLING:
            return sortInstallationsForInstallingColumn(scheduled, toSchedule);
        case Column.ACTIVE_ORDER:
            return sortInstallationsForActiveOrderColumn(scheduled, toSchedule);
        }
}