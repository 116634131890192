import NavbarPage from "Components/Page";
import { useGetPhoneNumberStatusQuery } from "generated/graphql";
import { buildAppendedId, usePhoneNumberParam } from "Globals/Hooks";
import { callCenterExistingJobPath, callCenterSchedulerPath } from "Globals/PathStrings";
import { useHistory } from "react-router";

export default function CallLandingPage() {
    const history = useHistory()

    const { phoneNumber } = usePhoneNumberParam()

    const { data } = useGetPhoneNumberStatusQuery({
        variables: { phoneNumber },
        onCompleted(data) {
            const {customerId, activeJobId} = data.phoneNumberStatus
            if(activeJobId) {
                // Go to job page
                history.push(callCenterExistingJobPath + buildAppendedId(activeJobId))
            }
            else if(customerId) {
                // Doesn't have an appointment id but customer is known
                history.push(callCenterSchedulerPath + buildAppendedId(customerId))
            }
            else {
                // Customer not known, make new appointment
                history.push(callCenterSchedulerPath)
            }
        }
    })

    return (
        <NavbarPage title="Call Center" padContent centerHorizontally>
            Page Phone Number {phoneNumber}, Customer Id: {data?.phoneNumberStatus.customerId}, Job Id: {data?.phoneNumberStatus.activeJobId}
        </NavbarPage>
    )
}