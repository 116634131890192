import { MenuItem, Select, Typography } from "@material-ui/core";
import { JobServiceOption } from "generated/graphql";

interface CombinedInstallationServiceEditorProps {
    installationServiceId: number;
    materialCategoryId?: number;
    updateInstallationService: (jobServiceId: number, materialCategoryId?: number) => void;
    installationServiceOptions: JobServiceOption[];
}

export default function CombinedInstallationServiceEditor({
    installationServiceId,
    materialCategoryId,
    updateInstallationService,
    installationServiceOptions,
}: CombinedInstallationServiceEditorProps) {
    const materialOptions =
        installationServiceOptions.find((op) => op.jobServiceId === installationServiceId)
            ?.materialOptions ?? [];

    return (
        <div
            className="fill-width flex-row"
            style={{ alignItems: "center" }}
        >
            <div className="flex-grow">
                <Typography style={{ fontSize: "1.25rem" }}>Installation Method:</Typography>
            </div>
            {installationServiceId !== -1 && installationServiceOptions.length > 0 && (
                <>
                    <div
                        className="flex-row-center"
                        style={{ width: "10rem" }}
                    >
                        <Select
                            value={installationServiceId}
                            fullWidth
                            disabled={installationServiceOptions.length <= 1}
                        >
                            {installationServiceOptions.map((option) => (
                                <MenuItem
                                    key={`install-service-option-${option.jobServiceId}`}
                                    value={option.jobServiceId}
                                    onClick={() =>
                                        updateInstallationService(
                                            option.jobServiceId,
                                            option.materialOptions.find(
                                                (op) => op.isDefaultMaterial
                                            )?.materialCategoryId
                                        )
                                    }
                                >
                                    {option.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div
                        className="flex-row-center"
                        style={{ width: "10rem" }}
                    >
                        {materialOptions.length > 0 && (
                            <Select
                                value={materialCategoryId ?? ""}
                                fullWidth
                            >
                                {materialOptions.map((option) => (
                                    <MenuItem
                                        key={`install-material-option-${option.materialCategoryId}`}
                                        value={option.materialCategoryId}
                                        onClick={() =>
                                            updateInstallationService(
                                                installationServiceId,
                                                option.materialCategoryId
                                            )
                                        }
                                    >
                                        {option.materialName}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </div>
                </>
            )}
            {installationServiceId === -1 && (
                <div
                    className="flex-row-center"
                    style={{ width: "16rem" }}
                >
                    <Typography style={{ fontSize: "1.25rem" }}>No Style Selected</Typography>
                </div>
            )}
        </div>
    );
}