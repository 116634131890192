import { TextField, Typography } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { changeBulkCost, changeSingleCost, resetBulkCost, resetSingleCost, selectBulkCost, selectIsBulkDifferent, selectIsSingleDifferent, selectSingleCost } from "Redux/productCostsReducer";
import CostEditAndDifferenceColumns from "../Editors/CostEditAndDifferenceColumn";
import ReadonlyMoneyTextField from "../Editors/ReadonlyMoneyTextField";

interface ProductCostsCostsColumnsProps {
    styleId: number,
    sourceId?: number
    singleCostPerSqft: number,
    bulkCostPerSqft: number,
    oneTimeCostPerSqft?: number,
    tdClassNames?: string
}

export default function ProductCostsCostsColumns({ styleId, sourceId, singleCostPerSqft, bulkCostPerSqft, oneTimeCostPerSqft, tdClassNames }: ProductCostsCostsColumnsProps) {

    const dispatch = useAppDispatch()

    const notOneTime = oneTimeCostPerSqft === undefined || oneTimeCostPerSqft === null
    const oneTimeCost = oneTimeCostPerSqft ?? 0

    const reduxBulk = useAppSelector(selectBulkCost(styleId, sourceId))
    const reduxSingle = useAppSelector(selectSingleCost(styleId, sourceId))
    const isBulkDifferent = useAppSelector(selectIsBulkDifferent(styleId, sourceId)) ?? false
    const isSingleDifferent = useAppSelector(selectIsSingleDifferent(styleId, sourceId)) ?? false

    
    const bulk = (isBulkDifferent) ? reduxBulk : bulkCostPerSqft;
    const single = (isSingleDifferent) ? reduxSingle : singleCostPerSqft;

    return (
        <>
            {
                !notOneTime &&
                <>
                    <td className={tdClassNames} align="center">

                        <ReadonlyMoneyTextField value={oneTimeCost} className='w-5r' />

                    </td>
                    <td className={tdClassNames} align="center">
                        <Typography>OTC</Typography>
                    </td>
                    <td className={tdClassNames} />
                    <td className={tdClassNames} align="center">

                        <ReadonlyMoneyTextField value={oneTimeCost} className='w-5r' />

                    </td>
                    <td className={tdClassNames} align="center">
                        <Typography>OTC</Typography>
                    </td>
                    <td className={tdClassNames}>
                        <TextField
                            style={{ width: "5rem", visibility: "hidden" }}
                            value={`$${oneTimeCost.toFixed(2)}`}
                            InputProps={{ readOnly: true }} />
                    </td>

                </>
            }
            {
                notOneTime &&
                <>
                    <CostEditAndDifferenceColumns
                        tdClassNames={tdClassNames}
                        loadedValue={singleCostPerSqft}
                        value={single}
                        hasDiff={isSingleDifferent}
                        clearDiff={() => dispatch(resetSingleCost(styleId, sourceId))}
                        setValue={(v) => dispatch(changeSingleCost({styleId, sourceId}, v))} />
                    <CostEditAndDifferenceColumns
                        tdClassNames={tdClassNames}
                        loadedValue={bulkCostPerSqft}
                        value={bulk}
                        hasDiff={isBulkDifferent}
                        clearDiff={() => dispatch(resetBulkCost(styleId, sourceId))}
                        setValue={(v) => dispatch(changeBulkCost({styleId, sourceId}, v))} />
                </>
            }
        </>
    )
}