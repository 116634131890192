import { Button, Typography } from "@material-ui/core"
import clsx from "clsx"
import { TextFieldXButton } from "Components/Forms/Controls/ResetUpdateMoneyField"
import { MoneyNumberFormat } from "Pages/Admin/LaborRates/Components/MoneyNumberFormat"
import ReadonlyMoneyTextField from "./ReadonlyMoneyTextField"

interface CostEditAndDifferenceColumnsProps {
    loadedValue: number,
    value: number | undefined,
    setValue: (newValue: number | undefined) => void,
    clearDiff: () => void,
    hasDiff: boolean,
    tdClassNames?: string
}


export default function CostEditAndDifferenceColumns({ loadedValue, value, setValue, clearDiff, hasDiff, tdClassNames }: CostEditAndDifferenceColumnsProps) {

    const diff = (((value ?? 0) - loadedValue) / (loadedValue === 0 ? .001 : loadedValue)) * 100

    return (
        <>
            <td className={clsx(tdClassNames, "w-5r")} align="center">
                <ReadonlyMoneyTextField value={loadedValue} className='w-5r'/>
            </td>
            <td className={clsx(tdClassNames, "w-5r")} align="center">
                {
                    !hasDiff &&
                    <Button onClick={()=>setValue(loadedValue)}>
                        Change
                    </Button>
                }
                {
                    hasDiff &&
                    <MoneyNumberFormat
                        value={value}
                        onValueChange={(v) => setValue(v.floatValue)}
                        customInput={TextFieldXButton}
                        giveToInputProps={{
                            className: 'w-5r',
                            onClickX: clearDiff
                        }} />
                }
            </td>
            <td className={clsx(tdClassNames, "w-5r")} align="center">
                {
                    hasDiff &&
                    <Typography style={{color: pickColor(diff)}}>
                        {`${diff > 0 ? "+" : ""}${diff.toFixed(2)}%`}
                    </Typography>
                }
            </td>
        </>
    )
}

function pickColor(diff: number) {
    if(diff > 0) return "red"
    else if(diff < 0) return "blue"
    else return undefined
}