import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { namedOperations, useGetSalespersonProfileQuery } from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import { formatNameStringLastFirst } from "Globals/StringFormatting";
import { useState } from "react";
import EditSalespersonDialog from "./EditSalespersonDialog";
import { ProfileCompanyInfoColumn, ProfileSummaryColumn } from "../GeneralProfileComponents/SummaryTables";
import WorkerActiveSwitch from "../GeneralProfileComponents/WorkerActiveSwitch";
import { BackgroundCheckTable } from "../GeneralProfileComponents/Tables/BackgroundCheckTable";
import W9Table from "../GeneralProfileComponents/Tables/W9Table";
import WorkerHistoryTable from "../GeneralProfileComponents/Tables/WorkerHistoryTable";
import WorkersCompTable from "../GeneralProfileComponents/Tables/WorkersCompTable";
import LiabilityInsuranceTable from "../GeneralProfileComponents/Tables/LiabilityInsuranceTable";
import SalespersonPerformanceSummaryTable from "./SalespersonPerformanceSummaryTable";
import SalespersonServiceAreaEditor from "./SalespersonServiceAreaEditor";

export default function SalesAgentProfile() {
    const workerId = useNumericIdParam().id;

    const {data} = useGetSalespersonProfileQuery({
        variables: {workerId: workerId!},
        skip: isNullOrUndefined(workerId),
        onError: () => alert("Could not load salesperson information"),
        fetchPolicy: "network-only"
    });
    const salesperson = data?.salespersonProfile;
    const salespersonName = isNullOrUndefined(salesperson) ? "" : formatNameStringLastFirst({firstName: salesperson!.firstName, lastName: salesperson!.lastName});

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const sectionHeader = (
        isNotNullOrUndefined(salesperson) ? (
            <span className="flex-row flex-gap-xsm">
                <p className="margin-none">{salespersonName}</p>
                <FlatEditButton onClick={() => setEditDialogOpen(true)}/>
            </span>
        ) : (<></>)
    );

    const activeSwitch = (
        isNotNullOrUndefined(salesperson) ? (
            <WorkerActiveSwitch
                workerId={salesperson!.workerId}
                isActive={salesperson!.isActive}
                toRefetch={namedOperations.Query.GetSalespersonProfile}
            />
        ) : (<></>)
    );

    return (<>
        <NavbarPage hideNavbar className="flat-page" centerHorizontally>
            <FlatNavbar title="Sales Agent Profile"/>
            <div className="flat-page-content padding-top-sm padding-bottom-sm flex-column flex-gap-sm">
                <FlatSection
                    header={sectionHeader}
                    endAdornment={activeSwitch}
                >
                    {isNullOrUndefined(salesperson) ? (
                        <Loading />
                    ) : (
                        <div>
                            <div id="profile-grid">
                                <div id="profile-grid-left-column">
                                    <ProfileSummaryColumn
                                        profile={salesperson!}
                                        toRefetch={namedOperations.Query.GetSalespersonProfile}
                                    />
                                    <BackgroundCheckTable 
                                        backgroundChecks={salesperson!.backgroundChecks}
                                        workerId={salesperson!.workerId}
                                        toRefetchOnUpdate={[namedOperations.Query.GetSalespersonProfile]}
                                    />
                                </div>

                                <div id="profile-grid-right-column">
                                    <ProfileCompanyInfoColumn profile={salesperson!}/>
                                    <W9Table
                                        workerId={salesperson!.workerId}
                                        w9s={salesperson?.w9s ?? []}
                                        toRefetch={namedOperations.Query.GetSalespersonProfile}
                                    />
                                    <WorkersCompTable
                                        workerId={salesperson!.workerId}
                                        wcs={salesperson?.workersComps ?? []}
                                        toRefetch={namedOperations.Query.GetContractorProfile}
                                    />
                                    <LiabilityInsuranceTable
                                        workerId={salesperson!.workerId}
                                        liabilityInsurances={salesperson?.liabilityInsurances ?? []}
                                        toRefetch={namedOperations.Query.GetContractorProfile}
                                    />
                                    <WorkerHistoryTable
                                        history={salesperson?.workerHistory ?? undefined}
                                        workerName={salespersonName}
                                        toRefetch={[namedOperations.Query.GetSalespersonProfile]}
                                    />
                                </div>
                            </div>
                            
                            <SalespersonServiceAreaEditor salespersonId={salesperson!.id} servicedZips={salesperson!.servicedZips}/>
                            <SalespersonPerformanceSummaryTable salespersonId={salesperson!.id}/>
                        </div>
                    )}
                </FlatSection>
            </div>
        </NavbarPage>

        {(editDialogOpen && isNotNullOrUndefined(salesperson)) && 
            <EditSalespersonDialog salesperson={salesperson!} onClose={() => setEditDialogOpen(false)}/>
        }
    </>)
}