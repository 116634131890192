import { Button, IconButton, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { CircularProgress } from "@mui/material";
import { BasePromotion, namedOperations, TypeDetailForBasePromotion, useDeleteBasePromotionMutation, useGetBasePromotionsTableDataQuery } from "generated/graphql";
import { useState } from "react";
import BasePromoEditorDialog, { basePromotionToEditable, newBasePromotion } from "./BasePromoEditorDialog";
import PromoSchedulingDialog from "./PromoSchedulingDialog";

export default function BasePromoTable() {
    const [basePromoDialogOpen, setBasePromoDialogOpen] = useState(false);
    
    const { data: basePromoData } =  useGetBasePromotionsTableDataQuery({
        onError: () => alert("Could not retrieve promotion information")
    });
    const basePromos = basePromoData?.allBasePromotions ?? [];
    const activeOrUpcomingBasePromoIds = basePromoData?.basePromotionIdsForActiveOrUpcoming ?? [];

    return (
        <div className="flex-column align-items-center" >
            <Typography className="align-self-flex-start" variant="h5">Base Promotions</Typography>
            <table>
                <thead>
                    <tr style={{ backgroundColor: "lightgrey", height: "3rem" }}>
                        <td>Actions</td>
                        <td align="left" className="whitespace-no-wrap padding-right-sm">Name</td>
                        <td align="left" className="whitespace-no-wrap padding-right-sm">Details</td>
                    </tr>
                </thead>

                <tbody>
                    {basePromos.map(p => 
                        <BasePromoRow promo={p} hasActiveOrUpcomingPromo={activeOrUpcomingBasePromoIds.includes(p.id)} key={p.id} />)}
                </tbody>
            </table>

            <Button className="margin-top-sm" variant="contained" onClick={() => setBasePromoDialogOpen(true)}>Add Base Promo</Button>

            {basePromoDialogOpen && <BasePromoEditorDialog onClose={() => setBasePromoDialogOpen(false)} promo={newBasePromotion}/>}
        </div>
    )
}

interface BasePromoRowProps {
    promo: BasePromotion;
    hasActiveOrUpcomingPromo: boolean;
}

export function typeForBasePromoToStr(typeDetails: TypeDetailForBasePromotion[]) {
    const promoDetailsStr = typeDetails.map(promoType => {
        let details = `[${promoType.promotionType.label}: `;
        if (promoType.isScalar) {
            details += `${(promoType.amount * 100).toFixed(0)}% off]`
        } else {
            details += `$${promoType.amount.toFixed(2)} off]`;
        }

        return details; 
    }).join(' ');

    return promoDetailsStr;
}

function BasePromoRow({promo, hasActiveOrUpcomingPromo}: BasePromoRowProps) {
    const [editorOpen, setEditorOpen] = useState(false);
    const [promoSchedulingDialogOpen, setPromoSchedulingDialogOpen] = useState(false);

    const promoDetailsStr = typeForBasePromoToStr(promo.promotionTypes);

    const [deleteBasePromotion , {loading: deleting}] = useDeleteBasePromotionMutation({
        variables: {basePromotionId: promo.id},
        onError: () => alert("Could not delete base promotion"),
        refetchQueries: [namedOperations.Query.GetBasePromotionsTableData]
    });

    function onDeleteBasePromotion() {
        if (hasActiveOrUpcomingPromo) {
            alert("Can't delete promotion. This promotion is currently being run, or is set to run in the future.");
            return;
        } else if (window.confirm("Are you sure you wish to delete this promotion?")) {
            deleteBasePromotion();
        }
    }

    function onEditBasePromotion() {
        const confStr = "This promotion is currently being run, or is set to be run in the future and therefore can't be edited. Would you like to create a new promotion based on this one?"
        if (!hasActiveOrUpcomingPromo || window.confirm(confStr)) {
            setEditorOpen(true);
        }
    }

    return (<>
        <tr>
            <td align="left" className="padding-right-xmd">
                <div className="flex-row">
                    {deleting ? (
                        <CircularProgress style={{marginRight: "0.5rem"}} />
                    ) : (
                        <Tooltip title={hasActiveOrUpcomingPromo ? "This promotion can't be deleted" : "Delete promotion"}>
                            <IconButton
                                className="padding-vertical-none"
                                onClick={onDeleteBasePromotion}
                            ><DeleteIcon /></IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title={hasActiveOrUpcomingPromo ? "This promotion can't be edited" : "Edit promotion"}>
                        <IconButton
                            className="padding-vertical-none"
                            disabled={deleting}
                            onClick={onEditBasePromotion}
                        ><EditIcon /></IconButton>
                    </Tooltip>

                    <Tooltip title="Schedule Promotion">
                        <IconButton
                            className="padding-vertical-none"
                            disabled={deleting}
                            onClick={() => setPromoSchedulingDialogOpen(true)}
                        ><ScheduleIcon /></IconButton>
                    </Tooltip>
                </div>
            </td>
            <td align="left" className="padding-right-xmd"><Typography>{promo.name}</Typography></td>
            <td align="left" className="padding-right-xmd"><Typography>{promoDetailsStr}</Typography></td>
        </tr>

        {editorOpen && 
            <BasePromoEditorDialog
                onClose={() => setEditorOpen(false)}
                // if there is an active/upcoming promo which uses this base promo, editing it creates a new copy
                promo={basePromotionToEditable({...promo, id: hasActiveOrUpcomingPromo ? -1 : promo.id, name: hasActiveOrUpcomingPromo ? `Copy of ${promo.name}` : promo.name})}
            />
        }

        {promoSchedulingDialogOpen && 
            <PromoSchedulingDialog basePromo={promo} onClose={() => setPromoSchedulingDialogOpen(false)} />
        }
    </>)
}