import FlatSection from "FlatComponents/Layout/FlatSection";
import { useGetPaymentsPaneInfoQuery } from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import NumberFormat from "react-number-format";

interface PaymentsPaneProps {
    jobConfigurationId: number;
    jobContractId: number;
}

export default function PaymentsPane({jobConfigurationId, jobContractId}: PaymentsPaneProps) {
    const { data, loading } = useGetPaymentsPaneInfoQuery({
        variables: {
            jobConfigurationId: jobConfigurationId,
            jobContractId: jobContractId,
        }
    });

    const totalPrice = data?.jobConfiguration.price?.total.toFixed(2);
    const amtPaid = data?.jobPayments.map(pmt => pmt.amount).reduce((sum, nextAmt) => sum + nextAmt, 0).toFixed(2);
    const cod = data?.jobCOD.remaining.toFixed(2);
    const financing = data?.jobConfiguration.financing;
    const hasFinancing = !isNullOrUndefined(financing?.financingOption);
    const finAmt = data?.financedAmount.toFixed(2) ?? 0;
    const finTerm = financing?.financingOption?.term ?? undefined;
    const finMonthlyPmt = hasFinancing ? financing!.perMonthAmount.toFixed(2) : undefined;

    return (
        <FlatSection header="Payments" className="fill-height">
            {!loading && (
                <div className="grid-60-40 flat-font flex-row-gap-xsm">
                    <span className="fill-width bold-text">Total</span>
                    <span className="fill-width text-align-right">
                        <NumberFormat
                            value={totalPrice}
                            thousandSeparator
                            prefix="$"
                            displayType="text"
                        />
                    </span>

                    <span className="fill-width bold-text">Amount Paid</span>
                    <span className="fill-width text-align-right">
                        <NumberFormat
                            value={amtPaid}
                            thousandSeparator
                            prefix="$"
                            displayType="text"
                        />
                    </span>

                    {hasFinancing && (<>
                        <span className="fill-width bold-text">Fin. Amount</span>
                        <span className="fill-width text-align-right">
                            <NumberFormat
                                value={finAmt}
                                thousandSeparator
                                prefix="$"
                                displayType="text"
                            />
                        </span>

                        <span className="fill-width bold-text">Fin. Term</span>
                        <span className="fill-width text-align-right">{finTerm} Months</span>

                        <span className="fill-width bold-text">Fin. Monthly</span>
                        <span className="fill-width text-align-right">
                            <NumberFormat
                                value={finMonthlyPmt}
                                thousandSeparator
                                prefix="$"
                                displayType="text"
                            />
                        </span>
                    </>)}

                    <span className="fill-width bold-text">COD</span>
                    <span className="fill-width text-align-right">
                        <NumberFormat
                            value={cod}
                            thousandSeparator
                            prefix="$"
                            displayType="text"
                        />
                    </span>
                </div>
            )}
        </FlatSection>
    )
}