import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import CalendarIcon from "@material-ui/icons/CalendarToday";

interface FlatDatePickerProps extends ReactDatePickerProps {}

export default function FlatDatePicker({ ...props }: FlatDatePickerProps) {
    return (
        <div
            className="flat-dark-bkg"
            style={{ position: "relative" }}
        >
            <DatePicker
                {...props}
                className="flat-date-picker"
                // why disabledKeyboardNavigation? https://github.com/Hacker0x01/react-datepicker/issues/4015#issuecomment-1538664377
                disabledKeyboardNavigation
            />
            <div className="flat-date-picker-icon" style={{ position: "absolute", right: 3, top: 0, bottom: 0, pointerEvents: "none" }} >
                <CalendarIcon fontSize="small" />
            </div>
        </div>
    );
}
