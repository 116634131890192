import clsx from "clsx";
import { DetailedHTMLProps, TextareaHTMLAttributes } from "react";
import { FlatLabeledInputBase } from "./flatInputUtils";

type FlatTextAreaProps = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

export default function FlatTextArea({className, ...props}: FlatTextAreaProps) {
    return (
        <textarea className={clsx("flat-textarea", className)} {...props} />
    )
}

// labels always go above text areas
type FlatLabeledTextAreaProps = Omit<FlatLabeledInputBase<FlatTextAreaProps>, "labelLocation">;

export function FlatLabeledTextArea({
    label,
    bold=true,
    labelSize="standard",
    labelStyle,
    containerProps,
    ...inputProps
}: FlatLabeledTextAreaProps) {
    const containerClassName = clsx("flex-column", "flex-gap-xsm", containerProps?.className);
    const labelClassName = clsx("margin-none", {
        "flat-font-xsm": labelSize === "xsm",
        "flat-font-sm": labelSize === "sm",
        "flat-font": labelSize === "standard",
        "flat-font-md": labelSize === "md",
        "flat-font-lg": labelSize === "lg",
        "bold-text": bold
    });

    // remove className from the spread props because it will overwrite the directly passed className prop
    const {className, ...cleanedContainerProps} = {...containerProps};

    return (
        <span className={containerClassName} {...cleanedContainerProps}>
            <p
                className={labelClassName}
                style={{...labelStyle}}
            >{label}</p>
            <FlatTextArea {...inputProps}/>
        </span>
    )
}