import { Button, Dialog, DialogContent } from "@material-ui/core";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingIcon from '@mui/icons-material/Pending';
import { useGetContractProgressQuery } from "generated/graphql";
import { homePath } from "Globals/PathStrings";
import { useState } from "react";
import { useHistory } from "react-router";
import EmailContractDialog from "./EmailContractDialog";

interface ContractSuccessDialogProps {
    contractId: number;
    customerName: string;
    customerEmail: string;
}

export default function ContractSuccessDialog({contractId, customerName, customerEmail}: ContractSuccessDialogProps) {
    const history = useHistory();
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);

    const {data} = useGetContractProgressQuery({
        variables: {jobContractId: contractId},
        pollInterval: 2000
    });
    const statusData = data?.contractProgress;
    const emailed = statusData?.emailed ?? false;
    let emailStatus: EmailStatus = "pending";
    if (emailed) {
        emailStatus = "succeeded";
    }
    
    let emailStatusIcon = <></>;
    let emailStatusText = <></>;
    if (emailStatus === "pending") {
        emailStatusIcon = <PendingIcon fontSize="small" className="margin-right-xxsm"/>;
        emailStatusText = <p className="flat-font-sm margin-none">Pending email to <i>{customerEmail}</i></p>;
    } else if (emailStatus === "succeeded") {
        emailStatusIcon = <CheckCircleOutlineIcon htmlColor="green" fontSize="small" className="margin-right-xxsm"/>
        emailStatusText = <p className="flat-font-sm margin-none">Contract emailed to <i>{customerEmail}</i></p>;
    } else {
        emailStatusIcon = <CancelIcon htmlColor="red" fontSize="small" className="margin-right-xxsm"/>
        emailStatusText = <p className="flat-font-sm margin-none">Failed to email contract to <i>{customerEmail}</i></p>;
    }

    return (<>
        <Dialog open={true} maxWidth="md">
            <DialogContent>
                <p className="flat-font-lg margin-none">Congratulations {customerName}!</p>
                <p className="flat-font-lg">Thank you for your business!</p>

                <div className="flex-column">
                    <span className="flex-row align-items-center">
                        <CheckCircleOutlinedIcon htmlColor="green" fontSize="small" className="margin-right-xxsm"/>
                        <p className="flat-font-sm margin-none">Contract created</p>
                    </span>

                    <span className="flex-row align-items-center">
                        {emailStatusIcon}
                        {emailStatusText}
                    </span>
                </div>

                <div className="fill-width flex-row margin-top-sm" style={{ justifyContent: "space-between", marginTop: ".5rem" }}>
                    <Button variant="outlined" color="secondary" onClick={() => history.push(homePath)}>Done</Button>
                    <Button variant="contained" color="primary" onClick={() => setEmailDialogOpen(true)}>Email More</Button>
                </div>
            </DialogContent>
        </Dialog>

        {emailDialogOpen && (
            <EmailContractDialog 
                contractId={contractId}
                defaultIncludeBaseEmail={false}
                baseEmail={customerEmail}
                closeDialog={() => setEmailDialogOpen(false)}
            />
        )}
    </>)
}

type EmailStatus = "pending" | "succeeded" | "failed";