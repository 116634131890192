import { useAddRecoveryCommunicationMutation, namedOperations } from "generated/graphql";
import CallCustomerPhoneButton, {
    CallCustomerPhoneButtonProps,
} from "Pages/Admin/RecoveryCenter/CallCustomerPhoneButton";
import { RECOVERY_COMMUNICATION_OPTION_PHONE_ID } from "Pages/Admin/RecoveryCenter/ClaimedRecoveryCenterCard";

interface CallCenterCallCustomerPhoneButtonProps
    extends Pick<CallCustomerPhoneButtonProps, "phoneNumber"> {
    jobId: number;
    onCallConnected?: () => void
}

export default function CallCenterCallCustomerPhoneButton({
    jobId,
    phoneNumber,
    onCallConnected,
}: CallCenterCallCustomerPhoneButtonProps) {
    const [addCommunication] = useAddRecoveryCommunicationMutation({
        refetchQueries: [namedOperations.Query.GetAllCallCenterJobs],
    });

    return (
        <CallCustomerPhoneButton
            phoneNumber={phoneNumber}
            onCallConnected={(error, dialoutId) => {
                if (error !== undefined) {
                    window.alert(error);
                } else {
                    addCommunication({
                        variables: {
                            jobId,
                            recoveryCommunicationOptionId: RECOVERY_COMMUNICATION_OPTION_PHONE_ID,
                            dialoutId: dialoutId,
                        },
                    });

                    onCallConnected?.();
                }
            }}
        />
    );
}
