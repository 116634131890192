import { Dialog } from "@material-ui/core";
import Loading from "Components/Loading";
import { useGetQuotePdfS3FileQuery } from "generated/graphql";
import { S3PdfViewerDialog } from "./S3PdfViewers";

interface S3QuotePdfDialogWrapperProps {
    quoteId: number;
    onClose: () => void;
}

export default function S3QuotePdfDialogWrapper({quoteId, onClose}: S3QuotePdfDialogWrapperProps) {
    const {data} = useGetQuotePdfS3FileQuery({
        variables: {quoteId: quoteId},
        onError: () => alert("Couldn't load quote PDF")
    });
    const s3FileId = data?.quotePdfS3File?.id;

    if (s3FileId) {
        return <S3PdfViewerDialog fileId={s3FileId} onClose={onClose} />
    } else {
        return (
            <Dialog open>
                <Loading />
            </Dialog>
        )
    }
}