import clsx from "clsx"

interface CustomerWillCallButtonProps extends Omit<React.HTMLProps<HTMLDivElement>, "onClick"> {
    cwcPeriod?: number,
    onClick?: () => void
}

interface ProjectCoordinatorCwcButtonProps extends CustomerWillCallButtonProps {
    useSmallVariant?: boolean;
}

// used on Project Coordinator dashboard header
export default function CustomerWillCallButton({ cwcPeriod, onClick, style, useSmallVariant=false, ...props }: ProjectCoordinatorCwcButtonProps) {
    const label = "CWC" + (cwcPeriod !== undefined ? ` ${cwcPeriod}` : '')
    const className = useSmallVariant ? "customer-will-call-button-sm" : "customer-will-call-button"

    return (
        <div
            {...props}
            onClick={onClick}
            className={className}
            style={{...style}}
        >{label}</div>
    )
}

// used on installation scheduler
export function SchedulerCustomerWillCallButton({ cwcPeriod, onClick, className, style, ...props }: CustomerWillCallButtonProps) {
    const label = "CWC" + (cwcPeriod !== undefined ? ` ${cwcPeriod}` : '')

    return (
        <div
            id="scheduler-customer-will-call-button"
            {...props}
            onClick={onClick}
            className={clsx("clickable", className)}>
            {label}
        </div>
    )
}