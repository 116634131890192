import { forwardRef } from 'react';
import { InputValue } from './ItemAmountPicker';

export type InputAmountPickerOption = React.ForwardRefExoticComponent<InputFieldProps & React.RefAttributes<HTMLInputElement>>;

interface InputFieldProps {
    inputFieldWidth: string | number,
    additionalText?: string,
    value?: InputValue
    onChange?: (e: InputValue) => void,
    disabled?: boolean
}

export const RollCountInput = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", margin: "0 .5rem", flexWrap: "nowrap" }}>
            <input
                disabled={props.disabled ?? false}
                value={props.value?.primary}
                onChange={e => {
                    props.onChange?.({ primary: e.target.value, secondary: ""})
                }}
                ref={ref}
                style={{ width: props.inputFieldWidth, marginRight: ".5rem" }} />
            roll
            <div style={{marginLeft: ".25rem"}}>
                {props?.additionalText}
            </div>
        </div>
    )
})

export const CutLengthInput = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", margin: "0 .5rem", flexWrap: "nowrap" }}>
            <input
                disabled={props.disabled ?? false}
                value={props.value?.primary}
                onChange={e =>
                    props.onChange?.(
                        { primary: e.target.value, secondary: props.value?.secondary??""}
                    )}
                ref={ref}
                style={{ width: props.inputFieldWidth }} />
            <p style={{ marginRight: ".5rem" }}>'</p>
            <input
                disabled={props.disabled ?? false}
                value={props.value?.secondary}
                onChange={e =>
                    props.onChange?.(
                        { primary: props.value?.primary ?? "0", secondary: e.target.value }
                    )}
                style={{ width: props.inputFieldWidth }} />
            <p style={{ marginRight: ".5rem" }}>"</p>
            <div style={{marginLeft: ".25rem"}}>
                {props?.additionalText}
            </div>
        </div>
    )
})

export const PalletCountInput = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", margin: "0 .5rem", flexWrap: "nowrap" }}>
           <input
                disabled={props.disabled ?? false}
                value={props.value?.primary}
                onChange={e => {
                    props.onChange?.({ primary: e.target.value, secondary: ""  })
                }}
                ref={ref}
                style={{ width: props.inputFieldWidth, marginRight: ".5rem" }} />
            plt
            <div style={{marginLeft: ".25rem"}}>
                {props?.additionalText}
            </div>
        </div>
    )
})

export const BoxCountInput = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", margin: "0 .5rem", flexWrap: "nowrap"}}>
            <input
                disabled={props.disabled ?? false}
                value={props.value?.primary}
                onChange={e => {
                    props.onChange?.({ primary: e.target.value, secondary: "" })
                }}
                ref={ref}
                style={{ width: props.inputFieldWidth, marginRight: ".5rem" }} />
            ctn
            <div style={{marginLeft: ".25rem"}}>
                {props?.additionalText}
            </div>
        </div>
    )
})

export const PieceCountInput = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", margin: "0 .5rem", flexWrap: "nowrap" }}>
            <input
                disabled={props.disabled ?? false}
                value={props.value?.primary}
                onChange={e => {
                    props.onChange?.({ primary: e.target.value, secondary: ""  })
                }}
                ref={ref}
                style={{ width: props.inputFieldWidth, marginRight: ".5rem" }} />
            pc
            <div style={{marginLeft: ".25rem"}}>
                {props?.additionalText}
            </div>
        </div>
    )
})