import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from 'clsx';
import { isNotNullOrUndefined } from 'Globals/GenericValidators';

interface ProjectCardCustomerBarProps {
    onCustomerClick?: () => void;
    customerName: string;
    customerCity: string;
    cardNumber: string;
    jobId?: number;
    jobConfigurationId?: number;
    contractId?: number;
    date: string | React.ReactNode;
    sahNumber?: string;
    statusChips: React.ReactNode;
    dividerClass?: string;
    onShowMore?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

export default function ProjectCardCustomerBar({
    onCustomerClick,
    customerName,
    customerCity,
    cardNumber,
    date,
    statusChips,
    dividerClass,
    onShowMore
}: ProjectCardCustomerBarProps) {
    const clickable = isNotNullOrUndefined(onCustomerClick);

    return (
        <div className={`customer-bar ${dividerClass ?? ''}`}>
            <div
                className={clsx("customer-bar-name flat-font-md", { 'clickable': clickable })}
                onClick={onCustomerClick}
            >{customerName}</div>
            <div className="customer-bar-sah-number flat-font-sm">{cardNumber}</div>
            <div className="customer-bar-bottom-row">
                <div className="customer-bar-city flat-font">{customerCity}</div>
                <div className="customer-bar-status-pill-container">{statusChips}</div>
                <span
                    className="fill-width flex-row justify-content-flex-end"
                    style={{width: "100%"}}
                >
                    {date}
                </span>
            </div>

            {onShowMore && (<>
                <div className="customer-bar-more-icon">
                    <MoreVertIcon
                        className='customer-bar-more-icon'
                        htmlColor='black'
                        fontSize='medium'
                        onClick={onShowMore}
                    />
                </div>
            </>)}
        </div>
    )
}