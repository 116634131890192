import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";

interface NumberFormattedTextFieldProps {
    value: number | undefined
    onChange: (newValue: number | undefined) => void,
    decimalScale: number
}

export default function NumberFormattedTextField({value, onChange, decimalScale} : NumberFormattedTextFieldProps) {
    return (
        <NumberFormat
            allowNegative={false}
            inputMode="numeric"
            decimalScale={decimalScale}
            value={value}
            onValueChange={v=>onChange(v.floatValue)}
            customInput={TextField}
        />
    )
}