import { IconButton, Input } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import clsx from "clsx";

interface ValueEditorProps {
    value: string,
    initialValue: number | null
    onChange: (value: string) => void,
    unit: string,
    disabled: boolean,
    nonMoneyVersion?: boolean
}

export default function ServiceValueEditor({ value, onChange, unit, initialValue, disabled, nonMoneyVersion }: ValueEditorProps) {

    function updateValue(v: string) {
        const noIllegalChars = v.replace(/[^0-9.]+/g, "")
        const splitNumber = noIllegalChars.split(".")

        onChange(splitNumber.filter((v, ind) => ind < 2).join('.'))
    }

    const hasChanges = initialValue !== null && +value !== +initialValue.toFixed(2);
    function clearChanges() {
        updateValue(initialValue!.toFixed(2))
    }

    return (
        <div className="flex-row" style={{ alignItems: "flex-start" }}>
            <Input
                style={{borderBottom:"#44444444 solid 1px"}}
                disabled={disabled}
                value={value}
                onChange={v => updateValue(v.target.value)}
                startAdornment={nonMoneyVersion ? "" :"$"}
                error={isInvalidAmount(value, nonMoneyVersion ?? false, nonMoneyVersion ? 1 : 0.01)}
                endAdornment={nonMoneyVersion ? unit : `/${unit}`} />
            <IconButton
                disabled={disabled}
                size="small"
                onClick={clearChanges}
                className={clsx({ 'visibility-hidden': !hasChanges })}>
                <Cancel fontSize="small" />
            </IconButton>
        </div >
    )
}

function isInvalidAmount(amount: string, emptyAllowed: boolean, minThreshold: number) {
    if(emptyAllowed && amount === "") return false
    else return isNaN(+amount) || +amount < minThreshold
}