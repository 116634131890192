import { Checkbox, FormLabel, IconButton, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Room } from "generated/graphql";
import { isNotEmptyString, isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { EMBOSSING_LEVELER_ID, FIVE_EIGHTHS_INCH_PLY_ID, HALF_INCH_PLY_ID, PLYWOOD_ID, QUARTER_INCH_PLY_ID, SHIM_ID, SKIM_COAT_ID, THREE_EIGHTHS_INCH_PLY_ID, THREE_QUARTERS_INCH_PLY_ID } from "Globals/globalConstants";
import { sumArray } from "Globals/Helpers";
import { useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import './build_up_editor.css';
import { RoomIDAndServicesForRoom } from "./EditInstallationServicesMenu";

interface BuildUpEditorProps {
    rooms: Room[];
    buildUpServices: RoomIDAndServicesForRoom[];
    addBuildUp: (roomId: number, roomIndex: number, amount: number, jobServiceId: number, materialCategoryIds?: number[]) => void;
    removeBuildUp: (roomId: number, jobServiceId: number) => void;
}

export default function BuildUpEditor({rooms, buildUpServices, addBuildUp, removeBuildUp}: BuildUpEditorProps) {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <div>
            <div className="flex-row align-items-center">
                <Typography style={{fontSize: "1.25rem"}}>Build Up</Typography>
                <IconButton size="medium" onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? <ArrowDropUpIcon fontSize="large"/> : <ArrowDropDownIcon fontSize="large"/>}
                </IconButton>
            </div>

            {!collapsed && (
                <div className="flex-column flex-row-gap-sm build-up-container">
                    {rooms.map((r, index) =>
                        <BuildUpForRoomEditor
                            key={r.id} room={r}
                            buildUpServices={buildUpServices.filter(sbu => sbu.roomId === r.id)}
                            addService={(amount: number, jobServiceId: number, mcIds?: number[]) => addBuildUp(r.id, index, amount, jobServiceId, mcIds)}
                            removeService={(jobServiceId: number) => removeBuildUp(r.id, jobServiceId)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

interface BuildUpForRoomEditorProps {
    room: Room;
    buildUpServices: RoomIDAndServicesForRoom[];  // passed selection should already be filtered for this room
    addService: (amount: number, jobServiceId: number, mcIds?: number[]) => void;
    removeService: (jobServiceId: number) => void;
}

interface PlywoodOption {
    selectIdx: number;  // the location it is placed in the select drop down menuf
    description: string;
    actualThickness: number;
    materialCategoryIds: number[];
}

export const plywoodOptions: PlywoodOption[] = [
    {selectIdx: 0, description: 'None', materialCategoryIds: [], actualThickness: 0},
    {selectIdx: 1, description: '1/4"', materialCategoryIds: [QUARTER_INCH_PLY_ID], actualThickness: .25},
    {selectIdx: 2, description: '3/8"', materialCategoryIds: [THREE_EIGHTHS_INCH_PLY_ID], actualThickness: .375},
    {selectIdx: 3, description: '1/2"', materialCategoryIds: [HALF_INCH_PLY_ID], actualThickness: .5},
    {selectIdx: 4, description: '5/8"', materialCategoryIds: [FIVE_EIGHTHS_INCH_PLY_ID], actualThickness: .625},
    {selectIdx: 5, description: '3/4"', materialCategoryIds: [THREE_QUARTERS_INCH_PLY_ID], actualThickness: .75},
    {selectIdx: 6, description: '1"', materialCategoryIds: [HALF_INCH_PLY_ID, HALF_INCH_PLY_ID], actualThickness: 1},
    {selectIdx: 7, description: '1 1/4"', materialCategoryIds: [THREE_QUARTERS_INCH_PLY_ID, HALF_INCH_PLY_ID], actualThickness: 1.25},
    {selectIdx: 8, description: '1 1/2"', materialCategoryIds: [THREE_QUARTERS_INCH_PLY_ID, THREE_QUARTERS_INCH_PLY_ID], actualThickness: 1.5}
];

export function getPlywoodThickness(plywoodMaterialId: number) {
    switch(plywoodMaterialId) {
        case QUARTER_INCH_PLY_ID: return .25;
        case THREE_EIGHTHS_INCH_PLY_ID: return .375;
        case HALF_INCH_PLY_ID: return .5;
        case FIVE_EIGHTHS_INCH_PLY_ID: return .625;
        case THREE_QUARTERS_INCH_PLY_ID: return .75;
        default: return 0;
    }
}

function getSummedPlywoodThickness(materialCategoryIds: number[]) {
    return sumArray(materialCategoryIds.map(getPlywoodThickness));
}

// determine this via calculated thickness because there could be multiple ways of achieving some of these amounts
export function findPlywoodOptionIndex(materialCategoryIds: number[]) {
    const thickness = getSummedPlywoodThickness(materialCategoryIds);
    return plywoodOptions.find(buo => buo.actualThickness === thickness)?.selectIdx ?? -1;
}

function BuildUpForRoomEditor({room, buildUpServices: selectedBuildUp, addService, removeService}: BuildUpForRoomEditorProps) {
    const roomName = getNameOfArea(room.labels);
    
    const selectedPlywoodIdx = useMemo(() => {
        // don't need to filter by room because only build up for this room is passed
        const matCatsForThisRoom = selectedBuildUp.map(bfr => bfr.materialCategoryId).filter(id => isNotNullOrUndefined(id)) as number[];
        return findPlywoodOptionIndex(matCatsForThisRoom)
    }, [selectedBuildUp]);
    function plywoodSelectionChanged(newIdx: number) {
        const newSelection = plywoodOptions[newIdx];
        if (newSelection.materialCategoryIds.length === 0) {
            removeService(PLYWOOD_ID);
        } else {
            addService(room.sqft, PLYWOOD_ID, plywoodOptions[newIdx].materialCategoryIds);
        }
    }

    const embossingLevelerService = selectedBuildUp.find(sbu => sbu.jobServiceId === EMBOSSING_LEVELER_ID);
    const hasEl = isNotNullOrUndefined(embossingLevelerService);
    function onElCheckClicked() {
        if (hasEl) {
            removeService(EMBOSSING_LEVELER_ID);
        } else {
            addService(room.sqft, EMBOSSING_LEVELER_ID);
        }
    };
    function onChangeElAmt(newAmt: string) {
        if (isNotEmptyString(newAmt)) {
            addService(+newAmt, EMBOSSING_LEVELER_ID);
        }
    }
    
    const skimCoatService = selectedBuildUp.find(sbu => sbu.jobServiceId === SKIM_COAT_ID);
    const hasSkim = isNotNullOrUndefined(skimCoatService);
    function onSkimCheckClicked() {
        if (hasSkim) { // remove it
            removeService(SKIM_COAT_ID);
        } else { // add it
            addService(room.sqft, SKIM_COAT_ID);
        }
    };
    function onChangeSkimAmt(newAmt: string) {
        if (isNotEmptyString(newAmt)) {
            addService(+newAmt, SKIM_COAT_ID);
        }
    }

    const shimService = selectedBuildUp.find(sbu => sbu.jobServiceId === SHIM_ID);
    const hasShim = isNotNullOrUndefined(shimService);
    function onShimCheckClicked() {
        if (hasShim) { // remove it
            removeService(SHIM_ID);
        } else { // add it
            // NOTE: this service is LNFT
            addService(room.lnft, SHIM_ID);
        }
    };
    function onChangeShimAmt(newAmt: string) {
        if (isNotEmptyString(newAmt)) {
            addService(+newAmt, SHIM_ID)
        }
    }

    return (
        <div className="flex-row flex-gap-sm room-build-up-container">
            <span className="w-5r">
                <Typography style={{fontSize: "1.25rem"}}>{roomName}</Typography>
            </span>

            <div className="flex-column flex-gap-sm w-10r" style={{paddingTop: "0.2rem"}}>
                <FormLabel>Plywood</FormLabel>
                <Select
                    value={selectedPlywoodIdx}
                    onChange={e => plywoodSelectionChanged(e.target.value as number)}
                    label="Plywood"
                >
                    {(selectedPlywoodIdx < 0) && <MenuItem value={-1} disabled>Select Build Up Amount</MenuItem>}
                    {plywoodOptions.map(opt => <MenuItem key={`buo-${opt.selectIdx}`} value={opt.selectIdx}>{opt.description}</MenuItem>)}
                </Select>

                <FormLabel>Embossing Leveler</FormLabel>
                <div className="flex-row">
                    <Checkbox className="padding-vertical-none padding-left-none" style={{paddingLeft: "0"}} checked={hasEl} onClick={onElCheckClicked}/>
                    <NumberFormat
                        value={isNullOrUndefined(embossingLevelerService?.laborAmount) ? '' : embossingLevelerService!.laborAmount.toFixed(2)}
                        onValueChange={(newVal) => onChangeElAmt(newVal.value)}
                        disabled={!hasEl}
                        suffix=" sqft"
                        decimalScale={2}
                        customInput={TextField}
                    />
                </div>
            </div>
            
            <div className="flex-column flex-gap-xsm w-10r" style={{paddingTop: "0.2rem"}}>
                <FormLabel>Skim Coat</FormLabel>
                <div className="flex-row" style={{marginTop: ".4rem"}}>
                    <Checkbox className="padding-vertical-none padding-left-none" style={{paddingLeft: "0"}} checked={hasSkim} onClick={onSkimCheckClicked} />
                    <NumberFormat
                        value={isNullOrUndefined(skimCoatService?.laborAmount) ? "" : skimCoatService!.laborAmount}
                        onValueChange={(newVal) => onChangeSkimAmt(newVal.value)}
                        disabled={!hasSkim}
                        suffix=" sqft"
                        decimalScale={2}
                        customInput={TextField}
                    />
                </div>

                <FormLabel style={{marginTop: ".6rem"}}>Shim</FormLabel>
                <div className="flex-row" style={{marginTop: ".45rem"}}>
                    <Checkbox className="padding-vertical-none" style={{paddingLeft: "0"}} checked={hasShim} onClick={onShimCheckClicked} />
                    <NumberFormat
                        value={isNullOrUndefined(shimService?.laborAmount) ? "" : shimService!.laborAmount}
                        onValueChange={(newVal) => onChangeShimAmt(newVal.value)}
                        disabled={!hasShim}
                        suffix=" lnft"
                        decimalScale={2}
                        customInput={TextField}
                    />
                </div>
            </div>
        </div>
    )
}