// import { Button, Container, Dialog, IconButton, TextField } from "@material-ui/core";
// import { Autocomplete } from '@material-ui/lab';
// import { RollEntry, useGetDummyInventoryQuery } from 'generated/graphql';
// import Job, { dummyJobData } from "Globals/DataStructures/Jobs";
import {
    //CarpetCut,
    LineItem,
    //TransactionCode
} from "Globals/DataStructures/Transactions";
// import { useGlobalState } from "Globals/GlobalState";
// import React, { useEffect, useState } from "react";
// import StringBackedTabGroup from "./StringBackedTabGroup";
// import { measureUnitToStockUnit, stockUnitToMeasureUnit, stockWasteForMeasureUnit } from "./UnitConversions";
// import Loading from "./Loading";
// import { DeleteForever } from "@material-ui/icons";
// import { useAuthenticatedQuery } from "Globals/API";

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    inputLineItem: LineItem | null,
    onSubmit?: (lineItem: LineItem) => void
}

export function LineItemEditor({ open, setOpen, inputLineItem, onSubmit }: Props) {

    return <></>
    // let [lineItem, setLineItem] = useState<LineItem | null>(null);
    // let [debugSettings] = useGlobalState('debugSettings');
    // let [carpetRolls, setCarpetRolls] = useState<RollEntry[] | null>(null);

    // const getDummyInventory = useAuthenticatedQuery(useGetDummyInventoryQuery);

    // useEffect(() => {
    //     setLineItem(inputLineItem);
    //     if (inputLineItem) {
    //         let entry = inputLineItem.inventoryEntry;
    //         if (entry.productType === 'CARPET') {
    //             getDummyInventory.refetch({ filter: { sku: { eq: entry.sku } } }).then(x => {
    //                 if (x.data.dummyInventory?.[0]?.rolls) {
    //                     let values = x.data.dummyInventory![0]!.rolls as RollEntry[];
    //                     setCarpetRolls(values);
    //                 }
    //             })
    //         }
    //     }
    // }, [inputLineItem, open]);

    // let tabOptions: TransactionCode[] = ['None', 'Hold', 'Picked']
    // if (debugSettings.isUpperManagement) {
    //     tabOptions.push('Drop');
    // }

    // let height = '50vh';
    // if (lineItem?.inventoryEntry.productType === 'CARPET') {
    //     height = '75vh';
    // }

    // return (
    //     <>
    //         <Dialog open={open} onClose={(x) => setOpen(false)} fullWidth maxWidth='md'>
    //             {lineItem &&
    //                 <div style={{ display: 'flex', flexDirection: 'column', height }}>
    //                     <div style={{ margin: '0.5rem' }}>
    //                         {lineItem.inventoryEntry && <h1>{lineItem.inventoryEntry.sku}: {lineItem.inventoryEntry.style} ({lineItem.inventoryEntry.color})</h1>}
    //                     </div>
    //                     <StringBackedTabGroup
    //                         value={lineItem.code}
    //                         onChange={(code) => { setLineItem({ ...lineItem!, code: code }) }}
    //                         values={tabOptions} />
    //                     <div style={{ margin: '0.5rem' }}>
    //                         <Container>
    //                             {lineItem.code === 'None' && <NoneForm {...{ lineItem, setLineItem }} />}
    //                             {lineItem.code === 'Hold' && <HoldPickForm {...{ mode: 'Hold', lineItem, setLineItem, carpetRolls }} />}
    //                             {lineItem.code === 'Picked' && <HoldPickForm {...{ mode: 'Pick', lineItem, setLineItem, carpetRolls }} />}
    //                             {lineItem.code === 'Drop' && <DropForm {...{ lineItem, setLineItem }} />}
    //                         </Container>
    //                     </div>
    //                     <div style={{ display: 'flex', flexDirection: 'column-reverse', flexGrow: 1 }}>
    //                         <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
    //                             <Button onClick={() => { onSubmit?.(lineItem!); setOpen(false); }} style={{ margin: '0.5rem', backgroundColor: 'green', color: 'white' }} variant='contained'>Submit</Button>
    //                         </div>
    //                     </div>
    //                 </div>
    //             }
    //         </Dialog >
    //     </>
    // )
}

// interface LineItemFormProps {
//     lineItem: LineItem
//     setLineItem: React.Dispatch<React.SetStateAction<LineItem | null>>
// }

// function NoneForm({ lineItem, setLineItem }: LineItemFormProps) {
//     return (
//         <>
//             <h1 style={{ textAlign: 'center', color: 'red' }}>Please select an action from the top</h1>
//         </>
//     );
// }

// interface HoldPickFormProps extends LineItemFormProps {
//     mode: 'Hold' | 'Pick'
//     carpetRolls: RollEntry[] | null
// }

// function HoldPickForm(props: HoldPickFormProps) {
//     if (props.lineItem.inventoryEntry.productType === 'CARPET') {
//         return <HoldPickSSForm {...props} />
//     }
//     else {
//         return <HoldPickHSForm {...props} />
//     }
// }

// function HoldPickHSForm({ mode, lineItem, setLineItem }: HoldPickFormProps) {

//     let wasteColor = 'black';
//     let wasteText = '';
//     if (lineItem.waste) {
//         if (lineItem.waste >= 0.7) {
//             wasteColor = 'red';
//         }
//         wasteText = `${lineItem.waste.toFixed(1)} ${lineItem.inventoryEntry!.stockUnit} Wasted`
//     }

//     return (
//         <>
//             <div style={{ display: 'flex', flexDirection: 'row' }}>
//                 <JobSelector items={dummyJobData()} selectedItem={lineItem.job} onSelect={(job) => setLineItem({ ...lineItem, job })} />
//                 <TextField
//                     style={{ margin: '0.5rem', padding: '0.5rem' }}
//                     label={`Amount (${lineItem.inventoryEntry!.measurementUnit})`}
//                     InputLabelProps={{ shrink: lineItem.amountInMeasureUnit !== '' }}
//                     value={lineItem.amountInMeasureUnit}
//                     onChange={(x) => { setMeasureUnit(x.target.value, lineItem, setLineItem) }} />
//                 <TextField
//                     style={{ margin: '0.5rem', padding: '0.5rem' }}
//                     label={`Amount (${lineItem.inventoryEntry!.stockUnit})`}
//                     InputLabelProps={{ shrink: lineItem.amountInMeasureUnit !== '' }}
//                     value={lineItem.amountInStockUnit}
//                     onChange={(x) => { setStockUnit(x.target.value, lineItem, setLineItem) }} />
//                 <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
//                     <p style={{ width: '10rem', color: wasteColor, paddingBottom: '0.5rem' }}>{wasteText}</p>
//                 </div>
//             </div>
//         </>
//     )
// }

// function HoldPickSSForm({ mode, lineItem, setLineItem, carpetRolls }: HoldPickFormProps) {

//     /*
//     A cut is considered empty if it's length is an empty string
//     The cuts form behaves as follows
//     The form is populated with 1 entry for each cut in the source data, plus an empty entry.
//     There is an empty entry at the bottom of the form for which
//         The value for roll is the same as the value for the last roll assigned
//         The value for length is an empty string
//     When the empty entry becomes non empty it is added to the source data.
//     When focus is lost, any cuts in the source data which are empty are removed.
//     */

//     const [currentRoll, setCurrentRoll] = useState<RollEntry | undefined>(undefined);

//     function getNextIndex() {
//         return Math.max(...lineItem.cuts.map(x => x.cutNumber), -1) + 1;
//     }

//     function makeDataArray() {
//         const sourceData = [...lineItem.cuts];

//         sourceData.push({ cutNumber: getNextIndex(), lengthInFt: '', roll: currentRoll });

//         return sourceData;
//     }

//     function pruneData() {
//         setLineItem({ ...lineItem, cuts: lineItem.cuts.filter(x => !cutIsEmpty(x)) });
//     }

//     function cutIsEmpty(cut: CarpetCut) {
//         return cut.lengthInFt === '';
//     }

//     function onCutChange(cut: CarpetCut, value?: string, roll?: RollEntry) {
//         const newCut: CarpetCut = { cutNumber: cut.cutNumber, lengthInFt: value ?? cut.lengthInFt, roll: roll ?? cut.roll };
//         setCurrentRoll(roll);
//         let newCuts = lineItem.cuts.map<CarpetCut>(x => x.cutNumber === newCut.cutNumber ? newCut : x);
//         if (!newCuts.includes(newCut) && newCut.lengthInFt !== '') {
//             newCuts.push(newCut);
//         }
//         setLineItem({ ...lineItem, cuts: newCuts });
//     }

//     function removeCut(cut: CarpetCut) {
//         setLineItem({ ...lineItem, cuts: lineItem.cuts.filter(x => x.cutNumber !== cut.cutNumber) });
//     }

//     if (carpetRolls) {
//         return (
//             <>
//                 <div style={{ display: 'flex', flexDirection: 'row' }}>
//                     <JobSelector items={dummyJobData()} selectedItem={lineItem.job} onSelect={(job) => setLineItem({ ...lineItem, job })} />
//                 </div>
//                 <div style={{ display: 'flex', flexDirection: 'column' }}>
//                     {makeDataArray().map(
//                         (x, i) =>
//                             <div key={'cut-selector=div' + x.cutNumber} style={{ display: 'flex', flexDirection: 'row' }} onBlur={(x) => { pruneData() }}>

//                                 <CarpetRollSelector id={x.cutNumber.toString()} items={carpetRolls} selectedItem={x.roll} onSelect={(y) => onCutChange(x, undefined, y)} />
//                                 <TextField
//                                     style={{ margin: '0.5rem' }}
//                                     key={'cut-text-field-' + x.cutNumber.toString()}
//                                     label={`Cut ${i + 1}`}
//                                     value={x.lengthInFt}
//                                     onChange={(e) => { onCutChange(x, e.target.value) }} />
//                                 <IconButton onClick={(e) => { removeCut(x); }} tabIndex={-1}>
//                                     <DeleteForever />
//                                 </IconButton>
//                             </div>)}
//                 </div>
//             </>);
//     }
//     else {
//         return <Loading />
//     }
// }

// interface SelectorProps<T> {
//     items: T[],
//     selectedItem: T | undefined,
//     onSelect?: (t: T | undefined) => void
// }

// function JobSelector({ items, selectedItem, onSelect }: SelectorProps<Job>) {
//     return <Autocomplete<Job>
//         id="transaction-line-item-job-selector-autocomplete"
//         options={dummyJobData()}
//         value={selectedItem}
//         onChange={(e, value) => onSelect?.(value ?? undefined)}
//         getOptionLabel={(option) => `${option.jobNumber}: ${option.customerInfo.lName}, ${option.customerInfo.fName}`}
//         style={{ margin: '0.5rem', padding: '0.5rem', width: 300 }}
//         renderInput={(params) => <TextField {...params} label="Job" variant="outlined" />} />;
// }

// function CarpetRollSelector({ id, items, selectedItem, onSelect }: { id: string } & SelectorProps<RollEntry>) {
//     return <Autocomplete<RollEntry>
//         id={"transaction-line-item-carpet-roll-autocomplete-" + id}
//         options={items}
//         value={selectedItem}
//         onChange={(e, value) => onSelect?.(value ?? undefined)}
//         getOptionLabel={(option) => `Roll ${option.rollNumber}: Actual: ${option.length}' Available: ${option.length - option.holding}'`}
//         style={{ margin: '0.5rem', padding: '0.5rem', width: 300 }}
//         renderInput={(params) => <TextField {...params} label="Roll" variant="outlined" />} />;
// }

// function setStockUnit(amount: string, lineItem: LineItem, setLineItem: React.Dispatch<React.SetStateAction<LineItem | null>>) {
//     let valueInStockUnit = parseFloat(amount);
//     let valueInMeasureUnit = stockUnitToMeasureUnit(valueInStockUnit, lineItem.inventoryEntry!);
//     let stockUnit = isNaN(valueInStockUnit) ? '' : valueInStockUnit.toFixed().toString();
//     let measureUnit = valueInMeasureUnit.toFixed(1).toString();
//     if (isNaN(valueInMeasureUnit)) {
//         measureUnit = '';
//     }
//     setLineItem(
//         {
//             ...lineItem,
//             amountInMeasureUnit: measureUnit,
//             amountInStockUnit: stockUnit,
//             waste: undefined
//         }
//     );
// }

// function setMeasureUnit(amount: string, lineItem: LineItem, setLineItem: React.Dispatch<React.SetStateAction<LineItem | null>>) {
//     let inventoryEntry = lineItem.inventoryEntry!;
//     let valueInMeasureUnit = parseFloat(amount);
//     let valueInStockUnit = measureUnitToStockUnit(valueInMeasureUnit, inventoryEntry);
//     let correctedValueInMeasureUnit = stockUnitToMeasureUnit(valueInStockUnit, inventoryEntry);

//     let measureUnit = amount;
//     let stockUnit = valueInStockUnit.toFixed().toString();
//     let correctedMeasureUnit = correctedValueInMeasureUnit.toFixed(1).toString();

//     let waste = stockWasteForMeasureUnit(valueInMeasureUnit, lineItem.inventoryEntry!);

//     let stockUnitText = isNaN(valueInMeasureUnit) ? '' : `${stockUnit}          (${correctedMeasureUnit} ${inventoryEntry.measurementUnit})`;

//     setLineItem({
//         ...lineItem,
//         amountInMeasureUnit: measureUnit,
//         amountInStockUnit: stockUnitText,
//         waste
//     });
// }

// function DropForm({ lineItem, setLineItem }: LineItemFormProps) {

//     let confirmString = `${lineItem.inventoryEntry!.style} ${lineItem.inventoryEntry!.color}`
//     let readyToConfirm = lineItem.confirmDropProductByTypingNameAndColor === confirmString;

//     return (
//         <>
//             <h1 style={{ textAlign: 'center' }}>Dropped</h1>
//             <div style={{ display: 'flex', flexDirection: 'column' }}>
//                 <h1 style={{ color: 'red' }}>Confirm by typing {confirmString} in the box below</h1>
//                 <TextField
//                     style={{ margin: '0.5rem', padding: '0.5rem' }}
//                     label={confirmString}
//                     value={lineItem.confirmDropProductByTypingNameAndColor}
//                     onChange={(x) => { setLineItem({ ...lineItem, confirmDropProductByTypingNameAndColor: x.target.value }) }}
//                 />
//                 <Button
//                     variant='contained'
//                     disabled={!readyToConfirm}
//                     style={{ backgroundColor: readyToConfirm ? 'red' : 'grey', color: readyToConfirm ? 'white' : 'darkgray', alignItems: 'end' }}
//                 >
//                     I am sure I would like to drop this product
//                 </Button>
//             </div>
//         </>
//     )
// }