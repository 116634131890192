import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { findLowestWholeNumberNotInArray } from 'Globals/Helpers'
import { RootState } from './store'


export type PickNPlaceCell = TypedPickNPlaceCell<any>

export interface TypedPickNPlaceCell<T> {
    data: T,
    isCellSelected: boolean,
    openContextMenu?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

interface CellLocation {
    table: string,
    column: number,
    row: number
}

export interface CellAndLocation<T> {
    cell: TypedPickNPlaceCell<T>,
    location: CellLocation
}

interface PickNPlaceTableData {
    tableData: { [column: number]: { [row: number]: PickNPlaceCell } },
    maxRowIndex: number,
    tableRows: number
}

interface PickNPlaceState { //Defined so that create slice can infer the type
    tables: { [tableName: string]: PickNPlaceTableData },
    isCellPicked: boolean,
    pickedCell?: CellLocation,
}

const initialState: PickNPlaceState = {
    tables: {},
    isCellPicked: false,
    pickedCell: undefined
}

const emptyTable: PickNPlaceTableData = {
    tableData: {},
    maxRowIndex: -1,
    tableRows: 0
}

// WARNING -- Make sure to have already used state at least once before calling this function
function InitializePosition(state: Draft<PickNPlaceState>, target: CellLocation, data: any) {
    if (state.tables[target.table] === undefined) {
        state.tables[target.table] = {
            tableData: { [target.column]: { [target.row]: { data, isCellSelected: false } } },
            maxRowIndex: target.row,
            tableRows: 0
        }
    }
    else if (state.tables[target.table].tableData[target.column] === undefined) {
        state.tables[target.table].tableData[target.column] = { [target.row]: { data, isCellSelected: false } }
    }
    else if (state.tables[target.table].tableData[target.column][target.row] === undefined) {
        state.tables[target.table].tableData[target.column][target.row] = { data, isCellSelected: false }
    }

    // Update max row / column

    const { maxRowIndex, ...remaining } = state.tables[target.table]

    const allRowIndices = Object.keys(state.tables[target.table].tableData).flatMap(col => [...Object.keys(state.tables[target.table].tableData[+col]).map(key => +key)])
    const newMax = Math.max(...allRowIndices)

    state.tables[target.table] = {
        ...remaining,
        maxRowIndex: newMax
    }
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const pickNPlaceSlice = createSlice({
    name: "pickNPlace", //No clue what this does
    initialState,
    reducers: {
        pickCell: {
            reducer(state, action: PayloadAction<CellLocation>) {
                var { table, column, row } = action.payload

                state.isCellPicked = true
                state.pickedCell = { ...action.payload }
                state.tables[table].tableData[column][row]!.isCellSelected = true
            },
            prepare(table: string, column: number, row: number) {
                return { payload: { table, row, column } }
            }
        },
        moveCell: {
            reducer(state, action: PayloadAction<{ table: string, column: number, row?: number, from: CellLocation }>) {
                const { from: oldLocation, ...newLocation } = action.payload
                const { table: oldTable, column: oldColumn, row: oldRow } = oldLocation!
                var { table, column, row } = newLocation

                if (row === undefined) {
                    // Pick best row
                    const isDifferentColumn = oldTable !== table || oldColumn !== column
                    // Finds all rows that have cells in them (excludes self if target is the same column and table)
                    var takenRowIndices = [...Object.keys(state.tables[table]?.tableData[column] ?? {})].map(r => +r).filter(r=>isDifferentColumn || r !== oldRow)
                  
                    if (takenRowIndices.length === 0) row = 0
                    else {
                        row = findLowestWholeNumberNotInArray(takenRowIndices)
                        if(!isDifferentColumn && row === oldRow){
                            // If the best position for this cell to move to is its current position, do nothing
                            return;
                        }
                    }
                }

                const data = state.tables[oldTable].tableData[oldColumn][oldRow].data

                // Moves selected cell data to new position (also ensures it is created)
                InitializePosition(state, { table, column, row }, data);

                // Removes old cell and resets state
                state.isCellPicked = false
                state.pickedCell = undefined
                delete state.tables[oldTable].tableData[oldColumn][oldRow]

                // Updates max row / column of old table
                const { maxRowIndex, ...remaining } = state.tables[oldTable]

                const allRowIndices = Object.keys(state.tables[oldTable].tableData).flatMap(col => [...Object.keys(state.tables[oldTable].tableData[+col]).map(key => +key)])
                const newMax = Math.max(...allRowIndices)

                state.tables[oldTable] = {
                    ...remaining,
                    maxRowIndex: newMax
                }
            },
            prepare(fromLocation: CellLocation, targetTable: string, targetCol: number, targetRow?: number) {
                return { payload: { table: targetTable, column: targetCol, row: targetRow, from: fromLocation } }
            }
        },
        unpickCell(state) {
            if (state.isCellPicked) {
                var { table, column, row } = state.pickedCell!
                state.tables[table].tableData[column][row].isCellSelected = false
                state.isCellPicked = false
                state.pickedCell = undefined
            }
        },
        reinitializePnPTable: {
            reducer(state, action: PayloadAction<{ tableName: string, rows: number }>) {
                const { tableName, rows } = action.payload

                state.tables[tableName] = { ...emptyTable, tableRows: rows }
                state.pickedCell = undefined
                state.isCellPicked = false
            },
            prepare(tableName: string, rows: number) {
                return { payload: { tableName, rows } }
            }
        },
        addDataToPnPTable: {
            reducer(state, action: PayloadAction<{ data: any, table: string, column: number, row?: number }>) {
                var { data, table, column, row } = action.payload

                if (row === undefined) {
                    // Pick best row
                    row = Math.max(...Object.keys(state.tables[table]?.tableData[column] ?? {}).map(row => +row), -1) + 1
                }

                var location = { table, column, row } as CellLocation
                InitializePosition(state, location, data)
            },
            prepare(data: any, table: string, column: number, row?: number) {
                return { payload: { data, table, column, row } }
            }
        }
    }
})
export const {
    pickCell, moveCell, unpickCell,
    reinitializePnPTable, addDataToPnPTable
} = pickNPlaceSlice.actions //Unpacks the actions created in the slice

export const selectIsPickedCell = (state: RootState) => state.pickNPlace.isCellPicked
export const selectPickedCellLocation = (state: RootState) => state.pickNPlace.pickedCell
export const selectPickedCell = (state: RootState) => extractPickedCellAndLocation(state.pickNPlace)
export function selectPickNPlaceCell<T>(table: string, col: number, row: number) {
    return (state: RootState) => state.pickNPlace.tables[table]?.tableData[col]?.[row] as TypedPickNPlaceCell<T>
}
export const selectMaxRowIndex = (table: string) => (state: RootState) => state.pickNPlace.tables[table]?.maxRowIndex ?? -1

const extractPickedCellLocation = (state: PickNPlaceState) => state.pickedCell;
export const extractPickedCellAndLocation = (state: PickNPlaceState) => {
    var location = extractPickedCellLocation(state)
    if (location === undefined) return undefined;
    return extractCellAndLocation<any>(state, location)
}

export function extractCellAndLocation<T>(state: PickNPlaceState, location: CellLocation) {
    return { cell: state.tables[location.table].tableData[location.column][location.row] as TypedPickNPlaceCell<T>, location }
}

export default pickNPlaceSlice.reducer
