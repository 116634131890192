import { ThemeProvider } from '@material-ui/core';
import { redTheme } from 'App';
import MediaQuery from 'react-responsive';
import DesktopNavbar from "./DesktopNavbar"
import MobileNavbar from "./MobileNavbar"
import { RouteNamePair } from './NavbarRoutes';

const sizeCutoff = 800;

export default function Navbar({ customRoutes }: { customRoutes?: RouteNamePair[] }) {
    return (
        <>
            <ThemeProvider theme={redTheme}>
                <MediaQuery maxWidth={sizeCutoff}>
                    <MobileNavbar customRoutes={customRoutes} />
                </MediaQuery>
                <MediaQuery minWidth={sizeCutoff}>
                    <DesktopNavbar customRoutes={customRoutes} />
                </MediaQuery>
            </ThemeProvider>
        </>
    )
}

