import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import FlatButton from "FlatComponents/Button/FlatButton";
import { namedOperations, useGetAllSalespeopleForJobQuery, useRecordPriceRequestMutation, useSendPriceOverrideMutation } from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useCallback, useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { selectCcActualCommissionRate, selectFinancingOption, selectJobCost, selectPreOverridePrice } from "Redux/pricingCenterReducer";
import { calculateFinancingAdjustedRevenue, calculateMargin } from "../marginUtils";

interface SendOverrideButtonProps {
    chatId: number;
    jobConfigurationId: number;
    amount?: number;
    completionCallback?: () => void;
    forLowestPriceClaim?: boolean;
}

/* TODO: need to decouple the 2 main ways this is used
    (1) When a price request has already been claimed
        (a) When an override has already been sent
        (b) When an override request has been claimed
    (2) When the override occurs before an override request has even been made (forLowestPriceClaim === true)
*/
export default function SendOverrideButton({chatId, amount, jobConfigurationId, completionCallback, forLowestPriceClaim=false}: SendOverrideButtonProps) {
    const preOverridePrice = useAppSelector(selectPreOverridePrice); 
    const commissionRate = useAppSelector(selectCcActualCommissionRate);
    const jobCost = useAppSelector(selectJobCost);
    const financingOption = useAppSelector(selectFinancingOption);
    const [salespersonId, setSalespersonId] = useState<number>(-1);

    const {data: salespeopleData} = useGetAllSalespeopleForJobQuery({
        variables: {
            jobConfigurationId: jobConfigurationId
        },
        onCompleted: (data) => setSalespersonId(data.allSalespeopleForJob[0]?.id ?? -1)
    });
    const salespeople = salespeopleData?.allSalespeopleForJob;
    
    const [sendOverride] = useSendPriceOverrideMutation({
        onCompleted: () => isNotNullOrUndefined(completionCallback) && completionCallback!(),
        onError: () => alert('Could not send override response'),
        refetchQueries: [namedOperations.Query.GetPricingSummary]
    });

    const [recordQuoteRequest] = useRecordPriceRequestMutation({
        onError: () => alert("Could not record price request")
    });

    const canSendOverride = useCallback((): boolean => {
        // in this case, need to make an override request when sending the override
        if (forLowestPriceClaim) {
            if (salespersonId < 1) {
                alert("Could not determine which salesperson is making request. Are you sure the appointment has been assigned to a salesperson?");
                return false;
            }
        }

        if (isNullOrUndefined(jobConfigurationId) || isNullOrUndefined(preOverridePrice)) {
            alert("Could not send override response");
            return false;
        }

        if (isNullOrUndefined(amount)) {
            alert("Must enter an amount for the override");
            return false;
        }

        if (isNullOrUndefined(commissionRate)) {
            alert("Must select the salesperson commission rate");
            return false;
        }

        if (amount! >= preOverridePrice!) {
            alert("Override amount must be less than the quoted price of the job");
            return false;
        }

        let margin = calculateMargin(calculateFinancingAdjustedRevenue(amount!, financingOption?.fee ?? 0), jobCost);
        // if (margin < 34) {
        //     alert("Margin must be at least 34%");
        //     return false;
        // }

        if (margin < 38) {
            return window.confirm("Margin is below 38%, are you sure you want to send this override?");
        }

        return true;
    }, [jobCost, jobConfigurationId, amount, preOverridePrice, commissionRate, salespersonId, forLowestPriceClaim, financingOption?.fee]);

    function onSendOverride () {
        if (canSendOverride()) {
            // if forLowestPriceClaim, no override request yet exists, so record one
            // $0 indicates the salesperson requested the lowest price possible
            if (forLowestPriceClaim && isNotNullOrUndefined(salespersonId)) {
                recordQuoteRequest({
                    variables: {
                        chatId: chatId,
                        jobConfigurationId: jobConfigurationId!,
                        amount: 0,
                        salespersonId: salespersonId!
                    }
                })
                // need the request mutation to finish before sending the override
                .then(result => {
                    if (result.data?.recordPriceRequest) {
                        sendOverride({
                            variables: {
                                jobConfigurationId: jobConfigurationId,
                                amount: amount!,
                                commissionRate: commissionRate!
                            }
                        });
                    }
                })
            } else {
                sendOverride({
                    variables: {
                        jobConfigurationId: jobConfigurationId,
                        amount: amount!,
                        commissionRate: commissionRate!
                    }
                });
            }

        }
    }
    
    return (
        <>
            <FlatButton
                size="small"
                color="secondary"
                onClick={onSendOverride}
                variant="contained"
                className="fit-content margin-left-xsm"
                disabled={!forLowestPriceClaim && isNullOrUndefined(amount)}
            >Send Override</FlatButton>

            {(((salespeople?.length ?? 0) > 1) && forLowestPriceClaim) ? (
                <FormControl className="margin-bottom-md">
                    <InputLabel id="override-select-salesperson">Salesperson</InputLabel>
                    <Select
                        style={{minWidth: "10rem"}}
                        labelId="select-salesperson"
                        value={salespersonId ?? -1}
                        onChange={(e) => setSalespersonId(e.target.value as number)}
                    >
                        {salespersonId < 1 && <MenuItem disabled value={-1}>Select Salesperson</MenuItem>}
                        {salespeople?.map(sp => (
                            <MenuItem key={`salesperson-${sp.id}`} value={sp.id}>{sp.firstName} {sp.lastName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                // if this fragment isn't here, a 0 is displayed for some reason
            ) : (<></>)} 
        </>
    )
}
