import { IconButton } from "@material-ui/core";
import GestureIcon from '@mui/icons-material/Gesture';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatDrawingButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatDrawingIcon color={color}/>
        </IconButton>
    )
}

export function FlatDrawingIcon({color="black"} : {color?: string}) {
    return <GestureIcon htmlColor={color}/>
}