import clsx from "clsx";
import { CarpetCutLineRendered } from "./CarpetCutLineRendered";
import { PiecesWithRoomNames, CutBlock, EditMode, lengthToFootInch } from "./SeamPlanner";

export function CarpetCutPreviewer({
    pieces,
    blocks,
    endOfUnpaddedLength,
    carpetPadding,
    editState,
    selectedPieceIds,
    setSelectedPieceIds,
}: {
    pieces: PiecesWithRoomNames[];
    blocks: CutBlock[];
    endOfUnpaddedLength: number;
    carpetPadding: number;
    editState: EditMode;
    selectedPieceIds: number[];
    setSelectedPieceIds: (newId: number[]) => void;
}) {
    const pixelScalar = 16;

    const heightInPixel = 12 * pixelScalar;
    const rollHeight = heightInPixel + "px";

    var totalLength = 0;

    var paddingShape = [
        { x: endOfUnpaddedLength, y: 0 },
        { x: endOfUnpaddedLength, y: 12 * pixelScalar },
        { x: endOfUnpaddedLength + carpetPadding, y: 12 * pixelScalar },
        { x: endOfUnpaddedLength + carpetPadding, y: 0 },
    ];

    var rollWidth = `${(endOfUnpaddedLength + carpetPadding + 2) * pixelScalar}px`;

    return (
        <div
            id="cut-packer-previewer"
            style={{
                overflowX: "auto",
                display: "relative",
                margin: "0 20px"
            }}
        >
            <div
                style={{
                    height: rollHeight,
                    marginTop: "20px",
                    position: "relative",
                    overflowY: "visible",
                }}
            >
                <svg
                    width={rollWidth}
                    height={rollHeight}
                    style={{ position: "absolute", left: 0, top: 0, overflow: "visible" }}
                >
                    <svg
                        y={-20}
                        width={rollWidth}
                        height="20px"
                    >
                        {blocks.map((block) => {
                            totalLength += block.length;
                            const xPos = totalLength * pixelScalar;

                            const { footLength, inchLength } = lengthToFootInch(totalLength);

                            return (
                                <g key={block.blockNumber}>
                                    <line
                                        className="svg-packer-step-divider"
                                        x1={xPos}
                                        x2={xPos}
                                        y1={0}
                                        y2="20px"
                                    />
                                    <text
                                        x={xPos + 5}
                                        y={15}
                                    >
                                        {footLength}
                                        <tspan
                                            y={10}
                                            style={{
                                                textDecorationLine: "underline",
                                                fontSize: ".7em",
                                            }}
                                        >
                                            {inchLength}
                                        </tspan>
                                    </text>
                                </g>
                            );
                        })}
                    </svg>
                    <pattern
                        id="diagonalHatch"
                        width="10"
                        height="10"
                        patternTransform="rotate(40 0 0)"
                        patternUnits="userSpaceOnUse"
                    >
                        <line
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="10"
                            style={{ stroke: "#00000099", strokeWidth: 10 }}
                        />
                    </pattern>
                    {pieces.map((piece) => {
                        const { id, packerShape, stepLength, name } = piece;

                        const bottomLeftX = Math.min(...packerShape.map(p=>p.x));
                        const bottomLeftY = Math.max(...packerShape.map(p=>p.y));
                        const topRightX = Math.max(...packerShape.map(p=>p.x));
                        const topRightY = Math.min(...packerShape.map(p=>p.y));

                        const centerPoint = {
                            x: (bottomLeftX + topRightX) / 2,
                            y: (bottomLeftY + topRightY) / 2,
                        };

                        return (
                            <g key={id}>
                                <CarpetCutLineRendered
                                    piece={piece}
                                    stepLength={stepLength}
                                    pixelScalar={pixelScalar}
                                />
                                <polygon
                                    onClick={() => setSelectedPieceIds([piece.id])}
                                    className={clsx(
                                        "test-cut-preview test-cut-clickable test-cut-faint-background",
                                        {
                                            "cut-selected": selectedPieceIds.includes(piece.id),
                                        }
                                    )}
                                    points={`${piece.packerShape
                                        .map(
                                            (point) =>
                                                `${point.x * pixelScalar},${point.y * pixelScalar}`
                                        )
                                        .join(" ")}`}
                                ></polygon>
                                <text
                                    x={centerPoint.x * pixelScalar}
                                    y={centerPoint.y * pixelScalar}
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                    style={{ pointerEvents: "none", userSelect: "none" }}
                                >
                                    {name}
                                </text>
                            </g>
                        );
                    })}

                    <polygon
                        className={clsx("test-cut-waste-section")}
                        points={`${paddingShape
                            .map((point) => `${point.x * pixelScalar},${point.y * pixelScalar}`)
                            .join(" ")}`}
                    ></polygon>
                </svg>
            </div>

            <div
                style={{
                    height: rollHeight,
                    width: rollWidth,
                    pointerEvents: "none"
                }}
            />
        </div>
    );
}
