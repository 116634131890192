import { FlatCertifiedIcon } from "FlatComponents/Button/FlatCertifiedButton";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import { FlatNotCertifiedIcon } from "FlatComponents/Button/FlatNotCertifiedButton";
import { FlatLabeledCheckbox } from "FlatComponents/Inputs/FlatCheckbox";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import { ContractorInstallationCapability, InstallationCertification, namedOperations, ProductTypeOption, useGetAllInstallationCertificationsQuery, useGetProductTypeOptionsQuery, useUpsertContractorInstallationCapabilityMutation } from "generated/graphql";
import { isNotEmptyString, isNotNullOrUndefined } from "Globals/GenericValidators";
import { useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import ContractorSkillDialog, { skillToCapability } from "../ContractorSkillDialog";

export interface InstallationSkill {
    productTypeId: number;
    productType: string;
    contractorId: number;
    isActive: boolean;
    capacity: string;
    certificationIds: number[];
}

function capabilitiesToSkills(
    contractorId: number,
    productTypes: ProductTypeOption[],
    capabilities: ContractorInstallationCapability[],
    makeEmptySkills: boolean  // will create a skill for product types which the contractor does not have when true
): InstallationSkill[] {
    const skills = productTypes.map(pType => {
        const thisPtypeCapability = capabilities.find(c => c.productTypeId === pType.id);
        if (isNotNullOrUndefined(thisPtypeCapability) || makeEmptySkills) {
            return {
                productTypeId: pType.id,
                productType: pType.type,
                contractorId: contractorId,
                isActive: thisPtypeCapability?.isActive ?? false,
                capacity: thisPtypeCapability?.sqftPotential.toFixed(0) ?? "",
                certificationIds: thisPtypeCapability?.certificationIds ?? []
            };
        } else {
            return undefined
        }
    }).filter(skill => isNotNullOrUndefined(skill));


    return skills as InstallationSkill[];
}


interface SkillTableProps {
    contractorId: number;
    capabilities: ContractorInstallationCapability[];
}

export default function SkillsTable({contractorId, capabilities}: SkillTableProps) {
    const {data: productTypeData} = useGetProductTypeOptionsQuery();
    const pTypes = useMemo(() => productTypeData?.options ?? [], [productTypeData]);

    const skills: InstallationSkill[] = useMemo(() => {
        if (pTypes.length > 0) {
            return capabilitiesToSkills(contractorId, pTypes, capabilities, true);
        } else {
            return [];
        }
    }, [pTypes, capabilities, contractorId]);

    const {data: certificationData} = useGetAllInstallationCertificationsQuery();
    const availableCertifications = certificationData?.allInstallationCertifications ?? [];

    return (
        <div className="flat-font profile-left-column-table-container">
            <div>
                <div className="thin-horizontal-bar grid-colspan-1-3" />
                <p>Skills</p>
                <div className="grid-33-33-33 margin-bottom-sm">
                    {skills.map(skill => (
                        <SkillRow
                            key={`skill-${skill.productTypeId}`}
                            skill={skill}
                            availableCertifications={availableCertifications.filter(c => c.productTypeId === skill.productTypeId)}    
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

/**
 * Given a list of IDs of installation certifications and all certifications available, this function returns the
 * full certification for each id passed.
 */
function mapIdsToCertifications(certificationIds: number[], allCertifications: InstallationCertification[]): InstallationCertification[] {
    return certificationIds.map(id => allCertifications.find(cert => cert.id === id)).filter(cert => isNotNullOrUndefined(cert)) as InstallationCertification[] ;
}

export interface SkillRowProps {
    skill: InstallationSkill;
    // used for editing
    // only certs that apply to the product type this skill is for
    availableCertifications: InstallationCertification[];
}

function SkillRow({skill, availableCertifications}: SkillRowProps) {
    const [skillEditorOpen, setSkillEditorOpen] = useState(false);
    
    // capacity is undefined when the contractor doesn't possess that skill
    const contractorHasSkill = isNotEmptyString(skill.capacity);

    const [updateSkill, {loading: upserting}] = useUpsertContractorInstallationCapabilityMutation({
        onError: () => alert("Failed to update contractor skill"),
        refetchQueries: [namedOperations.Query.GetContractor]
    });

    function onActiveToggle() {
        if (contractorHasSkill) {
            // can just directly update the skill
            updateSkill({
                variables: {capability: {...skillToCapability(skill), isActive: !skill.isActive}}
            });
        } else {
            // need to open dialog because it's being added
            setSkillEditorOpen(true);
        }
    }

    const certifications: InstallationCertification[] = mapIdsToCertifications(skill.certificationIds, availableCertifications);

    return (<>
        <span className="profile-table-cell profile-table-label-cell profile-table-cell-inner">
            <FlatLabeledCheckbox
                // negation when upserting gives illusion that it's been changed, even if there's some delay in completing the mutation
                checked={upserting ? !skill.isActive : skill.isActive}
                onClick={onActiveToggle}
                label={skill.productType}
                labelLocation="right"
                justification="left"
                bold={false}
            />
        </span>
        <span className="profile-table-cell profile-table-cell-inner flex-row justify-content-center">
            <NumberFormat
                className="profile-quantity-cell"
                customInput={FlatLabeledInput}
                value={skill.capacity}
                label={skill.productType === "Carpet" ? "sy/day" : "sf/day"}
                labelLocation="right"
                bold={false}
                labelStyle={{color: "var(--flat-gray-3)"}}
                readOnly
                onClick={() => setSkillEditorOpen(true)}
            />
        </span>
        <span className="profile-table-cell profile-table-action-cell">
            {(certifications.length > 0) ? (
                <span>
                    <FlatCertifiedIcon color="var(--flat-green)"/>
                    {certifications.map(cert => (
                        <p
                            key={cert.id}
                            className="flat-font-xsm"
                        >{cert.name}</p>
                    ))}
                </span>
            ) : (
                <span>
                    <FlatNotCertifiedIcon color="var(--flat-red)"/>
                    <p className="flat-font-xsm">Not certified</p>
                </span>
            )}
            <FlatEditButton onClick={() => setSkillEditorOpen(true)} />
        </span>

        {skillEditorOpen && (
            <ContractorSkillDialog
                skill={skill}
                availableCertifications={availableCertifications}
                onClose={() => setSkillEditorOpen(false)}
            />
        )}
    </>)
}

export function BubbleSkillsTable({contractorId, capabilities}: SkillTableProps) {
    const {data: productTypeData} = useGetProductTypeOptionsQuery();
    const pTypes = useMemo(() => productTypeData?.options ?? [], [productTypeData]);

    const {data: certificationData} = useGetAllInstallationCertificationsQuery();
    const allCertifications = certificationData?.allInstallationCertifications ?? [];
    const skills: InstallationSkill[] = useMemo(() => {
        if (pTypes.length > 0) {
            return capabilitiesToSkills(contractorId, pTypes, capabilities, false);
        } else {
            return [];
        }
    }, [pTypes, capabilities, contractorId]);
    
    return (
        <div className="flat-font profile-left-column-table-container">
            <div>
                <div className="thin-horizontal-bar grid-colspan-1-3" />
                <p>Skills</p>
                <div className="grid-33-33-33 margin-bottom-sm">
                    {skills.length === 0 && <p className="margin-none">No skills listed</p>}
                    {skills.map(skill => (
                        <BubbleSkillRow
                            key={`skill-${skill.productTypeId}`}
                            skill={skill}
                            certifications={mapIdsToCertifications(skill.certificationIds, allCertifications)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

function BubbleSkillRow({skill, certifications}: {skill: InstallationSkill, certifications: InstallationCertification[]}) {
    return (<>
        <span className="profile-table-cell profile-table-label-cell profile-table-cell-inner">
            <p className="margin-vertical-xsm">{skill.productType}</p>
        </span>
        <span className="profile-table-cell profile-table-cell-inner flex-row justify-content-center">
            <p className="flat-font margin-vertical-xsm">
                {`${skill.capacity} ${skill.productType === "Carpet" ? "sy/day" : "sf/day"}`}
            </p>
        </span>
        <span className="profile-table-cell profile-table-action-cell">
            {(certifications.length > 0) ? (
                <span>
                    <FlatCertifiedIcon color="var(--flat-green)"/>
                    {certifications.map(cert => (
                        <p
                            key={cert.id}
                            className="flat-font-xsm"
                        >{cert.name}</p>
                    ))}
                </span>
            ) : (
                <span>
                    <FlatNotCertifiedIcon color="var(--flat-red)"/>
                    <p className="flat-font-xsm">Not certified</p>
                </span>
            )}
        </span>
    </>)
}