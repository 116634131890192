export const adminPath = '/admin'
export const homePath = '/home'

export const installJobPath = '/install'

export const commandCenterPath = `${adminPath}/command-center`
export const commandCenterPricingPath = `${commandCenterPath}/pricing-center`
export const recoveryCenterPath = `${adminPath}/recovery-center`
export const quoteViewerPath = `${recoveryCenterPath}/quote`
export const recoveryCenterPricingPath = `${recoveryCenterPath}/pricing-center`
export const callCenterPath = `${adminPath}/call-center`
export const callCenterExistingJobPath = `${callCenterPath}/existing`
export const callCenterScriptsPath = `${callCenterPath}/scripts`
export const callCenterSchedulerPath = `${callCenterPath}/schedule`
export const projectsPath = `${adminPath}/projects`
export const readOnlyPricingCenterPath = `${projectsPath}/pricing-center`
export const projectEditorPath = `${projectsPath}/editor`
export const pricingCenterPath = `${projectsPath}/pricing-center`
export const seamPlannerPath = `${projectsPath}/seam-planner`
export const printMeasurePath = `${projectsPath}/print`
export const sellSheetPath = `${projectsPath}/sellsheet`
export const jobScopePath = `${projectsPath}/job-scope`
export const cartPath = `${projectsPath}/cart`
export const quotePath = `${projectsPath}/quote`
export const quoteDrawingPath = `${quotePath}/drawing`
export const invoicePath = `${projectsPath}/invoice`
export const installationDetailsPath = `${projectsPath}/installation-details`
export const projectManagementPath = `${adminPath}/project-management`
export const jobTrackerPath = `${projectManagementPath}/job-tracker`
export const originalContractPath = `${jobTrackerPath}/original-contract`
export const breakdownPath = `${jobTrackerPath}/breakdown`
export const addDepositPath = `${jobTrackerPath}/add-deposit`
export const measurePath = `${jobTrackerPath}/view-measure`
export const workOrdersPath = `${jobTrackerPath}/work-orders`
export const printWorkOrderPath = `${jobTrackerPath}/print-work-order`
export const printPickSheetPath = `${jobTrackerPath}/print-pick-sheet`
export const changeOrderPath = `${jobTrackerPath}/change-order`
export const serviceOrderPath = `${jobTrackerPath}/service-order`
export const picturesPath = `${jobTrackerPath}/pictures`

export const newSAHAppointmentPath = "/schedule-sah-appointment"

export const calendarPath = `${adminPath}/calendar`

export const dialerLandingPage = `${callCenterPath}/dialer`

export const contractorsPath = `${adminPath}/contractors`;
export const contractorProfilePath = `${contractorsPath}/profile`;
export const contractorRatingSettingsPath = `${contractorsPath}/rating-settings`;
export const salesPeoplePath = `${adminPath}/sales-agents`;
export const salespersonProfilePath = `${salesPeoplePath}/profile`;
