import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, Typography } from "@material-ui/core"
import clsx from "clsx"
import CircleAddButton from "Components/Forms/Controls/CircleAddButton"
import CircleCloseButton from "Components/Forms/Controls/CircleCloseButton"
import SpacedButton from "Components/Forms/Controls/SpacedButton"
import Loading from "Components/Loading"
import { HBar } from "Components/pageSpacing"
import { ServiceProductOffering, useAddMaterialToJobServiceMutation, useGetAllServiceMaterialCategoriesForProductQuery, useGetAllServiceProductOfferingsForJobServiceQuery, useGetMetadataOfferingsForProductAndServiceQuery, useRemoveMaterialFromJobServiceMutation, useSetDefaultMaterialForJobServiceMutation } from "generated/graphql"
import { useState } from "react"

interface ProductsForServiceDialogProps {
    jobServiceId: number,
    serviceName: string,
    pricingUnit: string,
    open: boolean,
    onClose: () => void
}

export default function ProductsForServiceDialog({ jobServiceId, serviceName: jobService, pricingUnit, open, onClose }: ProductsForServiceDialogProps) {

    const { data, loading } = useGetAllServiceProductOfferingsForJobServiceQuery({ variables: { jobServiceId } })

    const serviceProductOfferings = data?.allServiceProductOfferingsForJobService ?? []

    return (
        <Dialog open={open} maxWidth='xl'>
            <DialogTitle>Assign Products to {jobService}</DialogTitle>
            <DialogContent style={{ width: "50rem", height: "25rem" }}>
                {
                    loading ?
                        <Loading /> :
                        (
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                gridTemplateRows: "1fr",
                                gridGap: "1rem"
                            }}>
                                {
                                    serviceProductOfferings.map(spo =>
                                        <ProductForServiceEditor
                                            unit={pricingUnit}
                                            key={spo.productTypeId}
                                            serviceProductOffering={spo} />)
                                }
                            </div>
                        )

                }
            </DialogContent>
            <DialogActions>
                <SpacedButton variant="outlined" color="secondary" onClick={onClose}>Close</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}

interface ProductForServiceEditorProps {
    serviceProductOffering: ServiceProductOffering,
    unit: string
}

function ProductForServiceEditor({ serviceProductOffering: spo, unit }: ProductForServiceEditorProps) {

    const { data: availableProductData, loading } = useGetAllServiceMaterialCategoriesForProductQuery({
        variables: { productTypeId: spo.productTypeId },
        fetchPolicy: "network-only"
    })
    const { data: currentProductData, refetch } = useGetMetadataOfferingsForProductAndServiceQuery({
        fetchPolicy: "network-only",
        variables: { jobServiceId: spo.jobServiceId, productTypeId: spo.productTypeId }
    })

    const [addProduct] = useAddMaterialToJobServiceMutation({ onCompleted() { refetch() } })
    const [deleteProduct] = useRemoveMaterialFromJobServiceMutation({ onCompleted() { refetch() } })
    const [updateDefault] = useSetDefaultMaterialForJobServiceMutation({ onCompleted() { refetch() } })

    const availableProductCategories = (availableProductData?.allServiceMaterialCategoriesForProduct ?? []).filter(pro => pro.priceUnit === unit)
    const currentProductOfferings = currentProductData?.metadataOfferingsForProductAndService ?? []

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function handleItemClick(materialCategoryId: number) {
        addProduct({ variables: { materialCategoryId, jobServiceId: spo.jobServiceId, productTypeId: spo.productTypeId } })
        handleClose();
    }

    const noProductText = (loading || availableProductCategories.length > 0) ? "" : "No Products Available"

    const filteredAvailableProducts = availableProductCategories.filter(pro => !currentProductOfferings.some(cur => cur.serviceMaterialCategoryId === pro.id))

    return <div className="flex-column fill-height">
        <div className="flex-row" style={{ justifyContent: "space-between", alignItems: "end" }}>
            <Typography variant="h5">{spo.productType}</Typography>
            <CircleAddButton className={clsx({ 'visibility-hidden': availableProductCategories.length === 0 })} onClick={handleClick} />
            <Typography className={clsx({ 'visibility-hidden': currentProductOfferings.length === 0 })} variant="h5" style={{ fontSize: ".7em", marginBottom: ".25em" }}>(default)</Typography>
        </div>
        <HBar />
        <div style={{ backgroundColor: "#eeeeee", minHeight: "5rem", padding: "0 .25rem" }} className="flex-grow">
            {
                loading && <Loading />
            }
            <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>{noProductText}</Typography>
            {
                currentProductOfferings.map(pro => (
                    <div key={pro.id} className="flex-row" style={{ alignItems: "center" }}>
                        <CircleCloseButton style={{ minWidth: "1.5rem" }} onClick={() => deleteProduct({ variables: { materialForServiceOfferingId: pro.id } })} />
                        <Typography>{pro.serviceMaterialCategory}</Typography>
                        <div className="flex-grow" />
                        <Checkbox
                            style={{ minHeight: "2rem", minWidth: "2rem", height: "2rem", width: "2rem" }}
                            size="small"
                            checked={pro.isDefaultMaterial}
                            onClick={e => updateDefault({ variables: { materialForServiceOfferingId: pro.id, isDefault: !pro.isDefaultMaterial } })} />
                    </div>
                ))
            }
        </div>
        <Menu
            id={`availabe-menu-${spo.productTypeId}`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {
                filteredAvailableProducts.map(op => {
                    return <MenuItem key={op.id} onClick={() => handleItemClick(op.id)}>{op.name}</MenuItem>
                })
            }
        </Menu>
    </div>
}