import Loading from "Components/Loading"
import { useGetServiceMaterialCategoryHistoryQuery } from "generated/graphql"
import { isoStringToDateTime } from "Globals/GenericConversions"
import NumberFormat from "react-number-format"
import { calculateMarkup } from "./ServiceProductPricingTableRow"

export default function ServiceProductHistoryTable({ serviceMaterialCategoryProductPricingId }: { serviceMaterialCategoryProductPricingId: number }) {

    const { data, loading } = useGetServiceMaterialCategoryHistoryQuery({
        variables: { serviceMaterialCategoryProductPricingId }
    })

    const anyData = (data?.serviceMaterialCategoryHistory.length ?? 0) > 0

    return (
        <tr>
            <td colSpan={6} align="right">
                <div style={{ width: "30rem" }}>
                    {
                        loading ?
                            <Loading /> :
                            anyData ?
                                <table>
                                    <thead>
                                        <tr style={{ textAlign: "center" }}>
                                            <th align="center">End Date</th>
                                            <th align="center">Old Price</th>
                                            <th align="center">Old Cost</th>
                                            <th align="center">Markup %</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.serviceMaterialCategoryHistory.map(d => <ServiceProductHistoryTableRow key={d.id} {...d} />)}
                                    </tbody>
                                </table> :
                                <div style={{ textAlign: "center" }}>No Changes to Price</div>
                    }
                </div>
            </td>
        </tr>
    )
}

interface ServiceProductHistoryTableRowProps {
    changeTime: string,
    oldPrice: number,
    oldCost: number
}

function ServiceProductHistoryTableRow({ changeTime, oldPrice, oldCost }: ServiceProductHistoryTableRowProps) {

    const markup = calculateMarkup(oldPrice, oldCost)

    return (
        <tr>
            <td align="center">{isoStringToDateTime(changeTime)}</td>
            <td align="center">
                <NumberFormat
                    value={oldPrice}
                    prefix="$"
                    thousandSeparator={true}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale />
            </td>
            <td align="center">
                <NumberFormat
                    value={oldCost}
                    prefix="$"
                    thousandSeparator={true}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale />
            </td>
            <td align="center">
                {markup?.toFixed(0) ?? "-"}%
            </td>
        </tr>
    )
}

