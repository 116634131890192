import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import { FlatAlertIcon } from "FlatComponents/Button/FlatAlertButton";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import FlatSwitch from "FlatComponents/Inputs/FlatSwitch";
import { ContractorHelper, namedOperations, useUpdateWorkerIsActiveMutation } from "generated/graphql";
import { makeEmptyContractorHelperStartingToday } from "Globals/DataStructures/EmptyDataStructures";
import { dateTimeStrToMdy } from "Globals/DateAndTimeHelpers";
import { isDocumentOverdue, isNotNullOrUndefined } from "Globals/GenericValidators";
import { formatNameStringFirstLast, formatPhoneNumber } from "Globals/StringFormatting";
import { useState } from "react";
import ContractorHelperDialog from "../ContractorHelperDialog";

export default function HelpersTable({helpers, contractorId}: {helpers: ContractorHelper[], contractorId: number}) {
    const [dialogOpen, setDialogOpen] = useState(false);

    return (<>
        <div className="flat-font profile-right-column-table-container">
            <div className="thin-horizontal-bar grid-colspan-1-3" />
            <p>Helpers</p>
            <div className="margin-bottom-sm padding-left-sm flex-column">
                <span className="profile-table-cell profile-table-add-row">
                    <FlatAddButton onClick={() => setDialogOpen(true)}/>
                </span>
                {helpers.length === 0 && <p>Contractor has no helpers</p>}
                {helpers.map(h => <HelperSubtable key={h.id} helper={h}/>)}
            </div>
        </div>

        {dialogOpen && (
            <ContractorHelperDialog 
                onClose={() => setDialogOpen(false)}
                helper={{...makeEmptyContractorHelperStartingToday(), contractorId: contractorId}}
            />
        )}
    </>)
}

function HelperSubtable({helper}: {helper: ContractorHelper}) {
    const name = formatNameStringFirstLast({firstName: helper.firstName, lastName: helper.lastName});
    const bgCheck = (helper.backgroundChecks.length > 0) ? helper.backgroundChecks[0] : undefined;
    const bgCheckNum = bgCheck?.number ? `#${bgCheck!.number}` : "-";
    const bgCheckType =  isNotNullOrUndefined(bgCheck) ? (bgCheck?.isInternal ? "Internal" : "3rd Party") : undefined;
    const bgCheckDate = bgCheck?.expirationDate ? `exp. ${dateTimeStrToMdy(bgCheck!.expirationDate)}` : "";
    const bgCheckOverdue = isNotNullOrUndefined(bgCheck) ? isDocumentOverdue(bgCheck!.expirationDate) : false;

    const [setActive, {loading: updatingWorkerActive}] = useUpdateWorkerIsActiveMutation({
        onError: () => alert("Could not update worker active status"),
        refetchQueries: [namedOperations.Query.GetContractor]
    });

    const [dialogOpen, setDialogOpen] = useState(false);

    return (<>
        <div className="helper-subtable">
            <div className="flex-row flex-space-between">
                <span className="flex-row flex-gap-sm">
                    <p className="bold-text">{name}</p>
                    <FlatEditButton size="small" onClick={() => setDialogOpen(true)}/>
                </span>
                <FlatSwitch
                    checked={updatingWorkerActive ? !helper.isActive : helper.isActive}
                    onClick={() => setActive(
                        { variables: { workerId: helper.id!, isActive: !helper.isActive } }
                    )}
                />
            </div>
            <div className="grid-40-60">
                <p>Phone</p>
                <p className="less-bold-text">{formatPhoneNumber(helper.phone)}</p>
                <p>License #</p>
                <p className="less-bold-text">{helper.licenseNumber ?? "-"}</p>
                <p>Background Check</p>
                <span className="flex-row align-items-center flex-gap-xsm">
                    {isNotNullOrUndefined(bgCheckType) && (
                        <p className="less-bold-text">{bgCheckType}</p>
                    )}
                    <p className="less-bold-text">{bgCheckNum}</p>
                    <p className="less-bold-text">{bgCheckDate}</p>
                    {bgCheckOverdue && (
                        <FlatAlertIcon color="var(--flat-orange)"/>
                    )}
                </span>
            </div>
        </div>

        {dialogOpen && (
            <ContractorHelperDialog 
                onClose={() => setDialogOpen(false)}
                helper={helper}
            />
        )}
    </>)
}