import clsx from "clsx";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { FlatLabeledInputBase } from "./flatInputUtils";

export interface FlatInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    error?: boolean;
}

export default function FlatInput({error=false, disabled=false, className, ...props}: FlatInputProps) {
    return (
        <input
            className={clsx("flat-input flat-font", className, {
                "flat-input-error": error, "flat-input-disabled": disabled
            })}
            {...props}
            disabled={disabled}
        />
    )
}

export type FlatLabeledInputProps = FlatLabeledInputBase<FlatInputProps>;

export function FlatLabeledInput({
    label,
    bold=true,
    labelLocation="left",
    labelSize="standard",
    labelStyle,
    containerProps,
    ...inputProps
}: FlatLabeledInputProps) {
    const containerClassName = clsx("align-items-center", "flex-gap-xsm", containerProps?.className,
        { "flex-row": labelLocation === "left", "flex-row-reverse": labelLocation === "right" }
    );
    const labelClassName = clsx("margin-none", {
        "flat-font-xsm": labelSize === "xsm",
        "flat-font-sm": labelSize === "sm",
        "flat-font": labelSize === "standard",
        "flat-font-md": labelSize === "md",
        "flat-font-lg": labelSize === "lg",
        "bold-text": bold
    });

    // remove className from the spread props because it will overwrite the directly passed className prop
    const {className, ...cleanedContainerProps} = {...containerProps};

    return (
        <span className={containerClassName} {...cleanedContainerProps}>
            <p
                className={labelClassName}
                style={{...labelStyle}}
            >{label}</p>
            <FlatInput {...inputProps}/>
        </span>
    )
}