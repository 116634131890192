import { useHistory } from "react-router-dom";
import { AppBar, Button, Toolbar, IconButton, MenuItem, Popover } from "@material-ui/core";
import { useState } from "react";
import NavbarRoutes, { RouteNamePair } from "Components/Navbar/NavbarRoutes";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "Assets/Images/WorldOfFloorsLogoSmall.png";
import { signOut } from "Globals/FirebaseUtils";
import H1 from "FlatComponents/Visual/H1";
import { ThemeProvider } from "@material-ui/core/styles";
import { redTheme } from "App";
import { HBar } from "Components/pageSpacing";
import { useGetAuthenticatedWorkerQuery } from "generated/graphql";
import clsx from "clsx";
import { formatNameStringFirstLast } from "Globals/StringFormatting";

interface FlatNavbarProps {
    customRoutes?: RouteNamePair[];
    title?: string | JSX.Element;
    menuContent?: JSX.Element;
}

export default function FlatNavbar({ customRoutes, title, menuContent }: FlatNavbarProps) {
    const history = useHistory();

    const { data } = useGetAuthenticatedWorkerQuery();

    const { firstName, lastName } = data?.authenticatedWorker ?? { firstName: "", lastName: "" };

    const hasUsername = firstName !== "";
    let nameString = "";
    let initials = "";
    if (hasUsername) {
        initials = getFirstLetter(firstName) + getFirstLetter(lastName);
        nameString = formatNameStringFirstLast({ firstName, lastName });
    }

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const clickButton = (route: string) => {
        handleMenuClose();
        history.push(route);
    };

    function NavbarRouteToButton(routeNamePair: RouteNamePair) {
        return (
            <Button
                key={routeNamePair.name}
                variant="contained"
                color="primary"
                style={{ margin: ".25rem 0" }}
                onClick={(_) => clickButton(routeNamePair.route)}
            >
                {routeNamePair.name}
            </Button>
        );
    }

    var routes = customRoutes ?? NavbarRoutes();

    return (
        <ThemeProvider theme={redTheme}>
            <AppBar
                style={{
                    overflow: "hidden",
                    color: "unset",
                    backgroundColor: "white",
                }}
                position="sticky"
                elevation={0}
            >
                <Toolbar
                    disableGutters
                    style={{
                        zIndex: "inherit",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingRight: "1rem",
                        alignItems: "center"
                    }}
                >
                    <div className="flex-grow">
                        <div
                            className="flat-navbar-logo"
                            style={{
                                backgroundImage: `url(${logo})`,
                            }}
                        />
                    </div>
                    <H1>{title}</H1>
                    <div
                        className="flex-row flex-grow"
                        style={{ alignItems: "center", justifyContent: "end" }}
                    >
                        <div
                            className={clsx("flat-username-bubble flat-font-md flat-font-bold", {
                                "empty-bubble": !hasUsername,
                            })}
                        >
                            {initials}
                        </div>
                        <IconButton
                            edge="end"
                            onClick={handleMenuClick}
                            color="primary"
                            aria-label="menu"
                            className="flat-navbar-menu-button"
                        >
                            <MenuIcon
                                fontSize="large"
                                style={{ color: "var(--flat-red)" }}
                            />
                        </IconButton>
                    </div>
                    <Popover
                        id="navbar-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                        <div
                            className="flat-content"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "12rem",
                                alignItems: "center",
                            }}
                        >
                            <div className="flat-font-sm flat-username-label">
                                Hello, {hasUsername ? nameString : "Guest User"}!
                            </div>
                            <HBar
                                style={{ backgroundColor: "var(--flat-gray-3)", margin: ".5rem 0" }}
                            />
                            {routes.map(NavbarRouteToButton)}
                            <HBar
                                style={{ backgroundColor: "var(--flat-gray-3)", margin: ".5rem 0" }}
                            />
                            {menuContent !== undefined && <>{menuContent}</>}
                            <MenuItem
                                key={`navbar-logout`}
                                onClick={signOut}
                            >
                                Logout
                            </MenuItem>
                        </div>
                    </Popover>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

function getFirstLetter(name: string): string {
    return name.length > 0 ? name[0].toUpperCase() : "";
}
