import { Calendar, Day, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select } from "@material-ui/core";
import { namedOperations, GetFilteredBlockedTimeSlotsQueryVariables, useDeleteBlockedTimeSlotsMutation, useGetAllMarketsQuery, useGetFilteredBlockedTimeSlotsQuery } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import AtomicTimeSlot from "../SAHScheduling/sched_utils/AtomicTimeSlot";
import { generateStartAndEndTimes, timeToAmPmString } from "../SAHScheduling/sched_utils/timeSlotUtils";
import BlockedAppointmentTimesTable from "./BlockedAppointmentTimesTable";

interface OnSearchEntireDayChangeParams {
    preToggleSearchEntireDay: boolean;
    setSearchEntireDay: (block: boolean) => void;
    setStartTime: (time: Date | null) => void;
    setEndTime: (time: Date | null) => void;
}

function onSearchEntireDayChange({preToggleSearchEntireDay, setSearchEntireDay, setStartTime, setEndTime}: OnSearchEntireDayChangeParams): void {
    if (preToggleSearchEntireDay) {  // entire day was being searched BEFORE value was toggled
        // set null; user must now select start and end time explicitly
        setStartTime(null);
        setEndTime(null);
    } else {
        setStartTime(new Date(0, 0, 0, 0, 0));
        setEndTime(new Date(0, 0, 0, 23, 59));
    }
    setSearchEntireDay(!preToggleSearchEntireDay);
}

interface FilterFunctionParams {
    selectedDates: Day[];
    selectedMarketId: number | undefined;
    startTime: Date | null | undefined;
    endTime: Date | null | undefined;
}

function canRunGetFilteredTimeSlotsQuery(values: FilterFunctionParams): boolean {
    let datesOk = isNotNullOrUndefined(values.selectedDates) && (values.selectedDates.length > 0);
    //let selectedMarketIdOk = isNotNullOrUndefined(values.selectedMarketId) && (values.selectedMarketId >= 0);

    let timeRangeOk: boolean;
    if (isNotNullOrUndefined(values.startTime) && isNotNullOrUndefined(values.endTime)) {
        // it is safe to assert as Date due to check above
        timeRangeOk = (values.endTime as Date).getTime() > (values.startTime as Date).getTime();
    } else {
        timeRangeOk = false;
    }

    return datesOk //&& selectedMarketIdOk 
    && timeRangeOk;
}

const {startTimes: possibleStartTimes, endTimes: possibleEndTimes} = generateStartAndEndTimes(AtomicTimeSlot.nineToNine(), 30);

function getFilterVariables(values: FilterFunctionParams) : GetFilteredBlockedTimeSlotsQueryVariables {
    // no validation needs to be done because the "skip" property on useGetFilteredBlockedTimeSlotsQuery
    // only allows the query to run after running canRunGetFilteredTimeSlotsQuery returns true
    
    let queryDays = values.selectedDates.map(date => `${date.year}-${date.month}-${date.day}`);

    let startHour = values.startTime?.getHours() as number;
    let startMinute = values.startTime?.getMinutes() as number;
    let endHour = values.endTime?.getHours() as number;
    let endMinute = values.endTime?.getMinutes() as number;
    let queryTs = new AtomicTimeSlot(startHour, startMinute, endHour, endMinute);

    return {
        dates: queryDays,
        marketId: values.selectedMarketId,
        ts: queryTs
    }; 
}

export default function ViewBlockedAppointmentTimesPage() {
    // filter params
    const [selectedDates, setSelectedDates] = useState<Day[]>([]);
    const [startTime, setStartTime] = useState<Date | null | undefined>(new Date(0, 0, 0, 0, 0));
    const [endTime, setEndTime] = useState<Date | null | undefined>(new Date(0, 0, 0, 23, 59));
    const [selectedMarketId, setSelectedMarketId] = useState<number | undefined>(undefined);

    // control state
    const [searchEntireDay, setSearchEntireDay] = useState(true);

    // queries
    const { data } = useGetAllMarketsQuery();
    
    const {data: filteredData} = useGetFilteredBlockedTimeSlotsQuery({
        variables: getFilterVariables({selectedDates, selectedMarketId, startTime, endTime}),
        skip: !canRunGetFilteredTimeSlotsQuery({selectedDates, selectedMarketId, startTime, endTime}),
        fetchPolicy: "no-cache"
    });
    
    // mutation for removing selected rows
    const [deleteBlockedTimeSlotsMutation] = useDeleteBlockedTimeSlotsMutation({
        refetchQueries: [namedOperations.Query.GetFilteredBlockedTimeSlots],
        awaitRefetchQueries: true
    })

    const allMarkets = data?.allMarkets ?? []
    
    return (
        <div className="flex-column-center flex-centered">
            <h1>View Blocked Appointment Times</h1>
            <div className="padding-sm">
                <Calendar
                    value={selectedDates}
                    onChange={setSelectedDates}
                    minimumDate={utils('en').getToday()}
                />
            </div>

            <FormControl className="margin-bottom-md">
                <InputLabel id="select-market-label">Filter by Market</InputLabel>
                <Select
                    style={{minWidth: "10rem"}}
                    labelId="select-market-label"
                    value={selectedMarketId ?? -1}
                    onChange={(e) => {
                        const newId = e.target.value as number
                        setSelectedMarketId(newId === -1 ? undefined : newId);
                    }}
                >
                    <MenuItem value={-1}>All Markets</MenuItem>
                    {allMarkets?.map(mkt => (
                        <MenuItem key={`market-option-${mkt.id}`} value={mkt.id}>{mkt.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormGroup>
                <FormControlLabel 
                    className={!searchEntireDay ? "margin-bottom-sm" : ''}
                    control={<Checkbox checked={searchEntireDay}/>}
                    label="Search Entire Day"
                    onChange={() => onSearchEntireDayChange({preToggleSearchEntireDay: searchEntireDay, setSearchEntireDay, setStartTime, setEndTime})} 
                />
            </FormGroup>

            {
                !searchEntireDay && (
                    <>
                        <FormControl className="margin-bottom-md">
                            <InputLabel id="select-start-time-label">Search Start Time</InputLabel>
                            <Select
                                style={{minWidth: "10rem"}}
                                labelId="select-start-time-label"
                                value={startTime?.getTime() ?? ""}
                                onChange={(e) => setStartTime(new Date(e.target.value as number))}
                            >
                                {possibleStartTimes.map(time => (
                                    <MenuItem key={`start-time-${time}`} value={time.getTime()}>{timeToAmPmString(time)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        
                        
                        <FormControl className="margin-bottom-md">
                            <InputLabel id="select-end-time-label">Search End Time</InputLabel>
                            <Select
                                style={{minWidth: "10rem"}}
                                labelId="select-end-time-label"
                                value={endTime?.getTime() ?? ""}
                                onChange={(e) => setEndTime(new Date(e.target.value as number))}
                            >
                                {possibleEndTimes.map(time => (
                                    <MenuItem key={`end-time-${time}`} value={time.getTime()}>{timeToAmPmString(time)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                )
            }

            {
                (isNotNullOrUndefined(startTime) && isNotNullOrUndefined(endTime) && ((endTime as Date).getTime() <= (startTime as Date).getTime())) &&
                <p className="error-text">End time must be later than start time.</p>
            }

            {
                isNotNullOrUndefined(filteredData?.filteredBlockedTimeSlots) && 
                    <BlockedAppointmentTimesTable 
                        retrievedTimeSlots={filteredData?.filteredBlockedTimeSlots ?? []}
                        deleteBlockedTimeSlotsMutation={deleteBlockedTimeSlotsMutation}
                        allMarkets={allMarkets}
                    />
            }
        </div>
    )
}