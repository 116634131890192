

interface GalleryProps {
    children: React.ReactChild[]
    rowMax?: number,
    mobile?: boolean 
}

export default function Gallery({ rowMax, mobile, children }: GalleryProps) {
    const hasRowMax = rowMax !== undefined
    const rows = rowMax ?? 1

    // This might yield better centering
    // padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    // box-sizing: content-box; /* So the width will be 100% + 17px */

    // Width is set to 61 rem because the width of the Gallery Images is 20. 
    // The added one 1 is to account for the scrollbar popping up.
    return (
        <div style={{ overflow: hasRowMax ? "hidden" : undefined }}>
            <div className="flex-row" style={{
                minHeight: "15rem", width: mobile ? "100vw" : "61rem", maxHeight: hasRowMax ? `${(rows ?? 0) * 15}rem` : undefined,
                flexWrap: "wrap", overflowY: "auto"
            }}>
                {children}
            </div>
        </div>
    )
}