import { DayRange, DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import { Tooltip } from "@mui/material";
import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatChevronButton from "FlatComponents/Button/FlatChevronButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { useUpsertWorkerHistoryMutation, WorkerHistoryDates, WorkerHistoryDatesInput, WorkerHistoryInput } from "generated/graphql";
import { dateTimeStrToDay, dayToIso, dayValueToMdy, SingleDateCalendarDialog, todayAsDay } from "Globals/DateAndTimeHelpers";
import { isEmptyString, isNotNullOrUndefined, isNullOrUndefined, areWorkerHistoryDatesValid } from "Globals/GenericValidators";
import { useState } from "react";
import { WorkerHistoryTableProps } from "../Tables/WorkerHistoryTable";

interface WorkerHistoryDialogProps extends WorkerHistoryTableProps {
    workerId: number;
    onClose: () => void;
}

export default function WorkerHistoryDialog({
    workerName,
    history,
    onClose,
    toRefetch: toRefetchOnUpdate
}: WorkerHistoryDialogProps) {
    const workerId = history?.id ?? -1;
    const headerText = `${workerName}'s History`;
    const [ranges, setRanges] = useState<EditableWorkerHistoryDateRange[]>(historyDatesToDayRanges(history?.dates));

    function addNewRange() {
        const newId = Math.min(-1, Math.min(...ranges.map(row => row.rangeId)) - 1);
        const newRow: EditableWorkerHistoryDateRange = {rangeId: newId, range: {from: todayAsDay(), to: undefined}};
        setRanges([...ranges, newRow]);
    }

    function removeRange(rangeId: number) {
        setRanges(ranges.filter(r => r.rangeId !== rangeId));
    }

    function setStartDate(rangeId: number, date: DayValue) {
        const rangeIdx = ranges.findIndex(r => r.rangeId === rangeId);
        const oldRange = ranges[rangeIdx];
        const newRange: EditableWorkerHistoryDateRange = {
            ...oldRange,
            range: {
                ...oldRange.range,
                from: date
            }
        };

        const newRanges = [...ranges];
        newRanges.splice(rangeIdx, 1, newRange);
        setRanges(newRanges);
    }

    function setEndDate(rangeId: number, date: DayValue) {
        const rangeIdx = ranges.findIndex(r => r.rangeId === rangeId);
        const oldRange = ranges[rangeIdx];
        const newRange: EditableWorkerHistoryDateRange = {
            ...oldRange,
            range: {
                ...oldRange.range,
                to: date
            }
        };

        const newRanges = [...ranges];
        newRanges.splice(rangeIdx, 1, newRange);
        setRanges(newRanges);
    }

    // returns undefined when the data isn't ready for submission
    function validateAndPrepareData(): WorkerHistoryInput | undefined {
        const preparedData: WorkerHistoryInput = dayRangesToWorkerHistory(workerId, ranges);
        if (areWorkerHistoryDatesValid(preparedData.dates, true)) {
            return preparedData;
        } else {
            return undefined;
        }
    }

    const [updateHistory, {loading: updating}] = useUpsertWorkerHistoryMutation({
        onError: () => alert("Could not update worker history"),
        refetchQueries: toRefetchOnUpdate
    });

    function onSubmit() {
        const historyData = validateAndPrepareData();
        if (isNotNullOrUndefined(historyData)) {
            updateHistory({
                variables: { history: historyData! },
                onCompleted: onClose
            });
        }
    }

    const submitButton = (
        <FlatButton
            key={1}
            onClick={onSubmit}
            color="secondary"
            variant="contained"
            disabled={updating}
        >Submit</FlatButton>
    );
    
    return (
        <FlatDialog 
            sectionProps={{
                header: headerText,
                endAdornment: <FlatAddButton onClick={addNewRange}/>
            }}
            dialogProps={{
                open: true,
                onClose: onClose,
                maxWidth: "md"
            }}
            actionButtons={[submitButton]}
        >
            <div className="grid-50-50">
                {ranges.map(range => (
                    <EditableHistoryDialogRow
                        key={range.rangeId}
                        range={range.range}
                        setStartDate={(newDay: DayValue) => setStartDate(range.rangeId, newDay)}                  
                        setEndDate={(newDay: DayValue) => setEndDate(range.rangeId, newDay)}
                        removeRange={() => removeRange(range.rangeId)}
                    />
                ))}
            </div>
        </FlatDialog>
    )
}

interface EditableHistoryDialogRowProps {
    range: EditableWorkerHistoryDateRange["range"];
    setStartDate: (start: DayValue) => void;
    setEndDate: (start: DayValue) => void;
    removeRange: () => void;
}

/**
 * Designed to be placed inside of a container with the .grid-50-50 class. 
 */
export function EditableHistoryDialogRow({range, setStartDate, setEndDate, removeRange}: EditableHistoryDialogRowProps) {
    const [startDateOpen, setStartDateOpen] = useState(false);
    const [endDateOpen, setEndDateOpen] = useState(false);
    const startDate = range.from;
    const endDate = range.to;

    return (<>
        <span className="flex-row flex-gap-xsm">
            <Tooltip title="Remove this date range">
                <FlatDeleteButton onClick={removeRange}/>
            </Tooltip>
            <FlatLabeledInput
                label="Start Date"
                value={dayValueToMdy(startDate)}
                readOnly
                onClick={() => setStartDateOpen(true)}
            />
        </span>
        <span className="flex-row flex-gap-xsm">
            <FlatLabeledInput
                label="End Date"
                value={dayValueToMdy(endDate, "Current")}
                readOnly
                onClick={() => setEndDateOpen(true)}
            />
            <Tooltip title="Mark as current">
                <FlatChevronButton onClick={() => setEndDate(undefined)}/>
            </Tooltip>
        </span>

        <SingleDateCalendarDialog
            selectedDate={startDate}
            setSelectedDate={setStartDate}
            open={startDateOpen}
            setOpen={setStartDateOpen}
            blockPastDays={false}
        />
        <SingleDateCalendarDialog
            selectedDate={endDate}
            setSelectedDate={setEndDate}
            open={endDateOpen}
            setOpen={setEndDateOpen}
            blockPastDays={false}
        />
    </>)
}

export interface EditableWorkerHistoryDateRange {
    rangeId: number;
    range: DayRange;
}

export function historyDatesToDayRanges(dates?: WorkerHistoryDates[]): EditableWorkerHistoryDateRange[] {
    return dates?.map(d => {
        const startDay: DayValue = isEmptyString(d.startDate) ? undefined : dateTimeStrToDay(d.startDate);
        const endDay: DayValue = isNullOrUndefined(d.endDate) ? undefined : dateTimeStrToDay(d.endDate!);
        return {
            rangeId: d.id,
            range: {
                from: startDay,
                to: endDay
            }
        }
    }) ?? [];
}

function dayRangesToWorkerHistory(workerId: number, ranges: EditableWorkerHistoryDateRange[]): WorkerHistoryInput {
    const dates: WorkerHistoryDatesInput[] = ranges.map(range => ({
        id: range.rangeId,
        startDate: dayToIso(range.range.from!),
        endDate: isNullOrUndefined(range.range.to) ? undefined : dayToIso(range.range.to!)
    }));

    const history: WorkerHistoryInput = {
        id: workerId,
        dates: dates
    };
    
    return history;
}
