import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";

// export function useTrackInterfaceHasAllKeys<T>(objectToTrack: T, onChange?: (fullyFilledOut: boolean) => void, ...keys: (keyof T)[]) {
//     const [isFullyFilledOut, setIsFullyFilledOut] = useState(false);
//     useEffect(
//         () => {
//             const hasAllKeys = interfaceHasAllKeys(objectToTrack, ...keys);
//             if (hasAllKeys !== isFullyFilledOut) {
//                 setIsFullyFilledOut(hasAllKeys)
//                 onChange?.(hasAllKeys);
//             }
//         },
//         [objectToTrack]); //Look at lint error for this. May need useCallback
// }

// export function useFocusRef() {
//     const ref = useRef<HTMLElement>(null);
//     const focus = useCallback(() => { setTimeout(() => ref?.current?.focus()) }, [ref]);
//     return [ref, focus] as const;
// }

// export function useDebounceState<T>(setter: (value: T) => void, value: T, debounceInterval?: number,) {

//     const [state, setState] = useState<T>(value);

//     const debounceCallback = useMemo(() => debounce(newValue => setter(newValue), debounceInterval ?? 1000), [setter]);
//     const outputCallback = useCallback((newValue: T) => { debounceCallback(newValue); setState(newValue) }, [setter, debounceCallback]);

//     useEffect(() => {
//         setState(value);
//         debounceCallback.cancel();
//     }, [value]);

//     return [state, outputCallback] as const;
// }

export const idUrlMask = "/:id?/:print?"
export function useNumericIdParam(): { id: number | undefined, print: boolean } {
    //TODO: Replace with URLSearchParams() https://medium.com/@kate.pavlova/how-to-set-or-get-query-parameters-in-react-router-7d6e2440ede8
    const { id, print } = useParams<{ id: string | undefined, print: string | undefined }>();
    const numberId = Number(id);
    const printValue = print !== undefined && print === "true"
    if (id === undefined || isNaN(numberId)) return { id: undefined, print: printValue };
    else return { id: numberId, print: printValue }
}

export const twoIdUrlMask = "/:id1?/:id2?";
export function useTwoNumericIdParams(): { id1: number, id2: number } {
    //TODO: Replace with URLSearchParams() https://medium.com/@kate.pavlova/how-to-set-or-get-query-parameters-in-react-router-7d6e2440ede8
    const { id1, id2 } = useParams<{ id1: string, id2: string }>();
    const numberId1 = Number(id1);
    const numberId2 = Number(id2);

    return { id1: numberId1, id2: numberId2 };
}

export const phoneNumberUrlMask = "/:phone"
export function usePhoneNumberParam(): { phoneNumber: string } {
    //TODO: Replace with URLSearchParams() https://medium.com/@kate.pavlova/how-to-set-or-get-query-parameters-in-react-router-7d6e2440ede8
    const { phone } = useParams<{ phone: string }>();
    return { phoneNumber: phone.replace(/\D/g, '') }
}

export function buildAppendedId(id: number | string | undefined): string {
    return `/${id}`
}

export function useNumberBackedString(value: number | undefined, setValue: (value: number) => void, options?: { allowNaN?: boolean }) {

    const { allowNaN } = options ?? {};

    const [state, setState] = useState<string | undefined>(value?.toString());

    useEffect(() => {
        setState(value?.toString());
    }, [value]);

    const setStateCallback = useCallback((newValue: string | undefined) => {
        setState(newValue);

        if (newValue) {
            let numberVal = parseFloat(newValue);
            if (!isNaN(numberVal) || allowNaN) {
                setValue(numberVal);
            }
        }

    }, [setValue, allowNaN]);

    return [state, setStateCallback] as const;
}
