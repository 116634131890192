import { Button, Drawer } from "@material-ui/core";
import NavbarPage from "Components/Page";
import { useState } from "react";
import LaborRatesView from "./Components/LaborRatesView";
import LaborRateSpecifier from "./NewLaborRateBuilder";


export default function LaborRatesPage() {
    const [open, setOpen] = useState(false);

    const height = "calc(100vh - 15rem)"

    return (
        <NavbarPage title="Labor Rates" style={{ overflow: "hidden", display: "flex" }}>
            <div className="flex-column" style={{ paddingBottom: "2rem" }}>
                <LaborRatesView height={(open) ? `calc(${height} - 9rem)` : height} />
                <div hidden={open} className="flex-column flex-centered h-3r" style={{ justifyContent: "center" }}>
                    <Button
                        variant="contained"
                        className="margin-side-xsm"
                        onClick={() => setOpen(!open)}>
                        {(open) ? "Close Editor" : "Add New Labor"}
                    </Button>
                </div>
            </div>

            <Drawer
                variant="persistent"
                anchor="bottom"
                open={open}
            >
                <div className="flex-row" style={{ height: "12rem", maxHeight: "12rem", alignItems: "flex-start" }}>
                    <div className="flex-grow padding-sm" style={{ height: "11rem", maxHeight: "11rem" }}>
                        <LaborRateSpecifier />
                    </div>
                    <Button className="margin-xsm" variant="contained" onClick={() => setOpen(false)}>Close</Button>
                </div>
            </Drawer>
        </NavbarPage>
    )
}