import { useGetNewestContractorRatingSummaryQuery } from "generated/graphql";
import { dateTimeStrToMdy } from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { formatAsIntOrNDecimals } from "Globals/StringFormatting";
import RatingPill from "../../GeneralProfileComponents/RatingPill";

export default function ContractorRatingHistoryTable({contractorId}: {contractorId: number}) {
    const {data: summaryData} = useGetNewestContractorRatingSummaryQuery({
        variables: {contractorId: contractorId},
        skip: contractorId < 1,
    });
    const summary = summaryData?.newestContractorRatingSummary;
    const numAppts = summaryData?.newestContractorRatingSummary?.numAppointments;
    const numServices = summaryData?.newestContractorRatingSummary?.numServices;

    // avoiding division by 0
    const calledPctg = ((numAppts ?? 0) === 0) ? "-" : `${formatAsIntOrNDecimals(summary!.calledInAm / numAppts! * 100, 1)}%`;
    const checkedInPctg = ((numAppts ?? 0) === 0) ? "-" : `${formatAsIntOrNDecimals(summary!.checkedIn / numAppts! * 100, 1)}%`;
    const onTimePctg = ((numAppts ?? 0) === 0) ? "-" : `${formatAsIntOrNDecimals(summary!.onTime / numAppts! * 100, 1)}%`;
    const collectedCodPctg = ((numAppts ?? 0) === 0) ? "-" : `${formatAsIntOrNDecimals(summary!.collectedCod / numAppts! * 100, 1)}%`;
    const completedJobPctg = ((numAppts ?? 0) === 0) ? "-" : `${formatAsIntOrNDecimals(summary!.completedJob / numAppts! * 100, 1)}%`;
    const completionCertSignedPctg = ((numAppts ?? 0) === 0) ? "-" : `${formatAsIntOrNDecimals(summary!.completionCertificateSigned / numAppts! * 100, 1)}%`;
    const servicesCompletedOnTimePctg = ((numServices ?? 0) === 0) ? "-" : `${formatAsIntOrNDecimals(summary!.servicesCompletedOnTime / numServices! * 100, 1)}%`;

    return (
        <div className="flat-font profile-left-column-table-container">
            {isNullOrUndefined(summary) ? (
                <p className="flat-font">No summary found</p>
            ) : (
                <div>
                    <div className="thin-horizontal-bar grid-colspan-1-3" />
                    <div className="flex-column flex-gap-xxsm">
                        <p>Rating History</p>
                        <p className="flat-font" style={{color: "var(--flat-gray-4", marginBottom: ".1rem"}}>{dateTimeStrToMdy(summary!.date)}</p>
                        <RatingPill rating={summary!.rating}/>
                    </div>
                    <div className="grid-60-40 margin-bottom-sm fit-content-height">
                        <span className="profile-table-cell profile-table-label-cell">
                            Called AM
                        </span>
                        <span className="profile-table-cell flex-row flex-space-between">
                            <span className="flex-row">
                                <p className="bold-text flat-gray-4">{summary!.calledInAm}</p>
                                <p className="flat-gray-4">/{numAppts}</p>
                            </span>
                            <p className="flat-gray-4">{calledPctg}</p>
                        </span>

                        <span className="profile-table-cell profile-table-label-cell">
                            Checked In
                        </span>
                        <span className="profile-table-cell flex-row flex-space-between">
                            <span className="flex-row">
                                <p className="bold-text flat-gray-4">{summary!.checkedIn}</p>
                                <p className="flat-gray-4">/{numAppts}</p>
                            </span>
                            <p className="flat-gray-4">{checkedInPctg}</p>
                        </span>

                        <span className="profile-table-cell profile-table-label-cell">
                            Appt. On Time
                        </span>
                        <span className="profile-table-cell flex-row flex-space-between">
                            <span className="flex-row">
                                <p className="bold-text flat-gray-4">{summary!.onTime}</p>
                                <p className="flat-gray-4">/{numAppts}</p>
                            </span>
                            <p className="flat-gray-4">{onTimePctg}</p>
                        </span>

                        <span className="profile-table-cell profile-table-label-cell">
                            Collected COD
                        </span>
                        <span className="profile-table-cell flex-row flex-space-between">
                            <span className="flex-row">
                                <p className="bold-text flat-gray-4">{summary!.collectedCod}</p>
                                <p className="flat-gray-4">/{numAppts}</p>
                            </span>
                            <p className="flat-gray-4">{collectedCodPctg}</p>
                        </span>

                        <span className="profile-table-cell profile-table-label-cell">
                            Completed Job
                        </span>
                        <span className="profile-table-cell flex-row flex-space-between">
                            <span className="flex-row">
                                <p className="bold-text flat-gray-4">{summary!.completedJob}</p>
                                <p className="flat-gray-4">/{numAppts}</p>
                            </span>
                            <p className="flat-gray-4">{completedJobPctg}</p>
                        </span>

                        <span className="profile-table-cell profile-table-label-cell">
                            Comp. Cert. Signed
                        </span>
                        <span className="profile-table-cell flex-row flex-space-between">
                            <span className="flex-row">
                                <p className="bold-text flat-gray-4">{summary!.completionCertificateSigned}</p>
                                <p className="flat-gray-4">/{numAppts}</p>
                            </span>
                            <p className="flat-gray-4">{completionCertSignedPctg}</p>
                        </span>

                        <span className="profile-table-cell profile-table-label-cell">
                            Avg. Comp. Cert. Rating
                        </span>
                        <span className="profile-table-cell flex-row">
                            <span className="flex-row">
                                <p className="bold-text flat-gray-4">{(summary!.completionCertificateRating / 5).toFixed(1)}</p>
                                <p className="flat-gray-4">/5</p>
                            </span>
                        </span>

                        <span className="profile-table-cell profile-table-label-cell">
                            Service Requests
                        </span>
                        <p className="profile-table-cell bold-text flat-gray-4">{summary!.serviceRequests}</p>

                        <span className="profile-table-cell profile-table-label-cell">
                            Service Completion On Time
                        </span>
                        <span className="profile-table-cell flex-row flex-space-between">
                            <span className="flex-row">
                                <p className="bold-text flat-gray-4">{summary!.servicesCompletedOnTime}</p>
                                <p className="flat-gray-4">/{numServices}</p>
                            </span>
                            <p className="flat-gray-4">{servicesCompletedOnTimePctg}</p>
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}