import { IconButton, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import FlatButton from "FlatComponents/Button/FlatButton";
import { namedOperations, useUpsertRecoveryCenterOverrideMutation } from "generated/graphql";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useAppSelector } from "Redux/hooks";
import {
    selectFinancingOption,
    selectJobConfigurationId,
    selectJobCost,
    selectRecoveryActualCommissionRate,
    selectRecoveryOverride,
    selectRecoveryStandardCommissionRate,
    selectUsageContext
} from "Redux/pricingCenterReducer";
import MarginDisplay from "../MarginDisplay";
import { calculateFinancingAdjustedRevenue, calculateMargin } from "../marginUtils";
import PriceAndMarginEditor from "../PriceAndMarginEditor";
import { RecoveryOverrideCommissionSelect } from "./CommissionSelect";

export default function FinalFinalPriceEditor() {
    const [finalFinalPrice, setFinalFinalPrice] = useState<number | undefined>(undefined);
    const [editing, setEditing] = useState(false);
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const jobCost = useAppSelector(selectJobCost);
    const financingOption = useAppSelector(selectFinancingOption);
    const actualCommissionRate = useAppSelector(selectRecoveryActualCommissionRate);
    const standardCommissionRate = useAppSelector(selectRecoveryStandardCommissionRate);
    const usageContext = useAppSelector(selectUsageContext);
    const disableInteraction = !(usageContext === "rc");
    const recoveryOverride = useAppSelector(selectRecoveryOverride);

    const [upsertFinalFinalPrice, {loading}] = useUpsertRecoveryCenterOverrideMutation({
        onCompleted: (data) => {
            if (data.upsertRecoveryCenterOverride) {
                setEditing(false);
            } else {
                alert("Failed to set final final price.");
            }
        },
        onError: () => {
            alert("Failed to set final final price.");
        },
        refetchQueries: [namedOperations.Query.GetRecoveryCenterOverride],
    });

    function canSubmitFinalPrice() {
        if (jobConfigurationId < 1) {
            alert("Could not submit final final price");
        }

        if (!finalFinalPrice) {
            alert("Must enter a number");
            return false;
        }

        if (!actualCommissionRate) {
            alert("Must select a commission rate");
            return false;
        }

        const margin = calculateMargin(
            calculateFinancingAdjustedRevenue(finalFinalPrice, financingOption?.fee ?? 0),
            jobCost
        );

        if (margin < 25) {
            alert("Margin must be at least 25%");
            return false;
        }

        if (margin < 30) {
            return window.confirm(
                "Margin is below 30%, are you sure you want to send this override?"
            );
        }

        return true;
    }

    function onSubmitFinalFinalPrice() {
        if (canSubmitFinalPrice()) {
            upsertFinalFinalPrice({
                variables: {
                    jobConfigurationId: jobConfigurationId,
                    price: finalFinalPrice!,
                    commissionRate: actualCommissionRate!,
                },
            });
        }
    }

    function onEdit() {
        if (recoveryOverride) {
            setFinalFinalPrice(recoveryOverride.amount);
        }
        setEditing(true);
    }

    function onCancel() {
        setEditing(false);
        setFinalFinalPrice(undefined);
    }

    return (<>
        <div className="flat-font flat-font-bold flat-font-upper">Final Final Price</div>

        <div className="flex-row flex-gap-sm align-items-flex-end">
            {recoveryOverride && !editing ? (
                <>
                    <Typography variant="h6">
                        <NumberFormat
                            style={{ width: "5.75rem" }}
                            prefix="$"
                            thousandSeparator={true}
                            displayType="text"
                            value={recoveryOverride!.amount}
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </Typography>

                    <MarginDisplay
                        revenue={calculateFinancingAdjustedRevenue(
                            recoveryOverride.amount!,
                            financingOption?.fee ?? 0
                        )}
                        cost={jobCost}
                        variant="h6"
                    />

                    <Typography>
                        <NumberFormat
                            suffix="%"
                            thousandSeparator={true}
                            displayType="text"
                            style={{
                                color:
                                    recoveryOverride!.commissionRate ===
                                    standardCommissionRate
                                        ? "black"
                                        : "red",
                            }}
                            value={recoveryOverride!.commissionRate * 100}
                            decimalScale={0}
                        />
                    </Typography>

                    {usageContext === "rc" && (
                        <IconButton
                            onClick={onEdit}
                            size="small"
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                </>
            ) : (
                <>
                    <PriceAndMarginEditor
                        cost={jobCost}
                        revenue={finalFinalPrice}
                        setRevenue={setFinalFinalPrice}
                        isForRecovery
                        financingOption={financingOption}
                        disabled={disableInteraction}
                    />

                    {!disableInteraction && (
                        <RecoveryOverrideCommissionSelect />
                    )}

                    {/* can only cancel editing when there's a recovery override already */}
                    {recoveryOverride && (
                        <IconButton onClick={onCancel}>
                            <CancelIcon />
                        </IconButton>
                    )}

                    {!disableInteraction && (
                        <FlatButton
                            size="small"
                            color="secondary"
                            variant="contained"
                            onClick={onSubmitFinalFinalPrice}
                            disabled={loading}
                        >
                            Submit Final Price
                        </FlatButton>
                    )}
                </>
            )}
        </div>
    </>);
}
