import { Checkbox } from "@material-ui/core"
import { useGetInstallationJobServicesQuery, useGetSubstrateIdQuery } from "generated/graphql"
import { CARPET_PRODUCT_ID } from "Globals/globalConstants"
import { useEffect } from "react"
import { selectTypeId } from "Redux/addProductReducer"
import { useAppSelector } from "Redux/hooks"
import { IdToIds } from "./ProductSpecViewer"

interface InstallationMethodTableProps {
    selectedInstallationMethods: IdToIds[],
    onToggleCheckbox: (newValue: boolean, installId: number, subId: number) => void,
    readOnly?: boolean
}

export function InstallationMethodTable({ selectedInstallationMethods, onToggleCheckbox, readOnly }: InstallationMethodTableProps) {

    const productTypeId = useAppSelector(selectTypeId)
    const { data: loadedSubstratesData } = useGetSubstrateIdQuery()
    const { data: loadedInstallationsData } = useGetInstallationJobServicesQuery({ variables: { productTypeId: productTypeId }, skip: productTypeId < 1 })

    const substrateOptions = loadedSubstratesData?.substrateId ?? []
    const installationOptions = loadedInstallationsData?.installationJobServices ?? []

    //Once the substrate and installation options load, if the selected installation methods are empty, select all options
    useEffect(
        () => {
            if (selectedInstallationMethods.length === 0
                && substrateOptions.length > 0
                && installationOptions.length > 0) {

                installationOptions.forEach(inst => {
                    substrateOptions.forEach(sub => {
                        onToggleCheckbox(true, inst.id, sub.id)
                    })
                })
            }
        },
        // eslint-disable-next-line
        [substrateOptions, installationOptions]
    )

    //#region headers
    function CarpetHeader({ hidden }: { hidden: boolean }) {
        return (
            <tr hidden={hidden}>
                <td align="right">Installation Method</td>
                <td align="center" style={{ minWidth: "5rem" }}>Allowed?</td>
            </tr>
        )
    }

    function HardSurfaceHeader({ hidden }: { hidden: boolean }) {
        return (
            <tr hidden={hidden}>
                <td align="right">Installation Method</td>
                {substrateOptions.map(substrateOption => (
                    <td key={`install-method-header-${substrateOption.type}`} align="center" style={{ minWidth: "5rem" }}>{substrateOption.type}</td>
                ))}
            </tr>
        )
    }
    //#endregion

    //#region bodies
    function CarpetBody({ hidden }: { hidden: boolean }) {
        return (
            <>
                {
                    hidden !== true &&
                    installationOptions.map(installationOption => {
                        const matchingIds = selectedInstallationMethods.filter(localMethods => localMethods.id === installationOption.id)
                        const foundIds = (matchingIds.length === 1) ? matchingIds[0].group : []
                        const isChecked = foundIds.includes(substrateOptions[0].id)
                        return <tr key={`install-method-row-${installationOption.id}`}>
                            <td align="right" className="w-10r">{installationOption.description}</td>
                            <td align="center">
                                <Checkbox
                                    disabled={readOnly}
                                    onChange={(e) => {
                                        substrateOptions.map(op=>onToggleCheckbox(e.target.checked, installationOption.id, op.id))
                                    }}
                                    checked={isChecked} />
                            </td>
                        </tr>
                    })
                }
            </>
        )
    }

    function HardSurfaceBody({ hidden }: { hidden: boolean }) {

        return (
            <>
                {
                    hidden !== true &&
                    installationOptions.map(installationOption => {
                        const matchingIds = selectedInstallationMethods.filter(localMethods => localMethods.id === installationOption.id)
                        const foundIds = (matchingIds.length === 1) ? matchingIds[0].group : []
                        return <tr key={`install-method-row-${installationOption.id}`}>
                            <td align="right" className="w-10r">{installationOption.description}</td>
                            {substrateOptions.map(substrate => {
                                return (
                                    <td key={`install-method-${installationOption.description}-${substrate.type}-td`} align="center">
                                        <Checkbox
                                            disabled={readOnly}
                                            onChange={(e) => onToggleCheckbox(e.target.checked, installationOption.id, substrate.id)}
                                            checked={foundIds.includes(substrate.id)} />
                                    </td>
                                )
                            })}
                        </tr>
                    })

                }
            </>
        )
    }
    //#endregion

    return (
        <table>
            <thead>
                <HardSurfaceHeader hidden={productTypeId === CARPET_PRODUCT_ID} />
                <CarpetHeader hidden={productTypeId !== CARPET_PRODUCT_ID} />
            </thead>
            <tbody>
                <HardSurfaceBody hidden={productTypeId === CARPET_PRODUCT_ID} />
                <CarpetBody hidden={productTypeId !== CARPET_PRODUCT_ID} />
            </tbody>
        </table>
    )
}
