import { Dialog, DialogActions, DialogProps } from "@material-ui/core";
import clsx from "clsx";
import FlatSection, { FlatSectionProps } from "./FlatSection";

export interface FlatDialogProps {
    sectionProps?: FlatSectionProps;
    dialogProps: DialogProps;
    actionButtons?: JSX.Element[];
};

export default function FlatDialog({dialogProps, sectionProps, actionButtons, children}: React.PropsWithChildren<FlatDialogProps>) {
    const {PaperProps, ...remainingDialogProps} = dialogProps;
    const paperProps = {
        className: clsx(dialogProps?.PaperProps?.className, "hide-scrollbar", "flat-outer-container"),
        ...PaperProps
    };

    return (
        <Dialog
            fullWidth   
            PaperProps={paperProps}
            {...remainingDialogProps}
        >
            <FlatSection stickyHeader {...sectionProps}>
                <div className="flat-dialog-content hide-scrollbar">
                    {children}
                </div>
            
                {(actionButtons ?? []).length > 0 && (
                    <DialogActions>
                        {/* give the buttons keys in the component that supplies them */}
                        {actionButtons}
                    </DialogActions>
                )}
            </FlatSection>
        </Dialog>
    )
}