import FlatSection from "FlatComponents/Layout/FlatSection";
import {
    useGetInstallationAppointmentsQuery,
    useGetJobBreakdownQuery,
    useGetJobIdQuery
} from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useMemo } from "react";
import { useAppSelector } from "Redux/hooks";
import {
    selectContractIsCancelled,
    selectJobConfigurationId,
    selectJobContractId,
    selectWithinCancellationWindow
} from "Redux/jobTrackerReducer";
import JobCancellationButton from "../JobCancellationButton";
import InstallationSchedulingPane from "./InstallationScheduling/InstallationSchedulingPane";
import LaborBreakdownTable from "./LaborBreakdownTable";
import MaterialBreakdownTable from "./MaterialBreakdownTable";
import NotesPane from "./Notes/NotesPane";
import PaymentsPane from "./Notes/PaymentsPane";

export default function JobBreakdownPage() {
    /* Basic ID information required for the rest of the page */
    const contractId = useAppSelector(selectJobContractId);
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const withinCancellationWindow = useAppSelector(selectWithinCancellationWindow);
    const contractIsCancelled = useAppSelector(selectContractIsCancelled);

    const { data: jobIdData } = useGetJobIdQuery({
        variables: { jobConfigurationId: jobConfigurationId },
        skip: jobConfigurationId < 1,
    });

    const jobId = jobIdData?.jobId ?? -1;

    // gives us the data for the material/labor breakdown for the job
    const { data: breakdownData } = useGetJobBreakdownQuery({
        variables: { jobConfigurationId: jobConfigurationId },
        skip: jobConfigurationId < 1,
    });

    // gets all of the scheduled appointments for the job
    const { data: installationAppointmentsData } = useGetInstallationAppointmentsQuery({
        variables: { jobContractId: contractId! },
        skip: contractId < 1,
        fetchPolicy: "no-cache",
    });

    const installationAppointments = useMemo(
        () =>
            installationAppointmentsData?.installationAppointments.map((iwr) => iwr.appointment) ??
            [],
        [installationAppointmentsData]
    );
    const installationAppointmentsWithRooms = useMemo(
        () => installationAppointmentsData?.installationAppointments ?? [],
        [installationAppointmentsData]
    );

    return (
        <div className="flex-column">
            <div className="margin-vertical-sm">
                <FlatSection header={"Breakdowns"} collapsible >
                    {isNotNullOrUndefined(breakdownData?.jobBreakdown.areaBreakdowns) && (
                        <div>
                            <MaterialBreakdownTable
                                areaBreakdowns={breakdownData!.jobBreakdown.areaBreakdowns}
                            />
        
                            <div className="margin-top-sm">
                                <LaborBreakdownTable
                                    areaBreakdowns={breakdownData!.jobBreakdown.areaBreakdowns}
                                />
                            </div>
                        </div>
                    )}
                </FlatSection> 
            </div>

            {(jobConfigurationId > 0) && (jobId > 0) && ( contractId > 0) && (
                <div className="flex-row flex-space-between">                
                    <div style={{ width: "24%" }}>
                        <NotesPane
                            jobConfigurationId={jobConfigurationId}
                            installationAppointments={installationAppointments}
                        />
                    </div>

                    <div className="half-width">
                        <InstallationSchedulingPane
                            jobId={jobId}
                            jobConfigurationId={jobConfigurationId}
                            contractId={contractId}
                            areaBreakdowns={breakdownData?.jobBreakdown.areaBreakdowns ?? []}
                            installationAppointments={installationAppointmentsWithRooms}
                        />
                    </div>
                    
                    <div style={{ width: "24%" }}>
                        <PaymentsPane
                            jobConfigurationId={jobConfigurationId}
                            jobContractId={contractId}
                        />
                    </div>
                </div>
            )}

            {(contractId && !withinCancellationWindow && !contractIsCancelled) && (
                <div className="fill-width text-align-right margin-vertical-sm">
                    <JobCancellationButton />
                </div>
            )}
        </div>
    );
}