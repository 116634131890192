import { Button } from '@material-ui/core';
import { DataGrid, GridCellValue, GridColDef, GridRowId } from '@mui/x-data-grid';
import { GetFilteredBlockedTimeSlotsQuery, Market, useDeleteBlockedTimeSlotsMutation } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { timeSlotToEndTimeStr, timeSlotToStartTimeStr } from '../SAHScheduling/sched_utils/timeSlotUtils';

function ymd2mdy(value: GridCellValue): GridCellValue {
    let ymdList = (value as string).split('-');
    // the day contains a time, which comes after T character, so remove all of that (.split('T'))
    return [ymdList[1], ymdList[2].split('T')[0], ymdList[0]].join('-');
}

interface Row {
    id: number;
    day: string;
    startTime: string;
    endTime: string;
    market: string;
}

const baseColumnFields: Partial<GridColDef> = { align: "right", sortable: false, hideable: false, filterable: false }

const columns: GridColDef[] = [
    { field: 'day', headerName: 'Date', width: 100, valueFormatter: (params) => ymd2mdy(params.value), ...baseColumnFields },
    { field: 'startTime', headerName: 'Start Time', width: 110, ...baseColumnFields },
    { field: 'endTime', headerName: 'End Time', width: 110, ...baseColumnFields },
    { field: 'market', headerName: 'Market', width: 95, ...baseColumnFields }
];



function buildRows(timeSlotsOnDayAtZip: GetFilteredBlockedTimeSlotsQuery['filteredBlockedTimeSlots'], allMarkets: Market[]): Row[] {
    return timeSlotsOnDayAtZip.map(tsodaz => {

        const { __typename, ...timeSlot } = tsodaz.timeSlot

        return (
            {
                id: tsodaz.id,
                day: tsodaz.date,
                market: allMarkets.find(market => market.id === tsodaz.marketId)?.name ?? "",
                // FIXME: should be able to call tsoday.timeSlot.startTimeToString(), but for some reason this doesn't work, so this must be done instead
                startTime: timeSlotToStartTimeStr(timeSlot as any),
                // FIXME: should be able to call tsoday.timeSlot.endTimeToString(), but for some reason this doesn't work, so this must be done instead
                endTime: timeSlotToEndTimeStr(timeSlot as any)
            }
        )
    });
}

function handleRemoveSelectedTimeSlots(selectedRowIds: GridRowId[], setSelectedRowIds: (ids: GridRowId[]) => void, deleteBlockedTimeSlotMutation: any) {
    deleteBlockedTimeSlotMutation({
        variables: {
            ids: selectedRowIds.map(id => id.valueOf() as number)
        }
    });

    setSelectedRowIds(selectedRowIds.filter(rowId => !(selectedRowIds.includes(rowId.valueOf() as number))));
}

interface BlockedAppointmentTimesTableParams {
    retrievedTimeSlots: GetFilteredBlockedTimeSlotsQuery['filteredBlockedTimeSlots'],
    deleteBlockedTimeSlotsMutation: ReturnType<typeof useDeleteBlockedTimeSlotsMutation>[0],
    allMarkets: Market[]
}

export default function BlockedAppointmentTimesTable({ retrievedTimeSlots, deleteBlockedTimeSlotsMutation, allMarkets }: BlockedAppointmentTimesTableParams) {
    const [pageNum, setPageNum] = useState(0);
    const [rows, setRows] = useState<Row[]>([]);
    const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);

    useEffect(() => {
        setRows(buildRows(retrievedTimeSlots, allMarkets))
    }, [retrievedTimeSlots, allMarkets]);

    return (
        <>
            <div className='margin-top-sm margin-bottom-md' style={{ height: 'fit-content', maxWidth: "27rem", width: "90%" }}>
                <DataGrid
                    page={pageNum}
                    onPageChange={setPageNum}
                    paginationMode='client'
                    autoPageSize={true}
                    autoHeight={true}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    checkboxSelection
                    selectionModel={selectedRowIds}
                    onSelectionModelChange={(selection) => setSelectedRowIds(selection)}
                />
            </div>

            {
                selectedRowIds.length > 0 &&
                <Button
                    className="margin-bottom-md"
                    variant="contained"
                    onClick={() => handleRemoveSelectedTimeSlots(selectedRowIds, setSelectedRowIds, deleteBlockedTimeSlotsMutation)}
                >
                    Remove Selected Time Slots
                </Button>
            }
        </>
    )
}