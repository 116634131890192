import { Room } from "generated/graphql";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";

export interface RoomSvgProps {
    room: Room;
    areaIndex: number | null;
    onClick?: () => void;
}

export default function RoomSvg({ room, areaIndex, onClick }: RoomSvgProps) {
    const svg = room.svg ?? "";
    const { width, height } = getSvgSize(svg);

    const sizeScale = 0.5;

    return (
        <div
            key={room.id}
            style={{
                width: width * sizeScale,
                height: height * sizeScale,
                minWidth: "1em",
                minHeight: "1em",
                margin: ".5em",
                borderRadius: ".5em",
                padding: "1em 2em",
                position: "relative",
                boxSizing: "content-box",
                MozBoxSizing: "content-box",
                WebkitBoxSizing: "content-box",
                border: "2px solid lightgray",
                overflow: "hidden",
            }}
            onClick={onClick}
            className={`area-${areaIndex === null ? "none" : (areaIndex % 5) + 1}`}
        >
            <div
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translateX(-50%) translateY(-50%)",
                    fontSize: "2em",
                    textTransform: "uppercase",
                }}
            >
                {getNameOfArea(room.labels)}
            </div>
            <div dangerouslySetInnerHTML={{ __html: svg }} />
        </div>
    );
}

export function DetailedRoomSvg({ room, areaIndex, onClick }: RoomSvgProps) {
    const svg = room.detailedSvg ?? "";
    const { width, height } = getSvgSize(svg);

    const sizeScale = 0.5;

    return (
        <div
            key={room.id}
            style={{
                width: width * sizeScale,
                height: height * sizeScale,
                minWidth: "1em",
                minHeight: "1em",
                margin: ".5em",
                borderRadius: ".5em",
                padding: "1em 2em",
                position: "relative",
                boxSizing: "content-box",
                MozBoxSizing: "content-box",
                WebkitBoxSizing: "content-box",
                border: "2px solid lightgray",
                overflow: "hidden",
            }}
            onClick={onClick}
            className={`single-room area-${areaIndex === null ? "none" : (areaIndex % 5) + 1}`}
        >
            <div dangerouslySetInnerHTML={{ __html: svg }} />
        </div>
    );
}

export function getSvgSize(svg: string): { width: number; height: number } {
    return getSvgViewbox(svg)
}

export function getSvgViewbox(svg: string): { x: number; y: number; width: number; height: number } {
    const viewboxRegex = /viewBox="([\d,-\s]*)/gi;

    const matchData = [...svg.matchAll(viewboxRegex)];
    const foundData = matchData.length > 0 && (matchData[0]?.length ?? 0) > 0;
    if (!foundData) {
        return { x: 0, y: 0, width: 0, height: 0 };
    }

    const viewboxData = matchData[0][1];
    const splitData = viewboxData.split(", ");
    return {
        x: +splitData[0] ?? 0,
        y: +splitData[1] ?? 0,
        width: +splitData[2] ?? 0,
        height: +splitData[3] ?? 0,
    };
}
