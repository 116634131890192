import { FlatLabeledSwitch } from "FlatComponents/Inputs/FlatSwitch";
import { useUpdateWorkerIsActiveMutation } from "generated/graphql";

interface WorkerActiveSwitchProps {
    workerId: number;
    isActive: boolean;
    toRefetch: string;  // query to refetch after update
}

export default function WorkerActiveSwitch({workerId, isActive, toRefetch}: WorkerActiveSwitchProps) {
    const [setActive, {loading: updatingWorkerActive}] = useUpdateWorkerIsActiveMutation({
        onError: () => alert("Could not update worker active status"),
        refetchQueries: [toRefetch]
    });

    return (
        <FlatLabeledSwitch
            label="Is Active"
            // when loading, swap the state of the switch so there's not a lag between the click and the refetch
            checked={updatingWorkerActive ? !isActive : isActive}
            onClick={() => setActive(
                { variables: { workerId: workerId, isActive: !isActive } }
            )}
        />
    )
}