import FlatButton from "FlatComponents/Button/FlatButton";
import { buildAppendedId, useNumericIdParam } from "Globals/Hooks";
import { breakdownPath, changeOrderPath, jobTrackerPath, measurePath, originalContractPath, picturesPath, serviceOrderPath, workOrdersPath } from "Globals/PathStrings";
import { useHistory } from "react-router";

export default function JobTrackerNavigator({ openPaymentsDialog }: { openPaymentsDialog: () => void }) {
    const { id: jobContractIdParam } = useNumericIdParam();
    const appendId = buildAppendedId(jobContractIdParam)
    const history = useHistory();

    function changePage(pathExtension: string) {
        history.push(pathExtension + appendId);
    }

    function pathIsActive(path: string) {
        // remove the contract ID so we can compare paths
        const pathNoId = history.location.pathname.split('/').slice(0, -1).join('/');
        return pathNoId.endsWith(path);
    }

    return (
        <div id="jt-navigator">
            <JobTrackerNavigatorButton onClick={() => changePage(jobTrackerPath)} label="Job Tracker" isActive={pathIsActive(jobTrackerPath)}/>
            <JobTrackerNavigatorButton onClick={() => changePage(breakdownPath)} label="Break Down" isActive={pathIsActive(breakdownPath)}/>
            <JobTrackerNavigatorButton onClick={() => changePage(originalContractPath)} label="Original Contract" isActive={pathIsActive(originalContractPath)}/>
            <JobTrackerNavigatorButton onClick={openPaymentsDialog} label="Payments"/>
            <JobTrackerNavigatorButton onClick={() => changePage(measurePath)} label="Measure" isActive={pathIsActive(measurePath)}/>
            <JobTrackerNavigatorButton onClick={() => changePage(workOrdersPath)} label="Work Orders" isActive={pathIsActive(workOrdersPath)}/>
            <JobTrackerNavigatorButton onClick={() => changePage(changeOrderPath)} label="Change Order" isActive={pathIsActive(changeOrderPath)}/>
            <JobTrackerNavigatorButton onClick={() => changePage(serviceOrderPath)} label="Services" isActive={pathIsActive(serviceOrderPath)}/>
            <JobTrackerNavigatorButton onClick={() => changePage(picturesPath)} label="Pictures" isActive={pathIsActive(picturesPath)}/>
        </div>
    );
}

interface JobTrackerNavigatorButtonProps {
    label: string;
    onClick: () => void;
    isActive?: boolean;
}

function JobTrackerNavigatorButton({onClick, label, isActive=false}: JobTrackerNavigatorButtonProps) {
    return (
        <FlatButton
            variant="contained" color="primary"
            className={isActive ? "" : "jt-navigator-button-inactive"}
            style={{margin: 0, borderRadius: "var(--flat-inner-container-radius-sm)"}}
            size="small" onClick={onClick}
            disableElevation
        >{label}</FlatButton>
    )
}
