import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import clsx from "clsx";
import { GetJobConfigurationDocument, useToggleSeamDirectionMutation } from "generated/graphql";
import { useMemo } from "react";
import { getSvgSize } from "../../SellSheet/QuoteDetailsEditor/RoomSvg";
import { EditMode, RoomWithGroupNumber } from "./SeamPlanner";

export function RoomCutPreviewer({
    room,
    areaIndex,
    editState,
    selectedPieceIds,
    setSelectedPieceIds,
}: {
    room: RoomWithGroupNumber;
    areaIndex: number;
    editState: EditMode;
    selectedPieceIds: number[];
    setSelectedPieceIds: (newIds: number[]) => void;
}) {
    const svg = room.detailedSvg ?? "";
    const { width, height } = getSvgSize(svg);

    const normalizedRoomShape = useMemo(() => {
        const shape = room.shape;

        var minX = Math.min(...shape.map((p) => p.x));
        var minY = Math.min(...shape.map((p) => p.y));

        return shape.map((point) => ({ x: point.x - minX, y: point.y - minY }));
    }, [room]);

    const sizeScale = 1;
    const pixelScalar = 20;

    const [toggleCarpetDirection, { loading }] = useToggleSeamDirectionMutation({
        variables: { roomId: room.id, jobConfigurationId: room.jobConfigurationId },
        refetchQueries: [
            {
                query: GetJobConfigurationDocument,
                variables: { jobConfigurationId: room.jobConfigurationId },
                fetchPolicy: "network-only",
            },
        ],
        fetchPolicy: "network-only",
        awaitRefetchQueries: true,
    });

    return (
        <div
            key={room.id}
            style={{
                width: width * sizeScale,
                height: height * sizeScale,
                margin: ".5em",
                borderRadius: ".5em",
                position: "relative",
                boxSizing: "content-box",
                MozBoxSizing: "content-box",
                WebkitBoxSizing: "content-box",
                border: "2px solid lightgray",
                overflow: "hidden",
            }}
            className={`single-room area-${areaIndex === null ? "none" : (areaIndex % 5) + 1}`}
        >
            <div
                dangerouslySetInnerHTML={{ __html: svg }}
                className="fade-measure-details"
            />
            <svg
                height={height * sizeScale - 100}
                width={width * sizeScale - 100}
                style={{ position: "absolute", bottom: "50px", left: "50px" }}
                className="room-cut-preview-svg"
            >
                {room.carpetCuts.flatMap((cut) =>
                    cut.pieces.map((piece) => {

                        const bottomLeftX = Math.min(...piece.roomShape.map(p=>p.x));
                        const bottomLeftY = Math.max(...piece.roomShape.map(p=>p.y));
                        const topRightX = Math.max(...piece.roomShape.map(p=>p.x));
                        const topRightY = Math.min(...piece.roomShape.map(p=>p.y));

                        const centerPoint = {
                            x: (bottomLeftX + topRightX) / 2,
                            y: (bottomLeftY + topRightY) / 2,
                        };

                        return (
                            <g key={piece.id}>
                                <polygon
                                    onClick={() => setSelectedPieceIds([piece.id])}
                                    className={clsx("test-cut-preview", {
                                        "test-cut-clickable": editState === "None",
                                        "cut-selected": selectedPieceIds.includes(piece.id),
                                    })}
                                    points={`${piece.roomShape
                                        .map(
                                            (point) =>
                                                `${point.x * pixelScalar},${point.y * pixelScalar}`
                                        )
                                        .join(" ")}`}
                                />
                                <text
                                    style={{ pointerEvents: "none", userSelect: "none" }}
                                    x={centerPoint.x * pixelScalar}
                                    y={centerPoint.y * pixelScalar}
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                >
                                    {piece.fillName ?? getNameOfArea(room.labels)}
                                    {editState === "ToggleDirection" ? room.isCarpetVerticallyRun ? "⇑" : "⇒" : ""}
                                </text>
                            </g>
                        );
                    })
                )}

                {editState === "ToggleDirection" && (
                    <>
                        <polygon
                            onClick={() => {
                                if (!loading) toggleCarpetDirection();
                            }}
                            className={clsx(
                                "test-cut-preview",
                                `room-cut-number-${room.roomGroupNumber}`
                            )}
                            points={`${normalizedRoomShape
                                .map((point) => `${point.x * pixelScalar},${point.y * pixelScalar}`)
                                .join(" ")}`}
                        ></polygon>
                    </>
                )}
            </svg>
        </div>
    );
}
