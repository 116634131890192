import { DayRange } from "@hassanmojab/react-modern-calendar-datepicker";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import { WorkerHistory } from "generated/graphql";
import { dayValueToMdy } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import WorkerHistoryDialog, { historyDatesToDayRanges } from "../Dialogs/WorkerHistoryDialog";

export interface WorkerHistoryTableProps {
    workerName: string;
    history?: WorkerHistory;
    toRefetch: string[];
}

export default function WorkerHistoryTable({workerName, history, toRefetch}: WorkerHistoryTableProps) {
    const workerId = history?.id ?? -1;
    const [editorOpen, setEditorOpen] = useState(false);
    const rows = historyDatesToDayRanges(history?.dates);

    return (
        <div className="flat-font profile-right-column-table-container">
            <div className="thin-horizontal-bar grid-colspan-1-3" />
            <p>Working History</p>
            <div className="margin-bottom-sm padding-left-sm fit-content-height">
                <span className="profile-table-cell profile-table-add-row">
                    <FlatEditButton
                        onClick={() => setEditorOpen(true)}
                        disabled={workerId === -1 || isNullOrUndefined(history)}
                    />
                </span>
                {rows.map(row => <WorkHistoryTableRow key={row.rangeId} {...row}/>)}
            </div>

            {(editorOpen && isNotNullOrUndefined(history)) && (
                <WorkerHistoryDialog
                    workerId={workerId}
                    workerName={workerName}
                    history={history!}
                    onClose={() => setEditorOpen(false)}
                    toRefetch={toRefetch}
                />
            )}
        </div>
    )
}

function WorkHistoryTableRow({range}: {range: DayRange}) {
    return (
        <p className="profile-table-cell profile-table-date-cell">
            {`${dayValueToMdy(range.from)} - ${dayValueToMdy(range.to, "Current")}`}
        </p>
    )
}