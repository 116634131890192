import NavbarPage from "Components/Page";
import { useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import { User, useSetRoleMutation } from "../../generated/graphql";

interface State {
    user: User
}

interface Inputs{
    newRole: string
}

export default function SetRole() {

    const location = useLocation();

    const [state] = useState<State>(location.state as State);

    const [setRollMutation] = useSetRoleMutation();

    const { register, handleSubmit } = useForm<Inputs>();

    const history = useHistory();

    const onSubmit = async (data: Inputs) => {
        let userEmail = state?.user?.email;
        if(userEmail){
            console.log(`set ${userEmail} ${data.newRole}`);
            setRollMutation({ variables: { userEmail, userRole: data.newRole }}).then(x => history.push('/'));
        }
    }

    return (
        <NavbarPage title={(state)?"Change Role":"No User Selected"} centerHorizontally padContent>
            {state && (
                <Container>
                    <Row>
                        <h1>Current User: {state.user.email}</h1>
                    </Row>
                    <Row>
                        <h1>Current Role: {state.user.role}</h1>
                    </Row>
                    <Row>
                        <Form onSubmitCapture={handleSubmit(onSubmit)}>
                            <Form.Control type='text' {...register('newRole')} placeholder='New Role'/>
                            <Form.Control type='submit' value='Set Role'/>
                        </Form>
                    </Row>
                </Container>
            )}
        </NavbarPage>
    )
}
