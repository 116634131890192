import { Address, AddressInput, BackgroundCheck, BackgroundCheckInput, Company, CompanyInput, ContractorHelper, ContractorHelperInput, ContractorProfile, ContractorProfileInput, CustomerContact, CustomerContactInput, CustomerInput, CustomService, CustomServiceInput, GetJobInvoiceDataQuery, LiabilityInsurance, LiabilityInsuranceInput, SalespersonProfile, SalespersonProfileInput, Vehicle, VehicleInput, W9, W9Input, WorkerHistoryDates, WorkerHistoryDatesInput, WorkersComp, WorkersCompInput } from "generated/graphql";
import { EditableCustomService } from "Pages/Admin/CommandCenter/PricingCenter/Chargeables/CustomServiceEditorRow";
import { isEmptyAddress, isEmptyCompany, isEmptyVehicle } from "./DataStructures/EmptyDataStructures";
import { mdyToIso } from "./DateAndTimeHelpers";
import { isEmptyString, isNotNullOrUndefined, isNullOrUndefined } from "./GenericValidators";
import { formatRawPhoneNumber } from "./StringFormatting";

// This set of utilities is intended for use before validating/submitting data

export function removeTypeName(object: any) {
    const {__typename, ...obj} = object;
    return obj;
}

/**
 * An identity function, except when the passed string is the empty string, in which case undefined is returned.
 * @returns Undefined if the passed string is empty or undefined, otherwise, the passed string is returned as is.
 */
function undefinedIfEmpty(s?: string): string | undefined {
    if (isNullOrUndefined(s) || isEmptyString(s!)) return undefined;
    return s;
}

export function prepareAddress(address: Address): AddressInput {
    return removeTypeName({
        ...address,
        streetAddress: address.streetAddress.trim(),
        city: address.city.trim(),
        zip: address.zip.trim(),
        apartmentNumber: address.apartmentNumber?.trim()
    })
}

export function prepareVehicle(vehicle: Vehicle): VehicleInput {
    return removeTypeName({
        ...vehicle,
        description: vehicle.description.trim(),
        year: vehicle.year ?? undefined,
        condition: vehicle.condition ?? undefined,
        licensePlateNumber: isEmptyString(vehicle.licensePlateNumber?.trim() ?? "") ? undefined : vehicle.licensePlateNumber!.trim()
    });
}

export function prepareCompany(company: Company): CompanyInput {
    return removeTypeName({
        ...company,
        name: company.name.trim(),
        address: prepareAddress(company.address),
        eIN: company.eIN.trim()
    })
}

export function prepareContractorProfile(contractor: ContractorProfile): ContractorProfileInput {
    let address = isNullOrUndefined(contractor.address)  ? undefined : prepareAddress(contractor.address!);
    if (isNotNullOrUndefined(address) && isEmptyAddress(address!)) {
        address = undefined;
    }

    let vehicle = isNullOrUndefined(contractor.vehicle)  ? undefined : prepareVehicle(contractor.vehicle!);
    if (isNotNullOrUndefined(vehicle) && isEmptyVehicle(vehicle!)) {
        vehicle = undefined;
    }
    
    let company = isNullOrUndefined(contractor.company)  ? undefined : prepareCompany(contractor.company!);
    if (isNotNullOrUndefined(company) && isEmptyCompany(company!)) {
        company = undefined;
    }

    return removeTypeName({
        ...contractor,
        firstName: contractor.firstName.trim(),
        lastName: contractor.lastName.trim(),
        email: contractor.email.trim(),
        phone: formatRawPhoneNumber(contractor.phone),
        address: address,
        vehicle: vehicle,
        company: company,
        driversLicenseNumber: undefinedIfEmpty(contractor.driversLicenseNumber?.trim()),
        licenseNumber: undefinedIfEmpty(contractor.licenseNumber?.trim()),
        parentCompany: undefinedIfEmpty(contractor.parentCompany?.trim()),
        workerHistory: undefined, // this is updated in a separate mutation
        backgroundChecks: [], // this is updated in a separate mutation
        w9s: [], // this is updated in a separate mutation,
        capabilities: [], // this is updated in a separate mutation
        workersComps: [], // this is updated in a separate mutation
        liabilityInsurances: [] // this is updated in a separate mutation
    });
}

export function prepareContractorHelper (helper: ContractorHelper): ContractorHelperInput {
    return removeTypeName({
        ...helper,
        firstName: helper.firstName.trim(),
        lastName: helper.lastName.trim(),
        licenseNumber: undefinedIfEmpty(helper.licenseNumber?.trim()),
        phone: formatRawPhoneNumber(helper.phone),
        history: prepareWorkerHistoryDates(helper.history)
    });
}

export function prepareSalespersonProfile(salesperson: SalespersonProfile): SalespersonProfileInput {
    let address = isNullOrUndefined(salesperson.address)  ? undefined : prepareAddress(salesperson.address!);
    if (isNotNullOrUndefined(address) && isEmptyAddress(address!)) {
        address = undefined;
    }

    let vehicle = isNullOrUndefined(salesperson.vehicle)  ? undefined : prepareVehicle(salesperson.vehicle!);
    if (isNotNullOrUndefined(vehicle) && isEmptyVehicle(vehicle!)) {
        vehicle = undefined;
    }

    let company = isNullOrUndefined(salesperson.company)  ? undefined : prepareCompany(salesperson.company!);
    if (isNotNullOrUndefined(company) && isEmptyCompany(company!)) {
        company = undefined;
    }

    return removeTypeName({
        ...salesperson,
        firstName: salesperson.firstName.trim(),
        lastName: salesperson.lastName.trim(),
        email: salesperson.email.trim(),
        phone: formatRawPhoneNumber(salesperson.phone),
        address: address,
        vehicle: vehicle,
        company: company,
        driversLicenseNumber: undefinedIfEmpty(salesperson.driversLicenseNumber?.trim()),
        workerHistory: undefined, // this is updated in a separate mutation
        backgroundChecks: [], // this is updated in a separate mutation
        w9s: [], // this is updated in a separate mutation
        workersComps: [], // this is updated in a separate mutation
        liabilityInsurances: [] // this is updated in a separate mutation
    });
}

export function prepareWorkerHistoryDates(dates: WorkerHistoryDates[]): WorkerHistoryDatesInput[] {
    return dates.map(d => removeTypeName(d));
}

export function prepareBackgroundCheck(backgroundCheck: BackgroundCheck): BackgroundCheckInput {    
    return removeTypeName({
        ...backgroundCheck,
        number: undefinedIfEmpty(backgroundCheck.number?.trim() ?? undefined)
    });
}

type WorkerDocument = W9 | WorkersComp | LiabilityInsurance;
export type WorkerDocumentInput = W9Input | WorkersCompInput | LiabilityInsuranceInput;
export function prepareWorkerDocument(document: WorkerDocument): WorkerDocumentInput {
    return removeTypeName({
        ...document,
        activeDate: mdyToIso(document.activeDate),
        expirationDate: mdyToIso(document.expirationDate),
    });
}

export function prepareEditableCustomService(customService: EditableCustomService): CustomServiceInput {
    const {isDeleted, displayContractorPay, displayContractorPercentage, displayPrice, ...preparedService} = removeTypeName(customService);
    return preparedService;
}

export function prepareCustomService(customService: CustomService): CustomServiceInput {
    return removeTypeName(customService);
}

export function prepareCustomer(
    customer: GetJobInvoiceDataQuery["jobConfigurationHeader"]["customer"]
): CustomerInput {
    const { __typename, ...fields } = customer;
    return {
        ...fields,
        customerContacts: fields.customerContacts.map(prepareCustomerContact),
        primaryAddress: prepareAddress(fields.primaryAddress)!,
        billingAddress: fields.billingAddress ? prepareAddress(fields.billingAddress) : undefined,
    };
}

function prepareCustomerContact(contact: CustomerContact): CustomerContactInput {
    const { __typename, ...fields } = contact;
    return { ...fields };
}