import { ApolloClient, InMemoryCache, MutationHookOptions, MutationTuple, OperationVariables, QueryHookOptions, QueryResult } from "@apollo/client";
import { useAuthToken } from "./FirebaseUtils";

export function useAPIQueryOptions<T, U>(): QueryHookOptions<T, U> {

    // var [token] = useIdToken();
    var token = useAuthToken();

    const headers = {
        "Authorization": `Bearer ${token}`
    }

    const client = new ApolloClient({
        uri: apiEndpoint,
        cache: new InMemoryCache(),
        headers
    });
    return { client, skip: true }
}

export function useAPIMutationOptions<T, U>(): MutationHookOptions<T, U> {
    const token = useAuthToken();

    const headers = {
        "Authorization": `Bearer ${token}`
    }

    const client = new ApolloClient({
        uri: apiEndpoint,
        cache: new InMemoryCache(),
        headers
    });
    return { client }
}

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT as string;

export function useAuthenticatedQuery<TData = any, TVariables = OperationVariables>
    (
        useQueryFunction: (baseOptions?: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>,
        options?: QueryHookOptions<TData, TVariables>
    ) {
    const queryOptions = useAPIQueryOptions<TData, TVariables>();
    return useQueryFunction({ ...options, ...queryOptions });
}

export function useAuthenticatedMutation<TData = any, TVariables = OperationVariables>
    (
        useMutationFunction: (baseOptions?: MutationHookOptions<TData, TVariables>) => MutationTuple<TData, TVariables>,
        options?: MutationHookOptions<TData, TVariables>
    ) {
        const mutationOptions = useAPIMutationOptions<TData, TVariables>();
        return useMutationFunction({...options, ...mutationOptions});
}