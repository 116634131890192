import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { useUpdateCwcMutation, namedOperations } from "generated/graphql";
import { useState } from "react";

interface CustomerWillCallDialogProps {
    jobId: number;
    onClose: () => void;
    initialNumWeeks?: number;
}

export default function CustomerWillCallDialog({jobId, onClose, initialNumWeeks}: CustomerWillCallDialogProps) {
    const [ numWeeks, setNumWeeks ] = useState(initialNumWeeks ? initialNumWeeks : -1);
    const [ updateCWC ] = useUpdateCwcMutation({
        refetchQueries: [namedOperations.Query.GetCwcNumber]
    });

    function onUpdateCwc() {
        updateCWC({
            variables: {
                jobId: jobId,
                numWeeks: numWeeks > 0 ? numWeeks : null
            },
            onCompleted: (data) => {
                if (data.updateCWC) {
                    onClose();
                } else {
                    alert("Could not update customer will call information");
                }
            }
        });
    }

    return (
        <Dialog open={true}>
            <DialogTitle>Customer Will Call</DialogTitle>

            <DialogContent>
                <FormControl>
                    <FormLabel>Customer will call in:</FormLabel>
                    <RadioGroup value={numWeeks} onChange={(e) => setNumWeeks(+e.target.value)}>
                        <FormControlLabel value={-1} control={<Radio />} label="N/A"/>
                        <FormControlLabel value={1} control={<Radio />} label="1 Week"/>
                        <FormControlLabel value={2} control={<Radio />} label="2 Weeks"/>
                        <FormControlLabel value={3} control={<Radio />} label="3 Weeks"/>
                        <FormControlLabel value={4} control={<Radio />} label="4+ Weeks"/>
                    </RadioGroup>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <SpacedButton className='cancel-button' onClick={onClose}>Cancel</SpacedButton>
                <SpacedButton variant="contained" color='secondary' onClick={onUpdateCwc}>Submit CWC</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}