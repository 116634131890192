import { IconButton } from "@material-ui/core";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatUploadFileButton({color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(<>
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
        >
            <FlatUploadFileIcon color={color}/>
        </IconButton>
    </>)
}

export function FlatUploadFileIcon({color="black"} : {color?: string}) {
    return <UploadFileIcon htmlColor={color}/>
}