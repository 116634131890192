import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import { ReactComponent as SalespersonIcon } from "Assets/Svgs/salesperson_icon_black.svg";
import clsx from "clsx";
import {
    namedOperations,
    RecoveryReasonOption,
    RecoveryStepOption,
    useAddRecoveryCommunicationMutation,
    useAddRescheduledSahAppointmentMutation,
    useSubmitChangesToJobInRecoveryMutation,
} from "generated/graphql";
import {
    dateTimeStrToDate,
    dateToDay,
    dateToPaddedMdy,
    dateToYmd,
} from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { formatPhoneNumber } from "Globals/StringFormatting";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RecoveryCenterCallCustomerPhoneButton } from "./CallCustomerPhoneButton";
import RecoveryLedger from "./RecoveryLedger";
import SelectSalespersonAndTimeslotMenu from "./SelectSalespersonAndTimeslotMenu";
import "./VisualComponents/recovery-center-components.css";
import RecoveryCenterCard, {
    BaseRecoveryCenterCardProps,
} from "./VisualComponents/RecoveryCenterCard";

const OTHER_REASON_ID: number = -1;
const PLACEHOLDER_REASON_ID = -2;
const PLACEHOLDER_STEP_ID = -1;
const TODAYS_FOLLOW_UP_ID = -2;

const FOLLOW_UP_STEP_ID = 1;
const REVISIT_STEP_ID = 2;
const WRITE_UP_STEP_ID = 3;

export const RECOVERY_COMMUNICATION_OPTION_PHONE_ID = 1;
export const RECOVERY_COMMUNICATION_OPTION_TEXT_ID = 2;
export const RECOVERY_COMMUNICATION_OPTION_EMAIL_ID = 3;

export interface ClaimedRecoveryCenterCardProps extends BaseRecoveryCenterCardProps {
    reasonOptions: RecoveryReasonOption[];
    stepOptions: RecoveryStepOption[];
}

function ClaimedRecoveryCenterCard({
    job,
    reasonOptions,
    stepOptions,
}: ClaimedRecoveryCenterCardProps) {
    const todaysDate = dateToPaddedMdy(new Date());

    const [ledgerOpen, setLedgerOpen] = useState(false);

    const [submit] = useSubmitChangesToJobInRecoveryMutation({
        refetchQueries: [
            namedOperations.Query.GetAllJobsInRecovery,
            namedOperations.Query.GetAllRecoveryLedgerItems,
        ],
    });

    const [addCommunication] = useAddRecoveryCommunicationMutation({
        refetchQueries: [
            namedOperations.Query.GetAllJobsInRecovery,
            namedOperations.Query.GetAllRecoveryLedgerItems,
        ],
    });

    const [addRescheduledAppointment] = useAddRescheduledSahAppointmentMutation({
        refetchQueries: [namedOperations.Query.GetAllJobsInRecovery],
    });

    const [unlockEditing, setUnlockEditing] = useState(false);

    const stepCount = job.steps.length;
    let lastStep = stepCount > 1 ? job.steps[stepCount - 2] : null;
    let nextStep = stepCount > 0 ? job.steps[stepCount - 1] : null;
    if (job.isHot) {
        lastStep = nextStep;
    }
    if (nextStep !== null && dateToPaddedMdy(dateTimeStrToDate(nextStep.date)) === todaysDate) {
        nextStep = null;
    }

    // Last step and next step are already initalized to what they should be for the page

    // #region Reason
    const hasReason = job.reasons.length > 0;
    const loadedReason = hasReason ? job.reasons[job.reasons.length - 1] : undefined;
    const [selectedReasonId, setSelectedReasonId] = useState<number>(
        hasReason
            ? loadedReason?.recoveryReasonOptionId ?? PLACEHOLDER_REASON_ID
            : PLACEHOLDER_REASON_ID
    );

    const [otherReason, setOtherReason] = useState<string | undefined>(
        hasReason && isNullOrUndefined(loadedReason?.recoveryReasonOptionId)
            ? loadedReason?.reason
            : undefined
    );

    const canEditReason = nextStep === null || job.isHot || unlockEditing;
    const reasonDate = hasReason
        ? dateToPaddedMdy(dateTimeStrToDate(loadedReason?.date ?? ""))
        : todaysDate;

    const reasonDifferent =
        (otherReason ?? reasonOptions.find((op) => op.id === selectedReasonId)?.label) !==
        loadedReason?.reason;

    function updateSelectedReason(newId: number) {
        if (newId === OTHER_REASON_ID) {
            setOtherReason("");
        } else {
            setSelectedReasonId(newId);
        }
    }

    function clearOtherReason() {
        if (canEditReason) setOtherReason(undefined);
    }
    // #endregion

    // #region Next Step
    const canEditNextStep = nextStep === null || job.isHot || unlockEditing;

    const [selectedStepId, setSelectedStepId] = useState<number>(
        nextStep?.recoveryStepOptionId ?? PLACEHOLDER_STEP_ID
    );
    const [selectedDate, setSelectedDate] = useState<Date | null>(
        nextStep !== null ? dateTimeStrToDate(nextStep.date) : null
    );
    const [writeUpRevisitMenu, setWriteUpRevisitMenu] = useState<number | null>(null);

    function updateNextStepId(stepId: number) {
        if (stepId === TODAYS_FOLLOW_UP_ID) {
            stepId = FOLLOW_UP_STEP_ID;
            setSelectedDate(new Date());
        } else if (stepId === REVISIT_STEP_ID) {
            setWriteUpRevisitMenu(REVISIT_STEP_ID);
            return;
        } else if (stepId === WRITE_UP_STEP_ID) {
            setWriteUpRevisitMenu(WRITE_UP_STEP_ID);
            return;
        }

        setSelectedStepId(stepId);
    }

    // #endregion

    // #region Last Step
    const selectedLastStepId = lastStep?.recoveryStepOptionId ?? PLACEHOLDER_STEP_ID;
    const selectedLastDate =
        lastStep !== null ? dateToPaddedMdy(dateTimeStrToDate(lastStep.date)) : reasonDate;

    // #endregion

    // #region Action
    function handleEmailClicked() {
        addCommunication({
            variables: {
                jobId: job.id,
                recoveryCommunicationOptionId: RECOVERY_COMMUNICATION_OPTION_EMAIL_ID,
            },
        });
        window.open(
            `mailto:${job.customerEmail}?subject=Subject%20Goes%20Here&body=Body%20goes%20here`
        );
    }

    function handleTextClicked() {
        addCommunication({
            variables: {
                jobId: job.id,
                recoveryCommunicationOptionId: RECOVERY_COMMUNICATION_OPTION_TEXT_ID,
            },
        });
        window.alert(
            "Customer Phone: " + formatPhoneNumber(job.customerPhone) ?? job.customerPhone
        );
    }
    // #endregion

    // #region Submitting

    const reasonReadyToSubmit =
        (otherReason !== undefined && otherReason.length > 1) ||
        (otherReason === undefined && selectedReasonId > 0);
    const stepReadyToSubmit = selectedStepId > 0 && selectedDate !== null;

    const stepDifferent =
        nextStep?.recoveryStepOptionId !== selectedStepId ||
        (selectedDate !== null &&
            dateToYmd(dateTimeStrToDate(nextStep?.date)) !== dateToYmd(selectedDate));

    const areChangesToStepOrReason =
        (stepDifferent && stepReadyToSubmit) || (reasonDifferent && reasonReadyToSubmit);

    const canSubmit =
        (!canEditReason || reasonReadyToSubmit) &&
        stepReadyToSubmit &&
        (!unlockEditing || areChangesToStepOrReason);

    function submitChanges() {
        if ((!canEditReason || reasonReadyToSubmit) && stepReadyToSubmit) {
            if (reasonDifferent) {
                submit({
                    variables: {
                        jobId: job.id,
                        recoveryReasonOptionId: otherReason !== undefined ? null : selectedReasonId,
                        stepDate: dateToYmd(selectedDate!),
                        otherRecoveryReason: otherReason,
                        recoveryStepOption: selectedStepId,
                        updateLatestStep: unlockEditing,
                    },
                });
            } else {
                submit({
                    variables: {
                        jobId: job.id,
                        recoveryStepOption: selectedStepId,
                        stepDate: dateToYmd(selectedDate!),
                        updateLatestStep: unlockEditing,
                    },
                });
            }

            // Locks editing
            setUnlockEditing(false);
        }
    }

    function submitWriteUpOrRevisit(
        salespersonId: number | null,
        date: string,
        marketTimeSlotId: number
    ) {
        if (canEditReason && reasonReadyToSubmit) {
            submit({
                variables: {
                    jobId: job.id,
                    recoveryReasonOptionId: otherReason !== undefined ? null : selectedReasonId,
                    otherRecoveryReason: otherReason,
                    stepDate: date,
                    recoveryStepOption: writeUpRevisitMenu!,
                    updateLatestStep: unlockEditing,
                    removeFromRecovery: true,
                },
            });
        } else {
            submit({
                variables: {
                    jobId: job.id,
                    stepDate: date,
                    recoveryStepOption: writeUpRevisitMenu!,
                    updateLatestStep: unlockEditing,
                    removeFromRecovery: true,
                },
            });
        }

        addRescheduledAppointment({
            variables: {
                jobId: job.id,
                date,
                salespersonId,
                marketTimeSlotId,
                isWriteUp: writeUpRevisitMenu === WRITE_UP_STEP_ID,
            },
        });

        setWriteUpRevisitMenu(null);
    }

    // #endregion

    // #region Display Toggling and Disabling

    function toggleEditing() {
        if (unlockEditing) {
            // Going to be clearing the editing fields
            setSelectedStepId(nextStep?.recoveryStepOptionId ?? PLACEHOLDER_STEP_ID);
            setSelectedDate(nextStep !== null ? dateTimeStrToDate(nextStep.date) : null);
            setSelectedReasonId(
                hasReason
                    ? loadedReason?.recoveryReasonOptionId ?? PLACEHOLDER_REASON_ID
                    : PLACEHOLDER_REASON_ID
            );

            setOtherReason(
                hasReason && isNullOrUndefined(loadedReason?.recoveryReasonOptionId)
                    ? loadedReason?.reason
                    : undefined
            );
        }

        setUnlockEditing(!unlockEditing);
    }

    const renderLastStep = lastStep !== null || !job.isHot;
    const renderAction = true;
    const renderUnlockButton = nextStep !== null && !job.isHot;
    const allowSubmitting = canEditNextStep || canEditReason;

    const salesperson = job.assignedSalesperson;
    const spName = isNullOrUndefined(salesperson)
        ? ""
        : `${salesperson?.firstName} ${salesperson?.lastName}`;

    const displayPrice = isNullOrUndefined(job.latestPrice) ? "-" : job.latestPrice?.toFixed(2);

    //#endregion

    // #region Recovery Ledger
    function closeDialog() {
        setLedgerOpen(false);
    }

    const customerName = `${job.customerFirstName} ${job.customerLastName}`;
    const displayNumber = createLabeledRecoveryDisplayNumber(job);

    // #endregion

    return (
        <RecoveryCenterCard
            job={job}
            allowCustomerClick
        >
            <div className="claimed-card-content">
                <SalespersonIcon
                    className="claimed-sp-icon"
                    style={{ fill: "#275BFF" }}
                />
                <div className="claimed-sp">{spName}</div>
                <div className="claimed-sp-date small-text gray-text">
                    {dateToPaddedMdy(dateTimeStrToDate(job.dateEnteredRecovery))}
                </div>
                <div className="claimed-price">${displayPrice}</div>

                <div className="claimed-reason-label small-text gray-text">Reason:</div>
                <div className="claimed-reason-options">
                    <ReasonPicker
                        otherReason={otherReason}
                        setOtherReason={setOtherReason}
                        clearOtherReason={clearOtherReason}
                        selectedReasonId={selectedReasonId}
                        updateSelectedReason={updateSelectedReason}
                        canEditReason={canEditReason}
                        reasonOptions={reasonOptions}
                    />
                </div>
                <div className="claimed-reason-date small-text gray-text">{reasonDate}</div>
                <div className="claimed-journal-icon">
                    <IconButton
                        size="small"
                        onClick={() => setLedgerOpen(true)}
                    >
                        <LibraryBooks style={{ width: "1rem", height: "1rem" }} />
                    </IconButton>
                </div>

                {renderLastStep && (
                    <>
                        <div className="claimed-last-label small-text gray-text">Last Step:</div>
                        <div className="claimed-last-options">
                            <select
                                className="option-select"
                                value={selectedLastStepId}
                                disabled={true}
                                onChange={(e) => setSelectedStepId(+e.currentTarget.value)}
                            >
                                {stepOptions.map((option) => (
                                    <option
                                        value={option.id}
                                        key={`card-${job.id}-last-step-option-${option.id}`}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                                <option
                                    value={PLACEHOLDER_STEP_ID}
                                    hidden
                                >
                                    Initial
                                </option>
                            </select>
                        </div>
                        <div className="claimed-last-date small-text gray-text">
                            {selectedLastDate}
                        </div>
                    </>
                )}

                {renderAction && (
                    <>
                        <div className="claimed-action-label small-text gray-text">Action:</div>
                        <div className="claimed-action-options">
                            <RecoveryCenterCallCustomerPhoneButton
                                phoneNumber={job.customerPhone}
                                jobId={job.id}
                            />
                            <IconButton
                                size="small"
                                onClick={() => handleTextClicked()}
                            >
                                <TextsmsOutlinedIcon style={{ width: "1rem", height: "1rem" }} />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={() => handleEmailClicked()}
                            >
                                <AlternateEmailOutlinedIcon
                                    style={{ width: "1rem", height: "1rem" }}
                                />
                            </IconButton>
                        </div>
                        <div className="claimed-action-date small-text gray-text">{todaysDate}</div>
                    </>
                )}
                {renderUnlockButton && (
                    <div className="claimed-unlock-button">
                        <IconButton
                            size="small"
                            onClick={() => toggleEditing()}
                        >
                            <EditIcon style={{ width: "1rem", height: "1rem" }} />
                        </IconButton>
                    </div>
                )}

                <div className="claimed-next-label small-text gray-text">Next Step:</div>
                <div className="claimed-next-options">
                    <NextStepSelect
                        selectedStepId={selectedStepId}
                        canEditNextStep={canEditNextStep}
                        updateNextStepId={updateNextStepId}
                        stepOptions={stepOptions}
                    />
                </div>
                <div className="claimed-next-date">
                    <NextDate
                        canEditNextStep={canEditNextStep}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                    />
                </div>

                <div className="claimed-send-button">
                    {allowSubmitting && canSubmit && (
                        <IconButton
                            size="small"
                            onClick={() => submitChanges()}
                        >
                            <SendOutlinedIcon />
                        </IconButton>
                    )}
                </div>
            </div>

            <Dialog
                open={ledgerOpen}
                onClose={() => closeDialog()}
                maxWidth="xl"
            >
                <DialogTitle>
                    <div
                        className="flex-row"
                        style={{ padding: ".5rem 0", justifyContent: "space-between" }}
                    >
                        <div>
                            {customerName} {displayNumber}
                        </div>
                        <IconButton onClick={() => closeDialog()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <RecoveryLedger jobId={job.id} />
                </DialogContent>
                <DialogActions>
                    <table>
                        <tbody>
                            <tr>
                                <td
                                    align="right"
                                    className="small-text gray-text"
                                >
                                    Reason:
                                </td>
                                <td>
                                    <ReasonPicker
                                        otherReason={otherReason}
                                        setOtherReason={setOtherReason}
                                        clearOtherReason={clearOtherReason}
                                        selectedReasonId={selectedReasonId}
                                        updateSelectedReason={updateSelectedReason}
                                        canEditReason={canEditReason}
                                        reasonOptions={reasonOptions}
                                    />
                                </td>
                                <td>
                                    <div
                                        className="flex-row"
                                        style={{ justifyContent: "space-between" }}
                                    >
                                        <div className="small-text gray-text">{reasonDate}</div>
                                        {renderUnlockButton && (
                                            <div className="claimed-unlock-button">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => toggleEditing()}
                                                >
                                                    <EditIcon
                                                        style={{ width: "1rem", height: "1rem" }}
                                                    />
                                                </IconButton>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    align="right"
                                    className="small-text gray-text"
                                >
                                    Next Step:
                                </td>
                                <td>
                                    <NextStepSelect
                                        selectedStepId={selectedStepId}
                                        canEditNextStep={canEditNextStep}
                                        updateNextStepId={updateNextStepId}
                                        stepOptions={stepOptions}
                                    />
                                </td>
                                <td>
                                    <NextDate
                                        canEditNextStep={canEditNextStep}
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td align="right">
                                    <IconButton
                                        className={clsx({
                                            "visibility-hidden": !(allowSubmitting && canSubmit),
                                        })}
                                        size="small"
                                        onClick={() => submitChanges()}
                                    >
                                        <SendOutlinedIcon />
                                    </IconButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogActions>
            </Dialog>

            {writeUpRevisitMenu !== null && (
                <SelectSalespersonAndTimeslotMenu
                    initialSalespersonId={job.assignedSalesperson?.id}
                    initialDate={selectedDate === null ? undefined : dateToDay(selectedDate)}
                    zip={job.customerZip}
                    onClose={() => setWriteUpRevisitMenu(null)}
                    onSubmit={submitWriteUpOrRevisit}
                />
            )}
        </RecoveryCenterCard>
    );
}

//#region Reason Picker
interface ReasonPickerProps {
    otherReason?: string;
    setOtherReason: (newValue: string) => void;
    clearOtherReason: () => void;
    selectedReasonId: number;
    updateSelectedReason: (newId: number) => void;
    canEditReason: boolean;
    reasonOptions: ClaimedRecoveryCenterCardProps["reasonOptions"];
}

function ReasonPicker({
    otherReason,
    setOtherReason,
    clearOtherReason,
    selectedReasonId,
    updateSelectedReason,
    canEditReason,
    reasonOptions,
}: ReasonPickerProps) {
    return (
        <>
            {otherReason === undefined ? (
                <select
                    className="option-select"
                    value={selectedReasonId}
                    disabled={!canEditReason}
                    onChange={(e) => updateSelectedReason(+e.currentTarget.value)}
                >
                    {reasonOptions.map((option) => (
                        <option
                            value={option.id}
                            key={`reason-option-${option.id}`}
                        >
                            {option.label}
                        </option>
                    ))}
                    <option value={OTHER_REASON_ID}>Other</option>
                    <option
                        value={PLACEHOLDER_REASON_ID}
                        hidden
                    >
                        Please select
                    </option>
                </select>
            ) : (
                <div className="other-reason-position">
                    <input
                        disabled={!canEditReason}
                        className="other-reason-input"
                        value={otherReason}
                        onChange={(e) => setOtherReason(e.target.value)}
                    />
                    <span
                        className={clsx({ "disabled-reason": !canEditReason })}
                        onClick={() => clearOtherReason()}
                    >
                        &times;
                    </span>
                </div>
            )}
        </>
    );
}
//#endregion

//#region NextDate
interface NextDateProps {
    canEditNextStep: boolean;
    selectedDate: Date | null;
    setSelectedDate: (newDate: Date | null) => void;
}

function NextDate({ canEditNextStep, selectedDate, setSelectedDate }: NextDateProps) {
    return (
        <DatePicker
            disabled={!canEditNextStep}
            selected={selectedDate}
            onChange={setSelectedDate}
            placeholderText="MM-DD-YY"
            minDate={new Date()}
            className="next-date-picker-styles"
        />
    );
}
//#endregion

//#region NextStep
interface NextStepSelectProps {
    selectedStepId: number;
    canEditNextStep: boolean;
    updateNextStepId: (newId: number) => void;
    stepOptions: ClaimedRecoveryCenterCardProps["stepOptions"];
}

function NextStepSelect({
    selectedStepId,
    canEditNextStep,
    updateNextStepId,
    stepOptions,
}: NextStepSelectProps) {
    return (
        <select
            className="option-select"
            value={selectedStepId}
            disabled={!canEditNextStep}
            onChange={(e) => updateNextStepId(+e.currentTarget.value)}
        >
            <option value={TODAYS_FOLLOW_UP_ID}>Today's Follow Up</option>
            {stepOptions.map((option) => (
                <option
                    value={option.id}
                    key={`step-option-${option.id}`}
                >
                    {option.label}
                </option>
            ))}
            <option
                value={PLACEHOLDER_STEP_ID}
                hidden
            >
                Please select
            </option>
        </select>
    );
}
//#endregion

function createLabeledRecoveryDisplayNumber(job: ClaimedRecoveryCenterCardProps["job"]) {
    if (!isNullOrUndefined(job.contractNumber)) return `SAH-${job.contractNumber}`;
    else if (!isNullOrUndefined(job.quoteNumber)) return `Q-${job.quoteNumber}`;
    else return `A-${job.appointmentNumber}`;
}

export default ClaimedRecoveryCenterCard;
