import { MenuItem, Select } from "@material-ui/core"
import { useGetVendorsQuery } from "generated/graphql"
import { selectVendorId, setVendorId } from "Redux/addProductReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"

export default function VendorEditor() {

    const { data } = useGetVendorsQuery()

    const dispatch = useAppDispatch()
    const vendorId = useAppSelector(selectVendorId)

    function updateValue(newValue: number) {
        dispatch(setVendorId(newValue))
    }

    return (
        <div className="flex-column">
            <label htmlFor="vendor">Vendor:</label>

            <Select
                defaultValue={-1}
                onChange={(e) => updateValue(e.target.value as number)}
                value={vendorId}
                className="w-10r"
            >
                <MenuItem value={-1}>Unselected</MenuItem>
                { (data?.vendors ?? []).map(
                    v => <MenuItem key={`vendor-select-${v.id}`} value={v.id}>{v.name}</MenuItem>
                )}
            </Select>
        </div>
    )
}
