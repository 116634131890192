import { IconButton, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { BlockedMarketTimeSlot, SahAppointment } from "generated/graphql";
import { minutesBetween, timeSpanStrToTime } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { formatNameStringLastFirst } from "Globals/StringFormatting";
import { useEffect, useState } from "react";
import { TypedPickNPlaceCell } from "Redux/pickNPlaceReducer";
import {
    ACCEPTED_BY_SALESPERSON_STATUS_ID,
    ASSIGNED_STATUS_ID,
    CHECKED_IN_STATUS_ID,
    CHECKED_OUT_STATUS_ID,
    CUSTOMER_NOT_HOME_STATUS_ID,
    RELEASED_TO_SALESPERSON_STATUS_ID,
    RESCHEDULED_STATUS_ID,
    UNASSIGNED_STATUS_ID,
    WAITING_FOR_CUSTOMER_STATUS_ID
} from "./AppointmentStatus";
import "./command_center_style_sheet.css";

import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { commandCenterPricingPath, readOnlyPricingCenterPath } from "Globals/PathStrings";
import { CommandCenterCallButton } from "Pages/Admin/RecoveryCenter/CallCustomerPhoneButton";

export type CommandCenterCellData = SahAppointment | BlockedMarketTimeSlot;

export default function CommandCenterAppointmentCell({
    data,
    openContextMenu,
}: TypedPickNPlaceCell<CommandCenterCellData>) {
    const appointment = data as SahAppointment;
    const timeBlock = data as BlockedMarketTimeSlot;

    if (appointment.__typename === "SAHAppointment")
        return (
            <AppointmentCommandCenterCell
                appointment={appointment}
                openContext={openContextMenu}
            />
        );
    else return <BlockedCommandCenterCell timeBlock={timeBlock} />;
}

function AppointmentCommandCenterCell({
    appointment,
    openContext,
}: {
    appointment: SahAppointment;
    openContext?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) {
    const status = appointment.appointmentStatusId;
    const [flashCell, setFlashCell] = useState<boolean>(false);
    const [canOpenPricingCenter, setCanOpenPricingCenter] = useState<boolean>(isNotNullOrUndefined(appointment.activeJobConfigurationId));

    useEffect(() => {
        setCanOpenPricingCenter(isNotNullOrUndefined(appointment.activeJobConfigurationId));
    }, [setCanOpenPricingCenter, appointment]);

    const fakeRating = (appointment.lastName.length * 7.1) % 10;
    const statusClassName = getBackgroundColorForCell(appointment);

    const canFlash = statusClassName.some((cn) => cn === "flat-dark-bkg");

    useEffect(() => {
        const date = appointment.date;
        if (date.split("T")[0] !== new Date().toISOString().split("T")[0]) return () => {};

        const appointmentTime = timeSpanStrToTime(appointment.marketTimeSlot.startTime);
        const currentDate = new Date();
        const currentTime = { hour: currentDate.getHours(), minute: currentDate.getMinutes() };
        const minutesUntilAppointment = minutesBetween(currentTime, appointmentTime);

        if (status === UNASSIGNED_STATUS_ID) {
            const minutesUntilFlash = minutesUntilAppointment - 30;

            const id = setTimeout(() => {
                setFlashCell(true);
            }, minutesUntilFlash * 60000);

            return () => {
                clearTimeout(id);
                setFlashCell(false);
            };
        } else if (status === RELEASED_TO_SALESPERSON_STATUS_ID) {
            const minutesUntilFlash = Math.min(minutesUntilAppointment - 15, 15);

            const id = setTimeout(() => {
                setFlashCell(true);
            }, minutesUntilFlash * 60000);

            return () => {
                clearTimeout(id);
                setFlashCell(false);
            };
        } else if (status === ACCEPTED_BY_SALESPERSON_STATUS_ID) {
            const minutesUntilFlash = minutesUntilAppointment - 5;

            const id = setTimeout(() => {
                setFlashCell(true);
            }, minutesUntilFlash * 60000);

            return () => {
                clearTimeout(id);
                setFlashCell(false);
            };
        } else if (status === CUSTOMER_NOT_HOME_STATUS_ID) {
            const id = setTimeout(() => {
                setFlashCell(true);
            }, 0);

            return () => {
                clearTimeout(id);
                setFlashCell(false);
            };
        } else return () => {};
    }, [setFlashCell, status, appointment.marketTimeSlot.startTime, appointment.date]);

    const inEndState = !statusClassName.some((clss) => clss === "flat-dark-bkg");

    return (
        <div
            className={clsx(
                statusClassName,
                "flat-has-customer-rating-indicator fill-width flex-column",
                { "cell-flash": flashCell && canFlash && !appointment.isCancelled }
            )}
            style={{ borderRadius: "8px", overflow: "hidden", width: "calc(10em - 9px)" }}
        >
            <div
                className="flex-row fill-width"
                style={{
                    backgroundColor: "var(--theme-bkg-primary-color)",
                    padding: "5px 10px 0 10px",
                    overflow: "hidden",
                }}
            >
                <div
                    className="flex-column disable-selection"
                    style={{ alignItems: "flex-start", flexGrow: 1, overflow: "hidden" }}
                >
                    <div
                        className="flat-font-md"
                        style={{
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {formatNameStringLastFirst(appointment)}
                    </div>
                    <div
                        className="flat-font-sm flat-font-bold"
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {appointment.primaryCity}
                    </div>
                </div>
                <div>
                    <IconButton
                        size="small"
                        style={{ width: "10px" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            openContext?.(e);
                        }}
                    >
                        <MoreVertIcon style={{ color: "var(--theme-main-text-color)" }} />
                    </IconButton>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: "var(--theme-color)",
                    height: inEndState ? "6px" : "3px",
                }}
            ></div>
            <div
                className="flex-row"
                style={{
                    backgroundColor: "var(--theme-bkg-secondary-color)",
                    flexGrow: 1,
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 10px",
                }}
            >
                {inEndState ? (
                    <>
                        <div
                            className={clsx("flat-font-sm flat-font-bold clickable")}
                            style={{
                                fontSize: "12pt",
                                flex: 1,
                                padding: "0 5px",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                            onClick={() => window.open(`${readOnlyPricingCenterPath}/${appointment.activeJobConfigurationId}`)}
                        >{getAbbreviatedAppointmentStatusString(appointment)}</div>
                    </>
                ) : (
                    <>
                        <CommandCenterCallButton
                            phoneNumbers={[
                                { phoneNumber: appointment.primaryPhone, label: "Call Customer" },
                                { phoneNumber: "", label: "Call Salesperson" },
                            ]}
                        />
                        <Tooltip title={getFullAppointmentStatusString(appointment)}>
                            <div
                                className={clsx({"clickable": canOpenPricingCenter})}
                                style={{
                                    backgroundColor: "var(--theme-color)",
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "50%",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    lineHeight: 1,
                                    color: "var(--theme-comp-text-color)",
                                }}
                                onClick={(e) => {
                                    if (canOpenPricingCenter) {
                                        e.preventDefault();
                                        window.open(`${commandCenterPricingPath}/${appointment.activeJobConfigurationId}`);
                                    }
                                }}
                            >
                                {canOpenPricingCenter ? "$" : undefined}
                            </div>
                        </Tooltip>
                        <div
                            className={clsx(
                                getRatingColor(fakeRating),
                                "flat-font-sm flat-customer-rating-indicator disable-selection"
                            )}
                        >
                            {fakeRating.toFixed(1)}
                        </div>
                        <Tooltip
                            title={
                                <table>
                                    <tbody>
                                        <tr>
                                            <td align="right">
                                                <b># Rooms:</b>
                                            </td>
                                            <td>{appointment.numRooms}</td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                <b>Priorities:</b>
                                            </td>
                                            <td>
                                                {appointment.priorityOptions
                                                    ?.map((op) => op.text)
                                                    .join(", ")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                <b>Products:</b>
                                            </td>
                                            <td>{appointment.productTypes?.join(", ")}</td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                <b>Colors:</b>
                                            </td>
                                            <td>
                                                {appointment.colorCategories
                                                    ?.map((op) => op.category)
                                                    .join(", ")}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        >
                            <div
                                className="flex-row"
                                style={{
                                    height: "22px",
                                    width: "22px",
                                    borderRadius: "50%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <HelpOutlineIcon
                                    style={{
                                        height: "22px",
                                        width: "22px",
                                        lineHeight: 0,
                                        color: "var(--theme-main-text-color)",
                                    }}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip title={appointment.comments ?? "No Comments"}>
                            <div
                                className="flex-row"
                                style={{
                                    height: "22px",
                                    width: "22px",
                                    borderRadius: "50%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <AssignmentOutlinedIcon
                                    style={{
                                        height: "22px",
                                        width: "22px",
                                        lineHeight: 0,
                                        color: "var(--theme-main-text-color)",
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </>
                )}
            </div>
        </div>
    );
}

function BlockedCommandCenterCell({ timeBlock }: { timeBlock: BlockedMarketTimeSlot }) {
    const isDeletable = !isNullOrUndefined(timeBlock.workerBlockedTimeSlotIdIfDeletable);

    return (
        <div
            className={clsx("fill-width flex-column", {
                "diagonal-striping": !isDeletable,
                "diagonal-striping-deletable": isDeletable,
            })}
        />
    );
}

function getFullAppointmentStatusString(appointment: SahAppointment): string {
    if (appointment.isCancelled || appointment.contractCancelled) {
        if (appointment.isInRecovery) return "Sold, 3-Day Cancelled";
        else return "Appointment Cancelled";
    }

    switch (appointment.appointmentStatusId) {
        case CHECKED_OUT_STATUS_ID:
            if (isNotNullOrUndefined(appointment.contractTotalPrice)) return "Sale";
            else if (isNotNullOrUndefined(appointment.quoteTotalPrice)) return "Quote";
            else return "No Quote";
        default:
            return appointment.appointmentStatusString;
    }
}

function getAbbreviatedAppointmentStatusString(appointment: SahAppointment): string {
    if (appointment.isCancelled || appointment.contractCancelled) {
        if (appointment.isInRecovery) return "3-Day";
        else return "Cancelled";
    }

    switch (appointment.appointmentStatusId) {
        case CHECKED_OUT_STATUS_ID:
            if (isNotNullOrUndefined(appointment.contractTotalPrice)) return "Sale";
            else if (isNotNullOrUndefined(appointment.quoteTotalPrice)) return "Quote";
            else return "No Quote";
        default:
            return appointment.appointmentStatusString;
    }
}

export function getRatingColor(rating: number): string {
    if (rating < 3) return "flat-red-theme-color";
    else if (rating < 7) return "flat-orange-theme-color";
    else return "flat-green-theme-color";
}

function getBackgroundColorForCell(appointment: SahAppointment): string[] {
    if (appointment.isCancelled === false && appointment.contractCancelled === false) {
        const hasContract = isNotNullOrUndefined(appointment.contractTotalPrice);

        return getBackgroundColorForStatus(appointment.appointmentStatusId, hasContract);
    } else {
        if (appointment.isInRecovery) return ["flat-red-theme-color"];
        else return ["flat-orange-theme-color"];
    }
}

function getBackgroundColorForStatus(
    status: number,
    hasContract: boolean
): string[] {
    switch (status) {
        case UNASSIGNED_STATUS_ID:
            return ["flat-red-theme-color", "flat-dark-bkg"];
        case ASSIGNED_STATUS_ID:
            return ["flat-orange-theme-color", "flat-dark-bkg"];
        case RELEASED_TO_SALESPERSON_STATUS_ID:
            return ["flat-yellow-theme-color", "flat-dark-bkg"];
        case ACCEPTED_BY_SALESPERSON_STATUS_ID:
            return ["flat-white-theme-color", "flat-dark-bkg"];
        case CHECKED_IN_STATUS_ID:
            return ["flat-cyan-theme-color", "flat-dark-bkg"];
        case CHECKED_OUT_STATUS_ID:
            if (hasContract) return ["flat-green-theme-color"];
            else return ["flat-yellow-theme-color"];
        case CUSTOMER_NOT_HOME_STATUS_ID:
            return ["flat-cyan-theme-color", "flat-dark-bkg"];
        case WAITING_FOR_CUSTOMER_STATUS_ID:
            return ["flat-blue-theme-color", "flat-dark-bkg"];
        case RESCHEDULED_STATUS_ID:
            return ["flat-orange-theme-color", "flat-dark-bkg"];
        default:
            return [];
    }
}
