import { Button, debounce, Dialog, IconButton, Tooltip, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LocalPhoneOutlinedIcon from "@material-ui/icons/LocalPhoneOutlined";
import clsx from "clsx";
import Loading from "Components/Loading";
import { HBar } from "Components/pageSpacing";
import FlatCircleCheckButton from "FlatComponents/Button/FlatCircleCheckButton";
import {
    InstallationAppointment,
    namedOperations,
    useGetInstallationAppointmentsForJobContractQuery,
    useMarkInstallationAppointmentCompleteMutation,
    useUpdateEstimatedArrivalTimeForInstallationAppointmentMutation,
    useUpdateInstallationAppointmentNotesMutation
} from "generated/graphql";
import {
    dateTimeStrToHHMM12HR,
    formatAppointmentDateStringExact,
    formatMilitaryToHHMM12HR,
    timeSpanStrToMinutes
} from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { buildAppendedId } from "Globals/Hooks";
import { picturesPath } from "Globals/PathStrings";
import { formatNameStringLastFirst, formatPhoneNumber } from "Globals/StringFormatting";
import {
    generate30MinuteTimes,
    SelectedOption,
    TimeOption
} from "Pages/Home/ContractorHome/InstallJob/ArrivalTimeReporting";
import { CompletionCertificateDialog } from "Pages/Home/ContractorHome/InstallJob/InstallJobPage";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "Redux/hooks";
import { selectJobContractId } from "Redux/jobTrackerReducer";

export default function JobInstallationTrackerPage() {
    const jobContractId = useAppSelector(selectJobContractId);

    const { data, loading } = useGetInstallationAppointmentsForJobContractQuery({
        variables: { jobContractId },
        pollInterval: 5000,
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-and-network",
    });

    const appointments = useMemo(() => data?.installationAppointmentsForJobContract ?? [], [data]);

    return (
        <div
            className="fill-width"
            style={{ paddingTop: "1em" }}
        >
            {loading && appointments.length === 0 && <Loading />}
            {!loading && appointments.length === 0 && (
                <Typography variant="h4">No Appointments</Typography>
            )}
            {appointments.map((appt, index) => (
                <React.Fragment key={appt.id}>
                    {index > 0 && <HBar style={{ marginBottom: "1em" }} />}
                    <InstallationTrackerSection {...appt} />
                </React.Fragment>
            ))}
        </div>
    );
}

function InstallationTrackerSection(appointment: InstallationAppointment) {
    const {
        id,
        contractorFirstName,
        contractorLastName,
        dates,
        woodTotal,
        carpetTotal,
        spcTotal,
        checkInTime,
        checkOutTime,
        hasAfterPictures,
        hasBeforePictures,
        certificateRating,
        contractorLastContactAttempt,
        customerNotifiedOfEta,
        estimatedArrivalTime,
        cod,
        notes,
        isComplete
    } = appointment;

    const jobContractId = useAppSelector(selectJobContractId);
    const appendId = buildAppendedId(jobContractId);
    const history = useHistory();

    function goToPictures() {
        history.push(picturesPath + appendId);
    }

    const [markInstallationComplete, {loading: markingComplete}] = useMarkInstallationAppointmentCompleteMutation({
        onError: () => alert("Failed to mark installation appointment as complete.")
    });

    function markCompleted() {
        if (window.confirm("There is no completion certificate for this installation - are you sure you want to mark it as complete?")) {
            markInstallationComplete({variables: {installationAppointmentId: id}});
        }
    }

    const [showCert, setShowCert] = useState(false);
    const [showETA, setShowETA] = useState(false);

    const contractorName =
        isNullOrUndefined(contractorFirstName) || isNullOrUndefined(contractorLastName)
            ? "UNASSIGNED"
            : formatNameStringLastFirst({
                  firstName: contractorFirstName!,
                  lastName: contractorLastName!,
              });

    const dateSpan = formatAppointmentDateStringExact(dates);

    return (
        <div
            className="flex-column"
            style={{ marginBottom: "1rem" }}
        >
            <div
                className="flex-row fill-width"
                style={{ justifyContent: "space-between" }}
            >
                <div>
                    Contractor: <b>{contractorName}</b>
                </div>
                <div>
                    Scheduled Date: <b>{dateSpan}</b>
                </div>
                <div className="flex-row">
                    {woodTotal > 0 && (
                        <div style={{ paddingRight: ".75em" }}>
                            Wood: <b>{woodTotal.toFixed(0)} sqft</b>
                        </div>
                    )}
                    {carpetTotal > 0 && (
                        <div style={{ paddingRight: ".75em" }}>
                            Carpet: <b>{(carpetTotal / 9).toFixed(0)} sqyd</b>
                        </div>
                    )}
                    {spcTotal > 0 && (
                        <div>
                            SPC: <b>{spcTotal.toFixed(0)} sqft</b>
                        </div>
                    )}
                </div>
            </div>
            <div
                className="flex-row fill-width"
                style={{ minHeight: "15rem" }}
            >
                <div className="flex-grow flex-column">
                    <label>Notes:</label>
                    <InstallationNotes
                        installationAppointmentId={id}
                        notes={notes}
                    />
                    <div
                        style={{
                            display: "flex",
                            margin: ".5em",
                            minHeight: "2em",
                            justifyContent: "space-around",
                        }}
                    >
                        <div>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    window.alert(formatPhoneNumber(appointment.customerPhone))
                                }
                            >
                                Call Customer
                                <LocalPhoneOutlinedIcon style={{ marginLeft: ".5em" }} />
                            </Button>
                        </div>
                        <div>
                            <Button variant="contained">
                                Call Contractor
                                <LocalPhoneOutlinedIcon style={{ marginLeft: ".5em" }} />
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="flex-column flex-grow">
                    <div className="installation-tracker-row">
                        <div>Customer Contacted: </div>
                        <div>{customerNotifiedOfEta ? "YES" : "NO"}</div>
                        <div>Contact Time: </div>
                        <div>
                            {isNullOrUndefined(contractorLastContactAttempt)
                                ? "N/A"
                                : dateTimeStrToHHMM12HR(contractorLastContactAttempt!)}
                        </div>
                    </div>
                    <div className="installation-tracker-row">
                        <div>ETA: </div>
                        <div style={{ flexGrow: 4 }}>
                            <Typography>
                                {isNullOrUndefined(estimatedArrivalTime)
                                    ? "N/A"
                                    : dateTimeStringTo30MinRange(estimatedArrivalTime!)}
                            </Typography>
                            <IconButton
                                size="small"
                                onClick={() => setShowETA(true)}
                            >
                                <EditIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className="installation-tracker-row">
                        <div>Checked In: </div>
                        <div>
                            {isNullOrUndefined(checkInTime)
                                ? "NO"
                                : dateTimeStrToHHMM12HR(checkInTime!)}
                        </div>
                        <div>Checked Out: </div>
                        <div>
                            {isNullOrUndefined(checkOutTime)
                                ? "NO"
                                : dateTimeStrToHHMM12HR(checkOutTime!)}
                        </div>
                    </div>
                    <div className="installation-tracker-row">
                        <div>Before Pictures:</div>
                        <div>
                            {hasBeforePictures ? (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={goToPictures}
                                >
                                    View
                                </Button>
                            ) : (
                                "NO"
                            )}
                        </div>
                        <div>After Pictures:</div>
                        <div>
                            {hasAfterPictures ? (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={goToPictures}
                                >
                                    View
                                </Button>
                            ) : (
                                "NO"
                            )}
                        </div>
                    </div>
                    <div className="installation-tracker-row">
                        <div>Completion Certificate Signed:</div>
                        <div>
                            {isNotNullOrUndefined(certificateRating) ? (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => setShowCert(true)}
                                >
                                    {certificateRating}
                                </Button>
                            ) : (
                                <>
                                    {isComplete ? (
                                        <span className="text-align-right">
                                            <Typography>NO - Manually Closed</Typography>
                                        </span>
                                    ) : (
                                        <span className="flex-row flex-gap-xsm align-items-center">
                                            <Typography>NO</Typography>
                                            <Tooltip title="Mark Installation Completed">
                                                <span>
                                                    <FlatCircleCheckButton
                                                        onClick={markCompleted}
                                                        disabled={markingComplete}
                                                        color={markingComplete ? "flat-gray-4" : "green"}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </span>
                                    )}
                                </>                                
                            )}
                        </div>
                        <div>Open COD:</div>
                        <div>
                            <Typography>{cod <= 0 ? "NONE" : `$${cod.toFixed(2)}`}</Typography>
                        </div>
                    </div>
                </div>
            </div>

            <CompletionCertificateDialog
                open={showCert}
                onClose={() => setShowCert(false)}
                showPDF={true}
                installationAppointmentId={appointment.id}
            />

            <ArrivalTimeReportingDialog
                open={showETA}
                onClose={() => setShowETA(false)}
                installationAppointmentId={appointment.id}
            />
        </div>
    );
}

function dateTimeStringTo30MinRange(dateTimeStr: string) {
    var startingMinutes = timeSpanStrToMinutes(dateTimeStr);
    var endingMinutes = (startingMinutes + 30) % (60 * 24);

    return `${formatMilitaryToHHMM12HR(
        Math.floor(startingMinutes / 60),
        startingMinutes % 60
    )} - ${formatMilitaryToHHMM12HR(Math.floor(endingMinutes / 60), endingMinutes % 60)}`;
}

interface ArrivalTimeReportingDialogProps {
    installationAppointmentId: number;
    open: boolean;
    onClose: () => void;
}

function ArrivalTimeReportingDialog({
    installationAppointmentId,
    open,
    onClose,
}: ArrivalTimeReportingDialogProps) {
    const [option, setOption] = useState<string | null>(null);
    const [customerAnswered, setCustomerAnswered] = useState<boolean>(true);

    const [updateEstimate] = useUpdateEstimatedArrivalTimeForInstallationAppointmentMutation();

    const times = generate30MinuteTimes(21, 0);

    const renderOptions: TimeOption[] = times.slice(0, -2).map((time, index) => {
        return {
            time: `${time.time} - ${times[index + 2].time}`,
            timeSpan: time.timeSpan,
        };
    });

    function submitSelection() {
        updateEstimate({
            variables: {
                appointmentId: installationAppointmentId,
                eta: option,
                didCustomerAnswer: customerAnswered,
            },
        });
        setOption(null);
        setCustomerAnswered(true);
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <div
                className="flex-column flex-centered"
                style={{ margin: "1em" }}
            >
                <Typography variant="h5">Estimated ETA</Typography>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: ".5em",
                        margin: ".75em 0",
                        overflowY: "auto",
                    }}
                >
                    {renderOptions.map((time) => (
                        <SelectedOption
                            key={time.timeSpan}
                            selectedValue={option}
                            myValue={time.timeSpan}
                            myText={time.time}
                            onClick={setOption}
                        />
                    ))}
                </div>

                <SelectedOption
                    selectedValue={customerAnswered}
                    myValue={true}
                    myText={customerAnswered ? "Customer Answered" : "Did not Answer"}
                    onClick={() => setCustomerAnswered(!customerAnswered)}
                />

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={submitSelection}
                    style={{ fontSize: "1.3em", padding: ".5em 2em", marginTop: ".75em" }}
                    className={clsx({ "visibility-hidden": option === null })}
                >
                    Submit
                </Button>
            </div>
        </Dialog>
    );
}

function InstallationNotes({
    installationAppointmentId,
    notes,
}: {
    installationAppointmentId: number;
    notes: string;
}) {
    const [typedNotes, setTypedNotes] = useState<undefined | string>(undefined);
    const typedRef = useRef<boolean>(false);

    const [updateAppointmentNotes] = useUpdateInstallationAppointmentNotesMutation({
        refetchQueries: [namedOperations.Query.GetInstallationAppointmentsForJobContract],
    });

    const notesInput = typedNotes ?? notes;

    const debouncedUpdateNotes = useMemo(() => {
        return debounce((newNotes: string) => {
            updateAppointmentNotes({
                variables: { installationAppointmentId, newNotes, replace: true },
            });
        }, 500);
    }, [updateAppointmentNotes, installationAppointmentId]);

    function updateNotesField(newNotes: string) {
        setTypedNotes(newNotes);
        debouncedUpdateNotes(newNotes);
        typedRef.current = true;
    }

    useEffect(() => {
        if (notes === typedNotes) setTypedNotes(undefined);
    }, [notes, typedNotes]);

    const showSynced = typedRef.current === true && typedNotes === undefined;

    return (
        <div
            style={{ position: "relative", width: "100%" }}
            className="flex-grow"
        >
            <textarea
                value={notesInput}
                style={{ width: "100%", height: "100%", resize: "none" }}
                onChange={(e) => updateNotesField(e.currentTarget.value)}
            ></textarea>
            {showSynced && (
                <div
                    className="fade-out-synced-text"
                    style={{
                        position: "absolute",
                        right: ".6em",
                        bottom: ".15em",
                        fontSize: ".8em",
                        color: "#999",
                    }}
                >
                    Synced...
                </div>
            )}
        </div>
    );
}
