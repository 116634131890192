import { FlatLabeledSelect, FlatSelectOption } from "FlatComponents/Inputs/FlatSelect";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { range } from "lodash";
import { useState } from "react";
import { SingleValue } from "react-select";

interface VehicleYearSelectProps {
    year?: number;
    setYear: (newYear?: number) => void;
}

export function VehicleYearSelect({year, setYear}: VehicleYearSelectProps) {
    // +1 because vehicle model years are dumb
    const yearOptions: FlatSelectOption[] = range(new Date().getFullYear() + 1, 1969, -1).map(year => ({value: year, label: year}));
    // internal year value
    const [selectedOption, setSelectedOption] = useState<FlatSelectOption | null>(yearOptions.find(yo => yo.value === year) ?? null);

    function onChangeInternal(selectedOption: SingleValue<FlatSelectOption>) {
        setSelectedOption(selectedOption); // set internal value
        setYear(isNullOrUndefined(selectedOption) ? undefined : +selectedOption!.value) // set external value
    }

    return (
        <FlatLabeledSelect
            options={yearOptions}
            label="Vehicle Year"
            className="w-10r"
            value={selectedOption}
            onChange={(val) => onChangeInternal(val as SingleValue<FlatSelectOption>)}
        />
    )
}

interface VehicleConditionSelectProps {
    condition?: string;
    setCondition: (condition?: string) => void;
}

export function VehicleConditionSelect({condition, setCondition}: VehicleConditionSelectProps) {       
    const conditionOptions: FlatSelectOption[] = [
        {value: "Poor", label: "Poor"},
        {value: "Fair", label: "Fair"},
        {value: "Good", label: "Good"}
    ];

    // internal year value
    const [selectedOption, setSelectedOption] = useState<FlatSelectOption | null>(conditionOptions.find(co => co.value === condition) ?? null);

    function onChangeInternal(selectedOption: SingleValue<FlatSelectOption>) {
        setSelectedOption(selectedOption); // set internal value
        setCondition(isNullOrUndefined(selectedOption) ? undefined : selectedOption!.value as string) // set external value
    }

    return (
        <FlatLabeledSelect
            options={conditionOptions}
            label="Vehicle Condition"
            className="w-10r"
            value={selectedOption}
            onChange={(val) => onChangeInternal(val as SingleValue<FlatSelectOption>)}
        />
    );
}