import { Grid } from "@material-ui/core";
import carpetImg from 'Assets/Images/carpet_img.jpg';
import laminateImg from 'Assets/Images/laminate_img.jpg';
import spcImg from 'Assets/Images/vinyl_tile_img.jpg';
import woodImg from 'Assets/Images/wood_img.jpg';
import { useGetProductTypeOptionsQuery } from "generated/graphql";
import { toggleValueInList } from "Globals/Helpers";
import { selectPtypeIds, setPtypeIds } from "Redux/callCenterSchedulingReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import ProductSelectionCard from "./ProductSelectionCard";

export default function ProductTypeSelectionGrid() {
    const { data, loading } = useGetProductTypeOptionsQuery();
    const cptId = data?.options.find(op => op.type === 'Carpet')?.id ?? -1;
    const woodId = data?.options.find(op => op.type === 'Wood')?.id ?? -1;
    const lamId = data?.options.find(op => op.type === 'Laminate')?.id ?? -1;
    const spcId = data?.options.find(op => op.type === 'SPC')?.id ?? -1;

    const dispatch = useAppDispatch();
    const selectedProductTypeIds = useAppSelector(selectPtypeIds);

    function toggleUpdateProduct(pTypeId: number) {
        const newSelection = toggleValueInList(selectedProductTypeIds, pTypeId);
        dispatch(setPtypeIds(newSelection));
    }

    function isSelected(pTypeId: number) { return selectedProductTypeIds.some(pid => pid === pTypeId); }

    return (
        <>
            {(!loading && data) && (
                <Grid className="flex-row align-items-center" container spacing={2}>
                    <Grid item >
                        <ProductSelectionCard
                            img={carpetImg}
                            alt="Carpet"
                            handleSelection={() => toggleUpdateProduct(cptId)}
                            selectionControl={isSelected(cptId)}
                        />
                    </Grid>

                    <Grid item>
                        <ProductSelectionCard
                            img={spcImg}
                            alt="SPC"
                            handleSelection={() => toggleUpdateProduct(spcId)}
                            selectionControl={isSelected(spcId)}
                        />
                    </Grid>

                    <Grid item>
                        <ProductSelectionCard
                            img={woodImg}
                            alt="Wood"
                            handleSelection={() => toggleUpdateProduct(woodId)}
                            selectionControl={isSelected(woodId)}
                        />
                    </Grid>

                    <Grid item>
                        <ProductSelectionCard
                            img={laminateImg}
                            alt="Laminate"
                            handleSelection={() => toggleUpdateProduct(lamId)}
                            selectionControl={isSelected(lamId)}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    )
}