import { AppBar, Box, Button, Toolbar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import logo from "Assets/Images/WorldOfFloorsLogoSmall.png";
import DebugMenu from 'Components/DebugMenu';
import { signOut } from 'Globals/FirebaseUtils';
import React from 'react';
import { useHistory } from 'react-router-dom';
import NavbarRoutes, { RouteNamePair } from "./NavbarRoutes";

const useStyles = makeStyles(
    theme => ({
        appbar: {
            display: "flex",
            overflow: "hidden"
        },
        navbar: {
            zIndex: "inherit",
            backgroundColor: "#ffffff",
            flexWrap: 'nowrap',
        },
        navDivider: {
            height: ".5em"
        },
        navButtons: {
            margin: "0 1em"
        },
        navButton: {
            margin: "0 .5em"
        },
        logo: {
            backgroundImage: `url(${logo})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: "72px",
            '&:hover': {
                color: 'yellow'
            },
            flexGrow: 1,
        },
        link: {
            margin: theme.spacing(1, 1.5),
        },
    })
)

export default function DesktopNavbar({ customRoutes }: { customRoutes?: RouteNamePair[] }) {

    const classes = useStyles();
    const history = useHistory();

    const [debugAnchorEl, setDebugAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleDebugMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDebugAnchorEl(event.currentTarget);
    };

    const handleDebugMenuClose = () => {
        setDebugAnchorEl(null);
    };

    function NavbarRouteToButton(routeNamePair: RouteNamePair) {
        return <Button
            key={`Navbar-desktop-${routeNamePair.name}`}
            className={classes.navButton}
            variant="contained"
            color="primary"
            onClick={_ => history.push(routeNamePair.route)}
        >
            {routeNamePair.name}
        </Button>
    }

    var routes = customRoutes ?? NavbarRoutes()



    return (
        <AppBar className={classes.appbar} position="sticky" elevation={0}>
            <Toolbar className={classes.navbar}>
                <Button className={classes.logo} onClick={handleDebugMenuClick}></Button>
                {/* <Button onClick={()=>window.open(`https://app.wofadmin.com/drawing/${27}`)}>Test</Button> */}                
                <DebugMenu anchorEl={debugAnchorEl} handleClose={handleDebugMenuClose} />
                <nav className={classes.navButtons}>
                    {routes.map(NavbarRouteToButton)}
                </nav>
                <Button variant="outlined" color="primary" onClick={x => signOut()}>Logout</Button>
            </Toolbar>
            <Box color="primary" className={classes.navDivider} />
        </AppBar>
    )
}

