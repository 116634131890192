import { Select, MenuItem } from "@material-ui/core"
import { useAppSelector, useAppDispatch } from "Redux/hooks"
import { selectCarpetCostMode, setCarpetCostsMode } from "Redux/productCostsReducer"
import { CarpetCostsRowMode } from "./CarpetCostsStyleRow"

export default function CarpetUnitSelect() {
    const mode = useAppSelector(selectCarpetCostMode)
    const dispatch = useAppDispatch()

    function changeMode(newMode: CarpetCostsRowMode) {
        dispatch(setCarpetCostsMode(newMode))
    }

    return (
        <div className="flex-column w-5r margin-side-xxsm">
            <label htmlFor="mode-select" className="margin-side-xsm">Unit:</label>
            <Select name='mode-select' value={mode} onChange={(e) => changeMode(e.target.value as CarpetCostsRowMode)}>
                <MenuItem value={'sqft'}>Sqft</MenuItem>
                <MenuItem value={'sqyd'}>Sqyd</MenuItem>
            </Select>
        </div>
    )
}