import { Typography } from "@material-ui/core"
import CircleAddButton from "Components/Forms/Controls/CircleAddButton"
import SpacedButton from "Components/Forms/Controls/SpacedButton"
import { selectArea, selectAreaIndex } from "Redux/genericSalesReducer"
import { useAppSelector } from "Redux/hooks"
import { getLabelForRoomFullName } from "Redux/JobReducerDataStructures/AreaType"


export default function EditExistingAreaRooms() {

    const areaIndex = useAppSelector(selectAreaIndex)
    const area = useAppSelector(selectArea(areaIndex))

    return (
        <>
            <div className="flex-row fill-width padding-bottom-xsm" style={{ alignItems: "center" }}>
                <Typography>
                    Edit Rooms:
                </Typography>
                {
                    area.rooms.map((room, index) => {

                        return <SpacedButton key={`room-${index}-${room.id}-${room.areaId}`} variant="contained">
                            {room.labels.map(lbl => getLabelForRoomFullName(lbl, true)).join(", ")}
                        </SpacedButton>
                    })
                }
                <CircleAddButton variant="contained" className='margin-side-xxsm'/>
            </div>
        </>
    )
}

