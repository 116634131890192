import { PLYWOOD_ID } from "Globals/globalConstants";
import { JobServiceGroup, JobServiceGroups } from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/BreakdownTableUtils";
import BuildUpServiceGroupEditor from "./BuildUpServiceGroupEditor";
import GeneralGroupedServiceEditorRow, { GroupedServiceEditorRowProps } from "./GeneralGroupedServiceEditorRow";

// this component mainly determines which type of editor to render, so it functions similarly to the GroupedServiceEditorRow, but
// takes multiple service groups, therefore requiring slight differences in the props
interface FloorPrepServiceEditorSectionProps extends Omit<GroupedServiceEditorRowProps, "originalServiceGroup" | "editableServiceGroup" | "removeServiceGroup"> {
    originalServiceGroups: JobServiceGroups;
    editableServiceGroups: JobServiceGroups;
    removeServiceGroup: (jsId: number) => void;
}

export default function FloorPrepServiceEditorSection({
    originalServiceGroups,
    editableServiceGroups,
    updateServiceGroup,
    removeServiceGroup,
    preventRcEditing,
    getNextServiceId,
    allAreas
}: FloorPrepServiceEditorSectionProps) {
    return (<>
        {Object.entries(editableServiceGroups).map(([_, thisGroupAsUnknown]) => {
            const thisEditable = thisGroupAsUnknown as JobServiceGroup;
            const jsId = thisEditable.jobServiceId;
            const thisOriginal = (jsId in originalServiceGroups) ? originalServiceGroups[jsId] : null;
            if (thisEditable.jobServiceId === PLYWOOD_ID) {
                return (
                    <BuildUpServiceGroupEditor
                        key={thisEditable.jobServiceId}
                        originalServiceGroup={thisOriginal}
                        editableServiceGroup={thisEditable}
                        updateServiceGroup={updateServiceGroup}
                        removeServiceGroup={() => removeServiceGroup(jsId)}
                        preventRcEditing={preventRcEditing}
                        getNextServiceId={getNextServiceId}
                        allAreas={allAreas}
                    />
                )
            } else {
                return (
                    <GeneralGroupedServiceEditorRow
                        key={thisEditable.jobServiceId}
                        originalServiceGroup={thisOriginal}
                        editableServiceGroup={thisEditable}
                        updateServiceGroup={updateServiceGroup}
                        removeServiceGroup={() => removeServiceGroup(jsId)}
                        preventRcEditing={preventRcEditing}
                        getNextServiceId={getNextServiceId}
                        allAreas={allAreas}
                    />
                )
            }
        })}
    </>)
}