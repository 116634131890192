import { dateTimeStrToMdy, dateToMdy } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { formatNameStringLastFirst } from "Globals/StringFormatting";
import clsx from "clsx";
import {
    GetInstallationAppointmentForContractorAfterDateQuery,
    GetPartialServiceOrdersForContractorQuery,
    GetWorkerBlockedTimeSlotsAfterDateQuery,
    InstallationAppointment,
} from "generated/graphql";
import { useMemo } from "react";
import { CalendarDay } from "./CalendarDay";
import { TOTAL_DAYS_TO_SHOW } from "./ContractorHome";

interface OpenToDayProps {
    openToDay: (dateString: string) => void;
}

interface ContractorCalendarProps {
    appointments: GetInstallationAppointmentForContractorAfterDateQuery["installationAppointmentForContractorAfterDate"];
    services: GetPartialServiceOrdersForContractorQuery["partialServiceOrdersForContractor"];
    blocked: GetWorkerBlockedTimeSlotsAfterDateQuery["workerBlockedTimeSlotsAfterDate"];
    days: string[];
    useFake: boolean;
}

let seed = 100;

function randomFrom0To1() {
    let a = seed * 15485863;
    const ret = ((a * a * a) % 2038074742) / 2038074743;
    seed++;
    return ret;
}

function randomWholeInRange(upper: number): number {
    return Math.floor(randomFrom0To1() * upper);
}

const colorOptions = [
    "var(--calendar-carpet-color)",
    "var(--calendar-spc-color)",
    "var(--calendar-wood-color)",
    "var(--calendar-service-color)",
];

export const cityNames = [
    "Livonia",
    "Pewamo",
    "Stockbridge",
    "Highland Park",
    "New Baltimore",
    "West Bloomfield",
    "Vernon",
    "Allegan",
    "South Lyon",
    "Crystal Falls",
    "Saline",
    "Port Huron",
    "Maple Rapids",
    "Caspian",
    "Chatham",
    "Millersburg",
    "Grass Lake",
];

export const customerNames = [
    "Reilly",
    "Waller",
    "Kramer",
    "Hampton",
    "Valentine",
    "Ferguson",
    "Hancock",
    "Lawrence",
    "Hurst",
    "ONeal",
    "Bravo",
    "Skinner",
    "Holloway",
    "Lambert",
    "Gross",
    "Green",
    "Hanson",
    "Weaver",
    "Rubio",
    "Snyder",
    "Ahmed",
    "Bond",
    "Solomon",
    "Hughes",
    "Solis",
    "Alexander",
    "Warren",
    "Zhang",
    "Barron",
    "Miranda",
    "Moody",
    "ODonnell",
    "Green",
    "Clements",
    "Faulkner",
    "Day",
    "Mathews",
    "Harmon",
    "Hayden",
    "Meyers",
    "Zamora",
    "Montoya",
    "Shepard",
    "Hess",
    "Bell",
    "Powers",
    "Weiss",
    "Newman",
    "Cortez",
    "Jimenez",
    "Turner",
    "McCarty",
    "Wilkins",
    "Cant",
];

export function createRandomishItems<T>(
    seedFunc: number,
    totalItems: number,
    startingDay: string,
    totalDays: number,
    makeData: (getRandom: (upper: number) => number, span: number) => T
): PositionedCalendarItem<T>[] {
    seed = seedFunc;
    const total = totalItems;

    const startingDate = new Date(startingDay);

    return [...new Array(total)].map((v, index) => {
        const d = new Date(startingDate);
        const startDayOffset = randomWholeInRange(totalDays);

        const span = ((randomWholeInRange(2) + randomWholeInRange(2) + 3) % 3) + 1;
        const blocks = [...new Array(span)]
            .map((v, index) => (randomWholeInRange(2) !== 0 ? index : -1))
            .slice(1, -1)
            .filter((v) => v !== -1);

        const dayStrings = [...new Array(span)]
            .map((_, index) => {
                if (blocks.includes(index)) return "";

                d.setDate(startingDate.getDate() + startDayOffset + index);
                return dateToMdy(d);
            })
            .filter((v) => v !== "");

        return {
            id: index.toString(),
            itemSpan: span,
            startDate: dayStrings[0],
            orderedMDYDates: dayStrings,
            data: makeData(randomWholeInRange, span),
        };
    });
}

function generateLocalItem(getRandom: (upper: number) => number): CalendarItemData {
    const colorIter = [...new Array(Math.ceil(getRandom(6) / 2))];
    const colors: string[] =
        colorIter.length === 0
            ? [colorOptions[3]]
            : colorIter
                  .map(() => colorOptions[getRandom(3)])
                  .reduce<string[]>((arr, curr) => {
                      if (!arr.some((a) => a === curr)) arr.push(curr);
                      return arr;
                  }, []);

    const label =
        colorIter.length === 0 ? "Service" : customerNames[getRandom(customerNames.length)];

    return {
        darkCard: true,
        colors: colors,
        amountLabel: "768sf",
        customerName: label,
        cityName: cityNames[getRandom(cityNames.length)],
    };
}

function createColorArray(app: InstallationAppointment): string[] {
    return [
        app.woodTotal > 0 ? "var(--flat-wood-color)" : "",
        app.carpetTotal > 0 ? "var(--flat-carpet-color)" : "",
        app.spcTotal > 0 ? "var(--flat-spc-color)" : "",
    ].filter((col) => col !== "");
}

export function createAmountLabel(app: InstallationAppointment): string {
    var amount: string[] = [];
    if (app.woodTotal > 0) amount.push(app.woodTotal + " sf");
    if (app.carpetTotal > 0) amount.push((app.carpetTotal / 9).toFixed(0) + " sy");
    if (app.spcTotal > 0) amount.push(app.spcTotal + " sf");

    return amount.join(", ");
}

export function ContractorAppointmentCalendar({
    days,
    appointments,
    services,
    blocked,
    openToDay,
    useFake,
}: ContractorCalendarProps & OpenToDayProps) {
    const appointmentItems: PositionedCalendarItem<CalendarItemData>[] = useMemo(() => {
        return appointments.map((app) => {
            const dayStrings = app.dates.map((date) => dateTimeStrToMdy(date));

            return {
                id: `A-${app.id}`,
                startDate: dayStrings[0],
                itemSpan: app.totalDaysInRange,
                orderedMDYDates: dayStrings,
                data: {
                    darkCard: app.isComplete,
                    colors: createColorArray(app),
                    amountLabel: createAmountLabel(app),
                    customerName: formatNameStringLastFirst({
                        firstName: app.customerFirstName,
                        lastName: app.customerLastName,
                    }),
                    cityName: app.customerAddress.city,
                },
            };
        });
    }, [appointments]);

    const blockedItems: string[] = useMemo(() => {
        return blocked.map((block) => dateTimeStrToMdy(block.date));
    }, [blocked]);

    const serviceItems: PositionedCalendarItem<CalendarItemData>[] = useMemo(() => {
        return services
            .filter((ser) => isNotNullOrUndefined(ser.scheduledDate))
            .map((service) => {
                const serviceDate = dateTimeStrToMdy(service.scheduledDate!);
                return {
                    id: `S-${service.id}`,
                    startDate: serviceDate,
                    orderedMDYDates: [serviceDate],
                    itemSpan: 1,
                    data: {
                        darkCard: false,
                        colors: ["var(--calendar-service-color)"],
                        amountLabel: "",
                        cityName: service.serviceDescription,
                        customerName: "",
                    },
                };
            });
    }, [services]);

    const items = useFake
        ? createRandomishItems(4700, 40, days[0], TOTAL_DAYS_TO_SHOW, generateLocalItem)
        : [...appointmentItems, ...serviceItems];

    const blockedDays = useFake ? ["12/4/2022", "12/5/2022", "12/7/2022"] : blockedItems;

    const grouped = groupCalendarItems<CalendarItemData>(new Date(days[0]), days.length, items);

    const chunkedDays = useMemo(() => {
        const rowCount = days.length / 7;
        return [...Array(rowCount)].map((v, index) => {
            return { days: days.slice(7 * index, 7 * (index + 1)), startingIndex: index * 7 };
        });
    }, [days]);

    const todayDayOfWeek = new Date().getDay();

    return (
        <div className="fill-width contractor-calendar">
            <div>
                <div className={clsx({ "contractor-title-today": todayDayOfWeek === 1 })}>
                    <div>Mon</div>
                </div>
                <div className={clsx({ "contractor-title-today": todayDayOfWeek === 2 })}>
                    <div>Tue</div>
                </div>
                <div className={clsx({ "contractor-title-today": todayDayOfWeek === 3 })}>
                    <div>Wed</div>
                </div>
                <div className={clsx({ "contractor-title-today": todayDayOfWeek === 4 })}>
                    <div>Thu</div>
                </div>
                <div className={clsx({ "contractor-title-today": todayDayOfWeek === 5 })}>
                    <div>Fri</div>
                </div>
                <div className={clsx({ "contractor-title-today": todayDayOfWeek === 6 })}>
                    <div>Sat</div>
                </div>
                <div className={clsx({ "contractor-title-today": todayDayOfWeek === 0 })}>
                    <div>Sun</div>
                </div>
            </div>
            {chunkedDays.map((week) => (
                <ContractorAppointmentWeek
                    key={week.days[0]}
                    days={week.days}
                    blockedDays={blockedDays}
                    events={grouped.slice(week.startingIndex, week.startingIndex + 7)}
                    openToDay={openToDay}
                />
            ))}
        </div>
    );
}

interface AppointmentWeekProps {
    days: string[];
    blockedDays: string[];
    events: (CalendarItemProps<CalendarItemData> | undefined)[][];
}

function ContractorAppointmentWeek({
    days,
    events,
    blockedDays,
    openToDay,
}: AppointmentWeekProps & OpenToDayProps) {
    // Monday = 0, ... Sunday = 6
    // The getDay() function is Sunday = 0, ... Saturday = 6
    const today = new Date();
    const todayStr = dateToMdy(today);

    return (
        <>
            <div>
                {days.map((day, index) => (
                    <CalendarDay
                        key={day}
                        day={day}
                        isBlocked={blockedDays.includes(day)}
                        items={events[index]}
                        isToday={todayStr === day}
                        isEndOfWeek={index === 6}
                        onClick={events[index].length > 0 ? () => openToDay(day) : undefined}
                    />
                ))}
            </div>
            <div style={{ height: "2px", minHeight:"0px", backgroundColor: "var(--flat-gray-2)" }} />
        </>
    );
}

// function ContractorAppointmentColorBlock({ color }: { color: string }) {
//     return <div style={{ backgroundColor: color, flex: 1 }} />;
// }

export interface CalendarItemProps<T> {
    hasPreviousDay: boolean;
    hasNextDay: boolean;
    // isBlocked: boolean;
    orderedMDYDates: string[];
    dayInSpan: number;
    data: T;
}

export interface CalendarItemData {
    darkCard: boolean;
    colors: string[];
    amountLabel: string;
    cityName: string;
    customerName: string;
}

export interface PositionedCalendarItem<T> {
    id: string;
    startDate: string;
    orderedMDYDates: string[];
    itemSpan: number;
    data: T;
}

export function groupCalendarItems<T>(
    startingDay: Date,
    daysToCover: number,
    items: PositionedCalendarItem<T>[]
): (CalendarItemProps<T> | undefined)[][] {
    const itemsByStartDay = items.reduce((total, item) => {
        total[item.startDate] = [...(total?.[item.startDate] ?? []), item];
        return total;
    }, {} as { [key: string]: PositionedCalendarItem<T>[] });

    // Determine if there
    const maxDaySpan = Math.max(...items.map((i) => i.itemSpan), 1);
    const dayOffset = maxDaySpan - 1;

    const date = startingDay;
    date.setDate(date.getDate() - dayOffset);

    // Creates empty array for output with the correct width and empty arrays for each day
    // Even if the appointment were on the first or last day, adding the day offset t othe size prevents oob issues
    const output: (CalendarItemProps<T> | undefined)[][] = [
        ...new Array(daysToCover + dayOffset * 2),
    ].map(() => []);

    function growColumnToLength(columnIndex: number, rowCount: number) {
        while (output[columnIndex].length < rowCount) output[columnIndex].push(undefined);
    }

    function insertItem(
        data: T,
        columnIndex: number,
        itemSpan: number,
        rowIndex: number,
        orderedMDYDates: string[]
    ) {
        if (output[columnIndex].length <= rowIndex) {
            // If current items row index will cause column height to increase, need to ripple this change back to all previous days
            // That have appointments that span into this day
            let rippleBackOffset = 0;
            while (
                columnIndex - rippleBackOffset > 0 &&
                output[columnIndex - rippleBackOffset].some((item) => item?.hasPreviousDay)
            ) {
                rippleBackOffset++;
                growColumnToLength(columnIndex - rippleBackOffset, rowIndex + 1);
            }

            let rippleForwardOffset = 0;
            while (
                columnIndex + rippleForwardOffset < output.length &&
                output[columnIndex + rippleForwardOffset].some((item) => item?.hasNextDay)
            ) {
                rippleForwardOffset++;
                growColumnToLength(columnIndex + rippleForwardOffset, rowIndex + 1);
            }
        }
        const iterator = [...new Array(itemSpan)];
        iterator.forEach((v, offset) => {
            // May need to grow the height of the column
            growColumnToLength(
                columnIndex + offset,
                Math.max(rowIndex + 1, output[columnIndex].length)
            );

            // Column will surely fit the index, so can be inserted directly
            output[columnIndex + offset][rowIndex] = {
                data: data,
                hasPreviousDay: offset > 0,
                hasNextDay: offset + 1 < itemSpan,
                dayInSpan: offset + 1,
                orderedMDYDates,
            };
        });
    }

    const iterator = [...new Array(daysToCover + dayOffset)];
    iterator.forEach((i, index) => {
        const itemsToProcess = itemsByStartDay[dateToMdy(date)] ?? [];

        // Iterate on date
        date.setDate(date.getDate() + 1);

        // No Appointments start on this day
        if (itemsToProcess.length === 0) return;

        // Sorts by descending length of span (want the longer spanning one placed first)
        itemsToProcess.sort((a, b) => -(a.itemSpan - b.itemSpan));

        itemsToProcess.forEach((item) => {
            // Finds the first open row to put this item into
            let rowIndex = output[index].findIndex((slot) => slot === undefined);

            // If there was no open row, need to add a new one
            if (rowIndex === -1) rowIndex = output[index].length;

            insertItem(item.data, index, item.itemSpan, rowIndex, item.orderedMDYDates);
        });
    });

    // Trim off the excess columns
    const trimmedOutput = output.slice(dayOffset, dayOffset + daysToCover);

    return trimmedOutput;
}
