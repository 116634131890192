import { Route, RouteProps } from "react-router-dom";
import RequirePermission, { RequirePermissionProps } from "./RequirePermission";

type PermissionRouteProps = RequirePermissionProps & RouteProps

export default function PermissionRoute({ permissions, children, ...routeProps }: PermissionRouteProps) {

    return (
        <Route {...routeProps}>
            <RequirePermission permissions={permissions}>
                {children}
            </RequirePermission>
        </Route>
    )
}