import { useGetAllRecoveryReasonOptionsQuery, useGetAllRecoveryStepOptionsQuery } from "generated/graphql";
import { BaseColumnProps } from "./RecoveryCenterPage";
import ClaimedRecoveryCenterCard from "./ClaimedRecoveryCenterCard";
import RecoveryCenterColumn, { RecoveryCenterColumnProps } from "./VisualComponents/RecoveryColumn";

type ClaimedColumnProps = BaseColumnProps & RecoveryCenterColumnProps

function ClaimedColumn({ jobs, ...columnProps }: ClaimedColumnProps) {
    const { data: reasonData, loading: loadingReason } = useGetAllRecoveryReasonOptionsQuery();
    const { data: stepData, loading: loadingStep } = useGetAllRecoveryStepOptionsQuery();
    if (loadingReason || loadingStep) jobs = []

    const reasonOptions = reasonData?.allRecoveryReasonOptions ?? []
    const stepOptions = stepData?.allRecoveryStepOptions ?? []

    return (
        <RecoveryCenterColumn {...columnProps}>
            {jobs.map(job => (
                <ClaimedRecoveryCenterCard
                    key={`claimed-${job.id}`}
                    job={job}
                    reasonOptions={reasonOptions}
                    stepOptions={stepOptions}
                />
            ))}
        </RecoveryCenterColumn>
    );
}

export default ClaimedColumn;