import { useGetProductStyleIdsQuery } from "generated/graphql";
import { CARPET_PRODUCT_ID } from "Globals/globalConstants";
import { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { selectCostVendorId } from "Redux/productCostsReducer";
import CarpetCostsStyleRow from "../CarpetMode/CarpetCostsStyleRow";
import HardCostStyleRow from "../HardMode/HardCostsStyleRow";

interface ProductCostsTableProps {
    productTypeId: number,
    height?: string
}

export default function ProductCostsTable({ productTypeId, height }: ProductCostsTableProps) {

    const vendorId = useAppSelector(selectCostVendorId)

    const { data } = useGetProductStyleIdsQuery({ variables: { productTypeId, vendorId }, skip: vendorId === -1 })
    const renderRow = data?.productStyleIdsForProductType ?? []

    const [scrollHeight, setScrollHeight] = useState<number>(0);

    function handleScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
        if (e.currentTarget.scrollHeight !== scrollHeight) {
            setScrollHeight(e.currentTarget.scrollHeight)
            e.currentTarget.scrollTop = 0
        }
    }

    const isCarpet = productTypeId === CARPET_PRODUCT_ID

    const render = (isCarpet) ? renderSoftStyleRow : renderHardStyleRow

    return (
        <div className="table-fixed-new fill-width" style={{ height }} onScroll={handleScroll}>
            <table>
                <colgroup>
                    <col className="w-5r" />
                    <col width="20%" />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                </colgroup>
                <thead>
                    <tr>
                        <td align="center" colSpan={2}>Style</td>
                        <td />
                        <td align="center">{isCarpet ? "Cut" : "Box"} Cost</td>
                        <td align="center">New Cost</td>
                        <td align="center">% Diff</td>
                        <td align="center">{isCarpet ? "Roll" : "Pallet"} Cost</td>
                        <td align="center">New Cost</td>
                        <td align="center">% Diff</td>
                    </tr>
                </thead>
                <tbody>
                    {renderRow.map(render)}
                </tbody>
            </table>
        </div>
    )
}

const renderHardStyleRow = (styleId: number) => <HardCostStyleRow key={`costs-row-${styleId}`} styleId={styleId} />
const renderSoftStyleRow = (styleId: number) => <CarpetCostsStyleRow key={`costs-row-${styleId}`} styleId={styleId} />