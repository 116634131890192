import { Select, MenuItem } from "@material-ui/core"
import clsx from "clsx"
import { useGetAllValidProductStyleOptionsForConfigurationQuery } from "generated/graphql"
import { isNullOrUndefined } from "Globals/GenericValidators"


interface PStyleSelectorProps {
    pid?: number,
    substrateIdsInArea: number[]
    sid?: number,
    setStyleId: (newSid: number, name: string, installServiceId: number, materialId?: number) => void,
    disabled?: boolean,
    className?: string,
    label?: string
}


export default function ProductStyleSelect({ pid, substrateIdsInArea, sid, setStyleId, disabled, className, label }: PStyleSelectorProps) {

    const noPid = isNullOrUndefined(pid)

    const { data, loading } = useGetAllValidProductStyleOptionsForConfigurationQuery({ variables: { productTypeId: pid!, substrateIdsInArea }, skip: noPid })

    const styleOps = (noPid || loading) ? [] : (data?.allValidProductStyleOptionsForConfiguration ?? [])

    return (
        <>
            <label htmlFor="prod-style-select" className={clsx("margin-side-xsm", className)}>{label ?? "Product Style:"}</label>
            <Select disabled={disabled}
                className={clsx('w-10r', className)}
                name="prod-style-select"
                value={sid ?? -1}
                onChange={(e) => {
                    const newId = e.target.value as number
                    const selectedStyle = styleOps.filter(sty=>sty.id === newId)[0]
                    setStyleId(newId, selectedStyle.style, selectedStyle.defaultInstallJobServiceId!, selectedStyle.materialForDefaultInstallServiceId ?? undefined)
                }}
            >
                {loading && <MenuItem value={sid ?? -1} hidden>Loading...</MenuItem>}
                {styleOps.map(op => <MenuItem key={`product-style-op-${op.id}`} className={clsx({'line-through': !op.valid})} value={op.id} disabled={!op.valid}>{op.style}</MenuItem>)}
                <MenuItem value={-1} hidden>No Product</MenuItem>
            </Select>
        </>
    )
}