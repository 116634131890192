import clsx from "clsx";
import { CutBlock, FootInchText, lengthToFootInch } from "./SeamPlanner";
import { Typography } from "@material-ui/core";

export function CutBlockListItem({
    totalLength,
    blocks,
    selectedPieceIds,
    setSelectedPieceIds,
}: {
    totalLength: number;
    blocks: CutBlock[];
    selectedPieceIds?: number[];
    setSelectedPieceIds?: (newIds: number[]) => void;
}) {
    const totalBlockLength = blocks
        .map((block) => block.length)
        .reduce((partial, a) => partial + a, 0);

    const wasteBlockLength = totalLength - totalBlockLength;
    const { footLength, inchLength } = lengthToFootInch(wasteBlockLength);
    const { footLength: totalFootLength, inchLength: totalInchLength } =
        lengthToFootInch(totalLength);

    return (
        <div
            className="flex-column"
            style={{ marginLeft: "6px" }}
        >
            <Typography style={{ fontSize: "1.1em" }}>Cut List:</Typography>
            {blocks.map((block) => {
                return (
                    <CutBlockItem
                        key={block.blockNumber}
                        isSelected={block.pieces.some((piece) =>
                            selectedPieceIds?.includes(piece.id)
                        )}
                        footPart={block.footPart}
                        inchPart={block.inchPart}
                        onClick={() => setSelectedPieceIds?.(block.pieces.map((piece) => piece.id))}
                        blockName={block.names}
                    />
                );
            })}
            {wasteBlockLength > 0 && (
                <CutBlockItem
                    isSelected={false}
                    footPart={footLength}
                    inchPart={inchLength}
                    blockName="Waste"
                />
            )}
            <div style={{ fontWeight: "bold", borderTop: "1px solid black" }}>
                Total Length:{" "}
                <FootInchText
                    foot={totalFootLength}
                    inch={totalInchLength}
                />
            </div>
        </div>
    );
}

interface CutBlockItemProps {
    isSelected: boolean;
    onClick?: () => void;
    footPart: number;
    inchPart: number;
    blockName: string;
}

function CutBlockItem({ isSelected, onClick, footPart, inchPart, blockName }: CutBlockItemProps) {
    return (
        <div
            className={clsx(
                {
                    "cut-block-selected": isSelected,
                },
                "cut-block-list-item"
            )}
            onClick={onClick}
        >
            12' x{" "}
            <FootInchText
                foot={footPart}
                inch={inchPart}
            />{" "}
            {blockName}
        </div>
    );
}
