import { Dialog, DialogActions, DialogContent, DialogTitle, Radio, Typography } from "@material-ui/core";
import clsx from "clsx";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { FinancingOption, namedOperations, useAddFinancingOverrideMutation, useGetActiveFinancingOptionsQuery } from "generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { selectJobConfigurationId, selectJobCost, selectTotalPrice } from "Redux/pricingCenterReducer";
import { calculateFinancingAdjustedRevenue, calculateMargin } from "../marginUtils";

interface SwapMenuProps {
    toSwap: FinancingOption;  // the current option which is being swapped for another
    clearToSwap: () => void;  // sets swapOption to null (and therefore closes the dialog)
    toSwapIsSelected: boolean;  // indicates whether the config currently has that option selected (for warning messages)
    currentlyDisplayedOptions: FinancingOption[];
}

interface SwapMenuItem {
    option: FinancingOption;
    margin: number; // what the margin of the job would become if that option was selected
    selectable: boolean;  // hide radio button when false
}

// used to sort swap menu items in DESCENDING order based on margin
function compareSwapMenuItems(o1: SwapMenuItem, o2: SwapMenuItem) {
    if (o1.margin > o2.margin) return -1;
    else if (o1.margin > o2.margin) return 1;
    else return 0;
}

export default function SwapMenu({toSwap, clearToSwap, toSwapIsSelected, currentlyDisplayedOptions}: SwapMenuProps) {
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const addMode = toSwap.tranCode === "ADD";

    const optionDescription = `${toSwap.term} Months ${toSwap.typeName} Payments`;
    const { data: activeOptionsData } = useGetActiveFinancingOptionsQuery({
        onError: () => alert("Could not retrieve active financing options"),
        fetchPolicy: "cache-first"
    });

    const [addFinancingOverride] = useAddFinancingOverrideMutation({
        onError: () => alert(`Could not ${addMode ? "add" : "replace"} financing option`),
        onCompleted: (res) => res.addFinancingOverride && clearToSwap(),
        // refetch GetPricingSummary because the job price could change if the existing financing option gets removed
        refetchQueries: [namedOperations.Query.GetJobFinancingOptions, namedOperations.Query.GetPricingSummary]
    });

    const jobPrice = useAppSelector(selectTotalPrice);
    const jobCost = useAppSelector(selectJobCost);
    const determineFinOpMargin = useCallback((option: FinancingOption) => {
        const financingAdjustedRevenue = calculateFinancingAdjustedRevenue(jobPrice, option.fee);
        return calculateMargin(financingAdjustedRevenue, jobCost);
    }, [jobPrice, jobCost]);
    
    // prepare the list of options that can be selected for swapping
    const swappableOptions: SwapMenuItem[] = useMemo(() => {
        const currentlyDisplayedIds = currentlyDisplayedOptions.map(o => o.id);
        // don't allow to swap to any of the options that are currently displayed
        const validOptions: FinancingOption[] = activeOptionsData?.activeFinancingOptions.filter(afo => !currentlyDisplayedIds.includes(afo.id)) ?? [];
        let swapItems: SwapMenuItem[] = validOptions.map(option => {
            const margin = determineFinOpMargin(option)
            const selectable = (jobPrice >= +option.minAmount.toFixed(2) && margin >= 34);

            return {option, margin, selectable}
        });

        // sort by margin in DESCREASING order
        swapItems.sort(compareSwapMenuItems);

        // display the item being swapped at the top of the table (if it isn't the new swap option)
        if (!addMode) {
            const toSwapMenuItem = {option: toSwap, margin: determineFinOpMargin(toSwap), selectable: true};
            swapItems = [toSwapMenuItem, ...swapItems]
        }

        return swapItems;
    }, [activeOptionsData?.activeFinancingOptions, currentlyDisplayedOptions, determineFinOpMargin, jobPrice, addMode, toSwap]);

    // default to idx 0 when not adding since the one we are swapping should be selected by default
    const [selectionIdx, setSelectionIdx] = useState(addMode ? -1 : 0);

    function onSwap() {
        if (jobConfigurationId < 1) {
            alert("Could not replace financing option - please try again");
            return;
        } 

        if (selectionIdx < 0) {
            alert("Select an option");
            return;
        }

        if (toSwapIsSelected) {
            if (!window.confirm("Warning: Customer has selected the option you are attempting to replace - replacing this option will remove financing from this job. Are you sure you wish to continue?")) {
                clearToSwap(); // closes the dialog
                return;
            }
        }

        if (swappableOptions[selectionIdx].margin < 38) {
            if (!window.confirm("The margin will drop below 38% if this option is selected - are you sure you want to make this option available?")) {
                return;
            }
        }

        addFinancingOverride({
            variables: {
                idOfOptionToReplace: addMode ? null : toSwap.id,
                financingOverride: {
                    jobConfigurationId: jobConfigurationId,
                    financingOptionId: swappableOptions[selectionIdx].option.id,
                    slot: toSwap.slot
                }
            }
        });
    }

    return (
        <Dialog open={true}>
            <DialogTitle>{addMode ? "Add Financing Option" : `Replace Option: ${optionDescription}`}</DialogTitle>

            <DialogContent>
                {toSwapIsSelected && <Typography className="error-text margin-bottom-sm">WARNING: The financing option pending replacement is currently selected by the customer. Replacing this option will remove customer's selection.</Typography>}
                
                {swappableOptions.findIndex(opt => opt.selectable) === -1 && (
                    <Typography className="margin-bottom-sm">NOTE: None of these options may be selected, either due to margin being too low or the job not meeting the minimum threshold</Typography>
                )}
                
                <div className="flex-row justify-content-center">
                    <table className="solid-border padding-sm">
                        <thead className="solid-border-bottom" style={{backgroundColor: "gray"}} >
                            <tr>
                                <th className="solid-border-right"/>
                                <th className="w-5r solid-border-right padding-side-xsm">Type</th>
                                <th className="w-6r solid-border-right padding-side-xsm">Term</th>
                                <th className="w-6r solid-border-right padding-side-xsm">Minimum Amount</th>
                                <th className="w-5r padding-side-xsm">Margin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {swappableOptions.map((opt, idx) => (
                                <tr style={{backgroundColor: idx % 2 === 0 ? "white" : "lightgray"}} key={opt.option.id}>
                                    <td className="solid-border-right padding-side-xsm">
                                        <Radio
                                            onClick={() => setSelectionIdx(idx)}
                                            checked={idx === selectionIdx}
                                            style={{visibility: opt.selectable ? "visible" : "hidden"}}
                                            disabled={opt.option.id === toSwap.id}    
                                        />
                                    </td>
                                    <td className="solid-border-right padding-side-xsm">{opt.option.typeName}</td>
                                    <td className="solid-border-right padding-side-xsm">{opt.option.term} Months</td>
                                    <td className={clsx("solid-border-right", "padding-side-xsm", jobPrice < +opt.option.minAmount.toFixed(2) && "error-text")}>${opt.option.minAmount.toFixed(2)}</td>
                                    <td className={clsx(opt.margin < 38 && "error-text", "padding-side-xsm")}>{opt.margin.toFixed(2)}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </DialogContent>

            <DialogActions>
                <SpacedButton variant="contained" className='cancel-button' onClick={clearToSwap}>Cancel</SpacedButton>
                <SpacedButton
                    variant="contained" color="secondary" 
                    onClick={onSwap} disabled={!addMode && selectionIdx === 0}
                >{addMode ? "Add" : "Replace"}</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}