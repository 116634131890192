import { Button, createStyles, makeStyles, Popover, PropTypes } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';
import { ProductTypeOld } from 'generated/graphql';
import { useEffect, useRef, useState } from 'react';
import { BoxCountInput, CutLengthInput, InputAmountPickerOption, PalletCountInput, PieceCountInput, RollCountInput } from './ItemAmountPickerOptions';

/**
 *                 LVP          TM          Carpet
 * Measure unit:    sf          ft            ft
 * Stock unit:      ctn         pc           roll
 * Order unit:    ctn/plt   pc/ctn/plt    ft / roll    
 * 
 * TM is stored as either whole or half pieces
 */


const DEFAULT_WIDTH = 200
const DEFAULT_HEIGHT = 30
const INPUT_FIELD_WIDTH = "2.5rem"

export type InputType = "ctn" | "plt" | "pc" | "ftin" | "roll"

export interface InputValue {
    primary: string,
    secondary: string
}

export interface ItemAmount {
    type: InputType,
    value: InputValue
}

type PickerOptions = { [K in InputType]: InputAmountPickerOption }

const pickerOptions: PickerOptions = {
    'ctn': BoxCountInput,
    'plt': PalletCountInput,
    'pc': PieceCountInput,
    'ftin': CutLengthInput,
    'roll': RollCountInput
} as const

export const productTypeToDefaultOptions: { [K in ProductTypeOld]: InputType[] } = {
    [ProductTypeOld.Carpet]: ['roll', 'ftin'],
    [ProductTypeOld.Lvp]: ['plt', 'ctn'],
    [ProductTypeOld.LvpTm]: ['pc', 'ctn', 'plt']
}

const useStyles = (width?: number, height?: number) => {
    width = width ?? DEFAULT_WIDTH
    height = height ?? DEFAULT_HEIGHT
    const borderWidth = 1
    const expandButtonWidth = height
    const dropdownContentWidth = width - expandButtonWidth - 2 * borderWidth

    return makeStyles(() =>
        createStyles({
            root: {
                display: 'flex',
                flexDirection: "column",
                height: "100vh",
            },
            pickerBar: {
                width: width,
                borderStyle: "solid",
                borderWidth: borderWidth,
                borderRadius: "5px",
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
            },
            pickerButton: {
                width: expandButtonWidth,
                height: expandButtonWidth,
                minWidth: 0,
                minHeight: 0
            },
            pickerSelected: {
                height: height,
                width: dropdownContentWidth
            },
            pickerOpen: {
                borderBottomLeftRadius: 0,
                borderBottomStyle: "none"
            },
            pickerMenu: {
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                borderStyle: "none solid solid solid",
                borderWidth: borderWidth,
            },
            pickerContent: {
                width: dropdownContentWidth,
                display: "flex",
                flexDirection: "column",
            },
            pickerOption: {
                height: height,
                width: "100%",
                fontSize: ".875rem",
                overflow: "hidden",
                borderTopStyle: "solid",
                borderWidth: borderWidth,
                '&:hover': {
                    backgroundColor: "#D3D3D3"
                }
            }
        }),
    )();
}

type BuildConversionMapper = (boxesPerPallet?: number, feetPerRoll?: number) => ((sourceAmount: ItemAmount, target: InputType) => InputValue)

const buildConversionMapper: BuildConversionMapper = (boxesPerPallet?: number, feetPerRoll?: number) => {
    return (sourceAmount: ItemAmount, target: InputType): InputValue => {
        const PLT_TO_CTN = boxesPerPallet ?? 35;
        const CTN_TO_PLT = 1 / PLT_TO_CTN;
        const ROLL_TO_FT = feetPerRoll ?? 150

        const source = sourceAmount.type;
        const primary: number = Math.ceil(+sourceAmount.value?.primary)
        const secondary: number = Math.ceil(+sourceAmount.value?.secondary)

        if (isNaN(primary) || isNaN(secondary) || sourceAmount.value.primary.split(" ").join("") === "") return { primary: "", secondary: "" }

        if (source === 'plt' && target === "ctn") {
            return { primary: (primary * PLT_TO_CTN).toString(), secondary: secondary.toString() }
        }
        else if (source === 'ctn' && target === "plt") {
            return { primary: (Math.ceil(primary * CTN_TO_PLT ?? 0)).toString(), secondary: secondary.toString() }
        }
        else if (source === 'roll' && target === "ftin") {
            return { primary: (ROLL_TO_FT).toString(), secondary: secondary.toString() }
        }
        else if (source === 'ftin' && target === "roll") {
            return { primary: (Math.min(Math.ceil(primary ?? 0), 1)).toString(), secondary: secondary.toString() }
        }
        else {
            return { primary: primary.toString(), secondary: secondary.toString() }
        }
    }
}

interface ValueUnitPair {
    value: string,
    unit: string
}

function conditionallyBuildString(valueUnitPairs: ValueUnitPair[]): string {
    const nanFilteredPairs = valueUnitPairs.filter(pair => isNaN(+pair.value) === false)
    if (nanFilteredPairs.length === 0) return ""
    else return `(${nanFilteredPairs.map(pair => `${pair.value} ${pair.unit}`).join(", ")})`
}

type BuildAdditionalTextGenerator = (boxesPerPallet?: number, cartonToSqft?: number, type?: ProductTypeOld) => (amount: ItemAmount) => string

const buildAdditionalTextGenerator: BuildAdditionalTextGenerator = (boxesPerPallet?: number, cartonToSqft?: number, type?: ProductTypeOld) => {
    if (boxesPerPallet && cartonToSqft && type) {
        return (amount: ItemAmount) => {
            let valueUnitPairs: ValueUnitPair[] = [];
            if (amount.value?.primary) {
                if (type === ProductTypeOld.Lvp) {
                    if (amount.type === 'ctn') {
                        const value = +amount.value.primary * cartonToSqft;
                        valueUnitPairs = [...valueUnitPairs, { value: value.toFixed(1), unit: "sf." } as ValueUnitPair]
                    } else if (amount.type === 'plt') {
                        const carton = +amount.value.primary * boxesPerPallet;
                        const pltSf = carton * cartonToSqft;
                        valueUnitPairs = [
                            ...valueUnitPairs,
                            { value: carton.toFixed(0), unit: "ctn" } as ValueUnitPair,
                            { value: pltSf.toFixed(1), unit: "sf." } as ValueUnitPair
                        ]
                    }
                }
                else if (type === ProductTypeOld.LvpTm) {
                    if (amount.type === 'pc') {
                        const value = +amount.value.primary * cartonToSqft;
                        valueUnitPairs = [...valueUnitPairs, { value: value.toFixed(1), unit: "ft." } as ValueUnitPair]
                    } else if (amount.type === "ctn") {

                    }
                }
            }
            return conditionallyBuildString(valueUnitPairs)
        }
    }
    else return (amount: ItemAmount) => ""
}

interface ItemAmountPickerProps {
    type: ProductTypeOld,
    amount: ItemAmount,
    onChange?: (e: ItemAmount) => void,
    manualOptions?: InputType[], //Can be used to manually set the options for the picker
    boxesPerPallet?: number,
    feetPerRoll?: number,
    cartonToSqft?: number,
    width?: number,
    height?: number,
    color?: PropTypes.Color,
}

export default function ItemAmountPicker({ type, amount, onChange, manualOptions, width, height, color, boxesPerPallet, feetPerRoll, cartonToSqft }: ItemAmountPickerProps) {
    const classes = useStyles(width, height);
    const transformHorizontalOffset = (width ?? DEFAULT_WIDTH) / 2 + 1

    const selectedInputType = amount.type

    //Options to be displayed when menu is open
    let options: InputType[] = manualOptions ?? productTypeToDefaultOptions[type]

    //States
    const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

    //Refs
    const menuRootRef = useRef(null)
    const selectedRef = useRef<HTMLInputElement>(null)

    //Helper Functions
    const openMenu = () => setMenuAnchor(menuRootRef.current)
    const closeMenu = () => setMenuAnchor(null)
    const onChangeInput = (caller: InputType, value: InputValue) => onChange?.({ type: caller, value })
    const conversionMapper = buildConversionMapper(boxesPerPallet, feetPerRoll)
    const convertAmountTo = (target: InputType) => (amount) ? conversionMapper(amount, target) : { primary: "", secondary: "" }
    const generateAdditionalText = buildAdditionalTextGenerator(boxesPerPallet, cartonToSqft, type)

    //Update ref for selected picker whenever input type changes
    useEffect(() => {
        if (selectedRef.current) {
            selectedRef.current.focus()
        }
        return () => { }
    }, [amount.type])

    //Uses a capitalized name so it can be called using < /> notation 
    const SelectedPickerComponent = pickerOptions[amount.type]

    const additionalText = generateAdditionalText(amount)

    return (
        <>
            <div ref={menuRootRef} className={clsx(classes.pickerBar, { [classes.pickerOpen]: (menuAnchor !== null) })}>
                <div className={classes.pickerSelected}>
                    <SelectedPickerComponent
                        ref={selectedRef}
                        inputFieldWidth={INPUT_FIELD_WIDTH}
                        value={amount?.value ?? { primary: "", secondary: "" }}
                        onChange={(value) => onChangeInput(selectedInputType, value)}
                        additionalText={additionalText} />
                </div>
                <Button
                    color={color ?? "primary"}
                    variant="contained"
                    disabled={options.length <= 1}
                    onClick={openMenu}
                    className={classes.pickerButton}>
                    <ArrowDropDownIcon />
                </Button>
            </div>
            <Popover
                className={classes.pickerMenu}
                open={menuAnchor !== null}
                anchorEl={menuAnchor}
                onClose={closeMenu}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ horizontal: transformHorizontalOffset, vertical: "top" }}
                PaperProps={{ square: true, className: classes.pickerMenu }}
            >
                <div className={classes.pickerContent}>
                    {options.filter(option => option !== selectedInputType)
                        .map(
                            PickerOption => {
                                const PickerOptionComponent = pickerOptions[PickerOption]
                                const valueToRender: InputValue = convertAmountTo(PickerOption)
                                const previewAdditionalText = generateAdditionalText({ type: PickerOption, value: valueToRender })
                                return (
                                    <div
                                        key={`item-${PickerOption}`}
                                        className={classes.pickerOption}
                                        onClick={() => {
                                            const newAmount = valueToRender
                                            onChangeInput(PickerOption, newAmount)
                                            closeMenu()
                                        }}>
                                        <PickerOptionComponent
                                            inputFieldWidth={INPUT_FIELD_WIDTH}
                                            disabled
                                            value={valueToRender}
                                            additionalText={previewAdditionalText} />
                                    </div>
                                )
                            })}
                </div>
            </Popover>
        </>
    )
}