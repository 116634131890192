import { IconButton } from "@material-ui/core";
import { FullServiceMaterialCategoryPricing, GetServiceMaterialCategoryHistoryDocument, GetServiceMaterialCategoryHistoryQueryVariables, useUpdateServiceMaterialCategoryPricingsMutation } from "generated/graphql";
import { useState } from "react";
import clsx from "clsx";
import { isNumber } from "Globals/GenericValidators";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ServiceValueEditor from "./ServiceValueEditor";
import ServiceProductHistoryTable from "./ServiceProductHistoryTable";



export interface ServiceProductPricingTableRowData extends Omit<FullServiceMaterialCategoryPricing, 'productTypeId' | 'productType'> {
    singleProductType: string | null,
    groupedHistoryIds: number[]
}

interface ServiceProductPricingTableRowProps extends ServiceProductPricingTableRowData {
    editByProduct: () => void
}

export default function ServiceProductPricingTableRow({ editByProduct, ...category }: ServiceProductPricingTableRowProps) {
    const { serviceMaterialCategory, singleProductType, pricePerUnit, costPerUnit, priceUnit } = category

    const [update, { loading }] = useUpdateServiceMaterialCategoryPricingsMutation({
        onCompleted() {
            setUpdatedPrice(null)
            setUpdatedCost(null)
        },
        refetchQueries: [{
            query: GetServiceMaterialCategoryHistoryDocument,
            variables: { serviceMaterialCategoryProductPricingId: category.id } as GetServiceMaterialCategoryHistoryQueryVariables
        }]
    })

    const isMultiProduct = singleProductType === null
    const categoryLabel = isMultiProduct ? serviceMaterialCategory : `${serviceMaterialCategory} - [${singleProductType}]`

    const [updatedPrice, setUpdatedPrice] = useState<string | null>(null)
    const [updatedCost, setUpdatedCost] = useState<string | null>(null)
    const [showHistory, setShowHistory] = useState<boolean>(false)

    const displayPrice = updatedPrice ?? pricePerUnit.toFixed(2)
    const displayCost = updatedCost ?? costPerUnit.toFixed(2)

    const markup = tryCalculateMarkup(displayPrice, displayCost)

    function ungroup() {
        setUpdatedPrice(null)
        setUpdatedCost(null)
        editByProduct()
    }

    const costChanges = updatedCost !== null && +updatedCost !== costPerUnit
    const priceChanges = updatedPrice !== null && +updatedPrice !== pricePerUnit

    const areChanges = costChanges || priceChanges

    const canSubmit = areChanges && isNumber(displayPrice) && isNumber(displayCost)
    function submitChanges() {
        if (canSubmit) {
            update({
                variables: {
                    serviceMaterialCategoryProductPricingIds: category.groupedHistoryIds,
                    updatedCost: costChanges ? +displayCost : null,
                    updatedPrice: priceChanges ? +displayPrice : null
                }
            })
        }
        else {
            window.alert("Fix underlined values")
        }
    }

    return (
        <>
            <tr style={{ height: "2.75rem" }}>
                <td align="center" className="flex-row" style={{ justifyContent: "center", alignItems: "center", height: "2.75rem" }}>
                    <div>{categoryLabel}</div>
                    {
                        isMultiProduct &&
                        <button style={{ fontSize: ".75em", marginLeft: "1em" }} onClick={ungroup}>Edit by Product</button>
                    }
                </td>
                <td align="center" style={{ padding: "0 2rem" }}>
                    <ServiceValueEditor
                        disabled={loading}
                        value={displayPrice}
                        initialValue={pricePerUnit}
                        onChange={setUpdatedPrice}
                        unit={priceUnit} />
                </td>
                <td align="center" style={{ padding: "0 2rem" }}>
                    <ServiceValueEditor
                        disabled={loading}
                        value={displayCost}
                        initialValue={costPerUnit}
                        onChange={setUpdatedCost}
                        unit={priceUnit} />
                </td>
                <td align="center">
                    <button
                        style={{ fontSize: ".75em" }}
                        className={clsx({ 'visibility-hidden': !areChanges })}
                        onClick={submitChanges}>
                        Submit
                    </button>
                </td>
                <td align="center">
                    {markup?.toFixed(0) ?? "-"}%
                </td>
                <td align="center">
                    <IconButton onClick={() => setShowHistory(!showHistory)}>
                        {
                            showHistory ?
                                <ArrowDropDownIcon fontSize="small" /> :
                                <ArrowDropUpIcon fontSize="small" />
                        }
                    </IconButton>
                </td>
            </tr>
            {
                showHistory && <ServiceProductHistoryTable serviceMaterialCategoryProductPricingId={category.id} />
            }
        </>
    )
}


export function tryCalculateMarkup(price: string, cost: string): number | null {
    const numPrice = +price;
    const numCost = +cost;

    return (isNaN(numPrice) || isNaN(numCost) || numCost <= 0 || numPrice <= 0) ? null :
        calculateMarkup(numPrice, numCost)
}

export function calculateMarkup(price: number, cost: number): number {
    return ((price / cost) * 100) - 100
}

