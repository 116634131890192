import { Checkbox, Typography } from "@mui/material";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatInput from "FlatComponents/Inputs/FlatInput";
import { Market, namedOperations, useGetAllMarketsQuery, useUpdateMsrpScalarForMarketsMutation } from "generated/graphql";
import { isEmptyString } from "Globals/GenericValidators";
import { useState } from "react";
import NumberFormat from "react-number-format";

export default function MarketScalarTable() {
    const { data: marketData } = useGetAllMarketsQuery();
    const allMarkets = marketData?.allMarkets ?? [];
    const [selectedMarketIds, setSelectedMarketIds] = useState<number[]>([]);
    const [newScalar, setNewScalar] = useState('');
    
    function onToggleIsSelected(idToToggle: number) {
        if (selectedMarketIds.includes(idToToggle)) {
            setSelectedMarketIds(selectedMarketIds.filter(mId => mId !== idToToggle))
        } else {
            setSelectedMarketIds([...selectedMarketIds, idToToggle]);
        }
    }

    const [updateScalars] = useUpdateMsrpScalarForMarketsMutation({
        onError: () => alert("Could not update MSRP scalars"),
        refetchQueries: [namedOperations.Query.GetAllMarkets]
    });

    function onSubmit() {
        if (isEmptyString(newScalar)) {
            alert("Enter a value to scale by");
            return;
        }
        const scalarValue = +((+newScalar / 100) + 1).toFixed(2);

        const numMarkets = selectedMarketIds.length;
        if (numMarkets === 0) {
            alert("Select at least one market");
            return;
        }

        const confirmationStr = `WARNING: Are you sure you wish to update the MSRP scalar of ${numMarkets} market${numMarkets === 1 ? '' : 's'}. ` + 
        `The changes will take effect immediately, and could affect any jobs which are currently in the process of being sold. Are you sure you wish to continue?`

        if (window.confirm(confirmationStr)) {
            updateScalars({
                variables: {
                    marketIds: selectedMarketIds,
                    newScalar: scalarValue
                }
            }).then(() => {setSelectedMarketIds([]); setNewScalar('')});
        }
    }

    return (
        <div className="flex-column flex-gap-sm">
            <Typography className="align-self-flex-start" variant="h5">Market MSRP Scalars</Typography>
            <div className="flex-row flex-gap-sm justify-content-center">
                <NumberFormat
                    style={{width: "40px", textAlign: "right"}}
                    customInput={FlatInput}
                    placeholder="%"
                    suffix="%"
                    maxLength={3} // suffix counts as 1
                    decimalScale={0} fixedDecimalScale
                    allowLeadingZeros={false} allowNegative={false}
                    value={newScalar} onValueChange={(newVal) => setNewScalar(newVal.value)}
                />
                <FlatButton onClick={onSubmit}>Submit</FlatButton>
                <FlatButton
                    onClick={() => setSelectedMarketIds(allMarkets.map(m => m.id))}
                >Select All</FlatButton>
                <FlatButton
                    onClick={() => setSelectedMarketIds([])}
                >Clear Selection</FlatButton>
                <FlatButton
                    onClick={() => setSelectedMarketIds(allMarkets.map(m => m.id).filter(mId => !selectedMarketIds.includes(mId)))}
                >Invert Selection</FlatButton>
            </div>
            
            <table>
                <thead>
                    <tr style={{ backgroundColor: "lightgrey", height: "3rem" }}>
                        <td></td>
                        <td align="left" className="whitespace-no-wrap padding-right-sm">Market</td>
                        <td align="left" className="whitespace-no-wrap padding-right-sm">MSRP Scalar</td>
                    </tr>
                </thead>
                <tbody>
                    {allMarkets.map(m => (
                        <MarketScalarRow
                            key={m.id}
                            market={m}
                            isSelected={selectedMarketIds.includes(m.id)}
                            toggleIsSelected={() => onToggleIsSelected(m.id)}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

interface MarketScalarRowProps {
    market: Market;
    isSelected: boolean;
    toggleIsSelected: () => void;
}

function MarketScalarRow({market, isSelected, toggleIsSelected}: MarketScalarRowProps) {
    const scalarAmount = ((market.mSRPScalar - 1) * 100).toFixed(0);

    return (
        <tr>
            <td className="flex-row justify-content-center"><Checkbox checked={isSelected} onClick={toggleIsSelected}/></td>
            <td>{market.name}</td>
            <td>{scalarAmount}%</td>
        </tr>
   )
}