import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { CustomerSearchResult } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { formatPhoneNumber } from "Globals/StringFormatting";
import { useMemo, useState } from "react";
import { dumpCustomerInformation, mergeCustomerInformation, setMergeWithCustomerId } from "Redux/callCenterSchedulingReducer";
import { useAppDispatch } from "Redux/hooks";
import HistoryIcon from "@material-ui/icons/History";
import MergeIcon from "@material-ui/icons/MergeType";
import PeopleIcon from "@material-ui/icons/People";
import CustomerHistoryDialog from "./CustomerHistoryDialog";

interface CustomerMatchRowProps {
    customer: CustomerSearchResult;
    disableCustomerSearch: () => void;
    urlCustomerId?: number;
}

export default function CustomerMatchRow({customer, disableCustomerSearch, urlCustomerId}: CustomerMatchRowProps) {
    const dispatch = useAppDispatch();
    const isMergeMode = isNotNullOrUndefined(urlCustomerId);
    const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

    const primaryContact = customer.primaryContact;
    const secondaryContacts = customer.secondaryContacts;

    const contactNameTypography = useMemo(() => {
        const primaryStr = `${primaryContact.firstName}${primaryContact.lastName ? ` ${primaryContact.lastName}` : ''} (primary)`;
        const secondaryStrs = secondaryContacts.map(sc => `${sc.firstName}${sc.lastName ? ` ${sc.lastName}` : ''}`);
        const contactNames = [primaryStr, ...secondaryStrs];
        const titleStr = `Contact name${(contactNames.length > 1) ? 's' : ''}:`;
        const title = <b>{titleStr}</b>

        return <Typography>{title} {contactNames.join(', ')}</Typography>
    }, [primaryContact.firstName, primaryContact.lastName, secondaryContacts]);

    const contactNumberTypography = useMemo(() => {
        const primaryPhone = `${formatPhoneNumber(primaryContact.phone!)} (primary)`;
        const secondaryPhones = secondaryContacts.filter(sc => isNotNullOrUndefined(sc.phone)).map(sc => formatPhoneNumber(sc.phone!));
        const contactNums = [primaryPhone, ...secondaryPhones];
        const titleStr = `Contact number${(contactNums.length > 1) ? 's' : ''}:`;
        return <Typography><b>{titleStr}</b> {contactNums.join(', ')}</Typography>
    }, [primaryContact.phone, secondaryContacts]);

    const contactEmailTypography = useMemo(() => {
        const primaryEmail = `${primaryContact.email} (primary)`;
        const secondaryEmails = secondaryContacts.filter(sc => isNotNullOrUndefined(sc.email)).map(sc => sc.email!);
        const contactEmails = [primaryEmail, ...secondaryEmails];
        const titleStr = `Contact email${(contactEmails.length > 0) ? 's' : ''}:`;
        return <Typography><b>{titleStr}</b> {contactEmails.join(', ')}</Typography>
    }, [primaryContact.email, secondaryContacts]);

    const contactAddressTypography = useMemo(() => {
        const addressStr = `${customer.primaryAddress.streetAddress}, ${customer.primaryAddress.city} MI, ${customer.primaryAddress.zip}`;
        return <Typography><b>Address:</b> {addressStr}</Typography>
    }, [customer.primaryAddress.streetAddress, customer.primaryAddress.city, customer.primaryAddress.zip]);
    
    // dumps this customer's information into the form
    function onAutofill() {
        dispatch(dumpCustomerInformation(customer));
        disableCustomerSearch();
    }

    function onMerge() {
        dispatch(setMergeWithCustomerId(customer.id));
        dispatch(mergeCustomerInformation(customer));
        disableCustomerSearch();
        alert("Customer information merged locally - please verify the correctness of all information on the page before submitting");
    }
    
    return (
        <div className="customer-match-result padding-xsm" >
            <div className="flex-column justify-content-center">
                {isMergeMode ? (
                    <Tooltip title="Merge Customer Information">
                        <IconButton onClick={onMerge} className="margin-right-xsm"><MergeIcon /></IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Fill Customer Information">
                        <IconButton onClick={onAutofill} className="margin-right-xsm"><PeopleIcon /></IconButton>
                    </Tooltip>
                )}

                <Tooltip title="View Customer History">
                    <IconButton
                        className="margin-right-xsm"
                        onClick={() => setHistoryDialogOpen(true)}
                    ><HistoryIcon /></IconButton>
                </Tooltip>
            </div>

            <div className="flex-column">
                {contactNameTypography}
                {customer.businessName && <Typography><b>Business Name:</b> {customer.businessName}</Typography>}
                {contactNumberTypography}
                {contactEmailTypography}
                {contactAddressTypography}
            </div>

            {historyDialogOpen &&
                <CustomerHistoryDialog customerId={customer.id} onClose={() => setHistoryDialogOpen(false)} />}
        </div>
    )
}