interface FlatFileInputProps {
    setSelectedFile: (file: File | undefined) => void;
    accept?: string | undefined;
}

export default function FlatFileInput({setSelectedFile, accept}: FlatFileInputProps) {
    return (
        <input
            className="flat-file-input"
            type="file"
            onChange={(e) => setSelectedFile(e.target.files?.[0])}
            accept={accept}
        />
    )
}

export function FlatImageInput({setSelectedFile}: FlatFileInputProps) {
    return <FlatFileInput accept="image/*" setSelectedFile={setSelectedFile}/>
}