import { Tooltip } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import clsx from "clsx";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import FlatUndoDeleteButton from "FlatComponents/Button/FlatUndoDeleteButton";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import { isEmptyString } from "Globals/GenericValidators";
import { FLOOR_PREP_ID, FURNITURE_ID, R_AND_R_ID, TRANSITION_ID } from "Globals/globalConstants";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { WhoDoesServiceEditorForRoomProps } from "./WhoDoesServiceEditorForRoom";

interface EditableWhoDoesServiceEditor extends Omit<WhoDoesServiceEditorForRoomProps, "originalRoom" | "updateAmount"> {
    formattedPrice: JSX.Element;
    readOnly: boolean;
    setQuantity: (newQty: number) => void;
}

/**
 * Only used for Furniture services
 */
export default function EditableWhoDoesServiceEditorForRoom({
    editableRoom,
    updateWhoDoes,
    setQuantity,
    removeRoomFromService,
    restoreService,
    readOnly,
    disabled,
    formattedPrice
}: EditableWhoDoesServiceEditor) {
    function onQuantityChanged(qtyStr: string) {
        const qty = isEmptyString(qtyStr) ? 0 : +qtyStr;
        setQuantity(qty);
    }

    let unitStr: string;
    switch (editableRoom.service.serviceTypeId) {
        case (R_AND_R_ID):
            unitStr = "each";
            break;
        case (FURNITURE_ID):
            unitStr = "sqft";
            break;
        case (TRANSITION_ID):
            unitStr = "lnft";
            break;
        case (FLOOR_PREP_ID):
            unitStr = "sqft"
            break;
        default:
            throw new Error(`Unrecognized service type passed to editable - [${editableRoom.service.serviceTypeId}]`);
    }

    return (
        <tr className="flat-font">
            <td
                align="left"
                className={clsx({
                    "line-through": editableRoom.service.isDeleted,
                    "success-text": editableRoom.service.id < 0 // highlight new services green 
                })}
            >
                <span className="flex-row align-items-center flex-gap-xsm">
                    {editableRoom.service.isDeleted ? (
                        <Tooltip title="Restore this service">
                            <span>
                                <FlatUndoDeleteButton onClick={restoreService}/>
                            </span>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Remove this service">
                            <span>
                                <FlatDeleteButton onClick={removeRoomFromService}/>
                            </span>
                        </Tooltip>
                    )}
                    {getNameOfArea(editableRoom.labels)}
                </span>
            </td>
            <td align="center">
                <FlatLabeledInput
                    value={editableRoom.service.laborAmount}
                    onChange={e => onQuantityChanged(e.target.value)}
                    readOnly={readOnly}
                    label={unitStr}
                    labelLocation={"right"}
                    containerProps={{className: "justify-content-center"}}
                    className="w-2r text-align-center"
                    disabled={disabled || editableRoom.service.isDeleted}
                />
            </td>
            <td align="left">
                {(editableRoom.service.laborAmount === 0) ? (
                    <ToggleButtonGroup
                        size="small"
                        exclusive
                    >
                        <ToggleButton
                            value={true}
                            disabled
                        >
                            None
                        </ToggleButton>
                    </ToggleButtonGroup>
                ) : (
                    <ToggleButtonGroup
                        size="small"
                        exclusive
                        value={editableRoom.service.customerDoesService}
                        onChange={(_, newValue) => updateWhoDoes(newValue)}
                    >
                        <ToggleButton value={true} disabled={disabled || editableRoom.service.isDeleted}>Cust</ToggleButton>
                        <ToggleButton value={false} disabled={disabled || editableRoom.service.isDeleted}>WOF</ToggleButton>
                    </ToggleButtonGroup>
                )}
            </td>

            <td
                style={{ minWidth: "5rem" }}
                align="right"
            >
                {formattedPrice}
            </td>
        </tr>
    )
}