import { Dialog, MenuItem, MenuItemProps } from "@material-ui/core"
import { assignUnassignedRoom, openAssignUnassignedRoomDialog, selectArea, selectAreaCount, selectUnassignedRoomDialogIndex, selectUnassignedRoomDialogOpen } from "Redux/genericSalesReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType"

export default function AssignUnassignedRoomDialog() {
    const isPopupOpen = useAppSelector(selectUnassignedRoomDialogOpen)
    return (<>{isPopupOpen && <AssignUnassignedRoomDialogInner />}</>)
}

function AssignUnassignedRoomDialogInner() {
    const index = useAppSelector(selectUnassignedRoomDialogIndex)
    const areaCount = useAppSelector(selectAreaCount)
    var indexArr: number[] = []
    for (var i = 0; i < areaCount; i++) {
        indexArr = [...indexArr, i]
    }
    const dispatch = useAppDispatch()

    function onClose() {
        dispatch(openAssignUnassignedRoomDialog(-1))
    }

    function assignToArea(areaIndex: number) {
        dispatch(assignUnassignedRoom(index, areaIndex))
        onClose()
    }

    return (
        <Dialog
            id="basic-menu"
            open={true}
            onClose={onClose}
        >
            {
                indexArr.map(areaIndex => {
                    return <AreaMenuItem areaIndex={areaIndex} key={`assign-area-${areaIndex}`} onClick={() => assignToArea(areaIndex)} />
                })
            }
        </Dialog>
    )
}

interface AreaMenuItemProps extends Pick<MenuItemProps, 'key' | 'onClick'> {
    areaIndex: number
}

function AreaMenuItem({ areaIndex, ...props }: AreaMenuItemProps) {
    const area = useAppSelector(selectArea(areaIndex))
    const labels = area.rooms.flatMap(room => room.labels)
    const name = getNameOfArea(labels, '  \u{202f}')

    return <MenuItem {...props}>Room {areaIndex}: [{name}]</MenuItem>
}