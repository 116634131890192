import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import FlexGrow from "Components/PageLayout/FlexGrow";
import { HBar } from "Components/pageSpacing";
import { namedOperations, SahAppointment, Salesperson, useAcceptSahAppointmentMutation, useGetSahAppointmentsForSalespersonQuery, useUpdateSahAppointmentMutation, useWorkerPunchInMutation } from "generated/graphql";
import { dateTimeStrToDay, daysEq, todayAsDay } from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import DisplaySalespersonAppointments, { MobileSalespersonAppointment } from "./DisplaySalespersonAppointments";
import "./salesperson_home.css";

export default function SalespersonHome({ salesperson }: { salesperson: Salesperson }) {

    const [checkIn] = useWorkerPunchInMutation({
        refetchQueries: [namedOperations.Query.GetSalesperson]
    })

    const [updateAppointment] = useUpdateSahAppointmentMutation({
        refetchQueries: [namedOperations.Query.GetSahAppointmentsForSalesperson]
    })

    const [acceptAppointmentMutation] = useAcceptSahAppointmentMutation({
        refetchQueries: [namedOperations.Query.GetSahAppointmentsForSalesperson]
    })

    const { data, loading, refetch } = useGetSahAppointmentsForSalespersonQuery({
        variables: { salespersonId: salesperson.id },
        fetchPolicy: "network-only"
    })

    const [unacceptedAppointment, setUnacceptedAppointment] = useState<SahAppointment | null>(null)

    const rejectAppointment = (appointmentId: number) => updateAppointment({ variables: { sahAppointmentId: appointmentId, salespersonId: undefined } })
    const acceptAppointment = (appointmentId: number) => acceptAppointmentMutation({ variables: { sahAppointmentId: appointmentId } })

    const allAppointments = data?.sAHAppointmentsForSalesperson
    const acceptedAppointments = allAppointments?.filter(sah => !isNullOrUndefined(sah.currentSalesperson?.acceptedTime))
    .filter(sah => daysEq(dateTimeStrToDay(sah.date), todayAsDay()));

    useEffect(() => {
        const unacceptedAppointments = (allAppointments ?? []).filter(app => isNullOrUndefined(app.currentSalesperson?.acceptedTime))

        if (salesperson.isCheckedIn
            && !isNullOrUndefined(allAppointments)
            && unacceptedAppointments.length > 0) {
            setUnacceptedAppointment(unacceptedAppointments[0])
        }
        else if (!isNullOrUndefined(unacceptedAppointment)) setUnacceptedAppointment(null)
    }, [allAppointments, salesperson, unacceptedAppointment])

    return (
        <NavbarPage customRoutes={[{ name: "Block Time", route: "/block-time" }]}>
            <Container maxWidth="xl" style={{ position: "relative", height: "100%" }}>
                <div style={{ padding: "0 .5rem" }}>
                    <div className="flex-row" style={{ alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="h5">{salesperson.firstName} {salesperson.lastName}</Typography>
                    </div>
                    <div className="flex-row" style={{ alignItems: "center" }}>
                        <Typography variant="h4">Appointments</Typography>
                        <IconButton onClick={() => refetch()}>
                            <RefreshIcon />
                        </IconButton>
                        <FlexGrow />
                        {
                            !salesperson.isCheckedIn && !isMobile &&
                            <Button variant="contained" color="secondary" onClick={() => checkIn({ variables: { workerId: salesperson.workerId } })}>Clock In</Button>
                        }
                    </div>
                </div>
                <HBar />
                {
                    loading
                        ? <Loading dontFillHeight />
                        : <DisplaySalespersonAppointments appointments={acceptedAppointments ?? []} />
                }
                {
                    !salesperson.isCheckedIn && isMobile &&
                    <div className="check-in-popup">
                        <Typography variant="h5">Not Clocked In</Typography>
                        <Button variant="contained" color="secondary" onClick={() => checkIn({ variables: { workerId: salesperson.workerId } })}>Clock In</Button>
                    </div>
                }
            </Container>
            <Dialog open={!isNullOrUndefined(unacceptedAppointment)} fullWidth maxWidth="md">
                <DialogTitle>New Appointment</DialogTitle>
                <DialogContent>
                    {
                        unacceptedAppointment &&
                        <AcceptAppointmentMenuContent appointment={unacceptedAppointment!} />
                    }
                </DialogContent>
                <DialogActions>
                    <IconButton
                        size="small"
                        onClick={() => {
                            if (window.confirm("This appointment will be rejected and you won't recieve it again")) {
                                rejectAppointment(unacceptedAppointment!.id)
                            }
                        }}>
                        <CloseIcon />
                    </IconButton>
                    <SpacedButton
                        color="primary"
                        variant="contained"
                        onClick={() => acceptAppointment(unacceptedAppointment!.id)}>Accept</SpacedButton>
                </DialogActions>
            </Dialog>
        </NavbarPage>
    )
}

function AcceptAppointmentMenuContent({ appointment }: { appointment: SahAppointment }) {

    // if (isMobile) {
        return <MobileSalespersonAppointment appointment={appointment} showReducedVersion={true} />
    // }
    // else return (
    //     <Table>
    //         <TableHead>
    //             <TableRow className="desktop-appointment-header">
    //                 <TableCell>Customer Name</TableCell>
    //                 <TableCell>Address</TableCell>
    //                 <TableCell>Schedule Time</TableCell>
    //                 <TableCell>Phone</TableCell>
    //                 <TableCell>Comments</TableCell>
    //             </TableRow>
    //         </TableHead>
    //         <TableBody>
    //             <DesktopSalespersonAppointment appointment={appointment} showReducedVersion={true} />
    //         </TableBody>
    //     </Table>
    // )
}