import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { Discount, DiscountInput, namedOperations, useUpsertDiscountMutation } from "generated/graphql";
import { isEmptyString } from "Globals/GenericValidators";
import { PercentInput } from "Pages/Admin/Financing/FinancingRow";
import { useState } from "react";
import NumberFormat from "react-number-format";

export interface EditableDiscount extends Omit<Discount, "defaultValue" | "isDeleted" | "minimumMSRP"> {
    defaultValue: string;
    minimumMSRP: string;
}

export const newDiscount: EditableDiscount = {
    id: -1,
    name: "",
    isScalar: true,
    defaultValue: "",
    isHidden: false,
    minimumMSRP: ''
};

export function discountToEditable(discount: Discount): EditableDiscount {
    const value = discount.isScalar ? (100 - (discount.defaultValue * 100)).toFixed(0) : discount.defaultValue.toFixed(2);
    return {
        id: discount.id,
        name: discount.name,
        isScalar: discount.isScalar,
        defaultValue: value,
        isHidden: discount.isHidden,
        minimumMSRP: discount.minimumMSRP?.toFixed(0) ?? ''
    }
}

function editableToDiscount(editable: EditableDiscount): DiscountInput {
    const value = editable.isScalar ? +((1 - (+editable.defaultValue / 100)).toFixed(2)) : +editable.defaultValue;
    const minMSRP = (editable.id === -1) ? 
                    (isEmptyString(editable.minimumMSRP) ? undefined : +editable.minimumMSRP) :
                    (isEmptyString(editable.minimumMSRP) ? -1 : +editable.minimumMSRP); // -1 removes the min amount when updating
    return {
        id: editable.id,
        name: editable.name,
        isScalar: editable.isScalar,
        defaultValue: value,
        isHidden: editable.isHidden,
        isDeleted: false,
        minimumMSRP: minMSRP
    }
}

interface DiscountEditorDialogProps {
    discount: EditableDiscount;
    onClose: () => void;
}

export default function DiscountEditorDialog({discount: originalDiscount, onClose}: DiscountEditorDialogProps) {
    const newMode = originalDiscount.id === -1;
    const [editableDiscount, setEditableDiscount] = useState({...originalDiscount});

    function canSubmit() {
        if (isEmptyString(editableDiscount.name)) {
            alert("Enter a name for the discount");
            return false;
        }

        if (isEmptyString(editableDiscount.defaultValue)) {
            alert("Enter the discount amount");
            return false;
        } else if (editableDiscount.isScalar && +editableDiscount.defaultValue > 100) {
            alert("Discount percentage can't be greater than 100%");
            return false;
        }

        if (!editableDiscount.isScalar && isEmptyString(editableDiscount.minimumMSRP))  {
            alert("Non-scalar discounts require a minimum MSRP");
            return false;
        }

        return true;
    };

    const [upsertDiscount, {loading: submitting}] = useUpsertDiscountMutation({
        onError: () => alert(`Could not ${newMode ? "add" : "update"} discount`),
        onCompleted: (res) => {
            if (res.upsertDiscount) {
                alert(`Discount ${newMode ? "added" : "updated"} successfully`);
                onClose();
            }
        },
        refetchQueries: [namedOperations.Query.GetAllDiscounts, namedOperations.Query.GetAllMarkets]
    });
    function onSubmit() {
        const warningMsg = 'WARNING: Adding/updating discounts will cause the MSRP scalar to be recalculated for each market. This could affect any jobs which are currently in the process of being sold. Are you sure you want to continue?'
        if (canSubmit() && window.confirm(warningMsg)) {
            upsertDiscount({
                variables: { discount: editableToDiscount(editableDiscount) }
            });
        }
    }

    return (
        <Dialog open={true} fullWidth maxWidth="sm">
            <DialogTitle>{newMode ? "New Discount" : `Editing ${originalDiscount.name}`}</DialogTitle>
            
            <DialogContent className="padding-horizontal-md fit-content">
                <div className="flex-column flex-gap-sm">
                    <div className="flex-row flex-gap-md align-items-baseline">
                        <TextField
                            label={"Name"}
                            value={editableDiscount.name} onChange={e => setEditableDiscount({...editableDiscount, name: e.target.value})}
                        />
                        
                        <NumberFormat
                            value={editableDiscount.minimumMSRP}
                            onValueChange={(newVal) => setEditableDiscount({...editableDiscount, minimumMSRP: newVal.value })}
                            displayType="input" allowNegative={false}
                            customInput={TextField}
                            label="Min MSRP"
                            prefix="$"
                            decimalScale={0} fixedDecimalScale
                            className="w-5r"
                        />

                        <div className="flex-row align-items-center">
                            <Button
                                variant="contained" className="fit-content padding-none margin-right-sm"
                                onClick={() => setEditableDiscount({...editableDiscount, isScalar: !editableDiscount.isScalar})}
                            >{(editableDiscount.isScalar) ? "%" : "$"}</Button>

                            
                            {editableDiscount.isScalar ? (
                                <PercentInput
                                    className="w-5r"
                                    value={editableDiscount.defaultValue}
                                    setValue={(newVal) => setEditableDiscount({...editableDiscount, defaultValue: newVal})}
                                />
                            ) : (
                                <NumberFormat
                                    value={editableDiscount.defaultValue}
                                    onValueChange={(newVal) => setEditableDiscount({...editableDiscount, defaultValue: newVal.value })}
                                    displayType="input" allowNegative={false}
                                    prefix="$"
                                    decimalScale={2} fixedDecimalScale
                                    className="w-5r"
                                />
                            )}
                        </div>
                    </div>

                    <FormControlLabel
                        className="fit-content"
                        label="Visible"
                        control={
                            <Checkbox
                                checked={!editableDiscount.isHidden} 
                                onChange={(e) => setEditableDiscount({...editableDiscount, isHidden: !e.target.checked})}
                            />
                        }
                    />
                </div>
            </DialogContent>

            <DialogActions>
                <SpacedButton
                    className="cancel-button" disabled={submitting}
                    onClick={onClose}
                >Cancel</SpacedButton>
                <SpacedButton
                    variant="contained" color="secondary"
                    onClick={onSubmit}
                    disabled={submitting}
                >Submit</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}