import { MeasuredValue, v_MeasuredValue } from "Components/Inputs/MeasuredValueInput";
import { allTrue, isNotEmptyString, isPositiveNumber } from "Globals/GenericValidators";
import { LocalProductDetail } from "./ProductSpecViewer/ProductSpecViewer";

//#region New Style Validators

export function v_productTypeId(value: number) {
    return value > 0
}

export function v_cost(value: string){
    return isPositiveNumber(value)
}

export function v_newStyleName(value: string) {
    return isNotEmptyString(value)
}

export function v_sqft(value: string) {
    return isPositiveNumber(value)
}

export function v_structuredProductDetail(value: LocalProductDetail) {
    const mv: MeasuredValue = value.value as MeasuredValue
    return value.type === "Structured" &&
        value.id > 0 &&
        v_MeasuredValue(mv) &&
        isPositiveNumber(mv.inputValue)
}

export function v_unstructedProductDetail(value: LocalProductDetail) {
    return value.type === "Unstructured" &&
        value.id > 0 &&
        isNotEmptyString(value.value as string)
}

export function v_allStructuredProductDetails(values: LocalProductDetail[]) {
    return allTrue(values.map(v_structuredProductDetail))
}

export function v_allUnstructuredProductDetails(values: LocalProductDetail[]) {
    return allTrue(values.map(v_unstructedProductDetail))
}

//#endregion 