import { FormControl, FormLabel, MenuItem, Select } from "@material-ui/core";
import { GetServiceOrderStubsQuery, useGetServiceOrderStubsQuery, useGetServiceOrderTypesQuery } from "generated/graphql";
import { dateTimeStrToMdy } from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { clearContractorAndCharge, selectJobConfigurationId, selectJobContractId, selectServiceChargeAmount, selectServiceContractorId, selectServiceOrderTypeId, setServiceChargeAmount, setServiceContractorId, setServiceOrderType } from "Redux/jobTrackerReducer";
import ServiceOrderForm, { getProductSummaryStr } from "./ServiceOrderForm";

type ServiceOrderStub = GetServiceOrderStubsQuery['serviceOrderStubs'][number];

export function getStubText(stub: ServiceOrderStub) {
    return `${dateTimeStrToMdy(stub.dateCalledIn)} - ${getProductSummaryStr(stub.product)}`
}

export default function ServiceOrderPage() {

    const dispatch = useAppDispatch()

    const contractId = useAppSelector(selectJobContractId)
    const jobConfigurationId = useAppSelector(selectJobConfigurationId)
    const serviceOrderTypeId = useAppSelector(selectServiceOrderTypeId)
    const contractorId = useAppSelector(selectServiceContractorId)
    const chargeAmount = useAppSelector(selectServiceChargeAmount)

    const [serviceOrderId, setServiceOrderId] = useState(-1);

    const { data: serviceOrderStubsData } = useGetServiceOrderStubsQuery({
        variables: { jobContractId: contractId! },
        skip: isNullOrUndefined(contractId)
    });
    let serviceOrderStubs = serviceOrderStubsData?.serviceOrderStubs ?? [];

    return (
        <div>
            <FormControl className="margin-top-sm" style={{ width: "50%" }}>
                <FormLabel>Service Order</FormLabel>
                <Select value={serviceOrderId} onChange={e => setServiceOrderId(e.target.value as number)}>
                    <MenuItem value={-1}>New Service Order</MenuItem>
                    {serviceOrderStubs.map(stub => (
                        <MenuItem value={stub.id} key={`stub-${stub.id}`}>{getStubText(stub)}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {serviceOrderTypeId ? (
                <ServiceOrderForm
                    serviceOrderId={serviceOrderId} setServiceOrderId={setServiceOrderId}
                    jobConfigurationId={jobConfigurationId}
                    contractId={contractId!}
                    serviceOrderTypeId={serviceOrderTypeId!} setServiceOrderTypeId={id=>dispatch(setServiceOrderType(id))}
                    contractorId={contractorId} setContractorId={id=>dispatch(setServiceContractorId(id))}
                    chargeAmount={chargeAmount} setChargeAmount={id=>dispatch(setServiceChargeAmount(id))}
                />
            ) : <></>}
        </div>
    )
}

export function ServiceOrderStyleSelect() {
    const dispatch = useAppDispatch()
    const serviceOrderTypeId = useAppSelector(selectServiceOrderTypeId)

    function setServiceOrderTypeId(id: number) {
        dispatch(setServiceOrderType(id))
        dispatch(clearContractorAndCharge())
    }

    const { data: serviceOrderTypeData } = useGetServiceOrderTypesQuery({
        onCompleted: res => setServiceOrderTypeId(res.serviceOrderTypes[0].id)
    });
    let serviceOrderTypes = serviceOrderTypeData?.serviceOrderTypes ?? [];

    return (
        <FormControl>
            <Select value={serviceOrderTypeId} onChange={e => setServiceOrderTypeId(e.target.value as number)}>
                {serviceOrderTypeId === 0 && <MenuItem disabled value={0} key="sot-0">Select Type of Service Order</MenuItem>}
                {serviceOrderTypes.map(t => <MenuItem value={t.id} key={`sot-${t.id}`}>{t.type}</MenuItem>)}
            </Select>
        </FormControl>
    )
}