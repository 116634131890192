import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { CustomerCleanupListConnection } from "generated/graphql";
import { SubscriptionClient } from "subscriptions-transport-ws";
import firebase from "firebase";
import { getIdTokenAsync } from "./FirebaseUtils";

const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}`;
const apiProtocol = `${process.env.REACT_APP_API_PROTOCOL}`;
const wsUri = `${process.env.REACT_APP_WEB_SOCKET_PROTOCOL}://${window.location.hostname}:${process.env.REACT_APP_API_PORT}${process.env.REACT_APP_API_ENDPOINT}`;
const baseBackendEndpoint = `${apiProtocol}://${window.location.hostname}:${process.env.REACT_APP_API_PORT}`
const graphqlEndpoint = `${baseBackendEndpoint}${apiEndpoint}`;
const restBaseEndpoint = `${baseBackendEndpoint}/api`;


const cache = new InMemoryCache({
    typePolicies: {
        JobMetadata: {
            keyFields: ['id', 'JobConfiguration', ["id"]]
        },
        Price: {
            merge: true
        },
        PriceAndPromoSavings: {
            merge: true
        },
        Customer: {
            merge: true
        },
        ServiceTypeOption: {
            keyFields: ['serviceTypeId', 'productTypeId']
        },
        JobServiceOption: {
            keyFields: ['jobServiceId', 'productTypeId']
        },
        ServiceMaterialCategoryOption: {
            keyFields: ['materialCategoryId', 'productTypeId']
        },
        ProductStyleValidForConfiguration: {
            keyFields: ['id', 'substrateIdsInArea']
        },
        DiscountsOnJob: {
            fields: {
                discounts: {
                    merge(existing, incoming) {
                        return incoming
                    }
                },
                availableDiscounts: {
                    merge(existing, incoming) {
                        return incoming
                    }
                }
            }
        },
        RecoveryLedgerItem: {
            keyFields: ['communicationId', 'reasonId', 'stepId', 'overrideId', 'internalNotesId', 'callCenterActionId']
        },
        InstallationToSchedule: {
            keyFields: ['id', 'jobId']
        },
        GroupedInstallationAppointment: {
            keyFields: ['id', 'productTypeId']
        },
        Query: {
            fields: {
                sAHAppointmentsForDate: {
                    merge(_, incoming) {
                        return incoming;
                    }
                },
                allAppliedDiscountsForJob: {
                    merge(_, incoming) {
                        return incoming
                    }
                },
                customerCleanupList: {
                    // these settings facilitate merging paginated results together - see the link
                    // https://www.apollographql.com/docs/react/pagination/core-api#example
                    keyArgs: false,
                    merge(existing: CustomerCleanupListConnection | undefined, incoming: CustomerCleanupListConnection) {
                        if (!existing) {
                            return {...incoming}
                        } else {
                            /**
                             * This check is effectively seeing if the incoming data is the result of a manual cache update
                             * after deleting (in which case, the IF condition will evaluate to true). Without this, when the
                             * cache was manually updated after a delete, the list was effectively getting duplicated.
                             * 
                             * When there is a piece of data that's already displayed on the page, we know that the incoming
                             * data is the new version of the data after a manual cache update because when new a new page
                             * of data comes in, none of that will already be present on the page. I'm not exactly sure why
                             * these things aren't automatically merged (and therefore duplicated), but I have a feeling it
                             * has something to do with keyArgs: false (see the linked example above).
                             * 
                             * If something's gone wrong with this and you've been tasked with fixing it, I'm very sorry. Good luck.
                            */
                           if (existing.edges!.map(e => e.cursor).includes(incoming.edges![0].cursor)) {
                               // in this case, the incoming data is from a manual cache update
                               return incoming;
                            } else {
                                return {
                                    ...existing,
                                    nodes: [...existing?.nodes ?? [], ...incoming.nodes ?? []],
                                    edges: [...existing?.edges ?? [], ...incoming?.edges ?? []],
                                    pageInfo: incoming.pageInfo
                                }
                            }
                        }
                    }
                }
            }
        }
    }
});

const httpLink = new HttpLink({ uri: graphqlEndpoint });
export const apiBaseURL = restBaseEndpoint;
const wsLink = new WebSocketLink(new SubscriptionClient(wsUri, {
    reconnect: true,
    lazy: true,
    // connectionParams: firebase.auth().currentUser?.getIdToken()
    connectionParams: async () => {
        const token = await firebase.auth().currentUser?.getIdToken();
        return {token: token};
    }
}));  // needed for subscriptions

// used to automatically use the right link (http for query/mutation, ws for subscription)
const splitLink = split(
    ({ query }) => {
        let definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
)
    
    /*
    * This is crucially important. Need to use setContext instead of a normal ApolloLink instance
 * because the callback in the ApolloLink constructor doesn't support async functions. We need
 * to call getIdTokenAsync() because it will handle exchanging stale auth tokens automatically.
 * The previous approach was just to store the auth token in a piece of state, but it was difficult
 * to refresh the token after expiration.
 * 
 * For context, this helped me come to this realization.
 * https://github.com/apollographql/apollo-client/issues/2441
 */
const authMiddleware = setContext(async (_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${await getIdTokenAsync()}`,
      }
    }
  });

export const apolloClient = new ApolloClient({
    link: authMiddleware.concat(splitLink),
    cache,
    resolvers: {

    }
});