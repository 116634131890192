import Gallery from "Components/ImageGallery/Gallery";
import { RoomGalleryImageBaseProps } from "../JobPictureViewer";
import { RoomGalleryImage } from "./RoomGalleryImage";

export interface RoomPhotoGalleryProps {
    pictures: RoomGalleryImageBaseProps[];
    deletePicture?: (id: number) => void;
    mobile?: boolean;
}

export function RoomPhotoGallery({
    pictures,
    deletePicture,
    mobile,
}: RoomPhotoGalleryProps) {
    return (
        <>
            {pictures.length > 0 ? (
                <Gallery
                    rowMax={mobile ? undefined : 2}
                    mobile={mobile}
                >
                    {pictures.map((picture) => (
                        <RoomGalleryImage
                            key={`room-picture-${picture.pictureId}`}
                            {...picture}
                            deleteImage={
                                deletePicture === undefined
                                    ? undefined
                                    : () => deletePicture(picture.pictureForRoomId)
                            }
                            mobile={mobile}
                        />
                    ))}
                </Gallery>
            ) : (
                <p className="margin-none flat-font italic-text">No Photos</p>
            )}
        </>
    );
}
