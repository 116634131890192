import { Checkbox, CheckboxProps } from "@material-ui/core"
import clsx from "clsx";
import { FlatLabeledInputBase } from "./flatInputUtils";

interface FlatCheckboxProps extends Omit<CheckboxProps, "color"> {
    color?: string;
}

export default function FlatCheckbox({color="black", size="small", ...props}: FlatCheckboxProps) {
    const {style, ...cleanedProps} = props;
    
    return (
        <Checkbox
            style={{color: props.disabled ? "var(--flat-gray-4)" : color, ...style}}
            size={size}
            {...cleanedProps}
        />
    )
}

type FlatLabeledCheckboxProps = FlatLabeledInputBase<FlatCheckboxProps>;

export function FlatLabeledCheckbox({
    label,
    bold=true,
    labelLocation="left",
    labelSize="standard",
    labelStyle,
    justification="center",
    containerProps,
    ...checkboxProps
}: FlatLabeledCheckboxProps) {
    const containerClassName = clsx("align-items-center", containerProps?.className, {
        "flex-row": labelLocation === "left",
        "flex-row-reverse": labelLocation === "right",
        "justify-content-flex-start": (labelLocation === "left" && justification === "left") || (labelLocation === "right" && justification === "right"),
        "justify-content-center": justification === "center",
        "justify-content-flex-end": (labelLocation === "left" && justification === "right") || (labelLocation === "right" && justification === "left")
    });
    const labelClassName = clsx("margin-none", {
        "flat-font-xsm": labelSize === "xsm",
        "flat-font-sm": labelSize === "sm",
        "flat-font": labelSize === "standard",
        "flat-font-md": labelSize === "md",
        "flat-font-lg": labelSize === "lg",
        "bold-text": bold
    });

    // remove className from the spread props because it will overwrite the directly passed className prop
    const {className, ...cleanedContainerProps} = {...containerProps};
    
    return (
        <span className={containerClassName} {...cleanedContainerProps}>
            <p
                className={labelClassName}
                style={{...labelStyle}}
            >
                {label}
            </p>
            
            <FlatCheckbox 
                {...checkboxProps}
                style={{...checkboxProps.style, width: "fit-content"}}
            />
        </span>
    )
}