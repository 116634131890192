import { Select, MenuItem } from "@material-ui/core";

export default function UploadMode({ value, setValue }: { value: boolean, setValue: (newValue: boolean) => void }) {

    return (
        <div className="flex-column">
            <label htmlFor="upload-mode">Upload Mode:</label>
            <Select
                name="upload-mode"
                value={+value}
                onChange={(e) => setValue(Boolean(e.target.value as number))}
                className="w-10r"
            >
                <MenuItem value={+false}>Single</MenuItem>
                <MenuItem value={+true}>Bulk</MenuItem>
            </Select>
        </div>
    )
}
