import { IconButton } from "@material-ui/core";
import KeyboardArrowUpIcon from '@material-ui/icons//KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectTabOpen, setTabOpen } from "Redux/productCostsReducer";
import ProductCostsCostsColumns from "./ProductCostsCostsColumns";
import ProductCostsSourcesRows from "./ProductCostsSourcesRows";

interface ProductCostsStyleRowProps {
    styleId: number,
    styleName: string,
    loadedBulkCost: number,
    loadedSingleCost: number,
    preventShowColor?: boolean
}


export default function ProductCostsStyleRow({ styleId, styleName, loadedBulkCost, loadedSingleCost, preventShowColor }: ProductCostsStyleRowProps) {

    const open = useAppSelector(selectTabOpen(styleId))
    const dispatch = useAppDispatch()

    return (
        <>
            <tr>
                <td colSpan={2} align="center" style={{ maxWidth: "15rem" }}>
                    {styleName}
                </td>
                <td className='w-2r'>
                    <IconButton hidden={preventShowColor} className='w-2r' size='small' onClick={() => dispatch(setTabOpen(styleId, !open))}>
                        {!open && <KeyboardArrowDownIcon />}
                        {open && <KeyboardArrowUpIcon />}
                    </IconButton>
                </td>
                <ProductCostsCostsColumns
                    styleId={styleId}
                    bulkCostPerSqft={loadedBulkCost}
                    singleCostPerSqft={loadedSingleCost}
                />
            </tr>
            {
                open &&
                <ProductCostsSourcesRows styleId={styleId} />
            }
        </>
    )
}