import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { FinancingOption } from "generated/graphql"
import { dateTimeStrToMdy } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined } from "Globals/GenericValidators"
import { useEffect } from "react";
import { FinancingTypeMap } from "./FinancingPage";

interface FinancingOptionsHistoryViewer {
    options: FinancingOption[];
    typeMap: FinancingTypeMap;
    setOpen: (isOpen: boolean) => void;
}

export default function FinancingOptionHistoryViewer({options, typeMap, setOpen}: FinancingOptionsHistoryViewer) {
    const isForPromoHistory = options.some(opt => isNotNullOrUndefined(opt.promoParentTranCode));
    
    useEffect(() => {
        console.log(options);
    }, [options])

    return (
        <Dialog open={true} fullWidth maxWidth="lg">
            <DialogTitle>Viewing {isForPromoHistory ? "Promo" : "Option"} History</DialogTitle>
            
            <DialogContent>
                <div className="table-fixed-new flex-column align-items-center" style={{height: "100%"}} >
                    <table>
                        <thead style={{width: "fit-content"}}>
                            <tr style={{ backgroundColor: "lightgrey", height: "3rem" }}>
                                <td align="left" className="whitespace-no-wrap padding-right-sm">Term (months)</td>
                                <td align="left" className="whitespace-no-wrap padding-right-sm">Total Amount ($)</td>
                                <td align="left" className="whitespace-no-wrap padding-right">Type</td>
                                <td align="left" className="whitespace-no-wrap padding-right-sm">Fee</td>
                                <td align="left" className="whitespace-no-wrap padding-right-sm">Start Date</td>
                                <td align="left" className="whitespace-no-wrap padding-right-sm">End Date</td>
                                <td align="left" className="whitespace-no-wrap padding-right-sm">Tran Code</td>
                                {isForPromoHistory && (
                                    <td align="left" className="whitespace-no-wrap padding-right-sm">Parent Tran Code</td>
                                )}
                            </tr>
                        </thead>

                        <tbody>
                            {options.map(opt => (
                                <tr key={`hist-${opt.id}`}>
                                    <td><Typography>{opt.term}</Typography></td>
                                    <td><Typography>${opt.minAmount.toFixed(2)}</Typography></td>
                                    <td><Typography>{typeMap[opt.typeId]}</Typography></td>
                                    <td><Typography>{opt.fee.toFixed(2)}%</Typography></td>
                                    <td><Typography>{opt.startDate ? dateTimeStrToMdy(opt.startDate) : ""}</Typography></td>
                                    <td><Typography>{opt.endDate ? dateTimeStrToMdy(opt.endDate) : ""}</Typography></td>
                                    <td><Typography>{opt.tranCode}</Typography></td>
                                    {isForPromoHistory && (
                                        <td><Typography>{opt.promoParentTranCode}</Typography></td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </DialogContent>

            <DialogActions>
                <SpacedButton className='cancel-button' onClick={() => setOpen(false)}>Close</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}