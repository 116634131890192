import { FlatLabeledCheckbox } from "FlatComponents/Inputs/FlatCheckbox";

interface WorkerServiceAreaSelectorProps {
    servicedZips: string[];
    setServicedZips: (newZips: string[]) => void;
    allZips: string[];
}

export default function WorkerServicedZipEditor({servicedZips, setServicedZips, allZips}: WorkerServiceAreaSelectorProps) {
    function toggleZip(zip: string) {
        if (servicedZips.includes(zip)) {
            setServicedZips(servicedZips.filter(sz => sz !== zip));
        } else {
            setServicedZips([...servicedZips, zip]);
        }
    }
    
    return (
        <div className="flex-row-wrap flex-gap-sm">
            {allZips.map(z => (
                <FlatLabeledCheckbox
                    label={z}
                    checked={servicedZips.includes(z)}
                    onClick={() => toggleZip(z)}
                    labelLocation="right"
                />
            ))}
        </div>
    )
}