import { Button } from "@material-ui/core";
import { isEmptyString } from "Globals/GenericValidators";
import { selectCity, selectStreetAddress, selectZip } from "Redux/callCenterSchedulingReducer";
import { useAppSelector } from "Redux/hooks";

export default function MapButton() {
    const zip = useAppSelector(selectZip);
    const city = useAppSelector(selectCity);
    const address = useAppSelector(selectStreetAddress);
    const urlFormattedAddress = `${address} ${city}, MI, ${zip}`.replaceAll(" ", ",");
    const openMap = () => window.open(`https://www.google.com/maps/place/${urlFormattedAddress}}`);

    return (
        <Button
            className="fit-content"
            variant="contained"
            onClick={openMap}
            disabled={isEmptyString(zip) || isEmptyString(city) || isEmptyString(address)}
        >
            Map
        </Button>
    );
}