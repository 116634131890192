import { Switch, Menu, Table, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { useGlobalState, setGlobalState } from "../Globals/GlobalState";

interface Props{
    anchorEl: Element | null
    handleClose : () => void
}


export function GenerateDebugSettings() : DebugSettings{
    return { isUpperManagement: false, showDropZonesForDragNDrop: false};
}

export interface DebugSettings{
    isUpperManagement : boolean
    showDropZonesForDragNDrop: boolean
}

type BooleanKeys<T> = { [k in keyof T]: T[k] extends boolean ? k : never }[keyof T];

type OnlyBoolean<T> = { [k in BooleanKeys<T>]: boolean };



export default function DebugMenu({anchorEl, handleClose} : Props){

    const [debugSettings] = useGlobalState('debugSettings');

    const BindCheckbox = function(settings : DebugSettings, fieldName : keyof OnlyBoolean<DebugSettings>){
        let defaultChecked = settings[fieldName];

        const onChange = function(x: React.ChangeEvent<HTMLInputElement>) {
            setGlobalState('debugSettings', (y) => { y[fieldName] = x.target.checked; return y});
        };

        return {defaultChecked, onChange};
    }

    return (
        <Menu id='debug-menu' key='debug-menu' anchorEl={anchorEl} open={anchorEl !== null} onClose={handleClose}>
            <Table>
                <TableRow>
                    <TableCell>isUpperManagement</TableCell>
                    <TableCell><Switch {... BindCheckbox(debugSettings, 'isUpperManagement')}></Switch></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>showDropZonesForDragNDrop</TableCell>
                    <TableCell><Switch {... BindCheckbox(debugSettings, 'showDropZonesForDragNDrop')}></Switch></TableCell>
                </TableRow>
            </Table>
        </Menu>
    )
}