import { MenuItem, Select } from "@material-ui/core"
import { ServiceMaterialCategoryOption } from "generated/graphql"
import { isNumber } from "Globals/GenericValidators"
import { ServiceIdValue } from "../ServicesByTypeEditor"
import DefaultMaterialAmountEditor from "./DefaultMaterialAmountEditor"
import { ClosedEndsMaterialAmountEditor, DoubleCapMaterialAmountEditor, SingleCapMaterialAmountEditor } from "./StairNoseOnStepMaterialAmountEditors"

interface MaterialForServiceColumnsProps {
    showError?: boolean,
    laborUnit: string,
    value: Pick<ServiceIdValue, "materialCategoryId" | "materialAmount" | "value">,
    setValue: (material?: { newMatId?: number, newMatAmount?: string }) => void,
    options: ServiceMaterialCategoryOption[]
}

export default function MaterialForServiceColumns({ showError, laborUnit, value, setValue, options }: MaterialForServiceColumnsProps) {
    const selectedOption = getMaterialOption(value.materialCategoryId)
    const hideMaterialAmount = laborUnit !== "each" && laborUnit === selectedOption?.materialPriceUnit

    function getMaterialOption(materialCategoryId?: number) {
        return materialCategoryId === undefined
            ? undefined
            : options.find(op => op.materialCategoryId === (materialCategoryId))
    }

    function updateMaterialId(materialId: number) {
        if (materialId === -1) {
            setValue({ newMatAmount: (hideMaterialAmount) ? value.materialAmount : undefined })
        }
        else {
            const newMaterial = getMaterialOption(materialId)
            const takeLabor = laborUnit !== "each" && newMaterial?.materialPriceUnit === laborUnit
            setValue({ newMatId: materialId, newMatAmount: (takeLabor) ? value.value : newMaterial?.defaultAmount?.toString() ?? "" })
        }
    }

    function updateMaterialAmount(amount?: string) {
        setValue({ newMatId: value.materialCategoryId, newMatAmount: amount })
    }

    const hideMaterialSelect = shouldHideMaterialSelectEditor(value.materialCategoryId ?? -1)
    const Editor = getMaterialAmountEditor(value.materialCategoryId ?? -1)

    return (
        <>
            {!hideMaterialSelect &&
                <td className="w-11r padding-side-xsm">
                    <Select fullWidth value={value.materialCategoryId} onChange={e => updateMaterialId(e.target.value as number)}>
                        <MenuItem value={""}>None</MenuItem>
                        {
                            options.map(option => {
                                return (
                                    <MenuItem value={option.materialCategoryId} key={`ser-offmat-${option.materialCategoryId}`}>
                                        {
                                            option.materialName
                                        }
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </td>
            }
            <td className="w-11r padding-side-xsm">
                {
                    ((value.materialCategoryId ?? -1) > 0 && !hideMaterialAmount) &&
                    <Editor
                        error={showError && value.materialCategoryId !== undefined && !isNumber(value.materialAmount ?? "")}
                        value={value.materialAmount}
                        onChange={updateMaterialAmount}
                        endAdornment={selectedOption?.materialPriceUnit ?? ""}
                    />
                }
            </td>
            {
                hideMaterialSelect && <td className="w-11r padding-side-xsm"></td>
            }
        </>
    )
}

const stairNoseCloseEnds = new Set([1, 2, 14, 18, 20, 25])
const stairNoseSingleCap = new Set([3, 4, 17, 19, 21, 23])
const stairNoseDoubleCap = new Set([5, 6, 15, 16, 22, 24])

function getMaterialAmountEditor(materialId: number): ({ ...props }: MaterialAmountEditorProps) => JSX.Element {

    if (stairNoseCloseEnds.has(materialId)) return ClosedEndsMaterialAmountEditor
    else if (stairNoseSingleCap.has(materialId)) return SingleCapMaterialAmountEditor
    else if (stairNoseDoubleCap.has(materialId)) return DoubleCapMaterialAmountEditor
    else return DefaultMaterialAmountEditor
}

function shouldHideMaterialSelectEditor(materialId: number): boolean {
    return stairNoseCloseEnds.has(materialId) || stairNoseSingleCap.has(materialId) || stairNoseDoubleCap.has(materialId)
}

export interface MaterialAmountEditorProps {
    error?: boolean,
    value?: string,
    onChange: (value?: string) => void,
    endAdornment: string
}
