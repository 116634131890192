import { isNullOrUndefined } from "Globals/GenericValidators";
import { convertPictureToInput } from "Redux/genericSalesReducer";
import {
    Picture,
    namedOperations,
    useAddBeforeAndAfterPicturesForRoomMutation,
    useGetJobConfigurationQuery
} from "generated/graphql";
import { AddNewPhotoButton } from "../Generics/AddNewPhotoButton";
import { TypedUploadPicturesProps } from "./BeforePictureUploadButton";

export default function AfterPictureUploadButton({
    jobConfigurationId,
    singlePhoto,
    onPhotosUploaded
}: TypedUploadPicturesProps) {
    const { data } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        skip: isNullOrUndefined(jobConfigurationId) && jobConfigurationId! <= 0,
    });

    const [upload] = useAddBeforeAndAfterPicturesForRoomMutation({
        refetchQueries: [namedOperations.Query.GetJobConfiguration],
        awaitRefetchQueries: true,
    });

    function onUpload(roomId: number, pics: Picture[]) {
        upload({
            variables: {
                pictures: pics.map(convertPictureToInput),
                roomId: roomId,
                isBefore: false,
            },
        });
        onPhotosUploaded?.(pics.length)
    }

    return (
        <AddNewPhotoButton
            areas={data?.jobConfiguration.areas ?? []}
            onUpload={onUpload}
            singlePhoto={singlePhoto}
        />
    );
}
