import clsx from "clsx";
import { CalendarItemData, CalendarItemProps } from "./ContractorAppointmentCalendar";

interface CalendarHighlightableProps {
    isToday: boolean;
}

interface CalendarDayProps extends CalendarHighlightableProps {
    day: string;
    onClick?: () => void;
    items: (CalendarItemProps<CalendarItemData> | undefined)[];
    isBlocked: boolean;
    isEndOfWeek: boolean;
}

export function CalendarDay({
    day,
    onClick,
    items,
    isToday,
    isBlocked,
    isEndOfWeek,
}: CalendarDayProps) {
    const isClickable = onClick !== undefined;
    var splitDate = day.split("/");
    const currentMonth = new Date().getMonth() + 1;

    const mmDD = currentMonth === +splitDate[0] ? splitDate[1] : splitDate.splice(0, 2).join("/");

    return (
        <div
            onClick={onClick}
            className={clsx("contractor-calendar-day", {
                "darken-calendar-day": isToday,
                "blocked-calendar-day": isBlocked,
                clickable: isClickable,
            })}
            style={{ verticalAlign: "baseline", minHeight: "2rem", position: "relative" }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingLeft: "2px",
                    paddingTop: "2px",
                    color: "var(--flat-gray-5)",
                }}
                className="flat-font-xsm"
            >
                <div
                    style={{ padding: "0 3px 1px 3px" }}
                    className={clsx({ "calendar-day-circle": isToday })}
                >
                    {mmDD}
                </div>
            </div>
            {items.map((item, index) => {
                if (item === undefined) return <div key={index} />;
                else
                    return (
                        <CalendarDisplayBlock
                            key={index}
                            mdyDayString={day}
                            isEndOfWeek={isEndOfWeek}
                            {...item!}
                        />
                    );
            })}
            {/* </div> */}
        </div>
    );
}

interface CalendarDisplayBlockProps<T> extends CalendarItemProps<T> {
    mdyDayString: string;
    isEndOfWeek: boolean;
}

function CalendarDisplayBlock({
    data,
    hasPreviousDay,
    hasNextDay,
    isEndOfWeek,
}: CalendarDisplayBlockProps<CalendarItemData>) {
    const { darkCard, colors, customerName, cityName, amountLabel } = data;

    const preventOverflow = isEndOfWeek || !hasNextDay;

    const cardColor = darkCard ? "var(--flat-gray-5)" : colors[0];

    return (
        <div className={clsx("contractor-calendar-day-block")}>
            <div
                style={{ backgroundColor: cardColor, alignItems: "start" }}
                className={clsx("flex-column fill-width fill-height flat-dark-bkg", {
                    "unround-start": hasPreviousDay,
                    "unround-end": hasNextDay,
                })}
            >
                {!hasPreviousDay && (
                    <div className="flex-row fill-width fill-height">
                        <div
                            hidden={!darkCard}
                            className="contractor-calendar-stripe"
                            style={{ minWidth: "5px", height: "100%", backgroundColor: colors[0] }}
                        />
                        <div
                            className={clsx("flex-column contractor-calendar-day-block-container", {
                                "overflow-hidden": preventOverflow,
                            })}
                        >
                            <div
                                className={clsx("flat-font-xsm fill-width text-align-left", {
                                    "overflow-hidden": preventOverflow,
                                })}
                            >
                                {amountLabel}
                            </div>
                            <div
                                className={clsx("flat-font-sm fill-width text-align-left", {
                                    "overflow-hidden": preventOverflow,
                                })}
                            >
                                {cityName}
                            </div>
                            <div
                                className={clsx("flat-font-xsm fill-width text-align-left", {
                                    "overflow-hidden": preventOverflow,
                                })}
                            >
                                {customerName}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
