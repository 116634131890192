import FlatButton from "FlatComponents/Button/FlatButton";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { ContractorProfile, ContractorProfileInput, namedOperations, useUpsertContractorProfileMutation } from "generated/graphql";
import { prepareContractorProfile } from "Globals/dataPreparationUtils";
import { emptyAddress, emptyCompany, emptyVehicle } from "Globals/DataStructures/EmptyDataStructures";
import { isContractorProfileValid, isNotNullOrUndefined } from "Globals/GenericValidators";
import { formatNameStringFirstLast, formatPhoneNumber } from "Globals/StringFormatting";
import { useState } from "react";
import { VehicleConditionSelect, VehicleYearSelect } from "../GeneralProfileComponents/ProfileSelects";

interface EditContractorDialogProps {
    onClose: () => void;
    contractor: ContractorProfile;
    onComplete?: (newWorkerId: number) => void;  // what to do when the mutation completes
}

export default function EditContractorDialog({contractor: originalContractor, onClose, onComplete}: EditContractorDialogProps) {
    const newMode = originalContractor.id === -1;

    const headerText = newMode ? "New Contractor" : `Editing ${formatNameStringFirstLast({firstName: originalContractor.firstName, lastName: originalContractor.lastName})}`;
    const [contractor, setContractor] = useState(addContractorProfileMissingFields(originalContractor));

    /** Returns undefined if the data can't be submitted, otherwise formats the data for submission */
    function validateAndPrepareData(): ContractorProfileInput | undefined {
        const preparedContractor = prepareContractorProfile(contractor);
        if (isContractorProfileValid(preparedContractor, true)) {
            return preparedContractor;
        } else {
            return undefined;
        }
    }

    // the loading flag of the mutation doesn't get unset when mutation fails for some reason, so do it manually
    const [upserting, setUpserting] = useState(false);
    const [upsertContractor] = useUpsertContractorProfileMutation({
        onError: () => {
            setUpserting(false);
            alert("Could not update contractor information");
        },
        refetchQueries: [namedOperations.Query.GetContractorProfile]
    });

    function onSubmit() {
        const preparedData = validateAndPrepareData();
        if (isNotNullOrUndefined(preparedData)) {
            setUpserting(true);
            // worker history is updated in a separate mutation
            const {workerHistory, ...contractor} = preparedData!;
            upsertContractor({
                variables: ({contractor: contractor}),
                onCompleted: (data) => {
                    onClose();
                    if (onComplete) {
                        onComplete(data.upsertContractorProfile);
                    }
                }
            });
        }
    }

    const submitButton = (
        <FlatButton
            key={"submit-ecd"}
            onClick={onSubmit}
            variant="contained"
            color="secondary"
            disabled={upserting}
        >Submit</FlatButton>
    );

    return (
        <FlatDialog
            sectionProps={{header: headerText}}
            dialogProps={{open: true, onClose: onClose, maxWidth: "md"}}
            actionButtons={[submitButton]}
        >
            <div className="grid-50-50 flex-row-gap-xsm">
                <FlatLabeledInput
                    label="First Name"
                    value={contractor.firstName}
                    onChange={(e) => setContractor({...contractor, firstName: e.target.value})}    
                />
                <FlatLabeledInput
                    label="First Name"
                    value={contractor.lastName}
                    onChange={(e) => setContractor({...contractor, lastName: e.target.value})}    
                />
                <FlatLabeledInput
                    label="Phone"
                    value={formatPhoneNumber(contractor.phone)}
                    onChange={(e) => setContractor({...contractor, phone: e.target.value})}    
                />
                <FlatLabeledInput
                    label="Email"
                    className="w-15r"
                    value={contractor.email}
                    onChange={(e) => setContractor({...contractor, email: e.target.value})}    
                />
                <FlatLabeledInput
                    label="Street Address"
                    value={contractor.address!.streetAddress}
                    onChange={e => setContractor({
                        ...contractor,
                        address: { ...contractor.address!, streetAddress: e.target.value }
                    })}
                />
                <FlatLabeledInput
                    label="City"
                    value={contractor.address!.city}
                    onChange={e => setContractor({
                        ...contractor,
                        address: { ...contractor.address!, city: e.target.value }
                    })}
                />
                <FlatLabeledInput
                    label="Zip"
                    className="w-3r text-align-center"
                    value={contractor.address!.zip}
                    onChange={e => setContractor({
                        ...contractor,
                        address: { ...contractor.address!, zip: e.target.value }
                    })}
                />
                <FlatLabeledInput
                    label="Apt. #"
                    value={contractor.address!.apartmentNumber ?? undefined}
                    onChange={e => setContractor({
                        ...contractor,
                        address: { ...contractor.address!, apartmentNumber: e.target.value }
                    })}
                />
                <div className="flat-thin-horizontal-bar margin-vertical-xsm grid-colspan-1-3"/>
                <FlatLabeledInput
                    label="Driver's License #"
                    value={contractor.driversLicenseNumber ?? undefined}
                    onChange={(e) => setContractor({...contractor, driversLicenseNumber: e.target.value})}    
                />
                <FlatLabeledInput
                    label="License #"
                    value={contractor.licenseNumber ?? undefined}
                    onChange={(e) => setContractor({...contractor, licenseNumber: e.target.value})}    
                />
                <FlatLabeledInput
                    label="Vehicle Description"
                    value={contractor.vehicle?.description ?? undefined}
                    onChange={(e) => setContractor({
                        ...contractor, vehicle: {...contractor.vehicle!, description: e.target.value}
                    })} 
                />
                <VehicleYearSelect 
                    year={contractor.vehicle?.year ?? undefined}
                    setYear={(year?: number) => {
                        setContractor({
                            ...contractor,
                            vehicle: {
                                ...contractor.vehicle!,
                                year: year
                            }
                        })
                    }}
                />
                <VehicleConditionSelect 
                    condition={contractor.vehicle?.condition ?? undefined}
                    setCondition={(condition?: string) => {
                        setContractor({
                            ...contractor,
                            vehicle: {
                                ...contractor.vehicle!,
                                condition: condition
                            }
                        });
                    }}
                />
                <FlatLabeledInput
                    label="License Plate Number"
                    className="w-5r"
                    value={contractor.vehicle?.licensePlateNumber ?? undefined}
                    onChange={(e) => setContractor({
                        ...contractor, vehicle: {...contractor.vehicle!, licensePlateNumber: e.target.value}
                    })}
                />
                <div className="flat-thin-horizontal-bar margin-vertical-xsm grid-colspan-1-3"/>
                <FlatLabeledInput
                    label="Company Name"
                    value={contractor.company!.name}
                    onChange={(e) => setContractor({
                        ...contractor,
                        company: {...contractor.company!, name: e.target.value}
                    })}    
                />
                <FlatLabeledInput
                    label="Street Address"
                    value={contractor.company!.address!.streetAddress}
                    onChange={e => setContractor({
                        ...contractor,
                        company: {
                            ...contractor.company!,
                            address: {
                                ...contractor.company!.address,
                                streetAddress: e.target.value
                            }
                        }
                    })}
                />
                <FlatLabeledInput
                    label="City"
                    value={contractor.company!.address!.city}
                    onChange={e => setContractor({
                        ...contractor,
                        company: {
                            ...contractor.company!,
                            address: {
                                ...contractor.company!.address,
                                city: e.target.value
                            }
                        }
                    })}
                />
                <FlatLabeledInput
                    label="Zip"
                    value={contractor.company!.address!.zip}
                    onChange={e => setContractor({
                        ...contractor,
                        company: {
                            ...contractor.company!,
                            address: {
                                ...contractor.company!.address,
                                zip: e.target.value
                            }
                        }
                    })}
                />
                <FlatLabeledInput
                    label="EIN"
                    value={contractor.company!.eIN ?? ""}
                    onChange={(e) => setContractor({
                        ...contractor,
                        company: {
                            ...contractor.company!,
                            eIN: e.target.value
                        }
                    })}
                />
                <FlatLabeledInput
                    label="Parent Company"
                    value={contractor.parentCompany ?? ""}
                    onChange={(e) => setContractor({
                        ...contractor,
                        parentCompany: e.target.value
                    })}
                />
            </div>
        </FlatDialog>
    )
}


// enables editing of contractor profile when optional fields are missing
export function addContractorProfileMissingFields(contractor: ContractorProfile): ContractorProfile {
    return {
        ...contractor,
        address: isNotNullOrUndefined(contractor.address) ? {...contractor.address!} : {...emptyAddress},
        company: isNotNullOrUndefined(contractor.company) ? {...contractor.company!} : {...emptyCompany},
        vehicle: isNotNullOrUndefined(contractor.vehicle) ? {...contractor.vehicle!} : {...emptyVehicle},
    };
}