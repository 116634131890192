import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { CircularProgress, IconButton, InputAdornment, Popper, TextField, Typography } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import { isNotEmptyString } from "Globals/GenericValidators";
import { ReactElement, useRef } from "react";

interface SearchBarInputProps {
    searchStr: string;
    setSearchStr: (newStr: string) => void;
    onSearch: () => void;
    loadingResults: boolean;
    searchResults: ReactElement[] | null;
    clearSearchResults: () => void;
}

/**
 * This component handles the rendering of the search bar and search results. Any component which uses it is responsible
 * for retrieving search results, and creating the individual search result HTML elements. This component will handle
 * placing these individual search results in a popper anchored on the search element. It is recommended that these results array
 * (which are typed as ReactJSXElement[]) have a <div> as their root.
 * 
 */
export default function SearchBarInput({searchStr, setSearchStr, onSearch, loadingResults, searchResults, clearSearchResults}: SearchBarInputProps) {
    function onClear() {
        setSearchStr('');
        clearSearchResults();
    }

    const popperAnchor = useRef(null);

    return (
        <>
            <div id="search-bar" ref={popperAnchor}>
                {/* <input
                    placeholder="Search..."
                    value={searchStr}
                    onChange={(e) => setSearchStr(e.target.value)}
                    onKeyPress={(e) => (e.key === 'Enter') && onSearch()}
                    
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <div className="flex-row">
                                    { isNotEmptyString(searchStr) && <IconButton onClick={onClear}><CancelIcon /></IconButton> }
                                    { loadingResults ? <CircularProgress /> : <IconButton className="search-bar-search-icon" onClick={onSearch}><SearchIcon /></IconButton> }
                                </div>
                            </InputAdornment>
                        )
                    }}
                /> */}
                <TextField
                    variant="standard"
                    value={searchStr}
                    onChange={(e) => setSearchStr(e.target.value)}
                    onKeyPress={(e) => (e.key === 'Enter') && onSearch()}
                    InputProps={{
                        id: "search-bar-input",
                        disableUnderline: true,
                        placeholder: "Search...",
                        endAdornment: (
                            <InputAdornment position="end">
                                <div className="flex-row">
                                    { isNotEmptyString(searchStr) && <IconButton onClick={onClear}><CancelIcon /></IconButton> }
                                    { loadingResults ? <CircularProgress /> : <IconButton className="search-bar-search-icon" onClick={onSearch}><SearchIcon /></IconButton> }
                                </div>
                            </InputAdornment>
                        )
                    }}
                />
            </div>

            {(popperAnchor.current && searchResults) &&
                <SearchResultPopper inputAnchor={popperAnchor.current} searchResults={searchResults} />
            }
        </>
    )
}

interface SearchResultPopperProps {
    inputAnchor: HTMLElement;
    searchResults: ReactJSXElement[];
}

function SearchResultPopper({inputAnchor, searchResults}: SearchResultPopperProps) {
    return (
        <Popper id="search-results-popper" open={true} anchorEl={inputAnchor}>
            <div id="search-results-container">
                <>
                    {(searchResults.length === 0) ? (
                        <Typography>No results found</Typography>
                    ) : (
                        <>{searchResults}</>
                    )}
                </>
            </div>
        </Popper>
    )
}