import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { useGetActivePromoCodeQuery, useGetActivePromosExistQuery, useGetAllHowDidYouHearAboutUsOptionsQuery } from "generated/graphql";
import { isEmail, isEmptyString, isNotEmptyString, isNotNullOrUndefined, isNullOrUndefined, isPhoneNumber, isValidName } from "Globals/GenericValidators";
import { formatPhoneNumber } from "Globals/StringFormatting";
import { useState } from "react";
import { FormValues, HandleValueChangeFunc } from "./NewSAHAppointmentPage";
import { canMoveToProductSelection } from "./SchedulingDateTime";
import { canMoveToContactInfo } from "./SchedulingProductTypeSelector";

interface SchedulingContactInfoProps {
    formValues: FormValues,
    handleValueChange: HandleValueChangeFunc,
    errorFlag: boolean,
    setFormValues: (formValues: FormValues) => void
}

interface PromoCode {
    id: number,
    code: string,
    displayText: string
}

function promoCodeQueryComplete(promoCode: PromoCode | undefined | null, handleValueChange: HandleValueChangeFunc, setPromoDisplayText: (text: string | null) => void) {
    if (isNullOrUndefined(promoCode)) {
        handleValueChange('promoCodeId', null);
        setPromoDisplayText?.(null);
    } else {
        handleValueChange('promoCodeId', promoCode?.id);
        setPromoDisplayText?.(promoCode?.displayText as string);
    }
}

export function canSubmit(formValues: FormValues): boolean {
    const trimmedFname = formValues.firstName.trim();
    if (!isValidName(trimmedFname)) {
        if (isEmptyString(trimmedFname)) {
            alert("Please enter your first name")
        } else {
            alert("First name field contains invalid data - ensure that it doesn't contain any non-letter characters");
        }
        return false;
    }

    const trimmedLname = formValues.lastName.trim();
    if (!isValidName(trimmedLname)) {
        if (isEmptyString(trimmedLname)) {
            alert("Please enter your last name");
        } else {
            alert("Last name field contains invalid data - ensure that it doesn't contain any non-letter characters");
        }
        return false;
    }

    // if the appointment is not for a business, it doesn't matter what's in this field
    const trimmedBusinessName = formValues.businessName.trim();
    let businessNameOk = formValues.isForHome || ((isNotNullOrUndefined(trimmedBusinessName) && isNotEmptyString(trimmedBusinessName)));
    if (!businessNameOk) {
        alert("Please enter the name of your business");
        return false;
    }

    const trimmedPrimaryPhone = formValues.primaryPhone.trim();
    if (!isPhoneNumber(trimmedPrimaryPhone)) {
        if (isEmptyString(trimmedPrimaryPhone)) {
            alert("Please enter your primary phone number");
        } else {
            alert("Primary phone number field contains invalid data");
        }
        return false;
    }

    const trimmedSecondaryPhone = formValues.secondaryPhone.trim();
    // this field is optional
    if (!(isPhoneNumber(trimmedSecondaryPhone) || isEmptyString(trimmedSecondaryPhone))) {
        alert("Secondary phone number field contains invalid data");
        return false;
    }

    const trimmedPrimaryEmail = formValues.primaryEmail.trim();
    if (!isEmail(trimmedPrimaryEmail)) {
        if (isEmptyString(trimmedPrimaryEmail)) {
            alert("Please enter your primary email address");
        } else {
            alert("Primary email field contains invalid data");
        }

        return false;
    }

    const trimmedSecondaryEmail = formValues.secondaryEmail.trim();
    // this field is optional
    if (!(isEmail(trimmedSecondaryEmail) || isEmptyString(trimmedSecondaryEmail))) {
        alert("Secondary email field contains invalid data");
        return false;
    }

    const trimmedAddress = formValues.streetAddress.trim();
    if (isNullOrUndefined(trimmedAddress) || isEmptyString(trimmedAddress)) {
        alert("Please enter your street address");
        return false;
    }

    const trimmedPromoCodeText = formValues.promoCodeText.trim();
    if (!(isEmptyString(trimmedPromoCodeText) ? isNullOrUndefined(formValues.promoCodeId) : isNotNullOrUndefined(formValues.promoCodeId))) {
        alert("Promo code is invalid");
        return false;
    }

    if (isNullOrUndefined(formValues.howDidYouHearAboutUsId)) {
        alert("Please let us know how you heard about us");
        return false;
    }

    // double check that all information from the first two pages is valid
    // this is kind of overkill because it shouldn't even be possible to get here if those aren't true, but it doesn't hurt
    return canMoveToProductSelection(formValues) && canMoveToContactInfo(formValues);
}

export default function SchedulingContactInfo({ formValues, handleValueChange }: SchedulingContactInfoProps) {
    // the text to display when a valid promo code has been entered
    const [promoDisplayText, setPromoDisplayText] = useState<string | null | undefined>(null);

    const { data: activePromosData } = useGetActivePromosExistQuery();
    const activePromosExist = activePromosData?.activePromosExist;
    useGetActivePromoCodeQuery({
        variables: { code: formValues.promoCodeText },
        onCompleted: (response) => promoCodeQueryComplete(response.activePromoCode, handleValueChange, setPromoDisplayText)
    });
    const { data: howDidYouHearAboutUsOptions } = useGetAllHowDidYouHearAboutUsOptionsQuery({
        onCompleted: (response) => handleValueChange('howDidYouHearAboutUsId', response.allHowDidYouHearAboutUsOptions[0].id)
    });


    return (
        <div className="flex-row flex-centered">
            <div className="flex-column" style={{ width: "28rem" }}>
                <div className="flex-row flex-space-around">
                    <TextField
                        className="margin-sm w-13r"
                        value={formValues.firstName}
                        onChange={(e) => handleValueChange("firstName", e.target.value)}
                        label="First Name"
                        required
                    />
                    <TextField
                        className="margin-sm w-13r"
                        value={formValues.lastName}
                        onChange={(e) => handleValueChange("lastName", e.target.value)}
                        label="Last Name"
                        required
                    />
                </div>

                {/* show business name when the appointment is scheduled for a business */}
                {!formValues.isForHome &&
                    <div className="flex-row flex-space-around">
                        <TextField
                            className="margin-sm w-13r"
                            value={formValues.businessName}
                            onChange={(e) => handleValueChange("businessName", e.target.value)}
                            label="Business Name"
                            required
                        />
                    </div>
                }

                <div className="flex-row flex-space-around">
                    <TextField
                        className="margin-sm w-13r"
                        value={formValues.primaryPhone}
                        onChange={(e) => handleValueChange("primaryPhone", formatPhoneNumber(e.target.value))}
                        label="Primary Phone"
                        required
                    />
                    <TextField
                        className="margin-sm w-13r"
                        value={formValues.primaryEmail}
                        onChange={(e) => handleValueChange("primaryEmail", e.target.value)}
                        label="Primary Email"
                        type="email"
                        required
                    />
                </div>



                <div className="flex-row flex-space-around">
                    <TextField
                        className="margin-sm w-13r"
                        value={formValues.streetAddress}
                        onChange={(e) => handleValueChange("streetAddress", e.target.value)}
                        label="Street Address"
                        required
                    />
                    <TextField
                        className="margin-sm w-13r"
                        value={formValues.apartmentSuiteNum}
                        onChange={(e) => handleValueChange("apartmentSuiteNum", e.target.value)}
                        label="Apartment/Suite #"
                    />
                </div>

                <div className="flex-row flex-space-around">
                    <TextField
                        className="margin-sm w-13r"
                        value={formValues.city}
                        onChange={(e) => handleValueChange("city", e.target.value)}
                        label="City"
                        disabled
                    />
                    <TextField
                        className="margin-sm w-13r"
                        value="Michigan"
                        onChange={(e) => handleValueChange("state", e.target.value)}
                        label="State"
                        disabled
                    />
                </div>

                <div className="flex-row flex-space-around">
                    <TextField
                        className="margin-sm w-13r"
                        value={formValues.zip}
                        label="Zip Code"
                        disabled
                    />
                </div>


                <div className="flex-row flex-space-around">
                    {(activePromosExist &&
                        <TextField
                            className="margin-sm w-13r"
                            value={formValues.promoCodeText}
                            onChange={(e) => handleValueChange("promoCodeText", e.target.value)}
                            label="Promo Code"
                        />)
                    }

                    <FormControl className="margin-sm w-13r" style={{ whiteSpace: "nowrap" }}>
                        <InputLabel>How did you hear about us?</InputLabel>
                        <Select
                            value={formValues.howDidYouHearAboutUsId ?? ''}
                            onChange={(e) => handleValueChange('howDidYouHearAboutUsId', e.target.value as number)}
                            label="How did you hear about us?"
                            required
                        >
                            {howDidYouHearAboutUsOptions?.allHowDidYouHearAboutUsOptions.map(option =>
                                <MenuItem key={`how-did-you-hear-op-${option.id}`} value={option.id}>{option.optionText}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>

                {/* display message to indicate to the user that the promo code they entered is valid */}
                {
                    (activePromosExist && isNotNullOrUndefined(promoDisplayText)) &&
                    (<div style={{ backgroundColor: "lightgreen" }}>
                        <p>{promoDisplayText}</p>
                    </div>)
                }

                {/* Display error message when the entered promo code is invalid (but not the empty string) */}
                {
                    (activePromosExist && !isNotNullOrUndefined(promoDisplayText) && isNotEmptyString(formValues.promoCodeText)) &&
                    (<div style={{ backgroundColor: "#ffcccb" }}>
                        <p>Promo code is invalid.</p>
                    </div>)
                }

                <FormControlLabel
                    control={<Checkbox checked={formValues.receivePromoEmails} />}
                    onChange={() => handleValueChange("receivePromoEmails", !formValues.receivePromoEmails)}
                    label={<Typography variant="body2" color="textSecondary">I would like to receive emails about promotions and product/lifestyle trends</Typography>}
                />
            </div>

            <TextField
                className="margin-sm"
                multiline
                minRows={6}
                maxRows={20}
                variant="filled"
                onChange={(e) => handleValueChange("comments", e.target.value)}
                value={formValues.comments}
                label="Additional Comments"
            />
        </div>
    )
}
