import clsx from "clsx";
import { isNullOrUndefined } from "Globals/GenericValidators";
import NumberFormat from "react-number-format";
import FlatInput from "./FlatInput";
import { FlatLabeledInputBase } from "./flatInputUtils";

interface FlatPercentInputProps {
    value: string | undefined;
    setValue?: (value: string | undefined) => void;
    fixedDecimalScale?: boolean;
    decimalScale?: number;
    className?: string;
    disabled?: boolean;
}

export function FlatPercentInput({
    value,
    setValue,
    fixedDecimalScale=true,
    decimalScale=0,
    className="w-5r",
    disabled=false
}: FlatPercentInputProps) {
    const readOnly = isNullOrUndefined(setValue);

    return (
        <NumberFormat
            customInput={FlatInput}
            value={value}
            onValueChange={readOnly ? undefined : (v) => setValue!(v.value)}
            disabled={disabled}
            readOnly={readOnly}
            className={className}
            displayType="input" allowNegative={false}
            suffix="%"
            decimalScale={decimalScale} fixedDecimalScale={fixedDecimalScale}
        />
    )
}

type FlatLabeledPercentInputProps = FlatLabeledInputBase<FlatPercentInputProps>;

export function FlatLabeledPercentInput({
    value,
    setValue,
    disabled,
    label,
    labelStyle,
    containerProps,
    labelLocation="left",
    labelSize="standard",
    bold,
    ...inputProps
}: FlatLabeledPercentInputProps) {

    const containerClassName = clsx("align-items-center", "flex-gap-xsm", containerProps?.className,
        { "flex-row": labelLocation === "left", "flex-row-reverse": labelLocation === "right" }
    );
    const labelClassName = clsx("margin-none", {
        "flat-font-xsm": labelSize === "xsm",
        "flat-font-sm": labelSize === "sm",
        "flat-font": labelSize === "standard",
        "flat-font-md": labelSize === "md",
        "flat-font-lg": labelSize === "lg",
        "bold-text": bold
    });

    // remove className from the spread props because it will overwrite the directly passed className prop
    const {className, ...cleanedContainerProps} = {...containerProps};

    return (
        <span className={containerClassName} {...cleanedContainerProps}>
            <p className={labelClassName} style={{...labelStyle}}>
                {label}
            </p>
            <FlatPercentInput
                value={value}
                setValue={setValue}
                className={className}
                {...inputProps}
            />
        </span>
    )
}