import {
    useAddBeforeAndAfterPicturesForRoomMutation,
    namedOperations,
    Picture,
    useGetJobConfigurationQuery,
} from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { convertPictureToInput } from "Redux/genericSalesReducer";
import { AdditionalAddNewPhotoButtonProps, AddNewPhotoButton } from "../Generics/AddNewPhotoButton";

export interface TypedUploadPicturesProps extends AdditionalAddNewPhotoButtonProps {
    jobConfigurationId: number;
    onPhotosUploaded?: (uploadCount: number) => void;
}

export default function BeforePictureUploadButton({
    jobConfigurationId,
    singlePhoto,
    onPhotosUploaded
}: TypedUploadPicturesProps ) {
    const { data } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        skip: isNullOrUndefined(jobConfigurationId) && jobConfigurationId! <= 0,
    });

    const [upload] = useAddBeforeAndAfterPicturesForRoomMutation({
        refetchQueries: [namedOperations.Query.GetJobConfiguration],
        awaitRefetchQueries: true,
    });

    function onUpload(roomId: number, pics: Picture[]) {
        upload({
            variables: {
                pictures: pics.map(convertPictureToInput),
                roomId: roomId,
                isBefore: true,
            },
        });
        onPhotosUploaded?.(pics.length)
    }

    return (
        <AddNewPhotoButton
            areas={data?.jobConfiguration.areas ?? []}
            onUpload={onUpload}
            singlePhoto={singlePhoto}
        />
    );
}
