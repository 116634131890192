import { useGetJobServicesByTypeQuery } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { FLOOR_PREP_ID, FURNITURE_ID, FURNITURE_NONE_ID, R_AND_R_ID, SHIM_ID, TRANSITION_ID } from "Globals/globalConstants";
import { Item, Menu, Separator } from "react-contexify";

export const ADDITIONAL_SERVICE_CONTEXT_MENU_ID = "additional-service-menu";

interface AdditionalServicesContextMenuProps {
    handleCustomClicked: () => void;
    handleFurnitureClicked: (jsId: number) => void;
    handleRRClicked: (jsId: number) => void;
    presentFurnitureJobServiceIds: number[];  // furniture services already on the job
    presentRrJobServiceIds: number[]; // R&R services already on the job
    // floor prep is handled different in the AdditionalServicesEditor, so these won't be supplied 
    handleFpClicked?: (jsId: number) => void;
    presentFpJobServiceIds?: number[]; // Floor Prep services already on the job
    handleShimClicked?: (jsId: number) => void;
    hasShimService?: boolean;
}

export default function AdditionalServicesContextMenu({
    handleCustomClicked,
    handleFurnitureClicked,
    handleRRClicked,
    presentFurnitureJobServiceIds,
    presentRrJobServiceIds,
    handleFpClicked,
    presentFpJobServiceIds,
    handleShimClicked,
    hasShimService
}: AdditionalServicesContextMenuProps) {
    const { data: fpJobServiceData } = useGetJobServicesByTypeQuery({
        variables: { serviceTypeId: FLOOR_PREP_ID }
    });
    // don't show options that are already present in the job (they have already been added)
    const fpOptions = (fpJobServiceData?.jobServicesByType ?? []).filter(rrJs => !(presentFpJobServiceIds ?? []).includes(rrJs.id));
    
    const { data: reducerJobServiceData } = useGetJobServicesByTypeQuery({
        variables: { serviceTypeId: TRANSITION_ID }
    });
    // only show the shim service (unless it's already on the job)
    const shimOption = hasShimService ? undefined : reducerJobServiceData?.jobServicesByType.find(js => js.id === SHIM_ID);
    
    (fpJobServiceData?.jobServicesByType ?? [])
        .filter(rrJs => 
            rrJs.id === SHIM_ID
            && (presentFpJobServiceIds ?? []).includes(rrJs.id)
        );

    const { data: furnitureJobServiceData } = useGetJobServicesByTypeQuery({
        variables: { serviceTypeId: FURNITURE_ID }
    });
    // don't show options that are already present in the job (they have already been added)
    const furnitureOptions = (furnitureJobServiceData?.jobServicesByType ?? []).filter(fJs => !(presentFurnitureJobServiceIds.includes(fJs.id) || fJs.id === FURNITURE_NONE_ID));
    
    const { data: rrJobServiceData } = useGetJobServicesByTypeQuery({
        variables: { serviceTypeId: R_AND_R_ID }
    });
    // don't show options that are already present in the job (they have already been added)
    const rrOptions = (rrJobServiceData?.jobServicesByType ?? []).filter(rrJs => !presentRrJobServiceIds.includes(rrJs.id));

    // if the menu isn't meant to handle floor prep, then don't render (handleFpClicked will be undefined)
    const willRenderFpOptions = handleFpClicked && (fpOptions.length > 0 || isNotNullOrUndefined(shimOption));
    const willRenderFurnitureItems = furnitureOptions.length > 0;
    const willRenderRrItems = rrOptions.length > 0;

    return (
        <Menu id={ADDITIONAL_SERVICE_CONTEXT_MENU_ID}>
            <Item
                id="custom"
                onClick={handleCustomClicked}
            >
                Custom
            </Item>

            {(willRenderFpOptions) && <Separator />}
            {(willRenderFpOptions) && fpOptions.map(opt => (
                <Item
                    key={opt.id}
                    onClick={() => handleFpClicked!(opt.id)}
                >
                    {opt.description}
                </Item>
            ))}
            {shimOption && (
                <Item
                    key={shimOption.id}
                    onClick={() => handleShimClicked!(shimOption.id)}
                >
                    {shimOption.description}
                </Item>
            )}

            {(willRenderFurnitureItems) && <Separator />}
            {furnitureOptions.map(opt => (
                <Item
                    key={opt.id}
                    onClick={() => handleFurnitureClicked(opt.id)}
                >
                    Furniture - {opt.description}
                </Item>
            ))}

            {(willRenderFurnitureItems && willRenderRrItems) && <Separator />}
            {rrOptions.map(opt => (
                <Item
                    key={opt.id}
                    onClick={() => handleRRClicked(opt.id)}
                >
                    {opt.description}
                </Item>
            ))}
        </Menu>
   ) 
}