import { Button } from "@material-ui/core"
import { random } from "lodash";
import { changeIndex, selectArrayElement } from "Redux/dummyReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"



function RenderByData({ index }: { index: number }) {
    const width = useAppSelector((state) => selectArrayElement(state, index))
    const dispatch = useAppDispatch();

    return <div style={{ height: "100%", width: width }}>
        {`index: ${index.toString()}, width: ${width}`}
        <Button onClick={
            () => {
                dispatch(changeIndex({ index, newValue: (random(2, 8, false) * 5).toString() + "rem" }));
            }
        }>Randomize Me</Button>
    </div>
}


export default function DummyArrayedSelector() {
    return (
        <div style={{ width: "100vw", height: "100vh", display: "flex" }}>
            <RenderByData index={0} />
            <RenderByData index={1} />
            <RenderByData index={2} />
        </div>
    )
}