import { IconButton } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons//KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import React, { useState } from "react";
import FlatContainer from "./FlatContainer";

export interface FlatSectionProps {
    header?: string | JSX.Element;
    defaultClosed?: boolean;
    collapsible?: boolean;
    className?: string;
    isInnerContainer?: boolean;
    removePadding?: boolean;
    centerAdornment?: string | JSX.Element;
    endAdornment?: string | JSX.Element;
    darkHeader?: boolean;
    stickyHeader?: boolean;
}

export default function FlatSection({
    children,
    header,
    defaultClosed,
    collapsible,
    className,
    isInnerContainer,
    removePadding,
    centerAdornment,
    endAdornment,
    darkHeader=false,
    stickyHeader=false,
    ...props
}: React.PropsWithChildren<FlatSectionProps>) {
    const [isOpen, setIsOpen] = useState(!defaultClosed); 

    const hasCenterAdornment = isNotNullOrUndefined(centerAdornment);
    const hasEndAdornment = isNotNullOrUndefined(endAdornment);

    return (
        <FlatContainer
            removePadding
            className={className}
            isInnerContainer={isInnerContainer}
        >
            <FlatSectionHeader
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                collapsible={collapsible ?? false}
                darkTheme={darkHeader}
                sticky={stickyHeader}
            >
                <div className="grid fill-width">
                    <div className="flex-row fill-width align-items-center">
                        {header}
                    </div>
                    {hasCenterAdornment ? (<>
                        <div
                            className="flex-row fill-width justify-content-center align-items-center"
                            style={{marginLeft: (collapsible && !hasEndAdornment) ? '34px' : 0}}
                        >
                            {centerAdornment}
                        </div>
                        <div className="flex-row fill-width justify-content-end align-items-center">
                            {endAdornment}
                        </div>
                    </>) : (
                        <div style={{gridColumnStart: 2, gridColumnEnd: 5}}>
                            <div className="flex-row fill-width justify-content-end align-items-center">
                                {endAdornment}
                            </div>
                        </div>
                    )}
                </div>
            </FlatSectionHeader>
            {isOpen && <FlatSectionContent removePadding={removePadding}>{children}</FlatSectionContent>}
        </FlatContainer>
    );
}

interface FlatSectionHeaderProps {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    collapsible: boolean;
    darkTheme?: boolean;
    sticky?: boolean;
}

function FlatSectionHeader({
    children,
    isOpen,
    setIsOpen,
    collapsible,
    darkTheme=false,
    sticky=false
}: React.PropsWithChildren<FlatSectionHeaderProps>) {
    return (
        <div
            className={clsx("flat-font-md flat-section-header flat-font-bold", {
                "flat-section-header-pad-right": !collapsible,
                "flat-section-header-closed": !isOpen,
                "flat-lighter-bkg": !darkTheme,
                "flat-dark-bkg": darkTheme,
                "flat-section-header-sticky": sticky 
            })}
        >
            <div
                className="flex-grow flex-row"
                style={{ alignItems: "center" }}
            >
                {children}
            </div>
            <div hidden={!collapsible}>
                <IconButton
                    size="small"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? (
                        <KeyboardArrowUpIcon fontSize="medium" style={{color: "var(--theme-main-text-color)"}}/>
                    ) : (
                        <KeyboardArrowDownIcon fontSize="medium" style={{color: "var(--theme-main-text-color)"}}/>
                    )}
                </IconButton>
            </div>
        </div>
    );
}

function FlatSectionContent({
    removePadding,
    children,
}: React.PropsWithChildren<{ removePadding?: boolean }>) {
    return (
        <div
            className={clsx("flat-section-content flat-font-sm", {
                "flat-outer-container-padding": removePadding !== true,
            })}
        >
            {children}
        </div>
    );
}

// for symbolically meaningful import elsewhere
export type FlatSubsectionProps = React.PropsWithChildren<FlatSectionProps>;

export function FlatSubSection({
    children,
    header,
    defaultClosed,
    collapsible,
    className,
    isInnerContainer=true,
    removePadding,
    endAdornment,
    ...props
}: FlatSubsectionProps) {
    const [isOpen, setIsOpen] = useState(!defaultClosed);

    return (
        <FlatContainer
            removePadding
            className={className}
            isInnerContainer={isInnerContainer}
        >
            <FlatSubSectionHeader
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                collapsible={collapsible ?? false}
            >
                <div className="flex-row fill-width flex-space-between align-items-center">
                    {header}
                    {endAdornment}
                </div>
            </FlatSubSectionHeader>
            {isOpen && <FlatSubSectionContent removePadding={removePadding}>{children}</FlatSubSectionContent>}
        </FlatContainer>
    );
}

function FlatSubSectionHeader({
    children,
    isOpen,
    setIsOpen,
    collapsible,
}: React.PropsWithChildren<FlatSectionHeaderProps>) {
    return (
        <div
            className={clsx("flat-font flat-font-upper font-weight-500 flat-sub-section-header", {
                "flat-section-header-pad-right": collapsible === false,
                "flat-section-header-closed": isOpen === false,
            })}
        >
            <div className="flex-grow flex-row align-items-center">
                {children}
            </div>
            <div hidden={!collapsible}>
                <IconButton
                    size="small"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? (
                        <KeyboardArrowUpIcon fontSize="medium" />
                    ) : (
                        <KeyboardArrowDownIcon fontSize="medium" />
                    )}
                </IconButton>
            </div>
        </div>
    );
}

function FlatSubSectionContent({
    removePadding,
    children,
}: React.PropsWithChildren<{ removePadding?: boolean }>) {
    return (
        <div className={clsx("flat-sub-section-content flat-font-sm", {"flat-inner-container-padding-sm": removePadding !== true})}>
            {children}
        </div>
    );
}
