import { Day } from "@hassanmojab/react-modern-calendar-datepicker";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import CircleCloseButton from "Components/Forms/Controls/CircleCloseButton";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import FlatButton from "FlatComponents/Button/FlatButton";
import {
    dateTimeStrToMdy,
    dateToMdy,
    dayToYmd,
    miltiaryToHourMinute,
    timeSpanStrToHHMM12HR,
} from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import {
    WorkerBlockedTimeSlot,
    namedOperations,
    useAddWorkerBlockedTimeSlotMutation,
    useAddWorkerBlockedTimeSlotRangeMutation,
    useDeleteWorkerBlockedTimeSlotMutation,
    useGetAuthenticatedWorkerQuery,
    useGetSalespersonQuery,
    useGetWorkerBlockedTimeSlotsAfterDateQuery,
    useUpdateWorkerBlockedTimeSlotMutation,
} from "generated/graphql";
import { useState } from "react";
import AddBlockTimeRange from "./AddBlockTimeRange";
import AddOrUpdateBlockTime from "./AddOrUpdateBlockTime";

export default function BlockTimePage() {
    const { data, loading } = useGetAuthenticatedWorkerQuery({ fetchPolicy: "network-only" });
    const { data: salespersonData, loading: salespersonLoading } = useGetSalespersonQuery({
        variables: { workerId: data?.authenticatedWorker?.id! },
        skip: isNullOrUndefined(data?.authenticatedWorker?.id),
    });

    if (loading || salespersonLoading) {
        return <Loading />;
    } else if (isNullOrUndefined(data?.authenticatedWorker?.id)) {
        return <NavbarPage>Worker Not Found</NavbarPage>;
    } else {
        const routes = isNullOrUndefined(salespersonData?.salesperson)
            ? undefined
            : [{ name: "Home", route: "/home" }];

        return (
            <NavbarPage customRoutes={routes}>
                <BlockTimeBody workerId={data?.authenticatedWorker?.id!} />
            </NavbarPage>
        );
    }
}

interface BlockTimeBodyProps {
    workerId: number;
    blockWholeDaysOnly?: boolean;
    blockedDays?: Day[];
}

export function BlockTimeBody({ workerId, blockWholeDaysOnly, blockedDays }: BlockTimeBodyProps) {
    const { data } = useGetWorkerBlockedTimeSlotsAfterDateQuery({
        variables: { workerId, afterDate: dateToMdy(new Date()) },
    });
    const [addTimeSlot] = useAddWorkerBlockedTimeSlotMutation({
        refetchQueries: [
            namedOperations.Query.GetWorkerBlockedTimeSlotsAfterDate,
            namedOperations.Query.GetDaysContractorIsBusy,
        ],
    });
    const [addTimeSlotRange] = useAddWorkerBlockedTimeSlotRangeMutation({
        refetchQueries: [
            namedOperations.Query.GetWorkerBlockedTimeSlotsAfterDate,
            namedOperations.Query.GetDaysContractorIsBusy,
        ],
    });
    const [updateTimeSlot] = useUpdateWorkerBlockedTimeSlotMutation({
        refetchQueries: [
            namedOperations.Query.GetWorkerBlockedTimeSlotsAfterDate,
            namedOperations.Query.GetDaysContractorIsBusy,
        ],
    });
    const [deleteTimeSlot] = useDeleteWorkerBlockedTimeSlotMutation({
        refetchQueries: [
            namedOperations.Query.GetWorkerBlockedTimeSlotsAfterDate,
            namedOperations.Query.GetDaysContractorIsBusy,
        ],
    });
    const [editBlock, setEditBlock] = useState<WorkerBlockedTimeSlot | null | undefined>(null);
    const [addDayRange, setAddDayRange] = useState<boolean>(false);

    const isWholeDayOnly = blockWholeDaysOnly ?? false;

    return (
        <div
            className="flex-column flex-centered"
            style={{ padding: "8px 16px" }}
        >
            <div
                className="flex-row fill-width contractor-customer-bar flat-dark-bkg"
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: "1.5rem",
                }}
            >
                <div
                    className="flex-grow flat-font-lg"
                    style={{ textAlign: "center" }}
                >
                    Upcoming Blocked Time
                </div>
                <FlatButton
                    color="primary"
                    style={{ minWidth: 0, padding: "6px" }}
                    onClick={() => {
                        if (isWholeDayOnly) {
                            setAddDayRange(true);
                        } else {
                            setEditBlock(undefined);
                        }
                    }}
                    variant="contained"
                >
                    <MoreTimeIcon fontSize="small" />
                </FlatButton>
            </div>
            {data?.workerBlockedTimeSlotsAfterDate.length === 0 && <h3>No Blocked Times</h3>}
            {data?.workerBlockedTimeSlotsAfterDate.map((timeSlot) => {
                const allDay = timeSlot.startTime === "PT0S" && timeSlot.endTime === "PT23H59M";

                return (
                    <div
                        key={`blocked-row-${timeSlot.id}`}
                        className="flex-row fill-width"
                        style={{
                            alignItems: "center",
                            flexWrap: "wrap",
                            backgroundColor: "var(--flat-gray-2)",
                            borderRadius: "4px",
                            marginTop: "4px",
                            paddingLeft: "16px"
                        }}
                    >
                        <div className="flat-font" style={{ paddingRight: ".25rem" }}>
                            {dateTimeStrToMdy(timeSlot.date)}:
                        </div>
                        <div className="flat-font">
                            {allDay
                                ? "Whole Day"
                                : `${timeSpanStrToHHMM12HR(
                                      timeSlot.startTime
                                  )} - ${timeSpanStrToHHMM12HR(timeSlot.endTime)}`}
                        </div>
                        <IconButton
                            hidden={isWholeDayOnly}
                            onClick={() => setEditBlock(timeSlot)}
                            size="small"
                        >
                            <EditIcon />
                        </IconButton>
                        <div className="flex-grow" />
                        <CircleCloseButton
                            onClick={() =>
                                deleteTimeSlot({
                                    variables: { workerBlockedTimeSlotId: timeSlot.id },
                                })
                            }
                        />
                    </div>
                );
            })}
            {editBlock !== null && (
                <AddOrUpdateBlockTime
                    wholeDayOnly={blockWholeDaysOnly}
                    onClose={(newValue) => {
                        if (newValue === undefined) {
                            setEditBlock(null);
                            return;
                        }
                        const timeSlot = { ...newValue, workerId };

                        const { hour: startHour, minute: startMinute } = miltiaryToHourMinute(
                            timeSlot.startTime!
                        );
                        const { hour: endHour, minute: endMinute } = miltiaryToHourMinute(
                            timeSlot.endTime!
                        );

                        if (timeSlot?.id === -1) {
                            addTimeSlot({
                                variables: {
                                    workerId: workerId,
                                    date: timeSlot.date!,
                                    timeSlot: { startHour, startMinute, endHour, endMinute },
                                },
                            });
                        } else {
                            updateTimeSlot({
                                variables: {
                                    workerBlockedTimeSlotId: timeSlot.id!,
                                    date: timeSlot.date!,
                                    timeSlot: { startHour, startMinute, endHour, endMinute },
                                },
                            });
                        }
                        setEditBlock(null);
                    }}
                    existingBlockTime={editBlock ?? undefined}
                />
            )}
            {addDayRange && (
                <AddBlockTimeRange
                    blockedDays={blockedDays}
                    onClose={(range) => {
                        setAddDayRange(false);
                        if (range === undefined) return;

                        const { from, to } = range;

                        const fromDay = dayToYmd(from ?? to!);
                        const toDay = isNotNullOrUndefined(to) ? dayToYmd(to!) : fromDay;

                        addTimeSlotRange({
                            variables: {
                                workerId: workerId,
                                startDate: fromDay,
                                endDate: toDay,
                            },
                        });
                    }}
                />
            )}
        </div>
    );
}
