import { Calendar, Day, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { useGetAllSalespeopleInZipQuery } from "generated/graphql";
import { dayToIso, dayToYmd } from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import AvailableTimeSlots from "Pages/Admin/SAHScheduling/AvailableTimeSlots";
import { useState } from "react";

interface SelectSalespersonAndTimeslotMenuProps {
    initialSalespersonId?: number,
    initialDate?: Day,
    zip: string,
    onClose: () => void,
    onSubmit: (selectedSalespersonId: number | null, selectedDate: string, selectedMarketTimeSlotId: number) => void
}

export default function SelectSalespersonAndTimeslotMenu({ initialSalespersonId, initialDate, onSubmit, onClose, zip }: SelectSalespersonAndTimeslotMenuProps) {
    const currentDay = utils('en').getToday()

    const { data: spData } = useGetAllSalespeopleInZipQuery({ variables: { zip } })
    const salespeople = spData?.allSalespeopleInZip ?? []

    const [salespersonId, setSalespersonId] = useState<number>(initialSalespersonId ?? 0)
    const [day, setDay] = useState<DayValue>(initialDate !== undefined ? initialDate : currentDay)
    const [timeSlot, setTimeSlot] = useState<number | null>(null)

    const displaySalespersonId = !salespeople.some(sp => sp.id === salespersonId) ? undefined : salespersonId

    function updateDay(newVal: DayValue) {
        setTimeSlot(null);
        setDay(newVal)
    }

    function submit() {
        if (isNullOrUndefined(day) || isNullOrUndefined(timeSlot)) {
            return;
        }
        else onSubmit(displaySalespersonId ?? null, dayToYmd(day!), timeSlot!)
    }

    function updateSelectedSalesperson(spId: number) {
        setSalespersonId(spId);
        setTimeSlot(null)
    }

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>
                Reschedule Appointment
            </DialogTitle>
            <DialogContent>
                <div className="flex-column flex-centered" style={{ minHeight: "15rem", minWidth: "min(25rem, 85vw)" }}>
                    <div>
                        <Select value={displaySalespersonId ?? 0} onChange={e => updateSelectedSalesperson(e.target.value as number)}>
                            {
                                salespeople.map(sp => <MenuItem key={`sp-menu-${sp.id}`} value={sp.id}>{sp.firstName} {sp.lastName}</MenuItem>)
                            }
                            <MenuItem value={0}>Any Salesperson</MenuItem>
                        </Select>
                    </div>
                    <div className="padding-sm">
                        <Calendar
                            value={day}
                            onChange={(day) => updateDay(day)}
                            minimumDate={currentDay}
                        />
                    </div>
                    {
                        day?.day !== undefined &&
                        <div className="padding-sm">
                            <AvailableTimeSlots
                                selectedSlotId={timeSlot}
                                dayISO={dayToIso(day!)}
                                onSelectSlot={setTimeSlot}
                                zip={zip}
                                salespersonId={displaySalespersonId}
                            />
                        </div>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <SpacedButton className='cancel-button' onClick={onClose}>Cancel</SpacedButton>
                <SpacedButton variant="contained" color='secondary' onClick={submit}>Submit</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}