import FlatButton from "FlatComponents/Button/FlatButton";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { namedOperations, SalespersonProfile, SalespersonProfileInput, useUpsertSalespersonProfileMutation } from "generated/graphql";
import { prepareSalespersonProfile } from "Globals/dataPreparationUtils";
import { emptyAddress, emptyCompany, emptyVehicle } from "Globals/DataStructures/EmptyDataStructures";
import { isNotNullOrUndefined, isSalespersonProfileValid } from "Globals/GenericValidators";
import { formatNameStringFirstLast, formatPhoneNumber } from "Globals/StringFormatting";
import { useState } from "react";
import { VehicleConditionSelect, VehicleYearSelect } from "../GeneralProfileComponents/ProfileSelects";

interface EditSalespersonDialogProps {
    onClose: () => void;
    salesperson: SalespersonProfile;
    onComplete?: (newWorkerId: number) => void;
}

export default function EditSalespersonDialog({salesperson: originalSalesperson, onClose, onComplete}: EditSalespersonDialogProps) {
    const newMode = originalSalesperson.id === -1;

    const headerText = newMode ? "New Sales Agent" : `Editing ${formatNameStringFirstLast({firstName: originalSalesperson.firstName, lastName: originalSalesperson.lastName})}`;
    const [salesperson, setSalesperson] = useState(addSalespersonProfileMissingFields(originalSalesperson));

    /** Returns undefined if the data can't be submitted, otherwise formats the data for submission */
    function validateAndPrepareData(): SalespersonProfileInput | undefined {
        const preparedProfile = prepareSalespersonProfile(salesperson);
        if (isSalespersonProfileValid(preparedProfile, true)) {
            return preparedProfile;
        } else {
            return undefined;
        }
    }

    // the loading flag of the mutation doesn't get unset when mutation fails for some reason, so do it manually
    const [upserting, setUpserting] = useState(false);
    const [upsertSalesperson] = useUpsertSalespersonProfileMutation({
        onError: () => {
            setUpserting(false);
            alert("Could not update sales agent information");
        },
        refetchQueries: [namedOperations.Query.GetSalespersonProfile]
    });

    function onSubmit() {
        const preparedData = validateAndPrepareData();
        if (isNotNullOrUndefined(preparedData)) {
            setUpserting(true);
            // worker history is updated in a separate mutation
            const {workerHistory, ...sp} = preparedData!;
            upsertSalesperson({
                variables: ({salesperson: sp}),
                onCompleted: (data) => {
                    onClose();
                    if (onComplete) {
                        onComplete(data.upsertSalespersonProfile)
                    }
                }
            });
        }
    }

    const submitButton = (
        <FlatButton
            key={"submit-espd"}
            onClick={onSubmit}
            variant="contained"
            color="secondary"
            disabled={upserting}
        >Submit</FlatButton>
    );

    return (
        <FlatDialog
            sectionProps={{header: headerText}}
            dialogProps={{open: true, onClose: onClose, maxWidth: "md"}}
            actionButtons={[submitButton]}
        >
            <div className="grid-50-50 flex-row-gap-xsm">
                <FlatLabeledInput
                    label="First Name"
                    value={salesperson.firstName}
                    onChange={(e) => setSalesperson({...salesperson, firstName: e.target.value})}    
                />
                <FlatLabeledInput
                    label="Last Name"
                    value={salesperson.lastName}
                    onChange={(e) => setSalesperson({...salesperson, lastName: e.target.value})}    
                />
                <FlatLabeledInput
                    label="Phone"
                    value={formatPhoneNumber(salesperson.phone)}
                    onChange={(e) => setSalesperson({...salesperson, phone: e.target.value})}    
                />
                <FlatLabeledInput
                    label="Email"
                    className="w-15r"
                    value={salesperson.email}
                    onChange={(e) => setSalesperson({...salesperson, email: e.target.value})}    
                />
                <FlatLabeledInput
                    label="Street Address"
                    value={salesperson.address!.streetAddress}
                    onChange={e => setSalesperson({
                        ...salesperson,
                        address: { ...salesperson.address!, streetAddress: e.target.value }
                    })}
                />
                <FlatLabeledInput
                    label="City"
                    value={salesperson.address!.city}
                    onChange={e => setSalesperson({
                        ...salesperson,
                        address: { ...salesperson.address!, city: e.target.value }
                    })}
                />
                <FlatLabeledInput
                    label="Zip"
                    className="w-3r text-align-center"
                    value={salesperson.address!.zip}
                    onChange={e => setSalesperson({
                        ...salesperson,
                        address: { ...salesperson.address!, zip: e.target.value }
                    })}
                />
                <FlatLabeledInput
                    label="Apt. #"
                    value={salesperson.address!.apartmentNumber ?? undefined}
                    onChange={e => setSalesperson({
                        ...salesperson,
                        address: { ...salesperson.address!, apartmentNumber: e.target.value }
                    })}
                />
                <div className="flat-thin-horizontal-bar margin-vertical-xsm grid-colspan-1-3"/>
                <FlatLabeledInput
                    label="Driver's License #"
                    value={salesperson.driversLicenseNumber ?? undefined}
                    onChange={(e) => setSalesperson({...salesperson, driversLicenseNumber: e.target.value})}    
                />
                <FlatLabeledInput
                    label="Vehicle Description"
                    value={salesperson.vehicle?.description ?? undefined}
                    onChange={(e) => setSalesperson({
                        ...salesperson, vehicle: {...salesperson.vehicle!, description: e.target.value}
                    })} 
                />
                <VehicleYearSelect 
                    year={salesperson.vehicle?.year ?? undefined}
                    setYear={(year?: number) => {
                        setSalesperson({
                            ...salesperson,
                            vehicle: {
                                ...salesperson.vehicle!,
                                year: year
                            }
                        })
                    }}
                />
                <VehicleConditionSelect 
                    condition={salesperson.vehicle?.condition ?? undefined}
                    setCondition={(condition?: string) => {
                        setSalesperson({
                            ...salesperson,
                            vehicle: {
                                ...salesperson.vehicle!,
                                condition: condition
                            }
                        });
                    }}
                />
                <FlatLabeledInput
                    label="License Plate Number"
                    className="w-5r"
                    value={salesperson.vehicle?.licensePlateNumber ?? undefined}
                    onChange={(e) => setSalesperson({
                        ...salesperson, vehicle: {...salesperson.vehicle!, licensePlateNumber: e.target.value}
                    })}
                />
                <div className="flat-thin-horizontal-bar margin-vertical-xsm grid-colspan-1-3"/>
                <FlatLabeledInput
                    label="Company Name"
                    value={salesperson.company!.name}
                    onChange={(e) => setSalesperson({
                        ...salesperson,
                        company: {...salesperson.company!, name: e.target.value}
                    })}    
                />
                <FlatLabeledInput
                    label="Street Address"
                    value={salesperson.company!.address!.streetAddress}
                    onChange={e => setSalesperson({
                        ...salesperson,
                        company: {
                            ...salesperson.company!,
                            address: {
                                ...salesperson.company!.address,
                                streetAddress: e.target.value
                            }
                        }
                    })}
                />
                <FlatLabeledInput
                    label="City"
                    value={salesperson.company!.address!.city}
                    onChange={e => setSalesperson({
                        ...salesperson,
                        company: {
                            ...salesperson.company!,
                            address: {
                                ...salesperson.company!.address,
                                city: e.target.value
                            }
                        }
                    })}
                />
                <FlatLabeledInput
                    label="Zip"
                    value={salesperson.company!.address!.zip}
                    onChange={e => setSalesperson({
                        ...salesperson,
                        company: {
                            ...salesperson.company!,
                            address: {
                                ...salesperson.company!.address,
                                zip: e.target.value
                            }
                        }
                    })}
                />
                <FlatLabeledInput
                    label="EIN"
                    value={salesperson.company!.eIN ?? ""}
                    onChange={(e) => setSalesperson({
                        ...salesperson,
                        company: {
                            ...salesperson.company!,
                            eIN: e.target.value
                        }
                    })}
                />
                <FlatLabeledInput
                    label="Parent Company"
                    value={salesperson.parentCompany ?? ""}
                    onChange={(e) => setSalesperson({
                        ...salesperson,
                        parentCompany: e.target.value
                    })}
                />
            </div>
        </FlatDialog>
    )
}

// enables editing of salesperson profile when optional fields are missing
export function addSalespersonProfileMissingFields(salesperson: SalespersonProfile): SalespersonProfile {
    return {
        ...salesperson,
        address: isNotNullOrUndefined(salesperson.address) ? {...salesperson.address!} : {...emptyAddress},
        company: isNotNullOrUndefined(salesperson.company) ? {...salesperson.company!} : {...emptyCompany},
        vehicle: isNotNullOrUndefined(salesperson.vehicle) ? {...salesperson.vehicle!} : {...emptyVehicle}
    };
}