import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { BasePromotion, BasePromotionInput, namedOperations, TypeDetailForBasePromotion, TypeDetailForBasePromotionInput, useUpsertBasePromotionMutation } from "generated/graphql";
import { isEmptyString, isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import PromoTypeDetailEditor from "./PromoCategoryEditor";

export interface EditableTypeForBasePromotion {
    typeLabel: string;
    promotionTypeId: number;
    isScalar: boolean | null;
    amount: string;
}

export interface EditableBasePromotion {
    id: number;
    name: string,
    promotionTypeDetails: EditableTypeForBasePromotion[];
}

function typeForBasePromotionToEditable(detail: TypeDetailForBasePromotion): EditableTypeForBasePromotion {
    return {
        typeLabel: detail.promotionType.label,
        promotionTypeId: detail.promotionType.id,
        isScalar: detail.isScalar,
        amount: (detail.amount * (detail.isScalar ? 100 : 1)).toFixed(2)
    }
}

export function basePromotionToEditable(basePromo: BasePromotion): EditableBasePromotion {
    return {
        id: basePromo.id,
        name: basePromo.name,
        promotionTypeDetails: basePromo.promotionTypes.map(tfbp => typeForBasePromotionToEditable(tfbp))
    };
}

export const newBasePromotion: EditableBasePromotion = {
    id: -1,
    name: "",
    promotionTypeDetails: []
}

interface BasePromoEditorDialogProps {
    promo: EditableBasePromotion;
    onClose: () => void;
}

export default function BasePromoEditorDialog({promo, onClose}: BasePromoEditorDialogProps) {
    const originalPromo = {...promo};
    const newMode = originalPromo.id === -1;
    const [editablePromo, setEditablePromo] = useState({...originalPromo});

    const [upsertBasePromo] = useUpsertBasePromotionMutation({
        refetchQueries: [namedOperations.Query.GetBasePromotionsTableData],
        onError: () => alert(`Could not ${newMode ? "create" : "update"} promotion`),
        onCompleted: () => {
            alert(`Promotion ${newMode ? "created" : "updated"} successfully`);
            onClose();
        }
    });

    function canSubmit() {
        if (isEmptyString(editablePromo.name)) {
            alert("Enter a name for the promotion");
            return false;
        } else if (editablePromo.name.startsWith("Copy of")) {
            alert("The promo name may not begin with \"Copy of\"");
            return false;
        }

        let atLeastOneCategoryIncluded = false;
        let hasInvalidData = false;
        // ensure data for each promo type included is valid
        editablePromo.promotionTypeDetails.forEach(ptd => {
            // first flag is because there's no way to short circuit Array.prototype.forEach
            // if isScalar is null/undefined, it's not being included in the promo, so it doesn't matter
            if (!hasInvalidData && isNotNullOrUndefined(ptd.isScalar)) {
                atLeastOneCategoryIncluded = true;
                if (isEmptyString(ptd.amount)) {
                    alert(`Select an amount for ${ptd.typeLabel}`);
                    hasInvalidData = true;
                } else if (+ptd.amount === 0) {
                    alert(`Enter a nonzero amount for ${ptd.typeLabel}`);
                    hasInvalidData = true;
                } else if (ptd.isScalar && +ptd.amount > 100) {
                    alert(`Percentage must be 100 or less for ${ptd.typeLabel}`);
                    hasInvalidData = true;
                }
            }
        });
        if (hasInvalidData) return false;

        if (!atLeastOneCategoryIncluded) {
            alert("Promotion must apply to at least one category");
            return false;
        }

        return true;
    }

    function editablePromoTypeDetailsToInput(rawDetails: EditableTypeForBasePromotion[], basePromoId: number): TypeDetailForBasePromotionInput[] {
        return rawDetails.map(ptd => {
            if (isNullOrUndefined(ptd.isScalar)) {
                return null;
            } else {
                return {
                    basePromotionId: basePromoId,
                    promotionType: {
                        id: ptd.promotionTypeId,
                        label: ptd.typeLabel  // not used
                    },
                    isScalar: ptd.isScalar!,
                    amount: +ptd.amount / (ptd.isScalar ? 100 : 1)
                }
            }
        }).filter(ptd => isNotNullOrUndefined(ptd)) as TypeDetailForBasePromotionInput[];
    }

    function onSubmit() {
        if (canSubmit()) {
            const newPromo: BasePromotionInput = {
                id: editablePromo.id,
                name: editablePromo.name,
                promotionTypes: editablePromoTypeDetailsToInput(editablePromo.promotionTypeDetails, editablePromo.id),
                isDeleted: false
            }

            upsertBasePromo({variables: { promotion: newPromo }});
        }
    }
    
    return (
        <Dialog open={true} fullWidth maxWidth="md">
            <DialogTitle>{newMode ? "New Promotion" : `Editing ${originalPromo.name}`}</DialogTitle>
            
            <DialogContent className="padding-md fit-content">
                <div className="flex-column">
                    
                    <TextField
                        label="Name" error={editablePromo.name.startsWith("Copy of")}
                        value={editablePromo.name} onChange={e => setEditablePromo({...editablePromo, name: e.target.value})}
                    />

                    <PromoTypeDetailEditor promo={editablePromo} setPromo={setEditablePromo}/>
                </div>
            </DialogContent>

            <DialogActions>
                <SpacedButton className="cancel-button" onClick={onClose}>Cancel</SpacedButton>
                <SpacedButton variant="contained"color="secondary" onClick={onSubmit}>Submit</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}