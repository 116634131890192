import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommissionRate, FinancingOption, PriceOverride, PriceRequest, RecoveryCenterOverride } from 'generated/graphql';
import { PricingCenterUsageContext } from 'Pages/Admin/CommandCenter/PricingCenter/PricingCenter';
import { RootState } from './store';

interface PricingCenterState {
    chatId: number;
    jobConfigurationId: number;
    contractConfigurationId?: number;  // to indicate which config was sold (if any)
    quoteConfigIdToQuoteIdMap: {[configId: number]: number}; // maps config IDs with quotes to the quote ID
    ccWorkerId: number;
    commissionRates: CommissionRate[];
    ccStandardCommissionRate: number;  // commission suggested by the commission rate table
    ccActualCommissionRate: number; // actual commission that the salesperson will be given
    recoveryStandardCommissionRate: number;  // commission suggested by the commission rate table
    recoveryActualCommissionRate: number; // actual commission that the salesperson will be given
    jobCost: number;
    msrp: number;
    msrpScalar: number;
    preOverridePrice: number;
    totalPrice: number;
    priceRequest?: PriceRequest;
    override?: PriceOverride;
    recoveryOverride?: RecoveryCenterOverride;
    financingOption: FinancingOption | null;
    usageContext: PricingCenterUsageContext;
}

const initialState: PricingCenterState = {
    chatId: -1,
    jobConfigurationId: -1,
    contractConfigurationId: undefined,
    quoteConfigIdToQuoteIdMap: {},
    ccWorkerId: -1,
    commissionRates: [],
    // for the command center override editor
    ccStandardCommissionRate: -1,
    ccActualCommissionRate: -1,
    // for the recovery center price editor
    recoveryStandardCommissionRate: -1,
    recoveryActualCommissionRate: -1,
    jobCost: -1,
    msrp: -1,
    msrpScalar: -1,
    preOverridePrice: -1,
    totalPrice: -1,
    financingOption: null,
    usageContext: "readonly"
}

export const pricingCenterSlice = createSlice({
    name: "pricingSummary",
    initialState, 
    reducers: {
        setChatId: (state, action: PayloadAction<number>) => {state.chatId = action.payload},
        setJobConfigurationId: (state, action: PayloadAction<number>) => {state.jobConfigurationId = action.payload},
        setContractConfigurationId: (state, action: PayloadAction<number | undefined>) => {state.contractConfigurationId = action.payload},
        setQuoteConfigToQuoteIds: (state, action: PayloadAction<{[configId: number]: number}>) => {state.quoteConfigIdToQuoteIdMap = action.payload},
        setCcWorkerId: (state, action: PayloadAction<number>) => {state.ccWorkerId = action.payload},
        setCommissionRates: (state, action: PayloadAction<CommissionRate[]>) => {state.commissionRates = action.payload},
        setCcStandardCommissionRate: (state, action: PayloadAction<number>) => {state.ccStandardCommissionRate = action.payload},
        setCcActualCommissionRate: (state, action: PayloadAction<number>) => {state.ccActualCommissionRate = action.payload},
        setRecoveryStandardCommissionRate: (state, action: PayloadAction<number>) => {state.recoveryStandardCommissionRate = action.payload},
        setRecoveryActualCommissionRate: (state, action: PayloadAction<number>) => {state.recoveryActualCommissionRate = action.payload},
        setJobCost: (state, action: PayloadAction<number>) => {state.jobCost = action.payload},
        setMSRP: (state, action: PayloadAction<number>) => {state.msrp = action.payload},
        setMSRPScalar: (state, action: PayloadAction<number>) => {state.msrpScalar = action.payload},
        setPreOverridePrice: (state, action: PayloadAction<number>) => {state.preOverridePrice = action.payload},
        setTotalPrice: (state, action: PayloadAction<number>) => {state.totalPrice = action.payload},
        setPriceRequest: (state, action: PayloadAction<PriceRequest | undefined>) => {state.priceRequest = action.payload},
        setOverride: (state, action: PayloadAction<PriceOverride | undefined>) => {state.override = action.payload},
        setRecoveryOverride: (state, action: PayloadAction<RecoveryCenterOverride | undefined>) => {state.recoveryOverride = action.payload},
        setFinancingOption: (state, action: PayloadAction<FinancingOption | null>) => {state.financingOption = action.payload},
        setUsageContext: (state, action: PayloadAction<PricingCenterUsageContext>) => {state.usageContext = action.payload},
    }
});

export const {
    setChatId, setJobConfigurationId, setContractConfigurationId, setCcWorkerId, setCommissionRates,
    setCcStandardCommissionRate, setCcActualCommissionRate, setRecoveryStandardCommissionRate,
    setRecoveryActualCommissionRate, setJobCost, setMSRP, setMSRPScalar, setPreOverridePrice, setTotalPrice,
    setPriceRequest, setOverride, setFinancingOption, setUsageContext, setRecoveryOverride, setQuoteConfigToQuoteIds
} = pricingCenterSlice.actions

export const selectChatId = (state: RootState) => state.pricingSummary.chatId;
export const selectContractConfigurationId = (state: RootState) => state.pricingSummary.contractConfigurationId;
export const selectQuoteConfigToQuoteIdMap = (state: RootState) => state.pricingSummary.quoteConfigIdToQuoteIdMap;
export const selectJobConfigurationId = (state: RootState) => state.pricingSummary.jobConfigurationId;
export const selectCcWorkerId = (state: RootState) => state.pricingSummary.ccWorkerId;
export const selectCommissionRates = (state: RootState) => state.pricingSummary.commissionRates;
export const selectCcStandardCommissionRate = (state: RootState) => state.pricingSummary.ccStandardCommissionRate;
export const selectCcActualCommissionRate = (state: RootState) => state.pricingSummary.ccActualCommissionRate;
export const selectRecoveryStandardCommissionRate = (state: RootState) => state.pricingSummary.recoveryStandardCommissionRate;
export const selectRecoveryActualCommissionRate = (state: RootState) => state.pricingSummary.recoveryActualCommissionRate;
export const selectJobCost = (state: RootState) => state.pricingSummary.jobCost;
export const selectMSRP = (state: RootState) => state.pricingSummary.msrp;
export const selectMSRPScalar = (state: RootState) => state.pricingSummary.msrpScalar;
export const selectPreOverridePrice = (state: RootState) => state.pricingSummary.preOverridePrice;
export const selectTotalPrice = (state: RootState) => state.pricingSummary.totalPrice;
export const selectPriceRequest = (state: RootState) => state.pricingSummary.priceRequest;
export const selectOverride = (state: RootState) => state.pricingSummary.override;
export const selectRecoveryOverride = (state: RootState) => state.pricingSummary.recoveryOverride;
export const selectFinancingOption = (state: RootState) => state.pricingSummary.financingOption;
export const selectUsageContext = (state: RootState) => state.pricingSummary.usageContext;

export default pricingCenterSlice.reducer;