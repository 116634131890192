import { Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import Loading from "Components/Loading";
import { HBar } from "Components/pageSpacing";
import {
    SahAppointment,
    useCheckIntoSahAppointmentMutation,
    useGetJobSummaryDataQuery,
    useReportCustomerNotHomeSahAppointmentMutation,
    useWaitingForCustomerSahAppointmentMutation,
} from "generated/graphql";
import { dateTimeStrToMdy, timeSpanStrToHHMM12HR } from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { formatPhoneNumber } from "Globals/StringFormatting";
import {
    ACCEPTED_BY_SALESPERSON_STATUS_ID,
    ASSIGNED_STATUS_ID,
    CHECKED_IN_STATUS_ID,
    CHECKED_OUT_STATUS_ID,
    RELEASED_TO_SALESPERSON_STATUS_ID,
    UNASSIGNED_STATUS_ID,
    CUSTOMER_NOT_HOME_STATUS_ID,
    RESCHEDULED_STATUS_ID,
    WAITING_FOR_CUSTOMER_STATUS_ID,
    WRITE_UP_STATUS_ID,
} from "Pages/Admin/CommandCenter/Dashboard/AppointmentStatus";
import { useState } from "react";

export default function DisplaySalespersonAppointments({appointments}: {appointments: SahAppointment[]}) {
    function getShouldHighlight(appointment: SahAppointment): boolean {
        const apptDate = dateTimeStrToMdy(appointment.date);
        const currDate = dateTimeStrToMdy(new Date().toISOString());

        return apptDate === currDate;
    }

    const isMobile = window.screen.width < 600;

    return (
        <div style={{ width: "100%" }}>
            {isMobile ? (
                appointments.map((app, index) => (
                    <MobileSalespersonAppointment
                        key={`mobile-sp-app-${app.id}`}
                        appointment={app}
                        highlight={index === 0 && getShouldHighlight(app)}
                    />
                ))
            ) : (
                <div>
                    {appointments.map((app, index) => (
                        <DesktopSalespersonAppointment
                            key={`desktop-sp-app-${app.id}`}
                            appointment={app}
                            highlight={index === 0 && getShouldHighlight(app)}
                        />
                    ))}
                </div>
            )}
            {appointments.length === 0 && (
                <Typography
                    variant="h5"
                    style={{ width: "100%", textAlign: "center", paddingTop: "1rem" }}
                >
                    No Appointments
                </Typography>
            )}
        </div>
    );
}

interface SalespersonAppointmentRenderProps {
    appointment: SahAppointment;
    highlight?: boolean;
    showReducedVersion?: boolean;
}

export function MobileSalespersonAppointment({
    appointment,
    highlight,
    showReducedVersion,
}: SalespersonAppointmentRenderProps) {
    const formattedAddess = formatSAHAppointmentAddress(appointment, showReducedVersion);
    const openAddress = () =>
        window.open(
            `https://www.google.com/maps/dir/${formattedAddess
                .replaceAll(" ", "+")
                .replaceAll(",", "")}`
        );
    const openPhone = () => window.open(`tel:${appointment.primaryPhone}`);

    const displayCheckedIn = isNullOrUndefined(appointment.currentSalesperson?.checkInTime)
        ? "Not Checked In"
        : timeSpanStrToHHMM12HR(appointment.currentSalesperson!.checkInTime!);

    const displayCheckedOut = isNullOrUndefined(appointment.currentSalesperson?.checkOutTime)
        ? "Not Checked Out"
        : timeSpanStrToHHMM12HR(appointment.currentSalesperson!.checkOutTime!);

    const formattedName = `${appointment.firstName} ${appointment.lastName}`;
    const formattedDate = dateTimeStrToMdy(appointment.date);
    const formattedTimeSlot = `${timeSpanStrToHHMM12HR(
        appointment.marketTimeSlot.startTime
    )} - ${timeSpanStrToHHMM12HR(appointment.marketTimeSlot.endTime)}`;

    const showAdditionalDetails = !(showReducedVersion ?? false);

    return (
        <div className={clsx("mobile-appointment-row", { "highlight-appointment-row": highlight })}>
            <div className="flex-row">
                <div className="styled-label">
                    <Typography>Name:</Typography>
                    <Typography style={{ fontWeight: "bold" }}>{formattedName}</Typography>
                </div>
                {showAdditionalDetails && (
                    <div className="styled-label">
                        <Typography>Phone:</Typography>
                        <Typography
                            className={showReducedVersion ? undefined : "clickable-text"}
                            onClick={showReducedVersion ? undefined : openPhone}
                        >
                            {formatPhoneNumber(appointment.primaryPhone)}
                        </Typography>
                    </div>
                )}
            </div>
            <div className="flex-row">
                <div className="styled-label">
                    <Typography>Address:</Typography>
                    <Typography
                        className={showReducedVersion ? undefined : "clickable-text"}
                        onClick={showReducedVersion ? undefined : openAddress}
                    >
                        {formattedAddess}
                    </Typography>
                </div>
            </div>
            <div className="flex-row">
                <div className="styled-label">
                    <Typography>Time:</Typography>
                    <Typography style={{ paddingRight: ".25rem" }}>
                        {formattedDate} {formattedTimeSlot}
                    </Typography>
                </div>
            </div>
            {showAdditionalDetails && (
                <div
                    className="flex-row"
                    hidden={showReducedVersion}
                >
                    <div className="styled-label">
                        <Typography>Check In:</Typography>
                        <Typography>{displayCheckedIn}</Typography>
                    </div>
                    <div className="styled-label">
                        <Typography>Check Out:</Typography>
                        <Typography>{displayCheckedOut}</Typography>
                    </div>
                </div>
            )}
            {showAdditionalDetails && (
                <div className="flex-row">
                    <div className="styled-label">
                        <Typography>Status:</Typography>
                        <Typography>{appointment.appointmentStatusString}</Typography>
                    </div>
                </div>
            )}
            {!isNullOrUndefined(appointment.comments) && appointment.comments !== "" && (
                <div className="flex-row">
                    <div className="styled-label">
                        <Typography>Comments:</Typography>
                        <Typography>{appointment.comments}</Typography>
                    </div>
                </div>
            )}
        </div>
    );
}

export function DesktopSalespersonAppointment({ appointment }: SalespersonAppointmentRenderProps) {
    const [checkIntoAppointment] = useCheckIntoSahAppointmentMutation();
    const [waitingForCustomer] = useWaitingForCustomerSahAppointmentMutation();
    const [reportCustomerNotHome] = useReportCustomerNotHomeSahAppointmentMutation();

    const isCheckedOut = !isNullOrUndefined(appointment.currentSalesperson?.checkOutTime);
    const isCheckedIn = !isNullOrUndefined(appointment.currentSalesperson?.checkInTime);

    const [isOpen, setOpen] = useState<boolean>(!isCheckedOut);

    const formattedAddess = formatSAHAppointmentAddress(appointment, false);
    const cityName = appointment.primaryCity;
    const openAddress = () =>
        window.open(
            `https://www.google.com/maps/dir/${formattedAddess
                .replaceAll(" ", "+")
                .replaceAll(",", "")}`
        );
    const openPhone = () => window.open(`tel:${appointment.primaryPhone}`);

    const formattedName = `${appointment.firstName} ${appointment.lastName}`;
    const formattedTimeSlot = `${timeSpanStrToHHMM12HR(
        appointment.marketTimeSlot.startTime,
        true
    )} - ${timeSpanStrToHHMM12HR(appointment.marketTimeSlot.endTime, true)}`;

    function toggleOpen() {
        setOpen(!isOpen);
    }

    const { data, loading } = useGetJobSummaryDataQuery({
        variables: { jobId: appointment.jobId },
    });

    const customerIsNotHome =
        appointment.appointmentStatusId === CUSTOMER_NOT_HOME_STATUS_ID ||
        appointment.appointmentStatusId === WAITING_FOR_CUSTOMER_STATUS_ID;

    return (
        <>
            <div className="desktop-appointments-grid">
                <div
                    className="clickable primary-desktop-row-item "
                    onClick={toggleOpen}
                >
                    {formattedTimeSlot}
                </div>
                <div
                    className="clickable primary-desktop-row-item "
                    onClick={toggleOpen}
                >
                    {cityName}
                </div>
                <div
                    className="clickable primary-desktop-row-item "
                    onClick={toggleOpen}
                >
                    {formattedName}
                </div>
                <div
                    className="clickable"
                    onClick={toggleOpen}
                >
                    {isCheckedIn && (
                        <div>
                            In:{" "}
                            {timeSpanStrToHHMM12HR(appointment.currentSalesperson!.checkInTime!)}
                        </div>
                    )}
                </div>
                <div
                    className="clickable"
                    onClick={toggleOpen}
                >
                    {isCheckedOut && (
                        <div>
                            Out:{" "}
                            {timeSpanStrToHHMM12HR(appointment.currentSalesperson!.checkOutTime!)}
                        </div>
                    )}
                </div>
                <div
                    className="clickable"
                    onClick={toggleOpen}
                >
                    {salespersonDisplayStatus(appointment)}
                </div>
                <div
                    className="clickable primary-desktop-row-item"
                    onClick={toggleOpen}
                    style={{ textAlign: "right", whiteSpace: "nowrap" }}
                >
                    {appointment.appointmentNumber}
                </div>
                {isOpen && !loading && (
                    <>
                        <div style={{ gridRow: "3", gridColumn: "1" }}>
                            {isCheckedIn &&
                                !isCheckedOut &&
                                (appointment.appointmentStatusId === CHECKED_IN_STATUS_ID ? (
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            reportCustomerNotHome({
                                                variables: { sahAppointmentId: appointment.id },
                                            })
                                        }
                                    >
                                        No Show
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            checkIntoAppointment({
                                                variables: { sahAppointmentId: appointment.id },
                                            })
                                        }
                                    >
                                        Customer Arrived
                                    </Button>
                                ))}
                        </div>

                        <div style={{ gridRow: "2", gridColumn: "2" }}>
                            <Typography
                                onClick={openAddress}
                                className={"clickable-text"}
                            >
                                {formattedAddess}
                            </Typography>
                        </div>

                        <div style={{ gridRow: "2", gridColumn: "3" }}>
                            <Typography
                                onClick={openPhone}
                                className="clickable-text"
                            >
                                {formatPhoneNumber(appointment.primaryPhone)}
                            </Typography>
                        </div>

                        <div style={{ gridRow: "3", gridColumn: "3" }}>
                            <span className="flex-row flex-gap-xsm">
                                <p
                                    className="flat-font less-bold-text margin-none"
                                    style={{fontSize: "1rem"}}
                                >Room Count:</p>
                                <p
                                    className="flat-font margin-none"
                                    style={{fontSize: "1rem"}}
                                >{appointment.numRooms}</p>
                            </span>
                            
                            <span className="flex-row flex-gap-xsm">
                                <p
                                    className="flat-font less-bold-text margin-none"
                                    style={{fontSize: "1rem"}}
                                >Color{appointment.colorCategories!.length > 1 ? "s" : ""}:</p>
                                <p
                                    className="flat-font margin-none"
                                    style={{fontSize: "1rem"}}
                                >{appointment.colorCategories!.map(c => c.category).join(", ")}</p>
                            </span>

                            <span className="flex-row flex-gap-xsm">
                                <p
                                    className="flat-font less-bold-text margin-none"
                                    style={{fontSize: "1rem"}}
                                >{appointment.priorityOptions!.length > 1 ? "Priorities" : "Priority"}:</p>
                                <p
                                    className="flat-font margin-none"
                                    style={{fontSize: "1rem"}}
                                >{appointment.priorityOptions!.map(p => p.text).join(", ")}</p>
                            </span>

                            <span className="flex-row flex-gap-xsm">
                                <p
                                    className="flat-font less-bold-text margin-none"
                                    style={{fontSize: "1rem"}}
                                >Notes:</p>
                                <p
                                    className="flat-font margin-none"
                                    style={{fontSize: "1rem"}}
                                >{appointment.comments}</p>
                            </span>
                        </div>

                        <div
                            style={{
                                gridRow: "3",
                                gridColumn: "6 / span 2",
                                display: "grid",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {isCheckedIn ? (
                                customerIsNotHome ? (
                                    <Button
                                        hidden={appointment.appointmentStatusId === WAITING_FOR_CUSTOMER_STATUS_ID}
                                        variant="contained"
                                        onClick={() =>
                                            waitingForCustomer({
                                                variables: { sahAppointmentId: appointment.id },
                                            })
                                        }
                                    >
                                        Waiting For Customer
                                    </Button>
                                ) : (
                                    <a
                                        href={`https://app.wofadmin.com/drawing/${data?.jobSummaryData.configIds[0].id}`}
                                        className="link-to-button"
                                    >
                                        Measure
                                    </a>
                                )
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        checkIntoAppointment({
                                            variables: { sahAppointmentId: appointment.id },
                                        })
                                    }
                                >
                                    Check In
                                </Button>
                            )}
                        </div>
                    </>
                )}
                {isOpen && loading && (
                    <div style={{ gridRow: "2 / span 2", gridColumn: "1 / span 7" }}>
                        <Loading />
                    </div>
                )}
            </div>
            <HBar thin />
        </>
    );
}

function salespersonDisplayStatus(appointment: SahAppointment): string {
    switch (appointment.appointmentStatusId) {
        case UNASSIGNED_STATUS_ID:
        case ASSIGNED_STATUS_ID:
        case RELEASED_TO_SALESPERSON_STATUS_ID:
        case ACCEPTED_BY_SALESPERSON_STATUS_ID:
            return "Upcoming";
        case CHECKED_IN_STATUS_ID:
            return "Occuring";
        case CUSTOMER_NOT_HOME_STATUS_ID:
        case WAITING_FOR_CUSTOMER_STATUS_ID:
            return "No Show";
        case CHECKED_OUT_STATUS_ID:
            if (appointment.contractTotalPrice) {
                return `Sale: ${appointment.contractTotalPrice.toFixed(0)}`;
            } else if (appointment.quoteTotalPrice) {
                return `Quote: ${appointment.quoteTotalPrice.toFixed(0)}`;
            } else return "No Quote";
        case WRITE_UP_STATUS_ID:
            return "Write Up";
        case RESCHEDULED_STATUS_ID:
            return "Rescheduled";
        default:
            return "";
    }
}

function formatSAHAppointmentAddress(appointment: SahAppointment, showReducedVersion?: boolean) {
    if (showReducedVersion) return `${appointment.primaryCity}, MI, ${appointment.primaryZipCode}`;
    else
        return `${appointment.primaryAddress}, ${appointment.primaryCity}, MI, ${appointment.primaryZipCode}`;
}
