import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { FLOOR_PREP_ID, FURNITURE_ID, R_AND_R_ID, SHIM_ID } from "Globals/globalConstants";
import { RoomForWhoDoesService } from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/BreakdownTableUtils";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import EditableWhoDoesServiceEditorForRoom from "./EditableWhoDoesServiceEditorForRoom";
import ServiceForRoomPriceText from "./ServiceForRoomPriceText";

export interface WhoDoesServiceEditorForRoomProps {
    originalRoom: RoomForWhoDoesService | undefined;  // will be undefined when service is new
    editableRoom: RoomForWhoDoesService;
    updateWhoDoes: (newWhoDoes: boolean) => void;  // applies to all services
    updateAmount: (newAmt: number) => void;  // applies only to editable services
    removeRoomFromService: () => void;  // applies only to editable services
    restoreService: () => void;  // applies only to editable services
    disabled: boolean;
}

export default function WhoDoesServiceEditorForRoom({
    originalRoom,
    editableRoom,
    updateWhoDoes,
    updateAmount,
    removeRoomFromService,
    restoreService,
    disabled
}: WhoDoesServiceEditorForRoomProps) {
    const formattedPrice = (
        <ServiceForRoomPriceText
            originalService={originalRoom?.service ?? null}
            editableService={editableRoom.service}
        />
    );

    const isEditable = [R_AND_R_ID, FURNITURE_ID, FLOOR_PREP_ID].includes(editableRoom.service.serviceTypeId) ||
        editableRoom.service.jobServiceId === SHIM_ID;

    if (isEditable) {
        return (
            <EditableWhoDoesServiceEditorForRoom
                formattedPrice={formattedPrice}
                editableRoom={editableRoom}
                updateWhoDoes={updateWhoDoes}
                removeRoomFromService={removeRoomFromService}
                restoreService={restoreService}
                disabled={disabled}
                readOnly={editableRoom.service.serviceTypeId === FURNITURE_ID}
                setQuantity={updateAmount}
            />
        );
    } else {
        return (
            <GeneralWhoDoesServiceEditorForRoom
                formattedPrice={formattedPrice}
                editableRoom={editableRoom}
                updateWhoDoes={updateWhoDoes}
                disabled={disabled}       
            />
        )
    }
}

interface GeneralSubEditor extends Omit<WhoDoesServiceEditorForRoomProps, "originalRoom" | "updateAmount" | "removeRoomFromService" | "restoreService"> {
    formattedPrice: JSX.Element;
}

/**
 * Used for all job service types except for R&R and Furniture
 */
function GeneralWhoDoesServiceEditorForRoom({
    editableRoom,
    updateWhoDoes: updateRoom,
    disabled,
    formattedPrice
}: GeneralSubEditor) {
    return (
        <tr className="flat-font">
            <td />
            <td align="center">{getNameOfArea(editableRoom.labels)}</td>
            <td align="left">
                <ToggleButtonGroup
                    size="small"
                    exclusive
                    value={editableRoom.service.customerDoesService}
                    onChange={(_, newValue) => updateRoom(newValue)}
                >
                    <ToggleButton value={true} disabled={disabled}>Cust</ToggleButton>
                    <ToggleButton value={false} disabled={disabled}>WOF</ToggleButton>
                </ToggleButtonGroup>
            </td>

            <td
                style={{ minWidth: "5rem" }}
                align="right"
            >
                {formattedPrice}
            </td>
        </tr>
    )
}