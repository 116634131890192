import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from "@material-ui/core";
import FlatButton from "FlatComponents/Button/FlatButton";
import { useCancelContractMutation, namedOperations } from "generated/graphql";
import { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { selectJobContractId, selectSAHNumber, selectWithinCancellationWindow } from "Redux/jobTrackerReducer";

export default function JobCancellationButton() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const contractId = useAppSelector(selectJobContractId)
    const withinCancellationWindow = useAppSelector(selectWithinCancellationWindow);

    const [cancelContract] = useCancelContractMutation({
        refetchQueries: [namedOperations.Query.GetJobContract]
    });

    function onCancelContract() {
        if (contractId) {
            cancelContract({ variables: { contractId: contractId } }).then((response) => {
                if (response.data?.cancelContract) {
                    setDialogOpen(false);
                } else {
                    alert("Failed to cancel the contract");
                }
            });
        } else {
            alert("Could not cancel contract")
        }
    }

    return (
        // TODO: if this is after then 3 day cancellation period, it will require upper management's authorization
        <>
            <FlatButton
                onClick={() => setDialogOpen(true)}
                variant="contained"
                className="fit-content cancel-button"
            >{withinCancellationWindow ? "3 Day Cancellation" : "Cancel Contract"}</FlatButton>

            {dialogOpen && (
                <JobCancellationDialog onNo={() => setDialogOpen(false)} onYes={onCancelContract} />
            )}
        </>
    )
}
interface JobCancellationDialogProps {
    onYes: () => void,
    onNo: () => void,
    rawSahNumber?: string
}

export function JobCancellationDialog({ onYes, onNo, rawSahNumber }: JobCancellationDialogProps) {
    const selectedSahNumber = useAppSelector(selectSAHNumber);
    const appendedNumber = rawSahNumber !== undefined && rawSahNumber.charAt(0) !== 'S' ? `SAH-${rawSahNumber}` : rawSahNumber
    const sahNumber = appendedNumber ?? selectedSahNumber
    const [confirmationSAH, setConfirmationSAH] = useState("SAH-");

    // TODO: if the job is NOT within the 3 day cancellation period, get authorization from upper management instead of enter SAH num

    function onYesWrapper() {
        if (sahNumber) {
            if (confirmationSAH.toLowerCase() === sahNumber.toLowerCase()) {
                onYes();
            } else {
                alert("SAH number is incorrect");
            }
        } else {
            alert('Could not cancel contract; please try again in a few seconds');
        }
    }


    return (
        <Dialog open={true}>
            <DialogContent>
                <Typography variant="h6">Are you sure you want to cancel this contract?</Typography>
                <Typography>Enter the SAH number to cancel (<span style={{ color: "red" }}>{sahNumber?.split("-")[1]}</span>)</Typography>
                <TextField value={confirmationSAH} onChange={(e) => setConfirmationSAH(e.target.value)} />
            </DialogContent>

            <DialogActions>
                <Button className="cancel-button" onClick={onNo}>No</Button>
                <Button className="submit-button" onClick={onYesWrapper}>Yes</Button>
            </DialogActions>
        </Dialog>
    )
}