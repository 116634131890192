// Consts for all product_type entries
export const WOOD_PRODUCT_ID = 1;
export const SPC_PRODUCT_ID = 2;
export const CARPET_PRODUCT_ID = 4;
export const LAMINATE_PRODUCT_ID = 5;
export const VINYL_PRODUCT_ID = 6;

// Consts for all service_type entries
export const RIP_UP_AND_HAUL_ID = 1;
export const STEPS_ID = 2;
export const INSTALLATION_ID = 3;
export const FURNITURE_ID = 5;
export const R_AND_R_ID = 6;
export const NEW_WALL_FINISH_ID = 7;
export const TRANSITION_ID = 34;
export const INSTALLATION_UPCHARGE_ID = 35;
export const FLOOR_PREP_ID = 36;

// Consts for job_service entries
export const SHOE_MOLDING_ID = 12;
export const BASE_MOLDING_ID = 14;
export const EMBOSSING_LEVELER_ID = 66;
export const SKIM_COAT_ID = 67;
export const SHIM_ID = 68;
export const PLYWOOD_ID = 69;
export const FURNITURE_REGULAR_ID = 13;
export const FURNITURE_LARGE_ID = 48;
export const FURNITURE_NONE_ID = 71;

// Consts for the new wall finish service material categories
export const PRIMED_SHOE_MOLDING_MATERIAL_CATEGORY_ID = 2;
export const UNFINISHED_SHOE_MOLDING_MATERIAL_CATEGORY_ID = 3;
export const UNFINISHED_BASE_MOLDING_MATERIAL_CATEGORY_ID = 11;
export const PRIMED_BASE_MOLDING_MATERIAL_CATEGORY_ID = 12;
export const COVE_BASE_MOLDING_MATERIAL_CATEGORY_ID = 13;

// Consts for the build up service material categories
export const QUARTER_INCH_PLY_ID = 19;
export const THREE_EIGHTHS_INCH_PLY_ID = 20;
export const HALF_INCH_PLY_ID = 21;
export const FIVE_EIGHTHS_INCH_PLY_ID = 22;
export const THREE_QUARTERS_INCH_PLY_ID = 23;

// Consts for accepted payment method
export const CASH_PAYMENT_ID = 1;
export const CHECK_PAYMENT_ID = 2;
export const C_C_PAYMENT_ID = 3;
export const FINANCING_PAYMENT_ID = 4;

// Consts for service_order_type
export const STORE_SERVICE_ORDER_ID = 1;
export const MANUFACTURER_SERVICE_ORDER_ID = 2;
export const CONTRACTOR_SERVICE_ORDER_ID = 3;
export const CHARGEABLE_REPAIR_SERVICE_ORDER_ID = 4;

// Consts for all discount options
export const OVERRIDE_DISCOUNT_ID = 1;
export const WE_PAY_SALES_TAX_DISCOUNT_ID = 5;

// Consts for financing
export const EQUAL_PAYMENT_ID = 2;
export const FINANCING_DOWN_PAYMENT_AMOUNT = 200;

// consts for worker IDs
export const UNASSIGNED_CONTRACTOR_ID = 1;
export const GENERAL_COMMAND_CENTER_CHAT_PARTY_ID = 1;