import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Tooltip } from "@material-ui/core";
import clsx from "clsx";
import StatusPill from "Components/StatusPill";
import { formatAppointmentDateStringExact, formatShortDateRangeNoYear } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { breakdownPath } from "Globals/PathStrings";
import { useAppHistory } from "Globals/routingHooks";
import { useMemo } from "react";
import NumberFormat from "react-number-format";
import CustomerWillCallButton from "./CustomerWillCallButton";

export interface InstallationSubCardProps {
    contractId: number,
    productType: string,
    materialNumber?: number,
    isInstalled: boolean,
    styleAmounts: string[],
    financedAmount?: number,
    remainingCod?: number,
    cwcNumber?: number,
    installerName?: string,
    installationDates?: string[],
    deemphasize: boolean // if the installation is not pertinent to the column the card is placed ine
}

export default function InstallationSubCard({
    contractId,
    productType,
    materialNumber,
    isInstalled,
    styleAmounts,
    financedAmount,
    remainingCod,
    cwcNumber,
    installerName,
    installationDates,
    deemphasize
}: InstallationSubCardProps) {
    const displayNumber = materialNumber !== undefined ? materialNumber.toString() : "";
    const history = useAppHistory();
    const subCardRows: ReactJSXElement[][] = useMemo(() => {
        const rows: ReactJSXElement[][] = [];
        const numStyles = styleAmounts.length;
        const hasFinancing = isNotNullOrUndefined(financedAmount);
        const numDays = (installationDates ?? []).length;
        const showCwc = (numDays === 0);
        const shortenedDateStr = !showCwc ? formatShortDateRangeNoYear(installationDates!) : '';
        const hoverDateStr = !showCwc 
            ? formatAppointmentDateStringExact(installationDates!, true) : '';
        let divIdx = 0; // for unique key generation

        rows.push([
            <div key={`separator-${contractId}`} className="installation-sub-card-separator" />
        ])

        rows.push([
            (<div className="flex-row" key={`${contractId}-${divIdx++}`}>
                <p
                    className="margin-none flat-font bold-text"
                    style={{color: deemphasize ? "#8e8e8e" : (isInstalled ? "black" : "red")}}
                >{productType.toUpperCase()} {displayNumber}</p>
            </div>),
            (<div className="flex-row" key={`${contractId}-${divIdx++}`}>
                <p className="margin-none flat-font">{styleAmounts[0]}</p>
            </div>),
            (<div className="flex-row justify-content-center" style={{paddingTop: "5px"}} key={`${contractId}-${divIdx++}`}>
                {isInstalled ? 
                    <StatusPill label="Installed" backgroundColor="blue" /> : 
                    <StatusPill label="In Stock" backgroundColor="green"/>
                }
            </div>),
            (<div className="flex-row justify-content-flex-end" key={`${contractId}-${divIdx++}`}>
                {hasFinancing ? (
                    <NumberFormat
                        className="flat-font bold-text"
                        style={{color: "var(--flat-purple)"}}
                        displayType="text"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        value={financedAmount!.toFixed(2)}
                        prefix="$"
                    />
                ) : (
                    <>
                        {isNotNullOrUndefined(remainingCod) ? (
                            <NumberFormat
                                className="flat-font bold-text"
                                displayType="text"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                value={remainingCod!.toFixed(2)}
                                prefix="$"
                            />
                        ) : (
                            <p className="flat-font margin-none">{`$\u00A0-`}</p>
                        )}
                    </>
                )}
            </div>)
        ]);

        if (hasFinancing || numStyles > 1) {
            rows.push([
                <div key={`${contractId}-${divIdx++}`}/>,
                (<div className="flex-row" key={`${contractId}-${divIdx++}`}>
                    {(numStyles > 1) && <p className="margin-none flat-font">{styleAmounts[1]}</p>}
                </div>),
                (<div className="flex-row align-items-center" key={`${contractId}-${divIdx++}`}>
                    {(numStyles > 1) && (
                        isInstalled ? 
                            (<StatusPill label="Installed" backgroundColor="blue" />) :
                            (<StatusPill label="In Stock" backgroundColor="green" />)
                        )
                    }
                </div>),
                (<div className="flex-row justify-content-flex-end" key={`${contractId}-${divIdx++}`}>
                    {(hasFinancing) && (<>
                        {isNullOrUndefined(remainingCod) ? (
                            <p className="flat-font margin-none">{`$\u00A0-`}</p>
                        ) : (
                            <NumberFormat
                                className="flat-font"
                                displayType="text"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                value={remainingCod!.toFixed(2)}
                                prefix="$"
                            />
                        )}
                    </>)}
                </div>)
            ]);
        }

        const remainingRows = styleAmounts.slice(2);

        remainingRows.forEach(styleAmt => {
            rows.push([
                <div key={`${contractId}-${divIdx++}`}/>,
                <div key={`${contractId}-${divIdx++}`}>
                    <p className="margin-none flat-font">{styleAmt}</p>
                </div>,
                (<div className="align-items-center" key={`${contractId}-${divIdx++}`}>
                    {isInstalled ? 
                        (<StatusPill label="Installed" backgroundColor="blue" />) :
                        (<StatusPill label="In Stock" backgroundColor="green"/>)
                    }
                </div>),
                <div key={`${contractId}-${divIdx++}`}/>
            ]);
        });

        rows.push([
            <div className="sub-card-final-row" key={`${contractId}-${divIdx++}`}>
                <Tooltip
                    title={installerName ?? ''}
                    disableHoverListener={(installerName ?? '').length <= 20}
                    key={`${contractId}-${divIdx++}`}
                >
                    <div className="installer-name-colspan flat-font">
                        {installerName ?? ''}
                    </div>
                </Tooltip>
                <div className="flex-row justify-content-flex-end" key={`${contractId}-${divIdx++}`}>
                    {(showCwc && isNotNullOrUndefined(cwcNumber)) ? (
                        <div className="flex-row" style={{ alignItems: "center" }}>
                            {cwcNumber !== undefined &&
                                <CustomerWillCallButton
                                    useSmallVariant
                                    cwcPeriod={cwcNumber}
                                    onClick={() => history.push(`${breakdownPath}/${contractId}`)}
                                />
                            }
                        </div>
                    ) : (
                        <Tooltip title={hoverDateStr}>
                            <div className="installation-date-colspan flat-font fill-width">
                                <p className="margin-none flat-font">{shortenedDateStr}</p>
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
        ]);
        
        return rows;
    }, [remainingCod, displayNumber, financedAmount, installerName, isInstalled, productType, styleAmounts, contractId,  cwcNumber, history, installationDates, deemphasize]);

    return (
        <div className={clsx("installation-sub-card", {"installation-sub-card-deemphasize": deemphasize})}>
            {subCardRows}
        </div>
    )
}