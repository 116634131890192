import Loading from "Components/Loading";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatCancelButton from "FlatComponents/Button/FlatCancelButton";
import { FlatCircleCheckIcon } from "FlatComponents/Button/FlatCircleCheckButton";
import FlatCheckbox from "FlatComponents/Inputs/FlatCheckbox";
import { CustomerCleanupData, GetCustomerCleanupListDocument, GetCustomerCleanupListQuery, useEraseAllCustomerDataMutation, useGetCustomerCleanupListQuery } from "generated/graphql";
import { memo, useState } from "react";

// NOTE: If a larger page size is desired, then the [UsePaging] annotation for the query on the back end
// needs to be updated to allow more results per page
const PAGE_SIZE = 500;

const makeWarningStr = (numSelected: number) => 
    `WARNING: You are about to permanently delete all information about the ${numSelected} customer(s), including all contracts and job details. Are you sure you wish to do this?`


export default function TestDataCleanupPage() {
    const [allDataLoaded, setAllDataLoaded] = useState(false);
    const [selectedCustomerIds, setSelectedCustomerIds] = useState<number[]>([]);

    const {data, loading, fetchMore} = useGetCustomerCleanupListQuery({
        variables: { first: PAGE_SIZE },
        /**
         * This callback basically allows the page to be loaded in chunks without requiring the user to scroll to the bottom
         * or click a button to load the next 'page' of customers.
         * When each page of data finishes (and onCompleted is run), it automatically fetches the next page.
         * Here is why it is done this way:
         *  1. There is far too much data to load it all at once (it is very slow to render all at once)
         *  2. My initial thought was to just trigger fetching the next page when the user scrolls to the bottom,
         *     but because of dependencies changing, the window ended up having a new event listener attached each time
         *     a dependency changed, and this caused pages to be reloaded multiple times. 
         */
        onCompleted: (data) => {
            const newEdges = data.customerCleanupList!.edges;
            const newCursor = newEdges![newEdges!.length - 1].cursor;
            if (data.customerCleanupList?.pageInfo.hasNextPage) {
                fetchMore({variables: {first: PAGE_SIZE, after: newCursor}});
            } else {
                setAllDataLoaded(true);
            }
        }
    });
    const cList = data?.customerCleanupList?.edges ?? [];

    function toggleSelection(customerId: number, newIsSelected: boolean) {
        if (newIsSelected) {
            setSelectedCustomerIds([...selectedCustomerIds, customerId]);
        } else {
            setSelectedCustomerIds(selectedCustomerIds.filter(cId => cId !== customerId));
        }
    }

    const [erasing, setErasing] = useState(false);
    const [eraseCustomers] = useEraseAllCustomerDataMutation({
        variables: {customerIds: selectedCustomerIds},
        onCompleted: () => { setErasing(false); setSelectedCustomerIds([])},
        onError: () => {
            alert("Failed to delete selected customers' information")
            setErasing(false);
        }
    });

    function onEraseCustomers() {
        if (window.confirm(makeWarningStr(selectedCustomerIds.length))) {
            setErasing(true);
            eraseCustomers({
                variables: { customerIds: selectedCustomerIds },
                update: cache => {
                    const cachedCustomers: GetCustomerCleanupListQuery = cache.readQuery({query: GetCustomerCleanupListDocument})!;
                    const updated: GetCustomerCleanupListQuery = {
                        ...cachedCustomers,
                        customerCleanupList: {
                            ...cachedCustomers.customerCleanupList!,
                            edges: cachedCustomers.customerCleanupList!.edges!.filter(e => !selectedCustomerIds.includes(e.node.id)),
                            nodes: cachedCustomers.customerCleanupList!.nodes!.filter(c => !selectedCustomerIds.includes(c.id))
                        }
                    };
                    cache.writeQuery({query: GetCustomerCleanupListDocument, data: updated});
                }
            });
        }
    }

    return (<>
        {loading ? (
            <Loading altText="Gathering Customer List..."/>
        ) : (
            <div className="padding-md">
                <span className="flex-row flex-gap-xsm margin-bottom-xsm align-items-center">
                    {allDataLoaded ? <FlatCircleCheckIcon color="green" /> : <FlatCancelButton color="red" />}
                    
                    <p className="flat-font padding-none margin-none">
                        {allDataLoaded ? "All Data Loaded" : "Loading More Data" }
                    </p>

                    <FlatButton
                        style={{visibility: allDataLoaded ? "visible" : "hidden"}}
                        disabled={selectedCustomerIds.length === 0}
                        onClick={onEraseCustomers}
                        color="primary"
                    >
                        Erase {selectedCustomerIds.length} Customers
                    </FlatButton>
                </span>

                <div className="grid-50-50">
                    {cList.map(c => (
                        <ContactCleanupRow
                            customer={c.node}
                            key={c.node.id}
                            // disable deleting until everything loads because cache stuff gets weird otherwise
                            preventSelection={!allDataLoaded || erasing}
                            isSelected={selectedCustomerIds.includes(c.node.id)}
                            setIsSelected={(isSelected) => toggleSelection(c.node.id, isSelected)}
                        />
                    ))}
                </div>
            </div>
        )}
    </>)
}

interface ContactCleanupRowProps {
    customer: CustomerCleanupData;
    preventSelection: boolean;
    isSelected: boolean;
    setIsSelected: (selected: boolean) => void;
}

const ContactCleanupRow = memo(function ContactCleanupRow({customer, preventSelection, isSelected, setIsSelected}: ContactCleanupRowProps) {
    const pContactName = customer.contactNames[0];
    const secondaryContactNames = customer.contactNames.slice(1);
    const emails = customer.contactEmails;

    return (
        <div className="flex-column padding-xsm" style={{border: "1px solid var(--flat-gray-4)"}}>
            <span className="flex-row flex-gap-xsm">
                <h2>{pContactName}</h2>
                {!preventSelection && (<>
                    <FlatCheckbox onClick={() => setIsSelected(!isSelected)}/>
                </>)}
            </span>
            <span className="flex-row flex-gap-xsm">
                <p className="margin-none">Secondary contacts:</p>
                {secondaryContactNames.join(', ')}
            </span>
            <span className="flex-row flex-gap-xsm">
                <p className="margin-none">Emails:</p>
                {emails.join(', ')}
            </span>
        </div>
    )
})
