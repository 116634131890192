import Loading from "Components/Loading";
import { useNumericIdParam } from "Globals/Hooks";
import { useAppSelector } from "Redux/hooks";
import { selectDoesContractExist, selectJobContractId } from "Redux/jobTrackerReducer";
import { JobTrackerHeader } from "./HeaderComponents/JobTrackerHeader";
import JobTrackerNavbar from "./JobTrackerNavbar";

export default function JobTrackerPage({ children, title }: React.PropsWithChildren<{ decorationElement?: React.ReactNode, title: string }>) {
    const contractExists = useAppSelector(selectDoesContractExist)
    const reducerContractId = useAppSelector(selectJobContractId)
    const { id: jobContractIdParam } = useNumericIdParam();

    const contentReady = (jobContractIdParam ?? 0) === reducerContractId;

    return (
        <div id="job-tracker-page">
            <JobTrackerNavbar title={title} />

            <div id="job-tracker-content">
                <JobTrackerHeader />

                {!contentReady 
                    ? <Loading /> 
                    : contractExists 
                        ? <> {children} </> 
                        : <div>
                            <p className="margin-none flat-font">Contract does not exist</p>
                        </div>
                }
            </div>
        </div>
    )

}