import { Input } from "@material-ui/core";
import GrowForm, { GrowFormElement } from "Components/GrowForm/GrowForm";
import { useMemo, useState } from "react";

const growFormKeys = ['a', 'b']

export default function DummyLoc2() {

    const [a, setA] = useState<string>();
    const [b, setB] = useState<string>();

    const element1 = useMemo(
        () => (
            <GrowFormElement valueToWatch={a} id='a'>
                <Input value={a ?? ''} onChange={(e) => setA(e.target.value)}></Input>
            </GrowFormElement>
        ),
        [a]);

    const element2 = useMemo(
        () => (
            <GrowFormElement id='b'>
                <Input value={b ?? ''} onChange={(e) => setB(e.target.value)}></Input>
            </GrowFormElement>
        ),
        [b]);

    return <GrowForm filter={growFormKeys}>
        {element1}
        {element2}
    </GrowForm>
}