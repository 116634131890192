import { Select, MenuItem } from "@material-ui/core"
import { useGetProductTypeOptionsQuery } from "generated/graphql"
import { CARPET_PRODUCT_ID } from "Globals/globalConstants"
import { useAppSelector, useAppDispatch } from "Redux/hooks"
import { selectCostProductTypeId, clearAllCosts, setHardCostsProductTypeId } from "Redux/productCostsReducer"
import { promptUserIfChanges } from "../ProductCostsPage"

export default function HardCostProductTypeSelect() {
    const typeId = useAppSelector(selectCostProductTypeId)
    const dispatch = useAppDispatch()

    const { data: productOptionsData } = useGetProductTypeOptionsQuery()
    const typeOps = productOptionsData?.options.filter(t => t.id !== CARPET_PRODUCT_ID) ?? []
    
    function changeType(newId: number) {
        if (promptUserIfChanges()) {
            dispatch(clearAllCosts())
            dispatch(setHardCostsProductTypeId(newId))
        }
    }

    return (
        <div className="flex-column w-8r margin-side-xxsm">
            <label htmlFor="prod-type-select" className="margin-side-xsm">Product Type:</label>
            <Select name="prod-type-select" value={typeId} onChange={(e) => changeType(e.target.value as number)} className="w-8r">
                {typeOps.length === 0 && <MenuItem value={1}>Loading...</MenuItem>}
                {typeOps.map(op => <MenuItem key={`product-type-op-${op.id}`} value={op.id}>{op.type}</MenuItem>)}
            </Select>
        </div>
    )
}
