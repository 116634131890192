import { Box, Step, StepLabel, Stepper } from "@material-ui/core";

const steps = ["Schedule a Date & Time", "Product Types", "Contact Info"];

export default function SchedulingStepper({ activeStep }: { activeStep: number}) {
    return (
        <Box>
            <Stepper activeStep={activeStep}>
                {steps.map((stepLabel, index) => {
                    return (
                        <Step key={stepLabel}>
                            <StepLabel>{stepLabel}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}