 import { Calendar, Day, DayRange, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import CalendarIcon from '@material-ui/icons/CalendarToday';
import CancelIcon from '@material-ui/icons/Cancel';
import MarketIcon from '@material-ui/icons/Store';
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { Market, namedOperations, ScheduledPromotion, useCancelScheduledPromotionMutation, useCheckForPromotionConflictsLazyQuery, useGetAllMarketsQuery, useGetAllScheduledPromotionsQuery, useUpdateScheduledPromotionMutation, useUpdateWhichMarketsForScheduledPromotionMutation } from "generated/graphql";
import { dateTimeStrToDay, dateTimeStrToIso, dateTimeStrToMdy, dayBeforeOrEq, dayToIso, dayToMdy, handleCalendarDayRangeSelectionChange } from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { useEffect, useMemo, useState } from "react";
import { typeForBasePromoToStr } from "./BasePromoTable";
import { MarketSelectionSection } from "./PromoSchedulingDialog";

export default function ScheduledPromotionTable() {
    const {data: marketData} = useGetAllMarketsQuery({onError: () => alert("Could not load market data")});
    const allMarkets = useMemo(() => marketData?.allMarkets ?? [], [marketData]);

    const {data: scheduledPromoData} = useGetAllScheduledPromotionsQuery({
        variables: {excludeExpired: true, excludeCanceled: true},
        onError: () => alert("Failed to load scheduled promotions")
    });
    const scheduledPromotions = scheduledPromoData?.allScheduledPromotions ?? [];

    return (
        <div className="flex-column align-items-center" >
            <Typography className="align-self-flex-start" variant="h5">Scheduled Promotions</Typography>

            {scheduledPromotions.length === 0 ? (
                <p>No promotions currently scheduled</p>
            ) : (
                <table>
                    <thead>
                        <tr style={{ backgroundColor: "lightgrey", height: "3rem" }}>
                            <td>Actions</td>
                            <td align="left" className="whitespace-no-wrap padding-right-sm">Name</td>
                            <td align="left" className="whitespace-no-wrap padding-right-sm">Details</td>
                            <td align="left" className="whitespace-no-wrap padding-right-sm">Start Date</td>
                            <td align="left" className="whitespace-no-wrap padding-right-sm">End Date</td>
                            <td align="left" className="whitespace-no-wrap padding-right-sm">Markets</td>
                        </tr>
                    </thead>

                    <tbody>
                        {scheduledPromotions.map(promo => <ScheduledPromotionTableRow key={`sp-${promo.id}`} scheduledPromo={promo} allMarkets={allMarkets} />)}
                    </tbody>
                </table>
            )}

        </div>
    )
}

interface ScheduledPromotionTableRowProps {
    scheduledPromo: ScheduledPromotion;
    allMarkets: Market[];
}

function ScheduledPromotionTableRow({scheduledPromo, allMarkets}: ScheduledPromotionTableRowProps) {
    const promoDetailsStr = typeForBasePromoToStr(scheduledPromo.basePromotion.promotionTypes);
    const isForAllMarkets = scheduledPromo.marketIds.length === allMarkets.length;
    const marketNames = allMarkets.filter(mkt => scheduledPromo.marketIds.includes(mkt.id)).map(mkt => mkt.name);
    const marketStr = isForAllMarkets ? "All" : marketNames.join(', ');
    
    const [cancelPromotion, {loading: canceling}] = useCancelScheduledPromotionMutation({
        onError: () => alert("Could not cancel promotion"),
        onCompleted: () => alert("Promotion successfully canceled"),
        variables: {id: scheduledPromo.id},
        refetchQueries: [namedOperations.Query.GetAllScheduledPromotions]
    });
    
    function onCancel() {
        if (window.confirm("Are you sure you wish to cancel this promotion?")) {
            cancelPromotion();
        }
    }
    
    const [dateEditorOpen, setDateEditorOpen] = useState(false);
    const promoHasStarted = dayBeforeOrEq(dateTimeStrToDay(scheduledPromo.startDate), utils('en').getToday());
    const [marketEditorOpen, setMarketEditorOpen] = useState(false);

    return (<>
        <tr>
            <td align="left" className="padding-right-xmd">
                <div className="flex-row">
                    <Tooltip title="Cancel Promotion">
                        <IconButton
                            className="padding-vertical-none"
                            disabled={canceling}
                            onClick={onCancel}
                        ><CancelIcon /></IconButton>
                    </Tooltip>
                    
                    <Tooltip title="Edit Dates">
                        <IconButton
                            className="padding-vertical-none"
                            disabled={canceling}
                            onClick={() => setDateEditorOpen(true)}
                        ><CalendarIcon /></IconButton>
                    </Tooltip>
                    
                    <Tooltip title="Edit Markets">
                        <IconButton
                            className="padding-vertical-none"
                            disabled={canceling}
                            onClick={() => setMarketEditorOpen(true)}
                        ><MarketIcon /></IconButton>
                    </Tooltip>
                </div>
            </td>
            <td align="left" className="padding-right-xmd"><Typography>{scheduledPromo.basePromotion.name}</Typography></td>
            <td align="left" className="padding-right-xmd"><Typography>{promoDetailsStr}</Typography></td>
            <td align="left" className="padding-right-xmd"><Typography>{dateTimeStrToMdy(scheduledPromo.startDate)}</Typography></td>
            <td align="left" className="padding-right-xmd"><Typography>{dateTimeStrToMdy(scheduledPromo.endDate)}</Typography></td>
            <td align="left" className="padding-right-xmd"><Typography>{marketStr}</Typography></td>
        </tr>

        {(dateEditorOpen) && <>
            {promoHasStarted ? (
                <EndDateEditorDialog
                    scheduledPromoId={scheduledPromo.id}
                    startDate={dateTimeStrToDay(scheduledPromo.startDate)}
                    endDate={ dateTimeStrToDay(scheduledPromo.endDate)}
                    onClose={() => setDateEditorOpen(false)}
            />) : (
                <StartAndEndDateEditorDialog
                    scheduledPromoId={scheduledPromo.id}
                    startDate={dateTimeStrToDay(scheduledPromo.startDate)}
                    endDate={ dateTimeStrToDay(scheduledPromo.endDate)}
                    onClose={() => setDateEditorOpen(false)}
                />
            )}
        </>}

        {marketEditorOpen && (
            <MarketSelectionEditorDialog
                scheduledPromoId={scheduledPromo.id}
                selectedMarketIds={scheduledPromo.marketIds}
                startDateIsoStr={dateTimeStrToIso(scheduledPromo.startDate)} endDateIsoStr={dateTimeStrToIso(scheduledPromo.endDate)}
                onClose={() => setMarketEditorOpen(false)}
            />
        )}
    </>) 
}

interface PromoDateEditorDialogProps {
    scheduledPromoId: number;
    startDate: Day;
    endDate: Day;
    onClose: () => void;
}

export function StartAndEndDateEditorDialog({scheduledPromoId, startDate: originalStartDate, endDate: originalEndDate, onClose}: PromoDateEditorDialogProps) {
    const [range, setRange] = useState<DayRange>({from: originalStartDate, to: originalEndDate});
    const startDateStr = isNullOrUndefined(range.from) ? "" : dayToMdy(range.from!);
    const endDateStr = isNullOrUndefined(range.to) ? "" : dayToMdy(range.to!);

    const [update] = useUpdateScheduledPromotionMutation({
        onError: () => alert("Failed to update promotion dates"),
        onCompleted: () => {
            alert("Promotion dates successfully updated");
            onClose();
        },
        refetchQueries: [namedOperations.Query.GetAllScheduledPromotions]
    });

    function onSubmit() {
        if (isNullOrUndefined(range.from) || isNullOrUndefined(range.to)) {
            alert('Select a date range')
        } else {
            update({
                variables: {
                    scheduledPromotionId: scheduledPromoId,
                    startDate: dayToIso(range.from!),
                    endDate: dayToIso(range.to!)
                }
            });
        }
    }

    return (
        <Dialog open={true}>
            <DialogTitle>Update Promotion Dates</DialogTitle>

            <DialogContent>
                <div className="flex-column">
                    <Calendar
                        value={range}
                        onChange={newRange => setRange(handleCalendarDayRangeSelectionChange(range, newRange))}
                        minimumDate={utils('en').getToday()}
                        colorPrimary="var(--wof-red)"
                        colorPrimaryLight="#f79cab"
                        shouldHighlightWeekends
                    />

                    <div className="flex-row">
                        <TextField value={startDateStr} label="Start Date" disabled/>
                        <TextField value={endDateStr} label="End Date" disabled/>
                    </div>
                </div>
            </DialogContent>

            <DialogActions>
                <SpacedButton className="cancel-button" onClick={onClose}>Cancel</SpacedButton>
                <SpacedButton
                    variant="contained"
                    color="secondary"
                    onClick={onSubmit}
                    disabled={isNullOrUndefined(range.from) || isNullOrUndefined(range.to)}
                >Submit</SpacedButton>
            </DialogActions>
        </Dialog>
    )    
}

export function EndDateEditorDialog({scheduledPromoId, startDate, endDate: originalEndDate, onClose}: PromoDateEditorDialogProps) {
    const [endDate, setEndDate] = useState<DayValue>(originalEndDate);
    const startDateStr = dayToMdy(startDate);
    const endDateStr = isNullOrUndefined(endDate) ? "" : dayToMdy(endDate!);

    const [update] = useUpdateScheduledPromotionMutation({
        onError: () => alert("Failed to update promotion end date"),
        onCompleted: () => {
            alert("Promotion end date successfully updated");
            onClose();
        },
        refetchQueries: [namedOperations.Query.GetAllScheduledPromotions]
    });

    function onSubmit() {
        if (isNullOrUndefined(endDate)) {
            alert('Select a date')
        } else {
            update({
                variables: {scheduledPromotionId: scheduledPromoId, endDate: dayToIso(endDate!)}
            });
        }
    }

    return (
        <Dialog open={true}>
            <DialogTitle>Update Promotion End Date</DialogTitle>

            <DialogContent>
                <div className="flex-column">
                    <Calendar
                        value={endDate}
                        onChange={newDate => setEndDate(newDate)}
                        minimumDate={utils('en').getToday()}
                        colorPrimary="var(--wof-red)"
                        colorPrimaryLight="#f79cab"
                        shouldHighlightWeekends
                    />

                    <div className="flex-row">
                        <TextField value={startDateStr} label="Start Date" disabled/>
                        <TextField value={endDateStr} label="End Date" disabled/>
                    </div>
                </div>
            </DialogContent>

            <DialogActions>
                <SpacedButton className="cancel-button" onClick={onClose}>Cancel</SpacedButton>
                <SpacedButton variant="contained" color="secondary" onClick={onSubmit} disabled={isNullOrUndefined(endDate)}>Submit</SpacedButton>
            </DialogActions>
        </Dialog>
    )    
}

interface MarketSelectionEditorDialogProps {
    scheduledPromoId: number;
    selectedMarketIds: number[];
    startDateIsoStr: string;
    endDateIsoStr: string;
    onClose: () => void;
}

function MarketSelectionEditorDialog({scheduledPromoId, selectedMarketIds: originalSelectedMarketIds, startDateIsoStr, endDateIsoStr, onClose}: MarketSelectionEditorDialogProps) {
    const {data: marketData} = useGetAllMarketsQuery({onError: () => alert("Could not load market data")});
    const allMarkets = useMemo(() => marketData?.allMarkets ?? [], [marketData]);
    const [selectedMarketIds, setSelectedMarketIds] = useState<number[]>([...originalSelectedMarketIds]);

    const [conflictingMarketNames, setConflictingMarketNames] = useState<string[]>([]);
    const [checkForConflicts, {loading: checking}] = useCheckForPromotionConflictsLazyQuery({
        onError: () => alert("Failed attempt to check for promotion conflicts. Please refresh the page and try again."),
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        checkForConflicts({
            variables: {
                startDate: startDateIsoStr,
                endDate: endDateIsoStr,
                marketIds: selectedMarketIds,
                scheduledIdToIgnore: scheduledPromoId  // don't want this promo to conflict with itself
            },
        }).then((data) => {
            const conflictingMarketIds = data.data?.checkForPromotionConflicts ?? [];
            const names = allMarkets.filter(mkt => conflictingMarketIds.includes(mkt.id)).map(mkt => mkt.name);
            setConflictingMarketNames(names); 
        });
    }, [allMarkets, checkForConflicts, endDateIsoStr, scheduledPromoId, selectedMarketIds, startDateIsoStr]);

    const [updateMarkets] = useUpdateWhichMarketsForScheduledPromotionMutation({
        onError: () => alert("Could not update markets included in this promotion"),
        onCompleted: () => {alert("Successfully updated market ids for this promotion"); onClose()},
        refetchQueries: [namedOperations.Query.GetAllScheduledPromotions]
    });

    function onSubmit() {
        if (selectedMarketIds.length < 1) {
            alert("Select at least 1 market for the promotion")
        } else {
            updateMarkets({
                variables: {
                    scheduledPromotionId: scheduledPromoId,
                    addedMarketIds: selectedMarketIds.filter(item => !originalSelectedMarketIds.includes(item)),
                    removedMarketIds: originalSelectedMarketIds.filter(item => !selectedMarketIds.includes(item))
                }
            });
        }
    }

    return (
        <Dialog open={true}>
            <DialogTitle>Update Markets for Promotion</DialogTitle>

            <DialogContent>
                <MarketSelectionSection allMarkets={allMarkets} selectedMarketIds={selectedMarketIds} setSelectedMarketIds={setSelectedMarketIds} />

                {conflictingMarketNames.length > 0 && (
                    <Typography className="error-text margin-top-sm">Conflict detected for the following markets: {conflictingMarketNames.join(', ')}</Typography>
                )}
            </DialogContent>

            <DialogActions>
                <SpacedButton className="cancel-button" onClick={onClose}>Cancel</SpacedButton>
                <SpacedButton variant="contained" color="secondary" onClick={onSubmit} disabled={checking || conflictingMarketNames.length > 0}>Submit</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}
