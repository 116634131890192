import { FlatSubSection } from "FlatComponents/Layout/FlatSection";


// includes materials for service and product materials
export interface MaterialReturn {
    materialType: string;
    materialDescription?: string;
    amount: number;
    amountUnit: string;
}

export let testReturns: MaterialReturn[] = [
    {materialType: "Wood", materialDescription: "Stylename Colorname", amount: 55, amountUnit: "sqft"},
    {materialType: "Stair Nose", amount: 1, amountUnit: "stick"}
]

export default function ReturnsPane({returns} : {returns: MaterialReturn[]}) {
    return (
        <FlatSubSection header="Returns">
            <div className="flex-column">
                {returns.map((r, i) => (
                    <span key={`rp-${i}`} className="flex-row flex-gap-sm">
                        <p className="margin-none">{r.materialType}</p>
                        {r.materialDescription && <p className="margin-none">{r.materialDescription}</p>}
                        <p className="margin-none">{r.amount} {r.amountUnit}</p>
                    </span>
                ))}
            </div>
        </FlatSubSection> 
    )
}