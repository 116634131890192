import { Button, IconButton, StandardTextFieldProps, TextField } from "@material-ui/core"
import clsx from "clsx"
import { MoneyNumberFormat } from "Pages/Admin/LaborRates/Components/MoneyNumberFormat"
import ClearIcon from '@material-ui/icons/Clear';

interface ResetUpdateMoneyFieldProps {
    value: number | undefined,
    isDifferent: boolean,
    loadedValue: number,
    setValue: (newValue: number | undefined) => void,
    reset: () => void,
    submit: () => void
}

export default function ResetUpdateMoneyField({ value, isDifferent, loadedValue, setValue, reset, submit }: ResetUpdateMoneyFieldProps) {
    return (

        <>
            <MoneyNumberFormat
                style={{ width: "5rem" }}
                value={value}
                onValueChange={(values) => {
                    if (values.floatValue !== loadedValue) setValue(values.floatValue)
                }}
                customInput={TextFieldXButton}
                giveToInputProps={{
                    onClickX: reset,
                    xClasses: clsx({ 'visibility-hidden': !isDifferent }, "w-1r", "h-1r")
                }}
            />
            <Button onClick={submit} style={{ visibility: (isDifferent ? "visible" : "hidden") }}>
                Update
            </Button>
        </>
    )
}
interface TextFieldXButtonProps extends StandardTextFieldProps {
    onClickX: () => void,
    xClasses?: string
}

export function TextFieldXButton({ onClickX, xClasses, ...props }: TextFieldXButtonProps) {

    return <TextField {...props}
        InputProps={{
            endAdornment:
                (<IconButton size='small' onClick={onClickX} className={xClasses}>
                    <ClearIcon />
                </IconButton>)
        }}
    />
}




export function ResetUpdateMoneyField2({ value, isDifferent, loadedValue, setValue, reset, submit }: ResetUpdateMoneyFieldProps) {
    return (
        <>
            <MoneyNumberFormat
                style={{ width: "5rem" }}
                value={value}
                onValueChange={(values) => {
                    if (values.floatValue !== loadedValue) setValue(values.floatValue)
                }} />
            <Button onClick={reset} style={{ visibility: (isDifferent ? "visible" : "hidden"), minWidth: 0, width: "2em" }}>
                X
            </Button>
            <Button onClick={submit} style={{ visibility: (isDifferent ? "visible" : "hidden") }}>
                Update
            </Button>
        </>
    )
}