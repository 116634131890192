import FlatLockAndUnlockButton from "FlatComponents/Button/FlatLockAndUnlockButton";
import { AppliedDiscount, FinancingUpdatedDocument, FinancingUpdatedSubscription, GetJobConfigurationFinancingQuery, useGetJobConfigurationFinancingQuery } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { WE_PAY_SALES_TAX_DISCOUNT_ID } from "Globals/globalConstants";
import { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectFinancingOption, selectJobConfigurationId, selectUsageContext, setFinancingOption } from "Redux/pricingCenterReducer";
import FinancingOptionViewer from "./FinancingOptionViewer";

export default function FinancingSection({ discounts }: { discounts: AppliedDiscount[] }) {
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const hasSalesTaxDiscount = isNotNullOrUndefined(discounts.find(d => d.discountId === WE_PAY_SALES_TAX_DISCOUNT_ID));
    const dispatch = useAppDispatch();

    const { data: configFinancingData, loading: configFinLoading, subscribeToMore } = useGetJobConfigurationFinancingQuery({
        skip: jobConfigurationId < 1,
        variables: { jobConfigurationId: jobConfigurationId },
        onCompleted: (data) => {
            dispatch(setFinancingOption(data.jobConfiguration.financing?.financingOption ?? null));
        }
    });
    const financing = configFinancingData?.jobConfiguration.financing;
    const selectedFinOpId = useAppSelector(selectFinancingOption)?.id ?? -1;
    // listen for updates to job financing
    useCallback(() => {
        if (jobConfigurationId > 0) {
            subscribeToMore({
                document: FinancingUpdatedDocument,
                variables: { jobConfigurationId: jobConfigurationId }, 
                updateQuery: (prev, { subscriptionData }) => {
                    const updatedFinancing = (subscriptionData.data as unknown as FinancingUpdatedSubscription).financingUpdated;
                    if (!updatedFinancing) return prev;
                
                    const newQueryData: GetJobConfigurationFinancingQuery = {
                        ...prev,
                        jobConfiguration: {
                            ...prev.jobConfiguration,
                            financing: updatedFinancing
                        }
                    };

                    return newQueryData;
                }
            }
        )}
    }, [jobConfigurationId, subscribeToMore])();

    // only applicable to the recovery center (Rc)
    const [preventRcEditing, setPreventRcEditing] = useState(true);

    const usageContext = useAppSelector(selectUsageContext);

    return (
        <div className="flex-column flat-font-sm">
            <div
                className="flat-font flat-font-bold flat-font-upper flex-row flex-gap-xsm"
                style={{marginBottom: ".5rem"}}
            >
                <p className="margin-none">Financing</p>
                {usageContext === "rc" && <FlatLockAndUnlockButton locked={preventRcEditing} setLocked={setPreventRcEditing} size="small"/>}
            </div>

            {(!configFinLoading && selectedFinOpId > 0) && (
                <div className="flex-column margin-bottom-xsm">
                    <span className="flex-row">
                        <div className="margin-right-xsm">Down Payment:</div>
                        <div style={{fontWeight: "bold"}}>${financing!.financingDownPayment.toFixed(2)}</div>
                    </span>

                    <span className="flex-row">
                        <div className="margin-right-xsm">Monthly Payment:</div>
                        <div style={{fontWeight: "bold"}}>${financing!.perMonthAmount.toFixed(2)}</div>
                    </span>
                </div>
            )}

            {(!configFinLoading && selectedFinOpId < 1) && (
                <div className="margin-bottom-xsm">No financing option selected</div>
            )}

            <FinancingOptionViewer
                hasSalesTaxDiscount={hasSalesTaxDiscount}
                selectedFinOpId={selectedFinOpId}
                preventRcEditing={preventRcEditing}
            />
        </div>
    )
}