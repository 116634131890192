import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { formatSimpleDate } from "Globals/StringFormatting";
import "./InvoiceStyling.css";


export function InvoiceNoticeOfCancellationText({ endDate }: { endDate: Date }) {

    return (
        <>
            <Typography variant="h6" className="h-2r">Notice Of Cancellation</Typography>
            <p style={{ textAlign: "left" }}>
                You may cancel this transaction without penalty or obligation for any reason within three (3) business days from
                the date of the above conditional sales contract.
            </p>
            <p style={{ textAlign: "left" }}>
                <b>
                    To cancel this transaction, a signed and dated copy of this cancellation notice or any other written notice
                    must be either e-mailed to customerservice@worldoffloors.com, faxed to (586)353-1799, or postmarked if
                    mailed to World of Floors at 50327 Hayes Road, Shelby Township, MI 48315 no later than
                    midnight of <u>{formatSimpleDate(endDate)}</u>.
                </b>
            </p>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>I hereby cancel this transaction</td>
                            <td className="padding-side-sm">_________________________________________</td>
                            <td>______________________.</td>
                        </tr>
                        <tr>
                            <td />
                            <td style={{ fontSize: ".75rem" }}>(Buyer's Signature)</td>
                            <td style={{ fontSize: ".75rem" }}>(Date)</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export function InvoiceNoticeOfRightToCancelText() {
    return (
        <p style={{ textAlign: "left" }}>
            You, the buyer, have the right to cancel this contract within three (3) business days.
            You may cancel by e-mailing, certified mailing, faxing, or delivering a written notice to the seller at the seller’s place of business by midnight of the third business day after you received a signed and dated copy of the contract that includes this notice. Please include your name, your address, and the date you received the signed copy of the contract and this notice.
            The refund of any payments made by you shall be processed within 7 business days following receipt by the seller of your cancellation notice.
            I hereby acknowledge that I was provided this document titled “Notice of Three-Day Right to Cancel”.
        </p>
    )
}

export function InvoiceContractTermsAndConditions({ renderExpanded, className, onReachedBottom }:
    { renderExpanded?: boolean, className?: string, onReachedBottom?: () => void }) {

    function determinePosition(elem: HTMLDivElement) {
        const atBottom = (elem.scrollHeight - elem.clientHeight) === elem.scrollTop
        if (atBottom) onReachedBottom?.()
    }

    return (
        <div className={clsx({ 'flex-column': renderExpanded }, "solid-border fill-height")}>
            <Typography
                variant="h6"
                className={clsx("h-2r padding-side-sm fill-width", { "gray-bkg": !renderExpanded })}
                style={{ borderBottom: "solid black 2px" }}
            >
                Terms and Conditions
            </Typography>

            <div
                onScroll={e => determinePosition(e.currentTarget)}
                className={clsx("overflow-auto", "padding-side-xsm", "terms-and-conditions", {"hide-scrollbar": renderExpanded})}
                style={{ maxHeight: (renderExpanded) ? undefined : "15.5rem", margin: "auto 0" }}
            >
                <p className={clsx({
                    'font-size-larger': renderExpanded
                }, 'bold-text')}>
                    THIS IS A C.O.D. ORDER. PAYMENT IN CASH OR CERTIFIED CHECK MUST BE COLLECTED ON THE ARRIVAL OF
                    INSTALLERS AND BEFORE INSTALLATION BEGINS. C.O.D.S IN CREDIT CARD, DEBIT CARD AND/OR FINANCE WILL
                    BE PROCESSED THE BUSINESS DAY BEFORE YOUR INSTALLATION.
                    
                </p>
                <p className={clsx({

                    'font-size-larger': renderExpanded
                }, 'bold-text')}>
                    PLEASE INSPECT ALL NEW FLOOR COVERING AND INSTALLATION FOR ANY ISSUES WHILE THE INSTALLERS ARE
                    AT YOUR HOME. THEY WILL BE HAPPY TO ADDRESS ANY ISSUES BEFORE LEAVING. IF YOU ARE UNABLE TO BE
                    PRESENT DURING INSTALLATION, IT IS YOUR RESPONSIBILITY TO ASSIGN A PERSON 18 YEARS OR OLDER TO
                    BE PRESENT TO MAKE DECISIONS ON YOUR BEHALF, AND TO SIGN OFF ON FINAL WORK INSPECTION UPON JOB
                    COMPLETION.
                </p>
                <InvoiceAdditionalTermsAndConditions hidden={renderExpanded} />
                <p className="h-1r" hidden={renderExpanded} />
            </div>
        </div>
    )
}

export function InvoiceAdditionalTermsAndConditions({ hidden }: { hidden?: boolean }) {
    if (hidden) return <></>
    return (
        <>
            <h5>ADDITIONAL TERMS AND CONDITIONS</h5>

            <p>
                This sale is subject to the terms and conditions appearing herein and on the reverse side which the customer acknowledges reading and agrees to be bound by all terms and conditions and there are no written or spoken understandings, changing or modifying any of the terms of this agreement. If this invoice is based upon measurements or blueprints furnished by customer, the price indicated in this order shall be subject to change should the actual measurements vary from those submitted. Customer agrees that verbal confirmation of changed prices shall be binding.
            </p>
            <p>
                Please plan to be home on the day of your installation as a specific arrival time cannot be scheduled. If customer is unable to be present during installation, it is customer’s responsibility to leave a person 18 years or older at job site in charge of making executive decisions, and to sign off on final work inspection upon job completion on customer’s behalf.
            </p>
            <p>
                All furniture needs to be removed from the room to be installed. Before the installers arrive, customer needs to remove any and all fragile or breakable items such as glassware, lamps, art objects, antiques, computers, electronic equipment or components, valued items, etc., from rooms and closet to be installed, as we cannot be liable for damage or loss to those items or any furniture. It is customer’s responsibility to take apart any furniture assembled by screws or bolts prior to the installation as installers are not able to dissemble or reassemble any furniture that requires a tool to do so.
            </p>
            <p>
                World of Floors cannot be responsible for any damage to TV cables, telephone wires, alarm wires, water pipes, gas lines or damage to water lines for toilets, appliances, sinks, etc. If contract includes moving appliances, World of Floors will neither disconnect or reconnect water or gas fittings, nor be liable for them.
            </p>
            <p>
                All carpet is first quality, however, shading, shedding, fluffing or pile crushing are not manufacturing defects but inherent characteristics of all pile fabrics; thus, World of Floors cannot be responsible. Missing tufts are not a defect. Claims of this type will be considered only on the basis of reinserting such missing tufts by reburling without charge for labor or material. Claims for sprouting, high loops, flecks, oily yards, burrs, tarry tufts, hard and slack twisted yarns, will be considered only on the basis of correcting these conditions. If they cannot be corrected by World of Floors, they may be classified as manufacturer defects. Room temperature should be kept between 65-80 °F 48 hours before and after carpet is installed.
            </p>
            <p>
                Wood, tile and stone are natural products and thus their patterns, colors and shades vary from piece to piece. All woods and laminates will expand and contract with humidity and/or temperature changes and, sometimes, create gaps between planks. This is not a manufacturing defect but inherent characteristics of these products; thus, we cannot be responsible. Relative humidity level must be maintained between 30%-50% and 60-80 °F year-round to ensure long-term performance.
            </p>
            <p>
                Color match to samples will vary from dye lot to dye lot and cannot be guaranteed.
            </p>
            <p>
                Running direction of carpet or plank and placement of all seams shall be left to discretion of installers unless otherwise specified herein in writing. Pat- terns will be matched as closely as possible, but cannot be guaranteed. Seams are not invisible.
            </p>
            <p>
                All hard surface installations require wall base and/or shoe molding. When it is a part of this contract, World of Floors will supply and install primed white or unfinished moldings, unless otherwise specified herein in writing. In the event that removing and reinstalling existing shoe or wall moldings, installers will exercise every care possible but cannot be responsible for breakage or other damage.
            </p>
            <p>
                The amount of floorcovering, moldings and other accessories brought to customer’s home or business typically exceeds the quantity required to finish the contracted areas. This is to ensure the job is completed efficiently. Any and all material not actually used in the installation is the property of World of Floors’.
            </p>
            <p>
                As part of this written sales agreement, World of Floors offers a lifetime residential carpet installation warranty (original homeowner & installation only, excluding tape-down, loose-lay, form fit installations which will be done only at the customer’s responsibility, or any installation in room/area without year-round heat), unless due to customer abuse, neglect, acts of God, improper cleaning, water damage or other causes not related to improper installation.
            </p>
            <p>
                ALL FLOOR INSTALLATIONS: World of Floors will install flooring material over customer’s existing substrate, base floor or underlayment, but cannot be responsible for problems arising therefrom. In the event that such substrate, base floor or underlayment is not perfectly level, the new flooring may be subject to up-and-down movement. It is customer’s obligation to inform World of Floors of any and all existing issues and conditions that are not readily apparent at time of measure and purchase. Should a condition be found at the site and time of installation, this order could be modified to increase or decrease the purchase price. Additionally, should there be a height difference between floors, a transition piece will be used to address such differences unless otherwise specified herein in writing.
            </p>
            <p>
                World of Floors assumes no liability for any resultant damage to premises, or material located on the premises. Any local fire authority approval required for floor covering purchased must be obtained by the customer and World of Floors is not liable. Neither World of Floors nor the installers can be respon- sible for scratching or scoring of walls, moldings and trims due to the size of the material and installation procedure.
            </p>
            <p>
                Customer acknowledges that all installation dates that are written on this contract are estimates only and World of Floors’ failure to meet such estimated installation dates is not a valid basis for cancellation of this contract or for monetary deduction of any kind from the agreed contract price. World of Floors cannot guarantee that work can be completed within estimated installation dates and/or time.
            </p>
            <p>
                I have read the contract including the additional terms and conditions on the reversed side and understand and agree to be bound to them. There are no verbal understandings changing any of these terms. I have received a completed and signed copy of this agreement. I understand that in order for any warranty to be valid I must follow the specific manufacturer’s guidelines and practice regular maintenance of my new floor covering. I understand that if I delay the installation for over 90 days after this contract is signed, all payments I made will be applied as liquidated damages.
            </p>
            <p className='h-1r' />
        </>
    )
}
