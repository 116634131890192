import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

interface SalesState { //Defined so that create slice can infer the type
    arrowStatus: { left: boolean, right: boolean }
    hasFinancing: boolean
}

const initialState: SalesState = {
    arrowStatus: { left: false, right: false },
    hasFinancing: true
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const salesSlice = createSlice({
    name: "sellSheet", //No clue what this does
    initialState,
    reducers: { //DON'T Call async functions in here. No 
        updateArrowStatus: (state, action: PayloadAction<{ left: boolean, right: boolean }>) => {
            if (state.arrowStatus.left !== action.payload.left || state.arrowStatus.right !== action.payload.right) {
                state.arrowStatus.left = action.payload.left
                state.arrowStatus.right = action.payload.right
            }
        },
        setHasFinancing: (state, action: PayloadAction<boolean>) => {
            state.hasFinancing = action.payload;
        }
    }
})

export const { updateArrowStatus, setHasFinancing } = salesSlice.actions //Unpacks the actions created in the slice

export const selectArrowStatus = (state: RootState, isLeft: boolean) => (isLeft) ? state.sales.arrowStatus.left : state.sales.arrowStatus.right;
export const selectArrowsStatus = (state: RootState) => state.sales.arrowStatus;
export const selectHasFinancing = (state: RootState) => state.sales.hasFinancing;

export default salesSlice.reducer;