// import { Calendar, Day, utils } from "react-modern-calendar-datepicker";
import { Calendar, Day, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";
import { useBlockTimeSlotByMarketsMutation, useBlockTimeSlotForAllZipsMutation, useGetAllMarketsQuery } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import AtomicTimeSlot from "../SAHScheduling/sched_utils/AtomicTimeSlot";
import { generateStartAndEndTimes, timeToAmPmString } from "../SAHScheduling/sched_utils/timeSlotUtils";

// constants representing the type of blocking to do
const ALLZIPS = 1;  // for blocking over all zips
const MARKET = 2; // for blocking by market

// const SELECTZIPS = 3;  // for blocking by select zip codes

interface OnBlockEntireDayChangeParams {
    preToggleBlockEntireDay: boolean;
    setBlockEntireDay: (block: boolean) => void;
    setStartTime: (time: Date | null) => void;
    setEndTime: (time: Date | null) => void;
}

function onBlockEntireDayChange({ preToggleBlockEntireDay, setBlockEntireDay, setStartTime, setEndTime }: OnBlockEntireDayChangeParams): void {
    if (preToggleBlockEntireDay) {  // entire day was being blocked BEFORE value was toggled
        // set null; user must now select start and end time explicitly
        setStartTime(null);
        setEndTime(null);
    } else {
        setStartTime(new Date(0, 0, 0, 0, 0));
        setEndTime(new Date(0, 0, 0, 23, 59));
    }
    setBlockEntireDay(!preToggleBlockEntireDay);
}

function timesOk(startTime: Date | null | undefined, endTime: Date | null | undefined) {
    // final condition ensures that the end time is strictly later than the start time
    return isNotNullOrUndefined(startTime) && isNotNullOrUndefined(endTime) && ((endTime as Date).getTime() > (startTime as Date).getTime());
}

interface ValidateAndSubmitForAllZipsParams {
    blockTimeSlotForAllZipsMutation: ReturnType<typeof useBlockTimeSlotForAllZipsMutation>[0];
    setPageHasInvalidData: (errors: boolean) => void;
    values: {
        selectedDates: Day[];
        startTime: Date | null | undefined;
        endTime: Date | null | undefined;
    }
}

function validateAndSubmitForAllZips({ blockTimeSlotForAllZipsMutation, setPageHasInvalidData, values }: ValidateAndSubmitForAllZipsParams): void {
    let datesOk = isNotNullOrUndefined(values.selectedDates) && values.selectedDates.length > 0;
    let canSubmit = datesOk && timesOk(values.startTime, values.endTime);

    if (canSubmit) {
        setPageHasInvalidData(false);
        // asserting number is safe because validity of start/end time has already been verfied
        let startHour = values.startTime?.getHours() as number;
        let startMinute = values.startTime?.getMinutes() as number;
        let endHour = values.endTime?.getHours() as number;
        let endMinute = values.endTime?.getMinutes() as number;
        let ts = new AtomicTimeSlot(startHour, startMinute, endHour, endMinute);
        let days = values.selectedDates.map(date => `${date.year}-${date.month}-${date.day}`)
        blockTimeSlotForAllZipsMutation({ variables: { dates: days, ts: ts } });
    } else {
        setPageHasInvalidData(true);
    }
}

interface ValidateAndSubmitByMarketsParams {
    blockTimeSlotByMarketsMutation: ReturnType<typeof useBlockTimeSlotByMarketsMutation>[0];
    setPageHasInvalidData: (errors: boolean) => void;
    values: {
        selectedDates: Day[];
        selectedMarketIds: number[];
        startTime: Date | null | undefined;
        endTime: Date | null | undefined;
    }
}

const { startTimes: possibleStartTimes, endTimes: possibleEndTimes } = generateStartAndEndTimes(AtomicTimeSlot.nineToNine(), 30);

function validateAndSubmitByMarkets({ blockTimeSlotByMarketsMutation, setPageHasInvalidData, values }: ValidateAndSubmitByMarketsParams): void {
    let datesOk = isNotNullOrUndefined(values.selectedDates) && values.selectedDates.length > 0;
    let marketIdsOk = isNotNullOrUndefined(values.selectedMarketIds) && values.selectedMarketIds.length > 0;
    let canSubmit = datesOk && marketIdsOk && timesOk(values.startTime, values.endTime);

    if (canSubmit) {
        setPageHasInvalidData(false);
        // asserting number is safe because validity of start/end time has already been verfied
        let startHour = values.startTime?.getHours() as number;
        let startMinute = values.startTime?.getMinutes() as number;
        let endHour = values.endTime?.getHours() as number;
        let endMinute = values.endTime?.getMinutes() as number;
        let ts = new AtomicTimeSlot(startHour, startMinute, endHour, endMinute);
        let days = values.selectedDates.map(date => `${date.year}-${date.month}-${date.day}`)
        blockTimeSlotByMarketsMutation({ variables: { dates: days, marketIds: values.selectedMarketIds, ts: ts } });
    } else {
        setPageHasInvalidData(true);
    }
}

export default function BlockAppointmentTimesPage() {
    // actual data being submitted
    const [selectedDates, setSelectedDates] = useState<Day[]>([]);
    const [startTime, setStartTime] = useState<Date | null | undefined>(null);
    const [endTime, setEndTime] = useState<Date | null | undefined>(null);
    const [selectedMarketIds, setSelectedMarketIds] = useState<number[]>([]);

    // metadata/misc
    const [blockingGranularity, setBlockingGranularity] = useState(ALLZIPS);
    const [blockEntireDay, setBlockEntireDay] = useState(false);
    const [allMarkets, setAllMarkets] = useState<any[]>([]);

    // error flags
    const [pageHasInvalidData, setPageHasInvalidData] = useState<boolean>(false);
    const [submissionSuccessState, setSubmissionSuccessState] = useState<boolean | undefined>(undefined);

    // mutations
    // block for all zip codes
    const [blockTimeSlotForAllZipsMutation] = useBlockTimeSlotForAllZipsMutation({
        onCompleted: data => setSubmissionSuccessState(data.blockTimeSlotForAllZips),  // success
        onError: error => setSubmissionSuccessState(false)  // failure
    });

    // block by market
    const [blockTimeSlotByMarketsMutation] = useBlockTimeSlotByMarketsMutation({
        onCompleted: data => setSubmissionSuccessState(data.blockTimeSlotByMarkets),  // success
        onError: error => setSubmissionSuccessState(false)  // failure
    });

    // queries
    useGetAllMarketsQuery({ onCompleted: (response) => setAllMarkets(response.allMarkets) });

    return (
        <div className="flex-column-center flex-centered">
            <h1>Block Appointment Times</h1>
            <div className="padding-sm">
                <Calendar
                    value={selectedDates}
                    onChange={(dates) => setSelectedDates(dates as Day[])}
                    minimumDate={utils('en').getToday()}
                />
            </div>

            <FormControl component="fieldset" className="flex-row-center margin-sm">
                <FormLabel component="legend">Geographic Granularity</FormLabel>
                <RadioGroup row aria-label="Blocking Granularity" onChange={(e) => setBlockingGranularity(+e.target.value)} >
                    <FormControlLabel value={ALLZIPS} control={<Radio checked={blockingGranularity === ALLZIPS} />} label="All Zip Codes" />
                    <FormControlLabel value={MARKET} control={<Radio checked={blockingGranularity === MARKET} />} label="Market" />
                </RadioGroup>
            </FormControl>

            {
                (blockingGranularity === MARKET) && (
                    <FormControl className="margin-bottom-md">
                        <InputLabel id="select-market-label">Markets</InputLabel>
                        <Select
                            style={{ minWidth: "10rem" }}
                            multiple
                            labelId="select-market-label"
                            value={selectedMarketIds}
                            label="Markets"
                            onChange={(e) => setSelectedMarketIds(e.target.value as number[])}
                        >
                            {allMarkets?.map(mkt => (
                                <MenuItem value={mkt.id}>{mkt.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
            }

            <FormGroup>
                <FormControlLabel
                    className={!blockEntireDay ? "margin-bottom-sm" : ''}
                    control={<Checkbox checked={blockEntireDay} />}
                    label="Block Entire Day"
                    onChange={() => onBlockEntireDayChange({ preToggleBlockEntireDay: blockEntireDay, setBlockEntireDay, setStartTime, setEndTime })} />
            </FormGroup>

            {
                !blockEntireDay && (
                    <>
                        <FormControl className="margin-bottom-md">
                            <InputLabel id="select-start-time-label">Select Start Time</InputLabel>
                            <Select
                                style={{ minWidth: "10rem" }}
                                labelId="select-start-time-label"
                                value={startTime?.getTime() ?? ""}
                                onChange={(e) => setStartTime(new Date(e.target.value as number))}
                            >
                                {possibleStartTimes.map(time => (
                                    <MenuItem key={`start-time-select-${time}`} value={time.getTime()}>{timeToAmPmString(time)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <FormControl className="margin-bottom-md">
                            <InputLabel id="select-end-time-label">Select End Time</InputLabel>
                            <Select
                                style={{ minWidth: "10rem" }}
                                labelId="select-end-time-label"
                                value={endTime?.getTime() ?? ""}
                                onChange={(e) => setEndTime(new Date(e.target.value as number))}
                            >
                                {possibleEndTimes.map(time => (
                                    <MenuItem key={`end-time-select-${time}`} value={time.getTime()}>{timeToAmPmString(time)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                )
            }

            <Button
                className="margin-sm"
                variant="contained"
                onClick={() => {
                    setSubmissionSuccessState(undefined);
                    if (blockingGranularity === ALLZIPS) {
                        validateAndSubmitForAllZips({ blockTimeSlotForAllZipsMutation, setPageHasInvalidData, values: { selectedDates, startTime, endTime } });
                    } else if (blockingGranularity === MARKET) {
                        validateAndSubmitByMarkets({ blockTimeSlotByMarketsMutation, setPageHasInvalidData, values: { selectedDates, selectedMarketIds, startTime, endTime } })
                    }
                }}
            >Block</Button>

            {pageHasInvalidData && <p className="error-text">Can't submit because the page contains invalid data.</p>}

            {submissionSuccessState && (<p className="success-text">Successfully submitted blocks!</p>)}
            {(isNotNullOrUndefined(submissionSuccessState) && !submissionSuccessState) && (<p className="error-text">Failed to submit blocks.</p>)}
        </div>
    )
}