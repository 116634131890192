import { MenuItem, Select } from "@material-ui/core";
import { MAKE_NEW_AREA_INDEX, selectAreas, selectUnassignedRoomCount, UNASSINGED_AREA_INDEX } from "Redux/genericSalesReducer";
import { useAppSelector } from "Redux/hooks";

interface ChangeOrderAreaSelectProps {
    selectedAreaIndex?: number,
    onChange: (newIndex: number) => void,
    forceShowUnassigned?: boolean,
    showAddNewArea?: boolean
}

function ChangeOrderAreaSelect({ selectedAreaIndex, onChange, forceShowUnassigned, showAddNewArea }: ChangeOrderAreaSelectProps) {
    const areas = useAppSelector(selectAreas)
    const unassignedRoomCount = useAppSelector(selectUnassignedRoomCount)

    const showUnassigned = unassignedRoomCount > 0 || forceShowUnassigned;

    return (
        <Select value={selectedAreaIndex ?? -1} onChange={(e) => onChange(e.target.value as number)}>
            <MenuItem value={-1} hidden>Select Product</MenuItem>
            {
                areas?.map((area, index) => <MenuItem key={`area-index-${index}`} value={index}>{area.productTypeName}-{area.styleName}-{area.colorName}</MenuItem>)
            }
            <MenuItem value={UNASSINGED_AREA_INDEX} hidden={!showUnassigned}>No Product</MenuItem>
            <MenuItem value={MAKE_NEW_AREA_INDEX} hidden={!showAddNewArea}>Create New Job</MenuItem>
        </Select>
    );
}

export default ChangeOrderAreaSelect;