import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Input, MenuItem, Select, TextField, Tooltip } from "@material-ui/core"
import { Autocomplete } from "@mui/material"
import clsx from "clsx"
import SpacedButton from "Components/Forms/Controls/SpacedButton"
import Loading from "Components/Loading"
import { AddServiceMaterialCategoryPricingInput, GetAllServiceMaterialCategoryPricingsQuery, GetProductTypeOptionsQuery, namedOperations, useAddServiceMaterialCategoryPricingsMutation, useGetAllServiceMaterialCategoryPricingsQuery, useGetProductTypeOptionsQuery } from "generated/graphql"
import { isNotNullOrUndefined, isNumber } from "Globals/GenericValidators"
import { useMemo, useState } from "react"
import { tryCalculateMarkup } from "./ServiceProductPricingTableRow"
import ServiceValueEditor from "./ServiceValueEditor"

interface AddServiceProductDialogProps {
    open: boolean,
    onClose: () => void
}

export default function AddServiceProductDialog({ open, onClose }: AddServiceProductDialogProps) {
    const { data: matData } = useGetAllServiceMaterialCategoryPricingsQuery()
    const { data: pData } = useGetProductTypeOptionsQuery();

    const [addProducts, { loading }] = useAddServiceMaterialCategoryPricingsMutation({
        refetchQueries: [namedOperations.Query.GetAllServiceMaterialCategoryPricings],
        onCompleted(data) {
            clearChanges()
            onClose();
        }
    })

    const existingProductNames = useMemo(() => {
        const allRows = matData?.allServiceMaterialCategoryPricings ?? []

        return [...new Set(allRows?.map(row => row.serviceMaterialCategory))]
    }, [matData])

    const [name, setName] = useState<string>("")
    const [unit, setUnit] = useState("sqft")
    const [packageSize, setPackageSize] = useState<null | string>(null)
    const [packageDivisions, setPackageDivisions] = useState<null | string>(null)

    const [errorText, setShowErrorText] = useState<string | null>(null)

    const lowerCaseCustomName = name.toLowerCase()
    const existingProduct = existingProductNames.find(v => v.toLowerCase() === lowerCaseCustomName)

    const displayUnit = useMemo(() => {
        if (existingProduct === undefined) return undefined
        else return matData?.allServiceMaterialCategoryPricings.find(v => v.serviceMaterialCategory === existingProduct)?.priceUnit;
    }, [existingProduct, matData]) ?? unit

    const { categoryPackageSize, categoryPackageDivisions } = useMemo(() => {
        if (existingProduct === undefined) return { categoryPackageSize: null, categoryPackageDivisions: null }
        else {

            const category = matData?.allServiceMaterialCategoryPricings.find(v => v.serviceMaterialCategory === existingProduct);
            const groupingSize = category?.packageSize ?? null;
            const groupsToPackage = category?.packageSizeIncrement ?? null;

            if (groupingSize === null) return { categoryPackageSize: null, categoryPackageDivisions: null }
            else {
                const size = groupingSize * (groupsToPackage ?? 1)
                const divisions = groupsToPackage;

                return { categoryPackageSize: size, categoryPackageDivisions: divisions }
            }
        }
    }, [existingProduct, matData])

    const displayPackageSize = packageSize ?? categoryPackageSize?.toString() ?? ""
    const displayPackageDivisions = packageDivisions ?? categoryPackageDivisions?.toString() ?? ""

    const lockUnit = existingProduct !== undefined

    const packageSizeValid = displayPackageSize === "" || (isNumber(displayPackageSize) && +displayPackageSize >= 1)
    const packageDivisionsValid = displayPackageDivisions === "" || (isNumber(displayPackageDivisions) && +displayPackageDivisions >= 1)

    const [editingPricing, setEditingPricing] = useState<boolean>(false)
    const validToEditPricing = name.length > 0
        && isNotNullOrUndefined(matData)
        && isNotNullOrUndefined(pData)
        && packageSizeValid
        && packageDivisionsValid

    const [pricingRows, setPricingRows] = useState<{ rows: PricingRow[], areChanges: boolean }>({ rows: [], areChanges: false })

    function beginEditing() {
        setEditingPricing(true)
        setPricingRows({ rows: generatePricingRows(matData!.allServiceMaterialCategoryPricings, pData!.options, name), areChanges: false })
    }

    function stopEditing() {
        if (!pricingRows.areChanges || window.confirm("This will clear any pricing changes. Are you sure?")) {
            setEditingPricing(false)
            setPricingRows({ rows: [], areChanges: false })
        }
    }

    function clearChanges() {
        setName("")
        setPricingRows({ rows: [], areChanges: false })
        setPackageSize(null)
        setPackageDivisions(null)
        setEditingPricing(false)
        setUnit('sqft')
        setShowErrorText(null)
    }

    function closeAndClear() {
        clearChanges()
        onClose()
    }

    function submitChanges() {
        if (name.length > 0 && packageDivisionsValid && packageSizeValid && pricingRowsValid(pricingRows.rows)) {

            const dbPackageSize = displayPackageSize === "" ? null : +displayPackageSize / (+displayPackageDivisions ?? 1)
            const dbPackageSizeIncrements = (dbPackageSize === null || displayPackageDivisions === null) ? null : +displayPackageDivisions

            const firstCost = calculateFirstCost(pricingRows.rows)
            const firstPrice = calculateFirstPrice(pricingRows.rows)

            addProducts({
                variables: {
                    materialCategoryName: name,
                    pricingUnit: displayUnit,
                    packageSize: dbPackageSize,
                    packageSizeIncrement: dbPackageSizeIncrements,
                    newPricings: pricingRows.rows.filter(row => !row.isLocked && row.included).map(row => pricingRowsToAddServiceMaterialCategoryPricing(row, firstCost!, firstPrice!))
                }
            })
        }
        else setShowErrorText("Fix the highlighted fields")

    }

    function safeUpdateName(newName: string) {
        if (editingPricing) return
        setName(newName)
    }

    function changeProductName(newName: string) {
        if (existingProduct !== null) {
            const newValue = newName?.toLowerCase();
            if (existingProductNames.find(v => v.toLowerCase() === newValue)) {
                setPackageSize(null)
                setPackageDivisions(null)
            }
        }
        safeUpdateName(newName ?? "")
    }

    return (
        <Dialog open={open} maxWidth='xl'>
            <DialogTitle>Add Product</DialogTitle>
            <DialogContent style={{ width: "50rem", height: "20rem", position: "relative" }}>
                <div className="flex-row" >
                    <div className="flex-column" style={{ width: "15rem" }}>
                        <Autocomplete
                            freeSolo={true}
                            renderInput={({ disabled, inputProps, ...params }) => {

                                const { style, ...restProps } = inputProps

                                return <TextField
                                    {...params}
                                    inputProps={{ ...restProps, style: { ...style, display: "flex", alignItems: "center", height: "2.5rem" } }}
                                    disabled={disabled || editingPricing}
                                    label="Product"
                                    variant="standard" />
                            }}
                            getOptionLabel={(option) => option}
                            value={name}
                            onChange={(e, value) => {
                                changeProductName(value ?? "")
                            }}
                            onInputChange={(e, value) => {
                                if (e?.type === "change") {
                                    // This occurs when the textfield is changed by being typed into 
                                    // The type is different when the user selects and option
                                    changeProductName(value)
                                }
                            }}
                            disabled={editingPricing}
                            options={existingProductNames} />

                        <label style={{ marginTop: ".5em" }}>Unit</label>
                        <Select value={displayUnit} disabled={lockUnit || editingPricing} onChange={e => setUnit(e.target.value as string)}>
                            <MenuItem value="sqft">Sqft</MenuItem>
                            <MenuItem value="lnft">Lnft</MenuItem>
                            <MenuItem value="each">Each</MenuItem>
                        </Select>
                        <Tooltip title={`This product is sold in increments of ${displayPackageSize} ${displayUnit}`}>
                            <label style={{ marginTop: ".5em" }}>Package Size <span style={{ fontSize: ".8em" }}>(optional)</span></label>
                        </Tooltip>
                        <ServiceValueEditor
                            disabled={loading}
                            value={displayPackageSize}
                            initialValue={categoryPackageSize}
                            onChange={setPackageSize}
                            unit={displayUnit}
                            nonMoneyVersion />
                        {
                            displayPackageSize !== "" &&
                            <>
                                <label style={{ marginTop: ".5em" }}>Package Divisions <span style={{ fontSize: ".8em" }}>(optional)</span></label>
                                <ServiceValueEditor
                                    disabled={loading}
                                    value={displayPackageDivisions}
                                    initialValue={categoryPackageDivisions}
                                    onChange={setPackageDivisions}
                                    unit={'pieces'}
                                    nonMoneyVersion />
                            </>
                        }
                        {
                            validToEditPricing &&
                            <>
                                {
                                    editingPricing ?
                                        <Button variant="contained" onClick={stopEditing} style={{ marginTop: ".5em" }}>
                                            Change Product
                                        </Button> :
                                        <Button variant="contained" onClick={beginEditing} style={{ marginTop: ".5em" }}>
                                            Begin Editing Prices
                                        </Button>
                                }
                            </>
                        }
                    </div>

                    <table className="table-fixed-new" style={{ height: "100%", flex: 1 }}>
                        <thead>
                            <tr style={{ textAlign: "center" }}>
                                <th>Included</th>
                                <th>Product</th>
                                <th>Price</th>
                                <th>Cost</th>
                                <th>Markup %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pricingRows.rows.map((row, index) => <EditServiceMaterialRow
                                    key={row.productTypeId}
                                    row={row}
                                    updateRow={(newRow) => {
                                        const copy = [...pricingRows.rows]
                                        copy[index] = newRow
                                        setPricingRows({ rows: copy, areChanges: true })
                                    }}
                                    unit={displayUnit}
                                    showErrors={true}
                                    firstCost={calculateFirstCost(pricingRows.rows)}
                                    firstPrice={calculateFirstPrice(pricingRows.rows)} />)
                            }
                            {
                                !editingPricing &&
                                <tr>
                                    <td colSpan={5} style={{ height: "5em" }} align="center"><div style={{ fontSize: "1.2em" }}>Finish selecting product first.</div></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </DialogContent>
            <DialogActions>
                <div hidden={errorText === null} className="error-text">{errorText}</div>
                <SpacedButton variant="outlined" color="secondary" onClick={closeAndClear}>Close</SpacedButton>
                <SpacedButton variant="contained" onClick={submitChanges}>Submit</SpacedButton>
            </DialogActions>
            <div hidden={!loading} style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: "#00000044" }}>
                <Loading style={{ backgroundColor: "lightgray", padding: "10px", borderRadius: "10px" }} altText="Submitting" />
            </div>
        </Dialog >
    )
}

function calculateFirstCost(rows: PricingRow[]): string | null {
    return rows.find(r => r.cost !== null)?.cost ?? null
}

function calculateFirstPrice(rows: PricingRow[]): string | null {
    return rows.find(r => r.price !== null)?.price ?? null
}

function pricingRowsToAddServiceMaterialCategoryPricing(row: PricingRow, defaultCost: string, defaultPrice: string): AddServiceMaterialCategoryPricingInput {

    return {
        productTypeId: row.productTypeId,
        cost: +(row.cost ?? defaultCost),
        price: +(row.price ?? defaultPrice)
    }
}

function pricingRowsValid(rows: PricingRow[]): boolean {
    if (rows.length === 0) return true;

    const firstPrice = calculateFirstPrice(rows)
    const firstCost = calculateFirstCost(rows)

    if (firstCost === null || firstPrice === null || moneyInvalid(firstCost) || moneyInvalid(firstPrice)) return false;

    return !rows.some(row => !isPricingRowValid(row, firstPrice, firstCost))
}

function isPricingRowValid(row: PricingRow, firstPrice: string, firstCost: string): boolean {
    return row.isLocked || // Row is loaded from the database so the values must be valid 
        !row.included || // Row is not selected, the changes don't matter
        (row.included // Row is included, so fields must be validated
            && (row.price === null || !moneyInvalid(row.price)) // Price is valid
            && (row.cost === null || !moneyInvalid(row.cost)) // Cost is valid
            && +(row.price ?? firstPrice) >= +(row.cost ?? firstCost) // Price is greater than cost
        )
}

function moneyInvalid(value: string) {
    return !isNumber(value) || +value <= 0
}

interface EditServiceMaterialRowProps {
    row: PricingRow,
    updateRow: (row: PricingRow) => void,
    showErrors: boolean,
    unit: string,
    firstCost: string | null,
    firstPrice: string | null
}

function EditServiceMaterialRow({ updateRow, showErrors, unit, row, firstPrice, firstCost }: EditServiceMaterialRowProps) {

    const { cost, price, isLocked, included, productTypeName } = row
    const disabled = isLocked

    function setCost(c: string) {
        updateRow({ ...row, cost: c })
    }

    function setPrice(p: string) {
        updateRow({ ...row, price: p })
    }

    function setRowChecked(checked: boolean) {
        updateRow({ ...row, included: checked })
    }

    const displayCost = cost ?? firstCost
    const displayPrice = price ?? firstPrice

    const markup = tryCalculateMarkup(displayPrice ?? "", displayCost ?? "")?.toFixed(0)

    return (
        <tr>
            <td align="center"><Checkbox checked={included} disabled={disabled} onChange={e => setRowChecked(e.target.checked)} /></td>
            <td align="center">{productTypeName}</td>
            <td>
                <Input
                    style={{ borderBottom: "#44444444 solid 1px", margin: "0 .5em", height: "2em", width: "8em" }}
                    disabled={disabled}
                    value={displayPrice}
                    onChange={v => setPrice(v.target.value)}
                    startAdornment="$"
                    error={showErrors && isMoneyInvalid(displayPrice ?? "")}
                    endAdornment={"/" + unit} />
            </td>
            <td>
                <Input
                    style={{ borderBottom: "#44444444 solid 1px", margin: "0 .5em", height: "2em", width: "8em" }}
                    disabled={disabled}
                    value={displayCost}
                    onChange={v => setCost(v.target.value)}
                    startAdornment="$"
                    error={showErrors && isMoneyInvalid(displayCost ?? "")}
                    endAdornment={"/" + unit} />
            </td>
            <td align="center" className={clsx({ 'error-text': (markup === undefined || +markup < 0) })}>
                {markup ?? "-"} %
            </td>
        </tr>
    )
}

function generatePricingRows(
    existingMaterials: GetAllServiceMaterialCategoryPricingsQuery['allServiceMaterialCategoryPricings'],
    productTypes: GetProductTypeOptionsQuery['options'],
    productName: string): PricingRow[] {
    const matchingExistingRows = existingMaterials.filter(mat => mat.serviceMaterialCategory.toLowerCase() === productName.toLowerCase())

    return productTypes.map(product => {
        const matchingRow = matchingExistingRows.find(row => row.productTypeId === product.id)

        return {
            productTypeId: product.id,
            productTypeName: product.type,
            price: matchingRow?.pricePerUnit?.toFixed(2) ?? null,
            cost: matchingRow?.costPerUnit?.toFixed(2) ?? null,
            isLocked: isNotNullOrUndefined(matchingRow),
            included: isNotNullOrUndefined(matchingRow)
        }
    })
}

interface PricingRow {
    productTypeId: number,
    productTypeName: string,
    price: string | null,
    cost: string | null,
    isLocked: boolean,
    included: boolean
}

function isMoneyInvalid(amount: string) {
    return isNaN(+amount) || +amount <= 0
}