import { Calendar, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker"
import { Dialog, DialogTitle, DialogContent, Checkbox, Typography, Input, DialogActions } from "@material-ui/core"
import clsx from "clsx"
import SpacedButton from "Components/Forms/Controls/SpacedButton"
import { WorkerBlockedTimeSlot } from "generated/graphql"
import { dateTimeStrToDay, timeSpanStrToHHMM, dayToYmd, miltiaryToHourMinute, minutesBetween } from "Globals/DateAndTimeHelpers"
import { isNullOrUndefined } from "Globals/GenericValidators"
import { useState } from "react"
import { isMobile } from "react-device-detect"


interface AddOrUpdateBlockTimeProps {
    existingBlockTime?: WorkerBlockedTimeSlot,
    onClose: (newOrUpdated?: WorkerBlockedTimeSlot) => void,
    wholeDayOnly?: boolean
}

export default function AddOrUpdateBlockTime({ existingBlockTime, onClose, wholeDayOnly }: AddOrUpdateBlockTimeProps) {
    const [date, setDate] = useState<DayValue>(existingBlockTime !== undefined ? dateTimeStrToDay(existingBlockTime.date) : utils('en').getToday())

    const isWholeDayOnly = (wholeDayOnly ?? false)

    const [startTime, setStartTime] = useState<string>(
        isWholeDayOnly ? "00:00" :
            existingBlockTime?.startTime ?
                timeSpanStrToHHMM(existingBlockTime?.startTime) :
                ""
    )

    const [endTime, setEndTime] = useState<string>(
        isWholeDayOnly ? "23:59" :
            existingBlockTime?.endTime ?
                timeSpanStrToHHMM(existingBlockTime?.endTime) :
                "")

    const [allDay, setAllDay] = useState<boolean>(isWholeDayOnly || (startTime === "00:00" && endTime === "23:59"))

    function SubmitChanges() {
        //TODO: convert back and call onClose
        if (isNullOrUndefined(date)) return;
        if (isWholeDayOnly || allDay) {
            onClose({ id: existingBlockTime?.id ?? -1, workerId: -1, date: dayToYmd(date!), startTime: "00:00", endTime: "23:59" })
        }
        else {
            if (startTime === "" || endTime === "") return;
            onClose({ id: existingBlockTime?.id ?? -1, workerId: -1, date: dayToYmd(date!), startTime, endTime })
        }
    }

    function tryUpdateStartTime(newTime: string) {
        var sTime = newTime === "" ? null : miltiaryToHourMinute(newTime);
        var eTime = endTime === "" ? null : miltiaryToHourMinute(endTime);

        if (sTime !== null && eTime !== null && minutesBetween(sTime!, eTime!) <= 0) {
            setEndTime("")
        }
        setStartTime(newTime)
    }

    function tryUpdateEndTime(newTime: string) {
        var sTime = startTime === "" ? null : miltiaryToHourMinute(startTime);
        var eTime = newTime === "" ? null : miltiaryToHourMinute(newTime);

        if (sTime !== null && eTime !== null && minutesBetween(sTime!, eTime!) <= 0) {
            setStartTime("")
        }

        setEndTime(newTime)
    }

    return (
        <Dialog open={true} fullScreen={isMobile}>
            <DialogTitle>{existingBlockTime ? "Update " : ""}Block Time</DialogTitle>
            <DialogContent>
                <Calendar
                    value={date}
                    onChange={(date) => setDate(date)}
                    minimumDate={utils('en').getToday()}
                />
                <div className={clsx("flex-row", { 'visibility-hidden': isWholeDayOnly })} style={{ alignItems: "center", justifyContent: "center" }}>
                    <Checkbox checked={allDay} onChange={e => setAllDay(!allDay)} />
                    <Typography>All Day</Typography>
                </div>
                <div className={clsx("flex-row", { 'visibility-hidden': (isWholeDayOnly || allDay) })} style={{ justifyContent: "space-evenly" }}>
                    <div className="flex-column">
                        <Typography>Start Time:</Typography>
                        <Input value={startTime} onChange={v => tryUpdateStartTime(v.currentTarget.value)} type="time" />
                    </div>
                    <div className="flex-column">
                        <Typography>End Time:</Typography>
                        <Input value={endTime} onChange={v => tryUpdateEndTime(v.currentTarget.value)} type="time" />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <SpacedButton onClick={() => onClose(undefined)}>Close</SpacedButton>
                <SpacedButton onClick={SubmitChanges}>{existingBlockTime ? "Update" : "Add"}</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}
