import { MenuItem, Select } from "@material-ui/core";
import { HBar } from "Components/pageSpacing";
import { SubstrateId } from "generated/graphql";
import { ServiceTypeLabel } from "./ServicesByTypeEditor";


interface SubstrateEditorBlockProps {
    showError?: boolean,
    substrateOptions: SubstrateId[],
    substrate: number,
    setSubstrate: (newSubstrate: number) => void
}

export default function SubstrateEditorBlock({ showError, substrateOptions, substrate, setSubstrate }: SubstrateEditorBlockProps) {

    return (
        <div className="fill-width flex-column" style={{ minHeight: "5rem" }}>
            <div className="fill-width flex-row">
                <ServiceTypeLabel>Substrate:</ServiceTypeLabel>
                <table className="fill-width">
                    <tbody>
                        <tr>
                            <td className="w-10r">
                                <Select value={substrate} fullWidth displayEmpty error={showError && substrate === -1}>
                                    {
                                        substrate === -1 &&
                                        <MenuItem value={-1}>None Selected</MenuItem>
                                    }
                                    {
                                        substrateOptions.map(op =>
                                            <MenuItem
                                                value={op.id}
                                                key={`select-substrate-${op.id}`}
                                                onClick={(e) => setSubstrate(op.id)}>
                                                {op.type}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </td>
                            <td />
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex-grow" style={{ minHeight: ".5rem" }} />
            <HBar />
        </div>
    )
}