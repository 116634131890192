import { CircularProgress } from '@material-ui/core'
import { Grid } from '@mui/material';

export default function Loading({ altText, dontFillHeight, style }: { altText?: string, dontFillHeight?: boolean, style?: React.CSSProperties }) {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: dontFillHeight ? undefined : '100%', zIndex:10000000 }}
        >
            <Grid item xs={3}>
                <h1 style={{ textAlign: "center", ...style }}>{altText ?? "Loading"}</h1>
            </Grid>
            <Grid item xs={3}>
                <CircularProgress />
            </Grid>
        </Grid>);
}