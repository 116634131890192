import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { FinancingOption } from 'generated/graphql';
import { EQUAL_PAYMENT_ID } from 'Globals/globalConstants';
import { MoneyTextFormat } from '../QuoteCard/QuoteCard';

interface FinancingAnimatedWrapperProps {
    offset: number
}

export default function FinancingAnimatedWrapper({ offset, children }: React.PropsWithChildren<FinancingAnimatedWrapperProps>) {
    return (
        <motion.div
            initial={{ opacity: 0, x: "-100vw", width: "21vw" }}
            animate={{ opacity: 1, x: 0, transition: { duration: (.55) + offset * .15 } }}
            exit={{ opacity: 0, y: "-5em", transition: { duration: .2 } }}>

            {children}
        </motion.div>
    )
}

interface BaseFinancingCardProps {
    selectCard: () => void,
    clearSelection: () => void,
    isSelected: boolean,
    selectionPresent: boolean,
    price: number,
    loading: boolean
}

interface FinancingCardProps extends BaseFinancingCardProps {
    option: FinancingOption;
}

export function FinancingCard({ loading, option, selectCard, isSelected, selectionPresent, price, clearSelection }: FinancingCardProps) {
    const paymentPeriod = option.term + (option.typeId === EQUAL_PAYMENT_ID ? 1 : 0)
    const type = option.typeName ?? ""

    const payPerMonth = (price / option.term)

    const shadeDark = selectionPresent && !isSelected

    function cardClick() {
        if (loading) return;
        if (isSelected) {
            clearSelection();
        } else {
            selectCard();
        }
    }

    return (
        <FinancingBaseCard
            line1Text={`${paymentPeriod} Months`}
            line2Text={`${type} Payments`}
            line3Text={<><MoneyTextFormat value={payPerMonth} />/ month</>}
            shadeDark={shadeDark}
            onClick={cardClick} 
        />
    )
}

interface SalesTaxCardProps extends BaseFinancingCardProps { }

export function SalesTaxCard({ loading, selectCard, isSelected, selectionPresent, price, clearSelection }: SalesTaxCardProps) {
    const shadeDark = selectionPresent && !isSelected

    function cardClick() {
        if (loading) return;
        if (isSelected) {
            clearSelection();
        } else {
            selectCard();
        }
    }

    return (
        <FinancingBaseCard
            line1Text='We Pay Your'
            line2Text='Sales Tax'
            line3Text={<>Save <MoneyTextFormat value={price * .06} /></>}
            shadeDark={shadeDark}
            onClick={cardClick} />
    )
}

function FinancingBaseCard({ line1Text, line2Text, line3Text, shadeDark, onClick }: { line1Text: string, line2Text: string, line3Text: JSX.Element | string, shadeDark: boolean, onClick: () => void }) {

    return (
        <div className={clsx({ "ss-shade": shadeDark }, 'ss-gallery-item')}>
            <div
                className="flex-column fill-height fill-width"
                style={{ alignItems: "center", padding: ".5em 0" }}
                onClick={onClick}>
                <Typography
                    className={clsx("ss-inverted-fill-card", {'ss-shade-inverted': shadeDark})}
                    variant='h5'
                    style={{ textAlign: "center" }}>
                    {line1Text}
                </Typography>
                <Typography
                    className={clsx("ss-inverted-fill-card", {'ss-shade-inverted': shadeDark})}
                    variant='h5'
                    style={{ textAlign: "center" }}>
                    {line2Text}
                </Typography>
                <div className="flex-grow" />
                <Typography
                    className={clsx({'ss-shade-text': shadeDark})}
                    variant='h5'
                    style={{ textAlign: "center" }}>
                    {line3Text}
                </Typography>
            </div>
        </div>
    )
}