import {
    Button,
    IconButton,
    Menu,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import "App.css";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import NavbarPage from "Components/Page";
import { HBar } from "Components/pageSpacing";
import { emptyAddress, emptyCustomer } from "Globals/DataStructures/EmptyDataStructures";
import { buildAppendedId, idUrlMask, twoIdUrlMask } from "Globals/Hooks";
import {
    cartPath,
    installationDetailsPath,
    invoicePath,
    jobScopePath,
    printMeasurePath,
    projectsPath,
    quoteDrawingPath,
    quotePath,
    readOnlyPricingCenterPath,
    seamPlannerPath,
    sellSheetPath,
} from "Globals/PathStrings";
import {
    CustomerInput,
    namedOperations,
    useCreateJobWithNewCustomerMutation,
    useDeleteJobConfigurationMutation,
    useGetAllJobIdsQuery,
    useGetAllSahSalespeopleQuery,
    useGetJobSummaryDataQuery,
} from "generated/graphql";
import { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { ReadOnlyPricingCenterView } from "../CommandCenter/PricingCenter/PricingCenter";
import Cart from "./CartPage";
import GenericQuoteBuilderPage from "./GenericQuoteBuilder/GenericQuoteBuilderPage";
import Invoice from "./Invoice/InvoicePage";
import QuotePdfPage from "./Invoice/QuotePdfPage";
import JobScope from "./JobScope";
import SeamPlanner from "./SeamPlanner/Page/SeamPlanner";
import SellSheet from "./SellSheet/SellSheet";
import PrintableSeamPage from "./SeamPlanner/PrintableSeamPage";
import QuoteDrawingViewer from "./Dashboard/QuoteDrawingViewerPage";

function Projects() {
    const history = useHistory();
    const { data } = useGetAllJobIdsQuery();

    function changePage(path: string, configId: number) {
        history.push(path + buildAppendedId(configId));
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    return (
        <NavbarPage
            title={"Projects"}
            padContent
        >
            <div
                className="flex-column"
                style={{ width: "60rem", margin: "0 auto" }}
            >
                {data?.allJobIds.map((jobId) => {
                    return (
                        <JobRow
                            key={`job-row-${jobId}`}
                            jobId={jobId}
                            goToPath={changePage}
                        />
                    );
                })}
                <div className="fill-width flex-row-center padding-sm">
                    <Button
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        variant="contained"
                        color="secondary"
                    >
                        + Add New
                    </Button>
                </div>
            </div>
            <AddNewJobMenu
                anchor={anchorEl}
                closeMenu={() => setAnchorEl(null)}
            />
        </NavbarPage>
    );
}

function isCreateJobInvalid(customer: CustomerInput, salesperson?: number) {
    return (
        salesperson === undefined &&
        customer.firstName === "" &&
        customer.lastName === "" &&
        customer.primaryPhone === "" &&
        customer.primaryAddress.streetAddress === "" &&
        customer.primaryAddress.city === "" &&
        customer.primaryAddress.zip === "" &&
        customer.email === ""
    );
}

function AddNewJobMenu({
    anchor,
    closeMenu,
}: {
    anchor: HTMLElement | null;
    closeMenu: () => void;
}) {
    const { data } = useGetAllSahSalespeopleQuery();

    const [createJob] = useCreateJobWithNewCustomerMutation({
        refetchQueries: [namedOperations.Query.GetAllJobIds],
        awaitRefetchQueries: true,
    });

    const [showError, setShowError] = useState<boolean>(false);
    const [customerDetails, updateCustomerDetails] = useState<CustomerInput>(emptyCustomer);

    const [salesperson, setSalesperson] = useState<number | undefined>(undefined);

    function tryCreateJob() {
        if (isCreateJobInvalid(customerDetails, salesperson)) {
            setShowError(true);
        } else {
            createJob({ variables: { customer: customerDetails } });
            closeMenu();
        }
    }

    function closeAndClear() {
        closeMenu();
        updateCustomerDetails(emptyCustomer);
        setSalesperson(undefined);
    }

    var salesPeople = data?.sAHSalespeople ?? [];

    return (
        <Menu
            transformOrigin={{ horizontal: "center", vertical: "center" }}
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={closeMenu}
        >
            <div
                className="w-30r flex-column flex-centered"
                style={{ height: "33rem" }}
            >
                <Typography variant="h4">Create New Job</Typography>
                <HBar />
                <div className="fill-width padding-xsm">
                    <Typography variant="h5">Customer:</Typography>
                    <HBar thin />
                </div>
                <div className="flex-row fill-width">
                    <div className="flex-column flex-grow fill-height padding-xsm padding-top-none">
                        <TextField
                            onChange={(v) =>
                                updateCustomerDetails({
                                    ...customerDetails,
                                    firstName: v.currentTarget.value,
                                })
                            }
                            label="First Name"
                            value={customerDetails.firstName}
                            error={showError && customerDetails.firstName === ""}
                        />
                        <TextField
                            onChange={(v) =>
                                updateCustomerDetails({
                                    ...customerDetails,
                                    primaryPhone: v.currentTarget.value,
                                })
                            }
                            label="Primary Phone"
                            value={customerDetails.primaryPhone}
                            error={showError && customerDetails.primaryPhone === ""}
                        />
                    </div>
                    <div className="flex-column flex-grow fill-height padding-xsm padding-top-none">
                        <TextField
                            onChange={(v) =>
                                updateCustomerDetails({
                                    ...customerDetails,
                                    lastName: v.currentTarget.value,
                                })
                            }
                            label="Last Name"
                            value={customerDetails.lastName}
                            error={showError && customerDetails.lastName === ""}
                        />
                    </div>
                </div>
                <div className="flex-row fill-width">
                    <div className="flex-column flex-grow fill-height padding-xsm padding-top-none">
                        <TextField
                            onChange={(v) =>
                                updateCustomerDetails({
                                    ...customerDetails,
                                    primaryAddress: {
                                        ...customerDetails.primaryAddress,
                                        streetAddress: v.currentTarget.value,
                                    },
                                })
                            }
                            label="Primary Address"
                            value={customerDetails.primaryAddress}
                            error={showError && customerDetails.primaryAddress.streetAddress === ""}
                        />
                        <TextField
                            onChange={(v) =>
                                updateCustomerDetails({
                                    ...customerDetails,
                                    billingAddress: customerDetails.billingAddress
                                        ? {
                                              ...customerDetails.billingAddress,
                                              streetAddress: v.target.value,
                                          }
                                        : { ...emptyAddress, streetAddress: v.target.value },
                                })
                            }
                            label="Delivery Address"
                            value={customerDetails.billingAddress}
                        />
                    </div>
                    <div className="flex-column flex-grow fill-height padding-xsm padding-top-none">
                        <TextField
                            onChange={(v) =>
                                updateCustomerDetails({
                                    ...customerDetails,
                                    primaryAddress: {
                                        ...customerDetails.primaryAddress,
                                        city: v.target.value,
                                    },
                                })
                            }
                            label="City"
                            value={customerDetails.primaryAddress.city}
                            error={showError && customerDetails.primaryAddress.city === ""}
                        />
                        <TextField
                            onChange={(v) =>
                                updateCustomerDetails({
                                    ...customerDetails,
                                    billingAddress: customerDetails.billingAddress
                                        ? {
                                              ...customerDetails.billingAddress,
                                              city: v.target.value,
                                          }
                                        : { ...emptyAddress, city: v.target.value },
                                })
                            }
                            label="City"
                            value={customerDetails.billingAddress?.city ?? ""}
                        />
                    </div>
                    <div className="flex-column w-6r fill-height padding-xsm padding-top-none">
                        <TextField
                            onChange={(v) =>
                                updateCustomerDetails({
                                    ...customerDetails,
                                    primaryAddress: {
                                        ...customerDetails.primaryAddress,
                                        zip: v.currentTarget.value,
                                    },
                                })
                            }
                            label="Zip"
                            value={customerDetails.primaryAddress.zip}
                            error={showError && customerDetails.primaryAddress.zip === ""}
                        />
                        <TextField
                            onChange={(v) =>
                                updateCustomerDetails({
                                    ...customerDetails,
                                    billingAddress: customerDetails.billingAddress
                                        ? {
                                              ...customerDetails.billingAddress,
                                              zip: v.currentTarget.value,
                                          }
                                        : { ...emptyAddress, zip: v.currentTarget.value },
                                })
                            }
                            label="Zip"
                            value={customerDetails.billingAddress?.zip ?? ""}
                        />
                    </div>
                </div>
                <div className="flex-row fill-width">
                    <div className="flex-column flex-grow fill-height padding-xsm padding-top-none">
                        <TextField
                            onChange={(v) =>
                                updateCustomerDetails({
                                    ...customerDetails,
                                    email: v.currentTarget.value,
                                })
                            }
                            label="Email"
                            value={customerDetails.email}
                            error={showError && customerDetails.email === ""}
                        />
                    </div>
                </div>
                <div className="fill-width padding-xsm">
                    <Typography variant="h5">Salesperson:</Typography>
                    <HBar thin />
                </div>
                <Select
                    error={showError && salesperson === undefined}
                    className="w-15r"
                    value={salesperson ?? ""}
                    onChange={(e) => setSalesperson(e.target.value as number)}
                >
                    {salesPeople.map((person) => {
                        return (
                            <MenuItem
                                value={person.id}
                                key={`sah-person-${person.id}`}
                            >
                                {`${person.lastName}, ${person.firstName}`}
                            </MenuItem>
                        );
                    })}
                </Select>
                <Button
                    onClick={tryCreateJob}
                    variant="contained"
                    className="margin-xsm"
                >
                    Create Job
                </Button>
                <Button
                    onClick={closeAndClear}
                    variant="outlined"
                    className="margin-xsm"
                >
                    Close
                </Button>
            </div>
        </Menu>
    );
}

interface JobRowProps {
    jobId: number;
    goToPath: (path: string, quoteId: number) => void;
}

function JobRow({ jobId, goToPath }: JobRowProps) {
    const { data, refetch } = useGetJobSummaryDataQuery({ variables: { jobId } });
    const [deleteQuote] = useDeleteJobConfigurationMutation({
        refetchQueries: [namedOperations.Query.GetAllJobIds],
        onCompleted(remainingConfigs) {
            if (remainingConfigs.deleteJobConfiguration > 0) refetch();
        },
        awaitRefetchQueries: true,
    });

    const [selectedConfig, setSelectedConfig] = useState<number | undefined>(undefined);

    if (data?.jobSummaryData === undefined || data.jobSummaryData.configIds.length === 0)
        return <></>;

    const {
        partialJob: { customer },
        configIds,
    } = data.jobSummaryData;

    function onPathButton(path: string) {
        goToPath(path, selectedConfig ?? configIds[0].id);
    }

    function deleteSelectedQuote() {
        deleteQuote({ variables: { jobConfigurationId: selectedConfig ?? configIds[0].id } });
        setSelectedConfig(undefined);
    }

    return (
        <div
            className="flex-row fill-width h-5r"
            style={{ alignItems: "center", border: "gray 2px solid", borderRadius: "5px" }}
        >
            <div className="w-15r flex-column">
                <Typography variant="h5">Customer:</Typography>
                <Typography variant="h5">{`${customer.lastName}, ${customer.firstName}`}</Typography>
            </div>
            <div className="flex-column">
                <Typography variant="h5">Quote:</Typography>
                <Select
                    className="w-10r"
                    value={selectedConfig ?? configIds[0].id}
                    onChange={(e) => setSelectedConfig(e.target.value as number)}
                >
                    {configIds.map((configId, index) => {
                        return (
                            <MenuItem
                                value={configId.id}
                                key={`quote-option-${index}`}
                            >
                                {`(${configId.labels.join(", ")})`}
                            </MenuItem>
                        );
                    })}
                </Select>
            </div>
            <div className="w-1r" />
            <SpacedButton
                onClick={() => onPathButton(installationDetailsPath)}
                variant="contained"
            >
                Open Current Job
            </SpacedButton>
            <SpacedButton
                onClick={() => onPathButton(sellSheetPath)}
                variant="contained"
            >
                Sell Sheet
            </SpacedButton>
            <SpacedButton
                onClick={() => onPathButton(cartPath)}
                variant="contained"
            >
                Cart
            </SpacedButton>
            <SpacedButton
                onClick={() => onPathButton(invoicePath)}
                variant="contained"
            >
                Invoice
            </SpacedButton>
            <IconButton
                disabled
                onClick={deleteSelectedQuote}
            >
                <DeleteIcon />
            </IconButton>
        </div>
    );
}

export default function ProjectsPage() {
    return (
        <Switch>
            <Route path={sellSheetPath + idUrlMask}>
                <SellSheet />
            </Route>
            <Route path={jobScopePath + idUrlMask}>
                <JobScope />
            </Route>
            <Route path={cartPath + idUrlMask}>
                <Cart />
            </Route>
            <Route path={quoteDrawingPath + idUrlMask}>
                <QuoteDrawingViewer />
            </Route>
            <Route path={quotePath + twoIdUrlMask}>
                <QuotePdfPage />
            </Route>
            <Route path={invoicePath + idUrlMask}>
                <Invoice />
            </Route>
            <Route path={installationDetailsPath + idUrlMask}>
                <GenericQuoteBuilderPage />
            </Route>
            <Route path={seamPlannerPath + idUrlMask}>
                <SeamPlanner />
            </Route>
            <Route path={readOnlyPricingCenterPath + idUrlMask}>
                <ReadOnlyPricingCenterView />
            </Route>
            <Route path={printMeasurePath + idUrlMask}>
                <PrintableSeamPage />
            </Route>
            <Route path={projectsPath + idUrlMask}>
                <Projects />
            </Route>
        </Switch>
    );
}
