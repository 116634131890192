import { useGetPartialProductStyleQuery } from "generated/graphql";
import ProductCostsStyleRow from "../GenericTable/ProductCostsStyleRow";

export default function HardCostStyleRow({ styleId }: { styleId: number }) {
    const { data, loading } = useGetPartialProductStyleQuery({ variables: { styleId } })
    if (loading || !(data?.partialProductStyle)) return <tr />

    const {
        style,
        singleCostPerSqft: loadedSingleCost,
        bulkCostPerSqft: loadedBulkCost,
    } = data.partialProductStyle

    return <ProductCostsStyleRow
        styleId={styleId}
        styleName={style}
        loadedBulkCost={loadedBulkCost}
        loadedSingleCost={loadedSingleCost} />
}

