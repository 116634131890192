import { Button } from "@material-ui/core";
import clsx from "clsx";

interface QuoteCardIconButtonProps extends React.ComponentProps<typeof Button> {
    hideButton?: boolean
}

export default function QuoteCardIconButton(props: QuoteCardIconButtonProps) {
    const { style, hideButton, className, ...rest } = props

    return (
        <Button {...rest} style={{ margin: "0 .25em", marginBottom: ".25em", minWidth: "0", ...style }}  className={clsx({ 'visibility-hidden': hideButton }, className)}  />
    )
}