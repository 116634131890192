import { Button, Box, Dialog, Drawer } from "@material-ui/core";
import Navbar from "../../../Components/Navbar/Navbar";
import { InventoryViewRow, InventoryViewer } from "../../../Components/InventoryViewer";
import TransactionViewer from "../../../Components/TransactionViewer";
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'
import "react-reflex/styles.css"
import { CarpetDrawer } from "Components/CarpetDrawer/CarpetDrawer";
import { addTransaction, clearTransactions, updateTransaction } from "Redux/inventoryReducer";
import { useAppDispatch } from "Redux/hooks";
import { useContextMenu } from "Components/ContextMenu";
import React, { useRef, useState } from "react";
import { createLineItem, LineItem } from "Globals/DataStructures/Transactions";
import { LineItemEditor } from "Components/TransactionLineItemEditor";
import { useGlobalState } from "Globals/GlobalState";
import { ItemDetailsView } from "Components/ItemDetailsView";
import Loading from "Components/Loading";
import { InventoryEntry } from "generated/graphql";

const InventoryManagement = () => {
  const dispatch = useAppDispatch()

  const [openLineItemEditor, setOpenLineItemEditor] = useState(false);
  const [activeLineItem, setActiveLineItem] = useState<LineItem | null>(null);
  const [debugSettings] = useGlobalState('debugSettings');
  const [itemDetailsDialogOpen, setItemDetailsDialogOpen] = useState(false);
  const [carpetDrawerOpen, setCarpetDrawerOpen] = useState(false);
  const [itemForDetailsDialogue, setItemForDetailsDialogue] = useState<InventoryEntry | undefined>(undefined);
  const [activeCarpetSku, setActiveCarpetSku] = useState<string | undefined>(undefined);

  let hoveredRow = useRef<InventoryViewRow | null>(null);

  function editLineItem(lineItem: LineItem) {
    setActiveLineItem(lineItem);
    setOpenLineItemEditor(true);
  }

  function selectInventoryRow(row: InventoryViewRow) {
    const entry = row as InventoryEntry;

    editLineItem(createLineItem('None', entry));
  }

  function handleInventoryViewHotkey(e: React.KeyboardEvent<HTMLDivElement>) {
    // if (hoveredRow.current) {
    //   switch (e.key) {
    //     case 'h':
    //       editLineItem(createLineItem('Hold', hoveredRow.current))
    //       break;
    //     case 'p':
    //       editLineItem(createLineItem('Picked', hoveredRow.current))
    //       break;
    //     case 'd':
    //       if (debugSettings.isUpperManagement) {
    //         editLineItem(createLineItem('Drop', hoveredRow.current))
    //       }
    //       break;
    //   }
    // }
  }

  function openCarpetDrawer(sku: string){
    setActiveCarpetSku(sku);
    setCarpetDrawerOpen(true);
  }

  const rightClickMenu = useContextMenu<InventoryViewRow>(
    'inventory-viewer-context-menu',
    [
      { label: <p><b><u>H</u></b>old</p>, onClick: (x) => { editLineItem(createLineItem('Hold', x.props!)) } },
      { label: <p><b><u>P</u></b>icked</p>, onClick: (x) => { editLineItem(createLineItem('Picked', x.props!)) } },
      { label: <p><b><u>D</u></b>rop</p>, onClick: (x) => { editLineItem(createLineItem('Drop', x.props!)) }, showCondition: (row) => debugSettings.isUpperManagement },
      { label: 'Show Details', onClick: (x) => { setItemDetailsDialogOpen(true); setItemForDetailsDialogue(x.props!) }},
      { label: "View Rolls", onClick: (x) => openCarpetDrawer(x.props!.id), showCondition: (row) => row.productType === "CARPET" },
    ]);

  return (
    <div style={{ display: 'flex', flexDirection: "column", height: "100vh", overflow: 'hidden' }}>
      <Navbar />
      <div style={{ display: 'flex', flexDirection: "column", flex: 1, margin: 10 }}>
        <ReflexContainer orientation="horizontal">
          <ReflexElement className="top-pane" minSize={(window).screen.height * .2} >
            <div onKeyPress={handleInventoryViewHotkey} className="pane-content" style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", flexFlow: "column", flexWrap: "nowrap", justifyContent: "flex-start" }}>
              <h2>Inventory</h2>
              <InventoryViewer
                onHoveredElementChanged={(x) => hoveredRow.current = x ?? null}
                key='inventory-viewer'
                onSelectRow={x => selectInventoryRow(x.row)}
                rightClickMenu={rightClickMenu}
                columns={['SKU', 'Type', 'Style', 'Color', 'Actual', 'Available', 'Held', 'Potential']} />
            </div>
          </ReflexElement>
          <ReflexSplitter propagate={true} />
          <ReflexElement className="bottom-pane" size={(window).screen.height * .2} >
            <div className="pane-content" style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", flexFlow: "column", flexWrap: "nowrap", justifyContent: "flex-start" }}>
              <Box style={{ margin: "2px 0" }}>
                <h2 style={{ float: "left", margin: "auto 0" }}>Transactions</h2>
                <Button color="primary" variant="contained" style={{ float: "right", margin: "auto 0" }} onClick={() => dispatch(clearTransactions())}>Submit</Button>
              </Box>
              <TransactionViewer onDoubleClickRow={(x) => { editLineItem(x.row.lineItem) }} />
            </div>
          </ReflexElement>
        </ReflexContainer>
      </div>
      <Drawer open={carpetDrawerOpen} onClose={() => setCarpetDrawerOpen(false)} anchor='right'>
        { activeCarpetSku && <CarpetDrawer sku={activeCarpetSku} onClose={() => {setCarpetDrawerOpen(false)}} />}
      </Drawer>
      <LineItemEditor
        open={openLineItemEditor}
        setOpen={setOpenLineItemEditor}
        inputLineItem={activeLineItem}
        onSubmit={(x) => {
          console.log(x);
          if (x.lineItemNumber) {
            dispatch(updateTransaction(x))
          }
          else {
            dispatch(addTransaction(x))
          }
        }}
      />
      <Dialog open={itemDetailsDialogOpen} onClose={() => setItemDetailsDialogOpen(false)} fullWidth maxWidth='md' scroll='body'>
        <div style={{ minHeight: 'max(50vh, 15rem)', margin: '1rem'}}>
          { itemForDetailsDialogue ? <ItemDetailsView entry={itemForDetailsDialogue} /> : <Loading/>}
        </div>
      </Dialog>
    </div>
  )
}

export default InventoryManagement
