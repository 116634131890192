import { Room } from "generated/graphql";
import { CARPET_PRODUCT_ID, INSTALLATION_ID, NEW_WALL_FINISH_ID, PRIMED_SHOE_MOLDING_MATERIAL_CATEGORY_ID, SHOE_MOLDING_ID } from "Globals/globalConstants";

export function updateShoeInRoomsForProductTypeChange(currentTypeId: number | undefined, newId: number, currentRooms: Room[], originalRooms: Room[]): Room[] {
    if (currentTypeId === CARPET_PRODUCT_ID && newId !== CARPET_PRODUCT_ID) {
        // Setting the product type to hard surface. Job needs to have Shoe Molding
        return addShoeToRooms(currentRooms, originalRooms)
    }
    else if (currentTypeId !== CARPET_PRODUCT_ID && newId === CARPET_PRODUCT_ID) {
        // Job was hard surface but isn't anymore, so shoe molding needs to be removed.
        return removeShoeFromRooms(currentRooms)
    }
    else return currentRooms
}

function addShoeToRooms(currentRooms: Room[], originalRooms: Room[]): Room[] {
    return currentRooms.map(room => {
        // If room already has shoe molding, then no need to add another service
        if (room.services.some(ser => ser.id === SHOE_MOLDING_ID))
            return room;

        // Try locating a service in the room before modification where it was for shoe molding
        // This would really only come up if the room previously was scheduled to have wood installed and it was switched away and now back
        const originalRoom = room.id !== -1 ? originalRooms.find((v => v.id === room.id)) : undefined;
        const oldMoldingId = originalRoom?.services.find(ser => ser.jobServiceId === SHOE_MOLDING_ID)?.id ?? -1;

        // Add shoe molding service. 
        // TODO: change out hardcoded constants for a call to the database
        return {
            ...room, services: [...room.services, {
                id: oldMoldingId,
                roomId: room.id,
                jobServiceId: SHOE_MOLDING_ID,
                serviceTypeId: NEW_WALL_FINISH_ID,
                laborAmount: room.lnft,
                customerDoesService: false,
                isActive: true,
                materialCategoryId: PRIMED_SHOE_MOLDING_MATERIAL_CATEGORY_ID,
                materialAmount: room.lnft
            }]
        };
    })
}

function removeShoeFromRooms(currentRooms: Room[]) {
    return currentRooms.map(room => (
        {
            ...room,
            services: room.services.filter(ser => ser.jobServiceId !== SHOE_MOLDING_ID)
        })
    );
}

export function updateInstallationService(rooms: Room[], installServiceId: number, materialId: number | undefined) : Room[] {
    return rooms.map(room => {
        // Installation service for the rooms need to be updated to whatever the default installation method for the style.
        const updatedServices = room.services.map(ser => {
            if (ser.serviceTypeId === INSTALLATION_ID) {
                // Found the job service for the old installation method. Update to use the new id
                return { ...ser, jobServiceId: installServiceId, materialCategoryId: materialId, materialAmount: materialId !== undefined ? ser.laborAmount : undefined };
            }

            else
                return ser;
        });

        return { ...room, services: updatedServices };
    });
}

