import { DoubleClickHandler } from "Globals/Helpers";
import { CarpetCutInventoryRowInput } from "./CarpetCutInventoryView";
import { CarpetRollInventoryViewRowInput } from "./CarpetRollInventoryView";

export enum TabState {
    Rolls = 0,
    Cuts = 1
}

// let cutToRow = function (cut: RollCut): CarpetCutInventoryRowInput {
//     let roll = cut.rollID;
//     let sku = cut.sku;
//     return {
//         ...cut, sku: sku, rollNumber: roll!
//     }
// }

export interface Props {
    sku: string | null
    onClose?: () => void,
    onDoubleClickCarpetRoll?: DoubleClickHandler<CarpetRollInventoryViewRowInput>
    onDoubleClickCarpetCut?: DoubleClickHandler<CarpetCutInventoryRowInput>
}

export function CarpetDrawer({ sku, onClose, onDoubleClickCarpetRoll, onDoubleClickCarpetCut }: Props) {

    return <></>
    // const [tab, setTab, bindTab] = useAutoBindTabs<TabState>(TabState.Rolls);
    // const [activeEntry, setActiveEntry] = useState<InventoryEntry | null>(null);
    // const [search, setSearch] = useState('');
    // const [rollRows, setRollRows] = useState<CarpetRollInventoryViewRowInput[]>([]);
    // const [cutRows, setCutRows] = useState<CarpetCutInventoryRowInput[]>([]);

    // //const getDummyInventory = useAuthenticatedQuery(useGetDummyInventoryQuery);

    // // useEffect(() => {
    // //     let cancelled = false;

    // //     setActiveEntry(null);
    // //     setTab(TabState.Rolls);

    // //     getDummyInventory.refetch({ filter: { sku: { eq: sku } } }).then((x) => {
    // //         if (!cancelled) {
    // //             if (x.data.dummyInventory) {
    // //                 const entry = x.data.dummyInventory[0];
    // //                 if (entry) {
    // //                     setActiveEntry(entry);

    // //                     let rollEntries = [] as RollEntry[];
    // //                     let rollRows = [] as CarpetRollInventoryViewRowInput[];

    // //                     if (entry.rolls) {
    // //                         rollEntries = entry.rolls as RollEntry[];
    // //                         rollRows = rollEntries.map(entryToRow);
    // //                     }

    // //                     let cutRows = [] as CarpetCutInventoryRowInput[];

    // //                     for (let roll of rollEntries) {
    // //                         let dbCuts = roll.cuts as RollCut[]
    // //                         let myCuts = dbCuts.map(cutToRow);
    // //                         cutRows = [...cutRows, ...myCuts];
    // //                     }

    // //                     setRollRows(rollRows);
    // //                     setCutRows(cutRows);
    // //                 }
    // //             }
    // //         }
    // //     });

    // //     return () => { cancelled = true }

    // // }, [sku]);

    // let entryToRow = function (roll: RollEntry): CarpetRollInventoryViewRowInput {
    //     return {
    //         ...roll,
    //         id: roll.sku + '#' + roll.rollNumber
    //     };
    // }

    // let showCuts = function (x: RollEntry) {
    //     setTab(TabState.Cuts);
    //     if (x.rollNumber) {
    //         setSearch(x.rollNumber);
    //     }
    // }

    // const matches = useMediaQuery('(max-width:40em)');
    // const fullscreen = matches.valueOf();

    // return (
    //     <div style={{ width: fullscreen ? '100vw' : 'max(33vw, 35em)', height: '100%', display: 'flex', flexDirection: 'column' }}>
    //         {activeEntry === null && <Loading />}
    //         {activeEntry !== null && <>
    //             <div style={{ padding: '2vmin', display: 'flex', flexDirection: 'column' }}>
    //                 <h1>Carpet Rolls</h1>
    //                 {activeEntry && <h3>{activeEntry.sku}: {activeEntry.style} ({activeEntry.color})</h3>}
    //             </div>
    //             <Tabs variant='fullWidth' value={tab}>
    //                 <Tab label='Rolls' {...bindTab(TabState.Rolls)} />
    //                 <Tab label='Cuts' {...bindTab(TabState.Cuts)} />
    //                 {fullscreen && <Tab label='close' onClick={(e: any) => { onClose?.() }} />}
    //             </Tabs>
    //             <div style={{ padding: '2vmin', display: 'flex', flexDirection: 'column', flex: 1 }}>

    //                 {tab === TabState.Rolls
    //                     && <CarpetInventoryView rows={rollRows} inventoryEntry={activeEntry} onRowClick={onDoubleClickCarpetRoll} onViewCuts={(x) => showCuts(x)} />}
    //                 {tab === TabState.Cuts
    //                     && <CarpetCutInventoryView search={search} onRowClick={onDoubleClickCarpetCut} setSearch={setSearch} rows={cutRows} />}
    //             </div>
    //         </>}
    //     </div>
    // );
}
