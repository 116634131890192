import { CARPET_PRODUCT_ID } from "Globals/globalConstants";
import { CostInventoryModeSelect } from "../Editors/CostInventoryModeSelect";
import FilterVendorEditor from "../Editors/FilterVendorEditor";
import SubmitOrClearButton from "../Editors/SubmitOrClearButton";
import ProductCostsTable from "../GenericTable/ProductCostsTable";
import CarpetUnitSelect from "./CarpetUnitSelect";


export default function CarpetMode({ height }: { height: string }) {


    return (
        <div className="flex-column-center fill-width" style={{ alignItems: "center" }}>
            <div className='flex-row padding-bottom-xsm fill-width'>
                <CostInventoryModeSelect />
                <FilterVendorEditor />
                <CarpetUnitSelect />
                <div className="flex-grow" />
                <SubmitOrClearButton />
                {/* <UploadCostsEditor productTypeId={4} /> */}
            </div>
            <ProductCostsTable productTypeId={CARPET_PRODUCT_ID} height={height} />
        </div>
    )
}
