import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { COLUMN_WIDTH } from "../ProjectCoordinatorPage";
import './project-coordinator-styles.css';

type ProjectCoordinatorColumnProps = React.PropsWithChildren<{
    title: string;
    headerId: string;
    headerDecoration?: ReactJSXElement;
}>;

export default function ProjectCoordinatorColumn({ title, children, headerId, headerDecoration }: ProjectCoordinatorColumnProps) {
    return (
        <div className="pc-column" style={{ width: COLUMN_WIDTH }}>
            <div id={headerId} className="pc-column-header-base">
                {/* grid placeholder */}
                <div className="fill-width" />

                <div className="fill-width flex-row justify-content-center align-items-center">
                    <p className="flat-font-md margin-none">{title}</p>
                </div>

                <div className="flex-row justify-content-center">
                    {headerDecoration}
                </div>
                
            </div>
            {children}
        </div>
    );
}