import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Tooltip } from "@material-ui/core";
import S3QuotePdfDialogWrapper from "Components/Files/S3QuotePdfDialogWrapper";
import FlatDocumentButton from "FlatComponents/Button/FlatDocumentButton";
import FlatDrawingButton from "FlatComponents/Button/FlatDrawingButton";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { quoteDrawingPath } from "Globals/PathStrings";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
    selectCcWorkerId,
    selectChatId,
    selectCommissionRates,
    selectContractConfigurationId,
    selectFinancingOption,
    selectJobConfigurationId,
    selectJobCost,
    selectOverride,
    selectPreOverridePrice,
    selectPriceRequest,
    selectQuoteConfigToQuoteIdMap,
    selectRecoveryOverride,
    selectTotalPrice,
    selectUsageContext,
    setCcActualCommissionRate,
    setCcStandardCommissionRate
} from "Redux/pricingCenterReducer";
import { calculateFinancingAdjustedRevenue, calculateMargin } from "../marginUtils";
import { getCommissionRate } from "../PricingSummary";
import ExistingOverrideViewer from "./ExistingOverrideViewer";
import FinalFinalPriceEditor from "./FinalFinalPriceEditor";
import NewOverrideEditor from "./NewOverrideEditor";

export default function FinalPriceSection() {
    const dispatch = useAppDispatch();
    const chatId = useAppSelector(selectChatId);
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const ccWorkerId = useAppSelector(selectCcWorkerId);
    const totalPrice = useAppSelector(selectTotalPrice);
    const jobCost = useAppSelector(selectJobCost);
    const overrideRequest = useAppSelector(selectPriceRequest);
    const override = useAppSelector(selectOverride);
    const commissionRates = useAppSelector(selectCommissionRates);
    const financingOption = useAppSelector(selectFinancingOption);
    const determineDefaultFinalPrice = useCallback(() => {
        if (override) {
            return override.amount;
        } else if (overrideRequest) {
            return overrideRequest.amount;
        } else return totalPrice;
    }, [override, overrideRequest, totalPrice]);

    const [openedQuoteId, setOpenedQuoteId] = useState<number | undefined>(undefined);
    // close the quote viewer when a different tab is selected
    useEffect(() => {
        setOpenedQuoteId(undefined);
    }, [jobConfigurationId]);

    const [finalPrice, setFinalPrice] = useState<number | undefined>(determineDefaultFinalPrice());
    useEffect(() => {
        if (jobConfigurationId > 1) {
            // useless check, just needed a way to trigger recalculation of this when changing configs
            setFinalPrice(determineDefaultFinalPrice());
        }
    }, [determineDefaultFinalPrice, jobConfigurationId]);

    useEffect(() => {
        if (overrideRequest) {
            setFinalPrice(overrideRequest!.amount);
            const financingAdjustedRevenue = calculateFinancingAdjustedRevenue(
                overrideRequest.amount,
                financingOption?.fee ?? 0
            );
            let commissionRate = getCommissionRate(
                commissionRates,
                calculateMargin(financingAdjustedRevenue, jobCost) / 100
            );
            dispatch(setCcStandardCommissionRate(commissionRate));
            dispatch(setCcActualCommissionRate(commissionRate));
        }
    }, [overrideRequest, commissionRates, jobCost, financingOption?.fee, dispatch]);

    const usageContext = useAppSelector(selectUsageContext);
    const contractConfigId = useAppSelector(selectContractConfigurationId);
    const thisConfigSold = contractConfigId === jobConfigurationId;
    const quoteConfigToQuoteIdMap = useAppSelector(selectQuoteConfigToQuoteIdMap);
    const thisConfigQuoted = !thisConfigSold && Object.keys(quoteConfigToQuoteIdMap).includes(jobConfigurationId.toString());
    const recoveryOverride = useAppSelector(selectRecoveryOverride);
    const showFinalFinal = (usageContext === "rc") || isNotNullOrUndefined(recoveryOverride);
    // recovery can't set the final price, only final final
    const disableInteraction = (usageContext === "rc") || isNotNullOrUndefined(contractConfigId) || thisConfigQuoted;  // disables the final price section


    // when an override is applied, default the final price to the override amount
    useEffect(() => {
        if (override) {
            setFinalPrice(override!.amount);
            const financingAdjustedRevenue = calculateFinancingAdjustedRevenue(
                override.amount,
                financingOption?.fee ?? 0
            );
            let commissionRate = getCommissionRate(
                commissionRates,
                calculateMargin(financingAdjustedRevenue, jobCost) / 100
            );
            dispatch(setCcStandardCommissionRate(commissionRate));
            dispatch(setCcActualCommissionRate(commissionRate));
        }
    }, [override, commissionRates, jobCost, financingOption?.fee, dispatch]);

    // determines what, if anything should be show for price overrides
    const overrideBody: ReactJSXElement = useMemo(() => {
        if (isNotNullOrUndefined(override)) {
            // if there's been an override, any CC worker can edit it
            return (
                <ExistingOverrideViewer
                    chatId={chatId}
                    overrideAmount={finalPrice}
                    setOverrideAmount={setFinalPrice}
                    disabled={disableInteraction}
                />
            );
        } else if (isNotNullOrUndefined(overrideRequest)) {
            // there is an override request
            if (isNotNullOrUndefined(overrideRequest?.commandCenterWorkerId)) {
                // the override request has been claimed
                return (
                    <>
                        {ccWorkerId === overrideRequest!.commandCenterWorkerId ? ( // this is the worker that has claimed the request
                            <NewOverrideEditor
                                chatId={chatId}
                                overrideAmount={finalPrice}
                                setOverrideAmount={setFinalPrice}
                                disabled={disableInteraction}
                            />
                        ) : (
                            <div className="flat-font-sm">
                                Another command center worker is handling the override
                            </div>
                        )}
                    </>
                );
            } else {
                // no one has claimed the request
                return <div className="flat-font-sm">Claim the price request to send override.</div>;
            }
        } else {
            // no request or override exist
            return (
                <NewOverrideEditor
                    chatId={chatId}
                    overrideAmount={finalPrice}
                    setOverrideAmount={setFinalPrice}
                    forLowestPriceClaim
                    disabled={disableInteraction}
                />
            );
        }
    }, [ccWorkerId, overrideRequest, override, finalPrice, chatId, disableInteraction]);

    const preOverridePrice = useAppSelector(selectPreOverridePrice);

    return (
        <>
            {commissionRates.length > 0 && preOverridePrice > 1 && totalPrice > 1 && (
                <>
                    <div className="flat-outer-container-padding flex-column flat-font">
                        {thisConfigSold && <p className="flat-font-lg" style={{color: "var(--flat-red)"}}>SOLD</p>}

                        {thisConfigQuoted && (
                            <span className="flex-row flex-gap-xsm">
                                <p className="flat-font-lg margin-none" style={{color: "var(--flat-blue)"}}>QUOTE</p>
                                <Tooltip title={"View Quote"}>
                                    <FlatDocumentButton
                                        color="var(--flat-blue)"
                                        onClick={() => setOpenedQuoteId(quoteConfigToQuoteIdMap[jobConfigurationId])}
                                    />
                                </Tooltip>
                                
                                <Tooltip title={"View Drawing"}>
                                    <FlatDrawingButton
                                        color="var(--flat-blue)"
                                        onClick={() => window.open(`${quoteDrawingPath}/${quoteConfigToQuoteIdMap[jobConfigurationId]}`)}
                                    />
                                </Tooltip>
                            </span>
                        )}
                        
                        <div className="flat-font flat-font-bold flat-font-upper">
                            Final Price
                        </div>

                        <div className="flex-row flex-gap-sm align-items-flex-end" style={{marginBottom:"1rem"}}>
                            {overrideBody}
                        </div>

                        {showFinalFinal && <FinalFinalPriceEditor/>}
                    </div>
                    
                    {openedQuoteId && (
                        <S3QuotePdfDialogWrapper
                            quoteId={openedQuoteId}
                            onClose={() => setOpenedQuoteId(undefined)}
                        />
                    )}
                </>
            )}
        </>
    );
}
