import { IconButton } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatDeleteButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatDeleteIcon color={color}/>
        </IconButton>
    )
}

export function FlatDeleteIcon({color="black"} : {color?: string}) {
    return <DeleteIcon htmlColor={color}/>
}