import { Button, withStyles } from "@material-ui/core";
import { ComponentProps } from "react";

const paddingSide = "14px";
const paddingTop = "4.5px";

const paddingSideSM = "7px";
const paddingTopSM = "2px";

const styles: (theme: any) => any = theme => ({
    root: {
        borderRadius: "var(--flat-inner-container-radius-sm)"
    },
    outlined: {
        border: "2px solid",
        "&:hover": {
            border: "2px solid",
        },
        "&:disabled": {
            border: "2px solid",
            paddingLeft: paddingSide,
            paddingRight: paddingSide,
            paddingTop: paddingTop,
            paddingBottom: paddingTop,
        },
        paddingLeft: paddingSide,
        paddingRight: paddingSide,
        paddingTop: paddingTop,
        paddingBottom: paddingTop,
    },
    outlinedPrimary: {
        border: "2px solid",
        "&:hover": {
            border: "2px solid",
            paddingLeft: paddingSide,
            paddingRight: paddingSide,
            paddingTop: paddingTop,
            paddingBottom: paddingTop,
        },
        "&:disabled": {
            border: "2px solid",
            paddingLeft: paddingSide,
            paddingRight: paddingSide,
            paddingTop: paddingTop,
            paddingBottom: paddingTop,
        },
        paddingLeft: paddingSide,
        paddingRight: paddingSide,
        paddingTop: paddingTop,
        paddingBottom: paddingTop,
    },
    outlinedSecondary: {
        border: "2px solid",
        "&:hover": {
            border: "2px solid",
        },
        "&:disabled": {
            border: "2px solid",
            paddingLeft: paddingSide,
            paddingRight: paddingSide,
            paddingTop: paddingTop,
            paddingBottom: paddingTop,
        },
        paddingLeft: paddingSide,
        paddingRight: paddingSide,
        paddingTop: paddingTop,
        paddingBottom: paddingTop,
    },
    outlinedSizeSmall: {
        border: "2px solid",
        "&:hover": {
            border: "2px solid",
        },
        "&:disabled": {
            border: "2px solid",
            paddingLeft: paddingSideSM,
            paddingRight: paddingSideSM,
            paddingTop: paddingTopSM,
            paddingBottom: paddingTopSM,
        },
        paddingLeft: paddingSideSM,
        paddingRight: paddingSideSM,
        paddingTop: paddingTopSM,
        paddingBottom: paddingTopSM,
    },
  });

export type FlatButtonProps = ComponentProps<typeof Button>;
function FlatButton({ variant, ...other }: FlatButtonProps) {
    const useVariant = variant ?? "contained";

    return (
        <Button
            {...other}
            variant={useVariant}
        />
    );
}

export default withStyles(styles)(FlatButton);