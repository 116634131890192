import { Button, ButtonProps, styled } from "@material-ui/core";
import PriceRequestResponseBox from "FlatComponents/Chat/ActionComponents/PriceRequestResponseBox";
import Chat from "FlatComponents/Chat/Chat";
import Loading from "Components/Loading";
import { NotAuthorized } from "Components/RequireAuth";
import { FlatCustomerHeader } from "FlatComponents/Layout/FlatCustomerHeader";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";
import {
    Customer,
    GetChatForJobQuery,
    GetPricingCenterTabsQuery,
    JobChatCommandCenterMemberChangedDocument,
    JobChatCommandCenterMemberChangedSubscription,
    JobConfigurationExistenceChangedDocument,
    JobConfigurationExistenceChangedSubscription,
    JobConfigurationIdAndOptionNumber,
    JobQuotedDocument,
    JobQuotedSubscription,
    JobSoldDocument,
    JobSoldSubscription,
    namedOperations,
    useGetAuthenticatedCommandCenterWorkerIdQuery,
    useGetAuthenticatedWorkerPermissionsQuery,
    useGetAuthenticatedWorkerQuery,
    useGetChatForJobQuery,
    useGetJobInvoiceDataQuery,
    useGetJobIsFailQuery,
    useGetPricingCenterTabsQuery,
    useGetRecoveryCenterOverrideQuery,
    useUpdateCustomerDetailsMutation,
    useUpdateJobInRecoveryMutation
} from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import UpdateCustomerDetailDialog from "Pages/Admin/ProjectManagement/Invoice/UpdateCustomerDetailDialog";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectJobConfigurationId, setCcWorkerId, setChatId, setContractConfigurationId, setJobConfigurationId, setQuoteConfigToQuoteIds, setRecoveryOverride, setUsageContext } from "Redux/pricingCenterReducer";
import { FlatJobDetailsSection } from "./ConfigurationTab";
import { prepareCustomer } from "Globals/dataPreparationUtils";

export function CommandCenterPricingCenterView() {
    const { data, loading: permissionsLoading } = useGetAuthenticatedWorkerPermissionsQuery();
    const viewPermission = data?.authenticatedWorkerPermissions?.viewPricingCenter ?? false;
    const editPermission = data?.authenticatedWorkerPermissions?.editPricingCenter ?? false;
    const hasPermission = viewPermission && editPermission;

    if (permissionsLoading) {
        return <Loading />
    } else {
        return (<>
            {hasPermission ? (
                <PricingCenter usageContext="cc"/>
            ) : (
                <NotAuthorized />
            )}
        </>)
    }
}

export function RecoveryCenterPricingCenterView() {
    const { data, loading: permissionsLoading } = useGetAuthenticatedWorkerPermissionsQuery();
    const viewPermission = data?.authenticatedWorkerPermissions?.viewPricingCenter ?? false;
    const editPermission = data?.authenticatedWorkerPermissions?.editPricingCenter ?? false;
    const finalFinalPricePermission = data?.authenticatedWorkerPermissions?.setFinalFinalPrice ?? false;
    const hasPermission = viewPermission && editPermission && finalFinalPricePermission;

    if (permissionsLoading) {
        return <Loading />
    } else {
        return (<>
            {hasPermission ? (
                <PricingCenter usageContext="rc"/>
            ) : (
                <NotAuthorized />
            )}
        </>)
    }
}

export function ReadOnlyPricingCenterView() {
    const { data, loading: permissionsLoading } = useGetAuthenticatedWorkerPermissionsQuery();
    const hasPermission = data?.authenticatedWorkerPermissions?.viewPricingCenter ?? false;

    if (permissionsLoading) {
        return <Loading />
    } else {
        return (<>
            {hasPermission ? (
                <PricingCenter usageContext="readonly"/>
            ) : (
                <NotAuthorized />
            )}
        </>)
    }
}

export type PricingCenterUsageContext = "cc" | "rc" | "readonly";

function PricingCenter({usageContext}: {usageContext: PricingCenterUsageContext}) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setUsageContext(usageContext))
    }, [usageContext, dispatch]);

    // note: this is only used for running the GetPreContractJobSummaryData query, and setting the default opened tab
    //... the selected config is separate from this
    const { id: urlJobConfigId } = useNumericIdParam();

    const selectedConfigId = useAppSelector(selectJobConfigurationId);

    const { data: tabData, loading, subscribeToMore: subscribeToTabData } = useGetPricingCenterTabsQuery({
        variables: { jobConfigurationId: urlJobConfigId ?? -1},
        skip: isNullOrUndefined(urlJobConfigId) || urlJobConfigId! < 1,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            const contractedConfigId = data.pricingCenterTabs.contractConfigurationId ?? undefined;
            dispatch(setContractConfigurationId(contractedConfigId));
            const configToQuoteIdMap: {[configId: number]: number} = {};
            data.pricingCenterTabs.quotedConfigurationAndQuoteIds.forEach(item => configToQuoteIdMap[item.id] = item.quoteId);
            dispatch(setQuoteConfigToQuoteIds(configToQuoteIdMap));
            if (contractedConfigId) dispatch(setJobConfigurationId(contractedConfigId));
        }
    });
    const tabs = useMemo(
        () => tabData?.pricingCenterTabs.tabData ?? [],
        [tabData?.pricingCenterTabs.tabData]
    );
    const jobId = tabData?.pricingCenterTabs.id ?? -1;

    useGetRecoveryCenterOverrideQuery({
        skip: selectedConfigId < 1,
        variables: { jobConfigurationId: selectedConfigId },
        onCompleted: (data) => dispatch(setRecoveryOverride(data.recoveryCenterOverride ?? undefined)),
        onError: () => alert("Failed to load the final final price.")
    });

    // determines which tab to select by default
    useEffect(() => {
        if (loading) {
            dispatch(setJobConfigurationId(urlJobConfigId ?? -1));
        } else {
            let selectId = -1;
            if (tabs.find((t) => t.id === (urlJobConfigId ?? -1))) {
                selectId = urlJobConfigId!;
            } else {
                selectId = tabs.length > 0 ? tabs[0].id : -1;
            }

            dispatch(setJobConfigurationId(selectId));
        }
    }, [urlJobConfigId, tabs, loading, dispatch]);

    const listenForConfigExistenceChanges = useCallback(() => {
        if (subscribeToTabData && jobId) {
            subscribeToTabData({
                document: JobConfigurationExistenceChangedDocument,
                variables: { jobId: jobId },
                updateQuery: (prev, { subscriptionData }) => {
                    const existenceChangeAction = (
                        subscriptionData.data as unknown as JobConfigurationExistenceChangedSubscription
                    ).jobConfigurationExistenceChanged;
                    if (!existenceChangeAction) {
                        return prev;
                    }

                    const configIdForUpdate = existenceChangeAction.id;
                    let updatedTabs: JobConfigurationIdAndOptionNumber[] = [
                        ...prev.pricingCenterTabs!.tabData,
                    ];

                    if (existenceChangeAction.isDeletion) {
                        updatedTabs = updatedTabs.filter((tab) => tab.id !== configIdForUpdate);

                        // selected config was the one deleted, so select another
                        if (tabs.map((tab) => tab.id).includes(configIdForUpdate)) {
                            dispatch(setJobConfigurationId(updatedTabs[updatedTabs.length - 1].id));
                        }
                    } else {
                        const newTab: JobConfigurationIdAndOptionNumber = {
                            id: existenceChangeAction.id,
                            optionNumber: existenceChangeAction.optionNumber,
                        };
                        updatedTabs.push(newTab);
                    }

                    let updatedConfig: GetPricingCenterTabsQuery = {
                        ...prev,
                        pricingCenterTabs: {
                            ...prev.pricingCenterTabs,
                            tabData: updatedTabs,
                        },
                    };

                    return updatedConfig;
                },
            });
        }
    }, [jobId, subscribeToTabData, dispatch, tabs]);

    useEffect(() => {
        if (listenForConfigExistenceChanges) {
            listenForConfigExistenceChanges();
        }
    }, [listenForConfigExistenceChanges]);

    const listenForJobSold = useCallback(() => {
        if (subscribeToTabData && jobId) {
            subscribeToTabData({
                document: JobSoldDocument,
                variables: { jobId: jobId },
                updateQuery: (prev, { subscriptionData }) => {
                    const soldConfigId = (subscriptionData.data as unknown as JobSoldSubscription).jobSold;
                    if (!soldConfigId) return prev;

                    let updatedConfig: GetPricingCenterTabsQuery = {
                        ...prev,
                        pricingCenterTabs: {
                            ...prev.pricingCenterTabs,
                            contractConfigurationId: soldConfigId
                        }
                    };

                    return updatedConfig;
                }
            });
        }
    }, [jobId, subscribeToTabData]);

    useEffect(() => {
        if (listenForJobSold) {
            listenForJobSold();
        }
    }, [listenForJobSold]);

    const listenForJobQuoted = useCallback(() => {
        if (subscribeToTabData && jobId) {
            subscribeToTabData({
                document: JobQuotedDocument,
                variables: { jobId: jobId },
                updateQuery: (prev, { subscriptionData }) => {
                    const newConfigAndQuote = (subscriptionData.data as unknown as JobQuotedSubscription).jobQuoted;
                    if (!newConfigAndQuote) return prev;

                    const newQuotedList = [...prev.pricingCenterTabs.quotedConfigurationAndQuoteIds, newConfigAndQuote];

                    let updatedConfig: GetPricingCenterTabsQuery = {
                        ...prev,
                        pricingCenterTabs: {
                            ...prev.pricingCenterTabs,
                            quotedConfigurationAndQuoteIds: newQuotedList
                        }
                    };

                    return updatedConfig;
                }
            });
        }
    }, [jobId, subscribeToTabData]);

    useEffect(() => {
        if (listenForJobQuoted) {
            listenForJobQuoted();
        }
    }, [listenForJobQuoted]);

    const { data: failData } = useGetJobIsFailQuery({
        skip: jobId < 1,
        variables: { jobId: jobId },
    });
    const jobIsFail = failData?.jobIsFail ?? false;
    const jobInRecovery = failData?.jobIsInRecovery ?? false;

    const [sendToRecovery] = useUpdateJobInRecoveryMutation({
        variables: {
            jobId: jobId!,
            isInRecovery: true,
            isHot: true,
            claimedWorkerId: -1,
        },
        onError: () => alert("Could not send job to recovery"),
        refetchQueries: [namedOperations.Query.GetJobIsFail],
    });

    function sendJobToRecovery() {
        if (jobId > 0) {
            sendToRecovery();
        } else {
            alert("Could not send job to recovery");
        }
    }

    // TODO: would be better to combine the useGetAuth...Com... and useGetAuth...Worker so that only one query is required
    const { data: authCcWorkerData } = useGetAuthenticatedCommandCenterWorkerIdQuery();
    const ccWorkerId = authCcWorkerData?.authenticatedCommandCenterWorkerId ?? -1;
    useEffect(() => {
        dispatch(setCcWorkerId(ccWorkerId));
    }, [ccWorkerId, dispatch]);

    const { data: authWorkerData } = useGetAuthenticatedWorkerQuery();
    let authorizedWorker = authWorkerData?.authenticatedWorker ?? undefined;

    const { data: chatPartyData, subscribeToMore: subscribeToCcPartyChanges } = useGetChatForJobQuery({
            skip: jobId < 1,
            variables: {jobId: jobId},
            onCompleted: (data) => dispatch(setChatId(data.chatForJob.chatId))
        });
    const chatId = chatPartyData?.chatForJob.chatId ?? -1;
    const jobMember = chatPartyData?.chatForJob.jobMember;
    const commandCenterMember = chatPartyData?.chatForJob.commandCenterMember;
    const customerName = chatPartyData?.chatForJob.customerName;

    // replaces the command center chat party with the chat party for the worker that claimed the price request
    const listenForCcPartyChange = useCallback(() => {
        if (subscribeToCcPartyChanges) {
            subscribeToCcPartyChanges({
                document: JobChatCommandCenterMemberChangedDocument,
                variables: { jobId: jobId },
                updateQuery: (prev, { subscriptionData }) => {
                    const newCcMember = (subscriptionData.data as unknown as JobChatCommandCenterMemberChangedSubscription).jobChatCommandCenterMemberChanged;
                    if (!newCcMember) { return prev; }

                    const updatedQuery: GetChatForJobQuery = {
                        ...prev,
                        chatForJob: {
                            ...prev.chatForJob,
                            commandCenterMember: newCcMember
                        }
                    }
                    return updatedQuery;
                },
            });
        }
    }, [subscribeToCcPartyChanges, jobId]);

    useEffect(() => {
        if (listenForCcPartyChange) {
            listenForCcPartyChange();
        }
    }, [listenForCcPartyChange]);

    const { data: jobData } = useGetJobInvoiceDataQuery({
        variables: { jobConfigurationId: urlJobConfigId ?? 0 },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only",
        skip: (urlJobConfigId ?? 0) < 1,
    });
    const customer = jobData?.jobConfigurationHeader.customer;

    // the ACTIVE salespeople for the job
    const salespeople = jobData?.jobConfigurationHeader.assignedSalespeople;

    const [editCustomerOpen, setEditCustomerOpen] = useState(false);
    const [updateCustomerDetails] = useUpdateCustomerDetailsMutation({
        refetchQueries: [namedOperations.Query.GetJobInvoiceData],
        awaitRefetchQueries: true
    });

    return (
        <div>
            {((ccWorkerId > 0) && (jobId > 0))? (
                <>
                    <FlatNavbar title={"Pricing Center - " + jobData?.jobConfigurationHeader.appointmentNumber ?? ""} />
                    <div
                        className="padding-md column-pad-between-children"
                        style={{
                            backgroundColor: "var(--flat-gray-1)",
                            maxHeight: "calc(100vh - 72px)",
                            height: "calc(100vh - 72px)",
                            overflow: "auto",
                        }}
                    >
                        <div className="flex-row">
                            <FlatCustomerHeader
                                onEdit={() => setEditCustomerOpen(true)}
                                renderExpanded={false}
                                {...customer!}
                                salesperson1={salespeople?.[0]}
                                salesperson2={salespeople?.[1]}
                                defaultClosed={true}
                            />

                            {jobIsFail && !jobInRecovery && (
                                <SendToRecoveryButton onClick={sendJobToRecovery}>
                                    Send to Recovery
                                </SendToRecoveryButton>
                            )}
                        </div>

                        <FlatJobDetailsSection tabs={tabs} />

                        {editCustomerOpen && (
                            <UpdateCustomerDetailDialog
                                open={editCustomerOpen}
                                defaultCustomer={customer!}
                                onCancel={() => setEditCustomerOpen(false)}
                                onSubmit={(newCustomer: Customer) => {
                                    updateCustomerDetails({
                                        variables: {
                                            updatedCustomer: prepareCustomer(newCustomer),
                                        },
                                    }).then(() => {
                                        setEditCustomerOpen(false);
                                    });
                                }}
                            />
                        )}

                        {authorizedWorker && chatId > 0 && isNotNullOrUndefined(commandCenterMember) && isNotNullOrUndefined(jobMember) && (
                            <Chat
                                chatId={chatId}
                                sendingWorker={authorizedWorker}
                                sendingMember={commandCenterMember!}
                                actionComponent={
                                    <PriceRequestResponseBox
                                        optionNumber={tabs.find((t) => t.id === selectedConfigId)?.optionNumber}
                                        jobConfigurationId={tabs.find((t) => t.id=== selectedConfigId)?.id}
                                        usageContext="pc"
                                    />
                                }
                                title={jobMember!.partyName}
                                subtitle={customerName}
                            />
                        )}
                    </div>
                </>
            ) : (
                <Loading /> 
            )}
        </div>
    );
}

const SendToRecoveryButton = styled(Button)<ButtonProps>(({ theme }) => ({
    backgroundColor: "white",
    color: "var(--reject-color)",
    border: "3px solid var(--reject-color)",
    borderRadius: "5px",
    height: "fit-content",
    width: "fit-content",
    marginLeft: "2rem",
}));
