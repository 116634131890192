import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"
import FlatNavbar from "FlatComponents/Layout/FlatNavbar"

export default function JobTrackerNavbar({title}: {title: string}) {
    const basePathStyle = {color: "var(--flat-gray-3)"}

    const titleElement: ReactJSXElement = (
        <span className="flex-row justify-content-center flex-gap-xsm">
            <p className="margin-none flat-font-xlg" style={basePathStyle}>
                Project Coordinator Dashboard
            </p>
            <p className="margin-none flat-font-lg" style={basePathStyle}>/</p>
            <p className="margin-none flat-font-lg">{title}</p>
        </span>
    )

    return (
        <FlatNavbar title={titleElement}/>
    )
}