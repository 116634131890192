import { AnimatePresence } from 'framer-motion';
import { FinancingOption, FinancingSlotChangedDocument, FinancingSlotChangedSubscription, GetJobFinancingOptionsQuery, useGetJobFinancingOptionsQuery } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useCallback, useEffect } from 'react';
import { AlwaysScrollToEnd } from "../SellSheet";
import FinancingAnimatedWrapper, { FinancingCard, SalesTaxCard } from "./FinancingCard";

interface FinancingRowProps {
    financingOptionId: number | undefined;
    jobPrice: number;
    selectFinancingOptionId: (optionId: number | undefined) => void;  // uses mutation, if card isn't copy, changes local state only if it is
    loadingFinancingChanges: boolean;
    jobConfigurationId: number | null;  // should be non-null ONLY if the selected card is not a copy; used when retrieving financing options to check for override
}
export function FinancingRow({
    loadingFinancingChanges,
    financingOptionId,
    jobPrice,
    selectFinancingOptionId,
    jobConfigurationId
}: FinancingRowProps) {
    const { data, subscribeToMore: subscribeToFinancingOptions } = useGetJobFinancingOptionsQuery({ variables: { jobPrice, jobConfigurationId } });
    
    // listen for updates to which options should be displayed
    const listenForFinancingUpdates = useCallback(() => {
        if (jobConfigurationId) {
            subscribeToFinancingOptions({
                document: FinancingSlotChangedDocument,
                variables: { jobConfigurationId },
                updateQuery: (prev, { subscriptionData }) => {
                    const newOption = (subscriptionData.data as unknown as FinancingSlotChangedSubscription).financingSlotChanged;
                    if (newOption) {
                        const updatedOptions = {...prev.jobFinancingOptions}
                        switch (newOption.slot) {
                            case 1:
                                updatedOptions.slotA = newOption;
                                break;
                            case 2:
                                updatedOptions.slotB = newOption;
                                break;
                            case 3:
                                updatedOptions.slotC = newOption;
                                break;
                            default:
                                return prev;
                        }

                        const updatedResult: GetJobFinancingOptionsQuery = {
                            ...prev,
                            jobFinancingOptions: updatedOptions
                        }
                                        
                        return updatedResult;
                    } else {
                        return prev;
                    }
                }, 
            });
        }
    }, [jobConfigurationId, subscribeToFinancingOptions ]);

    useEffect(() => {
        listenForFinancingUpdates();
    }, [listenForFinancingUpdates]);

    const ops = data?.jobFinancingOptions;

    const finOps: FinancingOption[] = [ops?.slotA!, ops?.slotB!, ops?.slotC!].filter(op => isNotNullOrUndefined(op));

    const isSelectionPresent = financingOptionId !== undefined;

    const clearSelection = () => selectFinancingOptionId(undefined);

    return (
        <AnimatePresence initial={true}>
            <FinancingAnimatedWrapper key="sales-tax-card" offset={0}>
                <SalesTaxCard
                    selectionPresent={isSelectionPresent}
                    loading={loadingFinancingChanges}
                    selectCard={() => selectFinancingOptionId(-1)}
                    clearSelection={clearSelection}
                    isSelected={financingOptionId === -1} 
                    price={jobPrice}
                />
            </FinancingAnimatedWrapper>

            {finOps.map((op, i) => {
                return (
                    <FinancingAnimatedWrapper key={`financing-card-${op.id}`} offset={i + 1}>
                        <FinancingCard
                            selectionPresent={isSelectionPresent}
                            loading={loadingFinancingChanges}
                            selectCard={() => selectFinancingOptionId(op.id)}
                            clearSelection={clearSelection}
                            option={op}
                            price={jobPrice}
                            isSelected={financingOptionId === op.id} />
                    </FinancingAnimatedWrapper>
                );
            })}
            <AlwaysScrollToEnd />
        </AnimatePresence>
    );
}
