import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { Breakdowns } from "../Breakdown/BreakdownTableUtils";
import "./diff.css";

export enum DiffTypeEnum {
    Addition = "addition",
    Deletion = "deletion",
    Update = "update"
}

export interface Diff {
    preChangeDescription?: ReactJSXElement; // only relevant to Update type
    postChangeDescription: ReactJSXElement;
    type: DiffTypeEnum;
}

export function MakeDiffList(original: Breakdowns, updated: Breakdowns): Diff[]  {
    throw Error("Diffing not yet implemented")
    // This is not typed well, so have to do this in a hacky way. A pull request has been submitted but not merged.
    // see https://github.com/mattphillips/deep-object-diff/pull/72
    // let diff = detailedDiff(original, updated) as any;
}

export default function DiffList({diffs}: {diffs: Diff[]}) {
    return (
        <div className="flex-column margin-top-sm flex-gap-xsm fill-width">
            {diffs.map(d => {
                return (
                    <span className={`diff-${d.type} fill-width flex-row text-align-center justify-content-center padding-xxsm`}>
                        {isNotNullOrUndefined(d.preChangeDescription) ? (
                            <>{d.preChangeDescription}<ArrowRightIcon className="margin-side-xsm"/>{d.postChangeDescription}</>
                        ) : (
                            <>{d.postChangeDescription}</>
                        )}
                    </span>
                )
            })}
        </div>
    )
}