import clsx from "clsx";
import { S3PdfViewerDialog } from "Components/Files/S3PdfViewers";
import { FlatAlertIcon } from "FlatComponents/Button/FlatAlertButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import FlatDocumentButton from "FlatComponents/Button/FlatDocumentButton";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import FlatUploadFileButton from "FlatComponents/Button/FlatUploadFileButton";
import { useDeleteW9Mutation, W9 } from "generated/graphql";
import { makeEmptyW9 } from "Globals/DataStructures/EmptyDataStructures";
import { dateTimeStrToMdy } from "Globals/DateAndTimeHelpers";
import { isDocumentOverdue } from "Globals/GenericValidators";
import { useState } from "react";
import W9Dialog from "../Dialogs/W9Dialog";

interface W9TableProps {
    workerId: number;
    toRefetch: string;
    w9s: W9[];
}

export default function W9Table({workerId, toRefetch, w9s}: W9TableProps) {
    const [dialogOpen, setDialogOpen] = useState(false);

    return (<>
        <div className="flat-font profile-right-column-table-container">
            <div className="thin-horizontal-bar grid-colspan-1-3" />
            <p>W9</p>
            <div className="grid-15-50-35 padding-left-sm margin-bottom-sm fit-content-height">
                <span className="file-upload-row profile-table-cell">
                    <FlatUploadFileButton
                        color={"var(--flat-red)"}
                        onClick={() => setDialogOpen(true)}
                    />
                </span>

                {w9s.length === 0 && <p className="grid-colspan-1-4">No W9 information found</p>}
                {w9s.map(w9 => (
                    <W9TableRow
                        key={w9.id}
                        w9={w9}
                        toRefetchOnUpdate={toRefetch}
                    />
                ))}
            </div>
        </div>

        {dialogOpen && (
            <W9Dialog
                w9={makeEmptyW9(workerId)}
                toRefetchOnUpdate={toRefetch}
                onClose={() => setDialogOpen(false)}
            />
        )}
    </>)
}

interface W9TableRowProps {
    w9: W9;
    toRefetchOnUpdate: string;
}

function W9TableRow({w9, toRefetchOnUpdate}: W9TableRowProps) {
    const isOverdue = isDocumentOverdue(w9.expirationDate);
    const [uploaderOpen, setUploaderOpen] = useState(false);
    const [viewerOpen, setViewerOpen] = useState(false);

    const [deleteW9, {loading: deleting}] = useDeleteW9Mutation({
        variables: {id: w9.id},
        onError: () => alert("Could not delete W9"),
        refetchQueries: [toRefetchOnUpdate]
    });

    function onDeleteRow() {
        if (window.confirm("Are you sure you wish to delete this W9?")) {
            deleteW9();
        }
    }

    return (<>
        <span className="profile-table-cell profile-table-cell-inner profile-table-label-cell">
            {w9.s3FileId ? (
                <FlatDocumentButton
                    color="var(--flat-green)"
                    onClick={() => setViewerOpen(true)}
                    disabled={deleting}
                />
            ) : (
                <FlatUploadFileButton
                    color={"var(--flat-red)"}
                    onClick={() => setUploaderOpen(true)}
                    disabled={deleting}
                />
            )}
        </span>
        <p className="profile-table-cell profile-table-date-cell profile-table-cell-inner">
            {`${dateTimeStrToMdy(w9.activeDate)} - ${dateTimeStrToMdy(w9.expirationDate)}`}
        </p>
        
        <span className="profile-table-cell profile-table-action-cell">
            <span className={clsx({"visibility-hidden": !isOverdue})}>
                <FlatAlertIcon color="var(--flat-orange)"/>
                <p className="flat-font-xsm">Due</p>
            </span>
            <span className="flex-row flex-gap-sm">
                <FlatDeleteButton
                    onClick={onDeleteRow}
                    disabled={deleting}
                />
                <FlatEditButton
                    onClick={() => setUploaderOpen(true)}
                    disabled={deleting}
                />
            </span>
        </span>

        {uploaderOpen && (
            <W9Dialog
                w9={w9}
                toRefetchOnUpdate={toRefetchOnUpdate}
                onClose={() => setUploaderOpen(false)}
            />
        )}

        {(w9.s3FileId && viewerOpen) && (
            <S3PdfViewerDialog
                fileId={w9.s3FileId}
                onClose={() => setViewerOpen(false)}
            />
        )}
    </>)
}