import { MenuItem, Select, TextField } from "@material-ui/core"
import { GetMeasurementUnitQuery, useGetMeasurementUnitQuery } from "generated/graphql"
import { isNullOrUndefined, isNumber } from "Globals/GenericValidators"

export interface MeasuredValue {
    inputValue: string,
    unitId: number
}

export interface MeasuredValueInputProps {
    value: MeasuredValue,
    setValue: (newValue: MeasuredValue) => void,
    unitOptions: number[],
    readOnly?: boolean,
    renderUnit?: (unit: GetMeasurementUnitQuery["measurementUnit"][number]) => string
}

export default function MeasuredValueInput({ value, setValue, unitOptions, readOnly, renderUnit }: MeasuredValueInputProps) {

    const { data } = useGetMeasurementUnitQuery({ variables: { ids: unitOptions } });

    const units = data?.measurementUnit ?? [];

    const unitToString: typeof renderUnit = renderUnit ?? ((unit) => unit.long);

    return (
        <div className="flex-row fill-width flex-gap-xsm">
            <TextField
                type="number"
                value={value?.inputValue ?? ""}
                onChange={(x) => setValue?.({ ...value, inputValue: x.target.value })}
                inputProps={{ readOnly }}
            />

            {(units.length > 0) && (
                <Select
                    value={value?.unitId}
                    readOnly={readOnly}
                    onChange={(x) => setValue?.({ ...value, unitId: x.target.value as number })}
                >
                    {isNullOrUndefined(value?.unitId) && (
                        <MenuItem
                            key={`measured-input-undefined`}
                            value={-1}
                        >
                            Undefined
                        </MenuItem>
                    )}
                    {units.map(unit => (
                        <MenuItem
                            key={`measured-input-${unit.id}`}
                            value={unit.id}
                        >
                            {unitToString(unit)}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </div>
    )
}

export function v_MeasuredValue(value: MeasuredValue){
    return value.unitId > 0 &&
        isNumber(value.inputValue)
}
