import { Checkbox, Dialog, DialogActions, DialogContent, IconButton, TextField } from "@material-ui/core";
import { FlatCircleAddButton } from "Components/Forms/Controls/CircleAddButton";
import { CustomerContact } from "generated/graphql";
import { formatPhoneNumber } from "Globals/StringFormatting";
import { addSecondaryContact, blankContact, removeSecondaryContact, selectPrimaryContact, selectSecondaryContacts, setPrimaryContactFname, setPrimaryContactLname, setPrimaryEmail, setPrimaryPhone, setSecondaryContactFirstName, setSecondaryContactLastName, setSecondaryEmail, setSecondaryIsScheduling, setSecondaryPhone } from "Redux/callCenterSchedulingReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from "react-redux";

export default function SchedulingContactEditor({onDone}: {onDone: () => void}) {
    const primaryContact = useAppSelector(selectPrimaryContact);
    const secondaryContacts = useAppSelector(selectSecondaryContacts);
    const dispatch = useDispatch();

    return (
        <Dialog open={true} maxWidth="lg">
            <DialogContent>
                <table>
                    <thead>
                        <tr>
                            <th />
                            <th style={{ textAlign: "left", width: "8rem" }}>First Name</th>
                            <th style={{ textAlign: "left", width: "8rem" }}>Last Name</th>
                            <th style={{ textAlign: "left", width: "8rem" }}>Phone #</th>
                            <th style={{ textAlign: "left", width: "8rem" }}>Email Address</th>
                            <th style={{ textAlign: "center" }}>Scheduling Contact</th>
                        </tr>
                    </thead>
                    <tbody>
                        <SchedulingContactEditorRow contact={primaryContact}/>

                        {secondaryContacts.map(contact => (
                            <SchedulingContactEditorRow key={contact.id} contact={contact}/>
                        ))}
                    </tbody>
                </table>

                <div className="flex-row justify-content-center fill-width margin-top-sm">
                    <FlatCircleAddButton onClick={() => dispatch(addSecondaryContact({...blankContact}))} />
                </div>
            </DialogContent>

            <DialogActions>
                <button
                    className="flat-button-base flat-primary-minor-button"
                    onClick={onDone}
                >Done</button>
            </DialogActions>
        </Dialog>
    )
}

function SchedulingContactEditorRow({contact}: {contact: CustomerContact}) {
    const dispatch = useAppDispatch();
    const isPrimary = contact.isPrimary;

    function setFirstName(newFname: string) {
        if (isPrimary) {
            dispatch(setPrimaryContactFname(newFname));
        } else {
            dispatch(setSecondaryContactFirstName({ contactId: contact.id, fName: newFname }));
        }
    }

    function setLastName(newLname: string) {
        if (isPrimary) {
            dispatch(setPrimaryContactLname(newLname));
        } else {
            dispatch(setSecondaryContactLastName({ contactId: contact.id, lName: newLname }));
        }
    }

    function setPhone(newPhone: string) {
        if (isPrimary) {
            dispatch(setPrimaryPhone(newPhone));
        } else {
            dispatch(setSecondaryPhone({ contactId: contact.id, phone: newPhone}));
        }
    }

    function setEmail(newEmail: string) {
        if (isPrimary) {
            dispatch(setPrimaryEmail(newEmail));
        } else {
            dispatch(setSecondaryEmail({ contactId: contact.id, email: newEmail}));
        }
    }

    return (
        <tr>
            <td style={{ padding: "0 .25rem" }}>
                <IconButton
                    style={{visibility: isPrimary ? "hidden" : "visible"}}
                    onClick={() => dispatch(removeSecondaryContact(contact.id))}    
                >
                    <DeleteIcon />
                </IconButton>
            </td>
            <td style={{ padding: "0 .25rem" }}>
                <TextField
                    value={contact.firstName}
                    onChange={e => setFirstName(e.target.value)}
                />
            </td>
            <td style={{ padding: "0 .25rem" }}>
                <TextField
                    value={contact.lastName ?? ""}
                    onChange={e => setLastName(e.target.value)}
                />
            </td>
            <td style={{ padding: "0 .25rem" }}>
                <TextField
                    value={contact.phone ?? ""}
                    onChange={e => setPhone(formatPhoneNumber(e.target.value) ?? '')}
                />
            </td>
            <td style={{ padding: "0 .25rem" }}>
                <TextField
                    value={contact.email ?? ""}
                    onChange={e => setEmail(e.target.value)}
                />
            </td>
            <td style={{ textAlign: "center" }}>
                <Checkbox
                    // this can only be changed for secondary contacts 
                    disabled={isPrimary}
                    checked={contact.isSchedulingContact}
                    onChange={(_, c) => dispatch(setSecondaryIsScheduling({ contactId: contact.id, isSchedulingContact: c }))}
                />
            </td>
        </tr>
    )
}