import { Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Loading from "Components/Loading";
import { useGetCwcJobsForProjectCoordinatorQuery } from "generated/graphql";
import ProjectCoordinatorCard, { Column } from "./ProjectCoordinatorCard";

interface JobWithCWCDialogProps {
    closeDialog: (madeChanges: boolean) => void
}

export default function JobWithCWCDialog({ closeDialog }: JobWithCWCDialogProps) {
    function close() {
        closeDialog(false)
    }

    const { data, loading } = useGetCwcJobsForProjectCoordinatorQuery();

    const jobs = data?.cwcJobsForProjectCoordinator ?? []

    return (
        <Dialog open={true} onClose={close} maxWidth="md">
            <DialogTitle style={{ paddingBottom: 0 }}>
                <div style={{ width: "100%", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <div>Customer Will Call Jobs</div>
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent style={{ minHeight: "10em" }}>
                {loading ?
                    <Loading /> :
                    <div className="flex-column" style={{ width: "20em", maxHeight: "80vh", scrollBehavior: "auto" }}>
                        {
                            jobs.length === 0 ?
                                "No Jobs with CWC" :
                                jobs.map(job => (
                                    <ProjectCoordinatorCard
                                        key={`cwc-${job.id}`}
                                        job={job}
                                        disableMenu
                                        headerDividerClassName="customer-bar-to-schedule"
                                        column={Column.TO_SCHEDULE}
                                    />
                                ))
                        }
                    </div>
                }

            </DialogContent>
        </Dialog>
    )
}