import { Button, MenuItem, Select, Typography } from "@material-ui/core";
import logo from "Assets/Images/WorldOfFloorsLogoSmall.png";
import { useGetJobConfigurationHeaderQuery } from "generated/graphql";
import { buildAppendedId, useNumericIdParam } from "Globals/Hooks";
import { useHistory, useLocation } from "react-router";

export default function JobHeader({
    title,
    hideDate,
    hideConfigPicker,
}: {
    title?: string;
    hideDate?: boolean;
    hideConfigPicker?: boolean;
}) {
    const { id: jobConfigurationId } = useNumericIdParam();
    const history = useHistory();
    const location = useLocation();

    const { data, loading } = useGetJobConfigurationHeaderQuery({
        variables: { jobConfigurationId: jobConfigurationId ?? 0 },
        skip: jobConfigurationId === undefined,
    });

    var { firstName, lastName } = data?.jobConfigurationHeader.customer ?? {
        firstName: "",
        lastName: "",
    };
    var combinedName =
        firstName === "" || lastName === "" ? undefined : `${lastName}, ${firstName}`;

    const customerName = loading ? "Loading Name..." : combinedName ?? "Name not found";
    const appointmentNumber = loading
        ? "Loading Appt #..."
        : data?.jobConfigurationHeader.appointmentNumber ?? "No appointment found";
    const configurationOptions = data?.jobConfigurationHeader.configurationIds ?? [];

    function changeSelectedQuote(newId: number) {
        const fullPath = location.pathname;
        const path = location.pathname.substring(
            0,
            fullPath.length - buildAppendedId(jobConfigurationId).length
        );

        history.push(path + buildAppendedId(newId));
    }

    const showConfigPicker = jobConfigurationId !== undefined && !hideConfigPicker;

    return (
        <div className="job-header-styles">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        flexBasis: 0,
                    }}
                >
                    <Button
                        style={{
                            backgroundImage: `url(${logo})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            width: "26vh",
                            height: "7vh",
                            float: "left",
                        }}
                    />
                </div>
                <div className="flex-column-center">
                    <Typography
                        variant="h4"
                        style={{ fontSize: "4vh" }}
                    >
                        {title}
                    </Typography>
                    {showConfigPicker && (
                        <Select
                            value={configurationOptions.length === 0 ? "" : jobConfigurationId}
                            SelectDisplayProps={{ style: { fontSize: "2vh" } }}
                            onChange={(e) => changeSelectedQuote(e.target.value as number)}
                        >
                            {configurationOptions.map((op) => {
                                return (
                                    <MenuItem
                                        key={`quote-op-${op.id}`}
                                        style={{ fontSize: "2vh" }}
                                        value={op.id}
                                    >{`(${op.labels.join(", ")})`}</MenuItem>
                                );
                            })}
                        </Select>
                    )}
                </div>
                <div
                    className="job-header-cust-details-div"
                    style={{ flexGrow: 1, flexBasis: 0 }}
                >
                    <Typography
                        variant="h6"
                        className="job-header-cust-details-line"
                    >
                        {customerName}
                    </Typography>
                    <Typography
                        variant="h6"
                        className="job-header-cust-details-line"
                    >
                        {appointmentNumber}
                    </Typography>
                </div>
            </div>
        </div>
    );
}
