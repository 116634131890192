import { namedOperations, useUpdateSalespersonZipsMutation } from "generated/graphql";
import { useCallback, useState } from "react";
import WorkerServiceAreaEditor from "../GeneralProfileComponents/WorkerServiceAreaEditor/WorkerServiceAreaEditor";

interface SalespersonServiceAreaEditorProps {
    salespersonId: number;
    servicedZips: string[]
}

export default function SalespersonServiceAreaEditor({salespersonId, servicedZips}: SalespersonServiceAreaEditorProps) {
    const [updateSucceeded, setUpdateSucceeded] = useState(false);
    const [loading, setLoading] = useState(false);

    const [updateZips] = useUpdateSalespersonZipsMutation({
        onCompleted: () => {
            setLoading(false);
            setUpdateSucceeded(true);
            setTimeout(() => setUpdateSucceeded(false), 3000);
        },
        onError: () => alert("Failed to update salesperson zips"),
        refetchQueries: [namedOperations.Query.GetSalespersonProfile]
    });

    const onSubmitChanges = useCallback((addedZips: string[], removedZips: string[]) => {
        setLoading(true);
        updateZips({
            variables: {
                salespersonId: salespersonId,
                addedZips: addedZips,
                removedZips: removedZips
            }
        })
    }, [updateZips, salespersonId]);
    
    return (
        <div id="sp-service-area">
            <div className="thin-horizontal-bar" />

            <WorkerServiceAreaEditor
                servicedZips={servicedZips}
                onSubmit={onSubmitChanges}
                disableSubmit={loading}
                updateSucceeded={updateSucceeded}
            />
        </div>
    )
}