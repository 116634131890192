import { Calendar, Day, utils } from "@hassanmojab/react-modern-calendar-datepicker"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import FlatButton from "FlatComponents/Button/FlatButton"
import { isNullOrUndefined } from "Globals/GenericValidators"
import { useState } from "react"
import { isMobile } from "react-device-detect"
import { DayRange } from "react-modern-calendar-datepicker"


interface AddBlockTimeRangeProps {
    onClose: (newSlots?: DayRange) => void,
    blockedDays?: Day[]
}

export default function AddBlockTimeRange({ onClose, blockedDays }: AddBlockTimeRangeProps) {

    const [dayRange, setDayRange] = useState<DayRange>({from: null, to: null})

    function SubmitChanges() {
        if (isNullOrUndefined(dayRange.from) && isNullOrUndefined(dayRange.to)) return;
        
        onClose(dayRange);        
    }

    return (
        <Dialog open={true} fullScreen={isMobile}>
            <DialogTitle>Block Times</DialogTitle>
            <DialogContent>
                <Calendar
                    disabledDays={blockedDays}
                    value={dayRange}
                    onChange={setDayRange}
                    minimumDate={utils('en').getToday()}
                />
            </DialogContent>
            <DialogActions>
                <FlatButton color="primary" variant="outlined" onClick={() => onClose(undefined)}>Close</FlatButton>
                <FlatButton color="primary" onClick={SubmitChanges}>Add</FlatButton>
            </DialogActions>
        </Dialog>
    )
}
