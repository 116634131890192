import { AppliedDiscount, GetPromotionOnJobQuery } from "generated/graphql";
import { FlatDiscount } from "Pages/Admin/ProjectManagement/SellSheet/OldSellSheet/OldSellSheet";
import { useEffect } from "react";

interface AppliedDiscountSectionProps {
    discounts: AppliedDiscount[];
    listenForDiscountUpdates: () =>  void;
    promotion: GetPromotionOnJobQuery['promotionOnJob'] | undefined;
    listenForPromotionUpdates: () => void;
}

export default function AppliedDiscountSection({discounts, listenForDiscountUpdates, promotion, listenForPromotionUpdates}: AppliedDiscountSectionProps) {
    useEffect(() => {
        listenForDiscountUpdates();
    }, [listenForDiscountUpdates]);

    useEffect(() => {
        listenForPromotionUpdates();
    }, [listenForPromotionUpdates]);

    return (
        <div >
            <div className="flat-font flat-font-bold flat-font-upper" style={{marginBottom: ".5rem"}}>Applied Discounts</div>
            {(discounts.length > 0 || promotion) ? (
                <table>
                    <tbody>
                        {promotion && (
                            <tr key="promo-row" className="flat-font-sm">
                                <td>
                                    <div className="margin-right-md">
                                        {promotion.basePromotion.name}
                                    </div>
                                </td>
                                <td>
                                    <div>- ${promotion.savingsAmount}</div>
                                </td>
                            </tr>
                        )}
                        {discounts.filter(d => d.name !== "Override").map(d => (
                            <tr key={`discount-row-${d.discountId}`} className="flat-font-sm">
                                <td>
                                    <div className="margin-right-md">
                                        {d.name}
                                    </div>
                                </td>
                                <td>
                                    <FlatDiscount discount={d} isTotal/>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="flat-font-sm flat-font-italic">No discounts applied</div>
            )}
        </div>
    )
}
