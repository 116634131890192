import { Calendar, Day, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { Dialog, MenuItem, Select, Typography } from "@material-ui/core";
import clsx from "clsx";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { HourMinute, hourMinuteTo12HHMM } from "Globals/DateAndTimeHelpers";
import { useState } from "react";

export interface DateAndTime {
    date: Day; // Should be in MYD format
    time?: HourMinute; // Should be date time string
}

interface CallbackTimePickerProps {
    open: boolean;
    defaultValue?: DateAndTime;
    maxDate?: Day;
    onClose: (pickedDate: DateAndTime | null) => void;
    disabled?: boolean;
}

export function CallbackTimePicker({
    open,
    onClose,
    defaultValue,
    maxDate,
    disabled
}: CallbackTimePickerProps) {
    function closeDialog() {
        onClose(null);
    }

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => {
                closeDialog();
            }}
        >
            <div
                style={{ width: "30rem", height: "35rem" }}
                className="flex-column flex-centered"
            >
                <Typography variant="h3" style={{marginTop:"1rem"}}>Callback Date</Typography>
                {open && (
                    <DateTimePickerContent
                        onClose={onClose}
                        defaultValue={defaultValue}
                        pickTime={true}
                        maxDate={maxDate}
                        disabled={disabled}
                    />
                )}
            </div>
        </Dialog>
    );
}

// export function NotInterestedDatePicker({
//     open,
//     onClose,
//     defaultValue,
// }: Pick<CallbackTimePickerProps, "onClose" | "open" | "defaultValue">) {
//     return (
//         <Dialog
//             open={open}
//             onClose={() => {}}
//         >
//             <div
//                 style={{ width: "30rem", height: "35rem" }}
//                 className="flex-column flex-centered"
//             >
//                 <Typography variant="h3">Callback Date</Typography>
//                 {open && (
//                     <DateTimePickerContent
//                         onClose={onClose}
//                         defaultValue={defaultValue}
//                         pickTime={false}
//                     />
//                 )}
//             </div>
//         </Dialog>
//     );
// }

interface DateTimePickerContentProps
    extends Pick<CallbackTimePickerProps, "onClose" | "defaultValue" | "maxDate" | "disabled"> {
    pickTime: boolean;
}

function DateTimePickerContent({
    onClose,
    defaultValue,
    maxDate,
    pickTime,
    disabled
}: DateTimePickerContentProps) {
    const [date, setDate] = useState<DayValue>(defaultValue?.date);
    const [time, setTime] = useState<HourMinute | null>(null);

    const hourOptions = generateHourMinuteOptions(8, 18);

    return (
        <>
            <Calendar
                calendarClassName={clsx({ "visibility-hidden": disabled })}
                value={date}
                onChange={setDate}
                maximumDate={maxDate}
                minimumDate={utils('en').getToday()}
            />
            {pickTime && (
                <Select value={time === null ? "none" : hourMinuteTo12HHMM(time, true)} disabled={disabled}>
                    <MenuItem
                        value={"none"}
                        hidden
                    >
                        Pick a Time
                    </MenuItem>
                    {hourOptions.map((t) => {
                        const timeStr = hourMinuteTo12HHMM(t, true);
                        return (
                            <MenuItem
                                value={timeStr}
                                key={timeStr}
                                onClick={() => setTime(t)}
                            >
                                {timeStr}
                            </MenuItem>
                        );
                    })}
                </Select>
            )}

            <div className="flex-grow" />

            <div
                className="flex-row"
                style={{ padding: "1em" }}
            >
                <SpacedButton
                    variant="outlined"
                    color="secondary"
                    onClick={() => onClose(null)}
                    disabled={disabled}
                >Close</SpacedButton>

                <SpacedButton
                    variant="contained"
                    color="primary"
                    onClick={() => onClose({ date: date!, time: time ?? undefined })}
                    disabled={disabled}
                >Submit</SpacedButton>
            </div>
        </>
    );
}

function generateHourMinuteOptions(startingHour: number, endingHour: number): HourMinute[] {
    return [...new Array(endingHour - startingHour)].flatMap((v, hr) => {
        const hour = hr + startingHour;

        return [
            {
                hour: hour,
                minute: 0,
            },
            {
                hour: hour,
                minute: 30,
            },
        ];
    });
}
