import {
    Calendar,
    Day,
    DayRange,
    DayValue,
    utils,
} from "@hassanmojab/react-modern-calendar-datepicker";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { MILLISECONDS_IN_A_DAY } from "Components/ProjectDashboards/TimeClock";
import { MarketTimeSlot } from "generated/graphql";
import { useState } from "react";
import {
    allTrue,
    isEmptyString,
    isNotNullOrUndefined,
    isNullOrUndefined,
} from "./GenericValidators";

// ISO format is 'yyyy/mm/dd'; MDY format is 'mm/dd/yyyy'; can substitute '/' for '-'
const dateSplitRe = /\/|-/; // split on '/' or '-'
const mdyRe = /^([0-9]{1,2})[-|/]([0-9]{1,2})[-|/]([0-9]{4})$/; // check if a string is proper mm/dd/yyyy format

export function addDaysToDate(dateYmd: string, daysToAdd: number) {
    let d = new Date(dateYmd);
    d.setDate(d.getDate() + daysToAdd + 1);
    return dateToYmd(d);
}

export function mdyToMd(mdyString: string): string {
    return mdyString.split("/").splice(0, 2).join("/");
}

export function dayOfWeekNumberToString(dayOfWeek: number) {
    // Date().getDay() returns Monday as 0
    switch (dayOfWeek) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
    }
}

export function formatNumberOfDays(numberOfDays: number, upper?: boolean): string {
    return `${numberOfDays} ${upper === true ? "D" : "d"}ay${numberOfDays > 1 ? "s" : ""}`;
}

export function todaysDateWithoutTime(): Date {
    // constructing with new Date() will give a time zone offset sometimes, which is undesirable
    return new Date(`${new Date().toISOString().split("T")[0]}T00:00:00`);
}

export function todayAsIso(): string {
    return dayToIso(dateToDay(todaysDateWithoutTime()));
}

export function todayAsMdy() {
    return dateToMdy(todaysDateWithoutTime());
}

export function todayAsDay() {
    return dateToDay(todaysDateWithoutTime());
}

// takes a string formatted in extended ISO
export function trimIsoToDay(extended: string): Day {
    throw new Error("not implemented yet");
}

export function dateToDay(date: Date): Day {
    return {
        month: date.getMonth() + 1,
        day: date.getDate(),
        year: date.getFullYear(),
    };
}

export function dayToIso(d: Day): string {
    return `${d.year}/${d.month}/${d.day}`;
}

export function dayValueToIso(d: DayValue, undefinedStr=""): string {
    if (isNullOrUndefined(d)) {
        return undefinedStr;
    } else {
        return `${d!.year}/${d!.month}/${d!.day}`;
    }
}

export function isoToDay(iso: string): Day {
    let isoArr = iso.split(dateSplitRe);
    return { year: +isoArr[0], month: +isoArr[1], day: +isoArr[2] };
}

export function isoToDayValue(iso?: string): DayValue {
    if (isEmptyString(iso ?? "")) { return undefined; }
    let isoArr = iso!.split(dateSplitRe);
    return { year: +isoArr[0], month: +isoArr[1], day: +isoArr[2] };
}

export function dateTimeStrToDay(dateTimeStr: string): Day {
    // datetime string returned from DB is in the form 'yyyy-mm-ddT00:00:00:00Z'; this scraps everything after T
    let trimmed = dateTimeStr.split("T")[0];
    return isoToDay(trimmed);
}

export function dateTimeStrToIso(dateTimeStr: string): string {
    return dayToIso(dateTimeStrToDay(dateTimeStr));
}

export function dateTimeStrToDate(dateTimeStr: string): Date {
    // datetime string returned from DB is in the form 'yyyy-mm-ddT00:00:00:00Z'; this scraps everything after T
    let converted = dateTimeStrToDay(dateTimeStr);
    return new Date(converted.year, converted.month - 1, converted.day);
}

export function dateTimeStrToMdy(dateTimeStr?: string): string {
    return isNullOrUndefined(dateTimeStr) ? "" : dayToMdy(dateTimeStrToDay(dateTimeStr!));
}

export function dateTimeStrToMd(dateTimeStr?: string): string {
    return isNotNullOrUndefined(dateTimeStr)
        ? dayToMd(dateTimeStrToDay(dateTimeStr!))
        : '';
    }   

export function dateTimeStrToShortMdy(dateTimeStr: string): string {
    return dayToShortMdy(dateTimeStrToDay(dateTimeStr));
}

export function dateTimeStrMd(dateTimeStr: string): string {
    var { month, day } = dateTimeStrToDay(dateTimeStr);
    return `${month}/${day}`;
}

export function dateTimeStrToTime(dateTimeStr: string): HourMinute {
    var time = dateTimeStr.split("T")[1];
    const split = time.split("-");
    //const offset = split[1] // For now this offset will be ignored
    time = split[0];
    const splitTime = time.split(":");
    return { hour: +splitTime[0], minute: +splitTime[1] };
}

// 2022-06-30T12:31:24.166-04:00
// 2022-06-29T20:00:00.000-04:00
export function dateTimeStrToHHMM12HR(dateTimeStr: string): string {
    const { hour, minute } = dateTimeStrToTime(dateTimeStr);

    return formatMilitaryToHHMM12HR(hour, minute, false);
}

export function marketTimeSlotToStr(mts: MarketTimeSlot, separator: string = "-") {
    if (isNullOrUndefined(mts)) {
        return "";
    } else
        return `${timeSpanStrTo12HHMM(mts.startTime, true)} ${separator} ${timeSpanStrTo12HHMM(
            mts.endTime,
            true
        )}`;
}

export type HourMinute = { hour: number; minute: number };

export function timeSpanStrToTime(dateTimeStr: string): HourMinute {
    if (dateTimeStr === "PT0S") return { hour: 0, minute: 0 };
    const split = dateTimeStr.split("H");
    const minutes = split[1].length !== 0 ? parseInt(split[1]) : 0;
    return { hour: parseInt(split[0].substring(2)), minute: minutes };
}

export function timeSpanStrToMinutes(dateTimeStr: string): number {
    const hm = timeSpanStrToTime(dateTimeStr);

    return hm.hour * 60 + hm.minute;
}

export function timeSpanStrToHHMM(dateTimeStr: string): string {
    const { hour, minute } = timeSpanStrToTime(dateTimeStr);
    return `${hour}:${minute.toString().padStart(2, "0")}`;
}

export function hourMinuteTo12HHMM({ hour, minute }: HourMinute, includeTOD?: boolean): string {
    return `${militaryTimeTo12Hour(hour)}:${minute.toString().padStart(2, "0")}${
        includeTOD ? (hour < 12 ? "am" : "pm") : ""
    }`;
}

export function timeSpanStrTo12HHMM(dateTimeStr: string, includeTOD?: boolean): string {
    const time = timeSpanStrToTime(dateTimeStr);
    return hourMinuteTo12HHMM(time, includeTOD);
}

function militaryTimeTo12Hour(hour: number): number {
    return ((hour + 11) % 12) + 1;
}

export function timeSpanStrToHHMM12HR(dateTimeStr: string, hidePartOfDay?: boolean): string {
    const { hour, minute } = timeSpanStrToTime(dateTimeStr);

    return formatMilitaryToHHMM12HR(hour, minute, hidePartOfDay);
}

export function formatMilitaryToHHMM12HR(hour: number, minute: number, hidePartOfDay?: boolean) {
    const partOfDay = hidePartOfDay ? "" : hour < 12 ? "am" : "pm";

    return `${militaryTimeTo12Hour(hour)}:${minute.toString().padStart(2, "0")}${partOfDay}`;
}

export function dayToMdy(d: Day): string {
    return `${d.month}/${d.day}/${d.year}`;
}

export function dayValueToMdy(d: DayValue, undefinedStr=""): string {
    if (isNotNullOrUndefined(d)) {
        return `${d!.month}/${d!.day}/${d!.year}`;
    } else {
        return undefinedStr;
    }
}

export function dayToMd(d: Day): string {
    return `${d.month}/${d.day}`;
}

export function dayToShortMdy(d: Day): string {
    return `${d.month}/${d.day}/${d.year.toString().substring(2)}`;
}

export function dayToYmd(d: Day): string {
    return `${d.year}-${d.month.toString().padStart(2, "0")}-${d.day.toString().padStart(2, "0")}`;
}

export function dateToMdy(d: Date, separator?: string): string {
    separator = separator ?? "/";
    return `${d.getMonth() + 1}${separator}${d.getDate()}${separator}${d.getFullYear()}`;
}

export function dateToPaddedMdy(d: Date, separator?: string): string {
    separator = separator ?? "/";

    return `${(d.getMonth() + 1).toString().padStart(2, "0")}${separator}${d
        .getDate()
        .toString()
        .padStart(2, "0")}${separator}${d.getFullYear().toString().substring(2)}`;
}

export function ymdDashedToDate(ymd: string): Date {
    const sep = ymd.split("-");

    return new Date(sep.join("/"));
}

export function dateToYmd(d: Date, separator?: string): string {
    separator = separator ?? "-";

    const paddedMonth = (d.getMonth() + 1).toString().padStart(2, "0");
    const paddedDay = d.getDate().toString().padStart(2, "0");

    return `${d.getFullYear()}${separator}${paddedMonth}${separator}${paddedDay}`;
}

export function dateToHHMMSS(d: Date, use24Hr?: boolean): string {
    const rawHours = d.getHours();
    const partOfDay = use24Hr ? "" : rawHours < 12 ? "am" : "pm";
    const hours = use24Hr ? rawHours : militaryTimeTo12Hour(rawHours);
    const minutes = d.getMinutes().toString().padStart(2, "0");
    const seconds = d.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}${partOfDay}`;
}

export function mdyToDay(mdy: string): Day {
    let mdyArr = mdy.split(dateSplitRe);
    return { year: +mdyArr[2], month: +mdyArr[0], day: +mdyArr[1] };
}

export function isoToMdy(isoStr: string) {
    let isoArr = isoStr.split(dateSplitRe);
    return [isoArr[1], isoArr[2], isoArr[0]].join("/");
}

export function mdyToIso(mdyStr: string) {
    let mdyArr = mdyStr.split(dateSplitRe);
    return [mdyArr[2], mdyArr[0], mdyArr[1]].join("/");
}

export function mdyFormatValid(mdy: string): boolean {
    if (isNullOrUndefined(mdy) || isEmptyString(mdy)) {
        return false;
    }
    if (mdyRe.test(mdy)) {
        let matchResult = mdy.match(mdyRe);
        if (+matchResult![1] > 12) {
            return false;
        }
        if (+matchResult![2] > 31) {
            return false;
        }
        return true;
    } else {
        return false;
    }
}

export function miltiaryToHourMinute(military: string): HourMinute {
    var splitTime = military.split(":");

    return { hour: +splitTime[0], minute: +splitTime[1] };
}

export function minutesBetween(current: HourMinute, target: HourMinute) {
    const fromMinutes = current.hour * 60 + current.minute;
    const toMinutes = target.hour * 60 + target.minute;
    return toMinutes - fromMinutes;
}

export const emptyDayRange = { from: null, to: null };

export function daysEq(day1: DayValue, day2: DayValue) {
    if (isNullOrUndefined(day1) && isNullOrUndefined(day2)) {
        return true;
    } else if (
        (isNullOrUndefined(day1) && isNotNullOrUndefined(day2)) ||
        (isNotNullOrUndefined(day1) && isNullOrUndefined(day2))
    ) {
        return false;
    } else
        return day1!.year === day2!.year && day1!.month === day2!.month && day1!.day === day2!.day;
}

/**
 * Checks whether the day passed as the first argument is strictly before the second.
 * @returns true if the first argument is a day strictyl before the second date, false otherwise
 */
export function dayStrictlyBefore(day1: Day, day2: Day) {
    if (day1.year === day2.year) {
        if (day1.month === day2.month) {
            return day1.day < day2.day;
        } else if (day1.month < day2.month) {
            return true;
        } else {
            return false;
        }
    } else if (day1.year < day2.year) {
        return true;
    } else {
        return false;
    }
}

export function dayBeforeOrEq(day1: Day, day2: Day) {
    return dayStrictlyBefore(day1, day2) || daysEq(day1, day2);
}

export function dayStrictlyAfter(day1: Day, day2: Day) {
    if (day1.year === day2.year) {
        if (day1.month === day2.month) {
            return day1.day > day2.day;
        } else if (day1.month > day2.month) {
            return true;
        } else {
            return false;
        }
    } else if (day1.year > day2.year) {
        return true;
    } else {
        return false;
    }
}

export function dayInRange(day: Day, start: Day, end: Day) {
    return !(dayStrictlyBefore(day, start) || dayStrictlyAfter(day, end));
}

/**
 * Day ranges are considered equal if both are undefined, both are defined but have undefined "to" AND "from" values,
 * or if their "to" AND "from" values are the same days.
 * On the contrary, they are considered not equal when either of the "to"/"from" values are not same between them.
 */
export function dayRangesEq(r1: DayRange | undefined, r2: DayRange | undefined) {
    if (isNullOrUndefined(r1) && isNullOrUndefined(r2)) return true;
    else if (
        (isNullOrUndefined(r1) && isNotNullOrUndefined(r2)) ||
        (isNotNullOrUndefined(r1) && isNullOrUndefined(r2))
    ) {
        return false;
    } else if (allTrue([r1?.to, r1?.from, r2?.from, r2?.to].map(isNullOrUndefined))) return true;
    return daysEq(r1!.from!, r2!.from!) && daysEq(r1!.to!, r2!.to!);
}

// TODO: find all places that perform a check like this and replace it with use of this function
// TODO: maybe(?) add this to the global generic validators file
export function dayRangeValid(range: DayRange): boolean {
    return (
        isNotNullOrUndefined(range) &&
        isNotNullOrUndefined(range.from) &&
        isNotNullOrUndefined(range.to) &&
        dayBeforeOrEq(range.from!, range.to!) &&
        dayExists(range.from!) &&
        dayExists(range.to!)
    );
}

// used to ensure something isn't a nonexistent day (like Feb. 29 in a non-leap year)
// https://stackoverflow.com/a/6800290/8057105
export function dayExists(day: Day) {
    // month-1 because, for some reason, months are 0 indexed (but year and day aren't)
    let date = new Date(day.year, day.month - 1, day.day);
    /// invalid Dates have values different from the numbers used to construct the Date
    return (
        date.getDate() === day.day &&
        date.getMonth() === day.month - 1 &&
        date.getFullYear() === day.year
    );
}

interface CalendarDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    blockPastDays?: boolean;
}

interface SingleDateCalendarDialogProps extends CalendarDialogProps {
    selectedDate: DayValue;
    setSelectedDate: (date: DayValue) => void;
}

export function SingleDateCalendarDialog({
    selectedDate,
    setSelectedDate,
    open,
    setOpen,
    blockPastDays=true
}: SingleDateCalendarDialogProps) {
    function dateSelected(day: DayValue) {
        setSelectedDate(day);
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>Select a Date</DialogTitle>
            <DialogContent>
                <Calendar
                    value={selectedDate}
                    onChange={(newRange) => dateSelected(newRange)}
                    minimumDate={blockPastDays ? utils("en").getToday() : undefined}
                    colorPrimary="var(--wof-red)"
                    colorPrimaryLight="#f79cab"
                    shouldHighlightWeekends
                />
            </DialogContent>
        </Dialog>
    );
}

interface DateRangeCalendarDialogProps extends CalendarDialogProps {
    selectedRange: DayRange;
    setSelectedRange: (date: DayRange) => void;
}

export function DateRangeCalendarDialog({
    selectedRange: originalRange,
    setSelectedRange,
    open,
    setOpen,
}: DateRangeCalendarDialogProps) {
    const [range, setRange] = useState<DayRange>(originalRange);
    function onConfirm() {
        setSelectedRange(range);
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>Select a Date Range</DialogTitle>
            <DialogContent>
                <Calendar
                    value={range}
                    onChange={(newRange) =>
                        setRange(handleCalendarDayRangeSelectionChange(range, newRange))
                    }
                    minimumDate={utils("en").getToday()}
                    colorPrimary="var(--wof-red)"
                    colorPrimaryLight="#f79cab"
                    shouldHighlightWeekends
                />
            </DialogContent>

            <DialogActions>
                <SpacedButton
                    className="cancel-button"
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </SpacedButton>
                <SpacedButton
                    variant="contained"
                    color="secondary"
                    onClick={onConfirm}
                >
                    Confirm
                </SpacedButton>
            </DialogActions>
        </Dialog>
    );
}

/**
 * Makes the date range selection process run a bit smoother than it does by default with the
 * react-modern-calendar datepicker component. How does this improve over the default selection?
 *
 *  1. When nothing is selected, selecting a day sets it as the start and end of the range.
 * By default, the <Calendar /> component produces a range where the end date is undefined upon the first selection.
 *
 *  2. When a single day is selected, selecting another day extends the range (in either direction).
 * By default, the <Calendar /> component produces a new range where only the "from" component is set when making a new
 * selection after both dates are not undefined.
 *
 *
 * @param previousSelection The selection as it was before the new selection was made.
 * @param newRange The new selection as the <Calendar /> componenent thinks it should be.
 * @returns The new range of dates to be selected on the calendar.
 */
export function handleCalendarDayRangeSelectionChange(
    previousSelection: DayRange,
    newRange: DayRange
): DayRange {
    if (isNullOrUndefined(previousSelection.to)) {
        // this case occurs when the calendar had no selection before being clicked
        newRange.to = newRange.from;
    } else if (daysEq(previousSelection.from!, previousSelection.to!)) {
        // NOTE: "from" will never be null when "to" is not null
        /*  If the calendar component is clicked when both "to" and "from" are set, it nulls
        "to" and sets "from" to the date that was clicked. However, in the case where both,
        start and are are the same (due to the first if condition being true) we need
        to keep the value of "from" that was present before, and set the "to" value to what
        the Calendar component is telling us the new value of "from" is. */
        if (dayBeforeOrEq(previousSelection.from!, newRange.from!)) {
            newRange.to = newRange.from;
            newRange.from = previousSelection.from;
        } else {
            // in this case, the new selected end date is actually BEFORE the previously
            // selected dats, so we reveerse the order to ensure "to" is never before "from"
            newRange.to = previousSelection.from;
        }
    } else {
        // this case occurs when the calendar had DIFFERENT "to" and "from" values and was clicked again (causing the range to reset)
        newRange.to = newRange.from;
    }

    return newRange;
}

// interface ExtractedDateTimeArrayData {
//     // IN MDY format
//     startDate: string;
//     endDate: string;

//      // Total number of days between first and last date in array
//     totalDaySpan: number;

//     // Confusing, assuming an array where startDate is index 0, and endDate is index totalDaySpan - 1,
//     // each element in this array in an index where the day is blocked
//     blockedIndiciesInSpan: number[];
// }

// // IMPORTANT: the date time array must be in order
// export function extractDateTimeArrayData(dateTimeArray: string[]) : ExtractedDateTimeArrayData {
//     if(dateTimeArray.length === 0) throw new Error("Date array cannot be empty");

//     const startDate = dateTimeStrToDate(dateTimeArray[0]);

//     if(dateTimeArray.length === 1) {
//         return {
//             startDate: dateToMdy(startDate),
//             endDate: dateToMdy(startDate),
//             totalDaySpan: 1,
//             blockedIndiciesInSpan: []
//         }
//     }

//     const actualNumberOfDays = dateTimeArray.length;
//     const endDate = dateTimeStrToDate(dateTimeArray[actualNumberOfDays - 1]);

//     // Needs to add 1 to result because a start and end date on the same day are considered to be 1 day
//     const daysBetweenDates = calculateWholeDaysBetweenDates(startDate, endDate) + 1

//     if(daysBetweenDates === actualNumberOfDays) {
//         return {
//             startDate: dateToMdy(startDate),
//             endDate: dateToMdy(endDate),
//             totalDaySpan: daysBetweenDates,
//             blockedIndiciesInSpan: []
//         }
//     }

//     // There are blocked days within the span, need to figure out which ones they are

// }

// Returns total number of whole days between two dates. Dates 23 hrs apart are considered to be 0 Days apart
export function calculateWholeDaysBetweenDates(startDate: Date, endDate: Date) {
    return Math.floor(calculateDaysBetweenDates(startDate, endDate));
}

// Returns the number of days between the two DateTimes as a floating point number
export function calculateDaysBetweenDates(startDate: Date, endDate: Date) {
    const difference = endDate.getTime() - startDate.getTime();
    return difference / MILLISECONDS_IN_A_DAY;
}

// Formats the dates by grouping days where possible
// mdy, mdy-mdy, mdy-mdy, mdy, etc
export function formatAppointmentDateStringExact(orderedDateTimeStrings: string[], showNumDays: boolean = false): string {
    // If only one day, no grouping can occur
    if (orderedDateTimeStrings.length === 1) return dateTimeStrToMdy(orderedDateTimeStrings[0]);

    const dates = orderedDateTimeStrings.map((dts) => dateTimeStrToDate(dts));

    const groupedDates: Date[][] = [];
    let lastDate: Date | null = null;

    dates.forEach((date) => {
        if (lastDate === null) groupedDates.push([date]);
        else if (calculateDaysBetweenDates(lastDate, date) === 1) {
            // The current date is the day after the last,
            // Therefore they are continuous and should be grouped
            groupedDates[groupedDates.length - 1].push(date);
        } else {
            // The current day is not continuous with the last,
            // Therefore this day should start the next group
            groupedDates.push([date]);
        }

        // Set current date as last date for next loop;
        lastDate = date;
    });

    // Each element of groupDates[] is a list of continuous dates in order
    const groupedStrings = groupedDates.map((grouped) => {
        if (grouped.length === 1) return dateToMdy(grouped[0]);
        else return `${dateToMdy(grouped[0])} - ${dateToMdy(grouped[grouped.length - 1])}`;
    });

    let result = groupedStrings.join(", ");
    if (showNumDays) {
        result += ` [${orderedDateTimeStrings.length} days]`;
    }
    return result;
}

export function formatAppointmentDateStringAbbreviated(dateTimeStrings: string[]): string {
    if (dateTimeStrings.length > 1) {
        return `${formatDateRange(dateTimeStrings)} [${formatNumberOfDays(
            dateTimeStrings.length
        )}]`;
    } else {
        return formatDateRange(dateTimeStrings);
    }
}

export function formatAppointmentDateStringAbbreviatedShortened(dateTimeStrings: string[]): string {
    if (dateTimeStrings.length > 1) {
        return `${formatShortDateRange(dateTimeStrings)} [${dateTimeStrings.length}d]`;
    } else {
        return formatShortDateRange(dateTimeStrings);
    }
}

function formatDateRange(dateTimeStrings: string[]): string {
    if (dateTimeStrings.length === 1) return dateTimeStrToMdy(dateTimeStrings[0]);
    else
        return `${dateTimeStrToMdy(dateTimeStrings[0])} - ${dateTimeStrToMdy(
            dateTimeStrings[dateTimeStrings.length - 1]
        )}`;
}

function formatShortDateRange(dateTimeStrings: string[]): string {
    if (dateTimeStrings.length === 1) return dateTimeStrToShortMdy(dateTimeStrings[0]);
    else
        return `${dateTimeStrToShortMdy(dateTimeStrings[0])} - ${dateTimeStrToShortMdy(
            dateTimeStrings[dateTimeStrings.length - 1]
        )}`;
}

export function formatShortDateRangeNoYear(dateTimeStrings: string[]): string {
    if (dateTimeStrings.length === 1) return dateTimeStrToMd(dateTimeStrings[0]);
    else
        return `${dateTimeStrToMd(dateTimeStrings[0])} - ${dateTimeStrToMd(
            dateTimeStrings[dateTimeStrings.length - 1]
        )}`;
}

export function getStartOfMonthToToday(): DayRange {
    const today = utils('en').getToday();
    const firstOfMonth: Day = {year: today.year, month: today.month, day: 1};
    return {from: firstOfMonth, to: today};
}
