import { Tooltip } from "@mui/material";
import clsx from "clsx";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import FlatUndoDeleteButton from "FlatComponents/Button/FlatUndoDeleteButton";
import { FlatLabeledCheckbox } from "FlatComponents/Inputs/FlatCheckbox";
import FlatInput, { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import FlatRadioGroup, { FlatRadioGroupOption } from "FlatComponents/Inputs/FlatRadioGroup";
import { CustomService, LabelForRoom } from "generated/graphql";
import { isEmptyString } from "Globals/GenericValidators";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";

export interface AreaIdToRooms {
    [areaId: number]: {
        roomId: number;
        labels: LabelForRoom[]
    }[]
}

interface CustomServiceEditorRowProps {
    originalService: EditableCustomService;
    editableService: EditableCustomService;
    updateService: (updated: EditableCustomService) => void;
    removeService: () => void;
    areaIdsToRooms: AreaIdToRooms;
    preventRcEditing: boolean;
}

export default function CustomServiceEditorRow({
    originalService,
    editableService,
    updateService,
    removeService,
    areaIdsToRooms,
    preventRcEditing
}: CustomServiceEditorRowProps) {
    const [editingDescription, setEditingDescription] = useState(false);

    function onChangePrice(newDisplayPrice: string) {
        let newActualPrice: number;
        let newContractorPay: string;
        if (isEmptyString(newDisplayPrice)) {
            newActualPrice = 0;
            newContractorPay = "";
        } else {
            newActualPrice = +newDisplayPrice;
            newContractorPay = (newActualPrice * editableService.contractorPercentage).toFixed(2);
        }
        
        const updated: EditableCustomService = {
            ...editableService,
            displayPrice: newDisplayPrice,
            price: newActualPrice,
            displayContractorPay: newContractorPay
        };

        updateService(updated);  // notify the parent component of the changes
    }

    function onChangeContractorPercentage(newDisplayPct: string) {
        let newActualPctg: number;
        let newContractorPay: string;
        if (isEmptyString(newDisplayPct)) {
            newActualPctg = 0;
            newContractorPay = "";
        } else {
            newActualPctg = +newDisplayPct / 100;
            newContractorPay = (editableService.price * newActualPctg).toFixed(2);
        }
        
        const updated: EditableCustomService = {
            ...editableService,
            contractorPercentage: newActualPctg,
            displayContractorPercentage: newDisplayPct,
            displayContractorPay: newContractorPay
        };

        updateService(updated);  // notify the parent component of the changes
    }

    function onDelete() {
        if (editableService.id < 0) {
            // service is not in database, so just remove it from the list entirely
            removeService()
        } else {
            // service is in DB, so just mark it as deleted until changes are submitted
            // also revert it to it's original state, except for the deletion flag
            updateService({...originalService, isDeleted: true})
        }
    }

    function onChangeDescription(newDesc: string) {
        updateService({...editableService, description: newDesc});
    }

    function onRestore() {
        updateService({...editableService, isDeleted: false})
    }

    function onChangeSelectedArea(newAreaId: number) {
        updateService({...editableService, areaId: newAreaId, roomIds: []});
    }

    function setRoomIsSelected(roomId: number, newIsSelected: boolean) {
        if (newIsSelected) {
            updateService({
                ...editableService,
                roomIds: [...editableService.roomIds, roomId]
            });
        } else {
            updateService({
                ...editableService,
                roomIds: editableService.roomIds.filter(rId => rId !== roomId)
            });
        }
    }

    return (<>
            <span className="flex-row flex-gap-sm">
                {editingDescription ? (
                    <FlatInput
                        value={editableService.description}
                        onChange={e => onChangeDescription(e.target.value)}
                        onBlur={() => setEditingDescription(false)}
                    />
                ) : (
                    <p 
                        className={clsx("flat-font flat-font-bold margin-none", {
                            "success-text-important": editableService.id < 1,
                            "error-text-important": editableService.isDeleted
                        })}
                        onClick={() => {
                            if (!editableService.isDeleted) {
                                setEditingDescription(true)
                            }
                        }}
                    >
                        {editableService.description}
                    </p>
                )}

                {editableService.isDeleted ? (
                    <Tooltip title="Restore Service">
                        <span>
                            <FlatUndoDeleteButton onClick={onRestore}/>
                        </span>
                    </Tooltip>
                ) : (
                    <Tooltip title={`${editingDescription ? "Stop Editing Description to " : ""}Remove Service`}>
                        <span>
                            <FlatDeleteButton
                                onClick={onDelete}
                                disabled={editingDescription}
                            />
                        </span>
                    </Tooltip>
                )}                
            </span>

            <span className="grid-33-33-33 flex-space-between">
                <NumberFormat
                    customInput={FlatLabeledInput}
                    label="Price"
                    className="w-5r"
                    decimalScale={2} fixedDecimalScale
                    prefix="$"
                    value={editableService.displayPrice}
                    onValueChange={newVal => onChangePrice(newVal.value)}
                    disabled={preventRcEditing || editableService.isDeleted}
                />

                <NumberFormat
                    customInput={FlatLabeledInput}
                    label="Contractor %"
                    className="w-3r"
                    suffix="%"
                    value={editableService.displayContractorPercentage}
                    onValueChange={newVal => onChangeContractorPercentage(newVal.value)}
                    disabled={preventRcEditing || editableService.isDeleted}
                    justification="right"
                />

                <NumberFormat
                    customInput={FlatLabeledInput}
                    label="Cost"
                    className="w-5r"
                    disabled
                    prefix="$"
                    value={editableService.displayContractorPay}
                    justification="right"
                />
            </span>
            
            <span />

            <CustomServiceAreaAndRoomSelector
                selectedAreaId={editableService.areaId}
                selectedRoomIds={editableService.roomIds}
                areaIdToRoomMap={areaIdsToRooms}
                selectArea={onChangeSelectedArea}
                setRoomIsSelected={setRoomIsSelected}
                isDeleted={editableService.isDeleted}
            />
        </>
    )
}

// makes the numeric types easier to edit in input
export interface EditableCustomService extends Omit<CustomService, "__typename"> {
    displayPrice: string;
    displayContractorPercentage: string;
    displayContractorPay: string;
    isDeleted: boolean
}

export function customToEditable(actualService: CustomService): EditableCustomService {
    return {
        ...actualService,
        displayPrice: (actualService.price === 0) ? "" : actualService.price.toFixed(2),
        displayContractorPercentage: (actualService.contractorPercentage * 100).toFixed(0),
        displayContractorPay: (actualService.contractorPercentage * actualService.price).toFixed(2),
        isDeleted: false  // services only converted to editable when they're loaded from DB
    };
}

export function customFromEditable(internalService: EditableCustomService): CustomService {
    const {
        displayPrice: _,
        displayContractorPercentage: __,
        displayContractorPay: ___,  ...rest} = internalService;
    return rest;
}

interface CustomServiceAreaAndRoomSelectorProps {
    selectedAreaId: number;
    selectedRoomIds: number[];
    areaIdToRoomMap: AreaIdToRooms;
    selectArea: (areaId: number) => void;
    setRoomIsSelected: (roomId: number, isSelected: boolean) => void;
    isDeleted: boolean;
}

function CustomServiceAreaAndRoomSelector({
    selectedAreaId,
    selectedRoomIds,
    areaIdToRoomMap,
    selectArea,
    setRoomIsSelected,
    isDeleted
} : CustomServiceAreaAndRoomSelectorProps) {
    const options: FlatRadioGroupOption[] = Object.keys(areaIdToRoomMap).map(aIdStr => {
        const aId = +aIdStr;
        const label = getNameOfArea(areaIdToRoomMap[aId].flatMap(r => r.labels));
        const thisOption: FlatRadioGroupOption = {
            value: aId,
            label: label
        };

        return thisOption;
    });


    const hasSelection = selectedAreaId !== -1;
    
    return (
        <div className="flex-row flex-gap-md fil-width">
            <div>
                <FlatRadioGroup
                    vertical
                    disabled={isDeleted}
                    value={selectedAreaId}
                    onChange={newVal => selectArea(newVal as number)}
                    options={options}
                />
            </div>

            <div className="flex-row align-items-flex-start">
                {hasSelection && areaIdToRoomMap[selectedAreaId].map(r => {
                    const isSelected = selectedRoomIds.includes(r.roomId);

                    return (
                        <FlatLabeledCheckbox
                            checked={isSelected}
                            disabled={isDeleted}
                            onClick={() => setRoomIsSelected(r.roomId, !isSelected)}
                            key={r.roomId}
                            label={getNameOfArea(r.labels)}
                        />
                    )
                })}
            </div>
        </div>
    )
}