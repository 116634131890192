import { HBar } from "Components/pageSpacing";
import { formatAppointmentDateStringExact, formatNumberOfDays } from "Globals/DateAndTimeHelpers";
import {
    formatNameStringLastFirst,
    formatUnitAmount
} from "Globals/StringFormatting";
import {
    BreakdownTableProps,
    makeLaborBreakdownRows,
    makeMaterialBreakdownRows
} from "../../Breakdown/BreakdownTableUtils";
import { WorkOrderProps } from "./PrintableWorkOrder";

export default function MobileWorkOrder({
    sahNum,
    contractorName,
    projectCoordinatorName,
    dates,
    customer,
    salesRepNames,
    areaBreakdowns,
    hasFinancing,
    remainingCod,
    notes,
}: WorkOrderProps) {
    return (
        <div
            className="flex-column flex-centered"
            style={{
                width: "100%",
                padding: ".5em",
                boxSizing: "border-box",
                alignItems: "center",
            }}
        >
            <h2 style={{ marginBottom: "0" }}>Work Order</h2>

            <div
                className="flex-row fill-width"
                style={{ justifyContent: "space-between", alignItems: "end" }}
            >
                <p className="margin-none">
                    <b>{contractorName}</b>
                </p>
                <p className="margin-none">
                    <b>{sahNum}</b>
                </p>
            </div>
            <div
                className="flex-row fill-width"
                style={{ justifyContent: "space-between" }}
            >
                {formatAppointmentDateStringExact(dates)} {formatNumberOfDays(dates.length)}
            </div>
            <div
                className="border-vertical-double margin-top-xsm margin-bottom-xsm fill-width"
                style={{ display: "flex", flexWrap: "wrap" }}
            >
                <div
                    className="flex-row"
                    style={{ flex: "1 0 auto" }}
                >
                    <p style={{ margin: "0 4px 0 0" }}>Customer:</p>
                    <p className="margin-none">
                        <b>{formatNameStringLastFirst(customer)}</b>
                    </p>
                </div>
                {/* <div
                    className="flex-row"
                    style={{ flex: "1 0 auto" }}
                >
                    <p style={{ margin: "0 4px 0 0" }}>Phone:</p>
                    <p className="margin-none">
                        <b>{formatPhoneNumber(customer.primaryPhone)}</b>
                    </p>
                </div> */}
                <div
                    className="flex-row"
                    style={{ flex: "1 0 auto" }}
                >
                    <p style={{ margin: "0 4px 0 0" }}>City:</p>
                    <p className="margin-none">
                        <b>{customer.primaryAddress.city}</b>
                    </p>
                </div>
                {/* <div
                    className="flex-row"
                    style={{ flex: "1 0 auto" }}
                >
                    <p style={{ margin: "0 4px 0 0" }}>Address:</p>
                    <p className="margin-none">
                        <b>{formatAddress(customer.primaryAddress)}</b>
                    </p>
                </div> */}
                <div
                    className="flex-row"
                    style={{ flex: "1 0 auto" }}
                >
                    <p style={{ margin: "0 4px 0 0" }}>Email:</p>
                    <p className="margin-none">
                        <b>{customer.email}</b>
                    </p>
                </div>
                {salesRepNames.length > 0 && (
                    <div
                        className="flex-row"
                        style={{ flex: "1 0 auto" }}
                    >
                        <p style={{ margin: "0 4px 0 0" }}>
                            Sales Rep{salesRepNames.length > 1 && "s"}:
                        </p>
                        <p className="margin-none">
                            <b>{salesRepNames.join(", ")}</b>
                        </p>
                    </div>
                )}
                <div
                    className="flex-row"
                    style={{ flex: "1 0 auto" }}
                >
                    <p style={{ margin: "0 4px 0 0" }}>Coordinator:</p>
                    <p className="margin-none">
                        <b>{projectCoordinatorName}</b>
                    </p>
                </div>
            </div>
            <div
                className="fill-width flex-row"
                style={{ justifyContent: "end" }}
            >
                <p style={{ margin: "0 4px 0 0" }}>{hasFinancing ? "Financing" : "COD"}:</p>
                <p className="margin-none justify-content-flex-end">
                    <b>${remainingCod.toFixed(2)}</b>
                </p>
            </div>

            <PrintableWorkOrderMaterialTable areaBreakdowns={areaBreakdowns} />
            <PrintableWorkOrderLaborTable areaBreakdowns={areaBreakdowns} />

            <div className="fill-width margin-top-sm">
                <p className="margin-none">
                    <b>Notes:</b>
                </p>
                {notes}
            </div>
        </div>
    );
}

function PrintableWorkOrderMaterialTable({ areaBreakdowns }: BreakdownTableProps) {
    return (
        <>
            <HBar />
            <h5>Material</h5>
            {makeMaterialBreakdownRows(areaBreakdowns, true).map((row, i) => (
                <div
                    key={i}
                    className="flex-column fill-width"
                    style={{borderBottom:"1px solid #555"}}
                >
                    <div className="flex-row fill-width">
                        <div style={{ width: "2rem", minWidth: "2rem", textAlign: "center" }}>
                            {row.line}
                        </div>
                        <div
                            className="flex-row"
                            style={{ flex: 1, flexWrap: "wrap" }}
                        >
                            <p
                                style={{
                                    flex: "1 0 auto",
                                    whiteSpace: "nowrap",
                                    margin: "0 6px 0 0",
                                }}
                            >
                                {formatUnitAmount(row.amount, row.amountUnit)} {row.amountUnit}
                            </p>
                            <p
                                style={{
                                    flex: "1 0 auto",
                                    whiteSpace: "nowrap",
                                    margin: "0 6px 0 0",
                                    fontWeight: row.style === undefined ? "bold" : undefined,
                                }}
                            >
                                {row.productType}
                            </p>
                            {row.style !== undefined && (
                                <p
                                    style={{
                                        flex: "1 0 auto",
                                        whiteSpace: "nowrap",
                                        margin: "0 6px 0 0",
                                    }}
                                >
                                    <b>{row.style}</b>
                                </p>
                            )}
                            {row.color !== undefined && (
                                <p
                                    style={{
                                        flex: "1 0 auto",
                                        whiteSpace: "nowrap",
                                        margin: "0 6px 0 0",
                                    }}
                                >
                                    <b>{row.color}</b>
                                </p>
                            )}
                            <p
                                style={{
                                    flex: "1 0 auto",
                                    whiteSpace: "nowrap",
                                    margin: "0 6px 0 0",
                                }}
                            >
                                {row.area}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}

function PrintableWorkOrderLaborTable({ areaBreakdowns }: BreakdownTableProps) {
    return (
        <>
            <HBar />
            <h5>Labor</h5>
            {makeLaborBreakdownRows(areaBreakdowns, true).map((row, i) => (
                <div
                    key={i}
                    className="flex-column fill-width"
                    style={{borderBottom:"1px solid #555"}}
                >
                    <div className="flex-row fill-width">
                        <div style={{ width: "2rem", minWidth: "2rem", textAlign: "center" }}>
                            {row.line}
                        </div>
                        <div
                            className="flex-row"
                            style={{ flex: 1, flexWrap: "wrap" }}
                        >
                            <p
                                style={{
                                    flex: "1 0 auto",
                                    whiteSpace: "nowrap",
                                    margin: "0 6px 0 0",
                                }}
                            >
                                {formatUnitAmount(row.amount, row.amountUnit)} {row.amountUnit}
                            </p>
                            <p
                                style={{
                                    flex: "1 0 auto",
                                    whiteSpace: "nowrap",
                                    margin: "0 6px 0 0",
                                }}
                            >
                                <b>{row.type}</b>
                            </p>

                            <p
                                style={{
                                    flex: "1 0 auto",
                                    whiteSpace: "nowrap",
                                    margin: "0 6px 0 0",
                                }}
                            >
                                <b>{row.description}</b>
                            </p>

                            <p
                                style={{
                                    flex: "1 0 auto",
                                    whiteSpace: "nowrap",
                                    margin: "0 6px 0 0",
                                }}
                            >
                                {row.area}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}
