import { TextField, Typography } from "@material-ui/core";
import { FinancingOption } from "generated/graphql";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectCommissionRates, setCcActualCommissionRate, setCcStandardCommissionRate, setRecoveryActualCommissionRate, setRecoveryStandardCommissionRate } from "Redux/pricingCenterReducer";
import { calculateFinancingAdjustedRevenue, calculateMargin, calculateRevenueFromMargin } from "./marginUtils";
import { getCommissionRate } from "./PricingSummary";

interface PriceAndMarginEditorProps {
    revenue?: number;
    cost: number;
    setRevenue: (newRevenue: number | undefined) => void;
    isForRecovery?: boolean;
    financingOption: FinancingOption | null;
    disabled?: boolean;
}

export default function PriceAndMarginEditor({
    revenue,
    cost,
    setRevenue,
    isForRecovery=false,
    financingOption,
    disabled=false
}: PriceAndMarginEditorProps) {
    const [margin, setMargin] = useState<number>(
        revenue ?
            calculateMargin(financingOption ? calculateFinancingAdjustedRevenue(revenue, financingOption.fee) : revenue, cost) :
        0);
    /* Used to control the onChangeRevenue and onChangeMargin callbacks. Without this, there is a sort of "ping-pong" effect between
    the price and margin callbacks. This causes the margin to change to a value the user did not enter when directly editing the margin.
    When focus is lost, we return control to the revenue field, since when the tab is changed, the revenue gets updated (see onBlur of the margin input).*/
    const [editingRevenue, setEditingRevenue] = useState(true);
    const commissionRates = useAppSelector(selectCommissionRates); 
    const dispatch = useAppDispatch();

    function onChangeRevenue(newRevenue: number | undefined) {
        if (editingRevenue) {
            if (newRevenue) {
                setRevenue(newRevenue);
                const newAdjRev = calculateFinancingAdjustedRevenue(newRevenue, financingOption?.fee ?? 0);
                let newMargin = calculateMargin(newAdjRev, cost);
                setMargin(newMargin);
                let newCommissionRate = getCommissionRate(commissionRates, newMargin / 100);
                if (isForRecovery) {
                    dispatch(setRecoveryActualCommissionRate(newCommissionRate));
                    dispatch(setRecoveryStandardCommissionRate(newCommissionRate));
                } else {
                    dispatch(setCcActualCommissionRate(newCommissionRate));
                    dispatch(setCcStandardCommissionRate(newCommissionRate));
                }
            } else {
                setRevenue(0);
                setMargin(0);
                if (isForRecovery) {
                    dispatch(setRecoveryActualCommissionRate(-1));
                    dispatch(setRecoveryStandardCommissionRate(-1));
                } else {
                    dispatch(setCcActualCommissionRate(-1));
                    dispatch(setCcStandardCommissionRate(-1));
                }
            }
        }
    }

    function onChangeMargin(newMargin: number | undefined) {
        /* When the revenue changes, margin is recalculated, which then triggers revenue to again recalcualted.
           Without this condition, sometimes the typed in revenue would get changed because the of the rounding
           that if forced by the fixedDecimalScale={2} on the margin input. Ignoring beyond 2 decimal places
           prevents this from happening. 
        */
        if (!editingRevenue) {
            if (newMargin && newMargin > 0) {
                if (newMargin < 100) {
                    setMargin(newMargin!);
                    const newRevenue = calculateRevenueFromMargin(newMargin, cost, financingOption?.fee ?? 0);
                    setRevenue(newRevenue);
                    let newCommissionRate = getCommissionRate(commissionRates, newMargin / 100) ?? 0;
                    if (isForRecovery) {
                        dispatch(setRecoveryActualCommissionRate(newCommissionRate));
                        dispatch(setRecoveryStandardCommissionRate(newCommissionRate));
                    } else {
                        dispatch(setCcActualCommissionRate(newCommissionRate));
                        dispatch(setCcStandardCommissionRate(newCommissionRate));
                    }
                }
            } else {
                setMargin(0);
                setRevenue(0);
                if (isForRecovery) {
                    dispatch(setRecoveryActualCommissionRate(-1));
                    dispatch(setRecoveryStandardCommissionRate(-1));
                } else {
                    dispatch(setCcActualCommissionRate(-1));
                    dispatch(setCcStandardCommissionRate(-1));
                }
            }
        }
    }

    return (
        <div className="flex-row align-items-flex-end flex-gap-sm">
            <NumberFormat
                style={{width: "5.75rem"}}
                prefix="$" thousandSeparator={true}
                displayType="input"
                customInput={TextField}
                value={revenue}
                onValueChange={newVal => onChangeRevenue(newVal.floatValue)}
                decimalScale={2} fixedDecimalScale
                allowNegative={false}
                onFocus={() => setEditingRevenue(true)}
                readOnly={disabled}
                disabled={disabled}
            />
            
            <div className="flex-column flex-centered">
                <Typography style={{fontSize: ".5rem"}}>Margin</Typography>
                <div className="flex-row align-items-center">
                    <Typography>(</Typography>
                    <NumberFormat
                        style={{width: "3.5rem"}}
                        suffix="%" thousandSeparator={true}
                        displayType="input"
                        readOnly={disabled}
                        disabled={disabled}
                        customInput={TextField}
                        value={margin}
                        min={0} max={100}
                        onValueChange={newVal => onChangeMargin(newVal.floatValue)}
                        decimalScale={2} fixedDecimalScale
                        onFocus={() => setEditingRevenue(false)}
                        onBlur={() => setEditingRevenue(true)} // return control to the revenue input
                    />
                    <Typography>)</Typography>
                </div>
            </div>
        </div>
    )
}
