import { Button, Container, CssBaseline, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { redTheme } from 'App';
import bkg from "Assets/Backgrounds/wood5.jpg";
import logo from "Assets/Images/WorldOfFloorsLogoSmall.png";
import clsx from 'clsx';
import NavbarPage from 'Components/Page';
import { useAppHistory, useAppLocation } from 'Globals/routingHooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Redirect } from 'react-router-dom';
import { signIn, useAuthToken } from 'Globals/FirebaseUtils';

interface Inputs {
    email: string,
    password: string
}

const useStyles = makeStyles((theme) => {
    //Dynamic Content Styling
    const mobileCutoff = 500;
    const isDesktop = useMediaQuery({ query: `(min-device-width: ${mobileCutoff}px)` });

    return ({
        flexColumn: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center"
        },
        image: {
            backgroundImage: `url(${bkg})`,
            backgroundRepeat: 'repeat-x',
            backgroundColor: theme.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh !important',
            paddingTop: theme.spacing(8)
        },
        outerPaper: ((isDesktop)
            ? {}
            : {
                padding: "0 0 0 0",
                maxWidth: mobileCutoff,
                borderRadius: '0px'
            }
        ),
        paper: ((isDesktop)
            ? {
                backgroundColor: theme.palette.grey[100],
                padding: theme.spacing(2),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: "0 2px 2px rgb(0 0 0 / 20%), 0 1px 5px rgb(0 0 0 / 20%), 0 0 0 12px rgb(255 255 255 / 40%)",
                borderRadius: "4px",
            }
            : {
                backgroundColor: theme.palette.grey[100],
                padding: theme.spacing(2),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: "0 2px 2px rgb(0 0 0 / 20%), 0 1px 5px rgb(0 0 0 / 20%)",
                borderRadius: "0px",
            }
        ),
        logo: {
            backgroundImage: `url(${logo})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "88px",

        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(0, 0, 2),
        },
        copyright: {
            marginBottom: "1rem",
            color: "white",
            opacity: .5
        }
    })
});

function Copyright() {
    const classes = useStyles();

    return (
        <Typography variant="body2" color="textPrimary" align="center" className={classes.copyright}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.worldoffloors.com/">
                Worldoffloors.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login() {
    const classes = useStyles();
    const { register, handleSubmit } = useForm<Inputs>();
    const token = useAuthToken();
    const location = useAppLocation();
    const history = useAppHistory();
    const [loginFailed, setLoginFailed] = useState(false);

    const onSubmit = async (data: Inputs) => {
        await signIn(data).then((result) => {
            // returns true if it's successful, an object if it fails
            if (result !== true) {
                setLoginFailed(true);
                setTimeout(() => setLoginFailed(false), 5000);
            }
        });
    }

    //If already authenticated, redirect back to referrer unless referrer is self
    const referrer = history?.location?.state?.referrer ?? "/"
    if (token) return <Redirect to={(referrer === location?.pathname) ? "/" : referrer} />

    return (
        <NavbarPage className={clsx(classes.image, classes.flexColumn)} hideNavbar={true}>
            <ThemeProvider theme={redTheme}>
                <CssBaseline />
                <Container component="main" maxWidth="xs" className={classes.outerPaper}>
                    <Container className={classes.paper}>
                        <form className={classes.form} id='form-login-page' noValidate onSubmit={handleSubmit(onSubmit)}>
                            <div className={classes.logo} />
                            
                            <TextField
                                {...register("email")}
                                variant="filled"
                                margin="normal"
                                required
                                fullWidth
                                id="email-login-page"
                                label="Email Address"
                                name="email"
                                color='secondary'
                                autoComplete="email"
                                autoFocus
                            />
                            
                            <TextField
                                {...register("password")}
                                variant="filled"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password-login-page"
                                color="secondary"
                                autoComplete="current-password"
                            />

                            <p
                                className="margin-bottom-xsm error-text"
                                style={{visibility: loginFailed ? "visible" : "hidden"}}
                            >
                                Username or password is incorrect
                            </p>

                            <Button
                                type="submit"
                                fullWidth
                                id="button-login-page"
                                variant="contained"
                                color="primary"
                                onClick={()=>handleSubmit(onSubmit)}
                                className={classes.submit}
                            >
                                Log In
                            </Button>

                            {/* TODO: */}
                            <Link href="" variant="body2" color="secondary">
                                Forgot password?
                            </Link>
                        </form>
                    </Container>
                </Container>
                <Copyright />
            </ThemeProvider>
        </NavbarPage>
    );

}