import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import CircleAddButton from "Components/Forms/Controls/CircleAddButton";
import { GetJobConfigurationDocument, useGetJobConfigurationQuery, useReassignRoomMutation } from "generated/graphql";
import { useState } from "react";
import AreaColorButton from "./AreaColorButton";
import RoomSvg from "./RoomSvg";

interface QuoteDetailsDialogProps {
    optionNumber: number,
    jobConfigurationId: number,
    open: boolean,
    onClose: () => void
}

export default function QuoteDetailsDialog({ optionNumber, jobConfigurationId, open, onClose }: QuoteDetailsDialogProps) {

    const { data } = useGetJobConfigurationQuery({ variables: { jobConfigurationId } })
    const [selectedAreaIndex, setSelectedAreaIndex] = useState<number | null>(null)
    const [hasLocalArea, setHasLocalArea] = useState<boolean>(false)
    const localAreaIndex = data?.jobConfiguration.areas.length ?? 0
    const showAddButton = !hasLocalArea;

    const [reassignRoom] = useReassignRoomMutation({
        refetchQueries: [{
            query: GetJobConfigurationDocument,
            variables: { jobConfigurationId: jobConfigurationId },
            fetchPolicy: "network-only"
        }]
    })

    function assignRoomToArea(roomId: number) {
        if (selectedAreaIndex === null) return; // No Area Selected
        else if (selectedAreaIndex === -1) {
            // Unassigning room from any area
            reassignRoom({ variables: { roomId, areaId: null } })
        }
        else if (selectedAreaIndex === localAreaIndex) {
            // Assigning room to a new area
            // Must create new area and remove local area flag
            reassignRoom({ variables: { roomId, areaId: -1 } })
                .then(() => setHasLocalArea(false), () => { })
        }
        else {
            // Assigning to existing area
            reassignRoom({ variables: { roomId, areaId: data?.jobConfiguration.areas[selectedAreaIndex].id } })
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xl">
            <DialogTitle>Option {optionNumber}</DialogTitle>
            <DialogContent style={{ width: "min(max(40vw, 35rem), 95vw)", maxHeight: "70vh", display: "flex" }}>
                <div className="flex-row" style={{ flex: 1, overflowY: "auto", flexWrap: "wrap" }}>
                    {
                        data?.jobConfiguration.areas.flatMap((area, index) =>
                            area.rooms.map(room => <RoomSvg key={room.id} room={room} areaIndex={index} onClick={() => assignRoomToArea(room.id)} />))
                    }
                    {
                        data?.jobConfiguration.unassignedRooms.map(room => <RoomSvg key={room.id} room={room} areaIndex={null} onClick={() => assignRoomToArea(room.id)} />)
                    }
                </div>
                <div style={{ width: "8em", alignItems: "center" }} className="flex-column">
                    <AreaColorButton
                        areaNumber={null}
                        sqft={null}
                        isSelected={selectedAreaIndex === -1}
                        onClick={() => setSelectedAreaIndex(-1)} />
                    {
                        data?.jobConfiguration.areas.map((area, index) => {
                            const sqft = area.rooms.reduce((total, room) => total + (room.sqft ?? 0), 0)

                            return <AreaColorButton
                                key={area.id}
                                areaNumber={index}
                                sqft={sqft}
                                isSelected={selectedAreaIndex === index}
                                onClick={() => setSelectedAreaIndex(index)} />
                        })
                    }
                    {
                        hasLocalArea &&
                        <AreaColorButton
                            areaNumber={localAreaIndex}
                            sqft={0}
                            isSelected={selectedAreaIndex === localAreaIndex}
                            onClick={() => setSelectedAreaIndex(localAreaIndex)} />
                    }

                    {
                        showAddButton &&
                        <CircleAddButton variant="contained" style={{ marginTop: ".5em", marginLeft:"4.5em" }} onClick={() => setHasLocalArea(true)} />
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}