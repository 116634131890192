import clsx from "clsx";
import { S3PdfViewerDialog } from "Components/Files/S3PdfViewers";
import { FlatAlertIcon } from "FlatComponents/Button/FlatAlertButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import FlatDocumentButton from "FlatComponents/Button/FlatDocumentButton";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import FlatUploadFileButton from "FlatComponents/Button/FlatUploadFileButton";
import { useDeleteWorkersCompMutation, WorkersComp } from "generated/graphql";
import { makeEmptyWorkersComp } from "Globals/DataStructures/EmptyDataStructures";
import { dateTimeStrToMdy } from "Globals/DateAndTimeHelpers";
import { isDocumentOverdue } from "Globals/GenericValidators";
import { useState } from "react";
import WorkersCompDialog from "../Dialogs/WorkersCompDialog";

interface WorkersCompTableProps {
    workerId: number;
    toRefetch: string;
    wcs: WorkersComp[];
}

export default function WorkersCompTable({workerId, toRefetch, wcs}: WorkersCompTableProps) {
    const [dialogOpen, setDialogOpen] = useState(false);

    return (<>
        <div className="flat-font profile-right-column-table-container">
            <div className="thin-horizontal-bar grid-colspan-1-3" />
            <p>Workers' Comp</p>
            <div className="grid-15-50-35 padding-left-sm margin-bottom-sm fit-content-height">
                <span className="file-upload-row profile-table-cell">
                    <FlatUploadFileButton
                        color={"var(--flat-red)"}
                        onClick={() => setDialogOpen(true)}
                    />
                </span>

                {wcs.length === 0 && <p className="grid-colspan-1-4">No workers' comp information found</p>}
                {wcs.map(wc => (
                    <WorkersCompTableRow
                        key={wc.id}
                        wc={wc}
                        toRefetchOnUpdate={toRefetch}
                    />
                ))}
            </div>
        </div>

        {dialogOpen && (
            <WorkersCompDialog
                workersComp={makeEmptyWorkersComp(workerId)}
                toRefetchOnUpdate={toRefetch}
                onClose={() => setDialogOpen(false)}
            />
        )}
    </>)
}

interface WorkersCompTableRowProps {
    wc: WorkersComp;
    toRefetchOnUpdate: string;
}

function WorkersCompTableRow({wc, toRefetchOnUpdate}: WorkersCompTableRowProps) {
    const isOverdue = isDocumentOverdue(wc.expirationDate);
    const [uploaderOpen, setUploaderOpen] = useState(false);
    const [viewerOpen, setViewerOpen] = useState(false);

    const [deleteWc, {loading: deleting}] = useDeleteWorkersCompMutation({
        variables: {id: wc.id},
        onError: () => alert("Could not delete workers' comp information"),
        refetchQueries: [toRefetchOnUpdate]
    });

    function onDeleteRow() {
        if (window.confirm("Are you sure you wish to delete this workers' comp record?")) {
            deleteWc();
        }
    }

    return (<>
        <span className="profile-table-cell profile-table-cell-inner profile-table-label-cell">
            {wc.s3FileId ? (
                <FlatDocumentButton
                    color="var(--flat-green)"
                    onClick={() => setViewerOpen(true)}
                    disabled={deleting}
                />
            ) : (
                <FlatUploadFileButton
                    color={"var(--flat-red)"}
                    onClick={() => setUploaderOpen(true)}
                    disabled={deleting}
                />
            )}
        </span>
        <p className="profile-table-cell profile-table-date-cell profile-table-cell-inner">
            {`${dateTimeStrToMdy(wc.activeDate)} - ${dateTimeStrToMdy(wc.expirationDate)}`}
        </p>
        
        <span className="profile-table-cell profile-table-action-cell">
            <span className={clsx({"visibility-hidden": !isOverdue})}>
                <FlatAlertIcon color="var(--flat-orange)"/>
                <p className="flat-font-xsm">Due</p>
            </span>
            <span className="flex-row flex-gap-sm">
                <FlatDeleteButton
                    onClick={onDeleteRow}
                    disabled={deleting}
                />
                <FlatEditButton
                    onClick={() => setUploaderOpen(true)}
                    disabled={deleting}
                />
            </span>
        </span>

        {uploaderOpen && (
            <WorkersCompDialog
                workersComp={wc}
                toRefetchOnUpdate={toRefetchOnUpdate}
                onClose={() => setUploaderOpen(false)}
            />
        )}

        {(wc.s3FileId && viewerOpen) && (
            <S3PdfViewerDialog
                fileId={wc.s3FileId}
                onClose={() => setViewerOpen(false)}
            />
        )}
    </>)
}