import { Select, MenuItem, FormControl, FormLabel } from "@material-ui/core";
import { Market, County } from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { reduce } from "lodash";
import { useState, useMemo } from "react";
import { Row, ServiceArea } from "./ServiceAreasPage";

interface TableFiltersSectionProps {
    serviceAreas: ServiceArea[];
    markets: Market[];
    counties: County[];
    allRows: readonly Row[];
    setDisplayRowIds: (ids: number[]) => void;
    selectedRows: ReadonlySet<number>;
    setSelectedRows: (rows: ReadonlySet<number>) => void;
}

interface Filter {
    marketIds: number[];
    countyIds: number[];
    cities: string[];
    zips: string[];
    serviced: boolean | undefined;
}

export function setIntersection<T>(a: Set<T>, b: Set<T>) {
    return new Set<T>(Array.from(a).filter(aItem => b.has(aItem)));
}

export default function TableFilters({serviceAreas, markets, counties, allRows, setDisplayRowIds, selectedRows, setSelectedRows}: TableFiltersSectionProps) {
    const [includedMarketIds, setIncludedMarketIds] = useState<number[]>([-1]);
    const [includedCountyIds, setIncludedCountyIds] = useState<number[]>([-1]);
    const [includedCities, setIncludedCities] = useState<string[]>(['all']);
    const [includedZips, setIncludedZips] = useState<string[]>(['all']);
    const [servicedFilter, setServicedFilter] = useState<boolean | undefined>(undefined); // undefined to show both serviced/unserviced

    const allServiceAreaIds = useMemo(() => new Set(serviceAreas.map(sa => sa.id)), [serviceAreas]);

    // conveniently encapsulate all filter values together as they change
    // also allows us not to worry about how quickly state updates
    const currentFilter: Filter = {
        marketIds: includedMarketIds,
        countyIds: includedCountyIds,
        cities: includedCities,
        zips: includedZips,
        serviced: servicedFilter
    }

    function runFilter() {
        // apply market filter
        let marketSet: Set<number>;
        if (currentFilter.marketIds.includes(-1)) {
            marketSet = allServiceAreaIds;
        } else {
            marketSet = new Set(allRows.filter(row => (row.marketId > 0 && currentFilter.marketIds.includes(row.marketId))).map(row => row.id));
        }

        // apply county filter
        let countySet: Set<number>;
        if (currentFilter.countyIds.includes(-1)) {
            countySet = allServiceAreaIds;
        } else {
            countySet = new Set(allRows.filter(row => (row.countyId > 0 && currentFilter.countyIds.includes(row.countyId))).map(row => row.id));
        }

        // apply city filter
        let citySet: Set<number>;
        if (currentFilter.cities.includes("all")) {
            citySet = allServiceAreaIds;
        } else {
            citySet = new Set(allRows.filter(row => currentFilter.cities.includes(row.city)).map(row => row.id));
        }

        // apply zip filter
        let zipSet: Set<number>;
        if (currentFilter.zips.includes("all")) {
            zipSet = allServiceAreaIds;
        } else {
            zipSet = new Set(allRows.filter(row => currentFilter.zips.includes(row.zip)).map(row => row.id));
        }

        // apply serviced filter
        let servicedSet = allServiceAreaIds;
        if (!currentFilter.serviced) {
            servicedSet = allServiceAreaIds;
        } else {
            servicedSet = new Set(allRows.filter(row => currentFilter.serviced === servicedStrToBool(row.serviced)).map(row => row.id));
        }

        let allFilteredSets: Set<number>[] = [marketSet, countySet, citySet, zipSet, servicedSet];

        let initialSet = allServiceAreaIds;
        let newDisplayIds = Array.from(reduce(allFilteredSets, (a: Set<number>, b: Set<number>) => setIntersection(a, b), initialSet));

        setDisplayRowIds(newDisplayIds);

        // deselect any row which has already been selected, but is not included in this filter
        let newSelectedRows = Array.from(selectedRows).filter(rId => newDisplayIds.includes(rId));
        setSelectedRows(new Set(newSelectedRows));
    }

    // TODO: filter out valid selectable options (e.g., if we apply the "Detroit" market filter, we shouldn't be able to select any cities which are not in that market)

    function servicedStrToBool(servicedStr: string) {
        if (servicedStr === "Yes") {
            return true;
        }

        return false;
    }

    function onChangeMarketIdSelections(newSelection: number[]) {
        let newMarketFilter: number[];

        if (newSelection.length === 0) {  // when all filters are deselected, all becomes the value
            newMarketFilter = [-1];
        } else if (includedMarketIds.includes(-1)) {  // filter was all before, so remove "all"
            newMarketFilter = newSelection.filter(id => id !== -1)
        } else if (newSelection.includes(-1)) {
            newMarketFilter = [-1];
        } else {
            newMarketFilter = newSelection;
        }

        setIncludedMarketIds(newMarketFilter);
        currentFilter['marketIds'] = newMarketFilter;
        runFilter();
    }

    function onChangeCountyIdSelections(newSelection: number[]) {
        let newCountyFilter: number[];
        if (newSelection.length === 0) {  // when all filters are deselected, all becomes the value
            newCountyFilter = [-1];
        } else if (includedCountyIds.includes(-1)) {  // filter was all before, so remove "all"
            newCountyFilter = newSelection.filter(id => id !== -1);
        } else if (newSelection.includes(-1)) {
            newCountyFilter = [-1];
        } else {
            newCountyFilter = newSelection;
        }

        setIncludedCountyIds(newCountyFilter);
        currentFilter['countyIds'] = newCountyFilter;
        runFilter();
    }

    function onChangeCitySelections(newSelection: string[]) {
        let newCityFilter: string[];

        if (newSelection.length === 0) {
            newCityFilter = ['all'];
        } else if (includedCities.includes("all")) {  // filter was all before, so remove "all"
            newCityFilter = newSelection.filter(city => city !== "all");
        } else if (newSelection.includes("all")) {
            newCityFilter = ["all"];
        } else {
            newCityFilter = newSelection;
        }

        setIncludedCities(newCityFilter);
        currentFilter['cities'] = newCityFilter;
        runFilter();
    }

    function onChangeZipSelections(newSelection: string[]) {
        let newZipFilter: string[];

        if (newSelection.length === 0) {
            newZipFilter = ['all'];
        } else if (includedZips.includes("all")) {  // filter was all before, so remove "all"
            newZipFilter = newSelection.filter(city => city !== "all");
        } else if (newSelection.includes("all")) {
            newZipFilter = ["all"];
        } else {
            newZipFilter = newSelection;
        }

        setIncludedZips(newZipFilter);
        currentFilter['zips'] = newZipFilter;
        runFilter();
    }

    function onChangeServicedSelection(newSelection: string) {
        let newFilter: boolean | undefined;

        if (newSelection === "all") {  // show both serviced/unserviced
            newFilter = undefined; 
        } else if (newSelection === "true") {
            newFilter = true;
        } else {
            newFilter = false;
        }

        setServicedFilter(newFilter);
        currentFilter['serviced'] = newFilter;
        runFilter();
    }

    let allCities = useMemo(() => {
        let cities = Array.from(new Set(serviceAreas.map(sa => sa.city)));
        cities.sort();
        return cities;
    }, [serviceAreas]);
    let allZips = useMemo(() => {
        let zips =  Array.from(new Set(serviceAreas.map(sa => sa.zip)));
        zips.sort();
        return zips;
    }, [serviceAreas]);

    return (
        <>
            <h5>Filters</h5>
            <div className="flex-column flex-gap-sm">
                <div className="flex-row flex-gap-sm">
                    <FormControl>
                        <FormLabel>Markets</FormLabel>
                        <Select
                            multiple
                            style={{width: 300}}
                            value={includedMarketIds}
                            onChange={(e) => onChangeMarketIdSelections(e.target.value as number[])}
                        >
                            <MenuItem value={-1} key="mkt-fltr-all">All</MenuItem>
                            <MenuItem value={0} key="mkt-fltr-0">Unassigned</MenuItem>
                            {markets.map(m => (
                                <MenuItem value={m.id} key={`mkt-fltr-all-${m.id}`}>{m.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Counties</FormLabel>
                        <Select
                            multiple
                            style={{width: 300}}
                            value={includedCountyIds}
                            onChange={(e) => onChangeCountyIdSelections(e.target.value as number[])}
                        >
                            <MenuItem value={-1} key="cnty-fltr-all">All</MenuItem>
                            {counties.map(c => (
                                <MenuItem value={c.id} key={`cnty-fltr-all-${c.id}`}>{c.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div className="flex-row flex-gap-sm">
                    <FormControl>
                        <FormLabel>Cities</FormLabel>
                        <Select
                            multiple
                            style={{width: 300}}
                            value={includedCities}
                            onChange={(e) => onChangeCitySelections(e.target.value as string[])}
                        >
                            <MenuItem value={"all"} key="city-fltr-all">All</MenuItem>
                            {allCities.map(c => (
                                <MenuItem value={c} key={`city-fltr-all-${c}`}>{c}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Zips</FormLabel>
                        <Select
                            multiple
                            style={{width: 300}}
                            value={includedZips}
                            onChange={(e) => onChangeZipSelections(e.target.value as string[])}
                        >
                            <MenuItem value={"all"} key="zip-fltr-all">All</MenuItem>
                            {allZips.map(z => (
                                <MenuItem value={z} key={`zip-fltr-all-${z}`}>{z}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div className="flex-row flex-gap-sm">
                    <FormControl>
                        <FormLabel>Serviced?</FormLabel>
                        <Select
                            style={{width: 300}}
                            value={isNullOrUndefined(servicedFilter) ? 'all' : (servicedFilter ? "true" : "false")}
                            onChange={(e) => onChangeServicedSelection(e.target.value as string)}
                        >
                            <MenuItem value={'all'} key="serv-fltr-all">All</MenuItem>
                            <MenuItem value={"true"} key={`serv-fltr-true`}>Serviced</MenuItem>
                            <MenuItem value={"false"} key={`serv-fltr-false`}>Not Serviced</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </>
    )
}