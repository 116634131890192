import clsx from "clsx";
import { ComponentType } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
    CellAndLocation,
    extractPickedCellAndLocation,
    moveCell,
    pickCell,
    selectPickedCell,
    selectPickNPlaceCell,
    TypedPickNPlaceCell,
    unpickCell,
} from "Redux/pickNPlaceReducer";
import { store } from "Redux/store";

interface BasePickNPlaceCellProps<T> {
    tableName: string;
    colIndex: number;
    rowIndex: number;
    RenderCell: ComponentType<TypedPickNPlaceCell<T>>;
    isInAppendedRow: boolean;
    canPlaceAtCell?: (
        selectedCell: CellAndLocation<any>,
        currentCell: CellAndLocation<T | undefined>
    ) => boolean;
    canPickCell?: (cell: CellAndLocation<T>) => boolean;
    onCellPlaced?: (oldCell: CellAndLocation<any>, newCell: CellAndLocation<T>) => void;
    onRightClicked?: (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        cell: CellAndLocation<T | undefined>
    ) => void;
    shouldHighlightCell?: (cell: CellAndLocation<any>) => boolean;
    cellBackgroundColor?: string;
}

interface PickNPlaceCellProps<T> extends BasePickNPlaceCellProps<T> {
    shadeColor?: string;
}

export default function PickNPlaceCell<T>({
    tableName,
    colIndex,
    rowIndex,
    RenderCell,
    isInAppendedRow,
    shadeColor,
    canPlaceAtCell,
    canPickCell,
    onCellPlaced,
    onRightClicked,
    shouldHighlightCell,
    cellBackgroundColor,
}: PickNPlaceCellProps<T>) {
    const dispatch = useAppDispatch();
    const cell = useAppSelector(selectPickNPlaceCell<T>(tableName, colIndex, rowIndex));

    async function onClicked() {
        var storeState = store.getState();

        if (storeState.pickNPlace.isCellPicked) {
            const selectedCellAndLocation = extractPickedCellAndLocation(storeState.pickNPlace)!;
            const fromLocation = selectedCellAndLocation.location;
            const isSameColumn =
                fromLocation.table === tableName && fromLocation.column === colIndex;
            const isSameLocation = isSameColumn && fromLocation.row === rowIndex;

            if (isSameLocation) {
                // Deleselect cell
                dispatch(unpickCell());
            } else if (cell === undefined && (!isInAppendedRow || !isSameColumn)) {
                var currentCell = {
                    cell,
                    location: { table: tableName, column: colIndex, row: rowIndex },
                };

                if (
                    canPlaceAtCell === undefined ||
                    (await canPlaceAtCell(selectedCellAndLocation, currentCell))
                ) {
                    // Makes sure the target cell is empty
                    // Place here

                    const newRow = isInAppendedRow ? undefined : rowIndex;
                    dispatch(moveCell(fromLocation, tableName, colIndex, newRow));

                    var lastDefinedRowIndex =
                        storeState.pickNPlace.tables[fromLocation.table].tableRows;

                    onCellPlaced?.(
                        { cell: cell, location: fromLocation },
                        {
                            cell: selectedCellAndLocation.cell,
                            location: { table: tableName, column: colIndex, row: newRow ?? -1 },
                        }
                    );

                    // Update position of any cells below old position and last defined row
                    Object.keys(
                        storeState.pickNPlace.tables[fromLocation.table].tableData[
                            fromLocation.column
                        ]
                    )
                        .map((row) => +row)
                        .filter((row) => row > lastDefinedRowIndex && row !== fromLocation.row)
                        .sort()
                        .forEach((row) => {
                            dispatch(
                                moveCell(
                                    { ...fromLocation, row },
                                    fromLocation.table,
                                    fromLocation.column,
                                    undefined
                                )
                            );
                        });
                }
            }
        } else if (cell !== undefined) {
            if (
                canPickCell?.({
                    cell,
                    location: { table: tableName, row: rowIndex, column: colIndex },
                }) ??
                true
            )
                // Pick this cell
                dispatch(pickCell(tableName, colIndex, rowIndex));
        }
    }

    const hasValue = cell !== undefined;
    const selected = cell?.isCellSelected ?? false;
    const isHighlighted =
        shouldHighlightCell?.({
            cell: cell,
            location: { table: tableName, row: rowIndex, column: colIndex },
        }) ?? false;

    const onContext: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void = (e) =>
        onRightClicked?.(e, {
            cell,
            location: { table: tableName, column: colIndex, row: rowIndex },
        });

    return (
        <td
            onClick={onClicked}
            onContextMenu={onContext}
            className={clsx("cell cell-shadow", {
                "selected-cell": selected,
                "highlighted-cell": isHighlighted && !selected,
            })}
            style={{ position: "relative", zIndex: 100, backgroundColor: cellBackgroundColor }}
        >
            {hasValue && (
                <RenderCell
                    {...cell}
                    openContextMenu={onContext}
                />
            )}
            <div
                hidden={shadeColor === undefined}
                className={clsx("render-over-parent")}
                style={{ backgroundColor: shadeColor }}
            />
        </td>
    );
}

interface ShadedPickNPlaceCellProps<T> extends BasePickNPlaceCellProps<T> {
    shadeCellWhenOtherPicked: (
        selectedCell: CellAndLocation<any>,
        currentCell: CellAndLocation<T>
    ) => string | undefined;
}

export function ShadedPickNPlaceCell<T>({
    shadeCellWhenOtherPicked,
    ...otherProps
}: ShadedPickNPlaceCellProps<T>) {
    const { tableName: table, colIndex: column, rowIndex: row } = otherProps;

    const selectedCell = useAppSelector(selectPickedCell);
    const currentCell = useAppSelector(selectPickNPlaceCell<T>(table, column, row));
    const colorToRenderAs =
        selectedCell !== undefined
            ? shadeCellWhenOtherPicked(selectedCell, {
                  cell: currentCell,
                  location: { table, column, row },
              })
            : undefined;

    return (
        <PickNPlaceCell
            {...otherProps}
            shadeColor={colorToRenderAs}
        />
    );
}
