import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Menu, MenuItem } from "@material-ui/core";
import { Discount, DiscountsOnJob, GetJobConfigurationDocument, namedOperations, useGetPromotionOptionQuery, useUpdateAppliedPromotionMutation, useUpdateJobDiscountMutation } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import QuoteCardIconButton from "./QuoteCardIconButton";

interface AddDiscountButtonProps {
    jobConfigurationId: number,
    discounts: DiscountsOnJob | null,
    pendingPriceRequestResponse: boolean,
    isPromotionApplied: boolean
}

export default function AddDiscountButton({ jobConfigurationId, discounts, pendingPriceRequestResponse, isPromotionApplied }: AddDiscountButtonProps) {
    const availableDiscounts = discounts?.availableDiscounts ?? [];
    const isOverrideApplied = discounts?.isOverrideApplied ?? false;

    const {data: promoData} = useGetPromotionOptionQuery({variables: {jobConfigurationId: jobConfigurationId}});
    const promoOption = promoData?.promotionOption;

    const disableAddingMoreDiscounts = isOverrideApplied || availableDiscounts.length === 0 || pendingPriceRequestResponse;

    const [discountMenuAnchor, setDiscountMenuAnchor] = useState<Element | null>(null);

    //To be used for override amount menu
    const handleDiscountMenuClose = () => setDiscountMenuAnchor(null)
    const handleDiscountMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => setDiscountMenuAnchor(event.currentTarget)

    const [updateDiscount] = useUpdateJobDiscountMutation({
        refetchQueries: [
            {
                query: GetJobConfigurationDocument,
                variables: { jobConfigurationId },
                fetchPolicy: "network-only"
            },
            namedOperations.Query.CalculatePriceForLocalConfiguration
        ],
        awaitRefetchQueries: true
    });

    function handleDiscountOptionClicked(discount: Discount) {
        if (discount.id !== 1) {
            // Cant add an override
            updateDiscount(
                {
                    variables:
                    {
                        jobConfigurationId: jobConfigurationId ?? 0,
                        discountId: discount.id,
                        isRemoval: false
                    }
                }
            )
        }
        handleDiscountMenuClose();
    }

    const [applyPromotion] = useUpdateAppliedPromotionMutation({
        onCompleted: () => handleDiscountMenuClose(),
        onError: () => alert("Could not apply promotion"),
        refetchQueries: [
            {
                query: GetJobConfigurationDocument,
                variables: { jobConfigurationId },
                fetchPolicy: "network-only"
            },
            namedOperations.Query.CalculatePriceForLocalConfiguration
        ],
        awaitRefetchQueries: true
    });

    function handlePromoOptionClick() {
        if (isNotNullOrUndefined(promoOption)) {
            applyPromotion({
                variables: {jobConfigurationId: jobConfigurationId, basePromotionId: promoOption?.id}
            });
        }
    }

    // <Menu /> doesn't accept fragments, so must do as a list instead
    const menuItems: ReactJSXElement[] = [];
    if (isNotNullOrUndefined(promoOption) && !isPromotionApplied)  {
        menuItems.push(
            <MenuItem
                key="promo-discount"
                onClick={handlePromoOptionClick}
            >{promoOption!.name}</MenuItem>
        );
    } else {
        availableDiscounts.forEach(ad => {
            menuItems.push(
                <MenuItem
                    key={`discount-${ad.id}`}
                    onClick={() => handleDiscountOptionClicked(ad)}
                >{ad.name}</MenuItem>
            )
        });
    }

    return (
        <>
            <QuoteCardIconButton
                variant="outlined"
                color="secondary"
                disabled={disableAddingMoreDiscounts}
                onClick={handleDiscountMenuClick}>
                D
            </QuoteCardIconButton>
            <Menu
                id='add-discount-menu'
                key='add-discount-menu'
                keepMounted
                anchorEl={discountMenuAnchor}
                open={Boolean(discountMenuAnchor)}
                onClose={handleDiscountMenuClose}
            >
                {menuItems}
            </Menu>
        </>
    )
}