import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { JobServiceGroup } from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/BreakdownTableUtils";
import { useRef, useState } from "react";
import FormulaCard from "./FormulaCard";
import "./FormulaCardStyle.css";

interface ServiceGroupPriceProps {
    originalServiceGroup: JobServiceGroup | null; // will be null if service is newly added
    editableServiceGroup: JobServiceGroup;
}

export default function ServiceGroupPriceText({
    originalServiceGroup,
    editableServiceGroup
}: ServiceGroupPriceProps) {
    const [isShown, setIsShown] = useState(false);

    const elementRef = useRef<HTMLDivElement>(null);
    const endRef = useRef<HTMLDivElement>(null);

    const setElementLocation = () => {
        const winY = window.innerHeight;
        const endPointH = endRef.current?.offsetHeight ?? 0;
        const topY = elementRef.current?.getBoundingClientRect().top ?? 0;
        const bottomY = elementRef.current?.getBoundingClientRect().bottom ?? 0;
        const endHigh = elementRef.current?.getBoundingClientRect().height ?? 0;

        //default position
        let top = `${endPointH}px`;
        let bottom = "";
        let ok = true;
        
        //determine location
        let position = winY > bottomY ? "top" : topY > endPointH + endHigh ? "bottom" : "not";
        if (position === "top") {
            top = `${endPointH}px`;
            bottom = "";
        } else if (position === "bottom") {
            top = "";
            bottom = `${endPointH}px`;
        } else {
            ok = false;
        }

        if (isNotNullOrUndefined(elementRef.current)) {
            if (ok) {
                elementRef!.current!.style.top = top ?? "";
                elementRef!.current!.style.bottom = bottom ?? "";
            } else {
                setIsShown(false); //not visible
            }
        }
    };

    // nw listener for resize
    window.addEventListener("resize", setElementLocation);

    function handleMouseLeave(event: any) {
        event.target.parentElement.classList.remove("pop-top");
        setIsShown(false);
    }

    function handleMouseOver() {
        setIsShown(true);
        setElementLocation();
    }

    const effectiveOriginalPrice = originalServiceGroup ? calculateServiceGroupEffectivePrice(originalServiceGroup) : 0;
    const effectiveEditablePrice = calculateServiceGroupEffectivePrice(editableServiceGroup);
    const deltaPrice = +(effectiveEditablePrice - effectiveOriginalPrice).toFixed(2);
    let displayPrice, prefix;
    let textClassName = "flat-font margin-none"
    if (deltaPrice === 0) {
        displayPrice = editableServiceGroup.price;
        prefix = "$";
    } else if (deltaPrice > 0) {
        displayPrice = deltaPrice;
        prefix = "+$";
        textClassName += " success-text-important";
    } else {
        displayPrice = deltaPrice * -1;  // make it positive
        prefix = "-$";
        textClassName += " error-text-important";
    }

    const displayText = `${prefix}${displayPrice.toFixed(2)}`;

    return (
        <div style={{ position: "relative", zIndex: "9px" }}>
                <p
                    className={textClassName}
                    onMouseEnter={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                    ref={endRef}
                >
                    {displayText}
                </p>
            
            <FormulaCard
                renderOverElement={elementRef}
                rooms={editableServiceGroup.rooms}
                show={isShown}
            />
        </div>
    );
}


// this is the sum of the price of all services in the group which are performed by WOF
function calculateServiceGroupEffectivePrice(group: JobServiceGroup) {
    return group.rooms
        .filter(r => !r.service.customerDoesService && ! r.service.isDeleted)
        .map(r => r.service.price)
        .reduce((sum, next) => sum + next, 0);
}
