import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatLockAndUnlockButton from "FlatComponents/Button/FlatLockAndUnlockButton";
import FlatSection from "FlatComponents/Layout/FlatSection";
import {
    AppliedDiscount, Area, ChargeableServicePricingDetails, CustomService, CustomServiceInput,
    namedOperations,
    ServiceForRoomInput, useGetAllAreasForJobQuery,
    useGetChargeableServicePricingDetailsQuery,
    useGetJobConfigurationQuery,
    useGetJobServicesByTypeQuery,
    useUpdateChargeablesMutation
} from "generated/graphql";
import { prepareEditableCustomService } from "Globals/dataPreparationUtils";
import { emptyEditableCustomService } from "Globals/DataStructures/EmptyDataStructures";
import { isEmptyString, isNullOrUndefined } from "Globals/GenericValidators";
import { FLOOR_PREP_ID, FURNITURE_ID, FURNITURE_NONE_ID, PLYWOOD_ID, R_AND_R_ID, SHIM_ID } from "Globals/globalConstants";
import { getNextMostNegativeNumber, getUnique, numericArraysEq } from "Globals/Helpers";
import _, { isEqual } from "lodash";
import {
    calculateServicePrice,
    JobServiceGroup,
    JobServiceGroups, RoomServiceDetail
} from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/BreakdownTableUtils";
import AdditionalServicesContextMenu, { ADDITIONAL_SERVICE_CONTEXT_MENU_ID } from "Pages/Admin/ProjectManagement/SellSheet/InstallationDetailsEditor/AdditionalServicesContextMenu";
import { useEffect, useMemo, useRef, useState } from "react";
import { useContextMenu } from "react-contexify";
import { useAppSelector } from "Redux/hooks";
import { selectJobConfigurationId, selectMSRPScalar, selectUsageContext } from "Redux/pricingCenterReducer";
import CustomServiceEditorRow, { AreaIdToRooms, customToEditable, EditableCustomService } from "./CustomServiceEditorRow";
import FloorPrepServiceEditorGroup from "./FloorPrepServiceEditorSection";
import GeneralGroupedServiceEditorRow from "./GeneralGroupedServiceEditorRow";
import { FullEditInstallationServicesMenu } from "Pages/Admin/ProjectManagement/SellSheet/InstallationDetailsEditor/InstallationDetailsButton";
import FlatSelect, { FlatSelectOption } from "FlatComponents/Inputs/FlatSelect";
import { SingleValue } from "react-select";

interface ChargeablesEditorProps {
    serviceGroups: JobServiceGroups;
    customServices: CustomService[];
    discounts: AppliedDiscount[];
    setChangesPresent: (changesPresent: boolean) => void;
}

export default function ChargeablesEditor({
    serviceGroups: originalServiceGroups,
    customServices,
    setChangesPresent: tellParentWhetherChangesPresent,
    discounts
}: ChargeablesEditorProps) {
    const [showEditAreaDetail, setShowEditAreaDetail] = useState<boolean>(false);
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const {data: allAreasData} = useGetAllAreasForJobQuery({
        variables: {jobConfigurationId: jobConfigurationId},
        skip: jobConfigurationId < 1
    });
    const allAreas = useMemo(
        () => allAreasData?.allAreasForJob ?? [] as Area[],
        [allAreasData]
    );

    // transforms the original services to the editable form
    // this set of original services isn't to be edited, but we put it in this form
    // for easier comparison between this and the set that gets updated
    const originalCustomServices = useMemo(() => {
        return customServices.map(customToEditable);
    }, [customServices]);
    
    // this is the grouping that changes will actually be made to
    const [editableServiceGroups, setEditableServiceGroups] = useState(
        _.cloneDeep(originalServiceGroups)
    );

    const {nonFp: nonFloorPrepEditables, fp: floorPrepEditables} = useMemo(() => {
        return partitionServiceGroups(editableServiceGroups);
    }, [editableServiceGroups]);
    const {nonFp: nonFloorPrepOriginals, fp: floorPrepOriginals} = useMemo(() => {
        return partitionServiceGroups(originalServiceGroups);
    }, [originalServiceGroups]);
    
    const { data: furnitureJobServiceData } = useGetJobServicesByTypeQuery({
        variables: { serviceTypeId: FURNITURE_ID }
    });
    // furniture services that are present on this job
    const presentFurnitureJobServiceIds = getUnique(
        Object.keys(nonFloorPrepEditables)
        .filter(jsId => nonFloorPrepEditables[+jsId].serviceTypeId === FURNITURE_ID)
        .map(jsId => +jsId)
    );
    // don't show options that are already present in the job (they have already been added)
    const furnitureOptions = (furnitureJobServiceData?.jobServicesByType ?? []).filter(fJs => !(presentFurnitureJobServiceIds.includes(fJs.id) || fJs.id === FURNITURE_NONE_ID));
    const furnitureJobServiceIdsNotInJob = furnitureOptions
        .filter(s => !presentFurnitureJobServiceIds.includes(s.id)).map(s => s.id);

    const { data: rrJobServiceData } = useGetJobServicesByTypeQuery({
        variables: { serviceTypeId: R_AND_R_ID }
    });
    const presentRrJobServiceIds = getUnique(
        Object.keys(nonFloorPrepEditables)
        .filter(jsId => nonFloorPrepEditables[+jsId].serviceTypeId === R_AND_R_ID)
        .map(jsId => +jsId)
    );
    const rrServiceOptions = rrJobServiceData?.jobServicesByType ?? [];
    const rrJobServiceIdsNotInJob = rrServiceOptions
        .filter(s => !presentRrJobServiceIds.includes(s.id)).map(s => s.id);
    
    const { data: fpJobServiceData } = useGetJobServicesByTypeQuery({
        variables: { serviceTypeId: FLOOR_PREP_ID }
    });
    const presentFpServiceIds = getUnique(
        Object.keys(floorPrepEditables)
        .filter(jsId => floorPrepEditables[+jsId].serviceTypeId === FLOOR_PREP_ID)
        .map(jsId => +jsId)
    );
    const fpServiceOptions = fpJobServiceData?.jobServicesByType ?? [];
    const fpJobServiceIdsNotInJob = fpServiceOptions
        .filter(s => !presentFpServiceIds.includes(s.id)).map(s => s.id);

    const hasShimService = Object.keys(floorPrepEditables).includes(SHIM_ID.toString())

    const { data: chargeablesPricingDetailsData } = useGetChargeableServicePricingDetailsQuery({
        onError: () => alert("Failed to load chargeable service pricing data")
    });
    const chargeablePricingDetails = (chargeablesPricingDetailsData?.chargeableServicePricingDetails ?? [])!;

    // holds the version of the service that changes will be stored in
    const [editableCustoms, setEditableCustoms] = useState<EditableCustomService[]>(
        _.cloneDeep(originalCustomServices)
    );
            
    // when the job config is refreshed by the user, need to reset what the original state of the services is
    // the original state meaning the most recent state present in the databsae, not necessarily what they originally loaded on the page
    useEffect(() => {
        setEditableServiceGroups(_.cloneDeep(originalServiceGroups));
    }, [originalServiceGroups, setEditableServiceGroups, originalCustomServices, setEditableCustoms]);

    useEffect(() => {
        setEditableCustoms(_.cloneDeep(originalCustomServices));
    }, [originalCustomServices, setEditableCustoms])

    const intervalTimerId = useRef<ReturnType<typeof setInterval>>(); // useRef because changes to this should not cause rerender

    const [changesPresent, setChangesPresent] = useState(false);
    useEffect(() => {
        const groupsDiffer = !serviceGroupsEqual(originalServiceGroups, editableServiceGroups)
                 || !isEqual(originalCustomServices, editableCustoms);
        setChangesPresent(groupsDiffer);  // used locally
        tellParentWhetherChangesPresent(groupsDiffer);  // used by parent

        if (!groupsDiffer && intervalTimerId.current) clearInterval(intervalTimerId.current);
    }, [tellParentWhetherChangesPresent, editableCustoms, editableServiceGroups, originalCustomServices, originalServiceGroups]);

    /**
     * Removes a JobServiceGroup from editableServices.
     * Each service is examined individually. If that service is new (hasn't been added to DB -> has neg. id),
     * then it is fully removed from the set of editable services. Otherwise, it is just marked as deleted so that
     * it can still be displayed, and a list of changes can still be derived. In this case, all of the value will be reset to their originals as well
     */
    function removeServiceGroup(jobServiceId: number) {
        if (!Object.keys(editableServiceGroups).includes(jobServiceId.toString())) {
            throw new Error(`Trying to remove service group for jobServiceId or ${jobServiceId}, but no such group exists`);
        }

        const updated = {...editableServiceGroups};
        updated[jobServiceId].rooms.forEach(r => {
            if (r.service.id > 0) {  // service already exists in DB - keep it to show user changes
                r.service.isDeleted = true;
                // reset the service to the state it has in DB
                const thisOgService = originalServiceGroups[jobServiceId].rooms.find(rm => r.id === rm.id)!.service;
                r.service.laborAmount = thisOgService.laborAmount;
                r.service.price = thisOgService.price;
                r.service.customerDoesService = thisOgService.customerDoesService;
            }
        });

        // rooms that have not yet been flagged as deleted need to be actually deleted
        const flaggedAsDeleted = updated[jobServiceId].rooms.filter(r => r.service.isDeleted);
        updated[jobServiceId].rooms = flaggedAsDeleted;
        if (flaggedAsDeleted.length === 0) {
            // in this case, the entire group was new, so we remove it entirely
            delete updated[jobServiceId];
        }

        setEditableServiceGroups(updated);
    }
    
    function addFurnitureService(jsId: number) {
        const thisJobService = furnitureOptions.find(opt => opt.id === jsId)!;
        const thisServiceGroup: JobServiceGroup = {
            laborAmount: 0,
            laborPriceUnit: thisJobService.priceUnit,
            serviceTypeId: FURNITURE_ID,
            serviceType: "Furniture",
            serviceDescription: thisJobService.description,
            jobServiceId: jsId,
            price: 0,  // no rooms are part of the service yet
            rooms: []
        }

        addServiceGroup(thisServiceGroup);
    }

    function addRrService(jsId: number) {
        const thisJobService = rrServiceOptions.find(opt => opt.id === jsId)!;

        const thisServiceGroup: JobServiceGroup = {
            laborAmount: 0,
            laborPriceUnit: thisJobService.priceUnit,
            serviceTypeId: R_AND_R_ID,
            serviceType: "R&R",
            serviceDescription: thisJobService.description,
            jobServiceId: jsId,
            price: 0,  // no rooms are part of the service yet
            rooms: []
        }

        addServiceGroup(thisServiceGroup);
    }

    function addFloorPrepService(jsId: number) {
        const thisJobService = fpServiceOptions.find(opt => opt.id === jsId)!;

        const thisServiceGroup: JobServiceGroup = {
            laborAmount: 0,
            laborPriceUnit: thisJobService.priceUnit,
            serviceTypeId: FLOOR_PREP_ID,
            serviceType: "Floor Prep",
            serviceDescription: thisJobService.description,
            jobServiceId: jsId,
            price: 0,  // no rooms are part of the service yet
            rooms: []
        }

        addServiceGroup(thisServiceGroup);
    }

    function addShimService() {
        const thisServiceGroup: JobServiceGroup = {
            laborAmount: 0,
            laborPriceUnit: "lnft",
            serviceTypeId: FLOOR_PREP_ID,
            serviceType: "",
            serviceDescription: "Shim",
            jobServiceId: SHIM_ID,
            price: 0,  // no rooms are part of the service yet
            rooms: []
        }

        addServiceGroup(thisServiceGroup);
    }

    function getAllServiceIds() {
        return Object.keys(editableServiceGroups).flatMap(jsId => {
            return editableServiceGroups[+jsId].rooms.flatMap(r => r.service.id);
        });
    }

    const getNextServiceId = () => getNextMostNegativeNumber(getAllServiceIds());

    const msrpScalar = useAppSelector(selectMSRPScalar);

    function addServiceGroup(newGroup: JobServiceGroup) {
        const jsId = newGroup.jobServiceId;
        if (Object.keys(editableServiceGroups).includes(jsId.toString())) {
            throw new Error(`Trying to add service group for jobServiceId or ${jsId}, but such a group already exists`);
        }

        if (newGroup.rooms.length > 0) {
            const thisServicePricing = chargeablePricingDetails.find(details => details.jobServiceId === jsId);
            if (!thisServicePricing) throw new Error(`Could not find pricing details for job service with ID ${jsId}`);
            populateJobServiceGroupPrices(newGroup, discounts, thisServicePricing, msrpScalar);
        } // else no rooms have been added, can't calc pricing (e.g., in the case of build up - this isn't determined until a room is added)

        const updatedEditable = {...editableServiceGroups};
        updatedEditable[jsId] = newGroup;
        setEditableServiceGroups(updatedEditable);
    }

    function updateServiceGroup(jobServiceId: number, updatedGroup: JobServiceGroup) {
        if ([R_AND_R_ID, FURNITURE_ID, FLOOR_PREP_ID].includes(updatedGroup.serviceTypeId)) {
            // need to recalculate the price (because labor amounts may have potentially changed)
            if (jobServiceId === PLYWOOD_ID) {
                // price calculation works different for build up - build up may actually be composed of multiple services
                populateBuildUpServiceGroupPrices(updatedGroup, discounts, chargeablePricingDetails, msrpScalar);
            } else {
                const thisServicePricing = chargeablePricingDetails.find(details => details.jobServiceId === jobServiceId);
                if (!thisServicePricing) throw new Error(`Could not find pricing details for job service with ID ${jobServiceId}`);
                populateJobServiceGroupPrices(updatedGroup, discounts, thisServicePricing, msrpScalar);
            }
        } // else price won't change (because labor amounts don't change)
        
        let updatedServiceGroups = { ...editableServiceGroups };
        updatedServiceGroups[jobServiceId] = updatedGroup;
        setEditableServiceGroups(updatedServiceGroups);

        // reset the timer interval for warning the user to submit changes
        if (!isEqual(originalServiceGroups[jobServiceId], updatedGroup)) {
            // if the existing interval isn't cleared before a new one is set, we lose its ID and can never clear it
            if (intervalTimerId.current) clearInterval(intervalTimerId.current);
            intervalTimerId.current = setInterval(
                () => alert("Submit or discard your changes: breakdown can't be updated when changes are present"),
                30000
            );
        }
    }

    function addCustomService() {
        const newService: EditableCustomService = {
            ...emptyEditableCustomService,
            description: "Custom Service...",
            id: getNextMostNegativeNumber(editableCustoms.map(s => s.id))
        }
        setEditableCustoms([...editableCustoms, newService]);
    }

    function updateCustomService(updatedService: EditableCustomService) {
        let updatedAdditionalServices = [...editableCustoms];
        const editedIdx = editableCustoms.findIndex(s => s.id === updatedService.id);
        const hasChanges = !isEqual(editableCustoms[editedIdx], updatedService);
        updatedAdditionalServices[editedIdx] = {...updatedService};
        setEditableCustoms(updatedAdditionalServices);

        if (hasChanges) {
            // if the existing interval isn't cleared before a new one is set, we lose its ID and can never clear it
            if (intervalTimerId.current) clearInterval(intervalTimerId.current);
            intervalTimerId.current = setInterval(
                () =>
                    alert(
                        "Submit or discard your changes: breakdown can't be updated when changes are present"
                    ),
                30000
            );
        }
    }

    const [updateChargeables] = useUpdateChargeablesMutation({
        onError: () => alert("Could not apply changes"),
        refetchQueries: [
            namedOperations.Query.GetJobBreakdown,
            namedOperations.Query.GetPricingSummary,
        ],
    });

    // finds all services which have been added, updated, and removed
    function extractChangedServicesForRooms(): {upsertedServices: ServiceForRoomInput[], deletedServiceIds: number[]} {
        const upsertedServices: ServiceForRoomInput[] = [];
        const deletedServiceIds: number[] = [];

        Object.keys(editableServiceGroups).forEach((key) => {
            let jsId = +key;
            editableServiceGroups[jsId].rooms.forEach((r) => {
                const {price: _, sqftScaleFactor: __, lnftScaleFactor: ___, isDeleted, ...editableService } = r.service;
                if (isDeleted) {
                    deletedServiceIds.push(editableService.id);
                } else if (editableService.id < 1) {  // service is new
                    upsertedServices.push(editableService)
                } else {
                    // check to see if service was updated - ignore it if it wasn't
                    const ogService = originalServiceGroups[jsId].rooms.find(rm => rm.id === r.id)!.service;
                    if (
                        (ogService.customerDoesService !== editableService.customerDoesService)
                        || (ogService.laborAmount !== editableService.laborAmount)
                    ) {
                        upsertedServices.push(editableService);
                    } 
                }
            });
        });

        return {upsertedServices, deletedServiceIds};
    }

    // finds all custom services which have been changed, aded, or deleted
    function extractValidatePrepareCustomServices(): {upsertedServices: CustomServiceInput[], deletedServiceIds: number[]} | undefined {
        if (!customServicesValid(editableCustoms)) {
            return undefined;
        }

        const upsertedServices: CustomServiceInput[] = [];
        const deletedServiceIds: number[] = [];
        editableCustoms.forEach(editable => {
            if (editable.isDeleted) {
                deletedServiceIds.push(editable.id);
            } else {
                const original = originalCustomServices.find(og => og.id === editable.id);
                // add services which 
                if (!original || !isEqual(original, editable)) {
                    upsertedServices.push(prepareEditableCustomService(editable));
                }
            }
        });

        return {upsertedServices, deletedServiceIds};
    }

    function onApplyChanges() {
        // will return undefined if services are invalid
        const extracted = extractValidatePrepareCustomServices();
        if (extracted) {
            const {upsertedServices, deletedServiceIds} = extractChangedServicesForRooms();
            const {upsertedServices: upsertedCustomServices, deletedServiceIds: deletedCustomServiceIds} = extracted;
            updateChargeables({
                variables: {
                    jobConfigurationId: jobConfigurationId,
                    upsertedServices: upsertedServices,
                    deletedServiceIds: deletedServiceIds,
                    upsertedCustomServices: upsertedCustomServices,
                    deletedCustomServiceIds: deletedCustomServiceIds
                },
            });
        }
    }

    // NOTE: was asked to temporarily remove this feature
    // function onRevertChanges(){
    //     setEditableServiceGroups(_.cloneDeep(originalServiceGroups))
    //     setEditableCustoms(_.cloneDeep(originalCustomServices));
    // };

    // only applicable to the recovery center (Rc)
    const usageContext = useAppSelector(selectUsageContext);
    const [preventRcEditing, setPreventRcEditing] = useState(usageContext === "rc");
    const shouldShowAddButton = (usageContext !== "readonly") && (
        rrJobServiceIdsNotInJob.length > 0 || furnitureJobServiceIdsNotInJob.length > 0 || fpJobServiceIdsNotInJob.length > 0
    );

    const { show } = useContextMenu({ id: ADDITIONAL_SERVICE_CONTEXT_MENU_ID });

    const areaIdsToRooms: AreaIdToRooms = useMemo(() => {
        const thisMap: AreaIdToRooms = {};
        allAreas.forEach(area => {
            thisMap[area.id] = area.rooms.map(r => ({roomId: r.id, labels: r.labels}))
        });

        return thisMap;
    }, [allAreas]);

    const [selectArea, setSelectArea] = useState({areaId: 0, productTypeId: 0});
    // internal year value
    const [selectedAreaOption, setSelectedAreaOption] = useState<FlatSelectOption | null>(null);
    const jobData = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId! }
    });
    const areaOptions: FlatSelectOption[] = jobData.data?.jobConfiguration.areas.map(area => {
        return {
            value: area.id + ' ' + area.productTypeId,
            label: 'Line ' + area.lineNumber
        };
    }) ?? [];
    return (
        <FlatSection
            header={
                <span className="flex-row align-items-center flex-gap-sm">
                    <p className="margin-none">Chargeables</p>
                    <FlatButton
                        size="small"
                        variant="outlined"
                        disabled={!changesPresent}
                        onClick={onApplyChanges}
                        style={{whiteSpace: "nowrap"}}
                    >
                        Apply Changes
                    </FlatButton>
                    <FlatSelect
                        value={selectedAreaOption}
                        options={areaOptions}
                        onChange={e => {
                            const data = e as SingleValue<FlatSelectOption>;
                            if (typeof data?.value === "string") {
                                const option = data.value.split(' ');
                                setSelectArea({areaId: parseInt(option[0]), productTypeId: parseInt(option[1])});
                                setSelectedAreaOption(data);
                                setShowEditAreaDetail(true);
                            }
                        }}
                    />
                    <FullEditInstallationServicesMenu open={showEditAreaDetail} onClose={() => { setShowEditAreaDetail(false)}}  areaId={selectArea.areaId} productTypeId={selectArea.productTypeId} someAreaHasHardSurface={false} />
                    {/* TODO: was asked to hide this temporarily */}
                    {/* <FlatButton
                        size="small"
                        variant="outlined"
                        disabled={!changesPresent}
                        onClick={onRevertChanges}
                        style={{whiteSpace: "nowrap"}}
                    >
                        Revert Changes
                    </FlatButton> */}
                </span>
            }
            endAdornment={usageContext === "rc" ?
                <FlatLockAndUnlockButton locked={preventRcEditing} setLocked={setPreventRcEditing}/> :
                undefined
            }
        >
            <table className="fill-width">
                <tbody>
                    {/* render all editors that are not for floor prep */}
                    {Object.keys(nonFloorPrepEditables).map((esgKey) => {
                        const jsId = +esgKey;
                        const thisEditableGroup = nonFloorPrepEditables[jsId];
                        // if group is new (has only been added locally), there won't be a corresponding group in the originalServiceGroups object
                        const isNewGroup = !Object.keys(originalServiceGroups).includes(esgKey);
                        const thisOriginalGroup = isNewGroup ? null : nonFloorPrepOriginals[jsId];

                        return (
                            <GeneralGroupedServiceEditorRow
                                key={`service-group-${jsId}`}
                                originalServiceGroup={thisOriginalGroup}
                                editableServiceGroup={thisEditableGroup}
                                updateServiceGroup={(updatedGroup) => updateServiceGroup(jsId, updatedGroup)}
                                removeServiceGroup={() => removeServiceGroup(jsId)}
                                preventRcEditing={preventRcEditing}
                                allAreas={allAreas as Area[]}
                                getNextServiceId={getNextServiceId}
                            />
                        );
                    })}

                </tbody>
            </table>

            {(Object.keys(floorPrepEditables).length > 0) && <div className="flat-thin-horizontal-bar margin-vertical-xsm"/>}

            {/* has one row per floor prep service group */}
            <FloorPrepServiceEditorGroup
                originalServiceGroups={floorPrepOriginals}
                editableServiceGroups={floorPrepEditables}
                updateServiceGroup={(group: JobServiceGroup) => updateServiceGroup(group.jobServiceId, group)}
                removeServiceGroup={removeServiceGroup}
                preventRcEditing={preventRcEditing}
                getNextServiceId={getNextServiceId}
                allAreas={allAreas as Area[]}
            />

            {(editableCustoms.length > 0) &&  <div className="flat-thin-horizontal-bar margin-vertical-xsm"/>}

            <div className="grid-40-60" style={{rowGap: "0.5rem"}}>
                {editableCustoms.map(thisEditable => {
                    const thisOriginal = originalCustomServices.find(s => s.id === thisEditable.id)!;

                    return (
                        <CustomServiceEditorRow
                            key={`custom-${thisEditable.id}`}
                            originalService={thisOriginal}
                            editableService={thisEditable}
                            updateService={(updatedService) => updateCustomService(updatedService)}
                            preventRcEditing={preventRcEditing}
                            // only used when deleting new service (not in DB), otherwise updateService is used
                            removeService={() => setEditableCustoms(editableCustoms.filter(cs => cs.id !== thisEditable.id))}
                            areaIdsToRooms={areaIdsToRooms}
                        />
                    )
                })}
            </div>

            {shouldShowAddButton && <FlatAddButton onClick={show}/>}

            <AdditionalServicesContextMenu
                handleCustomClicked={addCustomService}
                handleFurnitureClicked={addFurnitureService}
                handleRRClicked={addRrService}
                handleFpClicked={addFloorPrepService}
                presentFurnitureJobServiceIds={presentFurnitureJobServiceIds}
                presentRrJobServiceIds={presentRrJobServiceIds}
                presentFpJobServiceIds={presentFpServiceIds}
                hasShimService={hasShimService}
                handleShimClicked={addShimService}
            />
        </FlatSection>
    );
}

function customServicesValid(services: EditableCustomService[]) {
    return services.every(s => {
        if (isEmptyString(s.description)) {
            alert("Must enter a description for custom services");
            return false;
        }

        if (isEmptyString(s.displayPrice)) {
            alert("Must enter a price for custom services");
            return false;
        }

        if (isEmptyString(s.displayContractorPercentage)) {
            alert("Must enter a contractor percentage for custom services");
            return false;
        }

        if (s.contractorPercentage > 1) {
            alert("Contractor percentage for custom services can't be greater than 100%");
            return false;
        }

        if (s.areaId === -1 || s.roomIds.length === 0) {
            alert("Must select an area and at least one room for custom services")
            return false;
        }

        return true;
    });
}

function populateRoomServiceDetailPrice(
    service: RoomServiceDetail,
    discounts: AppliedDiscount[],
    pricingDetails: ChargeableServicePricingDetails,
    wasteScalar: number,
    msrpScalar: number,
    log?: boolean
) {
    service.price = calculateServicePrice({
        laborAmount: Math.ceil(service.laborAmount * wasteScalar),
        minimumLaborAmount: pricingDetails.minimumLaborAmount,
        laborPricePerUnit: pricingDetails.laborPricePerUnit,
        materialAmount: service.materialAmount ? Math.ceil(service.materialAmount * wasteScalar) : null,
        materialPricePerUnit: pricingDetails.materialPricePerUnit,
        materialPackageSize: pricingDetails.materialPackageSize,
        discounts: discounts,
        msrpScalar: msrpScalar,
        log
    });
}

/**
 * Calculates the price of a JobServiceGroup, as well as the price for all of the services for the rooms in the group individually,
 * updating the passed object with the calculated values.
 * 
 * This does NOT work for build up, since what appears as one build up service on the UI is actually made up of multiple services.
 * See populateBuildUpServiceGroupPrices(...) for this.
 * 
 * NOTE: if in the future any services that aren't R&R/furniture can have their labor amounts changed, sqft/lnft waste factors need to
 * be considered, as well as minimum labor thresholds
 */
function populateJobServiceGroupPrices(
    group: JobServiceGroup,
    discounts: AppliedDiscount[],
    pricingDetails: ChargeableServicePricingDetails,
    msrpScalar: number
) {
    group.rooms.forEach(r => {
        let wasteScalar = 1;
        if (group.laborPriceUnit === "sqft") {
            wasteScalar = r.service.sqftScaleFactor;
        } else if (group.laborPriceUnit === "lnft") {
            wasteScalar = r.service.lnftScaleFactor;
        }
        populateRoomServiceDetailPrice(r.service, discounts, pricingDetails, wasteScalar, msrpScalar);
    });
    const totalGroupPrice = group.rooms.map(r => r.service.price).reduce((prev, next) => prev + next, 0);
    group.price = totalGroupPrice;
}

/**
 * Calculates the price of a build up JobServiceGroup, as well as the price for all of the services for the rooms in the group individually,
 * updating the passed object with the calculated values.
 * 
 * This does not work generally. This method is specifically for build up because what appears as one build up service on the UI
 * may actually be multiple behind the scenes.
*/
function populateBuildUpServiceGroupPrices(
    group: JobServiceGroup,
    discounts: AppliedDiscount[],
    pricingDetails: ChargeableServicePricingDetails[],
    msrpScalar: number
) {
    group.rooms.forEach(r => {
        const thisServicePricing = pricingDetails.find(pd => (pd.materialCategoryId ?? -1) === r.service.materialCategoryId!);
        if (isNullOrUndefined(thisServicePricing)) {
            throw new Error(`Could not find pricing details for job service with ID ${PLYWOOD_ID}`);
        }

        populateRoomServiceDetailPrice(r.service, discounts, thisServicePricing!, r.service.sqftScaleFactor, msrpScalar, true);
    });
    const totalGroupPrice = group.rooms.map(r => r.service.price).reduce((prev, next) => prev + next, 0);
    group.price = totalGroupPrice;
}

function serviceGroupsEqual(jsg1: JobServiceGroups, jsg2: JobServiceGroups) {
    const g1Keys = Object.keys(jsg1).map(k => +k);
    const g2Keys = Object.keys(jsg2).map(k => +k);

    // attempt to short-circuit
    if (!numericArraysEq(g1Keys, g2Keys)) {
        return false;
    }

    // return g1Keys.every(jsId => {
    const rv = g1Keys.every(jsId => {
        const g1 = jsg1[jsId];
        const g2 = jsg2[jsId];
        
        // try to short-circuit the comparison
        if (g1.laborAmount !== g2.laborAmount) return false;
        g1.rooms.sort((r1, r2) => r1.id - r2.id);
        g2.rooms.sort((r1, r2) => r1.id - r2.id);
        // make sure the list of rooms are the same between the groups
        if (!numericArraysEq(g1.rooms.map(r => r.id), g2.rooms.map(r => r.id))) return false;

        // can compare based on index because each list was sorted above
        for (let i = 0; i < g1.rooms.length; i++) {
            const g1s = g1.rooms[i].service;
            const g2s = g2.rooms[i].service;

            if (
                (g1s.laborAmount !== g2s.laborAmount) ||
                (g1s.isDeleted !== g2s.isDeleted) ||
                (g1s.customerDoesService !== g2s.customerDoesService)
            ) {
                return false
            }
        }

        return true;
    });
    return rv;
}

// though SHIM is not a floor prep service (reducer), it's grouped with floor prep for the purposes of this editor
function shouldGroupAsFpService(group: JobServiceGroup) {
    if (group.serviceTypeId === FLOOR_PREP_ID || group.jobServiceId === SHIM_ID) {
        return true;
    } else {
        return false;
    }
}

function partitionServiceGroups(groups: JobServiceGroups): {nonFp: JobServiceGroups, fp: JobServiceGroups} {
    const nonFp: JobServiceGroups = {};
    const fp: JobServiceGroups = {};
    
    Object.entries(groups).forEach(([jsId, serviceAsUnknown]) => {
        const service = serviceAsUnknown as JobServiceGroup;
        if (shouldGroupAsFpService(service)) {
            fp[+jsId] = service;
        } else {
            nonFp[+jsId] = service;
        }
    })

    return {nonFp, fp};
}
