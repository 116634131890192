import { Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import NumberFormat from "react-number-format";
import { calculateMargin } from "./marginUtils";

interface MarginDisplayProps {
    revenue: number;
    cost: number;
    variant?: Variant;
}

export default function MarginDisplay({revenue, cost, variant="body1"}: MarginDisplayProps) {
    return (
        <div className="flex-column flex-centered">
            <Typography variant={variant} style={{fontSize: ".5rem"}}>Margin</Typography>
            <Typography variant={variant} >(
                <NumberFormat
                    suffix="%" thousandSeparator={true}
                    displayType="text"
                    value={(revenue === 0) ? 0 : calculateMargin(revenue, cost)}
                    decimalScale={2} fixedDecimalScale
                />
            )</Typography>
        </div>
    )
}