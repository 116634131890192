import Loading from "Components/Loading";
import { ToggleableSubSection } from "Components/PageLayout/Section";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { useGetJobBreakdownQuery } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useMemo } from "react";
import { generateBreakdownDiffs } from "../Breakdown/BreakdownTableUtils";
import LaborBreakdownTable from "../Breakdown/LaborBreakdownTable";
import MaterialBreakdownTable from "../Breakdown/MaterialBreakdownTable";
import DiffList from "./DiffList";

interface HistorySectionProps {
    oldestToNewestConfigIds: number[]
}

export default function HistorySection({ oldestToNewestConfigIds }: HistorySectionProps) {

    const groupedConfigs = useMemo(() => buildGroupedBreakdowns(oldestToNewestConfigIds), [oldestToNewestConfigIds])

    return (
        <FlatSection header="History" collapsible defaultClosed>
            {groupedConfigs.length === 0 ?
                <p className="margin-none flat-font">No Change Orders have been created.</p> :
                groupedConfigs.map(grouped => 
                    <HistoryBreakdownAndDiff
                        key={`change-order-${grouped.jobConfigurationId}-${grouped.nextJobConfigurationId}`}
                        {...grouped}
                    />
                )
            }
        </FlatSection>
    )
}

interface HistoryBreakdownAndDiffProps {
    revisionNumber: number,
    jobConfigurationId: number,
    nextJobConfigurationId: number,
    isLast: boolean
}

function HistoryBreakdownAndDiff({ revisionNumber, jobConfigurationId, nextJobConfigurationId, isLast }: HistoryBreakdownAndDiffProps) {

    const { data: breakdownData } = useGetJobBreakdownQuery({
        variables: { jobConfigurationId: jobConfigurationId }
    });

    const { data: nextBreakdownData } = useGetJobBreakdownQuery({
        variables: { jobConfigurationId: nextJobConfigurationId }
    });

    const isDataLoaded = isNotNullOrUndefined(breakdownData?.jobBreakdown.areaBreakdowns) && isNotNullOrUndefined(nextBreakdownData?.jobBreakdown.areaBreakdowns)

    const breakdownDiffs = useMemo(() => {
        if (!isDataLoaded) return [];
        else return generateBreakdownDiffs(breakdownData?.jobBreakdown.areaBreakdowns!, nextBreakdownData?.jobBreakdown.areaBreakdowns!)
    }, [breakdownData, nextBreakdownData, isDataLoaded])

    if (!isDataLoaded)
        return <Loading />

    const breakdown = breakdownData?.jobBreakdown.areaBreakdowns!

    //Rendering the initial config 
    if (revisionNumber === 0) return (
        <>
            <MaterialBreakdownTable areaBreakdowns={breakdown} />
            <div className="margin-top-md">
                <LaborBreakdownTable areaBreakdowns={breakdown}/>
            </div>
            <DiffList diffs={breakdownDiffs} />
        </>
    )
    else return (
        <div className="margin-top-md fill-width">
            <ToggleableSubSection column defaultState={false} title={`Revision ${revisionNumber}`}>
                <MaterialBreakdownTable areaBreakdowns={breakdown} />
                <div className="margin-top-md margin-bottom-sm">
                    <LaborBreakdownTable areaBreakdowns={breakdown}/>
                </div>
            </ToggleableSubSection>
            <h5>Changes Made to Revision {revisionNumber} to Produce {isLast ? "the Current Breakdown" : `Revision ${revisionNumber + 1}`}</h5>
            <DiffList diffs={breakdownDiffs} />
        </div>
    )
}

function buildGroupedBreakdowns(configIds: number[]): HistoryBreakdownAndDiffProps[] {
    var result: HistoryBreakdownAndDiffProps[] = []

    for (var i = 0; i < configIds.length - 1; i++) {
        result.push({
            revisionNumber: i,
            jobConfigurationId: configIds[i],
            nextJobConfigurationId: configIds[i + 1],
            isLast: i === configIds.length - 2
        })
    }

    return result;
}