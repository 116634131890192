import { Tooltip } from "@material-ui/core";
import clsx from "clsx";
import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import FlatUndoDeleteButton from "FlatComponents/Button/FlatUndoDeleteButton";
import { LabelForRoom, useGetAllRoomsForJobQuery } from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { FLOOR_PREP_ID, PLYWOOD_ID } from "Globals/globalConstants";
import { JobServiceGroup, RoomForWhoDoesService } from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/BreakdownTableUtils";
import { makeAddRoomContextMenuId } from "Pages/Admin/ProjectManagement/SellSheet/InstallationDetailsEditor/AddRoomContextMenu";
import { plywoodOptions } from "Pages/Admin/ProjectManagement/SellSheet/InstallationDetailsEditor/BuildUpEditor";
import { useMemo } from "react";
import { Item, Menu, Submenu, useContextMenu } from "react-contexify";
import { useAppSelector } from "Redux/hooks";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { selectJobConfigurationId, selectUsageContext } from "Redux/pricingCenterReducer";
import { getTotalLaborAmount, GroupedServiceEditorRowProps } from "./GeneralGroupedServiceEditorRow";
import BuildUpServiceEditorForRoom, { BuildUpServiceDetails } from "./RoomServiceEditors/BuildUpServiceEditorForRoom";
import ServiceGroupPriceText from "./ServiceGroupPriceText";

/**
 * This editor is different from other chargeable editors because each room may have a service multiple times, since some
 * amounts of service require multiple material categories (e.g., 1 1/4" is actually 2 build up services - one with 1/2" material and another with 3/4")
 */
export default function BuildUpServiceGroupEditor({
    originalServiceGroup,
    editableServiceGroup,
    updateServiceGroup,
    removeServiceGroup,
    preventRcEditing,
    getNextServiceId,
    allAreas
}: GroupedServiceEditorRowProps) {
    const usageContext = useAppSelector(selectUsageContext);
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);

    const {data: allRoomData} = useGetAllRoomsForJobQuery({
        variables: {
            jobConfigurationId: jobConfigurationId,
        },
        skip: (jobConfigurationId ?? -1) < 1,
        // NOTE: I don't understand why, but the cache gets updated when any floor prep services are changed,
        // and this causes issues with price calculation in this section, so just totally ignore the cache
        fetchPolicy: "no-cache"
    });
    const allRooms: {id: number, areaId: number, sqft: number, lnft: number, labels: LabelForRoom[]}[] = useMemo(() => {
        return allRoomData?.allRoomsForJob.map(r => ({
            id: r.id,
            areaId: r.areaId!,
            sqft: r.sqft,
            lnft: r.lnft,
            labels: r.labels
        })) ?? [];
    }, [allRoomData])

    const numRoomsDeleted = editableServiceGroup.rooms.filter(r => r.service.isDeleted).length;
    const deletionStatus: "none" | "partial" | "total" = (numRoomsDeleted === 0)
        ? "none"
        : (numRoomsDeleted === editableServiceGroup.rooms.length
            ? "total"
            : "partial"
        );

    const roomsIdsInService = editableServiceGroup.rooms.map(r => r.id);
    const roomsNotInService = allRooms.filter(r => !roomsIdsInService.includes(r.id)); // only relevant to R&R (for shouldShowAddButton)
    const shouldShowAddButton = (usageContext !== "readonly") && roomsNotInService.length > 0;
    
    const serviceIsDeleteable = usageContext !== "readonly";
    const showDeleteButton = serviceIsDeleteable && (
        editableServiceGroup.rooms.length === 0 ||
        editableServiceGroup.rooms.some(r => !r.service.isDeleted)
    );
    const showRestoreButton = serviceIsDeleteable && editableServiceGroup.rooms.length > 0 && editableServiceGroup.rooms.every(r => r.service.isDeleted);

    function onAddRoomToService(roomId: number, materialCategoryIds: number[]) {
        const updatedGroup = {...editableServiceGroup};
        const room = allRooms.find(r => r.id === roomId)!;
        const area = allAreas.find(a => a.id === room.areaId);

        const servicesToAdd: RoomForWhoDoesService[] = materialCategoryIds.map((mcId, idx) => ({
            id: room.id,
            labels: room.labels,
            service: {
                id: getNextServiceId() - idx,
                customerDoesService: false,
                isActive: true,
                laborAmount: room.sqft,
                materialAmount: room.sqft,
                jobServiceId: PLYWOOD_ID,
                serviceTypeId: FLOOR_PREP_ID,
                materialCategoryId: mcId,
                price: 0, // will be calculated when updating the service group
                isDeleted: false,
                roomId: roomId,
                lnftScaleFactor: area?.lnftWasteFactor ? (1 + area!.lnftWasteFactor) : 1,
                sqftScaleFactor: area?.sqftWasteFactor ? (1 + area!.sqftWasteFactor) : 1
            },
        }));

        updatedGroup.rooms = [...updatedGroup.rooms, ...servicesToAdd];
        updatedGroup.laborAmount = getTotalLaborAmount(updatedGroup);
        updateServiceGroup(updatedGroup);
    }

    function onRemoveRoomFromService(roomId: number) {
        if (editableServiceGroup.rooms.length === 1) {
            removeServiceGroup();  // this is the only room left in the group, so remove the entire group
        } else {
            let updatedGroup: JobServiceGroup;
            if (editableServiceGroup.rooms.find(r => r.id === roomId)!.service.id < 1) {
                // service was never in DB, just remove it entirely
                updatedGroup = {
                    ...editableServiceGroup,
                    rooms: editableServiceGroup.rooms.filter(r => r.id !== roomId)
                };
            } else {
                const thisRoomIdx = editableServiceGroup.rooms.findIndex(r => r.id === roomId);

                // service was in DB, so we mark it as deleted
                updatedGroup = {...editableServiceGroup};
                updatedGroup.rooms[thisRoomIdx].service.isDeleted = true;
            }
            
            updateServiceGroup({...updatedGroup, laborAmount: getTotalLaborAmount(updatedGroup)});
        }
    }

    function onRestoreRoom(roomId: number) {
        const thisRoomIdx = editableServiceGroup.rooms.findIndex(r => r.id === roomId);
        const updated = {...editableServiceGroup};
        updated.rooms[thisRoomIdx].service.isDeleted = false;
        updateServiceGroup(updated);
    }

    /**
     * Sets isDeleted flag to false for all services in the group
     */
    function restoreServiceGroup() {
        const updated = {...editableServiceGroup};
        updated.rooms.forEach(r => r.service.isDeleted = false);
        updateServiceGroup(updated);
    }

    // there will be one group per room
    const editableServiceDetails: BuildUpServiceDetails[] = useMemo(() => {
        const details: BuildUpServiceDetails[] = [];

        editableServiceGroup.rooms.forEach(r => {
            const thisGroupIdx = details.findIndex(d => d.roomId === r.id);
            if (thisGroupIdx < 0) {
                // add new entry
                const newDetails: BuildUpServiceDetails = {
                    roomId: r.id,
                    roomLabels: r.labels,
                    serviceMaterialDetails: [{
                        serviceForRoomId: r.service.id,
                        materialCategoryId: r.service.materialCategoryId!,
                        isDeleted: r.service.isDeleted,
                        price: r.service.price
                    }]
                };
                
                details.push(newDetails);
            } else {
                details[thisGroupIdx].serviceMaterialDetails.push({
                    serviceForRoomId: r.service.id,
                    materialCategoryId: r.service.materialCategoryId!,
                    isDeleted: r.service.isDeleted,
                    price: r.service.price
                });
            }
        });

        return details;
    }, [editableServiceGroup]);

    const originalServiceDetails: BuildUpServiceDetails[] | null = useMemo(() => {
        if (isNullOrUndefined(originalServiceGroup)) return null;

        const details: BuildUpServiceDetails[] = [];

        originalServiceGroup?.rooms.forEach(r => {
            const thisGroupIdx = details.findIndex(d => d.roomId === r.id);
            if (thisGroupIdx < 0) {
                // add new entry
                const newDetails: BuildUpServiceDetails = {
                    roomId: r.id,
                    roomLabels: r.labels,
                    serviceMaterialDetails: [{
                        serviceForRoomId: r.service.id,
                        materialCategoryId: r.service.materialCategoryId!,
                        isDeleted: r.service.isDeleted,
                        price: r.service.price
                    }]
                };
                
                details.push(newDetails);
            } else {
                details[thisGroupIdx].serviceMaterialDetails.push({
                    serviceForRoomId: r.service.id,
                    materialCategoryId: r.service.materialCategoryId!,
                    isDeleted: r.service.isDeleted,
                    price: r.service.price
                });
            }
        });

        return details;
    }, [originalServiceGroup]);

    function setMaterialCategoryIds(roomId: number, materialCategoryIds: number[]) {
        const updated = {...editableServiceGroup};
        // mark as deleted any services in the DB for this room that do not have a material type that's part of the incoming ids
        const roomsToMarkDeleted: number[] = [];
        updated.rooms.forEach((r, idx) => {
            if (r.id === roomId && !materialCategoryIds.includes(r.service.id)) {
                roomsToMarkDeleted.push(idx)
            }
        });
        roomsToMarkDeleted.forEach(roomIdx => {
            updated.rooms[roomIdx].service.isDeleted = true;
        });

        // replace them with new services for each material category
        const thisRoom = allRooms.find(r => r.id)!;
        const area = allAreas.find(a => a.id === thisRoom.areaId)!;
        const sqft = thisRoom.sqft;
        const roomLabels = thisRoom.labels;
        const firstNewServiceId = getNextServiceId();
        const newServices: RoomForWhoDoesService[] = [];
        materialCategoryIds.forEach((mcId, idx) => {
            newServices.push({
                id: roomId,
                labels: roomLabels,
                service: {
                    id: firstNewServiceId - idx,  // keep decrementing id for every service added
                    roomId: roomId,
                    serviceTypeId: FLOOR_PREP_ID,
                    jobServiceId: PLYWOOD_ID,
                    laborAmount: sqft,
                    customerDoesService: false,
                    isActive: true,
                    materialCategoryId: mcId,
                    materialAmount: sqft,
                    price: 0, // will be calculated as part of updating the service group
                    isDeleted: false,
                    sqftScaleFactor: area.sqftWasteFactor ? (1 + area.sqftWasteFactor) : 1,
                    lnftScaleFactor: area.lnftWasteFactor ? (1 + area.lnftWasteFactor) : 1
                }
            });
        });
        updated.rooms = [...updated.rooms, ...newServices];
        updateServiceGroup(updated);
    }
    
    const { show } = useContextMenu({ id: makeAddRoomContextMenuId(editableServiceGroup.jobServiceId) });
    
    return (<>
        <tr className="flat-font">
            <td
                className="flat-font-bold"
                style={{ width: "100%" }}
            >
                <span
                    className={clsx(
                        "flex-row flex-gap-xsm align-items-center",
                        {
                            "line-through": deletionStatus === "total",
                            "error-text": deletionStatus === "total",
                            "warning-text": deletionStatus === "partial",
                            // highlight new services in green
                            "success-text": editableServiceGroup.rooms.every(r => r.service.id < 0)
                        }
                    )}
                >
                    {editableServiceGroup.serviceType} {editableServiceGroup.serviceDescription}

                    {showDeleteButton && (
                        <Tooltip title="Remove all services in this group">
                            <span>
                                <FlatDeleteButton onClick={removeServiceGroup}/>
                            </span>
                        </Tooltip>
                    )}

                    {showRestoreButton && (
                        <Tooltip title="Restore deleted services in this group">
                            <span>
                                <FlatUndoDeleteButton onClick={restoreServiceGroup}/>
                            </span>
                        </Tooltip>
                    )}

                    {shouldShowAddButton && <FlatAddButton onClick={show}/>}
                </span>
            </td>

            <td
                align="left"
                style={{ whiteSpace: "nowrap" }}
            >
                <span/>
            </td>

            <td style={{ minWidth: "5rem" }} align="right">
                {isNotNullOrUndefined(editableServiceGroup) ? (
                    <ServiceGroupPriceText
                        originalServiceGroup={originalServiceGroup}
                        editableServiceGroup={editableServiceGroup}
                    />
                ) : (
                    <></>
                )}
            </td> 
        </tr>

        {editableServiceGroup.rooms.length === 0 ? (
            <tr>
                <td>
                    <p className="flat-font">
                        No rooms have been specified for this service - click the "+" above to add rooms
                    </p>
                </td>
            </tr>
        ) : (editableServiceDetails.map(editableDetails => (
                <BuildUpServiceEditorForRoom
                    key={editableDetails.roomId}
                    editableServiceDetails={editableDetails}
                    setMaterialCategoryIds={(newIds: number[]) => setMaterialCategoryIds(editableDetails.roomId, newIds)}
                    removeRoomFromService={() => onRemoveRoomFromService(editableDetails.roomId)}
                    restoreService={() => onRestoreRoom(editableDetails.roomId)}
                    disabled={preventRcEditing || editableDetails.serviceMaterialDetails.every(s => s.isDeleted)}
                    originalServiceDetails={
                        originalServiceDetails?.find(s => s.roomId === editableDetails.roomId) ?? null
                    }
                />
            )))
        }

        {shouldShowAddButton && (
            <AddBuildUpForRoomContextMenu
                roomOptions={roomsNotInService.map(r => (
                    {id: r.id, label: getNameOfArea(r.labels)}
                ))}
                handleItemClick={onAddRoomToService}
            />
        )}
    </>)
}

interface AddBuildUpForRoomContextMenuProps {
    roomOptions: {id: number; label: string}[];
    handleItemClick: (roomId: number, materialCategoryIds: number[]) => void;
}

function AddBuildUpForRoomContextMenu({
    roomOptions,
    handleItemClick
}: AddBuildUpForRoomContextMenuProps) {
    return (
        <Menu id={makeAddRoomContextMenuId(PLYWOOD_ID)}>
            {roomOptions.map(room => (
                <Submenu label={room.label} key={room.label}>
                    {plywoodOptions.filter(po => po.description !== "None").map(po => (
                        <Item
                            key={`${room.label}-${po.description}`}
                            onClick={() => handleItemClick(room.id, po.materialCategoryIds)}
                        >
                            {po.description}
                        </Item>
                    ))}
                </Submenu>
            ))}
        </Menu>
    )
}