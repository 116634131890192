import { Select, MenuItem } from "@material-ui/core"
import clsx from "clsx"
import { useGetProductColorOptionsQuery } from "generated/graphql"
import { isNullOrUndefined } from "Globals/GenericValidators"


interface PColorSelectorProps {
    sid?: number,
    cid?: number,
    setColorId: (newSid: number, name: string) => void,
    disabled?: boolean,
    className?: string,
    label?: string
}


export default function ProductColorSelect({ sid, cid, setColorId, disabled, className, label }: PColorSelectorProps) {

    const noSid = isNullOrUndefined(sid)
    const { data, loading } = useGetProductColorOptionsQuery({ variables: { styleId: sid! }, skip: noSid })

    const colorOps = (noSid || loading) ? [] : (data?.productColorOptions ?? [])

    return (
        <>
            <label htmlFor="prod-style-select" className={clsx("margin-side-xsm", className)}>{label ?? "Product Color:"}</label>
            <Select disabled={disabled}
                className={clsx('w-10r', className)}
                name="prod-style-select"
                value={cid ?? -1}
                onChange={(e) => {
                    const cid = e.target.value as number
                    const selectedColor = colorOps.filter(op => op.id === cid)[0]
                    setColorId(cid, selectedColor.color)
                }}
            >
                {loading && <MenuItem value={cid ?? -1} hidden>Loading...</MenuItem>}
                {colorOps.map(op => <MenuItem key={`product-style-op-${op.id}`} value={op.id}>{op.color}</MenuItem>)}
                <MenuItem value={-1} hidden>No Product</MenuItem>
            </Select>
        </>
    )
}