//import { InventoryEntry, RollEntry } from "./InventoryEntry";
import { InventoryEntry, RollEntry } from "generated/graphql";
import Job from "./Jobs";

export const transactionCodes = ['None', 'Hold', 'Picked', 'On Order', 'Received', 'Drop',] as const

export type TransactionCode = typeof transactionCodes[number];

export type IncreaseDecrease = 'Increase' | 'Decrease';

export interface TransactionCodeChange {
    targetedField: keyof InventoryEntry,
    change: IncreaseDecrease
}

export function getDescriptionOfLineItem(lineItem: LineItem) {
    const amount = parseInt(lineItem.amountInStockUnit);
    // TODO: Add Description.
    switch (lineItem.code) {
        case 'Hold':
            return `Holding ${amount} ${lineItem.inventoryEntry.stockUnit} (Available: ${lineItem.inventoryEntry.available}-${amount} ⇒ ${lineItem.inventoryEntry.available - amount}, Held: ${lineItem.inventoryEntry.heldCount}+${amount} ⇒ ${lineItem.inventoryEntry.heldCount + amount})`;
        case 'Picked':
            return `Picked ${amount} ${lineItem.inventoryEntry.stockUnit} (Actual: ${lineItem.inventoryEntry.newCount}-${amount} ⇒ ${lineItem.inventoryEntry.newCount - amount}, Held: ${lineItem.inventoryEntry.heldCount}-${amount} ⇒ ${lineItem.inventoryEntry.heldCount - amount})`;
        default:
            return `Description of ${lineItem.code} will go here`;
    }
}

export interface LineItem {

    lineItemNumber?: string

    // The currently active transaction code
    code: TransactionCode
    // The inventory entry that is related to this line item.
    inventoryEntry: InventoryEntry

    /* Used to verify that the user is sure when they are preforming a destructive action.
     * Any change to the data structure other then setting this flag will reset it to false.
     */
    confirmDropProductByTypingNameAndColor: string

    // The job number for the line item, if applicable
    job?: Job

    carpetRoll?: RollEntry,
    cuts: CarpetCut[]

    // The amount of the line item change, if applicable
    amountInMeasureUnit: string,
    amountInStockUnit: string,
    waste?: number,
}

export interface CarpetCut{
    roll?: RollEntry,
    cutNumber: number,
    lengthInFt: string
}

export function createLineItem(code: TransactionCode = 'None', inventoryEntry: InventoryEntry): LineItem {
    return {
        code,
        inventoryEntry,
        confirmDropProductByTypingNameAndColor: '',
        job: undefined,
        amountInMeasureUnit: '',
        cuts: [],
        amountInStockUnit: '',
        waste: undefined
    }
}