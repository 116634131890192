import { Dialog, IconButton, Typography } from "@material-ui/core";
import { CameraAlt, FormatListBulleted, PictureInPicture } from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Loading from "Components/Loading";
import {
    dateTimeStrMd,
    formatMilitaryToHHMM12HR,
    minutesBetween,
    timeSpanStrToTime,
} from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import { homePath } from "Globals/PathStrings";
import { formatAddress, formatNameStringFirstLast } from "Globals/StringFormatting";
import { useAppHistory } from "Globals/routingHooks";
import {
    WorkOrder,
    WorkOrderProps,
} from "Pages/Admin/ProjectManagement/Dashboard/JobTracker/WorkOrder/WorkOrdersPage";
import clsx from "clsx";
import {
    InstallationAppointment,
    Maybe,
    useCallCustomerForInstallationAppointmentMutation,
    useCheckInToInstallationAppointmentMutation,
    useCheckOutFromInstallationAppointmentMutation,
    useCollectCodForInstallationAppointmentMutation,
    useGetInstallationAppointmentForContractorQuery,
    useGetInstallationAppointmentWithRoomsQuery,
    useMappedToCustomerForInstallationAppointmentMutation,
    usePickedUpMaterialsForInstallationAppointmentMutation,
} from "generated/graphql";
import { useEffect, useMemo, useState } from "react";
import ContractorNavbarPage from "../ContractorNavbarPage";
import ArrivalTimeReporting from "./ArrivalTimeReporting";
import CompletionCertificate, { CompletionCertificateProps } from "./CompletionCertificate";
import CompletionCertificatePDF from "./CompletionCertificatePDF";
import ContractorBeforeAndAfterEditor, { PhotoMode } from "./ContractorBeforeAndAfterEditor";
import JobSvgViewer from "./JobSvgViewer";

import AssignmentIcon from "@material-ui/icons/Assignment";
import BallotIcon from "@material-ui/icons/Ballot";
import MapIcon from "@material-ui/icons/Map";
import CheckIcon from "@mui/icons-material/Check";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";

import FlatButton from "FlatComponents/Button/FlatButton";

import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import FlatDialogAlert from "FlatComponents/Layout/FlatDialogAlert";
import StarRatings from "react-star-ratings";

export default function InstallJobPage() {
    const { id: installationAppointmentId } = useNumericIdParam();
    const { data, loading } = useGetInstallationAppointmentForContractorQuery({
        variables: { installationAppointmentId: installationAppointmentId! },
        skip: isNullOrUndefined(installationAppointmentId),
    });

    const appointmentDay = isNotNullOrUndefined(data?.installationAppointmentForContractor)
        ? dateTimeStrMd(data?.installationAppointmentForContractor.dates[0] ?? "")
        : "Job";

    const pageInvalid =
        installationAppointmentId === null ||
        (!loading && isNullOrUndefined(data?.installationAppointmentForContractor));

    return (
        <ContractorNavbarPage title={appointmentDay}>
            {pageInvalid ? (
                <Typography>Appointment does not exist</Typography>
            ) : loading ? (
                <Loading />
            ) : (
                <InstallJobContent appointment={data?.installationAppointmentForContractor!} />
            )}
        </ContractorNavbarPage>
    );
}

export function InstallJobContent({ appointment }: { appointment: InstallationAppointment }) {
    const history = useAppHistory();
    const {
        id: installationAppointmentId,
        appointmentNumber,
        customerFirstName,
        customerLastName,
        customerAddress,
        customerPhone,
        checkInTime,
        checkOutTime,
        cod,
        collectedCod,
        jobConfigurationId,
        contractorLastContactAttempt,
        estimatedArrivalTime,
        notes,
        isComplete,
        certificateRating
    } = appointment;

    const {
        customerEtaBar,
        callCustomerEtaBar,
        largeMapToMaterial,
        largeCallCustomer,
        largeMapToCustomer,
        largeCheckIn,
        largeCollectCOD,
        largeCheckOut,
        largeCompletionCertificate,
        largeJobCompleted,
        mainSectionSeparator,
        smallBaseJobScope,
        largeBeforePictures,
        smallBeforePictures,
        smallCOD,
        smallAfterPictures,
        smallCompletionCertificate,
        smallFullCOD,
        smallMapToCustomer,
        smallMapToMaterial,
    } = useMemo(() => getStageButtons(appointment), [appointment]);

    const [showPictures, setShowPictures] = useState<PhotoMode | null>(null);
    const [showWorkOrder, setShowWorkOrder] = useState<boolean>(false);
    const [showCompletion, setShowCompletion] = useState<boolean>(false);
    const [showNotes, setShowNotes] = useState<boolean>(false);

    const [flashEta, setFlashEta] = useState<boolean>(false);

    const formattedAddress = formatAddress(customerAddress);
    const hasCheckedIn = isNotNullOrUndefined(checkInTime);
    const hasCheckedOut = isNotNullOrUndefined(checkOutTime);

    const [mapToMaterial] = usePickedUpMaterialsForInstallationAppointmentMutation({
        variables: { appointmentId: installationAppointmentId },
    });

    const [mapToCustomer] = useMappedToCustomerForInstallationAppointmentMutation({
        variables: { appointmentId: installationAppointmentId },
    });

    const [callCustomer] = useCallCustomerForInstallationAppointmentMutation({
        variables: { appointmentId: installationAppointmentId },
    });

    const [checkIn] = useCheckInToInstallationAppointmentMutation({
        variables: { appointmentId: installationAppointmentId },
    });

    const [checkOut] = useCheckOutFromInstallationAppointmentMutation({
        variables: { appointmentId: installationAppointmentId },
    });

    const [collectedCOD] = useCollectCodForInstallationAppointmentMutation({
        variables: { appointmentId: installationAppointmentId },
    });

    const remainingCod = cod - collectedCod;

    const ableToCheckOut = hasCheckedIn && !hasCheckedOut;
    const ableToCheckIn = isNotNullOrUndefined(estimatedArrivalTime) && !hasCheckedIn;

    const needETANow =
        isNotNullOrUndefined(contractorLastContactAttempt) &&
        isNullOrUndefined(estimatedArrivalTime);

    const showETA = isNotNullOrUndefined(estimatedArrivalTime) && !hasCheckedIn;

    const etaRange = showETA ? createETARange(estimatedArrivalTime!) : "1:00pm-1:30pm"; //"Not Set";

    const customerFullName = formatNameStringFirstLast({
        firstName: customerFirstName,
        lastName: customerLastName,
    });

    useEffect(() => {
        if (isNullOrUndefined(estimatedArrivalTime)) {
            setFlashEta(false);
            return;
        }

        const etaAsTime = timeSpanStrToTime(estimatedArrivalTime!);
        const currentDate = new Date();
        const currentTime = { hour: currentDate.getHours(), minute: currentDate.getMinutes() };

        const minutesUntilAppointment = minutesBetween(currentTime, etaAsTime);
        const minutesUntilFlash = minutesUntilAppointment - 15;

        // Will make ETA start flashing 15 minutes before appointment
        const id = setTimeout(() => {
            setFlashEta(true);
        }, minutesUntilFlash * 60000);

        return () => {
            clearTimeout(id);
            setFlashEta(false);
        };
    }, [setFlashEta, estimatedArrivalTime]);

    return (
        <div className="contractor-page contractor-job-page">
            <div className="contractor-customer-bar flat-dark-bkg">
                <div className="flat-font-lg">{customerFullName}</div>
                <div className="flat-font-sm">
                    {appointmentNumber} | {customerAddress.city}
                </div>
                <div style={{ gridColumn: "2", gridRow: "1 / 3" }}>
                    <FlatButton
                        hidden={!callCustomerEtaBar}
                        color="primary"
                        onClick={() => {
                            callCustomer();
                            window.open(`tel:${customerPhone}`);
                        }}
                    >
                        <PhoneEnabledIcon />
                    </FlatButton>
                </div>
            </div>
            <div
                hidden={!customerEtaBar}
                className={clsx("contractor-eta-bar flat-dark-bkg", { "cell-flash": flashEta })}
                style={{ margin: "8px 16px" }}
            >
                <div
                    className="flat-font"
                    style={{ fontSize: "24pt" }}
                >
                    ETA
                </div>
                <div
                    className="flat-font"
                    style={{ fontSize: "20pt" }}
                >
                    {etaRange}
                </div>
            </div>
            <div
                hidden={!largeMapToMaterial}
                className="contractor-job-highlighted-action"
                style={{ padding: "8px 16px", alignItems: "center" }}
            >
                <div className="flat-font">Map to Materials</div>
                <div
                    className="flex-row"
                    style={{ paddingTop: "8px" }}
                >
                    <MaterialButtons
                        {...appointment}
                        installationAppointmentId={installationAppointmentId}
                    />
                </div>
            </div>
            <div
                hidden={!largeMapToMaterial}
                className="disable-selection fill-width"
                style={{ padding: "8px 16px", textAlign: "right" }}
            >
                <FlatButton
                    color="secondary"
                    onClick={() => mapToMaterial()}
                >
                    Skip &gt;
                </FlatButton>
            </div>
            <div
                hidden={!largeCallCustomer}
                className="contractor-job-highlighted-action"
                style={{ padding: "20px 16px", alignItems: "center" }}
            >
                <FlatButton
                    color="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                        callCustomer();
                        window.open(`tel:${customerPhone}`);
                    }}
                >
                    <div
                        className="flex-row fill-width"
                        style={{ height: "4rem", alignItems: "center" }}
                    >
                        <div
                            className="flex-grow flex-column flat-dark-bkg"
                            style={{
                                alignItems: "center",
                                textTransform: "none",
                                lineHeight: "1.3em",
                            }}
                        >
                            <div className="flat-font-md">Call Customer</div>
                            <div className="flat-font">{customerFullName}</div>
                        </div>
                        <PhoneEnabledIcon style={{ fontSize: "48pt" }} />
                    </div>
                </FlatButton>
            </div>
            <div
                hidden={!largeMapToCustomer}
                className="contractor-job-highlighted-action disable-selection"
                style={{ padding: "20px 16px", alignItems: "center" }}
                onClick={() => {
                    mapToCustomer();
                    openAddress(formattedAddress);
                }}
            >
                <MapIcon
                    className="flat-gray-color"
                    style={{ fontSize: "48pt" }}
                />
                <div className="flat-font-md flat-gray-color">Map to Customer</div>
            </div>
            <div
                hidden={!largeCheckIn}
                className="contractor-job-highlighted-action disable-selection"
                style={{ padding: "20px 16px", alignItems: "center" }}
                onClick={() => setShowPictures("Before")}
            >
                <div
                    className="flex-row fill-width"
                    style={{ alignItems: "center" }}
                >
                    <CameraAlt
                        className="flat-gray-color"
                        style={{ fontSize: "48pt", flex: 1 }}
                    />
                    <div
                        className="flat-font-md flat-gray-color"
                        style={{ textAlign: "center", flex: 2 }}
                    >
                        Check In
                    </div>
                </div>
            </div>
            <div
                hidden={!largeCollectCOD}
                className="contractor-job-highlighted-action disable-selection"
                style={{ padding: "16px 16px", alignItems: "center" }}
            >
                <div
                    className="flex-row fill-width"
                    style={{ alignItems: "center", justifyContent: "space-between" }}
                    onClick={() => {
                        if (
                            window.confirm(
                                `You are reporting that you have collected the full COD of $${remainingCod.toFixed(
                                    2
                                )}`
                            )
                        ) {
                            collectedCOD();
                        }
                    }}
                >
                    <div className="flex-column">
                        <div
                            className="flat-font"
                            style={{
                                fontSize: "20pt",
                                lineHeight: "1em",
                                color: "var(--flat-red)",
                                fontWeight: "bold",
                            }}
                        >
                            ${remainingCod.toFixed(2)}
                        </div>
                        <div
                            className="flat-font"
                            style={{ color: "var(--flat-gray-4)" }}
                        >
                            C.O.D.
                        </div>
                    </div>
                    <CreditCardIcon style={{ fontSize: "60pt", color: "var(--flat-red)" }} />
                </div>
            </div>
            <div
                hidden={!largeCheckOut}
                className="contractor-job-highlighted-action disable-selection"
                style={{ padding: "20px 16px", alignItems: "center" }}
                onClick={() => setShowPictures("After")}
            >
                <div
                    className="flex-row fill-width"
                    style={{ alignItems: "center" }}
                >
                    <CameraAlt
                        className="flat-gray-color"
                        style={{ fontSize: "48pt", flex: 1 }}
                    />
                    <div
                        className="flat-font-md flat-gray-color"
                        style={{ textAlign: "center", flex: 2 }}
                    >
                        Check Out
                    </div>
                </div>
            </div>
            <div
                hidden={!largeCompletionCertificate}
                className="contractor-job-highlighted-action disable-selection"
                style={{ padding: "16px 16px", alignItems: "center" }}
                onClick={() => setShowCompletion(true)}
            >
                <VerifiedOutlinedIcon
                    className="flat-gray-color"
                    style={{ fontSize: "48pt" }}
                />
                <div className="flat-font-md flat-gray-color">Completion Certificate</div>
            </div>
            <div
                hidden={!largeJobCompleted}
                className="disable-selection fill-width"
                style={{ padding: "32px 16px" }}
            >
                <div
                    className="flat-font-md"
                    style={{
                        color: "var(--flat-gray-4)",
                        textAlign: "center",
                        fontStyle: "italic",
                    }}
                >
                    Job Completed!
                </div>
            </div>
            <div
                className="flat-h-bar"
                hidden={!mainSectionSeparator}
            />
            <div className="contractor-job-button-panel disable-selection">
                <div
                    hidden={!smallBaseJobScope}
                    style={{ gridRow: "span 2", gridColumn: "1 / 4" }}
                    className="contractor-job-vertical-icon-button"
                    onClick={() => setShowWorkOrder(true)}
                >
                    <BallotIcon style={{ fontSize: "48pt" }} />
                    <div className="flat-font">Work Order & Drawing</div>
                </div>
                <div
                    hidden={!smallBaseJobScope}
                    style={{ gridColumn: "4 / 7" }}
                    className="contractor-job-horizontal-icon-button"
                    onClick={() => setShowNotes(true)}
                >
                    <AssignmentIcon style={{ fontSize: "24pt" }} />
                    <div className="flat-font">Notes</div>
                </div>
                <div
                    hidden={!smallBaseJobScope}
                    style={{ gridColumn: "4 / 7" }}
                    className="contractor-job-horizontal-icon-button"
                    onClick={() => setShowPictures("Sale")}
                >
                    <CameraAlt style={{ fontSize: "24pt" }} />
                    <div className="flat-font">Pictures</div>
                </div>
                <div
                    hidden={!smallMapToCustomer}
                    style={{ gridColumn: "1 / 3" }}
                    className="contractor-job-vertical-icon-button"
                    onClick={() => openAddress(formattedAddress)}
                >
                    <MapIcon style={{ fontSize: "18pt", margin: 0 }} />
                    <div className="flat-font-sm">Map to Cust.</div>
                </div>
                <div
                    hidden={!smallMapToMaterial}
                    style={{ gridColumn: "3 / 7" }}
                    className="contractor-job-materials"
                >
                    <MaterialButtons
                        {...appointment}
                        installationAppointmentId={installationAppointmentId}
                    />
                </div>
                <div
                    hidden={!smallBeforePictures}
                    style={{ gridColumn: "1" }}
                    className="contractor-job-vertical-icon-button"
                    onClick={() => setShowPictures("Before")}
                >
                    <CameraAlt style={{ fontSize: "18pt", margin: 0 }} />
                    <div className="flat-font-sm">Before</div>
                </div>
                <div
                    hidden={!largeBeforePictures}
                    style={{ gridColumn: "1 / 7" }}
                    className="contractor-job-horizontal-icon-button"
                    onClick={() => setShowPictures("Before")}
                >
                    <CameraAlt style={{ fontSize: "48pt", marginLeft: "10px" }} />
                    <div className="flat-font-md flat-gray-color">Before Pictures</div>
                </div>
                <div
                    hidden={!smallCOD && !smallFullCOD}
                    style={{ gridColumn: smallCOD ? "2 / 6" : "span 6" }}
                    className="contractor-job-reverse-horizontal-icon-button"
                >
                    <div className="flex-column">
                        <div className="flat-font-md flat-gray-color">Collected</div>
                        <div
                            className="flat-font-sm"
                            style={{ color: "var(--flat-gray-4)" }}
                        >
                            C.O.D.
                        </div>
                    </div>

                    <CheckIcon
                        style={{
                            color: "var(--flat-white)",
                            height: "1.5rem",
                            width: "1.5rem",
                            padding: "4px",
                            backgroundColor: "var(--flat-green)",
                            borderRadius: "50%",
                        }}
                    />
                </div>
                <div
                    hidden={!smallAfterPictures}
                    style={{ gridColumn: "6" }}
                    className="contractor-job-vertical-icon-button"
                    onClick={() => setShowPictures("After")}
                >
                    <CameraAlt style={{ fontSize: "18pt", margin: 0 }} />
                    <div className="flat-font-sm">After</div>
                </div>
                <div
                    hidden={!smallCompletionCertificate}
                    style={{ gridColumn: "1 / 7" }}
                    className="contractor-job-horizontal-icon-button"
                    onClick={() => setShowCompletion(true)}
                >
                    <VerifiedOutlinedIcon style={{ fontSize: "24pt", marginLeft: "10px" }} />
                    <StarRatings
                        rating={certificateRating ?? 0}
                        starDimension="calc(55vw / 6)"
                        starSpacing="3px"
                        starRatedColor="#ffd802"
                        starHoverColor="#ffd802"
                    />
                    {/* <div className="flat-font">View Completion Certificate</div> */}
                </div>
            </div>

            <div
                className="flex-grow flex-row"
                style={{ alignItems: "flex-end" }}
            >
                <FlatButton
                    style={{ minWidth: "2rem" }}
                    color="secondary"
                    onClick={() => history.push(homePath)}
                >
                    &lt;
                </FlatButton>
            </div>

            <ContractorBeforeAndAfterEditor
                openWithPhotoMode={showPictures}
                onClose={() => setShowPictures(null)}
                jobConfigurationId={jobConfigurationId}
                onUploadPhoto={(mode) => {
                    if (mode === "Before" && ableToCheckIn) checkIn();
                    else if (mode === "After" && ableToCheckOut) checkOut();
                }}
            />

            <WorkOrderViewerDialog
                installationAppointmentId={installationAppointmentId}
                jobConfigurationId={jobConfigurationId}
                open={showWorkOrder}
                onClose={() => setShowWorkOrder(false)}
            />

            <ArrivalTimeReporting
                showPage={needETANow}
                installationAppointmentId={installationAppointmentId}
                formattedAddress={formattedAddress}
                phoneNumber={customerPhone}
            />

            <CompletionCertificateDialog
                open={showCompletion}
                onClose={() => setShowCompletion(false)}
                installationAppointmentId={installationAppointmentId}
                showPDF={isComplete}
                isMobile
            />

            <FlatDialogAlert
                open={showNotes}
                onClose={() => setShowNotes(false)}
                title="Notes:"
                body={notes}
            />
        </div>
    );
}

interface ContractorJobElementVisibility {
    customerEtaBar: boolean;
    callCustomerEtaBar: boolean;
    largeMapToMaterial: boolean;
    largeCallCustomer: boolean;
    largeMapToCustomer: boolean;
    largeCheckIn: boolean;
    largeCollectCOD: boolean;
    largeCheckOut: boolean;
    largeCompletionCertificate: boolean;
    largeJobCompleted: boolean;
    mainSectionSeparator: boolean;
    smallBaseJobScope: boolean;
    largeBeforePictures: boolean;
    smallBeforePictures: boolean;
    smallCOD: boolean;
    smallFullCOD: boolean;
    smallAfterPictures: boolean;
    smallCompletionCertificate: boolean;
    smallMapToCustomer: boolean;
    smallMapToMaterial: boolean;
}

function getStageButtons(appointment: InstallationAppointment): ContractorJobElementVisibility {
    const base: ContractorJobElementVisibility = {
        customerEtaBar: false,
        callCustomerEtaBar: true,
        largeMapToMaterial: false,
        largeCallCustomer: false,
        largeMapToCustomer: false,
        largeCheckIn: false,
        largeCollectCOD: false,
        largeCheckOut: false,
        largeCompletionCertificate: false,
        largeJobCompleted: false,
        mainSectionSeparator: true,
        smallBaseJobScope: false,
        smallBeforePictures: false,
        largeBeforePictures: false,
        smallCOD: false,
        smallFullCOD: false,
        smallAfterPictures: false,
        smallCompletionCertificate: false,
        smallMapToCustomer: false,
        smallMapToMaterial: false,
    };

    const debugStage: null | number = null;
    const normalStages = debugStage === null;

    // Stage 1: Not picked up material yet
    if (
        debugStage === 1 ||
        (normalStages && isNullOrUndefined(appointment.pickedUpMaterialsTime))
    ) {
        return {
            ...base,
            largeMapToMaterial: true,
            mainSectionSeparator: false,
            callCustomerEtaBar: false,
        };
    }
    // Stage 2: Not yet called customer
    else if (
        debugStage === 2 ||
        (normalStages && isNullOrUndefined(appointment.contractorLastContactAttempt))
    ) {
        return {
            ...base,
            largeCallCustomer: true,
            smallBaseJobScope: true,
            smallMapToCustomer: true,
            smallMapToMaterial: true,
        };
    }
    // Stage 3: Not yet mapped to customer
    else if (
        debugStage === 3 ||
        (normalStages && isNullOrUndefined(appointment.mappedToCustomerTime))
    ) {
        return {
            ...base,
            largeMapToCustomer: true,
            smallBaseJobScope: true,
            customerEtaBar: true,
        };
    }
    // Stage 4: Not yet checked in to customer
    else if (debugStage === 4 || (normalStages && isNullOrUndefined(appointment.checkInTime))) {
        return {
            ...base,
            largeCheckIn: true,
            smallBaseJobScope: true,
            smallMapToCustomer: true,
            customerEtaBar: true,
        };
    }
    // Stage 5: Not yet collected cod
    else if (debugStage === 5 || (normalStages && appointment.cod > 0)) {
        return {
            ...base,
            largeCollectCOD: true,
            smallBaseJobScope: true,
            largeBeforePictures: true,
        };
    }
    // Stage 6: Not yet checked out
    else if (debugStage === 6 || (normalStages && isNullOrUndefined(appointment.checkOutTime))) {
        return {
            ...base,
            largeCheckOut: true,
            smallBaseJobScope: true,
            smallBeforePictures: true,
            smallCOD: true,
        };
    }
    // Stage 7: Not yet filled out completion certificate
    else if (
        debugStage === 7 ||
        (normalStages && isNullOrUndefined(appointment.certificateRating))
    ) {
        return {
            ...base,
            largeCompletionCertificate: true,
            smallBaseJobScope: true,
            smallCOD: true,
            smallAfterPictures: true,
        };
    }
    // Stage 8: Job is completed
    else if (debugStage === 8 || appointment.isComplete) {
        return {
            ...base,
            largeJobCompleted: true,
            mainSectionSeparator: false,
            smallBaseJobScope: true,
            smallFullCOD: true,
            smallCompletionCertificate: true,
        };
    } else {
        throw new Error("Appointment state is not valid");
    }
}

function MaterialButtons({
    carpetTotal,
    woodTotal,
    spcTotal,
    installationAppointmentId,
    pickedUpMaterialsTime,
}: {
    carpetTotal: number;
    woodTotal: number;
    spcTotal: number;
    installationAppointmentId: number;
    pickedUpMaterialsTime?: Maybe<string>;
}) {
    const [mapToMaterial] = usePickedUpMaterialsForInstallationAppointmentMutation({
        variables: { appointmentId: installationAppointmentId },
    });

    const updateJob = isNullOrUndefined(pickedUpMaterialsTime);

    return (
        <>
            {carpetTotal > 0 && (
                <FlatButton
                    color="secondary"
                    onClick={() => {
                        if (updateJob) mapToMaterial();
                        openSoftSurface();
                    }}
                >
                    CPT
                </FlatButton>
            )}
            {woodTotal > 0 && (
                <FlatButton
                    onClick={() => {
                        if (updateJob) mapToMaterial();
                        openHardSurface();
                    }}
                    color="secondary"
                >
                    Wood
                </FlatButton>
            )}
            {spcTotal > 0 && (
                <FlatButton
                    onClick={() => {
                        if (updateJob) mapToMaterial();
                        openHardSurface();
                    }}
                    color="secondary"
                >
                    SPC
                </FlatButton>
            )}
        </>
    );
}

function openHardSurface() {
    openAddress("50327 Hayes Rd, Shelby Township, MI 48315");
}

function openSoftSurface() {
    openAddress("43665 Utica Rd, Sterling Heights, MI 48314");
}

function openAddress(address: string) {
    const urlFormattedAddress = address.replaceAll(" ", "+");
    window.open(`https://www.google.com/maps/place/${urlFormattedAddress}`);
}

interface LocalDialogProps {
    open: boolean;
    onClose: () => void;
}

function WorkOrderViewerDialog({ open, onClose, ...woProps }: WorkOrderProps & LocalDialogProps) {
    const [showWorkOrder, setShowWorkOrder] = useState(true);

    const { data } = useGetInstallationAppointmentWithRoomsQuery({
        variables: { installationAppointmentId: woProps.installationAppointmentId },
    });

    return (
        <Dialog
            fullScreen
            fullWidth
            open={open}
            onClose={onClose}
        >
            <div style={{ position: "relative" }}>
                <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
                    {showWorkOrder ? (
                        <WorkOrder
                            {...woProps}
                            mobileFriendly
                        />
                    ) : (
                        <div
                            className="flex-column flex-centered"
                            style={{
                                width: "100%",
                                boxSizing: "border-box",
                                alignItems: "center",
                            }}
                        >
                            <JobSvgViewer
                                jobConfigurationId={woProps.jobConfigurationId}
                                roomIdsToHighlight={
                                    data?.installationAppointmentWithRooms.rooms.map((r) => r.id) ??
                                    undefined
                                }
                                showCarpetCuts
                                useAbbreviatedCutNames
                            />
                        </div>
                    )}
                </div>
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                    }}
                >
                    <IconButton
                        onClick={(e) => {
                            onClose();
                            e.stopPropagation();
                        }}
                        size="medium"
                    >
                        <HighlightOffIcon
                            style={{
                                backgroundColor: "#aaaa",
                                borderRadius: "1rem",
                            }}
                        />
                    </IconButton>
                </div>
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                    }}
                >
                    <IconButton
                        onClick={(e) => {
                            setShowWorkOrder(!showWorkOrder);
                            e.stopPropagation();
                        }}
                        size="medium"
                    >
                        {showWorkOrder ? <PictureInPicture /> : <FormatListBulleted />}
                    </IconButton>
                </div>
            </div>
        </Dialog>
    );
}

function createETARange(eta: string) {
    const { hour, minute } = timeSpanStrToTime(eta);

    const endHour = (hour + 1) % 24;

    return `${formatMilitaryToHHMM12HR(hour, minute, true)}-${formatMilitaryToHHMM12HR(
        endHour,
        minute,
        true
    )}`;
}

interface CompletionCertificateDialogProps {
    showPDF: boolean;
    isMobile?: boolean;
}

export function CompletionCertificateDialog({
    open,
    onClose,
    showPDF,
    isMobile,
    ...completionProps
}: LocalDialogProps & CompletionCertificateProps & CompletionCertificateDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={isMobile}
            fullWidth={isMobile}
        >
            <div
                className="flex-column"
                style={{ height: "100%", position: "relative" }}
            >
                {showPDF ? (
                    <CompletionCertificatePDF
                        installationAppointmentId={completionProps.installationAppointmentId}
                    />
                ) : (
                    <CompletionCertificate
                        {...completionProps}
                        onClose={onClose}
                    />
                )}
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                    }}
                >
                    <IconButton
                        onClick={(e) => {
                            onClose();
                            e.stopPropagation();
                        }}
                        size="medium"
                    >
                        <HighlightOffIcon
                            style={{
                                backgroundColor: "#aaaa",
                                borderRadius: "1rem",
                            }}
                        />
                    </IconButton>
                </div>
            </div>
        </Dialog>
    );
}
