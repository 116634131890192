import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { projectManagementPath } from "Globals/PathStrings";
import { useHistory } from "react-router-dom";

export default function GoBackButton() {
    const history = useHistory();

    function goToPmDashboard() {
        history.push(projectManagementPath);
    }

    return (
        <span
            onClick={goToPmDashboard}
            id="go-back"
        >
            <NavigateBeforeIcon htmlColor="var(--flat-red)"/>

            <p
                className="flat-font bold-text"
                style={{color: "var(--flat-red)", marginBottom: "2px"}}
            >
                Go Back
            </p>
        </span>
    )
}