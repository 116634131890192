import { Button, IconButton, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import { Room } from "generated/graphql";
import { isEmptyString, isNullOrUndefined } from "Globals/GenericValidators";
import { useMemo, useState } from "react";
import { useContextMenu } from "react-contexify";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { AddRoomContextMenu, makeAddRoomContextMenuId } from "./AddRoomContextMenu";

export interface EditableChargeableRoomDetail {
    roomId: number;
    serviceForRoomId: number;
    roomLabel: string;
    serviceQuantity: number;
    custDoes: boolean;
}

export interface EditableChargeableService {
    jobServiceId: number;
    serviceLabel: string;
    roomDetails: EditableChargeableRoomDetail[];
}

interface EditableChargeableServiceRowProps {
    service: EditableChargeableService;
    setCustDoes: (newCustDoes: boolean, serviceIds: number[]) => void;
    setQuantity?: (newQty: number, serviceId: number) => void;  // don't pass for furniture
    removeForRoom: (roomId: number) => void;  // removes this service for the room
    initializeForRoom: (roomId: number) => void;  // adds and initialized service for the room
    defaultOpenByRoom: boolean;  // whether to expand the rooms on initial render (useful when adding new service)
    allRoomsInArea: Room[]; // used to determine which rooms the service should be able to be added to
}

// this component facilitates more in-depth editing services grouped by job service ID, allowing access to the service for each room individually when expanded
export default function EditableChargeableServiceRow({
    service,
    allRoomsInArea,
    setCustDoes,
    setQuantity,
    removeForRoom,
    initializeForRoom,
    defaultOpenByRoom
}: EditableChargeableServiceRowProps) {
    const [byRoomOpen, setByRoomOpen] = useState(defaultOpenByRoom);
    
    const whoDoesLabel = useMemo(() => {
        // find all rooms where the service actually applies
        const roomsWithService = service.roomDetails.filter(rd => rd.serviceQuantity > 0);
        if (roomsWithService.length === 0) return "NONE";
        if (roomsWithService.every(sr => sr.custDoes)) return "CUST";
        if (roomsWithService.every(sr => !sr.custDoes)) return "WOF";
        return "BY ROOM";
    }, [service.roomDetails]);
    const allServiceIds = service.roomDetails.map(sr => sr.serviceForRoomId);

    function determineToggleWhoDoesForAll() {
        if (whoDoesLabel === "CUST" || whoDoesLabel === "BY ROOM") {
            return false; // mark as WOF does
        } else {
            return true; // mark as CUST does
        }
    }

    // the list of rooms that don't have this service - used to populate the context menu options
    const roomsWithoutThisService = useMemo(() => {
        return allRoomsInArea.filter(ria => isNullOrUndefined(service.roomDetails.find(rd => rd.roomId === ria.id)))
    }, [allRoomsInArea, service.roomDetails]);

    function onAddRoomToService(roomId: number) {
        setByRoomOpen(true);
        initializeForRoom(roomId);
    }

    const { show } = useContextMenu({id: makeAddRoomContextMenuId(service.jobServiceId)});

    return (
        <>
            <div
                className="fill-width flex-row"
                style={{ alignItems: "center" }}
            >
                <div className="flex-grow flex-row flex-gap-sm">
                    <Typography style={{ fontSize: "1.25rem" }}>{service.serviceLabel}</Typography>
                    {(roomsWithoutThisService.length > 0) && <FlatAddButton onClick={show}/>}
                </div>
                <div
                    className="flex-row-center"
                    style={{ width: "8rem" }}
                >
                    <Button
                        style={{ height: "2rem", fontSize: "1.15rem" }}
                        variant="contained"
                        onClick={() => setCustDoes(determineToggleWhoDoesForAll(), allServiceIds)}
                    >
                        {whoDoesLabel}
                    </Button>
                </div>
                <div style={{ width: "3rem" }}>
                    <IconButton
                        onClick={() => setByRoomOpen(!byRoomOpen)}
                        style={{
                            height: "2rem",
                            width: "4rem",
                            fontSize: "1.25rem",
                            margin: ".25rem 0",
                        }}
                    >
                        {!byRoomOpen && <ArrowDropDownIcon fontSize="large" />}
                        {byRoomOpen && <ArrowDropUpIcon fontSize="large" />}
                    </IconButton>
                </div>
            </div>
            
            {byRoomOpen && (
                <div className="fill-width flex-column flex-gap-xsm">
                    {service.roomDetails.map(rd => (
                        <EditableServiceForRoomEditor
                            key={rd.roomId}
                            roomDetails={rd}
                            setCustDoes={cd => setCustDoes(cd, [rd.serviceForRoomId])}
                            setQuantity={setQuantity ? (qty) =>  setQuantity(qty, rd.serviceForRoomId) : undefined}
                            removeService={() => removeForRoom(rd.roomId)}          
                        />
                    ))}
                </div>
            )}

            <AddRoomContextMenu
                roomOptions={roomsWithoutThisService.map(r => (
                    {id: r.id, label: getNameOfArea(r.labels)}
                ))}
                jobServiceId={service.jobServiceId}
                handleRoomClicked={onAddRoomToService}
            />
        </>
    );
}

interface EditableServiceForRoomEditorProps {
    roomDetails: EditableChargeableRoomDetail;
    setCustDoes: (custDoes: boolean) => void;
    setQuantity?: (qty: number) => void;  // don't pass for services based on room sqft
    removeService: () => void; // removes this room from the service grouping
}

function EditableServiceForRoomEditor({
    roomDetails: rd,
    setCustDoes,
    setQuantity,
    removeService
}: EditableServiceForRoomEditorProps) {
    const [qtyText, setQtyText] = useState(rd.serviceQuantity.toString());

    function onChangeQty(newQty: string) {
        if (setQuantity) {
            const qtyAsNum = +newQty;
            
            if (isEmptyString(newQty)) {
                setQtyText("0");
                setQuantity(0);
            } else if (isNaN(qtyAsNum) || qtyAsNum < 0) {
                return;
            } else {
                setQtyText(newQty); // updates internal state
                setQuantity(qtyAsNum);  // updates the service that backs this field
            }
        }
    }

    return (
        <div
            className="flex-row flex-gap-xsm"
            style={{ alignItems: "center" }}
            key={rd.serviceForRoomId}
        >
            <Typography
                style={{ fontSize: "1.15rem", padding: "0 .25rem 0 .75rem" }}
            >
                [{rd.roomLabel}]
            </Typography>

            <FlatLabeledInput
                label="Qty."
                value={qtyText}
                onChange={e => onChangeQty(e.target.value)}
                className="w-2r text-align-center"
                readOnly={!onChangeQty}
            />

            {/* can only adjust who does if there's a nonzero labor amount*/}
            {(rd.serviceQuantity > 0) && (
                <Button
                    style={{ height: "2rem", width: "4rem", fontSize: "1.05rem" }}
                    variant="contained"
                    onClick={() => setCustDoes(!rd.custDoes)}
                >
                    {rd.custDoes ? "CUST" : "WOF"}
                </Button>
            )}

            <FlatDeleteButton onClick={removeService}/>
        </div>
    )
}