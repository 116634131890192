import { Button, MenuItem, Select, TextField, Typography } from "@material-ui/core"
import MeasuredValueInput, { MeasuredValue } from "Components/Inputs/MeasuredValueInput"
import { useGetStructuredProductDetailOptionsQuery, useGetUnstructuredProductDetailOptionsQuery } from "generated/graphql"
import { selectTypeId } from "Redux/addProductReducer"
import { useAppSelector } from "Redux/hooks"
import { LocalProductDetail } from "./ProductSpecViewer"



interface ProductDetailTableProps {
    productDetails: LocalProductDetail[],
    addNewDetail: (newDetail: LocalProductDetail) => void,
    removeDetail: (detailIndex: number) => void,
    editDetail: (detailIndex: number, updatedDetail: LocalProductDetail) => void,
    readOnly?: boolean
}

export default function ProductDetailTable({ productDetails, addNewDetail, removeDetail, editDetail, readOnly }: ProductDetailTableProps) {
    const productTypeId = useAppSelector(selectTypeId)
    const { data: unstructuredData } = useGetUnstructuredProductDetailOptionsQuery({ variables: { productTypeId: productTypeId }, skip: productTypeId < 1 })
    const { data: structuredData } = useGetStructuredProductDetailOptionsQuery({ variables: { productTypeId: productTypeId }, skip: productTypeId < 1 })

    const loadedUnstructuredDetails = unstructuredData?.unstructuredProductDetailOptions ?? []
    const loadedStructuredDetails = structuredData?.structuredProductDetailOptions ?? []

    const availableDetailOptions = [
        ...loadedStructuredDetails.filter(d => !productDetails.some(addedDetail => addedDetail.type === "Structured" && addedDetail.id === d.id)),
        ...loadedUnstructuredDetails.filter(d => !productDetails.some(addedDetail => addedDetail.type === "Unstructured" && addedDetail.id === d.id))
    ]

    function addDetail(index: number) {
        const foundDetailOption = availableDetailOptions[index]

        const isStruct = foundDetailOption.__typename === loadedStructuredDetails?.[0].__typename
        const newDetail: LocalProductDetail = {
            type: isStruct ? "Structured" : "Unstructured",
            id: foundDetailOption.id,
            value: isStruct
                ? { inputValue: "", unitId: (foundDetailOption as typeof loadedStructuredDetails[number]).unitOptions?.[0].unitId }
                : ""
        }
        addNewDetail(newDetail)
    }

    return (
        <table className="fill-width">
            <tbody>
                {productDetails.map((addedDetail, index) => {
                    const unitOptions = (
                        (addedDetail.type === "Unstructured")
                            ? []
                            : loadedStructuredDetails.find(d => d.id === addedDetail.id)?.unitOptions ?? []
                    ).map(u => u.unitId);
                    
                    return (
                        <tr key={`product-detail-${index}`}>
                            <td>
                                <span className="flex-row margin-bottom-sm flex-gap-sm">
                                    <span className="flex-row flex-gap-sm align-items-center">
                                        <Typography>
                                            {((addedDetail.type === "Unstructured")
                                                ? loadedUnstructuredDetails.find(o => o.id === addedDetail.id)?.name
                                                : loadedStructuredDetails.find(o => o.id === addedDetail.id)?.name) ?? "UNKNOWN"
                                            }
                                        </Typography>
                                        
                                        <span className="flex-gap-md">
                                            {addedDetail.type === "Unstructured" && (
                                                <TextField
                                                    value={addedDetail.value}
                                                    onChange={(x) => editDetail(index, { ...addedDetail, value: x.target.value })}
                                                    inputProps={{ readOnly }}
                                                    className="fill-width"
                                                />
                                            )}

                                            <span>
                                                {addedDetail.type === "Structured" && (
                                                    <MeasuredValueInput
                                                        value={addedDetail.value as MeasuredValue}
                                                        setValue={(x) => editDetail(index, { ...addedDetail, value: x })}
                                                        unitOptions={unitOptions}
                                                        readOnly={readOnly}
                                                    />
                                                )}
                                            </span>
                                        </span>
                                    </span>

                                    {!readOnly && (
                                        <Button variant="contained" onClick={() => removeDetail(index)}>X</Button>
                                    )}
                                </span>
                            </td>
                        </tr>
                    )
                })}

                {(!readOnly && availableDetailOptions.length > 0) && (
                    <tr key="product-detail-add-new">
                        <td className="w-10r">
                            <Select value={-1}>
                                <MenuItem disabled value={-1}>
                                    Add Detail
                                </MenuItem>

                                {availableDetailOptions.map((o, index) => 
                                    <MenuItem
                                        key={`add-new-detail-${index}`}
                                        onClick={() => addDetail(index)}
                                        value={index}
                                    >
                                        {o.name}
                                    </MenuItem>)
                                }
                            </Select>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}