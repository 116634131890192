import { Radio } from "@material-ui/core";
import clsx from "clsx";
import { FlatLabeledInputBase } from "./flatInputUtils";

export interface FlatRadioGroupOption {
    value: number | string;
    label: string;
}

interface FlatRadioGroupProps {
    value: number | string;
    onChange: (newVal: number | string) => void;
    options: FlatRadioGroupOption[];
    disabled?: boolean;
    vertical?: boolean;
    size?: "small" | "medium"
    color?: string;
    bold?: boolean;
    radioLabelSize?: "xsm" | "sm" | "standard" | "md" | "lg";
    radioLabelClassName?: string;
    radioLabelStyle?: React.CSSProperties;
}

export default function FlatRadioGroup({
    value,
    onChange,
    options,
    color="black",
    size="medium",
    vertical=false,
    disabled=false,
    bold=false,
    radioLabelSize="standard",
    radioLabelClassName: additionalRadioLabelClassName,
    radioLabelStyle
} : FlatRadioGroupProps) {
    const radioLabelClassName = clsx("margin-none", {
        "flat-font-xsm": radioLabelSize === "xsm",
        "flat-font-sm": radioLabelSize === "sm",
        "flat-font": radioLabelSize === "standard",
        "flat-font-md": radioLabelSize === "md",
        "flat-font-lg": radioLabelSize === "lg",
        "bold-text": bold
    }, additionalRadioLabelClassName);

    return (
        <div className={vertical ? "flex-column" : "flex-row"}>
            {options.map(o => (
                <span className="flex-row align-items-center" key={`radio-${o.value}`}>
                    <Radio
                        disabled={disabled}
                        checked={value === o.value}
                        onClick={() => onChange(o.value)}
                        size={size}
                        style={{color: color}}
                    />
                    <p
                        className={radioLabelClassName}
                        style={radioLabelStyle}
                    >
                        {o.label}
                    </p>
                </span>
            ))}
        </div>
    )
}

type FlatLabeledRadioGroupProps = Omit<FlatLabeledInputBase<FlatRadioGroupProps>, "labelLocation" | "justification">

export function FlatLabeledRadioGroup({
    label,
    bold=true,
    labelSize="standard",
    labelStyle,
    containerProps,
    ...radioGroupProps
}: FlatLabeledRadioGroupProps) {
    const containerClassName = clsx("align-items-left", containerProps?.className);

    const labelClassName = clsx("margin-none", {
        "flat-font-xsm": labelSize === "xsm",
        "flat-font-sm": labelSize === "sm",
        "flat-font": labelSize === "standard",
        "flat-font-md": labelSize === "md",
        "flat-font-lg": labelSize === "lg",
        "bold-text": bold
    });

    // remove className from the spread props because it will overwrite the directly passed className prop
    const {className, ...cleanedContainerProps} = {...containerProps};
    
    return (
        <span className={containerClassName} {...cleanedContainerProps}>
            <p
                className={labelClassName}
                style={{...labelStyle}}
            >
                {label}
            </p>

            <FlatRadioGroup {...radioGroupProps} />
        </span>
    )
}