export const S3_WORKER_DIRECTORY = "workers";
export const S3_BG_CHECK_DIRECTORY = `${S3_WORKER_DIRECTORY}/background_check}`;
export const S3_DRIVERS_LICENSE_DIRECTORY = `${S3_WORKER_DIRECTORY}/drivers_license`;
export const S3_LICENSE_DIRECTORY = `${S3_WORKER_DIRECTORY}/license`;
export const S3_W9_DIRECTORY = `${S3_WORKER_DIRECTORY}/w9`;
export const S3_WORKERS_COMP_DIRECTORY = `${S3_WORKER_DIRECTORY}/workers_comp`;
export const S3_LIABILITY_INS_DIRECTORY = `${S3_WORKER_DIRECTORY}/liability_ins`;

export const PDF_EXT = "pdf";
export const SVG_EXP = "webp";
export const JPG_EXT = "jpg";
export const JPEG_EXT = "jpeg";
export const PNG_EXT = "png";
export const WEBP_EXP = "webp";
export const IMG_EXTENSIONS = [JPG_EXT, JPEG_EXT, PNG_EXT, WEBP_EXP];
export const isImageExtension = (ext: string) => IMG_EXTENSIONS.includes(ext);

/**
 * Get the file extension from the name of a file. Throws an exception if there is no extension.
 * @param fileName The name of the file, from which the extension will be extracted. 
 * @returns The file extension (without the leading "."), or an exception if there is no extension.
 */
export function extractFileExtension(fileName: string): string {
    const split = fileName.split(".");
    if (split.length <= 1) {
        throw new Error(`File name must include extension, but it does not. Got "${fileName}"`);
    } else {
        return split[split.length - 1];
    }
}

/**
 * Uploads a file to an AWS S3 bucket using a presigned URL. Note that this method does not permit
 * specification of bucket name or object key. All of this is specified in the presigned URL that is
 * generated on the back end.
 * Returns whether the upload was successful.
 * @param file The file to be uploaded
 * @param presignedUrl The presigned URL generated by the backend.
 */
export async function uploadFileToS3(file: File, presignedUrl: string) {
    return fetch(presignedUrl, {
        method: "PUT",
        body: file
    });
}

export async function getFileFromS3(presignedUrl: string) {
    return fetch(presignedUrl, { method: "GET" });
}