import { useGetPartialProductStyleQuery } from "generated/graphql"
import { useAppSelector } from "Redux/hooks"
import { selectCarpetCostMode } from "Redux/productCostsReducer"
import ProductCostsStyleRow from "../GenericTable/ProductCostsStyleRow"


export type CarpetCostsRowMode = 'sqft' | 'sqyd'

export default function CarpetCostsStyleRow({ styleId }: { styleId: number }) {

    const mode = useAppSelector(selectCarpetCostMode)
    const { data, loading } = useGetPartialProductStyleQuery({ variables: { styleId } })

    if (loading || !(data?.partialProductStyle)) return <tr />

    const {
        style,
        singleCostPerSqft: loadedSingleCost,
        bulkCostPerSqft: loadedBulkCost
    } = data.partialProductStyle

    const scaledLoadedBulkCost = loadedBulkCost * ((mode === 'sqft') ? 1 : 9)
    const scaledLoadedSingleCost = loadedSingleCost * ((mode === 'sqft') ? 1 : 9)

    return <ProductCostsStyleRow
        styleId={styleId}
        styleName={style}
        loadedBulkCost={scaledLoadedBulkCost}
        loadedSingleCost={scaledLoadedSingleCost}
        preventShowColor />

}

