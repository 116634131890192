import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import React from "react";

export default function CircleAddButton(props: React.ComponentProps<typeof Button>) {
    const { style, ...rest } = props

    return <Button {...rest} style={{ borderRadius: "2rem", width: "1rem", minWidth: "2.25rem", ...style }}>
        <AddIcon />
    </Button>
}

export function FlatCircleAddButton({onClick, style}: {onClick: () => void, style?: React.CSSProperties}) {
    return ( 
        <button
            style={{ borderRadius: "50%", padding: "5px", ...style }}
            className="flat-button-base flat-primary-minor-button"
            onClick={onClick}
        >
            <AddIcon />
        </button>
    )
}

export function ScalableCircleButton(props: React.ComponentProps<typeof Button>) {
    const { style, ...rest } = props

    return <Button {...rest} style={{ borderRadius: "100%", width: "1em", height: "2em", minWidth: "unset", ...style }}>
        <AddIcon fontSize="inherit" />
    </Button>
}