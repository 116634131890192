import { Select, MenuItem } from "@material-ui/core";
import { JobServiceOption } from "generated/graphql";

export default function AddServiceForRoomRow({ options, addOffer }: { options: JobServiceOption[], addOffer: (offerId: number) => void }) {

    return (
        <tr>
            <td className="w-10r">
                <Select value={""} fullWidth displayEmpty renderValue={() => "Add Row"}>
                    {
                        options.map(
                            op => <MenuItem value={op.jobServiceId} key={`add-service-for-room-${op.jobServiceId}`} onClick={(e) => addOffer(op.jobServiceId)}>{op.description}</MenuItem>
                        )
                    }
                </Select>
            </td>
            <td />
            <td />
            <td />
            <td />
        </tr>
    )
}
