import profilePlaceholderImg from 'Assets/Images/profile_placeholder.webp';
import { S3ImageViewer } from 'Components/Files/S3ImageViewers';
import FlatButton from 'FlatComponents/Button/FlatButton';
import FlatImageButton from 'FlatComponents/Button/FlatImageButton';
import { FlatImageInput } from 'FlatComponents/Inputs/FlatFileInput';
import FlatDialog from 'FlatComponents/Layout/FlatDialog';
import { useReportS3FileUploadStatusMutation, useSetWorkerProfilePictureMutation } from 'generated/graphql';
import { extractFileExtension, uploadFileToS3 } from 'Globals/FileUtils';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Globals/GenericValidators';
import { useState } from 'react';
import "./profile_styles.css";
import RatingPill from './RatingPill';

interface ProfileImageProps {
    workerId: number;
    toRefetch: string; // query to refetch when image is done uploading
    rating?: number;
    editable?: boolean;
    photoId?: number;
}

export default function ProfileImage({rating, toRefetch, workerId, photoId, editable=false}: ProfileImageProps) { 
    const [uploaderOpen, setUploaderOpen] = useState(false);

    return (<>
        <div id="profile-img-section">
            <div id="profile-img-container">
                <S3ImageViewer
                    fileId={photoId ?? -1}
                    placeholderSrc={profilePlaceholderImg}
                />
                {editable && (
                    <FlatImageButton
                        id="profile-img-button"
                        onClick={() => setUploaderOpen(true)}
                    />
                )}
            </div>
            <div className="flex-row flex-space-between align-items-center">
                {rating ? <RatingPill rating={rating}/> : <div />}
                <p className="margin-none flat-font" style={{visibility: workerId < 1 ? "hidden" : "visible"}}>
                    C-{10000 + workerId}
                </p>
            </div>
        </div>

        {uploaderOpen && (
            <ProfilePictureDialog
                workerId={workerId}
                refetchOnPhotoUpdate={toRefetch}
                onClose={() => setUploaderOpen(false)}
            />
        )}
    </>)
}

interface ProfilePictureDialogProps {
    workerId: number;
    refetchOnPhotoUpdate: string;
    onClose: () => void;
}

function ProfilePictureDialog({workerId, onClose, refetchOnPhotoUpdate}: ProfilePictureDialogProps) {
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [setProfilePicture, {loading: settingPicture}] = useSetWorkerProfilePictureMutation({
        onError: () => alert("Failed to update profile picture"),
        refetchQueries: [refetchOnPhotoUpdate]
    });
    const [reportUploadStatus, {loading: reportingStatus}] = useReportS3FileUploadStatusMutation();

    function onSubmit() {
        if (isNullOrUndefined(selectedFile)) {
            alert("Select a file");
            return;
        }

        let fileExtension: string;
        try {
            fileExtension = extractFileExtension(selectedFile!.name);
        } catch {
            alert("File must have extension (e.g., '.jpg' or '.png'");
            return;
        }

        setProfilePicture({
            variables: {
                workerId: workerId,
                fileExtension: fileExtension!
            }
        }).then(fileData => {
            const uploadInfo = fileData.data?.setWorkerProfilePicture;
            if (isNotNullOrUndefined(uploadInfo)) {
                const {id: fileId, uploadUrl} = uploadInfo!;
                uploadFileToS3(selectedFile!, uploadUrl).then(result => {
                    reportUploadStatus({variables:
                        {fileId: fileId, succeeded: result.ok}
                    }).then(_ => onClose());

                    if (!result.ok) {
                        alert("Failed to upload file");
                    }
                }).catch(_ => {
                    reportUploadStatus({variables:
                        {fileId: fileId, succeeded: false}
                    });

                    alert("Failed to upload file");
                });
            }
        });
    }

    const submitButton = (
        <FlatButton
            key={1}
            onClick={onSubmit}
            color="secondary"
            variant="contained"
            disabled={isNullOrUndefined(selectedFile) || settingPicture || reportingStatus}
        >Upload</FlatButton>
    );

    return (
        <FlatDialog
            dialogProps={{open: true, onClose: onClose}}
            sectionProps={{header: "Set Profile Picture"}}
            actionButtons={[submitButton]}
        >
            <FlatImageInput setSelectedFile={setSelectedFile} />
        </FlatDialog>
    )
}
