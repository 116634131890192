import { IconButton, Tooltip } from "@material-ui/core";
import EmailIcon from '@mui/icons-material/Email';
import { S3PdfViewer } from "Components/Files/S3PdfViewers";
import Loading from "Components/Loading";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatUploadFileButton from "FlatComponents/Button/FlatUploadFileButton";
import FlatFileInput from "FlatComponents/Inputs/FlatFileInput";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { namedOperations, useGetJobContractPdfS3FileQuery, useGetJobInvoiceDataQuery, useReportS3FileUploadStatusMutation, useUpsertContractPdfPresignedMutation } from "generated/graphql";
import { uploadFileToS3 } from "Globals/FileUtils";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { selectJobConfigurationId, selectJobContractId } from "Redux/jobTrackerReducer";
import EmailContractDialog from "../../Invoice/EmailContractDialog";

export default function OriginalContractPage() {
    const jobContractId = useAppSelector(selectJobContractId);
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    
    const { data: jobData } = useGetJobInvoiceDataQuery({
        skip: jobConfigurationId < 1 || jobContractId < 1,
        variables: { jobConfigurationId: jobConfigurationId ?? 0 },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only"
    });

    const customer = jobData?.jobConfigurationHeader.customer;

    const { data, loading } = useGetJobContractPdfS3FileQuery({
        variables: { jobContractId },
        skip: jobContractId < 1,
        onError: () => alert("Couldn't load contract PDF")
    });
    const contractPdfFileId = data?.jobContractPdfS3File?.id ?? -1;

    const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    const uploadButton = (
        <Tooltip title={"Upload PDF"}>
            <FlatUploadFileButton onClick={() => setUploadDialogOpen(true)} />
        </Tooltip>
    )

    return (<>
        <div className="flat-outer-container margin-vertical-sm" style={{height: "90vh"}}>
            {loading ? (
                <Loading />
            ) : (<>
                {(contractPdfFileId < 1) ? (
                    <span className="flex-row flex-gap-sm align-items-center justify-content-center padding-sm">
                        <Tooltip title={"Generate and Email Contract"}>
                            <IconButton onClick={() => setEmailDialogOpen(true)}>
                                <EmailIcon />
                            </IconButton>
                        </Tooltip>

                        <p className="margin-none flat-font">
                            Contract PDF has not been generated. Click the mail button to generate and email the PDF.
                        </p>

                        {uploadButton}
                    </span>
                ) : (
                    <div className="flex-column flex-gap-xsm fill-width padding-side-md" style={{height: "85vh"}}>
                        <span className="fit-content margin-top-xsm">
                            <Tooltip title="Email Contract">
                                <IconButton
                                    onClick={() => setEmailDialogOpen(true)}
                                >
                                    <EmailIcon />
                                </IconButton>
                            </Tooltip>

                            {uploadButton}
                        </span>

                        <S3PdfViewer
                            fileId={contractPdfFileId}
                            style={{ position: "relative", top: 0, bottom: 0, right: 0, width: "100%", height: "100%" }}
                        />
                    </div>
                )}</>
            )}
        </div>

        {emailDialogOpen && (
            <EmailContractDialog
                contractId={jobContractId}
                defaultIncludeBaseEmail={true}
                baseEmail={customer!.email}
                closeDialog={() => setEmailDialogOpen(false)}
                refetchQuery={namedOperations.Query.GetJobContractPdfS3File}
            />
        )}

        {uploadDialogOpen && (
            <UploadContractDialog
                contractId={jobContractId}
                onClose={() => setUploadDialogOpen(false)}
                isReplacementMode={contractPdfFileId > 0}
            />
        )}
    </>);
}

interface UploadContractDialogProps {
    contractId: number;
    isReplacementMode: boolean; // true if replacing an existing PDF, false if PDF is new
    onClose: () => void;
}

function UploadContractDialog({contractId, isReplacementMode, onClose}: UploadContractDialogProps) {
    const [file, setFile] = useState<File | undefined>(undefined);

    const [upsertPdf, {loading: upserting}] = useUpsertContractPdfPresignedMutation({
        refetchQueries: [namedOperations.Query.GetJobContractPdfS3File]
    });
    const [reportUploadStatus, {loading: reportingStatus}] = useReportS3FileUploadStatusMutation();

    function onSubmit() {
        if (isNotNullOrUndefined(file)) {
            // warn user that uploading this file will permanently replace existing file
            if (isReplacementMode &&
                !window.confirm("Uploading this file will replace the existing file. This can't be undone, are you sure you wish to replace the existing PDF?")
            ) {
                return;
            } // else, keep going

            upsertPdf({ variables: { contractId: contractId} })
            .then(res => {
                const uploadInfo = res.data?.upsertContractPdfPresigned;
                if (isNotNullOrUndefined(uploadInfo)) {
                    uploadFileToS3(file!, uploadInfo!.uploadUrl)
                    .then(res => {
                        reportUploadStatus({
                            variables: {
                                fileId: uploadInfo!.id,
                                succeeded: res.ok
                            }
                        });

                        if (isReplacementMode) {
                            // need to actually reload the because when query is refetched, the component that
                            // fetches the file from S3 sees no changes to its props, so it doesn't request the
                            // new file from S3 - reloading the entire page forces it to
                            window.location.reload();
                        }

                        if (res.ok) {
                            onClose();
                        } else {
                            alert("Something went wrong when uploading the file");
                        }
                    })
                    .catch(_ => {
                        reportUploadStatus({
                            variables: {
                                fileId: uploadInfo!.id,
                                succeeded: false
                            }
                        }).then(_ => onClose());
                    });
                } else {
                    alert("Something went wrong when uploading the file");
                    onClose();
                }
            })
            .catch(_ => alert(`Could not ${isReplacementMode ? "replace" : "add"} contract PDF`));
        } else {
            alert("Select a file to upload")
        }
    }

    const submitButton = (
        <FlatButton
            key={"submit-pdf"}
            onClick={onSubmit}
            variant="contained"
            color="secondary"
            disabled={upserting || reportingStatus}
        >Submit</FlatButton>
    );

    const closeButton = (
        <FlatButton
            key={"close-uploader"}
            onClick={onClose}
            variant="contained"
            color="primary"
            disabled={upserting || reportingStatus}
        >Cancel</FlatButton>
    );


    return (
        <FlatDialog
            dialogProps={{open: true, onClose: onClose}}
            sectionProps={{header: isReplacementMode ? "Replace PDF" : "Upload PDF"}}
            actionButtons={[closeButton, submitButton]}
        >
            <FlatFileInput
                accept=".pdf"
                setSelectedFile={setFile}
            />
        </FlatDialog>
    )
}