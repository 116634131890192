import { Button } from "@material-ui/core";
import { useGetJobConfigurationQuery } from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { SPC_PRODUCT_ID } from "Globals/globalConstants";
import { QuoteCardState, QuoteCardChangableState, blankQuoteCard } from "./SellSheet";

interface CompareButtonProps {
    jobConfigurationId: number;
    displayedCards: QuoteCardState[];
    setDisplayedCards: (newState: QuoteCardState[]) => void,
    nextOptionNumber: number
}
export function CompareButton({ jobConfigurationId, displayedCards, setDisplayedCards, nextOptionNumber }: CompareButtonProps) {

    const { data, loading } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    });

    function compareClicked() {
        if (displayedCards.length === 1
            && data?.jobConfiguration.areas.some(area => area.productTypeId === SPC_PRODUCT_ID)) {
            // 3 version breakdown
            // Ideally we want the breakdown to be Nova 7 (33), West Lake (25), and Rolling Hills (17)
            const NOVA_7_ID = 33;
            const WEST_LAKE_ID = 25;
            const ROLLING_HILLS_ID = 17;

            const spcArea = data.jobConfiguration.areas.find(area => area.productTypeId === 2)!;
            const spcAreaId = spcArea.id;

            const newSPCChanges: QuoteCardChangableState = { productTypeId: 2, productTypeName: "SPC" };
            const westLakeChanges: QuoteCardChangableState = { ...newSPCChanges, styleId: WEST_LAKE_ID, styleName: "West Lake" };
            const rollingHillsChanges: QuoteCardChangableState = { ...newSPCChanges, styleId: ROLLING_HILLS_ID, styleName: "Rolling Hills" };
            const nova7Changes: QuoteCardChangableState = { ...newSPCChanges, styleId: NOVA_7_ID, styleName: "Nova 7" };

            if (spcArea.styleId === WEST_LAKE_ID) {
                setDisplayedCards([
                    { ...blankQuoteCard, jobConfigurationId, isCopy: true, productChanges: { [spcAreaId]: nova7Changes }, optionNumber: nextOptionNumber },
                    ...displayedCards,
                    { ...blankQuoteCard, jobConfigurationId, isCopy: true, productChanges: { [spcAreaId]: rollingHillsChanges }, optionNumber: nextOptionNumber + 1 }
                ]);
            }
            else if (spcArea.styleId === ROLLING_HILLS_ID) {
                setDisplayedCards([
                    { ...blankQuoteCard, jobConfigurationId, isCopy: true, productChanges: { [spcAreaId]: nova7Changes }, optionNumber: nextOptionNumber },
                    { ...blankQuoteCard, jobConfigurationId, isCopy: true, productChanges: { [spcAreaId]: westLakeChanges }, optionNumber: nextOptionNumber + 1 },
                    ...displayedCards
                ]);
            }
            else {
                setDisplayedCards([
                    ...displayedCards,
                    { ...blankQuoteCard, jobConfigurationId, isCopy: true, productChanges: { [spcAreaId]: westLakeChanges }, optionNumber: nextOptionNumber },
                    { ...blankQuoteCard, jobConfigurationId, isCopy: true, productChanges: { [spcAreaId]: rollingHillsChanges }, optionNumber: nextOptionNumber + 1 }
                ]);
            }
        }
        else {
            setDisplayedCards([
                ...displayedCards,
                { ...blankQuoteCard, jobConfigurationId, isCopy: true, optionNumber: nextOptionNumber },
            ]);
        }
    }

    const allStylesSelected = data?.jobConfiguration.areas.some(area=>isNullOrUndefined(area.styleId)) === false

    return (
        <Button variant={allStylesSelected ? "contained" : "outlined"} color="secondary" onClick={compareClicked} disabled={!allStylesSelected}>
            {loading ? "Loading" : "Compare"}
        </Button>
    );
}
