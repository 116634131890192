import { configureStore } from "@reduxjs/toolkit";
import addProductReducer from "./addProductReducer";
import callCenterSchedulingReducer from "./callCenterSchedulingReducer";
import chatDrawerReducer from "./chatDrawerReducer";
import dummyReducer from "./dummyReducer";
import genericSalesReducer from "./genericSalesReducer";
import inventoryReducer from "./inventoryReducer";
import jobTrackerReducer from "./jobTrackerReducer";
import laborReducer from "./laborReducer";
import orderingReducer from "./orderingReducer";
import pickNPlaceReducer from "./pickNPlaceReducer";
import pricingSummaryReducer from "./pricingCenterReducer";
import productCostsReducer from "./productCostsReducer";
import productPricingReducer from "./productPricingReducer";
import sellSheetReducer from "./sellSheetReducer";
import weeklyInstallationReducer from "./weeklyIstallationCalendarReducer";

//Combine all the reducer slices here
export const store = configureStore({
  reducer: {
    addProduct: addProductReducer,
    dummy: dummyReducer,
    genericSales: genericSalesReducer,
    inventory: inventoryReducer,
    labor: laborReducer,
    ordering: orderingReducer,
    pickNPlace: pickNPlaceReducer,
    productCosts: productCostsReducer,
    productPricing: productPricingReducer,
    sales: sellSheetReducer,
    pricingSummary: pricingSummaryReducer,
    chatDrawer: chatDrawerReducer,
    jobTracker: jobTrackerReducer,
    callCenterScheduling: callCenterSchedulingReducer,
    weeklyInstallation: weeklyInstallationReducer
  }
});

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch