import NavbarPage from "Components/Page";
import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";
import { useGetSalespersonListQuery } from "generated/graphql";
import { emptySalespersonProfile } from "Globals/DataStructures/EmptyDataStructures";
import { idUrlMask } from "Globals/Hooks";
import { salesPeoplePath, salespersonProfilePath } from "Globals/PathStrings";
import { formatNameStringLastFirst } from "Globals/StringFormatting";
import { useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import EditSalespersonDialog from "./EditSalespersonDialog";
import SalespersonProfilePage from "./SalespersonProfilePage";

export default function SalespersonRouter() {
    return (
        <Switch>
            <Route path={salespersonProfilePath + idUrlMask}>
                <SalespersonProfilePage />
            </Route>
            <Route path={salesPeoplePath}>
                <SalespeoplePage />
            </Route>
        </Switch>
    );
}

function SalespeoplePage() {
    const history = useHistory();
    function goToSalespersonProfile(workerId: number) {
        history.push(`${salesPeoplePath}/profile/${workerId}`);
    }

    const {data} = useGetSalespersonListQuery({
        onError: () => alert("Could not load salespeople")
    });
    const salespeople = data?.allSalespeople ?? [];

    const [addDialogOpen, setAddDialogOpen] = useState(false);

    const pageTitle = (
        <span className="flex-row flex-gap-xsm">
            Sales Agents
            <FlatAddButton onClick={() => setAddDialogOpen(true)}/>
        </span>
    );

    return (<>
        <NavbarPage hideNavbar>
            <FlatNavbar title={pageTitle}/>

            {salespeople.map(s => (
                <FlatButton key={`c-${s.id}`} onClick={() => goToSalespersonProfile(s.workerId)}>
                    {formatNameStringLastFirst({firstName: s.firstName, lastName: s.lastName})}
                </FlatButton>
            ))}
        </NavbarPage>

        {addDialogOpen && (
            <EditSalespersonDialog
                onClose={() => setAddDialogOpen(false)}
                salesperson={{...emptySalespersonProfile}}
                onComplete={(workerId: number) => goToSalespersonProfile(workerId)}
            />
        )}
    </>)
}