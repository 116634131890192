import { ApolloError, ServerError } from "@apollo/client";
import { Dialog, DialogContent, IconButton, Menu, MenuItem } from "@material-ui/core";
import LocalPhoneOutlinedIcon from "@material-ui/icons/LocalPhoneOutlined";
import Loading from "Components/Loading";
import {
    namedOperations,
    useAddRecoveryCommunicationMutation,
    useMakeCallMutation,
} from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { formatPhoneNumber } from "Globals/StringFormatting";
import { RECOVERY_COMMUNICATION_OPTION_PHONE_ID } from "./ClaimedRecoveryCenterCard";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import { useRef, useState } from "react";
import clsx from "clsx";

interface RecoveryCallCenterPhoneProps extends Pick<CallCustomerPhoneButtonProps, "phoneNumber" | "className" | "asIconButton" | "iconColor"> {
    jobId: number;
}

export function RecoveryCenterCallCustomerPhoneButton({
    phoneNumber,
    jobId,
    className,
    asIconButton=true,
    iconColor
}: RecoveryCallCenterPhoneProps) {
    const [addCommunication] = useAddRecoveryCommunicationMutation({
        refetchQueries: [
            namedOperations.Query.GetAllJobsInRecovery,
            namedOperations.Query.GetAllRecoveryLedgerItems,
        ],
    });

    return (
        <CallCustomerPhoneButton
            onCallConnected={(error, dialoutId) => {
                if (error !== undefined) {
                    window.alert(error);
                } else {
                    addCommunication({
                        variables: {
                            jobId,
                            recoveryCommunicationOptionId: RECOVERY_COMMUNICATION_OPTION_PHONE_ID,
                            dialoutId: dialoutId,
                        },
                    });
                }
            }}
            phoneNumber={phoneNumber}
            className={className}
            asIconButton={asIconButton}
            iconColor={iconColor}
        />
    );
}

export interface CallCustomerPhoneButtonProps {
    onClick?: () => void;
    onCallConnected?: (error: string | undefined, dialoutId: string | undefined) => void;
    phoneNumber: string;
    className?: string;
    asIconButton?: boolean;
    iconColor?: string;
}

export default function CallCustomerPhoneButton({
    onClick,
    onCallConnected,
    phoneNumber,
    className,
    asIconButton=true,
    iconColor
}: CallCustomerPhoneButtonProps) {
    const [makeCall, { loading }] = useMakeCallMutation();

    function handlePhoneClicked() {
        onClick?.();
        makeCall({
            variables: { phoneNumber, alternativeUserEmail: "wofit@worldoffloors.com" },
        }).then(
            ({ data }) => {
                if (isNullOrUndefined(data?.makeCall)) {
                    onCallConnected?.(
                        "Bypassed the could not be made check",
                        data?.makeCall ?? undefined
                    );
                } else {
                    onCallConnected?.(undefined, data?.makeCall ?? undefined);
                }
            },
            (errObj) => {
                onCallConnected?.(
                    ((errObj as ApolloError).networkError as ServerError)?.result?.errors?.[0]
                        ?.message ?? errObj.message,
                    undefined
                );
            }
        );
    }

    return (
        <>
            {asIconButton ? (
                <IconButton
                    size="small"
                    onClick={handlePhoneClicked}
                >
                    <LocalPhoneOutlinedIcon
                        style={{ width: "1rem", height: "1rem" }}
                        className={clsx('project-card-action-icon', className)}
                        htmlColor={iconColor}
                    />
                </IconButton>
            ) : (
                <LocalPhoneOutlinedIcon
                    className={clsx('project-card-action-icon', className)}
                    onClick={handlePhoneClicked}
                />
            )}

            <Dialog open={loading}>
                <DialogContent>
                    <div style={{ width: "20rem", height: "20rem" }}>
                        <Loading altText={`Placing Call to ${formatPhoneNumber(phoneNumber)}`} />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

type PhoneNumberAndLabel = { phoneNumber: string; label: string };

interface MultiCallCustomerPhoneButtonProps {
    onClick?: () => void;
    onCallConnected?: (error: string | undefined, dialoutId: string | undefined) => void;
    phoneNumbers: PhoneNumberAndLabel[];
}

export function CommandCenterCallButton({
    onClick,
    onCallConnected,
    phoneNumbers,
}: MultiCallCustomerPhoneButtonProps) {
    const [makeCall, { loading }] = useMakeCallMutation();

    const callingPhoneNum = useRef<string>("");
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

    function handlePhoneClicked(phoneNumber: string) {
        callingPhoneNum.current = phoneNumber;
        onClick?.();
        makeCall({
            variables: { phoneNumber, alternativeUserEmail: "wofit@worldoffloors.com" },
        }).then(
            ({ data }) => {
                if (isNullOrUndefined(data?.makeCall)) {
                    onCallConnected?.(
                        "Bypassed the could not be made check",
                        data?.makeCall ?? undefined
                    );
                } else {
                    onCallConnected?.(undefined, data?.makeCall ?? undefined);
                }
            },
            (errObj) => {
                onCallConnected?.(
                    ((errObj as ApolloError).networkError as ServerError)?.result?.errors?.[0]
                        ?.message ?? errObj.message,
                    undefined
                );
            }
        );
    }

    return (
        <>
            <div
                className="flex-row"
                style={{
                    backgroundColor: "var(--flat-gray-1)",
                    height: "24px",
                    width: "24px",
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    setMenuAnchor(e.currentTarget);
                }}
            >
                <LocalPhoneIcon style={{ height: "20px", width: "20px", lineHeight: 0 }} />
            </div>

            <Menu
                open={menuAnchor !== null}
                onClose={() => setMenuAnchor(null)}
                anchorEl={menuAnchor}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: -45, horizontal: "left" }}
            >
                {phoneNumbers.map((pn) => {
                    return (
                        <MenuItem
                            key={pn.label}
                            onClick={() => handlePhoneClicked(pn.phoneNumber)}
                        >
                            {pn.label}
                        </MenuItem>
                    );
                })}
            </Menu>

            <Dialog open={loading}>
                <DialogContent>
                    <div style={{ width: "20rem", height: "20rem" }}>
                        <Loading
                            altText={`Placing Call to ${formatPhoneNumber(
                                callingPhoneNum.current ?? undefined
                            )}`}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
