
interface AreaColorButtonProps {
    areaNumber: number | null,
    sqft: number | null,
    isSelected: boolean,
    onClick?: () => void
}

export default function AreaColorButton({ areaNumber, sqft, isSelected, onClick }: AreaColorButtonProps) {

    return (
        <div className={`area-${areaNumber === null ? "none" : areaNumber % 5 + 1}`} style={{
            width: "8em",
            maxWidth: "8em",
            height: "2em",
            margin: ".5em .5em 0 .5em",
            display: "flex",
            alignItems: "center"
        }}>
            <div style={{ paddingRight: "7px", maxWidth: "4em", width:"4em", overflow: "hidden", whiteSpace: "nowrap", textAlign:"right" }}>
                {sqft !== null ? `${sqft.toFixed(0)} sf` : ""}
            </div>
            <div id="room-color"
                onClick={onClick}
                className="darken-on-hover"
                style={{
                    width: "4em",
                    height: "100%",
                    borderRadius: ".5em",
                    boxShadow: isSelected ? "0 0 0 5px var(--flat-red)" : "0 0 0 3px darkgray",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <div>
                    {
                        areaNumber === null ? "None" : `Area ${areaNumber + 1}`
                    }
                </div>
            </div>
        </div>
    )
}