import clsx from "clsx";
import { useGetAvailableTimeSlotsForSalespersonQuery, useGetAvailableTimeSlotsQuery } from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import AtomicTimeSlot from "./sched_utils/AtomicTimeSlot";
import ToggleChip from "./ToggleChip";

interface AvailableTimeSlotsProps {
    selectedSlotId: number | null,
    onSelectSlot: (newVal: number | null) => void,
    dayISO: string,
    zip: string,
    salespersonId?: number
    fullWidth?: boolean
}


export default function AvailableTimeSlots({ selectedSlotId, dayISO, zip, onSelectSlot, salespersonId, fullWidth=false }: AvailableTimeSlotsProps) {
    const { data: allData } = useGetAvailableTimeSlotsQuery({
        variables: { zip: zip, date: dayISO },
        skip:  !isNullOrUndefined(salespersonId),
        fetchPolicy: "no-cache"  // don't cache, in (the rare) case that slots become unavailable when clicking various days
    });

    const { data: spData } = useGetAvailableTimeSlotsForSalespersonQuery({
        variables: { zip: zip, date: dayISO, salespersonId: salespersonId! },
        skip: isNullOrUndefined(salespersonId),
        fetchPolicy: "no-cache"  // don't cache, in (the rare) case that slots become unavailable when clicking various days
    })

    const allTimeSlots = allData?.allTimeSlotsForZip ?? spData?.allTimeSlotsForZip ?? []
    const availableTimeSlots =  allData?.availableTimeSlots ?? spData?.availableTimeSlotsForSalesperson ?? []

    return (
        <div className={clsx("grid-50-50", "flex-gap-xsm", fullWidth && "fill-width")} style={{ minHeight: "9rem" }}>
            {
                allTimeSlots.map(ts => {

                    return (
                        <ToggleChip
                            key={`toggle-chip-${ts.id}`}
                            label={AtomicTimeSlot.fromMarketTimeSlot(ts).toString()}
                            thisVal={ts.id}
                            selectedVal={selectedSlotId}
                            onSelectTime={onSelectSlot}
                            selectable={availableTimeSlots.some(avail => avail.id === ts.id)} />
                    )
                })}
        </div>
    );
}