import { Typography } from "@material-ui/core";
import clsx from "clsx";

interface RatingSelectorProps {
    rating: number;
    setRating: (rating: number) => void;
}

export default function RatingSelector({ rating, setRating }: RatingSelectorProps) {
    return (
        <div
            className="rating-selector-component"
            style={{ width: "90vw" }}
        >
            {[...new Array(10)].map((v, i) => (
                <div
                    key={i}
                    style={{
                        flex: 1,
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                    }}
                    onClick={() => setRating(i + 1)}
                >
                    <div
                        className={clsx("rating-selector-item-component", {
                            "selected-rating-item": rating === i + 1,
                        })}
                        style={{ width: "8vw", height: "8vw" }}
                    >
                        <Typography>{i + 1}</Typography>
                    </div>
                </div>
            ))}
        </div>
    );
}
