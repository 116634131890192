import { humanize } from "@alduino/humanizer/string";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { InventoryEntry } from "generated/graphql";

interface Props {
    entry: InventoryEntry
}

interface KeyValue {
    key: string,
    value: string
}

export function ItemDetailsView({ entry }: Props) {

    function correctKey(key: keyof InventoryEntry): string {
        switch (key) {
            case 'newCount':
                return 'actualCount';
            default:
                return key;
        }
    }

    function convertToString(x: any) {
        if (typeof x === 'number') {
            if (x % 1 !== 0) {
                return x.toFixed(2).toString();
            }
            else {
                return x.toString();
            }
        }
        else if (typeof x === 'object') {
            return 'object';
        }
        else {
            return x.toString();
        }
    }

    const values = Object.entries(entry)
        .filter(([key, _]) => !['id', 'key', '__typename'].includes(key))
        .map<KeyValue>(([key, value]) => {
            return {
                key: correctKey(key as keyof InventoryEntry),
                value: convertToString(value)
            }
        });

    return (
        <>
            <h1>{entry.sku}:&nbsp;{entry.style}&nbsp;({entry.color})</h1>
            <Table>
                <TableBody>
                    {values.map(x => (
                        <TableRow id={x.key} key={`${entry.sku}-${x.key}`}>
                            <TableCell>
                                {humanize(x.key)}
                            </TableCell>
                            <TableCell>
                                {x.value}
                            </TableCell>
                        </TableRow>)
                    )}
                </TableBody>
            </Table>
        </>
    );
}