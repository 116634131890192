import { GetServiceProductOfferingIdsQueryVariables, useGetServiceProductOfferingIdsQuery } from "generated/graphql"
import React, { useState } from "react"
import { LaborRatesRow } from "./LaborRatesRow"

const renderRow = (value: number) => <LaborRatesRow key={`labor-rate-view-row-${value}`} laborRuleId={value} />

export default function LaborRatesTable({ filters, height }: { filters: GetServiceProductOfferingIdsQueryVariables, height: string }) {
    const { data } = useGetServiceProductOfferingIdsQuery({ variables: { ...filters }, fetchPolicy: 'network-only' })

    const indices = data?.serviceProductOfferingIds

    const [scrollHeight, setScrollHeight] = useState<number>(0);

    function handleScroll(e: React.UIEvent<HTMLDivElement, UIEvent>){
        if(e.currentTarget.scrollHeight !== scrollHeight) {
            setScrollHeight(e.currentTarget.scrollHeight)
            e.currentTarget.scrollTop = 0
        }
    }

    return (
        <div style={{height}} className="table-fixed-new" onScroll={handleScroll}>
            <table>
                <thead>
                    <tr style={{ backgroundColor: "lightgrey", height: "3rem" }}>
                        <td className="shrink"> </td>
                        <td align="center">Product</td>
                        <td align="center">Labor Type</td>
                        <td align="center">Description</td>
                        <td align="center">Unit</td>
                        <td align="left" className="shrink"><div style={{ width: "5rem", textAlign: "center" }}>Price</div></td>
                        <td align="left" className="shrink"><div style={{ width: "5rem", textAlign: "center" }} >Cost</div></td>
                        <td align="left" className="shrink"><div style={{ width: "10rem", textAlign: "center" }}>Minimum</div></td>
                        <td align="center">Margin</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        (indices ?? []).map(renderRow)
                    }
                </tbody>
            </table>
        </div>
    )
}