import { IconButton } from "@material-ui/core";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatChevronButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatChevronIcon color={color}/>
        </IconButton>
    )
}

export function FlatChevronIcon({color="black"} : {color?: string}) {
    return <DoubleArrowIcon htmlColor={color}/>
}