import { Button } from "@material-ui/core";
import clsx from "clsx";
import { UpdatedProductForAreaInput, useCopyJobConfigurationMutation, useTrimInvalidFieldsFromJobConfigurationMutation } from "generated/graphql";
import { buildAppendedId } from "Globals/Hooks";
import { cartPath } from "Globals/PathStrings";
import { useAppHistory } from "Globals/routingHooks";
import { QuoteCardChanges, QuoteCardState } from "./SellSheet";

interface GoToCartButtonProps {
    selectedQuote?: QuoteCardState;
    readyToProceed?: boolean;
}
export function GoToCartButton({ selectedQuote, readyToProceed }: GoToCartButtonProps) {
    const history = useAppHistory();

    const [trimInvalidFields, { loading: loadingTrim }] = useTrimInvalidFieldsFromJobConfigurationMutation({
        onCompleted(data) {
            history.push(cartPath + buildAppendedId(data.trimInvalidFieldsFromJobConfiguration));
        }
    });

    const [copyJob, { loading: loadingCreate }] = useCopyJobConfigurationMutation({
        onCompleted(data) {
            trimInvalidFields({ variables: { jobConfigurationId: data.copyJobConfiguration } });
        }
    });

    if (selectedQuote === undefined)
        return <></>;

    const loading = loadingTrim || loadingCreate;

    const { jobConfigurationId, isCopy, productChanges, optionNumber, selectedFinancingOptionId } = selectedQuote;

    function tryCheckout() {
        if (isCopy) {
            var updatedProducts: UpdatedProductForAreaInput[] = quoteCardChangesToUpdatedProductInput(productChanges)

            // Copies the job with the changes. When complete will call the complete function that trims fields 
            // and proceeds to the cart page
            copyJob({ variables: { jobConfigurationId, optionNumber, updatedProducts: updatedProducts, selectedFinancingOption: selectedFinancingOptionId } });
        }
        else {
            trimInvalidFields({ variables: { jobConfigurationId: jobConfigurationId } });
        }
    }

    return (
        <Button
            disabled={loading}
            className={clsx({ 'visibility-hidden': !readyToProceed })}
            variant="contained"
            color="secondary"
            onClick={tryCheckout}>
            Next
        </Button>
    );
}

export function quoteCardChangesToUpdatedProductInput(productChanges: QuoteCardChanges): UpdatedProductForAreaInput[] {
    return Object.keys(productChanges).map(areaId => {
        const { productTypeId, styleId } = productChanges[+areaId];

        return {
            areaId: +areaId,
            productTypeId: productTypeId ?? -1,
            styleId: styleId
        };
    }).filter(data => data.productTypeId !== -1 || data.areaId === -1);
}