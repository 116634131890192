import { IconButton } from "@material-ui/core";
import CheckIcon from '@mui/icons-material/Check';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatCheckButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatCheckIcon color={(iconButtonProps.disabled) ? "var(--flat-gray-4)" : color}/>
        </IconButton>
    )
}

export function FlatCheckIcon({color="black"} : {color?: string}) {
    return <CheckIcon htmlColor={color}/>
}