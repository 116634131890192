import React, { HTMLAttributes, PropsWithChildren } from "react";

interface HBarProps extends HTMLAttributes<HTMLDivElement> {
    thin?: boolean
}

export function HBar({ className, thin, ...props }: HBarProps) {
    className = `horizontal-bar ${className ?? ''}`;
    if (thin) return <div {...props} style={{ padding: '0 1rem', height: 1 }} className={className} />
    else return <div className={className} {...props} />
}

export function FormLabel({ children }: PropsWithChildren<{}>) {
    return (
        <div className='flex-row-center fill-width margin-xsm'>
            <h4>{children}</h4>
        </div>
    );
}
