import { IconButton, Typography } from "@material-ui/core"
import EditIcon from '@material-ui/icons/Edit'
import { GetJobInvoiceDataQuery } from "generated/graphql"
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators"
import { formatPhoneNumber, formatNameStringLastFirst } from "Globals/StringFormatting"
import InvoiceWOFLogo from "./InvoiceWOFLogo"

interface InvoiceDocumentHeaderProps extends InvoiceCustomerInformationTableProps {
    documentTitle: string;
    sahOrQuoteNumber?: string;
}

export default function InvoiceDocumentHeader({ documentTitle, sahOrQuoteNumber, ...infoTableProps }: InvoiceDocumentHeaderProps) {
    const sahSplit = sahOrQuoteNumber?.split('-');
    const sahSplitPrefix = sahSplit?.[0];
    let sahSplitNum = sahSplit?.[1];
    // present for quotes
    const variationLetter = ((sahSplit?.length ?? []) > 2) ? sahSplit?.[2] : undefined;
    if (variationLetter) sahSplitNum += `-${variationLetter}`; 

    return (
        <>
            <div className="margin-top-sm margin-bottom-xsm flex-row flex-space-between fill-width"
                style={{ alignItems: "center", breakBefore: "column" }} >
                <InvoiceWOFLogo />
                <Typography variant="h4">{documentTitle}</Typography>

                {isNotNullOrUndefined(sahOrQuoteNumber) && (
                    <Typography variant="h5" className="flex-row">
                        {sahSplitPrefix}-<div className='color-wof-red'>{sahSplitNum}</div>
                    </Typography>
                )}
            </div>
            <div className="margin-bottom-sm flex-row">
                <InvoiceCustomerInformationTable
                    {...infoTableProps}
                />
            </div>
        </>
    )
}

export type Customer = GetJobInvoiceDataQuery['jobConfigurationHeader']['customer']

export interface InvoiceCustomerInformationTableProps extends Customer {
    date?: Date,
    salesperson1?: { firstName?: string, lastName?: string },
    salesperson2?: { firstName?: string, lastName?: string },
    onEdit: () => void,
    renderExpanded: boolean
}

export function InvoiceCustomerInformationTable({
    businessName,
    firstName,
    lastName,
    date,
    primaryAddress,
    salesperson1,
    billingAddress,
    email,
    salesperson2,
    onEdit,
    renderExpanded,
    customerContacts
}: InvoiceCustomerInformationTableProps) {
    const isBusiness = !isNullOrUndefined(businessName)

    const contacts = customerContacts ?? []

    const phoneNumbers = [...contacts.filter(contact => contact.isPrimary && isNotNullOrUndefined(contact.phone) && contact.phone !== "") 
        .map(contact => contact.phone),
    ...contacts
        .filter(contact => !contact.isPrimary && isNotNullOrUndefined(contact.phone) && contact.phone !== "")
        .map(contact => contact.phone)
    ];

    const primaryPhone = phoneNumbers?.[0] ?? null
    const secondaryPhone = phoneNumbers?.[1] ?? null

    return (
        <table className="flex-grow invoice-document-header">
            <tbody>
                <tr hidden={renderExpanded}>
                    <td colSpan={5} className="gray-bkg">
                        Customer Information
                        <IconButton style={{ paddingLeft: ".25rem" }} size='small' onClick={onEdit}>
                            <EditIcon />
                        </IconButton>
                    </td>
                </tr>
                <tr>
                    <td hidden={!isBusiness}>Business: <span className="invoice-content-name">{businessName}</span></td>
                    <td colSpan={isBusiness ? 2 : 3}>{isBusiness ? "Contact" : "Name"}: <span className="invoice-content-name">{formatNameStringLastFirst({ firstName, lastName })}</span></td>
                    <td>Primary Phone: <span className="invoice-content-name">{primaryPhone !== null ? formatPhoneNumber(primaryPhone) : ""}</span></td>
                    <td>Date: <span className="invoice-content-name">{date?.toLocaleDateString() ?? (new Date()).toLocaleDateString()}</span></td>
                </tr>
                <tr>
                    <td>Address: <span className="invoice-content-name">{primaryAddress?.streetAddress ?? ""}</span></td>
                    <td>City: <span className="invoice-content-name">{primaryAddress?.city ?? ''}</span></td>
                    <td>Zip: <span className="invoice-content-name">{primaryAddress?.zip ?? ''}</span></td>
                    <td>Secondary Phone: <span className="invoice-content-name">{secondaryPhone !== null ? formatPhoneNumber(secondaryPhone) : ""}</span></td>
                    <td>Sales Person 1: <span className="invoice-content-name">{formatNameStringLastFirst({ ...salesperson1 })}</span></td>
                </tr>
                <tr>
                    <td>Billing Address: <span className="invoice-content-name">{billingAddress?.streetAddress ?? ''}</span></td>
                    <td>Billing City: <span className="invoice-content-name">{billingAddress?.city ?? ''}</span></td>
                    <td>Billing Zip: <span className="invoice-content-name">{billingAddress?.zip ?? ''}</span></td>
                    <td>Email Address: <span className="invoice-content-name">{email}</span></td>
                    <td>Sales Person 2: <span className="invoice-content-name">{formatNameStringLastFirst({ ...salesperson2 })}</span></td>
                </tr>
            </tbody>
        </table>
    )
}