import FlatCloseButton from "FlatComponents/Button/FlatCloseButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import { useGetS3FileQuery } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import "./pdf_viewer.css";

interface S3PDFViewerProps extends React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> {
    fileId: number;
}

/**
 * Loads and displays a PDF from Amazon S3
 * DO NOT rely on the default styling of this component.
 */
export function S3PdfViewer({
    fileId,
    ...iframeProps
}: S3PDFViewerProps) {
    const {data: s3PdfData} = useGetS3FileQuery({
        variables: {fileId: fileId},
        skip: fileId < 1
    });
    const src = s3PdfData?.s3File.signedUrl; 
    const fileName = s3PdfData?.s3File.fileName; 

    return (
        <iframe
            {...iframeProps}
            src={src ?? undefined}
            title={fileName}
        />
    )
}


// omit the height/width/style because the dialog should use the default defaultPdfViewerStyle
interface S3PdfViewerDialogProps extends Omit<S3PDFViewerProps, "height" | "width" | "style"> {
    onClose: () => void;
    onDelete?: () => void;
    disableDeleteButton?: boolean;  // has no effect if onDelete is not passed
}

export function S3PdfViewerDialog({
    fileId,
    onClose,
    onDelete,
    disableDeleteButton=false
}: S3PdfViewerDialogProps) {
    const closeButton = <FlatCloseButton id="close-pdf-button" onClick={onClose}/>

    const deleteButton = isNotNullOrUndefined(onDelete) ?
        <FlatDeleteButton
            id="delete-pdf-button"
            onClick={onDelete}
            disabled={disableDeleteButton}
        />
        : <></>;

    return (<>
        <S3PdfViewer
            fileId={fileId}
            id="pdf-viewer-dialog"
        />

        {closeButton}

        {deleteButton}
    </>)
}