import { IconButton } from "@material-ui/core";
import HistoryIcon from '@mui/icons-material/History';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatHistoryButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatHistoryIcon color={color}/>
        </IconButton>
    )
}

export function FlatHistoryIcon({color="black"} : {color?: string}) {
    return <HistoryIcon htmlColor={color}/>
}