import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { isEmptyString, isNotEmptyString, isNotNullOrUndefined, isNumber } from "Globals/GenericValidators";
import { ZipAndCityMap, zipIsServiced } from "Pages/Admin/SAHScheduling/SchedulingDateTime";
import { useState } from "react";
import { selectCity, selectZip, setCity, setZip } from "Redux/callCenterSchedulingReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";

export default function LocationSelectorRow({ zipsAndCities }: { zipsAndCities: ZipAndCityMap }) {
    const [selectableCities, setSelectableCities] = useState<ReactJSXElement[]>([]);
    function onChangeZip(newZip: string) {
        if ((newZip?.length ?? 0) <= 5 && (isNumber(newZip) || isEmptyString(newZip))) {
            dispatch(setZip(newZip));
            if (newZip && zipIsServiced(zipsAndCities, newZip)) {
                let newCities = zipsAndCities[newZip].map((city) => (
                    <MenuItem
                        value={city}
                        key={city}
                    >
                        {city}
                    </MenuItem>
                ));
                setSelectableCities(newCities);
                dispatch(setCity(zipsAndCities[newZip][0]));
            } else {
                setSelectableCities([]);
                dispatch(setCity(""));
            }
        }
    }

    const dispatch = useAppDispatch();
    const zip = useAppSelector(selectZip);
    const city = useAppSelector(selectCity);

    const state = isNotNullOrUndefined(zip) && isNotEmptyString(city) ? "Michigan" : "";
    return (
        <div className="flex-row flex-space-between flex-gap-lg">
            <TextField
                required
                label="Zip Code"
                className="w-13r"
                value={zip}
                onChange={(e) => onChangeZip(e.target.value)}
            />
            {selectableCities.length > 1 ? (
                <FormControl>
                    <InputLabel required>City</InputLabel>
                    <Select
                        className="w-13r align-self-flex-end"
                        value={city}
                        onChange={(e) => dispatch(setCity(e.target.value as string))}
                        required
                        label="City"
                    >
                        {selectableCities}
                    </Select>
                </FormControl>
            ) : (
                <TextField
                    disabled
                    required
                    label="City"
                    className="w-13r"
                    value={city}
                    inputProps={{ readOnly: true }}
                />
            )}
            <TextField
                disabled
                required
                label="State"
                className="w-13r"
                value={state}
            />
        </div>
    );
}