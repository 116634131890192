import {
    namedOperations,
    useDeleteBeforeAndAfterPictureForRoomMutation,
    useGetJobConfigurationQuery,
} from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { RoomPhotoGallery } from "../Generics/RoomPhotoGallery";
import { RoomGalleryImageBaseProps } from "../JobPictureViewer";

export function AfterPictureGallery({
    jobConfigurationId,
    mobile,
    preventDelete,
}: {
    jobConfigurationId: number;
    mobile?: boolean;
    preventDelete?: boolean;
}) {
    const [deleteBeforeAndAfterPicture] = useDeleteBeforeAndAfterPictureForRoomMutation({
        refetchQueries: [namedOperations.Query.GetJobConfiguration],
        awaitRefetchQueries: true,
    });
    function deleteImage(beforeAndAfterPictureForRoomId: number) {
        deleteBeforeAndAfterPicture({ variables: { beforeAndAfterPictureForRoomId } });
    }

    const { data } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        skip: isNullOrUndefined(jobConfigurationId) && jobConfigurationId! <= 0,
    });

    let afterPictures: RoomGalleryImageBaseProps[] =
        data?.jobConfiguration.areas.flatMap((a) =>
            a.rooms.flatMap((r) =>
                r.afterPictures.map((picture) => ({
                    label: getNameOfArea(r.labels),
                    description: picture.picture?.description ?? "",
                    pictureId: picture.picture?.id ?? -1,
                    pictureForRoomId: picture.id,
                }))
            )
        ) ?? [];

    return (
        <RoomPhotoGallery
            pictures={afterPictures}
            deletePicture={preventDelete ? undefined : deleteImage}
            mobile={mobile}
        />
    );
}
