import NumberFormat from "react-number-format";
import { useAppSelector } from "Redux/hooks";
import {
    selectFinancingOption, selectJobCost,
    selectPreOverridePrice
} from "Redux/pricingCenterReducer";
import { calculateFinancingAdjustedRevenue, calculateMargin } from "./marginUtils";

export default function PreOverridePriceSection() {
    const preOverridePrice = useAppSelector(selectPreOverridePrice);
    const jobCost = useAppSelector(selectJobCost);
    const financingOption = useAppSelector(selectFinancingOption);

    const revenue = calculateFinancingAdjustedRevenue(preOverridePrice, financingOption?.fee ?? 0);

    return (
        <>
            {preOverridePrice > 0 && (
                <>
                    <div style={{ fontWeight: "bold" }}>Total</div>

                    <div className="highlight-flat-text">
                        <NumberFormat
                            prefix="$"
                            thousandSeparator={true}
                            displayType="text"
                            value={preOverridePrice}
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </div>

                    <div
                        className="flat-font-upper"
                        style={{ color: "var(--flat-gray-4)" }}
                    >
                        Margin
                    </div>

                    {jobCost && (
                        <div>
                            <NumberFormat
                                suffix="%"
                                thousandSeparator={true}
                                displayType="text"
                                value={revenue === 0 ? 0 : calculateMargin(revenue, jobCost)}
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
}
