import { useGetProductStyleQuery } from "generated/graphql";
import { selectBulkCost, selectStyleId, setBulkCost } from "Redux/addProductReducer";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { BulkCostEditor } from "./CostEditors";

export default function ProductBulkCostEditor() {
    const singleCost = useAppSelector(selectBulkCost)
    const styleId = useAppSelector(selectStyleId)
    const dispatch = useAppDispatch();

    const { loading } = useGetProductStyleQuery({
        variables: { styleId },
        skip: styleId < 1,
        onCompleted(data) {
            updateCost(data.productStyle.bulkCostPerSqft.toString())
        }
    })

    function updateCost(newValue: string) {
        dispatch(setBulkCost(newValue))
    }

    return (
        <div className="flex-column">
            <label>Pallet Cost:</label>
            <BulkCostEditor cost={singleCost} setCost={updateCost} readOnly={loading} />
        </div>
    )
}