import { selectColor } from "Redux/addProductReducer"
import { useAppSelector } from "Redux/hooks"
import ExistingColorSpecEditor from "./ExistingColorSpecEditor"
import NewColorSpecEditor from "./NewColorSpecEditor"


export default function VariantSpecEditor() {
    const { id: selectedColorId, value: manualColorName } = useAppSelector(selectColor)

    if (selectedColorId === -1) return <></>
    else if(selectedColorId === -2) return <NewColorSpecEditor colorName={manualColorName}/>
    else return <ExistingColorSpecEditor colorId={selectedColorId}/>
}