import { createContext, PropsWithChildren, useReducer } from "react";

interface GrowFormContextProps {
    state: GrowFormState,
    dispatch: React.Dispatch<GrowFormAction>
}

export const GrowFormContext = createContext<GrowFormContextProps | undefined>(undefined);

interface GrowFormProps {
    filter: string[]
}

interface GrowFormState {
    currentIndex: number
    idToIndex: { [id: string]: number }
    renderMap: { [id: string]: boolean }
}

const growFormActionTypes = ['ReportAssigned'] as const;
type GrowFormActionType = typeof growFormActionTypes[number]

interface GrowFormAction {
    type: GrowFormActionType,
    payload: any
}

interface ReportAssignedAction extends GrowFormAction {
    type: 'ReportAssigned'
    payload: { id: string }
}

function isReportAssignedAction(action: GrowFormAction): action is ReportAssignedAction {
    return action.type === 'ReportAssigned';
}

function generateRenderMap(idToIndex: {[key: string]: number}, newCurrentIndex: number): { [key: string]: boolean } {
    return Object.entries(idToIndex).reduce((previous, [key, index]) => ({ ...previous, [key]: index <= newCurrentIndex }), {});
}

function reducer(state: GrowFormState, action: GrowFormAction) {
    if (isReportAssignedAction(action)) {
        const id = action.payload.id;
        const currentIndex = state.currentIndex;
        const elementIndex = state.idToIndex[id];
        const newIndex = elementIndex + 1;
        if (newIndex > currentIndex) {

            return {
                ...state,
                currentIndex: newIndex,
                renderMap: generateRenderMap(state.idToIndex, newIndex)
            };
        }
        else {
            return state;
        }
    }
    else{
        return state;
    }
}

export default function GrowForm({ filter, children }: PropsWithChildren<GrowFormProps>) {

    const idToIndex: { [key: string]: number } = filter.reduce((previous, current, index) => ({ ...previous, [current]: index }), {});
    const [state, dispatch] = useReducer(reducer, {currentIndex: 0, idToIndex: idToIndex, renderMap: generateRenderMap(idToIndex, 0)})

    return (
        <>
            <GrowFormContext.Provider value={{state, dispatch}}>
                {children}
            </GrowFormContext.Provider>
        </>
    )
}

interface GrowFormSectionProps {
    idOfFirstElement: string
}

export function GrowFormSection({ children, idOfFirstElement }: PropsWithChildren<GrowFormSectionProps>) {
    return (
        <GrowFormContext.Consumer>
            {
                (value) => {
                    if (value?.state.renderMap[idOfFirstElement]) {
                        return children
                    }
                    else {
                        return null;
                    }
                }
            }
        </GrowFormContext.Consumer>
    )
}

interface GrowFormElementProps {
    valueToWatch?: any
    id: string
}

export function GrowFormElement(props: PropsWithChildren<GrowFormElementProps>) {
    return (
        <GrowFormContext.Consumer>
            {
                (value) => <GrowFormElementContent {...{ ...props, context: value }} />
            }
        </GrowFormContext.Consumer>
    )
}

interface GrowFormElementContentProps extends GrowFormElementProps {
    context?: GrowFormContextProps
}

function GrowFormElementContent({ context, valueToWatch, id, children }: PropsWithChildren<GrowFormElementContentProps>) {
    // useEffect(
    //     () => {
    //         if (valueToWatch !== undefined && valueToWatch !== null) {
    //             context?.dispatch({type: 'ReportAssigned', payload: { id }})
    //         }
    //     },
    //     [valueToWatch]);

    if (context?.state.renderMap[id]) {
        return <>{children}</>;
    }
    else {
        return null;
    }
}