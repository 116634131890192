import { Button, Container, Drawer } from "@material-ui/core";
import NavbarPage from "Components/Page";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectCostDrawerOpen, selectCostPageIsSoft, setCostsDrawerOpen } from "Redux/productCostsReducer";
import { store } from "Redux/store";
import CarpetMode from "./CarpetMode/CarpetCostsMode";
import HardMode from "./HardMode/HardCostsMode";


export default function ProductCostsPage() {

    const isSoft = useAppSelector(selectCostPageIsSoft)
    const drawerOpen = useAppSelector(selectCostDrawerOpen)
    const dispatch = useAppDispatch()

    const height = `calc(100vh - ${(drawerOpen ? "35rem" : "15rem")}`

    return (
        <NavbarPage title="Product Costs" style={{padding:"0"}}>
            <Container className="fill-width flex-row-center" maxWidth="lg">
                {isSoft && <CarpetMode height={height} />}
                {!isSoft && <HardMode height={height} />}
            </Container>
            <Drawer
                variant="persistent"
                open={drawerOpen}
                anchor='bottom'>
                <Container className='flex-column fill-width' maxWidth='lg' style={{ height: "19rem", margin: ".5rem auto" }}>
                    <div className="flex-row" style={{ justifyContent: "flex-end" }}>
                        <Button variant="contained" onClick={() => dispatch(setCostsDrawerOpen(false))}>
                            Discard
                        </Button>
                    </div>
                </Container>
            </Drawer>
        </NavbarPage>
    )
}

export function promptUserIfChanges() {
    if (areThereChangesOnCostPage()) {
        //Changes found, ask user if they are sure they want to lose changes
        return window.confirm("Changes will be discarded? Click OK to remove changes and proceed")
    }
    //No changes, proceed with action
    else return true
}

function areThereChangesOnCostPage() {
    const pc = store.getState().productCosts
    if (pc.drawerOpen) return true
    if (Object.keys(pc.changedSingles).find(
        key => pc.changedSingles[key].changed
    )) return true
    if (Object.keys(pc.changedBulks).find(
        key => pc.changedBulks[key].changed
    )) return true

    return false
}
