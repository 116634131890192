import clsx from "clsx";
import NumberFormat, { NumberFormatPropsBase } from "react-number-format";


function selectedUnitFormatter(unit: string, totalPrice: string) {
    switch (unit) {
        case "Unselected":
        case "":
            return "";
        case "each":
            return ` un.${totalPrice}`;
        default:
            return ` ${unit}.${totalPrice}`
    }
}

export interface MinimumNumberFormatProps extends Pick<NumberFormatPropsBase<undefined>, "onValueChange"> {
    minimum: string,
    price: string,
    unit: string,
    highlightErrors?: boolean,
    hidden?: boolean,
    autoFocus?: boolean
}

export function MinimumAmountFormat({ minimum, price, unit, highlightErrors, onValueChange, hidden, autoFocus }: MinimumNumberFormatProps) {
    const numericMinimum = +minimum
    const numericPrice = +price

    const isMinimumInvalid = isNaN(numericMinimum) || minimum === ""
    const isPriceInvalid = isNaN(numericPrice) || price === ""

    const displayTotal = (isPriceInvalid || isMinimumInvalid) ? "" : ` ($${(numericPrice * numericMinimum).toFixed(2)})`

    const displaySuffix = selectedUnitFormatter(unit, displayTotal)
    const allowedDecimals = (unit === "each") ? 0 : 2

    const isError = highlightErrors && isMinimumInvalid

    return (
        <NumberFormat
            hidden={hidden}
            style={{ minWidth: 0, width: "10rem" }}
            className={clsx({
                "error-number-format": isError,
                "visibility-hidden": unit === "Unselected"
            })}
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={allowedDecimals}
            value={minimum}
            thousandSeparator={true}
            inputMode="numeric"
            suffix={displaySuffix}
            onValueChange={onValueChange} 
            autoFocus={autoFocus}/>
    )
}
