import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Chip } from "@material-ui/core";
import { ReactComponent as RecoveryIcon } from "Assets/Svgs/recovery_icon.svg";
import S3QuotePdfDialogWrapper from "Components/Files/S3QuotePdfDialogWrapper";
import ProjectCard from "Components/ProjectDashboards/ProjectCard";
import { CountUpTextClock } from "Components/ProjectDashboards/TimeClock";
import { GetAllJobsInRecoveryQuery } from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { recoveryCenterPricingPath } from "Globals/PathStrings";
import { useState } from "react";
import { useHistory } from "react-router";
import "./recovery-center-components.css";

export interface BaseRecoveryCenterCardProps {
    job: GetAllJobsInRecoveryQuery['allJobsInRecovery'][number]
    allowCustomerClick?: boolean
}

type RecoveryCenterCardProps = React.PropsWithChildren<BaseRecoveryCenterCardProps>

export default function RecoveryCenterCard({ children, job, allowCustomerClick }: RecoveryCenterCardProps) {
    const history = useHistory();

    // used to control whether the quote viewer dialog is open - can only be true when the job is a quote
    const [quoteViewerOpen, setQuoteViewerOpen] = useState(false);

    const agentName: string | undefined = isNullOrUndefined(job?.recoveryWorker) ? undefined : `${job?.recoveryWorker?.firstName} ${job?.recoveryWorker?.lastName}`;
    const cardNumber: string = (job.isContract ? job?.contractNumber : job.isQuote ? job?.quoteNumber : job?.appointmentNumber) ?? ""
    
    let chipLabel: string;
    let onClickChip: (() => void) | undefined = undefined;
    if (job.isContract) {
        chipLabel = "3-day";
    } else {
        if (job.isQuote) {
            chipLabel = "Quote";
            // onClickChip = () => window.open(`${quoteViewerPath}/${job.quoteId!}`)
            onClickChip = () => setQuoteViewerOpen(true);
        } else {
            chipLabel = "No Quote"
        }
    }
    const statusChip: ReactJSXElement = (
        <Chip
            label={chipLabel}
            size="small"
            onClick={onClickChip}
        />
    )

    function handleCustomerClicked() {
        history.push(`${recoveryCenterPricingPath}/${job.activeJobConfigurationId}`)
    }

    return (<>
        <ProjectCard
            agentName={agentName}
            agentIcon={<RecoveryIcon style={{ maxWidth: "1.5rem", maxHeight: "1.5rem" }} />}
            chips={[statusChip]}
            cardNumber={cardNumber}
            customerName={`${job?.customerFirstName} ${job?.customerLastName}`}
            customerCity={job?.customerCity}
            timeSlot={<CountUpTextClock creationTime={job!.dateEnteredRecovery} />}
            children={children}
            onCustomerClick={allowCustomerClick ? handleCustomerClicked : undefined}          
        />

        {(quoteViewerOpen && job.quoteId) && (
            <S3QuotePdfDialogWrapper quoteId={job.quoteId} onClose={() => setQuoteViewerOpen(false)}/>
        )}
    </>)
}