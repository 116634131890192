import { Calendar, DayRange, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField, Typography } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { BasePromotion, Market, namedOperations, useAddScheduledPromotionMutation, useCheckForPromotionConflictsLazyQuery, useGetAllMarketsQuery } from "generated/graphql";
import { dayToIso, dayToMdy, emptyDayRange, handleCalendarDayRangeSelectionChange } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { toggleValueInList } from "Globals/Helpers";
import { useEffect, useMemo, useState } from "react";

interface PromoSchedulingDialogProps {
    basePromo: BasePromotion;
    onClose: () => void;
}

export default function PromoSchedulingDialog({basePromo, onClose}: PromoSchedulingDialogProps) {
    const [selectedDateRange, setSelectedDateRange] = useState<DayRange>(emptyDayRange);
    const startDateStr = isNullOrUndefined(selectedDateRange.from) ? "" : dayToMdy(selectedDateRange.from!); 
    const endDateStr = isNullOrUndefined(selectedDateRange.to) ? "" : dayToMdy(selectedDateRange.to!);

    const {data: marketData} = useGetAllMarketsQuery({onError: () => alert("Could not load market data")});
    const allMarkets = useMemo(() => marketData?.allMarkets ?? [], [marketData]);
    const [selectedMarketIds, setSelectedMarketIds] = useState<number[]>(allMarkets.map(mkt => mkt.id));

    
    const [addPromo, {loading: adding}] = useAddScheduledPromotionMutation({
        onCompleted: () => { alert("Promotion scheduled successfully!"); onClose()},
        onError: () => alert("Could not schedule promotion"),
        refetchQueries: [namedOperations.Query.GetAllScheduledPromotions]
    });
    
    const [conflictingMarketNames, setConflictingMarketNames] = useState<string[]>([]);
    const [checkForConflicts, {loading: checking}] = useCheckForPromotionConflictsLazyQuery({
        onError: () => alert("Failed attempt to check for promotion conflicts. Please refresh the page and try again.")
    });
    // check for promotion conflicts when markets/dates change
    useEffect(() => {
        if (isNotNullOrUndefined(selectedDateRange.from) && isNotNullOrUndefined(selectedDateRange.to) && selectedMarketIds.length > 0) {
            checkForConflicts({
                variables: {
                    startDate: dayToIso(selectedDateRange.from!),
                    endDate: dayToIso(selectedDateRange.to!),
                    marketIds: selectedMarketIds
                },
            }).then((data) => {
                const conflictingMarketIds = data.data?.checkForPromotionConflicts ?? [];
                const names = allMarkets.filter(mkt => conflictingMarketIds.includes(mkt.id)).map(mkt => mkt.name);
                setConflictingMarketNames(names); 
            });
        } else {
            setConflictingMarketNames([])
        }
    }, [checkForConflicts, selectedDateRange.from, selectedDateRange.to, selectedMarketIds, allMarkets]);
    
    function canSubmit() {
        if (selectedMarketIds.length === 0) {
            alert("Select at least 1 market for the promotion");
            return false;
        }

        if (isNullOrUndefined(selectedDateRange.from) || isNullOrUndefined(selectedDateRange.to)) {
            alert("Select a start and end date");
            return false;
        }

        if (conflictingMarketNames.length > 0) {
            alert("The promotion you are trying to schedule conflicts with existing promotions. Resolve these conflicts to submit.");
            return false;
        }

        return true;
    }

    function onSubmit() {
        if (canSubmit()) {
            addPromo({
                variables: {
                    basePromotionId: basePromo.id,
                    startDate: dayToIso(selectedDateRange.from!),
                    endDate: dayToIso(selectedDateRange.to!),
                    marketIds: selectedMarketIds
                }
            });
        }
    }

    return (
        <Dialog open={true}>
            <DialogTitle>Schedule {basePromo.name}</DialogTitle>

            <DialogContent>
                <div className="flex-column margin-bottom-sm align-items-center">
                    <div className="margin-left-md">
                        <Calendar
                            value={selectedDateRange}
                            onChange={newRange => setSelectedDateRange(handleCalendarDayRangeSelectionChange(selectedDateRange, newRange))}
                            minimumDate={utils('en').getToday()}
                            colorPrimary="var(--wof-red)"
                            colorPrimaryLight="#f79cab"
                            shouldHighlightWeekends
                        />
                    </div>

                    <div className="flex-row justify-content-center flex-gap-sm">
                        <TextField value={startDateStr} label="Start Date" disabled/>
                        <TextField value={endDateStr} label="End Date" disabled/>
                    </div>
                </div>

                <MarketSelectionSection allMarkets={allMarkets} selectedMarketIds={selectedMarketIds} setSelectedMarketIds={setSelectedMarketIds} />

                {conflictingMarketNames.length > 0 && (
                    <Typography className="error-text">Conflict detected for the selected date range with the following markets: {conflictingMarketNames.join(', ')}</Typography>
                )}
            </DialogContent>
            
            <DialogActions>
                <SpacedButton className="cancel-button" onClick={onClose} disabled={adding}>Cancel</SpacedButton>
                <SpacedButton variant="contained" color="secondary" onClick={onSubmit} disabled={adding || checking || conflictingMarketNames.length > 0}>Submit</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}

interface MarketSelectionSectionProps {
    allMarkets: Market[];
    selectedMarketIds: number[];
    setSelectedMarketIds: (newIds: number[]) => void;
}

export function MarketSelectionSection({allMarkets, selectedMarketIds, setSelectedMarketIds}: MarketSelectionSectionProps) {
    const toggleMarketSelection = (marketId: number) => setSelectedMarketIds(toggleValueInList(selectedMarketIds, marketId));
    
    function clearSelection() {
        setSelectedMarketIds([]);
        setShowMarketCheckboxes(true);
    }
    
    function invertSelection() {
        setSelectedMarketIds(allMarkets.map(mkt => mkt.id).filter(mktId => !selectedMarketIds.includes(mktId)));
        setShowMarketCheckboxes(true);
    }
    
    function selectAll() {
        setSelectedMarketIds(allMarkets.map(mkt => mkt.id));
        setShowMarketCheckboxes(false);
    }
    
    const allAreSelected = allMarkets.length === selectedMarketIds.length;
    const [showMarketCheckboxes, setShowMarketCheckboxes] = useState(!allAreSelected);
    
    return (
        <div className="flex-column solid-border padding-xsm border-radius-5px">
            <div className="flew-row">
                <MarketCheckbox marketName="All" isSelected={allAreSelected}/>

                {showMarketCheckboxes ? (
                    <IconButton onClick={() => setShowMarketCheckboxes(false || !allAreSelected)} disabled={!allAreSelected}><ArrowDropDownIcon /></IconButton>
                ) : (       
                    <IconButton onClick={() => setShowMarketCheckboxes(true)}><ArrowDropUpIcon /></IconButton>
                )}

            </div>
            
            {showMarketCheckboxes && (<>
                <div className="horizontal-bar" />

                <div className="grid-50-50">
                    {allMarkets.map(mkt => 
                        <MarketCheckbox
                            marketName={mkt.name}
                            isSelected={selectedMarketIds.includes(mkt.id)}
                            toggleSelection={() => toggleMarketSelection(mkt.id)}
                        />
                    )}
                </div>
            </>)}

            <div className="flex-row margin-top-xsm">
                <SpacedButton variant="contained" onClick={selectAll}>Select All</SpacedButton>
                <SpacedButton variant="contained" onClick={clearSelection}>Clear Selection</SpacedButton>
                <SpacedButton variant="contained" onClick={invertSelection}>Invert Selection</SpacedButton>
            </div>
        </div>
    )
}


interface MarketCheckboxProps {
    marketName: string;
    isSelected: boolean;
    toggleSelection?: () => void;  // if not passed, the checkbox will be disabled

}

function MarketCheckbox({marketName, isSelected, toggleSelection}: MarketCheckboxProps) {
    function toggle() {
        if (isNotNullOrUndefined(toggleSelection)) {
            toggleSelection!();
        }
    }
    
    return (
        <FormControlLabel
            label={marketName}
            control={<Checkbox checked={isSelected} onChange={toggle} disabled={isNullOrUndefined(toggleSelection)}/>}
        />   
    )
}