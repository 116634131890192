import { useGetTestSvgQuery } from "generated/graphql";
import { getSvgSize } from "Pages/Admin/ProjectManagement/SellSheet/QuoteDetailsEditor/RoomSvg";

export default function SvgTest() {
    const { data } = useGetTestSvgQuery();
    const svg = colorRoomsForDrawingSvg(tempRepairSvg(data?.testSvg ?? ""), [
        {roomId: 1, areaIndex: 1},
        {roomId: 2, areaIndex: 3},
        {roomId: 3, areaIndex: 2},
        {roomId: 4, areaIndex: 2},
    ], false)
     
    const { width, height } = getSvgSize(svg);

    const sizeScale = 1;
    return (
        <div
            style={{
                width: width * sizeScale,
                height: height * sizeScale,
                minWidth: "1em",
                minHeight: "1em",
                margin: ".5em",
                borderRadius: ".5em",
                padding: "1em 2em",
                position: "relative",
                boxSizing: "content-box",
                MozBoxSizing: "content-box",
                WebkitBoxSizing: "content-box",
                border: "2px solid lightgray",
                overflow: "hidden",
            }}
        >
            <div dangerouslySetInnerHTML={{ __html: svg }} />
        </div>
    );
}

function tempRepairSvg(svg: string): string {
    return svg
        .replace(/room--1"/gi, 'room-1"')
        .replace(/room--2"/gi, 'room-2"')
        .replace(/room--3"/gi, 'room-3"')
        .replace(/room--4"/gi, 'room-4"');
}

export function colorRoomsForDrawingSvg(svg: string, roomMap: {roomId: number, areaIndex?: number}[], noColor: boolean): string {
    roomMap.forEach(v=> {
        const {roomId, areaIndex} = v

        const replace =`room-${roomId}"`
        const replaceRegex = new RegExp(replace, "ig") 

        const newArea = noColor === true ? "no-color" : (areaIndex ?? "none")

        svg = svg.replace(replaceRegex, `room-${roomId}" class="area-${newArea}"`)
    })

    return svg;
}