import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import carpetImg from 'Assets/Images/carpet_img.jpg';
import laminateImg from 'Assets/Images/laminate_img.jpg';
import vinylTileImg from 'Assets/Images/vinyl_tile_img.jpg';
import woodImg from 'Assets/Images/wood_img.jpg';
import { CARPET_PRODUCT_ID, LAMINATE_PRODUCT_ID, SPC_PRODUCT_ID, WOOD_PRODUCT_ID } from "Globals/globalConstants";
import _ from "lodash";
import ProductSelectionCard from "./Components/ProductSelectionCard";
import { FormValues, SchedulingPageProps } from "./NewSAHAppointmentPage";

// just makes sure at least one of the product types is selected
export function canMoveToContactInfo(formValues: FormValues) {
    return formValues.productIds.length > 0
}

export default function SchedulingProductTypeSelector({ formValues, handleValueChange }: SchedulingPageProps) {
    function toggleUpdateProduct(id: number) {
        if(id === -1) return;

        if (formValues.productIds.some(pid => pid === id)) {
            handleValueChange('productIds', formValues.productIds.filter(pid => pid !== id))
        }
        else {
            handleValueChange('productIds', [...formValues.productIds, id])
        }
    }

    function isSelected(id: number) {
        return formValues.productIds.some(pid => pid === id)
    }

    return (
        <>
            <div>
                <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                    <Grid item >
                        <ProductSelectionCard
                            img={carpetImg}
                            alt="Carpet"
                            handleSelection={() => toggleUpdateProduct(CARPET_PRODUCT_ID)}
                            selectionControl={isSelected(CARPET_PRODUCT_ID)}
                        />
                    </Grid>

                    <Grid item>
                        <ProductSelectionCard
                            img={vinylTileImg}
                            alt="LVP/LVT"
                            handleSelection={() => toggleUpdateProduct(SPC_PRODUCT_ID)}
                            selectionControl={isSelected(SPC_PRODUCT_ID)}
                        />
                    </Grid>

                    <Grid item>
                        <ProductSelectionCard
                            img={woodImg}
                            alt="Wood"
                            handleSelection={() => toggleUpdateProduct(WOOD_PRODUCT_ID)}
                            selectionControl={isSelected(WOOD_PRODUCT_ID)}
                        />
                    </Grid>

                    <Grid item>
                        <ProductSelectionCard
                            img={laminateImg}
                            alt="Laminate"
                            handleSelection={() => toggleUpdateProduct(LAMINATE_PRODUCT_ID)}
                            selectionControl={isSelected(LAMINATE_PRODUCT_ID)}
                        />
                    </Grid>
                </Grid>
            </div>

            <div className="solid-border rounded-border padding-xsm margin-top-sm">
                <FormControl className="margin-sm w-13r" style={{ whiteSpace: "nowrap" }}>
                    <InputLabel>Number of rooms</InputLabel>
                    <Select
                        value={formValues.numRooms}
                        onChange={(e) => handleValueChange("numRooms", e.target.value as number)}
                    >
                        {
                            _.range(1, 10).map(num => <MenuItem key={`room-count-${num}`} value={num}>{num}</MenuItem>)
                        }
                        <MenuItem value={0}>Whole {formValues.isForHome ? 'House' : 'Building'}</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </>
    )
}

