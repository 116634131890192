import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { ClaimPriceRequestButton } from "FlatComponents/Chat/ActionComponents/PriceRequestResponseBox";
import FlatButton from "FlatComponents/Button/FlatButton";
import { useGetAllSalespeopleForJobQuery, useGetGeneralCommandCenterWorkerIdQuery, useRecordPriceRequestMutation } from "generated/graphql";
import { isEmptyString, isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useAppSelector } from "Redux/hooks";
import { selectCcWorkerId, selectChatId, selectCommissionRates, selectContractConfigurationId, selectFinancingOption, selectJobConfigurationId, selectJobCost, selectPreOverridePrice, selectPriceRequest, selectQuoteConfigToQuoteIdMap, selectUsageContext } from "Redux/pricingCenterReducer";
import MarginDisplay from "./MarginDisplay";
import { calculateFinancingAdjustedRevenue, calculateMargin } from "./marginUtils";
import { getCommissionRate } from "./PricingSummary";

export default function PriceRequestSection() {
    const chatId = useAppSelector(selectChatId);
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);

    const [calledInRequestAmount, setCalledInRequestAmount] = useState<string>('');
    // clear the called in amount when changing tabs
    useEffect(() => setCalledInRequestAmount(''), [jobConfigurationId]);
    const [requestingSalespersonId, setRequestingSalespersonId] = useState<number>(-1);

    const overrideRequest = useAppSelector(selectPriceRequest); 
    const preOverridePrice = useAppSelector(selectPreOverridePrice);
    const commissionRates = useAppSelector(selectCommissionRates);
    const jobCost = useAppSelector(selectJobCost);
    const financingOption = useAppSelector(selectFinancingOption);
    const usageContext = useAppSelector(selectUsageContext);
    const contractConfigId = useAppSelector(selectContractConfigurationId);
    const quotedConfigIds = Object.keys(useAppSelector(selectQuoteConfigToQuoteIdMap)).map(id => +id);
    const disableInteraction = usageContext === "readonly" || isNotNullOrUndefined(contractConfigId) || quotedConfigIds.includes(jobConfigurationId);
    const financingAdjustedRevenue = overrideRequest ? calculateFinancingAdjustedRevenue(overrideRequest.amount, financingOption?.fee ?? 0) : undefined;
    const commissionRate = financingAdjustedRevenue ? getCommissionRate(commissionRates, calculateMargin(financingAdjustedRevenue, jobCost) / 100) : undefined;
    
    const { data: generalCcWorkerData } = useGetGeneralCommandCenterWorkerIdQuery();
    const generalCcWorkerId = generalCcWorkerData?.generalCommandCenterWorkerId;
    const ccWorkerId = useAppSelector(selectCcWorkerId);

    const {data: salespeopleData} = useGetAllSalespeopleForJobQuery({
        skip: jobConfigurationId < 1,
        variables: {
            jobConfigurationId: jobConfigurationId
        },
        onCompleted: (data) => setRequestingSalespersonId(data.allSalespeopleForJob[0]?.id ?? -1)
    });
    const salespeople = salespeopleData?.allSalespeopleForJob;

    const [recordPriceRequest] = useRecordPriceRequestMutation({
        onError: () => alert("Could not record price request")
    });

    function canRecordQuoteRequest() {
        if (overrideRequest) {
            alert("A request has already been made");
            return false;
        }

        if (isNullOrUndefined(preOverridePrice) || jobConfigurationId < 1) {
            alert("Could not record price request");
            return false;
        }
        
        if (isEmptyString(calledInRequestAmount)) {
            alert("Enter the request amount");
            return false;
        }

        if (+calledInRequestAmount! >= preOverridePrice!) {
            alert("Override amount must be less than the quoted price of the job");
            return false;
        }

        if (requestingSalespersonId < 1) {
            alert("Could not determine which salesperson is making request. Are you sure the appointment has been assigned to a salesperson?");
            return false;
        }
        
        return true;
    }

    function onRecordQuoteRequest() {
        if (canRecordQuoteRequest()) {
            recordPriceRequest({
                variables: {
                    chatId: chatId,
                    jobConfigurationId: jobConfigurationId,
                    amount: +calledInRequestAmount!,
                    salespersonId: requestingSalespersonId!
                }
            });
        }
    }
    
    return (
        <>
            {(jobCost > 1 && commissionRates.length > 0 && preOverridePrice > 0) && (
                <>
                    <div style={{fontWeight: "bold"}}>Requested Amount:</div>
                    
                    {overrideRequest ? (
                        <>
                            {(overrideRequest.amount > 0) ? (
                                <>
                                    <div>
                                        <NumberFormat
                                            prefix="$" thousandSeparator={true}
                                            displayType="text"
                                            value={overrideRequest.amount}
                                            decimalScale={2} fixedDecimalScale
                                            readOnly={disableInteraction}
                                        />
                                    </div>
                                    
                                    {(jobCost && financingAdjustedRevenue) && (
                                        <MarginDisplay revenue={financingAdjustedRevenue} cost={jobCost}/>
                                    )}

                                    {commissionRate ? (
                                        <div>
                                            <NumberFormat
                                                suffix="%" thousandSeparator={true}
                                                displayType="text"
                                                value={commissionRate * 100}
                                                decimalScale={0}
                                                readOnly={disableInteraction}
                                            />
                                        </div>
                                        // without the fragment, 0 is displayed for some reason
                                    ) : <></>}
                                </>
                            ) : (
                                <Typography>Lowest possible price</Typography>
                            )}


                            {/* show the button only if there's no request and the logged in worker is not the general command center worker */}
                            {(isNullOrUndefined(overrideRequest.commandCenterWorkerId) && generalCcWorkerId && (ccWorkerId !== generalCcWorkerId) && !disableInteraction) && (
                                <ClaimPriceRequestButton
                                    jobConfigurationId={jobConfigurationId}
                                    newWindow={false}
                                    useDefaultStyling
                                />
                            )}
                        </>
                    ) : (
                        // if the salesperson hasn't requested via chat, they can call in and the CC will enter it manually
                        <>
                            <NumberFormat
                                prefix="$" thousandSeparator={true}
                                displayType="input"
                                customInput={TextField}
                                value={calledInRequestAmount}
                                onValueChange={newVal => setCalledInRequestAmount(newVal.value)}
                                readOnly={disableInteraction}
                                disabled={disableInteraction}
                                decimalScale={2} fixedDecimalScale
                                allowNegative={false}
                            />

                            {!disableInteraction && (
                                <FlatButton
                                    variant="outlined"
                                    onClick={onRecordQuoteRequest}
                                    disabled={isEmptyString(calledInRequestAmount) || !requestingSalespersonId}
                                >Record Request</FlatButton>
                            )}
                            
                            {(salespeople?.length ?? 0) > 1 && (
                                <FormControl className="margin-bottom-md">
                                    <InputLabel id="request-select-salesperson">Salesperson</InputLabel>
                                    <Select
                                        style={{minWidth: "10rem"}}
                                        labelId="select-salesperson"
                                        value={requestingSalespersonId ?? -1}
                                        onChange={(e) => setRequestingSalespersonId(e.target.value as number)}
                                    >
                                        {(requestingSalespersonId < 1) && <MenuItem disabled value={-1}>Select Salesperson</MenuItem>}
                                        {salespeople?.map(sp => (
                                            <MenuItem key={`salesperson-${sp.id}`} value={sp.id}>{sp.firstName} {sp.lastName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </>
                    )}
                </>

            )}
        </>
    )
}