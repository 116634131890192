import { IconButton } from "@material-ui/core";
import { FlatIconButtonProps, IconSize } from "./flatButtonUtils";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

interface FlatLockAndUnlockButtonProps extends Omit<FlatIconButtonProps, "onClick"> {
    locked: boolean;
    setLocked: (locked: boolean) => void;
}

export default function FlatLockAndUnlockButton({
    locked,
    setLocked,
    color="black",
    style,
    ...iconButtonProps
}: FlatLockAndUnlockButtonProps) {
    return (
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={() => setLocked(!locked)}
        >
            {locked ? <FlatLockIcon color={color} size={iconButtonProps.size}/> : <FlatOpenLockIcon color={color} size={iconButtonProps.size}/>}
        </IconButton>
    )
}

export function FlatLockIcon({size, color="black"} : {size?: IconSize, color?: string}) {
    return <LockIcon htmlColor={color} fontSize={size}/>
}

export function FlatOpenLockIcon({size, color="black"} : {size?: IconSize, color?: string}) {
    return <LockOpenIcon htmlColor={color} fontSize={size}/>
}