import NavbarPage, { NavbarPageProps } from "Components/Page";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";

type ContractorNavbarPageProps = Omit<NavbarPageProps, "customRoutes">;

export default function ContractorNavbarPage({
    children,
    title,
    ...props
}: ContractorNavbarPageProps) {
    return (
        <NavbarPage
            hideNavbar
            {...props}
        >
            <FlatNavbar
                title={title ?? "My Calendar"}
                customRoutes={[
                    { name: "Home", route: "/home" },
                    { name: "Block Time", route: "/contractor-block-time" },
                ]}
            />
            {children}
        </NavbarPage>
    );
}
