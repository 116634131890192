import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Calendar, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import Loading from "Components/Loading";
import { GetAllActiveZipsAndCitiesQuery, useGetAllActiveZipsAndCitiesQuery } from "generated/graphql";
import { dayToIso } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, zipIsValid } from "Globals/GenericValidators";
import { useState } from "react";
import AvailableTimeSlots from "./AvailableTimeSlots";
import { FormValues, HandleValueChangeFunc } from "./NewSAHAppointmentPage";

export interface SchedulingDateTimeProps {
    formValues: FormValues;
    handleValueChange: HandleValueChangeFunc;
    errorFlag: boolean;
    setFormValues: (formValues: FormValues) => void
}

export function canMoveToProductSelection(formValues: FormValues) {
    return zipIsValid(formValues.zip) && isNotNullOrUndefined(formValues.day) && isNotNullOrUndefined(formValues.timeSlotId);
}

/* update functions */
export type SetErrorFunc = (error: boolean) => void;

function onDayUpdate(newDay: DayValue, formValues: FormValues, setFormValues: (formValues: FormValues) => void, setError: SetErrorFunc) {
    setFormValues({...formValues, day: newDay, timeSlotId: null})
    // setError(!isDaySelected);
}

function onSelectTime(newVal: number | null, handleValueChange: HandleValueChangeFunc, setError: SetErrorFunc) {
    handleValueChange("timeSlotId", newVal);  // update state regardless of error
    // debounce(() => setError(!isTimeSelected), 1000)(); // set error state after 1 second
}

function onIsForHomeUpdate(newVal: boolean, formValues: FormValues, setFormValues: (formValues: FormValues) => void) {
    // reset the business name when this changes to prevent submitting a business name for a home
    setFormValues({...formValues, isForHome: newVal, businessName: ''});
}

export type ZipAndCityMap = {[key: string]: string[]}
export function buildZipAndCityMap(data: GetAllActiveZipsAndCitiesQuery['allActiveZipsAndCities'] | undefined) {
    let zipAndCityMap: ZipAndCityMap = {};
    if (data) {
        data.forEach(item => {
            if (Object.keys(zipAndCityMap).includes(item.zip)) {
                zipAndCityMap[item.zip].push(item.city);
            } else {
                zipAndCityMap[item.zip] = [item.city];
            }
        });
    }

    return zipAndCityMap;
}

export function zipIsServiced(zipCityDict: ZipAndCityMap, zip: string) {
    return Object.keys(zipCityDict).includes(zip);
}

/* NOTE: though handleValueChange is a wrapper for use of setFormValues, here we also need direct access to setFormValues because
when the day changes, we also need to set the time to null. Setting state is asynchronous, so if we make two successive calls to
handleValueChange, the second call will not yet reflect the changes to the state from the first call. Therefore, the first update will
effectively be overwritten. So, by having direct access to setFormValues, we can not set them both at the time time.
Read here for a more detailed explanation:
    https://www.devasking.com/issue/react-hooks-async-setstate-update-with-old-state-because-of-closure-duplicate
*/
export default function SchedulingDateTime({ formValues, handleValueChange, errorFlag, setFormValues }: SchedulingDateTimeProps) {
    const [dateError, setDateError] = useState(false);
    const [timeError, setTimeError] = useState(false);

    const { data: zipAndCityData, loading: zipAndCityDataLoading } = useGetAllActiveZipsAndCitiesQuery();
    let zipsAndCities = buildZipAndCityMap(zipAndCityData?.allActiveZipsAndCities);

    const [selectableCities, setSelectableCities] = useState<ReactJSXElement[]>([]);
    function onChangeZip(newZip: string) {
        if (zipIsValid(newZip) && zipIsServiced(zipsAndCities, newZip)) {
            let newCities = zipsAndCities[newZip].map(city => (
                <MenuItem value={city} key={city}>{city}</MenuItem>
                ));
                setSelectableCities(newCities);
                setFormValues({...formValues, city: zipsAndCities[newZip][0], zip: newZip, day: null, timeSlotId: null})
            } else {
                setSelectableCities([]);
                setFormValues({...formValues, zip: newZip, day: null, timeSlotId: null}); 
        }
    }

    function onChangeCity(newCity: string) {
        setFormValues({...formValues, city: newCity})
    }
    
    return (<>
        {(zipAndCityDataLoading) ? (
            <Loading />
        ) : (
            <>
                <div className="flex-row flex-space-around align-items-center">
                    <TextField
                        // error={errorFlag && !isZipOk(formValues.zip)}
                        InputProps={{inputProps: {minLength: 5, maxLength: 5}}}
                        className="margin-sm"
                        id="zip-input"
                        value={formValues.zip}
                        onChange={(e) => onChangeZip(e.target.value)}
                        label="Zip Code"
                        required
                    />
                
                    {zipIsServiced(zipsAndCities, formValues.zip) && (
                        <Select 
                            style={{marginTop: "0.85rem"}}
                            value={formValues.city}
                            onChange={(e) => onChangeCity(e.target.value as string)}
                            disableUnderline  // a custom underline has been added in the CSS
                        >
                            {selectableCities}
                        </Select>
                    )}
                </div>

                {(!zipAndCityDataLoading && !Object.keys(zipsAndCities).includes(formValues.zip) && zipIsValid(formValues.zip)) && (
                    <p className="error-text">We do not currently service {formValues.zip}</p>
                )}
            
                <div className="margin-sm">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Is this appointment for a home or business?</FormLabel>
                        <RadioGroup
                            className="margin-auto"
                            defaultValue="home"
                            name="is-home-buttons-group"
                            onChange={(e) => onIsForHomeUpdate(e.target.value === "home" ? true : false, formValues, setFormValues)}
                            row >
                            <FormControlLabel 
                                value="home" 
                                control={<Radio checked={formValues.isForHome}/>} 
                                label="Home"
                            />
                            <FormControlLabel
                                value="business"
                                control={<Radio checked={!formValues.isForHome}/>} 
                                label="Business" />
                        </RadioGroup>
                    </FormControl>
                </div>

                {/* Don't display calendar until a valid zip code has been entered */}
                {
                    (!zipAndCityDataLoading && zipIsValid(formValues.zip) && zipIsServiced(zipsAndCities, formValues.zip)) ?
                    <div className="padding-sm" style={dateError ? {border: "0.25rem solid #f44336",} : {}}>
                        <Calendar
                            value={formValues.day}
                            onChange={(day) => onDayUpdate(day, formValues, setFormValues, setDateError)}
                            minimumDate={utils('en').getToday()}
                        />
                    </div> 
                    : <p>Please enter a valid zip code to see available dates and times.</p>
                }

                {/* Don't display times if there is no zip and no selected day */}
                {
                    (zipIsValid(formValues.zip) && zipIsServiced(zipsAndCities, formValues.zip) && !zipAndCityDataLoading && isNotNullOrUndefined(formValues.day)) && 
                    <div className="padding-sm" style={timeError ? {border: "0.25rem solid #f44336",} : {}}>
                        <AvailableTimeSlots
                            selectedSlotId={formValues.timeSlotId}
                            dayISO={dayToIso(formValues.day!)}
                            onSelectSlot={newTime=>onSelectTime(newTime, handleValueChange, setTimeError)} 
                            zip={formValues.zip}  
                        />    
                    </div>
                }
                
            </>
        )}
        </>
    );
}