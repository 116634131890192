import { Button, Container, IconButton, TextField, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import clsx from "clsx";
import BlockHeading from "Components/Forms/BlockHeading";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import UploadPictureDialog from "Components/Forms/Controls/UploadPictureDialog";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import FlexGrow from "Components/PageLayout/FlexGrow";
import { namedOperations, Picture, useGetJobConfigurationQuery, useUpsertJobConfigurationMutation } from "generated/graphql";
import { buildAppendedId, useNumericIdParam } from "Globals/Hooks";
import { sellSheetPath } from "Globals/PathStrings";
import { ProductTypeSelectButton } from "Pages/Admin/ProductPricing/SimpleEditors/ProductTypeSelector";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { convertConfigToInput, createNewArea, createNewRoom, openAssignUnassignedRoomDialog, openLabelForRoomDialog, openRoomSqftDialog, removeAllUnassignedRooms, removeArea, removeRoom, removeUnassignedRoom, selectArea, selectAreaCount, selectRoom, selectUnassignedRoom, selectUnassignedRoomCount, selectUnassignedRooms, setGenericSalesReducerState, updateArea, updateRoom } from "Redux/genericSalesReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { store } from "Redux/store";
import AssignUnassignedRoomDialog from "./AssignUnassignedRoomDialog";
import RoomLabelsEditorPopup from "./LabelForRoomEditor";
import RoomSqftDialog from "./RoomSqftDialog";
import ServicesForRoomDialog from "./ServicesForRoomDialog";

export default function GenericQuoteBuilderPage() {
    const { id } = useNumericIdParam();
    const history = useHistory();

    const dispatch = useAppDispatch()
    const areaCount = useAppSelector(selectAreaCount)
    const unassignedCount = useAppSelector(selectUnassignedRoomCount)
    const [submitMetadata] = useUpsertJobConfigurationMutation({
        onCompleted(data) {
            const configId = data.upsertJobConfiguration.id
            history.push(sellSheetPath + buildAppendedId(configId))
        },
        onError(error) {
            window.alert(error)
            console.log(error)
        },
        refetchQueries: [namedOperations.Query.GetJobConfiguration],
        awaitRefetchQueries: true
    })

    const { loading, error } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: id ?? 0 },
        skip: id === undefined,
        onCompleted(data) {
            dispatch(setGenericSalesReducerState(data.jobConfiguration))
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: 'cache-first'
    })

    function getJobConfig() {
        const sales = store.getState().genericSales
        if (sales.jobConfiguration) {
            return convertConfigToInput(sales.jobConfiguration)
        }
        else throw new Error('Job config doesnt exist')
    }

    function onUpdateQuote() {
        submitMetadata({ variables: { jobConfiguration: getJobConfig(), saveAsNew: false } })
    }

    function onNewQuote() {
        submitMetadata({ variables: { jobConfiguration: getJobConfig(), saveAsNew: true } })
    }

    return (
        <NavbarPage title="Installation Details">
            <Container className="flex-column solid-border fill-height padding-sm">
                {
                    loading &&
                    <Loading />
                }
                {
                    error &&
                    <h2>Job does not exist</h2>
                }
                {
                    !loading &&
                    !error &&
                    <>
                        {
                            [...Array(areaCount)].map(
                                (_, index) => <RenderArea key={`area-index-${index}`} areaIndex={index} />
                            )
                        }
                        <Button
                            className='margin-xsm'
                            color='secondary'
                            variant='contained'
                            onClick={() => dispatch(createNewArea())}>
                            Make Area
                        </Button>
                        {
                            unassignedCount > 0 &&
                            <RenderUnassignedRooms />
                        }
                    </>
                }
                <FlexGrow />
                <div className="flex-row-reverse">
                    <SpacedButton variant="contained" onClick={onUpdateQuote}>Update Quote</SpacedButton>
                    <SpacedButton variant="contained" onClick={onNewQuote}>New Quote</SpacedButton>
                </div>
            </Container>
            <RoomLabelsEditorPopup />
            <RoomSqftDialog />
            <ServicesForRoomDialog />
            <AssignUnassignedRoomDialog />
        </NavbarPage>
    )
}

function RenderArea({ areaIndex }: { areaIndex: number }) {
    const { productTypeId, rooms } = useAppSelector(selectArea(areaIndex))
    var labels = rooms.flatMap(room => room.labels)

    const dispatch = useAppDispatch()

    function changeAreaProductType(newProduct: number) {
        const areServices = rooms.some(room => room.services.length !== 0) ?? false

        if (!areServices || window.confirm("Changing product types will remove all services for the rooms it contains")) {
            dispatch(updateArea(areaIndex, { productTypeId: newProduct, rooms: rooms.map(room => ({ ...room, services: [] })) }))
        }
    }

    return (
        <div className='margin-xsm'>
            <BlockHeading
                preLabel={<ProductTypeSelectButton pid={productTypeId ?? undefined} setPid={changeAreaProductType} className={clsx("w-10r h-3r mh-3r", { 'f-1-5r': productTypeId !== -1 })} />}
                label={<b>{getNameOfArea(labels, '  \u{202f}')}</b>}
                style={{ whiteSpace: 'pre-wrap' }}
            >
                <div className="flex-row">
                    <Button
                        disabled={productTypeId === -1}
                        color='secondary'
                        variant='contained'
                        key={`remove-area-button-${areaIndex}`}
                        onClick={() => dispatch(createNewRoom(areaIndex))}>
                        Add Room
                    </Button>
                    <IconButton
                        style={{ marginRight: "0.5rem" }}
                        onClick={() => dispatch(removeArea(areaIndex))}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </BlockHeading>
            <div className="flex-row fill-width h-10r overflow-auto">
                {
                    rooms.map((room, roomIndex) => (
                        <RenderRoom key={`render-room-${areaIndex}-${roomIndex}`} areaIndex={areaIndex} roomIndex={roomIndex} />
                    ))
                }
            </div>
        </div>
    );
}

function RenderRoom({ areaIndex, roomIndex }: { areaIndex: number, roomIndex: number }) {
    const room = useAppSelector(selectRoom(areaIndex, roomIndex));
    const dispatch = useAppDispatch()

    const [uploadOpen, setUploadOpen] = useState<boolean>(false)

    const labels = room?.labels ?? []
    const notes = room?.notes ?? ""

    function updateRoomImages(images: Picture[]){
        dispatch(updateRoom(areaIndex, roomIndex, {pictures: [...room!.pictures, ...images.map(i=>({id: -1, roomId: -1, picture: i}))]}))
    }

    return (
        <div className="flex-column margin-side-xxsm w-20r minw-20r fill-height" style={{ justifyContent: "space-between", backgroundColor: "#eeeeee", borderRadius: ".5rem", paddingBottom: ".25rem" }}>
            <div className="fill-width h-2r flex-row padding-xsm" style={{ backgroundColor: "#dddddd", borderRadius: ".5rem .5rem 0 0", alignItems: "center" }}>
                <Typography><b>{getNameOfArea(labels, '  \u{202f}')}</b></Typography>
                <IconButton size="small" onClick={() => dispatch(openLabelForRoomDialog(roomIndex, areaIndex))}><EditIcon /></IconButton>
                <div className="flex-grow" />
                <IconButton size="small" onClick={() => setUploadOpen(true)}><PhotoCameraIcon /></IconButton>
                <IconButton size="small" onClick={() => dispatch(removeRoom(areaIndex, roomIndex))}><DeleteIcon /></IconButton>
            </div>
            <div className="fill-width flex-row-center">
                <Button
                    disabled={labels.length < 1}
                    variant="contained"
                    color="secondary"
                    onClick={() => dispatch(openRoomSqftDialog(roomIndex, areaIndex))}>
                    Edit Installation Details</Button>
            </div>
            <TextField
                value={notes}
                onChange={e => dispatch(updateRoom(areaIndex, roomIndex, { notes: e.target.value }))}
                className="margin-side-xxsm"
                style={{ backgroundColor: "white" }}
                multiline
                minRows={2}
                maxRows={2}
                label="Notes:"
                variant="outlined" />
            <UploadPictureDialog open={uploadOpen} closeDialog={()=>setUploadOpen(false)} uploadImages={updateRoomImages}/>
        </div>
    )
}

function RenderUnassignedRoom({ roomIndex }: { roomIndex: number }) {

    const room = useAppSelector(selectUnassignedRoom(roomIndex))
    const dispatch = useAppDispatch()

    const labels = room?.labels ?? []

    return (
        <div className="flex-column margin-side-xxsm w-20r minw-20r fill-height" style={{ justifyContent: "space-between", backgroundColor: "#eeeeee", borderRadius: ".5rem", paddingBottom: ".25rem" }}>
            <div className="fill-width h-2r flex-row padding-xsm" style={{ backgroundColor: "#dddddd", borderRadius: ".5rem .5rem 0 0", alignItems: "center" }}>
                <Typography><b>{getNameOfArea(labels, '  \u{202f}')}</b></Typography>
                <div className="flex-grow" />
                <IconButton size="small" onClick={() => dispatch(removeUnassignedRoom(roomIndex))}><DeleteIcon /></IconButton>
            </div>
            <div className="fill-width flex-row-center">
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => dispatch(openAssignUnassignedRoomDialog(roomIndex))}>
                    Assign To Area
                </Button>
            </div>
        </div>
    )
}

function RenderUnassignedRooms() {

    const unassignedRooms = useAppSelector(selectUnassignedRooms)
    const dispatch = useAppDispatch()

    return (
        <div className='margin-xsm'>
            <BlockHeading
                label={<b>Unassigned Rooms</b>}
                style={{ whiteSpace: 'pre-wrap' }}
            >
                <div className="flex-row">
                    <IconButton
                        style={{ marginRight: "0.5rem" }}
                        onClick={()=>dispatch(removeAllUnassignedRooms())}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </BlockHeading>
            <div className="flex-row fill-width h-10r overflow-auto">
                {
                    unassignedRooms.map((r, roomIndex) => (
                        <RenderUnassignedRoom key={`render-unassigned-room-${roomIndex}`} roomIndex={roomIndex} />
                    ))
                }
            </div>
        </div>
    )
}