import { Dialog, DialogActions, DialogTitle, IconButton, TextField } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { DialogContent } from "@mui/material";
import { Picture } from "generated/graphql";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { FileUploadImageButton } from "./FileUploadButton";
import SpacedButton from "./SpacedButton";

interface UploadPictureDialogProps extends Pick<UploadPictureDialogInnerProps, "uploadImages"> {
    open: boolean;
    closeDialog: () => void;
}

export default function UploadPictureDialog({
    open,
    closeDialog,
    uploadImages,
}: UploadPictureDialogProps) {
    return (
        <>
            {open && (
                <UploadPictureDialogInner
                    onClose={closeDialog}
                    uploadImages={uploadImages}
                />
            )}
        </>
    );
}

interface UploadPictureDialogInnerProps {
    onClose: () => void;
    uploadImages?: (images: Picture[]) => void;
}

function UploadPictureDialogInner({ onClose, uploadImages }: UploadPictureDialogInnerProps) {
    const [pictures, setPictures] = useState<Picture[]>([]);

    function onAddImages(e: React.ChangeEvent<HTMLInputElement>) {
        const newImages: File[] = [...(e.target.files ?? [])];

        newImages.forEach((img) => {
            loadFile(img, (bs) => setPictures((p) => [...p, makePicture(bs)]));
        });
    }

    function onRemoveImage(index: number) {
        setPictures(pictures.filter((u, i) => i !== index));
    }

    function updateDescription(index: number, description: string) {
        if (description.length > 512) {
            description = description.substring(0, 512);
        }

        const updated = [...pictures];
        updated[index].description = description;

        setPictures(updated);
    }

    function onSubmit() {
        if (pictures.length > 0) uploadImages?.(pictures);
        onClose();
    }

    const match = useMediaQuery({ query: "only screen and (max-width: 800px)" });

    return (
        <Dialog
            open={true}
            onClose={onClose}
            fullScreen={match}
        >
            <DialogTitle>Upload Pictures (Total: {pictures.length})</DialogTitle>
            <DialogContent>
                <div
                    className="flex-column flex-centered"
                    style={{ minHeight: "30rem", minWidth: "min(30rem, 85vw)", maxWidth: "40rem" }}
                >
                    {pictures.map((picture, index) => (
                        <div
                            key={`upload-img-${index}`}
                            className="flex-column flex-centered"
                            style={{ margin: ".5rem 0", position: "relative" }}
                        >
                            <img
                                src={`data:application/jpg;base64,${picture.image!}`}
                                alt={"Selected from file"}
                                style={{ maxWidth: "95%", maxHeight: "40rem" }}
                            />
                            <IconButton
                                onClick={() => onRemoveImage(index)}
                                size="medium"
                                style={{ position: "absolute", top: 0, right: "1rem" }}
                            >
                                <HighlightOffIcon
                                    style={{ backgroundColor: "#aaaa", borderRadius: "1rem" }}
                                />
                            </IconButton>
                            <TextField
                                multiline
                                maxRows={2}
                                label="Description:"
                                style={{ width: "95%" }}
                                value={picture.description}
                                onChange={(e) => updateDescription(index, e.target.value)}
                            />
                        </div>
                    ))}
                    <FileUploadImageButton
                        accept=".jpeg,.jpg,.png"
                        onUploadEvent={onAddImages}
                        style={{ fontSize: "3rem", width: "15rem", margin: "auto 0" }}
                    >
                        Upload
                    </FileUploadImageButton>
                </div>
            </DialogContent>
            <DialogActions>
                <SpacedButton
                    className="cancel-button"
                    onClick={onClose}
                >
                    Cancel
                </SpacedButton>
                <SpacedButton
                    variant="contained"
                    color="secondary"
                    onClick={onSubmit}
                >
                    Upload
                </SpacedButton>
            </DialogActions>
        </Dialog>
    );
}

export function loadFile(file: File, onLoaded: (loadedFile: string) => void) {
    const reader = new FileReader();

    reader.onload = (e) => {
        const bs = e.target!.result as string;
        onLoaded(bs);
    };

    reader.readAsBinaryString(file);
}

export function makePicture(imageBinaryString: string): Picture {
    return {
        id: -1,
        description: "",
        image: btoa(imageBinaryString),
    };
}
