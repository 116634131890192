import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import {
    useGetAuthenticatedWorkerQuery,
    useGetContractorQuery,
    useGetSalespersonQuery,
} from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import ContractorHome from "./ContractorHome/ContractorHome";
import SalespersonHome from "./SalespersonHome/SalespersonHome";
import logo from "Assets/Images/WorldOfFloorsLogoLarge.png";

export default function Home() {
    const { data, loading } = useGetAuthenticatedWorkerQuery({ fetchPolicy: "network-only" });
    const { data: salespersonData, loading: salespersonLoading } = useGetSalespersonQuery({
        variables: { workerId: data?.authenticatedWorker?.id! },
        skip: isNullOrUndefined(data?.authenticatedWorker?.id),
    });
    const { data: contractorData, loading: contractorLoading } = useGetContractorQuery({
        variables: { workerId: data?.authenticatedWorker?.id! },
        skip: isNullOrUndefined(data?.authenticatedWorker?.id),
    });

    const pageLoading = salespersonLoading || contractorLoading || loading;

    if (pageLoading) {
        return <Loading />;
    } else if (!isNullOrUndefined(salespersonData?.salesperson)) {
        return <SalespersonHome salesperson={salespersonData?.salesperson!} />;
    } else if (!isNullOrUndefined(contractorData?.contractor)) {
        return <ContractorHome contractor={contractorData?.contractor!} />;
    } else {
        return (
            <NavbarPage>
                <div
                    className="fill-height fill-width flex-row"
                    style={{ justifyContent: "center", alignItems: "center" }}
                >
                    <img
                        style={{ maxWidth: "min(95vw, 40rem)" }}
                        src={logo}
                        alt="World of Floors Logo"
                    />
                </div>
            </NavbarPage>
        );
    }
}
