import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useAppSelector } from "Redux/hooks";
import { selectChatId, selectContractConfigurationId, selectFinancingOption, selectJobConfigurationId, selectJobCost, selectQuoteConfigToQuoteIdMap, selectUsageContext } from "Redux/pricingCenterReducer";
import PriceAndMarginEditor from "../PriceAndMarginEditor";
import { CcOverrideCommissionSelect } from "./CommissionSelect";
import SendOverrideButton from "./SendOverrideButton";

export interface OverrideEditorProps {
    chatId: number;
    setOverrideAmount: (amt?: number) => void;
    overrideAmount?: number;
    disabled: boolean;
}

interface NewOverrideEditorProps extends OverrideEditorProps {
    // used when salesperson calls in and asks for lowest possible price
    forLowestPriceClaim?: boolean;
}

export default function NewOverrideEditor({ overrideAmount, forLowestPriceClaim, setOverrideAmount, disabled }: NewOverrideEditorProps) {
    const chatId = useAppSelector(selectChatId);
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const jobCost = useAppSelector(selectJobCost);
    const financingOption = useAppSelector(selectFinancingOption);
    const usageContext = useAppSelector(selectUsageContext);
    const contractConfigId = useAppSelector(selectContractConfigurationId);
    const quotedConfigIds = Object.keys(useAppSelector(selectQuoteConfigToQuoteIdMap)).map(id => +id);
    const disableInteraction = usageContext === "readonly" || isNotNullOrUndefined(contractConfigId) || quotedConfigIds.includes(jobConfigurationId);

    return (
        <div className="flex-row flex-gap-sm flex-centered align-items-flex-end">
            <PriceAndMarginEditor
                revenue={overrideAmount} setRevenue={setOverrideAmount}  
                cost={jobCost}
                financingOption={financingOption}
                disabled={disabled}
            />

            {!disableInteraction && (<>
                <CcOverrideCommissionSelect />

                <SendOverrideButton
                    chatId={chatId}
                    jobConfigurationId={jobConfigurationId}
                    amount={overrideAmount}
                    forLowestPriceClaim={forLowestPriceClaim}
                />
            </>)}
        </div>
    )
}

