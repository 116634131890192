import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import ItemAmountPicker, { ItemAmount, productTypeToDefaultOptions } from "Components/ItemAmountPicker/ItemAmountPicker";
import { InventoryEntry } from "generated/graphql";
import { formatProductNameString } from "Globals/StringFormatting";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { addAmount, removeAmount, selectItemsToOrder, selectOrderAmount, updateAmount } from "Redux/orderingReducer";
import "App.css"

export type Props = {
    setFilteredManufacturer: (value: string) => void,
}

function TotalViewRow({ row }: { row: InventoryEntry }) {
    const amounts = useAppSelector((state) => selectOrderAmount(state, row.sku))
    const dispatch = useAppDispatch();

    const DefaultButton = ({ onClick, label }: { onClick: () => void, label: string }) => {
        return (
            <Button
                variant="contained"
                color="secondary"
                style={{ width: 30, height: 30, minHeight: 0, minWidth: 0 }}
                onClick={onClick}>
                {label}
            </Button>
        )
    }

    const AddButton = () => {
        const newAmount: ItemAmount = {
            type: productTypeToDefaultOptions[row.productType][0],
            value: { primary: "0", secondary: "0" }
        }

        const onClick = () => dispatch(addAmount({ sku: row.sku, orderAmount: newAmount }))

        return (<DefaultButton onClick={onClick} label={"+"} />)
    }

    const RemoveButton = (index: number) => {
        const onClick = () => dispatch(removeAmount({ sku: row.sku, index: index }))

        return (<DefaultButton onClick={onClick} label={"-"} />)
    }

    const MakeButtonsForRow = (index: number) => {
        if (index === 0 && amounts.length === 1) return AddButton()
        else if (index !== amounts.length - 1) return RemoveButton(index)
        else return (<>{AddButton()}{RemoveButton(index)}</>)
    }

    return (
        <TableRow>
            <TableCell style={{ verticalAlign: "top" }}>{formatProductNameString(row)}</TableCell>
            <TableCell align="right" style={{ verticalAlign: "top" }}>{`${row.potential} ${row.stockUnit}`}</TableCell>
            <TableCell align="right" style={{ verticalAlign: "top" }}>{row.newCount}</TableCell>
            <TableCell align="right">
                <div className="flex-column" style={{ alignItems: "flex-end" }}>
                    {amounts.map((amount, index) => {
                        const key = `${row.sku}-amount-picker-${index + 1}`
                        return (
                            <div key={key} className="flex-row" style={{ marginBottom: ".1rem" }}>
                                {MakeButtonsForRow(index)}
                                <ItemAmountPicker
                                    type={row.productType}
                                    amount={amount}
                                    onChange={(e) => dispatch(updateAmount({ sku: row.sku, orderAmount: e, index: index }))}
                                    boxesPerPallet={row.stockUnitPerOrderUnit}
                                    feetPerRoll={row.measureUnitPerInventoryUnit}
                                    cartonToSqft={row.measureUnitPerInventoryUnit}
                                    width={250}
                                />
                            </div>
                        )
                    })}
                </div>
            </TableCell>
        </TableRow>
    )
}

export default function OrderTotalView({ setFilteredManufacturer }: Props) {
    const manufacturerItemPairs = useAppSelector(selectItemsToOrder)

    return (
        <>
            <h1>Order Total</h1>
            <div className="flex-column">
                {manufacturerItemPairs.map(({ manufacturer: name, items: rows }) => (
                    <div key={`manufacturer-${name}-section`} className="flex-column" style={{ margin: "10px 0" }}>
                        <div className="flex-row">
                            <h2 className="flex-grow">{name}</h2>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setFilteredManufacturer(name)}
                                    children={"Add More"}
                                />
                            </div>
                        </div>
                        <div className="flex-grow">
                            <Table size="small" className="fill-width">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Item</TableCell>
                                        <TableCell align="right">Potential</TableCell>
                                        <TableCell align="right">Actual</TableCell>
                                        <TableCell align="right">To Order</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => <TotalViewRow row={row} key={`${name}-${row.sku}`} />)}
                                </TableBody>
                            </Table>
                        </div>
                    </div>))
                }
            </div>
        </>
    )
}