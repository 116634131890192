import { Input } from "@material-ui/core";
import { MaterialAmountEditorProps } from "./MaterialForServiceColumns";


export default function DefaultMaterialAmountEditor({ onChange, ...otherProps }: MaterialAmountEditorProps) {
    return (
        <Input
            {...otherProps}
            onChange={e => onChange(e.target.value)}
        />
    )
}