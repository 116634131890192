import JobSearchBar from "Components/Inputs/Search/JobSearchBar";
import NavbarPage from "Components/Page";
import {
    GetAllJobsInRecoveryQuery,
    useGetAllJobsInRecoveryQuery,
    useGetAuthenticatedWorkerQuery,
} from "generated/graphql";
import { dateTimeStrToDate, dateToPaddedMdy } from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { idUrlMask } from "Globals/Hooks";
import { recoveryCenterPath } from "Globals/PathStrings";
import { Route, Switch } from "react-router";
import ClaimedColumn from "./ClaimedColumn";
import HotColumn from "./HotColumn";
import QuoteViewerPage from "./QuoteViewerPage";

function RecoveryCenter() {
    const { data } = useGetAllJobsInRecoveryQuery();
    const { data: workerData } = useGetAuthenticatedWorkerQuery();

    const today = dateToPaddedMdy(new Date());

    var cardsValidForPage =
        data?.allJobsInRecovery.filter(
            (job) =>
                isNullOrUndefined(job.recoveryWorker?.id) ||
                job.recoveryWorker?.id === workerData?.authenticatedWorker?.id
        ) ?? [];

    function sortCardsToColumns(
        previousValue: CardColumns,
        job: GetAllJobsInRecoveryQuery["allJobsInRecovery"][number]
    ): CardColumns {
        var cards = { ...previousValue };

        if (job.isHot && isNullOrUndefined(job.recoveryWorker)) {
            cards.hotCards = [...previousValue.hotCards, job];
        } else if (job.isHot && !isNullOrUndefined(job.recoveryWorker)) {
            cards.inProgressCards = [...previousValue.inProgressCards, job];
        } else if (
            job.steps.length > 0 &&
            dateToPaddedMdy(dateTimeStrToDate(job.steps[job.steps.length - 1].date)) === today
        ) {
            cards.todaysFollowUpCards = [...previousValue.todaysFollowUpCards, job];
        } else {
            cards.upcomingCards = [...previousValue.upcomingCards, job];
        }
        return {
            ...cards,
        };
    }

    const { hotCards, inProgressCards, todaysFollowUpCards, upcomingCards } =
        cardsValidForPage.reduce<CardColumns>(sortCardsToColumns, emptyColumns);

    return (
        <NavbarPage
            title={"Recovery Center"}
            centerHorizontally
            style={{ width: "fit-content", alignSelf: "center" }}
        >
            <JobSearchBar openingFrom="rc"/>
            <div style={{ fontSize: ".75rem" }}>
                Hello, {workerData?.authenticatedWorker?.firstName}{" "}
                {workerData?.authenticatedWorker?.lastName}
            </div>
            <div
                className="flex-row"
                style={{ width: "80rem" }}
            >
                <HotColumn
                    jobs={hotCards}
                    workerId={workerData?.authenticatedWorker?.id}
                    disabled={inProgressCards.length > 0}
                />
                <ClaimedColumn
                    title="IN PROGRESS"
                    jobs={inProgressCards}
                    id="rc-column-header-in-progress"
                />
                <ClaimedColumn
                    title="TODAY'S FOLLOW-UP"
                    id="rc-column-header-todays-follow-up"
                    jobs={todaysFollowUpCards}
                />
                <ClaimedColumn
                    title="UPCOMING"
                    id="rc-column-header-upcoming"
                    jobs={upcomingCards}
                />
            </div>
        </NavbarPage>
    );
}

export interface BaseColumnProps {
    jobs: GetAllJobsInRecoveryQuery["allJobsInRecovery"];
}

interface CardColumns {
    hotCards: GetAllJobsInRecoveryQuery["allJobsInRecovery"];
    inProgressCards: GetAllJobsInRecoveryQuery["allJobsInRecovery"];
    todaysFollowUpCards: GetAllJobsInRecoveryQuery["allJobsInRecovery"];
    upcomingCards: GetAllJobsInRecoveryQuery["allJobsInRecovery"];
}

const emptyColumns: CardColumns = {
    hotCards: [],
    inProgressCards: [],
    todaysFollowUpCards: [],
    upcomingCards: [],
};

export default function RecoveryCenterPage() {
    return (
        <Switch>
            <Route path={`${recoveryCenterPath}/quote${idUrlMask}`}>{<QuoteViewerPage />}</Route>
            <Route path={`${recoveryCenterPath}`}>{<RecoveryCenter />}</Route>
        </Switch>
    );
}
