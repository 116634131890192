import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";

interface AppointmentTypeSelectorProps {
    isForHome: boolean;
    setIsForHome: (newVal: boolean) => void;
}

// TODO: this is the same as the selector found in the SchedulingDateTime component; refactor
export default function AppointmentTypeSelector({isForHome, setIsForHome}: AppointmentTypeSelectorProps) {
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Is this appointment for a home or business?</FormLabel>
            <RadioGroup
                className="margin-auto"
                defaultValue="home"
                name="is-home-buttons-group"
                onChange={(e) => setIsForHome(e.target.value === "home" ? true : false)}
                row >
                <FormControlLabel 
                    value="home" 
                    control={<Radio checked={isForHome}/>} 
                    label="Home"
                />
                <FormControlLabel
                    value="business"
                    control={<Radio checked={!isForHome}/>} 
                    label="Business" />
            </RadioGroup>
        </FormControl>
    )
}