import { Button, IconButton, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useState } from "react";
import { ReducedService } from "./EditInstallationServicesMenu";

export type ServiceState = "Cust" | "WOF" | "Over";

interface CombinedJobServiceRowProps {
    serviceLabel: string;
    reducedServices: ReducedService[];
    allowedStates: ServiceState[];
    updateAllServices: (state: ServiceState) => void;
    updateService: (state: ServiceState, serviceIndex: number) => void;
}

export default function CombinedJobServiceRow({
    serviceLabel,
    reducedServices,
    allowedStates,
    updateAllServices,
    updateService,
}: CombinedJobServiceRowProps) {
    const [byRoomOpen, setByRoomOpen] = useState<boolean>(false);

    const { isByRoom, state: mainState } = determineOverallState(reducedServices);

    const displayValue = isByRoom ? "By Room" : mainState;
    const selectedValue = allowedStates.findIndex((curr) => curr === mainState);

    return (
        <>
            <div
                className="fill-width flex-row"
                style={{ alignItems: "center" }}
            >
                <div className="flex-grow">
                    <Typography style={{ fontSize: "1.25rem" }}>{serviceLabel}</Typography>
                </div>
                <div
                    className="flex-row-center"
                    style={{ width: "8rem" }}
                >
                    <Button
                        style={{ height: "2rem", fontSize: "1.15rem" }}
                        variant="contained"
                        onClick={() => {
                            const nextIndex = (selectedValue + 1) % allowedStates.length;

                            updateAllServices(allowedStates[nextIndex]);
                        }}
                    >
                        {displayValue}
                    </Button>
                </div>
                <div style={{ width: "3rem" }}>
                    <IconButton
                        onClick={() => setByRoomOpen(!byRoomOpen)}
                        style={{
                            height: "2rem",
                            width: "4rem",
                            fontSize: "1.25rem",
                            margin: ".25rem 0",
                        }}
                    >
                        {!byRoomOpen && <ArrowDropDownIcon fontSize="large" />}
                        {byRoomOpen && <ArrowDropUpIcon fontSize="large" />}
                    </IconButton>
                </div>
            </div>
            
            {byRoomOpen && (
                <div
                    className="fill-width flex-row"
                    style={{ alignItems: "center" }}
                >
                    {reducedServices.map(({ serviceIndex, roomLabel, state }) => {
                        return (
                            <div
                                className="flex-row"
                                style={{ alignItems: "center" }}
                                key={serviceIndex}
                            >
                                <Typography
                                    style={{ fontSize: "1.15rem", padding: "0 .25rem 0 .75rem" }}
                                >
                                    [{roomLabel}]
                                </Typography>
                                <Button
                                    style={{ height: "2rem", width: "4rem", fontSize: "1.05rem" }}
                                    variant="contained"
                                    onClick={() => {
                                        const indexOfCurrent = Math.max(
                                            allowedStates.findIndex((curr) => curr === state),
                                            0
                                        );
                                        const nextIndex =
                                            (indexOfCurrent + 1) % allowedStates.length;

                                        updateService(allowedStates[nextIndex], serviceIndex);
                                    }}
                                >
                                    {state}
                                </Button>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}

interface OverallStateResults {
    isByRoom: boolean;
    state?: ServiceState;
}

function determineOverallState(reducedServices: ReducedService[]): OverallStateResults {
    if (reducedServices.length === 0) return { isByRoom: true };

    const initialState = reducedServices[0].state;

    const hasDifferent = reducedServices.some((state) => state.state !== initialState);

    return {
        isByRoom: hasDifferent,
        state: hasDifferent ? undefined : initialState,
    };
}