import { humanize, toLowerCase, toTitleCase, transform } from "@alduino/humanizer/string";
import { MenuItem } from "@material-ui/core";
import {
    GetDummyInventoryQueryResult,
    InventoryEntry,
    InventoryEntryFilterInput,
} from "generated/graphql";
import React, { useState } from "react";
import { useContextMenu } from "react-contexify";
import {
    CalculatedColumn,
    Row as RowRenderer,
    RowRendererProps,
    SortColumn,
} from "react-data-grid";
import { isMobile } from "react-device-detect";
import { Primitive } from "react-hook-form";
import { StandardEnum } from "./UtilityTypes";

export type DoubleClickHandler<TRow> = (e: DoubleClickEvent<TRow>) => void;
export type ClickHandler<TRow> = (e: ClickEvent<TRow>) => void;

export interface DoubleClickEvent<TRow> {
    reactEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>;
    index: number;
    row: TRow;
}

export interface ClickEvent<TRow> {
    index: number;
    row: TRow;
}

function doubleClickHelper<TRow>(
    e: DoubleClickEvent<TRow>,
    eventHandler?: DoubleClickHandler<TRow>
) {
    if (eventHandler) eventHandler(e);
}

export function makeRowRenderer<TRow>(doubleClickHandler?: DoubleClickHandler<TRow>) {
    return (props: RowRendererProps<TRow>) => CustomRowRendererNoContext(props, doubleClickHandler);
}

export function makeSingleClickEventOnMobile<TRow>(selectHandler?: ClickHandler<TRow>) {
    if (isMobile) {
        return (rowIdx: number, row: TRow, column: CalculatedColumn<TRow, unknown>) => {
            if (selectHandler) {
                selectHandler({ index: rowIdx, row });
            }
        };
    } else {
        return () => {};
    }
}

interface GetEnumValuesArgs<T extends StandardEnum<T>> {
    omit?: T[keyof T][];
}

export function getEnumValues<T extends StandardEnum<T>>(
    enumType: T | T[keyof T][],
    args?: GetEnumValuesArgs<T>
) {
    let values: T[keyof T][];

    if (enumType as T) {
        let t = enumType as T;
        values = getEnumKeys(t).map((x) => t[x]);
    } else if (enumType as T[keyof T][]) {
        values = enumType as T[keyof T][];
    } else {
        throw new Error("enumType is not a valid type for getEnumValues");
    }

    if (args?.omit) {
        const omitSet = new Set(args.omit);
        return values.filter((x) => !omitSet.has(x));
    }
    return values;
}

export function getEnumKeys<T extends object>(enumType: T): (keyof T)[] {
    //Option 1
    //return Object.keys(enumType) as (keyof T)[];

    //Option 2
    //const keys = Object.keys(enumType);
    return (Object.keys(enumType) as (keyof T)[]).filter((k) => k !== undefined && k !== null);
}

interface RowRendererOptions<TRow> {
    contextMenuID?: string;
    doubleClickHandler?: DoubleClickHandler<TRow>;
    onMouseEnter?: (row: TRow) => void;
    onMouseLeave?: (row: TRow) => void;
}

export function useRowRendererWithMenu<TRow>({
    contextMenuID,
    doubleClickHandler,
    onMouseEnter,
    onMouseLeave,
}: RowRendererOptions<TRow>) {
    const { show } = useContextMenu({ id: contextMenuID });
    return (props: RowRendererProps<TRow>) => (
        <RowRenderer<TRow, unknown>
            {...props}
            onMouseEnter={() => onMouseEnter?.(props.row)}
            onMouseLeave={() => onMouseLeave?.(props.row)}
            onDoubleClick={(y) =>
                doubleClickHelper(
                    { reactEvent: y, index: props.rowIdx, row: props.row },
                    doubleClickHandler
                )
            }
            onContextMenu={(x) => {
                if (contextMenuID) show(x, { props: props.row });
            }}
        />
    );
}

function CustomRowRendererNoContext<TRow>(
    props: RowRendererProps<TRow>,
    eventHandler?: DoubleClickHandler<TRow>
) {
    return (
        <RowRenderer<TRow, unknown>
            {...props}
            onDoubleClick={(y) => {
                doubleClickHelper(
                    { reactEvent: y, index: props.rowIdx, row: props.row },
                    eventHandler
                );
            }}
        />
    );
}

export type TabAutoBind<T> = [
    T,
    React.Dispatch<React.SetStateAction<T>>,
    (value: T) => { onClick: (e: any) => void }
];

export function useAutoBindTabs<T>(initial: T) {
    const [state, setState] = useState<T>(initial);

    const bind = function (value: T) {
        const onClick = function (e: any) {
            setState(value);
        };
        return { onClick };
    };

    return [state, setState, bind] as TabAutoBind<T>;
}

export function generateLoadServerRowsEffect(
    getDummyInventory: GetDummyInventoryQueryResult,
    searchString: string,
    sortColumns: SortColumn[],
    andedFilter?: InventoryEntryFilterInput
): Promise<InventoryEntry[]> {
    return new Promise<InventoryEntry[]>((resolve) => {
        const search = searchString;
        const searchTerms = search
            .toLowerCase()
            .replace(/[^a-z0-9]/gim, " ")
            .replace(/\s+/g, " ")
            .split(" ");

        let filterPart: InventoryEntryFilterInput[] = searchTerms.map((x) => {
            return {
                or: [
                    { sku: { contains: x } },
                    { searchableProductType: { contains: x } },
                    { style: { contains: x } },
                    { color: { contains: x } },
                    { manufacturer: { contains: x } },
                ],
            };
        });

        let sortInput: null | { [key: string]: string } = null;
        if (sortColumns.length > 0) {
            let sortColumn = sortColumns[0];
            let key = sortColumn.columnKey;
            sortInput = {};
            let direction = sortColumn.direction;
            sortInput[key] = direction;
        }

        const additionalAnded: InventoryEntryFilterInput[] = andedFilter ? [andedFilter] : [];

        const promise = getDummyInventory.refetch({
            filter: {
                and: [...filterPart, ...additionalAnded],
            },
            order: sortInput,
        });
        if (!promise) resolve([]); //Apollo refetch does not like being called from react hook. Will return undefined on refresh (sometimes)
        promise
            .then((x) => resolve(x.data.dummyInventory as InventoryEntry[]))
            .catch((err) => resolve([]));
    });
}

export function compareSeparatedNumbers(a: string, b: string, separator: string) {
    const arr1 = a.split(separator);
    const arr2 = b.split(separator);

    for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
        if (i >= arr1.length) {
            return -1;
        } else if (i >= arr2.length) {
            return 1;
        }
        let val1 = parseInt(arr1[i]);
        let val2 = parseInt(arr2[i]);
        if (val1 !== val2) {
            let output = val1 - val2;
            if (isNaN(output)) {
                output = 0;
            }
            return output;
        }
    }

    return 0;
}

export function getFromArrOrDefault<T>(arr: T[], index: number, defaultValue: T) {
    if (index > 0 && index < arr.length) {
        return arr[index];
    } else {
        return defaultValue;
    }
}

/* 
Clones an object, and then deletes the specified keys. 
Useful for deconstructing objects into omit types.
*/
export function cloneDelete<T>(obj: T, ...keys: (keyof T)[]) {
    let clone = { ...obj };
    for (let key of keys) {
        delete clone[key];
    }
    return clone;
}

// Returns a new object which is the same as the original except that it only contains the live keys.
export function collectDeadKeys<T, U>(map: Map<T, U>, liveKeys: T[]) {
    const output = new Map<T, U>();

    for (const key of liveKeys) {
        if (map.has(key)) {
            output.set(key, map.get(key)!);
        }
    }

    return output;
}

export function jsxJoin(
    elements: JSX.Element[],
    separator: Element | ((key: string) => JSX.Element)
) {
    return elements.reduce<JSX.Element[] | null>((output, elem) => {
        let safeSeparator: JSX.Element;
        if (typeof separator === "function") {
            safeSeparator = separator(elem.key + "-separator");
        } else {
            safeSeparator = <div key={elem.key + "-separator"}>{separator}</div>;
        }
        return output === null ? [elem] : [...output, safeSeparator, elem];
    }, null);
}

export function createMaterialUIBindFunction<T>(value: T, onChange?: (newValue: T) => void) {
    return {
        bindInput(key: keyof T, defaultValue: any) {
            return {
                value: value[key] ?? defaultValue,
                onChange: (e: React.ChangeEvent<{ value: string }>) => {
                    onChange?.({ ...value, [key]: e.target.value });
                },
            };
        },
    };
}

export function interfaceHasAllKeys<T>(value: T, ...keys: (keyof T)[]) {
    for (let key of keys) {
        if (value[key] === undefined) return false;
    }
    return true;
}

export function interfaceHasSomeKey<T>(value: T, ...keys: (keyof T)[]) {
    for (let key of keys) {
        if (value[key] !== undefined) return true;
    }
    return false;
}

export function createObjectFromKeyArray<T extends keyof V, U, V extends { [K in T]: U }>(
    keys: readonly T[],
    value: U | ((key: T) => U)
): V {
    let funcValue: (key: T) => U;
    if (typeof value === "function") {
        // let u = value as U;
        // funcValue = () => u;
        funcValue = value as (key: T) => U;
    } else if (typeof value !== "function") {
        const u = value as U;
        funcValue = () => u;
    }
    return keys.reduce<Partial<V>>(
        (previous, current) => ({ ...previous, [current]: funcValue(current) }),
        {}
    ) as V;
}

// Derefs an arr. Returns undefined if the index is out of bounds.
export function safeArrDeref<T>(arr: T[] | undefined | null, index: number) {
    if (arr) {
        if (arr.length > index) {
            return arr[index];
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}

export function updateOrPush<T>(arr: T[], index: number, value: T) {
    if (arr.length > index) {
        arr[index] = value;
    } else {
        arr.push(value);
    }
}

export function updatePushOrRemove<T>(arr: T[], index: number, value: T) {
    if (value === undefined) {
        arr.splice(index, 1);
    } else if (arr.length > index) {
        arr[index] = value;
    } else {
        arr.push(value);
    }
}

export function humanizeEnum(value: string) {
    return transform(transform(humanize(value), toLowerCase), toTitleCase);
}

export function mapStringArrToMenuItems<T extends string>(
    options: readonly T[],
    dontHumanize?: boolean,
    map?: Map<string, string>
) {
    const conditionalHumanize: (x: string) => string = dontHumanize ? (x) => x : humanizeEnum;
    function render(value: string) {
        return map?.get(value) ?? conditionalHumanize(value);
    }
    return options.map((x) => (
        <MenuItem
            key={x}
            value={x}
        >
            {render(x)}
        </MenuItem>
    ));
}

export function stringIsBlank(str: string | undefined) {
    return !str || /^\s*$/.test(str);
}

export function sumArray(arr: number[]) {
    return arr.reduce((sum, nextVal) => sum + nextVal, 0);
}

export function zipArray<T>(arr1: T[], arr2: T[]) {
    return Array.from(Array(Math.max(arr1.length, arr2.length)), (_, i) => [arr1[i], arr2[i]]);
}

interface MapEnumToMenuItemsProps<T extends StandardEnum<T>> {
    dontHumanize?: boolean;
    map?: Map<string, string>;
}

export function mapEnumToMenuItems<T extends StandardEnum<T>>(
    options: T | T[keyof T][],
    args?: MapEnumToMenuItemsProps<T>
) {
    const { dontHumanize, map } = args ?? {};

    const strings = getEnumValues(options as T).map((x) => x as unknown as string);

    return mapStringArrToMenuItems(strings, dontHumanize, map);
}

export type EqualityComparison<T> = {
    [K in keyof T]?: boolean;
};

// export function compareFieldEquality<T>(a: T, b: T, comparator?: (a: any, b: any) => boolean): EqualityComparison<T> {

//     if (!comparator) {
//         comparator = (a, b) => a === b;
//     }

//     let output: EqualityComparison<T> = {};

//     for (let [key, value] of Object.entries(a)) {

//         const typedKey = key as keyof T;

//         if (comparator(value, b[typedKey])) {
//             output[typedKey] = true;
//         }
//     }

//     return output;
// }

type EqualityFunc<T> = (id: T, option: T) => boolean;
export function boundElementToValues<T>(
    id: T,
    options: T[],
    defaultIndex?: number,
    isEqual?: EqualityFunc<T>
): T {
    const equalityCheck: EqualityFunc<T> = isEqual ?? ((i, o) => i === o);

    for (var i = 0; i < options.length; i++) {
        if (equalityCheck(id, options[i])) return id;
    }

    return options[defaultIndex ?? 0];
}

// Returns the lowest whole number not in the array provided
export function findLowestWholeNumberNotInArray(array: number[]): number {
    const sorted = [...array].sort();

    var previous = -1;
    sorted.every((num) => {
        if (num <= previous + 1) {
            previous = num;
            return true;
        } else return false;
    });

    return previous + 1;
}

/**
 * Given a list and a value, this function returns a new list which has toggled the presence of
 * the item in the list.
 *
 * T must be a directly comparable type.
 *
 * Examples:
 *      toggleValueInList([1, 2, 3, 4, 5], 3) => [1, 2, 4, 5]
 *      toggleValueInList([1, 2, 3, 4, 5], 0) => [1, 2, 4, 5, 0]
 * @param list The list to be updated
 * @param targetValue The value to be toggled
 * @returns A copy of the original list with the passed value toggled
 */
export function toggleValueInList<T extends Primitive>(list: T[], targetValue: T) {
    let updatedList: typeof list = [];

    if (list.includes(targetValue)) {
        // remove the item from the list
        updatedList = list.filter((item) => item !== targetValue);
    } else {
        // add item to the list
        updatedList = [...list, targetValue];
    }

    return updatedList;
}

export function padArray<T>(array: T[], targetLength: number, fillerValue: T | (() => T)): T[] {
    const fillAction =
        typeof fillerValue === "function" ? (fillerValue as () => T) : () => fillerValue as T;
    return Array.from({ ...array, length: targetLength }, (v) => v ?? fillAction());
}

export function numericArraysEq(a1: number[], a2: number[]) {
    if (a1.length === a2.length) {
        a1.sort();
        a2.sort();

        for (var i = 0; i < a1.length; i++) {
            if (a1[i] !== a2[i]) return false;
        }

        return true;
    } else {
        return false;
    }
}

export const getUnique =
    (arr: number[]) => [...new Set(arr)]

export function* enumerate(arr: any[], start: number = 0) {
    let idx = start;
    for (const el of arr) yield [el, idx++];
}

export function getNextMostNegativeNumber(nums: number[]) {
    return Math.min(...nums, 0) - 1;
}

export function printPdfUsingBrowser(pdfBase64: string) {
    if (pdfBase64 === "") return;

    // // Create a Blob object from the base 64 string
    // const pdfBlob = new Blob([atob(pdfBase64)], { type: "application/pdf" });

    // // Create an object URL from the Blob object
    // const objectUrl = URL.createObjectURL(pdfBlob);

    // console.log("OBJ URL:" + objectUrl);

    // Create an iframe element
    const iframe = document.createElement("iframe") as HTMLIFrameElement;
    
    // Set the iframe's source to the object URL
    iframe.src = pdfBase64;
    iframe.title = "test.pdf";
    // Hide the iframe
    iframe.style.display = "none";

    // Append the iframe to the document
    document.body.appendChild(iframe);

    // iframe.addEventListener("load", () => {
    // Use the iframe's contentWindow to call the browser's print function
    if (iframe.contentWindow === null)
        throw new Error("The content window was not able to be created");
    iframe.contentWindow.focus();
    // (iframe.contentWindow.__proto__ = null;
    iframe.contentWindow.print();

    // // Clean up
    // setTimeout(() => {
    //     // Remove the iframe from the document
    //     document.body.removeChild(iframe);

    //     // Revoke the object URL
    //     // URL.revokeObjectURL(objectUrl);
    // }, 10000);
}
