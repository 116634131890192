import { Button, Dialog } from "@material-ui/core";
import FlatCancelButton from "FlatComponents/Button/FlatCancelButton";
import FlatCheckButton from "FlatComponents/Button/FlatCheckButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import FlatCheckbox from "FlatComponents/Inputs/FlatCheckbox";
import FlatInput from "FlatComponents/Inputs/FlatInput";
import { namedOperations, useEmailContractMutation } from "generated/graphql";
import { allTrue, isEmail, isNotEmptyString, isNotNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import "./email_contract_dialog.css";

interface EmailContractDialogProps {
    contractId: number;
    defaultIncludeBaseEmail: boolean;
    baseEmail: string;
    closeDialog: () => void;
    refetchQuery?: string; // an additional query to refetch if needed
}

export default function EmailContractDialog({ contractId, defaultIncludeBaseEmail, baseEmail: originalBaseEmail, closeDialog, refetchQuery: additionalRefetchQuery }: EmailContractDialogProps) {
    const [additionalEmails, setAdditionalEmails] = useState<string[]>([]);
    const [showErrors, setShowErrors] = useState(false);
    const [includeBaseEmail, setIncludeBaseEmail] = useState(defaultIncludeBaseEmail);
    const [baseEmail, setBaseEmail] = useState(originalBaseEmail);
    const [editingBase, setEditingBase] = useState(false);

    const [emailContract, {loading: sending}] = useEmailContractMutation({
        onError: () => alert("Failed to email contract"),
        onCompleted: (res) => {
            if (res.emailContract) {
                closeDialog();
            } else {
                alert("Failed to email contract");
            }
        },
        // in case primary email is updated
        refetchQueries: [namedOperations.Query.GetJobInvoiceData, additionalRefetchQuery].filter(q => isNotNullOrUndefined(q)) as string[]
    });

    function onSend() {
        const emails = ((includeBaseEmail) ? [baseEmail, ...additionalEmails] : additionalEmails).filter(e => isNotEmptyString(e));
        if (emails.length === 0) {
            alert('Enter at least one email address');
            return;
        } else if (allTrue(emails.map(isEmail))) {
            emailContract({
                variables: {
                    contractId: contractId,
                    emailAddresses: emails,
                    updatePrimaryEmail: (baseEmail !== originalBaseEmail)
                }
            });
        } else {
            alert("Could not submit - all email addresses must be valid");
            setShowErrors(true);
        }
    }

    function onEditBaseEmail(newBase: string) {
        if (newBase !== originalBaseEmail) {
            // email needs to be sent to the updated base email
            setIncludeBaseEmail(true);
        }
        setBaseEmail(newBase);
    }

    function onCancelEdits() {
        setEditingBase(false);
        setBaseEmail(originalBaseEmail);
    }

    return (
        <Dialog open={true} onClose={closeDialog} maxWidth="md">
            <div className="flex-column" style={{ width: "min(30rem, 100vw)", margin: "1rem" }}>
                <h3>Email Contract</h3>
                <div id="email-list-container">
                    <FlatInput
                        value={baseEmail}
                        onChange={(newBase) => onEditBaseEmail(newBase.target.value)}
                        // use readonly when list of recipients includes this email so it appears active (but still can't be edited)
                        readOnly={!editingBase && includeBaseEmail}
                        disabled={!editingBase && !includeBaseEmail}
                        className="flex-grow"
                        error={showErrors && !isEmail(baseEmail)}
                    />

                    <span className="email-actions">
                        {editingBase ? (<>
                            <FlatCheckButton onClick={() => setEditingBase(false)}/>
                            <FlatCancelButton onClick={onCancelEdits}/>
                        </>) : (<>
                            <FlatCheckbox
                                checked={includeBaseEmail}
                                onClick={() => setIncludeBaseEmail(!includeBaseEmail)}
                                disabled={sending || (baseEmail !== originalBaseEmail)}
                                style={{padding: 0}}
                            />
                            <FlatEditButton onClick={() => setEditingBase(true)}/>
                        </>)}
                        
                    </span>
                    
                    {additionalEmails.map((email, index) => (<>
                        <FlatInput
                            key={`additional-email-input-${index}`}
                            error={showErrors && !isEmail(email)}
                            autoFocus
                            className="flex-grow"
                            value={email}
                            onChange={(e) => {
                                var copy = [...additionalEmails]
                                copy[index] = e.target.value
                                setAdditionalEmails(copy)
                            }} 
                            disabled={sending}
                        />

                        <span className="email-options">
                            <FlatDeleteButton
                                key={`additional-email-delete-${index}`}
                                onClick={() => setAdditionalEmails(additionalEmails.filter((_, ind) => ind !== index))}
                                style={{marginLeft: "-2px"}}
                                disableFocusRipple
                                disabled={sending}
                            />
                        </span>
                    </>))}

                    <Button
                        variant="outlined"
                        onClick={() => setAdditionalEmails([...additionalEmails, ""])}
                        disabled={sending}
                        id="add-email-button"
                    >+</Button>
                </div>

                <div className="fill-width flex-row" style={{ justifyContent: "space-between", marginTop: ".5rem" }}>
                    <Button variant="outlined" color="secondary" onClick={closeDialog} disabled={sending}>Back</Button>
                    <Button variant="contained" color="primary" onClick={onSend} disabled={sending || editingBase}>Send Contract</Button>
                </div>
            </div>
        </Dialog>
    )
}