import { Input, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ServiceForRoom, useGetServiceTypeOptionsQuery, useGetSubstrateIdQuery } from "generated/graphql";
import { isNotEmptyString, isNullOrUndefined } from "Globals/GenericValidators";
import { CARPET_PRODUCT_ID, FURNITURE_ID, FURNITURE_NONE_ID, FURNITURE_REGULAR_ID, INSTALLATION_ID, INSTALLATION_UPCHARGE_ID } from "Globals/globalConstants";
import _ from "lodash";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { ContractArea, ContractRoom, DisplayServiceFormatter, generalServiceFormatter, installationServiceFormatter } from "./invoicePageHelpers";

interface AreaInvoiceRowProps {
    area: ContractArea;
    notes: string;
    onChangeNotes: (value: string) => void;
    printMode: boolean;
}

export function AreaInvoiceRow({ area, notes, onChangeNotes, printMode }: AreaInvoiceRowProps) {
    const { data } = useGetServiceTypeOptionsQuery({
        variables: { productTypeId: area.productTypeId },
    });
    const { data: subTypes } = useGetSubstrateIdQuery();

    //Uses loading service names and maps service to string using provided formatting function
    function getServiceName(
        service: ContractRoom["services"][number],
        formatService: DisplayServiceFormatter,
        postfix?: string
    ): { cust: boolean; disp: string } {
        const foundType = data?.serviceTypeOptions.find(
            (type) => type.serviceTypeId === service.serviceTypeId
        );

        const displayType =
            (foundType?.displayServiceTypeId ?? undefined) === undefined
                ? foundType
                : data?.serviceTypeOptions.find(
                      (type) => type.serviceTypeId === foundType?.displayServiceTypeId
                  );

        const foundJobService = foundType?.jobServices.find(
            (js) => js.jobServiceId === service.jobServiceId
        );

        const useMaterial = foundJobService?.materialOptions.find(
            (mat) => mat.materialCategoryId === service.materialCategoryId
        );

        return {
            cust: service.customerDoesService,
            disp: `${formatService(getNameOfArea(area.rooms.find(r => r.id === service.roomId)!.labels), service, displayType, foundJobService, useMaterial)}${
                postfix ?? ""
            }`,
        };
    }

    function groupFurnitureServices(services: ContractRoom["services"]): { custDoes: boolean; jobServiceId: number; roomIds: number[]; }[] {
        const groups: { custDoes: boolean; jobServiceId: number; roomIds: number[]; }[] = [];
        services.forEach(s => {
            const groupIdx = groups.findIndex(g => (g.custDoes === s.customerDoesService) && (g.jobServiceId === s.jobServiceId));
            if (groupIdx === -1) {
                // group doesn't exist
                groups.push({
                    custDoes: s.customerDoesService,
                    jobServiceId: s.jobServiceId,
                    roomIds: [s.roomId]
                });
            } else {
                // group exists, add this room ID to it
                groups[groupIdx] = {
                    ...groups[groupIdx],
                    roomIds: [...groups[groupIdx].roomIds, s.roomId]
                };
            }
        });

        return groups;
    }

    function getGroupedServiceName(group: { custDoes: boolean; jobServiceId: number; roomIds: number[]; }): { cust: boolean; disp: string; } {
        const roomLabels = getNameOfArea(area.rooms.filter(r => group.roomIds.includes(r.id)).flatMap(r => r.labels));
        if (group.jobServiceId === FURNITURE_REGULAR_ID) {
            const dispStr = `${group.custDoes ? "Cust." : "WOF"} to move Furniture: Regular - ${roomLabels}`;
            return { cust: group.custDoes, disp: dispStr };
        } else {
            if (group.jobServiceId === FURNITURE_NONE_ID) {
                return { cust: group.custDoes, disp: `No Furniture: ${roomLabels}` };
            } else { // group.jobServiceId === FURNITURE_LARGE_ID
                const dispStr = `${group.custDoes ? "Cust." : "WOF"} to move Large/Extra Furniture: ${roomLabels}`;
                return { cust: group.custDoes, disp: dispStr };
            }
        }
    }

    function getFurnitureServiceNames(services: ContractRoom["services"]): { cust: boolean; disp: string }[] {
        const groupedServices: { custDoes: boolean; jobServiceId: number; roomIds: number[]; }[] = groupFurnitureServices(services);
        return groupedServices.map(gs => getGroupedServiceName(gs));
    }

    //Uses loading substrate types and maps id to it's corrisponding name
    function getSubstrateName(substrateId: number) {
        const foundSubstrate = subTypes?.substrateId.find((sub) => sub.id === substrateId);
        return foundSubstrate?.type;
    }

    const showSpindleMessage =
        area.productTypeId !== CARPET_PRODUCT_ID &&
        area.rooms.some((room) => room.steps.some((step) => step.shouldPromptAboutSpindles));

    //Converts list of substrates to raw string form
    const uniqueSubstrates = area.rooms
        .map((r) => r.substrateId)
        .filter((val, ind, arr) => arr.indexOf(val) === ind)
        .sort();
    const displaySubstrates =
        (subTypes?.substrateId ?? undefined) === undefined
            ? []
            : uniqueSubstrates.map(getSubstrateName);

    //TODO: change when service type ids change
    //Splits out the installation services, installation upcharges from the services, and furniture
    const [installServices, allServicesButInstall] = _.partition(
        reduceServices(area.rooms.flatMap((room) => room.services)),
        (ser) => ser.serviceTypeId === INSTALLATION_ID
    );
    const [installUpcharge, remainingServicesWithFurniture] = _.partition(
        allServicesButInstall,
        (ser) => ser.serviceTypeId === INSTALLATION_UPCHARGE_ID
    );

    // furniture services are treated differently than the other services for string formatting
    let [furnitureServices, remainingServices] = _.partition(
        remainingServicesWithFurniture,
        (ser) => ser.serviceTypeId === FURNITURE_ID
    );
    // list all similar services near one another
    remainingServices.sort((s1, s2) => s1.serviceTypeId - s2.serviceTypeId);

    //Converts list of service objects to raw strings for display
    let displayServices =
        ((data?.serviceTypeOptions ?? undefined) === undefined) || (remainingServices.length === 0)
            ? [{ cust: false, disp: "None" }]
            : remainingServices.map((ser) => getServiceName(ser, generalServiceFormatter)).filter(name => isNotEmptyString(name.disp));

    // converts list of furniture services to raw strings for display
    const displayFurnitureServices = 
        isNullOrUndefined(data?.serviceTypeOptions ?? undefined) 
            ? [{ cust: false, disp: "None" }]
            : getFurnitureServiceNames(furnitureServices)

    const displayInstallServices = installServices.map((ser) =>
        getServiceName(ser, installationServiceFormatter, ` over [${displaySubstrates.join(",")}]`)
    );
    const displayInstallUpchargeServices = installUpcharge.map((ser) =>
        getServiceName(ser, installationServiceFormatter)
    );

    // custom services
    const displayCustomServices: {cust: boolean; disp: string;}[] = area.customServices.map(s => ({
        cust: false, disp: `WOF to ${s.description}`
    }));

    //Boolean used to decide if services should be rendered as two columns
    const allDisplayedServices = [...displayServices, ...displayFurnitureServices, ...displayCustomServices];
    const split = allDisplayedServices.length > 100;

    function getDisplayServices() {
        const halfIndex = Math.ceil(allDisplayedServices.length / 2);

        if (split) {
            return [allDisplayedServices.slice(0, halfIndex), allDisplayedServices.slice(halfIndex)];
        } else {
            return [allDisplayedServices, []];
        }
    }

    const [dispFirst, dispLast] = getDisplayServices();

    const labels = area.rooms.flatMap((room) => room.labels);

    return (
        <>
            <tr className="h-5r">
                <td className="solid-border fill-height vertical-align-top padding-side-xsm" colSpan={2}>
                    <div className="flex-row">
                        <div className="flex-column half-width" style={{borderRight: "1px solid black"}}>
                            <Typography
                                variant="body1"
                            >
                                <b>Product: </b>
                                {area.productTypeName}
                            </Typography>
                            <Typography
                                variant="body1"
                            >
                                <b>Style: </b>
                                {area.styleName}
                            </Typography>
                            <Typography
                                variant="body1"
                            >
                                <b>Color: </b>
                                {area.colorName}
                            </Typography>
                            <Typography
                                variant="body1"
                            >
                                <b>Area: </b>
                                {getNameOfArea(labels, ", ")}
                            </Typography>
                            <Typography
                                variant="body1"
                            >
                                <b>Installation: </b>
                                {displayInstallServices.map((s) => s.disp).join(", ")}
                            </Typography>
                            {displayInstallUpchargeServices.length > 0 && (
                                <Typography
                                    variant="body1"
                                >
                                    <b>Additional: </b>
                                    {displayInstallUpchargeServices.map((s) => s.disp).join(", ")}
                                </Typography>
                            )}
                        </div>

                        <div className="flex-column">
                            <Typography
                                variant="body1"
                                className="text-align-center"
                            >
                                <b>Services</b>
                            </Typography>
                            <div className="flex-row">
                                <div className="flex-column padding-side-xsm">
                                    {dispFirst.map((ser, ind) => renderServiceOption(ser, ind, 1))}
                                </div>
                                {split && (
                                    <div className="flex-column padding-side-xsm">
                                        {dispLast.map((ser, ind) => renderServiceOption(ser, ind, 2))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr className="h-2r">
                <td
                    className="solid-border"
                    colSpan={2}
                >
                    <div
                        className="flex-column"
                        style={{ alignItems: "flex-start", paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
                    >
                        <b>Notes:</b>
                        <span
                            hidden={!showSpindleMessage}
                            style={{ color: "red" }}
                        >
                            Customer must remove any spindles on stairs before installation.
                        </span>
                        <Input
                            style={{ flex: 1, width: "100%" }}
                            value={notes}
                            onChange={(e) => onChangeNotes(e.currentTarget.value)}
                            multiline
                            disableUnderline={printMode}
                        />
                    </div>
                </td>
            </tr>
        </>
    );
}

function renderServiceOption(ser: { cust: boolean; disp: string }, index: number, colNum: number) {
    return (
        <Typography
            key={`invoice-ser-${colNum}-${index}`}
            className={clsx({ "underline-text": ser.cust }, "whitespace-no-wrap")}
        >
            -{ser.disp}
        </Typography>
    );
}

type PartialServiceForRoom = Omit<ServiceForRoom, "__typename">;

/**
 * Collapses service list so that each service is only displayed once. The only exception to this
 * is that all furniture services are kept, since more processing is done further down the line
 * on these in order to prepare them for display.
 */
function reduceServices(services: PartialServiceForRoom[]) {
    return services.reduce<PartialServiceForRoom[]>((output, service) => {
        const matchIndex = output.findIndex(
            (ser) => (service.serviceTypeId !== FURNITURE_ID) &&  // need ALL of the furniture services, unreduced
                     (ser.jobServiceId === service.jobServiceId) &&
                     (ser.isActive === service.isActive)
        );

        if (matchIndex !== -1) {
            const copy: PartialServiceForRoom[] = [...output];
            const value = copy[matchIndex];
            copy[matchIndex] = { ...value, laborAmount: value.laborAmount + service.laborAmount };
            return copy;
        } else return [...output, service];
    }, []);
}