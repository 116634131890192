import { Button, CircularProgress, IconButton, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Checkbox } from "@mui/material";
import { Discount, namedOperations, useDeleteDiscountMutation, useGetAllDiscountsQuery, useUpdateDiscountVisibilityMutation } from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { OVERRIDE_DISCOUNT_ID } from "Globals/globalConstants";
import { useState } from "react";
import DiscountEditorDialog, { discountToEditable, newDiscount } from "./DiscountEditorDialog";

export default function DiscountTable() {
    const [discountDialogOpen, setDiscountDialogOpen] = useState(false);

    const {data} = useGetAllDiscountsQuery({
        onError: () => alert("Could not retrieve discount information")
    });
    const discounts = data?.allDiscounts.filter(d => d.id !== OVERRIDE_DISCOUNT_ID) ?? [];

    return (
        <div className="flex-column align-items-center" >
            <Typography className="align-self-flex-start" variant="h5">Discounts</Typography>
            <table>
                <thead>
                    <tr style={{ backgroundColor: "lightgrey", height: "3rem" }}>
                        <td>Actions</td>
                        <td align="left" className="whitespace-no-wrap padding-right-sm">Name</td>
                        <td align="left" className="whitespace-no-wrap padding-right-sm">Amount</td>
                        <td align="left" className="whitespace-no-wrap padding-right-sm">Minimum MSRP</td>
                        <td align="left" className="whitespace-no-wrap padding-right-sm">Visibility</td>
                    </tr>
                </thead>

                <tbody>
                    {discounts.map(d => <DiscountTableRow key={d.id} discount={d} />)}
                </tbody>
            </table>

            <Button className="margin-top-sm" variant="contained" onClick={() => setDiscountDialogOpen(true)}>Add Discount</Button>

            {discountDialogOpen && <DiscountEditorDialog onClose={() => setDiscountDialogOpen(false)} discount={{...newDiscount}} />}
        </div>
    )
}

function DiscountTableRow({discount}: {discount: Discount}) {
    const amountStr = discount.isScalar ? `${((1 - discount.defaultValue) * 100).toFixed(0)}% off` : `$${discount.defaultValue} off`;
    const minMsrp = isNullOrUndefined(discount.minimumMSRP) ? "None" : `$${discount.minimumMSRP!.toFixed(0)}`;
    const [editorOpen, setEditorOpen] = useState(false);

    const [deleteDiscount, {loading: deleting}] = useDeleteDiscountMutation({
        onError: () => alert("Could not delete discount"),
        refetchQueries: [namedOperations.Query.GetAllDiscounts, namedOperations.Query.GetAllMarkets]
    });
    function onDeleteDiscount() {
        const warningMsg = 'WARNING: Removing this discount will cause the MSRP scalar to be recalculated for each market. This could affect any jobs which are currently in the process of being sold. Are you sure you want to continue?';
        if (window.confirm(warningMsg)) {
            deleteDiscount({variables: {discountId: discount.id}});
        }
    }
    const [toggleVisibility, {loading: togglingVisibility}] = useUpdateDiscountVisibilityMutation({
        onError: () => alert("Could not update discount visbility"),
        refetchQueries: [namedOperations.Query.GetAllDiscounts, namedOperations.Query.GetAllMarkets]
    });
    const onToggleVisibility = () => {
        const warningMsg = 'WARNING: Toggling discount visibility will cause the MSRP scalar to be recalculated for each market. This could affect any jobs which are currently in the process of being sold. Are you sure you want to continue?';
        if (window.confirm(warningMsg)) {
            toggleVisibility({
                variables: {discountId: discount.id, isHidden: !discount.isHidden}
            });
        }
    }

    return (<>
        <tr>
            <td align="left" className="padding-right-xmd">
                <div className="flex-row">
                    {deleting ? (
                        <CircularProgress style={{marginRight: "0.5rem"}} />
                    ) : (
                        <Tooltip title="Delete discount">
                            <IconButton
                                className="padding-vertical-none"
                                onClick={onDeleteDiscount}
                            ><DeleteIcon /></IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="Edit discount">
                        <IconButton
                            className="padding-vertical-none"
                            disabled={deleting}
                            onClick={() => setEditorOpen(true)}
                        ><EditIcon /></IconButton>
                    </Tooltip>
                </div>
            </td>
            <td align="left" className="padding-right-xmd"><Typography>{discount.name}</Typography></td>
            <td align="left" className="padding-right-xmd"><Typography>{amountStr}</Typography></td>
            <td align="left" className="padding-right-xmd"><Typography>{minMsrp}</Typography></td>
            <td align="left" className="padding-right-xmd">
                <span className="flex-row">
                    <Typography>Visible: </Typography>
                    {togglingVisibility ? (
                        <CircularProgress size="small" />
                    ) : (
                        <Checkbox className="margin-left-xsm" checked={!discount.isHidden} onClick={onToggleVisibility} />
                    )}
                </span>
            </td>
        </tr>

        {editorOpen && 
            <DiscountEditorDialog
                onClose={() => setEditorOpen(false)} discount={discountToEditable(discount)}
            />
        }
    </>)
}