import { IconButton } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import { LineItem } from 'Globals/DataStructures/Transactions';
import React from 'react';
import ReactDataGrid, { Column } from 'react-data-grid';
import { useAppDispatch, useAppSelector } from 'Redux/hooks';
import { removeTransaction, selectTransactionEntries } from 'Redux/inventoryReducer';
import { DoubleClickHandler, useRowRendererWithMenu } from 'Globals/Helpers';
import { InventoryEntry } from 'generated/graphql';

export interface ColumnEntry extends Column<Row> {
  key: keyof Row | 'mUPerInvUnit' | "close"
}

export type TransactionViewRowInput = {
  rollNumber? : string
} & InventoryEntry

export interface TransactionViewRow extends TransactionViewRowInput {
  id: string,
  lineItem: LineItem
  description: string
}

type Row = TransactionViewRow;

interface Props{
  onDoubleClickRow?: DoubleClickHandler<Row>
}

export default function TransactionViewer({ onDoubleClickRow } : Props) {
  const dispatch = useAppDispatch();
  const rowData = useAppSelector(selectTransactionEntries)

  const rowRenderer = useRowRendererWithMenu<Row>({ doubleClickHandler: onDoubleClickRow })

  const [columns] = React.useState<ColumnEntry[]>([
    {
      key: 'sku', name: 'SKU', width: 150,
      formatter(props) {
        return (<div>
          <IconButton style={{ marginBottom: "6px" }} size="small" onClick={() => dispatch(removeTransaction({ id: props.rowIdx }))}>
            <DeleteForever />
          </IconButton>
          {props.row.sku} {props.row.rollNumber && '#' + props.row.rollNumber}
        </div>);
      },
    },
    { key: 'productType', name: 'Type', width: 150 },
    { key: 'style', name: 'Style', width: 150 },
    { key: 'color', name: 'Color', width: 250 },
    { key: 'description', name: 'Change' },
  ]);

  return <ReactDataGrid<Row>
    style={{ flex: 1 }}
    rowRenderer={rowRenderer}
    rows={rowData}
    columns={columns}
    className='rdg-light'
    />
}