import { HBar } from "Components/pageSpacing"
import { JobServiceOption, ServiceTypeOption } from "generated/graphql"
import { PropsWithChildren } from "react"
import { selectServicesForRoomDialogSqft } from "Redux/genericSalesReducer"
import { store } from "Redux/store"
import AddServiceForRoomRow from "./AddServiceForRoomRow"
import JobServiceEditorRow from "./JobServiceEditorRow"


export interface ServiceIdValue {
    jobServiceId: number,
    value: string,
    custDoesService: boolean,
    isActive: boolean,
    sourceServiceForRoomId: number,
    materialCategoryId?: number,
    materialAmount?: string
}

interface ServiceCategoryEditorProps {
    showError?: boolean,
    options: ServiceTypeOption,
    values: ServiceIdValue[],
    setValues: (newValues: ServiceIdValue[]) => void
}

export default function ServicesByTypeEditor({ showError, options, values, setValues }: ServiceCategoryEditorProps) {

    function updateValue(index: number, newValue?: string, custDoes?: boolean, material?: { newMatId?: number, newMatAmount?: string }) {
        const copy = [...values]
        copy[index].value = newValue ?? copy[index].value
        copy[index].custDoesService = custDoes ?? copy[index].custDoesService
        if (material !== undefined) {
            copy[index].materialAmount = material.newMatAmount
            copy[index].materialCategoryId = material.newMatId
        }

        return copy
    }

    function removeValue(index: number) {
        return values.filter((v, i) => i !== index)
    }

    const serviceLabel = options.name

    return (
        <div className="fill-width flex-column" style={{ minHeight: "5rem" }}>
            <div className="fill-width flex-row">
                <ServiceTypeLabel>{`${serviceLabel}:`}</ServiceTypeLabel>
                <table className="fill-width">
                    <tbody>
                        {
                            values.map(
                                (v, index) => <JobServiceEditorRow
                                    key={`type-${serviceLabel}-offer-${index}`}
                                    showError={showError}
                                    value={v}
                                    setValue={(nval, cust, mat) => setValues(updateValue(index, nval, cust, mat))}
                                    removeValue={() => setValues(removeValue(index))}
                                    options={options.jobServices}
                                />
                            )
                        }
                        {
                            options.jobServices.length > 0 &&
                            <AddServiceForRoomRow options={options.jobServices} addOffer={(id) => setValues([...values, {
                                jobServiceId: id,
                                ...determineInitalValue(options.jobServices.find(op => op.jobServiceId === id)!),
                                sourceServiceForRoomId: -1
                            }])} />
                        }
                    </tbody>
                </table>
            </div>
            <div className="flex-grow" style={{ minHeight: ".5rem" }} />
            <HBar />
        </div>
    )
}


export function ServiceTypeLabel({ children }: PropsWithChildren<{}>) {
    return (
        <div style={{ padding: "1px", width: "10rem" }}><b>{children}</b></div>
    )
}

function determineInitalValue(option: JobServiceOption): Omit<ServiceIdValue, "jobServiceId" | "sourceServiceForRoomId"> {
    const material = option?.materialOptions.find(mat => mat.isDefaultMaterial === true)
    const value = determineInitialLaborValue(option)

    const defaultMaterialAmount =
        (option?.laborPricingUnit !== "each" && option?.laborPricingUnit === material?.materialPriceUnit)
            ? value
            : material?.defaultAmount?.toString()

    return {
        value,
        custDoesService: false,
        isActive: true,
        materialCategoryId: material?.materialCategoryId,
        materialAmount: defaultMaterialAmount
    }
}

function determineInitialLaborValue(option: JobServiceOption): string {
    if (option.laborPricingUnit === "sqft") {
        const sqft = selectServicesForRoomDialogSqft(store.getState())

        return sqft?.toString() ?? ""
    }
    else if (option.laborPricingUnit === "each") {
        return "1"
    }
    else return ""
}