import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CloseIcon from '@material-ui/icons/Close';
import ForumIcon from '@material-ui/icons/Forum';
import SendIcon from '@material-ui/icons/Send';
import clsx from 'clsx';
import { useMemo } from "react";
import { NewMessageIcon } from './Chat';

interface ChatIconButtonProps {
    onClick: () => void;
    icon: "single" | "drawer" | "close";
    showNewMessageIcon: boolean;
    disabled?: boolean;
    id?: string;
}

export default function ChatButton({onClick, icon: iconType, showNewMessageIcon, disabled=false}: ChatIconButtonProps) {
    const icon = useMemo(() => {
        if (iconType === "single") {
            return <ChatBubbleIcon className="chat-icon"/>
        } else if (iconType === "drawer") {
            return <ForumIcon className="chat-icon"/>
        } else if (iconType === "close") {
            return <CloseIcon className="chat-icon"/>
        }
    }, [iconType]);

    return (
        <div id="chat-button-pin-right">
            {showNewMessageIcon && (
                <span className='chat-button-new-message-icon-positioner'>
                    <NewMessageIcon />
                </span>
            )}

            <button
                disabled={disabled}
                onClick={onClick}
                className={clsx("chat-button-base", disabled ? "chat-button-disabled" : "chat-button-active")}
            >{icon}</button>
        </div>
    )
}

interface SendMessageButtonProps {
    onClick: () => void;
    disabled: boolean;
}

export function SendMessageButton({onClick, disabled}: SendMessageButtonProps) {
    return (
        <button
            className={clsx("send-message-button-base", disabled ? "send-message-button-disabled" : "send-message-button-active")}
            disabled={disabled} onClick={onClick}
        >
            <SendIcon className="send-icon"/>
        </button>
    )
}