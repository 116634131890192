import { IconButton } from "@material-ui/core";
import Refresh from "@material-ui/icons/Refresh";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { FlatTabButtonGroup, TabbedSectionButton } from "FlatComponents/Layout/FlatTabbedSection";
import {
    DiscountUpdatedDocument,
    DiscountUpdatedSubscription,
    GetAllAppliedDiscountsForJobQuery,
    GetJobBreakdownQuery,
    GetPricingCenterTabsQuery,
    GetPromotionOnJobQuery,
    PromotionOnJobUpdatedDocument, PromotionOnJobUpdatedSubscription, useGetAllAppliedDiscountsForJobQuery,
    useGetJobBreakdownQuery,
    useGetMsrpScalarForConfigurationQuery,
    useGetPromotionOnJobQuery
} from "generated/graphql";
import {
    FLOOR_PREP_ID,
    FURNITURE_ID,
    RIP_UP_AND_HAUL_ID,
    R_AND_R_ID,
    SHIM_ID,
    SHOE_MOLDING_ID
} from "Globals/globalConstants";
import { cloneDeep } from "lodash";
import { groupServicesByJobService } from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/BreakdownTableUtils";
import LaborBreakdownTable from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/LaborBreakdownTable";
import MaterialBreakdownTable from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/MaterialBreakdownTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectContractConfigurationId, selectJobConfigurationId, selectMSRPScalar, selectQuoteConfigToQuoteIdMap, setJobConfigurationId, setMSRPScalar } from "Redux/pricingCenterReducer";
import ChargeablesEditor from "./Chargeables/ChargeablesEditor";
import { customToEditable, EditableCustomService } from "./Chargeables/CustomServiceEditorRow";
import PricingSummary from "./PricingSummary/PricingSummary";

export function FlatJobDetailsSection({tabs}: { tabs: GetPricingCenterTabsQuery["pricingCenterTabs"]["tabData"]; }) {
    const dispatch = useAppDispatch();
    const selectedConfigId = useAppSelector(selectJobConfigurationId);  // the config ID of the selected tab
    const contractConfigId = useAppSelector(selectContractConfigurationId);
    const quoteConfigToQuoteIdMap = useAppSelector(selectQuoteConfigToQuoteIdMap);

    const { data: discountData, subscribeToMore: subscribeToDiscountUpdates } =
        useGetAllAppliedDiscountsForJobQuery({
            skip: selectedConfigId < 1,
            variables: { jobConfigurationId: selectedConfigId },
            fetchPolicy: "cache-and-network",
    });

    useGetMsrpScalarForConfigurationQuery({
        variables: {
            jobConfigurationId: selectedConfigId
        },
        skip: selectedConfigId < 1,
        onCompleted: (data) => {
            dispatch(setMSRPScalar(data.msrpScalarForConfiguration))
        }
    });
    const msrpScalar = useAppSelector(selectMSRPScalar);

    const discounts = useMemo(() => discountData?.allAppliedDiscountsForJob ?? [], [discountData]);

    const listenForDiscountUpdates = useCallback(() => {
        subscribeToDiscountUpdates({
            document: DiscountUpdatedDocument,
            variables: { jobConfigurationId: selectedConfigId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const updatedDiscount = (
                    subscriptionData.data as unknown as DiscountUpdatedSubscription
                ).discountUpdated;

                // remove the discount (regardless of whether it's to be removed... updates will be added back to the list)
                let updatedDiscountList =
                    prev.allAppliedDiscountsForJob?.filter((d) => d.id !== updatedDiscount.id) ??
                    [];
                if (!updatedDiscount.isRemoval) {
                    updatedDiscountList.push(updatedDiscount.discount);
                }

                let newAppliedDiscountsObject: GetAllAppliedDiscountsForJobQuery = {
                    ...prev,
                    allAppliedDiscountsForJob: updatedDiscountList,
                };

                return newAppliedDiscountsObject;
            },
        });
    }, [selectedConfigId, subscribeToDiscountUpdates]);

    const {data: promoData, subscribeToMore: subscribeToPromotionUpdates} = useGetPromotionOnJobQuery({
        variables: {jobConfigurationId: selectedConfigId},
        skip: selectedConfigId < 1
    });
    const promo = promoData?.promotionOnJob;

    const listenForPromotionUpdates = useCallback(() => {
        subscribeToPromotionUpdates({
            document: PromotionOnJobUpdatedDocument,
            variables: { jobConfigurationId: selectedConfigId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;

                const newPromotionOnJob = (subscriptionData.data as PromotionOnJobUpdatedSubscription).promotionOnJobUpdated;
                if (newPromotionOnJob.isRemoval) {
                    const newPromotion: GetPromotionOnJobQuery = {
                        ...prev,
                        promotionOnJob: null
                    }
                    return newPromotion;
                } else {
                    const newPromotion: GetPromotionOnJobQuery = {
                        ...prev,
                        promotionOnJob: newPromotionOnJob.promotion!
                    };
                    return newPromotion
                }
            }
        });
    }, [selectedConfigId, subscribeToPromotionUpdates]);

    // when true, prevents the breakdown from refreshing
    const [serviceChangesPresent, setServiceChangesPresent] = useState(false);

    const {
        data: breakdownData,
        refetch: refetchBreakdown,
        startPolling,
        stopPolling,
    } = useGetJobBreakdownQuery({
        skip: selectedConfigId < 1,
        variables: {
            jobConfigurationId: selectedConfigId,
            hideNoLaborRAndR: false // need to be able to see 0 labor R&R services
        }
    });
    const areaBreakdowns = useMemo(
        () => breakdownData?.jobBreakdown.areaBreakdowns ?? [],
        [breakdownData]
    );

    // prevent polling for breakdown when there are changes, restart polling once they're gone
    useEffect(() => {
        if (serviceChangesPresent) {
            stopPolling();
        } else {
            // FIXME: this logic will "refetch" the breakdown on initial load
            if (selectedConfigId > 0) {
                // refetchBreakdown({jobConfigurationId});
                startPolling(10000); // poll every 10 seconds
            }
        }
    }, [serviceChangesPresent, startPolling, stopPolling, refetchBreakdown, selectedConfigId]);

    function serviceIsEditable(
        service: GetJobBreakdownQuery["jobBreakdown"]["areaBreakdowns"][number]["services"][number]
    ): boolean {
        return (
            service.serviceTypeId === RIP_UP_AND_HAUL_ID ||
            service.serviceTypeId === FURNITURE_ID ||
            service.serviceTypeId === R_AND_R_ID ||
            service.jobServiceId === SHOE_MOLDING_ID ||
            service.jobServiceId === SHIM_ID ||
            service.serviceTypeId === FLOOR_PREP_ID
        );
    }

    // takes an entire area breakdown and returns a new one that only includes services
    // that CAN be edited in some way
    const getEditableServicesAreaBreakdowns = useCallback(
        (wholeBreakdown: typeof areaBreakdowns): typeof areaBreakdowns => {
            let newBreakdowns = cloneDeep(wholeBreakdown);
            newBreakdowns!.forEach((breakdown) => {
                let filteredServices = breakdown.services.filter((service) =>
                    serviceIsEditable(service)
                );
                breakdown.services = filteredServices;
            });

            return newBreakdowns;
        },
        []
    );

    // takes an entire area breakdown and returns a new one that only includes services
    // that CAN NOT be edited in some way
    const getFixedServicesAreaBreakdowns = useCallback(
        (wholeBreakdown: typeof areaBreakdowns): typeof areaBreakdowns => {
            let newBreakdowns = cloneDeep(wholeBreakdown);
            newBreakdowns!.forEach((breakdown) => {
                let filteredServices = breakdown.services.filter(
                    (service) => !serviceIsEditable(service)
                );
                breakdown.services = filteredServices;
            });

            return newBreakdowns;
        },
        []
    );

    const fixedAreaBreakdowns = useMemo(() => {
        if (areaBreakdowns) {
            return getFixedServicesAreaBreakdowns(areaBreakdowns!);
        } else return [];
    }, [areaBreakdowns, getFixedServicesAreaBreakdowns]);

    const editableAreaBreakdowns = useMemo(() => {
        if (areaBreakdowns) {
            return getEditableServicesAreaBreakdowns(areaBreakdowns!);
        }
    }, [areaBreakdowns, getEditableServicesAreaBreakdowns]);

    const groupedEditableServices = useMemo(() => {
        if (editableAreaBreakdowns && discounts && msrpScalar > 0) {
            return groupServicesByJobService(editableAreaBreakdowns, discounts!, msrpScalar);
        } else return {};
    }, [editableAreaBreakdowns, discounts, msrpScalar]);

    const customServices: EditableCustomService[] = useMemo(() => {
        const services: EditableCustomService[] = 
            areaBreakdowns.flatMap(ab => ab.customServices).map(s => customToEditable(s));
        return services
    },[areaBreakdowns]);

    const headerLabel = (
        <span className="flex-row align-items-center flex-gap-xxsm">
            <div className="whitespace-no-wrap">Refresh Details</div>
            <IconButton
                onClick={() => refetchBreakdown()}
                disabled={serviceChangesPresent}
                size="small"
            >
                <Refresh />
            </IconButton>
        </span>
    );

    function makeButtons(): TabbedSectionButton[] {
        if (tabs.length <= 0) {
            return [{
                label: "No Configurations",
                buttonProps: { disabled: true }
            }];
        }

        return tabs.map((t) => {
            const toi = {
                label: `Option ${t.optionNumber}`,
                onClick: () => dispatch(setJobConfigurationId(t.id)),
                buttonProps: {
                    variant: 'small',
                    style: {
                        color: ''
                    }
                }
            };

            if (t.id === contractConfigId)
                toi.buttonProps.style.color = 'var(--flat-red)';
            else if (Object.keys(quoteConfigToQuoteIdMap).includes(t.id.toString()))
                toi.buttonProps.style.color = 'var(--flat-blue)';

            return toi;
        });
    }

    const selectedConfigIdx = tabs.findIndex(t => t.id === selectedConfigId);
    const configTabs = <FlatTabButtonGroup activeIdx={(selectedConfigIdx > -1) ? selectedConfigIdx : undefined} buttons={ makeButtons() }/>;

    return (
        <div className="flex-row row-pad-between-children">
            <div className="flex-column column-pad-between-children flex-even-ratio">
                <FlatSection header={ <span className="flex-row flex-gap-xsm">{headerLabel} {configTabs}</span> }>
                    { selectedConfigId > 0 && (
                        <div className="flex-colum column-pad-between-children">
                            <MaterialBreakdownTable title={"Materials"} areaBreakdowns={areaBreakdowns} />
                            <LaborBreakdownTable title={"Fixed Labor"} areaBreakdowns={fixedAreaBreakdowns} />
                        </div>
                    ) }
                </FlatSection>

                <ChargeablesEditor
                    serviceGroups={groupedEditableServices}
                    setChangesPresent={setServiceChangesPresent}
                    discounts={discounts}
                    customServices={customServices}
                />
            </div>
            <FlatSection header="Pricing Summary" className="flex-even-ratio" removePadding={true}>
                <PricingSummary
                    discounts={discounts}
                    listenForDiscountUpdates={listenForDiscountUpdates}
                    promotion={promo}
                    listenForPromotionUpdates={listenForPromotionUpdates}
                    areaBreakdowns={areaBreakdowns}
                />
            </FlatSection>
        </div>
    );
}
