import { createGlobalState } from 'react-hooks-global-state';
import { DebugSettings, GenerateDebugSettings } from '../Components/DebugMenu';

//request<T = any, V = Variables>(document: RequestDocument, variables?: V, requestHeaders?: Dom.RequestInit['headers']): Promise<T>;



interface GlobalState{
    token: string | null
    username: string | null
    debugSettings: DebugSettings
    // gqlRequestFunction: <T, V>(document: RequestDocument, variables?: V, headers? : HeadersInit) => Promise<T>
}

const initialState = { token : null, username : null, debugSettings: GenerateDebugSettings() } as GlobalState;
const { useGlobalState, setGlobalState, getGlobalState } = createGlobalState(initialState);

export { useGlobalState, setGlobalState, getGlobalState }