import { useAppSelector } from "Redux/hooks";
import { selectCostProductTypeId } from "Redux/productCostsReducer";
import { CostInventoryModeSelect } from "../Editors/CostInventoryModeSelect";
import HardCostProductTypeSelect from "./CostProductTypeSelect";
import FilterVendorEditor from "../Editors/FilterVendorEditor";
import ProductCostsTable from "../GenericTable/ProductCostsTable";
import SubmitOrClearButton from "../Editors/SubmitOrClearButton";

export default function HardMode({ height }: { height: string }) {
    const typeId = useAppSelector(selectCostProductTypeId)

    return (
        <div className="flex-column fill-width" style={{ alignItems: "center" }}>
            <div className="flex-row padding-bottom-xsm fill-width" style={{alignItems:"flex-end"}}>
                <CostInventoryModeSelect />
                <FilterVendorEditor />
                <HardCostProductTypeSelect />
                <div className="flex-grow" />
                <SubmitOrClearButton />
                {/* <UploadCostsEditor productTypeId={typeId} /> */}
            </div>
            <ProductCostsTable productTypeId={typeId} height={height} />
        </div>
    )
}
