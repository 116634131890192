import { selectOneTimeCost, setOneTimeCost } from "Redux/addProductReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { OneTimeCostEditor } from "./CostEditors";


export default function ProductOneTimeCostEditor(){
    const singleCost = useAppSelector(selectOneTimeCost)
    const dispatch = useAppDispatch();

    function updateCost(newValue: string) {
        dispatch(setOneTimeCost(newValue))
    }

    return <OneTimeCostEditor width='12rem' cost={singleCost} setCost={updateCost} />
}