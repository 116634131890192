import FlatButton from "FlatComponents/Button/FlatButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import { FlatLabeledCheckbox } from "FlatComponents/Inputs/FlatCheckbox";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import { FlatLabeledSelect, FlatSelectOption } from "FlatComponents/Inputs/FlatSelect";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { ContractorInstallationCapabilityInput, namedOperations, useDeleteContractorInstallationCapablilityMutation, useUpsertContractorInstallationCapabilityMutation } from "generated/graphql";
import { isEmptyString, isInstallationCapabilityValid, isNotNullOrUndefined } from "Globals/GenericValidators";
import { CARPET_PRODUCT_ID } from "Globals/globalConstants";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { MultiValue } from "react-select";
import { InstallationSkill, SkillRowProps } from "./Tables/SkillsTable";

interface ContractorSkillDialogProps extends SkillRowProps {
    onClose: () => void;
}

export default function ContractorSkillDialog({skill: ogSkill, availableCertifications, onClose}: ContractorSkillDialogProps) {
    const newMode = isEmptyString(ogSkill.capacity);
    // if a skill is new, it defaults to active
    const [skill, setSkill] = useState({...ogSkill, isActive: newMode ? true : ogSkill.isActive});
    const headerText = `${ogSkill.productType} Skills`
    const unit = ogSkill.productTypeId === CARPET_PRODUCT_ID ? " sy/day" : " sf/day";

    const certificationOptions: FlatSelectOption[] = availableCertifications.map(ac => ({value: ac.id, label: ac.name}));
    const selectedCertifications: MultiValue<FlatSelectOption> = certificationOptions.filter(co => skill.certificationIds.includes(+co.value));
    function onChangeSelectedCertification(newCerts: MultiValue<FlatSelectOption>, actionType: string) {
        if (actionType === "clear") {
            setSkill({...skill, certificationIds: []})
        } else {
            setSkill({...skill, certificationIds: newCerts.map(c => +c.value)})
        }
    }

    const [upsertSkill, {loading: upserting}] = useUpsertContractorInstallationCapabilityMutation({
        onCompleted: onClose,
        onError: () => `Could not ${newMode ? "add" : "update"} skill information`,
        refetchQueries: [namedOperations.Query.GetContractor]
    });

    const [deleteSkill, {loading: deleting}] = useDeleteContractorInstallationCapablilityMutation({
        variables: {contractorId: ogSkill.contractorId, productTypeId: ogSkill.productTypeId},
        onCompleted: onClose,
        onError: () => "Could not remove skill from contractor's profile",
        refetchQueries: [namedOperations.Query.GetContractor]
    });

    function validateAndPrepareData(): ContractorInstallationCapabilityInput | undefined {
        const preparedCapability = skillToCapability(skill);
        if (isInstallationCapabilityValid(preparedCapability, true)) {
            return preparedCapability;
        }

        return undefined;
    }

    function onSubmit() {
        const preparedCapability = validateAndPrepareData();
        if (isNotNullOrUndefined(preparedCapability)) {
            upsertSkill({variables: {capability: preparedCapability!}})
        }
    }

    const submitButton = (
        <FlatButton
            key={1}
            onClick={onSubmit}
            color="secondary"
            variant="contained"
            disabled={upserting || deleting}
        >Submit</FlatButton>
    );
    

    function onDelete() {
        if (window.confirm("Are you sure you wish to remove this skill from the contractor's profile?")) {
            deleteSkill();
        }
    }

    const deleteButton = !newMode 
        ? <FlatDeleteButton
            disabled={deleting || upserting}
            onClick={onDelete}
        />
        : <></>

    return (
        <FlatDialog
            sectionProps={{ header: headerText, endAdornment: deleteButton }}
            dialogProps={{
                open: true,
                onClose: onClose,
                maxWidth: "sm",
                // overflow: visible is required to allow the select drop downs to render properly
                PaperProps: {style: {overflow: "visible"}}
            }}
            actionButtons={[submitButton]}
        >
            <div className="grid-50-50">
                <NumberFormat
                    customInput={FlatLabeledInput}
                    label={"Capacity"}
                    value={skill.capacity}
                    onValueChange={(newValue) => setSkill({...skill, capacity: newValue.floatValue?.toFixed(0) ?? ""})}
                    suffix={unit}
                    className="w-5r"
                />
                <FlatLabeledCheckbox
                    checked={skill.isActive}
                    onClick={() => setSkill({...skill, isActive: !skill.isActive})}
                    label={"Active"}
                />
                {certificationOptions.length > 0 && (
                    <FlatLabeledSelect
                        label={"Certification"}
                        options={certificationOptions}
                        isMulti
                        className="w-10r"
                        value={selectedCertifications}
                        onChange={(newValue, actionType) => onChangeSelectedCertification(newValue as MultiValue<FlatSelectOption>, actionType.action)}
                    />
                )}
            </div>
        </FlatDialog>
    )
}

export function skillToCapability(skill: InstallationSkill): ContractorInstallationCapabilityInput {
    return {
        contractorId: skill.contractorId,
        productTypeId: skill.productTypeId,
        sqftPotential: isEmptyString(skill.capacity) ? -1 : +skill.capacity,
        isActive: skill.isActive,
        certificationIds: skill.certificationIds
    };
}