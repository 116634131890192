import { namedOperations, useUpdateSahAppointmentNotesMutation } from "generated/graphql";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";



export function AppointmentNotesEditor({ sahAppointmentId, notes }: {sahAppointmentId: number, notes: string}) {
    const [typedNotes, setTypedNotes] = useState<undefined | string>(undefined);
    const typedRef = useRef<boolean>(false);

    const [updateAppointmentNotes] = useUpdateSahAppointmentNotesMutation({
        refetchQueries: [namedOperations.Query.GetCallCenterJob]
    });

    const notesInput = typedNotes ?? notes;

    const debouncedUpdateNotes = useMemo(() => {
        return debounce((newNotes: string) => {
            updateAppointmentNotes({
                variables: { sahAppointmentId, notes: newNotes },
            });
        }, 500);
    }, [updateAppointmentNotes, sahAppointmentId]);

    function updateNotesField(newNotes: string) {
        setTypedNotes(newNotes);
        debouncedUpdateNotes(newNotes);
        typedRef.current = true;
    }

    useEffect(() => {
        // if (notes === typedNotes) setTypedNotes(undefined);
        console.log("notes", notes, typedNotes)
        if (notes === typedNotes) {
            setTypedNotes(undefined);
        }
    }, [notes, typedNotes]);

    const showSynced = (typedRef.current === true) && (typedNotes === undefined);

    return (
        <div
            style={{ position: "relative", width: "100%" }}
            className="flex-grow"
        >
            <textarea
                value={notesInput}
                style={{ width: "100%", height: "100%", resize:"none" }}
                onChange={(e) => updateNotesField(e.currentTarget.value)}
            ></textarea>
            {showSynced && (
                <div
                    className="fade-out-synced-text"
                    style={{
                        position: "absolute",
                        right: ".6em",
                        bottom: ".15em",
                        fontSize: ".8em",
                        color: "#999"
                    }}
                >
                    Synced...
                </div>
            )}
        </div>
    );
}
