import { useEffect, useState } from "react";
import { apolloClient } from "./appApolloClient";
import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyB3mJI-0yL4TALDZI-N45Yru9vKWvJ1rCg",
    authDomain: "wof-server-faf4d.firebaseapp.com",
    projectId: "wof-server-faf4d",
    storageBucket: "wof-server-faf4d.appspot.com",
    messagingSenderId: "414854320114",
    appId: "1:414854320114:web:7b21818b32d434fc1b656b",
    measurementId: "G-ZTZ9SGYS9L"
};
// not actually used anywhere, but it needs to be exported - see below link
// https://stackoverflow.com/a/69395008/8057105
export const app = firebase.initializeApp(firebaseConfig);

export async function signIn(signIn: {email: string, password: string}): Promise<boolean | Error> {
    return firebase.auth().signInWithEmailAndPassword(signIn.email, signIn.password).then(_ => true).catch(x => x);
}
export async function signOut() : Promise<void>{
    await apolloClient.cache.reset();
    return firebase.auth().signOut();
}

export async function getIdTokenAsync(){
    return await firebase.auth().currentUser?.getIdToken();
}

export function useAuthToken() {
    const [token, setToken] = useState<string | null>(null);
    // the currently signed in uid - used to detect changes to the signed in user
    const uid = firebase.auth().currentUser?.uid ?? null;

    useEffect(() => {
        let unsubscribe: firebase.Unsubscribe | null = null;
        if (uid) {
            unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
                setToken(await user?.getIdToken() ?? null);
            });
        } else {
            setToken(null);
        }

        return () => unsubscribe?.();
    }, [uid, setToken]);

    return token;
}