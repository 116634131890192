import { Button, Dialog } from "@material-ui/core";
import { useState } from "react";
import { PreventChangesContext } from "../OldSellSheet/OldSellSheet";
import EditInstallationServicesMenu from "./EditInstallationServicesMenu";

interface InstallationDetailsButtonProps {
    areaId: number;
    productTypeId: number;
    disabled?: boolean;
    someAreaHasHardSurface: boolean;
}

export default function InstallationDetailsButton({
    areaId,
    productTypeId,
    disabled,
    someAreaHasHardSurface
}: InstallationDetailsButtonProps) {
    const [menuOpen, setMenuOpen] = useState(false);
    const closeMenu = () => setMenuOpen(false);

    return (
        <PreventChangesContext.Consumer>
            {preventChanges => (
                <>
                    <Button
                        onClick={(e) => setMenuOpen(true)}
                        disabled={disabled || preventChanges}
                        variant="outlined"
                        color="secondary"
                        style={{ marginTop: ".5rem" }}>
                        Installation Details
                    </Button>
                    
                    {menuOpen && (
                        <FullEditInstallationServicesMenu
                            open={true}
                            onClose={closeMenu}
                            areaId={areaId}
                            productTypeId={productTypeId}
                            someAreaHasHardSurface={someAreaHasHardSurface}
                        />
                    )}
                </>
            )}
        </PreventChangesContext.Consumer>
    )
}

interface FullEditInstallationServicesMenuProps {
    open: boolean;
    onClose: () => void;
    areaId: number;
    productTypeId: number;
    someAreaHasHardSurface: boolean;
}

export function FullEditInstallationServicesMenu({
    open,
    onClose,
    areaId,
    productTypeId,
    someAreaHasHardSurface
}: FullEditInstallationServicesMenuProps) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" >
            <div className="w-40r">
                <EditInstallationServicesMenu
                    areaId={areaId}
                    productTypeId={productTypeId} 
                    onClose={onClose}
                    someAreaHasHardSurface={someAreaHasHardSurface}
                />
            </div>
        </Dialog>
    )
}