import NavbarPage from "Components/Page";
import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";
import { useGetContractorListQuery } from "generated/graphql";
import { emptyContractorProfile } from "Globals/DataStructures/EmptyDataStructures";
import { idUrlMask } from "Globals/Hooks";
import { contractorProfilePath, contractorsPath } from "Globals/PathStrings";
import { formatNameStringLastFirst } from "Globals/StringFormatting";
import { useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import ContractorProfile from "./ContractorProfilePage";
import EditContractorDialog from "./EditContractorDialog";

export default function ContractorRouter() {
    return (
        <Switch>
            <Route path={contractorProfilePath + idUrlMask}>
                <ContractorProfile />
            </Route>
            <Route path={contractorsPath}>
                <ContractorsPage />
            </Route>
        </Switch>
    );
}

function ContractorsPage() {
    const history = useHistory();
    function goToContractorProfile(workerId: number) {
        history.push(`${contractorsPath}/profile/${workerId}`);
    }

    const {data} = useGetContractorListQuery({
        onError: () => alert("Could not load contractors")
    });
    const contractors = data?.allContractors ?? [];

    const [addDialogOpen, setAddDialogOpen] = useState(false);

    const pageTitle = (
        <span className="flex-row flex-gap-xsm">
            Contractors
            <FlatAddButton onClick={() => setAddDialogOpen(true)}/>
        </span>
    );

    return (<>
        <NavbarPage hideNavbar>
            <FlatNavbar title={pageTitle}/>

            {contractors.map(c => (
                <FlatButton
                    variant="contained"
                    color="secondary"
                    key={`c-${c.id}`}
                    onClick={() => goToContractorProfile(c.workerId)}
                >
                    {formatNameStringLastFirst({firstName: c.firstName, lastName: c.lastName})}
                </FlatButton>
            ))}
        </NavbarPage>

        {addDialogOpen && (
            <EditContractorDialog
                onClose={() => setAddDialogOpen(false)}
                contractor={{...emptyContractorProfile}}
                onComplete={(workerId: number) => goToContractorProfile(workerId)}
            />
        )}
    </>)
}