import { FilledTextFieldProps, TextField } from "@material-ui/core";
import { CARPET_PRODUCT_ID } from "Globals/globalConstants";
import { MoneyNumberFormat } from "Pages/Admin/LaborRates/Components/MoneyNumberFormat";
import { selectTypeId } from "Redux/addProductReducer";
import { useAppSelector } from "Redux/hooks";


export interface CostEditorProps {
    cost: string,
    setCost: (newCost: string) => void,
    readOnly?: boolean,
    width?: string
}

interface FormattedTextFieldProps extends Omit<FilledTextFieldProps, "variant"> {
    name: string,
    label: string,
    unit: string,
    disclaimer: string,
    readonly?: boolean
}

function FormattedTextField({ name, label, unit, disclaimer, readonly, ...props }: FormattedTextFieldProps) {
    return <TextField
        key={`${name}-cost-editor`}
        label={label}
        InputProps={{
            endAdornment: <div style={{ whiteSpace: "nowrap", fontSize: ".75rem" }}>{unit}</div>,
            readOnly: readonly
        }}
        variant="filled"
        helperText={disclaimer}
        {...props}
    />
}

const disclaimer = "excl. shipping, excl. tax"

export function SingleCostEditor({ cost, setCost, readOnly }: CostEditorProps) {
    const typeId = useAppSelector(selectTypeId)

    const label = (typeId === CARPET_PRODUCT_ID) ? "Cut Cost" : "Box Cost"
    const unit = (typeId === CARPET_PRODUCT_ID) ? "per sq.yd." : "per sq.ft."

    return (
        <MoneyNumberFormat
            style={{ width: "10rem", margin: "0rem .5rem" }}
            value={cost}
            onValueChange={(v) => setCost(v.value)}
            customInput={FormattedTextField}
            giveToInputProps={{ name: "single", label, unit, disclaimer, readOnly }}
        />
    )
}

export function BulkCostEditor({ cost, setCost, readOnly }: CostEditorProps) {
    const typeId = useAppSelector(selectTypeId)

    const label = (typeId === CARPET_PRODUCT_ID) ? "Roll Cost" : "Pallet Cost"
    const unit = (typeId === CARPET_PRODUCT_ID) ? "per sq.yd." : "per sq.ft."

    return (
        <MoneyNumberFormat
            style={{ width: "10rem", margin: "0rem .5rem" }}
            value={cost}
            onValueChange={(v) => setCost(v.value)}
            customInput={FormattedTextField}
            giveToInputProps={{ name: "bulk", label, unit, disclaimer, readOnly }}
            
        />
    )
}

export function OneTimeCostEditor({ cost, setCost, readOnly, width }: CostEditorProps) {
    const typeId = useAppSelector(selectTypeId)

    const label = "One Time Cost"
    const unit = (typeId === CARPET_PRODUCT_ID) ? "per sq.yd." : "per sq.ft."

    return (
        <MoneyNumberFormat
            style={{ width: width ?? "10rem", margin: "0rem .5rem" }}
            value={cost}
            onValueChange={(v) => setCost(v.value)}
            customInput={FormattedTextField}
            giveToInputProps={{ name: "one-time", label, unit, disclaimer, readOnly }}
        />
    )
}
