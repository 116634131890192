import { namedOperations, Picture, useAddPicturesForRoomMutation, useGetJobConfigurationQuery } from "generated/graphql";
import { convertPictureToInput } from "Redux/genericSalesReducer";
import { AddNewPhotoButton } from "../Generics/AddNewPhotoButton";


export default function JobPictureUploadButton({ jobConfigurationId }: { jobConfigurationId: number }) {
    const { data } = useGetJobConfigurationQuery({ variables: { jobConfigurationId } })

    const [upload] = useAddPicturesForRoomMutation({ 
        refetchQueries: [namedOperations.Query.GetJobConfiguration],
        awaitRefetchQueries: true
    });

    function onUpload(roomId: number, pics: Picture[]) {
        upload({
            variables: {
                pictures: pics.map(convertPictureToInput),
                roomId: roomId
            }
        })
    }

    return (
        <AddNewPhotoButton areas={data?.jobConfiguration.areas ?? []} onUpload={onUpload} />
    )
}