import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import _ from "lodash";
import { selectIsForHome, selectRoomCount, setRoomCount } from "Redux/callCenterSchedulingReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";

export default function RoomCountSelector({hideBorder=false}: {hideBorder?: boolean}) {
    const classNames = hideBorder ? "" : "padding-xsm solid-border rounded-border";
    const dispatch = useAppDispatch();
    const roomCount = useAppSelector(selectRoomCount);
    const isForHome = useAppSelector(selectIsForHome);

    return (
        <div className={classNames}>
            <FormControl className="margin-sm w-13r" style={{ whiteSpace: "nowrap" }}>
                <InputLabel>Number of rooms</InputLabel>
                <Select
                    value={roomCount}
                    onChange={(e) => dispatch(setRoomCount(e.target.value as number))}
                >
                    {
                        _.range(1, 10).map(num => <MenuItem key={`room-count-${num}`} value={num}>{num}</MenuItem>)
                    }
                    <MenuItem value={0}>Whole {isForHome ? 'House' : 'Building'}</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}