import { StandardTextFieldProps, TextField } from "@material-ui/core";
import React from "react";
import NumberFormat, { NumberFormatPropsBase } from "react-number-format";

export interface MoneyNumberFormatProps<T> extends Pick<NumberFormatPropsBase<T>, "value" | "onValueChange" | "customInput"> {
    isError?: boolean,
    style?: React.CSSProperties,
    hidePrefix?: boolean,
    giveToInputProps?: T
}

export function MoneyNumberFormat<T>({ value, onValueChange, isError, hidePrefix, customInput, giveToInputProps }: MoneyNumberFormatProps<T>) {
    return (
        <NumberFormat
            // className={clsx({ ["error-number-format"]: isError ?? false })}
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={2} fixedDecimalScale
            value={value}
            thousandSeparator={true}
            prefix={(hidePrefix === true) ? "" : "$"}
            // inputMode="numeric"
            onValueChange={onValueChange}
            // customInput={customInput}
        // {...giveToInputProps}
        />
    )
}

interface UnderlinedMoneyNumberFormatProps extends Omit<MoneyNumberFormatProps<FormattedStandardTextFieldProps>, "customInput"> {
    autoFocus?: boolean
}

export function UnderlinedMoneyNumberFormat({ ...props }: UnderlinedMoneyNumberFormatProps) {
    return <MoneyNumberFormat
        customInput={FormattedTextField}
        {...props}
    />
}

interface FormattedStandardTextFieldProps extends Omit<StandardTextFieldProps, "variant"> {
    name?: string,
    label?: string,
    unit?: string,
    disclaimer?: string,
    readonly?: boolean,
    textFieldClassNames?: string
}

function FormattedTextField({ name, label, unit, disclaimer, readonly, autoFocus, textFieldClassNames, className, ...props }: FormattedStandardTextFieldProps) {
    return <TextField
        key={`${name}-editor`}
        autoFocus={autoFocus}
        label={label}
        InputProps={{
            endAdornment: <div style={{ whiteSpace: "nowrap", fontSize: ".75rem" }}>{unit}</div>,
            readOnly: readonly,
            className: textFieldClassNames
        }}
        variant="standard"
        helperText={disclaimer}
        {...props}
    />
}

interface MUINumberFormatProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const MUINumberFormat = React.forwardRef<NumberFormat<MUINumberFormatProps>, MUINumberFormatProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                allowedDecimalSeparators={["."]}
                thousandSeparator
                isNumericString
                inputMode="numeric"
                allowNegative={false}

                decimalScale={2}
                prefix="$"
            />
        );
    },
);
