import { GetJobInvoiceDataQuery, JobServiceOption, ServiceMaterialCategoryOption, ServiceTypeOption } from "generated/graphql";
import { FURNITURE_ID, NEW_WALL_FINISH_ID, RIP_UP_AND_HAUL_ID, R_AND_R_ID, STEPS_ID, TRANSITION_ID } from "Globals/globalConstants";

export type ContractArea = GetJobInvoiceDataQuery["jobConfiguration"]["areas"][number];
export type ContractRoom = ContractArea["rooms"][number];

export type DisplayServiceFormatter = (
    areaLabel: string,  // labels for all areas the service applies to
    service: ContractRoom["services"][number],
    matchingServiceType?: ServiceTypeOption,
    matchingJobService?: JobServiceOption,
    matchingMaterial?: ServiceMaterialCategoryOption
) => string;

//Forms coherent(ish) sentence to describe what service will be done
// used for all services but installation and furniture
export function generalServiceFormatter(
    areaLabel: string,
    service: ContractRoom["services"][number],
    matchingServiceType?: ServiceTypeOption,
    matchingJobService?: JobServiceOption,
    matchingMaterial?: ServiceMaterialCategoryOption
): string {
    // some R&R services specify 0 of the item to be extra explicit, so this handles that case more gracefully
    if (service.serviceTypeId === R_AND_R_ID && service.laborAmount === 0) {
        return `${matchingServiceType?.name}: No ${matchingJobService?.description} - ${areaLabel}`
    }

    const installOver = service.isActive === false;
    const whoDoesVerbage = installOver
        ? "Install over"
        : (service.customerDoesService
            ? "Cust. to"
            : "WOF to"
        );
    const optionalJoiningVerb = addVerbForServiceType(service.serviceTypeId);
    let formattedService = sensibleFormattingForServices(
        service.serviceTypeId,
        installOver,
        service.laborAmount,
        matchingServiceType?.name,
        matchingJobService?.description,
        matchingMaterial?.materialName ?? undefined
    );

    // don't show "Install over None"
    if (installOver && formattedService.endsWith("None")) {
        return "";
    }

    return `${whoDoesVerbage}${optionalJoiningVerb}${formattedService} - ${areaLabel}`;
}

//Returns only service name + material (if it has one)
export function installationServiceFormatter(
    areaLabel: string,
    service: ContractRoom["services"][number],
    matchingServiceType?: ServiceTypeOption,
    matchingJobService?: JobServiceOption,
    matchingMaterial?: ServiceMaterialCategoryOption
): string {
    const materialName = matchingMaterial?.materialName ?? undefined;

    return `${matchingJobService?.description}${
        materialName !== undefined ? ` + ${materialName}` : ""
    }`;
}

//Depending on service type returns a verb to describe the action carried out by WOF or customer
function addVerbForServiceType(typeId: number) {
    if ([STEPS_ID, NEW_WALL_FINISH_ID, TRANSITION_ID].includes(typeId)) return " install ";
    else if (typeId === FURNITURE_ID) return " move ";
    else return " ";
}

//Formats service name to readable string (formats differently depending on the type id to make it more coherient)
export function sensibleFormattingForServices(
    typeId: number,
    installOver: boolean,
    amount?: number,
    typeName?: string,
    serviceName?: string,
    materialName?: string
) {

    //When either new wall finish or transition pieces, only returns material name as the service name should match
    if (typeId === TRANSITION_ID) {
        return materialName ?? serviceName ?? "";
    } else if (typeId === NEW_WALL_FINISH_ID)
        return serviceName !== undefined && materialName !== undefined
            ? `${serviceName}: ${materialName}`
            : materialName ?? serviceName ?? "";
    else {
        //If steps, returns as # followed by typename with apporpriate plurality (hides material name as to not box us into corner with suplying treads)
        if (typeId === STEPS_ID)
            return `${amount} ${typeName?.substring(0, (amount ?? 0) > 1 ? 5 : 4)}: ${serviceName}`;
        // ${materialName !== undefined ? ` + ${materialName}` : ""}`
        //If R&R adds number but skips fixing plurality as it would be too hard
        else if (typeId === R_AND_R_ID)
            return `${typeName}: ${amount} ${serviceName} ${
                materialName !== undefined ? ` + ${materialName}` : ""
            }`;
        else if (typeId === RIP_UP_AND_HAUL_ID && installOver) {
            return ` ${serviceName}`;
        }
        //Returns without number
        else
            return `${typeName}: ${serviceName} ${
                materialName !== undefined ? ` + ${materialName}` : ""
            }`;
    }
}