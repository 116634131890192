import { TextField } from "@material-ui/core"
import { selectColorValue, selectStyleId, setColor } from "Redux/addProductReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"

export default function SoftColorEditor() {
    const dispatch = useAppDispatch()
    const styleId = useAppSelector(selectStyleId)
    const value = useAppSelector(selectColorValue)

    function updateValue(newValue?: string) {
        dispatch(setColor({ id: -2, value: newValue }))
    }

    return (
        <TextField
            className="margin-side-xsm w-10r"
            margin="none"
            variant="filled"
            label="Private Label Color"
            disabled={styleId <= 0}
            value={value}
            onChange={e => updateValue(e.target.value)}
        />
    )
}