import FlatButton from "FlatComponents/Button/FlatButton";
import FlatFileInput from "FlatComponents/Inputs/FlatFileInput";
import { FlatLabeledInput } from "FlatComponents/Inputs/FlatInput";
import FlatDialog from "FlatComponents/Layout/FlatDialog";
import { LiabilityInsurance, LiabilityInsuranceInput, useReportS3FileUploadStatusMutation, useUpsertLiabilityInsuranceMutation } from "generated/graphql";
import { uploadFileToS3 } from "Globals/FileUtils";
import { prepareWorkerDocument } from "Globals/dataPreparationUtils";
import { dateTimeStrToMdy, dayValueToMdy, mdyToDay, SingleDateCalendarDialog } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isWorkerDocumentValid } from "Globals/GenericValidators";
import { useState } from "react";

interface LiabilityInsuranceDialogProps {
    insurance: LiabilityInsurance;
    toRefetchOnUpdate: string;
    onClose: () => void;
}

export default function LiabilityInsuranceDialog({insurance: ogInsurance, toRefetchOnUpdate, onClose}: LiabilityInsuranceDialogProps) {
    const [insurance, setInsurance] = useState({
        ...ogInsurance,
        activeDate: dateTimeStrToMdy(ogInsurance.activeDate),
        expirationDate: dateTimeStrToMdy(ogInsurance.expirationDate)
    });
    const [file, setFile] = useState<File | undefined>(undefined);
    const [activeDialogOpen, setActiveDialogOpen] = useState(false);
    const [expirationDialogOpen, setExpirationDialogOpen] = useState(false);

    const [upsertInsurance, {loading: upserting}] = useUpsertLiabilityInsuranceMutation({
        refetchQueries: [toRefetchOnUpdate]
    });
    const [reportUploadStatus, {loading: reportingStatus}] = useReportS3FileUploadStatusMutation();

    function validateAndPrepareData(): LiabilityInsuranceInput | undefined {
        const preparedInsurance = prepareWorkerDocument(insurance);
        if (isWorkerDocumentValid(insurance, true)) {
            return preparedInsurance;
        } else {
            return undefined;
        }
    }

    function onSubmit() {
        const preparedInsurance = validateAndPrepareData();
        if (isNotNullOrUndefined(preparedInsurance)) {
            const newMode = preparedInsurance!.id < 0;
            const hasFile = isNotNullOrUndefined(file);
            upsertInsurance({
                variables: {
                    liabilityInsurance: preparedInsurance!,
                    hasFile: hasFile
                }
            }).then(res => {
                const uploadInfo = res.data?.upsertLiabilityInsurance;
                if (hasFile && isNotNullOrUndefined(uploadInfo)) {
                    uploadFileToS3(file!, uploadInfo!.uploadUrl)
                    .then(res => {
                        reportUploadStatus({
                            variables: {
                                fileId: uploadInfo!.id,
                                succeeded: res.ok
                            }
                        });
                        if (res.ok) {
                            onClose();
                        } else {
                            alert("Something went wrong when uploading the file");
                        }
                    })
                    .catch(_ => {
                        reportUploadStatus({
                            variables: {
                                fileId: uploadInfo!.id,
                                succeeded: false
                            }
                        }).then(_ => onClose());
                    });
                } else {
                    if (hasFile) {
                        // uploadInfo should not be null if hasFile is true
                        alert("Something went wrong when adding a liability insurance file");
                    }
                    onClose();
                }
            })
            .catch(_ => alert(`Could not ${newMode ? "add" : "update"} liability insurance information`));
        }
    }

    const submitButton = (
        <FlatButton
            key={"submit-insurance"}
            onClick={onSubmit}
            variant="contained"
            color="secondary"
            disabled={upserting || reportingStatus}
        >Submit</FlatButton>
    );

    return (
        <FlatDialog
            dialogProps={{open: true, onClose: onClose}}
            sectionProps={{header: "Liability Insurance"}}
            actionButtons={[submitButton]}
        >
            <div className="flex-column flex-gap-sm">
                <FlatLabeledInput
                    label="Active Date"
                    value={insurance.activeDate}
                    readOnly
                    onClick={() => setActiveDialogOpen(true)}
                />
                <FlatLabeledInput
                    label="Expiration Date"
                    value={insurance.expirationDate}
                    readOnly
                    onClick={() => setExpirationDialogOpen(true)}
                />
            
                <FlatFileInput
                    accept=".pdf"
                    setSelectedFile={setFile}
                /> 
            </div>
            
            <SingleDateCalendarDialog
                selectedDate={mdyToDay(insurance.activeDate)}
                setSelectedDate={(newDay) => setInsurance({...insurance, activeDate: dayValueToMdy(newDay)})}
                open={activeDialogOpen}
                setOpen={setActiveDialogOpen}
                blockPastDays={false}
                />
            <SingleDateCalendarDialog
                selectedDate={mdyToDay(insurance.expirationDate)}
                setSelectedDate={(newDay) => setInsurance({...insurance, expirationDate: dayValueToMdy(newDay)})}
                open={expirationDialogOpen}
                setOpen={setExpirationDialogOpen}
                blockPastDays={false}
            />
        </FlatDialog>
    )
}