import { CarpetCutPiece } from "generated/graphql";

export function CarpetCutLineRendered({
    piece, stepLength, pixelScalar,
}: {
    piece: CarpetCutPiece;
    stepLength: number | null;
    pixelScalar: number;
}) {
    if (stepLength === null)
        return <></>;

    var numberOfSteps: number = 0;
    var minY: number = 0;
    var maxY: number = 0;
    var minX: number = 0;
    if (stepLength !== null) {
        minX = Math.min(...piece.packerShape.map((p) => p.x));
        const maxX = Math.max(...piece.packerShape.map((p) => p.x));
        const pieceLength = maxX - minX;

        numberOfSteps = Math.round(pieceLength / stepLength) - 1;
        minY = Math.min(...piece.packerShape.map((p) => p.y));
        maxY = Math.max(...piece.packerShape.map((p) => p.y));
    }

    return (
        <>
            {[...new Array(numberOfSteps).keys()].map((offset) => {
                const xPos = (minX + (offset + 1) * stepLength!) * pixelScalar;

                return (
                    <line
                        className="svg-packer-step-divider"
                        key={`${piece.id}-${offset}`}
                        x1={xPos}
                        x2={xPos}
                        y1={minY * pixelScalar}
                        y2={maxY * pixelScalar} />
                );
            })}
        </>
    );
}
