import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isEmptyString, isNotEmptyString, isPositiveNumber } from 'Globals/GenericValidators'
import { RootState } from './store'

export interface ProductValues {
    useProduct: boolean,
    existsInDatabase?: boolean,
    price: string,
    cost: string,
    minimum: string
}

interface LaborRateBuilder {
    laborType: number,
    existingServiceId: number,
    newLaborDescription: string,
    newUnit: string,
    productCosts: { [productId: number]: ProductValues }
}


interface LaborState { //Defined so that create slice can infer the type
    showTrash: boolean,
    changedPrices: { [key: number]: { price?: number, changed?: boolean } },
    changedCosts: { [key: number]: { cost?: number, changed?: boolean } },
    changedMinimums: { [key: number]: { minimum?: number, changed?: boolean } },
    newLaborRate: LaborRateBuilder,
    highlightErrors: boolean
}

const initialState: LaborState = {
    showTrash: false,
    changedPrices: {},
    changedCosts: {},
    changedMinimums: {},
    newLaborRate: {
        laborType: 0,
        existingServiceId: -1,
        newLaborDescription: "",
        newUnit: "Unselected",
        productCosts: {}
    },
    highlightErrors: false
}

export const isSingleProductCostsValid = (values: ProductValues) =>
    !values.useProduct ||
    (
        isLaborMoneyValid(values.price) &&
        isLaborMoneyValid(values.cost) &&
        isLaborMinimumValid(values.minimum)
    )

export function isAllProductCostsValid(productValues: { [productId: number]: ProductValues }) {
    var keys = Object.keys(productValues)

    var someToBeUsed = keys.map(key => productValues[+key].useProduct).includes(true)
    if (someToBeUsed === false) return false

    //Checks each product cost is valid
    var boolArray = keys.map((key) => isSingleProductCostsValid(productValues[+key]))

    //Checks all returned true
    return !boolArray.includes(false)
}

export const isLaborMoneyValid = (value: string) => isPositiveNumber(value)
export const isLaborMinimumValid = (value: string) => (isEmptyString(value) || isPositiveNumber(value))
export const isLaborTypeValid = (laborType: number) => laborType !== 0
export const isLaborDescriptionValid = (description: string) => isNotEmptyString(description)
export const isLaborUnitValid = (unit: string) => unit !== "Unselected" && isNotEmptyString(unit)

export function allValuesFilledAndValid(newLaborRate: LaborRateBuilder) {
    return isLaborTypeValid(newLaborRate.laborType)
        && (
            newLaborRate.existingServiceId > 0 ||
            (   
                newLaborRate.existingServiceId === 0
                && isLaborDescriptionValid(newLaborRate.newLaborDescription)
                && isLaborUnitValid(newLaborRate.newUnit)
            )
        )
        && isAllProductCostsValid(newLaborRate.productCosts)
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const laborSlice = createSlice({
    name: "labor",
    initialState,
    reducers: {
        setShowTrash(state, action: PayloadAction<boolean>) {
            state.showTrash = action.payload
        },
        changePrice: {
            reducer(state, action: PayloadAction<{ price?: number, rowId: number }>) {
                const { price, rowId } = action.payload
                state.changedPrices[rowId] = { price, changed: true }
            },
            prepare(rowId: number, price?: number) {
                return { payload: { price, rowId } }
            }
        },
        resetPrice: {
            reducer(state, action: PayloadAction<{ rowId: number }>) {
                const { rowId } = action.payload
                state.changedPrices[rowId] = {}
            },
            prepare(rowId: number) {
                return { payload: { rowId } }
            }
        },
        changeCost: {
            reducer(state, action: PayloadAction<{ cost?: number, rowId: number }>) {
                const { cost, rowId } = action.payload
                state.changedCosts[rowId] = { cost, changed: true }
            },
            prepare(rowId: number, cost?: number) {
                return { payload: { cost, rowId } }
            }
        },
        resetCost: {
            reducer(state, action: PayloadAction<{ rowId: number }>) {
                const { rowId } = action.payload
                state.changedCosts[rowId] = {}
            },
            prepare(rowId: number) {
                return { payload: { rowId } }
            }
        },
        changeMinimum: {
            reducer(state, action: PayloadAction<{ minimum?: number, rowId: number }>) {
                const { minimum, rowId } = action.payload
                state.changedMinimums[rowId] = { minimum, changed: true }
            },
            prepare(rowId: number, minimum?: number) {
                return { payload: { minimum, rowId } }
            }
        },
        resetMinimum: {
            reducer(state, action: PayloadAction<{ rowId: number }>) {
                const { rowId } = action.payload
                state.changedMinimums[rowId] = {}
            },
            prepare(rowId: number) {
                return { payload: { rowId } }
            }
        },
        setExistsInDatabase(state, action: PayloadAction<number[]>) {
            const existsInDatabase = action.payload
            existsInDatabase.forEach(pid => state.newLaborRate.productCosts[pid].existsInDatabase = true)
        },
        setProductValues(state, action: PayloadAction<{ productId: number, useProduct?: boolean, price?: string, cost?: string, minimum?: string }>) {
            const { productId, useProduct, price, cost, minimum } = action.payload
            const pc = state.newLaborRate.productCosts[productId]
            var potentialReferenceKeys = Object.keys(state.newLaborRate.productCosts).filter(key => Number(key) !== productId)
            const reference: ProductValues = (potentialReferenceKeys.length > 0)
                ? state.newLaborRate.productCosts[Number(potentialReferenceKeys[0])]
                : { useProduct: true, existsInDatabase: false, price: "", cost: "", minimum: "" }

            state.newLaborRate.productCosts[productId] = {
                useProduct: useProduct ?? pc?.useProduct ?? reference.useProduct,
                existsInDatabase: pc?.existsInDatabase,
                price: price ?? pc?.price ?? reference.price,
                cost: cost ?? pc?.cost ?? reference.cost,
                minimum: minimum ?? pc?.minimum ?? reference.minimum
            }
        },
        setType(state, action: PayloadAction<number>) {
            state.newLaborRate.laborType = action.payload
        },
        setExistingServiceId(state, action: PayloadAction<number>) {
            state.newLaborRate.existingServiceId = action.payload
        },
        setDescription(state, action: PayloadAction<string>) {
            state.newLaborRate.newLaborDescription = action.payload
        },
        setUnit(state, action: PayloadAction<string>) {
            state.newLaborRate.newUnit = action.payload
        },
        clearNewLaborRate(state) {
            state.newLaborRate = {
                laborType: 0,
                existingServiceId: -1,
                newLaborDescription: "",
                newUnit: "Unselected",
                productCosts: {}
            }
            state.highlightErrors = false
        },
        setLaborErrorMode(state, action: PayloadAction<boolean>) {
            state.highlightErrors = action.payload
        }
    }
})

export const {
    setShowTrash, changePrice, resetPrice, changeCost,
    resetCost, setType, setProductValues, setDescription, setUnit,
    clearNewLaborRate, setLaborErrorMode, changeMinimum, resetMinimum,
    setExistsInDatabase, setExistingServiceId
} = laborSlice.actions //Unpacks the actions created in the slice

export const selectNLRType = (state: RootState) => state.labor.newLaborRate.laborType
export const selectNLRExistingServiceId = (state: RootState) => state.labor.newLaborRate.existingServiceId
export const selectNLRDescription = (state: RootState) => state.labor.newLaborRate.newLaborDescription
export const selectNLRUnit = (state: RootState) => state.labor.newLaborRate.newUnit

export const selectNLRUseProduct = (productId: number) => (state: RootState) => state.labor.newLaborRate.productCosts[productId]?.useProduct ?? false
export const selectNLRExistsInDatabase = (productId: number) => (state: RootState) => state.labor.newLaborRate.productCosts[productId]?.existsInDatabase ?? false
export const selectNLRPrice = (productId: number) => (state: RootState) => state.labor.newLaborRate.productCosts[productId]?.price ?? ""
export const selectNLRCost = (productId: number) => (state: RootState) => state.labor.newLaborRate.productCosts[productId]?.cost ?? ""
export const selectNLRMinimum = (productId: number) => (state: RootState) => state.labor.newLaborRate.productCosts[productId]?.minimum ?? ""

export const selectShowTrash = (state: RootState) => state.labor.showTrash
export const selectHideTrash = (state: RootState) => !state.labor.showTrash

export const selectIsPriceDifferent = (id: number) => (state: RootState) => state.labor.changedPrices[id]?.changed
export const selectIsCostDifferent = (id: number) => (state: RootState) => state.labor.changedCosts[id]?.changed
export const selectIsMinimumDifferent = (id: number) => (state: RootState) => state.labor.changedMinimums[id]?.changed

export const selectPrice = (id: number) => (state: RootState) => state.labor.changedPrices[id]?.price
export const selectCost = (id: number) => (state: RootState) => state.labor.changedCosts[id]?.cost
export const selectMinimum = (id: number) => (state: RootState) => state.labor.changedMinimums[id]?.minimum

export const selectHighlightErrors = (state: RootState) => state.labor.highlightErrors

export default laborSlice.reducer