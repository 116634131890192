import { IconButton } from "@material-ui/core";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatDocumentButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatDocumentIcon color={color}/>
        </IconButton>
    )
}

export function FlatDocumentIcon({color="black"} : {color?: string}) {
    return <TextSnippetIcon htmlColor={color}/>
}