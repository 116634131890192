import { Button, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import { isNumber } from "Globals/GenericValidators";
import { useState } from "react";
import { openRoomSqftDialog, openServicesForRoomDialog, selectRoomSqftDialogOpen, selectRoomSqftDialogSqft, updateRoom } from "Redux/genericSalesReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { store } from "Redux/store";

export default function RoomSqftDialog() {
    const isPopupOpen = useAppSelector(selectRoomSqftDialogOpen)
    return (<>{isPopupOpen && <RoomSqftDialogInner />}</>)
}

function RoomSqftDialogInner() {
    const dispatch = useAppDispatch()
    const reduxSqft = useAppSelector(selectRoomSqftDialogSqft)
    const [sqft, setSqft] = useState<string>(((reduxSqft ?? -1) === -1) ? "" : reduxSqft!.toString());
    const [err, setErr] = useState<boolean>(false)

    function onClose() {
        dispatch(openRoomSqftDialog(-1))
    }

    function handleSubmit() {
        const roomIndex = store.getState().genericSales.sqftEditorDialogIndex
        const areaIndex = store.getState().genericSales.selectedAreaIndex
        if (isNumber(sqft)) {
            dispatch(updateRoom(areaIndex, roomIndex, { sqft: +sqft }))
            onClose()
            dispatch(openServicesForRoomDialog(roomIndex))
        }
        else {
            setErr(true)
        }
    }

    return (
        <Dialog open={true}>
            <DialogContent>
                <MeasureEditor sqft={sqft} setSqft={setSqft} err={err} />
            </DialogContent>
            <DialogActions>
                <Button className='cancel-button' onClick={onClose}>Cancel</Button>
                <Button className='submit-button' onClick={handleSubmit} >Next</Button>
            </DialogActions>
        </Dialog>
    )
}

interface MeasureEditorProps {
    sqft: string,
    setSqft: (newSqft: string) => void,
    err: boolean
}

function MeasureEditor({ sqft, setSqft, err }: MeasureEditorProps) {
    return (
        <TextField
            label='Measure'
            type='numeric'
            value={sqft}
            onChange={e => setSqft(e.target.value)}
            InputProps={{ endAdornment: 'SQFT', className: 'no-spinners' }}
            variant='outlined'
            helperText={(err) ? "Enter valid sqft" : undefined}
            error={err} />
    );
}