import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { GetSahAppointmentsForDateDocument, GetSahAppointmentsForDateQuery, GetSahAppointmentsForDateQueryVariables, namedOperations, SahAppointment, useUpdateSahAppointmentMutation } from "generated/graphql";
import { dateTimeStrToDay, dayToIso, dayToYmd } from "Globals/DateAndTimeHelpers";
import AvailableTimeSlots from "Pages/Admin/SAHScheduling/AvailableTimeSlots";
import { useState } from "react";
import { Calendar, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker";

interface RescheduleAppointmentMenuProps {
    currentAppointment: SahAppointment,
    keepSalesperson?: boolean,
    onClose: () => void
}

export default function RescheduleAppointmentMenu({ currentAppointment, keepSalesperson, onClose }: RescheduleAppointmentMenuProps) {
    const originalDay = dateTimeStrToDay(currentAppointment.date)

    const [day, setDay] = useState<DayValue>(originalDay)
    const [timeSlot, setTimeSlot] = useState<number | null>(currentAppointment.marketTimeSlot?.id ?? null)

    const [updateAppointment] = useUpdateSahAppointmentMutation({
        update(cache, result) {
            if (dayToIso(originalDay) !== dayToIso(day!)) {
                cache.updateQuery<GetSahAppointmentsForDateQuery, GetSahAppointmentsForDateQueryVariables>(
                    {
                        query: GetSahAppointmentsForDateDocument,
                        variables: {
                            date: dayToYmd(originalDay!), marketId: currentAppointment.marketTimeSlot.marketId
                        }
                    }, (data) => {
                        if (data === null) return null;

                        const updatedList = data.sAHAppointmentsForDate.filter(app => app.id !== currentAppointment.id)

                        return { ...data!, sAHAppointmentsForDate: updatedList };
                    }
                )

                if (result.data?.updateSAHAppointment !== undefined) {
                    cache.updateQuery<GetSahAppointmentsForDateQuery, GetSahAppointmentsForDateQueryVariables>(
                        {
                            query: GetSahAppointmentsForDateDocument,
                            variables: {
                                date: dayToYmd(day!), marketId: currentAppointment.marketTimeSlot.marketId
                            }
                        }, (data) => {
                            if (data === null) return null;

                            const updatedList = [...data.sAHAppointmentsForDate, result.data!.updateSAHAppointment]

                            return { ...data!, sAHAppointmentsForDate: updatedList };
                        }
                    )
                }
            }
        },
        refetchQueries: [namedOperations.Query.GetAllCallCenterJobs]
    })

    function updateDay(newVal: DayValue) {
        setTimeSlot(null);
        setDay(newVal)
    }

    function onReschedule() {
        if (timeSlot !== null) {
            updateAppointment({
                variables: {
                    sahAppointmentId: currentAppointment.id,
                    salespersonId: keepSalesperson ? currentAppointment.currentSalesperson?.salespersonId : undefined,
                    marketTimeSlotIds: timeSlot,
                    date: dayToIso(day!)
                }
            })
            onClose()
        }
    }

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>
                Reschedule Appointment
            </DialogTitle>
            <DialogContent>
                <div className="flex-column flex-centered" style={{ minHeight: "15rem", minWidth: "min(25rem, 85vw)" }}>
                    <div className="padding-sm">
                        <Calendar
                            value={day}
                            onChange={(day) => updateDay(day)}
                            minimumDate={utils('en').getToday()}
                        />
                    </div>
                    {
                        day?.day !== undefined &&
                        <div className="padding-sm">
                            <AvailableTimeSlots
                                selectedSlotId={timeSlot}
                                dayISO={dayToIso(day!)}
                                onSelectSlot={setTimeSlot}
                                zip={currentAppointment.primaryZipCode}
                            />
                        </div>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <SpacedButton className='cancel-button' onClick={onClose}>Cancel</SpacedButton>
                <SpacedButton variant="contained" color='secondary' onClick={onReschedule}>Reschedule</SpacedButton>
            </DialogActions>
        </Dialog>
    )
}