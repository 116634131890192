import { Calendar, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { Button, Dialog, DialogContent, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import {
    GetCallCenterJobDocument,
    GetCallCenterJobQueryVariables,
    namedOperations,
    useCancelSahAppointmentMutation,
    useGetCallCenterJobQuery,
    useGetCallCenterJobSummaryQuery,
    useSetCallCenterCallbackMutation,
    useSetCallCenterCanceledByCustomerMutation, useSetCallCenterQualificationMutation,
    useSetCallCenterReminderSentMutation,
    useUpdateCustomerDetailsMutation
} from "generated/graphql";
import { prepareCustomer } from "Globals/dataPreparationUtils";
import {
    dateTimeStrToDay,
    dateTimeStrToMdy,
    dateTimeStrToTime,
    dateToDay,
    dayToIso,
    dayToMdy,
    dayToYmd,
    HourMinute,
    hourMinuteTo12HHMM,
    timeSpanStrToHHMM12HR
} from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import { callCenterPath } from "Globals/PathStrings";
import { useAppHistory } from "Globals/routingHooks";
import { useState } from "react";
import RescheduleAppointmentMenu from "../CommandCenter/Dashboard/RescheduleAppointmentMenu";
import {
    Customer,
    InvoiceCustomerInformationTable
} from "../ProjectManagement/Invoice/InvoiceDocumentHeader";
import UpdateCustomerDetailDialog from "../ProjectManagement/Invoice/UpdateCustomerDetailDialog";
import { AppointmentNotesEditor } from "./AppointmentNotesEditor";
import { CallbackTimePicker, DateAndTime } from "./CallbackTimePicker";
import { ProspectNotesEditor } from "./ProspectNotesEditor";

export default function CallCenterExistingJob() {
    const { id: jobId } = useNumericIdParam();

    const history = useAppHistory();

    const { data: summaryData } = useGetCallCenterJobSummaryQuery({
        skip: isNullOrUndefined(jobId),
        variables: { jobId: jobId! },
        onError: () => alert("Could not retrieve details for job"),
    });

    const { data: callCenterJobData } = useGetCallCenterJobQuery({
        skip: isNullOrUndefined(jobId),
        variables: { jobId: jobId! },
    });

    const [qualifyOpen, setQualifyOpen] = useState(false);

    const callCenterJob = callCenterJobData?.callCenterJob;

    const customer = summaryData?.callCenterJobSummary.customer;
    const salesperson = summaryData?.callCenterJobSummary.mostRecentSalesperson;
    const appointment = summaryData?.callCenterJobSummary.appointment;
    const prioritiesStr = appointment?.priorityOptions?.map(po => po.text).join(", ");
    const colorCategoriesStr = appointment?.colorCategories?.map(cc => cc.category).join(", ");
    const productTypesStr = appointment?.productTypes?.join(", ");
    const statusStr = appointment?.isCancelled ? "Cancelled" : appointment?.appointmentStatusString;
    const apptDate: DayValue = appointment ? dateTimeStrToDay(appointment?.date) : undefined;
    const dateStr = apptDate ? dayToMdy(apptDate) : "";
    const apptTimeSlot = appointment?.marketTimeSlot;
    const startTimeStr = apptTimeSlot ? timeSpanStrToHHMM12HR(apptTimeSlot.startTime) : "";
    const endTimeStr = apptTimeSlot ? timeSpanStrToHHMM12HR(apptTimeSlot.endTime) : "";
    const apptTimeStr = `${dateStr} - ${startTimeStr} to ${endTimeStr}`;

    const [editCustomerOpen, setEditCustomerOpen] = useState(false);
    const [updateCustomerDetails] = useUpdateCustomerDetailsMutation({
        refetchQueries: [namedOperations.Query.GetCallCenterJobSummary],
        awaitRefetchQueries: true,
    });

    const [rescheduleDialogOpen, setRescheduleDialogOpen] = useState(false);

    const [confirmAppointment] = useSetCallCenterReminderSentMutation();

    const [cancelAppointment] = useCancelSahAppointmentMutation({
        refetchQueries: [
            namedOperations.Query.GetCallCenterJobSummary,
            namedOperations.Query.GetAllCallCenterJobs,
        ],
        awaitRefetchQueries: true,
        onError: () => alert("Could not cancel appointment"),
    });

    const [qualifyAppointment] = useSetCallCenterQualificationMutation({
        refetchQueries: [
            {
                query: GetCallCenterJobDocument,
                variables: { jobId: jobId! } as GetCallCenterJobQueryVariables,
            },
        ],
    });

    function onCancel() {
        if (appointment) {
            if (window.confirm("Are you sure you wish to cancel this SAH appointment?")) {
                cancelAppointment({
                    variables: { sahAppointmentId: appointment!.id },
                });
            }
        } else {
            alert("Could not cancel appointment - please try again");
        }
    }

    function onQualify(dateStr: string) {
        qualifyAppointment({
            variables: { jobId: jobId!, isQualified: true, remindCustomerDate: dateStr },
        });
    }

    const callbackString = buildCallbackTimeString(callCenterJob?.callBackTime ?? undefined);

    return (
        <NavbarPage>
            <div style={{ padding: "0 2rem 2rem 2rem" }}>
                <div
                    className="flex-row fill-width"
                    style={{ margin: ".25em" }}
                >
                    <div className="flex-grow">
                        <Button
                            onClick={() => history.push(callCenterPath)}
                            variant="contained"
                            style={{ marginRight: ".5em", fontSize: "1.5rem", padding: ".1em" }}
                        >
                            &lt;
                        </Button>
                    </div>
                    <h2 style={{ margin: "0" }}>Existing Job</h2>
                    <div className="flex-grow" />
                </div>
                <div className="flex-row">
                    <InvoiceCustomerInformationTable
                        onEdit={() => setEditCustomerOpen(true)}
                        renderExpanded={false}
                        {...customer!}
                        salesperson1={salesperson ?? undefined}
                    />
                </div>

                <div className="flex-row flex-space-between margin-top-sm">
                    <Typography><b>Priorities:</b> {prioritiesStr}</Typography>
                    <Typography><b>Color Categories:</b> {colorCategoriesStr}</Typography>
                    <Typography><b>Product Types:</b> {productTypesStr}</Typography>
                    <Typography><b>Number of Rooms:</b> {appointment?.numRooms}</Typography>
                </div>

                <div className="flex-row flex-space-between margin-top-xsm">
                    <Typography>
                        <b>Status:</b> {statusStr}
                    </Typography>
                    <Typography>
                        <b>Is Qualified:</b> {callCenterJob?.isQualified === true ? "Yes" : "No"}
                    </Typography>
                    <Typography>
                        <b>Remind On:</b>{" "}
                        {isNullOrUndefined(callCenterJob?.appointment?.timeToSendReminder)
                            ? "Not Set"
                            : dateTimeStrToMdy(callCenterJob?.appointment?.timeToSendReminder!)}
                    </Typography>
                    <Typography>
                        <b>Date:</b> {apptTimeStr}
                    </Typography>
                    <Typography>
                        <b>Callback:</b> {callbackString}
                    </Typography>
                    <Typography>
                        <b>Salesperson:</b>{" "}
                        {salesperson
                            ? `${salesperson.firstName} ${salesperson.lastName}`
                            : "Unassigned"}
                    </Typography>
                </div>

                <div className="flex-row justify-content-center margin-top-xsm">
                    <div
                        className="flex-column"
                        style={{ alignItems: "start", width: "40rem", minHeight: "10rem" }}
                    >
                        <Typography>Internal Notes:</Typography>
                        <ProspectNotesEditor
                            jobId={jobId ?? 0}
                            notes={callCenterJob?.prospectNotes ?? ""}
                        />
                    </div>
                </div>
                
                {isNotNullOrUndefined(callCenterJob?.appointment) && (
                    <div className="flex-row justify-content-center margin-top-xsm">
                        <div
                            className="flex-column"
                            style={{ alignItems: "start", width: "40rem", minHeight: "10rem" }}
                        >
                            <Typography>Appointment Notes (A-{callCenterJob?.appointment?.appointmentNumber}):</Typography>
                            <AppointmentNotesEditor
                                sahAppointmentId={callCenterJob?.appointment?.id ?? 0}
                                notes={callCenterJob?.appointment?.notes ?? ""}
                            />
                        </div>
                    </div>
                )}


                <div className="flex-row justify-content-center margin-top-xsm">
                    <SpacedButton
                        disabled={appointment?.isCancelled ?? true}
                        variant="contained"
                        onClick={onCancel}
                    >
                        Cancel
                    </SpacedButton>

                    {(isNotNullOrUndefined(appointment?.id) && !appointment?.isCancelled) && (
                        <CanceledByCustomerButton appointmentId={appointment!.id}/>
                    )}

                    <SpacedButton
                        hidden={(callCenterJob?.isQualified ?? true) === true}
                        disabled={appointment?.isCancelled ?? true}
                        variant="contained"
                        onClick={() => setQualifyOpen(true)}
                    >
                        Qualify
                    </SpacedButton>
                    
                    <SpacedButton
                        disabled={appointment?.isCancelled ?? true}
                        variant="contained"
                        onClick={() => setRescheduleDialogOpen(true)}
                    >
                        Reschedule
                    </SpacedButton>
                    
                    <CallbackButton
                        jobId={callCenterJob?.id}
                        callbackTime={callCenterJob?.callBackTime ?? undefined}
                    />

                    {callCenterJob?.appointment?.isCancelled === false &&
                        callCenterJob?.isQualified === true &&
                        isNullOrUndefined(callCenterJob.appointment?.timeReminderSent) && (
                            <SpacedButton
                                variant="contained"
                                onClick={() =>
                                    confirmAppointment({
                                        variables: {
                                            sahAppointmentId: callCenterJob?.appointment!.id,
                                        },
                                    })
                                }
                            >
                                Confirm Appointment
                            </SpacedButton>
                        )}
                </div>
            </div>

            {editCustomerOpen && (
                <UpdateCustomerDetailDialog
                    open={editCustomerOpen}
                    defaultCustomer={customer!}
                    onCancel={() => setEditCustomerOpen(false)}
                    onSubmit={(newCustomer: Customer) => {
                        updateCustomerDetails({
                            variables: { updatedCustomer: prepareCustomer(newCustomer) },
                        }).then(() => {
                            setEditCustomerOpen(false);
                        });
                    }}
                />
            )}

            {rescheduleDialogOpen && (
                <RescheduleAppointmentMenu
                    currentAppointment={appointment!}
                    onClose={() => setRescheduleDialogOpen(false)}
                />
            )}

            {qualifyOpen && (
                <ConfirmDatePicker
                    open={qualifyOpen}
                    onClose={(dateStr) => {
                        if (dateStr !== undefined) {
                            onQualify(dateStr);
                        }
                        setQualifyOpen(false);
                    }}
                    scheduledDate={apptDate}
                />
            )}
        </NavbarPage>
    );
}

function buildCallbackTimeString(callbackTimeString?: string): string {
    if (callbackTimeString === undefined) return "None";
    else {
        const dateStr = dateTimeStrToMdy(callbackTimeString);

        const time = dateTimeStrToTime(callbackTimeString);
        if (time.hour === 0) {
            return dateStr;
        } else return `${dateStr} at ${hourMinuteTo12HHMM(time, true)}`;
    }
}

function CallbackButton({ jobId, callbackTime }: { jobId?: number; callbackTime?: string }) {
    const [menuOpen, setMenuOpen] = useState(false);

    const currentDay: DayValue = callbackTime !== undefined ? dateTimeStrToDay(callbackTime) : null;
    const currentTime: HourMinute | undefined =
        callbackTime !== undefined ? dateTimeStrToTime(callbackTime) : undefined;

    const dayTimeValue: DateAndTime | undefined =
        currentDay !== null
            ? { date: currentDay!, time: currentTime!.hour > 1 ? currentTime : undefined }
            : undefined;

    const [updateCallbackTime] = useSetCallCenterCallbackMutation();

    return (
        <>
            <SpacedButton
                hidden={jobId === undefined}
                variant="contained"
                onClick={() => setMenuOpen(true)}
            >
                {callbackTime === undefined ? "Set Callback" : "Update Callback"}
            </SpacedButton>
            <CallbackTimePicker
                open={menuOpen}
                onClose={(v) => {
                    setMenuOpen(false);
                    if (v !== null) {
                        const baseDate = new Date(
                            v.date.year,
                            v.date.month - 1,
                            v.date.day,
                            v.time?.hour ?? 0,
                            v.time?.minute ?? 0
                        );

                        updateCallbackTime({
                            variables: {
                                jobId: jobId!,
                                callBackTime: baseDate.toISOString(),
                            },
                        });
                    }
                }}
                defaultValue={dayTimeValue}
            />
        </>
    );
}

function ConfirmDatePicker({
    open,
    onClose,
    scheduledDate,
}: {
    open: boolean;
    onClose: (dateStr?: string) => void;
    scheduledDate: DayValue;
}) {
    const [date, setDate] = useState<DayValue>(scheduledDate ?? utils('en').getToday());
    const [error, setError] = useState<string | null>(null);

    function submit() {
        if (isNullOrUndefined(date)) {
            setError("Select a date");
        } else {
            onClose(dayToYmd(date!));
        }
    }

    return (
        <Dialog open={open}>
            <DialogContent>
                <div
                    style={{ width: "30rem", height: "35rem", position: "relative" }}
                    className="flex-column flex-centered"
                >
                    <Typography
                        variant="h4"
                        style={{ marginTop: "1rem" }}
                    >
                        Date to Remind Customer
                    </Typography>
                    <Calendar
                        value={date}
                        onChange={setDate}
                        minimumDate={utils('en').getToday()}
                        maximumDate={scheduledDate ?? undefined}
                    />
                    <div className="flex-grow" />
                    <Typography
                        style={{ width: "100%", textAlign: "end" }}
                        className="error-text"
                    >
                        {error}
                    </Typography>

                    <div
                        className="flex-row fill-width"
                        style={{ justifyContent: "flex-end" }}
                    >
                        <SpacedButton
                            className="cancel-button"
                            onClick={() => onClose(undefined)}
                        >
                            Cancel
                        </SpacedButton>
                        <SpacedButton
                            variant="contained"
                            color="secondary"
                            onClick={submit}
                        >
                            Submit
                        </SpacedButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

function CanceledByCustomerButton({appointmentId}: {appointmentId: number}) {
    const [dialogOpen, setDialogOpen] = useState(false);

    return (<>
        <SpacedButton
            onClick={() => setDialogOpen(true)}
            variant="contained"
        >CBC</SpacedButton>

        {dialogOpen && (
            <CbcDialog appointmentId={appointmentId} onClose={() => setDialogOpen(false)} />
        )}
    </>)
}

interface CbcDialogProps {
    appointmentId: number;
    onClose: () => void;
}

function CbcDialog({appointmentId, onClose}: CbcDialogProps) {
    const today = new Date();
    const defaultDate = new Date(today.setMonth(today.getMonth() + 3));

    const [date, setDate] = useState<DayValue>(dateToDay(defaultDate));
    const [notes, setNotes] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    const [setCbc, { loading }] = useSetCallCenterCanceledByCustomerMutation({
        refetchQueries: [
            namedOperations.Query.GetCallCenterJobSummary,
            namedOperations.Query.GetAllCallCenterJobs,
        ]
    });

    function submit() {
        if (isNotNullOrUndefined(date)) {
            setCbc({
                variables: {
                    appointmentId: appointmentId,
                    callbackDate: dayToIso(date!),
                    notes: notes,
                },
                onCompleted() { onClose(); },
                onError(error) {
                    setError(error.message);
                },
            });
        } else {
            setError("Select a date");
        }
    }

    return (
        <Dialog open={true}>
            <DialogContent>
                <div
                    style={{ width: "30rem", height: "35rem", position: "relative" }}
                    className="flex-column flex-centered"
                >
                    <Typography
                        variant="h4"
                        style={{ marginTop: "1rem" }}
                    >
                        Next Attempt Date
                    </Typography>

                    <Calendar
                        calendarClassName={clsx({ "visibility-hidden": loading })}
                        value={date}
                        onChange={setDate}
                        minimumDate={utils("en").getToday()}
                    />

                    <Typography style={{ width: "100%", textAlign: "start" }}>
                        Notes about attempt:
                    </Typography>
                    
                    <TextField
                        disabled={loading}
                        multiline
                        fullWidth
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />

                    <div className="flex-grow" />
                    
                    <Typography
                        style={{ width: "100%", textAlign: "end" }}
                        className="error-text"
                    >
                        {error}
                    </Typography>

                    {loading && (
                        <div
                            style={{
                                position: "absolute",
                                width: "30rem",
                                height: "35rem",
                                top: 0,
                                left: 0,
                            }}
                        >
                            <Loading />
                        </div>
                    )}

                    <div
                        className="flex-row fill-width"
                        style={{ justifyContent: "flex-end" }}
                    >
                        <SpacedButton
                            disabled={loading}
                            className="cancel-button"
                            onClick={onClose}
                        >Cancel</SpacedButton>
                        
                        <SpacedButton
                            disabled={loading}
                            variant="contained"
                            color="secondary"
                            onClick={submit}
                        >
                            Submit
                        </SpacedButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
