import clsx from "clsx";
import BaseProjectCard from "./BaseProjectCard";
import "./project-card-components.css";
import ProjectCardCustomerBar from "./ProjectCardCustomerBar";

export interface BaseProjectCardProps {
    agentName?: string,
    agentIcon?: React.ReactNode,
    chips?: React.ReactNode[],
    cardNumber: string,
    customerName: string,
    customerCity: string,
    timeSlot?: React.ReactNode,
    onCustomerClick?: () => void,
    className?: string,
    dividerClass?: string,
    showMoreMenu?: boolean
}

interface ProjectCardProps extends React.PropsWithChildren<BaseProjectCardProps> {
    jobId?: number;
    jobConfigurationId?: number;
    contractId?: number;
    date?: string | React.ReactNode;
    sahNumber?: string;
    onShowMore?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

export default function ProjectCard({
    children,
    agentName,
    agentIcon,
    chips=[],
    cardNumber,
    customerName,
    customerCity,
    timeSlot, // remove when recovery cards are redone
    onCustomerClick,
    dividerClass,
    onShowMore,
    jobId,
    jobConfigurationId,
    contractId,
    date,
    sahNumber,
    ...props
}: ProjectCardProps) {
    return (
        <DividedProjectCard className={props.className}
            topSection={
                <ProjectCardCustomerBar
                    onCustomerClick={onCustomerClick}
                    customerName={customerName}
                    customerCity={customerCity}
                    cardNumber={cardNumber}
                    statusChips={<>{chips}</>}
                    dividerClass={dividerClass}
                    onShowMore={onShowMore}
                    jobId={jobId}
                    jobConfigurationId={jobConfigurationId}
                    contractId={contractId}
                    date={date}
                    sahNumber={sahNumber}             
                />
            }
            middleSection={<></>}
            bottomSection={children} 
        />
    );
}

interface DividedProjectCardProps {
    className?: string,
    topSection: React.ReactNode,
    middleSection: React.ReactNode,
    bottomSection: React.ReactNode
}

export function DividedProjectCard({ className, topSection, middleSection, bottomSection }: DividedProjectCardProps) {
    return (
        <BaseProjectCard className={clsx("project-card", className)}>
            <div>
                {topSection}
            </div>
            <div>
                {middleSection}
            </div>
            <div className="rc-content">
                {bottomSection}
            </div>
        </BaseProjectCard>
    )
}