import { RoomForWhoDoesService } from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/BreakdownTableUtils";
import { useGetAllServiceProductOfferingsQuery } from "generated/graphql";
import { useAppSelector } from "Redux/hooks";
import { selectMSRPScalar } from "Redux/pricingCenterReducer";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import "./FormulaCardStyle.css";

interface FormulaCardProps {
    renderOverElement: React.RefObject<HTMLDivElement>;
    rooms: RoomForWhoDoesService[];
    show: boolean;
}

export default function FormulaCard({renderOverElement, rooms, show}: FormulaCardProps) {
    const request = useGetAllServiceProductOfferingsQuery();
    const msrpScalar = useAppSelector(selectMSRPScalar);
    return (
        <div
            className="formula-popper"
            style={{ right: "-10px", position: "absolute", display: show ? undefined : "none", zIndex: "9999999px" }}
            ref={renderOverElement}
        >
            <table className="formula">
                <thead>
                    <tr>
                        <th>Room</th>
                        <th>Labor</th>
                        <th>Price</th>
                        <th>Formula</th>
                    </tr>
                </thead>
                <tbody>
                    {rooms.map(r => {
                        const js = request?.data?.allServiceProductOfferings.find((psj) => psj.jobServiceId === r.service.jobServiceId);
                        return (
                        <tr key={r.service.id}>
                            <td style={{ minWidth: "5rem" }}>{getNameOfArea(r.labels)}</td>
                            <td>{r.service.laborAmount.toFixed(2)}</td>
                            <td align="right">{r.service.price.toFixed(2)}</td>
                            <td>
                                <span style={{whiteSpace: 'nowrap'}}>{r.service.laborAmount.toFixed(2)} * {js?.pricePerUnit} * {msrpScalar} = {(r.service.laborAmount * (js?.pricePerUnit ?? 0) * msrpScalar).toFixed(2)}</span>
                            </td>
                        </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}
