import { Button, Checkbox, MenuItem, Select, SelectProps, TextField } from "@material-ui/core";
import { AnyAction } from "@reduxjs/toolkit";
import clsx from "clsx";
import {
    GetProductTypeOptionsQuery,
    ProductAndValuesInput,
    useAddMultipleOfferingsToExistingServiceMutation,
    useAddMultipleOfferingsToNewServiceMutation,
    useGetJobServicesByTypeQuery,
    useGetProductTypeOptionsQuery,
    useGetServicePricingUnitNamesQuery,
    useGetServiceTypesQuery,
    namedOperations
} from "generated/graphql";
import { CARPET_PRODUCT_ID, SPC_PRODUCT_ID, WOOD_PRODUCT_ID } from "Globals/globalConstants";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
    allValuesFilledAndValid, clearNewLaborRate, isLaborDescriptionValid, isLaborMinimumValid, isLaborMoneyValid, isLaborTypeValid,
    isLaborUnitValid, ProductValues, selectHighlightErrors, selectNLRCost, selectNLRDescription, selectNLRExistingServiceId, selectNLRExistsInDatabase, selectNLRMinimum, selectNLRPrice, selectNLRType,
    selectNLRUnit, selectNLRUseProduct, setDescription, setExistingServiceId, setExistsInDatabase, setLaborErrorMode, setProductValues, setType, setUnit
} from "Redux/laborReducer";
import { RootState, store } from "Redux/store";
import { MoneyNumberFormat } from "./Components/MoneyNumberFormat";

type tdProps = Pick<React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>, "align" | "className">

function isEmpty(value: string) {
    return value === ""
}

export interface ProductSelectProps extends SelectProps {
    unselectedOptionText?: string
}

export function ProductSelect({unselectedOptionText, ...props}: ProductSelectProps) {

    const { data  } = useGetProductTypeOptionsQuery()
    const allowedProductOptions = [WOOD_PRODUCT_ID, SPC_PRODUCT_ID, CARPET_PRODUCT_ID];
    const productOptions = [{ id: 0, type: unselectedOptionText ?? "Unselected" }, ...(data?.options ?? []).filter(opt => allowedProductOptions.includes(opt.id))]

    return (
        <Select {...props} className={clsx("w-10r", props.style, props.className)}>
            {
                productOptions.map(value => <MenuItem key={`product-editor-${value.id}`} value={value.id}>{value.type}</MenuItem>)
            }
        </Select>
    )
}

export interface LaborTypeSelectProps extends SelectProps {
    unselectedOptionText?: string
}

export function LaborTypeSelect({unselectedOptionText, ...props}: LaborTypeSelectProps) {

    const { data } = useGetServiceTypesQuery()

    const typeOptions = [{ id: 0, name: unselectedOptionText ?? "Unselected" }, ...(data?.options ?? [])]


    return (
        <Select {...props} className={clsx("w-10r", props.style, props.className)}>
            {
                typeOptions.map(value => <MenuItem key={`type-editor-${value.id}`} value={value.id}>{value.name}</MenuItem>)
            }
        </Select>
    )
}

function LaborTypeEditor(props: tdProps) {
    const selectedType = useAppSelector(selectNLRType)
    const highlightErrors = useAppSelector(selectHighlightErrors)
    const dispatch = useAppDispatch()

    const isError = highlightErrors && !isLaborTypeValid(selectedType)

    function changeSelectedValue(event: React.ChangeEvent<{ value: unknown }>) {
        const value = event.target.value as number
        dispatch(setType(value))
        dispatch(setExistingServiceId(-1))
    }

    return (
        <td {...props}>
            <LaborTypeSelect value={selectedType} onChange={changeSelectedValue} error={isError} />
        </td>
    )
}

function DescriptionAndUnitEditor(props: tdProps) {
    const dispatch = useAppDispatch()
    const selectedType = useAppSelector(selectNLRType)
    const highlightErrors = useAppSelector(selectHighlightErrors)
    const existingServiceId = useAppSelector(selectNLRExistingServiceId)
    const { data, loading } = useGetJobServicesByTypeQuery({ variables: { serviceTypeId: selectedType }, skip: selectedType < 0, fetchPolicy:"network-only" })

    if (loading || data === undefined || data === null || selectedType < 1) return (
        <>
            <tr className="h-3r" />
            <tr className="h-3r" />
        </>
    )

    if (existingServiceId !== 0) { //Selecting existing
        const selectedService = (existingServiceId > 0) ? data.jobServicesByType.find(ser => ser.id === existingServiceId) : undefined
        const isError = highlightErrors && existingServiceId < 1

        return (
            <>
                <tr className="h-3r">
                    <td align="right">Description:</td>
                    <td>
                        <Select error={isError} className="fill-width" value={existingServiceId} onChange={(e) => dispatch(setExistingServiceId(e.target.value as number))}>
                            <MenuItem value={-1} >Unselected</MenuItem>
                            {
                                data.jobServicesByType.map(
                                    service => <MenuItem key={"service-" + service.id} value={service.id}>{service.description}</MenuItem>
                                )
                            }
                            <MenuItem value={0}>Add New</MenuItem>
                        </Select>
                    </td>
                </tr>
                <tr className="h-3r">
                    {
                        selectedService !== undefined &&
                        <>
                            <td align="right">Unit:</td>
                            <td>{selectedService.priceUnit}</td>
                        </>
                    }
                </tr>
            </>

        )
    }
    else return ( //Making new description
        <>
            <tr className="h-3r">
                <td align="right">Description:</td>
                <NewDescriptionEditor />
            </tr>
            <tr className="h-3r">
                <td align="right">Unit:</td>
                <NewUnitEditor />
            </tr>
        </>
    )


}

function NewDescriptionEditor(props: tdProps) {

    const description = useAppSelector(selectNLRDescription)
    const highlightErrors = useAppSelector(selectHighlightErrors)
    const dispatch = useAppDispatch()


    const isError = highlightErrors && !isLaborDescriptionValid(description)

    function changeSelectedValue(event: React.ChangeEvent<{ value: string }>) {
        const value = event.target.value
        dispatch(setDescription(value))
    }

    return (
        <td {...props}>
            <TextField className="w-10r" value={description} onChange={changeSelectedValue} error={isError} InputProps={{
                endAdornment:
                    <Button onClick={() => {
                        dispatch(setDescription(""))
                        dispatch(setExistingServiceId(-1))
                    }} style={{ minWidth: 0, width: "2em" }} >
                        X
                    </Button>
            }} />
        </td>
    )
}

function NewUnitEditor(props: tdProps) {
    const selectedUnit = useAppSelector(selectNLRUnit)
    const highlightErrors = useAppSelector(selectHighlightErrors)
    const dispatch = useAppDispatch()

    const { data, loading, error } = useGetServicePricingUnitNamesQuery()
    if (loading || error || data === undefined) return <td {...props} />

    const unitOptions = ["Unselected", ...data.names]

    const isError = highlightErrors && !isLaborUnitValid(selectedUnit)

    function changeSelectedValue(event: React.ChangeEvent<{ value: unknown }>) {
        const value = event.target.value as string
        dispatch(setUnit(value))
    }

    return (
        <td {...props}>
            <Select value={selectedUnit} onChange={changeSelectedValue} style={{ width: "10rem" }} error={isError}>
                {
                    unitOptions.map(value => <MenuItem key={`unit-editor-${value}`} value={value}>{value}</MenuItem>)
                }
            </Select>
        </td>
    )
}

interface MoneyEditorProps extends tdProps {
    valueSelector: (state: RootState) => string,
    valueValidator: (value: string) => boolean
    updateValue: (newValue: string) => AnyAction,
    hidePrefix?: boolean
}

function MoneyEditor({ valueSelector, valueValidator, updateValue, hidePrefix, className, ...props }: MoneyEditorProps) {
    const value = useAppSelector(valueSelector)
    const highlightErrors = useAppSelector(selectHighlightErrors)
    const dispatch = useAppDispatch()

    const isError = highlightErrors && !valueValidator(value)

    return (
        <td align="center" {...props} className={clsx(className, "w-10r")}>
            <MoneyNumberFormat hidePrefix={hidePrefix} style={{ width: "7rem" }} value={value} isError={isError} onValueChange={(values) => {
                if (values.value !== value) dispatch(updateValue(values.value))
            }} />
        </td>
    )
}

type ProductRowProps = Pick<GetProductTypeOptionsQuery["options"][number], "id" | "type">

function ProductRowEditor({ id, type }: ProductRowProps) {
    const useProduct = useAppSelector(selectNLRUseProduct(id))
    const selectExistsInDatabase = useAppSelector(selectNLRExistsInDatabase(id))
    const dispatch = useAppDispatch()

    const selectPrice = selectNLRPrice(id)
    const selectCost = selectNLRCost(id)
    const selectMinimum = selectNLRMinimum(id)

    const updatePrice = (value: string) => setProductValues({ productId: id, price: value })
    const updateCost = (value: string) => setProductValues({ productId: id, cost: value })
    const updateMinimum = (value: string) => setProductValues({ productId: id, minimum: value })

    return (
        <tr className={clsx({ "error-bkg": (selectExistsInDatabase && useProduct) })}>
            <td>

                <div className="flex-row" style={{ alignItems: "center" }}>
                    <Checkbox checked={useProduct} onChange={(_, checked) => dispatch(setProductValues({ productId: id, useProduct: checked }))} />
                    <label style={{ paddingLeft: ".1rem" }}>
                        {type}
                    </label>
                </div>
            </td>
            <MoneyEditor
                className={clsx({ 'visibility-hidden': !useProduct })}
                valueSelector={selectPrice}
                valueValidator={isLaborMoneyValid}
                updateValue={updatePrice} />
            <td className={clsx({ 'visibility-hidden': !useProduct })}>Placeholder...</td>
            <MoneyEditor
                className={clsx({ 'visibility-hidden': !useProduct })}
                valueSelector={selectCost}
                valueValidator={isLaborMoneyValid}
                updateValue={updateCost} />
            <td className={clsx({ 'visibility-hidden': !useProduct })}>Placeholder...</td>
            <MoneyEditor
                className={clsx({ 'visibility-hidden': !useProduct })}
                valueSelector={selectMinimum}
                valueValidator={isLaborMinimumValid}
                updateValue={updateMinimum}
                hidePrefix />
        </tr>
    )
}

function renderProductRowEditor(props: ProductRowProps) {
    return <ProductRowEditor key={`product-row-id-${props.id}`} {...props} />
}

export default function LaborRateSpecifier() {

    const [addToExisting] = useAddMultipleOfferingsToExistingServiceMutation()
    const [addToNew] = useAddMultipleOfferingsToNewServiceMutation()
    //const [addMultipleLaborRules] = useAddMultipleLaborRulesMutation();
    const { data: productOptions } = useGetProductTypeOptionsQuery()

    const dispatch = useAppDispatch();

    function trySubmitNewProduct() {
        const submitData = store.getState().labor.newLaborRate
        if (allValuesFilledAndValid(submitData)) {
            const extractProductValues = (id: number, values: ProductValues): ProductAndValuesInput =>
            ({
                productTypeId: id,
                price: +values.price,
                cost: +values.cost,
                minimum: (isEmpty(values.minimum) ? undefined : +values.minimum)
            })

            const validProducts = Object.keys(submitData.productCosts).map(key => +key).filter(key => submitData.productCosts[key]?.useProduct ?? false) //.map(key=>submitData.productCosts[key])
            const productValues = validProducts.map(id => extractProductValues(id, submitData.productCosts[id]))

            if (submitData.existingServiceId > 0) {
                addToExisting({
                    variables: {
                        jobServiceId: submitData.existingServiceId,
                        productValues
                    }, update(cache, result) {
                        const { errorProductIds, addedIds } = result.data?.addMultipleOfferingsToExistingService!
                        if (errorProductIds.length === 0) {
                            cache.modify(
                                {
                                    fields: {
                                        serviceProductOfferingIds(existing = []) {
                                            if (addedIds === undefined) return existing
                                            return [...existing, ...addedIds]
                                        }
                                    }
                                })
                            dispatch(clearNewLaborRate())
                        }
                        else {
                            dispatch(setExistsInDatabase(errorProductIds))
                            dispatch(setLaborErrorMode(true))
                            alert("Some rows were rejected");
                        }
                    }
                })
            }
            else {
                addToNew({
                    variables: {
                        serviceTypeId: submitData.laborType,
                        description: submitData.newLaborDescription,
                        priceUnit: submitData.newUnit,
                        productValues
                    }, update(cache, result) {
                        const addedIds = result.data?.addMultipleOfferingsToNewService!
                        // if (errors !== undefined && result.errors.length > 0) {
                        //     alert("There was a problem")
                        // }
                        // else {
                            cache.modify(
                                {
                                    fields: {
                                        serviceProductOfferingIds(existing = []) {
                                            if (addedIds === undefined) return existing
                                            return [...existing, ...addedIds]
                                        }
                                    }
                                })


                            dispatch(clearNewLaborRate())
                        //}
                    },
                    refetchQueries: [namedOperations.Query.GetJobServicesByType],
                    awaitRefetchQueries: true
                })
            }

        }
        else {
            dispatch(setLaborErrorMode(true))
            alert("Fill all missing fields");
        }
    }

    return (
        <div className="flex-row" style={{ alignItems: "center", justifyContent: "space-around", overflow: "hidden" }}>
            <table>
                <tbody>
                    <tr className="h-3r">
                        <td align="right">Labor Type:</td>
                        <LaborTypeEditor />
                    </tr>
                    <DescriptionAndUnitEditor />
                </tbody>
            </table>
            <div className="table-fix-head" style={{ maxHeight: "11rem", height: "100rem" }}>
                <table >
                    <thead>
                        <tr>
                            <td align="center" className="h-2r">Product</td>
                            <td align="center" className="h-2r">Price</td>
                            <td align="center" className="h-2r">Bronze Cost</td>
                            <td align="center" className="h-2r">Silver Cost</td>
                            <td align="center" className="h-2r">Gold Cost</td>
                            <td align="center" className="h-2r">Minimum</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (productOptions?.options! ?? []).map(renderProductRowEditor)
                        }
                    </tbody>
                </table>
            </div>
            <div className="flex-column">
                <Button variant="contained" onClick={trySubmitNewProduct}>
                    Submit
                </Button>
                <Button style={{ marginTop: ".5rem" }} variant="outlined" onClick={() => {
                    if (window.confirm("Are you sure you want to clear the form?")) {
                        dispatch(clearNewLaborRate())
                    }
                }}>
                    Clear
                </Button>
            </div>
        </div>
    )
}