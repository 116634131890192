import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TransactionViewRow } from 'Components/TransactionViewer'
import { getDescriptionOfLineItem, LineItem } from 'Globals/DataStructures/Transactions'
import { RootState } from './store'

interface InventoryState {
    transactionEntries: TransactionViewRow[],
}

const initialState: InventoryState = {
    transactionEntries: []
}

export const inventorySlice = createSlice({
    name: "inventory",
    initialState,
    reducers: {
        addTransaction: (state, action: PayloadAction<LineItem>) => {
            const id = state.transactionEntries.length ?? 0;
            const lineItem: LineItem = { ...action.payload, lineItemNumber: id.toString() };

            console.log('inventoryReducer.addTransaction', lineItem);

            const newEntry: TransactionViewRow = {
                id: lineItem.lineItemNumber!,
                lineItem,
                ...lineItem.inventoryEntry,
                description: getDescriptionOfLineItem(action.payload),
            }

            state.transactionEntries = [...state.transactionEntries, newEntry];
        },

        updateTransaction: (state, action: PayloadAction<LineItem>) => {
            const lineItem = action.payload;
            if (lineItem.lineItemNumber) {
                const description = getDescriptionOfLineItem(lineItem)
                const newData: TransactionViewRow = {
                    id: lineItem.lineItemNumber,
                    lineItem,
                    ...lineItem.inventoryEntry,
                    description
                }

                console.log(state.transactionEntries);
                state.transactionEntries = state.transactionEntries.map(row => (row.lineItem.lineItemNumber === newData.lineItem.lineItemNumber) ? newData : row)
            }
        },

        clearTransactions: (state) => {
            state.transactionEntries = []
        },

        removeTransaction: (state, action: PayloadAction<{ id: number }>) => {
            let copy = [...state.transactionEntries]
            copy.splice(action.payload.id, 1)
            state.transactionEntries = copy
        }
    }
})

export const { addTransaction, updateTransaction, clearTransactions, removeTransaction } = inventorySlice.actions

export const selectTransactionEntries = (state: RootState) => state.inventory.transactionEntries

export default inventorySlice.reducer

