import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatForJob, Worker } from 'generated/graphql';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Globals/GenericValidators';
import { RootState } from './store';

interface ChatDrawerState { //Defined so that create slice can infer the type
    drawerOpen: boolean;
    sendingWorker?: Worker;
    openChats: ChatForJob[];
    closedChats: ChatForJob[];
}

const initialState: ChatDrawerState = {
    drawerOpen: false,
    sendingWorker: undefined,
    openChats: [],
    closedChats: []
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const commandCenterChatDrawerSlice = createSlice({
    name: "commandCenterChatDrawer",
    initialState,
    reducers: { //DON'T Call async functions in here. No
        setDrawerOpen: (state, action: PayloadAction<boolean>) => { state.drawerOpen = action.payload; },
        setSendingWorker: (state, action: PayloadAction<Worker>) => { state.sendingWorker = action.payload; },
        setClosedChats: (state, action: PayloadAction<ChatForJob[]>) => { state.closedChats = action.payload },
        closeAllChats: (state) => {
            state.openChats.forEach(toClose => {
                state.closedChats = [ ...state.closedChats, toClose ];  
            });
            state.openChats = [];
        },
        closeChat: (state, action: PayloadAction<ChatForJob>) => {
            state.openChats = state.openChats.filter(openChat => openChat.chatId !== action.payload.chatId);
            state.closedChats = [...state.closedChats, action.payload];
        },
        // can be used both to add the chat to the drawer for the first time, or to go from a closed state to an open state
        openChat: (state, action: PayloadAction<ChatForJob>) => {
            state.drawerOpen = true;
            state.closedChats = state.closedChats.filter(closedChat => closedChat.chatId !== action.payload.chatId);
            // check to see if that is already open to prevent opening twice
            if (isNullOrUndefined(state.openChats.find(openChat => openChat.chatId === action.payload.chatId))) {
                state.openChats = [...state.openChats, action.payload];
            }
        },
        // can only be used when the chat is already in the drawer, unlike OpenChat
        openChatById: (state, action: PayloadAction<number>) => {
            const toOpenId = action.payload;
            const toOpen = [...state.closedChats, ...state.openChats].find(chat => chat.chatId === toOpenId);
            if (isNullOrUndefined(toOpen)) {
                throw new Error(`Tried opening chat with ID ${toOpenId}, but no such chat exists in the drawer`);
            } else {
                state.drawerOpen = true;
                state.closedChats = state.closedChats.filter(chat => chat.id !== toOpenId);
                // prevent the chat being opened multiple times
                state.openChats = [...state.openChats.filter(chat => chat.id !== toOpenId, {...toOpen!})]
            }
        },
        removeChat: (state, action: PayloadAction<number>) => {
            const toRemove = action.payload;
            // filter out of both the open and closed chats
            state.openChats = state.openChats.filter(openChat => openChat.chatId !== toRemove);
            const newClosedChats = state.closedChats.filter(openChat => openChat.chatId !== toRemove);
            state.closedChats = newClosedChats;
            
            // closes the drawer when there are no more chats to show in there
            if (newClosedChats.length === 0) {
                state.drawerOpen = false;
            }
        },
        // just pass it the new version of the chat, and it will find what to replace via the chatId field
        // used when one CC worker is swapped for another within the same chat
        replaceChat: (state, action: PayloadAction<ChatForJob>) => {
            const replacement = action.payload;

            // the chat to replace is open - need to remove from list of open chats before adding
            const toReplace = state.openChats.find(openChat => openChat.chatId === replacement.chatId);
            if (isNotNullOrUndefined(toReplace)) {
                state.openChats = [
                    ...state.openChats.filter(openChat => openChat.chatId !== replacement.chatId),
                    {...replacement}
                ];   
            }
            
            // the chat to replace is closed - need to remove from the list of closed chats before adding
            else if (state.closedChats.find(closedChat => closedChat.chatId === replacement.chatId)) {
                state.closedChats = [
                    ...state.closedChats.filter(closedChat => closedChat.chatId !== replacement.chatId),
                    {...replacement}
                ];   
            }
        },
        // updates whether the command center party member for a chat has a new message
        setChatNewMessageStatus: (state, action: PayloadAction<{chatId: number; hasNewMessage: boolean}>) => {
            const {chatId, hasNewMessage} = action.payload;
            let updateIdx = state.openChats.findIndex(c => c.chatId === chatId);
            if (updateIdx > -1) {
                const updated: ChatForJob = {
                    ...state.openChats[updateIdx],
                    commandCenterMember: {
                        ...state.openChats[updateIdx].commandCenterMember,
                        hasNewMessage: hasNewMessage
                    }
                };
                const updatedChats = [...state.openChats];
                updatedChats.splice(updateIdx, 1, updated);
                state.openChats = updatedChats;

            } else {
                // check whether the chat with the new message is closed
                updateIdx = state.closedChats.findIndex(c => c.chatId === chatId);

                if (updateIdx > -1) {
                    const updated: ChatForJob = {
                        ...state.closedChats[updateIdx],
                        commandCenterMember: {
                            ...state.closedChats[updateIdx].commandCenterMember,
                            hasNewMessage: hasNewMessage
                        }
                    };
                    const updatedChats = [...state.closedChats]
                    updatedChats.splice(updateIdx, 1, updated);
                    state.closedChats = updatedChats;
                }
            }
        }
    }
});

export const { setDrawerOpen, setSendingWorker, setClosedChats, closeAllChats, closeChat, openChat, openChatById, removeChat, replaceChat, setChatNewMessageStatus } = commandCenterChatDrawerSlice.actions //Unpacks the actions created in the slice

export const selectDrawerOpen = (state: RootState) => state.chatDrawer.drawerOpen;
export const selectSendingWorker = (state: RootState) => state.chatDrawer.sendingWorker;
export const selectOpenChats = (state: RootState) => state.chatDrawer.openChats;
export const selectClosedChats = (state: RootState) => state.chatDrawer.closedChats;

export default commandCenterChatDrawerSlice.reducer;