import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import FlatButton from "FlatComponents/Button/FlatButton";
import { Customer, SahAppointment, useGetCustomerQuery, useUpdateCustomerDetailsMutation, useUpdateSahAppointmentDetailsMutation } from "generated/graphql";
import { prepareCustomer } from "Globals/dataPreparationUtils";
import { dateTimeStrToMdy, marketTimeSlotToStr } from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { CustomerDialogView } from "Pages/Admin/ProjectManagement/Invoice/UpdateCustomerDetailDialog";
import { useState } from "react";

interface CustomerDialogProps {
    appointment: SahAppointment;
    onClose: () => void;
}

export default function EditDialogMenu({ appointment, onClose }: CustomerDialogProps) {
    const [updateCustomerDetails] = useUpdateCustomerDetailsMutation();
    const [updateAppointment] = useUpdateSahAppointmentDetailsMutation();

    function updateFields(customer?: Customer, appointment?: SahAppointment) {
        if (!(customer === undefined || appointment === undefined)) {
            updateCustomerDetails({
                variables: {
                    updatedCustomer: prepareCustomer(customer),
                },
            }).then(() => {
                updateAppointment({
                    variables: {
                        sahAppointmentId: appointment.id,
                        customerComments: appointment.comments ?? "",
                    },
                }).then(() => onClose());
            });
        } else onClose();
    }

    const { data } = useGetCustomerQuery({
        variables: { customerId: appointment.customerId },
        fetchPolicy: "network-only",
    });

    return (
        <Dialog
            PaperProps={{style: {borderRadius: "var(--flat-outer-container-radius)", paddingBottom: "var(--flat-outer-container-padding)"}}}
            open={true}
            onClose={onClose}
            maxWidth="xl"
        >
            <div className="flat-section-header flex-row justify-content-center">
                <h1 className="flat-font-lg margin-vertical-xsm">
                    Contact Details
                </h1>
            </div>

            {!isNullOrUndefined(data?.customer) && (
                <CustomerDialogMenuInner
                    initalCustomer={data?.customer!}
                    initialAppointment={appointment}
                    onClose={updateFields}
                />
            )}
        </Dialog>
    );
}

interface CustomerDialogInnerProps {
    initalCustomer: Customer;
    initialAppointment: SahAppointment;
    onClose: (updatedCustomer?: Customer, updatedAppointment?: SahAppointment) => void;
}

function CustomerDialogMenuInner({
    initalCustomer,
    initialAppointment,
    onClose,
}: CustomerDialogInnerProps) {
    const [customer, setCustomer] = useState<Customer>(initalCustomer);
    const [appointment, setAppointment] = useState<SahAppointment>(initialAppointment);

    return (
        <>
            <DialogContent style={{padding: 0}}>
                <div 
                    className="flex-column"
                    style={{ alignItems: "flex-start" }}
                >
                    <CustomerDialogView
                        customer={customer}
                        setCustomer={setCustomer}
                        primaryIsEditable={false}
                        howDidYouHearAboutUsOption={appointment.howDidYouHearAboutUsOption}
                    />

                    <div className="flat-section-header flat-section-header-rectangle fill-width padding-none justify-content-center">
                        <h1 className="flat-font-lg margin-vertical-xsm">
                            Task Details
                        </h1>
                    </div>
                    
                    <div style={{padding: "var(--flat-outer-container-padding) var(--flat-outer-container-radius)"}} className="fill-width">
                        <div className="flex-row fill-width margin-vertical-sm flex-space-between">
                            <div className="flex-row align-items-baseline">
                                <p
                                    className="flat-label-base flat-label-primary margin-none"
                                    style={{ paddingRight: ".25rem" }}
                                ># Rooms:</p>
                                <p className="flat-label-base flat-label-bold margin-none">
                                    {appointment?.numRooms}
                                </p>
                            </div>
                            <div className="flex-row align-items-baseline">
                                <p
                                    className="flat-label-base flat-label-primary margin-none"
                                    style={{ paddingRight: ".25rem" }}
                                >Products:</p>
                                <p className="flat-label-base flat-label-bold margin-none">
                                    [{appointment.productTypes.join(", ")}]
                                </p>
                            </div>
                            <div className="flex-row align-items-baseline">
                                <p
                                    className="flat-label-base flat-label-primary margin-none"
                                    style={{ paddingRight: ".25rem" }}
                                >Priorities:</p>
                                <p className="flat-label-base flat-label-bold margin-none">
                                    [{appointment.priorityOptions?.map((opt) => opt.text).join(", ")}]
                                </p>
                            </div>
                            <div className="flex-row align-items-baseline">
                                <p
                                    className="flat-label-base flat-label-primary margin-none"
                                    style={{ paddingRight: ".25rem" }}
                                >Colors:</p>
                                <p className="flat-label-base flat-label-bold margin-none">
                                    [{appointment.colorCategories?.map((c) => c.category).join(", ")}]
                                </p>
                            </div>
                        </div>
                        <div className="flex-row fill-width margin-bottom-sm align-items-baseline">
                            <p className="flat-label-base flat-label-primary" style={{ paddingRight: ".25rem" }}>
                                Appointment Date:{" "}
                            </p>
                            <p className="flat-label-base flat-label-bold margin-none">
                                {dateTimeStrToMdy(appointment?.date)} -{" "}
                                {marketTimeSlotToStr(appointment?.marketTimeSlot, "to")}
                            </p>
                        </div>

                        <div className="flex-row fill-width">
                            <div className="fill-width">
                                <p className="flat-label-base flat-label-primary" style={{ paddingRight: ".25rem" }}>
                                    Customer Comments:
                                </p>

                                <textarea
                                    className="flat-textarea fill-width"
                                    value={appointment.comments ?? ""}
                                    rows={4}
                                    onChange={(e) =>
                                        setAppointment({
                                            ...appointment,
                                            comments: e.currentTarget.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="flex-grow"></div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            
            <DialogActions>
                <FlatButton
                    onClick={() => onClose()}
                    color="primary"
                    variant="outlined"
                >Close</FlatButton>
                <FlatButton
                    onClick={() => onClose(customer, appointment)}
                    color="primary"
                >Save Changes</FlatButton>
            </DialogActions>
        </>
    );
}
