import clsx from "clsx";
import { S3PdfViewerDialog } from "Components/Files/S3PdfViewers";
import { FlatAlertIcon } from "FlatComponents/Button/FlatAlertButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import FlatDocumentButton from "FlatComponents/Button/FlatDocumentButton";
import FlatEditButton from "FlatComponents/Button/FlatEditButton";
import FlatUploadFileButton from "FlatComponents/Button/FlatUploadFileButton";
import { LiabilityInsurance, useDeleteLiabilityInsuranceMutation } from "generated/graphql";
import { makeEmptyLiabilityInsurance } from "Globals/DataStructures/EmptyDataStructures";
import { dateTimeStrToMdy } from "Globals/DateAndTimeHelpers";
import { isDocumentOverdue } from "Globals/GenericValidators";
import { useState } from "react";
import LiabilityInsuranceDialog from "../Dialogs/LiabilityInsuranceDialog";

interface LiabilityInsuranceTableProps {
    workerId: number;
    toRefetch: string;
    liabilityInsurances: LiabilityInsurance[];
}
export default function LiabilityInsuranceTable({workerId, toRefetch, liabilityInsurances}: LiabilityInsuranceTableProps) {
    const [dialogOpen, setDialogOpen] = useState(false);

    return (<>
        <div className="flat-font profile-right-column-table-container">
            <div className="thin-horizontal-bar grid-colspan-1-3" />
            <p>Liability Insurance</p>
            <div className="grid-15-50-35 padding-left-sm margin-bottom-sm fit-content-height">
                <span className="file-upload-row profile-table-cell">
                    <FlatUploadFileButton
                        color={"var(--flat-red)"}
                        onClick={() => setDialogOpen(true)}
                    />
                </span>

                {liabilityInsurances.length === 0 && <p className="grid-colspan-1-4">No liability insurance information found</p>}
                {liabilityInsurances.map(li => (
                    <LiabilityInsuranceTableRow
                        key={li.id}
                        liabilityInsurance={li}
                        toRefetchOnUpdate={toRefetch}
                    />)
                )}
            </div>
        </div>
        
        {dialogOpen && (
            <LiabilityInsuranceDialog
                insurance={makeEmptyLiabilityInsurance(workerId)}
                toRefetchOnUpdate={toRefetch}
                onClose={() => setDialogOpen(false)}
            />
        )}
    </>)
}

interface LiabilityInsuranceTableRowProps {
    liabilityInsurance: LiabilityInsurance;
    toRefetchOnUpdate: string;
}

function LiabilityInsuranceTableRow({liabilityInsurance, toRefetchOnUpdate}: LiabilityInsuranceTableRowProps) {
    const isOverdue = isDocumentOverdue(liabilityInsurance.expirationDate);
    const [uploaderOpen, setUploaderOpen] = useState(false);
    const [viewerOpen, setViewerOpen] = useState(false);

    const [deleteIns, {loading: deleting}] = useDeleteLiabilityInsuranceMutation({
        variables: {id: liabilityInsurance.id},
        onError: () => alert("Could not delete liability insurance record"),
        refetchQueries: [toRefetchOnUpdate]
    });

    function onDeleteRow() {
        if (window.confirm("Are you sure you wish to delete this liability insurance record?")) {
            deleteIns();
        }
    }

    return (<>
        <span className="profile-table-cell profile-table-cell-inner profile-table-label-cell">
            {liabilityInsurance.s3FileId ? (
                <FlatDocumentButton
                    color="var(--flat-green)"
                    onClick={() => setViewerOpen(true)}
                    disabled={deleting}
                />
            ) : (
                <FlatUploadFileButton
                    color={"var(--flat-red)"}
                    onClick={() => setUploaderOpen(true)}
                    disabled={deleting}
                />
            )}
        </span>
        
        <p className="profile-table-cell profile-table-date-cell profile-table-cell-inner">
            {`${dateTimeStrToMdy(liabilityInsurance.activeDate)} - ${dateTimeStrToMdy(liabilityInsurance.expirationDate)}`}
        </p>

        <span className="profile-table-cell profile-table-action-cell">
            <span className={clsx({"visibility-hidden": !isOverdue})}>
                <FlatAlertIcon color="var(--flat-orange)"/>
                <p className="flat-font-xsm">Due</p>
            </span>
            <span className="flex-row flex-gap-sm">
                <FlatDeleteButton
                    onClick={onDeleteRow}
                    disabled={deleting}
                />
                <FlatEditButton
                    onClick={() => setUploaderOpen(true)}
                    disabled={deleting}
                />
            </span>
        </span>

        {uploaderOpen && (
            <LiabilityInsuranceDialog
                insurance={liabilityInsurance}
                toRefetchOnUpdate={toRefetchOnUpdate}
                onClose={() => setUploaderOpen(false)}
            />
        )}

        {(liabilityInsurance.s3FileId && viewerOpen) && (
            <S3PdfViewerDialog
                fileId={liabilityInsurance.s3FileId!}
                onClose={() => setViewerOpen(false)}
            />
        )}
    </>)
}