import { CircularProgress } from "@material-ui/core";
import { GetPriceRequestQuery, PriceRequestUpdatedForJobDocument, PriceRequestUpdatedForJobSubscription, UpdatedProductForAreaInput, useCopyJobConfigurationMutation, useGetPriceRequestQuery, useSendPriceRequestMutation } from "generated/graphql";
import { isEmptyString, isNotEmptyString, isNullOrUndefined } from "Globals/GenericValidators";
import { quoteCardChangesToUpdatedProductInput } from "Pages/Admin/ProjectManagement/SellSheet/GoToCartButton";
import { QuoteCardState } from "Pages/Admin/ProjectManagement/SellSheet/SellSheet";
import { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { SendMessageButton } from "../ChatButton";

interface PriceRequestInputProps {
    chatId: number;
    selectedCard: QuoteCardState;
    updateCard: (updatedCard: QuoteCardState) => void;
    jobId: number;
    optionNumber: number;
}

export default function PriceRequestInput({ chatId, selectedCard, updateCard, jobId }: PriceRequestInputProps) {
    const selectedConfigId = selectedCard.jobConfigurationId;

    let jobPrice = selectedCard?.jobPrice;
    if (jobPrice && selectedCard?.selectedFinancingOptionId === -1) { // financing option id of -1 means sales tax discount is applied; must add sales tax back on
        // add sales tax back to job
        jobPrice = jobPrice * .94;
    }

    const [requestedPrice, setRequestedPrice] = useState<string>('');
    useEffect(() => {
        setRequestedPrice('');
    }, [selectedConfigId]);


    const { data: priceRequestData, subscribeToMore } = useGetPriceRequestQuery({
        skip: (selectedConfigId ?? 0) < 1,
        variables: {
            jobConfigurationId: selectedConfigId!
        },
        fetchPolicy: "network-only"
    });
    let overrideRequest = priceRequestData?.priceRequest;

    // used if salesperson calls in for some reason while the chat is open; will prevent attempting another request in this case
    const listenForPriceRequest = useCallback(() => {
        if (subscribeToMore && jobId) {
            subscribeToMore({
                document: PriceRequestUpdatedForJobDocument,
                variables: {
                    jobId: jobId!
                },
                updateQuery: (prev, { subscriptionData }) => {
                    const newRequest = (subscriptionData.data as unknown as PriceRequestUpdatedForJobSubscription).priceRequestUpdatedForJob;
                    // if the user doesn't have the config selected for the config, don't show the update
                    if (!newRequest || newRequest.jobConfigurationId !== selectedCard?.jobConfigurationId) { return prev; }

                    const updatedRequest: GetPriceRequestQuery = {
                        ...prev,
                        priceRequest: newRequest
                    }
                    return updatedRequest;
                }
            });
        }
    }, [subscribeToMore, jobId, selectedCard?.jobConfigurationId]);

    useEffect(() => {
        if (listenForPriceRequest) {
            listenForPriceRequest();
        }
    }, [listenForPriceRequest]);

    // the loading attribute of the mutation isn't resetting to false when it's done, so control this manually
    const [sendingRequest, setSendingRequest] = useState(false);
    const [requestPrice] = useSendPriceRequestMutation({
        onCompleted: (res) => {
            const requestObj = res.sendPriceRequest;
            if (requestObj) {
                setRequestedPrice('');
                setSendingRequest(false);
                const updatedCard = {
                    ...selectedCard!,
                    jobConfigurationId: requestObj.jobConfigurationId,  // set this bc a new config may have been made for the request
                    productChanges: {},
                    isCopy: false,
                    isDeletable: false // can't delete card once there's a requst for them
                };
                updateCard(updatedCard!);
            }
        },
        onError: () => {
            alert("Could not send price request");
            setSendingRequest(false);
        }
    });

    const [copyJob] = useCopyJobConfigurationMutation({
        onCompleted: (data) => {
            const newConfigId = data.copyJobConfiguration;
            if (newConfigId) {
                requestPrice({ variables: { chatId: chatId, jobConfigurationId: newConfigId, amount: +requestedPrice! } })
            }
        },
        onError: () => {
            alert("Could not send price request")
            setSendingRequest(false);
        }
    });


    async function onSendPriceRequest() {
        if (canRequestPrice(selectedCard, requestedPrice)) {
            setSendingRequest(true);
            let configId = selectedCard.jobConfigurationId;

            if (selectedCard.isCopy) {
                var updatedProducts: UpdatedProductForAreaInput[] = quoteCardChangesToUpdatedProductInput(selectedCard.productChanges)

                copyJob({
                    variables: {
                        jobConfigurationId: configId,
                        optionNumber: selectedCard.optionNumber!,
                        updatedProducts: updatedProducts,
                        selectedFinancingOption: selectedCard.selectedFinancingOptionId
                    }
                });
            } else {
                requestPrice({ variables: { chatId: chatId, jobConfigurationId: configId, amount: +requestedPrice! } });
            }

        }
    }

    var statusText = ""

    if (overrideRequest && !selectedCard.isCopy) {
        if (overrideRequest.commandCenterWorkerId) {
            if (overrideRequest.timeResponded) {
                statusText = "Price request has been responded to"
            } else {
                statusText = "The command center is reviewing the price request"
            }
        } else {
            statusText = "Awaiting command center review"
        }
    }

    return (
        <div className="action-box">
            <p className="margin-bottom-xsm" style={{fontWeight: "bold"}}>
                {selectedCard.optionNumber >= 1 ? (
                    `Option ${selectedCard.optionNumber}`
                ) : (
                    "Select an option"
                )}
            </p>

            {isNotEmptyString(statusText) && <p className="margin-none">{statusText}</p>}

            { (selectedCard.optionNumber > 0 && isEmptyString(statusText)) &&
                <div className="flex-row flex-space-between">
                    <NumberFormat
                        placeholder="Enter amount..."
                        className="action-input"
                        value={requestedPrice} onValueChange={(v) => setRequestedPrice(v.value)}
                        displayType="input"
                        inputMode="tel" // opens up numeric keyboard on mobile... "number" does not open numeric on iOS
                        disabled={selectedConfigId < 1}
                        prefix="$"
                        decimalScale={2} fixedDecimalScale allowNegative={false}
                    />

                    {sendingRequest ? (
                        <CircularProgress />
                    ) : (
                        <SendMessageButton 
                            disabled={isEmptyString(requestedPrice) || selectedConfigId < 1}
                            onClick={onSendPriceRequest}
                        />
                    )}
                </div>
            }
        </div>
    )
}

function canRequestPrice(card: QuoteCardState, requestedPrice: string) {
    if (isEmptyString(requestedPrice) || isNaN(+requestedPrice)) {
        alert("Enter a price");
        return false;
    };

    if (card.jobConfigurationId < 1 || isNullOrUndefined(card.isCopy) || isNullOrUndefined(card.jobPrice)) {
        alert("Must select a configuration before making a price request");
        return false;
    }

    if (+requestedPrice >= card.jobPrice!) {
        alert(`Price request amount must be less than the quoted price of the job ${card.jobPrice}`);
        return false;
    }

    return true;
}