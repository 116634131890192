import { FINANCING_DOWN_PAYMENT_AMOUNT } from "Globals/globalConstants";

export function calculateMargin(revenue: number, cost: number) {
    return (revenue - cost) / revenue * 100;
}

/**
 * Margin calculation is not as straightforward as just using calculateMargin (except in the case that we are interested in the 
 * margin on the job before any financing options are applied, in the case of the MSRP section of the pricing center).
 * In all other cases, the margin changes if financing has been applied.
 * 
 * To calculate the margin correctly when financing is applied, we adjust the number used for the revenue in the margin calculation.
 * Though there is a cost associated with financing (each option has some fee percentage), we do not add this to the cost of the job.
 * Instead, we deduct this from the revenue. This is an important distinction, since the revenue is in both the numerator and denominator
 * and the cost is only in the numerator. This is performed by the @see calculateFinancingAdjustedRevenue. In the case that financing
 * is not applied, and therefore the fee is 0, this function returns the original revenue.
 * 
 * When the margin is being set directly in the @see PriceAndMarginEditor component, we must reverse engineer what the original
 * revenue amount should be. This is performed by the @see calculateRevenueFromMargin function. To do this, we first find what
 * the adjusted revenue would need to be to produce this margin giventhe financing option applied. Once that is determined, we
 * can use that to determine what the actual revenue must be in order to produce this margin. If no financing is applied, then of
 * course the adjusted revenue is the same as the original revenue, just as it is in calculateFinancingAdjustedRevenue. 
 * 
 */

/**
 * Calculates the revenue which is used in margin calculation when there is financing present.
 * The financing fee does not contribute to the job cost, but is instead deducted from the price of the job.
 * @param originalRevenue The price of the job. NOTE: this will be divided by 100 internally, do not divide before passing.
 * @param financingFee The fee of the financing option that is applied to the job.
 * @returns The price of the job less the financing fee.
 */
 export function calculateFinancingAdjustedRevenue(originalRevenue: number, financingFee: number) {
    const financedAmount = originalRevenue - FINANCING_DOWN_PAYMENT_AMOUNT;
    return originalRevenue - (financedAmount * financingFee / 100);
}

/**
 * Calculates the actual price from the margin, accounting for any selected financing option.
 * @param margin The margin of the job. NOTE: This will be divided by 100 internally, do not divide before passing.
 * @param cost The cost of the job.
 * @param financingFee The fee of the selected financing option. NOTE: This will be divided by 100 internally, do not divide before passing.
 * @returns The price of the job based on the margin.
 */
export function calculateRevenueFromMargin(margin: number, cost: number, financingFee: number) {
    // need to calculate the financing adjusted revenue from the margin
    const adjRev = cost / (1 - (margin / 100));
    return (adjRev - (200 * financingFee / 100)) / (1 - (financingFee / 100));
}
