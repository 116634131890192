import { Dialog, DialogContent, DialogTitle, MenuItem } from "@material-ui/core";
import UploadPictureDialog, {
    loadFile,
    makePicture
} from "Components/Forms/Controls/UploadPictureDialog";
import { FlatAddIcon } from "FlatComponents/Button/FlatAddButton";
import FlatButton from "FlatComponents/Button/FlatButton";
import { Area, Picture } from "generated/graphql";
import { useRef, useState } from "react";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";

export interface AdditionalAddNewPhotoButtonProps { 
    singlePhoto?: boolean;
}

export interface AddNewPhotoButtonProps extends AdditionalAddNewPhotoButtonProps {
    areas: Area[];
    onUpload: (roomId: number, pics: Picture[]) => void;
}

export function AddNewPhotoButton({ areas, onUpload, singlePhoto }: AddNewPhotoButtonProps) {
    const [areaMenu, setAreaMenu] = useState<boolean>(false);
    const [uploadPhotoMenu, setUploadPhotoMenu] = useState<number>(-1);
    const inputRef = useRef<HTMLInputElement>(null);

    const rooms: { label: string; roomId: number }[] = areas.flatMap((area, areaIndex) =>
        area.rooms.flatMap((room) => ({
            label: `Area ${areaIndex + 1} [${area.productTypeName}]: ${getNameOfArea(room.labels)}`,
            roomId: room.id,
        }))
    );

    function uploadSingleFile(files: File[]) {
        if (files.length > 0) {
            loadFile(files[0], (bs) => onUpload(uploadPhotoMenu, [makePicture(bs)]));
            setUploadPhotoMenu(-1)
        }
    }

    return (
        <>
            <FlatButton
                onClick={() => setAreaMenu(true)}
                variant="contained"
                color="secondary"
            >
                <span className="flex-row flex-gap-xsm">
                    <FlatAddIcon />
                    Add New Photo
                </span>
            </FlatButton>

            <Dialog
                open={areaMenu}
                onClose={() => setAreaMenu(false)}
            >
                <DialogTitle>Select Room</DialogTitle>
                <DialogContent>
                    {rooms.map((room) => (
                        <MenuItem
                            onClick={() => {
                                setUploadPhotoMenu(room.roomId);
                                setAreaMenu(false);
                                if(singlePhoto) inputRef.current?.click();
                            }}
                            key={`room-label-${room.roomId}`}
                        >
                            {room.label}
                        </MenuItem>
                    ))}
                </DialogContent>
            </Dialog>

            <input
                ref={inputRef}
                hidden
                multiple={false}
                type="file"
                onChange={(e) => uploadSingleFile([...(e.target.files ?? [])])}
                accept={".jpeg,.jpg,.png"}
            />

            <UploadPictureDialog
                open={uploadPhotoMenu !== -1 && singlePhoto !== true}
                closeDialog={() => setUploadPhotoMenu(-1)}
                uploadImages={(pics) => onUpload(uploadPhotoMenu, pics)}
            />
        </>
    );
}
