import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Checkbox, FormControlLabel } from "@material-ui/core";

interface RoomCheckBoxProps {
    areaId: number;
    roomId: number;
    productTypeId: number;
    roomIsStaged: boolean;
    roomLabel: ReactJSXElement;
    onToggleRoom: (roomId: number, productTypeId: number) => void;
    disabled?: boolean;
}

export default function RoomCheckBox({
    roomId,
    productTypeId,
    roomIsStaged,
    roomLabel,
    onToggleRoom,
    disabled,
}: RoomCheckBoxProps) {
    return (
        <div className="flex-column" key={roomId}>
            <FormControlLabel
                style={{ marginLeft: "1.25rem" }}
                control={
                    <Checkbox
                        value={roomId}
                        checked={roomIsStaged}
                        onChange={() => onToggleRoom(roomId, productTypeId)}
                        style={{color: "black"}}
                        size={"small"}
                    />
                }
                label={roomLabel}
                disabled={disabled}
            />
        </div>
    );
}
