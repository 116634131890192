import { TextField } from "@mui/material";
import FlatButton from "FlatComponents/Button/FlatButton";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import NumberFormat from "react-number-format";

interface MoneyInputProps {
    initialValue: number | undefined;
    setValue: (value: number | undefined) => void;
    label?: string;
    size?: "small" | "medium" | undefined
    // passing onSubmit makes a button appear for submission, and delays the call on setValue until the submit button is pressed
    onSubmit?: (newValue: number) => void;
    onCancelEditing?: () => void;  // passing this will also make a cancel button appear
    variant?: "outlined" | "standard" | "filled" | undefined;
    disabled?: boolean;
    error?: boolean;
    disableUnderline?: boolean;
}

export default function MoneyInput({
    initialValue,
    setValue: setExternalValue,
    label="",
    variant,
    size="small",
    onSubmit,
    onCancelEditing,
    disabled=false,
    error=false,
    disableUnderline=false
}: MoneyInputProps) {
    const [internalValue, setInternalValue] = useState<number | undefined>(initialValue);

    function onChangeInputValue(newVal: number | undefined) {
        if (isNullOrUndefined(onSubmit)) {
            // the internal/external value need to be changed
            setInternalValue(newVal);
            setExternalValue(newVal);
        } else {
            // only the internal value needs to be changed (onSubmit should handle this)
            setInternalValue(newVal);
        }
    }

    function onClickSubmit() {
        if (isNotNullOrUndefined(onSubmit) && (isNotNullOrUndefined(internalValue))) {
            setExternalValue(internalValue);
            onSubmit!(internalValue!);
        } else {
            alert('Could not submit updated price');
        }
    }

    return (
        <div className="flex-row align-items-baseline">
            <NumberFormat
                size={size}
                prefix={'$'}
                thousandSeparator={true}
                displayType="input"
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale
                label={label}
                variant={variant ? variant : "standard"}
                value={internalValue}
                customInput={TextField}
                onValueChange={(newVal) => {onChangeInputValue(newVal.floatValue)}}
                InputProps={{
                    disableUnderline: disableUnderline,
                    error: error,
                    disabled: disabled,
                    type: "tel"  // opens up numeric keyboard on mobile... "number" does not open numeric on iOS
                }}
            />

            {isNotNullOrUndefined(onCancelEditing) &&
                <FlatButton
                    style={{fontSize: "8pt"}}
                    className="margin-left-sm fit-content"
                    variant="outlined"
                    color="primary"
                    disabled={isNullOrUndefined(internalValue)}
                    onClick={onCancelEditing}
                >Cancel</FlatButton>
            }

            {isNotNullOrUndefined(onSubmit) &&
                <FlatButton
                    style={{fontSize: "8pt"}}
                    className="margin-left-sm fit-content"
                    variant="contained"
                    color="secondary"
                    disabled={isNullOrUndefined(internalValue)}
                    onClick={onClickSubmit}
                >Submit</FlatButton>
            }
            
        </div>
    )
}