import { Chip } from "@material-ui/core";

interface ToggleChipProps {
    thisVal: number | null; // value of this chip
    selectedVal: number | null, // value of selected chip
    onSelectTime: (newVal: number | null) => void,
    selectable: boolean,
    label: string
}

export default function ToggleChip({thisVal, selectedVal, onSelectTime, selectable, label}: ToggleChipProps) {

    return (
         <Chip
            style={{border: '1px solid rgba(0, 0, 0, 0.23)', color: "black"}}
            className="overflow-visible"
            label={label}
            variant={thisVal === selectedVal ? "default" : 'outlined'}
            onClick={() => onSelectTime(thisVal)}
            disabled={!selectable}
        />
    )
}