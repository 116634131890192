import { IconButton } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { PropsWithChildren, useState } from "react";

interface SectionProps extends PropsWithChildren<{
    title?: string,
    flexEnd?: boolean,
    centerBody?: boolean,  // if flexEnd is true, this will be ignored
    column?: boolean
    alignTop?: boolean,
    hidden?: boolean,
    endAdornment?: JSX.Element
}> { }

export default function Section({ title, flexEnd, alignTop, centerBody, column, endAdornment, hidden, children }: SectionProps) {
    return (
        <div hidden={hidden} className="fill-width flex-column" style={{ alignItems: "flex-start" }}>
            <div className="flex-row fill-width" style={{ justifyContent: "space-between" }}>
                <h3>{title}</h3>
                {endAdornment}
            </div>
            <div className="horizontal-bar" />
            <div className={`fill-width padding-sm ${column ? "flex-column" : "flex-row"}`} style={{
                justifyContent: (flexEnd) ? "flex-end" : (centerBody ? "center" : undefined),
                alignItems: (alignTop) ? "flex-start" : "center"
            }} >
                {children}
            </div>
        </div>
    );
}

export function SubSection({ title, flexEnd, alignTop, centerBody, column, endAdornment, hidden, children }: SectionProps) {
    return (
        <div hidden={hidden} className="fill-width flex-column" style={{ alignItems: "flex-start" }}>
            <div className="flex-row fill-width" style={{ justifyContent: "space-between" }}>
                <h4>{title}</h4>
                {endAdornment}
            </div>
            <div className="horizontal-bar" />
            <div className={`fill-width padding-top-sm ${column ? "flex-column" : "flex-row"}`} style={{
                justifyContent: (flexEnd) ? "flex-end" : (centerBody ? "center" : undefined),
                alignItems: (alignTop) ? "flex-start" : "center"
            }} >
                {children}
            </div>
        </div>
    );
}

interface ToggleableSectionProps extends Omit<SectionProps, "endAdornment"> {
    isOpen?: boolean,
    setIsOpen?: (open: boolean) => void,
    defaultState?: boolean
}

export function ToggleableSection({ isOpen: exOpen, setIsOpen: exSetOpen, defaultState, children, ...sectionProps }: ToggleableSectionProps) {
    const [inOpen, inSetOpen] = useState<boolean>(defaultState ?? true)

    const isOpen = exOpen ?? inOpen
    const setOpen = exSetOpen ?? inSetOpen

    return (
        <Section {...sectionProps} endAdornment={
            <IconButton onClick={() => setOpen(!isOpen)} style={{ height: "1.75em" }}>
                {isOpen ? <ArrowDropUpIcon fontSize="large" /> : <ArrowDropDownIcon fontSize="large" />}
            </IconButton>
        }>
            {isOpen && children}
        </Section>
    )
}

export function ToggleableSubSection({ isOpen: exOpen, setIsOpen: exSetOpen, defaultState, children, ...sectionProps }: ToggleableSectionProps) {
    const [inOpen, inSetOpen] = useState<boolean>(defaultState ?? true)

    const isOpen = exOpen ?? inOpen
    const setOpen = exSetOpen ?? inSetOpen

    return (
        <SubSection {...sectionProps} endAdornment={
            <IconButton onClick={() => setOpen(!isOpen)} style={{ height: "1.75em" }}>
                {isOpen ? <ArrowDropUpIcon fontSize="large" /> : <ArrowDropDownIcon fontSize="large" />}
            </IconButton>
        }>
            {isOpen && children}
        </SubSection>
    )
}