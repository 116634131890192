import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import { formatNameStringFirstLast } from "Globals/StringFormatting";
import JobSvgViewer from "Pages/Home/ContractorHome/InstallJob/JobSvgViewer";
import { useGetJobContractQuery, useGetJobInvoiceDataQuery } from "generated/graphql";

export default function PrintableSeamPage() {
    const { id: jobContractId } = useNumericIdParam();

    const contractIdValid = (jobContractId ?? 0) > 0;

    const { data, loading } = useGetJobContractQuery({
        variables: { jobContractId: jobContractId ?? 0 },
        skip: !contractIdValid,
    });

    const sahNumber = data?.jobContract.sAHNumber ?? "";
    const jobConfigurationId = data?.jobContract.jobConfigurationId ?? 0;

    const { data: jobData } = useGetJobInvoiceDataQuery({
        skip: !contractIdValid || jobConfigurationId < 1,
        variables: { jobConfigurationId: jobConfigurationId },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only",
    });

    const customer = jobData?.jobConfigurationHeader.customer;

    return (
        <>
            {(jobContractId ?? 0) <= 0 || (!loading && isNullOrUndefined(data)) ? (
                "Job Does Not Exist"
            ) : (
                <div className="flex-column">
                    <div>
                        Customer {formatNameStringFirstLast(customer)}{" "}
                        <span style={{ marginLeft: "2em" }}>SAH-{sahNumber}</span>
                    </div>
                    {(jobConfigurationId > 0 && isNotNullOrUndefined(jobData)) && (
                        <JobSvgViewer
                            jobConfigurationId={jobConfigurationId}
                            showCarpetCuts
                            useAbbreviatedCutNames
                            openPrintWhenLoaded
                        />
                    )}
                </div>
            )}
        </>
    );
}
