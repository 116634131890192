import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { InventoryEntry } from 'generated/graphql'
import { ItemAmount, productTypeToDefaultOptions } from 'Components/ItemAmountPicker/ItemAmountPicker'

interface ManufacturerItemsPair {
    manufacturer: string,
    items: InventoryEntry[],
}

interface OrderingState { //Defined so that createSlice can infer the type
    selectedSkus: string[],
    itemsOnOrder: ManufacturerItemsPair[],
    orderAmount: { [key: string]: ItemAmount[] }
}

const initialState: OrderingState = {
    selectedSkus: [],
    itemsOnOrder: [],
    orderAmount: {}
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const orderingSlice = createSlice({
    name: "ordering", //No clue what this does
    initialState,
    reducers: { //DON'T Call async functions in here. No 
        addToSelected: (state, action: PayloadAction<InventoryEntry>) => {
            let entry = action.payload;
            let manufacturer = entry.manufacturer;
            let sku = entry.sku;
            if (!state.selectedSkus.includes(sku)) {
                //Update selected SKUs
                state.selectedSkus = [sku, ...state.selectedSkus]

                //Update order
                const savedOrderAmount = state.orderAmount[sku]
                const proposedNewOrderAmount: ItemAmount[] = [{
                    type: productTypeToDefaultOptions[entry.productType][0],
                    value: {
                        primary: entry.toOrderCountInOrderUnits.toString(),
                        secondary: ""
                    }
                }]
                
                state.orderAmount[sku] = savedOrderAmount ?? proposedNewOrderAmount 

                
                //Update items/manufacturer map
                const filterResult = state.itemsOnOrder.filter(itemPair => itemPair.manufacturer === manufacturer)
                switch (filterResult.length) {
                    case 0:
                        //No entry for this manufacturer yet. 
                        state.itemsOnOrder = [{ manufacturer: manufacturer, items: [entry] }, ...state.itemsOnOrder]
                        break;
                    case 1:
                        //Update existing entry for this manufacturer
                        state.itemsOnOrder = state.itemsOnOrder.map<ManufacturerItemsPair>(
                            item => {
                                return {
                                    manufacturer: item.manufacturer,
                                    items: (item.manufacturer === manufacturer) ?
                                        [entry, ...item.items] :
                                        item.items
                                }
                            })
                        break;
                    default:
                        //More than one entry found. Should only be one entry per manufacturer
                        console.error(`More than one entry for ${manufacturer} present in items on order object`, state)
                        break;
                }
            }
            else console.error(`Attempted to add entry [${entry.sku}] that already exists. If trying to update entry value use updateSelected explicitly`, state.selectedSkus)
        },
        removeFromSelected: (state, action: PayloadAction<string>) => {
            let targetSku = action.payload
            state.selectedSkus = state.selectedSkus.filter(sku => sku !== targetSku)
            const newItemsList: ManufacturerItemsPair[] = []
            state.itemsOnOrder.forEach(pair => {
                const reducedItems = pair.items.filter(item => item.sku !== targetSku)
                if (reducedItems.length !== pair.items.length) {
                    if (reducedItems.length === 0) return;
                    else {
                        newItemsList.push({ manufacturer: pair.manufacturer, items: reducedItems })
                    }
                } else {
                    newItemsList.push(pair)
                }
            })
            state.itemsOnOrder = newItemsList
        },
        updateSelected: (state, action: PayloadAction<InventoryEntry>) => {
            let entry = action.payload;
            let manufacturer = entry.manufacturer;
            let sku = entry.sku;
            if (!state.selectedSkus.includes(sku)) {
                console.error(`Cannot update order entry [${entry}] that does not exist`, state)
            } else {
                state.selectedSkus = [sku, ...state.selectedSkus]
                const filterResult = state.itemsOnOrder.filter(itemPair => itemPair.manufacturer === manufacturer)
                switch (filterResult.length) {
                    case 0:
                        //No entry for this manufacturer yet. 
                        console.error(`SKU: [${sku}] being tracked but, Entry: [${entry}] does not exist`, state)
                        break;
                    case 1:
                        //Update existing entry for this manufacturer
                        state.itemsOnOrder = state.itemsOnOrder.map<ManufacturerItemsPair>(
                            pair => {
                                return {
                                    manufacturer: pair.manufacturer,
                                    items: (pair.manufacturer === manufacturer) ?
                                        [entry, ...pair.items.filter(item => item.sku !== sku)] :
                                        pair.items
                                }
                            })
                        break;
                    default:
                        //More than one entry found. Should only be one entry per manufacturer
                        console.error(`More than one entry for ${manufacturer} present in items on order object`, state)
                }

            }
        },
        updateAmount: (state, action: PayloadAction<{ sku: string, orderAmount: ItemAmount, index: number }>) => {
            const sku = action.payload.sku
            const amount = action.payload.orderAmount
            const index = action.payload.index
            const currAmounts = [...state.orderAmount[sku]]
            currAmounts[index] = amount
            state.orderAmount = { ...state.orderAmount, [sku]: currAmounts }
        },
        removeAmount: (state, action: PayloadAction<{ sku: string, index: number }>) => {
            const sku = action.payload.sku
            const index = action.payload.index
            const newAmounts = [...state.orderAmount[sku]].filter((_, i) => i !== index);
            state.orderAmount = { ...state.orderAmount, [sku]: newAmounts }
        },
        addAmount: (state, action: PayloadAction<{ sku: string, orderAmount: ItemAmount }>) => {
            const sku = action.payload.sku
            const amount = action.payload.orderAmount
            const currAmounts = [...state.orderAmount[sku]]
            const newAmounts = [...currAmounts, amount]
            state.orderAmount = { ...state.orderAmount, [sku]: newAmounts }
        }
    }
})

export const { addToSelected, removeFromSelected, updateAmount, removeAmount, addAmount } = orderingSlice.actions //Unpacks the actions created in the slice

export const selectItemsToOrder = (state: RootState) => state.ordering.itemsOnOrder //This selector can be used as a getter for this value. Used like useSelector(selectItemsToOrder)

export const selectOrderAmounts = (state: RootState) => state.ordering.orderAmount
export const selectOrderAmount = (state: RootState, key: string) => state.ordering.orderAmount[key]

export default orderingSlice.reducer