import { IconButton, Typography } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectCcStandardCommissionRate, selectContractConfigurationId, selectFinancingOption, selectJobConfigurationId, selectJobCost, selectOverride, selectQuoteConfigToQuoteIdMap, selectUsageContext, setCcActualCommissionRate } from "Redux/pricingCenterReducer";
import MarginDisplay from "../MarginDisplay";
import { calculateFinancingAdjustedRevenue } from "../marginUtils";
import PriceAndMarginEditor from "../PriceAndMarginEditor";
import { CcOverrideCommissionSelect } from "./CommissionSelect";
import { OverrideEditorProps } from "./NewOverrideEditor";
import SendOverrideButton from "./SendOverrideButton";

export default function ExistingOverrideViewer({chatId, overrideAmount, setOverrideAmount, disabled}: OverrideEditorProps) {
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const standardCommissionRate = useAppSelector(selectCcStandardCommissionRate); // standard rate for the margin
    const jobCost = useAppSelector(selectJobCost);
    const financingOption = useAppSelector(selectFinancingOption);
    const override = useAppSelector(selectOverride);
    const usageContext = useAppSelector(selectUsageContext);
    const contractConfigId = useAppSelector(selectContractConfigurationId);
    const quotedConfigIds = Object.keys(useAppSelector(selectQuoteConfigToQuoteIdMap)).map(id => +id);
    const disableInteraction = disabled || usageContext === "readonly" || isNotNullOrUndefined(contractConfigId) || quotedConfigIds.includes(jobConfigurationId);
    const dispatch = useAppDispatch();
    const [editingOverride, setEditingOverride] = useState(false);

    function onEditOverride() {
        // ensure the editor opens with the commission rate the existing override has
        dispatch(setCcActualCommissionRate(override!.commissionRate));
        setEditingOverride(true);
    }

    return (
        <>
            {editingOverride ? (
                <>
                    <PriceAndMarginEditor
                        revenue={overrideAmount}
                        cost={jobCost}
                        setRevenue={setOverrideAmount}
                        financingOption={financingOption}
                    />
                    
                    <CcOverrideCommissionSelect />
                    
                    <IconButton onClick={() => setEditingOverride(false)} size="small"><CancelIcon /></IconButton>
                    
                    {!disableInteraction && (
                        <SendOverrideButton
                            chatId={chatId}
                            jobConfigurationId={jobConfigurationId}
                            amount={overrideAmount}
                            completionCallback={() => setEditingOverride(false)}
                        />                
                    )}
                </>
            ) : (
                <>
                    <Typography variant="h6">
                        <NumberFormat
                            style={{width: "5.75rem"}}
                            prefix="$" thousandSeparator={true}
                            displayType="text"
                            value={override!.amount}
                            decimalScale={2} fixedDecimalScale
                        />
                    </Typography>

                    <MarginDisplay revenue={calculateFinancingAdjustedRevenue(overrideAmount!, financingOption?.fee ?? 0)} cost={jobCost} variant="h6"/>

                    <Typography>
                        <NumberFormat
                            suffix="%" thousandSeparator={true}
                            displayType="text"
                            style={{color: (override!.commissionRate === standardCommissionRate) ? "black" : "red"}}
                            value={override!.commissionRate * 100}
                            decimalScale={0}
                        />
                    </Typography>
                    
                    <Typography>{override!.authorizationCode.toUpperCase()}</Typography>
                    
                    {!disableInteraction && (
                        <IconButton onClick={onEditOverride} size="small"><EditIcon /></IconButton>
                    )}
                </>
            )}
        </>
    )
}