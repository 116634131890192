import { IconButton } from "@material-ui/core";
import CancelIcon from '@mui/icons-material/Cancel';
import { FlatIconButtonProps } from "./flatButtonUtils";

export default function FlatCancelButton({onClick, color="black", style, ...iconButtonProps}: FlatIconButtonProps) {
    return(
        <IconButton
            {...iconButtonProps}
            style={{padding: "0", ...style}}
            onClick={onClick}
        >
            <FlatCancelIcon color={color}/>
        </IconButton>
    )
}

export function FlatCancelIcon({color="black"} : {color?: string}) {
    return <CancelIcon htmlColor={color}/>
}