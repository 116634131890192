import JobSearchBar from "Components/Inputs/Search/JobSearchBar";
import NavbarPage from "Components/Page";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";
import {
    GetAllJobsForProjectCoordinatorQuery, namedOperations, useCancelContractMutation, useGetAllJobsForProjectCoordinatorQuery,
    useUpdateCwcMutation
} from "generated/graphql";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { idUrlMask } from "Globals/Hooks";
import { jobTrackerPath, projectManagementPath } from "Globals/PathStrings";
import { useMemo, useState } from "react";
import { Item, Menu, Submenu } from "react-contexify";
import { useMediaQuery } from "react-responsive";
import { Route, Switch } from "react-router-dom";
import CustomerWillCallButton from "./Components/CustomerWillCallButton";
import JobWithCWCDialog from "./Components/JobWithCWCDialog";
import ProjectCoordinatorCard, { Column } from "./Components/ProjectCoordinatorCard";
import ProjectCoordinatorColumn from "./Components/ProjectCoordinatorColumn";
import { JobCancellationDialog } from "./JobCancellationButton";
import JobTrackerRouter from "./JobTracker/JobTrackerRouter";
import { ContractPaymentsDialog } from "./PaymentsDialog";

export const PROJECT_COORDINATOR_MENU_ID = "project-coordinator-context-menu";

export const COLUMN_WIDTH = "18em";
const PAGE_WIDTH = "98vw";
const DISPLAY_COLUMN_COUNT = 5;
const targetPageFontSize = `min(1rem, ${PAGE_WIDTH} / (${DISPLAY_COLUMN_COUNT + 0.5} * ${
    COLUMN_WIDTH.split("em")[0]
}))`;

export default function ProjectCoordinatorPage() {
    return (
        <Switch>
            <Route path={jobTrackerPath + idUrlMask}>
                <JobTrackerRouter />
            </Route>
            <Route path={projectManagementPath}>
                <ProjectCoordinatorDashboard />
            </Route>
        </Switch>
    );
}

function ProjectCoordinatorDashboard() {
    const { data, refetch } = useGetAllJobsForProjectCoordinatorQuery({ pollInterval: 5000 });
    const [cancelContract] = useCancelContractMutation({
        refetchQueries: [namedOperations.Query.GetAllJobsForProjectCoordinator],
    });
    const [updateCWC] = useUpdateCwcMutation({
        refetchQueries: [namedOperations.Query.GetAllJobsForProjectCoordinator],
    });

    const [paymentDialogData, setPaymentDialogData] = useState<null | {
        jobConfigurationId: number;
        contractId: number;
    }>(null);
    const [cwcDialogOpen, setCwcDialogOpen] = useState<boolean>(false);
    const [cancelConfirmOpen, setCancelConfirmOpen] = useState<{
        contractId: number;
        sahNumber: string;
    } | null>(null);

    const paymentDialogOpen = paymentDialogData !== null;

    const jobs = useMemo(
        () => data?.allJobsForProjectCoordinator ?? [],
        [data?.allJobsForProjectCoordinator]
    );

    function cwcClicked(jobId: number, cwcPeriod: number) {
        updateCWC({ variables: { jobId, numWeeks: cwcPeriod } });
    }

    function openPaymentDialog({ jobConfigurationId, contractId }: ProjectCoordinatorMenuProps) {
        setPaymentDialogData({ jobConfigurationId, contractId });
    }

    function cancelJobClicked({ contractId, sahNumber }: ProjectCoordinatorMenuProps) {
        setCancelConfirmOpen({ contractId, sahNumber });
    }

    function confirmCancelJobClicked() {
        cancelContract({ variables: { contractId: cancelConfirmOpen!.contractId } });
        setCancelConfirmOpen(null);
    }

    function closePaymentDialog(changesMade: boolean) {
        if (changesMade) {
            refetch();
        }
        setPaymentDialogData(null);
    }

    const { unclaimed, toSchedule, scheduled, installing, activeOrder } = useMemo(
        () => jobs.reduce<CardColumns>(sortCardsToColumns, emptyColumns),
        [jobs]
    );

    const useSmallCwcButton = useMediaQuery({ query: "only screen and (max-width: 1750px)" });

    return (
        <NavbarPage hideNavbar>
            <FlatNavbar title="Project Coordinator Dashboard"/>

            <JobSearchBar
                className="flex-row justify-content-flex-end" 
                containerStyle={{backgroundColor: "var(--flat-gray-1)", paddingTop: "1rem", paddingBottom: "1rem"}}
                openingFrom="pc"
            />

            <div id="pc-page">
                <div id="pc-page-content" style={{ width: PAGE_WIDTH, fontSize: targetPageFontSize }}>
                    <ProjectCoordinatorColumn
                        title="Unclaimed"
                        headerId="pc-column-header-unclaimed"
                    >
                        {unclaimed.map((job) => (
                            <ProjectCoordinatorCard
                                headerDividerClassName="customer-bar-unclaimed"
                                key={`unclaimed-${job.id}`}
                                job={job}
                                column={Column.UNCLAIMED}
                            />
                        ))}
                    </ProjectCoordinatorColumn>

                    <ProjectCoordinatorColumn
                        title="To Schedule"
                        headerId="pc-column-header-to-schedule"
                        headerDecoration={
                            <CustomerWillCallButton
                                useSmallVariant={useSmallCwcButton}
                                style={{ marginLeft: ".5em" }}
                                onClick={() => setCwcDialogOpen(true)}
                            />
                        }
                    >
                        {toSchedule.map((job) => (
                            <ProjectCoordinatorCard
                                key={`to-schedule-${job.id}`}
                                job={job}
                                headerDividerClassName="customer-bar-to-schedule"
                                column={Column.TO_SCHEDULE}
                            />
                        ))}
                    </ProjectCoordinatorColumn>

                    <ProjectCoordinatorColumn title="Scheduled" headerId="pc-column-header-scheduled">
                        {scheduled.map((job) => (
                            <ProjectCoordinatorCard
                                key={`scheduled-${job.id}`}
                                job={job}
                                headerDividerClassName="customer-bar-scheduled"
                                column={Column.SCHEDULED}
                            />
                        ))}
                    </ProjectCoordinatorColumn>
                
                    <ProjectCoordinatorColumn title="Installing" headerId="pc-column-header-installing">
                        {installing.map((job) => (
                            <ProjectCoordinatorCard
                                key={`installing-${job.id}`}
                                job={job}
                                headerDividerClassName="customer-bar-installing"
                                column={Column.INSTALLING}
                            />
                        ))}
                    </ProjectCoordinatorColumn>
                
                    <ProjectCoordinatorColumn title="Active Order" headerId="pc-column-header-active-order">
                        {activeOrder.map((job) => (
                            <ProjectCoordinatorCard
                                key={`active-order-${job.id}`}
                                job={job}
                                headerDividerClassName="customer-bar-active-order"
                                column={Column.ACTIVE_ORDER}
                            />
                        ))}
                    </ProjectCoordinatorColumn>
                </div>

                {paymentDialogOpen && (
                    <ContractPaymentsDialog
                        jobConfigurationId={paymentDialogData.jobConfigurationId}
                        contractId={paymentDialogData.contractId}
                        setOpen={(_, changesMade) => closePaymentDialog(changesMade)}
                    />
                )}

                {cwcDialogOpen && <JobWithCWCDialog closeDialog={() => setCwcDialogOpen(false)} />}

                {cancelConfirmOpen && (
                    <JobCancellationDialog
                        rawSahNumber={cancelConfirmOpen.sahNumber}
                        onYes={confirmCancelJobClicked}
                        onNo={() => setCancelConfirmOpen(null)}
                    />
                )}
                
                <Menu
                    id={PROJECT_COORDINATOR_MENU_ID}
                    style={{ minWidth: "unset" }}
                >
                    <Item
                        id={"override-3-day"}
                        hidden={({ props }) => (props as ProjectCoordinatorMenuProps).isIn3day}
                        onClick={() => {
                            console.log("TODO: Override 3 Day");
                        }}
                    >
                        Override 3-Day
                    </Item>
                    <Item
                        id={"cancel"}
                        onClick={({ props }) =>
                            cancelJobClicked(props as ProjectCoordinatorMenuProps)
                        }
                    >
                        Cancellation Requested
                    </Item>
                    <Item
                        id={"additional-payment"}
                        hidden={({ props }) => !(props as ProjectCoordinatorMenuProps).needsPayment}
                        onClick={({ props }) =>
                            openPaymentDialog(props as ProjectCoordinatorMenuProps)
                        }
                    >
                        Additional Payment
                    </Item>
                    <Submenu
                        id="cwc"
                        label="CWC"
                        hidden={({ props }) =>
                            !(props as ProjectCoordinatorMenuProps).needsScheduling
                        }
                        style={{ minWidth: "unset" }}
                    >
                        <Item
                            id="cwc-1"
                            onClick={({ props }) =>
                                cwcClicked((props as ProjectCoordinatorMenuProps).jobId, 1)
                            }
                        >
                                1 Week
                        </Item>
                        <Item
                            id="cwc-2"
                            onClick={({ props }) =>
                                cwcClicked((props as ProjectCoordinatorMenuProps).jobId, 2)
                            }
                        >
                            2 Weeks
                        </Item>
                        <Item
                            id="cwc-3"
                            onClick={({ props }) =>
                                cwcClicked((props as ProjectCoordinatorMenuProps).jobId, 3)
                            }
                        >
                            3 Weeks
                        </Item>
                        <Item
                            id="cwc-4"
                            onClick={({ props }) =>
                                cwcClicked((props as ProjectCoordinatorMenuProps).jobId, 4)
                            }
                        >
                            4 Weeks+
                        </Item>
                    </Submenu>
                </Menu>
            </div>
        </NavbarPage>
    );
}

function sortCardsToColumns(
    previousValue: CardColumns,
    job: GetAllJobsForProjectCoordinatorQuery["allJobsForProjectCoordinator"][number]
): CardColumns {
    var cards = { ...previousValue };

    if (job && isNullOrUndefined(job.projectCoordinator)) {
        cards.unclaimed = [...previousValue.unclaimed, job];
    } else {
        // cards may belong to multiple columns

        const instAppts = job.installationAppointments;

        if (!(job.cwcNumber !== null && instAppts.length > 0)
            && job.installationsToSchedule.length > 0) {
                cards.toSchedule = [...previousValue.toSchedule, job];
        }

        if (instAppts.some((app) => !app.startDatePassed)) {
            cards.scheduled = [...previousValue.scheduled, job];
        }

        if (instAppts.some((app) => app.startDatePassed && !app.endDatePassed && !app.isComplete)) {
            cards.installing = [...previousValue.installing, job];
        }

        const hasIncomplete = instAppts.some(appt => appt.endDatePassed && !appt.isComplete);
        const hasMissingCod = instAppts.some(appt => (appt.collectedCod ?? 0) < (appt.cod ?? 0));
        if (hasIncomplete || hasMissingCod) {
            cards.activeOrder = [...previousValue.activeOrder, job];
        }
    }
    
    return {
        ...cards,
    };
}

interface CardColumns {
    unclaimed: GetAllJobsForProjectCoordinatorQuery["allJobsForProjectCoordinator"];
    toSchedule: GetAllJobsForProjectCoordinatorQuery["allJobsForProjectCoordinator"];
    scheduled: GetAllJobsForProjectCoordinatorQuery["allJobsForProjectCoordinator"];
    installing: GetAllJobsForProjectCoordinatorQuery["allJobsForProjectCoordinator"];
    activeOrder: GetAllJobsForProjectCoordinatorQuery["allJobsForProjectCoordinator"];
}

const emptyColumns: CardColumns = {
    unclaimed: [],
    toSchedule: [],
    scheduled: [],
    installing: [],
    activeOrder: [],
};

export interface ProjectCoordinatorMenuProps {
    jobId: number;
    jobConfigurationId: number;
    contractId: number;
    sahNumber: string;
    isIn3day: boolean;
    needsPayment: boolean;
    needsScheduling: boolean;
}