import { TextField } from "@material-ui/core"
import { CARPET_PRODUCT_ID } from "Globals/globalConstants"
import { selectTypeId, selectVendorOrderSize, setVendorOrderSize } from "Redux/addProductReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"


export default function VendorOrderSizeEditor() {

    const productTypeId = useAppSelector(selectTypeId)
    const orderSize = useAppSelector(selectVendorOrderSize)
    const dispatch = useAppDispatch()

    function updateValue(newValue: string) {
        dispatch(setVendorOrderSize(newValue))
    }

    const placeholder = (productTypeId === CARPET_PRODUCT_ID) ?
        "Roll Length (lnft)" :
        "Pallet Size (boxes)"

    return (
        <TextField
            style={{ whiteSpace: "nowrap" }}
            disabled={productTypeId === -1}
            value={orderSize}
            onChange={(e) => updateValue(e.target.value)}
            margin="none"
            variant="filled"
            label={placeholder}
            type="number" />
    )
}
