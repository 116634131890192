import { EditableAppointmentSummary } from "./EditableAppointmentSummaryRow";
import { ScheduledInstallationSummary } from "./utils/installationSchedulingTypes";

interface InstallationSummariesProps {
    installationSummaries: ScheduledInstallationSummary[];
}

export function InstallationSummaries({installationSummaries}: InstallationSummariesProps) {
    return (
        <div className="flex-column align-self-flex-start flat-font">
            <p className="bold-text margin-none">Scheduled Appointments</p>

            <div className="padding-top-xsm">
                {installationSummaries.map(summary =>
                    <EditableAppointmentSummary
                        scheduled={summary}
                        key={summary.apptId}
                    />
                )}
            </div>
        </div>
    )
}