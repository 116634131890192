import { DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import { InputLabel, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import { HBar } from "Components/pageSpacing";
import {
    Address, CustomerContact, CustomerContactInput,
    CustomerInput,
    CustomerSearchParametersInput,
    namedOperations,
    RawSahAppointmentDataInput,
    useAddNewSahAppointmentMutation,
    useGetAllActiveZipsAndCitiesQuery,
    useGetAuthenticatedWorkerQuery,
    useGetCustomerAsSearchResultQuery,
    useMergeCustomersMutation
} from "generated/graphql";
import { prepareAddress, prepareCustomer } from "Globals/dataPreparationUtils";
import { dayToIso } from "Globals/DateAndTimeHelpers";
import {
    isAddressValid,
    isEmail,
    isEmptyString,
    isNotEmptyString,
    isNotNullOrUndefined,
    isNullOrUndefined, isPhoneNumber,
    isValidName
} from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import { callCenterPath } from "Globals/PathStrings";
import { useAppHistory } from "Globals/routingHooks";
import { formatPhoneNumber, formatRawPhoneNumber } from "Globals/StringFormatting";
import { useMemo, useState } from "react";
import {
    addSecondaryContact,
    blankContact,
    clearForm,
    dumpCustomerInformation,
    resetCustomerAndContactIds,
    selectAppointmentNotes,
    selectAptNo,
    selectAutofilledAddress, selectBusinessName, selectCity,
    selectColorCategoryIds, selectCustomerId,
    selectDay, selectHowDidYouHearId, selectInternalNotes, selectIsForHome, selectMergeBaseCustomerId, selectPrimaryContact, selectPriorityItemIds,
    selectPromoCodeId,
    selectPromoCodeText,
    selectPtypeIds,
    selectRecentJobId,
    selectRoomCount, selectSecondaryContacts, selectStreetAddress,
    selectTimeSlotId,
    selectZip,
    setAppointmentNotes,
    setAptNo,
    setBusinessName, setDay,
    setInternalNotes, setIsForHome,
    setPrimaryContactFname,
    setPrimaryContactLname,
    setPrimaryEmail,
    setPrimaryPhone,
    setSecondaryContactFirstName, setSecondaryEmail,
    setSecondaryPhone,
    setStreetAddress,
    setTimeSlotId,
    unmerge
} from "Redux/callCenterSchedulingReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import AppointmentTypeSelector from "../../SAHScheduling/Components/AppointmentTypeSelector";
import ColorCategorySelector from "../../SAHScheduling/Components/ColorCategorySelector";
import HowDidYouHearAboutUsSelector from "../../SAHScheduling/Components/HowDidYouHearAboutUsSelector";
import PrioritySelector from "../../SAHScheduling/Components/PrioritySelector";
import ProductTypeSelectionGrid from "../../SAHScheduling/Components/ProductTypeSelectionGrid";
import RoomCountSelector from "../../SAHScheduling/Components/RoomCountSelector";
import TimeSlotCalendar from "../../SAHScheduling/Components/TimeSlotCalendar";
import { buildZipAndCityMap, zipIsServiced } from "../../SAHScheduling/SchedulingDateTime";
import CustomerMatchSection from "../CustomerMatching/CustomerMatchSection";
import LocationSelectorRow from "./LocationSelectorRow";
import MapButton from "./MapButton";
import NotInterestedMenu from "./NotInterestedMenu";
import SchedulingContactEditor from "./SchedulingContactEditor";
import SetCallbackMenu from "./SetCallbackMenu";

// TODO: add error flags to all of these inputs
export default function CallCenterSchedulingPage() {
    const dispatch = useAppDispatch();
    const history = useAppHistory();

    // note this is different from the customerId; if a customer is searched and that information is autofilled, then the URL doesn't change but the customerId does
    const { id: urlCustomerId } = useNumericIdParam();

    useGetCustomerAsSearchResultQuery({
        skip: !urlCustomerId,
        variables: { customerId: urlCustomerId! },
        // autofill customer data
        onCompleted: (data) => {
            const customer = data.customerAsSearchResult;
            if (customer) {
                dispatch(dumpCustomerInformation(customer));
            }
        },
    });

    const [notInterestedOpen, setNotInterestedOpen] = useState(false);
    const [callbackOpen, setCallbackOpen] = useState(false);
    const [contactDialogOpen, setContactDialogOpen] = useState(false);

    // general appt data
    const autofilledAddress = useAppSelector(selectAutofilledAddress);
    const customerId = useAppSelector(selectCustomerId);
    const recentJobId = useAppSelector(selectRecentJobId);
    const isForHome = useAppSelector(selectIsForHome);
    const roomCount = useAppSelector(selectRoomCount);
    const internalNotes = useAppSelector(selectInternalNotes);
    const appointmentNotes = useAppSelector(selectAppointmentNotes);
    const pTypeIds = useAppSelector(selectPtypeIds);
    const selectedPriorityItemIds = useAppSelector(selectPriorityItemIds);
    const selectedColorCategoryIds = useAppSelector(selectColorCategoryIds);

    // primary contact
    const primaryContact = useAppSelector(selectPrimaryContact);
    const primaryContactId = primaryContact.id;
    const primaryFirstName = primaryContact.firstName;
    const primaryLastName = primaryContact.lastName ?? '';
    const primaryPhone = primaryContact.phone ?? '';
    const primaryEmail = primaryContact.email ?? '';

    // secondary contacts - only display the first secondary contact in the list of contacts
    const secondaryContacts = useAppSelector(selectSecondaryContacts);
    const displayedSecondaryContact = secondaryContacts.length > 0 ? secondaryContacts[0] : undefined;
    const displayedSecondaryContactId = displayedSecondaryContact?.id ?? -1;
    const displayedSecondaryPhone = displayedSecondaryContact?.phone ?? '';
    const displayedSecondaryEmail = displayedSecondaryContact?.email ?? '';
    const spouseName = displayedSecondaryContact?.firstName ?? '';
    const businessName = useAppSelector(selectBusinessName);

    function setDisplayedSecondaryFname(newFname: string) {
        if (secondaryContacts.length > 0) {
            dispatch(setSecondaryContactFirstName({contactId: displayedSecondaryContactId, fName: newFname, deleteWhenEmpty: true}));
        } else {
            // must add the contact
            const newContact: CustomerContact = {
                ...blankContact,
                firstName: newFname
            };
            
            dispatch(addSecondaryContact(newContact));
        }
    }

    function setDisplayedSecondaryPhone(newPhone: string) {
        const pNum = formatPhoneNumber(newPhone) ?? '';
        if (secondaryContacts.length > 0) {
            dispatch(setSecondaryPhone({contactId: displayedSecondaryContactId, phone: pNum, deleteWhenEmpty: true}))
        } else {
            // must add the contact
            const newContact: CustomerContact = {
                ...blankContact, 
                phone: pNum
            };
            
            dispatch(addSecondaryContact(newContact));
        }
    }

    function setDisplayedSecondaryEmail(newEmail: string) {
        if (secondaryContacts.length > 0) {
            dispatch(setSecondaryEmail({contactId: displayedSecondaryContactId, email: newEmail, deleteWhenEmpty: true}))
        } else {
            // must add the contact
            const newContact: CustomerContact = {
                ...blankContact,
                email: newEmail
            };
            
            dispatch(addSecondaryContact(newContact));
        }
    }

    // location info
    const streetAddress = useAppSelector(selectStreetAddress);
    const zip = useAppSelector(selectZip);
    const city = useAppSelector(selectCity);
    const aptNo = useAppSelector(selectAptNo);
    const howDidYouHearId = useAppSelector(selectHowDidYouHearId);
    const promoCodeText = useAppSelector(selectPromoCodeText);
    const promoCodeId = useAppSelector(selectPromoCodeId);
    const day = useAppSelector(selectDay);
    const timeSlotId = useAppSelector(selectTimeSlotId);
    const mergeWithBaseCustomerId = useAppSelector(selectMergeBaseCustomerId);

    const customerMatchSearchParams: CustomerSearchParametersInput = {
        firstName: isEmptyString(primaryFirstName.trim()) ? null : primaryFirstName.trim(),
        lastName: isEmptyString((primaryLastName).trim()) ? null : primaryLastName.trim(),
        businessName: isEmptyString(businessName.trim()) ? null : businessName.trim(),
        emailAddress1: isEmptyString(primaryEmail?.trim() ?? '') ? null : primaryEmail.trim(),
        emailAddress2: isEmptyString(displayedSecondaryEmail.trim()) ? null : displayedSecondaryEmail.trim(),
        streetAddress: isEmptyString(streetAddress.trim()) ? null : streetAddress.trim(),
        zip: isEmptyString(zip.trim()) ? null : zip.trim(),
        phoneNumber1: isEmptyString(formatRawPhoneNumber(primaryPhone.trim()))
            ? null
            : formatRawPhoneNumber(primaryPhone.trim()),
        phoneNumber2: isEmptyString(formatRawPhoneNumber(displayedSecondaryPhone.trim()))
            ? null
            : formatRawPhoneNumber(displayedSecondaryPhone.trim())
    };

    const { data: authData } = useGetAuthenticatedWorkerQuery();
    const schedulingWorkerId = authData?.authenticatedWorker?.id ?? -1;

    const { data: zipAndCityData } = useGetAllActiveZipsAndCitiesQuery();
    const zipsAndCities = useMemo(() => {
        return buildZipAndCityMap(zipAndCityData?.allActiveZipsAndCities);
    }, [zipAndCityData?.allActiveZipsAndCities]);

    function onClearForm() {
        // if the URL contains an ID, then we can only clear the merge customer ID and remove contacts not associated with that customer
        if (isNotNullOrUndefined(urlCustomerId)) {
            dispatch(unmerge())
        } else {
            dispatch(resetCustomerAndContactIds());
        }
        dispatch(clearForm());
    }

    function onIsForHomeChanged(newVal: boolean) {
        dispatch(setBusinessName(""));
        dispatch(setIsForHome(newVal));
    }

    function customerDataOk() {
        const trimmedFname = primaryFirstName.trim() ?? "";
        if (!isValidName(trimmedFname)) {
            if (isEmptyString(trimmedFname)) {
                alert("Please enter primary contact's first name");
            } else {
                alert(
                    "Primary contact's first name contains invalid data - ensure that it doesn't contain any non-letter characters"
                );
            }
            return false;
        }

        const trimmedLname = primaryLastName.trim();
        if (!isValidName(trimmedLname)) {
            if (isEmptyString(trimmedLname)) {
                alert("Please enter primary contact's last name");
            } else {
                alert(
                    "Primary contact's first name contains invalid data - ensure that it doesn't contain any non-letter characters"
                );
            }
            return false;
        }

        // if the appointment is not for a business, it doesn't matter what's in this field
        const trimmedBusinessName = businessName.trim();
        let businessNameOk =
            isForHome ||
            (isNotNullOrUndefined(trimmedBusinessName) && isNotEmptyString(trimmedBusinessName));
        if (!businessNameOk) {
            alert("Please enter the name of the customer's business");
            return false;
        }

        // if the appointment is for a business, it doesn't matter what's in this field
        const trimmedSpouseName = spouseName.trim();
        let spouseNameOk =
            !isForHome || isValidName(trimmedSpouseName) || isEmptyString(trimmedSpouseName);
        if (!spouseNameOk) {
            alert(
                "Spouse's name contains invalid data - ensure that it doesn't contain any non-letter characters"
            );
            return false;
        }

        const trimmedPrimaryPhone = primaryPhone.trim();
        if (!isPhoneNumber(trimmedPrimaryPhone)) {
            if (isEmptyString(trimmedPrimaryPhone)) {
                alert("Please enter a phone number for the primary contact");
            } else {
                alert("Primary contact's phone number contains invalid data");
            }
            return false;
        }

        const trimmedPrimaryEmail = primaryEmail.trim();
        if (!isEmail(trimmedPrimaryEmail)) {
            if (isEmptyString(trimmedPrimaryEmail)) {
                alert("Please enter an email address for the primary contact");
            } else {
                alert("Primary contact's email contains invalid data");
            }

            return false;
        }

        const trimmedSpousePhone = displayedSecondaryPhone.trim();
        // this field is optional
        if (!(isPhoneNumber(trimmedSpousePhone) || isEmptyString(trimmedSpousePhone))) {
            alert("Spouse's phone number contains invalid data");
            return false;
        }

        const trimmedSpouseEmail = displayedSecondaryEmail.trim();
        // this field is optional
        if (!(isEmail(trimmedSpouseEmail) || isEmptyString(trimmedSpouseEmail))) {
            alert("Spouse's email address contains invalid data");
            return false;
        }

        // make sure all secondary contacts not shown on the page are valid
        let secondaryContactsOk = secondaryContacts.every(c => secondaryContactValid(c));
        if (!secondaryContactsOk) {
            return false;
        }

        const trimmedZip = zip.trim();
    
        const sanitizedAddress = prepareAddress({id: -1, streetAddress: streetAddress, city: city, zip: zip, apartmentNumber: aptNo});
        const addressOk = isAddressValid(sanitizedAddress, true);
        if (!addressOk) return false;

        if (!zipIsServiced(zipsAndCities, trimmedZip)) {
            alert(`We do not service any cities in ${trimmedZip}`);
            return false;
        }

        return true;
    }

    function canSubmitAppointment() {
        if (!customerDataOk()) {
            return false; // this call will alert to the problem with customer data
        }

        if (schedulingWorkerId < 1) {
            alert("Could not schedule appointment");
            return false;
        }

        if (pTypeIds.length === 0) {
            alert("Select at least 1 product type");
            return false;
        }

        if (selectedPriorityItemIds.length === 0) {
            alert("Select at least 1 priority");
            return false;
        }

        if (selectedColorCategoryIds.length === 0) {
            alert("Select at least 1 color preference");
            return false;
        }

        if (howDidYouHearId < 1) {
            alert("Select an option for how the customer heard about us");
            return false;
        }

        const trimmedPromoCodeText = promoCodeText.trim();
        if (
            !(isEmptyString(trimmedPromoCodeText)
                ? isNullOrUndefined(promoCodeId)
                : isNotNullOrUndefined(promoCodeId))
        ) {
            alert("Promo code is invalid");
            return false;
        }

        if (isNullOrUndefined(day)) {
            alert("Select a date for the appointment");
            return false;
        }

        if (isNullOrUndefined(timeSlotId)) {
            alert("Select a time slot for the appointment");
            return false;
        }

        return true;
    }

    function stateToCustomer() {
        let contacts: CustomerContactInput[] = [];
        const formattedPrimaryPhone = formatRawPhoneNumber(primaryPhone);

        // primary contact
        contacts.push({
            id: primaryContactId, // will be -1 if new,
            customerId: customerId,
            firstName: primaryFirstName,
            lastName: primaryLastName,
            phone: formattedPrimaryPhone,
            email: primaryEmail,
            receivePromoEmails: true,
            isSchedulingContact: true,
            isPrimary: true
        });

        secondaryContacts.forEach(contact => {
            // empty strings will be inserted as null
            const formattedPhone = formatRawPhoneNumber(contact.phone ?? '');
            const email = contact.email;
            contacts.push({
                id: contact.id,
                customerId: contact.customerId,
                firstName: contact.firstName,
                lastName: contact.lastName,
                phone: formattedPhone,
                email: email,
                receivePromoEmails: contact.receivePromoEmails,
                isSchedulingContact: contact.isSchedulingContact,
                isPrimary: false
            });
        });

        // id will be -1 if for a new customer, otherwise it will be the ID of the loaded customer's address
        // the mutation will handle updating/creating accordingly
        let customerAddress: Address = {
            id: autofilledAddress.id, // -1 if customer was not autofilled/merged
            streetAddress: streetAddress,
            city: city,
            zip: zip,
            apartmentNumber: isEmptyString(aptNo) ? null : aptNo,
        };

        const customer: CustomerInput = {
            id: customerId, // -1 if new customer, >= 1 otherwise
            firstName: primaryFirstName,
            lastName: primaryLastName,
            businessName: isEmptyString(businessName) ? null : businessName,
            primaryPhone: formattedPrimaryPhone,
            primaryAddress: customerAddress,
            email: primaryEmail,
            receivePromoEmails: true,
            customerContacts: contacts,
        };

        return customer;
    }

    const [mergeCustomers] = useMergeCustomersMutation();

    const [addNewSAHAppointment, { loading: submitting }] = useAddNewSahAppointmentMutation({
        onCompleted: () => {
            alert("Appointment scheduled successfully!");
            history.push(callCenterPath);
        },
        onError: () => alert("Could not schedule new SAH appointment"),
        refetchQueries: [namedOperations.Query.GetAllCallCenterJobs]
    });

    // takes customer argument so that we can directly pass the merged customer to the mutation once it's returned from the merging mutation
    function addAppointment(customer?: CustomerInput) {
        const apptData: RawSahAppointmentDataInput = {
            customer: prepareCustomer(customer ? customer : stateToCustomer()),
            isForHome: isForHome,
            date: dayToIso(day!),
            marketTimeSlotId: timeSlotId!,
            productTypeIds: pTypeIds,
            priorityOptionIds: selectedPriorityItemIds,
            colorCategoryIds: selectedColorCategoryIds,
            numRooms: roomCount,
            secondaryEmail: isEmptyString(displayedSecondaryEmail) ? null : displayedSecondaryEmail,
            internalNotes: isEmptyString(internalNotes) ? null : internalNotes,
            appointmentNotes: isEmptyString(appointmentNotes) ? null : appointmentNotes,
            promoCodeId: promoCodeId,
            howDidYouHearAboutUsOptionId: howDidYouHearId,
            scheduledByWorkerId: schedulingWorkerId,
        };

        addNewSAHAppointment({ variables: { newSAHAppointment: apptData } });
    }

    function onSubmit() {
        if (canSubmitAppointment()) {
            // need to merge before we create the appt
            if (mergeWithBaseCustomerId > 0) {
                mergeCustomers({
                    variables: {
                        customerToMergeId: mergeWithBaseCustomerId,
                        customerToMergeInto: stateToCustomer(),
                    },
                    onCompleted: (data) => {
                        const mergedCustomer = data.mergeCustomers;
                        addAppointment(mergedCustomer);
                    },
                    onError: () => alert("Could not merge customer. Appointment was not scheduled.")
                });
            } else {
                addAppointment();
            }
        }
    }

    function submitMenus(submitted: boolean) {
        setNotInterestedOpen(false);
        setCallbackOpen(false);

        if (submitted) {
            history.push(callCenterPath);
        }
    }

    return (
        <NavbarPage
            title="Schedule SAH Appointment"
            padContent
            centerHorizontally
        >
            {schedulingWorkerId < 1 ? (
                <Loading />
            ) : (
                <div className="flex-column margin-bottom-lg padding-md flex-gap-xsm">
                    <CustomerMatchSection
                        searchParameters={customerMatchSearchParams}
                        urlCustomerId={urlCustomerId}
                    />

                    <div className="flex-column flex-gap-xsm">
                        <HBar />
                        <div
                            className={clsx("flex-row flex-space-end flex-gap-sm", {
                                "visibility-hidden": recentJobId === undefined,
                            })}
                        >
                            <SpacedButton
                                variant="contained"
                                onClick={() => setNotInterestedOpen(true)}
                            >
                                Not Interested
                            </SpacedButton>
                            <SpacedButton
                                variant="contained"
                                onClick={() => setCallbackOpen(true)}
                            >
                                Set Call Back
                            </SpacedButton>
                        </div>
                        <HBar />
                    </div>

                    <div className="flex-column flex-gap-md">
                        <button
                            onClick={onClearForm}
                            className="margin-bottom-xsm flat-button-base flat-primary-major-button"
                        >
                            Clear Form
                        </button>
                        <div className="flex-row flex-space-between flex-gap-lg">
                            <TextField
                                required
                                label="First Name"
                                className="w-13r"
                                value={primaryFirstName}
                                onChange={(e) => dispatch(setPrimaryContactFname(e.target.value))}
                            />
                            <TextField
                                required
                                label="Last Name"
                                className="w-13r"
                                value={primaryLastName}
                                onChange={(e) => dispatch(setPrimaryContactLname(e.target.value))}
                            />
                            {isForHome ? (
                                <TextField
                                    label="Spouse's Name"
                                    className="w-13r"
                                    value={spouseName}
                                    onChange={(e) => setDisplayedSecondaryFname(e.target.value)}
                                />
                            ) : (
                                <TextField
                                    label="Business Name"
                                    className="w-13r"
                                    value={businessName}
                                    onChange={(e) => setBusinessName(e.target.value)}
                                />
                            )}
                        </div>

                        <div className="flex-row flex-space-between flex-gap-lg">
                            <TextField
                                required
                                label="Primary Phone"
                                className="w-13r"
                                value={primaryPhone}
                                onChange={(e) =>
                                    dispatch(
                                        setPrimaryPhone(formatPhoneNumber(e.target.value) ?? "")
                                    )
                                }
                            />
                            <TextField
                                required
                                label="Primary Email"
                                className="w-13r"
                                value={primaryEmail}
                                onChange={(e) => dispatch(setPrimaryEmail(e.target.value))}
                            />
                            <HowDidYouHearAboutUsSelector />
                        </div>

                        <div className="flex-row flex-space-between flex-gap-lg">
                            <TextField
                                label="Secondary Phone"
                                className="w-13r"
                                value={displayedSecondaryPhone}
                                onChange={(e) => setDisplayedSecondaryPhone(e.target.value)}
                            />
                            <TextField
                                label="Secondary Email"
                                className="w-13r"
                                value={displayedSecondaryEmail}
                                onChange={(e) => setDisplayedSecondaryEmail(e.target.value)}
                            />

                            <button
                                onClick={() => setContactDialogOpen(true)}
                                className="flat-button-base flat-primary-minor-button w-13r"    
                            >View/Edit All Contacts</button>
                            {/* <PromoCodeInput /> */}
                        </div>

                        <LocationSelectorRow zipsAndCities={zipsAndCities} />

                        <div className="flex-row flex-space-between flex-gap-lg">
                            <TextField
                                required
                                label="Street Address"
                                className="w-13r"
                                value={streetAddress}
                                onChange={(e) => dispatch(setStreetAddress(e.target.value))}
                            />
                            <TextField
                                label="Apartment/Suite Number"
                                className="w-13r"
                                value={aptNo}
                                onChange={(e) => dispatch(setAptNo(e.target.value))}
                            />
                            <div className="w-13r flex-row align-items-flex-end">
                                <MapButton />
                            </div>
                        </div>

                        {customerId > 0 && (
                            <Typography className="error-text">
                                NOTE: Customer information was autofilled - any changes made here
                                will cause customer information to be updated
                            </Typography>
                        )}

                        <div className="flex-column flex-gap-sm margin-top-md">
                            <div className="flex-row flex-space-between">
                                <AppointmentTypeSelector
                                    isForHome={isForHome}
                                    setIsForHome={onIsForHomeChanged}
                                />
                                <RoomCountSelector hideBorder />
                            </div>

                            <ProductTypeSelectionGrid />
                        </div>

                        <div className="flex-row margin-top-md flex-gap-md align-items-flex-end">
                            <div style={{ width: "75%" }}>
                                <InputLabel>Internal Notes</InputLabel>
                                <TextField
                                    value={internalNotes}
                                    onChange={(e) => dispatch(setInternalNotes(e.target.value))}
                                    className="fill-width overflow-auto rounded-border padding-xsm"
                                    InputProps={{ disableUnderline: true }}
                                    style={{ border: "1px solid black" }}
                                    multiline
                                    minRows={5} maxRows={5}
                                />
                            </div>

                            <div
                                className="flex-column flex-gap-md align-items-flex-end"
                                style={{ width: "25%" }}
                            >
                                <PrioritySelector />
                                <ColorCategorySelector />
                            </div>
                        </div>

                        <div className="flex-row margin-top-md flex-gap-md align-items-flex-end">
                            <div style={{ width: "75%" }}>
                                <InputLabel>Appointment/Salesperson Notes</InputLabel>
                                <TextField
                                    value={appointmentNotes}
                                    onChange={(e) => dispatch(setAppointmentNotes(e.target.value))}
                                    className="fill-width overflow-auto rounded-border padding-xsm"
                                    InputProps={{ disableUnderline: true }}
                                    style={{ border: "1px solid black" }}
                                    multiline minRows={5} maxRows={5}
                                />
                            </div>
                            {/* just here to facilitate sizing this text box like the one above it */}
                            <div style={{width: "25%"}}/> 
                        </div>

                        <TimeSlotCalendar
                            landscape
                            zip={zip ?? ""}
                            zipIsServiced={zipIsServiced(zipsAndCities, zip ?? "")}
                            day={day}
                            setDay={(newDay: DayValue) => dispatch(setDay(newDay))}
                            timeSlotId={timeSlotId}
                            setTimeSlotId={(newId: number | null) => dispatch(setTimeSlotId(newId))}
                            onSubmit={onSubmit}
                            submitting={submitting}
                        />
                    </div>
                </div>
            )}

            <NotInterestedMenu
                jobId={recentJobId ?? -1}
                open={notInterestedOpen}
                onClose={submitMenus}
            />

            <SetCallbackMenu
                jobId={recentJobId ?? -1}
                open={callbackOpen}
                onClose={submitMenus}
            />

            {contactDialogOpen && <SchedulingContactEditor onDone={() => setContactDialogOpen(false)} /> }
        </NavbarPage>
    );
}

function secondaryContactValid(contact: CustomerContact) {
    
    const trimmedFname = contact.firstName.trim();
    const fNameOk = isValidName(trimmedFname) && isNotEmptyString(trimmedFname);
    if (!fNameOk) {
        alert("Ensure that all contacts have first names, and that they only contain letters");
        return false;
    }

    // last name is optional
    const trimmedLname = contact.lastName?.trim() ?? '';
    const lNameOk = isEmptyString(trimmedLname) || isValidName(trimmedLname);
    if (!lNameOk) {
        alert("Ensure all contact last names contain only letters");
        return false;
    }

    // phone # is optional
    const trimmedPhone = contact.phone ?? '';
    const phoneOk = isEmptyString(trimmedPhone) || isPhoneNumber(trimmedPhone);
    if (!phoneOk) {
        alert("Found an invalid phone number")
        return false;
    }

    // email is optional
    const trimmedEmail = contact.email ?? '';
    let emailOk = isEmptyString(trimmedEmail) || isEmail(trimmedEmail);
    if (!emailOk) {
        alert("Found an invalid email address");
        return false;
    }

    return true;
}
