import { IconButton } from "@material-ui/core";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { useCheckOutOfSahAppointmentMutation, useGetSahAppointmentIdForJobQuery } from "generated/graphql";
import { useAppHistory } from "Globals/routingHooks";

export default function NoQuoteButton({ jobConfigurationId }: { jobConfigurationId: number }) {
    const history = useAppHistory();

    const { data: appointmentIdData } = useGetSahAppointmentIdForJobQuery({
        variables: { jobConfigurationId: jobConfigurationId },
        skip: jobConfigurationId < 1
    });

    const appointmentId = appointmentIdData?.sAHAppointmentIdForJob;
    const [checkoutSalesperson] = useCheckOutOfSahAppointmentMutation({});

    function onNoQuote() {
        if (window.confirm("Are you sure you want to report this as a no quote? This can't be undone.")) {
            if (!appointmentId || appointmentId! < 1) {
                alert("Could not check out of appointment. Please retry in a few seconds.");
            } else {
                checkoutSalesperson({ variables: { sahAppointmentId: appointmentId! } })
                    .then((res) => {
                        if (res.data?.checkOutOfSAHAppointment) {
                            history.push("/");
                        } else {
                            alert("Could not check out of appointment.")
                        }
                    });
            }
        }
    }

    return (
        <IconButton
            id="no-quote-btn"
            onClick={onNoQuote}
            disabled={jobConfigurationId < 1 || (appointmentId ?? -1) < 1}
        >
            <AnnouncementIcon />
        </IconButton>
    )
}