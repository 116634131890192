import { MenuItem, Select, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import FlatUndoDeleteButton from "FlatComponents/Button/FlatUndoDeleteButton";
import { LabelForRoom } from "generated/graphql";
import { findPlywoodOptionIndex, plywoodOptions } from "Pages/Admin/ProjectManagement/SellSheet/InstallationDetailsEditor/BuildUpEditor";
import { useMemo } from "react";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { BuildUpServiceForRoomPriceText } from "./ServiceForRoomPriceText";
import { WhoDoesServiceEditorForRoomProps } from "./WhoDoesServiceEditorForRoom";

export interface ServiceMaterialDetails {
    serviceForRoomId: number;
    materialCategoryId: number;
    price: number;
    isDeleted: boolean;
}

export interface BuildUpServiceDetails {
    roomId: number;
    roomLabels: LabelForRoom[];
    serviceMaterialDetails: ServiceMaterialDetails[];
}

interface FloorPrepSubEditor extends Omit<WhoDoesServiceEditorForRoomProps, "originalRoom" | "updateAmount" | "editableRoom" | "updateWhoDoes"> {
    originalServiceDetails: BuildUpServiceDetails | null;
    editableServiceDetails: BuildUpServiceDetails;
    setMaterialCategoryIds: (newIds: number[]) => void;
}

/**
 * Only used for floor prep services
 */
export default function BuildUpServiceEditorForRoom({
    originalServiceDetails,
    editableServiceDetails,
    setMaterialCategoryIds,
    removeRoomFromService,
    restoreService,
    disabled
}: FloorPrepSubEditor) {
    const materialCategoryIds = editableServiceDetails.serviceMaterialDetails
        .filter(s => !s.isDeleted)
        .map(obj => obj.materialCategoryId);
    const formattedPrice = (
        <BuildUpServiceForRoomPriceText
            originalServices={originalServiceDetails?.serviceMaterialDetails ?? null}
            editableServices={editableServiceDetails.serviceMaterialDetails}
        />
    );

    return (
        <tr className="flat-font">
            <td
                align="left"
                className={clsx({
                    "line-through": editableServiceDetails.serviceMaterialDetails.every(s => s.isDeleted),
                    "success-text": editableServiceDetails.serviceMaterialDetails.some(obj => obj.serviceForRoomId < 0) // highlight new services green 
                })}
            >
                <span className="flex-row align-items-center flex-gap-xsm">
                    {editableServiceDetails.serviceMaterialDetails.every(s => s.isDeleted) ? (
                        <Tooltip title="Restore this service">
                            <span>
                                <FlatUndoDeleteButton onClick={restoreService}/>
                            </span>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Remove this service">
                            <span>
                                <FlatDeleteButton onClick={removeRoomFromService}/>
                            </span>
                        </Tooltip>
                    )}
                    {getNameOfArea(editableServiceDetails.roomLabels)}
                </span>
            </td>
            <td align="left">
                <PlywoodSelect
                    disabled={disabled}
                    selectedMaterialCategoryIds={materialCategoryIds}
                    setSelectedMaterialCategoryIds={setMaterialCategoryIds}
                />
            </td>
            <td
                style={{ minWidth: "5rem" }}
                align="right"
                colSpan={2}
            >
                {formattedPrice}
            </td>
        </tr>
    )
}

interface PlywoodSelectProps {
    disabled: boolean;
    selectedMaterialCategoryIds: number[];
    setSelectedMaterialCategoryIds: (newIds: number[]) => void;
}

function PlywoodSelect({selectedMaterialCategoryIds, setSelectedMaterialCategoryIds, disabled}: PlywoodSelectProps) {
    const selectedPlywoodIdx = useMemo(() => {
        return findPlywoodOptionIndex(selectedMaterialCategoryIds);
    }, [selectedMaterialCategoryIds]);

    function plywoodSelectionChanged(newIdx: number) {
        setSelectedMaterialCategoryIds(plywoodOptions[newIdx].materialCategoryIds);
    }

    return (
        <Select
            disabled={disabled}
            value={selectedPlywoodIdx}
            onChange={e => plywoodSelectionChanged(e.target.value as number)}
            label="Plywood"
        >
            {(selectedPlywoodIdx < 0) && <MenuItem value={-1} disabled>Select Build Up Amount</MenuItem>}
            {plywoodOptions.map(opt => <MenuItem key={`buo-${opt.selectIdx}`} value={opt.selectIdx}>{opt.description}</MenuItem>)}
        </Select>
    )
}