import { IconButton, Switch } from "@material-ui/core";
import PriceRequestResponseBox from "FlatComponents/Chat/ActionComponents/PriceRequestResponseBox";
import ChatDrawer from "FlatComponents/Chat/ChatDrawer";
import CustomerAppointmentSearchBar from "Components/Inputs/Search/CustomerAppointmentSearchBar";
import NavbarPage from "Components/Page";
import FlatNavbar from "FlatComponents/Layout/FlatNavbar";
import {
    useGetAllSalespeopleInMarketQuery,
    useGetAllTimeSlotsForMarketQuery,
    useGetAuthenticatedCommandCenterWorkerIdQuery,
    useGetAuthenticatedWorkerQuery,
    useGetGeneralCommandCenterWorkerIdQuery
} from "generated/graphql";
import { dateToYmd, dayOfWeekNumberToString, ymdDashedToDate } from "Globals/DateAndTimeHelpers";
import { useMemo, useState } from "react";
import "react-contexify/dist/ReactContexify.css";
import {
    setSendingWorker
} from "Redux/chatDrawerReducer";
import { useAppDispatch } from "Redux/hooks";
import CommandCenterBody from "./Dashboard/CommandCenterBody";
import { CommandCenterMarketSelect } from "./Dashboard/DashboardFilters";

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { NotAuthorized } from "Components/RequireAuth";
import FlatDatePicker from "FlatComponents/Visual/FlatDatePicker";

export const COMMAND_CENTER_MENU_ID = "command-center-context-menu";

export default function CommandCenterPage() {
    const [date, setDate] = useState<Date>(new Date());
    const dateAsYMD = useMemo(() => dateToYmd(date), [date]);

    const [marketId, setMarketId] = useState<number | undefined>(undefined);
    const [is24Hr, setIs24Hr] = useState<boolean>(false);
    const [highlightAppointmentId, setHighlightAppointmentId] = useState<number | null>(null);
    const dispatch = useAppDispatch(); // for chat things

    const { data: timeSlotData } = useGetAllTimeSlotsForMarketQuery({
        variables: { marketId: marketId ?? -1 },
        skip: marketId === undefined,
    });

    const { data: salespeopleData } = useGetAllSalespeopleInMarketQuery({
        variables: { marketId: marketId ?? -1 },
        fetchPolicy: "cache-and-network",
        skip: marketId === undefined,
        pollInterval: 2000,
    });

    const { data: authWorkerData } = useGetAuthenticatedWorkerQuery({
        onCompleted: (data) => {
            const worker = data.authenticatedWorker;
            // worker must be command center worker
            if (worker?.isCommandCenterWorker) {
                dispatch(setSendingWorker(worker));
            }
        },
    });
    // authenticated user must be command center worker
    const ccWorker = authWorkerData?.authenticatedWorker?.isCommandCenterWorker
        ? authWorkerData.authenticatedWorker
        : undefined;

    const { data: authCcWorkerData, loading } = useGetAuthenticatedCommandCenterWorkerIdQuery();
    const authCcWorkerId = authCcWorkerData?.authenticatedCommandCenterWorkerId;
    const { data: generalCcWorkerData } = useGetGeneralCommandCenterWorkerIdQuery();
    const generalCcWorkerId = generalCcWorkerData?.generalCommandCenterWorkerId;

    const dayAsDate = new Date(date);

    function addDaysToDate(daysToAdd: number) {
        let d = new Date(dayAsDate);
        d.setDate(d.getDate() + daysToAdd);
        setDate(d);
    }

    const ableToRender =
        marketId !== undefined &&
        timeSlotData !== undefined &&
        salespeopleData !== undefined &&
        ccWorker !== undefined &&
        authCcWorkerId &&
        generalCcWorkerId;
        
    return (
        <NavbarPage hideNavbar>
            <FlatNavbar
                title="Command Center "
                menuContent={
                    <div className="flex-row flex-centered flat-font">
                        <div>12Hr</div>
                        <Switch
                            checked={is24Hr}
                            onChange={() => setIs24Hr(!is24Hr)}
                        />
                        <div>24Hr</div>
                    </div>
                }
            />
            <div
                className="flex-row"
                style={{
                    justifyContent: "space-around",
                    backgroundColor: "var(--flat-gray-1)",
                    flexGrow: 1,
                    paddingTop: "2rem",
                }}
            >
                <div
                    style={{
                        minHeight: "10rem",
                        maxHeight: "calc(100vh - 72px - 3rem)",
                        overflow: "auto",
                    }}
                >
                    <div
                        className="flex-row"
                        style={{
                            backgroundColor: "var(--flat-gray-6)",
                            borderTopLeftRadius: "var(--flat-inner-container-radius)",
                            borderTopRightRadius: "var(--flat-inner-container-radius)",
                            alignItems: "center",
                            height: "3rem",
                        }}
                    >
                        <IconButton
                            onClick={() => addDaysToDate(-1)}
                            size="small"
                            style={{marginRight:"2rem"}}
                        >
                            <NavigateBeforeIcon
                                fontSize="large"
                                style={{ color: "var(--flat-gray-4)" }}
                            />
                        </IconButton>
                        <CommandCenterMarketSelect
                            marketId={marketId}
                            setMarketId={setMarketId}
                        />
                        <div
                            className="flex-row flat-dark-bkg"
                            style={{ alignItems: "center" }}
                        >
                            <div
                                className="flat-font-md"
                                style={{ paddingRight: ".25rem" }}
                            >
                                {dayOfWeekNumberToString(ymdDashedToDate(dateAsYMD).getDay())}
                            </div>
                            <FlatDatePicker
                                selected={date}
                                onChange={(d) => setDate(d ?? new Date())}
                            />
                        </div>
                        <CustomerAppointmentSearchBar
                            setDate={d => setDate(ymdDashedToDate(d))}
                            setMarketId={setMarketId}
                            setHighlightedAppointmentId={setHighlightAppointmentId}
                        />
                        <IconButton
                            onClick={() => addDaysToDate(1)}
                            size="small"
                            style={{marginLeft:"2rem"}}
                        >
                            <NavigateNextIcon
                                fontSize="large"
                                style={{ color: "var(--flat-gray-4)" }}
                            />
                        </IconButton>
                    </div>
                    
                    {ableToRender && (
                        <>
                            <CommandCenterBody
                                date={dateAsYMD}
                                marketId={marketId ?? -1}
                                use24HrFormat={is24Hr}
                                timeSlots={timeSlotData?.allTimeSlotsForMarket ?? []}
                                salespeople={salespeopleData.allSalespeopleInMarket.filter(sp => sp.isActive) ?? []}
                                thisCcWorkerId={authCcWorkerId!}
                                generalCcWorkerId={generalCcWorkerId!}
                                highlightAppointmentId={highlightAppointmentId}
                            />

                            <ChatDrawer 
                                actionComponent={<PriceRequestResponseBox usageContext="cc" />}
                                date={date}
                                marketId={marketId}                                
                            />
                        </>
                    )}

                    {authCcWorkerId === null && !loading && (
                        <div className="fill-width flex-row justify-content-center">
                            <NotAuthorized />
                        </div>
                    )}
                </div>
            </div>
        </NavbarPage>
    );
}
