import { useEffect, useState } from "react";

export const MILLISECONDS_IN_A_DAY = 86400000
export const SECONDS_IN_A_DAY = 86400
export const SECONDS_IN_AN_HOUR = 3600
export const SECONDS_IN_A_MINUTE = 60

export function CountUpTextClock({ creationTime }: { creationTime: string }) {
    const startingTime = new Date(creationTime);
    const [currentTime, setCurrentTime] = useState(new Date());

    const secondsBetween = Math.floor((currentTime.getTime() - startingTime.getTime()) / 1000);

    let remaining = secondsBetween
    // Seconds in a day 
    const days = Math.floor(remaining / SECONDS_IN_A_DAY);
    remaining %= SECONDS_IN_A_DAY

    // If we want to change the threshhold for when it should render condensed, it can be done here
    const renderCondensed = days > 0

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (days === 0) {
        // Seconds in an hour
        hours = Math.floor(remaining / SECONDS_IN_AN_HOUR)
        remaining %= SECONDS_IN_AN_HOUR

        minutes = Math.floor(remaining / SECONDS_IN_A_MINUTE)
        remaining %= SECONDS_IN_A_MINUTE

        seconds = remaining
    }

    const outputText = days > 0
        ? `${Math.max(1, days)} Day${days > 1 ? "s" : ""}`
        : `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`

    useEffect(() => {
        if (!renderCondensed) {
            const interval = setInterval(() => {
                setCurrentTime(new Date());
            }, 1000);

            return () => clearInterval(interval)
        }
        else return () => { }
    })

    return <div>{outputText}</div>
}

// export function CountDownBusinessTextClock({ creationTime, businessDaysToCount }: { creationTime: string, businessDaysToCount: number }) {
//     const startingTime = new Date(creationTime);
//     const currentDate = new Date();

//     let daysRemaining = businessDaysToCount;

//     for (let i = businessDaysToCount; i > 0; i--) {
//         const testDate = countBusinessDaysFrom(startingTime, i);
//         testDate.setDate(testDate.getDate() + 1)

//         if (currentDate > testDate) {
//             daysRemaining = businessDaysToCount - i;
//             break;
//         }
//     }

//     const outputText = daysRemaining > 0
//         ? `${daysRemaining} Day${daysRemaining > 1 ? "s" : ""}`
//         : ""

//     return <div className="flex-row" style={{ alignItems: "center" }}>
//         {daysRemaining > 0 && <HourglassEmptyIcon style={{ fontSize: "unset", color: "black" }} />}
//         {outputText}
//     </div>
// }