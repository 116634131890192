import FlatSection from "FlatComponents/Layout/FlatSection";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { Breakdowns } from "../Breakdown/BreakdownTableUtils";
import LaborBreakdownTable from "../Breakdown/LaborBreakdownTable";
import MaterialBreakdownTable from "../Breakdown/MaterialBreakdownTable";
import EditingPane from "./EditingPane";
import ReturnsPane, { MaterialReturn } from "./ReturnsPane";

interface CurrentSectionProps {
    currentBreakdowns: Breakdowns | undefined;
    returns: MaterialReturn[];
}

export default function CurrentSection({currentBreakdowns, returns}: CurrentSectionProps) {
    return (
        <FlatSection header="Current" collapsible>  
            {isNotNullOrUndefined(currentBreakdowns) && <>
                <MaterialBreakdownTable areaBreakdowns={currentBreakdowns!} />
                <div className="margin-top-md">
                    <LaborBreakdownTable areaBreakdowns={currentBreakdowns!}/>
                </div>
            </>}

            <div className="flex-row flex-gap-sm fill-width margin-top-sm fill-height">
                <ReturnsPane returns={returns} />
                <EditingPane originalPrice={1263.55} originalOverriddenPrice={1149.99}/>
            </div>
        </FlatSection>
    )
}

