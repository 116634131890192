import NavbarPage from "Components/Page";
import ServiceProductPricingTable from "./ServiceProductPricingTable";

export default function ServiceProductPricing() {

    return (
        <NavbarPage title='Accessory Product Pricing' centerHorizontally>
            <ServiceProductPricingTable />
        </NavbarPage>
    )
}