import NavbarPage from "Components/Page";
import PriceRequestInput from "FlatComponents/Chat/ActionComponents/PriceRequestInput";
import Chat from "FlatComponents/Chat/Chat";
import {
    GetChatForJobQuery,
    GetJobConfigurationDocument, JobChatCommandCenterMemberChangedDocument, JobChatCommandCenterMemberChangedSubscription, useGetAuthenticatedWorkerQuery, useGetChatForJobQuery, useGetJobActiveContractIdQuery, useGetJobIdQuery,
    useGetPreContractJobSummaryDataQuery, useUpdateJobFinancingOptionMutation
} from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useNumericIdParam } from "Globals/Hooks";
import { originalContractPath } from "Globals/PathStrings";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import JobHeader from "../JobHeader";
import { CompareButton } from "./CompareButton";
import { FinancingRow } from "./FinancingCard/FinancingRow";
import { GoToCartButton } from "./GoToCartButton";
import NoQuoteButton from "./NoQuoteButton";
import QuoteRow from "./QuoteCard/QuoteRow";
import "./SellSheet.css";

export type QuoteCardChanges = { [areaId: number]: QuoteCardChangableState }

export interface QuoteCardChangableState {
    productTypeId: number | undefined,
    productTypeName: string | undefined,
    styleId?: number | undefined,
    styleName?: string | undefined,
    finalPrice?: number | undefined
}

export interface QuoteCardState {
    jobConfigurationId: number,
    isCopy: boolean,
    productChanges: QuoteCardChanges,
    jobPrice: number,
    selectedFinancingOptionId: number | undefined, //Note -1 represents salestax, undefined means nothing
    isSelected: boolean,
    isDeletable: boolean,
    localId: number,
    optionNumber: number
}

export const blankQuoteCard: QuoteCardState = {
    jobConfigurationId: -1,
    isCopy: false,
    isDeletable: true,
    productChanges: {},
    isSelected: false,
    optionNumber: 0,
    localId: -1,
    jobPrice: 0,
    selectedFinancingOptionId: -2
}

export default function SellSheet() {
    const { id } = useNumericIdParam();

    const jobConfigurationId = id

    const { data: jobIdData } = useGetJobIdQuery({
        skip: !jobConfigurationId,
        variables: { jobConfigurationId: jobConfigurationId! }
    });
    const jobId = jobIdData?.jobId ?? -1;

    const history = useHistory();
    // if the job already has a contract, take user away from the sell sheet
    useGetJobActiveContractIdQuery({
        variables: {jobId: jobId!},
        skip: jobId < 1,
        onCompleted: (data) => {
            const contractId = data.jobActiveContractId;
            if (contractId > 0) {
                history.push(`${originalContractPath}/${contractId}`)
            }
        }
    });

    const [displayedCards, setDisplayedCards] = useState<QuoteCardState[]>([
        { ...blankQuoteCard, jobConfigurationId: jobConfigurationId ?? -1, isDeletable: false, localId: 0 }
    ]);

    useGetPreContractJobSummaryDataQuery({
        variables: { jobConfigurationId: jobConfigurationId ?? -1 },
        onCompleted(data) {
            if (!isNullOrUndefined(data)) {
                const configs = data.preContractJobSummaryData.configIds ?? []
                const configIdsToAdd = configs.filter(c => !displayedCards.some(d => d.jobConfigurationId === c))
                if (configIdsToAdd.length > 0) {
                    const lowestId = Math.min(...configs, ...displayedCards.map(c => c.jobConfigurationId))

                    const newCards = configIdsToAdd.map(id => {

                        return { ...blankQuoteCard, jobConfigurationId: id, isDeletable: lowestId !== id }
                    })

                    addNewDisplayedCards([...displayedCards, ...newCards])
                }
            }
        },
        skip: isNullOrUndefined(jobConfigurationId) || jobConfigurationId! < 0,
        fetchPolicy: "network-only"
    });

    const [showFinancingRow, setShowFinancingRow] = useState(false);
    const [updateFinancingOption, { loading: loadingFinChanges }] = useUpdateJobFinancingOptionMutation();

    const [selectedLocalId, setSelectedLocalId] = useState<number | undefined>(undefined)
    const selectedIndex = displayedCards.findIndex(crd => crd.localId === selectedLocalId);
    const selectedCard = displayedCards[selectedIndex] ?? blankQuoteCard;
    const selectedId = selectedCard?.jobConfigurationId ?? 0;
    const selectedFinancingOptionId = selectedCard?.selectedFinancingOptionId ?? -2;
    const selectedCardPrice = selectedCard?.jobPrice ?? 0;

    const nextOptionNumber = Math.max(...displayedCards.map(state => state.optionNumber)) + 1

    // data for chat
    const { data: salespersonData } = useGetAuthenticatedWorkerQuery();
    let salesperson = salespersonData?.authenticatedWorker ?? undefined;

    const { data: jobChatData, subscribeToMore } = useGetChatForJobQuery({
        variables: { jobId: jobId! },
        skip: jobId < 1
    });
    const chatId = jobChatData?.chatForJob.chatId ?? -1;
    const jobMember = jobChatData?.chatForJob.jobMember;
    const commandCenterMember = jobChatData?.chatForJob.commandCenterMember;

    // listens for changes in the command center chat member, which happens when a price request is claimed
    const listenForCcPartyChange = useCallback(() => {
        if (subscribeToMore) {
            subscribeToMore({
                document: JobChatCommandCenterMemberChangedDocument,
                variables: { jobId: jobId },
                updateQuery: (prev, { subscriptionData }) => {
                    const newCcMember = (subscriptionData.data as unknown as JobChatCommandCenterMemberChangedSubscription).jobChatCommandCenterMemberChanged;
                    if (!newCcMember) { return prev; }
                    const updatedQuery: GetChatForJobQuery = {
                        ...prev,
                        chatForJob: {
                            ...prev.chatForJob,
                            commandCenterMember: newCcMember
                        }
                    }
                    return updatedQuery;
                }
            });
        }
    }, [subscribeToMore, jobId]);
    useEffect(() => {
        if (listenForCcPartyChange) { listenForCcPartyChange(); }
    }, [listenForCcPartyChange]);

    const readyToProceed = selectedIndex !== -1 || isNotNullOrUndefined(selectedCard);

    if (isNullOrUndefined(jobConfigurationId)) {
        return (
            <NavbarPage hideNavbar>
                <JobHeader title={'No Job Selected'} hideConfigPicker />
            </NavbarPage>
        )
    }

    function addNewDisplayedCards(newStates: QuoteCardState[]) {
        const cards = [...newStates]
        for (var i = 0; i < cards.length; i++) {
            if (cards[i].localId === -1) {
                // Found a new card, needs a new ID
                const newId = getNextLocalId(cards)
                cards[i] = { ...cards[i], localId: newId }
            }
        }
        setDisplayedCards(cards);
    }

    function updateSelectedCardState(newState: QuoteCardState) {
        let copy = [...displayedCards];
        copy.splice(selectedIndex, 1, newState)
        setDisplayedCards(copy);
    }
    
    function createAndAddNewCard(newJobConfigId: number, newOptionNumber: number, atIndex: number) {
        const copy = [...displayedCards]
        copy.splice(atIndex, 0, { ...blankQuoteCard, jobConfigurationId: newJobConfigId, optionNumber: newOptionNumber })
        addNewDisplayedCards(copy)
    }

    function selectNewFinancing(newId: number | undefined) {
        if (selectedCard.isCopy) {
            const copy = [...displayedCards]
            copy[selectedIndex] = { ...copy[selectedIndex], selectedFinancingOptionId: newId }
            setDisplayedCards(copy)
        }
        else {
            updateFinancingOption({
                variables: {
                    jobConfigurationId: selectedId,
                    financingOptionId: newId ?? -2
                },
                refetchQueries: [{
                    query: GetJobConfigurationDocument,
                    variables: { jobConfigurationId: selectedId },
                    fetchPolicy: "network-only"
                }]
            })
        }
    }

    return (
        <NavbarPage hideNavbar className="ss-page-color">
            <JobHeader title={'Sell Sheet'} hideConfigPicker />
            <div className="flex-column " style={{ padding: "1vh 0", alignItems: "center", position: "relative", height: "92vh" }}>
                <div className="ss-top-left-floating-menu">
                    <CompareButton
                        jobConfigurationId={jobConfigurationId!}
                        displayedCards={displayedCards}
                        setDisplayedCards={addNewDisplayedCards}
                        nextOptionNumber={nextOptionNumber}
                    />
                </div>

                <QuoteRow
                    setSelectedLocalId={setSelectedLocalId}
                    quoteStates={displayedCards}
                    setQuoteStates={setDisplayedCards}
                    addNewCard={createAndAddNewCard}
                    nextOptionNumber={nextOptionNumber}
                    showFinancingRow={showFinancingRow}
                    setShowFinancingRow={setShowFinancingRow}
                />
                
                <div className="ss-bottom-gallery ss-snaps-inline hide-scrollbar" style={{ flex: 1 }}>
                    {(showFinancingRow) &&
                        <FinancingRow
                            loadingFinancingChanges={loadingFinChanges}
                            financingOptionId={selectedFinancingOptionId}
                            jobPrice={selectedCardPrice}
                            selectFinancingOptionId={selectNewFinancing}
                            jobConfigurationId={selectedCard.isCopy ? null : selectedCard.jobConfigurationId}
                        />
                    }
                </div>
                <div className="ss-bottom-right-floating-button">
                    <GoToCartButton
                        selectedQuote={selectedIndex !== -1 ? displayedCards[selectedIndex] : undefined}
                        readyToProceed={readyToProceed}
                    />
                </div>
                <NoQuoteButton jobConfigurationId={jobConfigurationId ?? -1}/>
            </div>

            {(chatId > 0 && jobConfigurationId && commandCenterMember) && (
                <Chat 
                    chatId={chatId}
                    sendingMember={jobMember!}
                    sendingWorker={salesperson!}  // won't ever be able to open the chat if the user (salesperson) is not authenticated
                    actionComponent={(
                        <PriceRequestInput
                            chatId={chatId}
                            jobId={jobId}
                            selectedCard={selectedCard}
                            updateCard={updateSelectedCardState}
                            optionNumber={selectedCard.optionNumber}
                        />
                    )}
                    
                    title={commandCenterMember!.partyName}
                />
            )}
        </NavbarPage>
    )
}

// Returns the highest unused local id + 1
function getNextLocalId(states: QuoteCardState[]) {
    return Math.max(...states.map(state => state.localId)) + 1
}

export function AlwaysScrollToEnd() {
    const elementRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const timeout = setTimeout(() => {
            elementRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 1000)
        return () => clearTimeout(timeout)
    });
    return <div ref={elementRef} />;
}