import { Menu, MenuItem, Select } from "@material-ui/core"
import clsx from "clsx"
import SpacedButton from "Components/Forms/Controls/SpacedButton"
import { useGetProductTypeOptionsQuery } from "generated/graphql"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "Redux/hooks"
import { selectPricingProductType, setPricingProductTypeId } from "Redux/productPricingReducer"


interface PTSelectorProps {
    pid?: number,
    setPid?: (newPid: number, name?: string) => void,
    disabled?: boolean,
    className?: string,
    label?: string
}

export default function ProductTypeSelector({ pid, setPid, disabled, className, label }: PTSelectorProps) {
    const { data: productOptionsData, loading } = useGetProductTypeOptionsQuery()
    const typeOps = productOptionsData?.options ?? []

    const appPid = useAppSelector(selectPricingProductType)
    const productTypeId = pid ?? appPid
    const dispatch = useAppDispatch()

    const setValue = setPid ?? ((newPid, s) => dispatch(setPricingProductTypeId(newPid)))

    return (
        <>
            <label htmlFor="prod-type-select" className={clsx("margin-side-xsm", className)}>{label ?? "Product Type:"}</label>
            <Select disabled={disabled}
                className={clsx('w-10r', className)}
                name="prod-type-select"
                value={loading ? '' : productTypeId}
                onChange={(e) => {
                    const pid = e.target.value as number
                    setValue(e.target.value as number, typeOps.filter(op => op.id === pid)[0].type)
                }}
            >
                {typeOps.length === 0 && <MenuItem value={1}>Loading...</MenuItem>}
                {typeOps.map(op => <MenuItem key={`product-type-op-${op.id}`} value={op.id}>{op.type}</MenuItem>)}
            </Select>
        </>
    )
}

export function ProductTypeSelectButton({ pid, setPid, disabled, className }: PTSelectorProps) {
    const { data: productOptionsData } = useGetProductTypeOptionsQuery()
    const typeOps = productOptionsData?.options ?? []

    const productTypeId = pid ?? -1
    const setValue = setPid ?? ((newPid) => { })

    const displayValue = typeOps.find(op => op.id === productTypeId)?.type ?? "Select Product"

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleItemClick = (id: number) => {
        setValue(id);
        handleClose();
    }

    return (
        <>
            <SpacedButton onClick={handleClick} variant="contained" color="secondary" className={className}>{displayValue}</SpacedButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    typeOps.map(op => {
                        return <MenuItem key={`product-button-menu-${op.id}`} onClick={() => handleItemClick(op.id)}>{op.type}</MenuItem>
                    })
                }
            </Menu>
        </>
    )
}