import { MenuItem, Select } from "@material-ui/core";
import { getUnique, numericArraysEq } from "Globals/Helpers";
import {
    COVE_BASE_MOLDING_MATERIAL_CATEGORY_ID,
    PRIMED_BASE_MOLDING_MATERIAL_CATEGORY_ID,
    PRIMED_SHOE_MOLDING_MATERIAL_CATEGORY_ID,
    UNFINISHED_BASE_MOLDING_MATERIAL_CATEGORY_ID,
    UNFINISHED_SHOE_MOLDING_MATERIAL_CATEGORY_ID
} from "Globals/globalConstants";
import { RoomIDAndServicesForRoom } from "../EditInstallationServicesMenu";
import { allRoomsHaveSameServices } from "./NewWallFinishUtils";

export interface FinishOption {
    index: number;
    description: string;
    materialCategoryIds: number[];
}

export const finishOptions: FinishOption[] = [
    {index: 0, description: "None", materialCategoryIds: []},
    {index: 1, description: "Varies By Room", materialCategoryIds: []},
    {index: 2, description: "Prime Shoe Molding", materialCategoryIds: [PRIMED_SHOE_MOLDING_MATERIAL_CATEGORY_ID]},
    {index: 3, description: "Prime Baseboard", materialCategoryIds: [PRIMED_BASE_MOLDING_MATERIAL_CATEGORY_ID]},
    {index: 4, description: "Prime Baseboard + Prime Shoe", materialCategoryIds: [PRIMED_SHOE_MOLDING_MATERIAL_CATEGORY_ID, PRIMED_BASE_MOLDING_MATERIAL_CATEGORY_ID]},
    {index: 5, description: "Unfinished Shoe", materialCategoryIds: [UNFINISHED_SHOE_MOLDING_MATERIAL_CATEGORY_ID]},
    {index: 6, description: "Unfinished Baseboard + Unfinished Shoe", materialCategoryIds: [UNFINISHED_BASE_MOLDING_MATERIAL_CATEGORY_ID, UNFINISHED_SHOE_MOLDING_MATERIAL_CATEGORY_ID]},
    {index: 7, description: "Cove Base", materialCategoryIds: [COVE_BASE_MOLDING_MATERIAL_CATEGORY_ID]}
];

interface NewWallFinishOptionSelectProps {
    keyPrefix: string;
    isForEntireArea: boolean;
    setMaterialCategories: (categoryIds: number[]) => void;
    services: RoomIDAndServicesForRoom[] | undefined;  // used to determine selected option index; empty list is "Varies by Room", undefined is "None"
    onSelectNone?: () => void;
}

export function NewWallFinishOptionSelect({
    keyPrefix,
    isForEntireArea,
    setMaterialCategories,
    services,
    onSelectNone
}: NewWallFinishOptionSelectProps) {
    function onNewSelection(optionIdx: number) {
        if (optionIdx === 0) {
            // this option shouldn't even be displayed when onSelectNone is undefined
            if (onSelectNone) {
                // remove the service
                onSelectNone();
            } else {
                throw new Error('Option "None" was selected, but the function required to select none was not passed to the component')
            }
        } else {
            // only option with id 0 has an undefined list of material category ids
            const newSelection = finishOptions.find(fo => fo.index === optionIdx)!;
            setMaterialCategories(newSelection.materialCategoryIds!);
        }
    }

    const selectedIdx = determineSelectedIdx(services, isForEntireArea);

    return (
        <Select
            className="w-15r"
            value={selectedIdx}
            onChange={e => onNewSelection(e.target.value as number)}
            disableUnderline
        >
            {onSelectNone && (
                <MenuItem
                    key={`${keyPrefix}-remove`}
                    value={0}
                >
                    {finishOptions[0].description}
                </MenuItem>
            )}

            {/* show this only when the area has different services by room */}
            {(selectedIdx === 1) && (
                <MenuItem
                    key={`${keyPrefix}-1`}
                    value={1}
                    disabled
                >
                    {finishOptions[1].description}
                </MenuItem>
            )}

            {/* slice(2) because the first two options should be displayed conditionally (see above) */}
            {(finishOptions.slice(2)).map((mo, idx) => (
                <MenuItem
                    // idx + 2 because indices 0 and 1 are conditionally displayed options option
                    key={`${keyPrefix}-${idx + 2}`}
                    value={idx + 2}
                >
                    {mo.description}
                </MenuItem>
            ))}
        </Select>
    )
}

function determineSelectedIdx(services: RoomIDAndServicesForRoom[] | undefined, isForEntireArea: boolean) {
    if (!services || (!isForEntireArea && services.length === 0)) {
        return 0; // "None"
    }
    
    // there may be different types for each room if for entire area
    if (isForEntireArea && !allRoomsHaveSameServices(services)) {
        return 1;  // "Varies By Room"
    }  // else all rooms have same services
    
    const presentMaterialIds = getUnique(
        services.map(s => s.materialCategoryId) as number[]
    );

    // slice because the first 2 options are checked for manually
    return finishOptions.slice(2).find(fo =>
        // only index 0 has an undefined value for materialCategoryIds 
        numericArraysEq(fo.materialCategoryIds!, presentMaterialIds)
    )!.index;
}