import clsx from "clsx";
import {
    InstallationAppointment,
    useUpdateInstallationAppointmentMutation,
    useGetInstallationAppointmentsAfterDateQuery,
    useToggleInstallationAppointmentReleaseMutation,
    useGetAllContractorInstallationCapabilitiesAndDaysOffQuery,
} from "generated/graphql";
import { dateToMdy, dateToYmd, dayOfWeekNumberToString, ymdDashedToDate } from "Globals/DateAndTimeHelpers";
import { UNASSIGNED_CONTRACTOR_ID } from "Globals/globalConstants";
import { buildAppendedId } from "Globals/Hooks";
import { breakdownPath, workOrdersPath } from "Globals/PathStrings";
import { useAppHistory } from "Globals/routingHooks";
import { useMemo } from "react";
import { Item, ItemParams, Menu, PredicateParams, Submenu } from "react-contexify";
import { useAppDispatch } from "Redux/hooks";
import { deselectAll } from "Redux/weeklyIstallationCalendarReducer";
import { ContractorWeeklyInstallationCalendar } from "./ContractorWeeklyInstallationCalendar";
import "./installation-calendar.css";
import MovementResolutionDialog from "./MovementResolutionDialog";

interface WeeklyInstallationCalendarProps {
    startDate?: string;
    totalDaysFromStart?: number;
}

const POLL_INTERVAL = 2000;

export default function WeeklyInstallationCalendar({ startDate, totalDaysFromStart }: WeeklyInstallationCalendarProps) {
    const actualStartDate = useMemo(() => startDate ?? dateToYmd(new Date()), [startDate]);
    const daysToShow = totalDaysFromStart ?? 21;

    // TODO: Add market id to these queries
    const { data: appointmentData, startPolling, stopPolling } = useGetInstallationAppointmentsAfterDateQuery({
        variables: { afterDate: actualStartDate },
        pollInterval: POLL_INTERVAL
    });
    const { data: contractorsData } = useGetAllContractorInstallationCapabilitiesAndDaysOffQuery({pollInterval: POLL_INTERVAL});

    const appointmentsByContractor = useMemo(() => {
        const appointments = appointmentData?.installationAppointmentsAfterDate ?? [];

        const uniqueContractorIds = [...new Set(appointments.map((app) => app.contractorId))];

        return uniqueContractorIds.reduce<InstallationAppointmentsByContractor>(
            (total, contractorId) => {
                total[contractorId] = appointments.filter(
                    (app) => app.contractorId === contractorId
                );
                return total;
            },
            {}
        );
    }, [appointmentData]);

    const contractors = useMemo(
        () => contractorsData?.allContractorInstallationCapabilitiesAndDaysOff.filter(c => c.contractor.isActive) ?? [],
        [contractorsData]
    );

    const {days, dayOfWeek} = useMemo(() => {
        const firstDay = ymdDashedToDate(actualStartDate);

        const grouped = [...Array(daysToShow)].map((v) => {
            const dayString = dateToMdy(firstDay);

            const dow = dayOfWeekNumberToString(firstDay.getDay())?.substring(0, 3)

            firstDay.setDate(firstDay.getDate() + 1);
            return { str: dayString.split("/").slice(0, 2).join("/"), dow: dow };
        });

        return {days: grouped.map(grp=>grp.str), dayOfWeek: grouped.map(grp=>grp.dow)}

    }, [actualStartDate, daysToShow]);

    return (
        <div
            className="fill-width weekly-install-calendar"
            style={{
                gridTemplateColumns: `repeat(${daysToShow + 1}, var(--weekly-column-width))`,
                maxHeight: "100%",
            }}
        >
            <div className="weekly-install-header flat-dark-bkg" />
            {days.map((day, index) => (
                <div
                    key={day}
                    className={clsx("weekly-install-header flat-dark-bkg")}
                >
                    <div>{dayOfWeek[index]} {day}</div>
                </div>
            ))}
            {contractors.map((contractor) => (
                <ContractorWeeklyInstallationCalendar
                    key={contractor.id}
                    contractor={contractor}
                    appointments={appointmentsByContractor[contractor.id] ?? []}
                    startDate={actualStartDate}
                    daysToShow={daysToShow}
                    days={days}
                    stopPolling={stopPolling}
                    startPolling={() => startPolling(POLL_INTERVAL)}
                />
            ))}
            <WeeklyInstallationContextMenu />
            <MovementResolutionDialog />
        </div>
    );
}

export type InstallationAppointmentsByContractor = {
    [contractorId: number]: InstallationAppointment[];
};

export const WEEKLY_INSTALLATION_SERVICE_MENU = "WEEKLY_INSTALLATION_SERVICE_MENU";
export const WEEKLY_INSTALLATION_APPT_MENU = "WEEKLY_INSTALLATION_APPT_MENU";

function WeeklyInstallationContextMenu() {
    return (
        <>
            <InstallationAppointmentWeeklyContextMenu />
        </>
    );
}

export interface InstallationRightClickProps {
    installationAppointmentId: number;
    contractId: number;
    isReleasedToContractor: boolean;
}

export interface ServiceRightClickProps {
    serviceId: number;
}

function InstallationAppointmentWeeklyContextMenu() {
    const history = useAppHistory();
    const dispatch = useAppDispatch();

    const [updateAppointment] = useUpdateInstallationAppointmentMutation();
    const [updateReleaseAppointment] = useToggleInstallationAppointmentReleaseMutation();

    function onUnassignClick({ props }: ItemParams<InstallationRightClickProps, any>) {
        const appointmentId = (props as InstallationRightClickProps).installationAppointmentId;

        updateAppointment({
            variables: {
                appointmentId,
                newContractorId: UNASSIGNED_CONTRACTOR_ID,
            },
        });

        dispatch(deselectAll());
    }

    function onEditClick({ props }: ItemParams<InstallationRightClickProps, any>) {
        const contractId = (props as InstallationRightClickProps).contractId;
        const path = breakdownPath + buildAppendedId(contractId);
        history.push(path);
        dispatch(deselectAll());
    }

    function onViewDetailsClick({ props }: ItemParams<InstallationRightClickProps, any>) {
        const contractId = (props as InstallationRightClickProps).contractId;
        const path = workOrdersPath + buildAppendedId(contractId);
        history.push(path);
        dispatch(deselectAll());
    }

    function setAppointmentLength(appointmentId: number, appointmentLength: number) {
        updateAppointment({ variables: { appointmentId, appointmentLength } });
        dispatch(deselectAll());
    }

    function toggleRelease(props: InstallationRightClickProps | undefined, setReleased: boolean) {
        if(props !== undefined) {
            const appointmentId = (props as InstallationRightClickProps).installationAppointmentId;
       
            updateReleaseAppointment({
                variables: {
                    appointmentId: appointmentId,
                    releasedToContractor: setReleased
                }
            })
    
            dispatch(deselectAll());
        }
    }

    function isReleaseHidden({ props }: PredicateParams<InstallationRightClickProps, any>) {
        const isReleasedToContractor = (props as InstallationRightClickProps).isReleasedToContractor;
        return isReleasedToContractor !== false
    }

    function onReleaseClick({ props }: ItemParams<InstallationRightClickProps, any>) {
        toggleRelease(props, true);
    }

    function isUnreleaseHidden({ props }: PredicateParams<InstallationRightClickProps, any>) {
        const isReleasedToContractor = (props as InstallationRightClickProps).isReleasedToContractor;
        return isReleasedToContractor !== true
    }

    function onUnreleaseClick({ props }: ItemParams<InstallationRightClickProps, any>) {
        toggleRelease(props, false);
    }

    return (
        <Menu
            id={WEEKLY_INSTALLATION_APPT_MENU}
            style={{ minWidth: "unset", zIndex: 2000 }}
        >
            <Item
                id="unassign"
                onClick={onUnassignClick}
            >
                Unassign
            </Item>
            <Item
                id="edit"
                onClick={onEditClick}
            >
                Edit
            </Item>
            <Item
                id="view-details"
                onClick={onViewDetailsClick}
            >
                View Details
            </Item>
            <Item
                id="release-contractor"
                onClick={onReleaseClick}
                hidden={isReleaseHidden}
            >
                Release
            </Item>
            <Item
                id="unrelease-contractor"
                onClick={onUnreleaseClick}
                hidden={isUnreleaseHidden}
            >
                Unrelease
            </Item>
            <Submenu
                id="override-duration"
                label="Override Duration"
                style={{ minWidth: "unset" }}
            >
                <Item
                    id="1-day"
                    onClick={({ props }) =>
                        setAppointmentLength(
                            (props as InstallationRightClickProps).installationAppointmentId,
                            1
                        )
                    }
                >
                    1 Day
                </Item>
                {[...new Array(5)].map((v, index) => (
                    <Item
                        id={`${index + 2}-days`}
                        key={index}
                        onClick={({ props }) =>
                            setAppointmentLength(
                                (props as InstallationRightClickProps).installationAppointmentId,
                                index + 2
                            )
                        }
                    >
                        {index + 2} Days
                    </Item>
                ))}
            </Submenu>
        </Menu>
    );
}
