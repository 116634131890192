import React from 'react';
import { useHistory } from 'react-router-dom';
import { signOut } from 'Globals/FirebaseUtils';
import { AppBar, Box, Button, Toolbar, IconButton, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import NavbarRoutes, { RouteNamePair } from "./NavbarRoutes";
import logo from "Assets/Images/WorldOfFloorsLogoSmall.png"
import DebugMenu from 'Components/DebugMenu';

const useStyles = makeStyles(
    theme => ({
        appbar: {
            display: "flex",
            overflow: "hidden"
        },
        navbar: {
            zIndex: "inherit",
            backgroundColor: "#ffffff",
            flexWrap: 'wrap',
        },
        menuButton: {
            marginLeft: theme.spacing(2),
        },
        navDivider: {
            height: ".25em"
        },
        navButtons: {
            margin: "0 1em"
        },
        navButton: {
            margin: "0 .5em"
        },
        logo: {
            backgroundImage: `url(${logo})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: "44px",

            '&:hover': {
                color: 'yellow'
            },
            flexGrow: 1,
        },
        link: {
            margin: theme.spacing(1, 1.5),
        },
    })
)


export default function MobileNavbar({ customRoutes }: { customRoutes?: RouteNamePair[] }) {
    const classes = useStyles();
    const history = useHistory();

    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const clickButton = (route: string) => {
        handleMenuClose();
        history.push(route);
    }

    function NavbarRouteToButton(routeNamePair: RouteNamePair) {
        return <MenuItem key={`Navbar-mobile-${routeNamePair.name}`} onClick={() => clickButton(routeNamePair.route)}>{routeNamePair.name}</MenuItem>
    }

    const [debugAnchorEl, setDebugAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleDebugMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDebugAnchorEl(event.currentTarget);
    };

    const handleDebugMenuClose = () => {
        setDebugAnchorEl(null);
    };

    var routes = customRoutes ?? NavbarRoutes()

    return (
        <AppBar className={classes.appbar} position="sticky" elevation={0} >
            <Toolbar className={classes.navbar}>
                <DebugMenu anchorEl={debugAnchorEl} handleClose={handleDebugMenuClose} />
                <Button className={classes.logo} onClick={handleDebugMenuClick} />
                <IconButton edge="end" className={classes.menuButton} onClick={handleMenuClick} color="primary" aria-label="menu" >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuClose}
                >
                    {
                        routes.map(NavbarRouteToButton)
                    }
                    <MenuItem key={`navbar-logout`} onClick={() => signOut()}>Logout</MenuItem>
                </Menu>
            </Toolbar>
            <Box color="primary" className={classes.navDivider} />
        </AppBar>
    )
}

