/**
 * The intention of this file is to provide "empty" or "new" versions of objects.
 * These are useful, for example, when creating new objects for things like inputs.
 */

import { Address, BackgroundCheck, Company, ContractorHelper, ContractorProfile, CustomerContact, CustomerInput, CustomService, LiabilityInsurance, SalespersonProfile, Vehicle, W9, WorkerHistoryDates, WorkersComp } from "generated/graphql";
import { addDaysToDate, todayAsIso } from "Globals/DateAndTimeHelpers";
import { isEmptyString, isNullOrUndefined } from "Globals/GenericValidators";
import { EditableCustomService } from "Pages/Admin/CommandCenter/PricingCenter/Chargeables/CustomServiceEditorRow";

export const emptyAddress: Address = {
    id: -1,
    streetAddress: "",
    city: "",
    zip: "",
}

export function isEmptyAddress(address: Address) {
    return (isEmptyString(address.streetAddress))
        && (isEmptyString(address.city))
        && (isEmptyString(address.zip));
}

export const emptyContact: CustomerContact = {
    id: -1,
    customerId: -1,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    receivePromoEmails: true,
    isPrimary: false,
    isSchedulingContact: false
}

export function isEmptyContact(contact: CustomerContact) {
    return (isEmptyString(contact.firstName))
        && (isEmptyString(contact.lastName ?? ""))
        && (isEmptyString(contact.phone ?? ""))
        && (isEmptyString(contact.email ?? ""))
}

export const emptyCustomer: CustomerInput = {
    id: -1,
    firstName: "",
    lastName: "",
    primaryPhone: "",
    primaryAddress: emptyAddress,
    email: "",
    receivePromoEmails: true,
    customerContacts: []
}

export const emptyCompany: Company = {
    id: -1,
    name: "",
    address: {...emptyAddress},
    eIN: ""
}

export function isEmptyCompany(company: Company) {
    return (isEmptyString(company.name))
        && (isEmptyAddress(company.address))
        && (isEmptyString(company.eIN))
}

export const emptyVehicle: Vehicle = {
    id: -1,
    description: "",
    year: undefined,
    licensePlateNumber: undefined,
    condition: undefined
}

export function isEmptyVehicle(vehicle: Vehicle) {
        return (isEmptyString(vehicle.description))
        && (isEmptyString(vehicle.licensePlateNumber ?? ""))
        && (isEmptyString(vehicle.condition ?? ""))
        && (isNullOrUndefined(vehicle.year));
}


export const emptyWorkerHistoryDates: WorkerHistoryDates = {
    id: 1,
    startDate: "",
    endDate: undefined
}

export const emptyContractorHelper: ContractorHelper = {
    id: -1,  // the worker ID
    firstName: "",
    lastName: "",
    phone: "",
    licenseNumber: undefined,
    contractorId: -1,
    history: [],
    isActive: true,
    backgroundChecks: []
}

/**
 * Allows an empty contractor helper whose history defaults to starting today
 */
export function makeEmptyContractorHelperStartingToday() {
    return {
        ...emptyContractorHelper,
        history: [{
            ...emptyWorkerHistoryDates,
            startDate: todayAsIso()
        }]
    }
}

export function makeEmptyBgCheck(workerId: number): BackgroundCheck {
    return {
        id: -1,
        workerId: workerId,
        isInternal: false,
        number: "",
        datePerformed: todayAsIso(),
        expirationDate: addDaysToDate(todayAsIso(), 365)
    }
}

export function makeEmptyW9(workerId: number): W9 {
    return {
        id: -1,
        workerId: workerId,
        activeDate: todayAsIso(),
        expirationDate: addDaysToDate(todayAsIso(), 365),
        s3FileId: undefined
    }
}

export function makeEmptyWorkersComp(workerId: number): WorkersComp {
    return {
        id: -1,
        workerId: workerId,
        activeDate: todayAsIso(),
        expirationDate: addDaysToDate(todayAsIso(), 365),
        s3FileId: undefined
    }
}

export function makeEmptyLiabilityInsurance(workerId: number): LiabilityInsurance {
    return {
        id: -1,
        workerId: workerId,
        activeDate: todayAsIso(),
        expirationDate: addDaysToDate(todayAsIso(), 365),
        s3FileId: undefined
    }
}

export const emptyContractorProfile: ContractorProfile = {
    id: -1,
    workerId: -1,
    isActive: true,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    servicedZips: [],
    address: {...emptyAddress},
    driversLicenseNumber: "",
    licenseNumber: "",
    vehicle: {...emptyVehicle},
    company: {...emptyCompany},
    helpers: [],
    backgroundChecks: [],
    capabilities: [],
    w9s: [],
    workersComps: [],
    liabilityInsurances: [],
    rating: -1
}

export const emptySalespersonProfile: SalespersonProfile = {
    id: -1,
    workerId: -1,
    isActive: true,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    servicedZips: [],
    address: {...emptyAddress},
    driversLicenseNumber: "",
    vehicle: {...emptyVehicle},
    company: {...emptyCompany},
    backgroundChecks: [],
    w9s: [],
    workersComps: [],
    liabilityInsurances: []
}

export const emptyCustomService: CustomService = {
    id: -1,
    areaId: -1,
    roomIds: [],
    description: "",
    price: -1,
    contractorPercentage: 0.5
}

export const emptyEditableCustomService: EditableCustomService = {
    id: -1,
    areaId: -1,
    roomIds: [],
    description: "",
    price: 0,
    displayPrice: "0",
    contractorPercentage: 0.5,
    displayContractorPercentage: "50",
    displayContractorPay: "0",
    isDeleted: false
}
