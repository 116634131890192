import 'App.css';
import clsx from "clsx";
import { PropsWithChildren } from "react";
import Navbar from "./Navbar/Navbar";
import { RouteNamePair } from './Navbar/NavbarRoutes';


interface PageProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "title" | "style"> {
    title?: string | JSX.Element,
    padContent?: boolean,
    centerHorizontally?: boolean,
    hideNavbar?: boolean,
    customRoutes?: RouteNamePair[],
    style?: React.CSSProperties
}

export type NavbarPageProps = PropsWithChildren<PageProps>

export default function NavbarPage({ children, title, padContent, centerHorizontally, hideNavbar, customRoutes, style, className, ...additionalProps }: NavbarPageProps) {

    if (padContent) {
        style = { ...style, padding: '0.5rem' };
    }
    if (centerHorizontally) {
        const center = { alignItems: "center" };
        style = { ...style, ...center };
    }

    return (
        <>
            <div className='flex-column fill-viewport' style={{ overflowX: "hidden" }}>
                {(hideNavbar) ? <></> : <Navbar customRoutes={customRoutes} />}
                {title && (
                    <div className="fill-width flex-row" style={{ justifyContent: "center" }}>
                        <h2 style={{ padding: ".5rem 0", ...style }}>{title}</h2>
                    </div>
                )}
                <div className={clsx('flex-grow', 'flex-column', 'fill-width', className)} style={style} {...additionalProps}>
                    {children}
                </div>
            </div>
        </>
    )
}