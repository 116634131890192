import React, { useState } from 'react';
import SearchBar from 'material-ui-search-bar';
import {InventoryEntry, MeasurementUnit, ProductTypeOld} from '../generated/graphql';
import ReactDataGrid, { Column, FormatterProps, SortColumn } from 'react-data-grid';
import { FormControlLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import { humanize } from "@alduino/humanizer/string";
import Loading from './Loading';
import { DoubleClickHandler, useRowRendererWithMenu } from '../Globals/Helpers';
import { RightClickMenu } from './ContextMenu';

const generatedInventoryFeilds = ['damagedCount'] as const;
export type InventoryViewCollumns = keyof Row | GeneratedInventoryFeilds;
type GeneratedInventoryFeilds = typeof generatedInventoryFeilds[number];

export interface ColumnEntry extends Column<Row> {
  key: InventoryViewCollumns
  name: ColumnDefinitionName
}

type Row = {
  id: string,
  key: string,
} & InventoryEntry

export type InventoryViewRow = Row;

const orderFormatter = (props: React.PropsWithChildren<FormatterProps<Row, unknown>>, orderKey: keyof Row, stockKey: keyof Row) => {
  const row = props.row;
  const text = (`${row[orderKey]} ${row.orderType} (${row[stockKey]} ${row.stockUnit})`);
  return (
    <Tooltip title={(text + ` ${row.stockUnitPerOrderUnit} ${row.stockUnit} per ${row.orderType}`)}>
      <div>
        {text}
      </div>
    </Tooltip>
  );
}

function convertToFtIn(value: number) {
  let wholePart = Math.floor(value);
  let decimalPart = value - wholePart;
  decimalPart = Math.floor(decimalPart * 4) / 4;
  let inches = decimalPart * 4 * 3

  return `${wholePart}' ${inches}"`;
}

const unitFormatter = function (props: React.PropsWithChildren<FormatterProps<Row, unknown>>, key: keyof Row, displayUnit: DisplayUnit) {
  let row = props.row;

  let text = ``
  let value = row[key] as number;
  let conversionFactor = row.measureUnitPerInventoryUnit;
  if (row.productType === ProductTypeOld.Carpet) {
    conversionFactor = 1;
  }

  let boxesText = '';
  let feetText = '';

  switch (row.productType) {
    case ProductTypeOld.Carpet:
      boxesText = convertToFtIn(value);
      break;
    case ProductTypeOld.Lvp:
      boxesText = `${value.toFixed(0)} ${row.stockUnit}`
      break;
    case ProductTypeOld.LvpTm:
      boxesText = `${value.toFixed(0)} ${row.stockUnit}`
      break;
  }

  switch (row.measurementUnit) {
    case MeasurementUnit.Ft:
      feetText = `${(value * conversionFactor).toFixed(2)} ft`
      break;
    case MeasurementUnit.FtInch:
      feetText = convertToFtIn((value * conversionFactor));
      break;
    case MeasurementUnit.Sqft:
      feetText = `${(value * conversionFactor).toFixed(2)} sqft`
      break;
    case MeasurementUnit.Inch:
      feetText = 'InventoryViewer.tsx check logic';
      break;
  }

  switch (displayUnit) {
    case 'boxes':
      text = boxesText;
      break;
    case 'feet':
      text = feetText;
      break;
  }

  let color = 'black';
  if (value < 0) {
    color = 'red';
  }

  return (
    <Tooltip style={{ color: color }} title={`${boxesText} ${feetText}`}>
      <div>
        {text}
      </div>
    </Tooltip>
  );
}

// TODO: When ordering, they want to see actual, potential and to order. They don't need to see other fields.

const columnDefinitionName = ['SKU', 'Type', 'Style', 'Color', 'Actual', 'Available', 'Held', 'Damaged', 'Minimum Stock', 'To Order', 'On Order', 'Potential'] as const
type ColumnDefinitionName = typeof columnDefinitionName[number]

// function formatterRedWhenNegative(fieldName: keyof Row) {
//   return (props: FormatterProps<Row, unknown>) => {
//     let value = props.row[fieldName];
//     let color = 'black';
//     if (typeof value === 'number') {
//       if (value < 0) {
//         color = 'red';
//       }
//       return (<div style={{ color: color }}>{value.toString()}</div>)
//     }
//     else {
//       throw 'Must be used with number keys only.';
//     }
//   }
// }

function renderCellWithTooltip(key: keyof Row) {
  return (props: FormatterProps<Row, unknown>) =>
  (
    <Tooltip title={props.row[key]!.toString()}>
      <div data-tip={props.row[key]}>{props.row[key]}</div>
    </Tooltip>
  )
}

// function mapEntriesResultToGridRow(entries: InventoryEntry[]): Row[] {
//   return entries.map((entry) => {
//     return {
//       id: entry.sku!,
//       key: entry.sku!,
//       ...entry
//     }
//   });
// }

const displayUnit = ['boxes', 'feet'] as const
type DisplayUnit = typeof displayUnit[number];

interface Props {
  onSelectRow?: DoubleClickHandler<Row>
  onHoveredElementChanged?: (row: Row | undefined) => void,
  rightClickMenu?: RightClickMenu
  columns?: ColumnDefinitionName[]
}

export function InventoryViewer({ onSelectRow, onHoveredElementChanged, rightClickMenu, columns }: Props) {
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  //const getDummyInventory = useAuthenticatedQuery(useGetDummyInventoryQuery);
  const [inventoryRows//, setInventoryRows
  ] = React.useState<Row[]>([]);
  const [loading//, setLoading
  ] = React.useState<boolean>(true);
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);
  const [displayUnit, setDisplayUnit] = useState<DisplayUnit>('boxes');

  // React.useEffect(() => {
  //   let active = true;

  //   (async () => {
  //     setLoading(true);
  //     //TODO: Figure out how to remove the dependency on getDummyInventory
  //     generateLoadServerRowsEffect(getDummyInventory, searchQuery, sortColumns).then((rows) => {
  //       const newRows = mapEntriesResultToGridRow(rows)

  //       if (!active) {
  //         return;
  //       }

  //       setInventoryRows(newRows);
  //       setLoading(false);
  //     })
  //   })();

  //   return () => {
  //     active = false;
  //   };
  // }, [searchQuery, sortColumns])

  const rowRenderer = useRowRendererWithMenu<Row>({ contextMenuID: rightClickMenu?.id!, doubleClickHandler: (x) => { if (onSelectRow) onSelectRow(x) }, onMouseEnter: onHoveredElementChanged })

  const displayUnitRadioButtons = (
    <RadioGroup value={displayUnit} onChange={(x) => setDisplayUnit(x.target.value as DisplayUnit)} row>
      <FormControlLabel value='boxes' control={<Radio />} label='Boxes' />
      <FormControlLabel value='feet' control={<Radio />} label='Feet' />
    </RadioGroup>);

  const columnDefinitions: ColumnEntry[] = [
    { key: 'sku', name: 'SKU', width: 120, sortable: true },
    { key: 'productType', name: 'Type', width: 125, formatter: (x) => <div>{humanize(x.row.productType)}</div>, sortable: true },
    { key: 'style', name: 'Style', formatter: renderCellWithTooltip('style'), sortable: true },
    { key: 'color', name: 'Color', formatter: renderCellWithTooltip('color'), sortable: true },
    { key: 'newCount', name: 'Actual', width: 150, sortable: true, formatter: (x) => unitFormatter(x, 'newCount', displayUnit) },
    { key: 'available', name: 'Available', width: 150, formatter: (x) => unitFormatter(x, 'available', displayUnit), sortable: true },
    { key: 'heldCount', name: 'Held', width: 150, formatter: (x) => unitFormatter(x, 'heldCount', displayUnit), sortable: true },
    { key: 'damagedCount', name: 'Damaged', width: 150, sortable: true },
    { key: 'minimumStockCount', name: 'Minimum Stock', width: 150, formatter: (x) => unitFormatter(x, 'minimumStockCount', displayUnit), sortable: true },
    {
      key: 'toOrderCountInOrderUnits', name: 'To Order', width: 200, sortable: true,
      formatter: (x) => orderFormatter(x, 'toOrderCountInOrderUnits', 'toOrderCountInStockUnits')
    },
    {
      key: 'onOrderCountInOrderUnits', name: 'On Order', width: 200, sortable: true,
      formatter: (x) => orderFormatter(x, 'onOrderCountInOrderUnits', 'onOrderCountInStockUnits')
    },
    { key: 'potential', name: 'Potential', width: 100, formatter: (x) => unitFormatter(x, 'potential', displayUnit), sortable: true },
  ];

  let myColumns = columnDefinitions;
  if (columns) {
    myColumns = columns.map(x => columnDefinitions.find(y => x === y.name)!);
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div style={{ width: "min(100vw, 600px)", margin: 10 }}>
          <SearchBar
            key='inventory-viewer-search-bar'
            style={{ boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 50%), 1px 1px 1px 0px rgb(0 0 0 / 50%), 0px 1px 3px 0px rgb(0 0 0 / 12%)" }}
            value={searchQuery}
            onChange={setSearchQuery}
            className='fill-width'
            onCancelSearch={() => setSearchQuery("")} />
        </div>
        <FormControlLabel value='displayUnit' control={displayUnitRadioButtons} label='Display Unit' labelPlacement='top' />

      </div>
      {loading && <Loading />}
      {!loading &&
        <>
          <ReactDataGrid<Row>
            sortColumns={sortColumns}
            onSortColumnsChange={setSortColumns}
            rows={inventoryRows}
            rowRenderer={rowRenderer}
            columns={myColumns}
            className='rdg-light flex-grow' />
          {rightClickMenu?.generate()}
        </>}
    </>
  )
}
