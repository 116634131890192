import { Button } from "@material-ui/core";
import RecoveryCenterCard, { BaseRecoveryCenterCardProps } from "./VisualComponents/RecoveryCenterCard";
import "./VisualComponents/recovery-center-components.css"

interface UnclaimedRecoveryCenterCardProps extends BaseRecoveryCenterCardProps {
    onClaim?: (job: BaseRecoveryCenterCardProps['job']) => void
}

function UnclaimedRecoveryCenterCard({ job, onClaim }: UnclaimedRecoveryCenterCardProps) {
    return (
        <RecoveryCenterCard job={job}>
            <div className="unclaimed-card-content">
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={onClaim === undefined}
                    onClick={() => onClaim?.(job)}
                >Claim</Button>
            </div>
        </RecoveryCenterCard>
    );
}

export default UnclaimedRecoveryCenterCard;