import { Select, MenuItem } from "@material-ui/core"
import { useAppSelector, useAppDispatch } from "Redux/hooks"
import { selectPricingViewMode, setPricingViewMode } from "Redux/productPricingReducer"


export default function ViewModeSelector() {

    const viewMode = useAppSelector(selectPricingViewMode)
    const dispatch = useAppDispatch()

    return (
        <>
            <label htmlFor="view-mode-select" className="margin-side-xsm">View Mode:</label>
            <Select
                name="view-mode-select"
                value={viewMode}
                onChange={(e) => dispatch(setPricingViewMode(e.target.value as number))}
                className="w-10r">
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Promo</MenuItem>
                <MenuItem value={2}>Pricing</MenuItem>
            </Select>
        </>
    )
}