import { DayRange } from "@hassanmojab/react-modern-calendar-datepicker";
import { IconButton, MenuItem, Select, Tooltip, Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import HistoryIcon from '@material-ui/icons/History';
import { FinancingTableRow, namedOperations, useUpsertFinancingOptionMutation } from "generated/graphql";
import { DateRangeCalendarDialog, dateTimeStrToDay, dayRangesEq, dayToIso, dayToMdy } from "Globals/DateAndTimeHelpers";
import { isEmptyString, isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useState } from "react";
import NumberFormat from "react-number-format";
import FinancingOptionHistoryViewer from "./FinancingOptionHistoryViewer";
import { FinancingTypeMap } from "./FinancingPage";
import NewFinancingOptionDialog from "./NewFinancingOptionDialog";

interface FinancingRowProps {
    row: FinancingTableRow;
    typeMap: FinancingTypeMap;
    existingTranCodes: string[];
}

export default function FinancingRow({row, typeMap, existingTranCodes}: FinancingRowProps) {
    // note: using strings for the nullable fields because https://github.com/s-yadav/react-number-format/issues/528#issuecomment-825787850

    // #region Base Option
    const [showingBaseHistory, setShowingBaseHistory] = useState(false);

    const baseOption = row.baseOption;
    const baseTerm = baseOption.term; // can't be changed; must be same across base/history, and active/historical promos
    const typeId = baseOption.typeId;  // can't be changed; must be same across base/history, and active/historical promos
    const baseTranCode = baseOption.tranCode; // can't be changed
    const baseHasHistory = row.baseHistory.length > 0;

    const originalBaseMinAmt = baseOption.minAmount.toFixed(0) ?? '';
    const [baseMinAmt, setBaseMinAmt] = useState(originalBaseMinAmt);
    function onResetBaseMinAmt() { setBaseMinAmt(originalBaseMinAmt); }
    const baseMinAmtChanged = baseMinAmt !== originalBaseMinAmt;

    const originalBaseFeePercent = baseOption.fee.toFixed(2) ?? '';
    const [baseFee, setBaseFee] = useState(originalBaseFeePercent);
    function onResetBaseFee() { setBaseFee(originalBaseFeePercent); }
    const baseFeeChanged = baseFee !== originalBaseFeePercent;
    
    const originalBaseSlot = baseOption.slot;
    const [baseSlot, setBaseSlot] = useState(originalBaseSlot);
    function onResetBaseSlot() { setBaseSlot(originalBaseSlot); }
    const baseSlotChanged = baseSlot !== originalBaseSlot;
    
    const baseChangesPresent = baseMinAmtChanged || baseFeeChanged || baseSlotChanged;
    // #endregion

    // #region Promos
    const [newPromoDialogOpen, setNewOptionDialogOpen] = useState(false);
    const [showingPromoHistory, setShowingPromoHistory] = useState(false);

    const promo = row.activePromo;
    const hasActivePromo = isNotNullOrUndefined(promo);
    const promoTranCode = promo?.tranCode ?? ''; // can't be changed
    const promoHasHistory = row.promoHistory.length > 0;

    const originalPromoMinAmt = promo?.minAmount.toFixed(0) ?? '';
    const [promoMinAmt, setPromoMinAmt] = useState(originalPromoMinAmt);
    function onResetPromoMinAmt() { setPromoMinAmt(originalPromoMinAmt); }
    const promoMinAmtChanged = (promoMinAmt !== originalPromoMinAmt);

    const originalPromoFee = promo?.fee.toFixed(2) ?? '';
    const [promoFee, setPromoFee] = useState(originalPromoFee);
    function onResetPromoFee() { setPromoFee(originalPromoFee); }
    const promoFeeChanged = promoFee !== originalPromoFee;
    
    const originalStartDate = promo?.startDate ? dateTimeStrToDay(promo.startDate) : null;
    const originalEndDate = promo?.endDate ? dateTimeStrToDay(promo.endDate) : null;
    const originalPromoDates: DayRange = {from: originalStartDate, to: originalEndDate ?? originalStartDate};
    const [promoDates, setPromoDates] = useState(originalPromoDates);
    const promoDatesChanged = !dayRangesEq(originalPromoDates, promoDates);

    const originalPromoSlot = promo?.slot ?? -1;
    const [promoSlot, setPromoSlot] = useState(originalPromoSlot);
    function onResetPromoSlot() { setPromoSlot(originalPromoSlot); }
    const promoSlotChanged = promoSlot !== originalPromoSlot;
    
    const promoChangesPresent = /*promoTermChanged ||*/ promoMinAmtChanged || promoFeeChanged || promoDatesChanged || promoSlotChanged;
    
    const [upsertOption] = useUpsertFinancingOptionMutation({
        refetchQueries: [namedOperations.Query.GetFinancingTableData]
    });

    function canUpdateBase() {
        if (isEmptyString(baseMinAmt)) {
            alert("Enter a minimum amount");
            return false;
        }

        if (isEmptyString(baseFee)) {
            alert("Enter a fee amount");
            return false;
        }

        return true;
    }

    function canUpdatePromo() {
        if (isNullOrUndefined(promoDates.from) || isNullOrUndefined(promoDates.to)) {
            alert("Select dates for the promotion");
            return false;   
        }

        if (isEmptyString(promoMinAmt)) {
            alert("Enter a minimum amount");
            return false;
        }

        if (isEmptyString(promoFee)) {
            alert("Enter a fee amount");
            return false;
        }

        return true;
    }

    function updateOption(forPromo: boolean) {
        if (forPromo && canUpdatePromo()) {
            const {__typename, ...promoAsInput} = promo!;
            upsertOption({
                variables: {
                    option: {...promoAsInput!,
                        minAmount: +promoMinAmt,
                        fee: +promoFee,
                        slot: promoSlot,
                        startDate: dayToIso(promoDates.from!),
                        endDate: dayToIso(promoDates.to!)
                    }
                },
                onError: (err) => {console.log(err); alert("Could not update promo option")}
            });
        } else {
            if (canUpdateBase()) {
                const {__typename,...baseAsInput} = row.baseOption;
                upsertOption({
                    variables: {
                        option: {...baseAsInput, minAmount: +baseMinAmt, fee: +baseFee, slot: baseSlot}
                    },
                    onError: (err) => {console.log(err); alert("Could not update financing option")}
                });
            }
        }
    }

    return (
        <>
            <tr>
                <td>
                    <div className="flex-row">
                        <Tooltip title="Submit Changes">
                            <IconButton onClick={() => updateOption(false)} style={{visibility: baseChangesPresent ? "visible" : "hidden" }}>
                                <CheckIcon />
                            </IconButton>
                        </Tooltip>
        
                        <Tooltip title="Show History">
                            <IconButton onClick={() => setShowingBaseHistory(true)} style={{visibility: baseHasHistory ? "visible" : "hidden" }}>
                                <HistoryIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </td>

                <td align="left" className="padding-right-xmd">
                    <Typography>{baseTerm}</Typography>
                </td>

                <td align="left">
                    <div className="flex-row">
                        <NumberFormat
                            value={baseMinAmt} onValueChange={(v) => setBaseMinAmt(v.value)}
                            className="w-5r"
                            displayType="input" allowNegative={false}
                            prefix="$" suffix="+"
                            decimalScale={0}
                        />
                        <IconButton onClick={onResetBaseMinAmt} style={{visibility: baseMinAmtChanged ? "visible" : "hidden"}} className="padding-vertical-none">
                            <CancelIcon />
                        </IconButton>
                    </div>
                </td>

                <td align="left" className="padding-right-xmd">
                    <Typography>{typeMap[typeId]}</Typography>
                </td>

                <td align="left">
                    <div className="flex-row">
                        <PercentInput value={baseFee} setValue={setBaseFee}/>
                        <IconButton onClick={onResetBaseFee} style={{visibility: baseFeeChanged ? "visible" : "hidden"}} className="padding-vertical-none" >
                            <CancelIcon />
                        </IconButton>
                    </div>
                </td>

                <td align="left" className="padding-right-xmd">
                    <Typography>{baseTranCode}</Typography>
                </td>

                <td align="left">
                    <div className="flex-row">
                        <Select value={baseSlot} onChange={(e) => setBaseSlot(e.target.value as number)} className="w-5r">
                            <MenuItem value={0}>Unassigned</MenuItem>
                            {[1, 2, 3].map(s => <MenuItem key={`slot-${s}`} value={s}>{s}</MenuItem>)}
                        </Select>
                        
                        <IconButton onClick={onResetBaseSlot} style={{visibility: baseSlotChanged ? "visible" : "hidden"}}  className="padding-vertical-none">
                            <CancelIcon />
                        </IconButton>
                    </div>
                </td>

                <td className="solid-border-left"  style={{borderLeft: "2px solid black", paddingLeft: "0.5rem"}}>
                    <div className="flex-row">
                        {hasActivePromo ? (
                            <>
                                <Tooltip title="Submit Changes">
                                    <IconButton onClick={() => updateOption(true)} style={{visibility: promoChangesPresent ? "visible" : "hidden" }}>
                                        <CheckIcon />
                                    </IconButton>
                                </Tooltip>
                
                                <Tooltip title="Show Promo History">
                                    <IconButton onClick={() => setShowingPromoHistory(true)} style={{visibility: promoHasHistory ? "visible" : "hidden" }}>
                                        <HistoryIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : (
                            <Tooltip title="Add Promo">
                                <IconButton onClick={() => setNewOptionDialogOpen(true)}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </td>

                {hasActivePromo && (
                    <>

                        <DateRangeTd range={promoDates} setRange={setPromoDates} originalRange={originalPromoDates}/>

                        <td align="left">
                            <div className="flex-row">
                                <NumberFormat
                                    value={promoMinAmt} onValueChange={(v) => setPromoMinAmt(v.value)}
                                    className="w-5r"
                                    displayType="input" allowNegative={false}
                                    prefix="$" suffix="+"
                                    decimalScale={0}
                                />
                                <IconButton onClick={onResetPromoMinAmt} style={{visibility: promoMinAmtChanged ? "visible" : "hidden"}}  className="padding-vertical-none">
                                    <CancelIcon />
                                </IconButton>
                            </div>
                        </td>

                        <td align="left">
                            <div className="flex-row">
                                <PercentInput value={promoFee} setValue={setPromoFee} />
                                <IconButton onClick={onResetPromoFee} style={{visibility: promoFeeChanged ? "visible" : "hidden"}}  className="padding-vertical-none">
                                    <CancelIcon />
                                </IconButton>
                            </div>
                        </td>

                        <td align="left" className="padding-right-xmd">
                            <Typography>{promoTranCode}</Typography>
                        </td>

                        <td align="left">
                            <div className="flex-row">
                                <Select value={promoSlot} onChange={(e) => setPromoSlot(e.target.value as number)} className="w-5r">
                                    <MenuItem value={0}>Unassigned</MenuItem>
                                    {[1, 2, 3].map(s => <MenuItem key={`s-item-${s}`} value={s}>{s}</MenuItem>)}
                                </Select>
                                <IconButton onClick={onResetPromoSlot} style={{visibility: promoSlotChanged ? "visible" : "hidden"}}  className="padding-vertical-none">
                                    <CancelIcon />
                                </IconButton>
                            </div>
                        </td>
                    </>
                )}
            </tr>

            {newPromoDialogOpen && (
                <NewFinancingOptionDialog 
                    parentTranCode={baseTranCode}
                    setOpen={setNewOptionDialogOpen}
                    existingTranCodes={existingTranCodes}
                    // TODO: once reimplemented to use redux, don't need to recontruct the types like this
                    financingTypes={Object.keys(typeMap).map(id => ({id: +id, name: typeMap[+id]}))}
                    defaultTerm={baseTerm}
                    defaultAmount={originalBaseMinAmt}
                    defaultTypeId={typeId}
                    defaultFee={originalBaseFeePercent}
                    defaultSlot={originalBaseSlot}
                />)
            }

            {showingBaseHistory && <FinancingOptionHistoryViewer options={row.baseHistory} typeMap={typeMap} setOpen={setShowingBaseHistory} />}
            {showingPromoHistory && <FinancingOptionHistoryViewer options={row.promoHistory} typeMap={typeMap} setOpen={setShowingPromoHistory} />}
        </>
    )
}


interface PercentInputProps {
    value: string;
    setValue: (newVal: string) => void;
    className?: string;
    disabled?: boolean;
}

// TODO: replace with FlatPercentInput
export function PercentInput({value, setValue, className="w-5r", disabled=false}: PercentInputProps) {
    return (
        <NumberFormat
            disabled={disabled}
            value={value} onValueChange={(v) => setValue(v.value)}
            className={`${className}`}
            displayType="input" allowNegative={false}
            suffix="%" decimalScale={2}
        />
    )
}

interface DateRangeTdProps {
    range: DayRange;
    // setRange and OriginalRange must BOTH be passed if this should be editable
    setRange?: (newRange: DayRange) => void;
    originalRange?: DayRange;
}

export function DateRangeTd({range, setRange, originalRange}: DateRangeTdProps) {
    const [rangeDialogOpen, setRangeDialogOpen] = useState(false);
    const isEditable = isNotNullOrUndefined(setRange);
    if (!isEditable && isNotNullOrUndefined(originalRange)) {
        throw new Error("Must pass setRange prop when the originalRange is passed");
    }

    function onClickInput() {
        if (isEditable) {
            setRangeDialogOpen(true);
        }
    }

    return (
        <td align="left">
            <div className="flex-row flex-gap-xsm align-items-center">
                <input
                    className="w-6r" readOnly
                    onClick={onClickInput}
                    value={range.from ? dayToMdy(range.from) : ''}
                />

                <Typography>through</Typography>
                
                <input
                    className="w-6r" readOnly
                    onClick={onClickInput}
                    value={range.to ? dayToMdy(range.to!) : ''}
                />

                {isEditable && isNotNullOrUndefined(originalRange) && (
                    <IconButton
                        onClick={() => setRange!(originalRange!)}
                        style={{visibility: !dayRangesEq(range, originalRange) ? "visible" : "hidden"}}>
                        <CancelIcon />
                    </IconButton>
                )}
            </div>

            {rangeDialogOpen && 
                <DateRangeCalendarDialog
                    open={true} setOpen={setRangeDialogOpen}
                    selectedRange={range} setSelectedRange={setRange!}
                />
            }
        </td>

    )
}