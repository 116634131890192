import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

interface JobTrackerState { //Defined so that create slice can infer the type
    pageTitle: string,
    jobContractId: number,
    jobConfigurationId: number,
    sahNumber: string,
    withinCancellationWindow: boolean,
    contractIsCancelled: boolean,
    doesContractExist: boolean,
    contractSigningDate: Date | undefined,
    serviceOrderTypeId: number,
    serviceContractorId: number | undefined,
    serviceChargeAmount: number | undefined
}

const initialState: JobTrackerState = {
    pageTitle: "",
    jobContractId: -1,
    jobConfigurationId: -1,
    sahNumber: "",
    withinCancellationWindow: false,
    contractIsCancelled: false,
    doesContractExist: true,
    contractSigningDate: undefined,
    serviceOrderTypeId: 0,
    serviceContractorId: undefined,
    serviceChargeAmount: undefined,
}

//A slice is a collection of reducer logic and actions. It will be combined to form the store in ./store
export const jobTrackerSlice = createSlice({
    name: "jobTracker",
    initialState,
    reducers: { //DON'T Call async functions in here. No 
        setPageTitle(state: JobTrackerState, action: PayloadAction<string>) {
            state.pageTitle = action.payload
        },
        updateContract: {
            reducer(state: JobTrackerState, action: PayloadAction<{
                jobContractId: number,
                jobConfigurationId: number,
                sahNumber: string,
                withinCancellationWindow: boolean,
                contractIsCancelled: boolean,
                contractExists: boolean,
                contractSigningDate?: Date
            }>) {
                const { jobContractId, jobConfigurationId, sahNumber, withinCancellationWindow, contractIsCancelled, contractExists, contractSigningDate } = action.payload;

                state.jobContractId = jobContractId;
                state.jobConfigurationId = jobConfigurationId;
                state.sahNumber = sahNumber;
                state.withinCancellationWindow = withinCancellationWindow;
                state.contractIsCancelled = contractIsCancelled;
                state.doesContractExist = contractExists;
                state.contractSigningDate = contractSigningDate;
            },
            prepare(
                jobContractId: number,
                jobConfigurationId: number,
                sahNumber: string,
                withinCancellationWindow: boolean,
                contractIsCancelled: boolean,
                contractExists: boolean,
                contractSigningDate?: Date) {
                return {
                    payload: { jobContractId, jobConfigurationId, sahNumber, withinCancellationWindow, contractIsCancelled, contractExists, contractSigningDate }
                }
            }
        },
        setWithinCancellationWindow: (state: JobTrackerState, action: PayloadAction<boolean>) => {
            state.withinCancellationWindow = action.payload;
        },
        setContractIsCancelled: (state: JobTrackerState, action: PayloadAction<boolean>) => {
            state.contractIsCancelled = action.payload;
        },
        setServiceOrderType: (state: JobTrackerState, action: PayloadAction<number>) => {
            state.serviceOrderTypeId = action.payload;
        },
        setServiceContractorId: (state: JobTrackerState, action: PayloadAction<number | undefined>) => {
            state.serviceContractorId = action.payload;
        },
        setServiceChargeAmount: (state: JobTrackerState, action: PayloadAction<number | undefined>) => {
            state.serviceChargeAmount = action.payload;
        },
        clearContractorAndCharge: (state: JobTrackerState) => {
            state.serviceContractorId = 0;
            state.serviceChargeAmount = undefined
        }
    }
})

export const { setPageTitle, updateContract, setWithinCancellationWindow, setContractIsCancelled,
    setServiceOrderType, setServiceContractorId, setServiceChargeAmount, clearContractorAndCharge } = jobTrackerSlice.actions //Unpacks the actions created in the slice

export const selectPageTitle = (state: RootState) => state.jobTracker.pageTitle;
export const selectJobConfigurationId = (state: RootState) => state.jobTracker.jobConfigurationId;
export const selectJobContractId = (state: RootState) => state.jobTracker.jobContractId;
export const selectSAHNumber = (state: RootState) => state.jobTracker.sahNumber;
export const selectWithinCancellationWindow = (state: RootState) => state.jobTracker.withinCancellationWindow;
export const selectContractIsCancelled = (state: RootState) => state.jobTracker.contractIsCancelled;
export const selectDoesContractExist = (state: RootState) => state.jobTracker.doesContractExist;
export const selectContractSigningDate = (state: RootState) => state.jobTracker.contractSigningDate;
export const selectServiceOrderTypeId = (state: RootState) => state.jobTracker.serviceOrderTypeId;
export const selectServiceContractorId = (state: RootState) => state.jobTracker.serviceContractorId;
export const selectServiceChargeAmount = (state: RootState) => state.jobTracker.serviceChargeAmount;

export default jobTrackerSlice.reducer 