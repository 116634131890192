import { Button, Container } from "@material-ui/core";
import clsx from "clsx";
import NavbarPage from "Components/Page";
import { GetPartialProductStyleDocument, GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables, useGetProductStyleIdsQuery, useUpdateProductPricesMutation } from "generated/graphql";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { resetPricePage, selectPricingProductType, selectPricingViewFilter } from "Redux/productPricingReducer";
import { store } from "Redux/store";
import { bToF_Prices, fToB_UpdateProducts } from "./Conversions";
import ProductPricingRow from "./ProductPricingRow";
import ProductTypeSelector from "./SimpleEditors/ProductTypeSelector";
import ViewModeSelector from "./SimpleEditors/ViewModeSelector";


export default function ProductPricingPage() {
    const productTypeId = useAppSelector(selectPricingProductType)

    const { data } = useGetProductStyleIdsQuery({ variables: { productTypeId } })
    const renderRow = data?.productStyleIdsForProductType ?? []

    const [scrollHeight, setScrollHeight] = useState<number>(0);

    function handleScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
        if (e.currentTarget.scrollHeight !== scrollHeight) {
            setScrollHeight(e.currentTarget.scrollHeight)
            e.currentTarget.scrollTop = 0
        }
    }

    const viewFilter = useAppSelector(selectPricingViewFilter)

    return (
        <NavbarPage title="Product Pricing">
            <Container className="fill-width flex-row-center" maxWidth="lg">
                <div className="flex-column" style={{ alignItems: "center" }}>
                    <div className="flex-row fill-width padding-bottom-xsm">
                        <div className='flex-grow' />
                        <ProductTypeSelector />
                        <div className="w-2r" />
                        <ViewModeSelector />
                        <div className='flex-grow' />
                        <PricingSaveChangesButton />
                    </div>
                    <div className={clsx(
                        "table-fixed-new",
                        ...viewFilter.map((showCol, index) => showCol ? '' : `hide-column-${index + 1}`)
                    )} style={{ height: "calc(100vh - 14rem)" }} onScroll={handleScroll}>
                        <table style={{ margin: "0 auto" }}>
                            <thead>
                                <tr>
                                    <td align="center" className="h-3r">Style</td>
                                    <td align="center">Cost</td>
                                    <td />
                                    <td align="center">Factor</td>
                                    <td />
                                    <td align="center">Shipping</td>
                                    <td />
                                    <td align="center">Price</td>
                                    <td align="center">%</td>
                                    <td align="center">Promo</td>
                                    <td align="center">%</td>
                                    <td align="center">From Date</td>
                                    <td align="center">To Date</td>
                                    <td align="center">Delete Promo</td>
                                    <td align="center">Clear Changes?</td>
                                </tr>
                            </thead>
                            <tbody>
                                {renderRow.map(renderPricingRow)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Container>
        </NavbarPage>
    )
}

const renderPricingRow = (styleId: number) => <ProductPricingRow key={`pricing-row-${styleId}`} styleId={styleId} />

function PricingSaveChangesButton() {
    const dispatch = useAppDispatch()

    const [updateProductPrices] = useUpdateProductPricesMutation({
        update(cache, results) {
            results.data?.updateProductPrices.forEach(
                updated => {
                    const data = cache.readQuery<GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables>({
                        query: GetPartialProductStyleDocument,
                        variables: { styleId: updated.styleId }
                    })

                    if (data !== null) {

                        cache.writeQuery<GetPartialProductStyleQuery, GetPartialProductStyleQueryVariables>({
                            query: GetPartialProductStyleDocument,
                            variables: { styleId: updated.styleId },
                            data: {
                                partialProductStyle:
                                {
                                    ...data.partialProductStyle,
                                    ...bToF_Prices(updated.updatedFactor, updated.updatedShipping, updated.updatedPromo ?? undefined)
                                }
                            }
                        })
                    }
                }
            )
            dispatch(resetPricePage())
        }
    })

    function TrySubmit() {
        const { changedFactors, changedShipping, changedPromoPrices, changedStartDate, changedEndDate } = store.getState().productPricing

        try {
            var products = fToB_UpdateProducts(changedFactors, changedShipping, changedPromoPrices, changedStartDate, changedEndDate)
            updateProductPrices({
                variables: {
                    products,
                    interpretCarpetShippingInSqyd: true
                }
            })
        } catch(e){
            window.alert(e);
        }
    }

    return (
        <Button onClick={TrySubmit} variant="contained" color="secondary">Submit Changes</Button>
    )
}