import { Typography } from "@material-ui/core";
import "./printable_pick_sheet.css"

export default function PrintablePickSheet() {
    return (
        <div className="flex-column padding-vertical-sm padding-side-md">
            <div id="printable-pick-sheet-header" className="margin-bottom-xsm">
                <div id="title">
                    <h1>Pick Sheet</h1>    
                </div>

                <div id="cust-label">
                    <Typography>Customer:</Typography>
                </div>

                <div id="customer">
                    <Typography>Spaulding</Typography> 
                </div>

                <div id="inst-date-label">
                    <Typography>CPU/Install Date</Typography>   
                </div>

                <div id="inst-date">
                    <Typography>3/31/22</Typography> 
                </div>

                <div id="weekday">
                    <Typography>Thu</Typography>  
                </div>

                <div id="sah-label">
                    <Typography>SC#:</Typography>
                </div>

                <div id="sah-num">
                    <Typography>SAH-24259</Typography>
                </div>

                <div id="pick-date-label">
                    <Typography>Pick Date</Typography>
                </div>
                
                <div id="pick-date">
                    <Typography>3/25/22</Typography>  
                </div>
            </div>

            <div id="material-header-row">
                <div>
                    <div className="header">
                        <Typography style={{fontWeight: "bold"}}>Material</Typography>
                    </div>
                    
                    <div className="sub-header">
                        <div>
                            <Typography>Product</Typography>
                        </div>
                    </div>
                </div>
                
                <div className="header" style={{height: "100%"}}>
                    <Typography style={{fontWeight: "bold"}}>Supplies</Typography>
                </div>
            </div>

            <div id="material-rows-container">
                {/* TODO: generate programmatically */}
                <MaterialRow
                    product={"Carpet"} productStyle={"E0260 Kid Crossing"} color={"431 Cobalt Vibe"}
                    bin={"?"} refNum={"#N/A"} sizeFt={17} sizeIn={0}                    
                />
            </div>
        </div>
    )
}

interface MaterialRowProps {
    product: string;
    bin: string;
    productStyle: string;
    color: string;
    refNum: string;
    sizeFt: number;
    sizeIn: number;
}

function MaterialRow({product, productStyle, color, bin, refNum, sizeFt, sizeIn}: MaterialRowProps) {
    return (
        <div className="material-row">
            <div className="material-sub-box">
                <div className="product-box">
                    <Typography style={{ fontWeight: 'bold' }}>{product}</Typography>
                </div>

                <div className="bin-box">
                <Typography style={{ fontWeight: 'bold' }}>{bin}</Typography>

                </div>

                <div className="style-color-box flex-column">
                    <Typography style={{ fontWeight: 'bold' }} variant="h5">{productStyle}</Typography>
                    <Typography style={{ fontWeight: 'bold' }} variant="h6">{color}</Typography>
                </div>    
                <div className="size-box">
                    <Typography style={{ fontWeight: 'bold' }} variant="h4">{sizeFt}'</Typography>
                    <Typography className="margin-left-xsm" style={{ fontWeight: 'bold', marginBottom: ".75rem" }} variant="h5">{sizeIn}"</Typography>  
                </div>

                <div className="ref-num-box">
                    <Typography style={{ fontWeight: 'bold' }}>{refNum}</Typography>
                </div>

                <div className="cuts-box"></div>    
                <div className="balance-box"></div>    
            </div>

            <div className="supplies-sub-box">
                <div className="solid-border-bottom flex-row justify-content-center">
                    <Typography variant="h6">4 Diamond Pad</Typography>    
                </div>
                <div></div>
            </div>    
        </div>
    )
}
