import { Calendar, DayValue, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import Gallery from "Components/ImageGallery/Gallery";
import MoneyInput from "Components/MoneyInput";
import { ToggleableSubSection } from "Components/PageLayout/Section";
import FlatButton from "FlatComponents/Button/FlatButton";
import FlatTextArea from "FlatComponents/Inputs/FlatTextArea";
import {
    GetAllAreasForJobQuery,
    GetServiceOrderQuery,
    GetVendorsForColorQuery,
    namedOperations,
    Product,
    ServiceOrderInput,
    useAddPicturesForServiceOrderMutation,
    useAddServiceOrderMutation,
    useDeletePictureForServiceOrderMutation,
    useDeleteServiceOrderMutation,
    useGetAllAreasForJobQuery,
    useGetAllContractorInstallationCapabilitiesAndDaysOffQuery,
    useGetAllRoomsForJobQuery,
    useGetInstallationAppointmentsQuery,
    useGetJobConfigurationQuery,
    useGetPicturesForServiceOrderQuery,
    useGetServiceOrderQuery,
    useGetVendorsForColorLazyQuery,
    useUpdateServiceOrderMutation,
    VendorName,
} from "generated/graphql";
import { dateTimeStrToDay, dayToIso, dayToMdy } from "Globals/DateAndTimeHelpers";
import {
    isNotEmptyString,
    isNotNullOrUndefined,
    isNullOrUndefined,
} from "Globals/GenericValidators";
import {
    CHARGEABLE_REPAIR_SERVICE_ORDER_ID,
    CONTRACTOR_SERVICE_ORDER_ID,
} from "Globals/globalConstants";
import { useEffect, useState } from "react";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import UploadPictureDialog from "../../../../../Components/Forms/Controls/UploadPictureDialog";
import { SelectableContractor } from "../Breakdown/InstallationScheduling/SchedulingPopout";
import { SelectableRoom } from "../Breakdown/Notes/AddNoteDialog";
import { RoomGalleryImage } from "../JobPictures/Generics/RoomGalleryImage";
import { RoomGalleryImageBaseProps } from "../JobPictures/JobPictureViewer";

interface ServiceOrderFormProps {
    serviceOrderId: number; // -1 indicates that the service order is to be created, otherwise viewed/updated
    setServiceOrderId: (id: number) => void;
    contractId: number;
    jobConfigurationId: number;
    serviceOrderTypeId: number;
    setServiceOrderTypeId: (id: number) => void;
    contractorId: number | undefined;
    setContractorId: (id: number | undefined) => void;
    chargeAmount: number | undefined;
    setChargeAmount: (charge: number | undefined) => void;
}

type SelectableArea = GetAllAreasForJobQuery["allAreasForJob"][number];

export function getProductSummaryStr(product: Partial<Product> | undefined) {
    if (product) {
        return `${product.productType} - ${product.productStyle} - ${product.productColor}`;
    } else {
        return "";
    }
}

export default function ServiceOrderForm({
    contractId,
    jobConfigurationId,
    serviceOrderId,
    setServiceOrderId,
    serviceOrderTypeId,
    setServiceOrderTypeId,
    contractorId,
    setContractorId,
    chargeAmount,
    setChargeAmount,
}: ServiceOrderFormProps) {
    // control state
    const [editMode, setEditMode] = useState(serviceOrderId > 0);
    const [schedulingCalendarOpen, setSchedulingCalendarOpen] = useState(false); // for date service is scheduled for
    const [updateSuccessful, setUpdateSuccessful] = useState(false);
    const [pictureDialogOpen, setPictureDialogOpen] = useState(false);

    // data state
    const [calledInDate, setCalledInDate] = useState<DayValue>();
    const [scheduledDate, setScheduledDate] = useState<DayValue>(undefined);
    const [scheduleAsap, setScheduleAsap] = useState(false);
    const [completionDate, setCompletionDate] = useState<DayValue>(undefined);
    const [selectedRooms, setSelectedRooms] = useState<SelectableRoom[]>([]);
    const [natureOfService, setNatureOfService] = useState("");
    const [selectedArea, setSelectedArea] = useState<SelectableArea | undefined>(undefined);
    const [product, setProduct] = useState<Product | undefined>(undefined);
    // FIXME: tried to do this with a normal function variable, but it kept getting reset for some reason; find a way to do it that way
    // const [productVendors, setProductVendors] = useState<VendorName[]>([]);
    // const [selectedVendorId, setSelectedVendorId] = useState(0);
    const [comments, setComments] = useState("");
    const [additionalActionRequired, setAdditionalActionRequired] = useState(false);
    const [additionalActionText, setAdditionalActionText] = useState("");

    function clearAllFields() {
        setEditMode(false);
        setServiceOrderTypeId(1);
        setCalledInDate(undefined);
        setScheduledDate(undefined);
        setScheduleAsap(false);
        setCompletionDate(undefined);
        setNatureOfService("");
        setProduct(undefined);
        setComments("");
        setAdditionalActionRequired(false);
        setAdditionalActionText("");
        setContractorId(0);
        setOriginalContractors([]);
        setSelectedArea(undefined);
        setSelectedRooms([]);
        // setSelectedVendorId(0);
        // setProductVendors([]);
        // FIXME: this is properly set when clearing, but does not rerender
        setChargeAmount(undefined);
    }

    const [addServiceOrder] = useAddServiceOrderMutation({
        onCompleted: (res) => {
            if (res.addServiceOrder > 0) {
                setServiceOrderId(res.addServiceOrder);
            } else {
                alert("Could not create service order");
            }
        },
        onError: (err) => {
            alert("Could not create service order");
        },
        refetchQueries: [namedOperations.Query.GetServiceOrderStubs],
        awaitRefetchQueries: true,
    });

    // TODO: this really shouldn't be a state variable, but it often gets reset for some reason when it's a normal variable
    const [vendorMap, setVendorMap] = useState<{ [colorId: number]: VendorName[] }>({});
    function makeVendorMap(vfcs: GetVendorsForColorQuery["vendorsForColors"]) {
        let newMap: { [colorId: number]: VendorName[] } = {};
        vfcs.forEach((vfc) => (newMap[vfc.id] = vfc.vendors));
        setVendorMap(newMap);
    }
    const [getVendorsForColors] = useGetVendorsForColorLazyQuery({
        onCompleted: (res) => makeVendorMap(res.vendorsForColors),
    });

    // TODO: refactor this so that we don't need to use both useGetAllAreasForJob and GetJobCOnfiguration
    // the reason both are used is because the areas in this type are different than the SelectableAreaType
    useGetJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        skip: isNullOrUndefined(jobConfigurationId) && jobConfigurationId! <= 0,
    });

    const { data: allContractorData } =
        useGetAllContractorInstallationCapabilitiesAndDaysOffQuery();
        
    let allContractors =
        allContractorData?.allContractorInstallationCapabilitiesAndDaysOff ?? [];
    function getSelectableContractors() {
        let contractors: SelectableContractor[] = [];
        if (product) {
            let pTypeId = product!.productTypeId;
            allContractors.forEach((c) => {
                if (c.installationCapabilities.some((ic) => ic.productTypeId === pTypeId)) {
                    contractors.push({
                        id: c.contractor.id,
                        firstName: c.contractor.firstName,
                        lastName: c.contractor.lastName,
                    });
                }
            });
        }

        return contractors;
    }

    const { data: installationData } = useGetInstallationAppointmentsQuery({
        variables: { jobContractId: contractId! },
        skip: !contractId,
    });
    let installations = installationData?.installationAppointments ?? [];

    const [originalContractors, setOriginalContractors] = useState<SelectableContractor[]>([]);
    function findOriginalContractorForRoom(room: SelectableRoom) {
        let thisAppt = installations.find((inst) =>
            inst.rooms.some((r) => r.id === room.id)
        )?.appointment;
        if (thisAppt) {
            let thisContractor = allContractors.find(
                (c) => c.contractor.id === thisAppt!.contractorId!
            )?.contractor;
            if (thisContractor) {
                return {
                    id: thisContractor.id,
                    firstName: thisContractor.firstName,
                    lastName: thisContractor.lastName,
                };
            }
        }
    }

    const { data: roomData } = useGetAllRoomsForJobQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        skip: isNullOrUndefined(jobConfigurationId),
    });
    let rooms = roomData?.allRoomsForJob ?? [];

    const { data: areaData } = useGetAllAreasForJobQuery({
        variables: { jobConfigurationId: jobConfigurationId! },
        skip: isNullOrUndefined(jobConfigurationId),
        onCompleted: (response) => {
            getVendorsForColors({
                variables: { colorIds: response.allAreasForJob.map((afj) => afj.colorId!) },
            });
            // default to the first area
            if (response.allAreasForJob.length > 0) {
                onChangeSelectedArea(response.allAreasForJob[0].id);
            }
        },
    });
    let areas = areaData?.allAreasForJob ?? [];

    /* This helps with setting the default product type on page load. Sometimes the contractor data
       doesn't finish loading by the time onChangeSelectedArea is called after the area data loads,
       in which case the default contractors don't display. This makes sure that never happens by forcing
       the onChangeSelectedArea function to run again once the contractor data loads.
    */
    useEffect(() => {
        if (selectedArea && areas) {
            onChangeSelectedArea(selectedArea.id);
        }
        // eslint-disable-next-line
    }, [allContractorData]);

    function findOriginalContractorsForRooms(roomIds: number[]) {
        let contractors: SelectableContractor[] = [];
        roomIds.forEach((id) => {
            let thisRoom = rooms.find((r) => r.id === id);
            if (thisRoom) {
                let contractor = findOriginalContractorForRoom(thisRoom!);
                // ensure the contractor exists and has not already been added to the list
                if (
                    contractor &&
                    isNullOrUndefined(contractors.find((c) => c.id === contractor!.id))
                ) {
                    contractors.push(contractor!);
                }
            }
        });

        return contractors;
    }

    function getProduct(area: SelectableArea) {
        return {
            productTypeId: area.productTypeId!,
            productType: area.productTypeName!,
            productStyleId: area.styleId!,
            productStyle: area.styleName!,
            productColorId: area.colorId!,
            productColor: area.colorName!,
        };
    }

    // function getProductVendors(area: SelectableArea): VendorName[] {
    //     return vendorMap[area.colorId!] ?? []
    // }

    // returns the list of contractor for the room for convenience of use with onChangeSelectedArea
    function onSelectAllRoomsForArea(areaId: number): SelectableContractor[] {
        let roomsForArea = rooms.filter((r) => (r.areaId ?? 0) === areaId);
        setSelectedRooms(roomsForArea);
        let contractorsForRooms = findOriginalContractorsForRooms(roomsForArea.map((r) => r.id));
        setOriginalContractors(contractorsForRooms);
        return contractorsForRooms;
    }

    function onChangeSelectedArea(newId: number) {
        let newArea = areas.find((a) => a.id === newId);
        setSelectedArea(newArea);
        if (newArea) {
            let originalContractors = onSelectAllRoomsForArea(newArea!.id);
            if (
                originalContractors.length > 0 &&
                serviceOrderTypeId === CONTRACTOR_SERVICE_ORDER_ID
            ) {
                // defaults to the original contractor when not a chargeable service
                setContractorId(originalContractors[0].id);
            } else {
                setContractorId(0);
            }

            setProduct(getProduct(newArea));
            // let vendors = getProductVendors(newArea);
            // if (vendors.length > 0) {
            //     setSelectedVendorId(vendors[0].id);
            // }
            // setProductVendors(vendors);
        }
    }

    function onToggleRoomCheckbox(roomId: number, checked: boolean) {
        let newSelection: SelectableRoom[];
        if (checked) {
            newSelection = [...selectedRooms, rooms.find((r) => r.id === roomId)].filter((r) =>
                isNotNullOrUndefined(r)
            ) as SelectableRoom[];
        } else {
            newSelection = selectedRooms.filter((r) => r.id !== roomId);
        }

        setSelectedRooms(newSelection);
        setOriginalContractors(findOriginalContractorsForRooms(newSelection.map((r) => r.id)));
    }

    function onChangeScheduleAsap(newVal: boolean) {
        setScheduleAsap(newVal);
        setScheduledDate(undefined);
        setSchedulingCalendarOpen(false);
    }

    function onChangeScheduledDate(newDate: DayValue) {
        setScheduledDate(newDate);
        setSchedulingCalendarOpen(false);
        setScheduleAsap(false);
    }

    function onServiceOrderLoaded(
        so: Exclude<GetServiceOrderQuery["serviceOrder"], null | undefined>
    ) {
        setEditMode(true);
        // IMPORTANT: setChargeAmount must be called before serServiceOrderTypeId because the proper value needs to be
        // set BEFORE the MoneyInput component rerenders (it takes its only its INITIAL value from chargeAmount)
        setChargeAmount(so.chargeAmount ?? undefined);
        setServiceOrderTypeId(so.serviceOrderTypeId);
        setCalledInDate(dateTimeStrToDay(so.calledInDate!));
        setScheduledDate(so.scheduledDate ? dateTimeStrToDay(so.scheduledDate!) : undefined);
        setScheduleAsap(so.scheduleAsap);
        setCompletionDate(so.completionDate ? dateTimeStrToDay(so.completionDate!) : undefined);
        setSelectedArea(areas.find((a) => a.id === so.areaId!));
        let defaultSelectedRooms = so
            .roomIds!.map((id) => rooms.find((r) => r.id === id))
            .filter((r) => isNotNullOrUndefined(r)) as SelectableRoom[];
        setSelectedRooms(defaultSelectedRooms);
        console.log(vendorMap);
        // setVendorId(so.vendorId);
        setNatureOfService(so.serviceDescription ?? "");
        setProduct(so.product!);
        setContractorId(so.contractorId ?? 0);
        setComments(so.comments ?? "");
        setAdditionalActionRequired(so.additionalActionRequired);
        setAdditionalActionText(so.additionalActionDescription ?? "");
    }

    useGetServiceOrderQuery({
        variables: { serviceOrderId: serviceOrderId },
        skip: serviceOrderId < 1,
        onCompleted: (res) => {
            if (res.serviceOrder) {
                onServiceOrderLoaded(res.serviceOrder!);
            } else {
                alert("Could not load the requested service order");
            }
        },
    });

    // clears all fields when NEW service order option is selected
    useEffect(() => {
        if (serviceOrderId === -1) {
            clearAllFields();

            // need to default back to the first areas
            if (areas.length > 0) {
                onChangeSelectedArea(areas[0].id);
            }
        }
        // eslint-disable-next-line
    }, [serviceOrderId, vendorMap]);

    // sets the selected contractor to the default contractor when the service order is for a contractor repair
    useEffect(() => {
        if (serviceOrderTypeId === CONTRACTOR_SERVICE_ORDER_ID && originalContractors.length > 0) {
            setContractorId(originalContractors[0].id ?? undefined);
        }
        // eslint-disable-next-line
    }, [serviceOrderTypeId, setContractorId, originalContractors]);

    function canSubmitNew(): boolean {
        if (!(contractId && contractId > 0)) {
            alert("Could not submit. Please try again.");
        }

        let productInfoOk =
            selectedArea &&
            product &&
            product!.productTypeId > 0 &&
            product!.productStyleId > 0 &&
            product!.productColorId > 0;
        if (!productInfoOk) {
            alert("Select the product for which service is required");
            return false;
        }

        let roomsOk = isNotNullOrUndefined(selectedRooms) && selectedRooms.length > 0;
        if (!roomsOk) {
            alert("Select at least 1 room for service");
            return false;
        }

        // let vendorOk = isNotNullOrUndefined(selectedVendorId) && (selectedVendorId > 0);
        // if (!vendorOk) {
        //     alert("Please select the product brand");
        //     return false;
        // }

        let natureOfServiceOk =
            isNotNullOrUndefined(natureOfService) && isNotEmptyString(natureOfService.trim());
        if (!natureOfServiceOk) {
            alert("Describe the nature of required services");
            return false;
        }

        let contractorOk = true;
        let chargeAmountOk = true;
        if ([3, 4].includes(serviceOrderTypeId)) {
            // a contractor must be selected
            contractorOk = isNotNullOrUndefined(contractorId) && contractorId! > 0;

            // there is also a charge amount included
            if (serviceOrderTypeId === CHARGEABLE_REPAIR_SERVICE_ORDER_ID) {
                chargeAmountOk = isNotNullOrUndefined(chargeAmount) && chargeAmount! > 0;
            }
        }
        if (!contractorOk) {
            alert("Select a contractor");
            return false;
        }

        if (!chargeAmountOk) {
            alert("Enter a charge amount");
            return false;
        }

        return true;
    }

    function onSubmitServiceOrder() {
        if (canSubmitNew()) {
            let serviceOrder: ServiceOrderInput = {
                id: -1,
                jobContractId: contractId!,
                serviceOrderTypeId: serviceOrderTypeId,
                serviceDescription: natureOfService,
                scheduledDate: scheduledDate ? dayToIso(scheduledDate!) : null,
                scheduleAsap: scheduleAsap,
                contractorId: contractorId! > 0 ? contractorId! : null,
                // vendorId: selectedVendorId,
                additionalActionRequired: false,
                additionalActionDescription: null,
                chargeAmount: chargeAmount ?? null,
                areaId: selectedArea!.id,
                roomIds: selectedRooms.map((r) => r.id),
            };

            addServiceOrder({
                variables: { serviceOrder: serviceOrder },
            });
        }
    }

    const [updateServiceOrder] = useUpdateServiceOrderMutation({
        onCompleted: (res) => {
            if (res.updateServiceOrder) {
                setUpdateSuccessful(true);
                setTimeout(() => setUpdateSuccessful(false), 3000);
            } else {
                alert("Could not update service order");
            }
        },
        refetchQueries: [namedOperations.Query.GetServiceOrder],
        awaitRefetchQueries: true,
    });

    function canUpdate(): boolean {
        if (!(contractId && contractId > 0 && serviceOrderId > 0)) {
            alert("Could not submit. Please try again.");
        }

        let roomsOk = isNotNullOrUndefined(selectedRooms) && selectedRooms.length > 0;
        if (!roomsOk) {
            alert("Select at least 1 room for service");
            return false;
        }

        // let vendorOk = isNotNullOrUndefined(selectedVendorId) && (selectedVendorId > 0);
        // if (!vendorOk) {
        //     alert("Please select the product brand");
        // }

        let natureOfServiceOk =
            isNotNullOrUndefined(natureOfService) && isNotEmptyString(natureOfService.trim());
        if (!natureOfServiceOk) {
            alert("Describe the nature of required services");
            return false;
        }

        let contractorOk = true;
        let chargeAmountOk = true;
        if ([3, 4].includes(serviceOrderTypeId)) {
            // a contractor must be selected
            contractorOk = isNotNullOrUndefined(contractorId) && contractorId! > 0;

            // there is also a charge amount included
            if (serviceOrderTypeId === CHARGEABLE_REPAIR_SERVICE_ORDER_ID) {
                chargeAmountOk = isNotNullOrUndefined(chargeAmount) && chargeAmount! > 0;
            }
        }
        if (!contractorOk) {
            alert("Select a contractor");
            return false;
        }

        if (!chargeAmountOk) {
            alert("Enter a charge amount");
            return false;
        }

        let additionalActionOk = true;
        if (additionalActionRequired) {
            additionalActionOk = isNotEmptyString(additionalActionText.trim());
        }

        if (!additionalActionOk) {
            alert("Describe the additional action required");
            return false;
        }

        return true;
    }

    function onUpdateServiceOrder() {
        if (canUpdate()) {
            updateServiceOrder({
                variables: {
                    serviceOrder: {
                        id: serviceOrderId,
                        jobContractId: contractId, // has no effect on the update
                        serviceOrderTypeId: serviceOrderTypeId,
                        // vendorId: selectedVendorId,
                        contractorId: contractorId && contractorId > 0 ? contractorId! : null,
                        serviceDescription: natureOfService.trim(),
                        scheduledDate: isNotNullOrUndefined(scheduledDate)
                            ? dayToIso(scheduledDate!)
                            : null,
                        scheduleAsap: scheduleAsap,
                        comments: isNotEmptyString(comments.trim()) ? comments.trim() : null,
                        additionalActionRequired: additionalActionRequired,
                        additionalActionDescription: isNotEmptyString(additionalActionText.trim())
                            ? additionalActionText.trim()
                            : null,
                        chargeAmount: chargeAmount ?? null,
                    },
                    roomIds: selectedRooms.map((room) => room.id),
                },
            });
        }
    }

    function onDeleteServiceOrder() {
        if (window.confirm("Are you sure you want to delete this service order?")) {
            deleteServiceOrder({ variables: { id: serviceOrderId } });
        }
    }

    const [deleteServiceOrder] = useDeleteServiceOrderMutation({
        onCompleted: (res) => {
            if (res.deleteServiceOrder) {
                clearAllFields();
                setServiceOrderId(-1);
            } else {
                alert("Could not delete service order");
            }
        },
        onError: () => alert("Could not delete service order"),
        refetchQueries: [namedOperations.Query.GetServiceOrderStubs],
        awaitRefetchQueries: true,
    });

    const [uploadPictures] = useAddPicturesForServiceOrderMutation({
        onError: () => alert("Could not upload images"),
        refetchQueries: [namedOperations.Query.GetPicturesForServiceOrder],
        awaitRefetchQueries: true,
    });

    const [deletePicture] = useDeletePictureForServiceOrderMutation({
        onError: () => alert("Could not delete image"),
        refetchQueries: [namedOperations.Query.GetPicturesForServiceOrder],
        awaitRefetchQueries: true,
    });

    const { data: pictureData } = useGetPicturesForServiceOrderQuery({
        variables: { id: serviceOrderId },
        skip: serviceOrderId < 1,
    });
    let pictures: RoomGalleryImageBaseProps[] =
        pictureData?.picturesForServiceOrder.map((picture) =>
            // while third parameter is called pictureForRoomId, it is actually the pictureForServieOrder id in this case (see its use here in RoomGalleryImage)
            ({
                label: "",
                description: picture.picture?.description ?? "",
                pictureId: picture.picture?.id ?? -1,
                pictureForRoomId: picture.id,
            })
        ) ?? [];

    return (
        <div>
            <div className="flex-column margin-top-sm flat-font">
                {editMode && (
                    <div className="flex-row flex-gap-sm">
                        <FormControl>
                            <FormLabel component="legend">Date Called In</FormLabel>
                            <TextField
                                // FIXME: multiline and style are the only way I could get the text field to be the same width as the row below, and the underline to be in line with the select next to it
                                multiline
                                style={{ paddingBottom: ".05rem" }}
                                value={calledInDate ? dayToMdy(calledInDate!) : ""}
                                inputProps={{ readOnly: true }}
                            />
                        </FormControl>
                    </div>
                )}

                <div className="flex-row flex-gap-sm">
                    {editMode ? (
                        <FormControl>
                            <FormLabel component="legend">Product</FormLabel>
                            <TextField
                                inputProps={{ readOnly: true }}
                                value={getProductSummaryStr(product)}
                            />
                        </FormControl>
                    ) : (
                        <FormControl>
                            <FormLabel component="legend">Product</FormLabel>
                            <Select
                                id="area-selector"
                                value={selectedArea?.id ?? 0}
                                onChange={(e) => onChangeSelectedArea(e.target.value as number)}
                            >
                                {!selectedArea && <MenuItem value={0}>Select Product</MenuItem>}
                                {areas.map((a) => (
                                    <MenuItem
                                        value={a.id}
                                        key={`a-${a.id}`}
                                    >
                                        {getProductSummaryStr(getProduct(a))}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {/* <FormControl>
                        <FormLabel component="legend">Brand</FormLabel>
                        {(product && vendorMap) ? (
                            <Select value={selectedVendorId} onChange={e => setSelectedVendorId(e.target.value as number)} >
                                {(selectedVendorId <= 0) && <MenuItem disabled value={0} key="v-0">Select Brand</MenuItem>}
                                {productVendors.map(v => <MenuItem  value={v.id} key={`v-${v.id}`}>{v.name}</MenuItem>)}
                            </Select>
                        ) : (
                            // this is just a placeholder because when no product area/room is selected, there should be no Select dropdown
                            <TextField
                                inputProps={{readOnly: true}}
                                value=''
                            />
                        )}
                        
                    </FormControl>         */}

                    <FormControl>
                        <FormLabel component="legend">
                            Original Contractor{originalContractors.length > 1 && "s"}
                        </FormLabel>
                        <TextField
                            multiline // allows text to wrap
                            inputProps={{ readOnly: true }}
                            value={originalContractors
                                .map((c) => `${c.firstName} ${c.lastName}`)
                                .join(", ")}
                        />
                    </FormControl>
                </div>

                {selectedArea && (
                    <FormControl className="margin-top-sm">
                        <FormLabel component="legend">Rooms</FormLabel>
                        <div className="flex-row align-items-center">
                            <FormGroup row>
                                {selectedArea.rooms.map((r) => (
                                    <FormControlLabel
                                        key={`room-${r.id}`}
                                        label={getNameOfArea(r.labels)}
                                        control={
                                            <Checkbox
                                                size="small"
                                                style={{color: "black"}}
                                                checked={isNotNullOrUndefined(
                                                    selectedRooms.find((sr) => sr.id === r.id)
                                                )}
                                                onChange={(e) =>
                                                    onToggleRoomCheckbox(r.id, e.target.checked)
                                                }
                                            />
                                        }
                                    />
                                ))}
                            </FormGroup>
                            <FlatButton
                                style={{fontSize: "8pt"}}
                                className="fit-content"
                                variant="contained"
                                color="secondary"
                                onClick={() => onSelectAllRoomsForArea(selectedArea!.id)}
                            >
                                All
                            </FlatButton>
                        </div>
                    </FormControl>
                )}

                <div className="flex-column">
                    <FormControl
                        className="margin-top-sm"
                        style={{ width: "50%" }}
                    >
                        <FormLabel component="legend">Nature of Service</FormLabel>
                        <FlatTextArea
                            rows={3}
                            value={natureOfService}
                            onChange={(e) => setNatureOfService(e.target.value)}
                        />
                    </FormControl>

                    {pictures.length > 0 && (
                        <ToggleableSubSection title="Photos">
                            <Gallery rowMax={2}>
                                {pictures.map((picture) => (
                                    <RoomGalleryImage
                                        key={`picture-${picture.pictureId}`}
                                        {...picture}
                                        deleteImage={() =>
                                            deletePicture({
                                                variables: { id: picture.pictureForRoomId },
                                            })
                                        }
                                    />
                                ))}
                            </Gallery>
                        </ToggleableSubSection>
                    )}

                    {serviceOrderId > 0 && (
                        <div
                            className="flex-column"
                            style={{ margin: "1em 0", width: "fit-content" }}
                        >
                            <Button
                                onClick={() => setPictureDialogOpen(true)}
                                variant="contained"
                                color="secondary"
                            >
                                Add New Photos
                            </Button>
                        </div>
                    )}
                </div>

                {pictureDialogOpen && (
                    <UploadPictureDialog
                        open={true}
                        closeDialog={() => setPictureDialogOpen(false)}
                        uploadImages={(pics) =>
                            uploadPictures({
                                variables: { serviceOrderId: serviceOrderId, pictures: pics },
                            })
                        }
                    />
                )}

                <div className="flex-row margin-top-sm flex-gap-md">
                    <div>
                        <FormControl>
                            <FormLabel component="legend">Date of Service</FormLabel>
                            <div className="flex-row">
                                <TextField
                                    value={
                                        scheduledDate
                                            ? dayToMdy(scheduledDate!)
                                            : scheduleAsap
                                            ? "ASAP"
                                            : "Select Date"
                                    }
                                    inputProps={{ readOnly: true }}
                                    onClick={() => setSchedulingCalendarOpen(true)}
                                />
                            </div>

                            {!scheduledDate && (
                                <div className="flex-row">
                                    <Checkbox
                                        id="schedule-asap"
                                        size="small"
                                        style={{ paddingLeft: 0, color: "black"}}
                                        checked={scheduleAsap}
                                        onChange={(asap) =>
                                            onChangeScheduleAsap(asap.target.checked)
                                        }
                                    />
                                    <FormLabel style={{ marginTop: "0.75rem" }}>ASAP</FormLabel>
                                </div>
                            )}

                            {schedulingCalendarOpen && (
                                <div className="margin-top-sm">
                                    <Calendar
                                        minimumDate={utils("en").getToday()}
                                        value={scheduledDate}
                                        onChange={(newDate) => onChangeScheduledDate(newDate)}
                                        colorPrimary="var(--wof-red)"
                                        colorPrimaryLight="#f79cab"
                                    />
                                </div>
                            )}
                        </FormControl>
                    </div>

                    {editMode && (
                        <FormControl>
                            <FormLabel component="legend">Date Completed</FormLabel>
                            <TextField
                                value={completionDate ? dayToMdy(completionDate!) : ""}
                                inputProps={{ readOnly: true }}
                            />
                        </FormControl>
                    )}

                    {/* service orders requiring contractors have IDs 3 and 4 */}
                    {[3, 4].includes(serviceOrderTypeId) && (
                        <FormControl>
                            <FormLabel component="legend">Assign To</FormLabel>
                            <Select
                                value={contractorId ?? 0}
                                onChange={(e) => setContractorId(e.target.value as number)}
                            >
                                {(contractorId ?? 0) === 0 && (
                                    <MenuItem
                                        disabled
                                        value={0}
                                    >
                                        Select Contractor
                                    </MenuItem>
                                )}
                                {getSelectableContractors().map((sc) => (
                                    <MenuItem
                                        value={sc.id}
                                        key={`sc-${sc.id}`}
                                    >
                                        {sc.firstName} {sc.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {serviceOrderTypeId === CHARGEABLE_REPAIR_SERVICE_ORDER_ID && (
                        <FormControl>
                            <FormLabel>Charge Amount</FormLabel>
                            {/* FIXME: the bottom of the input wasn't lining up with the others in the row, so this is a hacky fix for that */}
                            <span style={{ marginTop: ".5rem" }}>
                                <MoneyInput
                                    label=""
                                    initialValue={chargeAmount}
                                    setValue={setChargeAmount}
                                    variant="standard"
                                />
                            </span>
                        </FormControl>
                    )}
                </div>

                {editMode && (
                    <>
                        <div className="flex-row margin-top-sm">
                            <FormControl style={{ width: "50%" }}>
                                <FormLabel>Contractor Comments</FormLabel>
                                <TextField
                                    multiline
                                    minRows={3}
                                    maxRows={10}
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                />
                            </FormControl>
                        </div>

                        <div className="margin-bottom-md margin-top-sm">
                            <Checkbox
                                style={{ paddingLeft: 0 }}
                                checked={additionalActionRequired}
                                onChange={(e) => {
                                    setAdditionalActionRequired(e.target.checked);
                                    setAdditionalActionText("");
                                }}
                            />
                            <FormLabel className="margin-none">
                                Additional Action Required
                            </FormLabel>

                            <br />

                            {additionalActionRequired && (
                                <FormControl style={{ width: "50%" }}>
                                    <FormLabel>Additional Actions</FormLabel>
                                    <TextField
                                        multiline
                                        minRows={3}
                                        value={additionalActionText}
                                        onChange={(e) => setAdditionalActionText(e.target.value)}
                                    />
                                </FormControl>
                            )}
                        </div>
                    </>
                )}
            </div>

            {updateSuccessful && <p className="success-text padding-none">Updated Successfully</p>}
            <div className="flex-row">
                {editMode ? (
                    <>
                        <Button
                            className="submit-button margin-bottom-md"
                            variant="contained"
                            onClick={onUpdateServiceOrder}
                        >
                            Update Service Order
                        </Button>

                        <SpacedButton
                            className="cancel-button margin-bottom-md"
                            variant="contained"
                            onClick={onDeleteServiceOrder}
                        >
                            Delete Service Order
                        </SpacedButton>
                    </>
                ) : (
                    <FlatButton
                        className="margin-bottom-md margin-top-sm"
                        variant="contained"
                        color="primary"
                        onClick={onSubmitServiceOrder}
                    >
                        Create Service Order
                    </FlatButton>
                )}
            </div>
        </div>
    );
}
