import { Typography } from "@material-ui/core"
import { selectAreaIndex, selectArea } from "Redux/genericSalesReducer"
import { useAppSelector } from "Redux/hooks"

export default function AreaSqftAdditionalDetailsViewer() {
    const selectedAreaIndex = useAppSelector(selectAreaIndex)
    const selectedArea = useAppSelector(selectArea(selectedAreaIndex))

    return (
        <>
            <div className="flex-row fill-width padding-bottom-xsm" style={{ alignItems: "center", justifyContent: "center" }}>
                {
                    selectedArea.rooms.map((room, index) => {
                        return <Typography key={`area-${selectedAreaIndex}-room-${index}`} style={{ paddingLeft: ".5em" }}>
                            {room.labels[0].label.label}: {room.sqft}sqft
                        </Typography>
                    })
                }
            </div>
            {
                selectedArea.rooms.map((room, index) => {

                    return (
                        <div className="flex-row fill-width padding-bottom-xsm" key={`area-${selectedAreaIndex}-room-${index}-services`} style={{ alignItems: "center", justifyContent: "center" }}>
                            {
                                room.services.map((ser, index) => {
                                    return <Typography key={`area-${selectedAreaIndex}-room-${index}-service-${index}`} style={{ paddingLeft: ".5em" }}>
                                        JS-{ser.jobServiceId}: {ser.laborAmount}un.
                                    </Typography>
                                })
                            }
                        </div>
                    )
                })
            }

        </>
    )
}