import { selectColorVariantSpecValues, setColorVariantSpecValues } from "Redux/addProductReducer";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import SpecificationViewer, { SpecEditorValues } from "../ProductSpecViewer/ProductSpecViewer";

interface NewColorSpecEditorProps {
    colorName: string;
}

export default function NewColorSpecEditor({ colorName }: NewColorSpecEditorProps) {
    const dispatch = useAppDispatch();
    const values = useAppSelector(selectColorVariantSpecValues);
    const setValues: (updateFunction: (prevValues: SpecEditorValues) => SpecEditorValues) => void = 
        (updateFunction) => dispatch(setColorVariantSpecValues(updateFunction(values)))


    return (
        <div className="fill-width flex-column">
            <SpecificationViewer
                values={values}
                setValues={setValues}
                specName={`${colorName}-default`}
            />
        </div>
    )
}