import { IconButton } from "@material-ui/core";
import { CalendarToday, ListAlt } from "@material-ui/icons";
import { dateTimeStrToDate, dateToMdy } from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { formatMonthYear } from "Globals/StringFormatting";
import {
    Contractor,
    GetWorkerBlockedTimeSlotsAfterDateQuery,
    InstallationAppointment,
    WorkerBlockedTimeSlot,
    useGetInstallationAppointmentForContractorAfterDateQuery,
    useGetPartialServiceOrdersForContractorQuery,
    useGetWorkerBlockedTimeSlotsAfterDateQuery,
} from "generated/graphql";
import { useMemo, useState } from "react";
import { ContractorAppointmentCalendar } from "./ContractorAppointmentCalendar";
import { ContractorAppointmentList } from "./ContractorAppointmentList";
import ContractorNavbarPage from "./ContractorNavbarPage";
import "./contractor-page.css";

interface ContractorHomeProps {
    contractor: Contractor;
}

const WEEK_LENGTH = 7;
const WEEKS_TO_SHOW_PREVIOUS = 3;
const WEEKS_TO_SHOW_NEXT = 3;
const TOTAL_WEEKS_TO_SHOW = WEEKS_TO_SHOW_PREVIOUS + 1 + WEEKS_TO_SHOW_NEXT;
export const TOTAL_DAYS_TO_SHOW = TOTAL_WEEKS_TO_SHOW * WEEK_LENGTH;

function calculateStartOfPeriod(): Date {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(today.setDate(diff - WEEKS_TO_SHOW_PREVIOUS * WEEK_LENGTH));
}

export default function ContractorHome({ contractor }: ContractorHomeProps) {
    const { startDate, dateString } = useMemo(() => {
        const sd = calculateStartOfPeriod();

        return { startDate: sd, dateString: dateToMdy(sd, "-") };
    }, []);

    const { data } = useGetInstallationAppointmentForContractorAfterDateQuery({
        variables: { contractorId: contractor.id, afterDate: dateString },
        pollInterval: 5000,
    });

    const { data: serviceData } = useGetPartialServiceOrdersForContractorQuery({
        variables: { contractorId: contractor.id },
    });

    const { data: blockedData } = useGetWorkerBlockedTimeSlotsAfterDateQuery({
        variables: { workerId: contractor.workerId, afterDate: dateString },
    });

    const appointments = useMemo(
        () => data?.installationAppointmentForContractorAfterDate ?? [],
        [data]
    );
    const services = useMemo(
        () => serviceData?.partialServiceOrdersForContractor ?? [],
        [serviceData]
    );
    const blocked = useMemo(
        () => blockedData?.workerBlockedTimeSlotsAfterDate ?? [],
        [blockedData]
    );

    const [listTarget, setListTarget] = useState<null | string>(null);

    const calendarProps = useMemo(() => {
        const appointments = data?.installationAppointmentForContractorAfterDate ?? [];
        const services = serviceData?.partialServiceOrdersForContractor ?? [];
        const day = new Date(startDate);

        const days = [...Array(TOTAL_DAYS_TO_SHOW)].map((v, dayOffset) => {
            const dayString = dateToMdy(day);

            day.setDate(day.getDate() + 1);
            return dayString;
        });

        const appointmentByDays: { [key: string]: InstallationAppointmentBlock[] } = {};

        appointments.forEach((appt) => {
            const startDate = dateTimeStrToDate(appt.dates[0]);

            const dayCount = [...Array(appt.totalDaysInRange ?? 1)];
            dayCount.forEach((v, index) => {
                const dayString = dateToMdy(startDate);
                startDate.setDate(startDate.getDate() + 1);

                appointmentByDays[dayString] = [
                    ...(appointmentByDays[dayString] ?? []),
                    { ...appt, daysIntoAppointment: index + 1 },
                ];
            });
        });

        const eventsByDays: AppointmentEventsByDate = { ...appointmentByDays };

        if (services.length > 0) {
            console.log("Service Dashboard has not been created yet");
        }

        return { days, eventsByDays };
    }, [data, serviceData, startDate]);

    const allReady =
        isNotNullOrUndefined(data) &&
        isNotNullOrUndefined(blockedData) &&
        isNotNullOrUndefined(serviceData);

    return (
        <ContractorNavbarPage>
            <div className="contractor-page">
                <div
                    className="flex-row flat-dark-bkg flat-inner-container"
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "12px"
                    }}
                >
                    <div
                        className="flat-font-md"
                        style={{ paddingRight: "4px" }}
                    >
                        {formatMonthYear(new Date())}
                    </div>
                    <IconButton
                        style={{
                            height: "20px",
                            width: "20px",
                            color: "var(--theme-main-text-color)",
                        }}
                        onClick={() => setListTarget(listTarget === null ? "" : null)}
                    >
                        {listTarget === null ? (
                            <CalendarToday
                                fontSize="small"
                                style={{ height: "20px", width: "20px" }}
                            />
                        ) : (
                            <ListAlt
                                fontSize="small"
                                style={{ height: "20px", width: "20px" }}
                            />
                        )}
                    </IconButton>
                </div>
                {allReady && (
                    <>
                        {listTarget === null ? (
                            <ContractorAppointmentCalendar
                                days={calendarProps.days}
                                appointments={appointments}
                                services={services}
                                blocked={blocked}
                                openToDay={setListTarget}
                                useFake={false}
                            />
                        ) : (
                            <ContractorAppointmentList
                                {...calendarProps}
                                blocked={blocked}
                                scrollTarget={listTarget!}
                            />
                        )}
                    </>
                )}
            </div>
        </ContractorNavbarPage>
    );
}

type AppointmentEventsByDate = {
    [key: string]: InstallationAppointmentBlock[];
};

export interface ContractorAppointmentPageProps {
    days: string[];
    eventsByDays: AppointmentEventsByDate;
    blocked: GetWorkerBlockedTimeSlotsAfterDateQuery["workerBlockedTimeSlotsAfterDate"];
}

export interface EventDateProps {
    day: string;
    events: InstallationAppointmentBlock[];
    isBlocked: boolean;
}

export interface AppointmentDateProps {
    appointments: InstallationAppointment[];
}

export interface BlockedDateProps {
    timeslot: WorkerBlockedTimeSlot;
}

export interface InstallationAppointmentBlock extends InstallationAppointment {
    daysIntoAppointment: number;
}
