import { Card, CardActionArea, CardMedia } from "@material-ui/core";
import './product_selection_card.css';

interface ProductSelectionCardProps {
    img: string,
    alt: string,
    handleSelection: () => void;
    selectionControl: boolean
}

export default function ProductSelectionCard({ img, alt, handleSelection, selectionControl }: ProductSelectionCardProps) {
    return (
        <Card  
            onClick={handleSelection}
            className={selectionControl ? "card-selected" : "card-unselected"}
        >
            <CardActionArea>
                <CardMedia
                    height="200rem"
                    component="img"
                    alt={alt}
                    image={img}
                />
            </CardActionArea>
        </Card>
    )
}